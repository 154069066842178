import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import styled from "styled-components/macro"

import {
  WritingLinePayload,
  getDisplayedBillOfExchangeCode,
} from "../../utils/inputMask"
import { useRNBSelector } from "../../store/rootReducer"
import { getIdFromParams } from "../../utils/company"
import { Option, Select } from "../Commons/Select"
import { colors } from "../../styles/design.config"
import { changeBillOfExchangeMerchant } from "../../store/ducks/inputMask.ducks"

export const BillOfExchangeMerchantSelectCell = ({
  writingLine,
  isHoveredForDeletion,
  isHoveredForFuel,
}: {
  writingLine: WritingLinePayload
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
}) => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const dispatch = useDispatch()

  const {
    merchantCodes,
    company,
    editedWritingLines,
    editedFullDocument,
    accounts,
  } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
    editedWritingLines: state.inputMask.edited_writing_lines,
    merchantCodes: state.merchantCodes.merchantCodes,
    company: state.companies.companies[selectedCompanyId],
    accounts: state.inputMask.all_company_accounts,
  }))

  const merchantCodeOptions: Option<string>[] = merchantCodes.map((m) => ({
    value: m.id.toString(),
    label: m.code,
    disabled: false,
  }))

  const editedWritingLine =
    editedWritingLines.find(
      (wl) => wl.writing_line_uuid === writingLine.writing_line_uuid
    ) || writingLine

  const selectedMerchantCode = editedWritingLine
    ? merchantCodes.find(
        (mc) => editedWritingLine.merchant_code_id === mc.id
      ) || null
    : null

  const buyThirdPartyAccount =
    accounts?.buy.tax_included.find(
      (a) => a?.id === company?.buy_third_party_account_id
    ) || accounts?.buy.tax_included[0]
  return (
    <AbsoluteWrapper showError={Boolean(!selectedMerchantCode?.id)}>
      <StyledSelect
        height="4rem"
        intl={null}
        value={{
          value: (selectedMerchantCode?.id || "").toString(),
          label: getDisplayedBillOfExchangeCode({
            buyThirdPartyAccounNumber: buyThirdPartyAccount?.number,
            writingLine: editedWritingLine,
            buyOrSell: editedFullDocument?.buy_or_sell || "buy",
            merchantCode: selectedMerchantCode,
            buyAuxiliaryPrefix: company?.buy_auxiliary_prefix || "",
            sellAuxiliaryPrefix: company?.sell_auxiliary_prefix || "",
          }),
        }}
        defaultValue={null}
        options={merchantCodeOptions}
        onChangeCallback={(e) => {
          if (e.value) {
            dispatch(
              changeBillOfExchangeMerchant({
                writingLine,
                newMerchantCodeId: Number(e.value),
              })
            )
          }
        }}
        border="none"
        borderRadius="0"
        backgroundColor={
          isHoveredForDeletion
            ? "rgba(252, 90, 90, 0.2)"
            : isHoveredForFuel
            ? "rgba(107, 146, 255, 0.2)"
            : colors.white
        }
      />
    </AbsoluteWrapper>
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`

const AbsoluteWrapper = styled.div<{
  showError: boolean
}>`
  position: relative;
  width: 100%;
  border: ${({ showError }) =>
    showError ? `1px solid ${colors.amaranth}` : "auto"};
`
