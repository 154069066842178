import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { colors } from "../styles/design.config"
import * as Ct from "ldlj"

import { getIdFromParams } from "../utils/company"
import {
  Account,
  createOrUpdateAccountResetAction,
  createAccountThunk,
  updateAccountThunk,
  getAllAccountsThunk,
  deactivateAccountThunk,
  reactivateAccountThunk,
  deactivateOrReactivateAccountReset,
} from "../store/ducks/accounts.ducks"
import { useRNBSelector } from "../store/rootReducer"
import { useDispatch } from "react-redux"

interface CreateOrModifyAccountModalProps {
  createOrModify: "create" | "modify"
  selectedAccount?: Account
  onClose: () => void
  isDisplayed: boolean
}

export const CreateOrModifyAccountModal = ({
  isDisplayed,
  onClose,
  createOrModify,
  selectedAccount,
}: CreateOrModifyAccountModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const accounts = useRNBSelector((state) => state.accounts.all_accounts)
  const createOrUpdateAccountStatus = useRNBSelector(
    (state) => state.accounts.accountCreationOrUpdateStatus
  )
  const reactivateOrDeactivateAccountStatus = useRNBSelector(
    (state) => state.accounts.reactivateOrDeactivateAccountStatus
  )
  const [existingAccount, setExistingAccount] = useState<Account | undefined>(
    selectedAccount ? selectedAccount : undefined
  )
  const [number, setNumber] = useState<string>("")
  const [details, setDetails] = useState<string>("")
  const [isDeactivated, setIsDeactivated] = useState(false)

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    if (
      createOrUpdateAccountStatus === "SUCCESS" ||
      reactivateOrDeactivateAccountStatus === "SUCCESS"
    ) {
      if (selectedCompanyId) {
        dispatch(getAllAccountsThunk(selectedCompanyId))
      }
      closeModal()
    }
  }, [createOrUpdateAccountStatus, reactivateOrDeactivateAccountStatus])

  const handleFormSubmit = () => {
    if (selectedCompanyId) {
      if (createOrModify === "create") {
        dispatch(createAccountThunk(selectedCompanyId, number, details))
      }
      if (createOrModify === "modify" && existingAccount) {
        if (isDeactivated !== (existingAccount.deactivated_at ? true : false)) {
          if (isDeactivated) {
            dispatch(deactivateAccountThunk(selectedCompanyId, existingAccount))
          } else {
            dispatch(reactivateAccountThunk(selectedCompanyId, existingAccount))
          }
        }
        if (
          number !== existingAccount.number ||
          details !== existingAccount.details
        ) {
          dispatch(
            updateAccountThunk(
              selectedCompanyId,
              existingAccount.id,
              number,
              existingAccount.number,
              details,
              existingAccount.details || ""
            )
          )
        }
      }
    }
  }

  useEffect(() => {
    if (selectedAccount) {
      setExistingAccount(selectedAccount)
    }
  }, [selectedAccount, setExistingAccount])

  useEffect(() => {
    if (!isDisplayed) {
      resetForm()
    }
  }, [isDisplayed])

  const resetForm = () => {
    setNumber("")
    setDetails("")
    setIsDeactivated(false)
    setExistingAccount(undefined)
    dispatch(createOrUpdateAccountResetAction())
    dispatch(deactivateOrReactivateAccountReset())
  }

  useEffect(() => {
    if (isDisplayed && existingAccount) {
      setNumber(existingAccount.number)
      setDetails(existingAccount.details || "")
      setIsDeactivated(existingAccount.deactivated_at ? true : false)
    }
  }, [existingAccount, isDisplayed])

  const isNumberInvalid = accounts.some(
    (acc) => acc.number === number && number !== existingAccount?.number
  )

  const disableSaveButton =
    !number ||
    !details ||
    (createOrModify === "modify" &&
      number === existingAccount?.number &&
      details === existingAccount.details &&
      !isDeactivated === !existingAccount.deactivated_at) ||
    isNumberInvalid

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 50rem)"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={3} />
        <Ct.Title
          text={intl.formatMessage({
            id: `accounting-plan.accounts.modal.title.${createOrModify}`,
          })}
          size={3}
        />
        <Ct.Spacer height={3} />
        <VerticalLine />
        <Ct.Spacer height={5} />

        <Content>
          {selectedAccount?.buy_third_party_account && (
            <>
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.accounts.third-party-account.buy",
                })}
              />
              <Ct.Spacer />
            </>
          )}
          {selectedAccount?.sell_third_party_account && (
            <>
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.accounts.third-party-account.sell",
                })}
              />
              <Ct.Spacer />
            </>
          )}
          <Ct.Input
            value={number}
            onChange={(e) => {
              setNumber(
                isNaN(Number(e.target.value)) ||
                  [".", " "].includes(
                    e.target.value.charAt(e.target.value.length - 1)
                  )
                  ? number
                  : e.target.value
              )
            }}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.input.number",
            })}
            showError={isNumberInvalid}
          />
          {isNumberInvalid && (
            <>
              <Ct.Spacer height={0.5} />
              <StyledText
                text={intl.formatMessage({
                  id: "accounting-plan.accounts.modal.input.number.error",
                })}
              />
            </>
          )}
          <Ct.Spacer height={4} />

          <Ct.Input
            value={details || ""}
            onChange={(e) => {
              setDetails(e.target.value)
            }}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.input.details",
            })}
          />

          <Ct.Spacer height={4} />

          {createOrModify === "modify" && (
            <SwitchWrapper>
              <Ct.Switch
                value={!isDeactivated}
                onToggle={() => {
                  setIsDeactivated(!isDeactivated)
                }}
              />

              <Ct.Spacer width={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: isDeactivated
                    ? "accounting-plan.accounts.modal.switch.is_deactivated"
                    : "accounting-plan.accounts.modal.switch.is_activated",
                })}
                textStyle={{
                  fontSize: 1.75,
                  color: "navy",
                }}
              />
            </SwitchWrapper>
          )}
        </Content>

        <Ct.Spacer height={4} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />

          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.save",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            disabled={disableSaveButton}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
        </ButtonsPanel>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Content = styled.div`
  width: 100rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const VerticalLine = styled.div`
  height: 2px;
  background: ${colors.mist};
  width: 100%;
`

const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100rem;
`

const StyledText = styled(Ct.Text)`
  padding-left: 2rem;
  color: ${colors.amaranth};
`
