import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import {
  ModalComponentsProps,
  Modal,
  Title,
  Spacer,
  Row,
  JustifyCenter,
  Text,
  Checkbox,
} from "ldlj"
import {
  canDeactivateStatus,
  updateDeactivateCompanyReasons,
} from "../store/ducks/companies.ducks"
import styled from "styled-components/macro"
import { ReactComponent as CloseCrossSVG } from "../assets/close-cross.svg"
import { colors } from "../styles/design.config"
import { useState } from "react"

interface DeleteCompanyProps extends ModalComponentsProps {
  accountingType: "accrual_accounting" | "cash_accounting"
}

export const DeleteCompanyModal = ({
  onClose,
  isDisplayed,
  accountingType,
}: DeleteCompanyProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [nbReasons, setNbReasons] = useState(0)

  const reasons = ["leave", "functionalities", "closed"]

  const reasonsForAccounting =
    accountingType === "accrual_accounting"
      ? [...reasons, "time", "quantity", "quality", "price"]
      : [...reasons, "unavailable_bank"]

  const checkboxStatusInitialState = reasonsForAccounting.map((r) => false)

  const [checkboxStatus, setcheckboxStatus] = useState(
    checkboxStatusInitialState
  )

  const [reasonsList, setreasonsList] = useState<Array<string>>([])

  const updateReasonsList = (value: string, toRemove: boolean) => {
    var newReasonsList = [...reasonsList]
    if (toRemove) {
      newReasonsList = newReasonsList.filter((chaine) => chaine !== value)
    } else {
      newReasonsList.push(value)
    }
    setreasonsList(newReasonsList)
  }

  const handleSaveClick = () => {
    dispatch(updateDeactivateCompanyReasons(reasonsList))
    dispatch(canDeactivateStatus(true))
  }

  const resetModalStates = () => {
    setreasonsList([])
    setcheckboxStatus(checkboxStatusInitialState)
    onClose()
  }

  return (
    <Modal isDisplayed={isDisplayed} onClose={onClose} top={"30rem"}>
      <StyledCard>
        <HeaderPreviewModal>
          <TextWrapper>
            <Title
              text={intl.formatMessage({
                id: "office-company-deactivate.modal.title",
              })}
              size={3}
            />
          </TextWrapper>
          <CrossWrapper onClick={() => onClose()}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Line />
        <Spacer height={2} />
        <Content>
          <JustifyCenter>
            <Text
              text={intl.formatMessage({
                id: "office-company-deactivate.modal.reason.header",
              })}
              textStyle={{ lineHeight: 3, fontWeight: 500 }}
            />
          </JustifyCenter>
          <Spacer height={3} />
          <StyledColumn>
            {reasonsForAccounting.map((value, index) => {
              return (
                <StyledRow key={index}>
                  <Checkbox
                    label={""}
                    name={value}
                    isChecked={checkboxStatus[index]}
                    onChange={() => {
                      checkboxStatus[index]
                        ? setNbReasons(nbReasons - 1)
                        : setNbReasons(nbReasons + 1)
                      setcheckboxStatus([
                        ...checkboxStatus.slice(0, index),
                        !checkboxStatus[index],
                        ...checkboxStatus.slice(index + 1),
                      ])

                      updateReasonsList(value, checkboxStatus[index])
                    }}
                  />
                  <Spacer width={2} />
                  <Text
                    text={intl.formatMessage({
                      id: `office-company-deactivate.modal.reason.${value}`,
                    })}
                    textStyle={{ lineHeight: 3 }}
                  />
                </StyledRow>
              )
            })}
          </StyledColumn>
        </Content>
        <ButtonsPanel>
          <StyledButton
            onClick={() => {
              resetModalStates()
            }}
          >
            {intl.formatMessage({
              id: `office-company-deactivate.modal.back`,
            })}
          </StyledButton>
          <Spacer width={5} />
          <StyledButton
            disabled={reasonsList.length === 0}
            onClick={() => {
              resetModalStates()
              handleSaveClick()
            }}
          >
            {intl.formatMessage({
              id: `office-company-deactivate.modal.save`,
            })}
          </StyledButton>
        </ButtonsPanel>
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled.div`
  width: 100rem;
  background-color: white;
  border-radius: 10px;
`
const ButtonsPanel = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0 3rem 0;
  width: 100%;
`
const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 3rem 0;
`
const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Content = styled.div`
  width: 100%;
`
const StyledColumn = styled.div`
  margin-left: 12rem;
`
const StyledRow = styled(Row)`
  margin-bottom: 15px;
  align-items: center;
`
const StyledButton = styled.button`
  background: ${colors.mist};
  color: ${(props) => (props.disabled ? colors.moon : colors.cornflower)};
  border-radius: 1.25rem;
  border: none;
  width: 34rem;
  height: 4rem;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
`
