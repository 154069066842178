import styled, { css } from "styled-components/macro"
import { Fragment, ReactNode } from "react"
import { bump, colors } from "../styles/design.config"

interface TabProps {
  isSelected: boolean
  children: ReactNode
}

const Tab = ({ children, isSelected }: TabProps) => {
  return (
    <>
      <StyledLink match={isSelected}>{children}</StyledLink>
      <Underline match={isSelected} />
    </>
  )
}

interface MatchingLink {
  match: unknown
}

interface TabsProps {
  tabs: TabObject[]
}

export interface TabObject {
  title: string
  iconIdle: ReactNode
  iconSelected?: ReactNode
  isDisplayed: boolean
  isSelected: boolean
  onClick: () => void
}

export const TabsWithContent = ({ tabs }: TabsProps) => {
  return (
    <>
      <nav>
        <TabsWrapper id="tabList">
          {tabs.map((tab, i) => (
            <Fragment key={i}>
              {tab.isDisplayed && (
                <ListItem
                  key={tab.title}
                  onClick={() => {
                    tab.onClick()
                  }}
                >
                  <Tab isSelected={tab.isSelected}>
                    {tab.isSelected ? tab.iconSelected : tab.iconIdle}
                    {tab.title}
                  </Tab>
                </ListItem>
              )}
            </Fragment>
          ))}
        </TabsWrapper>
      </nav>
    </>
  )
}

const TabsWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style-type: none;
  padding: 0 2.5rem;
`

// !important to override rsuite global css
const StyledLink = styled.div<MatchingLink>`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.match
      ? `${colors.cornflower} !important`
      : `${colors.steelBlue} !important`};

  text-decoration: none;

  transition: color 0.35s ease-in-out 0.1s;
  cursor: pointer;

  & svg {
    padding-right: 0.75rem;
    width: 2rem;
    height: 2rem;
  }

  & path {
    transform-origin: center;

    animation: ${(props) =>
      props.match
        ? css`0.6s ${bump} 0.3s ease-in-out` /* stylelint-disable-line */
        : ""};
    transition: fill 0.6s ease-in-out;
  }
`

const Underline = styled.div<MatchingLink>`
  border-top: ${(props) =>
    props.match ? `2px solid ${colors.cornflower}` : `2px solid transparent`};
  border-radius: 5px;
  transition: border-top 0.35s ease-in-out 0.1s;
`
