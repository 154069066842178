import { DeactivatedDocumentInWritingForInputMaskWithLabels } from "../components/inputMask/DeactivatedWritingLines"
import { FullDocumentForInputMaskWithNewMerchant } from "../components/inputMask/FullDocumentsList"
import { DocumentType } from "./inputMask"

export const sortDocsByMerchantCode = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a?.merchant_code.localeCompare(b.merchant_code)
        : b?.merchant_code.localeCompare(a.merchant_code)
    ),
  ]
}

export const sortDocsByDate = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? +new Date(b.fd_document_date) - +new Date(a.fd_document_date)
        : +new Date(a.fd_document_date) - +new Date(b.fd_document_date)
    ),
  ]
}

export const sortDocsByIncludingTaxesAmount = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) => {
      const aAmount =
        a.writing_lines.find(
          (line) =>
            line.datum_type === "tax_included" ||
            line.datum_type === "tax_included_bill_of_exchange"
        )?.amount || "0"
      const bAmount =
        b.writing_lines.find(
          (line) =>
            line.datum_type === "tax_included" ||
            line.datum_type === "tax_included_bill_of_exchange"
        )?.amount || "0"

      return asc
        ? parseInt(aAmount) - parseInt(bAmount)
        : parseInt(bAmount) - parseInt(aAmount)
    }),
  ]
}

type TypologyOrder = `${"buy" | "sell"}-${DocumentType}`
export const sortDocsByBuyOrSellTypology = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  const typologyOrder: TypologyOrder[] = [
    "buy-invoice",
    "buy-credit_note",
    "sell-invoice",
    "sell-credit_note",
  ]

  return [
    ...documents.sort((a, b) => {
      const aTypology: TypologyOrder = `${a.buy_or_sell}-${a.document_type}`
      const bTypology: TypologyOrder = `${b.buy_or_sell}-${b.document_type}`
      return asc
        ? typologyOrder.findIndex((t) => t === aTypology) -
            typologyOrder.findIndex((t) => t === bTypology)
        : typologyOrder.findIndex((t) => t === bTypology) -
            typologyOrder.findIndex((t) => t === aTypology)
    }),
  ]
}

export const sortDocsByReference = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? (a?.fd_document_reference || "").localeCompare(
            b.fd_document_reference || ""
          )
        : (b?.fd_document_reference || "").localeCompare(
            a.fd_document_reference || ""
          )
    ),
  ]
}

export const sortDocsByMerchantName = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a.merchant_name.localeCompare(b.merchant_name)
        : b.merchant_name.localeCompare(a.merchant_name)
    ),
  ]
}

export const sortDocsByIsNewMerchant = (
  documents: FullDocumentForInputMaskWithNewMerchant[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a.isNewMerchant && !b.isNewMerchant
          ? 1
          : -1
        : a.isNewMerchant && !b.isNewMerchant
        ? -1
        : 1
    ),
  ]
}

export const sortDeactivatedDocByName = (
  documents: DeactivatedDocumentInWritingForInputMaskWithLabels[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) => {
      const aName = a.user_batch_name || a.original_batch_name
      const bName = b.user_batch_name || b.original_batch_name
      return asc ? aName.localeCompare(bName) : bName.localeCompare(aName)
    }),
  ]
}

export const sortDeactivatedDocByType = (
  documents: DeactivatedDocumentInWritingForInputMaskWithLabels[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a.documentTypeTranslated.localeCompare(b.documentTypeTranslated)
        : b.documentTypeTranslated.localeCompare(a.documentTypeTranslated)
    ),
  ]
}
export const sortDeactivatedDocByQualification = (
  documents: DeactivatedDocumentInWritingForInputMaskWithLabels[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a.labelTranslated.localeCompare(b.labelTranslated)
        : b.labelTranslated.localeCompare(a.labelTranslated)
    ),
  ]
}

export const sortDeactivatedDocByDate = (
  documents: DeactivatedDocumentInWritingForInputMaskWithLabels[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? +new Date(b.fd_created_at) - +new Date(a.fd_created_at)
        : +new Date(a.fd_created_at) - +new Date(b.fd_created_at)
    ),
  ]
}
