import { InvoicePayload } from "../store/ducks/invoicing.duck"
import { Document } from "../store/ducks/merchants.ducks"

export interface ProductsWithPrice {
  quantity: number
  price: number
  vatRate: string
  productDiscount: number
}

export interface InvoiceRecap {
  sellerName: string
  sellerAdress: string
  sellerSiren: string
  buyerName: string
  buyerAdress: string
  buyerSiren: string
  invoiceDate: string
  invoiceType: "invoicing" | "quotation" | "creditNote"
  isEditingQuotation: boolean
}

export type InvoiceDocumentType = "all" | "invoice" | "quotation" | "creditNote"

export const sortInvoicesAlphabetically = (
  invoices: Document[],
  asc: boolean
) => {
  return invoices.sort((a: Document, b: Document) =>
    asc
      ? a.user_file_name.localeCompare(b.user_file_name)
      : b.user_file_name.localeCompare(a.user_file_name)
  )
}

export const sortInvoicesExternalReference = (
  invoices: Document[],
  asc: boolean
) => {
  return invoices.sort((a, b) => {
    const documentA = a.document_reference || "0"
    const documentB = b.document_reference || "0"

    return asc
      ? documentA.localeCompare(documentB)
      : documentB.localeCompare(documentA)
  })
}

export const sortInvoicesByDeposit = (invoices: Document[], asc: boolean) => {
  return invoices.sort((a: Document, b: Document) =>
    asc
      ? a.original_file_name.localeCompare(b.original_file_name)
      : b.original_file_name.localeCompare(a.original_file_name)
  )
}

export const sortInvoicesByAmount = (invoices: Document[], asc: boolean) => {
  return invoices.sort((a: Document, b: Document) =>
    asc
      ? Number(a.amount) - Number(b.amount)
      : Number(b.amount) - Number(a.amount)
  )
}

export const sortInvoicesByDate = (invoices: Document[], asc: boolean) => {
  return invoices.sort((a: Document, b: Document) => {
    const aDate = a.document_date.split("/").reverse().join("")
    const bDate = b.document_date.split("/").reverse().join("")

    return asc
      ? aDate > bDate
        ? 1
        : aDate < bDate
        ? -1
        : 0
      : bDate > aDate
      ? 1
      : bDate < aDate
      ? -1
      : 0
  })
}

export const filterInvoices = (invoices: Document[], search: string) => {
  if (!search) {
    return invoices
  }

  return invoices.filter(
    (invoice: Document) =>
      invoice.document_reference
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      invoice.user_file_name?.toLowerCase().includes(search.toLowerCase()) ||
      String(invoice.archive_id).includes(search.toLowerCase()) ||
      invoice.amount.includes(search.toLowerCase()) ||
      invoice.original_file_name.toLowerCase().includes(search.toLowerCase())
  )
}

export const sortInvoicesBySearch = (
  invoices: InvoicePayload[],
  search: string
) => {
  return invoices.filter(
    (invoice) =>
      invoice.numbering?.toLowerCase().includes(search.toLowerCase()) ||
      invoice.client?.toLowerCase().includes(search.toLowerCase()) ||
      invoice.issueBy?.toLowerCase().includes(search.toLowerCase()) ||
      invoice.amount?.toLowerCase().includes(search.toLowerCase()) ||
      invoice.date?.toLowerCase().includes(search.toLowerCase())
  )
}
