import * as Ct from "ldlj"
import {
  AccountGoodsTransfer,
  AccountInstructions,
} from "../../store/ducks/companySettings.ducks"
import { useIntl } from "react-intl"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { useRNBSelector } from "../../store/rootReducer"
import { DateTime } from "luxon"
/* eslint-disable camelcase */

interface GoodsTransferHistoryProps extends Ct.ModalComponentsProps {
  account: AccountGoodsTransfer
}

export const GoodsTransferHistoryModal = ({
  isDisplayed,
  onClose,
  account,
}: GoodsTransferHistoryProps) => {
  const intl = useIntl()

  const {
    enum_translations,
    possible_goods_transfer_accounts,
    possible_goods_transfer_commission_accounts,
  } = useRNBSelector((state) => state.companySettings.goods_transfer)

  const findAccounts = (instruction: AccountInstructions) => {
    for (let i = 0; i < Object.keys(instruction.metadata).length; i++) {
      const metadataKey = Object.keys(instruction.metadata)[i]
      const metadataValue = Object.values(instruction.metadata)[i]

      if (metadataKey === "previous_account") {
        const accounts = account.type.includes("commission")
          ? possible_goods_transfer_commission_accounts
          : possible_goods_transfer_accounts
        const previousAccount = accounts.find(
          (a) => a.id === Number(metadataValue)
        )

        if (instruction.instruction_type === "create_account") {
          return ""
        } else {
          return ` : ${intl.formatMessage(
            {
              id: "office-company-settings.autoliquidated-history.previously",
            },
            {
              value: previousAccount
                ? `${previousAccount.number}`
                : "non défini",
            }
          )}`
        }
      }

      return ""
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(30vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Title
          text={intl.formatMessage({
            id: "accounting-plan.goods-transfer.modal-history.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            {account.type && enum_translations && (
              <Ct.Text
                text={enum_translations[account.type]}
                textStyle={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              />
            )}
          </ChangeHeader>
        </ChangeCardContent>

        {enum_translations &&
        account.instructions &&
        account.instructions?.length > 0 ? (
          <ChangesList>
            {account.instructions.map((instruction) => (
              <ChangeItem key={instruction.created_at}>
                <Ct.Text
                  text={intl.formatMessage(
                    {
                      id: `accounting-plan.goods-transfer.modal-history.${
                        instruction.instruction_type.split("_")[0]
                      }`,
                    },
                    {
                      value: `${instruction.account_number}`,
                    }
                  )}
                />
                <> par </>
                <Mailto href={`mailto:${instruction.email}`}>
                  {instruction.email}
                </Mailto>
                <Ct.Text
                  text={
                    " (" +
                    DateTime.fromJSDate(
                      new Date(instruction.created_at)
                    ).toFormat("dd/MM/yyyy HH:mm:ss") +
                    ")"
                  }
                />
                <Ct.Text
                  text={` ${findAccounts(instruction)}`}
                  textStyle={{
                    fontWeight: 700,
                  }}
                />
              </ChangeItem>
            ))}
          </ChangesList>
        ) : (
          <>
            <Ct.Spacer height={2} />
            <TextNoChanges
              text={intl.formatMessage({
                id: `accounting-plan.goods-transfer.modal-history.no-changes`,
              })}
            />
            <Ct.Spacer height={2} />
          </>
        )}
      </Ct.Card>
    </Ct.Modal>
  )
}
