import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import AccrualAccounting from "../../../assets/20_euros_account_type.svg"
import CashAccounting from "../../../assets/5_euros_account_type.svg"
import { ReactComponent as Ellipse } from "../../../assets/ellipse.svg"
import { colors, gradients } from "../../../styles/design.config"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useRNBSelector } from "../../../store/rootReducer"

export const AccountingType = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  const fiduciaryPricingType = useRNBSelector(
    (state) => state.fiduciary.active_pricing?.pricing_type
  )

  useEffect(() => {
    if (
      fiduciaryInformations.status === "SUCCESS" &&
      fiduciaryPricingType === "per_document"
    )
      navigate("/unauthorized")
  }, [fiduciaryPricingType, fiduciaryInformations.status])

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
        },
      })
    }
  }, [userInformations, fiduciaryInformations, userInformations.typology])

  return (
    <Wrapper>
      <TitleWrapper>
        <Ct.Spacer height={2} />
        <Ct.Title
          text={intl.formatMessage({ id: "company.accounting_type.title" })}
        />
      </TitleWrapper>

      <Ct.Spacer height={4} />

      <CardsContainer>
        <CardWrapper
          onClick={() =>
            navigate(`/office/fiduciary/register_company/accrual_accounting`)
          }
        >
          <StyledCard>
            <Ct.Spacer height={8} />
            <StyledImage src={AccrualAccounting} />
            <Ct.Spacer height={6} />
            <Ct.Spacer height={4} />
            <StyledText
              text={intl.formatMessage({
                id: "company.accounting_type.accrual_accounting",
              })}
              textStyle={{
                fontSize: 3,
                fontFamily: "Poppins",
                fontWeight: 700,
              }}
            />
            <Ct.Spacer height={2} />
            <StyledText
              text={intl.formatMessage({
                id: "company.accounting_type.accrual_accounting.subtitle",
              })}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            />
            <Ct.Spacer height={5} />
            <StyledEllipse />
          </StyledCard>
        </CardWrapper>

        <CardWrapper
          onClick={() =>
            navigate(`/office/fiduciary/register_company/cash_accounting`)
          }
        >
          <StyledCard
            width="58rem"
            height="80rem"
            justifyContent="flex-start"
            padding={"2rem"}
          >
            <NewLabelWrapper>
              <NewLabel>
                <Ct.Text
                  text={"Nouveau"}
                  textStyle={{
                    color: "radicalRed",
                    textTransform: "uppercase",
                    fontSize: 2.25,
                    fontFamily: "Poppins",
                    fontWeight: 700,
                  }}
                />
              </NewLabel>
            </NewLabelWrapper>
            <Ct.Spacer height={2} />

            <StyledImage src={CashAccounting} />

            <Ct.Spacer height={6} />
            <Ct.Spacer height={4} />
            <StyledText
              text={intl.formatMessage({
                id: "company.accounting_type.cash_accounting",
              })}
              textStyle={{
                fontSize: 3,
                fontFamily: "Poppins",
                fontWeight: 700,
              }}
            />
            <Ct.Spacer height={2} />
            <StyledText
              text={intl.formatMessage({
                id: "company.accounting_type.cash_accounting.subtitle",
              })}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            />
            <Ct.Spacer height={5} />
            <StyledEllipse />
          </StyledCard>
        </CardWrapper>
      </CardsContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 130rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;
`

const TitleWrapper = styled.div`
  width: 100%;
`
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledImage = styled.img`
  width: 30rem;
  height: 23rem;
`

const StyledEllipse = styled(Ellipse)`
  width: 8rem;
  height: 8rem;
`

const StyledText = styled(Ct.Text)``

const StyledCard = styled(Ct.Card)`
  width: 58rem;
  height: 80rem;

  :hover {
    background: ${gradients.blue};
    cursor: pointer;
    & ${StyledEllipse} {
      cursor: pointer;
      & circle {
        fill: ${colors.white};
      }
    }

    & ${StyledText} {
      color: ${colors.white};
      cursor: pointer;
    }
  }

  display: flex;
  justify-content: space-around;
  padding: 3rem;
`

const NewLabel = styled.div`
  width: 13.25rem;
  height: 4rem;
  display: flex;
  align-self: flex-end;
  background-color: #fdd2e2;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
`

const NewLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const CardWrapper = styled.div``
