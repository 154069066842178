import axios, { AxiosError } from "axios"
import { AnyAction } from "redux"
import * as R from "ramda"

import { indexedObjectFromArray, Indexed } from "../../utils/asyncTools"
import { Account } from "./accounts.ducks"
import { buyOrSell } from "../../utils/company"
import { isAxiosError } from "../../utils/asyncTools"
import { Dispatch, RNBThunkAction } from "../store.config"

import {
  createMerchantCode,
  isMerchantCode,
  MerchantCodesActionsType,
  NewMerchantCode,
  updateMerchantName,
} from "./merchantCodes.ducks"
import { RootState } from "../rootReducer"

const enum MerchantsActionsEnum {
  GET_FISCAL_YEAR_MERCHANTS_ATTEMPT = "MERCHANTS/GetFiscalYearMerchantsAttempt",
  GET_FISCAL_YEAR_MERCHANTS_SUCCESS = "MERCHANTS/GetFiscalYearMerchantsSuccess",
  GET_FISCAL_YEAR_MERCHANTS_FAILURE = "MERCHANTS/GetFiscalYearMerchantsFailure",

  LOAD_DOCUMENTS_ATTEMPT = "MERCHANTS/loadDocumentsAttempt",
  LOAD_DOCUMENTS_SUCCESS = "MERCHANTS/loadDocumentsSuccess",
  LOAD_DOCUMENTS_FAILURE = "MERCHANTS/loadDocumentsFailure",

  UPDATE_ONE_MERCHANT_ATTEMPT = "MERCHANTS/UpdateOneMerchantAttempt",
  UPDATE_ONE_MERCHANT_SUCCESS = "MERCHANTS/UpdateOneMerchantSuccess",
  UPDATE_ONE_MERCHANT_FAILURE = "MERCHANTS/UpdateOneMerchantFailure",

  MODIFY_MERCHANT_RESET = "MERCHANTS/ModifyMerchantReset",
  MODIFY_MERCHANT_ATTEMPT = "MERCHANTS/ModifyMerchantAttempt",
  MODIFY_MERCHANT_SUCCESS = "MERCHANTS/ModifyMerchantSuccess",

  BAN_LOCKED_ACCOUNT_FAILURE = "MERCHANTS/BanLockedAccountFailure",
  LOCK_BANNED_ACCOUNT_FAILURE = "MERCHANTS/ModFailure",
  DEACTIVATED_ACCOUNT_FAILURE = "MERCHANTS/AccountModFailure",

  EDIT_MERCHANT_NAME_FAILURE = "MERCHANTS/EditMerchantNameFailure",
  MODIFY_MERCHANT_FAILURE = "MERCHANTS/ModifyMerchantFailure",

  GET_OUTPUT_DISPLAY_ATTEMPT = "MERCHANTS/GetOutputDisplayAttempt",
  GET_OUTPUT_DISPLAY_SUCCESS = "MERCHANTS/GetOutputDisplaySuccess",
  GET_OUTPUT_DISPLAY_FAILURE = "MERCHANTS/GetOutputDisplayFailure",

  SET_RENAME_CODE_STATUS = "MERCHANTS/SetRenameCodeStatus",
  RESET_MERCHANT_LIST = "MERCHANTS/resetMerchantList",

  GET_MERCHANT_DATA_ATTEMPT = "MERCHANTS/getMerchantDataAttempt",
  GET_MERCHANT_DATA_SUCCESS = "MERCHANTS/getMerchantDataSuccess",
  GET_MERCHANT_DATA_FAILURE = "MERCHANTS/getMerchantDataFailure",

  GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_ATTEMPT = "MERCHANTS/getFullDocumentsOfFiscalYearAttempt",
  GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_SUCCESS = "MERCHANTS/getFullDocumentsOfFiscalYearSuccess",
  GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_FAILURE = "MERCHANTS/getFullDocumentsOfFiscalYearFailure",

  GET_URL_FULL_DOCUMENT_ATTEMPT = "MERCHANTS/getUrlFullDocumentAttempt",
  GET_URL_FULL_DOCUMENT_SUCCESS = "MERCHANTS/getUrlFullDocumentSuccess",
  GET_URL_FULL_DOCUMENT_FAILURE = "MERCHANTS/getUrlFullDocumentFailure",

  UPDATE_FUEL_VAT_ATTEMPT = "MERCHANTS/updateFuelVatAttempt",
  UPDATE_FUEL_VAT_SUCCESS = "MERCHANTS/updateFuelVatSuccess",
  UPDATE_FUEL_VAT_FAILURE = "MERCHANTS/updateFuelVatFailure",

  UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_ATTEMPT = "MERCHANTS/updateAutoliquidatedDefaultVatRateAttempt",
  UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_SUCCESS = "MERCHANTS/updateAutoliquidatedDefaultVatRateSuccess",
  UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_FAILURE = "MERCHANTS/updateAutoliquidatedDefaultVatRateFailure",
}

export type renameCodeStatus = "initial" | "renaming" | "renamed"
export type saveStatus = "initial" | "saving" | "success" | "failure"

export const GetFiscalYearMerchantsAttemptAction = () =>
  ({ type: MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_ATTEMPT } as const)
export const GetFiscalYearMerchantsSuccessAction = (
  merchants: { [index: number]: Merchant },
  merchantCodes: MerchantCode[]
) =>
  ({
    type: MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_SUCCESS,
    merchants,
    merchantCodes,
  } as const)
export const GetFiscalYearMerchantsFailureAction = (error: AxiosError) =>
  ({
    type: MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_FAILURE,
    error,
  } as const)

export const LoadDocumentsAttemptAction = (payload: { id: number }) =>
  ({ type: MerchantsActionsEnum.LOAD_DOCUMENTS_ATTEMPT, payload } as const)
export const LoadDocumentsSuccessAction = (payload: {
  id: number
  documents: Document[]
}) => ({ type: MerchantsActionsEnum.LOAD_DOCUMENTS_SUCCESS, payload } as const)
export const LoadDocumentsFailureAction = (error: AxiosError) =>
  ({ type: MerchantsActionsEnum.LOAD_DOCUMENTS_FAILURE, error } as const)

export const UpdateOneMerchantAttemptAction = () =>
  ({ type: MerchantsActionsEnum.UPDATE_ONE_MERCHANT_ATTEMPT } as const)

export const ModifyMerchantResetAction = () =>
  ({ type: MerchantsActionsEnum.MODIFY_MERCHANT_RESET } as const)

export const ModifyMerchantAttemptAction = () =>
  ({ type: MerchantsActionsEnum.MODIFY_MERCHANT_ATTEMPT } as const)

export const ModifyMerchantSuccessAction = (payload: buyOrSell) =>
  ({
    type: MerchantsActionsEnum.MODIFY_MERCHANT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office.modify-merchant.${payload}.toast.success.title`,
      bodyKey: `office.modify-merchant.${payload}.toast.success.message`,
    },
  } as const)

export const BanLockedAccountFailure = () =>
  ({
    type: MerchantsActionsEnum.BAN_LOCKED_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office.modify-merchant.ban.error.locked.title",
      bodyKey: "office.modify-merchant.ban.error.locked.message",
    },
  } as const)

export const LockBannedAccountFailure = () =>
  ({
    type: MerchantsActionsEnum.LOCK_BANNED_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office.modify-merchant.lock.error.banned.title",
      bodyKey: "office.modify-merchant.lock.error.banned.message",
    },
  } as const)

export const DeactivatedAccountFailure = () =>
  ({
    type: MerchantsActionsEnum.DEACTIVATED_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office.modify-merchant.deactivated.account.error.title",
      bodyKey: "office.modify-merchant.deactivated.account.error.message",
    },
  } as const)

export const EditMerchantNameFailure = (
  errorType: string,
  errorMessage?: string
) =>
  ({
    type: MerchantsActionsEnum.EDIT_MERCHANT_NAME_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office.modify-merchant.name-with-extra-spaces.error.title",
      bodyKey: `office.modify-merchant.${displayMerchantNameError(
        errorType
      )}.error.message`,
    },
    errorMessage,
  } as const)

const displayMerchantNameError = (errorType: string) => {
  const messageKey =
    errorType === "character"
      ? "name-with-unauthorized-characters"
      : "name-with-extra-spaces"
  return messageKey
}

export const ModifyMerchantFailureAction = (error: AxiosError | Error) => {
  return {
    type: MerchantsActionsEnum.MODIFY_MERCHANT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const
}

export const UpdateOneMerchantSuccessAction = (
  payload: MerchantFromAccountingData
) =>
  ({ type: MerchantsActionsEnum.UPDATE_ONE_MERCHANT_SUCCESS, payload } as const)

export const UpdateOneMerchantFailureAction = (error: AxiosError | Error) => {
  return {
    type: MerchantsActionsEnum.UPDATE_ONE_MERCHANT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const
}
export const GetOutputDisplayAttempt = () =>
  ({ type: MerchantsActionsEnum.GET_OUTPUT_DISPLAY_ATTEMPT } as const)
export const GetOutputDisplaySuccess = (payload: {
  id: number
  outputDisplay: MerchantOutputDisplay
}) =>
  ({ type: MerchantsActionsEnum.GET_OUTPUT_DISPLAY_SUCCESS, payload } as const)
export const GetOutputDisplayFailure = () =>
  ({ type: MerchantsActionsEnum.GET_OUTPUT_DISPLAY_FAILURE } as const)
export const ResetMerchantListAction = () =>
  ({ type: MerchantsActionsEnum.RESET_MERCHANT_LIST } as const)

export const getMerchantDataAttempt = () =>
  ({ type: MerchantsActionsEnum.GET_MERCHANT_DATA_ATTEMPT } as const)
export const getMerchantDataSuccess = (payload: MerchantFromAccountingData) =>
  ({ type: MerchantsActionsEnum.GET_MERCHANT_DATA_SUCCESS, payload } as const)
export const getMerchantDataFailure = () =>
  ({
    type: MerchantsActionsEnum.GET_MERCHANT_DATA_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `merchants.data.toaster.error.title`,
      bodyKey: `merchants.data.toaster.error.body`,
    },
  } as const)

export const SetRenameCodeAction = (payload: {
  id: number
  renameCodeStatus: renameCodeStatus
}) =>
  ({
    type: MerchantsActionsEnum.SET_RENAME_CODE_STATUS,
    payload,
  } as const)

const displayErrorMerchantCodes = (error: AxiosError | Error) => {
  let id = "error"
  if (
    isAxiosError(error) &&
    error.response !== undefined &&
    error.response.data
  ) {
    if (JSON.stringify(error.response.data).includes("empty")) {
      id = "office.modify-merchant.merchant-code.rename.error.empty"
    } else if (JSON.stringify(error.response.data).includes("too long")) {
      id = "office.modify-merchant.merchant-code.rename.error.too-long"
    } else if (JSON.stringify(error.response.data).includes("forbidden char")) {
      id =
        "office.modify-merchant.merchant-code.rename.error.forbidden-characters"
    } else if (JSON.stringify(error.response.data).includes("already exists")) {
      id = "office.modify-merchant.merchant-name.error.already-taken"
    } else if (
      JSON.stringify(error.response.data).includes("est déjà utilisé")
    ) {
      id = "office.modify-merchant.merchant-code.error.already-taken"
    } else if (JSON.stringify(error.response.data).includes("Centralize")) {
      id = "office.modify-merchant.merchant-code.error.decentralize-account"
    }
  }

  return id
}

export const getFullDocumentsOfFiscalYearAttempt = () =>
  ({
    type: MerchantsActionsEnum.GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_ATTEMPT,
  } as const)
export const getFullDocumentsOfFiscalYearSuccess = (documents: Document[]) =>
  ({
    type: MerchantsActionsEnum.GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_SUCCESS,
    documents,
  } as const)
export const getFullDocumentsOfFiscalYearFailure = () =>
  ({
    type: MerchantsActionsEnum.GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_FAILURE,
  } as const)

export const getUrlFullDocumentAttempt = () =>
  ({
    type: MerchantsActionsEnum.GET_URL_FULL_DOCUMENT_ATTEMPT,
  } as const)
export const getUrlFullDocumentSuccess = (
  merchantId: number,
  fullDocId: number,
  url: string
) =>
  ({
    type: MerchantsActionsEnum.GET_URL_FULL_DOCUMENT_SUCCESS,
    merchantId,
    fullDocId,
    url,
  } as const)
export const getUrlFullDocumentFailure = () =>
  ({
    type: MerchantsActionsEnum.GET_URL_FULL_DOCUMENT_FAILURE,
  } as const)

export const updateFuelVatAttempt = () =>
  ({
    type: MerchantsActionsEnum.UPDATE_FUEL_VAT_ATTEMPT,
  } as const)
export const updateFuelVatSuccess = (merchantId: number, isFuelVat: boolean) =>
  ({
    type: MerchantsActionsEnum.UPDATE_FUEL_VAT_SUCCESS,
    merchantId,
    isFuelVat,
  } as const)
export const updateFuelVatFailure = () =>
  ({
    type: MerchantsActionsEnum.UPDATE_FUEL_VAT_FAILURE,
  } as const)

export const updateAutoliquidatedDefaultVatRateAttempt = () =>
  ({
    type: MerchantsActionsEnum.UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_ATTEMPT,
  } as const)
export const updateAutoliquidatedDefaultVatRateSuccess = (
  merchantId: number,
  autoliquidatedVatRateId: number | null
) =>
  ({
    type: MerchantsActionsEnum.UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office.modify-merchant.autoliquidated_vat_rate.toast.success.title`,
      bodyKey: `office.modify-merchant.autoliquidated_vat_rate.toast.success.message`,
    },
    merchantId,
    autoliquidatedVatRateId,
  } as const)
export const updateAutoliquidatedDefaultVatRateFailure = () =>
  ({
    type: MerchantsActionsEnum.UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office.modify-merchant.autoliquidated_vat_rate.toast.failure.title`,
      bodyKey: `office.modify-merchant.autoliquidated_vat_rate.toast.failure.message`,
    },
  } as const)

type MerchantsActionsType = ReturnType<
  | typeof GetFiscalYearMerchantsAttemptAction
  | typeof GetFiscalYearMerchantsSuccessAction
  | typeof GetFiscalYearMerchantsFailureAction
  | typeof UpdateOneMerchantAttemptAction
  | typeof UpdateOneMerchantSuccessAction
  | typeof UpdateOneMerchantFailureAction
  | typeof ModifyMerchantResetAction
  | typeof ModifyMerchantAttemptAction
  | typeof ModifyMerchantSuccessAction
  | typeof ModifyMerchantFailureAction
  | typeof BanLockedAccountFailure
  | typeof LockBannedAccountFailure
  | typeof DeactivatedAccountFailure
  | typeof EditMerchantNameFailure
  | typeof GetOutputDisplayAttempt
  | typeof GetOutputDisplaySuccess
  | typeof GetOutputDisplayFailure
  | typeof SetRenameCodeAction
  | typeof ResetMerchantListAction
  | typeof LoadDocumentsAttemptAction
  | typeof LoadDocumentsSuccessAction
  | typeof LoadDocumentsFailureAction
  | typeof getMerchantDataAttempt
  | typeof getMerchantDataSuccess
  | typeof getMerchantDataFailure
  | typeof getFullDocumentsOfFiscalYearAttempt
  | typeof getFullDocumentsOfFiscalYearSuccess
  | typeof getFullDocumentsOfFiscalYearFailure
  | typeof getUrlFullDocumentAttempt
  | typeof getUrlFullDocumentSuccess
  | typeof getUrlFullDocumentFailure
  | typeof updateFuelVatAttempt
  | typeof updateFuelVatSuccess
  | typeof updateFuelVatFailure
  | typeof updateAutoliquidatedDefaultVatRateAttempt
  | typeof updateAutoliquidatedDefaultVatRateSuccess
  | typeof updateAutoliquidatedDefaultVatRateFailure
>

export const merchantsInitialState: MerchantsState = {
  merchants: {},
  merchantCodes: [],
  loading_status: "idle",
  saveStatus: "initial",
  errorMessage: "",
}

export interface MerchantsState {
  merchants: { [index: number]: Merchant }
  merchantCodes: MerchantCode[]
  loading_status: "loading" | "success" | "failure" | "idle"
  saveStatus: "initial" | "saving" | "success" | "failure"
  errorMessage: string | undefined
}

export function merchantsReducer(
  state = merchantsInitialState,
  action: MerchantsActionsType
): MerchantsState {
  switch (action.type) {
    case MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_FAILURE:
      return { ...state, loading_status: "failure" }
    case MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_ATTEMPT:
      return { ...state }
    case MerchantsActionsEnum.GET_FISCAL_YEAR_MERCHANTS_SUCCESS: {
      return {
        ...state,
        merchants: { ...action.merchants },
        merchantCodes: [...action.merchantCodes],
        loading_status: "success",
      }
    }
    case MerchantsActionsEnum.LOAD_DOCUMENTS_ATTEMPT: {
      const existing_merchant = state.merchants[action.payload.id]
      const new_merchant = {
        ...existing_merchant,
        documents: [],
      }
      return {
        ...state,
        merchants: {
          ...state.merchants,
          [action.payload.id]: new_merchant,
        },
      }
    }
    case MerchantsActionsEnum.LOAD_DOCUMENTS_SUCCESS: {
      const existing_merchant = state.merchants[action.payload.id]
      const new_merchant = {
        ...existing_merchant,
        documents: action.payload.documents,
      }
      return {
        ...state,
        merchants: {
          ...state.merchants,
          [action.payload.id]: new_merchant,
        },
      }
    }
    case MerchantsActionsEnum.LOAD_DOCUMENTS_FAILURE:
      return { ...state, loading_status: "failure" }
    case MerchantsActionsEnum.UPDATE_ONE_MERCHANT_SUCCESS: {
      const existing_merchant = state.merchants[action.payload.id]
      const new_merchant = existing_merchant
        ? {
            ...existing_merchant,
            ...action.payload,
            renameCodeStatus: existing_merchant.renameCodeStatus || "initial",
          }
        : {
            ...action.payload,
            documents: [],
            renameCodeStatus: "initial" as renameCodeStatus,
          }
      const newMerchantLens = R.lensPath(["merchants", action.payload.id])
      return R.set(newMerchantLens, new_merchant, state)
    }
    case MerchantsActionsEnum.GET_OUTPUT_DISPLAY_ATTEMPT:
      return { ...state }
    case MerchantsActionsEnum.GET_OUTPUT_DISPLAY_SUCCESS: {
      const outputDisplay = R.lensPath([
        "merchants",
        action.payload.id,
        "outputDisplay",
      ])
      return R.set(outputDisplay, action.payload.outputDisplay, state)
    }
    case MerchantsActionsEnum.GET_OUTPUT_DISPLAY_FAILURE:
      return { ...state }
    case MerchantsActionsEnum.SET_RENAME_CODE_STATUS: {
      const renameCodeStatusLens = R.lensPath([
        "merchants",
        action.payload.id,
        "renameCodeStatus",
      ])
      return R.set(renameCodeStatusLens, action.payload.renameCodeStatus, state)
    }
    case MerchantsActionsEnum.RESET_MERCHANT_LIST:
      return merchantsInitialState
    case MerchantsActionsEnum.MODIFY_MERCHANT_RESET:
      return { ...state, saveStatus: "initial" }
    case MerchantsActionsEnum.MODIFY_MERCHANT_ATTEMPT:
      return { ...state, saveStatus: "saving" }
    case MerchantsActionsEnum.MODIFY_MERCHANT_SUCCESS:
      return { ...state, saveStatus: "success" }
    case MerchantsActionsEnum.GET_MERCHANT_DATA_SUCCESS: {
      let existing_merchant = state.merchants[action.payload.id]
      let new_merchant = existing_merchant
        ? { ...existing_merchant, ...action.payload }
        : {
            ...action.payload,
            renameCodeStatus: "initial" as renameCodeStatus,
            documents: [],
          }
      return {
        ...state,
        merchants: { ...state.merchants, [action.payload.id]: new_merchant },
        loading_status: "success",
      }
    }
    case MerchantsActionsEnum.GET_MERCHANT_DATA_FAILURE:
      return { ...state, loading_status: "failure" }

    case MerchantsActionsEnum.GET_FULL_DOCUMENTS_OF_FISCAL_YEAR_SUCCESS: {
      let merchantDoc: { [key: number]: Document[] } = {}
      for (const doc of action.documents) {
        if (doc.merchant_id) {
          if (merchantDoc[doc.merchant_id]) {
            merchantDoc[doc.merchant_id] = [
              ...merchantDoc[doc.merchant_id],
              { ...doc },
            ]
          } else {
            merchantDoc[doc.merchant_id] = [{ ...doc }]
          }
        }
      }

      const newMerchants = Object.values(state.merchants)
        .map((merchant) => merchant.id)
        .reduce<{ [key: number]: Merchant }>(
          (acc, merchant) => ({
            ...acc,
            [merchant]: {
              ...state.merchants[merchant],
              documents: [...merchantDoc[merchant]],
            },
          }),
          {}
        )

      return {
        ...state,
        merchants: { ...newMerchants },
      }
    }

    case MerchantsActionsEnum.GET_URL_FULL_DOCUMENT_SUCCESS: {
      let indexFullDoc = state.merchants[action.merchantId].documents.findIndex(
        (d) => d.id === action.fullDocId
      )

      let fullDoc = {
        ...state.merchants[action.merchantId].documents[indexFullDoc],
        url: action.url,
      }

      const fullDocsLens = R.lensPath([
        "merchants",
        action.merchantId,
        "documents",
        indexFullDoc,
      ])

      return R.set(fullDocsLens, fullDoc, { ...state })
    }

    case MerchantsActionsEnum.EDIT_MERCHANT_NAME_FAILURE: {
      return { ...state, errorMessage: action.errorMessage }
    }

    case MerchantsActionsEnum.UPDATE_FUEL_VAT_SUCCESS: {
      return {
        ...state,
        merchants: {
          ...state.merchants,
          [action.merchantId]: {
            ...state.merchants[action.merchantId],
            is_fuel_vat: action.isFuelVat,
          },
        },
      }
    }

    case MerchantsActionsEnum.UPDATE_AUTOLIQUIDATED_DEFAULT_VAT_RATE_SUCCESS: {
      return {
        ...state,
        merchants: {
          ...state.merchants,
          [action.merchantId]: {
            ...state.merchants[action.merchantId],
            autoliquidated_default_vat_rate_id: action.autoliquidatedVatRateId,
          },
        },
      }
    }

    default:
      return { ...state }
  }
}

export interface Document {
  amount: string
  archive_id: number
  document_date: string
  document_reference: string
  email: string
  file_name: string
  id: number
  original_file_name: string
  user_file_name: string
  merchant_id?: number
  url?: string
}

interface MerchantFromDocument {
  documents: Array<Document>
  id: number
  name: string
}

interface MerchantFromAccountingData {
  id: number
  name: string
  buy_default_account: Account | null
  sell_default_account: Account | null
  merchant_code: MerchantCode
  banned_accounts: Account[]
  user_merchant_instructions?: Instructions[]
  user_merchant_code_instructions?: Instructions[]
  user_lem_instructions?: LemInstructions[]
  buy_vat_default_account_id: number | null
  sell_vat_default_account_id: number | null
  buy_lock_vat_instructions: lockVATInstruction
  sell_lock_vat_instructions: lockVATInstruction
  autoliquidated_default_vat_rate_id: number | null
  is_fuel_vat: boolean
}

export interface MerchantCode {
  id: number
  code: string
  centralize: boolean
}

export interface Instructions {
  created_at: string
  instruction_type: InstructionType
  user: string
  metadata: {
    [index: string]: string
  }
}

type LemInstructionType = "change_merchant"

export interface LemInstructions {
  created_at: string
  identification_number: string
  instruction_type: LemInstructionType
  user: string
  metadata: {
    previous_merchant_id: number
    previous_merchant_name: string
    previous_legal_entity_name: string
  }
}

type UserMerchantCodeInstruction =
  | "code_modification"
  | "code_creation"
  | "centralize_status"
  | "default_buy_code"
  | "default_sell_code"

type UserMerchantInstruction =
  | "name_modification"
  | "lock_account"
  | "unlock_account"
  | "ban_account"
  | "unban_account"
  | "merchant_code_change"
  | "lock_vat_account"
  | "unlock_vat_account"
  | "lock_without_vat"
  | "unlock_without_vat"
  | "lock_eu_vat"
  | "lock_construction_vat"
  | "validate_directly"
  | "mark_as_paid_directly"

export interface Merchant extends MerchantFromDocument {
  buy_default_account: Account | null
  sell_default_account: Account | null
  merchant_code: MerchantCode
  banned_accounts: Account[]
  outputDisplay?: MerchantOutputDisplay
  user_merchant_instructions?: Instructions[]
  user_merchant_code_instructions?: Instructions[]
  user_legal_entity_instructions?: LemInstructions[]
  renameCodeStatus: renameCodeStatus
  full_document_count?: number
  buy_vat_default_account_id: number | null
  sell_vat_default_account_id: number | null
  buy_lock_vat_instructions: lockVATInstruction
  sell_lock_vat_instructions: lockVATInstruction
  autoliquidated_default_vat_rate_id: number | null
  is_fuel_vat: boolean
}

type lockVATInstruction =
  | "lock_with_vat"
  | "lock_without_vat"
  | "unlock"
  | "lock_eu_vat"
  | "lock_eu_goods_vat"
  | "lock_world_vat"
  | "lock_world_goods_vat"
  | "lock_construction_vat"

interface GetFiscalYearMerchantsPayload {
  fiscalYearId: number
  buyOrSell: buyOrSell
}

interface GetFiscalYearMerchantDocumentsPayload {
  merchantId: number
  fiscalYearId: number
  buyOrSell: buyOrSell
}

interface MerchantOutputDisplay {
  account_line: {
    auxiliaryDetails: string
    auxiliaryNumber: string
    details: string
    number: string
  }
  code_instructions: []
}

interface IncomingMerchantOutputDisplay {
  account_line: {
    auxiliary_details: string
    auxiliary_number: string
    details: string
    number: string
  }
  code_instructions: []
}

export const loadDocuments =
  (merchantId: number) => (fiscalYearId: number) => (BOS: "buy" | "sell") => {
    return axios
      .get<Document[]>(
        `/full_documents/get_full_documents_per_fiscal_year_and_merchant`,
        {
          params: {
            merchant_id: merchantId,
            fiscal_year_id: fiscalYearId,
            buy_or_sell: BOS,
          },
        }
      )
      .then(({ data }) => data)
  }

export const cleanDefaultAccount = (merchant: MerchantFromAccountingData) => {
  let new_merchant = { ...merchant }
  if (
    merchant.buy_default_account &&
    Object.keys(merchant.buy_default_account).length === 0
  )
    new_merchant.buy_default_account = null
  if (
    merchant.sell_default_account &&
    Object.keys(merchant.sell_default_account).length === 0
  )
    new_merchant.sell_default_account = null
  return new_merchant
}

export const loadMerchantFromAccountingDataPerFiscalYear =
  (fiscalYearId: number) => (BOS: buyOrSell) => {
    return axios
      .get<{
        merchants: Array<MerchantFromAccountingData>
        merchant_codes: Array<MerchantCode>
      }>(`/fiscal_years/accounting_data_of_fiscal_year`, {
        params: { buy_or_sell: BOS, id: fiscalYearId },
      })
      .then((response) => {
        return {
          merchants: indexedObjectFromArray(
            response.data.merchants.map(cleanDefaultAccount)
          ),
          merchantCodes: response.data.merchant_codes,
        }
      })
  }

export const getMerchantData =
  (merchantId: number, selectedCompanyId: number) =>
  (dispatch: Dispatch<MerchantsActionsType>) => {
    dispatch(getMerchantDataAttempt())
    return axios
      .get<MerchantFromAccountingData>(`/merchants/get_merchant_data`, {
        params: { merchant_id: merchantId, company_id: selectedCompanyId },
      })
      .then((response) => dispatch(getMerchantDataSuccess(response.data)))
      .catch(() => {
        dispatch(getMerchantDataFailure())
      })
  }

export const combine =
  (d: Indexed<MerchantFromDocument>) =>
  (m: Indexed<MerchantFromAccountingData>) => {
    const keysIntersection = Object.keys(d)
      .filter((key) => Object.keys(m).includes(key))
      .map(Number)

    return indexedObjectFromArray(
      keysIntersection.map((merchantId) => ({
        ...d[merchantId],
        ...m[merchantId],
      }))
    )
  }

export const GetFiscalYearMerchantDocumentsThunk =
  ({
    merchantId,
    fiscalYearId,
    buyOrSell: BOS,
  }: GetFiscalYearMerchantDocumentsPayload) =>
  (dispatch: Dispatch<MerchantsActionsType>) => {
    dispatch(LoadDocumentsAttemptAction({ id: merchantId }))
    loadDocuments(merchantId)(fiscalYearId)(BOS)
      .then((documents) =>
        dispatch(
          LoadDocumentsSuccessAction({ id: merchantId, documents: documents })
        )
      )
      .catch((e: AxiosError) => {
        dispatch(LoadDocumentsFailureAction(e))
      })
  }

export const GetFiscalYearMerchantsThunk =
  ({ fiscalYearId, buyOrSell: BOS }: GetFiscalYearMerchantsPayload) =>
  (dispatch: Dispatch<MerchantsActionsType | RNBThunkAction>) => {
    dispatch(GetFiscalYearMerchantsAttemptAction())
    return loadMerchantFromAccountingDataPerFiscalYear(fiscalYearId)(BOS)
      .then((data) => {
        dispatch(
          GetFiscalYearMerchantsSuccessAction(
            data.merchants,
            data.merchantCodes
          )
        )
      })
      .then(() => {
        dispatch(LoadFullDocumentsPerFiscalYearThunk(fiscalYearId, BOS))
      })
      .catch((e: AxiosError) => {
        dispatch(GetFiscalYearMerchantsFailureAction(e))
      })
  }

export const autoliquidatedLockInstructions = [
  "lock_eu_vat",
  "lock_eu_goods_vat",
  "lock_world_vat",
  "lock_world_goods_vat",
  "lock_construction_vat",
] as const
export type AutoliquidatedLockInstructionsType =
  (typeof autoliquidatedLockInstructions)[number]

export type InstructionType =
  | UserMerchantCodeInstruction
  | UserMerchantInstruction

type LockAccountPayload =
  | { buy_default_account_id: number }
  | { sell_default_account_id: number }

type ChangeNamePayload = { name: string }

type UnlockAccountPayload =
  | { buy_default_account_id: null }
  | { sell_default_account_id: null }

type BanAccountPayload = { banned_accounts: Array<number> }
type MerchantCodeChangePayload = { merchant_code: MerchantCode }

type LockVATAccountPaylod =
  | { buy_vat_default_account_id: number }
  | { sell_vat_default_account_id: number }
type UnlockVATAccountPayload =
  | { buy_vat_default_account_id: null }
  | { sell_vat_default_account_id: null }
type LockWithoutVAT = UnlockVATAccountPayload
type UnlockWhithoutVAT = UnlockVATAccountPayload

export type MerchantInstructionPayload =
  | LockAccountPayload
  | ChangeNamePayload
  | UnlockAccountPayload
  | BanAccountPayload
  | MerchantCodeChangePayload
  | LockVATAccountPaylod
  | UnlockVATAccountPayload
  | LockWithoutVAT
  | UnlockWhithoutVAT

export const modifyMerchantThunk =
  (companyId: number) =>
  (buyOrSell: buyOrSell) =>
  (originalMerchant: Merchant) =>
  (newName: string) =>
  (newDefaultAccount: Account | null) =>
  (newBannedAccounts: Array<Account>) =>
  (merchantCode: MerchantCode | NewMerchantCode) =>
  (
    VATDefaultAccount:
      | number
      | "unlock_without_vat"
      | "lock_without_vat"
      | AutoliquidatedLockInstructionsType
      | null
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(ModifyMerchantAttemptAction())
    let actions = []
    const dispatchedGiveMerchantInstruction =
      give_merchant_instruction(dispatch)

    if (originalMerchant.name !== newName) {
      actions.push(
        dispatchedGiveMerchantInstruction("name_modification")(
          originalMerchant
        )({
          name: newName,
        })(buyOrSell)(companyId)
      )
    }

    const buyOrSellVatInstructions =
      originalMerchant[`${buyOrSell}_lock_vat_instructions`]

    if (
      newDefaultAccount &&
      newDefaultAccount.id !==
        originalMerchant[`${buyOrSell}_default_account`]?.id
    ) {
      const payload = {
        [`${buyOrSell}_default_account_id`]: newDefaultAccount.id,
      } as LockAccountPayload
      actions.push(
        dispatchedGiveMerchantInstruction("lock_account")(originalMerchant)(
          payload
        )(buyOrSell)(companyId)
      )
    } else if (
      !newDefaultAccount &&
      originalMerchant[`${buyOrSell}_default_account`]?.id != null
    ) {
      actions.push(
        dispatchedGiveMerchantInstruction("unlock_account")(originalMerchant)({
          [`${buyOrSell}_default_account_id`]: null,
        } as UnlockAccountPayload)(buyOrSell)(companyId)
      )
    } else if (
      VATDefaultAccount &&
      VATDefaultAccount !== "lock_without_vat" &&
      VATDefaultAccount !== "unlock_without_vat" &&
      !autoliquidatedLockInstructions.includes(
        VATDefaultAccount as AutoliquidatedLockInstructionsType
      ) &&
      VATDefaultAccount !==
        originalMerchant[`${buyOrSell}_vat_default_account_id`]
    ) {
      actions.push(
        dispatchedGiveMerchantInstruction("lock_vat_account")(originalMerchant)(
          {
            [`${buyOrSell}_vat_default_account_id`]: VATDefaultAccount,
          } as LockVATAccountPaylod
        )(buyOrSell)(companyId)
      )
    } else if (!VATDefaultAccount && buyOrSellVatInstructions !== "unlock") {
      actions.push(
        dispatchedGiveMerchantInstruction("unlock_vat_account")(
          originalMerchant
        )({
          [`${buyOrSell}_vat_default_account_id`]: null,
        } as UnlockVATAccountPayload)(buyOrSell)(companyId)
      )
    } else if (
      VATDefaultAccount === "lock_without_vat" &&
      buyOrSellVatInstructions !== "lock_without_vat"
    ) {
      actions.push(
        dispatchedGiveMerchantInstruction("lock_without_vat")(originalMerchant)(
          {
            [`${buyOrSell}_vat_default_account_id`]: null,
          } as LockWithoutVAT
        )(buyOrSell)(companyId)
      )
    } else if (
      autoliquidatedLockInstructions.includes(
        VATDefaultAccount as AutoliquidatedLockInstructionsType
      )
    ) {
      actions.push(
        dispatchedGiveMerchantInstruction(VATDefaultAccount as InstructionType)(
          originalMerchant
        )({
          [`${buyOrSell}_vat_default_account_id`]: null,
        } as LockWithoutVAT)(buyOrSell)(companyId)
      )
    } else if (
      VATDefaultAccount === "unlock_without_vat" &&
      originalMerchant[`${buyOrSell}_vat_default_account_id`] === null &&
      originalMerchant[`${buyOrSell}_lock_vat_instructions`] ===
        "lock_without_vat"
    ) {
      actions.push(
        dispatchedGiveMerchantInstruction("unlock_without_vat")(
          originalMerchant
        )({
          [`${buyOrSell}_vat_default_account_id`]: null,
        } as UnlockWhithoutVAT)(buyOrSell)(companyId)
      )
    }

    if (newBannedAccounts !== originalMerchant.banned_accounts) {
      actions.push(
        dispatchedGiveMerchantInstruction("ban_account")(originalMerchant)({
          banned_accounts: newBannedAccounts.map((a) => a.id),
        })(buyOrSell)(companyId)
      )
    }

    if (
      JSON.stringify(merchantCode.code) !==
      JSON.stringify(originalMerchant.merchant_code.code)
    ) {
      if (isMerchantCode(merchantCode) && merchantCode.id) {
        actions.push(
          dispatchedGiveMerchantInstruction("merchant_code_change")(
            originalMerchant
          )({
            merchant_code: merchantCode,
          })(buyOrSell)(companyId)
        )
      } else {
        actions.push(
          createMerchantCode(companyId)(originalMerchant.id)(merchantCode)
        )
      }
    }

    Promise.all(actions)
      .then(() => {})
      .catch((error) => {
        dispatch(ModifyMerchantFailureAction(error))
      })
  }

export const updateAutoliquidatedDefaultVatRateThunk =
  (merchantId: number, autoliquidatedVatRateId: string | null) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateAutoliquidatedDefaultVatRateAttempt())

    const vatRate =
      autoliquidatedVatRateId === null ? null : Number(autoliquidatedVatRateId)

    axios
      .put("/merchants/update_autoliquidated_default_vat_rate", {
        merchant_id: merchantId,
        autoliquidated_default_vat_rate_id: vatRate,
      })
      .then(() => {
        dispatch(updateAutoliquidatedDefaultVatRateSuccess(merchantId, vatRate))
      })
      .catch(() => {
        dispatch(updateAutoliquidatedDefaultVatRateFailure())
      })
  }

export const switchMerchantFuelThunk =
  ({ merchantId, newIsFuel }: { merchantId: number; newIsFuel: boolean }) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateFuelVatAttempt())
    axios
      .put("/merchants/update_merchant_fuel", {
        merchant_id: merchantId,
        is_fuel_vat: newIsFuel,
      })
      .then(() => {
        dispatch(updateFuelVatSuccess(merchantId, newIsFuel))
      })
      .catch(() => {
        dispatch(updateFuelVatFailure())
      })
  }

export const give_merchant_instruction =
  (
    dispatch: Dispatch<
      MerchantsActionsType | MerchantCodesActionsType | RNBThunkAction
    >
  ) =>
  (instruction: InstructionType) =>
  (originalMerchant: Merchant) =>
  (payload: MerchantInstructionPayload) =>
  (buyOrSell: buyOrSell) =>
  (companyId: number) =>
    axios
      .put("/merchants/give_merchant_instruction", {
        id: originalMerchant.id,
        merchant: { ...payload, instruction_type: instruction },
      })
      .then(() => {
        dispatch(ModifyMerchantSuccessAction(buyOrSell))
        dispatch(getMerchantData(originalMerchant.id, companyId))

        dispatch(
          getOutputDisplayThunk(companyId, originalMerchant.id, buyOrSell)
        )
        dispatch(ModifyMerchantResetAction())

        if (instruction === "name_modification") {
          dispatch(
            updateMerchantName({
              merchantId: originalMerchant.id,
              newMerchantName: (payload as ChangeNamePayload).name,
            })
          )
        }
      })
      .catch((error) => {
        if (error.request && error.response.data && error.response.data.error) {
          const message = error.response.data.error
          if (message.includes("est défini par défaut")) {
            return dispatch(BanLockedAccountFailure())
          }
          if (message.includes("est interdit")) {
            return dispatch(LockBannedAccountFailure())
          }
          if (message.includes("Deactivated account")) {
            return dispatch(DeactivatedAccountFailure())
          }
          if (message.includes("Spaces in the beginning or the end")) {
            return dispatch(EditMerchantNameFailure("space"))
          }
          if (message.includes("character-unauthorized")) {
            return dispatch(EditMerchantNameFailure("character"))
          }
        }
        throw error
      })

export const getOutputDisplayThunk =
  (companyId: number, merchantId: number, BOS: buyOrSell) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(GetOutputDisplayAttempt())
    const state = getState()
    dispatch(GetOutputDisplayAttempt())
    return axios
      .get<IncomingMerchantOutputDisplay>(
        `/fiduciaries/${state.fiduciary.id}/companies/${companyId}/merchants/${merchantId}/get_output_display`,
        { params: { buy_or_sell: BOS } }
      )
      .then((response) => {
        const output: MerchantOutputDisplay = {
          account_line: {
            auxiliaryDetails: response.data.account_line.auxiliary_details,
            auxiliaryNumber: response.data.account_line.auxiliary_number,
            details: response.data.account_line.details,
            number: response.data.account_line.number,
          },
          code_instructions: response.data.code_instructions,
        }

        dispatch(
          GetOutputDisplaySuccess({
            id: merchantId,
            outputDisplay: output,
          })
        )
      })
      .catch((error) => {})
  }

export const LoadFullDocumentsPerFiscalYearThunk =
  (fiscalYearId: number, BOS: "buy" | "sell") =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFullDocumentsOfFiscalYearAttempt())

    return axios
      .get<Document[]>(`/full_documents/get_full_documents_per_fiscal_year`, {
        params: {
          fiscal_year_id: fiscalYearId,
          buy_or_sell: BOS,
        },
      })
      .then(({ data }) => {
        dispatch(getFullDocumentsOfFiscalYearSuccess(data))
      })
      .catch((error) => {
        dispatch(getFullDocumentsOfFiscalYearFailure())
      })
  }

export const getUrlForFullDocumentDisplayThunk =
  (merchantId: number, fullDocumentId: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getUrlFullDocumentAttempt())
    axios
      .get<string>("/full_documents/get_url_for_full_document", {
        params: { id: fullDocumentId },
      })
      .then((response) => {
        dispatch(
          getUrlFullDocumentSuccess(merchantId, fullDocumentId, response.data)
        )
      })
      .catch(() => {
        dispatch(getUrlFullDocumentFailure())
      })
  }
