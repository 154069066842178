import axios, { AxiosError } from "axios"

import { Dispatch, RNBThunkAction } from "../store.config"
import { AnyAction } from "redux"
import { isAxiosError } from "../../utils/asyncTools"
import { GetCompanyThunk } from "./companies.ducks"
import { InstructionType } from "./merchants.ducks"
import { addOrReplaceBy } from "../../utils/array"
import { GetBillOfExchangeAccountsThunk } from "./accounts.ducks"

export enum MerchantCodesActionsEnum {
  UPDATE_MERCHANT_NAME = "MERCHANTCODES/UpdateMerchantName",
  ADD_MERCHANT_CODE = "MERCHANTCODES/AddMerchantCode",

  GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT = "MERCHANTCODES/GetCompanyMerchantCodesDataAttempt",
  GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS = "MERCHANTCODES/GetCompanyMerchantCodesDataSuccess",
  GET_COMPANY_MERCHANT_CODES_DATA_FAILURE = "MERCHANTCODES/GetCompanyMerchantCodesDataFailure",

  UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT = "MERCHANTCODES/UpdateMerchantCodeCentralizeAttempt",
  UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS = "MERCHANTCODES/UpdateMerchantCodeCentralizeSuccess",
  UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE = "MERCHANTCODES/UpdateMerchantCodeCentralizeFailure",

  RENAME_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/RenameMerchantCodeAttempt",
  RENAME_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/RenameMerchantCodeSuccess",
  RENAME_MERCHANT_CODE_FAILURE = "MERCHANTCODES/RenameMerchantCodeFailure",
  RENAME_MERCHANT_CODE_RESET = "MERCHANTCODES/RenameMerchantCodeReset",

  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeAttempt",
  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeSuccess",
  DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_FAILURE = "MERCHANTCODES/DecentralizeAndUpdateMerchantCodeFailure",

  CREATE_OR_UPDATE_MERCHANT = "MERCHANTCODES/CreateOrUpdateMerchant",
  CREATE_MERCHANT_CODE_FROM_INPUT_MASK = "MERCHANTCODES/CreateOrUpdateMerchantCode",

  GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS = "MERCHANTCODES/GetAllMerchantsOfCompanySuccess",
  GET_ALL_MERCHANTS_OF_COMPANY_FAILURE = "MERCHANTCODES/GetAllMerchantsOfCompanyFailure",

  UPDATE_MANY_MERCHANTS_FOR_CODE_ATTEMPT = "MERCHANTCODES/UpdateManyMerchantsForCodeAttempt",
  UPDATE_MANY_MERCHANTS_FOR_CODE_SUCCESS = "MERCHANTCODES/UpdateManyMerchantsForCodeSuccess",
  UPDATE_MANY_MERCHANTS_FOR_CODE_FAILURE = "MERCHANTCODES/UpdateManyMerchantsForCodeFailure",

  CREATE_MERCHANT_CODE_ATTEMPT = "MERCHANTCODES/CreateMerchantCodeAttempt",
  CREATE_MERCHANT_CODE_SUCCESS = "MERCHANTCODES/CreateMerchantCodeSuccess",
  CREATE_MERCHANT_CODE_FAILURE = "MERCHANTCODES/CreateMerchantCodeFailure",
  CREATE_MERCHANT_CODE_RESET = "MERCHANTCODES/CreateMerchantCodeReset",

  GET_ALL_MERCHANT_CODES_ATTEMPT = "MERCHANTCODES/GetAllMerchantCodesAttempt",
  GET_ALL_MERCHANT_CODES_SUCCESS = "MERCHANTCODES/GetAllMerchantCodesSuccess",
  GET_ALL_MERCHANT_CODES_FAILURE = "MERCHANTCODES/GetAllMerchantCodesFailure",

  CREATE_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/CreateRuleBillOfExchangeAttempt",
  CREATE_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/CreateRuleBillOfExchangeSuccess",
  CREATE_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/CreateRuleBillOfExchangeFailure",

  DESTROY_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/DestroyRuleBillOfExchangeAttempt",
  DESTROY_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/DestroyRuleBillOfExchangeSuccess",
  DESTROY_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/DestroyRuleBillOfExchangeFailure",

  EDIT_RULE_BILL_OF_EXCHANGE_ATTEMPT = "MERCHANTCODES/EditRuleBillOfExchangeAttempt",
  EDIT_RULE_BILL_OF_EXCHANGE_SUCCESS = "MERCHANTCODES/EditRuleBillOfExchangeSuccess",
  EDIT_RULE_BILL_OF_EXCHANGE_FAILURE = "MERCHANTCODES/EditRuleBillOfExchangeFailure",
}

export const updateMerchantName = ({
  merchantId,
  newMerchantName,
}: {
  merchantId: number
  newMerchantName: string
}) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_NAME,
    payload: {
      merchantId,
      newMerchantName,
    },
  } as const)
export const addMerchantCode = (payload: MerchantCode) =>
  ({
    type: MerchantCodesActionsEnum.ADD_MERCHANT_CODE,
    payload,
  } as const)

export const GetCompanyMerchantCodesDataAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT,
  } as const)
export const GetCompanyMerchantCodesDataSuccess = (
  merchantCodes: MerchantCode[]
) =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS,
    merchantCodes,
  } as const)
export const GetCompanyMerchantCodesDataFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_FAILURE,
  } as const)

export const UpdateMerchantCodeCentralizeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_ATTEMPT,
  } as const)
export const UpdateMerchantCodeCentralizeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS,
  } as const)
export const UpdateMerchantCodeCentralizeFailure = (
  error: AxiosError | Error
) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const)

export const RenameMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_ATTEMPT,
  } as const)
export const RenameMerchantCodeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_SUCCESS,
  } as const)
export const RenameMerchantCodeFailure = (error: AxiosError | Error) =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const)
export const RenameMerchantCodeReset = () =>
  ({
    type: MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_RESET,
  } as const)

export const DecentralizeAndUpdateMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_ATTEMPT,
  } as const)
export const DecentralizeAndUpdateMerchantCodeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.decantralize-code.success.title`,
      bodyKey: `accounting-plan.decantralize-code.success.body`,
    },
  } as const)
export const DecentralizeAndUpdateMerchantCodeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.DECENTRALIZE_AND_UPDATE_MERCHANT_CODE_FAILURE,
  } as const)

export const GetAllMerchantsOfCompanySuccess = (
  merchants: MerchantsOfCompany[]
) =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS,
    merchants,
  } as const)

export const CreateOrUpdateMerchant = (merchant: MerchantsOfCompany) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_OR_UPDATE_MERCHANT,
    merchant,
  } as const)

export const CreateMerchantCodeFromInputMask = (merchantCode: MerchantCode) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FROM_INPUT_MASK,
    merchantCode,
  } as const)

export const GetAllMerchantsOfCompanyFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_FAILURE,
  } as const)

export const UpdateManyMerchantsForCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_ATTEMPT,
  } as const)
export const UpdateManyMerchantsForCodeSuccess = (code: string) =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.add-merchant.success.title`,
      bodyKey: `accounting-plan.add-merchant.success.body`,
      bodyValues: { code: code },
    },
  } as const)
export const UpdateManyMerchantsForCodeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.UPDATE_MANY_MERCHANTS_FOR_CODE_FAILURE,
  } as const)

export const CreateMerchantCodeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT,
  } as const)
export const CreateMerchantCodeSuccess = (code: string) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.create-code.success.title`,
      titleValues: { code: code },
      bodyKey: `accounting-plan.create-code.success.message`,
      bodyValues: { code: code },
    },
  } as const)
export const CreateMerchantCodeReset = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_RESET,
  } as const)
export const CreateMerchantCodeFailure = (error: AxiosError | Error) =>
  ({
    type: MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${displayErrorMerchantCodes(error)}.title`,
      bodyKey: `${displayErrorMerchantCodes(error)}.message`,
    },
  } as const)

export const GetAllMerchantCodesAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_ATTEMPT,
  } as const)
export const GetAllMerchantCodesSuccess = (codes: MerchantCode[]) =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_SUCCESS,
    codes,
  } as const)
export const GetAllMerchantCodesFailure = () =>
  ({
    type: MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_FAILURE,
  } as const)

export const CreateRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const CreateRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.success-message`,
    },
  } as const)
export const CreateRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.CREATE_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.create-or-edit-modal.tooltip.error-message`,
    },
  } as const)

export const DestroyRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const DestroyRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.delete.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.delete.tooltip.success-message`,
    },
  } as const)
export const DestroyRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.DESTROY_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.delete.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.delete.tooltip.error-message`,
    },
  } as const)

export const EditRuleBillOfExchangeAttempt = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_ATTEMPT,
  } as const)
export const EditRuleBillOfExchangeSuccess = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.edit.tooltip.success-title`,
      bodyKey: `accounting-plan.lcr.edit.tooltip.success-message`,
    },
  } as const)
export const EditRuleBillOfExchangeFailure = () =>
  ({
    type: MerchantCodesActionsEnum.EDIT_RULE_BILL_OF_EXCHANGE_FAILURE,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.lcr.edit.tooltip.error-title`,
      bodyKey: `accounting-plan.lcr.edit.tooltip.error-message`,
    },
  } as const)

export const displayErrorMerchantCodes = (error: AxiosError | Error) => {
  let id = "error"
  if (
    isAxiosError(error) &&
    error.response !== undefined &&
    error.response.data
  ) {
    if (JSON.stringify(error.response.data).includes("too long")) {
      id = "accounting-plan.create-code.error.too-long"
    } else if (JSON.stringify(error.response.data).includes("forbidden char")) {
      id = "accounting-plan.create-code.error.forbidden-characters"
    } else if (
      JSON.stringify(error.response.data).includes("est déjà utilisé")
    ) {
      id = "accounting-plan.create-code.error.already-taken"
    } else if (
      JSON.stringify(error.response.data).includes(
        "cannot decentralize code because is default code"
      )
    ) {
      id = "accounting-plan.create-code.uniq-code-decentralize.error"
    } else if (
      JSON.stringify(error.response.data).includes(
        "Cannot set default code if not centralize"
      )
    ) {
      id = "accounting-plan.create-code.uniq-code-not-centralize.error"
    } else if (
      JSON.stringify(error.response.data).includes(
        "cannot centralize because company has disabled centralized codes"
      ) ||
      JSON.stringify(error.response.data).includes(
        "Votre dossier est paramétré sans code de regroupement"
      )
    ) {
      id =
        "accounting-plan.create-code.centralize.disabled-centralized-codes.error"
    } else if (JSON.stringify(error.response.data).includes("centralize")) {
      id = "accounting-plan.merchant-code.error.decentralize-account"
    } else if (
      JSON.stringify(error.response.data).includes(
        "another code has already been selected for default"
      )
    ) {
      id = "accounting-plan.create-code.uniq-code.error"
    } else if (
      JSON.stringify(error.response.data).includes(
        "Onboarding not finished for this company"
      )
    ) {
      id = "accounting-plan.create-code.onboarding-unifinished.error"
    }
  }

  return id
}

export type MerchantCodesActionsType = ReturnType<
  | typeof updateMerchantName
  | typeof addMerchantCode
  | typeof GetCompanyMerchantCodesDataAttempt
  | typeof GetCompanyMerchantCodesDataSuccess
  | typeof GetCompanyMerchantCodesDataFailure
  | typeof UpdateMerchantCodeCentralizeAttempt
  | typeof UpdateMerchantCodeCentralizeSuccess
  | typeof UpdateMerchantCodeCentralizeFailure
  | typeof RenameMerchantCodeAttempt
  | typeof RenameMerchantCodeSuccess
  | typeof RenameMerchantCodeFailure
  | typeof RenameMerchantCodeReset
  | typeof DecentralizeAndUpdateMerchantCodeAttempt
  | typeof DecentralizeAndUpdateMerchantCodeSuccess
  | typeof DecentralizeAndUpdateMerchantCodeFailure
  | typeof CreateOrUpdateMerchant
  | typeof CreateMerchantCodeFromInputMask
  | typeof GetAllMerchantsOfCompanySuccess
  | typeof GetAllMerchantsOfCompanyFailure
  | typeof UpdateManyMerchantsForCodeAttempt
  | typeof UpdateManyMerchantsForCodeSuccess
  | typeof UpdateManyMerchantsForCodeFailure
  | typeof CreateMerchantCodeAttempt
  | typeof CreateMerchantCodeSuccess
  | typeof CreateMerchantCodeFailure
  | typeof CreateMerchantCodeReset
  | typeof GetAllMerchantCodesAttempt
  | typeof GetAllMerchantCodesSuccess
  | typeof GetAllMerchantCodesFailure
  | typeof CreateRuleBillOfExchangeAttempt
  | typeof CreateRuleBillOfExchangeSuccess
  | typeof CreateRuleBillOfExchangeFailure
  | typeof DestroyRuleBillOfExchangeAttempt
  | typeof DestroyRuleBillOfExchangeSuccess
  | typeof DestroyRuleBillOfExchangeFailure
  | typeof EditRuleBillOfExchangeAttempt
  | typeof EditRuleBillOfExchangeSuccess
  | typeof EditRuleBillOfExchangeFailure
>

export const merchantCodesInitialState: MerchantCodesState = {
  merchantCodes: [],
  merchantsOfCompany: [],
  updateOneMerchantStatus: "idle",
  createMerchantCodeStatus: "idle",
}

export interface MerchantCodesState {
  merchantCodes: MerchantCode[]
  merchantsOfCompany: MerchantsOfCompany[]
  updateOneMerchantStatus: "idle" | "loading" | "success" | "error"
  createMerchantCodeStatus: "idle" | "loading" | "success" | "error"
}

export interface MerchantCode {
  id: number
  code: string
  centralize: boolean
  auxiliary: boolean
  merchants: MerchantsOfCode[]
  code_instructions: Instructions[]
  default_buy_code: boolean
  default_sell_code: boolean
}

export interface MerchantsOfCompany {
  id: number
  name: string
  code_id: number | null
  code: string | null
  is_new_merchant: boolean
}

export interface MerchantsWithCode extends MerchantsOfCompany {
  code_id: number
  code: string
}

export interface MerchantsOfCode {
  merchant_id: number
  merchant_name: string
  code_id: number
}

export interface Instructions {
  created_at: string
  instruction_type: InstructionType
  user: string
  metadata: {
    [index: string]: string
  }
}

export interface NewMerchantCode {
  code: string
  centralize: boolean
}

export const isMerchantCode = (
  merchantCode: NewMerchantCode | MerchantCode
): merchantCode is MerchantCode => {
  return (merchantCode as MerchantCode).id !== undefined
}

export function merchantCodesReducer(
  state = merchantCodesInitialState,
  action: MerchantCodesActionsType
): MerchantCodesState {
  switch (action.type) {
    case MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_ATTEMPT:
      return { ...state, merchantCodes: [] }
    case MerchantCodesActionsEnum.GET_COMPANY_MERCHANT_CODES_DATA_SUCCESS:
      return { ...state, merchantCodes: action.merchantCodes }
    case MerchantCodesActionsEnum.ADD_MERCHANT_CODE:
      return {
        ...state,
        merchantCodes: [...state.merchantCodes, action.payload],
      }
    case MerchantCodesActionsEnum.UPDATE_MERCHANT_CODE_CENTRALIZE_SUCCESS:
      return { ...state }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_ATTEMPT:
      return { ...state, updateOneMerchantStatus: "loading" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_SUCCESS:
      return { ...state, updateOneMerchantStatus: "success" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_FAILURE:
      return { ...state, updateOneMerchantStatus: "error" }
    case MerchantCodesActionsEnum.RENAME_MERCHANT_CODE_RESET:
      return { ...state, updateOneMerchantStatus: "idle" }
    case MerchantCodesActionsEnum.GET_ALL_MERCHANTS_OF_COMPANY_SUCCESS:
      return { ...state, merchantsOfCompany: action.merchants }
    case MerchantCodesActionsEnum.CREATE_OR_UPDATE_MERCHANT: {
      return {
        ...state,
        merchantsOfCompany: addOrReplaceBy({
          array: state.merchantsOfCompany,
          addIfItemNotFound: (e) => e.id === action.merchant.id,
          item: action.merchant,
        }),
      }
    }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FROM_INPUT_MASK: {
      return {
        ...state,
        merchantCodes: [...state.merchantCodes, action.merchantCode],
      }
    }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_ATTEMPT:
      return { ...state, createMerchantCodeStatus: "loading" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_SUCCESS:
      return { ...state, createMerchantCodeStatus: "success" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_FAILURE:
      return { ...state, createMerchantCodeStatus: "error" }
    case MerchantCodesActionsEnum.CREATE_MERCHANT_CODE_RESET:
      return { ...state, createMerchantCodeStatus: "idle" }
    case MerchantCodesActionsEnum.UPDATE_MERCHANT_NAME: {
      const updatedMerchants = state.merchantsOfCompany.map((m) => {
        if (m.id !== action.payload.merchantId) {
          return m
        }
        return { ...m, name: action.payload.newMerchantName }
      })

      return { ...state, merchantsOfCompany: updatedMerchants }
    }
    case MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_ATTEMPT: {
      return { ...state, merchantCodes: [] }
    }
    case MerchantCodesActionsEnum.GET_ALL_MERCHANT_CODES_SUCCESS: {
      return { ...state, merchantCodes: action.codes }
    }

    default:
      return { ...state }
  }
}

export const updateCentralizeStatus =
  (merchantCodeId: number, centralize: boolean, companyId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(UpdateMerchantCodeCentralizeAttempt())
    return axios
      .put("/merchant_codes/update_centralize_status", {
        id: merchantCodeId,
        centralize: centralize,
      })
      .then(() => {
        dispatch(UpdateMerchantCodeCentralizeSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((error: AxiosError) => {
        if (
          JSON.stringify(error.response?.data).includes(
            "cannot centralize because company has disabled centralized codes"
          )
        ) {
          dispatch(GetCompanyThunk(companyId))
        }
        dispatch(UpdateMerchantCodeCentralizeFailure(error))
      })
  }

export const createMerchantCode =
  (companyId: number) =>
  (merchantId: number) =>
  (payload: { centralize: boolean; code: string }) =>
    axios.post("/merchant_codes", {
      ...payload,
      company_id: companyId,
      merchant_id: merchantId,
    })

interface ResponseMerchantCodesData {
  merchant_codes: MerchantCode[]
}

export const renameMerchantCodeThunk =
  (
    merchantCodeId: number,
    newCode: string,
    companyId: number,
    default_buy_code: boolean,
    default_sell_code: boolean
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(RenameMerchantCodeAttempt())
    return axios
      .put("/merchant_codes/update_merchant_code", {
        id: merchantCodeId,
        new_code: newCode,
        default_buy_code: default_buy_code,
        default_sell_code: default_sell_code,
      })
      .then(() => {
        dispatch(RenameMerchantCodeSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch((e) => {
        if (
          e.response.data.error.includes(
            "another code has already been selected for default"
          )
        ) {
          dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        }
        dispatch(RenameMerchantCodeFailure(e))
      })
  }

export const GetCompanyMerchantCodesDataThunk =
  (companyId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(GetCompanyMerchantCodesDataAttempt())

    return axios
      .get<ResponseMerchantCodesData>(
        "/companies/get_merchants_codes_data_for_company",
        { params: { company_id: companyId } }
      )
      .then(({ data }) => {
        dispatch(GetCompanyMerchantCodesDataSuccess(data.merchant_codes))
      })
      .catch(GetCompanyMerchantCodesDataFailure)
  }

export const DecentralizeAndUpdateMerchantCodeThunk =
  (
    id: number,
    codesAndMerchants: {
      merchant_id: number
      code_id: number | null
      code: string
    }[],
    companyId: number
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(DecentralizeAndUpdateMerchantCodeAttempt())
    return axios
      .put("/merchant_codes/update_many_merchant_codes_decentralize", {
        id: id,
        codes_and_merchants: codesAndMerchants,
      })
      .then(() => {
        dispatch(DecentralizeAndUpdateMerchantCodeSuccess())
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
  }

export const GetAllMerchantsOfCompanyForCodes =
  (companyId: number) => (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    return axios
      .get<MerchantsOfCompany[]>("merchants/get_all_merchants_for_company", {
        params: { company_id: companyId },
      })
      .then(({ data }) => {
        dispatch(GetAllMerchantsOfCompanySuccess(data))
      })
      .catch(() => {
        dispatch(GetAllMerchantsOfCompanyFailure())
      })
  }

export const UpdateManyMerchantsForCode =
  (
    companyId: number,
    code: string,
    merchantCodeId: number,
    merchants: number[]
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(UpdateManyMerchantsForCodeAttempt())
    return axios
      .put("/merchant_codes/update_many_merchants_for_code", {
        id: merchantCodeId,
        merchants: merchants,
      })
      .then(() => {
        dispatch(UpdateManyMerchantsForCodeSuccess(code))
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      })
      .catch(() => {
        dispatch(UpdateManyMerchantsForCodeAttempt())
      })
  }

export const CreateMerchantCodeThunk =
  (
    companyId: number,
    code: string,
    centralize: boolean,
    merchantIds: number[],
    defaultBuyCode: boolean,
    defaultSellCode: boolean
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(CreateMerchantCodeAttempt())
    return axios
      .put("/merchant_codes/create_new_code", {
        company_id: companyId,
        code: code,
        centralize: centralize,
        merchant_ids: merchantIds,
        default_buy_code: defaultBuyCode,
        default_sell_code: defaultSellCode,
      })
      .then(() => {
        dispatch(CreateMerchantCodeSuccess(code))
        dispatch(GetCompanyMerchantCodesDataThunk(companyId))
        dispatch(GetAllMerchantsOfCompanyForCodes(companyId))
      })
      .catch((e) => {
        dispatch(CreateMerchantCodeFailure(e))
      })
  }

export const GetAllMerchantCodesThunk =
  (companyId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(GetAllMerchantCodesAttempt())
    return axios
      .get<MerchantCode[]>("/merchant_codes/get_all_merchant_codes", {
        params: { id: companyId },
      })
      .then((response) => {
        dispatch(GetAllMerchantCodesSuccess(response.data))
      })
      .catch(() => {
        dispatch(GetAllMerchantCodesFailure())
      })
  }

export const CreateRuleBillOfExchangeThunk =
  (
    companyId: number,
    rule: {
      priority_number: number
      text_in_description: string
      merchant_code_id: number
    }
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(CreateRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/create_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        new_rule: rule,
      })
      .then(() => {
        dispatch(CreateRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(CreateRuleBillOfExchangeFailure()))
  }

export const DestroyRuleBillOfExchangeThunk =
  (companyId: number, ruleId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(DestroyRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/destroy_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        rule_id: ruleId,
      })
      .then(() => {
        dispatch(DestroyRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(DestroyRuleBillOfExchangeFailure()))
  }

export const EditRuleBillOfExchangeThunk =
  (
    companyId: number,
    ruleId: number,
    editedRule: {
      priority_number: number
      merchant_code_id: number
      text_in_description: string
    }
  ) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(EditRuleBillOfExchangeAttempt())
    return axios
      .post(`/merchant_codes/edit_rule_bill_of_exchange_merchant_code`, {
        company_id: companyId,
        rule_id: ruleId,
        edited_rule: editedRule,
      })
      .then(() => {
        dispatch(EditRuleBillOfExchangeSuccess())
        dispatch(GetBillOfExchangeAccountsThunk(companyId))
      })
      .catch(() => dispatch(EditRuleBillOfExchangeFailure()))
  }
