import {
  FullDocument,
  PendingFullDocumentDeactivated,
  PendingFullDocumentProcessed,
} from "../store/ducks/fullDocuments.ducks"
import { ArchivesPayload } from "../store/ducks/bankArchives.ducks"

type ProcessedFullDocumentsKeys = keyof PendingFullDocumentProcessed
type DeactivatedFullDocumentsKeys = keyof PendingFullDocumentDeactivated

export function filterDocuments(
  documents: FullDocument[],
  search: string,
  customDateActive?: boolean,
  customDateRange?: [Date, Date]
) {
  const dateDefined = customDateActive && customDateRange

  if (!documents || documents.length === 0) return []
  if (!search && !dateDefined) return [...documents]

  return documents.filter((document) =>
    (document.original_file_name.toLowerCase().includes(search.toLowerCase()) ||
      (document?.user_file_name || "")
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      document.deactivated_because
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      (document?.user_full_document_name || "")
        .toLowerCase()
        .includes(search.toLowerCase())) &&
    dateDefined
      ? new Date(document.created_at) >= customDateRange[0] &&
        new Date(document.created_at) <= customDateRange[1]
      : true
  )
}

export function sortByDate(a: string | undefined, b: string | undefined) {
  if (a === b) return 0
  if (a === null || a === undefined) return -1
  if (b === null || b === undefined) return 1
  return +new Date(a) - +new Date(b)
}

export const sortFullDocsByDate = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  let sorted = []
  if (asc) {
    sorted = [
      ...fullDocuments.sort((a: FullDocument, b: FullDocument) =>
        sortByDate(a.created_at.toString(), b.created_at.toString())
      ),
    ]
  } else {
    sorted = [
      ...fullDocuments.sort((a: FullDocument, b: FullDocument) =>
        sortByDate(b.created_at.toString(), a.created_at.toString())
      ),
    ]
  }
  return sorted
}

export const sortFullDocsByOriginalFileName = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  return [
    ...fullDocuments.sort((a, b) =>
      asc
        ? a?.original_file_name.localeCompare(b.original_file_name)
        : b?.original_file_name.localeCompare(a.original_file_name)
    ),
  ]
}

export const sortFullDocsByFileName = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  return [
    ...fullDocuments.sort((a, b) =>
      asc
        ? (a?.user_file_name || "").localeCompare(b?.user_file_name || "")
        : (b?.user_file_name || "").localeCompare(a?.user_file_name || "")
    ),
  ]
}

export const sortBankArchiveById = (
  archives: ArchivesPayload[],
  asc: boolean
) => {
  return archives.sort((a, b) => (asc ? a.id - b.id : b.id - a.id))
}

export const sortFullDocsByArchiveId = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  return [
    ...fullDocuments.sort((a, b) =>
      asc ? a.archive_id - b.archive_id : b.archive_id - a.archive_id
    ),
  ]
}

export const sortFullDocsByType = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  let allFullDocuments = []

  const unsupported = fullDocuments.filter(
    (d) => d.deactivated_type === "unsupported"
  )
  const bank = fullDocuments.filter((d) => d.deactivated_type === "bank")
  const permanent = fullDocuments.filter(
    (d) => d.deactivated_type === "permanent"
  )
  const fiscal = fullDocuments.filter((d) => d.deactivated_type === "fiscal")
  const social = fullDocuments.filter((d) => d.deactivated_type === "social")

  allFullDocuments.push(
    ...unsupported,
    ...bank,
    ...permanent,
    ...fiscal,
    ...social
  )

  return asc ? allFullDocuments : allFullDocuments.reverse()
}

export const sortFullDocsByQualification = (
  fullDocuments: FullDocument[],
  asc: boolean
) => {
  return [
    ...fullDocuments.sort((a, b) =>
      asc
        ? a?.deactivated_because.localeCompare(b.deactivated_because)
        : b?.deactivated_because.localeCompare(a.deactivated_because)
    ),
  ]
}

export const sortProcessedFullDocumentByKey = (
  fullDocuments: PendingFullDocumentProcessed[][],
  asc: boolean,
  key: ProcessedFullDocumentsKeys,
  isDebitOrCredit?: string
) => {
  if (key === "account_details") {
    return fullDocuments.sort((a, b) => {
      const cA = a[0]
      const cB = b[0]

      return asc
        ? Number(cA.account_number) - Number(cB.account_number)
        : Number(cB.account_number) - Number(cA.account_number)
    })
  }
  if (
    [
      "merchant_code",
      "fd_document_reference",
      "journal_code",
      "original_batch_name",
    ].includes(key)
  )
    return fullDocuments.sort((a, b) => {
      let firstKey = a[0][key]
      let secondKey = b[0][key]
      if (firstKey === secondKey) return 0
      switch (asc) {
        case true:
          if (!firstKey) return -1
          if (!secondKey) return 1
          return String(firstKey).localeCompare(String(secondKey))
        case false:
          if (!firstKey) return 1
          if (!secondKey) return -1
          return String(secondKey).localeCompare(String(firstKey))
        default:
          return 0
      }
    })
  else if (["fd_document_date", "fd_document_due_date"].includes(key))
    return fullDocuments.sort((a, b) =>
      asc
        ? sortByDate(a[0][key]?.toString(), b[0][key]?.toString())
        : sortByDate(b[0][key]?.toString(), a[0][key]?.toString())
    )
  else if (key === "amount")
    return fullDocuments.sort((a, b) => {
      const maxA = Math.max(
        ...a
          .filter((element) => element.direction === isDebitOrCredit)
          .map((element) => Number(element[key]))
      )
      const maxB = Math.max(
        ...b
          .filter((element) => element.direction === isDebitOrCredit)
          .map((element) => Number(element[key]))
      )
      return asc ? Number(maxA) - Number(maxB) : Number(maxB) - Number(maxA)
    })
  else return fullDocuments
}

export const sortDeactivatedFullDocumentByKey = (
  fullDocuments: PendingFullDocumentDeactivated[],
  asc: boolean,
  key: DeactivatedFullDocumentsKeys,
  deactivatedReasonsGroup: { [id: string]: string }
) => {
  switch (key) {
    case "user_batch_name":
      return fullDocuments.sort((a, b) => {
        let firstKey = a[key] || a.original_batch_name
        let secondKey = b[key] || b.original_batch_name
        if (firstKey === secondKey) return 0
        return asc
          ? String(firstKey).localeCompare(String(secondKey))
          : String(secondKey).localeCompare(String(firstKey))
      })
    case "deactivated_because":
      const duplicateFullDocuments = fullDocuments.filter(
        (fd) => fd.deactivated_because === "duplicate"
      )
      const fullDocumentsFiltered = fullDocuments.filter(
        (fd) => fd.deactivated_because !== "duplicate"
      )
      fullDocumentsFiltered.sort((a, b) => {
        const firstCategory = Object.entries(deactivatedReasonsGroup).find(
          ([e, f]: [string, {}]) =>
            Object.keys(f).includes(a.deactivated_because)
        )
        const secondCategory = Object.entries(deactivatedReasonsGroup).find(
          ([e, f]: [string, {}]) =>
            Object.keys(f).includes(b.deactivated_because)
        )
        if (firstCategory && secondCategory) {
          const firstReason = Object.entries(firstCategory[1]).find(
            (element) => element[0] === a.deactivated_because
          )
          const secondReason = Object.entries(secondCategory[1]).find(
            (element) => element[0] === b.deactivated_because
          )
          if (firstReason && secondReason)
            return asc
              ? firstReason[1].localeCompare(secondReason[1])
              : secondReason[1].localeCompare(firstReason[1])
        }
        return 0
      })
      return asc
        ? [...fullDocumentsFiltered, ...duplicateFullDocuments]
        : [...duplicateFullDocuments, ...fullDocumentsFiltered]
    case "document_type":
      const sortOrder = [
        "unsupported",
        "duplicate",
        "bank",
        "permanent",
        "fiscal",
      ]
      const sortedFiltered = fullDocuments.sort((a, b) => {
        const firstCategory = Object.entries(deactivatedReasonsGroup).find(
          ([e, f]: [string, {}]) =>
            Object.keys(f).includes(a.deactivated_because)
        ) || [""]
        const secondCategory = Object.entries(deactivatedReasonsGroup).find(
          ([e, f]: [string, {}]) =>
            Object.keys(f).includes(b.deactivated_because)
        ) || [""]
        return (
          sortOrder.indexOf(firstCategory[0]) -
          sortOrder.indexOf(secondCategory[0])
        )
      })
      return asc ? sortedFiltered : sortedFiltered.reverse()
    case "fd_created_at":
      return fullDocuments.sort((a, b) =>
        asc
          ? sortByDate(a[key]?.toString(), b[key]?.toString())
          : sortByDate(b[key]?.toString(), a[key]?.toString())
      )
    default:
      return fullDocuments
  }
}

export function filterProcessedFullDocuments(
  fullDocuments: PendingFullDocumentProcessed[][],
  search: string
) {
  if (!search) return fullDocuments
  const fullDocumentsFiltered = fullDocuments.filter(
    (fullDocument: PendingFullDocumentProcessed[]) =>
      fullDocument.some(
        (writingLine) =>
          writingLine &&
          (writingLine.account_details
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
            writingLine.fd_document_reference
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            writingLine.account_number
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            writingLine.merchant_name
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            writingLine.merchant_code
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            writingLine.journal_code
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            writingLine.fd_document_date
              .substring(0, 10)
              .split("-")
              .reverse()
              .join("/")
              .includes(search.toLowerCase()) ||
            writingLine.amount.includes(search))
      )
  )
  return fullDocumentsFiltered
}

export function filterDeactivatedFullDocuments(
  fullDocuments: PendingFullDocumentDeactivated[],
  search: string,
  deactivatedReasonsGroup: { [id: string]: string }
) {
  if (!search) return fullDocuments
  const fullDocumentsFiltered = fullDocuments.filter((fullDocument) => {
    const documentTypeLabel = Object.entries(deactivatedReasonsGroup).find(
      ([e, f]: [string, {}]) =>
        Object.keys(f).includes(fullDocument.deactivated_because)
    )
    let deactivatedBecauseLabel: [string, string] | undefined
    if (documentTypeLabel) {
      deactivatedBecauseLabel = Object.entries(documentTypeLabel[1]).find(
        (element) => element[0] === fullDocument.deactivated_because
      )
    }
    const createdAt = fullDocument.fd_created_at
      .substring(0, 10)
      .split("-")
      .reverse()
      .join("/")

    return (
      (fullDocument.user_batch_name &&
        fullDocument.user_batch_name
          .toLowerCase()
          .includes(search.toLowerCase())) ||
      (!fullDocument.user_batch_name &&
        fullDocument.original_batch_name
          ?.toLowerCase()
          .includes(search.toLowerCase())) ||
      createdAt.substring(0, 10).includes(search) ||
      (deactivatedBecauseLabel &&
        deactivatedBecauseLabel[1].toLowerCase().includes(search.toLowerCase()))
    )
  })
  return fullDocumentsFiltered
}
