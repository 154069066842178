import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import {
  AccountRules,
  AccountRulesWithoutDocument,
} from "../../store/ducks/bank.ducks"
import { RuleCreateParts } from "./RuleCreateParts"
import { RuleWithoutDocumentCreateEditPart } from "./RuleWithoutDocumentCreateEditPart"
import { Text } from "../Commons/Text"
import { isAccountRuleWithoutDocument } from "../../utils/bank"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"

/* eslint-disable camelcase */

interface CreateOrEditRuleProps {
  isDisplayed: boolean
  onClose: () => void
  rulesLength: number
  createOrEdit: "create" | "edit"
  ruleOrRuleWithoutDocument: "rule" | "ruleWithoutDocument"
  ruleToEdit: AccountRules | AccountRulesWithoutDocument | null
}

export const CreateOrEditRuleModal = ({
  isDisplayed,
  onClose,
  rulesLength,
  createOrEdit,
  ruleOrRuleWithoutDocument,
  ruleToEdit,
}: CreateOrEditRuleProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"} padding={"0"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer />
        <Text
          text={intl.formatMessage({
            id: `bank-management.rules.${createOrEdit}-rule`,
          })}
          textStyle={{
            fontSize: 5,
            fontWeight: 600,
          }}
        />

        <Ct.Spacer height={2} />
        <Line />
        <Ct.Spacer height={2} />

        {ruleOrRuleWithoutDocument === "ruleWithoutDocument" &&
          (isAccountRuleWithoutDocument(ruleToEdit) || !ruleToEdit) && (
            <RuleWithoutDocumentCreateEditPart
              isDisplayed={isDisplayed}
              onClose={onClose}
              createOrEdit={createOrEdit}
              ruleToEdit={ruleToEdit}
            />
          )}

        {ruleOrRuleWithoutDocument === "rule" &&
          !isAccountRuleWithoutDocument(ruleToEdit) && (
            <RuleCreateParts
              isDisplayed={isDisplayed}
              onClose={onClose}
              rulesLength={rulesLength}
              createOrEdit={createOrEdit}
              ruleToEdit={ruleToEdit}
              from={"rules"}
              createForOneTransaction={null}
              disableAll={false}
            />
          )}

        <Ct.Spacer height={4} />
      </Ct.Card>
    </Ct.Modal>
  )
}

const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
