import { ReactComponent as Lock } from "../assets/lock.svg"
import { MutableRefObject } from "react"
import { FieldValues, UseFormRegister } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { SirenStatus } from "../utils/siren"
import { colors } from "../styles/design.config"

interface SirenInputProps {
  sirenRegistered: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: MutableRefObject<any>
  register: UseFormRegister<FieldValues>
  siren: string
  errors: {
    [x: string]: { message: string }
  }
  sirenState: SirenStatus
  isInvalid: boolean
  isValidated: boolean
  isLoading: boolean
  isHiddenSiren: boolean
  sirenType: string
  disabled?: boolean
  existingCompany?: boolean
  existingSiren?: boolean
  currentCompany?: string
}

export const SirenInput = ({
  sirenRegistered,
  inputRef,
  register,
  siren,
  errors,
  isInvalid,
  sirenState,
  isValidated,
  isLoading,
  isHiddenSiren,
  sirenType,
  disabled,
  existingCompany,
  existingSiren,
  currentCompany,
}: SirenInputProps) => {
  const intl = useIntl()

  const VerboseLoader = () => (
    <StyledLoaderContainer>
      <Ct.SpinningLoader />
      <Ct.Text
        text={intl.formatMessage({
          id: "fiduciary-register.siren.verification",
        })}
      />
    </StyledLoaderContainer>
  )

  const InvalidFormatMessage = () => (
    <StyledMessage
      text={intl.formatMessage({
        id: "fiduciary-register.siren.format",
      })}
      textStyle={{ color: "orange", fontSize: 1.5 }}
    />
  )

  const ExistingFiduciary = () => (
    <StyledMessage
      text={intl.formatMessage({
        id: "fiduciary-register.siren.existing-fiduciary",
      })}
      textStyle={{ color: "orange", fontSize: 1.5 }}
    />
  )

  const ExistingCompany = () => (
    <StyledMessage
      text={intl.formatMessage({
        id: "invoicing.settings.create-client.know-company",
      })}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )
  const HiddenSiren = () => (
    <StyledMessage
      text={intl.formatMessage({
        id: "invoicing.settings.create-client.hidden-siren",
      })}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )

  const ExistingSiren = () => (
    <StyledMessage
      text={intl.formatMessage(
        { id: "invoicing.settings.create-client.know-siren" },
        { companyName: currentCompany }
      )}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )

  const UnknownErrorMessage = () => (
    <StyledMessage
      text={intl.formatMessage({
        id: `${sirenType}-register.siren.error`,
      })}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )

  return (
    <>
      {sirenRegistered ? (
        <Ct.Input
          name="siren"
          dataCy="siren"
          register={register as unknown as UseFormRegister<FieldValues>}
          label={intl.formatMessage({ id: "fiduciary-register.siren" })}
          placeholder={intl.formatMessage({
            id: "fiduciary-register.placeholder",
          })}
          type="text"
          value={siren}
          suffix={<StyledLock />}
          disabled={true}
          bypassDisabled={true}
          autoFocus={true}
          ref={inputRef ? (el) => (inputRef.current = el) : null}
        />
      ) : (
        <Ct.Input
          name="siren"
          dataCy="siren"
          register={register as unknown as UseFormRegister<FieldValues>}
          label={intl.formatMessage({ id: "fiduciary-register.siren" })}
          placeholder={intl.formatMessage({
            id: "fiduciary-register.placeholder",
          })}
          type="text"
          value={siren}
          showWarning={
            (!isInvalid && errors?.siren?.message === "length") ||
            sirenState === "taken fiduciary"
          }
          showError={
            isInvalid ||
            errors?.siren?.message === "format" ||
            existingCompany ||
            existingSiren
          }
          showSuccess={isValidated && !sirenRegistered}
          disabled={isLoading || sirenRegistered || disabled}
          autoFocus={true}
          ref={inputRef ? (el) => (inputRef.current = el) : null}
        />
      )}

      {isLoading && <VerboseLoader />}

      {!isLoading && !isInvalid && errors?.siren?.message === "length" && (
        <InvalidFormatMessage />
      )}

      {!isLoading && !isInvalid && sirenState === "taken fiduciary" && (
        <ExistingFiduciary />
      )}

      {!isLoading && sirenState === "hidden" && <HiddenSiren />}

      {!isLoading && !isInvalid && existingCompany && <ExistingCompany />}

      {!isLoading && !isInvalid && existingSiren && <ExistingSiren />}

      {!isLoading &&
        ((isInvalid && !isHiddenSiren) ||
          errors?.siren?.message === "format") && <UnknownErrorMessage />}
    </>
  )
}

const StyledLoaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
`

const StyledMessage = styled((props) => <Ct.Text {...props} />)`
  width: 100%;
  padding: 0.5rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
`

const StyledLock = styled(Lock)`
  fill: ${colors.cornflower};
`
