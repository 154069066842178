import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import * as Ct from "ldlj"

import { ReactComponent as Valid } from "../../../assets/upload/valid.svg"
import { ReactComponent as Wrong } from "../../../assets/upload/wrong.svg"
import { ReactComponent as XLS } from "../../../assets/upload/xls.svg"
import { ReactComponent as NoFec } from "../../../assets/upload/no_fec.svg"

import { getIdFromParams } from "../../../utils/company"
import { useRNBSelector } from "../../../store/rootReducer"
import {
  setUploadStatus,
  uploadAndValidateStandardLedgerThunk,
  resetUpload,
  CheckCreateWithoutFecThunk,
  CreateWithoutFecThunk,
  CreateWithoutFecReset,
} from "../../../store/ducks/standardLedger.ducks"
import { truncateFileName } from "../../../utils/string"
import { useSelectedCompany } from "../../../hooks/useSelectedCompany"
import { DropFileUpload, UploadStatus } from "../../../components/DropFile"
import { Alert } from "../../../components/Commons/Alert"
import { Button } from "../../../components/Commons/Button"
import { Company, CompanyStatus } from "../../../store/ducks/companies.ducks"
import { usePrompt } from "../../../utils/usePrompt.hook"

/* eslint-disable camelcase */

export const InitializationFEC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const {
    uploadProgress,
    uploadStatus,
    checkCreateWithoutFecStatus,
    createWithoutFecStatus,
  } = useRNBSelector((state) => state.standardLedger)

  const selectedCompanyId = getIdFromParams(params)("company_id")
  const selectedCompany = useSelectedCompany(params, dispatch)
  const companyStatus = useRNBSelector(
    (state) => state.companies.companyStatusForUser
  )
  const userTypology = useRNBSelector((state) => state.user.typology)
  const company_to_copy = useRNBSelector(
    (state) => state.standardLedger.company_to_copy
  )

  useEffect(() => {
    if (userTypology === "customer") {
      navigate("/Unauthorized")
    }
  }, [userTypology, navigate])

  useEffect(() => {
    if (createWithoutFecStatus === "success") {
      navigate(`/office/company/${selectedCompanyId}/drop`)
      dispatch(CreateWithoutFecReset())
    }
  }, [createWithoutFecStatus])

  useEffect(() => {
    if (
      selectedCompanyId &&
      checkCreateWithoutFecStatus === "idle" &&
      selectedCompany?.standard_ledgers?.length === 0
    ) {
      dispatch(CheckCreateWithoutFecThunk(selectedCompanyId))
    }
  }, [
    checkCreateWithoutFecStatus,
    selectedCompanyId,
    selectedCompany,
    dispatch,
  ])

  usePrompt(
    intl.formatMessage({
      id: "initializationFEC.uploading-not-finished.prompt",
    }),
    uploadStatus === "uploading"
  )

  return (
    <>
      {checkCreateWithoutFecStatus === "loading" ? (
        <LoadingWrapper>
          <div>
            <Button label={""} loadingStatus={"loading"} />
          </div>

          <Ct.Spacer height={3} />

          <Ct.Text
            text={intl.formatMessage({
              id: "initializationFEC.no-fec.loading",
            })}
            textStyle={{
              fontSize: 2,
            }}
          />
        </LoadingWrapper>
      ) : checkCreateWithoutFecStatus === "can" &&
        selectedCompany?.no_fec === false ? (
        <>
          <Ct.Title
            text={intl.formatMessage({ id: "initializationFEC.title" })}
          />
          <Ct.Spacer height={5} />
          <OptionsWrapper>
            <FecWrapper>
              <FECDrop
                companyStatus={companyStatus}
                uploadProgress={uploadProgress}
                uploadStatus={uploadStatus}
                selectedCompany={selectedCompany}
                selectedCompanyId={selectedCompanyId}
                isAnOption={true}
              />
            </FecWrapper>

            <Ct.Spacer width={2} />

            <NoFecWrapper>
              <Ct.Text
                text={intl.formatMessage({
                  id: "initializationFEC.no-fec.title",
                })}
                textStyle={{
                  fontSize: 2,
                  fontWeight: 700,
                }}
              />

              <Ct.Spacer height={2} />

              <Ct.Text
                text={intl.formatMessage({
                  id: "initializationFEC.no-fec.text1",
                })}
                textStyle={{
                  fontSize: 1.75,
                }}
              />

              <Ct.Spacer height={1} />

              <Ct.Text
                text={intl.formatMessage({
                  id: "initializationFEC.no-fec.text2",
                })}
                textStyle={{
                  fontSize: 1.75,
                }}
              />
              <Ct.Spacer height={2} />

              <NoFec />

              <Ct.Button
                label={intl.formatMessage({
                  id: "initializationFEC.no-fec.cta",
                })}
                width={42.5}
                onClick={() => {
                  if (
                    selectedCompanyId &&
                    company_to_copy &&
                    createWithoutFecStatus === "idle"
                  ) {
                    dispatch(
                      CreateWithoutFecThunk(selectedCompanyId, company_to_copy)
                    )
                  }
                }}
                disabled={uploadStatus !== "start"}
              />
            </NoFecWrapper>
          </OptionsWrapper>
        </>
      ) : (
        <FECDrop
          companyStatus={companyStatus}
          uploadProgress={uploadProgress}
          uploadStatus={uploadStatus}
          selectedCompany={selectedCompany}
          selectedCompanyId={selectedCompanyId}
          isAnOption={false}
        />
      )}
    </>
  )
}

interface FECDropProps {
  companyStatus: CompanyStatus
  uploadStatus: UploadStatus
  uploadProgress: number
  selectedCompany: Company | null
  selectedCompanyId: number | null
  isAnOption: boolean
}

export const FECDrop = ({
  companyStatus,
  uploadProgress,
  uploadStatus,
  selectedCompany,
  selectedCompanyId,
  isAnOption,
}: FECDropProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [showFile, setShowFile] = useState(false)
  const [formatError, setFormatError] = useState(false)

  useEffect(() => {
    setShowFile(false)
    dispatch(resetUpload())
  }, [selectedCompany])

  switch (companyStatus) {
    case "FOUND":
      if (
        selectedCompany &&
        selectedCompany.standard_ledgers &&
        selectedCompany.standard_ledgers.length > 0
      ) {
        if (uploadStatus !== "success") {
          navigate("/unauthorized")
        }
      }
      break
    case "FORBIDDEN":
    case "NOT_FOUND":
      navigate("/unauthorized")
      break
  }

  if (["error", "success"].includes(uploadStatus) && showFile) {
    setShowFile(false)
  }

  useEffect(() => {
    if (uploadStatus === "success") {
      dispatch(resetUpload())
      navigate(`/office/company/${selectedCompanyId}/drop`)
    }
  }, [uploadStatus])

  const uploadFile = (file: File) => {
    if (uploadStatus !== "start") return
    if (!validateFile(file)) {
      setFormatError(true)
      return
    }
    setFormatError(false)
    dispatch(setUploadStatus("confirmation"))
    setTimeout(() => {
      setShowFile(true)
    }, 1000)
    setFile(file)
  }

  const confirmUpload = () => {
    dispatch(setUploadStatus("uploading"))

    if (selectedCompanyId === null || file === null) {
      return
    }

    dispatch(
      uploadAndValidateStandardLedgerThunk({
        companyId: String(selectedCompanyId),
        file: file,
      })
    )
  }

  return (
    <>
      {companyStatus !== "FOUND" ? (
        <div>Loading</div>
      ) : (
        <AbsoluteWrapper>
          <DropCard
            width={isAnOption ? "auto" : "108rem"}
            uploadStatus={uploadStatus}
            height={isAnOption ? "50rem" : "auto"}
          >
            <Alert alertType="info" margin="0 0 0 2rem">
              <Ct.Text
                text={intl.formatMessage({ id: "initializationFEC.alert.1" })}
                textStyle={{ lineHeight: 3 }}
              />
              <Ct.Text
                text={intl.formatMessage({ id: "initializationFEC.alert.2" })}
                textStyle={{ lineHeight: 3 }}
              />
            </Alert>
            <Ct.Spacer height={3} />
            <DropFileUpload
              dropText="Fec"
              uploadProgress={uploadProgress}
              uploadFile={uploadFile}
              uploadStatus={uploadStatus}
              height="22rem"
            />
            <DropConfirmMessage
              uploadStatus={uploadStatus}
              data-cy={"init-fec-confirm-message"}
            >
              <Ct.Spacer height={1} />
              <Alert alertType="bulb">
                <Ct.Text
                  text={intl.formatMessage({ id: "initializationFEC.confirm" })}
                />
              </Alert>
            </DropConfirmMessage>
            <DropErrorMessage uploadStatus={uploadStatus}>
              <Ct.Spacer height={1} />
              <Alert alertType={"error"}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "initializationFEC.error.description.1",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "initializationFEC.error.description.2",
                  })}
                  textStyle={{
                    lineHeight: 3,
                  }}
                />
              </Alert>
              <Ct.Spacer />
              <Ct.Button
                label={intl.formatMessage({
                  id: "initializationFEC.error.description.cta",
                })}
                onClick={() => dispatch(resetUpload())}
              />
            </DropErrorMessage>
            {formatError && (
              <>
                <Ct.Spacer />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "initializationFEC.formatError",
                  })}
                  textStyle={{ color: "amaranth" }}
                />
              </>
            )}
          </DropCard>

          <Ct.JustifyCenter>
            <DropConfirmChoice uploadStatus={uploadStatus}>
              <DropConfirmChoiceContent uploadStatus={uploadStatus}>
                <div />
                <Ct.Row>
                  <ScaleIn
                    uploadStatus={uploadStatus}
                    onClick={() => {
                      dispatch(resetUpload())
                      setShowFile(false)
                    }}
                  >
                    <Wrong />
                  </ScaleIn>
                  <Ct.Spacer width={4} />
                  <ScaleIn uploadStatus={uploadStatus} onClick={confirmUpload}>
                    <Valid id="validSvg" />
                  </ScaleIn>
                </Ct.Row>
              </DropConfirmChoiceContent>
            </DropConfirmChoice>
          </Ct.JustifyCenter>

          <File
            uploadStatus={uploadStatus}
            showFile={showFile}
            isAnOption={isAnOption}
          >
            <StyledXLSsvg />
            <Ct.Text
              text={truncateFileName(file?.name || "", 30)}
              textStyle={{ fontWeight: 700 }}
            />
          </File>
        </AbsoluteWrapper>
      )}
    </>
  )
}

const StyledXLSsvg = styled(XLS)`
  height: 4rem;
`

function validateFile(file: File) {
  let ext = file.name.slice(-4)
  return [".txt", ".csv"].includes(ext.toLowerCase())
}

const AbsoluteWrapper = styled((props) => <Ct.AlignSelfCenter {...props} />)`
  position: relative;
`

interface DropCardProps {
  uploadStatus: UploadStatus
  height: string
}

const DropCard = styled((props) => <Ct.Card {...props} />)<DropCardProps>`
  z-index: 2;
  height: ${(props) => props.height};
`

interface UploadStatusProp {
  uploadStatus: UploadStatus
}

const DropConfirmMessage = styled.div<UploadStatusProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) =>
    props.uploadStatus === "confirmation" ? "10rem" : "0rem"};
  transition: 0.6s all 0.6s cubic-bezier(0.65, 0.31, 0.83, 1.2);
  overflow: hidden;
  padding: ${(props) =>
    props.uploadStatus === "confirmation" ? "2rem" : "0rem"};
`

const DropErrorMessage = styled.div<UploadStatusProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.uploadStatus === "error" ? "20rem" : "0rem")};
  transition: 0.6s all 0.6s cubic-bezier(0.65, 0.31, 0.83, 1.2);
  overflow: hidden;
  padding: ${(props) => (props.uploadStatus === "error" ? "2rem" : "0rem")};
`

interface DropConfirmChoiceProps {
  uploadStatus: UploadStatus
}

const confirmSize = 15

const DropConfirmChoice = styled((props) => (
  <Ct.Card {...props} />
))<DropConfirmChoiceProps>`
  display: flex;
  z-index: 1;
  position: relative;
  bottom: ${(props) =>
    ["confirmation", "uploading"].includes(props.uploadStatus)
      ? confirmSize * 0.25 + "rem"
      : confirmSize + "rem"};
  height: ${(props) =>
    ["confirmation", "uploading"].includes(props.uploadStatus)
      ? `${confirmSize}rem`
      : "0rem"};
  transition: 0.6s bottom 0.6s ease-in-out, height 0.6s ease-in-out;
`

interface FileProps {
  uploadStatus: UploadStatus
  showFile: boolean
  isAnOption: boolean
}

const File = styled.div<FileProps>`
  align-items: center;
  z-index: 3;
  position: absolute;
  top: ${(props) =>
    ["start", "confirmation"].includes(props.uploadStatus) && !props.isAnOption
      ? "64rem"
      : ["start", "confirmation"].includes(props.uploadStatus) &&
        props.isAnOption
      ? "53rem"
      : ["uploading"].includes(props.uploadStatus)
      ? "50rem"
      : "41rem"};
  left: ${(props) =>
    ["start", "uploading"].includes(props.uploadStatus)
      ? "30rem"
      : ["confirmation"].includes(props.uploadStatus)
      ? "30rem"
      : "44rem"};
  opacity: ${(props) => (props.showFile ? "1" : "0")};
  display: flex;
  transition: 0.6s top ease-in-out 0.6s;
`

const DropConfirmChoiceContent = styled.div<DropConfirmChoiceProps>`
  width: 100%;
  position: relative;
  top: ${() => confirmSize * 0.125 + "rem"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) =>
    ["uploading", "start"].includes(props.uploadStatus) ? "0" : "1"};
  transition: 0.2s opacity 0s;
`

const ScaleIn = styled.div<DropConfirmChoiceProps>`
  transform: ${(props) =>
    ["uploading", "start"].includes(props.uploadStatus)
      ? "scale(0)"
      : "scale(1)"};
  transition: 0.6s transform 1.2s cubic-bezier(0.65, 0.31, 0.83, 1.2);
  cursor: pointer;
`

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const NoFecWrapper = styled((props) => <Ct.Card {...props} />)<DropCardProps>`
  width: 49%;
  height: 50rem;
`

const FecWrapper = styled.div`
  height: 50rem;
  width: 49%;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  flex-direction: column;
`
