import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components/macro"
import { useRNBSelector } from "../store/rootReducer"
import { getCurrentUserThunk } from "../store/ducks/user.ducks"
import { gradients } from "../styles/design.config"
import { UserMenu } from "./UserMenu"
import * as Ct from "ldlj"

export const LogoutButton = () => {
  const [showMenu, setShowMenu] = useState(false)

  const dispatch = useDispatch()

  const userFirstName = useRNBSelector((state) => state.user.firstName)
  const userEmail = useRNBSelector((state) => state.user.email)

  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  const initial = getInitial({
    userFirstName,
    userEmail,
  })
  return (
    <Ct.Column>
      <StyledLogoutButton
        id="userButton"
        onClick={() => {
          setShowMenu(!showMenu)
        }}
      >
        {initial}
      </StyledLogoutButton>
      <Ct.Modal
        isDisplayed={showMenu}
        backgroundDisabled={true}
        onClose={() => {
          setShowMenu(false)
        }}
        top={"11.7rem"}
        right={"4rem"}
      >
        <UserMenu showMenu={showMenu} />
      </Ct.Modal>
    </Ct.Column>
  )
}

function getInitial({
  userFirstName,
  userEmail,
}: {
  userFirstName: string
  userEmail: string
}) {
  if (userFirstName) {
    return userFirstName.slice(0, 1)
  }
  if (userEmail) {
    return userEmail.slice(0, 1)
  }
  return ""
}

const StyledLogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  align-self: flex-end;
  border-radius: 50%;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  min-height: 4rem;
  box-sizing: border-box;
  background: ${gradients.blue};
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;
  border: none;
  transition: outline 0.3s ease-in-out;
  outline: 2px solid transparent;
`
