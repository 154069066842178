import { useEffect, useRef, useState, VFC } from "react"
import { useIntl } from "react-intl"
import { useRNBSelector } from "../store/rootReducer"
import { shallowEqual, useDispatch } from "react-redux"
import { cleanSIREN, validateSIREN } from "../utils/siren"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { debounce } from "../utils/debounce"
import {
  attemptSirenValidationThunk,
  registerFiduciaryFriend,
} from "../store/ducks/fiduciary.ducks"
import { attemptCompanySirenValidationThunk } from "../store/ducks/companies.ducks"
import { sizes } from "../styles/design.config"
import { SirenInput } from "./SIRENInput"
import { Alert } from "./Commons/Alert"

export type sirenType = "fiduciary" | "company"

interface SIRENCheckerProps {
  handleSirenRegistration: () => void
  sirenRegistered: boolean
  sirenType: sirenType
}

export const SIRENCheckerPanel: VFC<SIRENCheckerProps> = ({
  handleSirenRegistration,
  sirenRegistered,
  sirenType,
}: SIRENCheckerProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { sirenCheck, name, sirenState } = useRNBSelector(
    (state) => (sirenType === "fiduciary" ? state.fiduciary : state.companies),
    shallowEqual
  )

  const regexSirenFormat = new RegExp(`^(?:[0-9]{9}|[0-9]{14})$`)

  const isBadApe = useRNBSelector((state) =>
    sirenType === "fiduciary" ? state.fiduciary.isBadApe : false
  )

  const schema = yup.object({
    accountant: yup.string(),
    siren: yup
      .string()
      .transform(cleanSIREN)
      .min(9, "length")
      .max(14, "length")
      .matches(regexSirenFormat, "format")
      .required(),
  })

  const companyState = useRNBSelector((state) => state.companies)

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const siren = watch("siren")
  const accountant = watch("accountant")

  const [accountantFocus, setAccountantFocus] = useState(false)

  const isValidated = sirenCheck === "VALIDATED"
  const isUnchecked = sirenCheck === "UNCHECKED"
  const isLoading = sirenCheck === "CHECKING"
  const isInvalid = sirenCheck === "INVALID"
  const isHiddenSiren = sirenState === "hidden"
  const SIRENValidationIsDisabled =
    !isValid ||
    isInvalid ||
    isUnchecked ||
    (isBadApe && !accountant && sirenType === "fiduciary") ||
    isLoading ||
    sirenRegistered ||
    isHiddenSiren

  useEffect(() => {
    if (!siren || !validateSIREN(siren)) {
      return
    }

    sirenType === "fiduciary"
      ? debounce(dispatch(attemptSirenValidationThunk(siren)), 800)
      : debounce(dispatch(attemptCompanySirenValidationThunk(siren)), 800)
  }, [siren, dispatch, sirenType])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>(null)

  const submitSirenInformation = () => {
    handleSirenRegistration()
    dispatch(registerFiduciaryFriend({ referer: accountant }))
  }

  const HiddenErrorMessage = () => (
    <>
      <Ct.Spacer height={3} />

      <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
        <Ct.Text
          text={intl.formatMessage({
            id: `${sirenType}-register.siren.hidden.message-first`,
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Text
          text={intl.formatMessage({
            id: "company-register.siren.hidden.message-second",
          })}
          textStyle={{ lineHeight: 3 }}
        />
      </Alert>
      <Ct.Spacer height={4} />
    </>
  )

  const VerboseBackendResponseFiduciary = () => (
    <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
      {name ? (
        <>
          <Ct.Text
            text={intl.formatMessage({ id: "fiduciary-register.siren.name" })}
            textStyle={{ lineHeight: 3 }}
          />
          <Ct.Text
            text={name}
            textStyle={{
              color: "cornflower",
              fontFamily: "Roboto",
              fontWeight: 700,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </Alert>
  )

  const VerboseBackendResponseCompany = () => (
    <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
      <>
        <Ct.Text
          text={intl.formatMessage({ id: "company-register.siren.name" })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer height={1} />
        <Ct.Text
          text={companyState.name}
          textStyle={{
            color: "cornflower",
            fontFamily: "Roboto",
            fontWeight: 700,
          }}
        />
      </>
    </Alert>
  )

  const AccountingReferalInformation = () => (
    <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
      {name ? (
        <>
          <TextBlock>
            <Ct.Text
              text={intl.formatMessage({
                id: "fiduciary-register.siren.warning",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Text
              text={name}
              textStyle={{ fontWeight: 700, color: "cornflower" }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "fiduciary-register.siren.accounting-issue",
              })}
              textStyle={{ lineHeight: 3 }}
            />
          </TextBlock>

          <Ct.Spacer height={3} />
          <Ct.Input
            name="accountant"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({
              id: "fiduciary-register.siren.accounting-name",
            })}
            type="text"
            value={accountant}
            disabled={sirenRegistered}
            autoFocus={accountantFocus}
            onInput={() => {
              setAccountantFocus(true)
            }}
          />
          <Ct.Spacer height={1} />
        </>
      ) : (
        <></>
      )}
    </Alert>
  )

  const SirenCTA = () => (
    <>
      {!sirenRegistered ? (
        (!SIRENValidationIsDisabled || isHiddenSiren || isBadApe) &&
        isValid && (
          <Ct.Button
            disabled={SIRENValidationIsDisabled}
            width={sizes.button.standard}
            type="submit"
            label={intl.formatMessage({
              id: "fiduciary-register.siren.validate",
            })}
          />
        )
      ) : (
        <Ct.Button
          width={sizes.button.standard}
          type="submit"
          label={intl.formatMessage({
            id: "fiduciary-register.siren.change",
          })}
          colorType={"Secondary"}
          colorScheme={{
            background: "mist",
            color: "cornflower",
            border: "mist",
          }}
          onClick={() => {
            handleSirenRegistration()
            setAccountantFocus(false)
          }}
        />
      )}
    </>
  )

  return (
    <Ct.Card alignSelf={"flex-start"}>
      <Ct.StyledForm onSubmit={handleSubmit(submitSirenInformation)}>
        <SirenInput
          sirenRegistered={sirenRegistered}
          inputRef={inputRef}
          register={register as unknown as UseFormRegister<FieldValues>}
          siren={siren}
          errors={errors}
          isInvalid={isInvalid}
          sirenState={sirenState}
          isValidated={isValidated}
          isLoading={isLoading}
          isHiddenSiren={isHiddenSiren}
          sirenType={sirenType}
        />
        {!isLoading && isInvalid && isHiddenSiren && <HiddenErrorMessage />}

        {!isLoading &&
          !isInvalid &&
          !isUnchecked &&
          isBadApe &&
          !(errors?.siren?.message === "length") &&
          sirenType === "fiduciary" && (
            <>
              <Ct.Spacer height={3} />
              <AccountingReferalInformation />
              <Ct.Spacer height={4} />
            </>
          )}
        {!isLoading &&
          !isInvalid &&
          !isUnchecked &&
          !isBadApe &&
          sirenType === "fiduciary" && (
            <>
              <Ct.Spacer height={3} />
              <VerboseBackendResponseFiduciary />
              <Ct.Spacer height={4} />
            </>
          )}
        {!isLoading &&
          !isInvalid &&
          !isUnchecked &&
          sirenType === "company" &&
          !errors?.siren?.message && (
            <>
              <Ct.Spacer height={4} />
              <VerboseBackendResponseCompany />
              <Ct.Spacer height={4} />
            </>
          )}
        <SirenCTA />
      </Ct.StyledForm>
    </Ct.Card>
  )
}

const TextBlock = styled.div`
  display: initial;
`
