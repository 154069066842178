/* eslint-disable camelcase */
import React, {
  Suspense,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
} from "react"
import styled, { createGlobalStyle } from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { boxShadow, colors, gradients } from "../../styles/design.config"
import { ReactComponent as Chevron } from "../../assets/chevron-down-bigger.svg"
import { formatDateFRdate, locale } from "../../utils/date"
import { ValueType, RangeType } from "rsuite/esm/DateRangePicker/types"

const DateRangePicker = React.lazy(() => import("rsuite/DateRangePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

interface FullDocumentPeriodicityPickerProps {
  value: [Date, Date] | undefined
  onChange: (value: ValueType, event: SyntheticEvent<Element, Event>) => void
  minimum?: Date
  maximum?: Date
  predefinedRanges: RangeType[]
}

export const FullDocumentPeriodicityPicker = (
  props: FullDocumentPeriodicityPickerProps
) => {
  const intl = useIntl()
  const { value, onChange, maximum, minimum } = props

  const disabledDate = (date: Date): boolean => {
    if (minimum && maximum) {
      const startDate = minimum
      const endDate = maximum
      return date < startDate || date > endDate
    }
    return false
  }

  const wrapperRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<HTMLDivElement>(null)
  const [calendarToogle, setCalendarToogle] = useState<boolean>(false)

  useEffect(() => {
    const handleDocumentMouseDown = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setCalendarToogle(false)
      }
    }
    document.addEventListener("mousedown", handleDocumentMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown)
    }
  }, [])

  return (
    <StyledDiv ref={wrapperRef} onClick={() => setCalendarToogle(true)}>
      <Wrapper>
        <StyledLabel>
          <TextWrapper>
            <Ct.Text
              text={
                value
                  ? `${formatDateFRdate(value[0])} - ${formatDateFRdate(
                      value[1]
                    )}`
                  : intl.formatMessage({
                      id: "input-mask.full-document.periodicity-none",
                    })
              }
              textStyle={{
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "slateGrey",
              }}
            />
            <Ct.Spacer width={0.5} />
            <Ct.Text
              text={""}
              textStyle={{
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "navy",
              }}
            />
          </TextWrapper>
          <SeparatorBar />
          <IconChevronWrapper>
            <StyledChevronSquare rotation={calendarToogle ? 1 : 0} />
          </IconChevronWrapper>
        </StyledLabel>
        <StyledCalendar />
        <CalendarWraper ref={pickerRef} className="periodicity-wrapper">
          <Suspense fallback={<Ct.SpinningLoader />}>
            <CustomProvider locale={locale}>
              <DateRangePicker
                ranges={props.predefinedRanges}
                style={{ width: 160 }}
                value={value}
                isoWeek={true}
                onChange={onChange}
                onClose={() => setCalendarToogle(!calendarToogle)}
                disabledDate={disabledDate}
                placement="bottomEnd"
              />
            </CustomProvider>
          </Suspense>
        </CalendarWraper>
      </Wrapper>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  width: 33rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  background-color: ${colors.white};
  font-weight: 600;
  font-size: 3.25rem;
  box-shadow: ${boxShadow};

  & > div {
    position: relative;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const TextWrapper = styled.div`
  width: 30rem;
  display: flex;
  justify-self: flex-start;
  padding-left: 1rem;
`
const CalendarWraper = styled.div`
  display: flex;
`

const StyledLabel = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  z-index: 10;
  border-radius: 5px;
`

const IconChevronWrapper = styled.div`
  width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-self: end;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const SeparatorBar = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${colors.rock};
`
const StyledChevronSquare = styled(Chevron)<{ rotation: number }>`
  transition-duration: 0.2s;
  transform: ${({ rotation }) => (rotation ? "rotate(180deg)" : ``)};
  fill: ${colors.cornflower};
`

const StyledCalendar = createGlobalStyle`
    .rs-picker-daterange-menu .rs-calendar:first-child  {
      border-right: none;
    }
    
    .rs-picker-daterange {
      color: transparent;
    }

    .rs-picker-daterange .rs-picker-toggle.rs-btn {
      width: 26rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .rs-picker-daterange-menu .rs-btn-link {
      color: ${colors.navy}
    }

    .rs-picker-daterange-menu .rs-btn-primary {
      background: ${gradients.blue};
      font-weight: 500;
    }

    .rs-picker-daterange-menu .rs-picker-daterange-header {
      display: none;
    }

    .periodicity-wrapper .rs-btn-link.rs-btn-disabled,
    .periodicity-wrapper .rs-btn-link:disabled,
    .periodicity-wrapper .rs-btn.rs-btn-disabled,
    .periodicity-wrapper .rs-btn:disabled {
      opacity: 1;
      font-weight: 500;
      cursor: auto;
      color: ${colors.white}
    } 

    .periodicity-wrapper .rs-picker-daterange-calendar-group {
      display: flex;
    }

    .periodicity-wrapper .rs-picker-toggle-textbox {
      display: none ;
      pointer-events: none;
    }

    .periodicity-wrapper .rs-picker-default .rs-picker-toggle {
      border: none;
    }

    
    .periodicity-wrapper .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
      top: 1px;
      right: 50px;

    }
    
    .periodicity-wrapper .rs-picker-toggle .rs-picker-toggle-placeholder {
      color: transparent;

    }

    .periodicity-wrapper .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
      display: none;
    }
    .periodicity-wrapper .rs-picker-toggle-value,
    .periodicity-wrapper .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
      color: transparent;
    }

`
