import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { sizes } from "../../styles/design.config"
import { ValidateWritingThunk } from "../../store/ducks/writings.ducks"
import { Alert } from "../Commons/Alert"
/* eslint-disable camelcase */

interface ValidateWritingProps extends Ct.ModalComponentsProps {
  companyId: number
  writing_ids: number[]
  fiscalYearId: number
}

export const ValidateWritingModal = ({
  isDisplayed,
  onClose,
  companyId,
  writing_ids,
  fiscalYearId,
}: ValidateWritingProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width="109rem">
        <Ct.CloseCross
          onClick={() => {
            onClose()
          }}
        />
        <Ct.Title
          text={intl.formatMessage({
            id: "writings.validation-writing.title",
          })}
        />
        <Ct.Spacer height={4} />

        <Alert alertType="bulb">
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.validation-writing.text1",
            })}
            textStyle={{ lineHeight: 3 }}
          />
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.validation-writing.text2",
            })}
            textStyle={{ lineHeight: 3 }}
          />
          <Ct.Spacer height={4} />
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.validation-writing.text3",
            })}
            textStyle={{ lineHeight: 3 }}
          />
          <Ct.Spacer height={4} />
          <Ct.Text
            text={intl.formatMessage({
              id: "writings.validation-writing.text4",
            })}
            textStyle={{ lineHeight: 3, fontStyle: "italic" }}
          />
        </Alert>

        <Ct.Spacer height={4} />
        <Ct.SpacedBetweenCenter>
          <Ct.Button
            width={sizes.button.standard}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "writings.validation-writing.cancel",
            })}
            onClick={() => {
              onClose()
            }}
          />
          <Ct.Spacer height={4} />
          <Ct.Button
            width={sizes.button.standard}
            label={intl.formatMessage({
              id: "writings.validation-writing.title",
            })}
            onClick={() => {
              dispatch(
                ValidateWritingThunk(companyId, writing_ids, fiscalYearId)
              )
              onClose()
            }}
          />
        </Ct.SpacedBetweenCenter>
      </Ct.Card>
    </Ct.Modal>
  )
}
