import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { DisplayActions, MoreActions } from "../MoreActions"
import { ReactComponent as BankCardPaid } from "../../assets/treasury-bank-card-checked-paid.svg"
import { ReactComponent as FileChecked } from "../../assets/treasury-file-check.svg"
import { ReactComponent as Chevron } from "../../assets/chevron-down-bigger.svg"
import { colors } from "../../styles/design.config"
import { useIntl } from "react-intl"

export interface SelectLikeButtonProps {
  actionId: number
  showActions: boolean
  fullDocumentId: number
  prefix?: "fileChecked" | "bankCardChecked"
  label: string
  width?: string
  fontSize?: number
  actionsToDisplay: DisplayActions[]
  disabledButton: boolean
  disabledSelect: boolean
  onClickSelectAction: () => void
  onClickAction: () => void
  onBlurAction: () => void
  onSetAskValidation?: () => void
  onSetMarkAsPaid?: () => void
  onSetReject?: () => void
  onSetHistory?: () => void
  onEdit?: () => void
  onSetMarkAsToControl?: () => void
  onSetMarkAsIrrecoverable?: () => void
  onSetValidateAndPay?: () => void
  onSetPay?: () => void
  onSetReminder?: () => void
  onDuplicate?: () => void
  canNotMarkAsPaid?: boolean
  canNotValidateAndPay?: boolean
  canNotReject?: boolean
  canNotMarkAsIrrecoverable?: boolean
  canNotPay?: boolean
  canNotAskValidation?: boolean
  canNotMarkAsToControl?: boolean
}

export const SelectLikeButton = ({
  actionId,
  showActions,
  fullDocumentId,
  prefix,
  label,
  width,
  fontSize,
  actionsToDisplay,
  disabledButton,
  disabledSelect,
  onClickAction,
  onClickSelectAction,
  onBlurAction,
  onSetAskValidation,
  onSetMarkAsPaid,
  onSetReject,
  onSetHistory,
  onEdit,
  onSetMarkAsToControl,
  onSetMarkAsIrrecoverable,
  onSetValidateAndPay,
  onSetPay,
  onSetReminder,
  onDuplicate,
  canNotMarkAsPaid,
  canNotValidateAndPay,
  canNotReject,
  canNotMarkAsIrrecoverable,
  canNotPay,
  canNotAskValidation,
  canNotMarkAsToControl,
}: SelectLikeButtonProps) => {
  const intl = useIntl()
  const displayedSuffix = () => {
    switch (prefix) {
      case "fileChecked":
        return (
          <StyledIcon disabled={disabledButton}>
            <FileChecked />
          </StyledIcon>
        )
      case "bankCardChecked":
        return (
          <StyledIcon disabled={disabledButton}>
            <BankCardPaid />
          </StyledIcon>
        )
      default:
        break
    }
  }

  return (
    <CheckSelect width={width || "auto"}>
      <ButtonWrapper disabled={disabledButton}>
        <StyledLabel
          onClick={() => {
            !disabledButton && onClickAction()
          }}
        >
          {displayedSuffix()}
          <Ct.Spacer width={1} />
          <StyledText
            disabled={disabledButton}
            text={intl.formatMessage({ id: label })}
            textStyle={{
              fontSize: fontSize || 1.75,
              color: "white",
              fontStyle: "normal",
              textTransform: "uppercase",
              fontWeight: 500,
            }}
          />
        </StyledLabel>
      </ButtonWrapper>
      <ButtonSeparator />
      <SelectWrapper disabled={disabledSelect} onClick={onClickSelectAction}>
        <SelectIcon>
          <StyledChevronSquare
            onClick={onClickSelectAction}
            disabled={disabledSelect}
          />
        </SelectIcon>
        <MoreActions
          actionsToDisplay={actionsToDisplay}
          showActions={
            !disabledSelect &&
            showActions &&
            actionId !== null &&
            actionId === fullDocumentId
          }
          deleteAction={"hide"}
          onDelete={() => {}}
          onBlur={() => {
            !disabledSelect && onBlurAction()
          }}
          onSetAskValidation={() => {
            !disabledSelect && onSetAskValidation && onSetAskValidation()
          }}
          onSetMarkAsPaid={() => {
            !disabledSelect && onSetMarkAsPaid && onSetMarkAsPaid()
          }}
          onSetMarkAsToControl={() => {
            !disabledSelect && onSetMarkAsToControl && onSetMarkAsToControl()
          }}
          onSetReject={() => {
            !disabledSelect && onSetReject && onSetReject()
          }}
          onSetTreasuryHistoric={() => {
            !disabledSelect && onSetHistory && onSetHistory()
          }}
          onEdit={() => {
            !disabledSelect && onEdit && onEdit()
          }}
          onSetMarkAsIrrecoverable={() => {
            !disabledSelect &&
              onSetMarkAsIrrecoverable &&
              onSetMarkAsIrrecoverable()
          }}
          onSetPay={() => {
            !disabledSelect && onSetPay && onSetPay()
          }}
          onSetValidateAndPay={() => {
            !disabledSelect && onSetValidateAndPay && onSetValidateAndPay()
          }}
          onSetReminder={() => {
            !disabledSelect && onSetReminder && onSetReminder()
          }}
          onDuplicate={() => {
            !disabledSelect && onDuplicate && onDuplicate()
          }}
          customWidth={35}
          top="5rem"
          canNotMarkAsPaid={canNotMarkAsPaid}
          canNotReject={canNotReject}
          canNotMarkAsIrrecoverable={canNotMarkAsIrrecoverable}
          canNotValidateAndPay={canNotValidateAndPay}
          canNotPay={canNotPay}
          canNotAskValidation={canNotAskValidation}
          canNotMarkAsToControl={canNotMarkAsToControl}
        />
      </SelectWrapper>
    </CheckSelect>
  )
}

const SelectWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  background: ${colors.cornflower};
  border-radius: 0 10px 10px 0;
  min-width: 6rem;
  max-width: 6rem;
  justify-content: center;
  align-content: center;
  background-color: ${({ disabled }) =>
    disabled ? colors.desertStorm : colors.cornflower};
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
`

const CheckSelect = styled.div<{ width: string }>`
  display: flex;
  flex-direction: row;
  height: 5rem;
  width: 100%;
  background: ${colors.cornflower};
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  max-width: ${({ width }) => (width ? `${width}rem` : "auto")};
  min-width: ${({ width }) => (width ? `${width}rem` : "auto")};
  position: relative;
`
const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0;
`
const SelectIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ButtonSeparator = styled.div`
  width: 1px;
  background: white;
  height: 100%;
`
const StyledIcon = styled.div<{ disabled: boolean }>`
  width: 3rem;
  height: 3rem;
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};

  & svg {
    width: 100%;
    height: 100%;
  }

  & path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.moon}` : `${colors.white}`};
  }

  /* } */
`
const StyledChevronSquare = styled(Chevron)<{ disabled: boolean }>`
  width: 3rem;
  height: 3rem;

  & path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.moon}` : `${colors.white}`};
  }
`
const StyledText = styled(Ct.Text)<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
  color: ${({ disabled }) => (disabled ? `${colors.moon}` : `${colors.white}`)};
`
const ButtonWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  background: ${colors.cornflower};
  border-radius: 10px 0 0 10px;
  width: 100%;
  justify-content: center;
  align-content: center;
  background-color: ${({ disabled }) =>
    disabled ? colors.desertStorm : colors.cornflower};
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
`
