import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { ButtonsHeader } from "./ButtonsHeader"
import { StyledTopBar } from "./StyledTopBar"

export const ManagementTopBar = () => {
  const intl = useIntl()
  const fiduciaryName = useRNBSelector((state) => state.fiduciary.name)

  return (
    <StyledTopBar>
      <Ct.Column>
        <Ct.Title size={3} text={fiduciaryName} />
        <Ct.Spacer height={0.5} />
        <Ct.Subtitle
          size={2.25}
          text={intl.formatMessage({
            id: "office.list",
          })}
        />
      </Ct.Column>
      <ButtonsHeader />
    </StyledTopBar>
  )
}
