/* eslint-disable camelcase */
import { DeactivatedSettingsKey } from "../store/ducks/companies.ducks"
import { getDineroAmount } from "./money"
import Dinero from "dinero.js"
import { v4 as uuidv4 } from "uuid"

import { CurrencyCode } from "./currencyUtils"
import { AllAccounts, AutoliquidationVat } from "../store/ducks/inputMask.ducks"
import {
  IsOnboardingFinishedType,
  VatAccountPayload,
  VatType,
  vatTypeArray,
} from "../store/ducks/companySettings.ducks"
import { buyOrSell } from "./company"
import { MerchantCode } from "../store/ducks/merchantCodes.ducks"
import { Account } from "../store/ducks/accounts.ducks"
import { FullDocumentForInputMaskWithNewMerchant } from "../components/inputMask/FullDocumentsList"

export type EntryDatumType =
  | "deposit"
  | "reverse_deposit"
  | "tax_excluded"
  | "tax_included"
  | "construction_vat"
  | "eu_reverse_vat"
  | "eu_vat"
  | "eu_goods_reverse_vat"
  | "eu_goods_vat"
  | "world_reverse_vat"
  | "world_vat"
  | "world_goods_reverse_vat"
  | "world_goods_vat"
  | "construction_reverse_vat"
  | "vat"
  | "custom"

export const magicCheckBoxDatumTypes = ["vat", "tax_excluded"] as const
export type MagicCheckBoxDatumTypes = (typeof magicCheckBoxDatumTypes)[number]

export const transferGoodsDatumTypes = [
  "goods_transfer_vat",
  "tobacco_goods_transfer",
  "tobacco_goods_transfer_commission",
  "monetics_goods_transfer",
  "monetics_goods_transfer_commission",
  "scratch_games_goods_transfer",
  "scratch_games_goods_transfer_commission",
  "scratch_games_paid_lots_goods_transfer",
  "draw_games_goods_transfer",
  "draw_games_paid_lots_goods_transfer",
  "draw_games_goods_transfer_commission",
  "tax_stamps_goods_transfer",
  "tax_stamps_goods_transfer_commission",
  "postage_stamps_goods_transfer",
  "postage_stamps_goods_transfer_commission",
  "metro_goods_transfer",
  "metro_goods_transfer_commission",
  "press_goods_transfer",
  "press_goods_transfer_commission",
  "other_goods_transfer",
  "other_goods_transfer_commission",
] as const

export type TransferGoodsDatumType = (typeof transferGoodsDatumTypes)[number]

export const billOfExchangeDatumTypes = [
  "tax_excluded_bill_of_exchange",
  "tax_included_bill_of_exchange",
] as const

export type BillOfExchangeDatumTypes = (typeof billOfExchangeDatumTypes)[number]

export type AllEntryDatumTypes =
  | EntryDatumType
  | TransferGoodsDatumType
  | BillOfExchangeDatumTypes

export type VatRate = 0 | 2.1 | 5.5 | 8.5 | 10 | 13 | 20

export const LINES_TO_ADD = [
  "0",
  "2.1",
  "5.5",
  "8.5",
  "10",
  "13",
  "20",
  "deposit",
  "reverse_deposit",
  "eu_vat",
  "eu_goods_vat",
  "world_vat",
  "world_goods_vat",
  "construction_vat",
  "custom_debit",
  "custom_credit",
  "tax_excluded_bill_of_exchange",
] as const

export type CreditOrDebit = "credit" | "debit"

type LineToAddTuple = typeof LINES_TO_ADD
export type LineToAdd = LineToAddTuple[number]

type SourceType =
  | "human"
  | "ocr_mark_1"
  | "ocr_triplet"
  | "ocr_amount"
  | "input_mask"

export const autoliquidatedVatTypes = [
  "eu_vat",
  "eu_goods_vat",
  "world_vat",
  "world_goods_vat",
  "construction_vat",
]

export interface WritingLine {
  amount: string
  datum_type: AllEntryDatumTypes
  writing_line_uuid: string
  direction: CreditOrDebit
  account: WritingLineAccount | null
  source: SourceType
  currency_code: CurrencyCode
  vat_rate: VatRate
}

export interface WritingLinePayload {
  amount: string
  merchant_code_id: number | null
  datum_type: AllEntryDatumTypes
  writing_line_uuid: string
  direction: CreditOrDebit
  account: WritingLineAccount | null
  source: SourceType
  currency_code: CurrencyCode
  currency_amount: number | null
  exchange_rate: number | null
  exchange_rate_date: string | null
  vat_rate: string
  is_fuel_vat_line: boolean
}

interface WritingLineAccount {
  id: number
  number: string
  details: string
}

export type WritingLines = WritingLine[]

export interface Document {
  id: number
  batch_document_id: number
  created_at: Date
  updated_at: Date
  file_name: string
  page_number: number
  full_document_id: number
  page_number_in_full_document: number
  preview_file_name: string
}

export type DocumentType = "invoice" | "credit_note" | "bill_of_exchange"

export interface FullDocumentForInputMask {
  documents: Document[]
  fd_id: number
  document_type: DocumentType
  buy_or_sell: "buy" | "sell"
  fd_status: string
  journal_code: string
  periodicity_date_start: string | null
  periodicity_date_end: string | null
  fd_document_date: string
  fd_document_due_date: string | null
  fd_document_reference: string | null
  legal_entity_id: number
  fd_user_file_name: string
  original_file_name: string
  merchant_code: string
  merchant_code_id: number
  merchant_name: string
  merchant_id: number
  writing_lines: WritingLinePayload[]
  merchant_buy_default_account_id: number | null
  merchant_buy_vat_default_account_id: number | null
  merchant_sell_default_account_id: number | null
  merchant_sell_vat_default_account_id: number | null
  goods_or_services: "goods" | "services" | null
  document_source: "fr" | "eu" | "world" | null
  previously_deactivated_because: DeactivatedSettingsKey | null
}

export interface DeactivatedDocumentInWritingForInputMask {
  documents: Document[]
  batch_document_id: number
  buy_or_sell: null
  deactivated_because: DeactivatedSettingsKey
  document_type: null
  fd_created_at: Date
  fd_id: number
  fd_status: "deactivated"
  original_batch_name: string
  user_batch_name: null | string
  is_being_reactivated?: boolean
}

export function getLinesOptions(document_type: DocumentType) {
  if (document_type === "bill_of_exchange") {
    return ["tax_excluded_bill_of_exchange"] as const
  }

  return LINES_TO_ADD.filter((l) => l !== "tax_excluded_bill_of_exchange")
}

export function isFullDocumentDeactivated(
  fullDocument:
    | DeactivatedDocumentInWritingForInputMask
    | FullDocumentForInputMask
): fullDocument is DeactivatedDocumentInWritingForInputMask {
  return (
    (fullDocument as DeactivatedDocumentInWritingForInputMask)
      .deactivated_because !== undefined
  )
}

export const generateEmptyTaxIncluded = (): WritingLinePayload[] => {
  return [
    {
      amount: "0.00",
      datum_type: "tax_included",
      writing_line_uuid: uuidv4(),
      direction: "credit",
      account: null,
      source: "input_mask",
      currency_code: "EUR",
      currency_amount: 0,
      vat_rate: "0",
      exchange_rate: null,
      exchange_rate_date: null,
      merchant_code_id: null,
      is_fuel_vat_line: false,
    },
  ]
}

export const updateAmount = ({
  newAmount,
  newCurrencyAmount,
  writingLine,
  existingLines,
}: {
  newAmount: string
  newCurrencyAmount: string
  writingLine: WritingLinePayload
  existingLines: WritingLinePayload[]
}) => {
  const modifiedLines = [...existingLines].map((l) => ({
    ...l,
    currency_code: "EUR" as CurrencyCode,
  }))
  const lineToModifyIndex = modifiedLines.findIndex(
    (l) =>
      l.writing_line_uuid === writingLine.writing_line_uuid &&
      l.datum_type === writingLine.datum_type
  )
  if (lineToModifyIndex === -1) {
    return modifiedLines
  }

  modifiedLines[lineToModifyIndex].amount = newAmount.replace(",", ".")
  modifiedLines[lineToModifyIndex].currency_amount =
    writingLine.source === "input_mask"
      ? Number(newCurrencyAmount.replace(",", "."))
      : writingLine.currency_amount

  if (writingLine.datum_type === "tax_excluded") {
    const newAmountForVat = ["", ","].includes(newAmount) ? "0" : newAmount
    const taxIncludedAmount = getDineroAmount(newAmountForVat)

    const associatedVatLines = modifiedLines.filter(
      (l) =>
        l.writing_line_uuid === writingLine.writing_line_uuid &&
        [
          "vat",
          "construction_vat",
          "eu_reverse_vat",
          "eu_vat",
          "eu_goods_reverse_vat",
          "eu_goods_vat",
          "world_reverse_vat",
          "world_vat",
          "world_goods_reverse_vat",
          "world_goods_vat",
          "construction_reverse_vat",
        ].includes(l.datum_type)
    )

    associatedVatLines.forEach((vatLine) => {
      const lineIndex = modifiedLines.findIndex(
        (l) =>
          l.writing_line_uuid === writingLine.writing_line_uuid &&
          l.datum_type === vatLine.datum_type
      )

      let vatRate = 0
      if (modifiedLines[lineIndex].vat_rate !== null) {
        vatRate = Number(modifiedLines[lineIndex].vat_rate) / 100
      } else {
        vatRate = Number("0.2")
      }

      const newVatAmount = taxIncludedAmount.multiply(vatRate).toUnit()
      modifiedLines[lineIndex].amount = newVatAmount.toString()
    })
  }

  const updatedLines = updateTaxIncluded(modifiedLines)

  return updatedLines
}

export const increaseCents = ({
  writingLine,
  existingLines,
}: {
  writingLine: WritingLinePayload
  existingLines: WritingLinePayload[]
}) => {
  const existingAmount = getDineroAmount(writingLine.amount)
  const oneCent = Dinero({ amount: 1 })
  const newAmount = existingAmount
    .add(oneCent)
    .toUnit()
    .toString()
    .replace(",", ".")

  const modifiedLines = [...existingLines]
  const lineToModifyIndex = modifiedLines.findIndex(
    (l) =>
      l.writing_line_uuid === writingLine.writing_line_uuid &&
      l.datum_type === writingLine.datum_type
  )
  if (lineToModifyIndex === -1) {
    return modifiedLines
  }

  modifiedLines[lineToModifyIndex].amount = newAmount.replace(",", ".")

  if (vatTypeArray.includes(writingLine.datum_type as VatType)) {
    const oppositeVatType = writingLine.datum_type.includes("_reverse_vat")
      ? writingLine.datum_type.replace("_reverse_vat", "_vat")
      : writingLine.datum_type.replace("_vat", "_reverse_vat")

    const lineToModifyIndex = modifiedLines.findIndex(
      (l) =>
        l.writing_line_uuid === writingLine.writing_line_uuid &&
        l.datum_type === oppositeVatType
    )
    modifiedLines[lineToModifyIndex].amount = newAmount.replace(",", ".")
  }

  const updatedLines = updateTaxIncluded(modifiedLines)

  return updatedLines
}

export const decreaseCents = ({
  writingLine,
  existingLines,
}: {
  writingLine: WritingLinePayload
  existingLines: WritingLinePayload[]
}) => {
  const existingAmount = getDineroAmount(writingLine.amount)
  const oneCent = Dinero({ amount: 1 })
  const newAmount = existingAmount
    .subtract(oneCent)
    .toUnit()
    .toString()
    .replace(",", ".")

  const modifiedLines = [...existingLines]
  const lineToModifyIndex = modifiedLines.findIndex(
    (l) =>
      l.writing_line_uuid === writingLine.writing_line_uuid &&
      l.datum_type === writingLine.datum_type
  )
  if (lineToModifyIndex === -1 || Number(newAmount) < 0) {
    return modifiedLines
  }

  modifiedLines[lineToModifyIndex].amount = newAmount.replace(",", ".")

  if (vatTypeArray.includes(writingLine.datum_type as VatType)) {
    const oppositeVatType = writingLine.datum_type.includes("_reverse_vat")
      ? writingLine.datum_type.replace("_reverse_vat", "_vat")
      : writingLine.datum_type.replace("_vat", "_reverse_vat")

    const lineToModifyIndex = modifiedLines.findIndex(
      (l) =>
        l.writing_line_uuid === writingLine.writing_line_uuid &&
        l.datum_type === oppositeVatType
    )
    modifiedLines[lineToModifyIndex].amount = newAmount.replace(",", ".")
  }

  const updatedLines = updateTaxIncluded(modifiedLines)

  return updatedLines
}

export const updateTaxIncluded = (modifiedLines: WritingLinePayload[]) => {
  const updatedLines = [...modifiedLines]

  let newTotal = Dinero({ amount: 0 })
  let reverseDepositTotal = Dinero({ amount: 0 })

  const direction = updatedLines[updatedLines.length - 1].direction

  updatedLines.forEach((l, index) => {
    if (index !== updatedLines.length - 1) {
      const lineAmount = getDineroAmount(l.amount)
      if (l.direction === direction) {
        newTotal = newTotal.subtract(lineAmount)
        if (l.datum_type === "reverse_deposit") {
          reverseDepositTotal = reverseDepositTotal.add(lineAmount)
        }
      } else {
        newTotal = newTotal.add(lineAmount)
      }
    } else {
      // tax_included
      const newTotalFormatted = newTotal.toUnit().toString()
      l.amount = newTotalFormatted.includes(".")
        ? newTotalFormatted
        : `${newTotalFormatted}.00`
      if (newTotal.lessThan(Dinero({ amount: 0 }))) {
        l.direction = l.direction === "credit" ? "debit" : "credit"
        l.amount = l.amount.replace("-", "")
      }
    }
  })

  return updatedLines
}

export interface VatAccountsWithRate extends VatAccountPayload {
  vat_rate: string // be careful, "10.0" instead of "10"
}

interface AccountsConfiguration {
  merchant_buy_default_account_id: number | null
  merchant_buy_vat_default_account_id: number | null
  merchant_sell_default_account_id: number | null
  merchant_sell_vat_default_account_id: number | null
  vat_accounts: VatAccountsWithRate[]
  buy_or_sell: "buy" | "sell"
}

const getDefaultTaxExcludedDirection = (
  documentType: DocumentType,
  buyOrSell: buyOrSell
) => {
  switch (documentType) {
    case "invoice":
      return buyOrSell === "buy" ? "debit" : "credit"
    case "credit_note":
      return buyOrSell === "buy" ? "credit" : "debit"
    case "bill_of_exchange":
      return buyOrSell === "buy" ? "debit" : "credit"
  }
}

export const createWritingLine = ({
  existingLines,
  selectedOption,
  possibleAccounts,
  accountsConfiguration,
  documentType,
  buyThirdPartyAccount,
  autoliquidation_vat_rates,
}: {
  existingLines: WritingLinePayload[]
  selectedOption: LineToAdd
  possibleAccounts: AllAccounts
  accountsConfiguration: AccountsConfiguration
  documentType: DocumentType
  buyThirdPartyAccount: Account | null
  autoliquidation_vat_rates: AutoliquidationVat
}) => {
  const taxIncludedLine = existingLines.pop() as WritingLinePayload

  const defaultTaxExcludedDirection = getDefaultTaxExcludedDirection(
    documentType,
    accountsConfiguration.buy_or_sell
  )
  const { linesToAdd, newLineUuid } = getLinesToAdd({
    existingLines,
    selectedOption,
    currencyCode: "EUR",
    direction: defaultTaxExcludedDirection,
    possibleAccounts,
    accountsConfiguration,
    buyThirdPartyAccount,
    autoliquidation_vat_rates: autoliquidation_vat_rates,
  })
  return {
    newLineUuid,
    modifiedLines: [...existingLines, ...linesToAdd, taxIncludedLine],
  }
}

const getLinesToAdd = ({
  existingLines,
  selectedOption,
  currencyCode,
  direction,
  possibleAccounts,
  accountsConfiguration,
  buyThirdPartyAccount,
  autoliquidation_vat_rates,
}: {
  existingLines: WritingLinePayload[]
  selectedOption: LineToAdd
  currencyCode: CurrencyCode
  direction: CreditOrDebit
  possibleAccounts: AllAccounts
  accountsConfiguration: AccountsConfiguration
  buyThirdPartyAccount: Account | null
  autoliquidation_vat_rates: AutoliquidationVat
}): { linesToAdd: WritingLinePayload[]; newLineUuid: string } => {
  const newLineUuid = uuidv4()

  const vatOptions: LineToAdd[] = ["2.1", "5.5", "8.5", "10", "13", "20"]
  const exchange_rate = existingLines[0]?.exchange_rate
  const exchange_rate_date = existingLines[0]?.exchange_rate_date
  const currency_amount = 0
  if (vatOptions.includes(selectedOption)) {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: getTaxExcludedAccount({
            accountsConfiguration,
            possibleAccounts,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "tax_excluded",
          direction,
          vat_rate: selectedOption,
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
        {
          account: getVatAccount({
            accountsConfiguration,
            possibleAccounts,
            selectedOption: Number(selectedOption) as VatRate,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "vat",
          direction,
          vat_rate: selectedOption,
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (selectedOption === "0") {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: getTaxExcludedAccount({
            accountsConfiguration,
            possibleAccounts,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "tax_excluded",
          direction,
          vat_rate: "0",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (selectedOption === "custom_credit") {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: getTaxExcludedAccount({
            accountsConfiguration,
            possibleAccounts,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "custom",
          direction: "credit",
          vat_rate: "0",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (selectedOption === "custom_debit") {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: getTaxExcludedAccount({
            accountsConfiguration,
            possibleAccounts,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "custom",
          direction: "debit",
          vat_rate: "0",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (selectedOption === "deposit") {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: possibleAccounts.deposit[0],
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "deposit",
          direction,
          vat_rate: "0",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (selectedOption === "reverse_deposit") {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: possibleAccounts.reverse_deposit[0],
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "reverse_deposit",
          direction: direction === "credit" ? "debit" : "credit",
          vat_rate: "0",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (autoliquidatedVatTypes.includes(selectedOption)) {
    const vat = selectedOption as keyof AutoliquidationVat
    const reverseVat = selectedOption.replace(
      "_vat",
      "_reverse_vat"
    ) as keyof AutoliquidationVat
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: getTaxExcludedAccount({
            accountsConfiguration,
            possibleAccounts,
          }),
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "tax_excluded",
          direction,
          vat_rate: String(autoliquidation_vat_rates[vat]),
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
        {
          account: possibleAccounts[vat][0],
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: vat as AllEntryDatumTypes,
          direction,
          vat_rate: String(autoliquidation_vat_rates[vat]),
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
        {
          account: possibleAccounts[reverseVat][0],
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: reverseVat as AllEntryDatumTypes,
          direction: direction === "credit" ? "debit" : "credit",
          vat_rate: String(
            autoliquidation_vat_rates[vat as keyof AutoliquidationVat]
          ),
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  if (
    selectedOption === "tax_excluded_bill_of_exchange" &&
    buyThirdPartyAccount
  ) {
    return {
      newLineUuid,
      linesToAdd: [
        {
          account: buyThirdPartyAccount,
          writing_line_uuid: newLineUuid,
          amount: "0",
          currency_code: currencyCode,
          exchange_rate: exchange_rate,
          exchange_rate_date: exchange_rate_date,
          currency_amount: currency_amount,
          datum_type: "tax_excluded_bill_of_exchange",
          direction,
          vat_rate: "",
          source: "input_mask",
          merchant_code_id: null,
          is_fuel_vat_line: false,
        },
      ],
    }
  }

  return {
    newLineUuid: "",
    linesToAdd: [],
  }
}

const getTaxExcludedAccount = ({
  accountsConfiguration,
  possibleAccounts,
}: {
  accountsConfiguration: AccountsConfiguration
  possibleAccounts: AllAccounts
}): Account | null => {
  if (accountsConfiguration.buy_or_sell === "buy") {
    const accountId = accountsConfiguration.merchant_buy_default_account_id
    return possibleAccounts.tax_excluded.find((a) => a.id === accountId) || null
  }

  if (accountsConfiguration.buy_or_sell === "sell") {
    const accountId = accountsConfiguration.merchant_sell_default_account_id
    return possibleAccounts.tax_excluded.find((a) => a.id === accountId) || null
  }

  return null
}

const getVatAccount = ({
  accountsConfiguration,
  possibleAccounts,
  selectedOption,
}: {
  accountsConfiguration: AccountsConfiguration
  possibleAccounts: AllAccounts
  selectedOption: VatRate
}): Account | null => {
  if (accountsConfiguration.buy_or_sell === "buy") {
    const accountId = accountsConfiguration.merchant_buy_vat_default_account_id
    const account = possibleAccounts.vat.find((a) => a.id === accountId)
    if (account) {
      return account
    }
  }

  if (accountsConfiguration.buy_or_sell === "sell") {
    const accountId = accountsConfiguration.merchant_sell_vat_default_account_id
    const account = possibleAccounts.vat.find((a) => a.id === accountId)
    if (account) {
      return account
    }
  }

  const accountByRate = accountsConfiguration.vat_accounts.find(
    (a) =>
      Number(a.vat_rate) === Number(selectedOption) && // convert in number to avoid "10.0" != "10"
      accountsConfiguration.buy_or_sell === a.buy_or_sell
  )
  if (accountByRate) {
    const account = possibleAccounts.vat.find(
      (a) => a.id === accountByRate.account_id
    )
    if (account) {
      return account
    }
  }

  const generalAccount = accountsConfiguration.vat_accounts.find(
    (a) =>
      a.vat_rate_id === null &&
      accountsConfiguration.buy_or_sell === a.buy_or_sell
  )
  if (generalAccount) {
    const account = possibleAccounts.vat.find(
      (a) => a.id === generalAccount.account_id
    )
    if (account) {
      return account
    }
  }

  return null
}

export const isMagicCheckBoxDatumType = (
  datumType: AllEntryDatumTypes
): datumType is MagicCheckBoxDatumTypes => {
  return magicCheckBoxDatumTypes.includes(datumType as MagicCheckBoxDatumTypes)
}

const isTransferGoods = (
  datumType: AllEntryDatumTypes
): datumType is TransferGoodsDatumType => {
  return transferGoodsDatumTypes.includes(datumType as TransferGoodsDatumType)
}

export const getOptionsInfo = ({
  option,
  editedWritingLines,
  buyOrSell,
  possibleAccounts,
  companyOnboarding,
}: {
  option: LineToAdd
  editedWritingLines: WritingLinePayload[] | []
  buyOrSell: "buy" | "sell" | undefined
  possibleAccounts: AllAccounts
  companyOnboarding: IsOnboardingFinishedType
}): { isOptionEnabled: boolean; tooltipKey?: string } => {
  if (companyOnboarding === "unfinished")
    return {
      isOptionEnabled: false,
      tooltipKey: "input-mask.select-add.tooltip.onboarding-unfinished",
    }

  if (buyOrSell === undefined) return { isOptionEnabled: false }

  const customOptions: LineToAdd[] = ["custom_credit", "custom_debit"]

  if (customOptions.includes(option)) return { isOptionEnabled: true }

  // buralistes : block adding new lines
  if (
    editedWritingLines.some((l) => {
      return isTransferGoods(l.datum_type)
    })
  )
    return { isOptionEnabled: false }

  if (option === "deposit" && possibleAccounts.deposit.length === 0)
    return {
      isOptionEnabled: false,
      tooltipKey: "input-mask.select-add.tooltip.missing-account",
    }

  if (
    option === "reverse_deposit" &&
    possibleAccounts.reverse_deposit.length === 0
  )
    return {
      isOptionEnabled: false,
      tooltipKey: "input-mask.select-add.tooltip.missing-account",
    }

  const vatTypeOption = option
  const reverseVatTypeOption = option.replace("_vat", "_reverse_vat")

  if (autoliquidatedVatTypes.includes(option)) {
    if (buyOrSell === "sell")
      return {
        isOptionEnabled: false,
        tooltipKey: "input-mask.select-add.tooltip.autoliquidation-sell",
      }

    if (
      possibleAccounts[vatTypeOption as keyof AllAccounts]?.length === 0 ||
      possibleAccounts[reverseVatTypeOption as keyof AllAccounts]?.length === 0
    )
      return {
        isOptionEnabled: false,
        tooltipKey: "input-mask.select-add.tooltip.missing-vat-account",
      }

    if (
      !editedWritingLines
        .filter((l) => l.datum_type === "tax_excluded")
        .every((taxEcludedLine) =>
          editedWritingLines.find((l) => {
            return (
              l.writing_line_uuid === taxEcludedLine.writing_line_uuid &&
              String(l.datum_type) === String(option)
            )
          })
        )
    )
      return {
        isOptionEnabled: false,
        tooltipKey: "input-mask.select-add.tooltip.multiple-vat",
      }

    const conditions = [
      "deposit",
      "reverse_deposit",
      "construction_vat",
      "construction_reverse_vat",
      "vat",
      "eu_vat",
      "eu_reverse_vat",
      "eu_goods_vat",
      "eu_goods_reverse_vat",
      "world_vat",
      "world_reverse_vat",
      "world_goods_vat",
      "world_goods_reverse_vat",
    ].filter((item) => item !== vatTypeOption && item !== reverseVatTypeOption)

    const isOptionEnabled = !editedWritingLines.some((l) =>
      conditions.includes(l.datum_type)
    )
    return {
      isOptionEnabled,
      tooltipKey: !isOptionEnabled
        ? "input-mask.select-add.tooltip.multiple-vat"
        : undefined,
    }
  }

  const isOptionEnabled = !editedWritingLines.some((l) => {
    return [
      "construction_vat",
      "eu_vat",
      "eu_goods_vat",
      "world_vat",
      "world_goods_vat",
    ].includes(l.datum_type)
  })

  return {
    isOptionEnabled,
    tooltipKey: !isOptionEnabled
      ? "input-mask.select-add.tooltip.multiple-vat"
      : undefined,
  }
}

interface GetDisplayedCode {
  buyOrSell: buyOrSell
  merchantCode?: MerchantCode
  buyAuxiliaryPrefix: string
  sellAuxiliaryPrefix: string
}
export const getDisplayedCode = ({
  buyOrSell,
  merchantCode,
  buyAuxiliaryPrefix,
  sellAuxiliaryPrefix,
}: GetDisplayedCode) => {
  if (!merchantCode) return ""

  const codePrefix =
    buyOrSell === "buy" ? buyAuxiliaryPrefix : sellAuxiliaryPrefix

  return `${codePrefix}${merchantCode.code}`
}

interface GetDisplayedBillOfExchangeCode {
  writingLine: WritingLinePayload
  buyOrSell: buyOrSell
  merchantCode: MerchantCode | null
  buyAuxiliaryPrefix: string
  sellAuxiliaryPrefix: string
  buyThirdPartyAccounNumber: string | undefined
}

export const getDisplayedBillOfExchangeCode = ({
  writingLine,
  buyOrSell,
  merchantCode,
  buyAuxiliaryPrefix,
  sellAuxiliaryPrefix,
  buyThirdPartyAccounNumber,
}: GetDisplayedBillOfExchangeCode) => {
  if (!merchantCode) return ""

  const codePrefix =
    buyOrSell === "buy" ? buyAuxiliaryPrefix : sellAuxiliaryPrefix

  if (merchantCode.auxiliary) {
    return `${
      writingLine.account?.number || buyThirdPartyAccounNumber
    }, ${codePrefix}${merchantCode.code}`
  } else {
    const prefix = buyOrSell === "buy" ? "401" : "411"
    return `${prefix}${codePrefix}${merchantCode.code}`
  }
}

interface GetDisplayedNumber {
  writingLines: WritingLinePayload[]
  buyOrSell: buyOrSell
  merchantCode?: MerchantCode
  buyAuxiliaryPrefix: string
  sellAuxiliaryPrefix: string
}

export const getDisplayedNumber = ({
  writingLines,
  buyOrSell,
  merchantCode,
  buyAuxiliaryPrefix,
  sellAuxiliaryPrefix,
}: GetDisplayedNumber) => {
  if (!merchantCode) return ""
  const taxIncludedWritingLineAccount = writingLines.find(
    (l) =>
      l.datum_type === "tax_included" ||
      l.datum_type === "tax_included_bill_of_exchange"
  )?.account

  if (!taxIncludedWritingLineAccount) return ""

  const codePrefix =
    buyOrSell === "buy" ? buyAuxiliaryPrefix : sellAuxiliaryPrefix

  if (merchantCode.auxiliary) {
    return `${taxIncludedWritingLineAccount.number} ${taxIncludedWritingLineAccount.details} (${codePrefix}${merchantCode.code})`
  } else {
    const prefix = buyOrSell === "buy" ? "401" : "411"
    return `${prefix}${codePrefix}${merchantCode.code}`
  }
}

interface ChangeBuyOrSell {
  taxIncludedAccount: Account | undefined
  direction: CreditOrDebit
  currencyCode: CurrencyCode
  exchange_rate: number | null
  exchange_rate_date: string | null
  currency_amount: number | null
}

export const changeBuyOrSell = ({
  taxIncludedAccount,
  direction,
  currencyCode,
  exchange_rate,
  exchange_rate_date,
  currency_amount,
}: ChangeBuyOrSell): WritingLinePayload[] => {
  if (!taxIncludedAccount) {
    return []
  }
  const newLineUuid = uuidv4()

  return [
    {
      amount: "0.00",
      datum_type: "tax_included",
      writing_line_uuid: newLineUuid,
      direction,
      account: taxIncludedAccount,
      source: "input_mask",
      currency_code: currencyCode,
      vat_rate: "0",
      exchange_rate: exchange_rate,
      exchange_rate_date: exchange_rate_date,
      currency_amount: currency_amount,
      merchant_code_id: null,
      is_fuel_vat_line: false,
    },
  ]
}

interface ChangeDocumentType {
  currentWritingLines: WritingLinePayload[]
}

export const changeDocumentType = ({
  currentWritingLines,
}: ChangeDocumentType): WritingLinePayload[] => {
  return currentWritingLines.map((l) => ({
    ...l,
    direction: l.direction === "credit" ? "debit" : "credit",
  }))
}

interface UpdatedAmout {
  newAmount: string
  newCurrencyAmount: string
}

export const getAmountFromUserInput = ({
  userInput,
  exchangeRate,
}: {
  userInput: string
  exchangeRate: number | null
}): UpdatedAmout | null => {
  if (userInput === "") {
    return {
      newAmount: "0",
      newCurrencyAmount: "0",
    }
  }
  const matchUnAuthorizedCharacter = userInput.match(/^[\d+\-,.()*/]+$/)

  if (!matchUnAuthorizedCharacter) {
    return null
  }

  let userInputWithoutComma = userInput.replace(/,/g, ".")
  userInputWithoutComma =
    userInputWithoutComma[0] === "0" && userInputWithoutComma.length > 1
      ? userInputWithoutComma.substring(1)
      : userInputWithoutComma

  let evaluatedAmount

  try {
    // eslint-disable-next-line no-eval
    evaluatedAmount = eval(userInputWithoutComma)
  } catch {
    try {
      if (
        ["/", "+", "-", "*", "(", ")"].some((operand) =>
          userInput.endsWith(operand)
        )
      ) {
        const removedLast = userInput
          .substring(0, userInput.length - 1)
          .replace(/,/g, ".")

        try {
          // eslint-disable-next-line no-eval
          evaluatedAmount = (0, eval)(removedLast)
        } catch {
          try {
            const removed2Last = ["/", "+", "-", "*"].includes(
              removedLast[removedLast.length - 1]
            )
              ? removedLast
                  .substring(0, removedLast.length - 1)
                  .replace(/,/g, ".")
              : removedLast

            // eslint-disable-next-line no-eval
            evaluatedAmount = (0, eval)(removed2Last.replace(/[()]/g, ""))
          } catch {
            return null
          }
        }
      } else {
        try {
          // eslint-disable-next-line no-eval
          evaluatedAmount = (0, eval)(
            userInputWithoutComma.replace(/[()]/g, "")
          )
        } catch {
          return null
        }
      }
    } catch {
      return null
    }
  }

  if (!isFinite(evaluatedAmount)) {
    return null
  }
  const newAmountNumber = parseFloat(String(evaluatedAmount.toFixed(2)))
  const newCurrencyAmount = exchangeRate
    ? (exchangeRate * newAmountNumber).toFixed(2)
    : ""

  return {
    newAmount: String(newAmountNumber),
    newCurrencyAmount,
  }
}

export const getGroupedLines = (sortedWritingLines: WritingLinePayload[]) => {
  const grouped = sortedWritingLines.reduce<WritingLinePayload[][]>(
    (groups: WritingLinePayload[][], line: WritingLinePayload) => {
      const lastGroup = groups[groups.length - 1]
      if (
        lastGroup.some(
          (l: WritingLinePayload) =>
            l.writing_line_uuid === line.writing_line_uuid
        )
      ) {
        let groupToChange = groups.pop() || []
        const returnValue = [...groups, [...groupToChange, line]]
        return returnValue
      }
      const returnValue = [...groups, [line]]
      return returnValue
    },
    [[]]
  )

  grouped.shift()
  return grouped
}

export type AlreadySeenFilterOptions =
  | "input-mask.filter-dropdown.all"
  | "input-mask.filter-dropdown.not-seen"
  | "input-mask.filter-dropdown.already-seen"

export const filterAlreadySeenDocuments = ({
  sortedDocuments,
  alreadySeenFilterOption,
}: {
  sortedDocuments: FullDocumentForInputMaskWithNewMerchant[]
  alreadySeenFilterOption: AlreadySeenFilterOptions
}) => {
  const alreadySeenFullDocuments: number[] = JSON.parse(
    localStorage.getItem("seenFullDocuments") || "[]"
  )

  switch (alreadySeenFilterOption) {
    case "input-mask.filter-dropdown.all":
      return sortedDocuments
    case "input-mask.filter-dropdown.already-seen":
      return sortedDocuments.filter((d) =>
        alreadySeenFullDocuments.includes(d.fd_id)
      )
    case "input-mask.filter-dropdown.not-seen":
      return sortedDocuments.filter(
        (d) => !alreadySeenFullDocuments.includes(d.fd_id)
      )
  }
}
