export const sum = (numbers: number[]) =>
  numbers.reduce((total, aNumber) => total + aNumber, 0)

export const average = (numbers: number[]) => sum(numbers) / numbers.length

export const regexPriceWithComma = RegExp(/^[+]?([,|.]\d+|\d+([,|.]\d+)?)$/)

export function toFixed(num: number, fixed: number) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?")
  const matched = num.toString().match(re)
  if (matched) {
    const fixedNumberResult = matched[0]
    return fixedNumberResult
  }
  return ""
}
