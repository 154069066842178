import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { DateTime } from "luxon"
import { TreasuryUserHistory } from "../../store/ducks/treasury.ducks"
import { sizes } from "../../styles/design.config"

interface FullDocumentHistoryModalProps extends Ct.ModalComponentsProps {
  userEmail: string
  userHistory: TreasuryUserHistory[] | null
}

/* eslint-disable camelcase */
export const UserTreasurSettingsyHistoryModal = ({
  isDisplayed,
  onClose,
  userHistory,
  userEmail,
}: FullDocumentHistoryModalProps) => {
  const intl = useIntl()

  const close = () => {
    onClose()
  }

  const getHistoricValue = (history: TreasuryUserHistory) => {
    if (history.event_type === "invit_user")
      return intl.formatMessage({
        id: `treasury.user.history.modal.invit_user`,
      })
    if (
      history.event_type === "validation_payment_limit" ||
      history.event_type === "payment_limit"
    ) {
      if (history.metadata.new_value === "none")
        return intl.formatMessage({
          id: `treasury.user.history.modal.${history.event_type}.remove`,
        })
      const oldValue =
        history.metadata.old_value === "none"
          ? "Aucun"
          : history.metadata.old_value === "all"
          ? "Tous"
          : history.metadata.old_value
      const newValue =
        history.metadata.new_value === "all"
          ? "Tous"
          : history.metadata.new_value
      return intl.formatMessage(
        { id: `treasury.user.history.modal.${history.event_type}` },
        { oldValue, newValue }
      )
    }
    const boolean =
      String(history.metadata.new_value) === "true" ? "true" : "false"
    return intl.formatMessage({
      id: `treasury.user.history.modal.${history.event_type}.${boolean}`,
    })
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="calc(50vh - 45rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Title
          text={intl.formatMessage({ id: "treasury.user.history.modal.title" })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={userEmail}
              textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
            />
          </ChangeHeader>

          {userHistory && userHistory.length > 0 ? (
            <ChangesList>
              {userHistory.map((history) => (
                <ChangeItem key={history.id}>
                  <Ct.Text text={getHistoricValue(history)} />

                  <Mailto href={`mailto:${history.current_user_email}`}>
                    {history.current_user_email}
                  </Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(
                        new Date(history.updated_at)
                      ).toFormat("dd/MM/yyyy HH:mm:ss") +
                      ")"
                    }
                  />
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `treasury.user.history.modal.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={close}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
