import { ReactComponent as BankCardPaidIcon } from "../../../../assets/treasury-bank-card-checked-paid.svg"
import { ReactComponent as ToReceiveHourGlass } from "../../../../assets/treasury-hourglass.svg"
import { ReactComponent as IrrecoverableCross } from "../../../../assets/treasury-cross.svg"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { isRouteSelected } from "../../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"
import { useIntl } from "react-intl"
import { useEffect } from "react"
import {
  getCurrentUserPermissionsThunk,
  getUsersWithPermissions,
} from "../../../../store/ducks/treasury.ducks"
import { getIdFromParams } from "../../../../utils/company"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../../store/rootReducer"

export const TreasurySettings = () => {
  const intl = useIntl()
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/invoicing\\/settings"
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )
  const navigate = useNavigate()
  const getCurrentUserPermissionsStatus = useRNBSelector(
    (state) => state.treasury.currentUserPermissionStatus
  )

  useEffect(() => {
    if (
      getCurrentUserPermissionsStatus === "idle" ||
      getCurrentUserPermissionsStatus === "loading"
    )
      return
    if (
      getCurrentUserPermissionsStatus === "success" &&
      (currentUserPermissions.marked_as_appearing_in_permissions_list ===
        true ||
        currentUserTypology === "customer" ||
        currentUserTypology === "customer_accountant")
    )
      return
    navigate("/unauthorized")
  }, [
    getCurrentUserPermissionsStatus,
    currentUserPermissions,
    currentUserTypology,
  ])

  useEffect(() => {
    dispatch(getUsersWithPermissions(companyId))
    dispatch(getCurrentUserPermissionsThunk(companyId))
  }, [dispatch])

  const linkTabs: TabObject[] = [
    {
      to: "buy",
      title: intl.formatMessage({ id: "menu.treasury.settings.buy" }),
      icon: <ToReceiveHourGlass />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "buy"),
    },
    {
      to: "sell",
      title: intl.formatMessage({ id: "menu.treasury.settings.sell" }),
      icon: <BankCardPaidIcon />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "sell"),
    },
    {
      to: "auto_validation",
      title: intl.formatMessage({
        id: "menu.treasury.settings.auto_validation",
      }),
      icon: <IrrecoverableCross />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "auto_validation"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
        Link={Link}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}
