import { useIntl } from "react-intl"
import {
  ModalComponentsProps,
  Modal,
  Title,
  Spacer,
  Text,
  TableHeader,
  Button,
  Alert,
} from "ldlj"
import {} from "../store/ducks/companies.ducks"
import styled from "styled-components/macro"
import { ReactComponent as CloseCrossSVG } from "../assets/close-cross.svg"
import { colors } from "../styles/design.config"
import { useRNBSelector } from "../store/rootReducer"
import { DateTime } from "luxon"
import { Account, AccountInstruction } from "../store/ducks/accounts.ducks"

interface AccountInstructionsHistoryModalProps extends ModalComponentsProps {
  accountSelected: Account
}

export const AccountInstructionsHistoryModal = ({
  onClose,
  isDisplayed,
  accountSelected,
}: AccountInstructionsHistoryModalProps) => {
  const intl = useIntl()

  const accountHistory = useRNBSelector(
    (state) => state.accounts.accountInstructionsHistory
  )

  interface ModalRowProps {
    instruction: AccountInstruction
    key: string
  }

  const ModalRow = ({ instruction, key }: ModalRowProps) => {
    const intl = useIntl()
    let dateFirstPart = DateTime.fromJSDate(
      new Date(instruction.created_at)
    ).toFormat("dd/MM/yyyy")
    let dateSecondPart = DateTime.fromJSDate(
      new Date(instruction.created_at)
    ).toFormat("HH:mm:ss")
    const instructionType =
      instruction.instruction_type === "modify_account"
        ? "Modifié"
        : instruction.instruction_type === "create_account"
        ? "Créé"
        : instruction.instruction_type === "deactivate_account"
        ? "Désactivé"
        : instruction.instruction_type === "reactivate_account"
        ? "Réactivé"
        : instruction.instruction_type
    return (
      <StyledRow key={key}>
        <Text
          text={intl.formatMessage(
            {
              id: "accounting-plan.accounts.modal.history.line",
            },
            {
              instructionType: instructionType,
              user: instruction.email,
              date1: dateFirstPart,
              date2: dateSecondPart,
            }
          )}
          textStyle={{ fontSize: 1.75 }}
        />
        <Text
          text={
            instruction.metadata.old_account_number
              ? intl.formatMessage(
                  {
                    id: "accounting-plan.accounts.modal.history.formatted-modifications.number",
                  },
                  {
                    oldAccountNumber: instruction.metadata.old_account_number,
                    newAccountNumber: accountSelected.number,
                  }
                )
              : ""
          }
          textStyle={{ fontSize: 1.75 }}
        />
        <Text
          text={
            instruction.metadata.old_account_details
              ? intl.formatMessage(
                  {
                    id: "accounting-plan.accounts.modal.history.formatted-modifications.details",
                  },
                  {
                    oldAccountDetails: instruction.metadata.old_account_details,
                    newAccountDetails: accountSelected.details,
                  }
                )
              : ""
          }
          textStyle={{ fontSize: 1.75 }}
        />
      </StyledRow>
    )
  }

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      top="calc(50vh - 50rem)"
      left="50%"
      right="50%"
    >
      <StyledCard>
        <HeaderPreviewModal>
          <TextWrapper>
            <Title
              text={intl.formatMessage({
                id: "accounting-plan.accounts.modal.history.title",
              })}
              size={3}
            />
          </TextWrapper>
          <CrossWrapper onClick={() => onClose()}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Line />
        <Spacer height={5} />
        <AlignCenter>
          <Tableheader>
            <Text
              text={intl.formatMessage(
                {
                  id: "accounting-plan.accounts.modal.history..title.account.number",
                },
                { number: accountSelected.number }
              )}
              textStyle={{
                textTransform: "uppercase",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 2.5,
                color: "navy",
              }}
            />
          </Tableheader>
          <Content>
            <Spacer height={4} />
            <StyledColumn>
              {accountHistory && accountHistory.length > 0 ? (
                accountHistory.map((instruction, index) => (
                  <ModalRow
                    instruction={instruction}
                    key={index + "_instruction"}
                  />
                ))
              ) : (
                <AlertContent>
                  <AlertWrapper>
                    <Alert alertType={"info"}>
                      <Text
                        text={intl.formatMessage({
                          id: "accounting-plan.accounts.modal.history.no-content",
                        })}
                      />
                    </Alert>
                    <Spacer height={4} />
                  </AlertWrapper>
                </AlertContent>
              )}
              <Spacer height={2} />
            </StyledColumn>
          </Content>
          <Spacer height={4} />
          <Button label={"ok"} width={50} onClick={() => onClose()} />
          <Spacer height={4} />
        </AlignCenter>
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled.div`
  width: 140rem;
  background-color: white;
  border-radius: 10px;
`
const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 3rem 0;
`
const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Content = styled.div`
  width: 90%;
  outline: 1px ${colors.lavender} solid;
  outline-offset: -1px;
  border-radius: 0 0 8px 8px;
`
const StyledColumn = styled.ul`
  margin-left: 2rem;
  max-height: 50rem;
  overflow: auto;
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const StyledRow = styled.li`
  margin-bottom: 15px;
`
const Tableheader = styled(TableHeader)`
  background-color: ${colors.lavender};
  justify-content: left;
  padding-left: 5rem;
  width: 90%;
  border-radius: 8px 8px 0 0;
`
const AlignCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const AlertWrapper = styled.div`
  width: 50rem;
`
const AlertContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
