import axios, { AxiosError } from "axios"
import { AnyAction } from "redux"

import { Dispatch, RNBThunkAction } from "../store.config"
import { RootActionsType } from "../rootActions"
import { ConfigureAxios } from "../../axios.config"
import { ToasterMessageAction } from "../notifications.config"
import { login_SSO_redirection, resetCurrentUser } from "./user.ducks"

const enum LoginActionsEnum {
  LOGIN_ATTEMPT = "LOGIN/loginAttempt",
  LOGIN_SUCCESS = "LOGIN/loginSuccess",
  LOGIN_FAILURE = "LOGIN/loginFailure",
  LOGOUT_ATTEMPT = "LOGIN/logoutAttempt",
  LOGOUT_SUCCESS = "LOGIN/logoutSuccess",
  LOGOUT_FAILURE = "LOGIN/logoutFailure",
  CHECK_SSO_REDIRECTION_ATTEMPT = "LOGIN/check_SSO_redirection_Attempt",
  CHECK_SSO_REDIRECTION_SUCCESS = "LOGIN/check_SSO_redirection_Success",
  CHECK_SSO_REDIRECTION_FAILURE = "LOGIN/check_SSO_redirection_Failure",
  FORGOTTEN_PASSWORD_ATTEMPT = "LOGIN/forgottenPasswordAttempt",
  FORGOTTEN_PASSWORD_SUCCESS = "LOGIN/forgottenPasswordSuccess",
  FORGOTTEN_PASSWORD_FAILURE = "LOGIN/forgottenPasswordFailure",
  FORGOTTEN_PASSWORD_RESET = "LOGIN/forgottenPasswordReset",
  RESET_PASSWORD_ATTEMPT = "LOGIN/resetPasswordAttempt",
  RESET_PASSWORD_SUCCESS = "LOGIN/resetPasswordSuccess",
  RESET_PASSWORD_FAILURE = "LOGIN/resetPasswordFailure",
  REGISTERING_ATTEMPT = "LOGIN/registeringAttempt",
  REGISTERING_SUCCESS = "LOGIN/registeringSuccess",
  REGISTERING_FAILURE = "LOGIN/registeringFailure",
  REGISTERING_EXISTING_EMAIL_FAILURE = "LOGIN/registeringExistingEmailFailure",
}

export const loginAttemptAction = () =>
  ({ type: LoginActionsEnum.LOGIN_ATTEMPT } as const)
export const loginSuccessAction = () =>
  ({ type: LoginActionsEnum.LOGIN_SUCCESS } as const)
export const loginFailureAction = () =>
  ({ type: LoginActionsEnum.LOGIN_FAILURE } as const)
export const logoutAttemptAction = () =>
  ({ type: LoginActionsEnum.LOGOUT_ATTEMPT } as const)
export const logoutSuccessAction = () =>
  ({ type: LoginActionsEnum.LOGOUT_SUCCESS } as const)
export const forgottenPasswordAttemptAction = () =>
  ({ type: LoginActionsEnum.FORGOTTEN_PASSWORD_ATTEMPT } as const)
export const logoutFailureAction = () =>
  ({ type: LoginActionsEnum.LOGOUT_FAILURE } as const)
export const check_SSO_redirection_AttemptAction = () =>
  ({ type: LoginActionsEnum.CHECK_SSO_REDIRECTION_ATTEMPT } as const)
export const check_SSO_redirection_SuccessAction = () =>
  ({ type: LoginActionsEnum.CHECK_SSO_REDIRECTION_SUCCESS } as const)
export const check_SSO_redirection_FailureAction = () =>
  ({ type: LoginActionsEnum.CHECK_SSO_REDIRECTION_FAILURE } as const)
export const forgottenPasswordResetAction = () =>
  ({ type: LoginActionsEnum.FORGOTTEN_PASSWORD_RESET } as const)
export const forgottenPasswordAction = () =>
  ({ type: LoginActionsEnum.FORGOTTEN_PASSWORD_ATTEMPT } as const)
export const forgottenPasswordSuccessAction = () =>
  ({ type: LoginActionsEnum.FORGOTTEN_PASSWORD_SUCCESS } as const)
export const forgottenPasswordFailureAction = (error: AxiosError) =>
  ({
    type: LoginActionsEnum.FORGOTTEN_PASSWORD_FAILURE,
    withoutToast: true,
  } as const)
export const resetPasswordAttemptAction = () =>
  ({ type: LoginActionsEnum.RESET_PASSWORD_ATTEMPT } as const)
export const resetPasswordSuccessAction = () =>
  ({ type: LoginActionsEnum.RESET_PASSWORD_SUCCESS } as const)
export const resetPasswordFailureAction = (error: AxiosError) =>
  ({ type: LoginActionsEnum.RESET_PASSWORD_FAILURE, error } as const)
export const registeringAttemptAction = () =>
  ({ type: LoginActionsEnum.REGISTERING_ATTEMPT } as const)
export const registeringSuccessAction = () =>
  ({
    type: LoginActionsEnum.REGISTERING_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "register.toaster.success.title",
      bodyKey: "register.toaster.success.body",
    },
  } as const)
export const registeringFailureAction = (error: AxiosError) =>
  ({ type: LoginActionsEnum.REGISTERING_FAILURE, error } as const)

const toasterEmail: ToasterMessageAction = {
  type: LoginActionsEnum.REGISTERING_EXISTING_EMAIL_FAILURE,
  withToast: true,
  toasterType: "error",
  message: {
    titleKey: "register.email-taken",
    bodyKey: "register.choose-another",
  },
} as const

export const registeringExistingEmailFailureAction = () => toasterEmail

type LoginActionsType = ReturnType<
  | typeof loginAttemptAction
  | typeof loginSuccessAction
  | typeof loginFailureAction
  | typeof logoutAttemptAction
  | typeof logoutSuccessAction
  | typeof forgottenPasswordAttemptAction
  | typeof forgottenPasswordSuccessAction
  | typeof forgottenPasswordFailureAction
  | typeof forgottenPasswordResetAction
  | typeof registeringAttemptAction
  | typeof registeringSuccessAction
  | typeof registeringFailureAction
  | typeof registeringExistingEmailFailureAction
  | typeof resetPasswordAttemptAction
  | typeof resetPasswordFailureAction
  | typeof resetPasswordSuccessAction
>

export const loginInitialState: LoginState = {
  status: "NOT_CONNECTED",
  forgottenPassword: "UNFORGOTTEN",
  registering: "UNREGISTERED",
  resetPasswordStatus: "UNRESET",
}

export interface LoginState {
  status: "NOT_CONNECTED" | "LOADING" | "LOGGED" | "ERROR" | "DISCONNECTED"
  forgottenPassword: "REQUESTING" | "SUCCESS" | "ERROR" | "UNFORGOTTEN"
  registering:
    | "REGISTERING"
    | "SUCCESS"
    | "EMAIL_EXISTING_ERROR"
    | "ERROR"
    | "UNREGISTERED"
  resetPasswordStatus: "RESETTING" | "SUCCESS" | "ERROR" | "UNRESET"
}

export function loginReducer(
  state = loginInitialState,
  action: LoginActionsType
): LoginState {
  switch (action.type) {
    case LoginActionsEnum.LOGIN_ATTEMPT:
      return { ...state, status: "LOADING" }
    case LoginActionsEnum.LOGIN_SUCCESS:
      return { ...state, status: "LOGGED" }
    case LoginActionsEnum.LOGIN_FAILURE:
      return { ...state, status: "ERROR" }
    case LoginActionsEnum.LOGOUT_ATTEMPT:
      return { ...state, status: "LOADING" }
    case LoginActionsEnum.LOGOUT_SUCCESS:
      return { ...state, status: "DISCONNECTED" }
    case LoginActionsEnum.FORGOTTEN_PASSWORD_ATTEMPT:
      return { ...state, forgottenPassword: "REQUESTING" }
    case LoginActionsEnum.FORGOTTEN_PASSWORD_RESET:
      return { ...state, forgottenPassword: "UNFORGOTTEN" }
    case LoginActionsEnum.FORGOTTEN_PASSWORD_SUCCESS:
      return { ...state, forgottenPassword: "SUCCESS" }
    case LoginActionsEnum.FORGOTTEN_PASSWORD_FAILURE:
      return { ...state, forgottenPassword: "ERROR" }
    case LoginActionsEnum.REGISTERING_ATTEMPT:
      return { ...state, registering: "REGISTERING" }
    case LoginActionsEnum.REGISTERING_SUCCESS:
      return { ...state, registering: "SUCCESS" }
    case LoginActionsEnum.REGISTERING_FAILURE:
      return { ...state, registering: "ERROR" }
    case LoginActionsEnum.REGISTERING_EXISTING_EMAIL_FAILURE:
      return { ...state, registering: "EMAIL_EXISTING_ERROR" }
    case LoginActionsEnum.RESET_PASSWORD_ATTEMPT:
      return { ...state, resetPasswordStatus: "RESETTING" }
    case LoginActionsEnum.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordStatus: "SUCCESS" }
    case LoginActionsEnum.RESET_PASSWORD_FAILURE:
      return { ...state, resetPasswordStatus: "ERROR" }
    default:
      return { ...state }
  }
}

export interface UserRegisterPayload {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  acceptedConditions: boolean
  acceptedSalesConditions: boolean
  firstConnexion: boolean
}

type RegisterErrorResponse = {
  error: {
    email: Array<unknown>
  }
}

export const attemptRegisteringThunk =
  ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    acceptedConditions,
    acceptedSalesConditions,
    firstConnexion,
  }: UserRegisterPayload) =>
  (dispatch: Dispatch<LoginActionsType | RNBThunkAction>) => {
    dispatch(registeringAttemptAction())
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirmation: confirmPassword,
      terms_of_sales_accepted_at: acceptedSalesConditions,
      terms_of_service_accepted_at: acceptedConditions,
      first_connexion_at: firstConnexion,
    }

    axios
      .post(`/users.json`, data)
      .then((response) => {
        const token = response.headers.authorization
        localStorage.setItem("token", token)
        ConfigureAxios()
        dispatch(registeringSuccessAction())
        dispatch(attemptLoginThunk(email, password, false))
      })
      .catch((e: AxiosError) => {
        if (
          (e.response?.data as RegisterErrorResponse)?.error?.email?.length > 0
        ) {
          dispatch(registeringExistingEmailFailureAction())
        } else {
          dispatch(registeringFailureAction(e))
        }
      })
  }

export const attemptLoginThunk =
  (
    email: string,
    password: string,
    rememberMe: boolean // TODO : Update this code which is currently unused
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(resetCurrentUser())
    dispatch(loginAttemptAction())
    const data = {
      user: {
        email: email,
        password: password,
        remember_me: false,
      },
    }
    return axios
      .get(`/users/check_sso_redirection`, { params: { email } })
      .then(() => {
        axios
          .post(`/users/sign_in.json`, data)
          .then((response) => {
            const token = response.headers.authorization
            localStorage.setItem("token", token)
            ConfigureAxios()
            dispatch(loginSuccessAction())
          })
          .catch(() => dispatch(loginFailureAction()))
      })
      .catch((e: AxiosError) => {
        const redirection_url = (
          e.response?.data as { redirection_url: string }
        )?.redirection_url
        if (e.response?.status === 403 && redirection_url) {
          dispatch(login_SSO_redirection(redirection_url))
          dispatch(loginFailureAction())
        }
      })
  }

export const forgottenPasswordThunk =
  (email: string) => (dispatch: Dispatch<LoginActionsType>) => {
    dispatch(forgottenPasswordAttemptAction())
    const data = {
      user: {
        email: email,
      },
    }
    return axios
      .post(`/users/password.json`, data)
      .then(() => {
        dispatch(forgottenPasswordSuccessAction())
      })
      .catch((e: AxiosError) => dispatch(forgottenPasswordFailureAction(e)))
  }

export const resetPasswordThunk =
  (password: string, confirmPassword: string, token: string) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(resetPasswordAttemptAction())
    const user = {
      user: {
        password: password,
        password_confirmation: confirmPassword,
        reset_password_token: token,
      },
    }
    //https://github.com/lynndylanhurley/devise_token_auth/issues/1221
    //https://stackoverflow.com/questions/60088159/react-form-returns-404-not-found-when-trying-to-sign-up-with-devise-rails-api
    //https://stackoverflow.com/questions/16807937/devise-password-recoverable-module-via-ajax
    /*Apparament, sur d'autres fonction de Devise, on peut envoyer du JSON et quand je fais des tests, ça semble fonctionner.
                Mais j'ai aussi lu qu'il faut un "respond_to: json" dans certains cas pour que ça fonctionne.
                Donc je laisse la version JSON (qui semble fonctionner) et je laisse la version x-www-form-urlencoded ou cas ou
                const data = new URLSearchParams(Object.entries(user)).toString();*/

    return axios
      .put(`/users/password.json`, user)
      .then(() => {
        dispatch(resetPasswordSuccessAction())
      })
      .catch((e: AxiosError) => dispatch(resetPasswordFailureAction(e)))
  }

export const attemptLogoutThunk =
  () => (dispatch: Dispatch<LoginActionsType | RootActionsType>) => {
    dispatch(logoutAttemptAction())
    localStorage.removeItem("token")
    return axios.delete(`/users/sign_out.json`).then(() => {
      dispatch(logoutSuccessAction())
    })
  }
