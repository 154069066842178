import { Params } from "react-router-dom"
import { getIdFromParams } from "../utils/company"
import { useEffect } from "react"
import { GetCompanyThunk } from "../store/ducks/companies.ducks"
import { AnyAction } from "redux"
import { useRNBSelector } from "../store/rootReducer"
import { Dispatch, RNBThunkAction } from "../store/store.config"

export function useSelectedCompany(
  params: Readonly<Params<string>>,
  dispatch: Dispatch<AnyAction | RNBThunkAction>
) {
  const selectedCompanyId = getIdFromParams(params)("company_id")
  useEffect(() => {
    if (selectedCompanyId) dispatch(GetCompanyThunk(selectedCompanyId))
  }, [selectedCompanyId, dispatch])
  const companies = useRNBSelector((state) => state.companies.companies)
  return selectedCompanyId ? companies[selectedCompanyId] || null : null
}
