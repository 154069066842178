import { Dispatch, RNBThunkAction } from "../store.config"
import axios from "axios"
import {
  CropType,
  RuleValue,
  WritingLabelBuyOrSell,
  WritingLabelDocumentType,
} from "../../utils/writingLabelRule"

const enum WritingLabelsActionsEnum {
  GET_WRITING_LABELS_DATA_ATTEMPT = "COMPANY/GetWritingLabelsDataAttempt",
  GET_WRITING_LABELS_DATA_SUCCESS = "COMPANY/GetWritingLabelsDataSuccess",
  GET_WRITING_LABELS_DATA_FAILURE = "COMPANY/GetWritingLabelsDataFailure",

  DESTROY_WRITING_LABELS_RULE_ATTEMPT = "COMPANY/DestroyWritingLabelRuleAttempt",
  DESTROY_WRITING_LABELS_RULE_SUCCESS = "COMPANY/DestroyWritingLabelRuleSuccess",
  DESTROY_WRITING_LABELS_RULE_FAILURE = "COMPANY/DestroyWritingLabelRuleFailure",

  CREATE_WRITING_LABELS_RULE_ATTEMPT = "COMPANY/CreateWritingLabelRuleAttempt",
  CREATE_WRITING_LABELS_RULE_SUCCESS = "COMPANY/CreateWritingLabelRuleSuccess",
  CREATE_WRITING_LABELS_RULE_FAILURE = "COMPANY/CreateWritingLabelRuleFailure",
}

export const GetWritingLabelsDataAttempt = () =>
  ({
    type: WritingLabelsActionsEnum.GET_WRITING_LABELS_DATA_ATTEMPT,
  } as const)
export const GetWritingLabelsDataSuccess = (payload: WritingLabelData) =>
  ({
    type: WritingLabelsActionsEnum.GET_WRITING_LABELS_DATA_SUCCESS,
    payload,
  } as const)
export const GetWritingLabelsDataFailure = () =>
  ({
    type: WritingLabelsActionsEnum.GET_WRITING_LABELS_DATA_FAILURE,
  } as const)

export const DestroyWritingLabelRuleAttempt = () =>
  ({
    type: WritingLabelsActionsEnum.DESTROY_WRITING_LABELS_RULE_ATTEMPT,
  } as const)
export const DestroyWritingLabelRuleSuccess = () =>
  ({
    type: WritingLabelsActionsEnum.DESTROY_WRITING_LABELS_RULE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office-company.writing-labels.destroy-tooltip-succes.title`,
      bodyKey: `office-company.writing-labels.destroy-tooltip-succes.message`,
    },
  } as const)
export const DestroyWritingLabelRuleFailure = () =>
  ({
    type: WritingLabelsActionsEnum.DESTROY_WRITING_LABELS_RULE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office-company.writing-labels.destroy-tooltip-error.title`,
      bodyKey: `office-company.writing-labels.destroy-tooltip-error.message`,
    },
  } as const)

export const CreateWritingLabelRuleAttempt = () =>
  ({
    type: WritingLabelsActionsEnum.CREATE_WRITING_LABELS_RULE_ATTEMPT,
  } as const)
export const CreateWritingLabelRuleSuccess = () =>
  ({
    type: WritingLabelsActionsEnum.CREATE_WRITING_LABELS_RULE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office-company.writing-labels.create-tooltip-succes.title`,
      bodyKey: `office-company.writing-labels.create-tooltip-succes.message`,
    },
  } as const)
export const CreateWritingLabelRuleFailure = () =>
  ({
    type: WritingLabelsActionsEnum.CREATE_WRITING_LABELS_RULE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office-company.writing-labels.create-tooltip-error.title`,
      bodyKey: `office-company.writing-labels.create-tooltip-error.message`,
    },
  } as const)

type WritingLabelsActionsType = ReturnType<
  | typeof GetWritingLabelsDataAttempt
  | typeof GetWritingLabelsDataSuccess
  | typeof GetWritingLabelsDataFailure
  | typeof DestroyWritingLabelRuleAttempt
  | typeof DestroyWritingLabelRuleSuccess
  | typeof DestroyWritingLabelRuleFailure
  | typeof CreateWritingLabelRuleAttempt
  | typeof CreateWritingLabelRuleSuccess
  | typeof CreateWritingLabelRuleFailure
>

export const writingLabelsInitialState: WritingLabelsState = {
  writing_labels_data: {
    fields: {},
  },
}

export interface WritingLabelsState {
  writing_labels_data: WritingLabelData
}

interface WritingLabelField {
  [index: string]: Field
}

interface WritingLabelData {
  fields: WritingLabelField
}

export interface Field {
  start: number
  max_length: number | null
  default_fields: RuleValue[]
  translation: string
  writing_label_rules: WritingLabelRule[]
}

export interface WritingLabelRule {
  id: number
  buy_or_sell: WritingLabelBuyOrSell
  document_type: WritingLabelDocumentType
  field: string
  values: RuleValue[]
  free_text: string | null
  crop: CropType | null
}

export function writingLabelsReducer(
  state = writingLabelsInitialState,
  action: WritingLabelsActionsType
): WritingLabelsState {
  switch (action.type) {
    case WritingLabelsActionsEnum.GET_WRITING_LABELS_DATA_SUCCESS: {
      return {
        ...state,
        writing_labels_data: action.payload,
      }
    }

    default:
      return { ...state }
  }
}

export const GetWritingLabelDataThunk =
  (companyId: number) => (dispatch: Dispatch<WritingLabelsActionsType>) => {
    dispatch(GetWritingLabelsDataAttempt())
    return axios
      .get<WritingLabelData>(`companies/${companyId}/writing_labels_data`)
      .then((response) => dispatch(GetWritingLabelsDataSuccess(response.data)))
      .catch(() => dispatch(GetWritingLabelsDataFailure()))
  }

export const DestroyWritingLabelRuleThunk =
  (companyId: number, writingLabelRuleId: number) =>
  (dispatch: Dispatch<WritingLabelsActionsType | RNBThunkAction>) => {
    dispatch(DestroyWritingLabelRuleAttempt())
    return axios
      .put(`companies/${companyId}/destroy_writing_label_rule`, {
        writing_label_rule_id: writingLabelRuleId,
      })
      .then(() => {
        dispatch(DestroyWritingLabelRuleSuccess())
        dispatch(GetWritingLabelDataThunk(companyId))
      })
      .catch(() => dispatch(DestroyWritingLabelRuleFailure()))
  }

export const CreateWritingLabelRuleThunk =
  (companyId: number, new_rule: WritingLabelRule) =>
  (dispatch: Dispatch<WritingLabelsActionsType | RNBThunkAction>) => {
    dispatch(CreateWritingLabelRuleAttempt())
    return axios
      .put(`companies/${companyId}/create_or_edit_writing_label_rule`, {
        new_rule: new_rule,
      })
      .then(() => {
        dispatch(CreateWritingLabelRuleSuccess())
        dispatch(GetWritingLabelDataThunk(companyId))
      })
      .catch(() => dispatch(CreateWritingLabelRuleFailure()))
  }
