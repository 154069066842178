import { useIntl } from "react-intl"
import { useLocation, useParams } from "react-router-dom"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { ButtonsHeader } from "./ButtonsHeader"
import { StyledTopBar } from "./StyledTopBar"
import { getIdFromParams } from "../../utils/company"
import { CompanyStub } from "../../store/ducks/companies.ducks"
import { useSubtitle } from "../../hooks/useSubtitle"

export const CompanyTopBar = () => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const companies = useRNBSelector((state) => state.companies.companies)
  const selectedCompany = companies[selectedCompanyId] || CompanyStub
  const selectedCompanyName = selectedCompany?.user_company_name
    ? selectedCompany?.user_company_name
    : selectedCompany?.name
  const location = useLocation()
  const params = useParams()
  const prefix = "office.company.top-bar"

  const subtextId = useSubtitle(location.pathname, params, prefix)

  return (
    <StyledTopBar>
    <Ct.Column>
      <Ct.Title size={2.25} text={selectedCompanyName} />
      <Ct.Spacer height={0.5} />
      {subtextId && (
        <Ct.Subtitle
          size={2.25}
          text={intl.formatMessage({
            id: subtextId,
          })}
        />
      )}
    </Ct.Column>
    <ButtonsHeader />
  </StyledTopBar>
  )
}
