import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { ReactComponent as Users } from "../assets/users.svg"
import styled from "styled-components/macro"
import { colors } from "../styles/design.config"
import * as Ct from "ldlj"

export const UsersButton = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const sendToManagement = () => {
    navigate(`/office/management`)
  }

  return (
    <Ct.Button
      height={4}
      width={17}
      onClick={sendToManagement}
      textTransform={"uppercase"}
      prefix={<UsersWhite />}
      label={intl.formatMessage({
        id: "office.users",
      })}
    />
  )
}

const UsersWhite = styled(Users)`
  && path {
    fill: ${colors.white};
  }
`
