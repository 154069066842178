import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import {
  AttachBatchOnTransactionThunk,
  DropBatchForTransactionThunk,
  MarkWithoutDocumentThunk,
  Transaction,
} from "../../store/ducks/bank.ducks"
import { DateTime } from "luxon"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  Table,
} from "../Commons/Table"
import { CSSProperties, useEffect, useState } from "react"
import { Color, boxShadow, colors } from "../../styles/design.config"
import { ReactComponent as Search } from "../../assets/search.svg"
import { ReactComponent as File } from "../../assets/file-file.svg"
import { ReactComponent as Zoom } from "../../assets/zoom.svg"
import { ReactComponent as Eye } from "../../assets/eye.svg"
import { ReactComponent as Info } from "../../assets/info-full.svg"
import { ReactComponent as AddFile } from "../../assets/file-plus.svg"
import { ReactComponent as Download } from "../../assets/FileDownloadSolid.svg"
import { useRNBSelector } from "../../store/rootReducer"
import {
  AttachFullDocumentToTransactionThunk,
  DetachFullDocumentOfTransactionThunk,
  FullDocumentToAttach,
} from "../../store/ducks/fullDocuments.ducks"
import AutoSizer from "react-virtualized-auto-sizer"
import React from "react"
import { VariableSizeGrid } from "react-window"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
} from "../dropDocuments/StyledDropsComponents"
import { Alert } from "../Commons/Alert"
import { Text } from "../Commons/Text"
import {
  FilterFullDocsToAttach,
  fullDocNotSelectable,
  sortFullDocsByAmount,
  sortFullDocsByDate,
  sortFullDocsByMerchantName,
  sortFullDocsByOriginalFileName,
  sortFullDocsByType,
  sortFullDocsByUserFileName,
} from "../../utils/bank"
import { FullDocumentPages } from "../documentsViewer/FullDocumentPages"
import { DocumentViewer } from "../documentsViewer/DocumentViewer"
import { Button } from "../Commons/Button"
import { Checkbox } from "../Commons/Checkbox"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { useKeyDown } from "../../hooks/useKeyDown"
import { dropDocumentEmptyAction } from "../../store/ducks/dropDocuments.ducks"
import { base64ArrayBuffer } from "../../utils/base64ArrayBuffer"
import { getUrlForBatchDocumentThunk } from "../../store/ducks/batchDocuments.ducks"
import ReactTooltip from "react-tooltip"
import { croppedEndString } from "../../utils/string"

/* eslint-disable camelcase */

interface AttachFullDocumentProps {
  onClose: () => void
  transaction: Transaction
  goToFullDocAttached: boolean
  attachOrImport: "attach" | "import"
}

export const AttachFullDocumentToTransaction = ({
  onClose,
  transaction,
  goToFullDocAttached,
  attachOrImport,
}: AttachFullDocumentProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const accountID = getIdFromParams(useParams())("account_id") || 0
  const fullDocuments = useRNBSelector(
    (state) => state.bank.documentsToAttach.full_documents
  )
  const userTypology = useRNBSelector((state) => state.user.typology)
  const attachDocumentStatus = useRNBSelector(
    (state) => state.bank.attachDocumentStatus
  )
  const transactions = useRNBSelector(
    (state) => state.bank.accounts[accountID].transactions
  )
  const batch_document = useRNBSelector(
    (state) => state.bank.documentsToAttach.batch_data?.current_batch
  )
  const batch_data = useRNBSelector(
    (state) => state.bank.documentsToAttach.batch_data
  )

  const [currentTransaction, setCurrentTransaction] = useState(
    transactions.find((t) => t.transaction_id === transaction.transaction_id)
      ? transactions.find(
          (t) => t.transaction_id === transaction.transaction_id
        )
      : transaction
  )
  const [search, setSearch] = useState("")
  const listRef = React.createRef<VariableSizeGrid>()
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [rowData, setRowData] = useState<FullDocumentToAttach[]>([])
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [selectedFullDocument, setSelectedFullDocument] =
    useState<FullDocumentToAttach | null>(null)
  const [selectedPage, setSelectedPage] = useState<number | null>(null)
  const [rowHeigths, setRowHeights] = useState<number[]>([])

  enum SortOptionsValues {
    "user_file_name",
    "original_file_name",
    "type",
    "merchant_name",
    "date",
    "amount",
  }

  const [sortType, setSortType] = useState<
    { type: SortOptionsValues; asc: boolean } | undefined
  >({ type: SortOptionsValues.date, asc: true })

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setSortType({ type: option, asc: asc })

    switch (option) {
      case SortOptionsValues.user_file_name:
        setRowData(sortFullDocsByUserFileName(rowData, asc))
        break
      case SortOptionsValues.original_file_name:
        setRowData(sortFullDocsByOriginalFileName(rowData, asc))
        break
      case SortOptionsValues.type:
        setRowData(sortFullDocsByType(rowData, asc))
        break
      case SortOptionsValues.merchant_name:
        setRowData(sortFullDocsByMerchantName(rowData, asc))
        break
      case SortOptionsValues.date:
        setRowData(sortFullDocsByDate(rowData, asc))
        break
      case SortOptionsValues.amount:
        setRowData(sortFullDocsByAmount(rowData, asc))
        break

      default:
        setRowData(sortFullDocsByUserFileName(rowData, asc))
        break
    }
  }

  const columnsTransactionFirst = [
    {
      headerText: "bank-management.transaction.table-header.date",
      content: (row: Transaction) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={DateTime.fromJSDate(new Date(row.date)).toFormat(
                  "dd/MM/yyyy"
                )}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        </>
      ),
    },
    {
      headerText: "bank-management.transaction.table-header.label",
      content: (row: Transaction) => (
        <ColumnRow>
          {row.lines.map((l) => (
            <Row text={row.description} key={row.transaction_id} />
          ))}
        </ColumnRow>
      ),
    },
  ]

  const columnsTransactionCustomer = [
    {
      headerText: "bank-management.transaction.table-header.amount",
      content: (row: Transaction) => {
        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row text={l.amount} key={l.amount} />
            ))}
          </ColumnRow>
        )
      },
    },
  ]

  const columnsTransactionAccounting = [
    {
      headerText: "bank-management.transaction.table-header.debit",
      content: (row: Transaction) => {
        const empty = intl.formatMessage({
          id: "bank-management.transaction.table-body.empty-space",
        })

        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={l.direction === "debit" ? l.amount : empty}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
    {
      headerText: "bank-management.transaction.table-header.credit",
      content: (row: Transaction) => {
        const empty = intl.formatMessage({
          id: "bank-management.transaction.table-body.empty-space",
        })

        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={l.direction === "credit" ? l.amount : empty}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
    {
      headerText: "bank-management.transaction.table-header.assignment",
      content: (row: Transaction) => {
        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={
                  l.assignment +
                  (l.auxiliary_assignment ? ", " + l.auxiliary_assignment : "")
                }
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
  ]

  const columnsTransaction =
    userTypology === "customer"
      ? [...columnsTransactionFirst, ...columnsTransactionCustomer]
      : [...columnsTransactionFirst, ...columnsTransactionAccounting]

  const columnsFullDocumentsFirst = [
    {
      headerText:
        "bank-management.transaction.attach.table-header.user_file_name",
      content: (row: FullDocumentToAttach) => (
        <>
          <ColumnRowFullDocs justifyContent={"flex-start"}>
            <WrapperSvg
              padding={
                currentTransaction?.full_document_id === row.id
                  ? "0 0 0 2rem"
                  : selectedFullDocument && selectedFullDocument.id === row.id
                  ? "0 2rem"
                  : "2rem"
              }
            >
              {currentTransaction?.full_document_id === row.id ? (
                <Checkbox
                  label={""}
                  isChecked={true}
                  value={currentTransaction.full_document_id === row.id}
                  disabled={false}
                />
              ) : selectedFullDocument && selectedFullDocument.id === row.id ? (
                <StyledEye />
              ) : (
                <StyledFilePlus />
              )}
            </WrapperSvg>
            <RowFullDocs
              text={row.user_file_name}
              textStyle={{
                color:
                  selectedFullDocument?.id === row.id ? "cornflower" : "navy",
              }}
            />
          </ColumnRowFullDocs>
        </>
      ),
    },
    {
      headerText:
        "bank-management.transaction.attach.table-header.original_file_name",
      content: (row: FullDocumentToAttach) => (
        <ColumnRowFullDocs>
          <RowFullDocs
            text={croppedEndString(row.original_file_name, 40)}
            textStyle={{
              color:
                selectedFullDocument?.id === row.id ? "cornflower" : "navy",
              textAlign: "center",
            }}
          />
        </ColumnRowFullDocs>
      ),
    },
  ]

  const columnsFullDocumentsAccounting = [
    {
      headerText: "bank-management.transaction.attach.table-header.type",
      content: (row: FullDocumentToAttach) => {
        return (
          <ColumnRowFullDocs>
            <RowFullDocs
              text={
                row.document_type && row.buy_or_sell
                  ? intl.formatMessage({
                      id: `bank-management.transaction.attach.table-header.type-${row.document_type}`,
                    }) +
                    " " +
                    (row.document_type &&
                    row.document_type !== "bill_of_exchange"
                      ? intl.formatMessage({
                          id: `bank-management.transaction.attach.table-header.${row.buy_or_sell}`,
                        })
                      : "")
                  : "-"
              }
              textStyle={{
                color:
                  selectedFullDocument?.id === row.id ? "cornflower" : "navy",
                textAlign: "center",
              }}
            />
          </ColumnRowFullDocs>
        )
      },
    },
    {
      headerText:
        "bank-management.transaction.attach.table-header.merchant_name",
      content: (row: FullDocumentToAttach) => (
        <ColumnRowFullDocs>
          <RowFullDocs
            text={row.merchant_name || "-"}
            textStyle={{
              color:
                selectedFullDocument?.id === row.id ? "cornflower" : "navy",
              textAlign: "center",
            }}
          />
        </ColumnRowFullDocs>
      ),
    },
  ]

  const columnsFullDocumentsLast = [
    {
      headerText: "bank-management.transaction.attach.table-header.date",
      content: (row: FullDocumentToAttach) => (
        <ColumnRowFullDocs>
          <RowFullDocs
            text={DateTime.fromJSDate(new Date(row.date)).toFormat(
              "dd/MM/yyyy"
            )}
            textStyle={{
              color:
                selectedFullDocument?.id === row.id ? "cornflower" : "navy",
              textAlign: "center",
            }}
          />
        </ColumnRowFullDocs>
      ),
    },
    {
      headerText: "bank-management.transaction.attach.table-header.amount",
      content: (row: FullDocumentToAttach) => {
        const text = row.amount ? row.amount + (row.amount ? "€" : "") : "-"
        return (
          <ColumnRowFullDocs>
            <RowFullDocs
              text={text}
              textStyle={{
                color:
                  selectedFullDocument?.id === row.id ? "cornflower" : "navy",
                textAlign: "center",
              }}
            />
          </ColumnRowFullDocs>
        )
      },
    },
  ]

  const columnsFullDocuments =
    userTypology === "customer"
      ? [...columnsFullDocumentsFirst, ...columnsFullDocumentsLast]
      : [
          ...columnsFullDocumentsFirst,
          ...columnsFullDocumentsAccounting,
          ...columnsFullDocumentsLast,
        ]

  async function uploadFile(file: File) {
    if (!selectedCompanyId) {
      throw new Error("no company selected")
    }

    if (!file.size) {
      dispatch(
        dropDocumentEmptyAction({
          fileName: file.name,
        })
      )
      return
    }
    crypto.subtle
      .digest("SHA-512", await file.arrayBuffer())
      .then(function (h) {
        let fingerprint = base64ArrayBuffer(h)

        dispatch(
          DropBatchForTransactionThunk(
            accountID,
            transaction.transaction_id,
            file.name,
            file.size,
            fingerprint,
            file,
            selectedCompanyId
          )
        )
      })
  }

  const clickHandler = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = false
    input.accept = ".png,.jpg,.jpeg,.pdf,.tiff,.tif"
    document.body.appendChild(input)

    input.addEventListener("change", (e: Event) => {
      const target = e.target as HTMLInputElement
      const fileList = target.files
      if (fileList && uploadFile) {
        const file = fileList[0]

        uploadFile(file)

        document.body.removeChild(input)
      }
    })

    input.click()
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const fullDoc: FullDocumentToAttach = rowData[rowIndex]

    const backgroundColor =
      selectedFullDocument?.id === fullDoc.id ? `clearBlue` : `white`

    return (
      <>
        <div
          style={{
            ...style,
            backgroundColor: colors[backgroundColor],
            cursor: "pointer",
          }}
          key={columnIndex + fullDoc.id}
          onClick={() => {
            setSelectedFullDocument(fullDoc)
            setSelectedPage(fullDoc.documents[0].page_number)
          }}
        >
          {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
          <StyledRow>
            <Ct.ColumnCenterCenter
              style={{ height: "100%", width: "100%" }}
              alignItems={
                columnIndex === 0 || columnIndex === 1 ? "flex-start" : "center"
              }
              textAlign={columnIndex === 0 ? "flex-start" : "center"}
            >
              <Ct.Row
                style={{
                  width: "100%",
                  justifyContent:
                    columnIndex === 0 || columnIndex === 1
                      ? "flex-start"
                      : "center",
                  height: "100%",
                  wordBreak: "break-word",
                }}
              >
                {columnIndex === 1 && <Ct.Spacer width={2} height={0} />}
                {columnsFullDocuments[columnIndex].content(fullDoc)}
              </Ct.Row>
            </Ct.ColumnCenterCenter>
          </StyledRow>
        </div>

        {fullDoc?.transactions_attached &&
          fullDoc?.transactions_attached?.length > 0 && (
            <>
              <Ct.Spacer height={3} />
              <div
                style={{
                  ...style,
                  width: "100%",
                  top: Number(style?.top) + 50,
                  left: "12px",
                  height: "70px",
                }}
              >
                <TransactionAttached
                  fullDoc={fullDoc}
                  backgroundColor={backgroundColor}
                />
              </div>
            </>
          )}
      </>
    )
  }

  useEffect(() => {
    if (fullDocuments.length > 0) {
      const foundTransaction = transactions.find(
        (t) => t.transaction_id === transaction.transaction_id
      )
        ? transactions.find(
            (t) => t.transaction_id === transaction.transaction_id
          )
        : transaction
      setCurrentTransaction(foundTransaction)
      setRowData(FilterFullDocsToAttach(fullDocuments, search))

      const heights = fullDocuments.map((fd, index) => {
        return fd.transactions_attached.length > 0 ? 60 + 50 : 60
      })
      setRowHeights(heights)
    } else {
      const foundTransaction = transactions.find(
        (t) => t.transaction_id === transaction.transaction_id
      )
        ? transactions.find(
            (t) => t.transaction_id === transaction.transaction_id
          )
        : transaction
      setCurrentTransaction(foundTransaction)
      setRowData([])
      setRowHeights([])
    }
  }, [
    fullDocuments,
    search,
    attachDocumentStatus,
    attachOrImport,
    JSON.stringify(fullDocuments),
    batch_document,
    currentTransaction,
  ])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])

      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setRowData(FilterFullDocsToAttach(fullDocuments, search))
      }
    }
  }, [columnToSort, batch_document])

  useEffect(() => {
    if (goToFullDocAttached && rowData && transaction.full_document_id) {
      const fullDoc = rowData.find(
        (fd) => fd.id === transaction.full_document_id
      )
      if (fullDoc) {
        setSelectedFullDocument(fullDoc)
        setSelectedPage(fullDoc.documents[0].page_number)
      }
    } else if (
      (!goToFullDocAttached ||
        (goToFullDocAttached && !transaction.full_document_id)) &&
      rowData.length > 0 &&
      attachOrImport === "attach"
    ) {
      setSelectedFullDocument(rowData[0])
      setSelectedPage(rowData[0].documents[0].page_number)
    } else {
      setSelectedFullDocument(null)
      setSelectedPage(null)
    }
  }, [goToFullDocAttached, currentTransaction, rowData])

  useKeyDown(() => {
    if (selectedFullDocument) {
      const index = rowData.indexOf(selectedFullDocument)
      if (index >= 0 && index < rowData.length - 1) {
        setSelectedFullDocument(rowData[index + 1])
      }
    }
  }, ["ArrowDown"])

  useKeyDown(() => {
    if (selectedFullDocument) {
      const index = rowData.indexOf(selectedFullDocument)
      if (index > 0) {
        setSelectedFullDocument(rowData[index - 1])
      }
    }
  }, ["ArrowUp"])

  return (
    <Wrapper>
      <Part>
        <Ct.Spacer height={2} />

        <TableWrapper height={userTypology === "customer" ? "11rem" : "15rem"}>
          <Table
            intl={intl}
            columns={columnsTransaction}
            rows={[currentTransaction || transaction]}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            padding={"0"}
            paddingHeader={"0rem 0rem 0rem 1rem"}
            paddingRows={"0px 0px 8px 0px"}
            heightHeader={"5rem"}
            fontWeightTitle={600}
            keyBuilder={(rowData) => String(rowData.transaction_id)}
            customScrollBar={true}
          />
        </TableWrapper>

        {attachOrImport === "import" && (
          <>
            <Alert alertType={"bulb"}>
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.attach-modal.alert`,
                })}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.attach-modal.alert2${
                    userTypology === "customer" ? "-customer" : ""
                  }`,
                })}
              />
            </Alert>

            <Ct.Spacer height={2} />

            <DropCard>
              <StyledAddFile />
              <Ct.Spacer width={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.attach-modal.drop-text`,
                })}
                textStyle={{
                  color: "cornflower",
                  underline: true,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  clickHandler()
                }}
              />
            </DropCard>

            <Ct.Spacer height={2} />
          </>
        )}

        <StyledInput
          name={"searchRule"}
          id={"searchRule"}
          label="Recherche"
          value={search}
          suffix={<Search />}
          onChange={(event: { target: HTMLInputElement }) => {
            const value = event.target.value.trimStart().replace("  ", " ")
            setSearch(value.replace(/[^a-zA-Z0-9 .\-/]/g, ""))
          }}
          shadowed={true}
          noBorder={true}
        />
        <Ct.Spacer height={3} />

        <StyledTable height={attachOrImport === "attach" ? "64vh" : "48vh"}>
          <AutoSizer
            onResize={() => {
              listRef?.current?.resetAfterRowIndex(0, true)
            }}
          >
            {({ height, width }: { height: number; width: number }) => {
              const columnSizeByTypology = {
                manager: [27, 20, 12, 17, 12, 12],
                customer: [35, 35, 15, 15],
              }

              const columnWidths = columnsFullDocuments.map((_, index) => {
                return userTypology !== "customer"
                  ? (columnSizeByTypology.manager[index] / 100) * width
                  : (columnSizeByTypology.customer[index] / 100) * width
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0rem"}>
                    {columnsFullDocuments.map((column, index) => (
                      <HeaderTitleWrapper
                        justifyContent={index === 0 ? "flex-start" : "center"}
                        calculatedWidth={columnWidths[index] - 50}
                        key={column.headerText}
                        padding={"0 0 0 1rem"}
                      >
                        <ClickableTitleSort
                          tid={column.headerText}
                          intl={intl}
                          index={index}
                          sortToReturn={(column: SortToReturn) => {
                            setColumnToSort(column)
                            if (rowData.length > 0 && sortType) {
                              sorter(sortType.asc)(sortType.type)
                            }
                          }}
                          currentChevron={currentChevron}
                        />
                      </HeaderTitleWrapper>
                    ))}
                  </HeaderWrapper>

                  {rowData.length === 0 &&
                  attachOrImport === "import" &&
                  currentTransaction &&
                  attachDocumentStatus === "ATTEMPT" ? (
                    <LoaderWrapper
                      totalWidth={width}
                      height={height - 56}
                      flexDirection={"column"}
                    >
                      <Button label={""} loadingStatus={"loading"} />
                    </LoaderWrapper>
                  ) : rowData.length === 0 &&
                    attachOrImport === "import" &&
                    currentTransaction &&
                    currentTransaction.batch_document_id ? (
                    <>
                      <LoaderWrapper
                        totalWidth={width}
                        height={height - 56}
                        flexDirection={"column"}
                      >
                        <WrapperBatchDocument>
                          {batch_data && batch_data.batch_original !== null ? (
                            <>
                              <Text
                                text={intl.formatMessage(
                                  {
                                    id: "bank-management.transaction.attach-modal.batch-duplicate",
                                  },
                                  {
                                    currentName:
                                      batch_document?.original_file_name,
                                    currentDate: batch_document?.created_at,
                                    currentEmail: batch_document?.email,
                                    originalName:
                                      batch_data.batch_original
                                        ?.original_file_name,
                                    originalDate:
                                      batch_data.batch_original?.created_at,
                                    originalEmail:
                                      batch_data.batch_original?.email,
                                  }
                                )}
                                textStyle={{
                                  textAlign: "center",
                                }}
                              />
                              <Ct.Spacer height={3} />

                              <Button
                                label={intl.formatMessage({
                                  id: "bank-management.transaction.attach-modal.attach-original",
                                })}
                                width={35}
                                onClick={() => {
                                  if (
                                    batch_data.batch_original !== null &&
                                    batch_data.batch_original.id &&
                                    selectedCompanyId
                                  ) {
                                    dispatch(
                                      AttachBatchOnTransactionThunk(
                                        accountID,
                                        transaction.transaction_id,
                                        batch_data.batch_original.id,
                                        selectedCompanyId,
                                        "attachOriginal"
                                      )
                                    )
                                  }
                                }}
                              />
                            </>
                          ) : batch_data?.has_archive_to_generate ? (
                            <>
                              <Text
                                text={intl.formatMessage({
                                  id: `bank-management.transaction.attach-modal.archive-ready${
                                    userTypology === "customer"
                                      ? "-customer"
                                      : ""
                                  }`,
                                })}
                                textStyle={{
                                  textAlign: "center",
                                  color: "orange",
                                }}
                              />
                              <Text
                                text={intl.formatMessage({
                                  id: `bank-management.transaction.attach-modal.archive-ready2${
                                    userTypology === "customer"
                                      ? "-customer"
                                      : ""
                                  }`,
                                })}
                                textStyle={{
                                  textAlign: "center",
                                  color: "orange",
                                }}
                              />
                              <Ct.Spacer height={3} />

                              {userTypology !== "customer" && (
                                <Button
                                  label={intl.formatMessage({
                                    id: "bank-management.transaction.attach-modal.go-to-generate",
                                  })}
                                  onClick={() =>
                                    navigate(
                                      `/office/company/${selectedCompanyId}/writings`
                                    )
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Button label={""} loadingStatus={"loading"} />
                              <Ct.Spacer height={3} />
                              <Text
                                text={intl.formatMessage(
                                  {
                                    id: `bank-management.transaction.attach-modal.batch-doc${
                                      userTypology === "customer"
                                        ? "-customer"
                                        : ""
                                    }`,
                                  },
                                  {
                                    fileName:
                                      batch_document?.original_file_name,
                                    date: batch_document?.created_at,
                                    email: batch_document?.email,
                                  }
                                )}
                                textStyle={{
                                  textAlign: "center",
                                }}
                              />
                            </>
                          )}
                        </WrapperBatchDocument>
                      </LoaderWrapper>
                    </>
                  ) : rowData.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Text
                          text={intl.formatMessage({
                            id: "bank-management.transaction.table-body.empty-full-docs",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <>
                      {rowHeigths.length > 0 && (
                        <GridWrapper totalWidth={width}>
                          <Scroll
                            ref={listRef}
                            height={height - 56}
                            rowCount={rowData.length}
                            width={width}
                            columnWidth={(index) => columnWidths[index]}
                            rowHeight={(index) => rowHeigths[index]}
                            columnCount={columnsFullDocuments.length}
                          >
                            {Cell}
                          </Scroll>
                        </GridWrapper>
                      )}
                    </>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </StyledTable>
      </Part>

      <Part>
        {selectedFullDocument && selectedPage !== null && currentTransaction ? (
          <RightPart>
            <WrapperDocuments
              isMoreThanOnePage={
                selectedFullDocument &&
                selectedFullDocument.documents.length !== 1
              }
            >
              <FullDocumentPages
                documents={
                  selectedFullDocument ? selectedFullDocument?.documents : []
                }
                selectedPage={selectedPage}
                onSelect={(page) => {
                  setSelectedPage(page)
                }}
              />

              <Invoice flex={"90rem 0 1"}>
                <DocumentViewer
                  documentId={
                    selectedFullDocument
                      ? selectedFullDocument?.documents[selectedPage]?.id
                      : 0
                  }
                  rectanglesToDraw={null}
                  isHighlighted={false}
                  width={window.innerHeight < 1000 ? "80%" : "90%"}
                />
              </Invoice>
              <Ct.Spacer width={4} />
            </WrapperDocuments>

            {fullDocNotSelectable(
              selectedFullDocument,
              currentTransaction.amount_type
            ).impossible && (
              <>
                <Ct.Spacer height={2} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: `bank-management.transaction.attach-modal.not-selectable.${
                      fullDocNotSelectable(
                        selectedFullDocument,
                        transaction.amount_type
                      ).documentType
                    }`,
                  })}
                  textStyle={{
                    color: "amaranth",
                  }}
                />
                <Ct.Spacer height={2} />
              </>
            )}

            <StyledButton>
              {attachOrImport === "attach" && (
                <span
                  data-tip={intl.formatMessage({
                    id: "bank-management.transaction.attach-modal.tooltip-warning-detach-batch",
                  })}
                >
                  <Button
                    label={intl.formatMessage({
                      id: "bank-management.transaction.full-document-modal.mark_without_doc",
                    })}
                    width={35}
                    colorType={"Tertiary"}
                    colorScheme={{
                      background: "mist",
                      color: "cornflower",
                      border: "mist",
                    }}
                    disabled={
                      !!transaction.batch_document_id ||
                      !!transaction.full_document_id
                    }
                    onClick={() => {
                      dispatch(
                        MarkWithoutDocumentThunk(
                          accountID,
                          transaction.transaction_id,
                          true
                        )
                      )
                      onClose()
                    }}
                  />
                  {currentTransaction.batch_document_id && (
                    <ReactTooltip
                      delayShow={300}
                      effect={"solid"}
                      eventOff="mouseleave scroll mousewheel blur"
                    />
                  )}
                </span>
              )}

              <Ct.Spacer width={2} height={0} />

              {selectedFullDocument && (
                <>
                  {!(
                    currentTransaction?.full_document_id &&
                    selectedFullDocument.id ===
                      currentTransaction.full_document_id
                  ) && (
                    <Button
                      label={intl.formatMessage({
                        id: currentTransaction?.full_document_id
                          ? `bank-management.transaction.attach-modal.button-replace`
                          : "bank-management.transaction.attach-modal.button-add",
                      })}
                      width={35}
                      disabled={
                        currentTransaction?.full_document_id ===
                          selectedFullDocument?.id ||
                        fullDocNotSelectable(
                          selectedFullDocument,
                          transaction.amount_type
                        ).impossible
                      }
                      onClick={() => {
                        dispatch(
                          AttachFullDocumentToTransactionThunk(
                            accountID,
                            selectedFullDocument.id,
                            transaction.transaction_id
                          )
                        )
                        onClose()
                      }}
                    />
                  )}

                  <Ct.Spacer />

                  {((currentTransaction.batch_document_id &&
                    attachOrImport === "import") ||
                    (currentTransaction?.full_document_id &&
                      selectedFullDocument.id ===
                        currentTransaction.full_document_id)) && (
                    <Button
                      label={intl.formatMessage({
                        id: "bank-management.transaction.attach-modal.detach-batch-doc",
                      })}
                      colorType={"Tertiary"}
                      colorScheme={{
                        background: "amaranth",
                        color: "white",
                        border: "amaranth",
                      }}
                      width={35}
                      onClick={() => {
                        if (!accountID) return
                        dispatch(
                          DetachFullDocumentOfTransactionThunk(
                            accountID,
                            currentTransaction.transaction_id
                          )
                        )
                      }}
                    />
                  )}

                  <Ct.Spacer />
                </>
              )}
            </StyledButton>
          </RightPart>
        ) : (
          currentTransaction && (
            <>
              <EmptyWrapper>
                {currentTransaction.batch_document_id ? (
                  <Download />
                ) : (
                  <StyledZoom />
                )}

                <Ct.Spacer height={4} />
                <Ct.Text
                  text={intl.formatMessage(
                    {
                      id: currentTransaction.batch_document_id
                        ? "bank-management.transaction.attach-modal.download-batch"
                        : !selectedFullDocument && rowData.length === 0
                        ? "bank-management.transaction.attach-modal.empty"
                        : `bank-management.transaction.attach-modal.not-selected`,
                    },
                    {
                      name: batch_document?.original_file_name,
                      date: batch_document?.created_at,
                      email: batch_document?.email,
                    }
                  )}
                  textStyle={{
                    fontSize: 1.75,
                    fontWeight: currentTransaction.batch_document_id
                      ? 400
                      : 600,
                    textAlign: "center",
                    color: currentTransaction.batch_document_id
                      ? "cornflower"
                      : "navy",
                    underline: currentTransaction.batch_document_id
                      ? true
                      : false,
                    cursor: currentTransaction.batch_document_id
                      ? "pointer"
                      : "default",
                  }}
                  onClick={() => {
                    if (currentTransaction.batch_document_id) {
                      dispatch(
                        getUrlForBatchDocumentThunk(
                          currentTransaction.batch_document_id
                        )
                      )
                    }
                  }}
                />
              </EmptyWrapper>

              <Ct.Spacer />

              {currentTransaction.batch_document_id && (
                <StyledButton>
                  <Button
                    label={intl.formatMessage({
                      id: "bank-management.transaction.attach-modal.detach-batch-doc",
                    })}
                    colorType={"Tertiary"}
                    colorScheme={{
                      background: "amaranth",
                      color: "white",
                      border: "amaranth",
                    }}
                    width={35}
                    onClick={() => {
                      if (!accountID) return
                      dispatch(
                        DetachFullDocumentOfTransactionThunk(
                          accountID,
                          currentTransaction.transaction_id
                        )
                      )
                    }}
                  />
                  <Ct.Spacer />
                </StyledButton>
              )}
            </>
          )
        )}
      </Part>
    </Wrapper>
  )
}

export const TransactionAttached = ({
  fullDoc,
  backgroundColor,
}: {
  fullDoc: FullDocumentToAttach
  backgroundColor: Color
}) => {
  const intl = useIntl()

  return (
    <WrapperTransactions>
      <WrapperScroll backgroundColor={backgroundColor}>
        {fullDoc?.transactions_attached?.map((transaction, index) => (
          <TransactionDisplay
            key={String(transaction.id) + String(index) + String(fullDoc.id)}
          >
            <StyledInfo />
            <Ct.Spacer width={1} />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: `bank-management.transaction.attach-modal.transaction-attached`,
                },
                { date: transaction.date, amount: transaction.amount }
              )}
              textStyle={{
                color: "amaranth",
              }}
            />
            <Ct.Spacer height={2} />
          </TransactionDisplay>
        ))}
      </WrapperScroll>
    </WrapperTransactions>
  )
}

const Scroll = styled(VariableSizeGrid)`
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 2px;
  }
`
const WrapperTransactions = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`
const WrapperScroll = styled.div<{ backgroundColor: Color }>`
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  max-height: 7.7rem;
  background-color: ${({ backgroundColor }) =>
    `${colors[backgroundColor]}`}; /* Do not remove, it prevents the rows to overlap */
`
const TransactionDisplay = styled.div`
  text-align: center;
  margin: 1rem 0 0 1rem;
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 96%;
`
const Part = styled.div`
  flex: 1;
  text-align: center;
`
const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`
const Row = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  align-items: center;
  padding-top: 2rem;
`
const ColumnRowFullDocs = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: auto;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
`
const RowFullDocs = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const TableWrapper = styled.div<{ height?: string }>`
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 0rem;
  width: 100%;
  height: ${({ height }) => height};
`
const StyledInput = styled((props) => <Ct.Input {...props} />)<{
  notClickable?: boolean
}>`
  box-shadow: ${boxShadow};
  cursor: ${(props) => (props.notClickable ? "initial" : "pointer")};
  width: 100%;
`
const StyledTable = styled((props) => <TableWrapper {...props} />)<{
  height?: string
}>`
  border: none;
  height: ${({ height }) => height};
`
const StyledFilePlus = styled(({ ...props }) => <File {...props} />)`
  width: 3rem;
  height: 2rem;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  width: 2rem;
  height: 2rem;
`
const StyledInfo = styled(({ ...props }) => <Info {...props} />)`
  width: 2rem;
  height: 2rem;
  & path {
    fill: ${colors.amaranth};
  }
`
const WrapperDocuments = styled.div<{ isMoreThanOnePage: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isMoreThanOnePage ? "end" : "center")};
  width: 100%;
  height: 90%;
`
const Invoice = styled.div<{ flex: string }>`
  align-self: flex-start;
  flex: ${(props) => props.flex};
  border: 2px solid ${colors.grey};
  max-height: 100%;
  overflow: hidden;
  margin-top: 2rem;
`
const EmptyWrapper = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${colors.grey};
  margin-left: auto;
  margin-right: 3rem;
  margin-top: 2rem;
  padding: 0 3rem;
`
const StyledZoom = styled(({ ...props }) => <Zoom {...props} />)`
  width: 6rem;
  height: 6rem;
  & path {
    fill: ${colors.navy};
  }
`
const WrapperSvg = styled.div<{ padding: string }>`
  width: 3rem;
  height: 3rem;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${({ padding }) => padding};
`
const RightPart = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const StyledButton = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
`
const StyledRow = styled(Ct.Row)`
  height: 9rem;
`
const DropCard = styled.div`
  border: 1px solid ${colors.lavender};
  border-radius: 1rem;
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledAddFile = styled(({ ...props }) => <AddFile {...props} />)`
  & path {
    fill: ${colors.cornflower};
  }
`
const WrapperBatchDocument = styled.div`
  margin: auto 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
