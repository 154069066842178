import { Fragment, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useNavigate, useParams } from "react-router-dom"
import * as Ct from "ldlj"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { boxShadow, colors } from "../../../../styles/design.config"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useDispatch } from "react-redux"
import {
  createFiscalYear,
  updateManyFiscalYears,
  FiscalYear,
  getFiscalYears,
  createFiscalYearResetAction,
} from "../../../../store/ducks/fiscalYears.ducks"
import { getIdFromParams } from "../../../../utils/company"
import { CompanyStub } from "../../../../store/ducks/companies.ducks"
import { sortFiscalYears } from "../../../../utils/fiscalYears"
import { getCurrentUserThunk } from "../../../../store/ducks/user.ducks"
import { InputDate } from "../../../../components/Commons/InputDate"
import { Alert } from "../../../../components/Commons/Alert"
/* eslint-disable camelcase */

export const FiscalYears = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const companies = useRNBSelector((state) => state.companies.companies)
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const company = companies[selectedCompanyId] || CompanyStub
  const user = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const fiscalYearsState = useRNBSelector(
    (state) => state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId]
  )
  const createFiscalYearsStatus = useRNBSelector(
    (state) => state.fiscalYears.createFiscalYearsStatus
  )

  const [beginExercise, setBeginExercise] = useState("")
  const [endExercise, setEndExercise] = useState("")
  const [modifiedFiscalYears, setModifiedFiscalYears] = useState<FiscalYear[]>(
    []
  )

  useEffect(() => {
    dispatch(getFiscalYears(company.id))
  }, [dispatch, company])

  useEffect(() => {
    if (!fiscalYearsState) {
      return
    }
    setModifiedFiscalYears(sortFiscalYears(fiscalYearsState))
  }, [setModifiedFiscalYears, fiscalYearsState])

  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  useEffect(() => {
    if (user.typology === "customer") {
      navigate("/unauthorized")
    }
  }, [user.typology, navigate])

  useEffect(() => {
    if (createFiscalYearsStatus === "SUCCESS") {
      setBeginExercise("")
      setEndExercise("")
      dispatch(createFiscalYearResetAction())
    }
  }, [createFiscalYearsStatus])

  const { register, handleSubmit } = useForm({})

  const handleFormSubmit = () => {
    dispatch(
      createFiscalYear({ beginExercise, endExercise, companyId: company.id })
    )
  }

  const handleManyFiscalYearsSubmit = () => {
    dispatch(updateManyFiscalYears(sortFiscalYears(modifiedFiscalYears)))
  }

  /* eslint-disable camelcase */
  useEffect(() => {
    if (user && company.id !== 0) {
      window.userpilot.identify(user.id, {
        name: user.firstName + " " + user.lastName,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        created_at: user.created_at,
        role: user.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [user, fiduciaryInformations, company.id])

  return (
    <Wrapper>
      <StyledSection>
        {modifiedFiscalYears.length > 0 && (
          <FiscalYearComponent>
            <Ct.Text
              text={intl.formatMessage({
                id: "company-office.fiscal-years.create-title",
              })}
              textStyle={{
                lineHeight: 3,
                fontFamily: "Roboto",
                fontSize: 1.9,
                fontWeight: 500,
              }}
            />
            <Ct.Spacer height={2} />

            <CardNewFiscalYear onSubmit={handleSubmit(handleFormSubmit)}>
              <InputGroup>
                <InputDate
                  label={intl.formatMessage({
                    id: "company-register.begin-exercise",
                  })}
                  name="beginExercise"
                  disabled={false}
                  value={beginExercise}
                  onChangeValue={(beginExerciseDate: string) => {
                    setBeginExercise(beginExerciseDate)
                    setEndExercise("")
                  }}
                  register={register as unknown as UseFormRegister<FieldValues>}
                />
                <InputDate
                  label={intl.formatMessage({
                    id: "company-register.end-exercise",
                  })}
                  name="endExercise"
                  disabled={false}
                  value={endExercise}
                  onChangeValue={setEndExercise}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  minimum={beginExercise}
                />
                <Ct.Button
                  type="submit"
                  label={intl.formatMessage({
                    id: "office-company-fiscal-years.create.action",
                  })}
                  width={29}
                />
              </InputGroup>
            </CardNewFiscalYear>

            <Ct.Spacer height={4} />

            <CreateFiscalYear>
              <StyledContent>
                <Ct.Text
                  text={intl.formatMessage(
                    { id: "company-office.fiscal-years-title-years" },
                    { name: company.name }
                  )}
                  textStyle={{
                    lineHeight: 3,
                    fontFamily: "Roboto",
                    fontSize: 1.9,
                    fontWeight: 500,
                  }}
                />
              </StyledContent>
            </CreateFiscalYear>
            <ChangeFiscalYearContent>
              <StickyHead>
                <Titles />
              </StickyHead>
              <ContentSection fiscalYearsLoaded={true}>
                <FullHeightStyledForm
                  onSubmit={handleSubmit(handleManyFiscalYearsSubmit)}
                >
                  <StyledFormContent>
                    {modifiedFiscalYears &&
                      modifiedFiscalYears.map((fiscalYear, index) => (
                        <Fragment key={index}>
                          <FiscalYearLine
                            fiscalYear={fiscalYear}
                            modifiedFiscalYears={modifiedFiscalYears}
                            setModifiedFiscalYears={(fys) => {
                              setModifiedFiscalYears(fys)
                            }}
                            index={index}
                          />
                          {index < modifiedFiscalYears.length - 1 && (
                            <>
                              <Ct.Separator size="full" />
                            </>
                          )}
                        </Fragment>
                      ))}
                  </StyledFormContent>
                  <Ct.Button
                    type={"submit"}
                    label={intl.formatMessage({
                      id: "company-office.fiscal-years.save-change",
                    })}
                    width={29.25}
                    disabled={
                      fiscalYearsState &&
                      JSON.stringify(sortFiscalYears(fiscalYearsState)) ===
                        JSON.stringify(modifiedFiscalYears)
                    }
                  />
                </FullHeightStyledForm>
              </ContentSection>
            </ChangeFiscalYearContent>
          </FiscalYearComponent>
        )}

        {modifiedFiscalYears.length === 0 && (
          <FiscalYearComponent>
            <CreateFiscalYear>
              <StyledContent>
                <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
                  <Alert
                    alertType="info"
                    boxSizing={"border-box"}
                    stretch={true}
                  >
                    <Ct.Text
                      text={intl.formatMessage(
                        { id: "company-office.fiscal-years.create" },
                        { name: company.name }
                      )}
                      textStyle={{ lineHeight: 3, fontFamily: "Roboto" }}
                    />
                  </Alert>

                  <Ct.Spacer height={4} />

                  <InputGroup>
                    <InputDate
                      label={intl.formatMessage({
                        id: "company-register.begin-exercise",
                      })}
                      name="beginExercise"
                      disabled={false}
                      value={beginExercise}
                      onChangeValue={(beginExerciseDate: string) => {
                        setBeginExercise(beginExerciseDate)
                        setEndExercise("")
                      }}
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                    />
                    <Ct.Spacer width={3} />
                    <InputDate
                      label={intl.formatMessage({
                        id: "company-register.end-exercise",
                      })}
                      name="endExercise"
                      disabled={false}
                      value={endExercise}
                      onChangeValue={setEndExercise}
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      minimum={beginExercise}
                    />
                    <Ct.Spacer width={3} />
                    <Ct.Button
                      type="submit"
                      label={intl.formatMessage({
                        id: "office-company-fiscal-years.create.action",
                      })}
                      width={25}
                    />
                  </InputGroup>
                </Ct.StyledForm>
              </StyledContent>
            </CreateFiscalYear>
          </FiscalYearComponent>
        )}
      </StyledSection>
    </Wrapper>
  )
}

const Titles = () => (
  <StyledTextRow>
    <IDTitle>
      <Title tid={"company-office.fiscal-years.begin-exercise"} />
    </IDTitle>
    <IDTitle>
      <Title tid={"company-office.fiscal-years.end-exercise"} />
    </IDTitle>
  </StyledTextRow>
)

const Title = ({ tid }: { tid: string }) => {
  const intl = useIntl()
  return (
    <Ct.Text
      text={intl.formatMessage({ id: tid })}
      textStyle={{
        fontSize: 2,
        fontFamily: "Poppins",
        fontWeight: 700,
        textTransform: "uppercase",
      }}
    />
  )
}

const CardNewFiscalYear = styled(Ct.StyledForm)`
  border: 1px solid ${colors.rock};
  border-radius: 1.5rem;
  padding: 4rem 4rem;
  display: flex;
  justify-content: space-between;
  width: auto;
`
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 5rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`
const StyledContent = styled.div`
  width: 100%;
`
const StyledFormContent = styled.div`
  width: 92%;
  overflow-y: scroll;
  margin-bottom: 4rem;
  height: 100%;
  padding: 0 5rem 1.5rem;
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const IDTitle = styled.div`
  flex: 1;
  text-align: center;
`
const InputGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`
const CreateFiscalYear = styled.div`
  display: flex;
  justify-content: center;
`
const ChangeFiscalYearContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 122.5rem;
  height: 100%;
  padding-top: 2rem;
  overflow: auto;
`
const FiscalYearComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`
const StickyHead = styled.header`
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  height: 7rem;
  width: 100%;
  flex-shrink: 0;
  background-color: ${colors.lavender};
  justify-content: space-between;
`
const StyledTextRow = styled((props) => <Ct.Row {...props} />)`
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
  margin: 2rem 4rem;
`
const DateRow = styled.div`
  width: 100%;
`
const DateContainer = styled((props) => <Ct.Row {...props} />)`
  display: flex;
  justify-content: space-around;
  margin: 2rem;
`
interface WithTransition {
  fiscalYearsLoaded: boolean
}

const ContentSection = styled((props) => (
  <Ct.Card {...props} />
))<WithTransition>`
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: ${({ fiscalYearsLoaded }) => (fiscalYearsLoaded ? "100%" : 0)};
  overflow-x: hidden;
  transition: 0.8s height ease-in-out;
  transform: translateZ(0);
  border: 1px ${colors.lavender} solid;
  box-shadow: unset;
  padding-left: unset;
  padding-right: unset;
`
const FullHeightStyledForm = styled(Ct.StyledForm)`
  height: 100%;
`
interface FiscalYearLineProps {
  fiscalYear: FiscalYear
  modifiedFiscalYears: FiscalYear[]
  setModifiedFiscalYears: (fys: FiscalYear[]) => void
  index: number
}
const FiscalYearLine = ({
  fiscalYear,
  modifiedFiscalYears,
  setModifiedFiscalYears,
  index,
}: FiscalYearLineProps) => {
  const intl = useIntl()

  const [beginExercise, setBeginExercise] = useState("")
  const [endExercise, setEndExercise] = useState("")

  useEffect(() => {
    setBeginExercise(fiscalYear.beginExercise)
    setEndExercise(fiscalYear.endExercise)
  }, [setBeginExercise, setEndExercise, fiscalYear])

  const { register } = useForm({})

  return (
    <DateRow>
      <DateContainer>
        <InputDate
          label={intl.formatMessage({
            id: "company-register.begin-exercise",
          })}
          name="beginExercise"
          disabled={false}
          value={beginExercise}
          onChangeValue={(beginExerciseDate: string) => {
            const modifiedFiscalYearBeginExerice = {
              ...modifiedFiscalYears[index],
              beginExercise: beginExerciseDate,
            }
            const modifiedFiscalYearsBeginExerice = [
              ...modifiedFiscalYears.slice(0, index),
              modifiedFiscalYearBeginExerice,
              ...modifiedFiscalYears.slice(index + 1),
            ]

            setModifiedFiscalYears(modifiedFiscalYearsBeginExerice)
            setBeginExercise(beginExerciseDate)
          }}
          register={register as unknown as UseFormRegister<FieldValues>}
        />
        <InputDate
          label={intl.formatMessage({
            id: "company-register.end-exercise",
          })}
          name="endExercise"
          disabled={false}
          value={endExercise}
          onChangeValue={(endExerciseDate: string) => {
            const modifiedFiscalYearEndExercice = {
              ...modifiedFiscalYears[index],
              endExercise: endExerciseDate,
            }
            const modifiedFiscalYearsEndExercice = [
              ...modifiedFiscalYears.slice(0, index),
              modifiedFiscalYearEndExercice,
              ...modifiedFiscalYears.slice(index + 1),
            ]

            setModifiedFiscalYears(modifiedFiscalYearsEndExercice)
            setEndExercise(endExerciseDate)
          }}
          register={register as unknown as UseFormRegister<FieldValues>}
          minimum={beginExercise}
        />
      </DateContainer>
    </DateRow>
  )
}
