import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { DateTime } from "luxon"
import { TreasuryMerchantHistory } from "../../store/ducks/treasury.ducks"
import { sizes } from "../../styles/design.config"

interface MerchantHistoryModalProps extends Ct.ModalComponentsProps {
  merchantName: string
  userMerchantInstruction: TreasuryMerchantHistory[] | null
}

/* eslint-disable camelcase */
export const MerchantTreasuryHistoryModal = ({
  isDisplayed,
  onClose,
  merchantName,
  userMerchantInstruction,
}: MerchantHistoryModalProps) => {
  const intl = useIntl()

  const close = () => {
    onClose()
  }

  const getHistoryValue = (umi: TreasuryMerchantHistory) => {
    const previous =
      umi.instruction_type === "validate_directly"
        ? umi.metadata.validate_directly
        : umi.instruction_type === "mark_as_paid_directly"
        ? umi.metadata.mark_as_paid_directly
        : false

    return intl.formatMessage({
      id: `treasury.auto-validation.history.modal.${umi.instruction_type}.${previous}`,
    })
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="calc(50vh - 45rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Title
          text={intl.formatMessage({
            id: "treasury.auto-validation.history.modal.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={merchantName}
              textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
            />
          </ChangeHeader>

          {userMerchantInstruction && userMerchantInstruction.length > 0 ? (
            <ChangesList>
              {userMerchantInstruction.map((merchant) => (
                <ChangeItem key={merchant.merchant_id}>
                  <Ct.Text text={getHistoryValue(merchant)} />

                  <Mailto href={`mailto:${merchant.user_email}`}>
                    {merchant.user_email}
                  </Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(
                        new Date(merchant.created_at)
                      ).toFormat("dd/MM/yyyy HH:mm:ss") +
                      ")"
                    }
                  />
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `treasury.auto-validation.history.modal.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={close}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
