import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Alert } from "../Commons/Alert"
import styled from "styled-components/macro"
import { Button } from "./Button"

interface DeleteModalProps extends Ct.ModalComponentsProps {
  titleIntl?: string
  firstTextIntl: string
  secondTextIntl?: string
  deleteItemIntl?: string
  onConfirm: () => void
}

export const DeleteModalTemplate = ({
  onClose,
  isDisplayed,
  titleIntl,
  firstTextIntl,
  secondTextIntl,
  deleteItemIntl,
  onConfirm,
}: DeleteModalProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Title
          text={
            titleIntl
              ? titleIntl
              : intl.formatMessage({
                  id: `delete-title`,
                })
          }
          size={7}
        />
        <Ct.Spacer height={4} />

        <Alert alertType="info">
          <Ct.Text
            text={firstTextIntl}
            textStyle={{
              fontWeight: 600,
            }}
          />
          <Ct.Spacer height={2} />
          <Ct.Text
            text={
              secondTextIntl
                ? secondTextIntl
                : intl.formatMessage({
                    id: `delete-text`,
                  })
            }
          />
        </Alert>

        <Ct.Spacer height={4} />
        <Controls>
          <Button
            label={intl.formatMessage({
              id: `cancel`,
            })}
            colorType="Tertiary"
            onClick={() => {
              onClose()
            }}
            width={30}
            colorScheme={{
              color: "cornflower",
              background: "mist",
              border: "mist",
            }}
          />
          <Ct.Spacer height={4} />
          <Ct.Button
            label={
              deleteItemIntl
                ? deleteItemIntl
                : intl.formatMessage({
                    id: `delete-item`,
                  })
            }
            onClick={() => {
              onConfirm()
            }}
            width={30}
          />
        </Controls>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Controls = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
