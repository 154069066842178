import styled from "styled-components/macro"
import { colors, gradients } from "../styles/design.config"
import { useIntl } from "react-intl"
import { UserTypes } from "../model/users"
import { useDispatch } from "react-redux"
import { downloadWritingThunk } from "../store/ducks/writings.ducks"
import { useEffect, useState } from "react"

interface ArchiveDownProps {
  userTypology: UserTypes | ""
  selectedCompanyId: number
  archiveId: number
  archiveDownload?: string | null
}

export const ArchiveDownLink = ({
  userTypology,
  selectedCompanyId,
  archiveId,
  archiveDownload,
}: ArchiveDownProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [downloadArchive, setDownloadArchive] = useState(false)

  useEffect(() => {
    if (archiveDownload !== null) {
      setDownloadArchive(true)
    }
  }, [dispatch, archiveDownload])

  return (
    <>
      {userTypology !== "customer" ? (
        <ArchiveLinkDownload
          id={"archiveLink"}
          onClick={() => {
            dispatch(downloadWritingThunk(archiveId, selectedCompanyId))
            setDownloadArchive(true)
          }}
          archiveDownload={downloadArchive}
        >
          {intl.formatMessage({
            id: `ged.other-files.number`,
          })}
          {archiveId}
        </ArchiveLinkDownload>
      ) : (
        <ArchiveLink
          id={"archiveLinkCustomer"}
          archiveDownload={downloadArchive}
        >
          {intl.formatMessage({
            id: `ged.other-files.number`,
          })}
          {archiveId}
        </ArchiveLink>
      )}
    </>
  )
}

interface RowContentProps {
  archiveDownload: boolean
}

const ArchiveLink = styled.a<RowContentProps>`
  background: ${({ archiveDownload }) =>
    archiveDownload ? colors.mist : gradients.blue};
  color: ${({ archiveDownload }) =>
    archiveDownload ? colors.cornflower : colors.white};
  border-radius: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem;
  height: 3rem;

  :hover {
    background-color: ${({ archiveDownload }) =>
      archiveDownload ? colors.mist : gradients.blue};
    color: ${({ archiveDownload }) =>
      archiveDownload ? colors.cornflower : colors.white};
  }
`

const ArchiveLinkDownload = styled(ArchiveLink)`
  cursor: pointer;
`
