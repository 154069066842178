import {
  ArchiveStatus,
  WritingsLineToDisplay,
} from "../routes/office/company/writings/Writings"
import { FiscalYear } from "../store/ducks/fiscalYears.ducks"
import { convertToMonth } from "./fiscalYears"

export function sortWritingsByArchiveId(
  writingsLines: WritingsLineToDisplay[],
  asc: boolean
): WritingsLineToDisplay[] {
  let sortedLines = []
  const pending = writingsLines.find((line) => line.status === "PENDING")
  if (pending) {
    sortedLines.push(pending)
  }

  const readyForArchive = writingsLines.find(
    (line) => line.status === "READY_FOR_ARCHIVE"
  )
  if (readyForArchive) {
    sortedLines.push(readyForArchive)
  }

  const created = writingsLines.filter((line) => line.status === "CREATED")
  if (created) {
    sortedLines.push(...created)
  }

  const linesWithoutTopThree = writingsLines.filter(
    (line) => !["PENDING", "READY_FOR_ARCHIVE", "CREATED"].includes(line.status)
  )

  sortedLines.push(...sortWritingsById(asc, linesWithoutTopThree))
  return sortedLines
}

export const sortWritingsById = (
  asc: boolean,
  writingsLines: WritingsLineToDisplay[]
): WritingsLineToDisplay[] => {
  return writingsLines.sort((a, b) =>
    asc
      ? (a?.archiveId || 0) - (b?.archiveId || 0)
      : (b?.archiveId || 0) - (a?.archiveId || 0)
  )
}

export const sortWritingsByProgress = (
  writingsLines: WritingsLineToDisplay[],
  asc: boolean
) => {
  let sortedLines = writingsLines.sort((a, b) =>
    asc ? a.progress - b.progress : b.progress - a.progress
  )
  return sortedLines
}

export const sortWritingsByEmail = (
  writingsLines: WritingsLineToDisplay[],
  asc: boolean
) => {
  let sortedLines = []
  const linesWithoutUser = writingsLines.filter((lines) => !lines.downloadUser)

  const LinesWithUser = writingsLines
    .filter((lines) => lines.downloadUser)
    .sort((a, b) =>
      asc
        ? String(a.downloadUser).localeCompare(String(b.downloadUser))
        : String(b.downloadUser).localeCompare(String(a.downloadUser))
    )

  sortedLines.push(...linesWithoutUser)
  sortedLines.push(...LinesWithUser)

  return sortedLines
}

export const sortWritingsByDate = (
  writingsLines: WritingsLineToDisplay[],
  asc: boolean
) => {
  let sortedLines = []
  const linesWithoutDate = writingsLines.filter((line) => !line.downloadDate)

  const linesWithDate = writingsLines
    .filter((line) => line.downloadDate)
    .sort((a, b) =>
      asc
        ? +new Date(String(a.downloadDate)) - +new Date(String(b.downloadDate))
        : +new Date(String(b.downloadDate)) - +new Date(String(a.downloadDate))
    )

  sortedLines.push(...linesWithoutDate)
  sortedLines.push(...linesWithDate)

  return sortedLines
}

export const sortWritingArchiveDownloadByDate = (
  allEvents: {
    email: string
    created_at: string
  }[]
) => {
  return [
    ...allEvents.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  ]
}

export const showFiscalYear = (
  status: ArchiveStatus,
  selectedFiscalYear: FiscalYear | null | undefined
) => {
  if (status === "PENDING" || !selectedFiscalYear) return ""

  return `(${convertToMonth(selectedFiscalYear?.beginExercise)} -
    ${convertToMonth(selectedFiscalYear?.endExercise)})`
}

export const setProgressBarColor = (status: ArchiveStatus) => {
  switch (status) {
    case "PENDING":
      return "cornflower"
    case "WRITING_READY":
      return "purple"
    case "READY_FOR_ARCHIVE":
      return "shamrock"
    case "CREATED":
      return "moon"
    case "GENERATED":
      return "moon"
  }
}

export const setSubtitle = (
  status: ArchiveStatus,
  docAmount: number,
  batchNumber: number
) => {
  const seeDoc =
    docAmount === 1 ? `Voir le document` : `Voir les ${docAmount} documents`
  const seeBatch = batchNumber === 1 ? `(1 dépôt)` : `(${batchNumber} dépôts)`
  switch (status) {
    case "PENDING":
      return batchNumber === 1
        ? `Voir le dépôt`
        : `Voir les ${batchNumber} dépôts`
    case "WRITING_READY":
      return batchNumber === 1
        ? `Voir le dépôt`
        : `Voir les ${batchNumber} dépôts`
    case "READY_FOR_ARCHIVE":
      return `${seeDoc} ${seeBatch}`
    case "CREATED":
      return `${seeDoc} ${seeBatch}`
    case "GENERATED":
      return `${seeDoc} ${seeBatch}`
  }
}
