import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Option, Select, emptyOptionValue } from "../Commons/Select"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Input } from "../Commons/Input"
import { LockableInput } from "../Commons/LockableInput"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../store/rootReducer"
import {
  AccountRules,
  BankLineType,
  CompareAmountType,
  CreateOrEditRuleReset,
  CreateRuleForOneTransactionThunk,
  EditRuleThunk,
  NewRuleParams,
  PrefixType,
  Transaction,
  createRuleThunk,
} from "../../store/ducks/bank.ducks"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { Alert } from "../Commons/Alert"
import { ReactComponent as Bulb } from "../../assets/bulb.svg"
import { filterAccountsForAssignment, prefixValues } from "../../utils/bank"
import ReactTooltip from "react-tooltip"

/* eslint-disable camelcase */

interface RuleCreatePartsProps {
  isDisplayed: boolean
  onClose: () => void
  rulesLength: number
  createOrEdit: "create" | "edit" | "createForOneTransaction"
  ruleToEdit: AccountRules | null
  width?: string
  from: "rules" | "transactions"
  createForOneTransaction: Transaction | null
  selectedTab?: "createForOneTransaction" | "create"
  disableAll: boolean
}

export const RuleCreateParts = ({
  isDisplayed,
  onClose,
  rulesLength,
  createOrEdit,
  ruleToEdit,
  width,
  from,
  createForOneTransaction,
  selectedTab,
  disableAll,
}: RuleCreatePartsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const accountID = getIdFromParams(useParams())("account_id")
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId || 0]
  )
  const dataToCreateRule = useRNBSelector(
    (state) => state.bank.dataToCreateRule
  )
  const createOrEditRuleStatus = useRNBSelector(
    (state) => state.bank.createOrEditRuleStatus
  )

  const codeOptions: Array<Option<string>> =
    dataToCreateRule?.merchant_codes.map((c) => {
      return { value: String(c.id), label: c.code }
    })
  codeOptions.unshift(emptyOptionValue)

  const vatRateOptions: Array<Option<string>> = dataToCreateRule?.vat_rates.map(
    (r) => {
      return { value: String(r.id), label: String(r.rate) }
    }
  )
  vatRateOptions.unshift(emptyOptionValue)

  const priorityOptions: Array<Option<string>> = Array.from(
    { length: rulesLength },
    (_, index) => ({ value: String(index + 1), label: String(index + 1) })
  )
  const inOutOptions: Array<Option<string>> = [
    { value: "out", label: "Sortie" },
    { value: "in", label: "Entrée" },
  ]
  const compareAmountOptions: Array<Option<string>> = [
    emptyOptionValue,
    { value: "greater", label: "Supérieur" },
    { value: "lesser", label: "Inférieur" },
    { value: "equal", label: "Egal" },
  ]

  const prefixOptions: Option<string>[] = prefixValues.map((prefix) => ({
    value: prefix,
    label: prefix,
  }))

  const [priority, setPriority] = useState<Option<string> | null>(
    priorityOptions[0]
  )
  const [textInDescription, SetTextInDescription] = useState<string>("")
  const [inOut, setInOut] = useState<Option<string>>(inOutOptions[0])
  const [compareAmount, setCompareAmount] =
    useState<Option<string>>(emptyOptionValue)
  const [amount, setAmount] = useState<string>("")
  const [prefix, setPrefix] = useState<Option<string>>(
    prefixOptions.find((p) => p.value === "6") || emptyOptionValue
  )
  const [newAccount, setNewAccount] = useState<Option<string>>(emptyOptionValue)
  const [vatRate, setVatRate] = useState<Option<string>>(emptyOptionValue)
  const [accountOptions, setAccountOptions] = useState<Array<Option<string>>>(
    []
  )

  useEffect(() => {
    if (prefix && dataToCreateRule.accounts.length > 0) {
      setAccountOptions(
        filterAccountsForAssignment(dataToCreateRule?.accounts, prefix.value)
      )
    }
  }, [prefix, dataToCreateRule])

  useEffect(() => {
    if (ruleToEdit) {
      reset()
      if (createOrEdit !== "createForOneTransaction") {
        setPriority(
          priorityOptions.find(
            (o) => o.value === String(ruleToEdit.priority_number)
          ) || priorityOptions[0]
        )
        setAmount(
          ruleToEdit.amount !== null && ruleToEdit.amount !== undefined
            ? String(ruleToEdit.amount)
            : ""
        )
        setInOut(
          inOutOptions.find((o) => o.value === ruleToEdit.bank_line_type) ||
            inOutOptions[0]
        )
        setCompareAmount(
          ruleToEdit.compare_amount
            ? compareAmountOptions.find(
                (o) => o.value === ruleToEdit.compare_amount
              ) || emptyOptionValue
            : emptyOptionValue
        )
      }

      if (createOrEdit !== "create") {
        const description = ruleToEdit
          ? ruleToEdit.text_in_description || ""
          : createForOneTransaction && selectedTab === "createForOneTransaction"
          ? createForOneTransaction.description
          : ""
        SetTextInDescription(description)
        setPrefix(
          prefixOptions.find((o) => o.value === ruleToEdit.prefix) ||
            prefixOptions[0]
        )

        if (isMerchantCodeOrAccount(ruleToEdit.prefix) === "merchantCode") {
          setNewAccount(
            ruleToEdit.value_account_id
              ? codeOptions.find(
                  (o) => o.value === String(ruleToEdit.value_account_id)
                ) || emptyOptionValue
              : emptyOptionValue
          )
        } else {
          setNewAccount(
            ruleToEdit.value_account_id
              ? filterAccountsForAssignment(
                  dataToCreateRule?.accounts,
                  ruleToEdit.prefix
                ).find(
                  (o) => o.value === String(ruleToEdit.value_account_id)
                ) || emptyOptionValue
              : emptyOptionValue
          )
        }
        setVatRate(
          ruleToEdit.rate
            ? vatRateOptions.find((o) => o.label === String(ruleToEdit.rate)) ||
                emptyOptionValue
            : emptyOptionValue
        )
      }
    }
  }, [ruleToEdit, dataToCreateRule, selectedTab])

  useEffect(() => {
    if (createForOneTransaction && selectedTab === "createForOneTransaction") {
      setInOut(
        createForOneTransaction.amount_type === "negative"
          ? inOutOptions[0]
          : inOutOptions[1]
      )
      SetTextInDescription(
        createForOneTransaction && selectedTab === "createForOneTransaction"
          ? createForOneTransaction.description
          : ""
      )
    }
  }, [createForOneTransaction, dataToCreateRule, selectedTab])

  useEffect(() => {
    if (!isDisplayed) {
      reset()
    }
  }, [dispatch, isDisplayed])

  useEffect(() => {
    if (createOrEditRuleStatus === "SUCCESS" && from === "rules") {
      dispatch(CreateOrEditRuleReset())
      onClose()
    } else if (
      createOrEditRuleStatus === "SUCCESS" &&
      from === "transactions" &&
      createOrEdit === "createForOneTransaction"
    ) {
      onClose()
      reset()
    } else if (
      createOrEditRuleStatus === "SUCCESS" &&
      from === "transactions"
    ) {
      reset()
    }
  }, [createOrEditRuleStatus])

  const reset = () => {
    dispatch(CreateOrEditRuleReset())
    setPriority(priorityOptions[0])
    SetTextInDescription("")
    setAmount("")
    setInOut(inOutOptions[0])
    setPrefix(prefixOptions[0])
    setCompareAmount(emptyOptionValue)
    setNewAccount(emptyOptionValue)
    setVatRate(emptyOptionValue)
  }

  const disableAssignment =
    inOut.value ||
    textInDescription !== "" ||
    (amount !== "" && compareAmount.value !== "") ||
    createOrEdit === "createForOneTransaction"

  const onboardingUnfinished =
    !dataToCreateRule.company_data ||
    (dataToCreateRule.merchant_codes.length === 0 &&
      dataToCreateRule.accounts.length === 0)

  const preview = () => {
    if (
      !disableAssignment ||
      !dataToCreateRule.company_data ||
      onboardingUnfinished
    ) {
      return ""
    }

    if (isMerchantCodeOrAccount(prefix.value) === "merchantCode") {
      let code = dataToCreateRule?.merchant_codes.find(
        (c) => c.id === Number(newAccount.value)
      )

      let partyAccount: "buy_third_party_account" | "sell_third_party_account" =
        prefix.value === "401"
          ? "buy_third_party_account"
          : "sell_third_party_account"
      let auxiliaryPrefix: "buy_auxiliary_prefix" | "sell_auxiliary_prefix" =
        prefix.value === "401"
          ? "buy_auxiliary_prefix"
          : "sell_auxiliary_prefix"
      if (code?.auxiliary) {
        return (
          dataToCreateRule.company_data[`${partyAccount}`] +
          ", " +
          dataToCreateRule.company_data[`${auxiliaryPrefix}`] +
          newAccount.label
        )
      } else {
        return (
          prefix.value +
          dataToCreateRule.company_data[`${auxiliaryPrefix}`] +
          newAccount.label
        )
      }
    } else {
      return (
        newAccount.label +
        (vatRate.value !== "" ? " " + vatRate.label + "% TVA" : "")
      )
    }
  }

  const isMerchantCodeOrAccount = (value: string) => {
    return value === "401" || value === "411" ? "merchantCode" : "account"
  }

  const saveRule = () => {
    if (createOrEdit === "createForOneTransaction" && createForOneTransaction) {
      const newRule = {
        account_id:
          isMerchantCodeOrAccount(prefix.value) === "account"
            ? Number(newAccount.value)
            : null,
        merchant_code_id:
          isMerchantCodeOrAccount(prefix.value) === "merchantCode"
            ? Number(newAccount.value)
            : null,
        prefix: prefix.value as PrefixType,
        text_in_description:
          textInDescription === "" ? null : textInDescription,
        vat_rate_id: vatRate.value === "" ? null : Number(vatRate.value),
      }
      dispatch(
        CreateRuleForOneTransactionThunk(
          newRule,
          accountID || 0,
          createForOneTransaction.transaction_id
        )
      )
    } else if (accountID) {
      const newRule: NewRuleParams = {
        account_id:
          isMerchantCodeOrAccount(prefix.value) === "account"
            ? Number(newAccount.value)
            : null,
        amount: amount === "" ? null : Number(amount),
        bank_line_type: inOut.value as BankLineType,
        compare_amount:
          compareAmount.value === ""
            ? null
            : (compareAmount.value as CompareAmountType),
        merchant_code_id:
          isMerchantCodeOrAccount(prefix.value) === "merchantCode"
            ? Number(newAccount.value)
            : null,
        prefix: prefix.value as PrefixType,
        priority_number: Number(priority?.value),
        text_in_description:
          textInDescription === "" ? null : textInDescription,
        vat_rate_id: vatRate.value === "" ? null : Number(vatRate.value),
      }

      if (createOrEdit === "create") {
        dispatch(createRuleThunk(newRule, accountID, from))
      } else if (ruleToEdit) {
        dispatch(EditRuleThunk(newRule, accountID, ruleToEdit?.id, false))
      }
    }
  }

  const checkIfRuleChanged = () => {
    if (createOrEdit === "create") return false
    if (ruleToEdit === null) return false

    const accountOrMerchantCode = () => {
      if (isMerchantCodeOrAccount(ruleToEdit.prefix) === "merchantCode") {
        return (
          codeOptions.find(
            (o) => o.value === String(ruleToEdit.value_account_id)
          ) || emptyOptionValue
        )
      } else {
        return (
          accountOptions.find(
            (o) => o.value === String(ruleToEdit.value_account_id)
          ) || emptyOptionValue
        )
      }
    }

    const check =
      (createOrEdit !== "createForOneTransaction"
        ? priority?.value === String(ruleToEdit?.priority_number)
        : true) &&
      textInDescription === ruleToEdit?.text_in_description &&
      amount === (ruleToEdit.amount ? String(ruleToEdit.amount) : "") &&
      (createOrEdit !== "createForOneTransaction"
        ? inOut.value === ruleToEdit.bank_line_type
        : true) &&
      compareAmount.value ===
        (ruleToEdit.compare_amount ? ruleToEdit.compare_amount : "") &&
      prefix.value === ruleToEdit.prefix &&
      vatRate.label === (ruleToEdit.rate || "") &&
      newAccount.value === accountOrMerchantCode().value

    return check
  }

  const disableVat =
    company?.disable_vat_accounts ||
    !disableAssignment ||
    isMerchantCodeOrAccount(prefix.value) === "merchantCode" ||
    onboardingUnfinished ||
    disableAll ||
    (isMerchantCodeOrAccount(prefix.value) === "account" &&
      !["6", "7"].includes(prefix.value))

  return (
    <Wrapper width={width}>
      {createOrEdit !== "createForOneTransaction" && (
        <Trigger>
          {createOrEdit === "create" && (
            <>
              <Ct.Row>
                <StyledBulb />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "bank-management.transaction.detail.rules.warning",
                  })}
                  textStyle={{
                    color: disableAll ? "moon" : "navy",
                  }}
                />
              </Ct.Row>
              <Ct.Spacer />
            </>
          )}

          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.rules.add-rule.trigger",
            })}
            textStyle={{
              fontWeight: 700,
              fontSize: 2,
              textTransform: "uppercase",
              color: disableAll ? "moon" : "navy",
            }}
          />
          <Ct.Spacer height={4} />

          <Ct.Row>
            <Select
              intl={intl}
              options={priorityOptions}
              value={priority}
              onChangeCallback={(e: Ct.Option<string>) => {
                setPriority(e)
              }}
              label={""}
              domain={"bank-management.rules.add-rule"}
              optionType={"priority"}
              defaultValue={priorityOptions[priorityOptions.length - 1]}
              disabled={disableAll}
            />

            <Ct.Spacer width={4} />

            <Input
              label={intl.formatMessage({
                id: "bank-management.rules.add-rule.text-in-description",
              })}
              value={textInDescription}
              onChange={(e) => {
                SetTextInDescription(e.target.value)
              }}
              disabled={disableAll}
            />
          </Ct.Row>

          <Ct.Spacer height={4} />

          <Ct.Row>
            <Select
              intl={intl}
              options={inOutOptions}
              value={inOut}
              onChangeCallback={(e: Ct.Option<string>) => {
                setInOut(e)
              }}
              label={""}
              domain={"bank-management.rules.add-rule"}
              optionType={"in-out"}
              defaultValue={inOutOptions[0]}
              disabled={disableAll}
            />

            <Ct.Spacer width={2} />

            <Select
              intl={intl}
              options={compareAmountOptions}
              value={compareAmount}
              onChangeCallback={(e: Ct.Option<string>) => {
                setCompareAmount(e)
              }}
              label={""}
              domain={"bank-management.rules.add-rule"}
              optionType={"compare-amount"}
              defaultValue={compareAmountOptions[0]}
              warning={compareAmount.value === "" && amount !== ""}
              disabled={disableAll}
            />

            <Ct.Spacer width={2} />

            <Input
              label={intl.formatMessage({
                id: "bank-management.rules.add-rule.amount",
              })}
              value={amount}
              showWarning={compareAmount?.value !== "" && amount === ""}
              onChange={(e) => {
                setAmount(e.target.value.replace(/\D/g, ""))
              }}
              disabled={disableAll}
            />
          </Ct.Row>

          <ErrorBlock>
            <Ct.Spacer width={2} />
            <ErrorDisplay>
              {compareAmount?.value === "" && amount !== "" ? (
                <Ct.Text
                  text={intl.formatMessage({
                    id: `bank-management.rules.add-rule.amount.no-compare`,
                  })}
                  textStyle={{
                    color: "orange",
                    fontSize: 1.75,
                  }}
                />
              ) : (
                <Ct.Spacer width={2} />
              )}
            </ErrorDisplay>
            <ErrorDisplay>
              {compareAmount.value !== "" && amount === "" ? (
                <Ct.Text
                  text={intl.formatMessage({
                    id: `bank-management.rules.add-rule.amount.no-amount`,
                  })}
                  textStyle={{
                    color: "orange",
                    fontSize: 1.75,
                  }}
                />
              ) : (
                <Ct.Spacer width={2} />
              )}
            </ErrorDisplay>
          </ErrorBlock>
        </Trigger>
      )}

      <Assignment>
        <Ct.Text
          text={intl.formatMessage({
            id: "bank-management.rules.add-rule.assignment",
          })}
          textStyle={{
            color: disableAll ? "moon" : "navy",
            fontWeight: 700,
            fontSize: 2,
            textTransform: "uppercase",
          }}
        />
        <Ct.Spacer height={2} />

        {onboardingUnfinished && (
          <>
            <Alert alertType="warning">
              <Ct.Text
                text={intl.formatMessage({
                  id: "bank-management.rules.onboarding_unfinished",
                })}
                textStyle={{ lineHeight: 3 }}
              />
            </Alert>
            <Ct.Spacer height={2} />
          </>
        )}

        <Ct.Spacer height={2} />

        <Ct.Row>
          <Select
            intl={intl}
            options={prefixOptions}
            value={prefix}
            onChangeCallback={(e: Ct.Option<string>) => {
              if (
                isMerchantCodeOrAccount(e.value) === "merchantCode" &&
                isMerchantCodeOrAccount(prefix.value) === "account"
              ) {
                setNewAccount(emptyOptionValue)
                setVatRate(emptyOptionValue)
              } else if (
                isMerchantCodeOrAccount(e.value) === "account" &&
                isMerchantCodeOrAccount(prefix.value) === "merchantCode"
              ) {
                setNewAccount(emptyOptionValue)
              } else if (
                isMerchantCodeOrAccount(e.value) === "account" &&
                isMerchantCodeOrAccount(prefix.value) === "account"
              ) {
                if (e.value !== prefix.value) {
                  setNewAccount(emptyOptionValue)
                }
              }
              setPrefix(e)
            }}
            label={""}
            domain={"bank-management.rules.add-rule"}
            optionType={"prefix-assignment"}
            defaultValue={prefixOptions[0]}
            customWidth={"50%"}
            disabled={!disableAssignment || onboardingUnfinished || disableAll}
          />

          <Ct.Spacer width={2} />

          <Select
            intl={intl}
            options={
              isMerchantCodeOrAccount(prefix.value) === "merchantCode"
                ? codeOptions
                : accountOptions
            }
            value={newAccount}
            onChangeCallback={(e: Ct.Option<string>) => {
              setNewAccount(e)
            }}
            label={""}
            domain={"bank-management.rules.add-rule"}
            optionType={"assignment"}
            defaultValue={emptyOptionValue}
            disabled={!disableAssignment || onboardingUnfinished || disableAll}
          />
          <Ct.Spacer width={2} />

          <DivToolTip
            data-tip={
              disableVat
                ? intl.formatMessage({
                    id: company?.disable_vat_accounts
                      ? "bank-management.rules.add-rule.tooltip.disable-vat"
                      : "bank-management.rules.add-rule.tooltip.no-vat-for-accounts",
                  })
                : ""
            }
          >
            <Select
              intl={intl}
              options={vatRateOptions}
              value={vatRate}
              onChangeCallback={(e: Ct.Option<string>) => {
                setVatRate(e)
              }}
              label={""}
              domain={"bank-management.rules.add-rule"}
              optionType={"vat-rate"}
              defaultValue={vatRateOptions[0]}
              customWidth={"100%"}
              disabled={
                company?.disable_vat_accounts ||
                !disableAssignment ||
                isMerchantCodeOrAccount(prefix.value) === "merchantCode" ||
                onboardingUnfinished ||
                disableAll ||
                (isMerchantCodeOrAccount(prefix.value) === "account" &&
                  !["6", "7"].includes(prefix.value))
              }
            />
          </DivToolTip>

          <ReactTooltip
            delayShow={300}
            effect={"solid"}
            eventOff="mouseleave scroll mousewheel blur"
          />
        </Ct.Row>

        <Ct.Spacer height={4} />

        <Ct.Row>
          <LockableInput
            label={intl.formatMessage({
              id: "bank-management.rules.add-rule.preview",
            })}
            value={preview()}
            locked={true}
            disabled={onboardingUnfinished || disableAll}
          />
        </Ct.Row>
      </Assignment>

      <Ct.Spacer height={4} />

      <ButtonCenter>
        <Ct.Button
          label={intl.formatMessage({
            id: `bank-management.rules.${createOrEdit}-rule.cta`,
          })}
          width={40}
          onClick={() => {
            saveRule()
          }}
          disabled={
            !disableAssignment ||
            newAccount.value === "" ||
            checkIfRuleChanged() ||
            onboardingUnfinished ||
            disableAll
          }
        />
      </ButtonCenter>
    </Wrapper>
  )
}

interface WrapperProps {
  width?: string
}

const Wrapper = styled.div<WrapperProps>`
  align-items: flex-start;
  width: ${({ width }) => (width ? width : "80%")};
  margin: 0 auto;
`
const Trigger = styled.div``
const Assignment = styled.div``

const ErrorDisplay = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: center;
`
const ErrorBlock = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledBulb = styled(Bulb)`
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
`
const ButtonCenter = styled.div`
  display: flex;
  justify-content: center;
`
const DivToolTip = styled.div`
  width: 50%;
`
