import { AnyAction, applyMiddleware, createStore } from "redux"
import thunkMiddleware, { ThunkAction } from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer, { RootState } from "./rootReducer"
import { notificationMiddleware } from "./notifications.config"

export type RNBThunkAction = ThunkAction<
  Promise<unknown>,
  RootState,
  unknown,
  AnyAction
>

export interface Dispatch<A = AnyAction | RNBThunkAction> {
  <T extends A>(action: T): T
}

export default function configureStore() {
  const middlewares = [thunkMiddleware, notificationMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, {}, composedEnhancers)

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./rootReducer", () => store.replaceReducer(rootReducer))
  }

  return store
}
