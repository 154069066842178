/* eslint-disable camelcase */
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { BaseSyntheticEvent, useEffect } from "react"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import * as Ct from "ldlj"
import { yupResolver } from "@hookform/resolvers/yup"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"

import { colors } from "../../../../styles/design.config"
import { ReactComponent as CloseCross } from "../../../../assets/close-cross.svg"
import { ReactComponent as Info } from "../../../../assets/info.svg"
import {
  getMailMessage,
  multipleEmailsValidation,
  optionalMultipleEmailsValidation,
} from "../../../../utils/emails"
import { Input } from "../../../../components/Commons/Input"
import {
  InvoicePayload,
  sendReminderReset,
  sendReminderThunk,
} from "../../../../store/ducks/invoicing.duck"
import { useRNBSelector } from "../../../../store/rootReducer"

interface ReminderModalProps extends Ct.ModalComponentsProps {
  invoicingDocument: InvoicePayload | undefined
}

export const ReminderModal = ({
  isDisplayed,
  onClose,
  invoicingDocument,
}: ReminderModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const schema = yup.object({
    emails: multipleEmailsValidation.required(),
    bccEmails: optionalMultipleEmailsValidation,
    object: yup.string().required(),
    message: yup.string().required(),
  })

  const { userEmail, sendReminderStatus } = useRNBSelector((state) => ({
    userEmail: state.user.email,
    sendReminderStatus: state.invoicing.sendReminderStatus,
  }))

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    reValidateMode: "onChange",
  })

  const emails: string = watch("emails")
  const bccEmails: string = watch("bccEmails")
  const object: string = watch("object")
  const message: string = watch("message")

  const invoiceType =
    invoicingDocument?.documentType === "invoice" ? "invoicing" : "quotation"
  useEffect(() => {
    if (sendReminderStatus === "SUCCESS") {
      dispatch(sendReminderReset())
      onClose()
    }
  }, [sendReminderStatus])
  // PRE-SET VALUES
  useEffect(() => {
    if (isDisplayed && invoicingDocument) {
      setValue("emails", invoicingDocument.emailedTo.join(","))
      setValue("bccEmails", "")
      setValue(
        "object",
        intl.formatMessage(
          {
            id: `${invoiceType}.issuance.email.object`,
          },
          { numberingPreview: invoicingDocument.numbering }
        )
      )

      const message = getMailMessage({
        invoiceType,
        numberingPreview: invoicingDocument.numbering,
        companyName: invoicingDocument.client,
        vatIncludedTotal: invoicingDocument.amount,
        dueDate: invoicingDocument.dueDate,
        intl,
      })

      setValue("message", message)
    }
  }, [isDisplayed, setValue, invoicingDocument])

  const handleFormSubmit = () => {
    if (!invoicingDocument?.id) {
      return
    }

    const htmlMessage = message.replaceAll("\n", "<br/>")
    dispatch(
      sendReminderThunk({
        to: emails.split(","),
        bcc: bccEmails.split(","),
        cc: [userEmail],
        subject: object,
        message: htmlMessage,
        from: userEmail,
        invoicing_document_id: invoicingDocument.id,
      })
    )
  }

  return (
    <Ct.Modal
      left="50%"
      right="50%"
      top="15%"
      isDisplayed={isDisplayed}
      onClose={onClose}
    >
      <Ct.Card width="calc(50vw - 4rem)" height="calc(65vh - 4rem)" padding="0">
        <FullHeight>
          <HeaderTitleWrapper>
            <Ct.Text
              text={intl.formatMessage({
                id: `reminder.modal.title`,
              })}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 2.5,
              }}
            />
            <Close onClick={onClose}>
              <CloseCross />
            </Close>
          </HeaderTitleWrapper>

          <Ct.Separator size="full" color="lightBlueGrey" />

          <Form
            onSubmit={(
              e: BaseSyntheticEvent<object, unknown, unknown> | undefined
            ) => {
              handleSubmit(handleFormSubmit, (errorsCallback) => {})(e)
            }}
          >
            <Input
              label={intl.formatMessage({
                id: "invoicing.preview.modal.emails",
              })}
              value={emails}
              name={"emails"}
              register={register as unknown as UseFormRegister<FieldValues>}
              showError={errors[`emails`]?.message.includes(
                "Toutes les valeurs ne sont pas des emails"
              )}
            />
            <Ct.Spacer />
            <Explanation>
              <StyledInfo />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.email.explanation",
                })}
                textStyle={{
                  fontSize: 1.5,
                }}
              />
            </Explanation>

            <Ct.Spacer />
            <Input
              required={false}
              label={intl.formatMessage({
                id: "invoicing.preview.modal.bccEmails",
              })}
              value={bccEmails}
              name={"bccEmails"}
              register={register as unknown as UseFormRegister<FieldValues>}
              showError={errors[`bccEmails`]?.message?.includes(
                "Toutes les valeurs ne sont pas des emails"
              )}
            />
            <Ct.Spacer />
            <Input
              label={intl.formatMessage({
                id: "invoicing.preview.modal.object",
              })}
              value={object}
              name={"object"}
              register={register as unknown as UseFormRegister<FieldValues>}
              showError={errors[`object`]?.message}
            />
            <Ct.Spacer />
            <Input
              AutoGrowing={true}
              height="18rem"
              label={intl.formatMessage({
                id: "invoicing.preview.modal.message",
              })}
              value={message}
              name={"message"}
              register={register as unknown as UseFormRegister<FieldValues>}
              showError={errors[`message`]?.message}
            />
            <Ct.Spacer height={4} />
            <EvenlyButtons>
              <Ct.Button
                label={intl.formatMessage({
                  id: "reminder.modal.cancel",
                })}
                colorType={"Tertiary"}
                textTransform={"uppercase"}
                colorScheme={{
                  background: "mist",
                  color: "cornflower",
                  border: "mist",
                }}
                onClick={() => {
                  onClose()
                }}
                width={30}
              />
              <Ct.Spacer height={7} />
              <Ct.Button
                label={intl.formatMessage({
                  id: `reminder.modal.cta`,
                })}
                width={30}
                type="submit"
                disabled={
                  Object.values(errors).length > 0 ||
                  sendReminderStatus === "LOADING"
                }
              />
              <Ct.Spacer height={7} />
            </EvenlyButtons>
          </Form>
        </FullHeight>
      </Ct.Card>
    </Ct.Modal>
  )
}

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const EvenlyButtons = styled(Ct.RowCenter)`
  justify-content: space-evenly;
`
const HeaderTitleWrapper = styled.div`
  padding: 2rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

const Form = styled((props) => <Ct.StyledForm {...props} />)`
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4rem 2rem;
  box-sizing: border-box;
  max-width: 70rem;
  height: 100%;
  align-self: center;
`
const Close = styled.div`
  right: 2rem;
  position: absolute;
  cursor: pointer;
`
const Explanation = styled.div`
  display: flex;
  align-items: center;
`
const StyledInfo = styled(Info)`
  fill: ${colors.cornflower};
`
