import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import {
  forgottenPasswordResetAction,
  forgottenPasswordThunk,
} from "../../store/ducks/login.ducks"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Alert } from "../../components/Commons/Alert"
import { Background } from "../../components/Background"
import { ReactComponent as PasswordSVG } from "../../assets/illustration_password.svg"
import styled from "styled-components/macro"

const UserForgottenPassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const schema = yup.object({
    email: yup.string().email().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const email = watch("email")

  const handlePassword = () => {
    dispatch(forgottenPasswordThunk(email))
  }

  const forgottenPasswordStatus = useRNBSelector(
    (state) => state.login.forgottenPassword
  )

  useEffect(() => {
    dispatch(forgottenPasswordResetAction())
  }, [dispatch])

  return (
    <Background centeredTop={false}>
      <Content>
        <Ct.Title
          text={intl.formatMessage({ id: "forgotten-password.title" })}
        />
        <Ct.Spacer height={10} />
        <RowStyle>
          <PasswordSVG height={300} />
          <Ct.Spacer width={10} />
          <Ct.Spacer width={10} />
          <Ct.Card width={"70rem"}>
            <Ct.StyledForm onSubmit={handleSubmit(handlePassword)}>
              <Alert alertType="info" margin="0 0 0 2.25rem">
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "forgotten-password.do-not-panic",
                  })}
                  textStyle={{
                    color: "navy",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                  }}
                />
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "forgotten-password.instructions",
                  })}
                  textStyle={{
                    color: "navy",
                    lineHeight: 3,
                  }}
                />
                {forgottenPasswordStatus === "UNFORGOTTEN" ? (
                  <>
                    <Ct.Spacer height={3} />
                    <Ct.Input
                      label={"Votre email"}
                      type={"email"}
                      name={"email"}
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      value={email}
                    />
                  </>
                ) : null}
                <Ct.Spacer height={1} />
              </Alert>
              <Ct.Spacer height={4} />
              {forgottenPasswordStatus === "ERROR" ? (
                <>
                  <Alert alertType="error" margin="0 9.25rem 0 7.25rem">
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "forgotten-password.error",
                      })}
                      textStyle={{ fontWeight: 700, color: "amaranth" }}
                    />
                  </Alert>
                  <Ct.Spacer />
                  <Ct.Link
                    RouterLink={Link}
                    text={intl.formatMessage({
                      id: "forgotten-password.login-link",
                    })}
                    href={"/user/login"}
                  />
                </>
              ) : forgottenPasswordStatus === "SUCCESS" ? (
                <>
                  <Alert alertType="success">
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "forgotten-password.success",
                      })}
                      textStyle={{ fontWeight: 700, color: "shamrock" }}
                    />
                  </Alert>
                  <Ct.Spacer height={2} />
                  <Ct.Link
                    RouterLink={Link}
                    text={intl.formatMessage({
                      id: "forgotten-password.login-link",
                    })}
                    href={"/user/login"}
                  />
                </>
              ) : (
                <Ct.Button
                  disabled={!isValid}
                  type="submit"
                  width={43}
                  label={intl.formatMessage({ id: "forgotten-password.cta" })}
                />
              )}
            </Ct.StyledForm>
          </Ct.Card>
        </RowStyle>
      </Content>
    </Background>
  )
}

export default UserForgottenPassword

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
