import * as Ct from "ldlj"
import { useIntl } from "react-intl"

export const DropDocumentsOfficeFormatModal = ({
  isDisplayed,
  onClose,
}: Ct.ModalComponentsProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      onClose={onClose}
      isDisplayed={isDisplayed}
      backgroundDisabled={true}
      top={"37rem"}
      right={"4rem"}
    >
      <Ct.Card
        padding={"2rem"}
        backgroundColor={"navy"}
        trianglePosition={{
          position: "top",
          right: 13.5,
          top: -0.5,
          size: 1.25,
        }}
      >
        <Ct.Text
          text={intl.formatMessage({
            id: "company-drop-document.office-untreated-format",
          })}
          textStyle={{
            color: "white",
            textAlign: "center",
            fontWeight: 500,
          }}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
