import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"

import * as Ct from "ldlj"
import {
  deactivateNumberingsResetAction,
  deleteCompanyNumberingThunk,
} from "../../../../../store/ducks/numberings.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { getIdFromParams } from "../../../../../utils/company"
import { Alert } from "../../../../../components/Commons/Alert"

interface DeleteNumberingModalProps extends Ct.ModalComponentsProps {
  numberingIdToDelete: number | null
}
export const DeleteNumberingModal = ({
  isDisplayed,
  onClose,
  numberingIdToDelete,
}: DeleteNumberingModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()

  const selectedCompanyId = getIdFromParams(params)("company_id")

  const deleteNumberingStatus = useRNBSelector(
    (state) => state.numberings.deleteNumberingStatus
  )
  useEffect(() => {
    if (deleteNumberingStatus === "success") {
      onClose()
      dispatch(deactivateNumberingsResetAction())
    }
  }, [dispatch, deleteNumberingStatus, onClose])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card width="80rem">
          <Alert alertType="info">
            <Ct.Text
              text={intl.formatMessage({
                id: "office.company.numberings.delete-modal.about",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "office.company.numberings.delete-modal.sure",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Spacer />
          </Alert>
          <Ct.Spacer />
          <SpaceEvenly>
            <Ct.Button
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              label={intl.formatMessage({
                id: "office.company.numberings.delete-modal.cancel",
              })}
              onClick={onClose}
            />
            <Ct.Spacer />
            <Ct.Button
              label={intl.formatMessage({
                id: "office.company.numberings.delete-modal.confirm",
              })}
              onClick={() => {
                if (!selectedCompanyId || !numberingIdToDelete) return

                dispatch(
                  deleteCompanyNumberingThunk({
                    companyId: selectedCompanyId,
                    numberingId: numberingIdToDelete,
                  })
                )
              }}
            />
          </SpaceEvenly>
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const SpaceEvenly = styled.div `
justify-content: center;
display: flex;
flex-direction: row;
`