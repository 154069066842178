import * as React from "react"
import * as Ct from "ldlj"
import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"
import styled from "styled-components/macro"
import { ImageOrPdfViewer } from "./ImageOrPdfViewer"
import { useDispatch } from "react-redux"
import { getSingleBatchThunk } from "../../store/ducks/batchDocuments.ducks"
import { useRNBSelector } from "../../store/rootReducer"

export interface displayPreviewParams {
  isDisplayed: boolean
  batchDocumentId: number | null
  elementName: string | null
  displayInvoice?: boolean
  companyId: number | null
}

export interface BatchDocumentPreviewModalParams
  extends Ct.ModalComponentsProps {
  batchDocumentId: number | null
  elementName: string | null
  companyId: number | null
  displayInvoice?: boolean
}

export const BatchDocumentPreviewModal = ({
  isDisplayed,
  onClose,
  batchDocumentId,
  companyId,
  elementName,
  displayInvoice,
}: BatchDocumentPreviewModalParams) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (batchDocumentId !== null) {
      dispatch(getSingleBatchThunk(batchDocumentId))
    }
  }, [batchDocumentId])
  const getUrl = useRNBSelector((state) => {
    if (!batchDocumentId || !companyId) return null
    const batches = state.batchDocuments.batchesByCompany[companyId]
    if (!batches) return null
    const batch = batches[batchDocumentId]
    if (!batch) return null
    return batch.get_url
  })

  return (
    <Ct.Modal isDisplayed={isDisplayed} onClose={onClose} top="16rem">
      <StyledCard width="58vw" height={"100rem"}>
        <Ct.Spacer height={2} />
        <HeaderPreviewModal>
          <TextWrapper>
            {elementName ? <Ct.Title text={elementName} size={3} /> : <></>}
          </TextWrapper>
          <CrossWrapper onClick={() => onClose()}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Ct.Spacer height={2} />
        <ImageOrPdfViewer getUrl={getUrl} />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  padding: 0;
  border-radius: 1rem;
`

const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`

const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
