import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import * as Ct from "ldlj"

import { Header, ContentSection } from "../Commons/Table"
import { getIdFromParams } from "../../utils/company"
import { useRNBSelector } from "../../store/rootReducer"
import { colors } from "../../styles/design.config"
import { ReactComponent as Check } from "../../assets/drop-documents/Check.svg"
import { ReactComponent as Duplicate } from "../../assets/red-duplicate.svg"

interface DropDocumentsDuplicateDetailsModalType
  extends Ct.ModalComponentsProps {}

export const DropDocumentsDuplicateDetailsModal = ({
  isDisplayed,
  onClose,
}: DropDocumentsDuplicateDetailsModalType) => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") as number
  const batchDocumentId = useRNBSelector(
    (state) => state.dropDocuments.duplicateDetails.batchDocumentId
  )
  const { selectedDuplicateBatchDocument, otherDuplicate } = useRNBSelector(
    (state) => {
      if (
        !batchDocumentId ||
        !state.batchDocuments.batchesByCompany[selectedCompanyId]
      ) {
        return {
          selectedDuplicateBatchDocument: null,
          otherDuplicate: [],
        }
      }
      const selectedDuplicateBatchDocument =
        state.batchDocuments.batchesByCompany[selectedCompanyId][
          batchDocumentId
        ]
      return {
        selectedDuplicateBatchDocument,
        otherDuplicate: Object.values(
          state.batchDocuments.batchesByCompany[selectedCompanyId]
        ).filter(
          (bd) =>
            selectedDuplicateBatchDocument &&
            bd.id !== batchDocumentId &&
            bd.fingerprint === selectedDuplicateBatchDocument.fingerprint
        ),
      }
    }
  )

  const allDocuments = [selectedDuplicateBatchDocument, ...otherDuplicate].sort(
    (a, _) => {
      return !a?.duplicate ? -1 : 1
    }
  )

  return (
    <Ct.Modal onClose={onClose} isDisplayed={isDisplayed}>
      <StyledCard width={"164rem"} height={"auto"} padding={"0"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Text
          text={intl.formatMessage(
            {
              id: `company-drop-document.duplicate-details.title`,
            },
            {
              originalFileName:
                selectedDuplicateBatchDocument?.originalFileName || "",
            }
          )}
          textStyle={{ fontSize: 3 }}
        />
        <Ct.Spacer height={4} />
        <Ct.Separator size="full" color="mist" />

        <Ct.Spacer height={4} />
        <TableWrapper>
          <Header paddingHeader="0">
            {["filename", "date", "email"].map((column) => (
              <Flex1 key={column}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: `company-drop-document.duplicate-details.${column}`,
                  })}
                  textStyle={{
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    textTransform: "uppercase",
                    textAlign: "center",
                    cursor: "auto",
                  }}
                />
              </Flex1>
            ))}
          </Header>
          <ContentSection>
            {allDocuments.map((bd) => {
              return (
                <Row key={bd?.id} isDuplicate={bd?.duplicate || false}>
                  <Flex1>
                    <div>{bd?.duplicate ? <Duplicate /> : <Check />}</div>
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={bd?.originalFileName || ""}
                      textStyle={{
                        fontWeight: 600,
                      }}
                    />
                  </Flex1>
                  <Flex1>{bd?.createdAt}</Flex1>
                  <Flex1>{bd?.email}</Flex1>
                </Row>
              )
            })}
          </ContentSection>
        </TableWrapper>
        <Ct.Spacer height={2} />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
`
const Flex1 = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  align-items: center;
  justify-content: center;
`
const TableWrapper = styled.div`
  padding: 4rem;
  width: 156rem;
`
const Row = styled.div<{ isDuplicate: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 7rem;
  border-bottom: 1px solid ${colors.grey};

  background-color: ${({ isDuplicate }) =>
    isDuplicate ? "rgba(255, 46, 108, 0.1)" : colors.white};

  :last-of-type {
    border-bottom: none;
  }
`
