import * as Ct from "ldlj"
import { RowCenter, Spacer } from "ldlj"
import { useIntl } from "react-intl"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../store/rootReducer"
import { entries } from "../../utils/entries"
import {
  FullDocumentAPI,
  getDeactivatedReasonsThunk,
} from "../../store/ducks/fullDocuments.ducks"
import { ReactComponent as File } from "../../assets/fileSimple.svg"
import { ArchiveDownLink } from "../ArchiveDownLink"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getUrlForFullDocumentThunk } from "../../store/ducks/invoicing.duck"

interface DropDocumentsDetailsModalType extends Ct.ModalComponentsProps {
  batch?: string
}

export const DropDocumentsDetailsModal = ({
  isDisplayed,
  onClose,
  batch,
}: DropDocumentsDetailsModalType) => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") as number
  const userTypology = useRNBSelector((state) => state.user.typology)
  const details = useRNBSelector((state) => state.dropDocuments.details)
  const pageTotal = details.pageTotal
  const fullDocumentsWithoutTotal = entries(details).filter(
    ([key]) => key !== "pageTotal"
  ) as [number, FullDocumentAPI][]
  const fullDocuments = fullDocumentsWithoutTotal.map(([, value]) => value)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDeactivatedReasonsThunk())
  }, [dispatch])

  const deactivatedReasonsGroup = useRNBSelector(
    (state) => state.writings.deactivatedReasons.deactivated_reasons
  )

  const deactivatedBecauseLabel = (
    deactivatedBecause: string,
    scope: string
  ): string => {
    const category = Object.entries(deactivatedReasonsGroup).find(
      ([e, f]: [string, {}]) => Object.keys(f).includes(deactivatedBecause)
    )
    if (category) {
      if (scope === "documentType") return category[0]
      const reasons = Object.entries(category[1]).find(
        (element) => element[0] === deactivatedBecause
      )
      if (scope === "label" && reasons) return reasons[1]
    }
    if (deactivatedBecause === "duplicate") return "Doublon"
    return "default"
  }

  const columns = [
    {
      headerText: "company-drop-document.details.ref",
      content: (fullDoc: FullDocumentAPI) => {
        if (fullDoc?.archive_generated_at) {
          return (
            <RowCenter>
              <File />
              <Spacer />
              <StyledLink
                onClick={() => {
                  dispatch(getUrlForFullDocumentThunk(fullDoc.id))
                }}
              >
                {fullDoc?.user_file_name}
              </StyledLink>
            </RowCenter>
          )
        } else if (fullDoc?.status === "processed") {
          return <div>{fullDoc?.document_reference}</div>
        } else if (fullDoc?.deactivated_because) {
          return (
            <div>
              {deactivatedBecauseLabel(fullDoc?.deactivated_because, "label")}
            </div>
          )
        } else {
          return <></>
        }
      },
    },
    {
      headerText: "company-drop-document.details.date",
      content: (fullDoc: FullDocumentAPI) => (
        <div>{fullDoc?.document_date}</div>
      ),
    },
    {
      headerText: "company-drop-document.details.suspense_account",
      content: (fullDoc: FullDocumentAPI) => (
        <div>{fullDoc?.merchant_name}</div>
      ),
    },
    {
      headerText: "company-drop-document.details.page",
      content: (fullDoc: FullDocumentAPI) => (
        <div>
          {fullDoc.first_page_number !== fullDoc.last_page_number
            ? `${Number(fullDoc?.first_page_number) + 1} - ${
                Number(fullDoc?.last_page_number) + 1
              } / ${pageTotal}`
            : `${Number(fullDoc?.last_page_number) + 1} / ${pageTotal}`}
        </div>
      ),
    },
    {
      headerText: "company-drop-document.details.archive",
      content: (fullDoc: FullDocumentAPI) => {
        if (!fullDoc?.writing_archive_id) {
          return <></>
        } else {
          return (
            <ArchiveDownLink
              archiveId={fullDoc?.writing_archive_id}
              selectedCompanyId={selectedCompanyId}
              userTypology={userTypology}
              archiveDownload={fullDoc?.archive_downloaded_at}
            />
          )
        }
      },
    },
  ]

  return (
    <Ct.Modal onClose={onClose} isDisplayed={isDisplayed}>
      <StyledCard width={"auto"} height={"84vh"} padding={"1rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Title
          text={intl.formatMessage(
            {
              id: `company-drop-document.details.title`,
            },
            { batch }
          )}
          size={2.25}
        />

        <Ct.Spacer height={5} />
        <Ct.Table
          intl={intl}
          columns={columns}
          alignItems={"center"}
          width={"64vw"}
          rows={fullDocuments}
        />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
`

const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`
