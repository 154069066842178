import { FiscalYear } from "../store/ducks/fiscalYears.ducks"
import { DateTime } from "luxon"

export function sortFiscalYears(array: FiscalYear[]) {
  return array.sort(
    (x, y) => +new Date(x.beginExercise) - +new Date(y.beginExercise)
  )
}

export function getDifferenceInDays(date1: string, date2: string) {
  const diffInDays =
    Math.abs(+new Date(date2) - +new Date(date1)) / (1000 * 60 * 60 * 24)
  return diffInDays
}

export function insideFiscalYear(
  dateString: string,
  fiscalYear: FiscalYear | undefined
): boolean {
  if (!dateString || !fiscalYear) {
    return false
  }
  const fiscalYearBeginExercise = DateTime.fromFormat(
    fiscalYear.beginExercise,
    "yyyy-MM-dd"
  )
  const fiscalYearEndExercise = DateTime.fromFormat(
    fiscalYear.endExercise,
    "yyyy-MM-dd"
  )

  const date = DateTime.fromFormat(dateString, "yyyy-MM-dd")

  return date >= fiscalYearBeginExercise && date <= fiscalYearEndExercise
}

export function findFiscalYearByDate(
  dateString: string,
  fiscalYears: FiscalYear[]
) {
  return fiscalYears.find((fy) => insideFiscalYear(dateString, fy))
}

export const convertToMonth = (date: string) => {
  if (!date) return
  const arr = date.split("-")
  const month = arr[1]

  switch (month) {
    case "01":
      return "Janvier " + arr[0]
    case "02":
      return "Février " + arr[0]
    case "03":
      return "Mars " + arr[0]
    case "04":
      return "Avril " + arr[0]
    case "05":
      return "Mai " + arr[0]
    case "06":
      return "Juin " + arr[0]
    case "07":
      return "Juillet " + arr[0]
    case "08":
      return "Août " + arr[0]
    case "09":
      return "Septembre " + arr[0]
    case "10":
      return "Octobre " + arr[0]
    case "11":
      return "Novembre " + arr[0]
    case "12":
      return "Décembre " + arr[0]
    default:
      return
  }
}
