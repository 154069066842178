import { Document } from "../store/ducks/merchants.ducks"
export const sortDocsByInternalReference = (
  documents: Document[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a?.user_file_name.localeCompare(b.user_file_name)
        : b?.user_file_name.localeCompare(a.user_file_name)
    ),
  ]
}

export const sortDocsByExternalReference = (
  documents: Document[],
  asc: boolean
) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a?.document_reference.localeCompare(b.document_reference)
        : b?.document_reference.localeCompare(a.document_reference)
    ),
  ]
}

export const sortDocsByName = (documents: Document[], asc: boolean) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? a?.original_file_name.localeCompare(b.original_file_name)
        : b?.original_file_name.localeCompare(a.original_file_name)
    ),
  ]
}

export const sortDocsByAmount = (documents: Document[], asc: boolean) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? parseInt(a.amount) - parseInt(b.amount)
        : parseInt(b.amount) - parseInt(a.amount)
    ),
  ]
}

export const sortDocsByDate = (documents: Document[], asc: boolean) => {
  return [
    ...documents.sort((a, b) =>
      asc
        ? +new Date(b.document_date) - +new Date(a.document_date)
        : +new Date(a.document_date) - +new Date(b.document_date)
    ),
  ]
}

export const sortDocsByArchiveId = (documents: Document[], asc: boolean) => {
  return [
    ...documents.sort((a, b) =>
      asc ? a?.archive_id - b.archive_id : b?.archive_id - a.archive_id
    ),
  ]
}
