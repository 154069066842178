import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import * as Ct from "ldlj"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useRNBSelector } from "../../store/rootReducer"
import { getErrorCodes } from "../../utils/form"
import { Navigate, useNavigate } from "react-router-dom"
import { sizes } from "../../styles/design.config"
import { getCurrentUserThunk } from "../../store/ducks/user.ducks"
import styled from "styled-components/macro"
import {
  attemptRegisteringThunk,
  UserRegisterPayload,
} from "../../store/ducks/login.ducks"
import { Alert } from "../../components/Commons/Alert"
import { Background } from "../../components/Background"
import { ReactComponent as SignupSVG } from "../../assets/illustration_inscription.svg"

const UserRegister = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [acceptedSalesConditions, setAcceptedSalesConditions] = useState(false)

  const schema = yup.object({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    password: yup
      .string()
      .min(8, "length")
      .required()
      .matches(RegExp(/^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).*$/gm), "format"),
    confirmPassword: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const email = watch("email")
  const firstName = watch("firstName")
  const lastName = watch("lastName")
  const password = watch("password")
  const confirmPassword = watch("confirmPassword")

  const userRegistration = ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  }: UserRegisterPayload) => {
    dispatch(
      attemptRegisteringThunk({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        acceptedConditions,
        acceptedSalesConditions,
        firstConnexion: true,
      })
    )
  }

  const registeringStatus = useRNBSelector((state) => {
    return state.login.registering
  })

  const passwordErrors = getErrorCodes(errors, password, confirmPassword)

  const getCurrentUserStatus = useRNBSelector((state) => {
    return state.user.status
  })

  useEffect(() => {
    if (getCurrentUserStatus === "IDLE") {
      dispatch(getCurrentUserThunk())
    } else if (getCurrentUserStatus === "SUCCESS") {
      navigate(`/office/fiduciary/companies`)
    }
  }, [dispatch, getCurrentUserStatus, navigate])

  return (
    <Background centeredTop={false}>
      {registeringStatus === "SUCCESS" && (
        <Navigate to={"/fiduciary/register"} />
      )}
      <Content>
        <Ct.Title text={intl.formatMessage({ id: "register.title" })} />
        <Ct.Subtitle text={intl.formatMessage({ id: "register.subtitle" })} />
        <Ct.Spacer height={10} />
        <RowStyle>
          <StyledSignupSVG />
          <Ct.Card>
            <Ct.StyledForm onSubmit={handleSubmit(userRegistration)}>
              <>
                <Ct.Spacer height={1.5} />
                <Ct.Input
                  name="email"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({ id: "register.email" })}
                  type="email"
                  value={email}
                  showError={registeringStatus === "EMAIL_EXISTING_ERROR"}
                />

                <Ct.Spacer height={2} />

                {registeringStatus === "EMAIL_EXISTING_ERROR" && (
                  <Ct.Text
                    text={intl.formatMessage({
                      id: `register.duplicate`,
                    })}
                    textStyle={{ color: "amaranth", fontSize: 1.75 }}
                  />
                )}

                <Ct.Spacer
                  height={registeringStatus === "EMAIL_EXISTING_ERROR" ? 3 : 2}
                />

                <Ct.StyledDuoInput>
                  <Ct.Input
                    name="firstName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({ id: "register.first-name" })}
                    type="text"
                    value={firstName}
                  />
                  <Ct.Spacer width={3} />
                  <Ct.Input
                    name="lastName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({ id: "register.last-name" })}
                    type="text"
                    value={lastName}
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={4} />
                <Ct.StyledDuoInput>
                  <Ct.PasswordInput
                    name="password"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({ id: "register.password" })}
                    type="password"
                    value={password}
                    showWarning={
                      passwordErrors.includes("format") ||
                      passwordErrors.includes("length")
                    }
                  />
                  <Ct.Spacer width={3} />
                  <Ct.PasswordInput
                    name="confirmPassword"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "register.password",
                    })}
                    type="password"
                    value={confirmPassword}
                    showError={
                      passwordErrors.includes("mismatch") &&
                      confirmPassword.length
                    }
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={3} />
                {passwordErrors.length > 0 && (
                  <>
                    <Alert alertType="bulb" centerIcon={false}>
                      {passwordErrors.map((passwordError) => (
                        <Ct.Text
                          key={passwordError}
                          text={intl.formatMessage({
                            id: `password-errors.${passwordError}`,
                          })}
                          textStyle={{ lineHeight: 3 }}
                        />
                      ))}
                    </Alert>
                    <Ct.Spacer height={3} />
                  </>
                )}

                <AlignedStart>
                  <Ct.Checkbox
                    onChange={() => setAcceptedConditions(!acceptedConditions)}
                    name="acceptConditions"
                    isChecked={acceptedConditions}
                    label={intl.formatMessage({
                      id: "register.conditions",
                    })}
                  />
                  <Ct.Spacer width={0.5} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "register.conditions-link",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      color: "cornflower",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGU_EC.pdf`,
                        "_blank"
                      )
                    }
                  />
                </AlignedStart>

                <Ct.Spacer height={2} />
                <AlignedStart>
                  <Ct.Checkbox
                    onChange={() => {
                      setAcceptedSalesConditions(!acceptedSalesConditions)
                    }}
                    name="acceptSalesConditions"
                    isChecked={acceptedSalesConditions}
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.conditions",
                    })}
                  />
                  <Ct.Spacer width={0.5} />

                  <Ct.Text
                    text={intl.formatMessage({
                      id: "user.incomplete-registration.sales-conditions-link",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      color: "cornflower",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGV_EC.pdf`,
                        "_blank"
                      )
                    }
                  />
                </AlignedStart>
                <Ct.Spacer height={4} />
                <Ct.Button
                  width={sizes.button.standard}
                  disabled={
                    !isValid ||
                    !acceptedConditions ||
                    !acceptedSalesConditions ||
                    password !== confirmPassword
                  }
                  type="submit"
                  label={intl.formatMessage({ id: "register.cta" })}
                />
              </>
            </Ct.StyledForm>
          </Ct.Card>
        </RowStyle>
      </Content>
    </Background>
  )
}

export default UserRegister

const AlignedStart = styled.div`
  align-self: start;
  display: flex;
  align-items: column;
`

const RowStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledSignupSVG = styled(SignupSVG)`
  height: 350px;
  padding-right: 20rem;
`

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
