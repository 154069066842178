import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import {
  BillOfExchangeDatumTypes,
  EntryDatumType,
  TransferGoodsDatumType,
  WritingLinePayload,
  getDisplayedNumber,
} from "../../utils/inputMask"
import { colors } from "../../styles/design.config"
import {
  changeAccount,
  resetAccountToSelectAction,
} from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Option, Select } from "../Commons/Select"
import { ReactComponent as Lock } from "../../assets/lock.svg"
import { ReactComponent as Plus } from "../../assets/smallPlus.svg"

import { getIdFromParams } from "../../utils/company"
import { CreateAccountModal } from "./CreateAccountModal"
import { Account } from "../../store/ducks/accounts.ducks"
import { formatDateFRstring } from "../../utils/date"
import { BillOfExchangeMerchantSelectCell } from "./BillOfExchangeMerchantSelectCell"

export const AccountSelectCell = ({
  writingLine,
  isHoveredForDeletion,
  isHoveredForFuel,
}: {
  writingLine: WritingLinePayload
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
}) => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [showAccountCreationModal, setShowAccountCreationModal] =
    useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    possibleAccounts,
    merchantCodes,
    company,
    editedWritingLines,
    editedFullDocument,
    accountIdToSelectFromCreation,
  } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
    editedWritingLines: state.inputMask.edited_writing_lines,
    accountIdToSelectFromCreation:
      state.inputMask.accountIdToSelectFromCreation,
    possibleAccounts:
      state.inputMask.all_company_accounts &&
      state.inputMask.all_company_accounts[
        state.inputMask.edited_full_document?.buy_or_sell || "buy"
      ][writingLine.datum_type as EntryDatumType]
        ? state.inputMask.all_company_accounts[
            state.inputMask.edited_full_document?.buy_or_sell || "buy"
          ][writingLine.datum_type as EntryDatumType]
        : [],

    merchantCodes: state.merchantCodes.merchantCodes,
    company: state.companies.companies[selectedCompanyId],
  }))

  const accountOptions: Option<string>[] = possibleAccounts
    .sort((a, b) => {
      return a.deactivated_at ? 1 : -1
    })
    .map((l) => ({
      value: l.id.toString(),
      label: l.deactivated_at
        ? intl.formatMessage(
            {
              id: "input-mask.deactivated-account",
            },
            {
              deactivatedAt: formatDateFRstring(l.deactivated_at),
              account: `${l.number} ${l.details}`,
            }
          )
        : `${l.number} ${l.details}`,
      disabled: false,
    }))

  const editableAccountsDatumTypes: (
    | EntryDatumType
    | TransferGoodsDatumType
    | BillOfExchangeDatumTypes
  )[] = [
    "vat",
    "tax_excluded",
    "goods_transfer_vat",
    "tobacco_goods_transfer",
    "tobacco_goods_transfer_commission",
    "monetics_goods_transfer",
    "monetics_goods_transfer_commission",
    "scratch_games_goods_transfer",
    "scratch_games_goods_transfer_commission",
    "scratch_games_paid_lots_goods_transfer",
    "draw_games_goods_transfer",
    "draw_games_paid_lots_goods_transfer",
    "draw_games_goods_transfer_commission",
    "tax_stamps_goods_transfer",
    "tax_stamps_goods_transfer_commission",
    "postage_stamps_goods_transfer",
    "postage_stamps_goods_transfer_commission",
    "metro_goods_transfer",
    "metro_goods_transfer_commission",
    "press_goods_transfer",
    "press_goods_transfer_commission",
    "other_goods_transfer",
    "other_goods_transfer_commission",
    "custom",
    "tax_excluded_bill_of_exchange",
  ]
  const isLocked = !editableAccountsDatumTypes.includes(writingLine.datum_type)
  const isTaxIncluded =
    writingLine.datum_type === "tax_included" ||
    writingLine.datum_type === "tax_included_bill_of_exchange"

  useEffect(() => {
    if (isLocked && !writingLine.account) {
      const taxIncludedAccountId =
        editedFullDocument?.buy_or_sell === "sell"
          ? company.sell_third_party_account_id
          : company.buy_third_party_account_id
      dispatch(
        changeAccount({
          writingLine,
          newAccount: possibleAccounts.find(
            (a) => a.id === taxIncludedAccountId
          ) as Account,
        })
      )
    }
  }, [isLocked, writingLine.account])

  useEffect(() => {
    if (!accountIdToSelectFromCreation) {
      return
    }
    if (
      accountIdToSelectFromCreation.writinLineUuid !==
      writingLine.writing_line_uuid
    ) {
      return
    }

    const account = possibleAccounts.find(
      (a) => a.id === accountIdToSelectFromCreation.accountId
    )

    // if creating a sell account from a buy writing line account will be undefined, so do not change selection
    if (account) {
      dispatch(
        changeAccount({
          writingLine,
          newAccount: account,
        })
      )
    }
    dispatch(resetAccountToSelectAction())
  }, [accountIdToSelectFromCreation])

  if (writingLine.datum_type === "tax_excluded_bill_of_exchange") {
    return (
      <BillOfExchangeMerchantSelectCell
        writingLine={writingLine}
        isHoveredForDeletion={isHoveredForDeletion}
        isHoveredForFuel={isHoveredForFuel}
      />
    )
  }
  if (isLocked && writingLine.account) {
    const mc = merchantCodes.find(
      (mc) => mc.id === editedFullDocument?.merchant_code_id
    )

    return (
      <Flex
        isHoveredForDeletion={isHoveredForDeletion}
        isHoveredForFuel={isHoveredForFuel}
      >
        <Ellipsis>
          {isTaxIncluded && mc
            ? getDisplayedNumber({
                writingLines: editedWritingLines,
                buyOrSell: editedFullDocument?.buy_or_sell || "buy",
                merchantCode: mc,
                buyAuxiliaryPrefix: company?.buy_auxiliary_prefix || "",
                sellAuxiliaryPrefix: company?.sell_auxiliary_prefix || "",
              })
            : `${writingLine.account.number} ${writingLine.account.details}`}
        </Ellipsis>
        <StyledLock />
      </Flex>
    )
  }

  return (
    <>
      <CreateAccountModal
        onClose={() => {
          setShowAccountCreationModal(false)
        }}
        isDisplayed={showAccountCreationModal}
        onAccountCreated={() => {}}
        writingLineUuid={writingLine.writing_line_uuid}
      />
      <AbsoluteWrapper
        showError={Boolean(
          !accountOptions.find(
            (a) => writingLine.account?.id.toString() === a.value
          )
        )}
      >
        <StyledSelect
          height="4rem"
          intl={null}
          value={
            accountOptions.find(
              (a) => writingLine.account?.id.toString() === a.value
            ) || null
          }
          defaultValue={null}
          options={accountOptions}
          onChangeCallback={(e) => {
            const selectedAccount = possibleAccounts.find(
              (a) => a.id.toString() === e.value
            )
            if (selectedAccount) {
              dispatch(
                changeAccount({
                  writingLine,
                  newAccount: selectedAccount,
                })
              )
            }
          }}
          border="none"
          borderRadius="0"
          backgroundColor={
            isHoveredForDeletion
              ? "rgba(252, 90, 90, 0.2)"
              : isHoveredForFuel
              ? "rgba(107, 146, 255, 0.2)"
              : colors.white
          }
          hasPlusButton={writingLine.datum_type === "tax_excluded"}
        />
        {writingLine.datum_type === "tax_excluded" && (
          <StyledPlus
            onClick={() => {
              setShowAccountCreationModal(true)
            }}
          />
        )}
      </AbsoluteWrapper>
    </>
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`

const Ellipsis = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.75rem;
  padding-right: 1.5rem;
`

const Flex = styled.div<{
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
}>`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  overflow: hidden;
  background-color: ${({ isHoveredForDeletion, isHoveredForFuel }) =>
    isHoveredForDeletion
      ? "rgba(252, 90, 90, 0.2)"
      : isHoveredForFuel
      ? "rgba(107, 146, 255, 0.2)"
      : colors.white};
  transition: 0.5s background-color ease-in-out;
`

const StyledLock = styled(Lock)`
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  fill: ${colors.rock};
`

const StyledPlus = styled(Plus)`
  width: 1.6rem;
  height: 1.6rem;
  top: 1.2rem;
  right: 1rem;
  position: absolute;
  cursor: pointer;
  fill: ${colors.rock};
  transition: 0.5s fill ease-in-out;
  &:hover {
    fill: ${colors.cornflower};
  }
`
const AbsoluteWrapper = styled.div<{
  showError: boolean
}>`
  position: relative;
  width: 100%;
  border: ${({ showError }) =>
    showError ? `1px solid ${colors.amaranth}` : "auto"};
`
