import { BatchDocument } from "../store/ducks/batchDocuments.ducks"
import { ArchiveDetail } from "../store/ducks/writings.ducks"
import { capitalizeFirstLetter, normalizeString } from "./string"

export function filterBatches(
  batches: BatchDocument[],
  search: string
): BatchDocument[] {
  if (!search) {
    return batches
  }
  return [
    ...batches.filter(
      (batch) =>
        batch?.userBatchName?.toLowerCase().includes(search.toLowerCase()) ||
        batch?.originalFileName.toLowerCase().includes(search.toLowerCase()) ||
        batch.email?.toLowerCase()?.includes(search.toLowerCase()) ||
        getFileExtension(batch?.originalFileName)
          .toLowerCase()
          .includes(search.toLowerCase())
    ),
  ]
}

export function filterArchivedBatches(
  batches: ArchiveDetail[],
  search: string
) {
  if (!search) {
    return batches
  }

  return batches.filter((batch) => {
    return (
      normalizeString(batch.original_file_name).includes(
        normalizeString(search)
      ) ||
      normalizeString(batch.email).includes(normalizeString(search)) ||
      normalizeString(batch.full_doc_name).includes(normalizeString(search))
    )
  })
}

export function sortBatchesAlphabetically(
  batches: BatchDocument[],
  asc: boolean
) {
  return [
    ...batches.sort((a, b) =>
      asc
        ? a?.originalFileName.localeCompare(b?.originalFileName)
        : b?.originalFileName.localeCompare(a?.originalFileName)
    ),
  ]
}

export function sortBatchesByType(batches: BatchDocument[], asc: boolean) {
  return [
    ...batches.sort((a, b) =>
      asc
        ? getFileExtension(a?.originalFileName).localeCompare(
            getFileExtension(b?.originalFileName)
          )
        : getFileExtension(b?.originalFileName).localeCompare(
            getFileExtension(a?.originalFileName)
          )
    ),
  ]
}

export function sortBatchesDepositBy(batches: BatchDocument[], asc: boolean) {
  return [
    ...batches.sort((a, b) => {
      return asc
        ? (a?.email || "zzzzzzzzzzzzzz").localeCompare(
            b?.email || "zzzzzzzzzzzzzz"
          )
        : (b?.email || "a").localeCompare(a?.email || "a")
    }),
  ]
}

export function sortBatchesByDate(batches: BatchDocument[], asc: boolean) {
  return [...batches.sort((a, b) => (asc ? a.id - b.id : b.id - a.id))]
}

export function sortDisplayedNameBatches(
  batches: ArchiveDetail[],
  asc: boolean
) {
  return [
    ...batches.sort((a, b) =>
      asc
        ? a.original_file_name.localeCompare(b.original_file_name)
        : b.original_file_name.localeCompare(a.original_file_name)
    ),
  ]
}

export function sortDocumentNameBatches(
  batches: ArchiveDetail[],
  asc: boolean
) {
  return [
    ...batches.sort((a, b) =>
      asc
        ? a.full_doc_name.localeCompare(b.full_doc_name)
        : b.full_doc_name.localeCompare(a.full_doc_name)
    ),
  ]
}

export function sortBatchDocumentByEmail(
  batches: ArchiveDetail[],
  asc: boolean
) {
  let allBatches: ArchiveDetail[] = []
  const validEmails = batches.filter((b) => b.email !== "null")
  const invalidEmails = batches.filter((b) => b.email === "null")

  const sortedEmails = validEmails.sort((a, b) => {
    return a.email.localeCompare(b.email)
  })

  asc
    ? allBatches.push(...sortedEmails, ...invalidEmails)
    : allBatches.push(...invalidEmails, ...sortedEmails)

  return [...(asc ? allBatches : allBatches.reverse())]
}

export const sortDisplayedBatchByDate = (
  batches: ArchiveDetail[],
  asc: boolean
) => {
  return [
    ...batches.sort((a, b) =>
      asc ? a.batch_id - b.batch_id : b.batch_id - a.batch_id
    ),
  ]
}

export function getDocumentTypeDisplayed(batch: BatchDocument) {
  const extension = getFileExtension(batch.originalFileName)
  return capitalizeFirstLetter(extension?.replace(".", ""))
}

export function getListWithoutDuplicates(batches: BatchDocument[]) {
  return [...batches.filter((batch) => batch.duplicate === false)]
}

export function getListOfOfficeDocuments(batches: BatchDocument[]) {
  return [...batches.filter((batch) => batch.exchange === true)]
}

export function getFileExtension(fileName: string) {
  return fileName?.substring(fileName.lastIndexOf(".")).toLowerCase()
}

const NOT_EXCHANGE_EXTENSIONS = [
  ".png",
  ".jpg",
  ".jpeg",
  ".pdf",
  ".tiff",
  ".tif",
]
export function isExchange(fileName: string): boolean {
  return !NOT_EXCHANGE_EXTENSIONS.includes(getFileExtension(fileName))
}

export function sortBatchesByState(batches: BatchDocument[], asc: boolean) {
  return [
    ...batches.sort((a, b) =>
      asc
        ? a.duplicate === b.duplicate
          ? 0
          : a.duplicate
          ? 1
          : -1
        : a.duplicate === b.duplicate
        ? 0
        : a.duplicate
        ? -1
        : 1
    ),
  ]
}
