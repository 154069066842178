import { useIntl } from "react-intl"
import { useRNBSelector } from "../../store/rootReducer"
import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { Option, Select } from "../Commons/Select"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { TransferRuleToAnotherAccountThunk } from "../../store/ducks/bank.ducks"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { colors } from "../../styles/design.config"

interface TransferRulesProps {
  isDisplayed: boolean
  onClose: () => void
  ruleWithoutDocument: boolean
}

export const TransferRulesModal = ({
  isDisplayed,
  onClose,
  ruleWithoutDocument,
}: TransferRulesProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const accountID = getIdFromParams(useParams())("account_id") || 0

  const companiesAccounts = useRNBSelector(
    (state) => state.bank.companiesAccounts
  )

  const emptyValue = { value: "", label: "" }

  const [accountOptions, setAccountOptions] = useState<Array<Option<string>>>([
    emptyValue,
  ])
  const [selectedAccount, setSelectedAccount] =
    useState<Option<string>>(emptyValue)

  useEffect(() => {
    if (companiesAccounts.length > 0) {
      setAccountOptions(
        companiesAccounts.map((a) => {
          return {
            value: String(a.bank_account_id),
            label: a.bank_name + " | " + a.account_name,
          }
        })
      )
    }
  }, [companiesAccounts])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"} padding={"0"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={2} />
        <Ct.Title
          text={intl.formatMessage({
            id: `bank-management.rules.transfer-rules.title`,
          })}
          size={7}
        />
        <Line />
        <Ct.Spacer height={4} />

        <Wrapper>
          <Select
            intl={intl}
            options={[]}
            value={emptyValue}
            onChangeCallback={(e: Ct.Option<string>) => {}}
            label={""}
            domain={"bank-management.rules.transfer-rules"}
            optionType={"companies"}
            defaultValue={emptyValue}
            disabled={true}
          />

          <Ct.Spacer height={4} />

          <Select
            intl={intl}
            options={accountOptions}
            value={selectedAccount}
            onChangeCallback={(e: Ct.Option<string>) => {
              setSelectedAccount(e)
            }}
            label={""}
            domain={"bank-management.rules.transfer-rules"}
            optionType={"account"}
            defaultValue={emptyValue}
          />

          <Ct.Spacer height={4} />

          <Ct.ColumnCenterCenter>
            <Ct.Button
              label={intl.formatMessage({
                id: `bank-management.rules.transfer-rules.transfer`,
              })}
              width={40}
              onClick={() => {
                dispatch(
                  TransferRuleToAnotherAccountThunk(
                    accountID,
                    Number(selectedAccount.value),
                    ruleWithoutDocument
                  )
                )
                onClose()
              }}
              disabled={selectedAccount.value === ""}
            />
          </Ct.ColumnCenterCenter>
        </Wrapper>

        <Ct.Spacer height={4} />
      </Ct.Card>
    </Ct.Modal>
  )
}

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
