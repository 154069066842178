import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"

import * as Ct from "ldlj"
import {
  createNumberingResetAction,
  createOrModifyNumberingThunk,
  CreateOrModify,
  getCompanyNumberingDetailsThunk,
  getNumberingParametersThunk,
  Numbering,
  NumberingOrder,
  NumberingType,
  CreateNumberingPayload,
} from "../../../../../store/ducks/numberings.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { colors, sizes } from "../../../../../styles/design.config"
import { getIdFromParams } from "../../../../../utils/company"
import { useDebounce } from "../../../../../hooks/useDebounce"
import {
  disableDateInput,
  generateNumberingPreview,
  isValidNumberingPayload,
} from "../../../../../utils/numberings"
import { Text } from "../../../../../components/Commons/Text"
import { Select } from "../../../../../components/Commons/Select"
import { ReactComponent as Warning } from "../../../../../assets/warning-orange.svg"
import { ReactComponent as CreditNote } from "../../../../../assets/creditNote.svg"
import { ReactComponent as CreditNoteDuotone } from "../../../../../assets/creditNoteDuotone.svg"
import { ReactComponent as Invoice } from "../../../../../assets/invoice.svg"
import { ReactComponent as InvoiceDuotone } from "../../../../../assets/invoiceDuotone.svg"
import { ReactComponent as Quotation } from "../../../../../assets/quotation.svg"
import { ReactComponent as QuotationDuotone } from "../../../../../assets/quotationDuotone.svg"

interface CreateOrModifyNumberingModalProps extends Ct.ModalComponentsProps {
  createOrModify: CreateOrModify
  numberingToModify?: Numbering | null
}
export const CreateOrModifyNumberingModal = ({
  isDisplayed,
  onClose,
  createOrModify,
  numberingToModify,
}: CreateOrModifyNumberingModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()

  const selectedCompanyId = getIdFromParams(params)("company_id")

  const [initialNumberingToModify, setInitialNumberingToModify] =
    useState<CreateNumberingPayload | null>(null)

  useEffect(() => {
    if (!selectedCompanyId) {
      return
    }
    dispatch(getNumberingParametersThunk({ companyId: selectedCompanyId }))
    dispatch(getCompanyNumberingDetailsThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId, isDisplayed])

  const {
    createNumberingStatus,
    numberingMaxLength,
    numberingDetails,
    numberingParameters,
  } = useRNBSelector((state) => state.numberings)

  useEffect(() => {
    if (createNumberingStatus === "success") {
      onClose()
      dispatch(createNumberingResetAction())
    }
  }, [dispatch, createNumberingStatus, onClose])

  useEffect(() => {
    if (!isDisplayed) {
      const emptyOption: Ct.Option<string> = {
        value: "",
        label: "",
      }

      setNumberingLength(emptyOption)
      setNumberingOrder({
        value: "",
        label: "",
      })
      setNumberingSeparator(emptyOption)
      setNumberingDateFormat(emptyOption)
      setNextInvoiceNumber("1")
      setNumberingRoot("")
      setNumberingType("invoice")
    }
    const numberingDetailsToModify =
      selectedCompanyId &&
      numberingDetails[selectedCompanyId]?.find(
        (d) => d.id === numberingToModify?.id
      )
    if (isDisplayed && numberingDetailsToModify) {
      const foundLengthOption = numberingLengthOptions.find(
        (o) => o.value === numberingDetailsToModify.number_length.toString()
      )
      if (foundLengthOption) {
        setNumberingLength(foundLengthOption)
      }

      const foundOrderOption = numberingOrderOptions.find(
        (o) => o.value === numberingDetailsToModify.order.toString()
      )
      if (foundOrderOption) {
        setNumberingOrder(foundOrderOption as Ct.Option<NumberingOrder | "">)
      }

      const foundSeparatorOption = separatorOptions.find(
        (o) => o.value === numberingDetailsToModify.separator.toString()
      )
      if (foundSeparatorOption) {
        setNumberingSeparator(foundSeparatorOption)
      }

      const dateFormatValue = numberingParameters?.date_numbering.find(
        (d) => d.id === numberingDetailsToModify.date_format_id
      )
      const foundDateFormatOption = numberingDateFormatOptions.find(
        (o) => o.value === dateFormatValue?.date_order
      )
      if (foundDateFormatOption) {
        setNumberingDateFormat(foundDateFormatOption)
      }

      setNextInvoiceNumber(
        numberingDetailsToModify.invoice_numbers_start_with.toString()
      )
      setNumberingRoot(numberingDetailsToModify.root)
      setNumberingType(numberingDetailsToModify.numbering_type)

      setInitialNumberingToModify({
        numberingOrder: foundOrderOption?.value as NumberingOrder,
        numberingSeparator: foundSeparatorOption?.value || "",
        numberingDateFormat: foundDateFormatOption?.value || "",
        numberingRoot: numberingDetailsToModify.root,
        nextInvoiceNumber:
          numberingDetailsToModify.invoice_numbers_start_with.toString(),
        numberingLength: foundLengthOption?.value || "",
        numberingType: numberingDetailsToModify.numbering_type,
      })
    }
  }, [isDisplayed])

  const numberingLengthOptions = ["6", "7", "8", "9", "10", "11", "12"].map(
    (length) => ({
      value: length,
      label: length,
    })
  )
  const [numberingLength, setNumberingLength] = useState<Ct.Option<string>>({
    value: "",
    label: "",
  })

  const [numberingOrder, setNumberingOrder] = useState<
    Ct.Option<NumberingOrder | "">
  >({
    value: "",
    label: "",
  })
  const numberingOrderOptions = Object.entries(
    numberingParameters?.order_numbering || {}
  ).map(([entryKey, entryValue]) => ({
    value: entryKey,
    label: entryValue,
  }))

  const [numberingSeparator, setNumberingSeparator] = useState<
    Ct.Option<string>
  >({
    value: "",
    label: "",
  })
  const separatorOptions = Object.entries(
    numberingParameters?.separator_numbering || {}
  ).map(([entryKey, entryValue]) => ({
    value: entryKey,
    label: entryValue,
  }))

  const [numberingDateFormat, setNumberingDateFormat] = useState<
    Ct.Option<string>
  >({
    value: "",
    label: "",
  })
  const numberingDateFormatOptions =
    numberingParameters?.date_numbering.map((dateFormat) => ({
      value: dateFormat.date_order,
      label: dateFormat.date_order,
    })) || []

  const [nextInvoiceNumber, setNextInvoiceNumber] = useState("1")
  const [numberingRoot, setNumberingRoot] = useState("")
  const debouncedRoot = useDebounce<string>(numberingRoot, 500)

  const isExistingRootOtherThanEdited = Boolean(
    selectedCompanyId &&
      numberingDetails[selectedCompanyId]?.some(
        (d) => d.root === numberingRoot && d.id !== numberingToModify?.id
      )
  )
  const isExistingDeactivatedRoot = Boolean(
    selectedCompanyId &&
      numberingDetails[selectedCompanyId]?.some(
        (d) =>
          d.root === numberingRoot &&
          d.id !== numberingToModify?.id &&
          d.deactivated_at !== null
      )
  )

  const isExistingRootNotDeactivated = Boolean(
    selectedCompanyId &&
      numberingDetails[selectedCompanyId]?.some(
        (d) =>
          d.root === numberingRoot &&
          d.id !== numberingToModify?.id &&
          d.deactivated_at === null
      )
  )

  const showModificationToDeactivatedRouteError =
    createOrModify === "modify" && isExistingDeactivatedRoot

  const showExistingRootError =
    !showModificationToDeactivatedRouteError && createOrModify === "modify"
      ? isExistingRootOtherThanEdited
      : isExistingRootNotDeactivated

  const showReactivationWarning =
    createOrModify === "create" &&
    Boolean(
      selectedCompanyId &&
        numberingDetails[selectedCompanyId]?.some(
          (d) =>
            d.root === numberingRoot &&
            d.id !== numberingToModify?.id &&
            d.deactivated_at !== null
        )
    )

  useEffect(() => {
    if (!selectedCompanyId) return

    dispatch(getCompanyNumberingDetailsThunk(selectedCompanyId))
  }, [debouncedRoot])

  const [numberingType, setNumberingType] = useState<NumberingType>(
    params["document_type"] &&
      ["quotation", "creditNote"].includes(params["document_type"])
      ? (params["document_type"] as NumberingType)
      : "invoice"
  )

  useEffect(() => {
    if (
      params["document_type"] &&
      ["quotation", "creditNote"].includes(params["document_type"])
    ) {
      setNumberingType(params["document_type"] as NumberingType)
    } else {
      setNumberingType("invoice")
    }
  }, [params])

  const preview = generateNumberingPreview(
    {
      numberingOrder: numberingOrder.value || null,
      numberingSeparator: numberingSeparator.label,
      numberingDateFormat: numberingDateFormat.value,
      numberingRoot,
      nextInvoiceNumber,
      numberingLength: numberingLength.value,
    },
    new Date()
  )

  const hasChanged =
    JSON.stringify(initialNumberingToModify) !==
    JSON.stringify({
      numberingOrder: numberingOrder?.value as NumberingOrder,
      numberingSeparator: numberingSeparator?.value,
      numberingDateFormat:
        numberingDateFormat?.value ||
        (numberingDateFormatOptions[0] &&
          numberingDateFormatOptions[0]?.value) ||
        "", // for formats without date (nr | rn)
      numberingRoot,
      nextInvoiceNumber,
      numberingLength: numberingLength.value,
      numberingType,
    })

  const isValid = isValidNumberingPayload({
    numberingOrder: numberingOrder.value || null,
    numberingSeparator: numberingSeparator.label,
    numberingDateFormat: numberingDateFormat.value,
    numberingRoot,
    nextInvoiceNumber,
    numberingLength: numberingLength.value,
    preview,
    numberingMaxLength,
  })

  const createOrModifyNumbering = () => {
    if (!selectedCompanyId || numberingOrder.value === "") {
      return
    }
    dispatch(
      createOrModifyNumberingThunk({
        companyId: selectedCompanyId,
        numberingToModifyId: numberingToModify?.id,
        createOrModify,
        numberingPayload: {
          numberingOrder: numberingOrder.value as NumberingOrder,
          numberingSeparator: numberingSeparator.value,
          numberingDateFormat:
            numberingDateFormat.value || numberingDateFormatOptions[0].value, // for formats without date (nr | rn)
          numberingRoot,
          nextInvoiceNumber,
          numberingLength: numberingLength.value,
          numberingType,
        },
      })
    )
  }

  const maxValue = Array(Number(numberingLength.value || 6))
    .fill("9")
    .join("")

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 52rem)"
    >
      {isDisplayed && (
        <Ct.Card width="auto">
          <Ct.CloseCross onClick={onClose} />
          <TitleWrapper>
            <Ct.Title
              text={intl.formatMessage({
                id: `office.company.numberings.add-modal.${createOrModify}.title`,
              })}
            />
          </TitleWrapper>
          <Ct.Spacer height={4} />
          <DocumentTypeWrapper>
            {numberingType === "invoice" ? (
              <InvoiceDuotone />
            ) : (
              <Invoice
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNumberingType("invoice")
                }}
              />
            )}
            <Ct.Spacer width={6} />
            {numberingType === "creditNote" ? (
              <CreditNoteDuotone />
            ) : (
              <CreditNote
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNumberingType("creditNote")
                }}
              />
            )}
            <Ct.Spacer width={6} />
            {numberingType === "quotation" ? (
              <QuotationDuotone />
            ) : (
              <Quotation
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setNumberingType("quotation")
                }}
              />
            )}
          </DocumentTypeWrapper>
          <Ct.Spacer height={6} />
          <Ct.Row>
            <SelectWrapper>
              <Select
                intl={intl}
                disabled={false}
                value={numberingLength}
                options={numberingLengthOptions}
                domain={"office.company.numberings.add-modal"}
                optionType={"numbering-length"}
                onChangeCallback={(value) => {
                  setNumberingLength(value)
                }}
              />
            </SelectWrapper>
            <Ct.Spacer width={3} />
            <SelectWrapper>
              <Ct.Input
                type="number"
                min={"1"}
                max={maxValue}
                label={intl.formatMessage({
                  id: "office.company.numberings.add-modal.next-invoice-number",
                })}
                value={nextInvoiceNumber}
                onChange={(e) => {
                  const maxValueNumber = Number(maxValue)
                  if (
                    Number(e.target.value) > maxValueNumber ||
                    Number(e.target.value) < 1
                  ) {
                    return
                  }
                  setNextInvoiceNumber(e.target.value)
                }}
              />
            </SelectWrapper>
          </Ct.Row>
          <Ct.Spacer height={4} />
          <Ct.Row>
            <SelectWrapper>
              <Select
                intl={intl}
                disabled={false}
                value={numberingOrder}
                options={numberingOrderOptions}
                domain={"office.company.numberings.add-modal"}
                optionType={"numbering-order"}
                onChangeCallback={(value) => {
                  setNumberingOrder(value as Ct.Option<"" | NumberingOrder>)
                }}
              />
            </SelectWrapper>
            <Ct.Spacer width={3} />

            <SelectWrapper>
              <Select
                intl={intl}
                disabled={false}
                value={numberingSeparator}
                options={separatorOptions}
                domain={"office.company.numberings.add-modal"}
                optionType={"numbering-separator"}
                onChangeCallback={(value) => {
                  setNumberingSeparator(value)
                }}
              />
            </SelectWrapper>
          </Ct.Row>
          <Ct.Spacer height={4} />
          <Ct.Row>
            <SelectWrapper>
              <Ct.Input
                label={intl.formatMessage({
                  id: "office.company.numberings.add-modal.numbering-root",
                })}
                value={numberingRoot}
                onChange={(e) => {
                  setNumberingRoot(e.target.value)
                }}
                showError={
                  showModificationToDeactivatedRouteError ||
                  showExistingRootError
                }
                maxLength={12}
              />
            </SelectWrapper>
            <Ct.Spacer width={3} />

            <SelectWrapper>
              <Select
                intl={intl}
                disabled={disableDateInput(numberingOrder.value || null)}
                value={numberingDateFormat}
                options={numberingDateFormatOptions}
                domain={"office.company.numberings.add-modal"}
                optionType={"numbering-date"}
                onChangeCallback={(value) => {
                  setNumberingDateFormat(value)
                }}
              />
            </SelectWrapper>
          </Ct.Row>
          {showExistingRootError && (
            <>
              <Ct.Spacer />
              <Ct.Row>
                <SelectWrapper>
                  <Text
                    text={intl.formatMessage({
                      id: "office.company.numberings.add-modal.form-error.root",
                    })}
                    textStyle={{
                      color: "amaranth",
                    }}
                  />
                </SelectWrapper>
                <Ct.Spacer width={3} />
                <SelectWrapper />
              </Ct.Row>
            </>
          )}
          {showModificationToDeactivatedRouteError && (
            <>
              <Ct.Spacer />
              <Ct.Row>
                <SelectWrapper>
                  <Text
                    text={intl.formatMessage({
                      id: "office.company.numberings.add-modal.form-error.root-modification",
                    })}
                    textStyle={{
                      color: "amaranth",
                    }}
                  />
                </SelectWrapper>
                <Ct.Spacer width={3} />
                <SelectWrapper />
              </Ct.Row>
            </>
          )}
          <Ct.Spacer height={4} />
          <PreviewZoneHeader>
            <Text
              text={intl.formatMessage(
                {
                  id: "office.company.numberings.add-modal.preview",
                },
                {
                  documentType:
                    numberingType === "quotation"
                      ? "prochain devis"
                      : numberingType === "invoice"
                      ? "prochaine facture"
                      : "prochain avoir",
                }
              )}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 1.75,
              }}
            />
          </PreviewZoneHeader>
          <PreviewZoneContent>
            <div>{preview}</div>

            {numberingMaxLength && (
              <LengthPreview isError={preview.length > numberingMaxLength}>
                {intl.formatMessage(
                  {
                    id: "office.company.numberings.add-modal.max-length",
                  },
                  {
                    currentLength: preview.length,
                    maxLength: numberingMaxLength,
                  }
                )}
              </LengthPreview>
            )}
          </PreviewZoneContent>
          <Ct.Spacer height={6} />
          {showReactivationWarning && (
            <>
              <WarningWrapper>
                <Warning />
                <Ct.Spacer width={0.5} />
                <Text
                  text={intl.formatMessage({
                    id: "office.company.numberings.add-modal.warning.root-reactivated",
                  })}
                  textStyle={{
                    color: "orange",
                  }}
                />
              </WarningWrapper>
              <Ct.Spacer />
            </>
          )}
          <Ct.Button
            label={intl.formatMessage({
              id: `office.company.numberings.add-modal.${createOrModify}`,
            })}
            width={sizes.button.standard}
            onClick={createOrModifyNumbering}
            disabled={!isValid || !hasChanged || showExistingRootError}
          />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const SelectWrapper = styled.div`
  width: 30rem;
`

const TitleWrapper = styled.div`
  width: 94rem;
  margin: 0 20rem;
`

const PreviewZoneHeader = styled.div`
  width: 59rem;
  background-color: ${colors.lavender};
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  padding: 2rem;
  border: 1px solid ${colors.lavender};
`

const PreviewZoneContent = styled.div`
  width: 59rem;
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  padding: 2rem;
  border: 1px solid ${colors.lavender};
`

const DocumentTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 55rem;
`

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LengthPreview = styled.div<{ isError: boolean }>`
  color: ${(props) => (props.isError ? colors.radicalRed : colors.navy)};
  transition: color 0.3s ease-in-out;
`
