import { useIntl } from "react-intl"
import { Transaction } from "../../store/ducks/bank.ducks"
import * as Ct from "ldlj"
import { TabObject, FullTabs } from "../FullTabs"
import { AttachFullDocumentToTransaction } from "./AttachFullDocumentToTransaction"
import { ReactComponent as Attach } from "../../assets/file-attach.svg"
import { ReactComponent as Import } from "../../assets/file-import.svg"
import { useState } from "react"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { useDispatch } from "react-redux"
import { GetDocumentsToAttachToTransactionsThunk } from "../../store/ducks/fullDocuments.ducks"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"

interface FullDocumentProps {
  isDisplayed: boolean
  onClose: () => void
  transaction: Transaction
  goToFullDocAttached: boolean
  attachOrImport: "attach" | "import" | null
}

export const FullDocumentModal = ({
  isDisplayed,
  onClose,
  transaction,
  goToFullDocAttached,
  attachOrImport,
}: FullDocumentProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState<"attach" | "import">(
    attachOrImport || "attach"
  )

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const accountId = getIdFromParams(useParams())("account_id") || 0

  const AttachFullDocContent = (
    <AttachFullDocumentToTransaction
      onClose={() => {
        onClose()
      }}
      transaction={transaction}
      goToFullDocAttached={goToFullDocAttached}
      attachOrImport={selectedTab}
    />
  )

  const tabs: TabObject[] = [
    {
      title: `${intl.formatMessage({
        id: "bank-management.transaction.full-document-modal.attach-tab",
      })}`,
      icon: (
        <StyledAttach
          color={selectedTab === "attach" ? "inital" : colors.navy}
        />
      ),
      onClick: () => {
        setSelectedTab("attach")
        dispatch(
          GetDocumentsToAttachToTransactionsThunk(
            accountId,
            selectedCompanyId,
            transaction.transaction_id,
            false
          )
        )
      },
      content: AttachFullDocContent,
      needNotif: false,
    },
    {
      title: `${intl.formatMessage({
        id: "bank-management.transaction.full-document-modal.import-tab",
      })}`,
      icon: (
        <StyledImport
          color={
            transaction.full_document_id
              ? colors.slateGrey
              : selectedTab === "import"
              ? "white"
              : colors.navy
          }
        />
      ),
      onClick: () => {
        if (transaction.full_document_id) return
        setSelectedTab("import")
        dispatch(
          GetDocumentsToAttachToTransactionsThunk(
            accountId,
            selectedCompanyId,
            transaction.transaction_id,
            true,
            true
          )
        )
      },
      content: AttachFullDocContent,
      disabled: !!transaction.full_document_id,
      needNotif: transaction.need_action,
    },
  ]

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="1vh"
    >
      <Ct.Card width={"95vw"} height={"95vh"}>
        <StyledCross onClick={onClose} />

        <StyledFullTabs>
          <FullTabs
            tabs={tabs}
            selectedTabIndex={selectedTab === "attach" ? 0 : 1}
          />
        </StyledFullTabs>
      </Ct.Card>
    </Ct.Modal>
  )
}

const StyledFullTabs = styled.div`
  width: 100%;
  height: 100%;
`

const StyledAttach = styled(({ ...props }) => <Attach {...props} />)`
  & path {
    fill: ${(props) => props.color};
    transition: 0.3s fill ease-in-out;
  }
`
const StyledImport = styled(({ ...props }) => <Import {...props} />)`
  & path {
    fill: ${(props) => props.color};
    transition: 0.3s fill ease-in-out;
  }
`
const StyledCross = styled(({ ...props }) => <Ct.CloseCross {...props} />)`
  top: 2.5rem;
  right: 2rem;
`
