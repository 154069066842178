import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { boxShadow, colors, sizes } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../../../store/rootReducer"
import { CompanyStub } from "../../../../store/ducks/companies.ducks"
import { VatAccounts } from "../../../../components/accountingPlan/VatAccounts"
import {
  AutoliquidatedVatAccounts,
  AutoliquidatedVatAccountsState,
} from "../../../../components/accountingPlan/AutoliquidatedVatAccounts"
import { useIntl } from "react-intl"
import { useState } from "react"
import {
  AccountsVatRates,
  VatAccountPayload,
  VatAssignMode,
  updateAutoliquidatedVatSettingsThunk,
  updateVatSettingsThunk,
} from "../../../../store/ducks/companySettings.ducks"
import { useDispatch } from "react-redux"
import { usePrompt } from "../../../../utils/usePrompt.hook"

export const Vat = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || CompanyStub
  )

  const [vatAccountsDisabled, setVatAccountsDisabled] = useState(true)
  const [vatAccountsMode, setVatAccountsMode] =
    useState<VatAssignMode>("no_vat")
  const [vatAccounts, setVatAccounts] = useState<VatAccountPayload[]>([])
  const [
    autoliquidatedVatAccountsDisabled,
    setAutoliquidatedVatAccountsDisabled,
  ] = useState(true)
  const [autoliquidatedVatAccounts, setAutoliquidatedVatAccounts] =
    useState<AutoliquidatedVatAccountsState>({
      constructionVatAccount: null,
      constructionReverseVatAccount: null,
      euVatAccount: null,
      euReverseVatAccount: null,
      euGoodsVatAccount: null,
      euGoodsReverseVatAccount: null,
      worldVatAccount: null,
      worldReverseVatAccount: null,
      worldGoodsVatAccount: null,
      worldGoodsReverseVatAccount: null,
    })

  const [autoliquidatedVatRatesDisabled, setAutoliquidatedVatRatesDisabled] =
    useState(true)
  /* eslint-disable camelcase */
  const [autoliquidatedVatRates, setAutoliquidatedVatRates] =
    useState<AccountsVatRates>({
      selected_eu_vat_rate: null,
      selected_eu_goods_vat_rate: null,
      selected_construction_vat_rate: null,
      selected_world_vat_rate: null,
      selected_world_goods_vat_rate: null,
    })

  const submit = () => {
    if (!vatAccountsDisabled) {
      dispatch(
        updateVatSettingsThunk({
          companyId: selectedCompanyId,
          assignMode: vatAccountsMode,
          accounts: vatAccounts,
        })
      )
    }
    dispatch(
      updateAutoliquidatedVatSettingsThunk({
        companyId: selectedCompanyId,
        accounts: autoliquidatedVatAccounts,
        vatRates: autoliquidatedVatRates,
      })
    )
    return
  }

  usePrompt(
    intl.formatMessage({ id: "form.unsaved.message" }),
    !vatAccountsDisabled ||
      !autoliquidatedVatAccountsDisabled ||
      !autoliquidatedVatRatesDisabled
  )

  return (
    <Wrapper>
      <StyledSection>
        <Scroll>
          {company.is_onboarded ? (
            <>
              <VatAccounts
                onAccountsUpdated={({ disabled, accounts, tabSelected }) => {
                  setVatAccountsDisabled(disabled)
                  setVatAccountsMode(tabSelected)
                  setVatAccounts(accounts)
                }}
              />
              <Ct.Spacer height={3} />
            </>
          ) : (
            <div>
              <Ct.Spacer height={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-settings.vat-accounts.soon",
                })}
              />
              <Ct.Spacer height={2} />
            </div>
          )}
          <Ct.Spacer height={2} />
          {vatAccountsMode !== "no_vat" && (
            <>
              <AutoliquidatedVatAccounts
                onAccountsUpdated={({
                  disabled,
                  autoliquidatedVatAccounts,
                  autoliquidatedVatRates,
                }) => {
                  setAutoliquidatedVatAccountsDisabled(disabled)
                  setAutoliquidatedVatRatesDisabled(disabled)
                  setAutoliquidatedVatAccounts(autoliquidatedVatAccounts)
                  setAutoliquidatedVatRates(autoliquidatedVatRates)
                }}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </Scroll>

        <Ct.Spacer height={4} />

        <CenteredButton>
          <Ct.Button
            label={intl.formatMessage({ id: "office-company-settings.cta" })}
            onClick={() => submit()}
            width={sizes.button.standard}
            disabled={vatAccountsDisabled && autoliquidatedVatAccountsDisabled}
          />
        </CenteredButton>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
const Scroll = styled.div`
  overflow-y: auto;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 25px, 100% 25px;
  background-attachment: local, local, scroll, scroll;
`

const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
`
