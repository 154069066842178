import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { Checkbox } from "../Commons/Checkbox"
import { useState } from "react"
import { Button } from "../Commons/Button"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  GenerateChoice,
  generateArchiveThunk,
} from "../../store/ducks/bankArchives.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { colors } from "../../styles/design.config"
/* eslint-disable camelcase */

interface GenerateArchiveChoicesProps {
  isDisplayed: boolean
  onClose: () => void
  fiscalYearId: number
}

export const GenerateArchiveChoicesModal = ({
  isDisplayed,
  onClose,
  fiscalYearId,
}: GenerateArchiveChoicesProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [generateAll, setGenerateAll] = useState<boolean>(true)
  const [generateAllButNotAssigned, setGenerateAllButNotAssigned] =
    useState<boolean>(false)
  const [generateAllButNoFullDoc, setGenerateAllButNoFullDoc] =
    useState<boolean>(false)

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const itemId = getIdFromParams(useParams())("bank_id")
  const accountId = getIdFromParams(useParams())("account_id")
  const fiduciaryId = useRNBSelector((state) => state.fiduciary.id)

  const choice: GenerateChoice =
    generateAllButNoFullDoc && generateAllButNotAssigned
      ? "all_except_no_full_doc_and_no_assignment"
      : generateAllButNoFullDoc
      ? "all_except_no_full_doc"
      : generateAllButNotAssigned
      ? "all_except_no_assignment"
      : "all"

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"} padding={"0"}>
        <Ct.Spacer height={2} />
        <Ct.CloseCross onClick={onClose} />
        <Ct.Text
          text={intl.formatMessage({
            id: `bank-management.archives.generate-archive-modal.title`,
          })}
          textStyle={{
            fontSize: 3,
            fontWeight: 600,
          }}
        />

        <Ct.Spacer height={3} />

        <Line />
        <Ct.Spacer height={3} />

        <div>
          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.archives.generate-archive-modal.text1",
            })}
          />
          <Ct.Spacer height={1} />

          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.archives.generate-archive-modal.text2",
            })}
          />

          <Ct.Spacer height={4} />

          <Checkbox
            id={"choice1"}
            label={intl.formatMessage({
              id: "bank-management.archives.generate-archive-modal.choice1",
            })}
            isChecked={generateAll}
            value={generateAll}
            onChange={() => {
              if (generateAllButNoFullDoc && generateAllButNotAssigned) return
              setGenerateAll(!generateAll)
            }}
            disabled={generateAllButNotAssigned || generateAllButNoFullDoc}
          />

          <Ct.Spacer height={2} />

          <Checkbox
            id={"choice2"}
            label={intl.formatMessage({
              id: "bank-management.archives.generate-archive-modal.choice2",
            })}
            isChecked={generateAllButNotAssigned}
            value={generateAllButNotAssigned}
            onChange={() => {
              if (generateAll) return
              setGenerateAllButNotAssigned(!generateAllButNotAssigned)
            }}
            disabled={generateAll}
          />
          <Ct.Spacer height={2} />

          <Checkbox
            id={"choice3"}
            label={intl.formatMessage({
              id: "bank-management.archives.generate-archive-modal.choice3",
            })}
            isChecked={generateAllButNoFullDoc}
            value={generateAllButNoFullDoc}
            onChange={() => {
              if (generateAll) return
              setGenerateAllButNoFullDoc(!generateAllButNoFullDoc)
            }}
            disabled={generateAll}
          />
          <Ct.Spacer height={6} />

          <WrapperButtons>
            <Button
              label={intl.formatMessage({
                id: "bank-management.archives.generate-archive-modal.cancel",
              })}
              onClick={onClose}
              colorType="Tertiary"
              width={30}
              colorScheme={{
                border: "mist",
                color: "cornflower",
                background: "mist",
              }}
            />

            <Ct.Spacer width={4} />

            <Button
              label={intl.formatMessage({
                id: "bank-management.archives.generate-archive-modal.confirm",
              })}
              onClick={() => {
                if (!(selectedCompanyId && itemId && accountId)) return
                dispatch(
                  generateArchiveThunk(fiscalYearId, {
                    fiduciaryId: fiduciaryId,
                    companyId: selectedCompanyId,
                    itemId: itemId,
                    accountId: accountId,
                    generate_choice: choice,
                  })
                )
                onClose()
              }}
              width={30}
              disabled={
                !generateAllButNoFullDoc &&
                !generateAll &&
                !generateAllButNotAssigned
              }
            />
          </WrapperButtons>
        </div>
        <Ct.Spacer height={4} />
      </Ct.Card>
    </Ct.Modal>
  )
}

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-around;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.mist};
  width: 100%;
`
