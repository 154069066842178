import axios, { AxiosError } from "axios"
import { AnyAction } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { isExchange } from "../../utils/batchDocuments"
import { RootState } from "../rootReducer"
import { getBatchesForCompanyThunk } from "./batchDocuments.ducks"

import { SignRequestResponse, UploadPayload } from "./fiduciary.ducks"
import { DateTime } from "luxon"
import { Dispatch } from "../store.config"
import {
  FullDocumentAPI,
  FullDocumentsActionsEnum,
  FullDocumentsActionsType,
} from "./fullDocuments.ducks"
import { fileExtensionOfString } from "../../utils/string"
import { acceptedBatchExtensions } from "../../utils/file"

export const enum DropDocumentsActionsEnum {
  GET_ACCEPTED_FORMATS_ATTEMPT = "DROP_DOCUMENTS/GetAcceptedFormatsAttempt",
  GET_ACCEPTED_FORMATS_SUCCESS = "DROP_DOCUMENTS/GetAcceptedFormatsSuccess",
  GET_ACCEPTED_FORMATS_ERROR = "DROP_DOCUMENTS/GetAcceptedFormatsError",
  DROP_DOCUMENT_ATTEMPT = "DROP_DOCUMENTS/DropDocumentAttempt",
  DROP_DOCUMENT_SUCCESS = "DROP_DOCUMENTS/DropDocumentSuccess",
  DROP_DOCUMENT_ERROR = "DROP_DOCUMENTS/DropDocumentError",
  DROP_DOCUMENT_EMPTY = "DROP_DOCUMENTS/DropDocumentEmpty",
  SET_UPLOAD_PROGRESS = "DROP_DOCUMENTS/setUploadProgress",
  RESET_UPLOAD_STATUS = "DROP_DOCUMENTS/resetUploadStatus",
  RESET_UPLOADS_TOTAL = "DROP_DOCUMENTS/resetUploadsTotal",
  SET_DUPLICATE_BATCH_ID = "DROP_DOCUMENTS/setDuplicateBatchId",
  RESET_DUPLICATE_BATCH_ID = "DROP_DOCUMENTS/resetDuplicateBatchId",
}

export const getAcceptedFormatsAttemptAction = () =>
  ({ type: DropDocumentsActionsEnum.GET_ACCEPTED_FORMATS_ATTEMPT } as const)
export const getAcceptedFormatsSuccessAction = (payload: string[]) =>
  ({
    type: DropDocumentsActionsEnum.GET_ACCEPTED_FORMATS_SUCCESS,
    payload,
  } as const)
export const getAcceptedFormatsFailureAction = (error: AxiosError) =>
  ({
    type: DropDocumentsActionsEnum.GET_ACCEPTED_FORMATS_ERROR,
    error,
  } as const)

export const dropDocumentsAttemptAction = () =>
  ({ type: DropDocumentsActionsEnum.DROP_DOCUMENT_ATTEMPT } as const)
export const dropDocumentEmptyAction = (payload: { fileName: string }) =>
  ({ type: DropDocumentsActionsEnum.DROP_DOCUMENT_EMPTY, payload } as const)
export const dropDocumentsSuccessAction = (
  payload: DropDocumentResultPayload
) =>
  ({ type: DropDocumentsActionsEnum.DROP_DOCUMENT_SUCCESS, payload } as const)
export const dropDocumentsFailureAction = (
  payload: DropDocumentResultPayload
) => ({ type: DropDocumentsActionsEnum.DROP_DOCUMENT_ERROR, payload } as const)

interface DocumentProgressPayload {
  progress: number
  total: number
  id: number
}

export const setUploadProgressAction = (payload: DocumentProgressPayload) =>
  ({ type: DropDocumentsActionsEnum.SET_UPLOAD_PROGRESS, payload } as const)
export const resetUploadStatusAction = () =>
  ({ type: DropDocumentsActionsEnum.RESET_UPLOAD_STATUS } as const)
export const resetUploadsTotalAction = () =>
  ({ type: DropDocumentsActionsEnum.RESET_UPLOADS_TOTAL } as const)

export const setDuplicateDetailBatchDocumentIdAction = (
  batchDocumentId: number
) =>
  ({
    type: DropDocumentsActionsEnum.SET_DUPLICATE_BATCH_ID,
    payload: { batchDocumentId },
  } as const)
export const resetDuplicateDetailBatchDocumentIdAction = () =>
  ({ type: DropDocumentsActionsEnum.RESET_DUPLICATE_BATCH_ID } as const)

type DropDocumentsActionsType = ReturnType<
  | typeof getAcceptedFormatsAttemptAction
  | typeof getAcceptedFormatsSuccessAction
  | typeof getAcceptedFormatsFailureAction
  | typeof dropDocumentsAttemptAction
  | typeof dropDocumentsSuccessAction
  | typeof dropDocumentsFailureAction
  | typeof dropDocumentEmptyAction
  | typeof setUploadProgressAction
  | typeof resetUploadStatusAction
  | typeof resetUploadsTotalAction
  | typeof setDuplicateDetailBatchDocumentIdAction
  | typeof resetDuplicateDetailBatchDocumentIdAction
>

export const dropDocumentsInitialState: DropDocumentsState = {
  allDroppedDocumentsStatus: "idle",
  allDroppedDocumentsProgress: 0,
  documentsUpload: {},
  rejectedDocumentsNames: [],
  emptyDocumentsNames: [],
  acceptedBatchesFormats: [],
  recentUploads: {},
  documentsCount: {
    accepted: 0,
    officeAccepted: 0,
    rejected: 0,
    total: 0,
  },
  details: { pageTotal: 0 },
  duplicateDetails: { batchDocumentId: null },
}

interface SingleDocumentUpload {
  status: DropDocumentStatus
  progress: number
  total: number
}

export type DropDocumentStatus = "idle" | "loading" | "success" | "error"

export interface DropDocumentsDetails {
  pageTotal: number

  [index: number]: FullDocumentAPI
}

export interface DropDocumentsState {
  allDroppedDocumentsStatus: DropDocumentStatus
  allDroppedDocumentsProgress: number
  documentsUpload: { [index: number]: SingleDocumentUpload }
  emptyDocumentsNames: string[]
  rejectedDocumentsNames: string[]
  acceptedBatchesFormats: string[]
  recentUploads: {
    [index: string]: string
  }
  documentsCount: {
    accepted: number
    officeAccepted: number
    rejected: number
    total: number
  }
  details: DropDocumentsDetails
  duplicateDetails: { batchDocumentId: null | number }
}

export function dropDocumentsReducer(
  state = dropDocumentsInitialState,
  action: DropDocumentsActionsType | FullDocumentsActionsType
): DropDocumentsState {
  switch (action.type) {
    case DropDocumentsActionsEnum.DROP_DOCUMENT_ATTEMPT:
      return { ...state, allDroppedDocumentsStatus: "loading" }

    case DropDocumentsActionsEnum.DROP_DOCUMENT_EMPTY: {
      const otherDocuments = Object.entries(state.documentsUpload)
      const treatedStatuses: DropDocumentStatus[] = ["success", "error"]

      const allOtherDocumentsTreated = otherDocuments
        .map(([_, value]) => value.status)
        .every((docStatus) => treatedStatuses.includes(docStatus))

      const documentsCount = {
        ...state.documentsCount,
        rejected: state.documentsCount.rejected + 1,
      }

      if (allOtherDocumentsTreated) {
        return {
          ...state,
          allDroppedDocumentsStatus: "error",
          emptyDocumentsNames: [
            ...state.emptyDocumentsNames,
            action.payload.fileName,
          ],
          documentsCount,
        }
      }

      return {
        ...state,
        allDroppedDocumentsStatus: "loading",
        emptyDocumentsNames: [
          ...state.emptyDocumentsNames,
          action.payload.fileName,
        ],
        documentsCount,
      }
    }

    case DropDocumentsActionsEnum.DROP_DOCUMENT_SUCCESS: {
      const otherDocuments = Object.entries(state.documentsUpload).filter(
        ([key, _]) => Number(key) !== action.payload.obj_id
      )
      const allOtherDocumentsInSuccess = otherDocuments
        .map(([_, value]) => value.status)
        .every((docStatus) => docStatus === "success")

      const documentsUpload = Object.fromEntries(otherDocuments)
      const isExchangeFile = isExchange(action.payload.file_name)

      const documentsCount = {
        ...state.documentsCount,
        accepted: state.documentsCount.accepted + (isExchangeFile ? 0 : 1),
        officeAccepted:
          state.documentsCount.officeAccepted + (isExchangeFile ? 1 : 0),
        total: state.documentsCount.total + 1,
      }

      if (
        allOtherDocumentsInSuccess &&
        state.allDroppedDocumentsStatus !== "error"
      ) {
        return {
          ...state,
          allDroppedDocumentsStatus: "success",
          documentsUpload,
          documentsCount,
          recentUploads: {
            ...state.recentUploads,
            [action.payload.obj_id]: action.payload.dropTimeStamp as string,
          },
        }
      }

      if (state.allDroppedDocumentsStatus === "error") {
        return {
          ...state,
          allDroppedDocumentsStatus: "error",
          documentsCount,
          recentUploads: {
            ...state.recentUploads,
            [action.payload.obj_id]: action.payload.dropTimeStamp as string,
          },
        }
      }

      return {
        ...state,
        allDroppedDocumentsStatus: "loading",
        documentsUpload,
        documentsCount,
        recentUploads: {
          ...state.recentUploads,
          [action.payload.obj_id]: action.payload.dropTimeStamp as string,
        },
      }
    }

    case DropDocumentsActionsEnum.DROP_DOCUMENT_ERROR: {
      const documentsCount = {
        ...state.documentsCount,
        rejected: state.documentsCount.rejected + 1,
      }

      return {
        ...state,
        allDroppedDocumentsStatus: "error",
        rejectedDocumentsNames: [
          ...state.rejectedDocumentsNames,
          action.payload.file_name,
        ],
        documentsCount,
      }
    }

    case DropDocumentsActionsEnum.SET_UPLOAD_PROGRESS: {
      const otherDocumentsProgresses = Object.entries(state.documentsUpload)
        .filter(([key, _]) => Number(key) !== action.payload.id)
        .map(([_, value]) => value.progress)

      const otherDocumentsTotals = Object.entries(state.documentsUpload)
        .filter(([key, _]) => Number(key) !== action.payload.id)
        .map(([_, value]) => value.total)

      const updatedProgresses = [
        ...otherDocumentsProgresses,
        action.payload.progress,
      ]
      const updatedTotals = [...otherDocumentsTotals, action.payload.total]

      const sum = (accumulator: number, curr: number) => accumulator + curr
      const total = updatedTotals.reduce(sum)
      const progress = updatedProgresses.reduce(sum)

      const allDroppedDocumentsProgress = (progress / total) * 100

      return {
        ...state,
        allDroppedDocumentsProgress,
        documentsUpload: {
          ...state.documentsUpload,
          [action.payload.id]: {
            progress: action.payload.progress,
            status: "loading",
            total: action.payload.total,
          },
        },
      }
    }

    case DropDocumentsActionsEnum.RESET_UPLOAD_STATUS:
      return {
        ...state,
        allDroppedDocumentsStatus: "idle",
        allDroppedDocumentsProgress: 0,
        documentsUpload: {},
      }
    case DropDocumentsActionsEnum.SET_DUPLICATE_BATCH_ID:
      return {
        ...state,
        duplicateDetails: { batchDocumentId: action.payload.batchDocumentId },
      }
    case DropDocumentsActionsEnum.RESET_DUPLICATE_BATCH_ID:
      return {
        ...state,
        duplicateDetails: { batchDocumentId: null },
      }

    case DropDocumentsActionsEnum.RESET_UPLOADS_TOTAL:
      return {
        ...state,
        documentsUpload: {},
        rejectedDocumentsNames: [],
        documentsCount: {
          accepted: 0,
          officeAccepted: 0,
          rejected: 0,
          total: 0,
        },
      }

    case DropDocumentsActionsEnum.GET_ACCEPTED_FORMATS_SUCCESS:
      return {
        ...state,
        acceptedBatchesFormats: action.payload,
      }

    case FullDocumentsActionsEnum.GET_FULL_DOCUMENTS_OF_BATCH_SUCCESS:
      return {
        ...state,
        details: action.fullDocuments.reduce(
          (acc: DropDocumentsDetails, fd) => ({
            ...acc,
            [fd.id]: fd,
            pageTotal:
              acc.pageTotal <= Number(fd.last_page_number)
                ? Number(fd.last_page_number) + 1
                : acc.pageTotal,
          }),
          { pageTotal: 0 }
        ),
      }
    default:
      return { ...state }
  }
}

interface DropDocumentsUpload extends UploadPayload {
  selectedCompanyId: number
}

export const dropDocumentThunk =
  ({
    originalFileName,
    originalFileSize,
    fingerprint,
    file,
    selectedCompanyId,
  }: DropDocumentsUpload) =>
  (dispatch: Dispatch<AnyAction> & ThunkDispatch<RootState, {}, AnyAction>) => {
    dispatch(dropDocumentsAttemptAction())

    const config = (id: number, extension: string | null) => {
      const headers =
        extension && acceptedBatchExtensions.includes(extension)
          ? {
              headers: {
                "Content-type":
                  extension === "pdf"
                    ? "application/pdf"
                    : ["jpg", "jpeg"].includes(extension)
                    ? "image/jpeg"
                    : ["tiff", "tif"].includes(extension)
                    ? "image/tiff"
                    : "image/png",
              },
            }
          : {}

      return {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const progress = progressEvent.loaded
          const total = progressEvent.total
          dispatch(setUploadProgressAction({ progress, total, id }))
        },
        withCredentials: false,
        ...headers,
      }
    }

    let id: number = 0
    axios
      .post<DropDocumentResultPayload>(`/batch_documents`, {
        company_id: selectedCompanyId,
        fingerprint: fingerprint,
        original_file_name: originalFileName,
        original_file_size: originalFileSize,
      })
      .then(({ data }) => {
        id = data.obj_id
        signRequest(id).then((res) => {
          axios
            .put(
              res.data.put_url,
              file,
              config(id, fileExtensionOfString(originalFileName))
            )
            .then(() => {
              axios.put(`/batch_documents/trigger_split`, {
                id: id,
              })
            })
            .then(() => {
              dispatch(
                getBatchesForCompanyThunk({
                  companyId: selectedCompanyId,
                })
              )
              dispatch(
                dropDocumentsSuccessAction({
                  obj_id: id,
                  file_name: originalFileName,
                  status: "ok",
                  dropTimeStamp: DateTime.now().toISO(),
                })
              )
            })
        })
      })
      .catch(() => {
        dispatch(
          dropDocumentsFailureAction({
            status: "error",
            obj_id: id, // ⚠️ id will be 0 if error occurs on first post before signing and uploading
            file_name: file.name,
          })
        )
      })
  }

export interface DropDocumentResultPayload {
  status: "ok" | "error"
  obj_id: number
  file_name: string
  dropTimeStamp?: string
}

export function signRequest(id: number) {
  return axios.put<SignRequestResponse>(`/batch_documents/sign_request`, {
    id,
  })
}

export const getAcceptedFormatsThunk =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getAcceptedFormatsAttemptAction())

    return axios
      .get<{ formats: string[] }>(`/batch_documents/accepted_batches_formats`)
      .then((response) => {
        const acceptedFormats = Array.from(
          // some formats from brackend are duplicates
          new Set(
            // these forst formats are present in backend, it's only a trick to show them first
            [
              ".png",
              ".jpg",
              ".jpeg",
              ".pdf",
              ...response.data.formats.reverse(),
            ]
          )
        )

        dispatch(getAcceptedFormatsSuccessAction(acceptedFormats))
      })
      .catch((e: AxiosError) => {
        dispatch(getAcceptedFormatsFailureAction(e))
      })
  }
