import axios from "axios"
import { loadSoftwareRulesThunk } from "./softwareRules.ducks"
import { Dispatch, RNBThunkAction } from "../store.config"

const enum AccountingSoftwareActionsEnum {
  PENNYLANE_TOKEN_ATTEMPT = "ACCOUNTING_SOFTWARE/pennylaneTokenAttempt",
  PENNYLANE_TOKEN_SUCCESS = "ACCOUNTING_SOFTWARE/pennylaneTokenSuccess",
  PENNYLANE_TOKEN_FAILURE = "ACCOUNTING_SOFTWARE/pennylaneTokenFailure",
}

export const pennylaneTokenAttemptAction = () =>
  ({
    type: AccountingSoftwareActionsEnum.PENNYLANE_TOKEN_ATTEMPT,
  } as const)

export const pennylaneTokenSuccessAction = () =>
  ({
    type: AccountingSoftwareActionsEnum.PENNYLANE_TOKEN_SUCCESS,
  } as const)
export const pennylaneTokenFailureAction = () =>
  ({
    type: AccountingSoftwareActionsEnum.PENNYLANE_TOKEN_FAILURE,
  } as const)

type AccountingSoftwareActionsType = ReturnType<
  | typeof pennylaneTokenAttemptAction
  | typeof pennylaneTokenSuccessAction
  | typeof pennylaneTokenFailureAction
>

export const accountingSoftwareInitialState: AccountingSoftwareState = {
  pennylaneCodeRegisterStatus: "IDLE",
}

export interface AccountingSoftwareState {
  pennylaneCodeRegisterStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
}

export function accountingSoftwareReducer(
  state = accountingSoftwareInitialState,
  action: AccountingSoftwareActionsType
): AccountingSoftwareState {
  switch (action.type) {
    case AccountingSoftwareActionsEnum.PENNYLANE_TOKEN_ATTEMPT:
      return { ...state, pennylaneCodeRegisterStatus: "LOADING" }
    case AccountingSoftwareActionsEnum.PENNYLANE_TOKEN_SUCCESS:
      return { ...state, pennylaneCodeRegisterStatus: "SUCCESS" }
    default:
      return { ...state }
  }
}

interface PennylaneTokenResponseType {}
export const getPennylaneTokenFromCodeThunk =
  ({
    companyId,
    code,
    redirectUri,
  }: {
    companyId: number
    code: string
    redirectUri: string
  }) =>
  (dispatch: Dispatch<AccountingSoftwareActionsType | RNBThunkAction>) => {
    dispatch(pennylaneTokenAttemptAction())
    axios
      .post<PennylaneTokenResponseType>(
        `/companies/${companyId}/connect_pennylane`,
        {
          code,
          redirect_uri: redirectUri,
        }
      )
      .then((response) => {
        dispatch(pennylaneTokenSuccessAction())

        // reload company to hide Pennylane connect button
        dispatch(loadSoftwareRulesThunk(companyId))
      })
      .catch((e) => {
        return dispatch(pennylaneTokenFailureAction())
      })
  }
