import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Alert } from "../Commons/Alert"
import styled from "styled-components/macro"

interface DeleteRuleProps {
  isDisplayed: boolean
  onClose: () => void
  rule: { id: number; priority_number?: number }
  deleteRule: () => void
}

export const DeleteRuleModal = ({
  isDisplayed,
  onClose,
  rule,
  deleteRule,
}: DeleteRuleProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Title
          text={intl.formatMessage({
            id: `bank-management.rules.delete-rule.title`,
          })}
          size={7}
        />
        <Ct.Spacer height={4} />

        <Alert alertType="info">
          <Ct.Text
            text={intl.formatMessage(
              {
                id: rule.priority_number
                  ? "bank-management.rules.delete-rule.text1"
                  : "bank-management.rules.delete-rule.text1-rule-without-document",
              },
              { priority: rule.priority_number }
            )}
          />

          {rule.priority_number && (
            <>
              <Ct.Spacer height={2} />

              <Ct.Text
                text={intl.formatMessage({
                  id: "bank-management.rules.delete-rule.text2",
                })}
              />
            </>
          )}

          <Ct.Spacer height={4} />

          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.rules.delete-rule.text3",
            })}
          />
        </Alert>

        <Ct.Spacer height={4} />
        <Controls>
          <Ct.Button
            label={intl.formatMessage({
              id: `bank-management.rules.delete-rule.cancel`,
            })}
            colorType="Tertiary"
            onClick={() => {
              onClose()
            }}
            width={30}
          />
          <Ct.Spacer height={4} />
          <Ct.Button
            label={intl.formatMessage({
              id: `bank-management.rules.delete-rule.delete`,
            })}
            onClick={() => {
              deleteRule()
            }}
            width={30}
          />
        </Controls>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Controls = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
