import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { ButtonsHeader } from "./ButtonsHeader"
import { StyledTopBar } from "./StyledTopBar"

export const TopBar = () => {
  const intl = useIntl()
  const userFirstName = useRNBSelector((state) => state.user.firstName)

  return (
    <StyledTopBar>
      <Ct.Column>
        <Ct.Title
          size={3}
          text={intl.formatMessage(
            {
              id: "home.greeting",
            },
            {
              firstName: userFirstName,
            }
          )}
        />
        <Ct.Spacer height={0.5} />
        <Ct.Subtitle
          size={2.25}
          text={intl.formatMessage({
            id: "home.subtitle",
          })}
        />
      </Ct.Column>
      <ButtonsHeader />
    </StyledTopBar>
  )
}
