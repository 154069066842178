/* eslint-disable camelcase */
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"

import { ReactComponent as CloseCross } from "../../../../assets/close-cross.svg"
import {
  InvoicePayload,
  getInvoicingDocumentHistoryThunk,
} from "../../../../store/ducks/invoicing.duck"
import { useRNBSelector } from "../../../../store/rootReducer"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  TextNoChanges,
} from "../../../../components/ChangesList"
import { DateTime } from "luxon"

interface InvoicingDocumentHistoryModalProps extends Ct.ModalComponentsProps {
  invoicingDocument: InvoicePayload | undefined
}

export const InvoicingDocumentHistoryModal = ({
  isDisplayed,
  onClose,
  invoicingDocument,
}: InvoicingDocumentHistoryModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!invoicingDocument?.id) return

    dispatch(
      getInvoicingDocumentHistoryThunk({
        invoicing_document_id: invoicingDocument.id,
      })
    )
  }, [invoicingDocument?.id])

  const { invoicingDocumentHistoryEvents } = useRNBSelector((state) => ({
    invoicingDocumentHistoryEvents:
      state.invoicing.invoicingDocumentHistoryEvents,
  }))

  return (
    <Ct.Modal
      left="50%"
      right="50%"
      top="15%"
      isDisplayed={isDisplayed}
      onClose={onClose}
    >
      <Ct.Card width="calc(80vw - 4rem)" height="calc(65vh - 4rem)" padding="0">
        <FullHeight>
          <HeaderTitleWrapper>
            <Ct.Text
              text={intl.formatMessage({
                id: `invoicing-document-history.modal.title`,
              })}
              textStyle={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 2.5,
              }}
            />
            <Close onClick={onClose}>
              <CloseCross />
            </Close>
          </HeaderTitleWrapper>

          <Ct.Separator size="full" color="lightBlueGrey" />

          <Ct.Spacer height={4} />
          <ChangeCardContent>
            <ChangeHeader>
              <Ct.Text
                text={intl.formatMessage({
                  id: `invoicing-document-history.modal.header`,
                })}
                textStyle={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              />
              <Ct.Text
                text={" : "}
                textStyle={{
                  fontWeight: 700,
                }}
              />
              <Ct.Text
                text={invoicingDocument?.numbering || ""}
                textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
              />
            </ChangeHeader>
            <ChangesList>
              {invoicingDocumentHistoryEvents.length > 0 ? (
                invoicingDocumentHistoryEvents.map((e) => {
                  return (
                    <ChangeItem key={e.timestamp}>
                      <Ct.Text
                        text={intl.formatMessage(
                          {
                            id: `invoicing-document-history.modal.${e.event_type}`,
                          },
                          {
                            date: DateTime.fromJSDate(
                              new Date(e.timestamp)
                            ).toFormat("dd/MM/yyyy à HH:mm"),
                            email: e.email,
                          }
                        )}
                      />
                    </ChangeItem>
                  )
                })
              ) : (
                <TextNoChanges
                  text={intl.formatMessage({
                    id: `invoicing-document-history.modal.no-change`,
                  })}
                />
              )}
            </ChangesList>
          </ChangeCardContent>
        </FullHeight>
      </Ct.Card>
    </Ct.Modal>
  )
}

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`
const HeaderTitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

const Close = styled.div`
  right: 2rem;
  position: absolute;
  cursor: pointer;
`
