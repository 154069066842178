import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useMemo, useState, useRef } from "react"
import { ReactComponent as CloseCross } from "../assets/close-cross.svg"
import { useIntl } from "react-intl"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import {
  ProductFormatted,
  addProductReset,
  addProductThunk,
  modifyProductReset,
  modifyProductThunk,
} from "../store/ducks/invoicing.duck"
import { VatRatesOptions } from "../store/ducks/vatRates.ducks"
import { useParams } from "react-router-dom"
import { useSelectedCompany } from "../hooks/useSelectedCompany"
import { useRNBSelector } from "../store/rootReducer"
import { regexPriceWithComma, toFixed } from "../utils/numbers"
import { Select } from "../components/Commons/Select"
import { InputNumber } from "../components/Commons/InputNumber"
import { Input } from "../components/Commons/Input"

interface ProductProps extends Ct.ModalComponentsProps {
  createOrModify: "create" | "modify"
  selectedProduct?: ProductFormatted
  documentType?: "creditNote" | "quotation" | "invoicing"
}

export const CreateOrModifyProductModal = (props: ProductProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const selectedCompany = useSelectedCompany(params, dispatch)

  const { addProductStatus, modifyProductStatus, units, vatRatesOptions } =
    useRNBSelector((state) => ({
      units: state.invoicing.units,
      addProductStatus: state.invoicing.addProductStatus,
      modifyProductStatus: state.invoicing.modifyProductStatus,
      vatRatesOptions: state.vatRates.vatRatesOptions,
    }))

  const [currentUnitValue, setCurrentUnitValue] = useState<UnitsOption>({
    value: "UNSET",
    label: "",
  })
  const [currentVatRateValue, setCurrentVatRateValue] =
    useState<VatRatesOptions>({ value: "UNSET", label: "" })
  const [creditNoteOnly, setCreditNoteOnly] = useState(false)

  const schema = yup.object({
    productNameValue: yup.string().required(),
    price: yup.string().matches(regexPriceWithComma, "format").required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const [productDescription, productNameValue, price] = watch([
    "productDescription",
    "productNameValue",
    "price",
  ])

  const [priceSetted, setPrice] = useState(0)

  useEffect(() => {
    setPrice(price)
  }, [price])

  interface UnitsOption {
    value: string
    label: string
  }
  const initialUnitsOptionsList: Array<UnitsOption> = useMemo(() => {
    return units.map((u) => ({
      value: u.id.toString(),
      label: u.unit,
    }))
  }, [units])

  const unitsOptions: Array<UnitsOption> = initialUnitsOptionsList

  const handleFormSubmit = () => {
    if (selectedCompany) {
      if (props.createOrModify === "create") {
        dispatch(
          addProductThunk(
            {
              price: priceSetted.toString(),
              productName: productNameValue.toLowerCase().trim(),
              productDescription,
              unitId: currentUnitValue.value,
              vatRateId: currentVatRateValue.value,
              creditNoteOnly,
            },
            selectedCompany.id
          )
        )
      } else if (props.createOrModify === "modify" && props.selectedProduct) {
        dispatch(
          modifyProductThunk(
            {
              oldPrice: props.selectedProduct?.unitPrice.toString() || "",
              newPrice: priceSetted.toString(),
              oldProductName: props.selectedProduct?.productName || "",
              newProductName: productNameValue.toLowerCase().trim(),
              oldUnitId: props.selectedProduct?.unitId.toString() || "",
              newUnitId: currentUnitValue.value,
              oldVatRateId: props.selectedProduct?.vatRateId.toString() || "",
              newVatRateId: currentVatRateValue.value,
              oldCreditNoteOnly: props.selectedProduct?.creditNoteOnly || false,
              newCreditNoteOnly: creditNoteOnly,
              oldDescription: props.selectedProduct?.productDescription || "",
              newDescription: productDescription,
            },
            props.selectedProduct?.id,
            selectedCompany.id
          )
        )
      }
    }
  }

  const resetForm = () => {
    reset({
      productNameValue: undefined,
      productDescription: undefined,
      price: undefined,
    })

    setValue("productNameValue", "")
    setValue("productDescription", "")
    setValue("price", "")
    setCurrentUnitValue({ value: "UNSET", label: "" })
    setCurrentVatRateValue({ value: "UNSET", label: "" })
  }

  useEffect(() => {
    if (props.isDisplayed && props.selectedProduct) {
      setValue("productNameValue", props.selectedProduct.productName)
      setValue("productDescription", props.selectedProduct.productDescription)
      setValue("price", props.selectedProduct.unitPrice)
      const selectedUnitOption = initialUnitsOptionsList.find(
        (u) => u.value === props.selectedProduct?.unitId?.toString()
      )
      if (selectedUnitOption) {
        setCurrentUnitValue(selectedUnitOption)
      }

      const vatRateToSet = vatRatesOptions.find(
        (vat) => Number(vat.value) === props.selectedProduct?.vatRateId
      )
      if (vatRateToSet) {
        setCurrentVatRateValue({
          value: vatRateToSet.value,
          label: vatRateToSet.label,
        })
      }
    }
  }, [props.isDisplayed])

  useEffect(() => {
    if (
      modifyProductStatus === "SUCCESS" ||
      addProductStatus === "SUCCESS" ||
      !props.isDisplayed
    ) {
      resetForm()
      props.onClose()
    }
  }, [addProductStatus, modifyProductStatus, reset, props])

  useEffect(() => {
    if (productNameValue) {
      setValue(
        "productNameValue",
        productNameValue.replace(/  +/, " ").trimStart()
      )
    }
  }, [productNameValue])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = `${
        Math.trunc((productDescription.length / 90) * 2) + 6
      }rem`
    }
  }, [productDescription])

  return (
    <Ct.Modal
      isDisplayed={props.isDisplayed}
      onClose={() => {
        dispatch(modifyProductReset())
        dispatch(addProductReset())
        props.onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"139rem"} radius={1.25}>
        <CloseCrossWrapper onClick={() => props.onClose()}>
          <CloseCross />
        </CloseCrossWrapper>
        <Ct.Title
          text={intl.formatMessage({
            id: `invoicing.settings.products.${props.createOrModify}-product.title`,
          })}
        />
        <Ct.Spacer height={6} />
        <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
          <InputsWrapper>
            <Ct.StyledDuoInput>
              <Ct.Input
                name={"productNameValue"}
                register={register as unknown as UseFormRegister<FieldValues>}
                value={productNameValue}
                label={intl.formatMessage({
                  id: "invoicing.settings.products.add-product.label.product-name",
                })}
              />
              <Ct.Spacer width={3} />
              <Select
                intl={intl}
                options={unitsOptions}
                value={currentUnitValue}
                label={"unit"}
                domain={"invoicing.settings.products"}
                optionType={"add-product.label.unit"}
                onChangeCallback={(e) => {
                  setCurrentUnitValue(e as VatRatesOptions)
                }}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <Ct.StyledDuoInput>
              <InputNumber
                step={0.01}
                name={"price"}
                register={register as unknown as UseFormRegister<FieldValues>}
                value={priceSetted}
                min={0.0}
                label={intl.formatMessage({
                  id: "invoicing.settings.products.add-product.label.price",
                })}
                onChange={(e: { target: { value: string } }) => {
                  if (!e.target.value || parseFloat(e.target.value) < 0) {
                    setPrice(0)
                  } else {
                    setPrice(parseFloat(e.target.value))
                  }
                }}
              />
              <Ct.Spacer width={3} />
              <Select
                intl={intl}
                options={vatRatesOptions}
                value={currentVatRateValue}
                label={"vat"}
                domain={"invoicing.settings.products"}
                optionType={"add-product.label.vat"}
                // eslint-disable-next-line
                onChangeCallback={(e) => {
                  setCurrentVatRateValue(e as VatRatesOptions)
                }}
              />
              <Ct.Spacer width={3} />
              <InputNumber
                value={Number(
                  toFixed(
                    priceSetted *
                      (1 +
                        Number(currentVatRateValue.label.replace("%", "")) /
                          100),
                    2
                  )
                )}
                label={intl.formatMessage({
                  id: "invoicing.settings.products.add-product.label.price-vat-included",
                })}
                onChange={(e: { target: { value: string } }) => {
                  return
                }}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <Input
              AutoGrowing={true}
              ref={inputRef}
              maxWidth={"90rem"}
              name={"productDescription"}
              register={register as unknown as UseFormRegister<FieldValues>}
              value={productDescription}
              label={intl.formatMessage({
                id: "invoicing.settings.products.add-product.label.product-description",
              })}
              required={false}
            />
            <Ct.Spacer height={4} />
            {params["*"] !== "products" &&
            props.documentType !== "creditNote" ? null : (
              <Ct.Checkbox
                isChecked={creditNoteOnly}
                label={intl.formatMessage({
                  id: "invoicing.settings.products.add-product.only-credit-note",
                })}
                onChange={() =>
                  setCreditNoteOnly(
                    params["*"] !== "products" &&
                      props.documentType !== "creditNote"
                      ? false
                      : !creditNoteOnly
                  )
                }
                name="onlyCreditNote"
              />
            )}
          </InputsWrapper>
          <Ct.Spacer height={4} />
          <Ct.Button
            type="submit"
            label={intl.formatMessage({
              id: `invoicing.settings.products.${props.createOrModify}-product.validate-button`,
            })}
            width={42.5}
            height={6}
            disabled={
              props.createOrModify === "create"
                ? Object.keys(errors).length !== 0 ||
                  currentVatRateValue.value === "UNSET" ||
                  currentUnitValue.value === "UNSET" ||
                  [productNameValue].includes("") ||
                  priceSetted === null ||
                  priceSetted === undefined
                : Object.keys(errors).length !== 0 ||
                  currentVatRateValue.value === "UNSET" ||
                  currentUnitValue.value === "UNSET" ||
                  [productNameValue].includes("") ||
                  priceSetted === null ||
                  priceSetted === undefined ||
                  (props.selectedProduct?.productName === productNameValue &&
                    Number(props.selectedProduct?.unitPrice).toFixed(2) ===
                      Number(priceSetted).toFixed(2) &&
                    productDescription ===
                      props.selectedProduct?.productDescription &&
                    currentUnitValue.value.toString() ===
                      props.selectedProduct?.unitId.toString() &&
                    currentVatRateValue.value.toString() ===
                      props.selectedProduct?.vatRateId.toString() &&
                    props.selectedProduct?.creditNoteOnly === creditNoteOnly)
            }
          />
        </Ct.StyledForm>
      </Ct.Card>
    </Ct.Modal>
  )
}

const InputsWrapper = styled.div`
  width: 58.8%;
`

const CloseCrossWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
