import { IntlShape } from "react-intl"
import * as yup from "yup"

export const multipleEmailsValidation = yup
  .string()
  .test(
    "all-values-email",
    "Toutes les valeurs ne sont pas des emails",
    function (v) {
      if (!v) return false

      return everyPartIsEmail(v)
    }
  )

export const optionalMultipleEmailsValidation = yup
  .string()
  .test(
    "all-values-email",
    "Toutes les valeurs ne sont pas des emails",
    function (v) {
      if (v === "") return true
      if (!v) return false

      return everyPartIsEmail(v)
    }
  )

function everyPartIsEmail(emailsString: string) {
  const emails = emailsString.split(",")

  const allAreEmails = emails.every((email) =>
    String(email)
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
  return allAreEmails
}

export const getMailMessage = ({
  invoiceType,
  numberingPreview,
  companyName,
  vatIncludedTotal,
  dueDate,
  intl,
}: {
  invoiceType: "quotation" | "invoicing" | "creditNote"
  numberingPreview: string
  companyName: string
  vatIncludedTotal: string
  dueDate: string
  intl: IntlShape
}) => {
  if (invoiceType === "invoicing") {
    return `Bonjour,

${intl.formatMessage(
  {
    id: `${invoiceType}.issuance.email.message`,
  },
  { numberingPreview: numberingPreview }
)} de l'entreprise ${companyName}.
Cette facture ${numberingPreview} d'un montant de ${vatIncludedTotal}€ est payable avant le ${dueDate}.
Merci d'inclure la référence ${numberingPreview} lors de votre paiement.

Merci de votre compréhension`
  }

  return `Bonjour,

${intl.formatMessage(
  {
    id: `${invoiceType}.issuance.email.message`,
  },
  { numberingPreview: numberingPreview }
)} ${numberingPreview} de l'entreprise ${companyName}.

Merci de votre compréhension`
}
