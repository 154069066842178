import axios, { AxiosError } from "axios"
import { Dispatch } from "../store.config"

import { AnyAction } from "redux"
import { sortMergedCompaniesKpisByFavoriteAndCount } from "../../utils/fiduciary"

export enum KPIsActionEnum {
  GET_KPIS_ATTEMPT = "KPIS/getKPIsAttempt",
  GET_KPIS_SUCCESS = "KPIS/getKPIsSuccess",
  GET_KPIS_FAILURE = "KPIS/getKPIsFailure",
}

export const getFiduciaryKPIsAttemptAction = () =>
  ({ type: KPIsActionEnum.GET_KPIS_ATTEMPT } as const)
export const getFiduciaryKPIsSuccessAction = (filesArray: Array<KPIFile>) =>
  ({ type: KPIsActionEnum.GET_KPIS_SUCCESS, filesArray } as const)
export const getFiduciaryKPIsFailureAction = (error: AxiosError) =>
  ({ type: KPIsActionEnum.GET_KPIS_FAILURE, error } as const)

export type KPIsActionType = ReturnType<
  | typeof getFiduciaryKPIsAttemptAction
  | typeof getFiduciaryKPIsSuccessAction
  | typeof getFiduciaryKPIsFailureAction
>

export interface KPIFile {
  company_name: string
  total_fd_count: number
  total_fd_period: number
  buy: number
  sell: number
  pending: number
  deactivated: number
  is_favorite: boolean
  id: number
  accounting_software_reference: string
  created_at: string
  siren: string
  accounting_type: "cash_accounting" | "accrual_accounting"
  email: string
}

export interface KPIsState {
  files: {
    [index: number]: KPIFile
  }
  startDate: string
  endDate: string
}

export const KPIsInitialState: KPIsState = {
  files: {},
  startDate: "",
  endDate: "",
}

export function KPIsReducer(state = KPIsInitialState, action: KPIsActionType) {
  switch (action.type) {
    case KPIsActionEnum.GET_KPIS_SUCCESS: {
      const companies = {
        ...state,
        files: sortMergedCompaniesKpisByFavoriteAndCount(
          Object.values(
            action.filesArray.reduce(
              (acc: KPIsState["files"], current: KPIFile) => ({
                ...acc,
                [current.id]: { ...current },
              }),
              {}
            )
          )
        ),
      }
      return companies
    }

    default:
      return state
  }
}

export const getFiduciaryKPIsThunk =
  (fiduciaryId: number, dateParams: { startDate: string; endDate: string }) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFiduciaryKPIsAttemptAction())
    return axios
      .get<KPIFile[]>(`/fiduciaries/${fiduciaryId}/kpis`, {
        params: {
          start_date: dateParams.startDate,
          end_date: dateParams.endDate,
        },
      })
      .then(({ data }) => {
        dispatch(getFiduciaryKPIsSuccessAction(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getFiduciaryKPIsFailureAction(e))
      })
  }
