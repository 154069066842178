import { ReactNode } from "react"
import styled from "styled-components/macro"
import { colors, gradients } from "../../styles/design.config"
import { NavigateFunction } from "react-router-dom"

export interface MenuItemProps {
  label: string
  linkTo?: string
  selected: boolean
  iconIdle: ReactNode
  iconSelected: ReactNode
  iconMenu?: ReactNode
  useNavigate: () => NavigateFunction
  disabled?: boolean
}

export const MenuItem = ({
  label,
  selected,
  linkTo,
  iconIdle,
  iconSelected,
  iconMenu,
  useNavigate,
  disabled,
}: MenuItemProps) => {
  const navigate = useNavigate()
  const click = () => {
    if (!selected && linkTo && !disabled) navigate(linkTo)
  }
  return (
    <ItemWrapper selected={selected} disabled={disabled} onClick={click}>
      <IconContainer selected={selected} disabled={disabled}>
        {selected ? iconSelected : iconIdle}
      </IconContainer>
      <SecondPartWrapper>
        <TextWrapper>
          <span>{label}</span>
        </TextWrapper>
        {!disabled && <TextWrapper>{iconMenu}</TextWrapper>}
      </SecondPartWrapper>
    </ItemWrapper>
  )
}

interface BarProps {
  selected: boolean
  disabled?: boolean
}

const IconContainer = styled.div<BarProps>`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 2rem;
    width: 2rem;
    stroke: ${({ selected }) => (selected ? "" : "")};

    & path {
      fill: ${({ selected, disabled }) => (disabled ? colors.moon : selected ? "white" : colors.steelBlue)};
    }
  }
`
const ItemWrapper = styled.h3<BarProps>`
  display: flex;
  width: 25.25rem;
  height: 4rem;
  border-radius: 1.25rem;
  font-family: "poppins", sans-serif;
  color: ${({ selected, disabled }) => (disabled ? colors.moon : selected ? "white" : colors.steelBlue)};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  line-height: 4rem;
  font-size: 1.75rem;
  background: ${({ selected, disabled }) => (disabled ? "none" : selected ? gradients.blue : "none")};
  text-transform: uppercase;
  user-select: none;

  transition: 0.2s font-weight ease-in-out, 0.4s background ease-in-out;

  cursor: ${({disabled}) => disabled ? `not-allowed`:  `pointer`};
`
const SecondPartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`
