/* eslint-disable camelcase */
import {
  FullDocumentOnly,
  HistoryModification,
} from "../store/ducks/inputMask.ducks"
import { buyOrSell } from "./company"
import {
  LineToAdd,
  WritingLine,
  DocumentType,
  AllEntryDatumTypes,
} from "./inputMask"

type FullDocumentModificationsType =
  | "reference"
  | "due_date"
  | "document_date"
  | "buy_or_sell"
  | "document_type"

type WritingLineModificationsType = "account" | "amount"

type WritingLineChangeType = "create_line" | "delete_line"

export interface FullDocumentModification {
  type: FullDocumentModificationsType
  previousValue: string | null
  newValue: string | null
  email: string
  created_at: string
  amount: string
  currencyCode: string
}

export interface WritingLineChange {
  type: WritingLineChangeType
  email: string
  created_at: string
  typeOfLine: LineToAdd
}

export interface WritingLineModification {
  type: WritingLineModificationsType
  previousValue: string | null
  newValue: string | null
  email: string
  created_at: string
  typeOfLine: LineToAdd
  amount: string
  currencyCode: string
  entryDatum: AllEntryDatumTypes
}

const getBuyOrSellTextValue = (buyOrSell: buyOrSell) => {
  return buyOrSell === "buy" ? "achat" : "vente"
}

const getDocumentTypeTextValue = (documentType: DocumentType) => {
  return documentType === "invoice" ? "facture" : "avoir"
}

export const getFullDocumentModification = ({
  initial_full_document,
  edited_full_document,
  initial_writing_lines,
  edited_writing_lines,
  created_at,
  email,
}: {
  initial_full_document: FullDocumentOnly | null
  edited_full_document: FullDocumentOnly | null
  initial_writing_lines: WritingLine[]
  edited_writing_lines: WritingLine[]
  email: string
  created_at: string
}) => {
  if (!initial_full_document || !edited_full_document) {
    return []
  }
  const modifications: FullDocumentModification[] = []

  const amount =
    edited_writing_lines
      .find(
        (l) =>
          l.datum_type === "tax_included" ||
          l.datum_type === "tax_included_bill_of_exchange"
      )
      ?.amount.toString() || ""
  const currencyCode =
    edited_writing_lines.find(
      (l) =>
        l.datum_type === "tax_included" ||
        l.datum_type === "tax_included_bill_of_exchange"
    )?.currency_code || ""
  if (
    initial_full_document.document_type !== edited_full_document.document_type
  ) {
    modifications.push({
      type: "document_type",
      previousValue: getDocumentTypeTextValue(
        initial_full_document.document_type
      ),
      newValue: getDocumentTypeTextValue(edited_full_document.document_type),
      email,
      created_at,
      amount,
      currencyCode,
    })
  }

  if (initial_full_document.buy_or_sell !== edited_full_document.buy_or_sell) {
    modifications.push({
      type: "buy_or_sell",
      previousValue: getBuyOrSellTextValue(initial_full_document.buy_or_sell),
      newValue: getBuyOrSellTextValue(edited_full_document.buy_or_sell),
      email,
      created_at,
      amount,
      currencyCode,
    })
  }

  if (
    initial_full_document.fd_document_reference !==
    edited_full_document.fd_document_reference
  ) {
    modifications.push({
      type: "reference",
      previousValue: initial_full_document.fd_document_reference,
      newValue: edited_full_document.fd_document_reference,
      email,
      created_at,
      amount,
      currencyCode,
    })
  }

  if (
    initial_full_document.fd_document_due_date !==
    edited_full_document.fd_document_due_date
  ) {
    modifications.push({
      type: "due_date",
      previousValue: initial_full_document.fd_document_due_date,
      newValue: edited_full_document.fd_document_due_date,
      email,
      created_at,
      amount,
      currencyCode,
    })
  }

  if (
    initial_full_document.fd_document_date !==
    edited_full_document.fd_document_date
  ) {
    modifications.push({
      type: "document_date",
      previousValue: initial_full_document.fd_document_date,
      newValue: edited_full_document.fd_document_date,
      email,
      created_at,
      amount,
      currencyCode,
    })
  }

  return modifications
}

export const getWritingLineChanges = ({
  initial_writing_lines,
  edited_writing_lines,
  created_at,
  email,
}: {
  initial_writing_lines: WritingLine[]
  edited_writing_lines: WritingLine[]
  email: string
  created_at: string
}) => {
  const writingLineChanges: WritingLineChange[] = []
  const writingLineModifications: WritingLineModification[] = []

  const previousWritingLines = [
    ...new Set(initial_writing_lines.map((w) => w.writing_line_uuid)),
  ]
  const newWritingLines = [
    ...new Set(edited_writing_lines.map((w) => w.writing_line_uuid)),
  ]
  const addedLines: string[] = []
  const removedLines: string[] = []

  for (let previousWritingLine of previousWritingLines) {
    if (!newWritingLines.includes(previousWritingLine)) {
      removedLines.push(previousWritingLine)
      writingLineChanges.push({
        type: "delete_line",
        email,
        created_at,
        typeOfLine: getWritingLineType(
          initial_writing_lines.filter(
            (l) => l.writing_line_uuid === previousWritingLine
          )
        ),
      })
    }
  }

  for (let newWritingLine of newWritingLines) {
    if (!previousWritingLines.includes(newWritingLine)) {
      addedLines.push(newWritingLine)
      writingLineChanges.push({
        type: "create_line",
        email,
        created_at,
        typeOfLine: getWritingLineType(
          edited_writing_lines.filter(
            (l) => l.writing_line_uuid === newWritingLine
          )
        ),
      })
    }
  }

  const commonLines = edited_writing_lines.filter(
    (l) => !addedLines.includes(l.writing_line_uuid)
  )

  for (let commonLine of commonLines) {
    const previousLine = initial_writing_lines.find(
      (l) =>
        l.writing_line_uuid === commonLine.writing_line_uuid &&
        l.datum_type === commonLine.datum_type
    )
    if (!previousLine) break

    if (commonLine.account?.id !== previousLine.account?.id) {
      writingLineModifications.push({
        type: "account",
        previousValue: previousLine.account
          ? `${previousLine.account?.id} ${previousLine.account?.details}`
          : "",
        newValue: commonLine.account
          ? `${commonLine.account?.id} ${commonLine.account?.details}`
          : "",
        email,
        created_at,
        typeOfLine: getWritingLineType(
          initial_writing_lines.filter(
            (l) => l.writing_line_uuid === commonLine.writing_line_uuid
          )
        ),
        amount: commonLine.amount,
        currencyCode: "EUR",
        entryDatum: commonLine.datum_type,
      })
    }

    if (commonLine.amount !== previousLine.amount) {
      writingLineModifications.push({
        type: "amount",
        previousValue: previousLine.amount,
        newValue: commonLine.amount,
        email,
        created_at,
        typeOfLine: getWritingLineType(
          initial_writing_lines.filter(
            (l) => l.writing_line_uuid === commonLine.writing_line_uuid
          )
        ),
        amount: commonLine.amount,
        currencyCode: "EUR",
        entryDatum: commonLine.datum_type,
      })
    }
  }

  return [...writingLineModifications, ...writingLineChanges]
}

const getWritingLineType = (writingLines: WritingLine[]): LineToAdd => {
  if (writingLines.some((w) => w.datum_type === "deposit")) {
    return "deposit"
  }

  if (writingLines.some((w) => w.datum_type === "reverse_deposit")) {
    return "reverse_deposit"
  }

  if (writingLines.some((w) => w.datum_type === "eu_vat")) {
    return "eu_vat"
  }

  if (writingLines.some((w) => w.datum_type === "construction_vat")) {
    return "construction_vat"
  }

  const vatLine = writingLines.find((l) => l.datum_type === "vat")

  if (!vatLine) return "0"

  return String(vatLine.vat_rate) as LineToAdd
}

export const getHistoryOldValue = (
  historyModification: HistoryModification
) => {
  if (isLineChange(historyModification)) {
    return ""
  }

  return historyModification.previousValue
}

export const getHistoryNewValue = (
  historyModification: HistoryModification
) => {
  if (isLineChange(historyModification)) {
    return ""
  }

  return historyModification.newValue
}

export const getHistoryAmount = (historyModification: HistoryModification) => {
  if (isLineChange(historyModification)) {
    return ""
  }

  return historyModification.amount
}

export const getHistoryCurrencyCode = (
  historyModification: HistoryModification
) => {
  if (isLineChange(historyModification)) {
    return ""
  }

  return historyModification.currencyCode
}

export const getEntryType = (historyModification: HistoryModification) => {
  if (!isLineModification(historyModification)) {
    return ""
  }

  switch (historyModification.entryDatum) {
    case "vat":
      return "TVA"
    case "tax_excluded":
      return "HT"
    case "tax_included":
      return "TTC"
  }

  return ""
}

export const isLineChange = (
  historyModification: HistoryModification
): historyModification is WritingLineChange => {
  return (
    historyModification.type === "create_line" ||
    historyModification.type === "delete_line"
  )
}
export const isLineModification = (
  historyModification: HistoryModification
): historyModification is WritingLineModification => {
  return (
    (historyModification as WritingLineModification).entryDatum !== undefined
  )
}
