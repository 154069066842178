import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import * as Ct from "ldlj"
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import {
  getBuyOrSellFromParams,
  getIdFromParams,
} from "../../../../utils/company"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { ReactComponent as Eye } from "../../../../assets/eyeFilled.svg"
import { ReactComponent as Chevron } from "../../../../assets/right-chevron-solo.svg"
import { ReactComponent as CloseCrossSVG } from "../../../../assets/close-cross.svg"
import { ReactComponent as Fuel } from "../../../../assets/fuel.svg"
import { ReactComponent as Calendar } from "../../../../assets/calendarSearch.svg"
import { ReactComponent as InfoFull } from "../../../../assets/info-full.svg"

import { FiscalYearPicker } from "../../../../components/FiscalYearPicker"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useDispatch } from "react-redux"
import {
  GetFiscalYearMerchantsThunk,
  getMerchantData,
  getOutputDisplayThunk,
  Merchant,
  modifyMerchantThunk,
  Document,
  autoliquidatedLockInstructions,
  AutoliquidatedLockInstructionsType,
  switchMerchantFuelThunk,
  updateAutoliquidatedDefaultVatRateThunk,
} from "../../../../store/ducks/merchants.ducks"
import {
  filterMerchantsAndDocs,
  optionOfAccount,
  sortMerchantByAccount,
  sortMerchantByBannedAccounts,
  sortMerchantByVatAccount,
  sortMerchants,
  sortMerchantsByDocumentsCount,
  sortMerchantsByIsFuel,
  sortMerchantsByName,
} from "../../../../utils/merchants"
import { Alert } from "../../../../components/Commons/Alert"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { loadPNLAccountsThunk } from "../../../../store/ducks/accounts.ducks"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../components/dropDocuments/StyledDropsComponents"
import AutoSizer from "react-virtualized-auto-sizer"
import React from "react"
import { VariableSizeGrid } from "react-window"
import { MerchantChangeModal } from "../../../../components/modifyMerchant/MerchantChangeModal"
import { Select } from "../../../../components/Commons/Select"
import {
  DisplayedMask,
  FullDocumentsTable,
} from "../../../../components/EDM/FullDocumentsTable"
import { MerchantHistoryModal } from "../../../../components/EDM/MerchantHistoryModal"
import { ImageOrPdfViewer } from "../../../../components/dropDocuments/ImageOrPdfViewer"
import { DeactivateDocumentModal } from "../../../../components/DeactivateDocumentModal"
import { FullDocumentHistoryModal } from "../../../../components/inputMask/FullDocumentHistoryModal"
import { GetCompanyMerchantCodesDataThunk } from "../../../../store/ducks/merchantCodes.ducks"
import { getVatAccountSettingsThunk } from "../../../../store/ducks/companySettings.ducks"
import { getVatRatesThunk } from "../../../../store/ducks/vatRates.ducks"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../components/Commons/Table"
import { InputMaskModal } from "../../../../components/inputMask/InputMaskModal"
import { Text } from "../../../../components/Commons/Text"
import { Switch } from "../../../../components/Commons/Switch"
import { deactivateFullDocumentReset } from "../../../../store/ducks/fullDocuments.ducks"
import { InputDateRange } from "../../../../components/Commons/InputDateRange"
import { DateTime } from "luxon"
import { FiscalYear } from "../../../../store/ducks/fiscalYears.ducks"
import { filterDocs } from "../../../../utils/fullDocuments"

export const getHighlightedText = (text: string, highlight: string) => {
  if (!highlight || !text) {
    return text
  }

  const parts = text.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={
            part.toLowerCase().includes(highlight.toLowerCase()) ||
            highlight.toLowerCase().includes(part.toLowerCase())
              ? {
                  background: "rgba(255, 197, 66, 0.3)",
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </>
  )
}

export const AccountingPlanVat = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const buyOrSell = getBuyOrSellFromParams(useParams())
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { selectedFiscalYearId, fiscalYearsCompany } = useRNBSelector(
    (state) => ({
      selectedFiscalYearId: state.fiscalYears.selectedFiscalYearId,
      fiscalYearsCompany:
        state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId],
    })
  )
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYear>({
    id: 0,
    beginExercise: "",
    endExercise: "",
    companyId: 0,
  })
  const [customDateFilterIsActive, setCustomDateFilterIsActive] =
    useState<boolean>(false)
  const [customDateRange, setCustomDateRange] = useState<[Date, Date]>([
    DateTime.now().set({ day: 1 }).toJSDate(),
    DateTime.now().set({ day: DateTime.now().daysInMonth }).toJSDate(),
  ])
  const merchants = useRNBSelector((state) => state.merchants.merchants)
  const userTypology = useRNBSelector((state) => state.user.typology)
  const availableAccounts = useRNBSelector((state) =>
    buyOrSell ? state.accounts.PNL[buyOrSell] : []
  )
  const VATAccounts = useRNBSelector((state) =>
    buyOrSell === "buy"
      ? state.companySettings.possibleBuyAccounts
      : state.companySettings.possibleSellAccounts
  )
  const vatRatesOptions = useRNBSelector(
    (state) => state.vatRates.vatRatesOptions
  )
  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )
  const fullDocumentsDeactivateStatus = useRNBSelector(
    (state) => state.fullDocuments.fullDocumentsDeactivateStatus
  )

  enum SortOptionsValues {
    "merchantName",
    "account",
    "isFuel",
    "bannedAccount",
    "vatAccount",
    "documentsCount",
  }

  const listRef = React.createRef<VariableSizeGrid>()
  const [search, setSearch] = useState<string>("")
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })
  const [sortedMerchants, setSortedMerchants] = useState<Merchant[]>([])
  const [displayChanges, setDisplayChanges] = useState<number | null>(null)
  const [displayHistory, setDisplayHistory] = useState<Merchant | undefined>(
    undefined
  )
  const [displayPreview, setDisplayPreview] = useState<{
    isDisplayed: boolean
    fullDocId: number | null
    elementName: string
    merchantId: number | null
  }>({
    isDisplayed: true,
    fullDocId: null,
    elementName: "",
    merchantId: null,
  })
  const [historyDisplayFullDoc, setHistoryDisplayFullDoc] = useState<{
    isDisplayed: boolean
    fullDocId: null | number
  }>({
    isDisplayed: false,
    fullDocId: null,
  })
  const [toggles, setToggles] = useState<{ id: number; toggle: boolean }[]>([])
  const [rowHeigths, setRowHeights] = useState<number[]>([])
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [fullDocIdListToDelete, setFullDocIdListToDelete] = useState<number[]>(
    []
  )
  const [sortType, setSortType] = useState<
    { type: SortOptionsValues; asc: boolean } | undefined
  >({ type: SortOptionsValues?.merchantName, asc: true })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [displayMask, setDisplayMask] = useState<DisplayedMask>({
    isDisplayed: false,
    fullDocumentId: null,
    elementName: "",
    element: {} as Document,
    merchantId: 0,
  })
  const [toggleName, setToggleName] = useState<{
    open: boolean
    merchantId: number | null
  }>({ open: false, merchantId: null })

  const defaultVATAccountOptions: Ct.OptionList<string> = VATAccounts.map(
    (account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    })
  )

  if (buyOrSell === "buy") {
    if (companyOnboarding === "finished") {
      defaultVATAccountOptions.unshift(
        { value: "lock_without_vat", label: "Sans TVA" },
        {
          value: "lock_eu_vat",
          label: "Autoliquidation Intracommunautaire Services",
        },
        {
          value: "lock_eu_goods_vat",
          label: "Autoliquidation TVA Intracommunautaire Biens",
        },
        { value: "lock_construction_vat", label: "Autoliquidation BTP" },
        {
          value: "lock_world_vat",
          label: "Autoliquidation TVA Hors UE Services",
        },
        {
          value: "lock_world_goods_vat",
          label: "Autoliquidation TVA Hors UE Biens",
        }
      )
    }
  } else if (buyOrSell === "sell") {
    defaultVATAccountOptions.unshift({
      value: "lock_without_vat",
      label: "Sans TVA",
    })
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setSortType({ type: option, asc: asc })

    if (option === SortOptionsValues?.merchantName) {
      setSortedMerchants(sortMerchantsByName(sortedMerchants, asc))
    } else if (option === SortOptionsValues?.isFuel) {
      setSortedMerchants(sortMerchantsByIsFuel(sortedMerchants, asc))
    } else if (option === SortOptionsValues?.documentsCount) {
      setSortedMerchants(sortMerchantsByDocumentsCount(sortedMerchants, asc))
    } else if (option === SortOptionsValues?.account) {
      setSortedMerchants(
        sortMerchantByAccount(
          sortedMerchants,
          asc,
          buyOrSell ? buyOrSell : "buy"
        )
      )
    } else if (option === SortOptionsValues?.bannedAccount) {
      setSortedMerchants(sortMerchantByBannedAccounts(sortedMerchants, asc))
    } else if (option === SortOptionsValues?.vatAccount) {
      setSortedMerchants(
        sortMerchantByVatAccount(
          sortedMerchants,
          asc,
          buyOrSell ? buyOrSell : "buy",
          defaultVATAccountOptions
        )
      )
    }
  }

  const filterAndSortMerchants = useCallback(
    (
        search: string,
        customDateFilterIsActive: boolean,
        customDateRange: [Date, Date]
      ) =>
      (merchants: { [index: number]: Merchant }) => {
        if (merchants) {
          setSortedMerchants(
            Object.values(merchants)
              .filter(
                filterMerchantsAndDocs(
                  search,
                  customDateFilterIsActive,
                  customDateRange
                )
              )
              .sort(sortMerchants(true))
          )
        }
      },
    []
  )

  const autoToggle = () => {
    const newToggle = sortedMerchants.map((me) => {
      const merchantNeedToggle = toggles.some(
        (t) => t.id === me.id && t.toggle === true
      )
      if (!merchantNeedToggle) {
        setToggleName({ open: false, merchantId: null })
      }
      return { id: me.id, toggle: merchantNeedToggle }
    })

    setToggles(newToggle)

    const newRows = new Array(sortedMerchants.length)
      .fill(true)
      .map((_, index) => {
        return newToggle[index].toggle === true ? 60 + 440 : 60
      })

    setRowHeights(newRows)
    onResize()
  }

  const toogleHandler = (merchantId: number, forceToggle?: boolean) => {
    const found = toggles.find((t) => t.id === merchantId)
    if (found) {
      const index = toggles.indexOf(found)

      toggles.splice(index, 1, {
        id: merchantId,
        toggle: forceToggle ? !forceToggle : !found.toggle,
      })
      setToggleName({ open: false, merchantId: null })
    }

    const newRows = new Array(sortedMerchants.length)
      .fill(true)
      .map((_, index) => {
        return toggles[index].toggle === true ? 60 + 440 : 60
      })

    setRowHeights(newRows)
    onResize()
  }

  const toggleHandlerMoreOrLess = (merchantId: number, more: boolean) => {
    if (more) {
      const merchant = sortedMerchants.find((m) => m.id === merchantId)
      if (merchant) {
        const indexMerchant = sortedMerchants.indexOf(merchant)

        const newRows = new Array(sortedMerchants.length)
          .fill(true)
          .map((_, index) => {
            return indexMerchant === index ? 60 + 60 : 60
          })

        setToggleName({ open: true, merchantId: merchant.id })
        setRowHeights(newRows)
        onResize()
      }
    } else {
      const newRows = new Array(sortedMerchants.length)
        .fill(true)
        .map((_) => 60)

      setToggleName({ open: false, merchantId: null })
      setRowHeights(newRows)
      onResize()
    }
  }

  const deToggleAll = () => {
    setToggles(
      sortedMerchants.map((me) => {
        return { id: me.id, toggle: false }
      })
    )

    setRowHeights(new Array(sortedMerchants.length).fill(true).map(() => 60))

    onResize()
  }

  const onResize = () => {
    if (listRef.current != null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const handleDelete = (fullDocId: number, uniqueDelete: boolean) => {
    if (uniqueDelete) {
      setFullDocIdListToDelete([fullDocId])
      setDisplayDeleteModal(true)
    } else {
      if (fullDocIdListToDelete.includes(fullDocId)) {
        setFullDocIdListToDelete((prevValue) =>
          prevValue.filter((e) => e !== fullDocId)
        )
      } else setFullDocIdListToDelete((prevValue) => [...prevValue, fullDocId])
    }
  }

  const getFullDocumentCountByMerchant = (merchant: Merchant) => {
    const { documents } = merchant
    if (documents && documents.length > 0) {
      return documents.filter(
        filterDocs(
          search || "",
          merchant.name,
          customDateFilterIsActive,
          customDateRange
        )
      ).length
    } else return "0"
  }

  useMemo(() => {
    if (merchants) {
      filterAndSortMerchants(
        search,
        customDateFilterIsActive,
        customDateRange
      )(merchants)

      if (toggles.length > 0) {
        autoToggle()
        onResize()
      } else {
        setToggles(
          sortedMerchants.map((me) => {
            return { id: me.id, toggle: false }
          })
        )

        setRowHeights(
          new Array(sortedMerchants.length).fill(true).map(() => 60)
        )
      }
      return
    }
  }, [search, merchants, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (userTypology === "customer") {
      navigate("/unauthorized")
    }
  }, [userTypology])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedMerchants(
          Object.values(merchants)
            .filter(
              filterMerchantsAndDocs(
                search,
                customDateFilterIsActive,
                customDateRange
              )
            )
            .sort(sortMerchants(true))
        )
      }
    }
  }, [columnToSort, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (sortedMerchants.length > 0 && sortType) {
      sorter(sortType.asc)(sortType.type)
    }
    setFullDocIdListToDelete([])
  }, [merchants, selectedFiscalYearId])

  useEffect(() => {
    if (!(buyOrSell && selectedFiscalYearId)) {
      return
    }

    setSearch("")
    setRowHeights([])
    setToggles([])
    setSortedMerchants([])
    setHighlightValues({ highlight: false, searchString: "" })

    dispatch(
      GetFiscalYearMerchantsThunk({
        fiscalYearId: selectedFiscalYearId,
        buyOrSell: buyOrSell,
      })
    )

    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))

    setSearch("")
  }, [dispatch, buyOrSell, selectedFiscalYearId, selectedCompanyId])

  useEffect(() => {
    dispatch(loadPNLAccountsThunk(selectedCompanyId))
    dispatch(getVatAccountSettingsThunk({ companyId: selectedCompanyId }))
    dispatch(getVatRatesThunk())
  }, [dispatch])

  useEffect(() => {
    if (sortedMerchants.length > 0 && search.length >= 1) {
      const timeOutId = setTimeout(() => {
        filterAndSortMerchants(
          search,
          customDateFilterIsActive,
          customDateRange
        )(merchants)
        setHighlightValues({ searchString: search, highlight: true })
      }, 500)

      return function cleanUp() {
        clearTimeout(timeOutId)
      }
    } else {
      setHighlightValues({ searchString: "", highlight: false })
    }
  }, [dispatch, merchants, search, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (sortedMerchants.length > 0 && search.length >= 1) {
      const merchantsFullDocsFound = Object.values(merchants).filter(
        filterMerchantsAndDocs(
          search,
          customDateFilterIsActive,
          customDateRange
        )
      )

      const newToggle = sortedMerchants.map((me) => {
        const merchantNeedToggle = merchantsFullDocsFound.some(
          (m) => m.id === me.id
        )
        return { id: me.id, toggle: merchantNeedToggle }
      })

      setToggles(newToggle)

      const newRows = new Array(sortedMerchants.length)
        .fill(true)
        .map((_, index) => {
          return newToggle[index].toggle === true ? 60 + 340 : 60
        })

      setRowHeights(newRows)
      onResize()
    } else {
      autoToggle()
    }
  }, [sortedMerchants, search, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (fiscalYearsCompany) {
      let companyFySelected = fiscalYearsCompany.find(
        (fy) => fy.id === selectedFiscalYearId
      )
      if (companyFySelected) {
        setSelectedFiscalYear(companyFySelected)
        setCustomDateRange([
          new Date(companyFySelected.beginExercise),
          new Date(companyFySelected.endExercise),
        ])
      }
    }
  }, [selectedFiscalYearId])

  useEffect(() => {
    if (["success", "error"].includes(fullDocumentsDeactivateStatus)) {
      dispatch(deactivateFullDocumentReset())
      setFullDocIdListToDelete([])
    }
  }, [fullDocumentsDeactivateStatus])

  const columns: Ct.TableBuilder<Merchant>[] = [
    {
      headerText: `accounting-plan.table-header.${buyOrSell}`,
      content: (merchant: Merchant) => (
        <FlexItem>
          <StyledText>
            {getHighlightedText(
              capitalizeFirstLetter(merchant.name),
              highlightValues.searchString
            )}
          </StyledText>
          {buyOrSell && (
            <EditWrapper
              onClick={() => {
                dispatch(
                  getOutputDisplayThunk(
                    selectedCompanyId,
                    merchant.id,
                    buyOrSell
                  )
                )
                setDisplayChanges(merchant.id)
              }}
            >
              <StyledEdit />
              <Ct.Spacer width={2} />
            </EditWrapper>
          )}
        </FlexItem>
      ),
    },
    {
      headerText: `ged.merchants.table-header.vat-account`,
      content: (merchant: Merchant) => {
        let defaultValue = { value: "", label: "" }
        let defaultVatRateValue = { value: "", label: "" }

        if (buyOrSell) {
          if (merchant[`${buyOrSell}_vat_default_account_id`]) {
            defaultValue = defaultVATAccountOptions.find(
              (a) =>
                a.value ===
                String(merchant[`${buyOrSell}_vat_default_account_id`])
            ) || { value: "", label: "" }
          } else {
            switch (merchant[`${buyOrSell}_lock_vat_instructions`]) {
              case "lock_without_vat":
                defaultValue = {
                  value: "lock_without_vat",
                  label: "Sans TVA",
                }
                break
              case "lock_eu_vat":
                defaultValue = {
                  value: "lock_eu_vat",
                  label: "Autoliquidation TVA Intracommunautaire Services",
                }
                break
              case "lock_eu_goods_vat":
                defaultValue = {
                  value: "lock_eu_goods_vat",
                  label: "Autoliquidation TVA Intracommunautaire Biens",
                }
                break
              case "lock_world_vat":
                defaultValue = {
                  value: "lock_world_vat",
                  label: "Autoliquidation TVA HORS UE Services",
                }
                break
              case "lock_world_goods_vat":
                defaultValue = {
                  value: "lock_world_goods_vat",
                  label: "Autoliquidation TVA HORS UE Biens",
                }
                break
              case "lock_construction_vat":
                defaultValue = {
                  value: "lock_construction_vat",
                  label: "Autoliquidation BTP",
                }
                break
              default:
                break
            }
          }
        }

        if (
          buyOrSell === "buy" &&
          Number(merchant.autoliquidated_default_vat_rate_id)
        ) {
          defaultVatRateValue = vatRatesOptions.find(
            (e) =>
              e.value === String(merchant.autoliquidated_default_vat_rate_id)
          ) || { value: "", label: "" }
        }

        return (
          <>
            <DoubleItemSelect>
              {buyOrSell && (
                <SelectWrapper width={buyOrSell === "buy" ? 60 : 100}>
                  <Select
                    intl={intl}
                    optionType={"label"}
                    domain={`ged.merchants.code.vat-account`}
                    options={defaultVATAccountOptions}
                    value={defaultValue}
                    isClearable={true}
                    onChangeCallback={(
                      selectedAccount: Ct.Option<string> | null
                    ) => {
                      const VATAccountValue =
                        selectedAccount?.value === "" ||
                        selectedAccount?.value === undefined
                          ? null
                          : selectedAccount?.value === "lock_without_vat"
                          ? "lock_without_vat"
                          : autoliquidatedLockInstructions.includes(
                              selectedAccount?.value as AutoliquidatedLockInstructionsType
                            )
                          ? (selectedAccount?.value as AutoliquidatedLockInstructionsType)
                          : Number(selectedAccount?.value)

                      if (selectedAccount?.value === defaultValue.value) {
                        return
                      }

                      if (
                        !autoliquidatedLockInstructions.includes(
                          selectedAccount?.value as AutoliquidatedLockInstructionsType
                        )
                      )
                        dispatch(
                          updateAutoliquidatedDefaultVatRateThunk(
                            merchant.id,
                            null
                          )
                        )

                      if (buyOrSell && selectedFiscalYearId) {
                        dispatch(
                          modifyMerchantThunk(selectedCompanyId)(buyOrSell)(
                            merchant
                          )(merchant.name.trim())(
                            merchant[`${buyOrSell}_default_account`]
                          )(merchant.banned_accounts)(merchant.merchant_code)(
                            VATAccountValue
                          )
                        )
                      }
                    }}
                    moreSpace={true}
                  />
                </SelectWrapper>
              )}
              {buyOrSell === "buy" && (
                <SelectWrapper width={40}>
                  <Select
                    intl={intl}
                    optionType={"label"}
                    domain={`ged.merchants.code.vat-account-vat`}
                    options={vatRatesOptions.filter((e) => e.label !== "0.0%")}
                    disabled={
                      !autoliquidatedLockInstructions.includes(
                        defaultValue.value as AutoliquidatedLockInstructionsType
                      )
                    }
                    value={defaultVatRateValue}
                    isClearable={true}
                    onChangeCallback={(
                      selectedVatRate: Ct.Option<string> | null
                    ) => {
                      const vatRateValue =
                        selectedVatRate?.value === "" ||
                        selectedVatRate?.value === undefined
                          ? null
                          : selectedVatRate.value
                      dispatch(
                        updateAutoliquidatedDefaultVatRateThunk(
                          merchant.id,
                          vatRateValue
                        )
                      )
                    }}
                    moreSpace={true}
                  />
                </SelectWrapper>
              )}
            </DoubleItemSelect>
          </>
        )
      },
    },
    {
      headerText: `ged.merchants.table-header.fuel-vat`,
      content: (merchant: Merchant) => {
        return (
          <>
            <ReactTooltip effect={"solid"} place="bottom" />

            <SwitchWrapper
              data-tip={intl.formatMessage({
                id: merchant.is_fuel_vat
                  ? `merchant-${buyOrSell}.fuel.tooltip`
                  : `merchant-${buyOrSell}.fuel.disabled.tooltip`,
              })}
            >
              <Switch
                value={merchant.is_fuel_vat}
                onToggle={() => {
                  dispatch(
                    switchMerchantFuelThunk({
                      merchantId: merchant.id,
                      newIsFuel: !merchant.is_fuel_vat,
                    })
                  )
                }}
                icon={<StyledFuel />}
              />
            </SwitchWrapper>
          </>
        )
      },
    },
    {
      headerText: `ged.merchants.table-header.${buyOrSell}`,
      content: (merchant: Merchant) => {
        const bannedAccounts = merchant.banned_accounts
        const unbannedAccounts = Object.values(availableAccounts).filter(
          (a) => !bannedAccounts?.map((ba) => ba.id).includes(a.id)
        )
        const defaultAccountOptions = unbannedAccounts.map(optionOfAccount)

        let defaultValue = { value: "", label: "" }
        if (buyOrSell && merchant[`${buyOrSell}_default_account`]?.id) {
          const selectedMerchantDefaultAccount =
            merchant[`${buyOrSell}_default_account`]
          if (selectedMerchantDefaultAccount) {
            defaultValue = optionOfAccount(selectedMerchantDefaultAccount)
          }
        }

        return (
          <ItemSelect>
            {buyOrSell && (
              <Select
                intl={intl}
                optionType={"label"}
                domain={`office.modify-merchant.${buyOrSell}.default-account`}
                options={defaultAccountOptions}
                value={defaultValue}
                isClearable={true}
                onChangeCallback={(
                  selectedAccount: Ct.Option<string> | null
                ) => {
                  const account = selectedAccount
                    ? Number(selectedAccount?.value)
                    : selectedAccount

                  if (selectedAccount?.value === defaultValue.value) {
                    return
                  }
                  if (buyOrSell && selectedFiscalYearId) {
                    dispatch(
                      modifyMerchantThunk(selectedCompanyId)(buyOrSell)(
                        merchant
                      )(merchant.name.trim())(
                        account ? availableAccounts[account] : null
                      )(merchant.banned_accounts)(merchant.merchant_code)(
                        merchant[`${buyOrSell}_vat_default_account_id`]
                      )
                    )
                  }
                }}
              />
            )}
          </ItemSelect>
        )
      },
    },
    {
      headerText: `ged.merchants.table-header.banned-account`,
      content: (merchant: Merchant) => {
        const bannedNumbers = merchant.banned_accounts
          .map((a) => String(a.number))
          .join(", ")

        return (
          <FlexBetween>
            {merchant.banned_accounts.length > 0 && (
              <StyledP
                moreOrLess={
                  toggleName.open && toggleName.merchantId === merchant.id
                }
              >
                <Text
                  text={
                    toggleName.open && toggleName.merchantId === merchant.id
                      ? bannedNumbers
                      : bannedNumbers.slice(0, listWidth < 1300 ? 35 : 50)
                  }
                  textStyle={{
                    fontSize: 2,
                    color: "navy",
                  }}
                />

                {bannedNumbers.length > (listWidth < 1300 ? 35 : 50) && (
                  <span>
                    <SeeMore
                      text={intl.formatMessage(
                        { id: "accounting-plan.table-content.see-more" },
                        {
                          moreOrLess:
                            toggleName.open &&
                            toggleName.merchantId === merchant.id
                              ? "moins"
                              : "plus",
                        }
                      )}
                      textStyle={{
                        fontSize: 2,
                        color: "cornflower",
                      }}
                      onClick={() => {
                        toogleHandler(merchant.id, true)
                        toggleHandlerMoreOrLess(merchant.id, !toggleName.open)
                      }}
                    />
                  </span>
                )}
              </StyledP>
            )}
          </FlexBetween>
        )
      },
    },
    {
      headerText: "ged.merchants.table-header.document-count",
      content: (merchant: Merchant) => (
        <div>{getFullDocumentCountByMerchant(merchant)}</div>
      ),
    },
    {
      headerText: "ged.merchants.table-header.historical",
      content: (merchant: Merchant) => (
        <LastItem>
          <EditWrapper
            onClick={() => {
              setDisplayHistory(merchant)
              dispatch(getMerchantData(merchant.id, selectedCompanyId))
            }}
          >
            <StyledEye />
          </EditWrapper>
        </LastItem>
      ),
    },
    {
      headerText: "empty",
      content: (merchant: Merchant) => (
        <ChevronWrapper
          onClick={() => {
            toogleHandler(merchant.id)
          }}
          rotation={toggles.find((t) => t.id === merchant.id)?.toggle}
        >
          <Chevron />
        </ChevronWrapper>
      ),
    },
  ]

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const merchant: Merchant = sortedMerchants[rowIndex]
    const index = sortedMerchants.indexOf(merchant)
    const isToggled = toggles.find((t) => t.id === merchant.id)?.toggle === true

    return (
      <>
        <div
          style={{
            ...style,
            backgroundColor: rowIndex % 2 === 0 ? "white" : "#F4F8FF",
          }}
        >
          {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
          <StyledRow>
            <Ct.Spacer height={3} />

            {columnIndex === columns.length && <Ct.Spacer width={4} />}
            <StyledCell
              width={
                columnIndex === 4
                  ? String(Number(style?.width) - 40) + "px"
                  : columnIndex === 1
                  ? String(Number(style?.width) + 40) + "px"
                  : "100%"
              }
              alignItems={columnIndex === 0 ? "flex-start" : "center"}
            >
              {columns[columnIndex].content(merchant)}
            </StyledCell>
          </StyledRow>
        </div>

        {rowIndex === index &&
          columnIndex + 1 === columns.length &&
          isToggled && (
            <>
              <Ct.Spacer height={3} />
              <div
                style={{
                  ...style,
                  width: "98%",
                  top: Number(style?.top) + 60,
                  left: 0,
                  height: "420px",
                  padding: "0 2rem",
                  marginTop: "1rem",
                }}
              >
                <FullDocumentsTable
                  documents={merchant.documents}
                  merchantId={merchant.id}
                  highlightValues={highlightValues}
                  fullDocIdListToDelete={fullDocIdListToDelete}
                  customDateFilterIsActive={customDateFilterIsActive}
                  customDateRange={customDateRange}
                  onOpenInputMask={(displayedMask: DisplayedMask) => {
                    setDisplayMask(displayedMask)
                  }}
                  onPreviewFullDoc={(
                    isDisplayed: boolean,
                    fullDocId: number | null,
                    elementName: string,
                    merchantId: number
                  ) => {
                    setDisplayPreview({
                      isDisplayed,
                      fullDocId,
                      elementName,
                      merchantId,
                    })
                  }}
                  onSelectFullDocToDelete={handleDelete}
                  onHistoryFullDoc={(
                    isDisplayed: boolean,
                    fullDocId: number | null
                  ) => {
                    setHistoryDisplayFullDoc({ isDisplayed, fullDocId })
                  }}
                />
                <Ct.Spacer width={2} />
              </div>
            </>
          )}
      </>
    )
  }

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <HeaderLeftWrapper>
            <StyledInput
              name={"searchMerchant"}
              id={"searchMerchant"}
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(event: { target: HTMLInputElement }) => {
                const value = event.target.value.trimStart().replace("  ", " ")
                if (event.target.value === "") {
                  deToggleAll()
                }
                setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
              }}
              shadowed={true}
              noBorder={true}
            />
          </HeaderLeftWrapper>

          {selectedCompanyId && (
            <FiscalYearPicker
              companyId={selectedCompanyId}
              buyOrSell={buyOrSell}
            />
          )}

          <HeaderRightWrapper>
            <CustomDateContainer>
              {customDateFilterIsActive ? (
                <InputDateRange
                  value={customDateRange}
                  onChange={(value) => setCustomDateRange(value as never)}
                  onClose={() => setCustomDateFilterIsActive(false)}
                  placement="bottomEnd"
                  minimum={selectedFiscalYear.beginExercise}
                  maximum={selectedFiscalYear.endExercise}
                />
              ) : (
                <CustomDateInnerContainer>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "ged.header.date-custom.message",
                    })}
                    textStyle={{
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: 1.8,
                    }}
                  />
                  <StyledInfoFull
                    data-tip={intl.formatMessage({
                      id: "ged.header.date-custom.message.tooltip",
                    })}
                  />
                  <Ct.Button
                    label={<Calendar />}
                    onClick={() => {
                      setCustomDateFilterIsActive(true)
                    }}
                    width={6}
                    height={6}
                  />
                </CustomDateInnerContainer>
              )}
            </CustomDateContainer>

            {fullDocIdListToDelete.length > 0 && <Ct.Spacer width={1} />}
            {fullDocIdListToDelete.length > 0 && (
              <Ct.Button
                label={
                  fullDocumentsDeactivateStatus === "loading" ? (
                    <Ct.SpinningLoader />
                  ) : (
                    `Supprimer la sélection (${fullDocIdListToDelete.length})`
                  )
                }
                onClick={() => {
                  setDisplayDeleteModal(true)
                }}
                width={30}
                colorType="Tertiary"
                colorScheme={{
                  background: "amaranth",
                  color: "white",
                  border: "amaranth",
                }}
              />
            )}
          </HeaderRightWrapper>
        </Header>

        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                // make the widths in percent instead of fixed size for each column
                if (index === 0) {
                  return (12 / 100) * width
                }
                if (index === 1) {
                  return (19 / 100) * width
                }
                if (index === 2) {
                  return (10 / 100) * width
                }
                if (index === 3) {
                  return (19 / 100) * width
                }
                if (index === 4) {
                  return (15 / 100) * width
                }
                if (index === 5 || index === 6) {
                  return (9 / 100) * width
                }

                return (6 / 100) * width
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 1rem"}>
                    {columns.map((column, index) => (
                      <StyledHeaderTitleWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                      >
                        {index < 4 ? (
                          <ClickableTitleSort
                            tid={column.headerText}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable tid={column.headerText} intl={intl} />
                        )}
                      </StyledHeaderTitleWrapper>
                    ))}
                  </HeaderWrapper>

                  {sortedMerchants.length === 0 ? (
                    <LoaderWrapper
                      totalWidth={width}
                      height={(height || 0) - 56}
                    >
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage(
                            {
                              id: "ged.merchants.table.no-merchant1",
                            },
                            {
                              merchantType:
                                `${buyOrSell}` === "buy"
                                  ? "fournisseur"
                                  : "clients",
                            }
                          )}
                        />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "ged.merchants.table.no-merchant2",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <>
                      {rowHeigths.length > 0 && (
                        <GridWrapper totalWidth={width}>
                          <VariableSizeGrid
                            ref={listRef}
                            height={height - 56} // header size
                            rowCount={sortedMerchants.length}
                            width={width} //scrollbar
                            columnWidth={(index) => columnWidths[index]}
                            rowHeight={(index) => rowHeigths[index]}
                            columnCount={columns.length}
                          >
                            {Cell}
                          </VariableSizeGrid>
                        </GridWrapper>
                      )}
                    </>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>

        {displayPreview.fullDocId && displayPreview.merchantId && (
          <Ct.Modal
            isDisplayed={!!displayPreview.fullDocId}
            onClose={() => {
              setDisplayPreview({
                isDisplayed: false,
                fullDocId: null,
                elementName: "",
                merchantId: null,
              })
            }}
            left="25%"
            right="25%"
            top="calc(25vh - 30rem)"
          >
            <StyledCard width="130rem" height={"calc(100vh - 20rem)"}>
              <Ct.Spacer height={2} />
              <HeaderPreviewModal>
                <TextWrapper>
                  <Ct.Title text={displayPreview.elementName} size={3} />
                </TextWrapper>
                <CrossWrapper
                  onClick={() => {
                    setDisplayPreview({
                      isDisplayed: false,
                      fullDocId: null,
                      elementName: "",
                      merchantId: null,
                    })
                  }}
                >
                  <CloseCrossSVG />
                </CrossWrapper>
              </HeaderPreviewModal>
              <Ct.Spacer height={2} />

              <ImageOrPdfViewer
                getUrl={
                  merchants[displayPreview.merchantId].documents.find(
                    (d) => d.id === displayPreview.fullDocId
                  )?.url || ""
                }
              />
            </StyledCard>
          </Ct.Modal>
        )}

        {selectedFiscalYearId &&
          displayMask.isDisplayed &&
          displayMask.fullDocumentId && (
            <InputMaskModal
              isDisplayed={!!displayMask.merchantId && displayMask.isDisplayed}
              fiscalYearId={selectedFiscalYearId}
              merchantId={displayMask.merchantId}
              fullDocumentId={displayMask.fullDocumentId}
              onClose={() => {
                setDisplayMask({
                  isDisplayed: false,
                  fullDocumentId: null,
                  elementName: "",
                  element: {} as Document,
                  merchantId: 0,
                })
              }}
            />
          )}

        {displayHistory && (
          <MerchantHistoryModal
            merchantName={displayHistory.name}
            merchant={displayHistory}
            isDisplayed={
              !!sortedMerchants.find((m) => m.id === displayHistory.id)
                ?.user_merchant_instructions
            }
            onClose={() => {
              setDisplayHistory(undefined)
            }}
          />
        )}

        {displayChanges && (
          <MerchantChangeModal
            isDisplayed={
              !!sortedMerchants.find((m) => m.id === displayChanges)
                ?.outputDisplay
            }
            onClose={() => {
              setDisplayChanges(null)
            }}
            merchantId={displayChanges}
          />
        )}

        <DeactivateDocumentModal
          isDisplayed={displayDeleteModal}
          onClose={() => {
            setDisplayDeleteModal(false)
          }}
          documentIdList={fullDocIdListToDelete}
          companyId={selectedCompanyId || 0}
          whichDocument={"fullDocument"}
          buyOrSell={buyOrSell}
        />

        {historyDisplayFullDoc.isDisplayed && (
          <FullDocumentHistoryModal
            isDisplayed={historyDisplayFullDoc.isDisplayed}
            fullDocumentId={historyDisplayFullDoc.fullDocId}
            onClose={() => {
              setHistoryDisplayFullDoc({ isDisplayed: false, fullDocId: null })
            }}
          />
        )}
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`

const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`

const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const LastItem = styled((props) => <Ct.Cell {...props} />)`
  display: flex;
  justify-content: space-around;
  height: 100%;
`

const StyledText = styled.span`
  padding: 0.5rem;
`

const EditWrapper = styled.span`
  display: flex;
`

const StyledEdit = styled(({ ...props }) => <Edit {...props} />)`
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  width: 3rem;
  height: 3rem;
`

const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: pointer;
`

const ItemSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  /* padding: 0.5rem 2rem; */
  width: 100%;
`

const DoubleItemSelect = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
`

const SelectWrapper = styled.div<{ width: number }>`
  display: flex;
  width: ${({ width }) => `${width}%`};
  padding: 0.5rem 1rem;
`

interface RotationProps {
  rotation?: boolean
}
const ChevronWrapper = styled.span<RotationProps>`
  && svg {
    & path {
      fill: ${colors.rock};
    }
    transition-duration: 0.2s;
    transform: ${({ rotation }) => (rotation ? "rotate(90deg)" : ``)};
  }
`

const StyledRow = styled(Ct.Row)`
  height: 8rem;
`

interface StyledCellProps {
  width: string
  alignItems: string
}
const StyledCell = styled(Ct.ColumnCenterCenter)<StyledCellProps>`
  width: ${(props) => props.width};
  align-items: ${(props) => props.alignItems};
`
const StyledCard = styled(Ct.Card)`
  border-radius: 1rem;
`

const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`

const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`

const StyledHeaderTitleWrapper = styled(HeaderTitleWrapper)`
  text-align: "center";
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

interface StyledPProps {
  moreOrLess: boolean
}
const StyledP = styled.p<StyledPProps>`
  overflow-y: auto;
  height: ${(props) => (props.moreOrLess ? "100%" : "fit-content")};
`

const SeeMore = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  padding-left: 1rem;

  :hover {
    color: ${colors.cornflower};
  }
`

const StyledFuel = styled(Fuel)`
  width: 2.5rem;
  height: 2.5rem;
`
const SwitchWrapper = styled.div`
  width: 80%;
`

const StyledInfoFull = styled(InfoFull)`
  margin: 0 2rem 0 1rem;
  & path {
    fill: ${colors.black};
  }
`

const HeaderLeftWrapper = styled.div`
  width: 70rem;
`
const HeaderRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70rem;
`
const CustomDateContainer = styled.div`
  width: 35rem;
  display: flex;
  justify-content: flex-end;
`
const CustomDateInnerContainer = styled.div`
  display: flex;
  align-items: center;
`
