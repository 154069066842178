import axios from "axios"

import { Dispatch, RNBThunkAction } from "../store.config"
import { sortNumberingsAlphabetically } from "../../utils/numberings"

const enum NumberingsActionsEnum {
  GET_NUMBERINGS_ATTEMPT = "NUMBERINGS/getNumberingsAttempt",
  GET_NUMBERINGS_SUCCESS = "NUMBERINGS/getNumberingsSuccess",
  GET_NUMBERINGS_ERROR = "NUMBERINGS/getNumberingsError",

  GET_NUMBERING_DETAILS_ATTEMPT = "NUMBERINGS/getNumberingDetailsAttempt",
  GET_NUMBERING_DETAILS_SUCCESS = "NUMBERINGS/getNumberingDetailsSuccess",
  GET_NUMBERING_DETAILS_ERROR = "NUMBERINGS/getNumberingDetailsError",

  CREATE_NUMBERING_ATTEMPT = "NUMBERINGS/createNumberingAttempt",
  CREATE_NUMBERING_SUCCESS = "NUMBERINGS/createNumberingSuccess",
  CREATE_NUMBERING_ERROR = "NUMBERINGS/createNumberingError",
  CREATE_NUMBERING_RESET = "NUMBERINGS/createNumberingReset",

  DEACTIVATE_NUMBERINGS_ATTEMPT = "NUMBERINGS/deactivateNumberingsAttempt",
  DEACTIVATE_NUMBERINGS_SUCCESS = "NUMBERINGS/deactivateNumberingsSuccess",
  DEACTIVATE_NUMBERINGS_ERROR = "NUMBERINGS/deactivateNumberingsError",
  DEACTIVATE_NUMBERINGS_RESET = "NUMBERINGS/deactivateNumberingsReset",

  GET_NUMBERINGS_PARAMETERS_ATTEMPT = "NUMBERINGS/getNumberingsParametersAttempt",
  GET_NUMBERINGS_PARAMETERS_SUCCESS = "NUMBERINGS/getNumberingsParametersSuccess",
  GET_NUMBERINGS_PARAMETERS_ERROR = "NUMBERINGS/getNumberingsParametersError",

  GET_NUMBERING_HISTORY_ATTEMPT = "NUMBERINGS/getNumberingHistoryAttempt",
  GET_NUMBERING_HISTORY_SUCCESS = "NUMBERINGS/getNumberingHistorySuccess",
  GET_NUMBERING_HISTORY_ERROR = "NUMBERINGS/getNumberingHistoryError",
}

export const getNumberingsAttemptAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERINGS_ATTEMPT } as const)
export const getNumberingsSuccessAction = (
  numberings: Numbering[],
  numberingMaxLength: number | null,
  selectedCompanyId: number
) =>
  ({
    type: NumberingsActionsEnum.GET_NUMBERINGS_SUCCESS,
    payload: { numberings, numberingMaxLength, selectedCompanyId },
  } as const)
export const getNumberingsErrorAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERINGS_ERROR } as const)

export const getNumberingDetailsAttemptAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERING_DETAILS_ATTEMPT } as const)
export const getNumberingDetailsSuccessAction = (
  numberingRoots: NumberingDetails[],
  selectedCompanyId: number
) =>
  ({
    type: NumberingsActionsEnum.GET_NUMBERING_DETAILS_SUCCESS,
    payload: { numberingRoots, selectedCompanyId },
  } as const)
export const getNumberingDetailsErrorAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERING_DETAILS_ERROR } as const)

export const createNumberingAttemptAction = () =>
  ({ type: NumberingsActionsEnum.CREATE_NUMBERING_ATTEMPT } as const)
export const createNumberingSuccessAction = () =>
  ({
    type: NumberingsActionsEnum.CREATE_NUMBERING_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "office.company.numberings.add-modal.success.title",
      bodyKey: `office.company.numberings.add-modal.success.body`,
    },
  } as const)

type CreateError = "existing_root" | "too_long" | "unknown"

export const createNumberingErrorAction = (reason: CreateError) => {
  return {
    type: NumberingsActionsEnum.CREATE_NUMBERING_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office.company.numberings.add-modal.errors.title",
      bodyKey: `office.company.numberings.add-modal.errors.${reason}`,
    },
  } as const
}
export const createNumberingResetAction = () =>
  ({ type: NumberingsActionsEnum.CREATE_NUMBERING_RESET } as const)

export const deactivateNumberingAttemptAction = () =>
  ({ type: NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_ATTEMPT } as const)
export const deactivateNumberingSuccessAction = (
  deletedNumberingId: number,
  selectedCompanyId: number
) =>
  ({
    type: NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_SUCCESS,
    payload: { selectedCompanyId, deletedNumberingId },
  } as const)
export const deactivateNumberingErrorAction = () =>
  ({ type: NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_ERROR } as const)
export const deactivateNumberingsResetAction = () =>
  ({ type: NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_RESET } as const)

export const getNumberingParametersAttemptAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERINGS_PARAMETERS_ATTEMPT } as const)
export const getNumberingParametersSuccessAction = (
  numberingParameters: NumberingParameters
) =>
  ({
    type: NumberingsActionsEnum.GET_NUMBERINGS_PARAMETERS_SUCCESS,
    payload: { numberingParameters },
  } as const)
export const getNumberingParametersErrorAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERINGS_PARAMETERS_ERROR } as const)

export const getNumberingHistoryAttemptAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERING_HISTORY_ATTEMPT } as const)
export const getNumberingHistorySuccessAction = (
  numberingHistory: NumberingHistory[]
) =>
  ({
    type: NumberingsActionsEnum.GET_NUMBERING_HISTORY_SUCCESS,
    payload: { numberingHistory },
  } as const)
export const getNumberingHistoryErrorAction = () =>
  ({ type: NumberingsActionsEnum.GET_NUMBERING_HISTORY_ERROR } as const)

type NumberingsActionsType = ReturnType<
  | typeof getNumberingsAttemptAction
  | typeof getNumberingsSuccessAction
  | typeof getNumberingsErrorAction
  | typeof getNumberingDetailsAttemptAction
  | typeof getNumberingDetailsSuccessAction
  | typeof getNumberingDetailsErrorAction
  | typeof createNumberingAttemptAction
  | typeof createNumberingSuccessAction
  | typeof createNumberingErrorAction
  | typeof createNumberingResetAction
  | typeof deactivateNumberingAttemptAction
  | typeof deactivateNumberingSuccessAction
  | typeof deactivateNumberingErrorAction
  | typeof deactivateNumberingsResetAction
  | typeof getNumberingParametersAttemptAction
  | typeof getNumberingParametersSuccessAction
  | typeof getNumberingParametersErrorAction
  | typeof getNumberingHistoryAttemptAction
  | typeof getNumberingHistorySuccessAction
  | typeof getNumberingHistoryErrorAction
>

export const numberingsInitialState: NumberingsState = {
  numberings: {},
  numberingDetails: {},
  numberingMaxLength: null,
  fetchNumberingStatus: "idle",
  createNumberingStatus: "idle",
  deleteNumberingStatus: "idle",
  numberingParameters: null,
  numberingHistory: [],
}

export interface NumberingsState {
  numberingMaxLength: number | null
  numberings: { [companyId: number]: Numbering[] } // only contains active numberings, and only has formated_number info
  numberingDetails: { [companyId: number]: NumberingDetails[] } // also contains deactivated and details to prefill on modify (root, separator, date format...)
  fetchNumberingStatus: "idle" | "loading" | "success" | "error"
  createNumberingStatus: "idle" | "loading" | "success" | "error"
  deleteNumberingStatus: "idle" | "loading" | "success" | "error"
  numberingParameters: NumberingParameters | null
  numberingHistory: NumberingHistory[]
}

export function numberingsReducer(
  state = numberingsInitialState,
  action: NumberingsActionsType
): NumberingsState {
  switch (action.type) {
    case NumberingsActionsEnum.GET_NUMBERINGS_ATTEMPT:
      return { ...state, fetchNumberingStatus: "loading" }
    case NumberingsActionsEnum.GET_NUMBERINGS_SUCCESS: {
      const orderedNumberings = sortNumberingsAlphabetically(
        action.payload.numberings
      )
      return {
        ...state,
        fetchNumberingStatus: "success",
        numberingMaxLength: action.payload.numberingMaxLength,
        numberings: {
          ...state.numberings,
          [action.payload.selectedCompanyId]: orderedNumberings,
        },
      }
    }
    case NumberingsActionsEnum.GET_NUMBERINGS_ERROR:
      return { ...state, fetchNumberingStatus: "error" }

    case NumberingsActionsEnum.GET_NUMBERING_DETAILS_ATTEMPT:
      return { ...state }
    case NumberingsActionsEnum.GET_NUMBERING_DETAILS_SUCCESS: {
      return {
        ...state,
        numberingDetails: {
          ...state.numberingDetails,
          [action.payload.selectedCompanyId]: action.payload.numberingRoots,
        },
      }
    }
    case NumberingsActionsEnum.GET_NUMBERING_DETAILS_ERROR:
      return { ...state, fetchNumberingStatus: "error" }

    case NumberingsActionsEnum.CREATE_NUMBERING_ATTEMPT:
      return { ...state, createNumberingStatus: "loading" }
    case NumberingsActionsEnum.CREATE_NUMBERING_SUCCESS:
      return {
        ...state,
        createNumberingStatus: "success",
      }

    case NumberingsActionsEnum.CREATE_NUMBERING_ERROR:
      return { ...state, createNumberingStatus: "error" }
    case NumberingsActionsEnum.CREATE_NUMBERING_RESET:
      return { ...state, createNumberingStatus: "idle" }

    case NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_ATTEMPT:
      return { ...state, deleteNumberingStatus: "loading" }
    case NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_ERROR:
      return { ...state, deleteNumberingStatus: "error" }
    case NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_RESET:
      return { ...state, deleteNumberingStatus: "idle" }
    case NumberingsActionsEnum.DEACTIVATE_NUMBERINGS_SUCCESS: {
      const numberingsWithoutDeactivated = state.numberings[
        action.payload.selectedCompanyId
      ].filter((n) => n.id !== action.payload.deletedNumberingId)
      return {
        ...state,
        deleteNumberingStatus: "success",
        numberings: {
          ...state.numberings,
          [action.payload.selectedCompanyId]: numberingsWithoutDeactivated,
        },
      }
    }

    case NumberingsActionsEnum.GET_NUMBERINGS_PARAMETERS_SUCCESS: {
      return {
        ...state,
        numberingParameters: action.payload.numberingParameters,
      }
    }

    case NumberingsActionsEnum.GET_NUMBERING_HISTORY_SUCCESS: {
      return {
        ...state,
        numberingHistory: action.payload.numberingHistory,
      }
    }

    case NumberingsActionsEnum.GET_NUMBERING_HISTORY_ATTEMPT: {
      return {
        ...state,
        numberingHistory: [],
      }
    }

    case NumberingsActionsEnum.GET_NUMBERINGS_PARAMETERS_ERROR: {
      return {
        ...state,
        numberingHistory: [],
      }
    }

    default:
      return { ...state }
  }
}

export interface Numbering {
  id: number
  numbering: string
  created_at: string
  user_email: string
  type: NumberingType
  already_used: boolean
}

export interface NumberingDetails {
  id: number
  company_id: number
  user_id: number
  root: string
  number_length: number
  separator: SeparatorNumbering
  order: NumberingOrder
  created_at: string
  updated_at: string
  invoice_numbers_start_with: number
  date_format_id: number
  deactivated_at: string
  numbering_type: NumberingType
}

interface NumberingResponse {
  numbering_max_length: number | null
  numberings: Array<Numbering>
}

export const getCompanyNumberingsThunk =
  (companyId: number) => (dispatch: Dispatch<NumberingsActionsType>) => {
    dispatch(getNumberingsAttemptAction())
    return axios
      .get<NumberingResponse>(`invoicing_numberings/get_company_numberings`, {
        params: { company_id: companyId },
      })
      .then((response) => {
        dispatch(
          getNumberingsSuccessAction(
            response.data.numberings,
            response.data.numbering_max_length,
            companyId
          )
        )
      })
      .catch(() => {
        dispatch(getNumberingsErrorAction())
      })
  }

export const getCompanyNumberingDetailsThunk =
  (companyId: number) => (dispatch: Dispatch<NumberingsActionsType>) => {
    dispatch(getNumberingDetailsAttemptAction())
    return axios
      .get<NumberingDetails[]>(
        `invoicing_numberings/get_company_numbering_details`,
        {
          params: { company_id: companyId },
        }
      )
      .then((response) => {
        dispatch(getNumberingDetailsSuccessAction(response.data, companyId))
      })
      .catch(() => {
        dispatch(getNumberingDetailsErrorAction())
      })
  }

export interface CreateNumberingPayload {
  numberingOrder: NumberingOrder
  numberingSeparator: string
  numberingDateFormat: string
  numberingRoot: string
  nextInvoiceNumber: string
  numberingLength: string
  numberingType: NumberingType
}

export type NumberingType = "invoice" | "quotation" | "creditNote"

export type CreateOrModify = "create" | "modify"

export const createOrModifyNumberingThunk =
  ({
    companyId,
    numberingPayload,
    createOrModify,
    numberingToModifyId,
  }: {
    companyId: number
    numberingPayload: CreateNumberingPayload
    createOrModify: CreateOrModify
    numberingToModifyId: number | undefined
  }) =>
  (dispatch: Dispatch<NumberingsActionsType | RNBThunkAction>) => {
    dispatch(createNumberingAttemptAction())
    const endpointMethod = createOrModify === "create" ? axios.post : axios.put
    const endpointUrl =
      createOrModify === "create"
        ? `invoicing_numberings/create_or_reactivate_numbering`
        : `invoicing_numberings/${numberingToModifyId}/modify_numbering_parameters`

    return endpointMethod<string>(endpointUrl, {
      date_numbering: numberingPayload.numberingDateFormat,
      separator_numbering: numberingPayload.numberingSeparator,
      root: numberingPayload.numberingRoot,
      number_len: Number(numberingPayload.numberingLength),
      order_numbering: numberingPayload.numberingOrder,
      invoice_numbers_start_with: numberingPayload.nextInvoiceNumber,
      numbering_type: numberingPayload.numberingType,
      company_id: companyId,
    })
      .then(() => {
        dispatch(createNumberingSuccessAction())
        dispatch(getCompanyNumberingsThunk(companyId))
      })
      .catch((err) => {
        const errorMessage: string | undefined = err?.response?.data?.error
        if (errorMessage?.includes("Cette numérotation existe")) {
          dispatch(createNumberingErrorAction("existing_root"))
        } else if (errorMessage?.includes("La longueur est trop longue")) {
          dispatch(createNumberingErrorAction("too_long"))
        } else {
          dispatch(createNumberingErrorAction("unknown"))
        }
      })
  }

export const deleteCompanyNumberingThunk =
  ({ companyId, numberingId }: { companyId: number; numberingId: number }) =>
  (dispatch: Dispatch<NumberingsActionsType>) => {
    dispatch(deactivateNumberingAttemptAction())
    return axios
      .post<{ numberings: Array<Numbering> }>(
        `invoicing_numberings/deactivate_numbering`,
        { company_id: companyId, invoice_numbering_id: numberingId }
      )
      .then(() => {
        dispatch(deactivateNumberingSuccessAction(numberingId, companyId))
      })
      .catch(() => {
        dispatch(deactivateNumberingErrorAction())
      })
  }

export const getNumberingParametersThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch<NumberingsActionsType>) => {
    dispatch(getNumberingParametersAttemptAction())
    return axios
      .get<NumberingParameters>(
        `invoicing_numberings/get_numbering_parameters`,
        {
          params: { company_id: companyId },
        }
      )
      .then((response) => {
        dispatch(getNumberingParametersSuccessAction(response.data))
      })
      .catch(() => {
        dispatch(getNumberingParametersErrorAction())
      })
  }

interface NumberingHistory {
  instruction_type: "modify" | "deactivate" | "create" | "reactivate"
  created_at: string
  user_email: string
}

export const getNumberingHistoryThunk =
  ({ numberingId }: { numberingId: number }) =>
  (dispatch: Dispatch<NumberingsActionsType>) => {
    dispatch(getNumberingHistoryAttemptAction())
    return axios
      .get<NumberingHistory[]>(
        `invoicing_numberings/${numberingId}/numbering_history`
      )
      .then((response) => {
        dispatch(getNumberingHistorySuccessAction(response.data))
      })
      .catch(() => {
        dispatch(getNumberingHistoryErrorAction())
      })
  }

export type NumberingOrder =
  | "dnr" //: "[date][num][racine]"
  | "drn" //: "[date][racine][num]"
  | "ndr" //: "[num][date][racine]"
  | "nrd" //: "[num][racine][date]"
  | "rdn" //: "[racine][date][num]"
  | "rnd" //: "[racine][num][date]"
  | "rn" //: "[racine][num]"
  | "nr" //: "[num][racine]"

export type OrderNumbering = Record<NumberingOrder, string>

export interface DateNumbering {
  id: number
  date_order: string
  date_format: string
}

export interface SeparatorNumbering {
  dash: string
  underscore: string
  dot: string
  space: string
  none: string
}

export interface NumberingParameters {
  order_numbering: OrderNumbering
  date_numbering: DateNumbering[]
  separator_numbering: SeparatorNumbering
}
