import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { DateTime } from "luxon"
import { TreasuryFullDocumentHistory } from "../../store/ducks/treasury.ducks"
import { sizes } from "../../styles/design.config"

interface FullDocumentHistoryModalProps extends Ct.ModalComponentsProps {
  file_name: string
  fullDocumentHistory: TreasuryFullDocumentHistory[] | null
}

/* eslint-disable camelcase */
export const FullDocumentTreasuryHistoryModal = ({
  isDisplayed,
  onClose,
  file_name,
  fullDocumentHistory,
}: FullDocumentHistoryModalProps) => {
  const intl = useIntl()

  const close = () => {
    onClose()
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="calc(50vh - 45rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Title
          text={intl.formatMessage({
            id: "treasury.full_document.history.modal.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={file_name}
              textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
            />
          </ChangeHeader>

          {fullDocumentHistory && fullDocumentHistory.length > 0 ? (
            <ChangesList>
              {fullDocumentHistory.map((history) => (
                <ChangeItem key={history.id}>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: `treasury.full_document.history.modal.${history.event_type}`,
                    })}
                  />

                  <Mailto href={`mailto:${history.user_email}`}>
                    {history.user_email}
                  </Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(
                        new Date(history.created_at)
                      ).toFormat("dd/MM/yyyy HH:mm:ss") +
                      ")"
                    }
                  />
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `treasury.full_document.history.modal.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={close}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
