import axios, { AxiosError } from "axios"
import { Dispatch } from "../store.config"

import { AnyAction } from "redux"
import {
  TeamMember,
  UserPermissions,
  UsersTeamResponse,
  UserTypes,
} from "../../model/users"
import { ToasterMessageAction } from "../notifications.config"
import { RootState } from "../rootReducer"
import { Company } from "./companies.ducks"

export type NotificationTimeframe = "weekly" | "daily" | "none"

const enum TeamActionsEnum {
  SELECT_USER_TO_EDIT = "TEAM/selectUserToEdit",
  EDIT_USER_RESET = "TEAM/editUserReset",

  EDIT_USER_NAME_ATTEMPT = "TEAM/editUserNameAttempt",
  EDIT_USER_NAME_SUCCESS = "TEAM/editUserNameSuccess",
  EDIT_USER_NAME_FAILURE = "TEAM/editUserNameFailure",

  EDIT_USER_TYPOLOGY_ATTEMPT = "TEAM/editUserTypologyAttempt",
  EDIT_USER_TYPOLOGY_SUCCESS = "TEAM/editUserTypologySuccess",
  EDIT_USER_TYPOLOGY_FAILURE = "TEAM/editUserTypologyFailure",

  GET_ACCESSIBLE_COMPANIES_ATTEMPT = "TEAM/getAccessibleCompaniesAttempt",
  GET_ACCESSIBLE_COMPANIES_SUCCESS = "TEAM/getAccessibleCompaniesSuccess",
  GET_ACCESSIBLE_COMPANIES_FAILURE = "TEAM/getAccessibleCompaniesFailure",

  GET_ALL_USERS_ATTEMPT = "TEAM/getAllUsersAttempt",
  GET_ALL_USERS_SUCCESS = "TEAM/getAllUsersSuccess",
  GET_ALL_USERS_FAILURE = "TEAM/getAllUsersFailure",

  GET_USER_PERMISSIONS_ATTEMPT = "TEAM/getUserPermissionAttempt",
  GET_USER_PERMISSIONS_SUCCESS = "TEAM/getUserPermissionSuccess",
  GET_USER_PERMISSIONS_FAILURE = "TEAM/getUserPermissionFailure",

  SET_NOTIFICATIONS_TIMEFRAME_ATTEMPT = "TEAM/setNotificationsTimeframeAttempt",
  SET_NOTIFICATIONS_TIMEFRAME_SUCCESS = "TEAM/setNotificationsTimeframeSuccess",
  SET_NOTIFICATIONS_TIMEFRAME_FAILURE = "TEAM/setNotificationsTimeframeFailure",

  GRANT_COMPANIES = "TEAM/grantCompanies",
  PUT_GRANT_ACCESS_ATTEMPT = "TEAM/putGrantAccessAttempt",
  PUT_GRANT_ACCESS_SUCCESS = "TEAM/putGrantAccessSuccess",
  PUT_GRANT_ACCESS_FAILURE = "TEAM/putGrantAccessFailure",

  REVOKE_COMPANY = "TEAM/revokeCompany",
  PUT_REVOKE_ACCESS_ATTEMPT = "TEAM/putRevokeAccessAttempt",
  PUT_REVOKE_ACCESS_SUCCESS = "TEAM/putRevokeAccessSuccess",
  PUT_REVOKE_ACCESS_FAILURE = "TEAM/putRevokeAccessFailure",

  DEACTIVATE_USER_RESET = "TEAM/deactivateUserReset",
  DEACTIVATE_USER_ATTEMPT = "TEAM/deactivateUserAttempt",
  DEACTIVATE_USER_SUCCESS = "TEAM/deactivateUserSuccess",
  DEACTIVATE_USER_TOASTER_SUCCESS = "TEAM/deactivateUserToasterSuccess",
  DEACTIVATE_USER_FAILURE = "TEAM/deactivateUserFailure",
}

export const selectUserToEditAction = (payload: number) =>
  ({ type: TeamActionsEnum.SELECT_USER_TO_EDIT, payload } as const)
export const editUserNameAttemptAction = () =>
  ({ type: TeamActionsEnum.EDIT_USER_NAME_ATTEMPT } as const)
export const editUserNameSuccessAction = (payload: Partial<TeamMember>) =>
  ({
    type: TeamActionsEnum.EDIT_USER_NAME_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "edit-user.edit-name.success.title",
      bodyKey: "edit-user.edit-name.success.body",
    },
    payload,
  } as const)
export const editUserNameFailureAction = () =>
  ({
    type: TeamActionsEnum.EDIT_USER_NAME_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "edit-user.update-infos-failure.title",
      bodyKey: "edit-user.update-infos-failure.body",
    },
  } as const)
export const editUserResetAction = () =>
  ({ type: TeamActionsEnum.EDIT_USER_RESET } as const)

export const editUserTypologyAttemptAction = () =>
  ({ type: TeamActionsEnum.EDIT_USER_TYPOLOGY_ATTEMPT } as const)

export const editUserTypologySuccessAction = (payload: UserTypes) =>
  ({
    type: TeamActionsEnum.EDIT_USER_TYPOLOGY_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "edit-user.edit-typoplogy.success.title",
      bodyKey: "edit-user.edit-typoplogy.success.body",
    },
    payload,
  } as const)
export const editUserTypologyFailureAction = () =>
  ({
    type: TeamActionsEnum.EDIT_USER_TYPOLOGY_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "edit-user.edit-typoplogy.failure.title",
      bodyKey: "edit-user.edit-typoplogy.failure.body",
    },
  } as const)

export const getAccessibleCompaniesAttemptAction = () =>
  ({ type: TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_ATTEMPT } as const)
export const getAccessibleCompaniesSuccessAction = (
  payload: {
    id: number
    name: string
    hidden_from_team: boolean
  }[]
) =>
  ({ type: TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_SUCCESS, payload } as const)
export const getAccessibleCompaniesFailureAction = () =>
  ({ type: TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_FAILURE } as const)

export const getAllUsersAttemptAction = () =>
  ({ type: TeamActionsEnum.GET_ALL_USERS_ATTEMPT } as const)
export const getAllUsersSuccessAction = (payload: UsersTeamResponse) =>
  ({ type: TeamActionsEnum.GET_ALL_USERS_SUCCESS, payload } as const)
export const getAllUsersFailureAction = (error: AxiosError) =>
  ({ type: TeamActionsEnum.GET_ALL_USERS_FAILURE, error } as const)
export const getUserPermissionAttemptAction = () =>
  ({ type: TeamActionsEnum.GET_USER_PERMISSIONS_ATTEMPT } as const)
export const getUserPermissionSuccessAction = (payload: UserPermissions) =>
  ({
    type: TeamActionsEnum.GET_USER_PERMISSIONS_SUCCESS,
    payload,
  } as const)
export const getUserPermissionFailureAction = (error: AxiosError) =>
  ({ type: TeamActionsEnum.GET_USER_PERMISSIONS_FAILURE, error } as const)
export const setNotificationsTimeframeAttemptAction = () =>
  ({ type: TeamActionsEnum.SET_NOTIFICATIONS_TIMEFRAME_ATTEMPT } as const)
export const setNotificationsTimeframeSuccessAction = () =>
  ({
    type: TeamActionsEnum.SET_NOTIFICATIONS_TIMEFRAME_SUCCESS,
    withToast: true,
    message: {
      titleKey: "edit-user.notification-title",
      bodyKey: "edit-user.notification-message",
    },
  } as const)
export const setNotificationsTimeframeFailureAction = (error: AxiosError) =>
  ({
    type: TeamActionsEnum.SET_NOTIFICATIONS_TIMEFRAME_FAILURE,
    error,
  } as const)

export const grantCompanies = (
  payload: {
    id: number
    name: string
  }[]
) => ({ type: TeamActionsEnum.GRANT_COMPANIES, payload } as const)
export const putGrantAccessAttemptAction = () =>
  ({ type: TeamActionsEnum.PUT_GRANT_ACCESS_ATTEMPT } as const)

export const putGrantAccessSuccessAction = (
  payload: {
    id: number
    name: string
  }[]
) =>
  ({
    type: TeamActionsEnum.PUT_GRANT_ACCESS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "edit-user.grant-access.success.title",
      bodyKey: "edit-user.grant-access.success.body",
    },
    payload,
  } as const)
export const putGrantAccessFailureAction = (error: AxiosError) =>
  ({ type: TeamActionsEnum.PUT_GRANT_ACCESS_FAILURE, error } as const)

export const revokeCompany = (payload: Company) =>
  ({ type: TeamActionsEnum.REVOKE_COMPANY, payload } as const)
export const putRevokeAccessAttemptAction = () =>
  ({ type: TeamActionsEnum.PUT_REVOKE_ACCESS_ATTEMPT } as const)
export const putRevokeAccessSuccessAction = (payload: Company[]) =>
  ({
    type: TeamActionsEnum.PUT_REVOKE_ACCESS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "edit-user.revoke-access.success.title",
      bodyKey: "edit-user.revoke-access.success.body",
    },
    payload,
  } as const)
export const putRevokeAccessFailureAction = (error: AxiosError) =>
  ({ type: TeamActionsEnum.PUT_REVOKE_ACCESS_FAILURE, error } as const)

export const deactivateUserAttemptAction = () =>
  ({ type: TeamActionsEnum.DEACTIVATE_USER_ATTEMPT } as const)

const toasterDeactivatedEmail: (user: TeamMember) => ToasterMessageAction = (
  user: TeamMember
) =>
  ({
    type: TeamActionsEnum.DEACTIVATE_USER_TOASTER_SUCCESS,
    withToast: true,
    message: {
      titleKey: "edit-user.deactivation-title",
      bodyKey: "edit-user.deactivation-body",
      bodyValues: {
        firstName: user.first_name || "",
        lastName: user.last_name || "",
      },
    },
  } as const)
export const deactivateUserToasterSuccessAction = (user: TeamMember) =>
  toasterDeactivatedEmail(user)

export const deactivateUserSuccessAction = (payload: number) =>
  ({ type: TeamActionsEnum.DEACTIVATE_USER_SUCCESS, payload } as const)
export const deactivateUserFailureAction = (user: TeamMember) =>
  ({
    type: TeamActionsEnum.DEACTIVATE_USER_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "edit-user.deactivation-failure.title",
      bodyKey: "edit-user.deactivation-failure.body",
      bodyValues: { firstName: user.first_name, lastName: user.last_name },
    },
    user,
  } as const)
export const deactivateUserResetAction = () =>
  ({ type: TeamActionsEnum.DEACTIVATE_USER_RESET } as const)

type TeamActionsType = ReturnType<
  | typeof selectUserToEditAction
  | typeof editUserNameAttemptAction
  | typeof editUserNameSuccessAction
  | typeof editUserNameFailureAction
  | typeof editUserResetAction
  | typeof editUserTypologyAttemptAction
  | typeof editUserTypologySuccessAction
  | typeof editUserTypologyFailureAction
  | typeof getAllUsersAttemptAction
  | typeof getAllUsersSuccessAction
  | typeof getAllUsersFailureAction
  | typeof getUserPermissionAttemptAction
  | typeof getUserPermissionSuccessAction
  | typeof getUserPermissionFailureAction
  | typeof setNotificationsTimeframeAttemptAction
  | typeof setNotificationsTimeframeSuccessAction
  | typeof setNotificationsTimeframeFailureAction
  | typeof getAccessibleCompaniesAttemptAction
  | typeof getAccessibleCompaniesSuccessAction
  | typeof getAccessibleCompaniesFailureAction
  | typeof grantCompanies
  | typeof putGrantAccessAttemptAction
  | typeof putGrantAccessSuccessAction
  | typeof putGrantAccessFailureAction
  | typeof revokeCompany
  | typeof putRevokeAccessAttemptAction
  | typeof putRevokeAccessSuccessAction
  | typeof putRevokeAccessFailureAction
  | typeof deactivateUserResetAction
  | typeof deactivateUserAttemptAction
  | typeof deactivateUserSuccessAction
  | typeof deactivateUserFailureAction
>

export const teamInitialState: TeamState = {
  editedUserId: null,
  editNameStatus: "IDLE",
  editGrantAccessStatus: "IDLE",
  editTypologyStatus: "IDLE",
  grantAccessStatus: "IDLE",
  revokeAccessStatus: "IDLE",
  getCompaniesStatus: "IDLE",
  deactivateUserStatus: "IDLE",
  teamStatus: "IDLE",
  team: {},
  accessibleCompanies: [],
  grantedCompanies: [],
  revokedCompanies: [],
  userPermissions: null,
}

export interface TeamStateEditRequestsStatuses {
  editNameStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  editTypologyStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  grantAccessStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  revokeAccessStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
}
export interface TeamState extends TeamStateEditRequestsStatuses {
  editedUserId: number | null
  editGrantAccessStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  deactivateUserStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  getCompaniesStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  teamStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  team: { [id: string]: TeamMember }
  accessibleCompanies: {
    id: number
    name: string
  }[]
  grantedCompanies: {
    id: number
    name: string
  }[]
  revokedCompanies: Company[]
  userPermissions: UserPermissions | null
}

export function teamReducer(
  state = teamInitialState,
  action: TeamActionsType
): TeamState {
  switch (action.type) {
    case TeamActionsEnum.SELECT_USER_TO_EDIT:
      return { ...state, editedUserId: action.payload }
    case TeamActionsEnum.EDIT_USER_NAME_ATTEMPT:
      return { ...state, editNameStatus: "LOADING" }
    case TeamActionsEnum.EDIT_USER_NAME_SUCCESS: {
      const updatedTeam = { ...state.team }
      if (state.editedUserId) {
        updatedTeam[state.editedUserId] = {
          ...updatedTeam[state.editedUserId],
          ...action.payload,
        }
      }

      return { ...state, editNameStatus: "SUCCESS", team: updatedTeam }
    }
    case TeamActionsEnum.EDIT_USER_NAME_FAILURE:
      return { ...state, editNameStatus: "ERROR" }

    case TeamActionsEnum.EDIT_USER_RESET:
      return {
        ...state,
        editNameStatus: "IDLE",
        editTypologyStatus: "IDLE",
        editGrantAccessStatus: "IDLE",
        grantedCompanies: [],
        revokedCompanies: [],
      }

    case TeamActionsEnum.GET_ALL_USERS_ATTEMPT:
      return { ...state, teamStatus: "LOADING" }

    case TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_ATTEMPT:
      return { ...state, getCompaniesStatus: "LOADING" }
    case TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_SUCCESS:
      return {
        ...state,
        getCompaniesStatus: "SUCCESS",
        accessibleCompanies: action.payload,
      }
    case TeamActionsEnum.GET_ACCESSIBLE_COMPANIES_FAILURE:
      return { ...state, getCompaniesStatus: "ERROR" }

    case TeamActionsEnum.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        teamStatus: "SUCCESS",

        team: action.payload.reduce(
          (team, member) => ({ ...team, [member.id]: member }),
          {}
        ),
      }
    case TeamActionsEnum.GET_ALL_USERS_FAILURE:
      return { ...state, team: {}, teamStatus: "ERROR" }

    case TeamActionsEnum.GRANT_COMPANIES:
      return {
        ...state,
        grantedCompanies: [...state.grantedCompanies, ...action.payload],
      }

    case TeamActionsEnum.PUT_GRANT_ACCESS_ATTEMPT:
      return {
        ...state,
        grantAccessStatus: "LOADING",
      }

    case TeamActionsEnum.PUT_GRANT_ACCESS_FAILURE:
      return {
        ...state,
        grantAccessStatus: "ERROR",
        editGrantAccessStatus: "ERROR",
      }

    case TeamActionsEnum.PUT_GRANT_ACCESS_SUCCESS:
      return {
        ...state,
        grantAccessStatus: "SUCCESS",
        accessibleCompanies: [...state.accessibleCompanies, ...action.payload],
        grantedCompanies: [],
        editGrantAccessStatus: "SUCCESS",
      }

    case TeamActionsEnum.REVOKE_COMPANY:
      return {
        ...state,
        revokedCompanies: [...state.revokedCompanies, action.payload],
        grantedCompanies: state.grantedCompanies.filter(
          (c) => c.id !== action.payload.id
        ),
      }

    case TeamActionsEnum.PUT_REVOKE_ACCESS_ATTEMPT:
      return {
        ...state,
        revokeAccessStatus: "LOADING",
      }

    case TeamActionsEnum.PUT_REVOKE_ACCESS_FAILURE:
      return {
        ...state,
        revokeAccessStatus: "ERROR",
      }

    case TeamActionsEnum.PUT_REVOKE_ACCESS_SUCCESS:
      return {
        ...state,
        revokeAccessStatus: "SUCCESS",
        accessibleCompanies: [
          ...state.accessibleCompanies.filter(
            (accessibleCompany) =>
              !action.payload.find(
                (removedCompany) => removedCompany.id === accessibleCompany.id
              )
          ),
        ],
        revokedCompanies: [],
      }

    case TeamActionsEnum.DEACTIVATE_USER_RESET:
      return { ...state, deactivateUserStatus: "IDLE" }

    case TeamActionsEnum.DEACTIVATE_USER_ATTEMPT:
      return { ...state, deactivateUserStatus: "LOADING" }

    case TeamActionsEnum.DEACTIVATE_USER_FAILURE:
      return { ...state, deactivateUserStatus: "ERROR" }

    case TeamActionsEnum.DEACTIVATE_USER_SUCCESS: {
      const teamWithoutDeactivatedUser = Object.entries(state.team).filter(
        ([key, _]) => Number(key) !== action.payload
      )
      return {
        ...state,
        team: Object.fromEntries(teamWithoutDeactivatedUser),
        deactivateUserStatus: "SUCCESS",
      }
    }

    case TeamActionsEnum.GET_USER_PERMISSIONS_SUCCESS:
      return { ...state, userPermissions: action.payload }

    case TeamActionsEnum.EDIT_USER_TYPOLOGY_ATTEMPT:
      return { ...state, editTypologyStatus: "LOADING" }

    case TeamActionsEnum.EDIT_USER_TYPOLOGY_FAILURE:
      return { ...state, editTypologyStatus: "ERROR" }

    case TeamActionsEnum.EDIT_USER_TYPOLOGY_SUCCESS:
      if (!state.editedUserId) {
        return { ...state }
      }
      return {
        ...state,
        editTypologyStatus: "SUCCESS",
        team: {
          ...state.team,
          [state.editedUserId]: {
            ...state.team[state.editedUserId],
            typology: action.payload,
          },
        },
      }

    default:
      return { ...state }
  }
}

export const attemptToLoadUsersThunk =
  () => (dispatch: Dispatch<TeamActionsType>) => {
    dispatch(getAllUsersAttemptAction())
    const params = {
      typologies: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
        "customer",
      ],
    }
    return axios
      .get<UsersTeamResponse>(`/users/users_of_fiduciary`, { params })
      .then((response) => {
        return dispatch(getAllUsersSuccessAction([...response.data]))
      })
      .catch((e: AxiosError) => dispatch(getAllUsersFailureAction(e)))
  }

export interface ClientRegisterPayload {
  firstName: string
  lastName: string
  email: string
  userTypology: string
  fiduciaryId: number
}

interface EditUserThunkPayload {
  email: string
  firstName: string
  lastName: string
  isSelfEditing?: boolean
  currentRole?: string
}

export const editUserThunk =
  ({
    email,
    firstName,
    lastName,
    isSelfEditing,
    currentRole,
  }: EditUserThunkPayload) =>
  (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(editUserNameAttemptAction())

    const editedUserId = getState().team.editedUserId

    if (!editedUserId) {
      dispatch(editUserNameFailureAction())
      return
    }

    const editedUser = {
      id: editedUserId,
      email,
      first_name: firstName,
      last_name: lastName,
    }

    const axiosCallroutes =
      isSelfEditing && currentRole === "collaborator"
        ? [axios.put(`/users/edit_user_name_informations`, editedUser)]
        : [
            axios.put(`/users/edit_user_name_informations`, editedUser),
            axios.put(`/users/edit_email`, editedUser),
          ]
    return axios
      .all(axiosCallroutes)
      .then(() => {
        dispatch(editUserNameSuccessAction(editedUser))
      })
      .catch(() => {
        dispatch(editUserNameFailureAction())
      })
  }

export const editUserTypologyThunk =
  ({ role }: { role: UserTypes }) =>
  (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(editUserTypologyAttemptAction())

    const editedUserId = getState().team.editedUserId

    if (!editedUserId) {
      dispatch(editUserTypologyFailureAction())
      return
    }

    const editedUser = {
      id: editedUserId,
      user_typology: role,
    }

    return axios
      .put(`/users/edit_user_typology`, editedUser)
      .then(() => {
        dispatch(editUserTypologySuccessAction(role))
      })
      .catch(() => {
        dispatch(editUserTypologyFailureAction())
      })
  }

interface CompanyResponse {
  id: number
  company_name: string
  hidden_from_team: boolean
  accounting_software_reference: string
}
export const getAccessibleCompaniesThunk =
  () => (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(getAccessibleCompaniesAttemptAction())

    const editedUserId = getState().team.editedUserId

    const editedUser = {
      user_id: editedUserId,
    }

    return axios
      .get<CompanyResponse[]>(
        `/users/get_accessible_companies?user_id=${editedUserId}`,
        {
          params: editedUser,
        }
      )
      .then((res) => {
        const companies = res.data.map((responseCompany) => ({
          id: responseCompany.id,
          name: responseCompany.accounting_software_reference
            ? responseCompany.company_name +
              (" / " + responseCompany.accounting_software_reference)
            : responseCompany.company_name,
          hidden_from_team: responseCompany.hidden_from_team,
        }))
        dispatch(getAccessibleCompaniesSuccessAction(companies))
      })
      .catch(() => {
        dispatch(getAccessibleCompaniesFailureAction())
      })
  }

export const getUserPermissionThunk =
  () => (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(getUserPermissionAttemptAction())
    return axios
      .get<UserPermissions>(`/users/get_user_permissions_matrix`)
      .then((res) => {
        dispatch(getUserPermissionSuccessAction(res.data))
      })
      .catch((e: AxiosError) => {
        dispatch(getUserPermissionFailureAction(e))
      })
  }

export const setNotificationsTimeframeThunk =
  ({
    preferences,
    userId,
  }: {
    preferences: NotificationTimeframe
    userId: string
  }) =>
  (dispatch: Dispatch<TeamActionsType>) => {
    dispatch(setNotificationsTimeframeAttemptAction())
    return axios
      .put(`/users/edit_user_notifications_preferences`, {
        notifications_preferences: preferences,
        id: userId,
      })
      .then(() => {
        dispatch(setNotificationsTimeframeSuccessAction())
      })
      .catch((e) => {
        dispatch(setNotificationsTimeframeFailureAction(e))
      })
  }

export const putGrantAccessThunk =
  () => (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(putGrantAccessAttemptAction())

    const grantedCompanies = getState().team.grantedCompanies
    const grantedCompaniesIds = grantedCompanies.map((c) => c.id)
    const editedUserId = getState().team.editedUserId

    return axios
      .put(`/users/${editedUserId}/grant_access`, {
        company_ids: grantedCompaniesIds,
      })
      .then(() => {
        dispatch(putGrantAccessSuccessAction(grantedCompanies))
      })
      .catch((e) => {
        dispatch(putGrantAccessFailureAction(e))
      })
  }

export const putRevokeAccessThunk =
  () => (dispatch: Dispatch<TeamActionsType>, getState: () => RootState) => {
    dispatch(putRevokeAccessAttemptAction())

    const revokedCompanies = getState().team.revokedCompanies
    const revokedCompaniesIds = revokedCompanies.map((c) => c.id)
    const editedUserId = getState().team.editedUserId

    return axios
      .put(`/users/revoke_access`, {
        company_ids: revokedCompaniesIds,
        id: editedUserId,
      })
      .then(() => {
        dispatch(putRevokeAccessSuccessAction(revokedCompanies))
      })
      .catch((e) => {
        dispatch(putRevokeAccessFailureAction(e))
      })
  }

export const deactivateUserThunk =
  () =>
  (
    dispatch: Dispatch<TeamActionsType | AnyAction>,
    getState: () => RootState
  ) => {
    dispatch(deactivateUserAttemptAction())

    const deactivateUserId = getState().team.editedUserId
    if (deactivateUserId === null) {
      return
    }
    const deactivateUser = getState().team.team[deactivateUserId]

    return axios
      .put(`/users/deactivate_user`, {
        id: deactivateUserId,
      })
      .then(() => {
        dispatch(deactivateUserSuccessAction(deactivateUserId))
        dispatch(deactivateUserToasterSuccessAction(deactivateUser))
      })
      .catch((e) => {
        dispatch(deactivateUserFailureAction(deactivateUser))
      })
  }
