import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"

import { boxShadow, colors, spacings } from "../styles/design.config"
import { ReactComponent as Search } from "../assets/search.svg"
import * as Ct from "ldlj"
import { useRNBSelector } from "../store/rootReducer"
import { getUserCompaniesThunk } from "../store/ducks/companies.ducks"
import { getStandardLedgersThunk } from "../store/ducks/user.ducks"
import { selectCompany } from "../utils/company"
import { selectCompanyAction } from "../store/ducks/companySettings.ducks"
import { sortCompaniesByFavoriteAndName } from "../utils/fiduciary"
import { ReactComponent as HeartFilled } from "../assets/heart-filled.svg"
import { ReactComponent as HeartEmpty } from "../assets/heart-empty.svg"
import { croppedEndString } from "../utils/string"

export const CompanyPickerModal = (props: Ct.ModalComponentsProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserCompaniesThunk())
    dispatch(getStandardLedgersThunk())
  }, [dispatch])

  const companies = Object.values(
    useRNBSelector((state) => state.companies.companies)
  )

  const standardLedgers = useRNBSelector((state) => state.user.standardLedgers)
  const currentUserTypology = useRNBSelector((state) => state.user.typology)

  const standardLedgersCompanyIds = standardLedgers.map(
    (standardLedger) => standardLedger["companyId"]
  )
  const companiesForCustomer = companies.filter((element) =>
    standardLedgersCompanyIds.includes(element.id)
  )

  const [search, setSearch] = useState("")
  const companiesToDisplay =
    currentUserTypology === "customer"
      ? companiesForCustomer
      : sortCompaniesByFavoriteAndName(companies, true)

  return (
    <Ct.Modal {...props} top={"14rem"} left={"37rem"}>
      <AlignTopCard
        trianglePosition={{
          position: "left",
          size: 2.5,
          left: -1.25,
          right: 0,
          top: 12.5,
          bottom: 0,
        }}
        backgroundColor="mist"
        width={"119rem"}
        height={"93rem"}
      >
        <StyledSpacedBetween>
          <div>
            <StyledTitle>
              {intl.formatMessage({
                id: "menu.company-picker.title",
              })}
            </StyledTitle>
            <StyledSubtitle>
              {intl.formatMessage({
                id: "menu.company-picker.subtitle",
              })}
            </StyledSubtitle>
          </div>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            shadowed={true}
            noBorder={true}
          />
        </StyledSpacedBetween>
        <CompaniesList>
          {companiesToDisplay.length > 0 &&
            companiesToDisplay
              .filter(
                (company) =>
                  company.name?.toLowerCase().includes(search.toLowerCase()) ||
                  company.accounting_software_reference
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((company) => (
                <CompanyCard key={company.id}>
                  <Ct.AlignSelfStart>
                    <CompanyName id="companyName">
                      <Ct.Text
                        text={croppedEndString(
                          company.user_company_name
                            ? company.user_company_name
                            : company.name,
                          25
                        )}
                        textStyle={{
                          color: "navy",
                          fontSize: 1.75,
                          fontWeight: 600,
                          textTransform: "uppercase",
                        }}
                      />

                      {company.is_favorite ? (
                        <StyledHeartFilled />
                      ) : (
                        <StyledHeartEmpty />
                      )}
                    </CompanyName>

                    <Ct.Spacer height={0.5} />
                    <Ct.Text
                      text={company.accounting_software_reference || ""}
                      textStyle={{
                        color: "cornflower",
                        fontSize: 1.5,
                        fontWeight: 700,
                      }}
                    />

                    {currentUserTypology !== "customer" && (
                      <div>
                        <Ct.Text
                          text={intl.formatMessage({
                            id: `office-company-settings.accounting_type.${company.accounting_type}`,
                          })}
                          textStyle={{
                            color: "slateGrey",
                            fontSize: 1.5,
                            fontWeight: 500,
                          }}
                        />
                      </div>
                    )}

                    <CompanyPeople>
                      {company.collaborator_count} collaborateur
                      {company.collaborator_count &&
                      company.collaborator_count > 1
                        ? "s"
                        : ""}{" "}
                      /
                      <span /> {company.customer_count} client
                      {company.customer_count && company.customer_count > 1
                        ? "s"
                        : ""}
                    </CompanyPeople>
                  </Ct.AlignSelfStart>
                  <Ct.Button
                    label={intl.formatMessage({
                      id: "menu.company-picker.cta",
                    })}
                    width={15}
                    height={4.25}
                    onClick={() => {
                      dispatch(selectCompanyAction())

                      selectCompany(
                        company.id,
                        navigate,
                        location.pathname,
                        companies
                      )
                      props.onClose()
                    }}
                  />
                </CompanyCard>
              ))}
        </CompaniesList>
      </AlignTopCard>
    </Ct.Modal>
  )
}

const AlignTopCard = styled((props) => <Ct.Card {...props} />)`
  justify-content: flex-start;
  padding: 2rem;
`
const StyledSpacedBetween = styled((props) => <Ct.SpacedBetween {...props} />)`
  padding: 2rem 3rem;
  box-sizing: border-box;
`
const StyledTitle = styled.h2`
  text-align: left;
  color: ${colors.navy};
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
`
const StyledSubtitle = styled.h3`
  text-align: left;
  font-size: 1.75rem;
  font-weight: 400;

  color: ${colors.slateGrey};
`
const CompaniesList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  overflow-y: auto;
  padding: 2rem;
`
const CompanyCard = styled.section`
  background: ${colors.white};
  border-radius: 1.25rem;
  padding: ${spacings.defaultPadding};
  padding: 2rem;
  box-shadow: ${boxShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 23rem;
  min-width: 23rem;
  min-height: 20rem;
  box-sizing: border-box;
`
const CompanyName = styled.div`
  max-height: 4rem;
  overflow: hidden;
  line-height: 2rem;
`

const CompanyPeople = styled.h4`
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: ${colors.slateGrey};
  height: 3rem;
`
const StyledHeartFilled = styled(HeartFilled)`
  margin-left: 1rem;
  width: 1.75rem;
  height: 1.75rem;
`
const StyledHeartEmpty = styled(HeartEmpty)`
  margin-left: 1rem;
  width: 1.75rem;
  height: 1.75rem;
`
