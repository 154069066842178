import { Fragment } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { Spacer } from "ldlj"

import { colors } from "../../styles/design.config"
import { Document } from "../../utils/inputMask"
import { ReactComponent as ChevronSquareRight } from "../../assets/chevron-square-right.svg"
import { ReactComponent as Eye } from "../../assets/eye.svg"

interface FullDocumentPagesProps {
  documents: Document[]
  selectedPage: number
  onSelect: (page: number) => void
}

export const FullDocumentPages = ({
  documents,
  selectedPage,
  onSelect,
}: FullDocumentPagesProps) => {
  const intl = useIntl()
  const totalWidth = documents.length > 1 ? "20rem" : "0"
  const opacity = documents.length > 1 ? "1" : "0"

  return (
    <InvoicePages totalWidth={totalWidth} opacity={opacity}>
      <Spacer />
      {intl.formatMessage({ id: "input-mask.invoices.pages" })}
      <Spacer />
      <ChevronUp
        isFirst={selectedPage === 0}
        onClick={() => {
          if (selectedPage > 0) {
            onSelect(selectedPage - 1)
          }
        }}
      />
      <Spacer />
      <ScrollableZone>
        {documents.map((d, index) => (
          <Fragment key={d.id}>
            <Background>
              <MiniImg
                isHighlighted={selectedPage === index}
                id={String(d.id)}
                src={`/documents/show_image?id=${d.id}`}
                onClick={() => {
                  onSelect(index)
                }}
                isSelected={selectedPage === index}
              />
              <StyledEye isSelected={selectedPage === index} size={5} />
            </Background>
            <Spacer />
          </Fragment>
        ))}
      </ScrollableZone>
      <Spacer />
      <ChevronDown
        isLast={selectedPage === documents.length - 1}
        onClick={() => {
          if (selectedPage < documents.length - 1) {
            onSelect(selectedPage + 1)
          }
        }}
      />
    </InvoicePages>
  )
}

const InvoicePages = styled.div<{ totalWidth: string; opacity: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ totalWidth }) => totalWidth};
  opacity: ${({ opacity }) => opacity};
  transition: ${({ opacity }) =>
    opacity === "0"
      ? `0.3s width ease-in-out 0.3s, 0.3s opacity ease-out;`
      : `0.3s width ease-in-out, 0.3s opacity ease-out 0.3s;`};
  height: 100%;
`

const ChevronUp = styled(ChevronSquareRight)<{ isFirst: boolean }>`
  transform: rotate(-0.25turn);
  width: 3rem;
  min-height: 3rem;
  cursor: pointer;
  transition: fill 0.5s ease-in-out;
  fill: ${({ isFirst }) => (isFirst ? colors.grey : colors.cornflower)};
`

const ChevronDown = styled(ChevronSquareRight)<{ isLast: boolean }>`
  transform: rotate(0.25turn);
  width: 3rem;
  min-height: 3rem;
  cursor: pointer;
  transition: fill 0.5s ease-in-out;
  fill: ${({ isLast }) => (isLast ? colors.grey : colors.cornflower)};
`

const ScrollableZone = styled.div`
  overflow-y: scroll;
  padding: 0 2rem;
`

const MiniImg = styled.img<{ isSelected: boolean; isHighlighted: boolean }>`
  width: 10rem;
  height: 15rem;
  border: ${(props) =>
    props.isSelected
      ? `2px solid ${colors.cornflower}`
      : `2px solid ${colors.lavender}`};
  transition: 0.5s border ease-in-out, 0.5s opacity ease-in-out;
  opacity: ${({ isHighlighted }) => (isHighlighted ? `0.7` : `1`)};
  box-sizing: border-box;
  cursor: pointer;
`
const Background = styled.div`
  position: relative;
  width: 10rem;
  height: 15rem;
  border: none;
  background-color: ${colors.cornflower};
`

const StyledEye = styled(Eye)<{ isSelected: boolean; size: 5 | 10 }>`
  width: ${(props) => `${props.size}rem`};
  height: ${(props) => `${props.size * (55 / 80)}rem`};
  position: absolute;
  left: ${(props) => `calc(50% - ${props.size / 2}rem)`};
  top: ${(props) => `calc(50% - ${(props.size / 2) * (55 / 80)}rem)`};
  opacity: ${(props) => (props.isSelected ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
`
