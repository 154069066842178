import { IntlShape } from "react-intl"
import {
  AccountRules,
  AccountRulesWithoutDocument,
  AccountToCreateRule,
  AccountsResource,
  CompareAmountType,
  DataToCreateRule,
  Transaction,
} from "../store/ducks/bank.ducks"
import { newOperationsWithoutArchive } from "../store/ducks/bankArchives.ducks"
import { sortByDate } from "./filesList"
import { capitalizeFirstLetter } from "./string"
import { FullDocumentToAttach } from "../store/ducks/fullDocuments.ducks"
import { DateTime } from "luxon"
import { emptyOptionValue } from "../components/Commons/Select"

export const accountIsSet = (account: AccountsResource | null | undefined) =>
  account === null || account === undefined
    ? false
    : !!account.bankin_suspense_account &&
      !!account.bankin_financial_account &&
      !!account.bankin_journal_code &&
      !!account.first_import_date

export const formattedTime = (date: string) => {
  if (!date) return ""
  const onlyDate = date.split("-")
  return onlyDate[2].split("T")[0] + "/" + onlyDate[1] + "/" + onlyDate[0]
}

export interface RowPayload extends Partial<newOperationsWithoutArchive> {
  created_at?: string
  downloadUserId?: number
  downloadedAt?: string
  archiveId?: number
  downloadFirstName?: string
  downloadLastName?: string
  count_bankin_transaction?: number[]
  generated_at?: string
}

export const formattedFiscalYearDate = (begin: string, end: string) => {
  const beginInputDate = new Date(begin)
  const endInputDate = new Date(end)
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long" }
  const formatter = new Intl.DateTimeFormat("fr-FR", options)
  const beginDate = formatter.format(beginInputDate)
  const endDate = formatter.format(endInputDate)
  return (
    capitalizeFirstLetter(beginDate) + " - " + capitalizeFirstLetter(endDate)
  )
}

export const sortBankArchiveById = (archives: RowPayload[], asc: boolean) => {
  let withoutArchive = archives.filter((a) => !a.archiveId)
  let withArchive = archives.filter((a) => a.archiveId)

  withArchive.sort((a, b) => {
    let aArchive = a.archiveId ? a.archiveId : 0
    let bArchive = b.archiveId ? b.archiveId : 0
    return asc ? aArchive - bArchive : bArchive - aArchive
  })

  return [...withoutArchive, ...withArchive]
}

export const sortArchiveByDate = (archives: RowPayload[], asc: boolean) => {
  let withoutArchive = archives.filter((a) => !a.archiveId)
  let withArchive = archives.filter((a) => a.archiveId)

  withArchive.sort((a: RowPayload, b: RowPayload) => {
    return asc
      ? sortByDate(a.created_at, b.created_at)
      : sortByDate(b.created_at, a.created_at)
  })

  return [...withoutArchive, ...withArchive]
}

export const sortByFiscalYear = (archives: RowPayload[], asc: boolean) => {
  let withoutArchive = archives.filter((a) => !a.archiveId)
  let withArchive = archives.filter((a) => a.archiveId)

  withoutArchive.sort((a: RowPayload, b: RowPayload) => {
    return asc
      ? sortByDate(a.begin_exercise, b.begin_exercise)
      : sortByDate(b.begin_exercise, a.begin_exercise)
  })

  withArchive.sort((a: RowPayload, b: RowPayload) => {
    return asc
      ? sortByDate(a.begin_exercise, b.begin_exercise)
      : sortByDate(b.begin_exercise, a.begin_exercise)
  })

  return [...withoutArchive, ...withArchive]
}

export const sortByDownloadUser = (archives: RowPayload[], asc: boolean) => {
  let withoutArchive = archives.filter((a) => !a.archiveId)
  let withArchive = archives.filter((a) => a.archiveId)

  withArchive.sort((a, b) => {
    let aArchive = a.downloadLastName ? a.downloadLastName : "zzzzzzzzzzz"
    let bArchive = b.downloadLastName ? b.downloadLastName : "zzzzzzzzzzz"
    return asc
      ? aArchive.localeCompare(bArchive)
      : bArchive.localeCompare(aArchive)
  })

  return [...withoutArchive, ...withArchive]
}

export const sortRulesByPriority = (rules: AccountRules[], asc: boolean) => {
  return rules.sort((a, b) => {
    return asc
      ? a.priority_number - b.priority_number
      : b.priority_number - a.priority_number
  })
}

export const displayRule = (rule: AccountRules) => {
  let display = ""

  if (rule.prefix === "401" || rule.prefix === "411") {
    if (rule.third_party) {
      display =
        rule.third_party + ", " + rule.company_prefix + rule.value_account
    } else {
      display = rule.company_prefix + rule.value_account
    }
  } else {
    if (rule.rate) {
      display = rule.value_account + ", +" + rule.rate + "% de TVA"
    } else {
      display = rule.value_account
    }
  }

  return display
}

export const getRuleEvents = (
  metadata: { [index: string]: string },
  data: DataToCreateRule,
  intl: IntlShape
) => {
  for (let i = 0; i < Object.keys(metadata).length; i++) {
    const metadataKey = Object.keys(metadata)[i]
    const metadataValue = Object.values(metadata)[i]

    let val = ""

    switch (metadataKey) {
      case "priority_number":
        val = metadataValue
        break
      case "bank_line_type":
        val =
          metadataValue === null
            ? "non définit"
            : metadataValue === "in"
            ? "entrée"
            : "sortie"
        break
      case "amount":
        val = metadataValue === null ? "non définit" : metadataValue + "€"
        break
      case "compare_amount":
        val =
          metadataValue === null
            ? "non définit"
            : (metadataValue as CompareAmountType) === "equal"
            ? "égal"
            : (metadataValue as CompareAmountType) === "greater"
            ? "supérieur"
            : "inférieur"
        break
      case "text_in_description":
        val = metadataValue === null ? "non définit" : metadataValue
        break
      case "account_id":
        const account = data.accounts.find(
          (a) => a.id === Number(metadataValue)
        )
        val = metadataValue === null ? "non définit" : String(account?.number)
        break
      case "merchant_code_id":
        const code = data.merchant_codes.find(
          (c) => c.id === Number(metadataValue)
        )
        val = metadataValue === null ? "non définit" : code?.code || ""
        break
      case "vat_rate_id":
        const rate = data.vat_rates.find((r) => r.id === Number(metadataValue))
        val = metadataValue === null ? "non définit" : rate?.rate + "%"
        break
      case "prefix":
        val = metadataValue
        break

      default:
        return ""
    }

    return intl.formatMessage(
      {
        id: `bank-management.rules.events.edit_rule.${metadataKey}`,
      },
      { value: val }
    )
  }
  return ""
}

export const getRuleEventType = (metadata: { [index: string]: string }) => {
  for (let i = 0; i < Object.keys(metadata).length; i++) {
    const metadataKey = Object.keys(metadata)[i]

    const triggers = [
      "priority_number",
      "bank_line_type",
      "amount",
      "compare_amount",
      "text_in_description",
    ]
    const assignment = [
      "account_id",
      "merchant_code_id",
      "vat_rate_id",
      "prefix",
    ]

    if (triggers.includes(metadataKey)) {
      return "Déclenchement modifié : "
    } else if (assignment.includes(metadataKey)) {
      return "Affectation modifiée : "
    }
  }
  return ""
}

export const searchRules = (search: string, rules: AccountRules[]) => {
  return rules.length === 0
    ? []
    : [
        ...rules.filter(
          (r) =>
            r.value_account.toLowerCase().includes(search.toLowerCase()) ||
            r.text_in_description?.toLowerCase().includes(search.toLowerCase())
        ),
      ]
}

export const sortTransactionsByDate = (
  transactions: Transaction[],
  asc: boolean
) => {
  return transactions.sort((a, b) =>
    asc ? sortByDate(a.date, b.date) : sortByDate(b.date, a.date)
  )
}

export const sortTransactionsByDescription = (
  transactions: Transaction[],
  asc: boolean
) => {
  return transactions.sort((a, b) =>
    asc
      ? a.description.localeCompare(b.description)
      : b.description.localeCompare(a.description)
  )
}

export const sortTransactionsByStatus = (
  transactions: Transaction[],
  asc: boolean
) => {
  return transactions.sort((a, b) => {
    const aa = a.lines.some((l) => l.assignment?.startsWith("471")) ? -1 : 0
    const bb = b.lines.some((l) => l.assignment?.startsWith("471")) ? -1 : 0

    return asc ? aa - bb : bb - aa
  })
}

export const sortTransactionsByDebit = (
  transactions: Transaction[],
  asc: boolean
) => {
  return transactions.sort((a, b) =>
    asc ? a.amount - b.amount : b.amount - a.amount
  )
}

export const sortTransactionsByDocumentAttached = (
  transactions: Transaction[],
  asc: boolean
) => {
  const sorted = transactions.sort((a, b) => {
    if (a.full_document_id !== null && b.full_document_id === null) {
      return -1
    } else if (a.full_document_id === null && b.full_document_id !== null) {
      return 1
    }

    if (a.batch_document_id !== null && b.batch_document_id === null) {
      return -1
    } else if (a.batch_document_id === null && b.batch_document_id !== null) {
      return 1
    }

    if (a.marked_without_document && !b.marked_without_document) {
      return -1
    } else if (!a.marked_without_document && b.marked_without_document) {
      return 1
    }

    return 0
  })

  return asc ? sorted : sorted.reverse()
}

export const searchTransactions = (
  search: string,
  transactions: Transaction[]
) => {
  return transactions.length === 0
    ? []
    : [
        ...transactions.filter((t) =>
          t.description.toLowerCase().includes(search.toLowerCase())
        ),
      ]
}

export const redirectionsAccount = (
  companyId: number,
  bankId: number,
  account: AccountsResource
) => {
  if (accountIsSet(account)) {
    return `/office/company/${companyId}/bank/accounts/bank/${bankId}/${account.id}/transactions`
  } else {
    return `/office/company/${companyId}/bank/accounts/bank/${bankId}/${account.id}/settings`
  }
}

export const sortFullDocsByUserFileName = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) =>
    asc
      ? a.user_file_name.localeCompare(b.user_file_name)
      : b.user_file_name.localeCompare(a.user_file_name)
  )
}

export const sortFullDocsByOriginalFileName = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) =>
    asc
      ? a.original_file_name.localeCompare(b.original_file_name)
      : b.original_file_name.localeCompare(a.original_file_name)
  )
}

export const sortFullDocsByType = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) => {
    const aFullDoc = a.document_type
      ? (a.document_type === "invoice" ? "Facture" : "Avoir") +
        " " +
        (a.buy_or_sell === "buy" ? "achat" : "vente")
      : "zzzzzz"
    const bFullDoc = b.document_type
      ? (b.document_type === "invoice" ? "Facture" : "Avoir") +
        " " +
        (b.buy_or_sell === "buy" ? "achat" : "vente")
      : "zzzzzz"

    return asc
      ? aFullDoc.localeCompare(bFullDoc)
      : bFullDoc.localeCompare(aFullDoc)
  })
}

export const sortFullDocsByMerchantName = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) => {
    const aFullDoc = a.merchant_name ? a.merchant_name : "zzzzzz"
    const bFullDoc = b.merchant_name ? b.merchant_name : "zzzzzz"

    return asc
      ? aFullDoc.localeCompare(bFullDoc)
      : bFullDoc.localeCompare(aFullDoc)
  })
}

export const sortFullDocsByDate = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) =>
    asc ? sortByDate(a.date, b.date) : sortByDate(b.date, a.date)
  )
}

export const sortFullDocsByAmount = (
  fullDocs: FullDocumentToAttach[],
  asc: boolean
) => {
  return fullDocs.sort((a, b) => {
    const aFullDoc = a.amount || 0
    const bFullDoc = b.amount || 0

    return asc ? aFullDoc - bFullDoc : bFullDoc - aFullDoc
  })
}

export const FilterFullDocsToAttach = (
  fullDocs: FullDocumentToAttach[],
  search: string
) => {
  if (fullDocs.length === 0) return []

  const filtered = fullDocs.filter((fd) => {
    const type = fd.document_type
      ? (fd.document_type === "invoice" ? "Facture" : "Avoir") +
        " " +
        (fd.buy_or_sell === "buy" ? "achat" : "vente")
      : "zzzzzz"

    return (
      fd.user_file_name.toLowerCase().includes(search.toLowerCase()) ||
      fd.original_file_name.toLowerCase().includes(search.toLowerCase()) ||
      fd.user_file_name.toLowerCase().includes(search.toLowerCase()) ||
      fd.merchant_name?.toLowerCase().includes(search.toLowerCase()) ||
      type.toLowerCase().includes(search.toLowerCase()) ||
      String(fd.amount).toLowerCase().includes(search.toLowerCase()) ||
      DateTime.fromJSDate(new Date(fd.date))
        .toFormat("dd/MM/yyyy")
        .includes(search)
    )
  })

  return filtered
}

export const fullDocNotSelectable = (
  fullDoc: FullDocumentToAttach,
  type: "positive" | "negative"
) => {
  if (fullDoc.document_type === "invoice") {
    if (fullDoc.buy_or_sell === "buy" && type === "positive") {
      return {
        documentType: "invoice-buy",
        impossible: true,
      }
    }
    if (fullDoc.buy_or_sell === "sell" && type === "negative") {
      return {
        documentType: "invoice-sell",
        impossible: true,
      }
    }
  }

  if (fullDoc.document_type === "credit_note") {
    if (fullDoc.buy_or_sell === "buy" && type === "negative") {
      return {
        documentType: "credit-note-buy",
        impossible: true,
      }
    }
    if (fullDoc.buy_or_sell === "sell" && type === "positive") {
      return {
        documentType: "credit-note-sell",
        impossible: true,
      }
    }
  }

  if (fullDoc.document_type === "bill_of_exchange") {
    if (fullDoc.buy_or_sell === "buy" && type === "positive") {
      return {
        documentType: "bill_of_exchange-sell",
        impossible: true,
      }
    }
  }

  return {
    documentType: "",
    impossible: false,
  }
}

export const prefixValues = [
  "1",
  "2",
  "3",
  "4",
  "401",
  "411",
  "5",
  "6",
  "7",
  "8",
  "9",
] as const

export type PrefixAssignment = typeof prefixValues

export const filterAccountsForAssignment = (
  accounts: AccountToCreateRule[],
  prefix: string
) => {
  const filteredAccounts = accounts
    .filter((a) => a.number.startsWith(prefix))
    .map((a) => {
      return { value: String(a.id), label: a.number + " " + a.details }
    })

  return [emptyOptionValue, ...filteredAccounts]
}

export const isAccountRuleWithoutDocument = (
  ruleToEdit: AccountRules | AccountRulesWithoutDocument | null
): ruleToEdit is AccountRulesWithoutDocument => {
  if (ruleToEdit === undefined || ruleToEdit === null) return false
  return (ruleToEdit as AccountRulesWithoutDocument).rule_without_document
}
