import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"

import { useHover } from "../../hooks/useHover"
import { formatEntryDatumType } from "../../utils/currencyUtils"
import {
  VatRate,
  WritingLinePayload,
  isMagicCheckBoxDatumType,
} from "../../utils/inputMask"
import { Text } from "../Commons/Text"
import { AmountCell } from "./AmountInput"
import { AccountSelectCell } from "./AccountSelectCell"
import { MagicCheckbox } from "./MagicCheckbox"
import { colors } from "../../styles/design.config"
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg"
import {
  destroyLineAction,
  toggleIsFuelVatAction,
} from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { ReactComponent as Fuel } from "../../assets/fuel.svg"
import { Switch } from "../Commons/Switch"

interface GroupOfLinesProps {
  group: WritingLinePayload[]
  isLast: boolean
}

export const GroupOfLines = ({ group, isLast }: GroupOfLinesProps) => {
  const intl = useIntl()
  const [hoverRef, isHoveredForDeletion] = useHover<HTMLDivElement>()
  const [hoverForFuelRef, isHoveredForFuel] = useHover<HTMLDivElement>()
  const dispatch = useDispatch()
  const { magicCheckbox, fullDocument } = useRNBSelector((state) => ({
    magicCheckbox: state.inputMask.magic_checkbox,
    fullDocument: state.inputMask.initial_full_document,
  }))

  const isFuelVatLine = group[0].is_fuel_vat_line

  return (
    <div>
      {group.map((l, index) => {
        const isMagicCheckbox =
          isMagicCheckBoxDatumType(l.datum_type) &&
          l.writing_line_uuid === magicCheckbox[l.datum_type]?.writing_line_uuid
        const datumTypeText = formatEntryDatumType({
          entryType: l.datum_type,
          vatRate: Number(l.vat_rate) as VatRate,
          intl,
        })
        return (
          <ContentLine
            key={`${l.writing_line_uuid}-${l.datum_type}`}
            isLast={isLast}
            isMagicCheckbox={isMagicCheckbox}
          >
            <Cell
              isHoveredForDeletion={isHoveredForDeletion}
              isHoveredForFuel={isHoveredForFuel}
              borderBottomLeftRadius={isLast}
              maxWidth="24rem"
            >
              <Text
                text={datumTypeText}
                textStyle={{
                  fontWeight: 700,
                  fontSize: datumTypeText.length > 12 ? 1.5 : 1.75,
                  lineHeight: datumTypeText.length > 12 ? 1.5 : 1.75,
                  fontFamily: "Poppins",
                  textAlign: "right",
                  width: "100%",
                  position: "relative",
                }}
              />

              {index === 0 &&
                l.datum_type !== "tax_included" &&
                l.datum_type !== "tax_included_bill_of_exchange" && (
                  <div
                    ref={hoverRef}
                    onClick={() =>
                      dispatch(
                        destroyLineAction({
                          writingLineUuid: l.writing_line_uuid,
                        })
                      )
                    }
                  >
                    <StyledTrashIcon />
                  </div>
                )}

              {l.datum_type === "vat" && (
                <>
                  <ReactTooltip effect={"solid"} />
                  <SwitchWrapper
                    ref={hoverForFuelRef}
                    data-tip={intl.formatMessage({
                      id: `input-mask.fuel.tooltip`,
                    })}
                  >
                    <Switch
                      value={isFuelVatLine}
                      onToggle={() => {
                        dispatch(
                          toggleIsFuelVatAction({
                            writingLineUuid: l.writing_line_uuid,
                          })
                        )
                      }}
                      icon={<StyledFuel />}
                      size="small"
                    />
                  </SwitchWrapper>
                </>
              )}
            </Cell>
            <AccountCell
              isHoveredForDeletion={false}
              isHoveredForFuel={false}
              padding="0"
            >
              <AccountSelectCell
                isHoveredForDeletion={isHoveredForDeletion}
                isHoveredForFuel={isHoveredForFuel}
                writingLine={l}
              />
              <MagicCheckbox
                isDisplayed={isMagicCheckbox && !!fullDocument}
                fullDocument={fullDocument}
                accountId={l.account?.id}
                datumType={l.datum_type}
              />
            </AccountCell>
            <Cell
              isHoveredForDeletion={false}
              isHoveredForFuel={false}
              padding={"0"}
              maxWidth={"30rem"}
            >
              <AmountCell
                index={index}
                isHoveredForDeletion={isHoveredForDeletion}
                isHoveredForFuel={isHoveredForFuel}
                direction="debit"
                writingLine={l}
              />
            </Cell>
            <Cell
              hideBorderRight={true}
              borderBottomRightRadius={isLast}
              isHoveredForDeletion={false}
              isHoveredForFuel={isHoveredForFuel}
              padding={"0"}
              maxWidth={"30rem"}
            >
              <AmountCell
                index={index}
                isHoveredForDeletion={isHoveredForDeletion}
                isHoveredForFuel={isHoveredForFuel}
                direction="credit"
                writingLine={l}
              />
            </Cell>
          </ContentLine>
        )
      })}
    </div>
  )
}

const ContentLine = styled.div<{ isLast: boolean; isMagicCheckbox: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  min-height: ${({ isMagicCheckbox }) => (isMagicCheckbox ? "8rem" : "4rem")};

  transition: 0.5s height ease-in-out;
  border-bottom: ${({ isLast }) =>
    isLast ? "0" : `1px solid ${colors.grey};`};
`

const StyledTrashIcon = styled(TrashIcon)`
  fill: ${colors.rock};
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  cursor: pointer;
  transition: 0.5s fill ease-in-out;

  :hover {
    fill: ${colors.amaranth};
  }
`
const SwitchWrapper = styled.div`
  padding-left: 1rem;
`

const Cell = styled.div<{
  padding?: string
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
  hideBorderRight?: boolean
  borderBottomLeftRadius?: boolean
  borderBottomRightRadius?: boolean
  maxWidth?: string
}>`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  background-color: ${({ isHoveredForDeletion, isHoveredForFuel }) =>
    isHoveredForDeletion
      ? "rgba(252, 90, 90, 0.2)"
      : isHoveredForFuel
      ? "rgba(107, 146, 255, 0.2)"
      : colors.white};

  transition: 0.5s background-color ease-in-out;

  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  padding: ${({ padding }) => (padding ? padding : "0 1rem")};
  border-right: ${({ hideBorderRight }) =>
    hideBorderRight ? "none" : `1px solid ${colors.grey}`};

  border-bottom-left-radius: ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius ? "1rem" : "0"};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) =>
    borderBottomRightRadius ? "1rem" : "0"};
  min-width: 0;
  box-sizing: border-box;
  user-select: none;
`

const AccountCell = styled(Cell)`
  display: flex;
  flex-direction: column;
`

const StyledFuel = styled(Fuel)`
  width: 1.25rem;
  height: 1.25rem;
`
