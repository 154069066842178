/* eslint-disable camelcase */
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import { getIdFromParams } from "../../../../utils/company"
import { Button } from "../../../../components/Commons/Button"
import { Text } from "../../../../components/Commons/Text"
import { useRNBSelector } from "../../../../store/rootReducer"
import { getPennylaneTokenFromCodeThunk } from "../../../../store/ducks/accountingSoftware.ducks"

export const PennyLaneLoginButton = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const [query] = useSearchParams()
  const code = query.get("code")
  const { pennylaneCodeRegisterStatus, pennylaneTokenCreatedAt } =
    useRNBSelector((state) => ({
      pennylaneTokenCreatedAt: state.softwareRules.pennylaneTokenCreatedAt,
      pennylaneCodeRegisterStatus:
        state.accountingSoftware.pennylaneCodeRegisterStatus,
    }))

  useEffect(() => {
    if (pennylaneCodeRegisterStatus === "IDLE" && code && selectedCompanyId) {
      dispatch(
        getPennylaneTokenFromCodeThunk({
          code,
          companyId: selectedCompanyId,
          redirectUri: window.location.href.split("?")[0],
        })
      )
    }
  }, [pennylaneCodeRegisterStatus, code])

  const pennylaneLoginUrlPath = "https://app.pennylane.com/oauth/authorize"
  const params = {
    client_id: "FsFPGhXvSzUEUBwsjPbzcGBKrLKj_chLtxT3S7zuZkk",
    // redirect_uri does not work when there is a number
    redirect_uri: "https://app.chaintrust.io/rnb/office/fiduciary/companies",
    response_type: "code",
    scope: "ledger",
  }

  const pennylaneLoginUrl = `${pennylaneLoginUrlPath}?client_id=${params.client_id}&redirect_uri=${params.redirect_uri}&response_type=${params.response_type}&scope=${params.scope}`

  return pennylaneTokenCreatedAt ? (
    <Text
      text={intl.formatMessage(
        {
          id: "office-company-change-software.pennylane.connected-at",
        },
        {
          pennylaneTokenCreatedAt: pennylaneTokenCreatedAt,
        }
      )}
    />
  ) : (
    <Button
      width={32}
      label={intl.formatMessage({
        id: "office-company-change-software.pennylane.login",
      })}
      onClick={() => {
        localStorage.setItem(
          "pennylaneCompanyId",
          JSON.stringify(selectedCompanyId)
        )

        window.location.href = pennylaneLoginUrl
      }}
    />
  )
}
