export function generateEaseInOut(maxHeight: number) {
  // from https://easings.net/#easeInOutElastic

  return `
        0% {
	  height: 1rem;
	}
        4% {
	  height: ${1 - 0.008 * maxHeight}rem;
	}

	8% {
          height: ${1 - 0.008 * maxHeight}rem;
	}

	18% {
          height: ${1 - 0.008 * maxHeight}rem;
	}

	20% {
          height: ${1 - 0.008 * maxHeight}rem;
	}

	28% {
          height: ${1 - 0.008 * maxHeight}rem;
	}
	30% {
          height: 1rem;   
	}
	38% {
          height: ${1 + 0.0927 * maxHeight}rem;
	} 
	60% {
          height: ${1 + 1.1175 * maxHeight}rem;
	}

        62% {
          height: ${1 + 1.0927 * maxHeight}rem;
	}

	70% {
          height: ${1 + 0.9761 * maxHeight}rem;
	}

	72% {
          height: ${1 + 0.9765 * maxHeight}rem;
	}

	80% {
          height: ${1 + 1.0039 * maxHeight}rem;
	}

	82% {
          height: ${1 + 1.0052 * maxHeight}rem;
	}

	90% {
          height: ${1 + 0.9997 * maxHeight}rem;
	}

	92% {
          height: ${1 + 0.999 * maxHeight}rem;
	}

        100% {
          height: ${1 + 1 * maxHeight}rem;
	}
    `
}

export function generateEaseInOutReverse(maxHeight: number) {
  return `
      0% {
        height: ${1 + 1 * maxHeight}rem;
      }
      4% {
        height: ${1 + 0.999 * maxHeight}rem;
      }

      8% {
        height: ${1 + 0.9997 * maxHeight}rem;
      }

      18% {
        height: ${1 + 1.0052 * maxHeight}rem;
      }

      20% {
        height: ${1 + 1.0039 * maxHeight}rem;
      }

      28% {
        height: ${1 + 0.9765 * maxHeight}rem;
      }
      30% {
        height: ${1 + 0.9761 * maxHeight}rem;

      }
      38% {
        height: ${1 + 1.0927 * maxHeight}rem;

      } 

      62% {
        height: ${1 + 0.0927 * maxHeight}rem;
      }

      70% {
        height: 1rem;   
      }

      72% {
        height: ${1 - 0.008 * maxHeight}rem;
      }

      80% {
        height: ${1 - 0.008 * maxHeight}rem;
      }

      82% {
        height: ${1 - 0.008 * maxHeight}rem;
      }

      90% {
        height: ${1 - 0.008 * maxHeight}rem;
      }

      92% {
        height: ${1 - 0.008 * maxHeight}rem;
      }

      100% {
        height: 1rem;
      }
  `
}
