import { TabObject, Tabs } from "../../../../components/Commons/Tabs"
import { isRouteSelected } from "../../../../utils/routes"
import { ReactComponent as BankNote } from "../../../../assets/bank-note.svg"
import { ReactComponent as Rules } from "../../../../assets/list-boxes.svg"
import { ReactComponent as Archives } from "../../../../assets/folder-archive.svg"
import { ReactComponent as Settings } from "../../../../assets/settings-bank.svg"
import { ReactComponent as Warning } from "../../../../assets/warning.svg"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom"
import { ReactComponent as ReturnIcon } from "../../../../assets/left-chevron.svg"
import { ReactComponent as DistributeVertical } from "../../../../assets/distribute-vertical.svg"
import * as Ct from "ldlj"
import { getIdFromParams } from "../../../../utils/company"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  getAccountsByBankThunk,
  syncErrorThunk,
} from "../../../../store/ducks/bank.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { Option, Select } from "../../../../components/Commons/Select"
import { useIntl } from "react-intl"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { accountIsSet, redirectionsAccount } from "../../../../utils/bank"
import styled from "styled-components/macro"
import { Button } from "../../../../components/Commons/Button"
/* eslint-disable camelcase */

export const AccountPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()

  const bankId = getIdFromParams(useParams())("bank_id")
  const accountID = getIdFromParams(useParams())("account_id")
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const items = useRNBSelector((state) => state.bank.items)
  const userTypology = useRNBSelector((state) => state.user.typology)
  const accounts = useRNBSelector((state) => state.bank.accounts)
  const { fiduciaryName, fiduciaryId, pricingType, override_pricing } =
    useRNBSelector((state) => ({
      fiduciaryName: state.fiduciary.name,
      fiduciaryId: state.fiduciary.id,
      pricingType: state.fiduciary.active_pricing?.pricing_type,
      override_pricing: state.fiduciary.override_pricing,
    }))

  if (pricingType === "per_document" && !override_pricing) {
    navigate("/Unauthorized")
  }

  const [companyId, setCompanyId] = useState<number>(0)

  useEffect(() => {
    if (selectedCompanyId && selectedCompanyId !== 0) {
      setCompanyId(selectedCompanyId)
      if (selectedCompanyId !== companyId && companyId !== 0) {
        navigate(`/office/company/${selectedCompanyId}/bank/accounts`)
      }
    }
  }, [selectedCompanyId])

  const currentAcccount = Object.values(accounts).find(
    (a) => a.id === accountID
  )

  useEffect(() => {
    if (
      userTypology === "customer" &&
      ((currentAcccount && !accountIsSet(currentAcccount)) ||
        currentTab !== "transactions")
    ) {
      navigate("/Unauthorized")
    }
  }, [userTypology, currentAcccount])

  const itemAccount = Object.values(items).find((item) => item.id === bankId)

  const [accountSelected, setAccountSelected] =
    useState<Option<string> | null>()

  const options: Array<Option<string>> = Object.values(accounts).map((o) => {
    return {
      value: String(o.id),
      label: capitalizeFirstLetter(o.name),
      disabled: userTypology === "customer" && !accountIsSet(o),
    }
  })

  const routePattern =
    "\\/company\\/\\d+\\/bank\\/accounts\\/bank\\/\\d+\\/\\d+\\/"

  const currentTab =
    location.pathname.split("/")[location.pathname.split("/").length - 1]

  useEffect(() => {
    if (accountID && accounts) {
      setAccountSelected(options.find((o) => o.value === String(accountID)))
    }
  }, [accountID, accounts])

  useEffect(() => {
    if (selectedCompanyId && bankId && fiduciaryId !== 0) {
      dispatch(getAccountsByBankThunk(fiduciaryId, selectedCompanyId, bankId))
    }
  }, [dispatch, selectedCompanyId, fiduciaryId, bankId])

  const customerTabs: TabObject[] = [
    {
      to: "transactions",
      title: "Transactions",
      isDisplayed: true,
      icon: <BankNote />,
      isSelected: isRouteSelected(location, routePattern, "transactions"),
      disabled: !accountIsSet(currentAcccount),
    },
  ]

  const linkTabs: TabObject[] = [
    {
      to: "rules",
      title: "Règles d'affectation",
      isDisplayed: true,
      icon: <Rules />,
      isSelected: isRouteSelected(location, routePattern, "rules"),
      disabled: !accountIsSet(currentAcccount),
    },
    {
      to: "rules_without_document",
      title: "Règles sans pièce jointe",
      isDisplayed: true,
      icon: <DistributeVertical />,
      isSelected: isRouteSelected(
        location,
        routePattern,
        "rules_without_document"
      ),
      disabled: !accountIsSet(currentAcccount),
    },
    {
      to: "archives",
      title: "Archives",
      isDisplayed: true,
      icon: <Archives />,
      isSelected: isRouteSelected(location, routePattern, "archives"),
      disabled: !accountIsSet(currentAcccount),
    },
    {
      to: "settings",
      title: "Paramètres du compte",
      isDisplayed: true,
      icon: <Settings />,
      isSelected: isRouteSelected(location, routePattern, "settings"),
    },
  ]

  const allTabs: TabObject[] =
    userTypology !== "customer"
      ? [...customerTabs, ...linkTabs]
      : [...customerTabs]

  return (
    <>
      <Ct.Button
        label={"retour"}
        prefix={<ReturnIcon />}
        width={12.75}
        height={4}
        onClick={() =>
          navigate(
            `/office/company/${selectedCompanyId}/bank/accounts/bank/${bankId}`
          )
        }
      />

      <Ct.Spacer />

      {accountSelected && currentAcccount && (
        <WrapperSelect>
          <Select
            intl={intl}
            options={options}
            value={accountSelected}
            onChangeCallback={(e: Ct.Option<string>) => {
              setAccountSelected(e)
              const account = Object.values(accounts).find(
                (a) => a.id === Number(e.value)
              )

              if (selectedCompanyId && bankId && account) {
                navigate(
                  redirectionsAccount(selectedCompanyId, bankId, account)
                )
              }
            }}
            label={""}
            domain={"bank-accounts"}
            optionType={"select-account"}
            defaultValue={options.find(
              (option) => option.value === String(accountID)
            )}
          />
        </WrapperSelect>
      )}

      <Ct.Spacer />

      {itemAccount &&
        itemAccount.status > 0 &&
        itemAccount.status !== 1100 &&
        currentTab === "transactions" && (
          <>
            <ErrorStatusWrapper>
              <Button
                label={intl.formatMessage({
                  id: "bank-accounts.error-bridge.reconnect",
                })}
                colorType="Tertiary"
                colorScheme={{
                  background: "amaranth",
                  color: "white",
                  border: "amaranth",
                }}
                onClick={() => {
                  if (!selectedCompanyId) return
                  dispatch(
                    syncErrorThunk(
                      fiduciaryId,
                      selectedCompanyId,
                      itemAccount.id,
                      fiduciaryName
                    )
                  )
                }}
              />

              <TextWarning>
                <StyledWarning />
                <StyledText
                  text={intl.formatMessage({
                    id: "bank-accounts.error-bridge2",
                  })}
                  textStyle={{
                    color: "amaranth",
                  }}
                />
                <Ct.Text
                  text={intl.formatMessage(
                    { id: "bank-accounts.error-bridge-status" },
                    { bankinId: itemAccount.id, status: itemAccount.status }
                  )}
                  textStyle={{
                    color: "amaranth",
                    fontStyle: "italic",
                  }}
                />
              </TextWarning>
            </ErrorStatusWrapper>
            <Ct.Spacer />
          </>
        )}

      <Tabs
        tabs={allTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}

const WrapperSelect = styled.div`
  width: 42rem;
`
const ErrorStatusWrapper = styled.div`
  display: flex;
  align-items: center;
`
const StyledWarning = styled(({ ...props }) => <Warning {...props} />)`
  width: 3rem;
  height: 2rem;
`

const TextWarning = styled.div`
  padding: 0 2rem;
`
const StyledText = styled(Ct.Text)`
  padding-right: 1rem;
`
