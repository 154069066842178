/* eslint-disable camelcase */
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { getIdFromParams } from "../../../../../utils/company"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  TextNoChanges,
} from "../../../../../components/ChangesList"
import { sizes } from "../../../../../styles/design.config"
import {
  LegalEntityInfo,
  getCustomerHistoryThunk,
} from "../../../../../store/ducks/invoicing.duck"

interface CustomerHistoryModalProps extends Ct.ModalComponentsProps {
  legalEntityInfo: LegalEntityInfo | null
}
export const CustomerHistoryModal = ({
  isDisplayed,
  onClose,
  legalEntityInfo,
}: CustomerHistoryModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()

  const selectedCompanyId = getIdFromParams(params)("company_id")

  const customerHistoryEvents = useRNBSelector(
    (state) => state.invoicing.customerHistoryEvents
  )
  useEffect(() => {
    if (!legalEntityInfo?.legal_entity_info_id) {
      return
    }
    dispatch(
      getCustomerHistoryThunk({
        legal_entity_info_id: legalEntityInfo.legal_entity_info_id,
      })
    )
  }, [legalEntityInfo?.legal_entity_info_id, selectedCompanyId])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          text={intl.formatMessage({
            id: "invoicing.customer.modal-history.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={intl.formatMessage({
                id: `invoicing.customer.modal-history.client`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={" : "}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={legalEntityInfo?.name || ""}
              textStyle={{ fontWeight: 700 }}
            />
          </ChangeHeader>
          <ChangesList>
            {customerHistoryEvents.length > 0 ? (
              customerHistoryEvents.map((e) => {
                return (
                  <ChangeItem key={e.timestamp}>
                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: `invoicing.customer.modal-history.${e.event_type}`,
                        },
                        {
                          date: DateTime.fromJSDate(
                            new Date(e.timestamp)
                          ).toFormat("dd/MM/yyyy à HH:mm"),
                          email: e.email,
                        }
                      )}
                    />
                  </ChangeItem>
                )
              })
            ) : (
              <TextNoChanges
                text={intl.formatMessage({
                  id: `invoicing.customer.modal-history.no-changes`,
                })}
              />
            )}
          </ChangesList>
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: "invoicing.customer.modal-history.ok",
          })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
