import styled from "styled-components/macro"
import { ReactComponent as RoundCheckSVG } from "../assets/round-check.svg"
import { ReactComponent as RoundCrossSVG } from "../assets/round-cross.svg"
import { Text } from "../components/Commons/Text"
import * as Ct from "ldlj"

export interface RowPermissionProps {
  isAuthorized?: boolean
  disabled?: boolean
  text: string
}

export const RowPermission = ({
  isAuthorized = true,
  disabled = false,
  text,
}: RowPermissionProps) => (
  <Row>
    <SVG>
      {isAuthorized && !disabled ? <RoundCheckSVG /> : <RoundCrossSVG />}
    </SVG>
    <Ct.Spacer width={1} />
    <Permission isAuthorized={isAuthorized}>
      <Text
        text={text}
        textStyle={{
          color: isAuthorized && !disabled ? "navy" : "hawkes",
        }}
      />
    </Permission>
  </Row>
)

interface Authorized {
  isAuthorized: boolean
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Permission = styled.div<Authorized>`
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 3.25rem;
  flex-wrap: nowrap;

  max-width: fit-content;
  word-break: break-all;
`

const SVG = styled.div`
  display: flex;
`
