interface Config {
  baseURL: string
}

const production: Config = {
  baseURL: "https://app.chaintrust.io",
}

const staging: Config = {
  baseURL: "https://staging.chaintrust.io",
}

const local: Config = {
  baseURL: "http://localhost:3000",
}

const cypress: Config = {
  baseURL: "https://ci.chaintrust.io",
}

const kubernetes: Config = {
  baseURL: "http://kub.chaintrust.io",
}

const emma: Config = {
  baseURL: "https://emma.chaintrust.io:3000",
}

const pierreh: Config = {
  baseURL: "https://pierreh.chaintrust.io:3000",
}

const alais: Config = {
  baseURL: "https://alais.chaintrust.io:3000",
}

const pierref: Config = {
  baseURL: "https://pierref.chaintrust.io:3000",
}

const alex: Config = {
  baseURL: "https://alex.chaintrust.io:3000",
}

const flore: Config = {
  baseURL: "https://flore.chaintrust.io:3000",
}

const salem: Config = {
  baseURL: "https://salem.chaintrust.io:3000",
}

const micael: Config = {
  baseURL: "https://micael.chaintrust.io:3000",
}

type env =
  | "production"
  | "staging"
  | "local"
  | "kubernetes"
  | "cypress"
  | "emma"
  | "pierreh"
  | "alex"
  | "flore"
  | "salem"
  | "pierref"
  | "alais"
  | "micael"

const configByEnv: Record<env, Config> = {
  production: production,
  staging: staging,
  local: local,
  kubernetes: kubernetes,
  cypress: cypress,
  emma: emma,
  pierreh: pierreh,
  alex: alex,
  micael: micael,
  flore: flore,
  salem: salem,
  pierref: pierref,
  alais: alais,
}

const config = process.env.REACT_APP_ENV
  ? configByEnv[process.env.REACT_APP_ENV as env]
  : local

export default {
  // Add common config values here
  name: "really_not_bootstrap",
  invoicingLogosBucketUri:
    "https://chaintrust-invoicing-logos.s3.eu-west-3.amazonaws.com/",
  invoicingAdditionalLogosBucketUri:
    "https://chaintrust-invoicing-additional-logos.s3.eu-west-3.amazonaws.com/",
  ...config,
}
