import styled from "styled-components/macro"
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom"

import { isRouteSelected } from "../../../../utils/routes"
import { ReactComponent as CreatedDocuments } from "../../../../assets/created_documents.svg"
import { ReactComponent as CreatedInvoice } from "../../../../assets/file-file.svg"
import { ReactComponent as CreatedQuotation } from "../../../../assets/file.svg"
import { ReactComponent as CreatedCreditNote } from "../../../../assets/file-invoice-line.svg"
import { ReactComponent as DraftDocuments } from "../../../../assets/draft_documents.svg"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"

export const InvoiceDocumentsMenu = () => {
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/edm"

  const linkTabs: TabObject[] = [
    {
      to: "created",
      title: "Tous les documents émis",
      isDisplayed: true,
      icon: <CreatedDocuments />,
      isSelected: isRouteSelected(location, routePattern, "created"),
    },
    {
      to: "invoice",
      title: "Factures",
      isDisplayed: true,
      icon: <CreatedInvoice />,
      isSelected: isRouteSelected(location, routePattern, "invoice"),
    },
    {
      to: "quotation",
      title: "Devis",
      isDisplayed: true,
      icon: <CreatedQuotation />,
      isSelected: isRouteSelected(location, routePattern, "quotation"),
    },
    {
      to: "creditNote",
      title: "Avoirs",
      isDisplayed: true,
      icon: <CreatedCreditNote />,
      isSelected: isRouteSelected(location, routePattern, "creditNote"),
    },
    {
      to: "drafts",
      title: "Brouillons",
      isDisplayed: true,
      icon: <DraftDocuments />,
      isSelected: isRouteSelected(location, routePattern, "draft"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <Content>
        <Outlet />
      </Content>
    </>
  )
}

const Content = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  padding: 4rem;
  margin-bottom: 2rem;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  border-top-right-radius: 4rem;
  box-sizing: border-box;
`
