import { Navigate, RouteObject } from "react-router-dom"

import UserLogin from "./user/Login"
import UserSSO from "./user/UserSSO"
import UserRegister from "./user/Register"
import UserForgottenPassword from "./user/ForgottenPassword"
import UserResetPassword from "./user/ResetPassword"
import IncompleteRegistration from "./user/IncompleteRegistration"
import { AcceptTermsAndConditions } from "./user/AcceptTermsAndConditions"

import { Management } from "./user/Management"
import FiduciaryRegister from "./fiduciary/Register"
import { CompanyRegister } from "../components/CompanyRegister"
import { InitializationFEC } from "./office/company/InitializationFEC"
import { AccountingType } from "./office/company/AccountingType"

import Unauthorized403 from "./4XX/403Unauthorized"
import NotFound404 from "./4XX/404NotFound"
import { Layout } from "../Layout"

import Office from "./office/Office"
import Company from "./office/company/Company"
import CompanySettings from "./office/company/settings/Settings"
import CompanySettingsMenu from "./office/company/CompanySettingsMenu"

import FiduciarySettings from "./office/fiduciary/Settings"
import CaptureMail from "./office/company/settings/CaptureMail"
import { FiscalYears } from "./office/company/settings/FiscalYears"
import Deactivate from "./office/company/settings/Deactivate"
import { ChangeSoftware } from "./office/company/settings/ChangeSoftware"
import { WritingLabels } from "./office/company/settings/WritingLabels"
import Companies from "./office/fiduciary/Companies"
import { Indicators } from "./office/fiduciary/Indicators"
import Fiduciary from "./office/fiduciary/Fiduciary"
import CompanyDropDocuments from "./office/company/dropDocuments/CompanyDropDocuments"
import { AccessDenied } from "../components/AccessDenied"
import CompanyWritingsDisplay from "./office/company/writings/Writings"
import { WritingDetail } from "./office/company/writings/WritingDetail"
import { Accounts } from "./office/company/bank/Accounts"
import { Bank } from "./office/company/bank/Bank"
import { CompanyInformations } from "./office/company/settings/Informations"
import Customers from "./office/company/invoicing/settings/Customers"
import { InvoicingMenu } from "./office/company/invoicing/InvoicingMenu"
import { Products } from "./office/company/invoicing/settings/Products"
import { Numberings } from "./office/company/invoicing/settings/Numberings"
import BankSettings from "./office/company/invoicing/settings/BankSettings"
import { InvoiceIssuance } from "./office/company/invoicing/InvoiceIssuance"
import { InvoiceDocuments } from "./office/company/invoicing/InvoiceDocuments"
import { InvoiceDrafts } from "./office/company/invoicing/InvoiceDrafts"
import { InvoiceDocumentsMenu } from "./office/company/invoicing/InvoiceDocumentsMenu"
import FiduciaryBills from "./office/fiduciary/Bills"
import { InvoiceNavigation } from "./office/company/invoicing/InvoiceNavigation"
import { EdmMenu } from "./office/company/EDM/EdmMenu"
import { Merchants } from "./office/company/EDM/Merchants"
import { AccountingPlanCodes } from "./office/company/accountingPlan/AccountingPlanCodes"
import { AccountingPlanMenu } from "./office/company/accountingPlan/AccountingPlanMenu"
import { AccountingPlanVat } from "./office/company/accountingPlan/AccountingPlanVat"
import { Vat } from "./office/company/accountingPlan/Vat"
import { GoodsTransfer } from "./office/company/accountingPlan/GoodsTransfer"
import { AccountingPlanAccounts } from "./office/company/accountingPlan/AccountingPlanAccounts"
import { LCR } from "./office/company/accountingPlan/LCR"
import { AccountingPlanJournals } from "./office/company/accountingPlan/AccountingPlanJournals"
import { OtherFiles } from "./office/company/EDM/OtherFiles"
import { TreasuryBuy } from "./office/company/treasury/TreasuryBuy"
import { TreasurySell } from "./office/company/treasury/TreasurySell"
import { TreasurySettings } from "./office/company/treasury/TreasurySettings"
import { ToControl } from "./office/company/treasury/buy/ToControl"
import { ToPay } from "./office/company/treasury/buy/ToPay"
import { Paid } from "./office/company/treasury/buy/Paid"
import { Rejected } from "./office/company/treasury/buy/Rejected"
import { Irrecoverable } from "./office/company/treasury/sell/Irrecoverable"
import { ToReceive } from "./office/company/treasury/sell/ToReceive"
import { Received } from "./office/company/treasury/sell/Received"
import { TreasuryBuySettings } from "./office/company/treasury/settings/TreasuryBuySettings"
import { TreasurySellSettings } from "./office/company/treasury/settings/TreasurySellSettings"
import { TreasuryAutovalidationSettings } from "./office/company/treasury/settings/TreasuryAutovalidationSettings"
import { AccountPage } from "./office/company/bank/AccountPage"
import { Transactions } from "./office/company/bank/bankSettings/Transactions"
import { Rules } from "./office/company/bank/bankSettings/Rules"
import { RulesWithoutDocument } from "./office/company/bank/bankSettings/RulesWithoutDocument"
import { Archives } from "./office/company/bank/bankSettings/Archives"
import { Settings } from "./office/company/bank/bankSettings/Settings"

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <UserLogin />,
        index: true,
      },
      {
        path: "user/login",
        element: <UserLogin />,
      },
      {
        path: "user/sso",
        element: <UserSSO />,
      },
      {
        path: "user/register",
        element: <UserRegister />,
      },
      {
        path: "user/forgotten_password",
        element: <UserForgottenPassword />,
      },
      {
        path: "user/reset_password",
        element: <UserResetPassword />,
      },
      {
        path: "user/incomplete_registration",
        element: <IncompleteRegistration />,
      },
      {
        path: "user/accept_terms_and_conditions",
        element: <AcceptTermsAndConditions />,
      },
      {
        path: "fiduciary/register",
        element: <FiduciaryRegister />,
      },
      {
        path: "office/*",
        element: <Office />,
        children: [
          {
            index: true,
            element: <Navigate to={"fiduciary/companies"} />,
          },
          {
            path: "management",
            element: <Management />,
          },
          {
            path: "fiduciary/company_accounting_type",
            element: <AccountingType />,
          },
          {
            path: "fiduciary/register_company/:account_type",
            element: <CompanyRegister />,
          },
          {
            path: "*",
            element: <Navigate to={"/not_found"} />,
          },
          {
            path: "fiduciary/*",
            element: <Fiduciary />,
            children: [
              {
                index: true,
                element: <Companies />,
              },
              {
                path: "companies",
                element: <Companies />,
              },
              {
                path: "indicators",
                element: <Indicators />,
              },
              {
                path: "settings",
                element: <FiduciarySettings />,
              },
              {
                path: "bills",
                element: <FiduciaryBills />,
              },
              {
                path: "*",
                element: <Navigate to={"/not_found"} />,
              },
            ],
          },
          {
            path: "company",
            element: <Company />,
            children: [
              {
                path: "*",
                element: <Navigate to={"/not_found"} />,
              },
              {
                path: ":company_id",
                children: [
                  {
                    index: true,
                    element: <Navigate to={"drop"} />,
                  },
                  {
                    path: "initialize",
                    element: <InitializationFEC />,
                  },
                  { path: "drop", element: <CompanyDropDocuments /> },
                  {
                    path: "edm/*",
                    element: <EdmMenu />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to={"options"} />,
                      },
                      {
                        path: ":buy_or_sell",
                        element: <Merchants />,
                      },
                      {
                        path: "other",
                        element: <OtherFiles fileType={"unsupported"} />,
                      },
                      {
                        path: "bank",
                        element: <OtherFiles fileType={"bank"} />,
                      },
                      {
                        path: "fiscal",
                        element: <OtherFiles fileType={"fiscal"} />,
                      },
                      {
                        path: "social",
                        element: <OtherFiles fileType={"social"} />,
                      },
                      {
                        path: "permanent",
                        element: <OtherFiles fileType={"permanent"} />,
                      },
                      {
                        path: "*",
                        element: <Navigate to={"/not_found"} />,
                      },
                    ],
                  },
                  {
                    path: "accounting_plan/*",
                    element: <AccountingPlanMenu />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to={"buy"} />,
                      },
                      {
                        path: ":buy_or_sell",
                        element: <AccountingPlanVat />,
                      },
                      {
                        path: "accounts",
                        element: <AccountingPlanAccounts />,
                      },
                      {
                        path: "codes",
                        element: <AccountingPlanCodes />,
                      },
                      {
                        path: "vat",
                        element: <Vat />,
                      },
                      {
                        path: "goods_transfer",
                        element: <GoodsTransfer />,
                      },
                      {
                        path: "lcr",
                        element: <LCR />,
                      },
                      {
                        path: "journals",
                        element: <AccountingPlanJournals />,
                      },
                      {
                        path: "*",
                        element: <Navigate to={"/not_found"} />,
                      },
                    ],
                  },
                  {
                    path: "invoicing/*",
                    children: [
                      {
                        index: true,
                        element: <Navigate to={"navigation"} />,
                      },
                      {
                        path: "issuance/:document_type",
                        element: <InvoiceIssuance />,
                      },
                      {
                        path: "navigation",
                        element: <InvoiceNavigation />,
                      },
                      {
                        path: "documents/*",
                        element: <InvoiceDocumentsMenu />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"created"} />,
                          },
                          {
                            path: "created",
                            element: <InvoiceDocuments />,
                          },
                          {
                            path: "invoice",
                            element: (
                              <InvoiceDocuments documentType={"invoice"} />
                            ),
                          },
                          {
                            path: "quotation",
                            element: (
                              <InvoiceDocuments documentType={"quotation"} />
                            ),
                          },
                          {
                            path: "creditNote",
                            element: (
                              <InvoiceDocuments documentType={"creditNote"} />
                            ),
                          },
                          {
                            path: "drafts",
                            element: <InvoiceDrafts />,
                          },
                        ],
                      },
                      {
                        path: "settings/*",
                        element: <InvoicingMenu />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"customers"} />,
                          },
                          {
                            path: "customers",
                            element: <Customers />,
                          },
                          {
                            path: "products",
                            element: <Products />,
                          },
                          {
                            path: "numbering",
                            element: <Numberings />,
                          },
                          {
                            path: "banksettings",
                            element: <BankSettings />,
                          },
                          {
                            path: "*",
                            element: <Navigate to={"/not_found"} />,
                          },
                        ],
                      },
                      {
                        path: "*",
                        element: <Navigate to={"/not_found"} />,
                      },
                    ],
                  },
                  {
                    path: "writings/*",
                    children: [
                      {
                        index: true,
                        element: <CompanyWritingsDisplay />,
                      },
                      {
                        // id is a query param and not a route param as pending writings have no ids yet
                        path: ":selectedFiscalYearId",
                        element: <WritingDetail />,
                      },
                      {
                        path: "*",
                        element: <Navigate to={"/not_found"} />,
                      },
                    ],
                  },
                  {
                    path: "settings",
                    element: <CompanySettingsMenu />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to={"options"} />,
                      },
                      {
                        path: "options",
                        element: <CompanySettings />,
                      },
                      {
                        path: "informations",
                        element: <CompanyInformations />,
                      },
                      {
                        path: "capture",
                        element: <CaptureMail />,
                      },
                      {
                        path: "fiscalyears",
                        element: <FiscalYears />,
                      },
                      {
                        path: "deactivate",
                        element: <Deactivate />,
                      },
                      {
                        path: "change_software",
                        element: <ChangeSoftware />,
                      },
                      {
                        path: "writing_labels",
                        element: <WritingLabels />,
                      },
                    ],
                  },
                  {
                    path: "bank",
                    children: [
                      {
                        path: "accounts",
                        children: [
                          {
                            index: true,
                            element: <Accounts />,
                          },
                          {
                            path: "bank",
                            children: [
                              {
                                path: "*",
                                element: <Navigate to={"/not_found"} />,
                              },
                              {
                                path: ":bank_id",
                                children: [
                                  {
                                    index: true,
                                    element: <Bank />,
                                  },
                                  {
                                    path: ":account_id/*",
                                    element: <AccountPage />,
                                    children: [
                                      {
                                        path: "transactions",
                                        element: <Transactions />,
                                      },
                                      {
                                        path: "rules",
                                        element: <Rules />,
                                      },
                                      {
                                        path: "rules_without_document",
                                        element: <RulesWithoutDocument />,
                                      },
                                      {
                                        path: "archives",
                                        element: <Archives />,
                                      },
                                      {
                                        path: "settings",
                                        element: <Settings />,
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "treasury/*",
                    children: [
                      {
                        index: true,
                        element: <Navigate to={"buy"} />,
                      },
                      {
                        path: "buy/*",
                        element: <TreasuryBuy />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"to_control"} />,
                          },
                          {
                            path: "to_control",
                            element: <ToControl />,
                          },
                          {
                            path: "to_pay",
                            element: <ToPay />,
                          },
                          {
                            path: "paid",
                            element: <Paid />,
                          },
                          {
                            path: "rejected",
                            element: <Rejected />,
                          },
                          {
                            path: "*",
                            element: <Navigate to={"/not_found"} />,
                          },
                        ],
                      },
                      {
                        path: "sell/*",
                        element: <TreasurySell />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"to_receive"} />,
                          },
                          {
                            path: "to_receive",
                            element: <ToReceive />,
                          },
                          {
                            path: "received",
                            element: <Received />,
                          },
                          {
                            path: "irrecoverable",
                            element: <Irrecoverable />,
                          },
                          {
                            path: "*",
                            element: <Navigate to={"/not_found"} />,
                          },
                        ],
                      },
                      {
                        path: "settings/*",
                        element: <TreasurySettings />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={"buy"} />,
                          },
                          {
                            path: "buy",
                            element: <TreasuryBuySettings />,
                          },
                          {
                            path: "sell",
                            element: <TreasurySellSettings />,
                          },
                          {
                            path: "auto_validation",
                            element: <TreasuryAutovalidationSettings />,
                          },
                          {
                            path: "*",
                            element: <Navigate to={"/not_found"} />,
                          },
                        ],
                      },
                      {
                        path: "*",
                        element: <Navigate to={"/not_found"} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "unauthorized",
        element: <Unauthorized403 />,
      },
      {
        path: "/access_denied",
        element: <AccessDenied />,
      },
      {
        path: "*",
        element: <NotFound404 />,
      },
    ],
  },
]
