import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { changeTypology } from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Select } from "../Commons/Select"
import { buyOrSell } from "../../utils/company"
import { DocumentType } from "../../utils/inputMask"

interface DocumentTypology {
  documentType: DocumentType
  buyOrSell: buyOrSell
}
export const InvoiceTypeSelectCell = () => {
  const intl = useIntl()

  const dispatch = useDispatch()

  const { editedFullDocument } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
  }))

  const typologies: DocumentTypology[] = [
    {
      documentType: "invoice",
      buyOrSell: "buy",
    },
    {
      documentType: "invoice",
      buyOrSell: "sell",
    },
    {
      documentType: "credit_note",
      buyOrSell: "buy",
    },
    {
      documentType: "credit_note",
      buyOrSell: "sell",
    },
    {
      documentType: "bill_of_exchange",
      buyOrSell: "buy",
    },
  ]
  const options = typologies.map((t) => ({
    value: `${t.documentType}-${t.buyOrSell}`,
    label: intl.formatMessage({
      id: `input-mask.typology.${t.documentType}-${t.buyOrSell}`,
    }),
  }))

  const selectedOption =
    options.find((o) => {
      const documentType = o.value.split("-")[0]
      const buyOrSell = o.value.split("-")[1]

      return (
        documentType === editedFullDocument?.document_type &&
        buyOrSell === editedFullDocument?.buy_or_sell
      )
    }) || null

  return (
    <StyledSelect
      height="8rem"
      intl={null}
      value={selectedOption}
      defaultValue={null}
      options={options}
      onChangeCallback={({ value }) => {
        if (!editedFullDocument) {
          return
        }
        const documentType = value.split("-")[0] as DocumentType
        const buyOrSell = value.split("-")[1] as buyOrSell

        dispatch(changeTypology({ documentType, buyOrSell }))
      }}
      border="none"
      borderRadius="0"
    />
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`
