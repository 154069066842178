import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import {
  AccountRules,
  AccountRulesWithoutDocument,
} from "../../store/ducks/bank.ducks"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
} from "../ChangesList"
import { useRNBSelector } from "../../store/rootReducer"
import { getRuleEventType, getRuleEvents } from "../../utils/bank"
import { DateTime } from "luxon"
import { sizes } from "../../styles/design.config"

interface RuleEventsProps {
  isDisplayed: boolean
  onClose: () => void
  rule: AccountRules | AccountRulesWithoutDocument
}

export const RuleEventsModal = ({
  isDisplayed,
  onClose,
  rule,
}: RuleEventsProps) => {
  const intl = useIntl()
  const dataRule = useRNBSelector((state) => state.bank.dataToCreateRule)
  const events = useRNBSelector(
    (state) =>
      state.bank.accounts[rule.bankin_account_id].rules?.find(
        (r) => r.id === rule.id
      )?.events
  )

  const isAccountRule = (
    rule: AccountRules | AccountRulesWithoutDocument | null
  ): rule is AccountRules => {
    if (rule === undefined || rule === null) return false
    return !(rule as AccountRules).rule_without_document
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Title
          text={intl.formatMessage({
            id: `bank-management.rules.events.title`,
          })}
          size={7}
        />
        <Ct.Spacer height={4} />

        <ChangeCardContent width={"120rem"}>
          {isAccountRule(rule) && (
            <ChangeHeader>
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: `bank-management.rules.events.header`,
                  },
                  { priority: rule.priority_number }
                )}
                textStyle={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              />
            </ChangeHeader>
          )}

          <ChangesList height={"32rem"}>
            {events &&
              events.map((e) => (
                <ChangeItem key={e.email + rule.id}>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: `bank-management.rules.events.${e.event_type}`,
                    })}
                    textStyle={{
                      fontWeight: 700,
                    }}
                  />

                  <Mailto href={`mailto:${e.email}`}>{e.email}</Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(new Date(e.created_at)).toFormat(
                        "dd/MM/yyyy HH:mm:ss"
                      ) +
                      "). "
                    }
                  />

                  {e.event_type === "edit_rule" && (
                    <>
                      <Ct.Text
                        text={getRuleEventType(e.metadata)}
                        textStyle={{
                          fontWeight: 700,
                        }}
                      />

                      <Ct.Text
                        text={getRuleEvents(e.metadata, dataRule, intl)}
                        textStyle={{
                          fontWeight: 700,
                        }}
                      />
                    </>
                  )}
                </ChangeItem>
              ))}
          </ChangesList>
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
