import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { render } from "react-dom"
import "rsuite/dist/rsuite.min.css"
import "./index.css"
import RNB from "./RNB"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import configureStore from "./store/store.config"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter } from "react-router-dom"

// process.env.REACT_APP_ENV : undefined in local, "staging", "production"
if (process.env.REACT_APP_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0,
  })
}

const store = configureStore()

const renderApp = () =>
  render(
    <BrowserRouter basename="/rnb">
      <Provider store={store}>
        <RNB />
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  )

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./RNB", renderApp)
}

renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
