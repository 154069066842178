import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../../utils/company"
import { useParams } from "react-router-dom"
import {
  CreateOrModifyClientReset,
  GetAllLegalEntitiesForCompanyThunk,
  GetTypologiesThunk,
  LegalEntityInfo,
  LegalEntityWithoutInfos,
} from "../../../../../store/ducks/invoicing.duck"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { useIntl } from "react-intl"
import { ReactComponent as Edit } from "../../../../../assets/edit.svg"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as AddUsers } from "../../../../../assets/add-users.svg"
import { ReactComponent as Eye } from "../../../../../assets/eyeFilled.svg"

import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../../styles/design.config"
import { CreateOrModifyClientModal } from "../../../../../components/CreateOrModifyClientModal"
import { capitalizeFirstLetter } from "../../../../../utils/string"
import {
  sortLegalEntitiesAlphabetically,
  sortLegalEntitiesBySiren,
  sortLegalEntitiesByTypology,
  trimNumber,
  sortClientsBySearch,
  isLegalEntityInfos,
  isLegalEntityWithoutInfos,
  sortLegalEntitiesByTotalDiscount,
  isLegalEntityInfo,
} from "../../../../../utils/invoicing"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../../components/Commons/Table"
import { Text } from "../../../../../components/Commons/Text"
import { CustomerHistoryModal } from "./CustomerHistoryModal"

const Customers = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const params = useParams()
  const selectedCompanyId = getIdFromParams(params)("company_id")

  const [showHistoryClientIdModal, setShowHistoryClientIdModal] =
    useState<LegalEntityInfo | null>(null)
  const [search, setSearch] = useState("")
  const [isModalDisplayed, setIsModalDisplayed] = useState(false)
  const [createOrModify, setCreateOrModify] = useState<"create" | "modify">(
    "create"
  )
  const [clientToEdit, setClientToEdit] = useState<LegalEntityInfo>()
  const [clientToCreate, setClientToCreate] =
    useState<LegalEntityWithoutInfos | null>(null)

  const { legalEntities, typologies, legalEntitiesWithoutInfos } =
    useRNBSelector((state) => ({
      legalEntities: state.invoicing.legalEntities,
      typologies: state.invoicing.typologies,
      legalEntitiesWithoutInfos: state.invoicing.legalEntitiesWithoutInfos,
    }))

  const [clientsToDisplay, setClientsToDisplay] = useState<
    (LegalEntityInfo | LegalEntityWithoutInfos)[]
  >([])

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(GetTypologiesThunk(selectedCompanyId))
      dispatch(GetAllLegalEntitiesForCompanyThunk(selectedCompanyId))
      setSearch("")
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (legalEntities) {
      setClientsToDisplay(
        sortClientsBySearch(
          [...legalEntities, ...legalEntitiesWithoutInfos],
          search
        )
      )
    }
  }, [legalEntities, search])

  enum SortOptionsValues {
    "name",
    "typology",
    "total_discount",
    "siren",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.name) {
      setClientsToDisplay(
        sortLegalEntitiesAlphabetically(clientsToDisplay, asc)
      )
    } else if (option === SortOptionsValues?.siren) {
      setClientsToDisplay(sortLegalEntitiesBySiren(clientsToDisplay, asc))
    } else if (option === SortOptionsValues?.total_discount) {
      setClientsToDisplay(
        sortLegalEntitiesByTotalDiscount(clientsToDisplay, asc)
      )
    } else if (option === SortOptionsValues?.typology) {
      setClientsToDisplay(sortLegalEntitiesByTypology(clientsToDisplay, asc))
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setClientsToDisplay(sortClientsBySearch(legalEntities, search))
      }
    }
  }, [columnToSort])

  const columns = [
    {
      headerText: "invoicing.settings.header-name",
      content: (client: LegalEntityInfo | LegalEntityWithoutInfos) => (
        <Text
          text={capitalizeFirstLetter(client.name)}
          textStyle={{
            fontWeight: 500,
            textTransform: "initial",
            fontSize: 2,
            textAlign: "center",
            color: isLegalEntityWithoutInfos(client) ? "orange" : "navy",
          }}
          dataCy={"clientName"}
        />
      ),
    },
    {
      headerText: "invoicing.settings.header-typology",
      content: (client: LegalEntityInfo | LegalEntityWithoutInfos) => (
        <Ct.Text
          text={isLegalEntityInfos(client) ? typologies[client.typology] : ""}
          textStyle={{
            fontWeight: 400,
            textTransform: "initial",
            fontSize: 2,
            textAlign: "center",
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.header-total-discount",
      content: (client: LegalEntityInfo | LegalEntityWithoutInfos) => (
        <Ct.Text
          text={
            isLegalEntityInfo(client)
              ? client.total_discount > 0
                ? `${client.total_discount.toString()} %`
                : ""
              : ""
          }
          textStyle={{
            fontWeight: 400,
            textTransform: "initial",
            fontSize: 2,
            textAlign: "center",
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.header-siren",
      content: (client: LegalEntityInfo | LegalEntityWithoutInfos) => (
        <Ct.Text
          text={
            client.identification_number
              ? trimNumber(Number(client.identification_number)).toString()
              : ""
          }
          textStyle={{
            fontWeight: 400,
            textTransform: "initial",
            fontSize: 2,
            textAlign: "center",
          }}
          dataCy={"clientSiren"}
        />
      ),
    },
    {
      headerText: "invoicing.settings.header-action",
      content: (client: LegalEntityInfo | LegalEntityWithoutInfos) =>
        isLegalEntityInfos(client) ? (
          <>
            <Ct.Button
              label={intl.formatMessage({
                id: "invoicing.settings.header-action.edit",
              })}
              colorType={"Tertiary"}
              textTransform={"uppercase"}
              prefix={<EditLogo />}
              width={20}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              dataCy={
                client.identification_number
                  ? client.identification_number.toString()
                  : client.name
              }
              onClick={() => {
                setCreateOrModify("modify")
                setIsModalDisplayed(!isModalDisplayed)
                setClientToEdit(client)
              }}
            />
            <Ct.Spacer />
            <Eye
              onClick={() => {
                setShowHistoryClientIdModal(client)
              }}
            />
          </>
        ) : (
          <Ct.Button
            label={intl.formatMessage({
              id: "invoicing.settings.header-action.edit",
            })}
            colorType={"Tertiary"}
            textTransform={"uppercase"}
            prefix={<EditLogoWhite />}
            width={20}
            colorScheme={{
              background: "orange",
              color: "white",
              border: "orange",
            }}
            dataCy={
              client.identification_number
                ? client.identification_number.toString()
                : client.name
            }
            onClick={() => {
              setCreateOrModify("create")
              setIsModalDisplayed(!isModalDisplayed)
              setClientToCreate(client)
            }}
          />
        ),
    },
  ]

  const rowBackgroundColors = clientsToDisplay.map((_, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <>
      <CustomerHistoryModal
        isDisplayed={showHistoryClientIdModal !== null}
        legalEntityInfo={showHistoryClientIdModal}
        onClose={() => {
          setShowHistoryClientIdModal(null)
        }}
      />
      <Wrapper>
        <StyledSection>
          <Ct.Spacer height={4} />

          <WrapperTopBar>
            <Ct.Input
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              dataCy={"searchClient"}
              shadowed={true}
              noBorder={true}
              isSearch={true}
            />

            <Ct.FlexEnd>
              <Ct.Button
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.title",
                })}
                textTransform={"uppercase"}
                prefix={<AddUsers />}
                width={31}
                height={5}
                onClick={() => {
                  setIsModalDisplayed(!isModalDisplayed)
                  setCreateOrModify("create")
                  setClientToEdit(undefined)
                }}
                dataCy={"addClientButton"}
              />

              <Ct.Spacer width={4} />
            </Ct.FlexEnd>
          </WrapperTopBar>

          <Ct.Spacer height={3} />

          <TableWrapper>
            <Table
              intl={intl}
              columns={columns}
              rows={clientsToDisplay}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              padding={"0"}
              paddingHeader={"0 7rem 0 4rem"}
              paddingRows={"2rem 10rem 2rem 5rem"}
              fontWeightTitle={600}
              alertMessage={"menu.invoicing.settings.filter.not-found"}
              sortableColumnsLength={columns.length - 1}
              sortingMainFunction={(columnToSort) => {
                setColumnToSort(columnToSort)
              }}
              currentSortColumn={currentChevron}
              rowBackgroundColors={rowBackgroundColors}
              customScrollBar={true}
            />
          </TableWrapper>

          <CreateOrModifyClientModal
            isDisplayed={isModalDisplayed}
            onClose={() => {
              setIsModalDisplayed(!isModalDisplayed)
              dispatch(CreateOrModifyClientReset())
              setClientToCreate(null)
            }}
            createOrModify={createOrModify}
            selectedClient={clientToEdit}
            clientToCreate={clientToCreate}
          />
        </StyledSection>
      </Wrapper>
    </>
  )
}

export default Customers

const EditLogo = styled(Edit)`
  & path {
    fill: ${colors.cornflower};
  }
`

const EditLogoWhite = styled(Edit)`
  & path {
    fill: ${colors.white};
  }
`

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
`

const WrapperTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
