import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import { useEffect } from "react"
import { sizes } from "../../styles/design.config"
import { getHistoryThunk } from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { formatDateFRstring, formatTimeFRstring } from "../../utils/date"
import {
  getEntryType,
  getHistoryAmount,
  getHistoryCurrencyCode,
  getHistoryNewValue,
  getHistoryOldValue,
} from "../../utils/inputMaskHistory"

interface FullDocumentHistoryModalProps extends Ct.ModalComponentsProps {
  fullDocumentId: number | null
}

export const FullDocumentHistoryModal = ({
  isDisplayed,
  onClose,
  fullDocumentId,
}: FullDocumentHistoryModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const historyChanges = useRNBSelector(
    (state) => state.inputMask.history_modifications
  )

  useEffect(() => {
    if (!fullDocumentId) return
    dispatch(getHistoryThunk({ fullDocumentId }))
  }, [isDisplayed])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="10rem"
      right="10rem"
      top={window.innerHeight < 900 ? "5rem" : "20rem"}
      bottom={window.innerHeight < 900 ? "5rem" : "20rem"}
    >
      {isDisplayed && (
        <Ct.Card width="100%">
          <Ct.CloseCross onClick={onClose} />
          <Ct.Title
            text={intl.formatMessage({ id: "input-mask.history.title" })}
          />
          <Ct.Spacer height={4} />
          <ul>
            {historyChanges.map((historyChange) => {
              return (
                <div key={`${historyChange.type} -${historyChange.created_at}`}>
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: `input-mask.history.modification.${historyChange.type}`,
                      },
                      {
                        email: historyChange.email,
                        oldValue:
                          getHistoryOldValue(historyChange) ||
                          intl.formatMessage({ id: "input-mask.history.none" }),
                        newValue:
                          getHistoryNewValue(historyChange) ||
                          intl.formatMessage({ id: "input-mask.history.none" }),
                        amount: getHistoryAmount(historyChange),
                        currencyCode: getHistoryCurrencyCode(historyChange),
                        entryType: getEntryType(historyChange),
                        dateTimestamp: formatDateFRstring(
                          historyChange.created_at
                        ),
                        timeTimestamp: formatTimeFRstring(
                          historyChange.created_at
                        ),
                      }
                    )}
                  />
                  <Ct.Spacer height={1} />
                </div>
              )
            })}
          </ul>
          <Ct.Spacer height={4} />
          <Ct.Button
            label={intl.formatMessage({ id: "input-mask.history.button" })}
            onClick={onClose}
            width={sizes.button.standard}
          />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}
