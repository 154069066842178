import styled from "styled-components/macro"

import { ReactComponent as RotateLeft } from "../../assets/rotate-left.svg"
import { ReactComponent as RotateRight } from "../../assets/rotate-right.svg"
import { ReactComponent as NewWindow } from "../../assets/new_window.svg"
import { colors } from "../../styles/design.config"
import { NumberOrientation } from "../../utils/canvas"

interface ControlBarProps {
  rotationAngle: NumberOrientation
  setRotationAngle: (n: NumberOrientation) => void
  documentImageSrc: string
}

export const ControlBar = ({
  setRotationAngle,
  rotationAngle,
  documentImageSrc,
}: ControlBarProps) => {
  return (
    <ControlBarWrapper>
      <Button
        onClick={() => {
          const newRotation = (rotationAngle - 90) % 360
          const positiveRotation =
            newRotation >= 0 ? newRotation : newRotation + 360

          setRotationAngle(positiveRotation as NumberOrientation)
        }}
      >
        <RotateLeft />
      </Button>

      <Button
        onClick={() => {
          setRotationAngle(((rotationAngle + 90) % 360) as NumberOrientation)
        }}
      >
        <RotateRight />
      </Button>

      <Button
        onClick={() => {
          window.open(documentImageSrc, "_blank")
        }}
      >
        <NewWindow />
      </Button>
    </ControlBarWrapper>
  )
}

const ControlBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.cornflower};
  border: 2px solid ${colors.cornflower};
  height: 4.2rem;
  width: 4.2rem;
  border-radius: 50%;
  margin: 0 0.5rem;
  cursor: pointer;
`
