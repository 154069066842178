import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"
import { RedirectionDirective } from "../model/users"
import {
  getUserAllAccessThunk,
  warnBlockedUserThunk,
} from "../store/ducks/user.ducks"
import { useRNBSelector } from "../store/rootReducer"
import { ButtonsHeader } from "./topBars/ButtonsHeader"
import { Background } from "./Background"
import * as Ct from "ldlj"
import { colors } from "../styles/design.config"
import { ReactComponent as Illustration } from "../assets/illustration_question.svg"

export const AccessDenied = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const redirectionDirective = useRNBSelector((state) => {
    return state.user.redirection
  })

  useEffect(() => {
    dispatch(getUserAllAccessThunk())
  }, [dispatch])

  useEffect(() => {
    const routeByDirectives: Record<RedirectionDirective, string> = {
      UNSET: "",
      ACCESS_DENIED: "/access_denied",
      COMPANIES_LIST: "/office",
      SPECIFIC_COMPANY: `/office/company/${redirectionDirective.companyToRedirect}`,
      COMPANY_CREATION: "/office/fiduciary/company_accounting_type",
      ERROR: "",
      TERMS_OF_SERVICE_NOT_ACCEPTED: "/user/accept_terms_and_conditions",
      TERMS_OF_SALES_NOT_ACCEPTED: "/user/accept_terms_and_conditions",
      FIRST_CONNEXION: "/user/incomplete_registration",
      FIDUCIARY_NOT_CREATED: "/fiduciary/register",
      SSO: "/user/sso",
    }
    if (
      redirectionDirective.directive !== "ACCESS_DENIED" &&
      redirectionDirective.directive !== "UNSET" &&
      redirectionDirective.directive !== "TERMS_OF_SERVICE_NOT_ACCEPTED" &&
      redirectionDirective.directive !== "FIRST_CONNEXION" &&
      redirectionDirective.directive !== "ERROR" &&
      redirectionDirective.directive &&
      routeByDirectives[redirectionDirective.directive]
    ) {
      navigate(routeByDirectives[redirectionDirective.directive])
    }
  }, [
    redirectionDirective.directive,
    redirectionDirective.companyToRedirect,
    navigate,
  ])

  useEffect(() => {
    // use of session storage to send one warning even if multiple refresh happen
    if (!sessionStorage.getItem("hasWarnedUser")) {
      sessionStorage.setItem("hasWarnedUser", "true")
      dispatch(warnBlockedUserThunk())
    }
  }, [])
  return (
    <Background centeredTop={false}>
      <Content>
        <WrapperHeader>
          <ButtonsHeader />
        </WrapperHeader>
        <StyledCard radius={1.25} width={"auto"}>
          <Ct.RowCenter>
            <BolderedText
              text={intl.formatMessage({ id: "access-denied.title" })}
              textStyle={{
                lineHeight: 3,
                fontSize: 3,
              }}
            />
          </Ct.RowCenter>
          <Ct.Spacer height={4} />
          <MainsDiv>
            <DivLine />
            <DivTxt>
              <Ct.Text
                text={intl.formatMessage({ id: "access-denied.first-block.1" })}
                textStyle={{
                  lineHeight: 3,
                  fontSize: 1.75,
                }}
              />
              <Ct.Spacer height={1} />
              <StyledText
                text={intl.formatMessage({ id: "access-denied.first-block.2" })}
                textStyle={{
                  lineHeight: 3,
                  fontSize: 1.75,
                }}
              />
              <StyledText
                text={intl.formatMessage({ id: "access-denied.first-block.3" })}
                textStyle={{
                  lineHeight: 3,
                  fontSize: 1.75,
                }}
              />
              <StyledText
                text={intl.formatMessage({ id: "access-denied.first-block.4" })}
                textStyle={{
                  lineHeight: 3,
                  fontSize: 1.75,
                }}
              />
              <StyledText
                text={intl.formatMessage({ id: "access-denied.first-block.5" })}
                textStyle={{
                  lineHeight: 3,
                  fontSize: 1.75,
                }}
              />
            </DivTxt>
          </MainsDiv>
          <Ct.Spacer height={4} />
          <MainsDiv>
            <DivLine />
            <DivTxt paddingRight={10}>
              <Ct.Text
                text={intl.formatMessage({
                  id: "access-denied.second-block.1",
                })}
                textStyle={{
                  lineHeight: 2.3,
                  fontSize: 1.75,
                }}
              />
            </DivTxt>
          </MainsDiv>
          <Ct.Spacer height={4} />
          <MainsDiv>
            <DivLine />
            <Ct.Column>
              <DivTxt paddingRight={10}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "access-denied.third-block.1",
                  })}
                  textStyle={{
                    lineHeight: 2.3,
                    fontSize: 1.75,
                  }}
                />
                <Ct.Spacer height={2} />
              </DivTxt>
              <DivTxt>
                <Ct.Row>
                  <DivTxt paddingRight={4}>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "access-denied.fourth-block.1",
                      })}
                      textStyle={{
                        lineHeight: 3,
                        fontSize: 1.75,
                      }}
                    />
                    <Ct.Spacer height={1} />
                    <StyledText
                      text={intl.formatMessage({
                        id: "access-denied.fourth-block.2",
                      })}
                      textStyle={{
                        lineHeight: 3,
                        fontSize: 1.75,
                      }}
                    />
                    <Ct.Spacer height={0.5} />
                    <StyledText
                      text={intl.formatMessage({
                        id: "access-denied.fourth-block.3",
                      })}
                      textStyle={{
                        lineHeight: 3,
                        fontSize: 1.75,
                      }}
                    />
                    <Ct.Spacer height={0.5} />
                    <StyledText
                      text={intl.formatMessage({
                        id: "access-denied.fourth-block.4",
                      })}
                      textStyle={{
                        lineHeight: 3,
                        fontSize: 1.75,
                      }}
                    />
                    <Ct.Spacer height={0.5} />
                    <StyledText
                      text={intl.formatMessage({
                        id: "access-denied.fourth-block.5.1",
                      })}
                      textStyle={{
                        lineHeight: 2,
                        fontSize: 1.75,
                      }}
                    />
                    <DivTxt paddingLeft={2}>
                      <StyledText
                        text={intl.formatMessage({
                          id: "access-denied.fourth-block.5.2",
                        })}
                        textStyle={{
                          lineHeight: 2,
                          fontSize: 1.75,
                        }}
                      />
                      <StyledText
                        text={intl.formatMessage({
                          id: "access-denied.fourth-block.5.3",
                        })}
                        textStyle={{
                          lineHeight: 2,
                          fontSize: 1.75,
                        }}
                      />
                    </DivTxt>
                  </DivTxt>
                </Ct.Row>
              </DivTxt>
            </Ct.Column>
          </MainsDiv>
          <Styl height={170} width={170} />
        </StyledCard>
      </Content>
    </Background>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 4rem 4rem 6rem 6rem;
  width: 95rem;
`
const WrapperHeader = styled.div`
  position: absolute;
  top: 5rem;
  right: 4rem;
`
const MainsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const DivLine = styled.div`
  background-color: ${colors.navy};
  height: 100%;
  min-width: 1rem;
  border-radius: 5px;
`
interface DivProps {
  paddingRight?: number
  paddingLeft?: number
}
const DivTxt = styled.div<DivProps>`
  display: flex;
  flex-direction: column;
  padding-right: ${({ paddingRight }) =>
    paddingRight ? paddingRight + "rem" : 0};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? paddingLeft + "rem" : "1rem"};
`
const StyledText = styled(Ct.Text)`
  padding-left: 1rem;
`
const BolderedText = styled(Ct.Text)`
  font-weight: 900;
`
const Styl = styled(Illustration)`
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
`

const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`
