import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useLocation, Navigate } from "react-router-dom"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { getErrorCodes } from "../../utils/form"
import { resetPasswordThunk } from "../../store/ducks/login.ducks"
import { sizes } from "../../styles/design.config"
import { Alert } from "../../components/Commons/Alert"
import { Background } from "../../components/Background"
import styled from "styled-components/macro"
import { ReactComponent as PasswordSVG } from "../../assets/illustration_password.svg"

const UserResetPassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation() as unknown as Location

  const searchUrl = new URLSearchParams(location.search)
  const token = searchUrl.get("reset_password_token") || ""

  const schema = yup.object({
    password: yup
      .string()
      .min(8, "length")
      .matches(RegExp(/^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).*$/gm), "format"),
    confirmPassword: yup.string(),
  })
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const password = watch("password")
  const confirmPassword = watch("confirmPassword")

  const handlePassword = () => {
    if (token !== "") {
      dispatch(resetPasswordThunk(password, confirmPassword, token))
    }
  }

  const resetPasswordStatus = useRNBSelector((state) => {
    return state.login.resetPasswordStatus
  })

  const passwordErrors = getErrorCodes(errors, password, confirmPassword)

  return (
    <>
      {token === "" ? (
        <Navigate to={"/unauthorized"} />
      ) : (
        <Background>
          <Ct.Title text={intl.formatMessage({ id: "reset-password.title" })} />
          <Ct.Spacer height={10} />
          <RowStyle>
            <PasswordSVG height={300} />
            <Ct.Spacer width={10} />
            <Ct.Spacer width={10} />
            <Ct.Card>
              <Ct.Spacer height={2} />
              <Ct.StyledForm onSubmit={handleSubmit(handlePassword)}>
                {resetPasswordStatus === "ERROR" ? (
                  <>
                    <Ct.PasswordInput
                      borderRadius={1.25}
                      name="password"
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      label={intl.formatMessage({
                        id: "reset-password.password",
                      })}
                      type="password"
                      value={password}
                      showError={resetPasswordStatus === "ERROR"}
                    />
                    <Ct.Spacer height={4} />
                    <Ct.PasswordInput
                      borderRadius={1.25}
                      name="confirmPassword"
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      label={intl.formatMessage({
                        id: "reset-password.confirm-password",
                      })}
                      type="password"
                      value={confirmPassword}
                      showError={resetPasswordStatus === "ERROR"}
                    />
                    <Ct.Spacer height={4} />
                    <Alert alertType="error">
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "reset-password.failure-token",
                        })}
                        textStyle={{ fontWeight: 700, color: "amaranth" }}
                      />
                    </Alert>
                    <Ct.Spacer height={4} />
                    <Ct.Button
                      width={sizes.button.standard}
                      disabled={
                        !isValid ||
                        (password !== confirmPassword && password !== "")
                      }
                      type="submit"
                      label={intl.formatMessage({ id: "reset-password.cta" })}
                    />
                  </>
                ) : resetPasswordStatus === "SUCCESS" ? (
                  <Navigate to={"/user/login"} />
                ) : (
                  <>
                    <Ct.PasswordInput
                      borderRadius={1.25}
                      name="password"
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      label={intl.formatMessage({
                        id: "reset-password.password",
                      })}
                      type="password"
                      value={password}
                      showWarning={
                        passwordErrors.includes("format") ||
                        passwordErrors.includes("length")
                      }
                    />
                    <Ct.Spacer height={4} />
                    <Ct.PasswordInput
                      borderRadius={1.25}
                      name="confirmPassword"
                      register={
                        register as unknown as UseFormRegister<FieldValues>
                      }
                      label={intl.formatMessage({
                        id: "reset-password.confirm-password",
                      })}
                      type="password"
                      value={confirmPassword}
                      showError={
                        passwordErrors.includes("mismatch") &&
                        confirmPassword.length
                      }
                    />
                    <Ct.Spacer height={4} />
                    {passwordErrors.length > 0 && (
                      <>
                        <Alert alertType="bulb" centerIcon={false}>
                          {passwordErrors.map((passwordError) => (
                            <>
                              <Ct.Text
                                text={intl.formatMessage({
                                  id: `password-errors.${passwordError}`,
                                })}
                                textStyle={{ lineHeight: 3 }}
                              />
                            </>
                          ))}
                        </Alert>
                        <Ct.Spacer />
                      </>
                    )}
                    <Ct.Button
                      width={sizes.button.standard}
                      disabled={
                        !isValid ||
                        (password !== confirmPassword && password !== "")
                      }
                      type="submit"
                      label={intl.formatMessage({ id: "reset-password.cta" })}
                    />
                  </>
                )}
              </Ct.StyledForm>
            </Ct.Card>
          </RowStyle>
        </Background>
      )}
    </>
  )
}

export default UserResetPassword

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
`
