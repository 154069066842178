import { UsersButton } from "../UsersButton"
import * as Ct from "ldlj"
import { LogoutButton } from "../LogoutButton"
import styled from "styled-components/macro"
import { useRNBSelector } from "../../store/rootReducer"

export const ButtonsHeader = () => {
  const userTypology = useRNBSelector((state) => state.user.typology)

  return (
    <StyledButtonsHeader>
      {userTypology !== "customer" &&
        userTypology !== "customer_accountant" && <UsersButton />}
      <Ct.Spacer width={3} />
      <LogoutButton />
    </StyledButtonsHeader>
  )
}

const StyledButtonsHeader = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`
