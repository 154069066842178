import { colors } from "../styles/design.config"
import styled, { css } from "styled-components/macro"

const SpotNotif = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors.white};
  height: 2rem;
  width: 2rem;
  font-size: 1.25rem;
  font-family: inter, sans-serif;
  background-color: ${colors.amaranth};
  color: ${colors.white};
  position: relative;
  font-weight: 700;
  user-select: none;
`

export const SpotNotifLeft = styled.div`
  ${SpotNotif}
  right: 2rem;
  bottom: 1rem;
`

export const SpotNotifRight = styled.div`
  ${SpotNotif}
  left: 5.25rem;
  bottom: 1rem;
`
export const SpotNotifCenter = styled.div`
  ${SpotNotif}
  left: 10px;
`

export const SpotNotifBottom = styled.div`
  ${SpotNotif}
  left: 10px;
  bottom: -10px;
`
