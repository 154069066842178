import { TeamMember } from "../model/users"
import { useIntl } from "react-intl"
import { ReactComponent as Edit } from "../assets/edit.svg"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useRNBSelector } from "../store/rootReducer"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  NotificationTimeframe,
  setNotificationsTimeframeThunk,
} from "../store/ducks/team.ducks"
import { Select } from "./Commons/Select"

/* eslint-disable camelcase */

export const UserRow = ({
  user,
  onEdit,
}: {
  user: TeamMember
  onEdit: () => void
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [notificationTimeFrame, setTimeFrame] = useState<Ct.Option<string>>({
    value: "",
    label: "",
  })
  const userTypology = useRNBSelector((state) => state.user.typology)
  const userID = useRNBSelector((state) => state.user.id)

  const options: Ct.OptionList<string> = [
    { value: "weekly", label: "Hebdomadaire" },
    { value: "daily", label: "Quotidien" },
    { value: "none", label: "Aucune" },
  ]

  useEffect(() => {
    if (notificationTimeFrame?.value !== "") {
      dispatch(
        setNotificationsTimeframeThunk({
          preferences: notificationTimeFrame.value as NotificationTimeframe,
          userId: String(user.id),
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTimeFrame])

  return (
    <User>
      <IDZone>
        <Ct.Row>
          <Ct.Text
            text={user?.first_name}
            textStyle={{ fontWeight: 700, fontSize: 2 }}
          />
          <Ct.Spacer width={0.5} />
          <Ct.Text
            text={user.last_name || ""}
            textStyle={{ fontWeight: 700, fontSize: 2 }}
          />
        </Ct.Row>
        <Ct.Text text={user.email} textStyle={{ fontFamily: "Poppins" }} />
      </IDZone>
      <SettingsZone>
        <Ct.Text
          text={intl.formatMessage({ id: `office.${user.typology}` })}
          textStyle={{ fontSize: 2 }}
        />
        <Ct.Button
          label={intl.formatMessage({ id: `office.edit-access` })}
          textTransform={"uppercase"}
          colorType={"Tertiary"}
          prefix={<Edit />}
          width={20}
          disabled={
            !AccessPermissions?.[userTypology]?.[user.typology] &&
            user.id !== userID
          }
          onClick={onEdit}
          colorScheme={{
            background: "mist",
            color: "cornflower",
            border: "mist",
          }}
        />
        <Select
          intl={intl}
          options={options}
          value={notificationTimeFrame}
          onChangeCallback={(e: Ct.Option<string>) => {
            setTimeFrame(e)
          }}
          label={"preferences"}
          domain={"office"}
          optionType={"timeframe"}
          disabled={
            !NotificationsPermissions?.[userTypology]?.[user.typology] &&
            userID !== user.id
          }
          defaultValue={options.find(
            (option) => option.value === user.notifications_preferences
          )}
        />
      </SettingsZone>
    </User>
  )
}

const User = styled((props) => <Ct.Row {...props} />)`
  display: flex;
  width: 100%;
`

const IDZone = styled((props) => <Ct.Column {...props} />)`
  justify-content: space-around;
  width: 30%;
`

const SettingsZone = styled((props) => <Ct.Row {...props} />)`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:first-child {
    width: 15%;
  }

  > *:nth-child(2) {
    width: 20rem;
  }

  > *:last-child {
    width: 20rem;
    align-self: flex-end;
    flex-grow: 0;
  }
`

type NotificationPermissionsType = {
  [key: string]: {
    [key: string]: boolean
  }
}

type AccessPermissionsType = NotificationPermissionsType

const AccessPermissions: AccessPermissionsType = {
  administrator: {
    customer: true,
    customer_accountant: true,
    collaborator: true,
    manager: true,
    administrator: false,
  },
  manager: {
    customer: true,
    customer_accountant: true,
    collaborator: true,
    manager: true,
    administrator: false,
  },
  collaborator: {
    customer: true,
    customer_accountant: false,
    collaborator: true,
    manager: false,
    administrator: false,
  },
} as const

const NotificationsPermissions: NotificationPermissionsType = {
  administrator: {
    customer: false,
    customer_accountant: false,
    collaborator: true,
    manager: true,
    administrator: false,
  },
  manager: {
    customer: false,
    customer_accountant: false,
    collaborator: true,
    manager: false,
    administrator: false,
  },
  collaborator: {
    customer: false,
    customer_accountant: false,
    collaborator: false,
    manager: false,
    administrator: false,
  },
} as const
