import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useState } from "react"
import styled from "styled-components/macro"
import { Select } from "../Commons/Select"

interface usersOptionListFormat {
  label: string
  value: string
}

interface AskUserValidationModalProps extends Ct.ModalComponentsProps {
  options: usersOptionListFormat[]
  onValidateClick: (selectedValue: string) => void
}

export const AskUserValidationModal = ({
  isDisplayed,
  onClose,
  options,
  onValidateClick,
}: AskUserValidationModalProps) => {
  const intl = useIntl()

  const [selectedValue, setSelectedValue] = useState<string>("")

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="10rem"
      right="10rem"
      top={"35%"}
      bottom={"35%"}
    >
      {isDisplayed && (
        <Ct.Card width="60%">
            <Ct.CloseCross
              onClick={() => {
                setSelectedValue("")
                onClose()
              }}
              />
            <Ct.Title
                text={intl.formatMessage({ id: "treasury.buy.to-control.ask_user_validation.modal" })}
                size={3}
            />
            <Ct.Spacer height={4} />
            <Ct.Separator size="full" color="mist" />
            <Ct.Spacer height={6} />

            <SelectWrapper>
                <Select
                  intl={intl}
                  value={{label: "", value: ""}}
                  domain={"treasury.buy.to-control.ask-validation"}
                  optionType={"select.user"}
                  options={options}
                  onChangeCallback={(e) => {
                    setSelectedValue(e.value)
                  }}
                />
            </SelectWrapper>
            <Ct.Spacer height={5} />
            <ButtonsWrapper>
                <Ct.Button
                  width={45}
                  label={intl.formatMessage({id: "treasury.buy.to-control.ask-validation.button.return"})}
                  colorType="Tertiary"
                  colorScheme={{
                    background: "mist",
                    color: "cornflower",
                    border: "mist"
                  }}
                  onClick={() => {
                    setSelectedValue("")
                    onClose()
                  }}
                />
                <Ct.Button
                  width={45}
                  label={intl.formatMessage({id: "treasury.buy.to-control.ask-validation.button.validate"})}
                  disabled={!selectedValue}
                  onClick={() => {
                      onValidateClick(selectedValue)
                      setSelectedValue("")
                      onClose()
                    }
                  }
                />
            </ButtonsWrapper>
            <Ct.Spacer height={5} />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const ButtonsWrapper = styled.div`
    width: 80%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const SelectWrapper = styled.div`
    width: 80%;
    display:flex;
    justify-content: center;
    overflow: visible;
`