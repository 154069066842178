import styled from "styled-components/macro"

export const StyledTopBar = styled.header`
  width: 100%;
  height: 8rem;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  align-items: center;
`
