import { useState, useEffect } from "react"
import { IntlShape } from "react-intl"
import { OnChangeValue } from "react-select"
import styled from "styled-components/macro"
import { Option, OptionList, Select } from "./Select"

export const SortButton = <T extends string>({
  sortMethod,
  options,
  defaultOption,
  shadowed = false,
  dataCy,
  intl,
  asc,
  height,
  reRender,
}: {
  sortMethod: (option: T) => void
  options: OptionList<T>
  defaultOption: Option<T>
  shadowed?: boolean
  dataCy?: string
  intl: IntlShape
  asc?: boolean
  height?: string
  reRender?: boolean
}) => {
  const [selection, setSelection] = useState<Option<T>>(defaultOption)

  useEffect(() => {
    if (reRender) {
      setSelection(defaultOption)
    }
  }, [defaultOption, reRender])

  return (
    <DropShadowContainer id={"sortButton"}>
      <Select
        selectType={"sort"}
        options={options}
        intl={intl}
        value={selection}
        defaultValue={options.find(
          (option) => option.value === defaultOption.value
        )}
        onChangeCallback={(e: OnChangeValue<Option<string>, false>) => {
          if (e) {
            sortMethod(e.value as T)
            setSelection(e as Option<T>)
          }
        }}
        shadowed={shadowed}
        dataCy={dataCy}
        asc={asc}
        height={height}
      />
    </DropShadowContainer>
  )
}

const DropShadowContainer = styled.div`
  filter: drop-shadow(0px 4px 14px rgba(2, 76, 248, 0.1));

  border-radius: 1.25rem;
  z-index: 1;
`
