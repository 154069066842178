import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { getIdFromParams } from "../../utils/company"
import {
  VatAccountPayload,
  createAccountResetAction,
  getVatAccountSettingsThunk,
  VatAssignMode,
} from "../../store/ducks/companySettings.ducks"
import { TabObject, TabsWithContent } from "../TabsWithContent"
import { Select } from "../Commons/Select"
import { CreateVatAccountModal } from "./CreateVatAccountModal"
import { useRNBSelector } from "../../store/rootReducer"

import { ReactComponent as Plus } from "../../assets/plus.svg"
import { colors } from "../../styles/design.config"

/* eslint-disable camelcase */
interface VatAccountsState {
  unique: {
    buy: number | null
    sell: number | null
  }
  perRate: VatAccountPayload[]
}

interface VatAccountProps {
  onAccountsUpdated: ({
    disabled,
    accounts,
  }: {
    disabled: boolean
    accounts: VatAccountPayload[]
    tabSelected: VatAssignMode
  }) => void
}
export const VatAccounts = ({ onAccountsUpdated }: VatAccountProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    createdAccount,
    rates,
    possibleBuyAccounts,
    possibleSellAccounts,
    selectedAccounts,
    assignMode,
    accountNumberLimit,
    construction_vat,
    construction_reverse_vat,
    eu_vat,
    eu_reverse_vat,
    eu_goods_vat,
    eu_goods_reverse_vat,
    world_vat,
    world_reverse_vat,
    world_goods_vat,
    world_goods_reverse_vat,
  } = useRNBSelector((state) => state.companySettings)

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  useEffect(() => {
    dispatch(getVatAccountSettingsThunk({ companyId: selectedCompanyId }))
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (createdAccount) {
      if (createdAccount.vatRateId === null) {
        const newAccounts = {
          ...accounts,
          unique: {
            ...accounts.unique,
            sell:
              createdAccount.buyOrSell === "sell"
                ? createdAccount.id
                : accounts.unique.sell,
            buy:
              createdAccount.buyOrSell === "buy"
                ? createdAccount.id
                : accounts.unique.buy,
          },
        }
        setAccounts(newAccounts)
      } else {
        const newAccounts = {
          ...accounts,
          perRate: accounts.perRate.map((perRateAccount) => {
            if (
              perRateAccount.buy_or_sell === createdAccount.buyOrSell &&
              perRateAccount.vat_rate_id === createdAccount.vatRateId
            ) {
              return {
                ...perRateAccount,
                account_id: createdAccount.id,
              }
            }
            return {
              ...perRateAccount,
            }
          }),
        }
        setAccounts(newAccounts)
      }
      dispatch(createAccountResetAction())
    }
  }, [createdAccount])

  const [isSellingAccountsRemoved, setIsSellingAccountsRemoved] =
    useState<boolean>(false)
  const [initialIsSellingAccountsRemoved, setInitialIsSellingAccountsRemoved] =
    useState<boolean>(false)

  const [accounts, setAccounts] = useState<VatAccountsState>({
    unique: {
      buy: null,
      sell: null,
    },
    perRate: [],
  })
  const [initialAccounts, setInitialAccounts] = useState<VatAccountsState>({
    unique: {
      buy: null,
      sell: null,
    },
    perRate: [],
  })

  const selectedAccountsArray = [
    eu_reverse_vat?.selected_account?.id,
    eu_vat?.selected_account?.id,
    eu_goods_reverse_vat?.selected_account?.id,
    eu_goods_vat?.selected_account?.id,
    world_reverse_vat?.selected_account?.id,
    world_vat?.selected_account?.id,
    world_goods_reverse_vat?.selected_account?.id,
    world_goods_vat?.selected_account?.id,
    construction_vat?.selected_account?.id,
    construction_reverse_vat?.selected_account?.id,
  ]

  const sellOptions = possibleSellAccounts
    .filter((account) => !selectedAccountsArray.includes(account.id))
    .map((account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    }))

  const buyOptions = possibleBuyAccounts
    .filter((account) => !selectedAccountsArray.includes(account.id))
    .map((account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    }))

  useEffect(() => {
    const perRateAccounts = rates
      .sort((a, b) => Number(a.rate) - Number(b.rate))
      .map((rate) => {
        const existingPerRateBuyAccount = selectedAccounts.find(
          (acc) => acc.vatRateId === rate.id && acc.buyOrSell === "buy"
        )

        const existingPerRateSellAccount = selectedAccounts.find(
          (acc) => acc.vatRateId === rate.id && acc.buyOrSell === "sell"
        )
        const accounts: VatAccountPayload[] = [
          {
            company_id: selectedCompanyId,
            account_id: existingPerRateBuyAccount
              ? existingPerRateBuyAccount.accountId
              : null,
            vat_rate_id: rate.id,
            buy_or_sell: "buy",
            vat_accounting: "per_rate",
          },
          {
            company_id: selectedCompanyId,
            account_id: existingPerRateSellAccount
              ? existingPerRateSellAccount.accountId
              : null,
            vat_rate_id: rate.id,
            buy_or_sell: "sell",
            vat_accounting: "per_rate",
          },
        ]
        return accounts
      })
      .flat()

    const uniqueBuyAccountId = selectedAccounts.find(
      (acc) => acc.buyOrSell === "buy" && acc.vatAccounting === "unique"
    )?.accountId

    const uniqueBuyAccountNumber =
      uniqueBuyAccountId &&
      possibleBuyAccounts.find((acc) => acc.id === uniqueBuyAccountId)?.id

    const uniqueSellAccountId = selectedAccounts.find(
      (acc) => acc.buyOrSell === "sell" && acc.vatAccounting === "unique"
    )?.accountId

    const uniqueSellAccountNumber =
      uniqueBuyAccountId &&
      possibleSellAccounts.find((acc) => acc.id === uniqueSellAccountId)?.id

    const accounts = {
      unique: {
        buy: uniqueBuyAccountNumber || null,
        sell: uniqueSellAccountNumber || null,
      },
      perRate: perRateAccounts,
    }
    setAccounts(accounts)
    setInitialAccounts(accounts)

    const preCheck =
      (assignMode === "per_rate" &&
        accounts.perRate.every((acc) => {
          return acc.buy_or_sell === "buy" || acc.account_id === null
        })) ||
      (assignMode === "unique" && accounts.unique.sell === null)

    setIsSellingAccountsRemoved(preCheck)
    setInitialIsSellingAccountsRemoved(preCheck)

    setTabSelected(assignMode)
    setInitialAssignMode(assignMode)
  }, [selectedAccounts])

  const [isModalDisplayed, setIsModalDisplayed] = useState(false)
  const [vatAccountToCreate, setVatAccountToCreate] =
    useState<VatAccountPayload | null>(null)
  const [vatRateToCreate, setVatRateToCreate] = useState<string | null>(null)

  const [tabSelected, setTabSelected] = useState<VatAssignMode>("no_vat")
  const [initialAssignMode, setInitialAssignMode] =
    useState<VatAssignMode>("no_vat")

  const Tabs: TabObject[] = [
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.no-vat",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "no_vat",
      onClick: () => {
        setTabSelected("no_vat")
      },
    },
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.unique",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "unique",
      onClick: () => {
        setTabSelected("unique")
      },
    },
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.per-rate",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "per_rate",
      onClick: () => {
        setTabSelected("per_rate")
      },
    },
  ]

  useEffect(() => {
    const isUniqueUpdateDisabled = Boolean(
      isSellingAccountsRemoved
        ? !accounts.unique.buy
        : !accounts.unique.buy || !accounts.unique.sell
    )
    const isPerRateUpdateDisabled = isSellingAccountsRemoved
      ? !accounts.perRate
          .filter((acc) => acc.buy_or_sell === "buy")
          .every((acc) => acc.account_id)
      : !accounts.perRate.every((acc) => acc.account_id)

    const accountsChanged =
      JSON.stringify(accounts) !== JSON.stringify(initialAccounts)
    const tabChanged = tabSelected !== initialAssignMode

    const isSellingAccountsRemovedChanged =
      initialIsSellingAccountsRemoved !== isSellingAccountsRemoved

    const disabled =
      (tabSelected === "per_rate" && isPerRateUpdateDisabled) ||
      (tabSelected === "unique" && isUniqueUpdateDisabled) ||
      (!accountsChanged && !tabChanged && !isSellingAccountsRemovedChanged)

    const uniqueAccounts: VatAccountPayload[] = [
      {
        company_id: selectedCompanyId,
        account_id: Number(accounts.unique.buy),
        vat_rate_id: null,
        buy_or_sell: "buy",
        vat_accounting: "unique",
      },
      {
        company_id: selectedCompanyId,
        account_id: Number(accounts.unique.sell),
        vat_rate_id: null,
        buy_or_sell: "sell",
        vat_accounting: "unique",
      },
    ]
    const accountsToUpdate =
      tabSelected === "no_vat"
        ? []
        : tabSelected === "unique"
        ? uniqueAccounts.filter(
            (acc) => !isSellingAccountsRemoved || acc.buy_or_sell === "buy"
          )
        : tabSelected === "per_rate"
        ? accounts.perRate.filter(
            (acc) => !isSellingAccountsRemoved || acc.buy_or_sell === "buy"
          )
        : []

    onAccountsUpdated({
      accounts: accountsToUpdate,
      disabled,
      tabSelected,
    })
  }, [accounts, tabSelected, isSellingAccountsRemoved])

  return (
    <>
      <CreateVatAccountModal
        isDisplayed={isModalDisplayed}
        onClose={() => {
          setIsModalDisplayed(false)
          setVatAccountToCreate(null)
          setVatRateToCreate(null)
        }}
        vatAccountToCreate={
          vatAccountToCreate || {
            vat_accounting: "unique",
            buy_or_sell: "buy",
            vat_rate_id: null,
            company_id: selectedCompanyId,
            account_id: null,
          }
        }
        vatRateToCreate={vatRateToCreate}
        accountNumberLimit={accountNumberLimit}
      />

      <Container>
        <Ct.Text
          text={intl.formatMessage({
            id: "office-company-settings.vat-accounts-info",
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer height={3} />
        <TabsWithContent tabs={Tabs} />
        <Ct.Spacer height={3} />

        <BorderedContainer>
          {tabSelected === "no_vat" && (
            <>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-settings.vat-modal.no-vat",
                })}
              />
            </>
          )}
          {tabSelected === "unique" && (
            <>
              <Ct.Spacer />
              <Ct.Checkbox
                onChange={() =>
                  setIsSellingAccountsRemoved(!isSellingAccountsRemoved)
                }
                name="isSellingAccountsRemoved"
                isChecked={isSellingAccountsRemoved}
                label={intl.formatMessage({
                  id: "office-company-settings.differentiated-vat",
                })}
              />
              <Ct.Spacer />

              <div>
                {intl.formatMessage({
                  id: "office-company-settings.vat-accounts.buy",
                })}
              </div>
              <Ct.Spacer height={1} />
              <Ct.Row>
                <Select
                  intl={intl}
                  disabled={false}
                  value={
                    buyOptions.find(
                      (option) =>
                        option.value === accounts.unique.buy?.toString()
                    ) || { label: "", value: "" }
                  }
                  options={buyOptions}
                  domain={"office-company-settings.vat-accounts"}
                  optionType={"buy-account"}
                  onChangeCallback={(selectedAccount) => {
                    setAccounts({
                      ...accounts,
                      unique: {
                        ...accounts.unique,
                        buy: Number(selectedAccount.value),
                      },
                    })
                  }}
                  error={accounts.unique.buy === null}
                />
                <Ct.Spacer />
                <Ct.Button
                  label={intl.formatMessage({
                    id: "office-company-settings.vat-accounts.create",
                  })}
                  onClick={() => {
                    setVatAccountToCreate({
                      vat_accounting: "unique",
                      buy_or_sell: "buy",
                      vat_rate_id: null,
                      company_id: selectedCompanyId,
                      account_id: null,
                    })
                    setIsModalDisplayed(true)
                  }}
                  width={22}
                  prefix={<Plus />}
                />
              </Ct.Row>

              {!isSellingAccountsRemoved && (
                <>
                  <Ct.Spacer />
                  <div>
                    {intl.formatMessage({
                      id: "office-company-settings.vat-accounts.sell",
                    })}
                  </div>
                  <Ct.Spacer height={1} />
                  <Ct.Row>
                    <Select
                      intl={intl}
                      disabled={false}
                      value={
                        sellOptions.find(
                          (option) =>
                            option.value === accounts.unique.sell?.toString()
                        ) || { label: "", value: "" }
                      }
                      options={sellOptions}
                      domain={"office-company-settings.vat-accounts"}
                      optionType={"buy-account"}
                      onChangeCallback={(selectedAccount) => {
                        setAccounts({
                          ...accounts,
                          unique: {
                            ...accounts.unique,
                            sell: Number(selectedAccount.value),
                          },
                        })
                      }}
                      error={accounts.unique.sell === null}
                    />
                    <Ct.Spacer />
                    <Ct.Button
                      label={intl.formatMessage({
                        id: "office-company-settings.vat-accounts.create",
                      })}
                      onClick={() => {
                        setVatAccountToCreate({
                          vat_accounting: "unique",
                          buy_or_sell: "sell",
                          vat_rate_id: null,
                          company_id: selectedCompanyId,
                          account_id: null,
                        })
                        setIsModalDisplayed(true)
                      }}
                      width={22}
                      prefix={<Plus />}
                    />
                  </Ct.Row>
                </>
              )}
            </>
          )}
          {tabSelected === "per_rate" && (
            <>
              <Ct.Spacer />
              <Ct.Checkbox
                onChange={() =>
                  setIsSellingAccountsRemoved(!isSellingAccountsRemoved)
                }
                name="isSellingAccountsRemoved"
                isChecked={isSellingAccountsRemoved}
                label={intl.formatMessage({
                  id: "office-company-settings.differentiated-vat",
                })}
              />
              {rates
                .sort((a, b) => Number(a.rate) - Number(b.rate))
                .map((rate) => (
                  <div key={rate.id.toString()}>
                    <Ct.Spacer height={4} />
                    <div>
                      {intl.formatMessage(
                        {
                          id: "office-company-settings.vat-accounts.buy-rate",
                        },
                        { rate: rate.rate }
                      )}
                    </div>
                    <Ct.Spacer height={1} />
                    <Ct.Row>
                      <Select
                        intl={intl}
                        disabled={false}
                        value={
                          buyOptions.find(
                            (option) =>
                              option.value ===
                              accounts.perRate
                                .find(
                                  (acc) =>
                                    acc.buy_or_sell === "buy" &&
                                    acc.vat_rate_id === rate.id
                                )
                                ?.account_id?.toString()
                          ) || { label: "", value: "" }
                        }
                        options={buyOptions}
                        domain={"office-company-settings.vat-accounts"}
                        optionType={"buy-account"}
                        onChangeCallback={(selectedAccount) => {
                          setAccounts({
                            ...accounts,
                            perRate: accounts.perRate.map((perRate) => {
                              if (
                                perRate.vat_rate_id === rate.id &&
                                perRate.buy_or_sell === "buy"
                              ) {
                                return {
                                  ...perRate,
                                  account_id: Number(selectedAccount.value),
                                }
                              }
                              return perRate
                            }),
                          })
                        }}
                        error={
                          accounts.perRate.find(
                            (r) => r.vat_rate_id === rate.id
                          )?.account_id === null
                        }
                      />
                      <Ct.Spacer />
                      <Ct.Button
                        label={intl.formatMessage({
                          id: "office-company-settings.vat-accounts.create",
                        })}
                        onClick={() => {
                          setVatAccountToCreate({
                            vat_accounting: "per_rate",
                            buy_or_sell: "buy",
                            vat_rate_id: rate.id,
                            company_id: selectedCompanyId,
                            account_id: null,
                          })
                          setVatRateToCreate(rate.rate)
                          setIsModalDisplayed(true)
                        }}
                        width={22}
                        prefix={<Plus />}
                      />
                    </Ct.Row>

                    {!isSellingAccountsRemoved && (
                      <>
                        <Ct.Spacer height={1} />
                        <div>
                          {intl.formatMessage(
                            {
                              id: "office-company-settings.vat-accounts.sell-rate",
                            },
                            { rate: rate.rate }
                          )}
                        </div>
                        <Ct.Spacer height={1} />
                        <Ct.Row>
                          <Select
                            intl={intl}
                            disabled={false}
                            value={
                              sellOptions.find(
                                (option) =>
                                  option.value ===
                                  accounts.perRate
                                    .find(
                                      (acc) =>
                                        acc.buy_or_sell === "sell" &&
                                        acc.vat_rate_id === rate.id
                                    )
                                    ?.account_id?.toString()
                              ) || { label: "", value: "" }
                            }
                            options={sellOptions}
                            domain={"office-company-settings.vat-accounts"}
                            optionType={"buy-account"}
                            onChangeCallback={(selectedAccount) => {
                              setAccounts({
                                ...accounts,
                                perRate: accounts.perRate.map((perRate) => {
                                  if (
                                    perRate.vat_rate_id === rate.id &&
                                    perRate.buy_or_sell === "sell"
                                  ) {
                                    return {
                                      ...perRate,
                                      account_id: Number(selectedAccount.value),
                                    }
                                  }
                                  return perRate
                                }),
                              })
                            }}
                            error={
                              accounts.perRate.find(
                                (r) => r.vat_rate_id === rate.id
                              )?.account_id === null
                            }
                          />
                          <Ct.Spacer />
                          <Ct.Button
                            label={intl.formatMessage({
                              id: "office-company-settings.vat-accounts.create",
                            })}
                            onClick={() => {
                              setVatAccountToCreate({
                                vat_accounting: "per_rate",
                                buy_or_sell: "sell",
                                vat_rate_id: rate.id,
                                company_id: selectedCompanyId,
                                account_id: null,
                              })
                              setVatRateToCreate(rate.rate)
                              setIsModalDisplayed(true)
                            }}
                            width={22}
                            prefix={<Plus />}
                          />
                        </Ct.Row>
                      </>
                    )}
                  </div>
                ))}
              <Ct.Spacer />
            </>
          )}
          <Ct.Spacer height={1} />
        </BorderedContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 0 6rem;
`
const BorderedContainer = styled.div`
  border: 1px solid ${colors.cornflower};
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
`
