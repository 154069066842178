import * as Ct from "ldlj"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import {
  getAllInvoicesThunk,
  InvoiceBlock,
  InvoicePayload,
} from "../../../../store/ducks/invoicing.duck"
import { ReminderModal } from "./ReminderModal"
import { InvoicingDocumentHistoryModal } from "./InvoicingDocumentHistoryModal"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useIntl } from "react-intl"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { ReactComponent as ChevronRight } from "../../../../assets/chevron-right-navy.svg"
import { Alert } from "../../../../components/Commons/Alert"
import { displayPreviewParams } from "../../../../components/dropDocuments/BatchDocumentPreviewModal"
import { resetBatchDocumentCreatedForInvoiceId } from "../../../../store/ducks/invoicing.duck"
import {
  sortInvoicesByClient,
  sortInvoicesByDate,
  sortInvoicesByNumber,
  sortInvoicesByIssueBy,
  sortInvoicesByAmount,
  sortInvoicesByEmailSentTo,
  isColoredLine,
} from "../../../../utils/invoicing"
import { sortInvoicesBySearch } from "../../../../utils/invoices"
import { Modals } from "../../../../components/dropDocuments/Modals"
import { useLocation } from "react-router-dom"
import { Type } from "react-tooltip"
import { getBatchesForCompanyThunk } from "../../../../store/ducks/batchDocuments.ducks"

import {
  TitleTable,
  SortToReturn,
  ClickableTitleSort,
  ChevronProps,
} from "../../../../components/Commons/Table"
import { HeaderTitleWrapper } from "../../../../components/dropDocuments/StyledDropsComponents"
import { SelectLikeButton } from "../../../../components/treasury/SelectLikeButton"

import { InvoiceDocumentType } from "../../../../utils/invoices"

interface InvoiceDocumentProps {
  documentType?: InvoiceDocumentType
}

enum SortOptionsValues {
  "number",
  "sentToEmail",
  "date",
  "amount",
  "client",
  "issueBy",
}

export const InvoiceDocuments = ({
  documentType = "all",
}: InvoiceDocumentProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const params = useParams()
  const navigate = useNavigate()

  const selectedCompanyId = getIdFromParams(params)("company_id")

  const invoices = useRNBSelector((state) => state.invoicing.allInvoices)

  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [asc, setAsc] = useState(true)
  const [sortCriteria, setSortCriteria] = useState<SortOptionsValues>(
    SortOptionsValues.date
  )
  const [search, setSearch] = useState("")
  const [showActions, setShowActions] = useState(false)
  const [invoicesToDisplay, setInvoicesToDisplay] = useState<InvoicePayload[]>(
    []
  )
  const [displayAcceptedTooltip, setDisplayAcceptedTooltip] = useState(false)
  const [displayOfficeAccepted, setDisplayOfficeAccepted] = useState(false)
  const [renamedBatchDocumentId, setRenamedBatchDocumentId] = useState<
    number | null
  >(null)

  const [displayDetails, setDisplayDetails] = useState<string | undefined>(
    undefined
  )
  const [displayPreview, setDisplayPreview] = useState<displayPreviewParams>({
    isDisplayed: false,
    batchDocumentId: null,
    companyId: selectedCompanyId,
    elementName: "",
    displayInvoice: false,
  })

  const acceptedFormats = useRNBSelector(
    (state) => state.dropDocuments.acceptedBatchesFormats
  )

  const batchDocumentCreatedForInvoiceId = useRNBSelector(
    (state) => state.invoicing.batchDocumentCreatedForInvoiceId
  )

  const location = useLocation()
  const state = location.state as Type
  const titleName =
    String(state) === String("quotation")
      ? "Votre devis"
      : String(state) === String("creditNote")
      ? "Votre avoir"
      : String(state) === String("invoicing")
      ? "Votre facture"
      : "Votre document"

  useEffect(() => {
    if (batchDocumentCreatedForInvoiceId) {
      setDisplayPreview({
        isDisplayed: true,
        batchDocumentId: batchDocumentCreatedForInvoiceId,
        companyId: selectedCompanyId,
        elementName: titleName,
        displayInvoice: true,
      })
      dispatch(resetBatchDocumentCreatedForInvoiceId())
    }
  }, [batchDocumentCreatedForInvoiceId])

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getAllInvoicesThunk(selectedCompanyId))
      setInvoicesToDisplay([])
      dispatch(
        getBatchesForCompanyThunk({
          companyId: selectedCompanyId,
        })
      )
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (invoices) {
      const allInvoices: InvoiceBlock[] = Object.entries(invoices).map(
        ([e, f]: [string, {}]) => {
          return Object(f)
        }
      )

      const invoicesNotNull = addExtendedLinesToDisplay(allInvoices)
      setInvoicesToDisplay(invoicesNotNull)
    }
  }, [invoices, documentType, search, sortCriteria, asc])

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setAsc(!asc)
    setSortCriteria(option)
  }

  const [actionId, setActionId] = useState<number | null>(null)

  const openActions = (invoicingDocumentId: number) => {
    const newShowAction = actionId !== invoicingDocumentId ? true : !showActions
    setShowActions(newShowAction)
    setActionId(invoicingDocumentId)
  }

  const addExtendedLinesToDisplay = (allInvoices: InvoiceBlock[]) => {
    allInvoices = filterInvoicesByDocumentType(allInvoices)
    const invoices: InvoicePayload[] = []
    if (sortCriteria === SortOptionsValues?.number) {
      allInvoices = sortInvoicesByNumber(allInvoices, asc)
    } else if (sortCriteria === SortOptionsValues?.sentToEmail) {
      allInvoices = sortInvoicesByEmailSentTo(allInvoices, asc)
    } else if (sortCriteria === SortOptionsValues?.client) {
      allInvoices = sortInvoicesByClient(allInvoices, asc)
    } else if (sortCriteria === SortOptionsValues?.date) {
      allInvoices = sortInvoicesByDate(allInvoices, asc)
    } else if (sortCriteria === SortOptionsValues?.issueBy) {
      allInvoices = sortInvoicesByIssueBy(allInvoices, asc)
    } else if (sortCriteria === SortOptionsValues?.amount) {
      allInvoices = sortInvoicesByAmount(allInvoices, asc)
    }
    allInvoices.forEach((line) => {
      if (line.status === "extended" && line.invoices.length > 1) {
        const invoice =
          documentType === "all"
            ? line.invoices[0]
            : line.invoices.find(
                (invoice) => invoice.documentType === documentType
              ) || line.invoices[0]
        invoices.push({
          id: invoice.id,
          numbering: invoice.numbering,
          date: invoice.date,
          dueDate: invoice.dueDate,
          amount: "",
          client: "",
          emailedTo: invoice.emailedTo,
          issueBy: "",
          documentType: "",
          overtakingId: invoice.overtakingId,
          linkedDocumentsNumber: line.invoices.length,
          batchDocumentId: invoice.batchDocumentId,
        })
        if (line.invoices.length > 2) invoices.push(line.invoices[2])
        invoices.push(line.invoices[1])
        invoices.push({ ...line.invoices[0], isLastInvoiceOfGroup: true })
      } else if (line.invoices.length > 1) {
        const invoice =
          documentType === "all"
            ? line.invoices[0]
            : line.invoices.find(
                (invoice) => invoice.documentType === documentType
              ) || line.invoices[0]
        invoices.push({
          id: invoice.id,
          numbering: invoice.numbering,
          date: invoice.date,
          dueDate: invoice.dueDate,
          amount: "",
          client: "",
          issueBy: "",
          emailedTo: [],
          documentType: line.invoices[line.invoices.length - 1].documentType,
          overtakingId: line.invoices[line.invoices.length - 1].overtakingId,
          linkedDocumentsNumber: line.invoices.length,
          batchDocumentId: invoice.batchDocumentId,
          isLastInvoiceOfGroup: true,
        })
      } else {
        invoices.push(line.invoices[0])
      }
    })
    return sortInvoicesBySearch(invoices, search)
  }

  const filterInvoicesByDocumentType = (allInvoices: InvoiceBlock[]) => {
    return documentType === "all"
      ? allInvoices
      : allInvoices.filter((line) =>
          line.invoices.some((subItem) => subItem.documentType === documentType)
        )
  }

  const openOrCloseLine = (line: InvoicePayload) => {
    const allInvoices = Object.entries(invoices).map(
      ([e, f]: [string, InvoiceBlock]) => {
        if (f.invoices.find((invoice) => invoice.id === line.id)) {
          f.status === "extended"
            ? (f.status = "collapsed")
            : (f.status = "extended")
          return Object(f)
        } else {
          return Object(f)
        }
      }
    )
    setInvoicesToDisplay(addExtendedLinesToDisplay(allInvoices))
  }

  const columnWidth = [
    { width: "13" },
    { width: "15" },
    { width: "12" },
    { width: "18" },
    { width: "15" },
    { width: "18" },
    { width: "22" },
  ]

  const columnsTitle: {
    title: string
  }[] = [
    { title: "number" },
    { title: "emailedTo" },
    { title: "date" },
    { title: "amount" },
    { title: "client" },
    { title: "issue" },
    { title: "action" },
  ]

  const columnsConfig = columnWidth.map((widthConfig, index) => ({
    ...widthConfig,
    ...columnsTitle[index],
  }))

  const parametersOfModals = {
    selectedCompanyId,
    displayPreview,
    displayDetails,
    deactivatedBatchDocumentIdList: [],
    displayDeactivateModal: false,
    renamedBatchDocumentId,
    displayOfficeAccepted,
    displayAcceptedTooltip,
    acceptedFormats,
    setDisplayAcceptedTooltip,
    setDisplayOfficeAccepted,
    setRenamedBatchDocumentId,
    handleDeactivatedModalClose: () => {},
    setDisplayDeactivateModal: () => {},
    setDisplayDetails,
    setDisplayPreview,
  }

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        sorter(true)(1)
      }
    }
  }, [columnToSort])

  return (
    <>
      <Modals {...parametersOfModals} />
      <ReminderModal
        invoicingDocument={invoicesToDisplay.find((i) => i.id === actionId)}
        isDisplayed={showReminderModal}
        onClose={() => {
          setShowReminderModal(false)
        }}
      />
      <InvoicingDocumentHistoryModal
        invoicingDocument={invoicesToDisplay.find((i) => i.id === actionId)}
        isDisplayed={showHistoryModal}
        onClose={() => {
          setShowHistoryModal(false)
        }}
      />
      <WrapperTopBar>
        <Ct.Input
          label="Rechercher"
          value={search}
          suffix={<Search />}
          maxWidth={30}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          dataCy={"searchClient"}
          shadowed={true}
          noBorder={true}
          isSearch={true}
        />
      </WrapperTopBar>

      <Ct.Spacer height={3} />

      <TableWrapper>
        <StyledTableHeader>
          {columnsConfig.map(({ title, width }, index) => (
            <StyledHeaderTitleWrapper
              calculatedWidth={Number(width)}
              key={title}
            >
              {index < 5 ? (
                <ClickableTitleSort
                  tid={`invoicing.documents.header-${title}`}
                  intl={intl}
                  index={index}
                  sortToReturn={(column: SortToReturn) => {
                    setColumnToSort(column)
                  }}
                  currentChevron={currentChevron}
                />
              ) : (
                <TitleTable
                  tid={`invoicing.documents.header-${title}`}
                  intl={intl}
                />
              )}
            </StyledHeaderTitleWrapper>
          ))}
        </StyledTableHeader>
        {invoicesToDisplay && invoicesToDisplay.length > 0 ? (
          <StyledTableBody>
            {invoicesToDisplay.map((line, index) => {
              const hasLinkedDocuments = line?.linkedDocumentsNumber
                ? line?.linkedDocumentsNumber > 1
                : false

              const isColoredLineValue = isColoredLine(line, invoices)
              return (
                <Fragment key={index}>
                  <WrapperRows
                    isColoredLine={isColoredLineValue}
                    hasBorderBottom={
                      !isColoredLineValue || Boolean(line.isLastInvoiceOfGroup)
                    }
                    onClick={() =>
                      hasLinkedDocuments ? openOrCloseLine(line) : ""
                    }
                  >
                    <PaddingRow>
                      <StyledFlexNumber hasPadding={!isColoredLineValue}>
                        {isColoredLineValue && !hasLinkedDocuments && (
                          <DocumentsLineGroup
                            isLastInvoiceOfGroup={Boolean(
                              line.isLastInvoiceOfGroup
                            )}
                          />
                        )}
                        {hasLinkedDocuments && (
                          <ChevronRotate rotation={line.documentType === ""} />
                        )}
                        <Ct.Spacer width={1} />
                        <NumberingWrapper>
                          <StyledLink
                            isColoredLine={isColoredLineValue}
                            onClick={() => {
                              if (line.batchDocumentId) {
                                setDisplayPreview({
                                  isDisplayed: true,
                                  batchDocumentId: line.batchDocumentId,
                                  elementName: line.numbering,
                                  companyId: selectedCompanyId,
                                  displayInvoice: true,
                                })
                              }
                            }}
                          >
                            {line.numbering}
                          </StyledLink>
                          <Ct.Spacer height={0.5} />
                          <Ct.Row>
                            {hasLinkedDocuments ? (
                              <Ct.Text
                                text={
                                  line?.linkedDocumentsNumber?.toString() +
                                  " documents liés"
                                }
                                textStyle={{
                                  fontWeight: 400,
                                  textTransform: "initial",
                                  fontSize: 1.5,
                                  textAlign: "center",
                                }}
                              />
                            ) : (
                              <TypeWrapper isColoredLine={isColoredLineValue}>
                                {line.documentType === "invoice"
                                  ? "FACTURE"
                                  : line.documentType === "creditNote"
                                  ? "AVOIR"
                                  : "DEVIS"}
                              </TypeWrapper>
                            )}
                          </Ct.Row>
                        </NumberingWrapper>
                      </StyledFlexNumber>
                      <StyledFlex1 width={columnWidth[1].width}>
                        <Ct.Text
                          text={line.emailedTo.join("\n")}
                          textStyle={{
                            fontWeight: 400,
                            textTransform: "initial",
                            fontSize: 1.5,
                            textAlign: "center",
                          }}
                        />
                      </StyledFlex1>
                      <StyledFlex1 width={columnWidth[2].width}>
                        <Ct.Text
                          text={line.date.split(" - ")[0]}
                          textStyle={{
                            fontWeight: 400,
                            textTransform: "initial",
                            fontSize: 2,
                            textAlign: "center",
                          }}
                        />
                      </StyledFlex1>
                      <StyledFlex1 width={columnWidth[3].width}>
                        <Ct.Text
                          text={
                            hasLinkedDocuments
                              ? ""
                              : line.documentType === "creditNote"
                              ? "- " + line.amount + " €"
                              : line.amount + " €"
                          }
                          textStyle={{
                            fontWeight: 400,
                            textTransform: "initial",
                            fontSize: 2,
                            textAlign: "center",
                          }}
                        />
                      </StyledFlex1>
                      <StyledFlex1 width={columnWidth[4].width}>
                        <Ct.Text
                          text={line.client}
                          textStyle={{
                            fontWeight: 400,
                            textTransform: "initial",
                            fontSize: 2,
                            textAlign: "center",
                          }}
                        />
                      </StyledFlex1>
                      <StyledFlex1 width={columnWidth[5].width}>
                        <Ct.Text
                          text={line.issueBy}
                          textStyle={{
                            fontWeight: 400,
                            textTransform: "initial",
                            fontSize: 1.5,
                            textAlign: "center",
                          }}
                        />
                      </StyledFlex1>
                      <StyledFlex1 width={columnWidth[6].width}>
                        {hasLinkedDocuments || line.overtakingId ? (
                          ""
                        ) : (
                          <Ct.Cell>
                            {line.documentType === "quotation" && (
                              <SelectLikeButton
                                actionId={actionId || 0}
                                width={"26"}
                                fontSize={1.5}
                                showActions={showActions}
                                fullDocumentId={line.id} // not a fd but necessary for opening select
                                actionsToDisplay={[
                                  "reminder",
                                  "edit",
                                  "treasury_historic",
                                ]}
                                onClickAction={() => {
                                  navigate(
                                    `/office/company/${selectedCompanyId}/invoicing/issuance/quotationToInvoice`,
                                    {
                                      state: line.id,
                                    }
                                  )
                                }}
                                label="more-actions.overtakingToInvoice"
                                disabledButton={false}
                                disabledSelect={false}
                                onClickSelectAction={() => {
                                  openActions(line.id)
                                }}
                                onSetReminder={() => {
                                  setShowReminderModal(true)
                                }}
                                onSetHistory={() => {
                                  setShowHistoryModal(true)
                                }}
                                onEdit={() => {
                                  navigate(
                                    `/office/company/${selectedCompanyId}/invoicing/issuance/quotationEdit`,
                                    {
                                      state: line.id,
                                    }
                                  )
                                }}
                                onBlurAction={() => {
                                  setShowActions(!showActions)
                                }}
                              />
                            )}
                            {line.documentType === "invoice" && (
                              <SelectLikeButton
                                actionId={actionId || 0}
                                width={"26"}
                                fontSize={1.5}
                                showActions={showActions}
                                fullDocumentId={line.id} // not a fd but necessary for opening select
                                actionsToDisplay={[
                                  "treasury_historic",
                                  "reminder",
                                  "duplicate",
                                ]}
                                onClickAction={() => {
                                  navigate(
                                    `/office/company/${selectedCompanyId}/invoicing/issuance/invoiceToCreditNote`,
                                    {
                                      state: line.id,
                                    }
                                  )
                                }}
                                label="more-actions.overtakingToCreditNote"
                                disabledButton={false}
                                disabledSelect={false}
                                onClickSelectAction={() => {
                                  openActions(line.id)
                                }}
                                onSetHistory={() => {
                                  setShowHistoryModal(true)
                                }}
                                onSetReminder={() => {
                                  setShowReminderModal(true)
                                }}
                                onDuplicate={() => {
                                  navigate(
                                    `/office/company/${selectedCompanyId}/invoicing/issuance/invoicing`,
                                    {
                                      state: line.id,
                                    }
                                  )
                                }}
                                onBlurAction={() => {
                                  setShowActions(!showActions)
                                }}
                              />
                            )}
                          </Ct.Cell>
                        )}
                      </StyledFlex1>
                    </PaddingRow>
                  </WrapperRows>
                </Fragment>
              )
            })}
          </StyledTableBody>
        ) : (
          <RowCenterWrapper>
            <Alert alertType="info">
              {intl.formatMessage({
                id: "invoicing.documents.empty",
              })}
            </Alert>
          </RowCenterWrapper>
        )}
      </TableWrapper>
    </>
  )
}

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  box-shadow: ${boxShadow};
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
`

const WrapperTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`

const NumberingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TypeWrapper = styled.div<{ isColoredLine: boolean }>`
  background-color: ${({ isColoredLine }) =>
    isColoredLine ? colors.navy : colors.cornflower};

  color: ${colors.white};
  border-radius: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  width: min-content;
`

const StyledTableHeader = styled(Ct.TableHeader)`
  padding: 0 3rem;
`
const StyledTableBody = styled(Ct.TableBody)`
  height: 92%;
  background-color: white;
`

const StyledFlex1 = styled(Ct.Flex1)<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  min-width: ${({ width }) => (width ? `${width}rem` : `auto`)};
`

const StyledFlexNumber = styled(Ct.Flex1)<{ hasPadding: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  padding-left: ${({ hasPadding }) => (hasPadding ? "3rem" : `0`)};
  padding-right: ${({ hasPadding }) => (hasPadding ? "0" : `3rem`)};
  width: 13rem;
`

const PaddingRow = styled(Ct.Row)`
  padding: 0 3rem;
  height: 12rem;
`

const WrapperRows = styled.div<{
  isColoredLine: boolean
  hasBorderBottom: boolean
}>`
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom ? `1px solid ${colors.lavender}` : ""};
  background-color: ${({ isColoredLine }) =>
    isColoredLine ? colors.mist : `white`};

  :last-child {
    border-bottom: none;
  }
`

const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  margin-top: 5rem;
  justify-content: center;
`

interface RotationProps {
  rotation: boolean
}

const StyledHeaderTitleWrapper = styled(HeaderTitleWrapper)<{
  calculatedWidth: number
  justifyContent?: string
}>`
  min-width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  width: auto;
`

const ChevronRotate = styled(ChevronRight)<RotationProps>`
  transition-duration: 0.2s;
  transform: ${({ rotation }) => (rotation ? "rotate(90deg)" : ``)};
  min-height: 3rem;
  min-width: 3rem;
`
const StyledLink = styled.a<{ isColoredLine: boolean }>`
  color: ${({ isColoredLine }) =>
    isColoredLine ? colors.navy : colors.cornflower};

  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer;
`
const DocumentsLineGroup = styled.div<{ isLastInvoiceOfGroup: boolean }>`
  background: ${colors.navy};
  min-width: 0.5rem;
  min-height: 12rem;
  margin: 0 1.5rem;
  margin-bottom: ${({ isLastInvoiceOfGroup }) =>
    isLastInvoiceOfGroup ? `3rem` : `0`};
`
