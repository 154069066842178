import { Suspense, SyntheticEvent } from "react"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../styles/design.config"
import { ReactComponent as CalendarCornflower } from "../../assets/calendarSearchCornflower.svg"
import { ReactComponent as Cross } from "../../assets/circled-cross.svg"
import React from "react"
import { ValueType } from "rsuite/esm/DateRangePicker/types"
import frFR from "date-fns/locale/fr"
import * as Ct from "ldlj"

type Placement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd"

interface InputDateProps {
  value: [Date, Date]
  onChange: (value: ValueType, event: SyntheticEvent<Element, Event>) => void
  onClose: () => void
  minimum?: string
  maximum?: string
  placement?: Placement
}

const Calendar = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "OK",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heures",
  minutes: "Minutes",
  seconds: "Secondes",
  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",
  dateLocale: frFR,
}

const locale = {
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "7 derniers jours",
  },
}

const DateRangePicker = React.lazy(() => import("rsuite/DateRangePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

export const InputDateRange = (props: InputDateProps) => {
  const {
    value,
    onChange,
    onClose,
    maximum,
    minimum,
    placement = "bottomStart",
  } = props

  const disabledDate = (date: Date): boolean => {
    if (minimum && maximum) {
      const startDate = new Date(minimum)
      const endDate = new Date(maximum)
      return date < startDate || date > endDate
    }
    return false
  }

  return (
    <StyledDiv>
      <StyledPickerHeader>
        <Suspense fallback={<Ct.SpinningLoader />}>
          <CustomProvider locale={locale}>
            <DateRangePicker
              appearance="subtle"
              value={value}
              isoWeek={true}
              cleanable={false}
              character={" - "}
              onChange={onChange}
              ranges={[]}
              format={"dd/MM/yyyy"}
              placement={placement}
              disabledDate={disabledDate}
            />
          </CustomProvider>
        </Suspense>
      </StyledPickerHeader>
      <Ct.Spacer width={2} />
      <CalendarWrapper>
        <StyledCalendar />
        <StyledCross
          onClick={() => {
            onClose()
          }}
        />
      </CalendarWrapper>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  font-family: "Poppins", sans-serif;
  padding: 0 1rem 0 1rem;
  background-color: ${colors.white};
  font-weight: 600;
  font-size: 3rem;
  box-shadow: ${boxShadow};
`

const CalendarWrapper = styled.div`
  width: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledCalendar = styled(CalendarCornflower)`
  width: 3rem;
  height: 3rem;
  padding-right: 1rem;
`
const StyledCross = styled(Cross)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`

const StyledPickerHeader = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  > .rs-picker-daterange > .rs-picker-toggle.rs-btn {
    width: 34rem;
    padding-right: 0;
    padding-left: 0.5rem;
  }

  > span.rs-picker-toggle-value,
  div.rs-picker-toggle.rs-btn.rs-btn-subtle {
    width: auto;
  }

  > .rs-picker-daterange
    > .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-subtle,
  > .rs-picker-daterange
    > .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-subtle
    > input,
  > .rs-picker-daterange > div.rs-picker-toggle.rs-btn.rs-btn-subtle:hover {
    background-color: transparent;
    color: transparent;
    cursor: pointer;
    width: auto;
  }

  .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: ${colors.navy};
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    color: transparent;
  }
`
