import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { colors, opacityColors } from "../../styles/design.config"

export const DropHeader = styled((props) => (
  <Ct.SpacedBetweenCenter {...props} />
))`
  padding-right: 2rem;
  padding-left: 2rem;
  box-sizing: border-box;
`

export const ControlBar = styled((props) => (
  <Ct.SpacedBetweenCenter {...props} />
))`
  box-sizing: border-box;
  padding-right: 2rem;
  padding-left: 2rem;
`

export const Content = styled((props) => <Ct.Card {...props} />)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
`

export const HeaderWrapper = styled((props) => <Ct.Header {...props} />)<{
  totalWidth: number
}>`
  width: ${({ totalWidth }) => `${totalWidth}px`};
`

export const LoaderWrapper = styled.div<{
  totalWidth: number
  height: number
  flexDirection?: string
}>`
  background: white;
  width: ${({ totalWidth }) => `${totalWidth}px`};
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  border: 1px solid ${colors.lavender};
  border-radius: 2.5rem;

  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: center;
  justify-content: center;
`

export const GridWrapper = styled.div<{ totalWidth: number }>`
  background: white;
  width: ${({ totalWidth }) => `${totalWidth}px`};
  box-sizing: border-box;
  border: 1px solid ${colors.lavender};
  border-radius: 2.5rem;

  border-top-right-radius: 0;
  border-top-left-radius: 0;
  overflow: hidden;
`

export const HeaderTitleWrapper = styled.div<{
  calculatedWidth: number
  justifyContent?: string
  padding?: string
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  padding: ${({ padding }) => (padding ? padding : "0 4rem")};
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
  align-items: center;
  user-select: none;
`

export const StyledCell = styled((props) => (
  <Ct.ColumnCenterCenter {...props} />
))<{
  isGray: boolean
  isRecent: boolean
}>`
  background-color: ${({ isGray, isRecent }) =>
    isRecent ? colors.mist : isGray ? opacityColors.radicalRed : colors.white};
  min-height: 50px;
  height: 100%;
  max-height: 50px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
  box-sizing: border-box;
`

export const UploadIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid ${colors.cornflower};
  background-color: ${colors.mist};
  flex: 50%;
  flex-shrink: 0;
  height: 25rem;
  border-radius: 3rem;
`

export const DropActionButton = styled.div`
  cursor: pointer;
`
