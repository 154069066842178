import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  Instructions,
  MerchantCode,
} from "../../store/ducks/merchantCodes.ducks"
import { sizes } from "../../styles/design.config"
import { sortMerchantChangesByDate } from "../../utils/merchants"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { DateTime } from "luxon"

interface CodeHistoryProps extends Ct.ModalComponentsProps {
  merchantCode: MerchantCode
}

export const CodeHistoryModal = ({
  isDisplayed,
  onClose,
  merchantCode,
}: CodeHistoryProps) => {
  const intl = useIntl()
  const [filteredInstructions, setFilteredInstructions] = useState<
    Instructions[]
  >([])

  useEffect(() => {
    setFilteredInstructions(merchantCode.code_instructions.slice(0, 10))
  }, [merchantCode])

  const findAccounts = (metadata: { [index: string]: string }) => {
    for (let i = 0; i < Object.keys(metadata).length; i++) {
      const metadataKey = Object.keys(metadata)[i]
      const metadataValue = Object.values(metadata)[i]

      switch (metadataKey) {
        case "previous_code":
          return intl.formatMessage(
            { id: "accounting-plan.history.previously" },
            { value: metadataValue }
          )

        default:
          return ""
      }
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(30vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Title
          text={intl.formatMessage({ id: "ged.client.modal.title" })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={intl.formatMessage({
                id: `accounting-plan.history.code`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={" : "}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={merchantCode.code}
              textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
            />
          </ChangeHeader>

          {filteredInstructions.length > 0 ? (
            <ChangesList>
              {sortMerchantChangesByDate(filteredInstructions).map((code) => (
                <ChangeItem key={code.created_at}>
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: `accounting-plan.history.${code.instruction_type}`,
                      },
                      {
                        centralize:
                          Object.keys(code.metadata)[0] === "centralize" &&
                          Object.values(code.metadata)[0]
                            ? "Centralisation"
                            : "Décentralisation",
                        addOrRemove:
                          Object.keys(code.metadata)[0] ===
                            code.instruction_type &&
                          !Object.values(code.metadata)[0]
                            ? "Annulation "
                            : "Ajout ",
                      }
                    )}
                  />

                  <Mailto href={`mailto:${code.user}`}>{code.user}</Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(new Date(code.created_at)).toFormat(
                        "dd/MM/yyyy HH:mm:ss"
                      ) +
                      ")"
                    }
                  />
                  {
                    <Ct.Text
                      text={`${findAccounts(
                        code.metadata as { [index: string]: string }
                      )}`}
                      textStyle={{
                        fontWeight: 700,
                      }}
                    />
                  }
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({ id: `ged.client.modal.no-changes` })}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: `accounting-plan.history.code`,
                })}
                textStyle={{
                  textTransform: "lowercase",
                }}
              />
              .
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
