import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import AutoSizer from "react-virtualized-auto-sizer"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"

import {
  filterDraftsBySearch,
  sortDraftsByAmount,
  sortDraftsByClient,
  sortDraftsByDate,
  sortDraftsByIssueBy,
  sortDraftsByType,
} from "../../../../utils/invoicesDrafts"
import { useRNBSelector } from "../../../../store/rootReducer"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import {
  InvoiceDraftsPayload,
  deleteDraftInvoiceThunk,
  draftInvoiceReset,
  getDraftInvoicesThunk,
} from "../../../../store/ducks/invoicing.duck"
import { getIdFromParams } from "../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { Color, colors } from "../../../../styles/design.config"
import { Alert } from "../../../../components/Commons/Alert"
import {
  TitleTable,
  SortToReturn,
  ClickableTitleSort,
  ChevronProps,
} from "../../../../components/Commons/Table"
import {
  GridWrapper,
  HeaderWrapper,
} from "../../../../components/dropDocuments/StyledDropsComponents"
import { IconActions } from "../../../../components/IconActions"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { formatDateFRstring } from "../../../../utils/date"

enum SortOptionsValues {
  "type",
  "date",
  "amount",
  "client",
  "issueBy",
}

export const InvoiceDrafts = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const selectedCompanyId = getIdFromParams(params)("company_id")
  const navigate = useNavigate()

  const [draftsToDisplay, setDraftsToDisplay] = useState<
    InvoiceDraftsPayload[]
  >([])
  const [asc, setAsc] = useState(true)
  const [sortCriteria, setSortCriteria] = useState<SortOptionsValues>(
    SortOptionsValues.date
  )

  const [search, setSearch] = useState("")
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setAsc(!asc)
    setSortCriteria(option)
  }
  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        sorter(true)(1)
      }
    }
  }, [columnToSort])

  useEffect(() => {
    dispatch(draftInvoiceReset())

    if (!selectedCompanyId) return
    dispatch(getDraftInvoicesThunk(selectedCompanyId))
  }, [selectedCompanyId])

  const { draftInvoices } = useRNBSelector((state) => ({
    draftInvoices: state.invoicing.draftInvoices,
  }))

  useEffect(() => {
    if (draftInvoices) {
      const formattedDrafts = getSortedDrafts(draftInvoices)
      setDraftsToDisplay(formattedDrafts)
    }
  }, [draftInvoices, search, sortCriteria, asc])

  const sortMethodsByOption: {
    [index: number]: (
      d: InvoiceDraftsPayload[],
      asc: boolean
    ) => InvoiceDraftsPayload[]
  } = {
    [SortOptionsValues.type]: sortDraftsByType,
    [SortOptionsValues.date]: sortDraftsByDate,
    [SortOptionsValues.amount]: sortDraftsByAmount,
    [SortOptionsValues.client]: sortDraftsByClient,
    [SortOptionsValues.issueBy]: sortDraftsByIssueBy,
  }
  const getSortedDrafts = (allDrafts: InvoiceDraftsPayload[]) => {
    const sortedDrafts = sortMethodsByOption[sortCriteria](allDrafts, asc)

    return filterDraftsBySearch(sortedDrafts, search)
  }
  const columnWidth = [
    { width: "20" },
    { width: "20" },
    null,
    null,
    null,
    { width: "14" },
  ]

  const columnsTitle: {
    title: string
  }[] = [
    { title: "type" },
    { title: "date" },
    { title: "amount" },
    { title: "client" },
    { title: "issue" },
    { title: "action" },
  ]

  const columnsConfig = columnWidth.map((widthConfig, index) => ({
    ...widthConfig,
    ...columnsTitle[index],
  }))

  return (
    <>
      <WrapperTopBar>
        <Ct.Input
          label="Rechercher"
          value={search}
          suffix={<Search />}
          maxWidth={30}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          dataCy={"searchClient"}
          shadowed={true}
          noBorder={true}
          isSearch={true}
        />
      </WrapperTopBar>

      <Ct.Spacer height={3} />

      <TableWrapper>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            const numberOfAdjustableColumns = columnWidth.filter(
              (c) => !c?.width
            ).length
            const allColumnsWidthPx =
              columnWidth.reduce((totalWidth, cWidth) => {
                return totalWidth + (cWidth?.width ? Number(cWidth.width) : 0)
              }, 0) * 8
            const paddingLeft = 3 * 8
            const remainingSpacePx = width - allColumnsWidthPx - paddingLeft

            const remainingSpacePerColumnPx =
              remainingSpacePx / numberOfAdjustableColumns

            return (
              <>
                <StyledHeaderWrapper
                  totalWidth={width}
                  paddingHeader={"0 1rem"}
                >
                  {columnsConfig.map(({ title, width }, index) => (
                    <HeaderTitleWrapper
                      calculatedWidthPx={
                        width ? Number(width) * 8 : remainingSpacePerColumnPx
                      }
                      key={title}
                    >
                      {index < 5 ? (
                        <ClickableTitleSort
                          tid={`invoicing-draft.documents.header-${title}`}
                          intl={intl}
                          index={index}
                          sortToReturn={(column: SortToReturn) => {
                            setColumnToSort(column)
                          }}
                          currentChevron={currentChevron}
                        />
                      ) : (
                        <TitleTable
                          tid={`invoicing.documents.header-${title}`}
                          intl={intl}
                        />
                      )}
                    </HeaderTitleWrapper>
                  ))}
                </StyledHeaderWrapper>
                {draftsToDisplay && draftsToDisplay.length > 0 ? (
                  <GridWrapper totalWidth={width}>
                    {draftsToDisplay.map((line, index) => {
                      return (
                        <WrapperRows key={index}>
                          <PaddingRow
                            backgroundColor={
                              index % 2 === 0 ? "white" : "clearBlue"
                            }
                          >
                            <StyledFlex1
                              width={
                                Number(columnWidth[0]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <AlignStart>
                                <Ct.Text
                                  text={"Brouillon"}
                                  textStyle={{
                                    fontWeight: 500,
                                    textTransform: "initial",
                                    fontSize: 2,
                                    textAlign: "center",
                                  }}
                                />
                                <Ct.Spacer height={0.5} />
                                <TypeWrapper>
                                  {line.document_type === "invoice"
                                    ? "FACTURE"
                                    : line.document_type === "creditNote"
                                    ? "AVOIR"
                                    : "DEVIS"}
                                </TypeWrapper>
                              </AlignStart>
                            </StyledFlex1>
                            <StyledFlex1
                              width={
                                Number(columnWidth[1]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <Ct.Text
                                text={formatDateFRstring(line.created_at)}
                                textStyle={{
                                  fontWeight: 400,
                                  textTransform: "initial",
                                  fontSize: 1.75,
                                  textAlign: "center",
                                  color: "slateGrey",
                                }}
                              />
                            </StyledFlex1>

                            <StyledFlex1
                              width={
                                Number(columnWidth[2]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <Ct.Text
                                text={
                                  line.amount === null
                                    ? "-"
                                    : line.document_type === "creditNote"
                                    ? "- " + line.amount + " €"
                                    : line.amount + " €"
                                }
                                textStyle={{
                                  fontWeight: 400,
                                  textTransform: "initial",
                                  fontSize: 1.75,
                                  textAlign: "center",
                                  color: "slateGrey",
                                }}
                              />
                            </StyledFlex1>
                            <StyledFlex1
                              width={
                                Number(columnWidth[3]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <Ct.Text
                                text={line.client || "-"}
                                textStyle={{
                                  fontWeight: 400,
                                  textTransform: "initial",
                                  fontSize: 1.75,
                                  textAlign: "center",
                                  color: "slateGrey",
                                }}
                              />
                            </StyledFlex1>
                            <StyledFlex1
                              width={
                                Number(columnWidth[4]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <Ct.Text
                                text={line.issue_by}
                                textStyle={{
                                  fontWeight: 400,
                                  textTransform: "initial",
                                  fontSize: 1.75,
                                  textAlign: "center",
                                  color: "slateGrey",
                                }}
                              />
                            </StyledFlex1>
                            <StyledFlex1
                              width={
                                Number(columnWidth[5]?.width) * 8 ||
                                remainingSpacePerColumnPx
                              }
                            >
                              <Ct.Spacer width={4} />
                              <IconActions
                                actionsToDisplay={["edit", "delete"]}
                                deleteAction={"enable"}
                                onDelete={() => {
                                  if (!selectedCompanyId) return

                                  dispatch(
                                    deleteDraftInvoiceThunk({
                                      companyId: selectedCompanyId,
                                      draftInvoicingDocumentId: line.id,
                                    })
                                  )
                                }}
                                onEdit={() => {
                                  navigate(
                                    `/office/company/${selectedCompanyId}/invoicing/issuance/draftTo${capitalizeFirstLetter(
                                      line.document_type
                                    )}`,
                                    {
                                      state: line.id,
                                    }
                                  )
                                }}
                              />
                              <Ct.Spacer width={4} />
                            </StyledFlex1>
                          </PaddingRow>
                        </WrapperRows>
                      )
                    })}
                  </GridWrapper>
                ) : (
                  <GridWrapper totalWidth={width}>
                    <RowCenterWrapper>
                      <div>
                        <Alert alertType="info">
                          {intl.formatMessage({
                            id: "invoicing-draft.documents.empty",
                          })}
                        </Alert>
                        <Ct.Spacer height={4} />
                      </div>
                    </RowCenterWrapper>
                  </GridWrapper>
                )}
              </>
            )
          }}
        </AutoSizer>
      </TableWrapper>
    </>
  )
}

const StyledHeaderWrapper = styled(HeaderWrapper)`
  padding: 0;
  padding-left: 3rem;
`

const WrapperTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`

const AlignStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 2rem;
`
const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
`

const TypeWrapper = styled.div`
  background: ${colors.orange};
  color: ${colors.white};
  border-radius: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  width: min-content;
`

const StyledFlex1 = styled(Ct.Flex1)<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: ${({ width }) => (width ? `${width}px` : `auto`)};
  min-width: ${({ width }) => (width ? `${width}px` : `auto`)};
`

const PaddingRow = styled(Ct.Row)<{ backgroundColor: Color }>`
  padding: 0 3rem;
  height: 12rem;
  background-color: ${({ backgroundColor }) => colors[backgroundColor]};
`

const WrapperRows = styled.div`
  border-bottom: 1px solid ${colors.lavender};
  background-color: white;

  :last-child {
    border-bottom: none;
  }
`

const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  margin-top: 5rem;
  justify-content: center;
`

export const HeaderTitleWrapper = styled.div<{
  calculatedWidthPx: number
}>`
  width: ${({ calculatedWidthPx }) => `${calculatedWidthPx}px`};
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  justify-content: center;
`
