import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { updateBatchResetAction } from "../../store/ducks/batchDocuments.ducks"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../store/rootReducer"
import { Alert } from "../Commons/Alert"

interface RenameBatchDocumentModalProps extends Ct.ModalComponentsProps {
  onClick: (newName: string) => void
  inputLabelId: string
  additionalTextId?: string
}

export const RenameBatchDocumentModal = ({
  isDisplayed,
  onClose,
  onClick,
  inputLabelId,
  additionalTextId,
}: RenameBatchDocumentModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [newFileName, setNewFileName] = useState("")

  const updateBatchStatus = useRNBSelector(
    (state) => state.batchDocuments.batchesUpdateStatus
  )

  useEffect(() => {
    if (updateBatchStatus === "success") {
      onClose()
      dispatch(updateBatchResetAction())
    }
  }, [dispatch, updateBatchStatus, onClose])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card>
          <Alert alertType="info">
            <Ct.Text
              text={intl.formatMessage({
                id: "company-drop-document.rename.about",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            {additionalTextId && (
              <>
                <Ct.Text
                  text={intl.formatMessage({
                    id: additionalTextId,
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
              </>
            )}
            <Ct.Spacer />
            <Ct.Input
              label={intl.formatMessage({
                id: inputLabelId,
              })}
              value={newFileName}
              onChange={(e) => {
                setNewFileName(e.target.value)
              }}
            />
            <Ct.Spacer />
          </Alert>
          <Ct.Spacer />
          <Ct.FlexEnd>
            <Ct.Button
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              label={intl.formatMessage({
                id: "company-drop-document.rename.cancel",
              })}
              onClick={onClose}
            />
            <Ct.Spacer />
            <Ct.Button
              label={intl.formatMessage({
                id: "company-drop-document.rename.confirm",
              })}
              onClick={() => {
                onClick(newFileName)
              }}
            />
          </Ct.FlexEnd>
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}
