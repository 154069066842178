import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { FieldValues, UseFormRegister, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { InputDate } from "../../../../../components/Commons/InputDate"
import { boxShadow, colors } from "../../../../../styles/design.config"
import { getIdFromParams } from "../../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { useRNBSelector } from "../../../../../store/rootReducer"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "../../../../../components/Commons/Alert"
import { useDispatch } from "react-redux"
import {
  createFirstImportDateThunk,
  updateAccountThunk,
  updateAccountNameThunk,
  deactivateAccountReset,
} from "../../../../../store/ducks/bank.ducks"
import {
  DeleteBankOrAccount,
  DisplayDeletionModalParams,
} from "../../../../../components/bank/DeleteBankOrAccount"
import { Input } from "../../../../../components/Commons/Input"

export const Settings = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [importDate, setImportDate] = useState<string>("")
  const [accountName, setAccountName] = useState<string>("")
  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<DisplayDeletionModalParams>({
      isDisplayed: false,
      accountOrBank: "",
      accountId: null,
      bankId: null,
    })

  const accountID = getIdFromParams(useParams())("account_id") || 0
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const bankId = getIdFromParams(useParams())("bank_id")

  const fiduciaryId = useRNBSelector((state) => state.fiduciary.id)
  const selectAcccount = useRNBSelector((state) =>
    Object.values(state.bank.accounts).find((a) => a.id === accountID)
  )
  const deactivateAccountStatus = useRNBSelector(
    (state) => state.bank.deactivateAccountStatus
  )

  const lastYear = new Date()
  lastYear.setFullYear(lastYear.getFullYear() - 1)
  const schema = yup.object({
    journalCode: yup.string().matches(RegExp(/^\w+$/), "format").required(),
    financialAccount: yup
      .string()
      .matches(RegExp(/^\w+$/), "format")
      .required(),
    suspenseAccount: yup.string().matches(RegExp(/^\w+$/), "format").required(),
  })

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    reValidateMode: "onChange",
  })

  const journalCode = watch("journalCode")
  const financialAccount = watch("financialAccount")
  const suspenseAccount = watch("suspenseAccount")

  useEffect(() => {
    if (selectAcccount) {
      setValue("journalCode", selectAcccount.bankin_journal_code)
      setValue(
        "financialAccount",
        selectAcccount.bankin_financial_account
          ? selectAcccount.bankin_financial_account
          : "512"
      )
      setValue(
        "suspenseAccount",
        selectAcccount.bankin_suspense_account
          ? selectAcccount.bankin_suspense_account
          : "471"
      )
      setAccountName(selectAcccount.name)
      setImportDate(
        selectAcccount.first_import_date
          ? String(selectAcccount.first_import_date)
          : ""
      )
    }
  }, [selectAcccount, setValue])

  useEffect(() => {
    if (deactivateAccountStatus === "SUCCESS") {
      navigate(
        `/office/company/${selectedCompanyId}/bank/accounts/bank/${bankId}`
      )
      dispatch(deactivateAccountReset())
    }
  }, [deactivateAccountStatus, selectAcccount])

  const validateSettings = () => {
    if (selectedCompanyId && selectAcccount) {
      if (
        accountName !== selectAcccount?.name &&
        accountName !== null &&
        accountName !== "" &&
        selectAcccount?.bankin_item_id
      ) {
        dispatch(
          updateAccountNameThunk({
            fiduciaryId,
            companyId: selectedCompanyId,
            itemId: selectAcccount.bankin_item_id,
            accountId: accountID,
            name: accountName,
          })
        )
      }
      if (importDate !== "" && !selectAcccount?.first_import_date) {
        dispatch(
          createFirstImportDateThunk(
            selectedCompanyId,
            selectAcccount?.bankin_item_id,
            selectAcccount?.id,
            importDate
          )
        )
      }
      if (
        journalCode !== selectAcccount.bankin_journal_code ||
        suspenseAccount !== selectAcccount.bankin_suspense_account ||
        financialAccount !== selectAcccount.bankin_financial_account
      ) {
        dispatch(
          updateAccountThunk({
            fiduciaryId,
            companyId: selectedCompanyId,
            itemId: selectAcccount.bankin_item_id,
            accountId: accountID,
            journalCode: journalCode,
            financialAccount: financialAccount,
            suspenseAccount: suspenseAccount,
          })
        )
      }
    }
  }

  const disableValidate =
    !journalCode ||
    !financialAccount ||
    !suspenseAccount ||
    !accountName ||
    importDate === "" ||
    (journalCode === selectAcccount?.bankin_journal_code &&
      financialAccount === selectAcccount?.bankin_financial_account &&
      suspenseAccount === selectAcccount?.bankin_suspense_account &&
      accountName === selectAcccount?.name) ||
    !!errors.journalCode ||
    !!errors.suspenseAccount ||
    !!errors.financialAccount

  return (
    <Content>
      <StyledSection>
        {selectAcccount && (
          <Wrapper>
            <Ct.Spacer height={5} />

            <>
              <Ct.Input
                label="Renommer le compte"
                type="text"
                value={accountName}
                name="accountName"
                placeholder="name"
                onChange={(e) => {
                  setAccountName(e.target.value)
                }}
              />

              <Ct.Spacer height={5} />

              <InputDate
                label={intl.formatMessage({
                  id: "bank-accounts.bank-account.settings.date-input",
                })}
                name="date"
                disabled={!!selectAcccount.first_import_date}
                value={importDate}
                onChangeValue={(selectedDate: string) => {
                  setImportDate(selectedDate)
                }}
                register={register as unknown as UseFormRegister<FieldValues>}
                minimum={lastYear.toISOString().substring(0, 10)}
                maximum={new Date().toISOString().substring(0, 10)}
                data-cy={"inputDateImport"}
                maxWidth={"100%"}
              />

              <Ct.Spacer height={5} />

              <ContentWrapper>
                <RowContent margin={"2rem"}>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "bank-management.account-modification.journal-code",
                    })}
                    textStyle={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  />
                  <Ct.Spacer height={2} />
                  <AccountModificationRow
                    text={
                      "bank-management.account-modification.journal-code.example"
                    }
                    label={
                      "bank-management.account-modification.journal-code.label"
                    }
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    inputName={"journalCode"}
                    errors={errors}
                  />
                  {errors.journalCode && (
                    <>
                      <Ct.Spacer height={1} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "bank-accounts.bank-account.settings.suffix.error",
                        })}
                        textStyle={{
                          color: "amaranth",
                        }}
                      />
                    </>
                  )}
                </RowContent>
                <Ct.Spacer height={4} />

                <RowContent margin={"2rem"}>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "bank-management.account-modification.accounts",
                    })}
                    textStyle={{
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  />
                  <Ct.Spacer height={2} />
                  <AccountModificationRow
                    text={
                      "bank-management.account-modification.financial_account.example"
                    }
                    label={
                      "bank-management.account-modification.suffix.label-financial_account"
                    }
                    suffixDefaultValue={"compte financier"}
                    suffixValue={"compte financier"}
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    inputName={"financialAccount"}
                    errors={errors}
                  />
                  {errors.financialAccount && (
                    <>
                      <Ct.Spacer height={1} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "bank-accounts.bank-account.settings.suffix.error",
                        })}
                        textStyle={{
                          color: "amaranth",
                        }}
                      />
                    </>
                  )}
                </RowContent>
                <Ct.Spacer height={4} />

                <RowContent margin={"2rem"}>
                  <AccountModificationRow
                    text={
                      "bank-management.account-modification.suspense_account.example"
                    }
                    label={
                      "bank-management.account-modification.suffix.label-suspense_account"
                    }
                    suffixDefaultValue={"compte d'attente"}
                    suffixValue={"compte d'attente"}
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    inputName={"suspenseAccount"}
                    errors={errors}
                  />
                  {errors.suspenseAccount && (
                    <>
                      <Ct.Spacer height={1} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "bank-accounts.bank-account.settings.suffix.error",
                        })}
                        textStyle={{
                          color: "amaranth",
                        }}
                      />
                    </>
                  )}
                </RowContent>
              </ContentWrapper>
            </>

            <Ct.Spacer height={5} />

            <WrapperButtons>
              <Ct.Button
                type="submit"
                label={intl.formatMessage({
                  id: "bank-management.button.save",
                })}
                width={40}
                disabled={disableValidate}
                onClick={() => {
                  validateSettings()
                }}
              />

              <Ct.Button
                label={intl.formatMessage({
                  id: "bank-management.delete.cta",
                })}
                width={40}
                onClick={() => {
                  setDisplayDeletionModal({
                    isDisplayed: true,
                    accountOrBank: "compte",
                    accountId: selectAcccount.id,
                    bankId: bankId,
                  })
                }}
                colorType={"Tertiary"}
                colorScheme={{
                  background: "white",
                  color: "amaranth",
                  border: "amaranth",
                }}
              />
            </WrapperButtons>
          </Wrapper>
        )}

        <DeleteBankOrAccount
          onClose={() => {
            setDisplayDeletionModal({
              isDisplayed: false,
              accountOrBank: "",
              accountId: null,
              bankId: null,
            })
          }}
          isDisplayed={displayDeletionModal.isDisplayed}
          accountOrBank={displayDeletionModal.accountOrBank}
          accountId={displayDeletionModal.accountId}
          bankId={displayDeletionModal.bankId}
        />
      </StyledSection>
    </Content>
  )
}

interface AccountModificationRowParams {
  text: string
  label: string
  suffixDefaultValue?: string
  suffixValue?: string
  inputName: string
  register: UseFormRegister<FieldValues>
  errors: {
    [x: string]: { message: string }
  }
}

export const AccountModificationRow = ({
  text,
  label,
  suffixDefaultValue,
  suffixValue,
  inputName,
  register,
  errors,
}: AccountModificationRowParams) => {
  const intl = useIntl()

  return (
    <AccountModificationRowWrapper>
      <Input
        label={intl.formatMessage(
          {
            id: label,
          },
          {
            suffixValue: suffixValue,
          }
        )}
        value={inputName}
        name={inputName}
        register={register as unknown as UseFormRegister<FieldValues>}
        showWarning={errors[`${inputName}`]?.message === "format"}
        maxWidth={"35rem"}
      />
      <Ct.Spacer width={8} />
      <AlertWrapper>
        <Alert alertType="bulb">
          <Ct.Text
            text={intl.formatMessage(
              {
                id: text,
              },
              {
                suffixDefaultValue: suffixDefaultValue,
              }
            )}
          />
        </Alert>
      </AlertWrapper>
    </AccountModificationRowWrapper>
  )
}

const Content = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: auto;
`

const Wrapper = styled.div`
  width: 83.25rem;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`
const AccountModificationRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const AlertWrapper = styled.div`
  width: 100%;
`
interface RowContentProps {
  margin: string
}

const ContentWrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.rock};
  border-radius: 1.25rem;
`
const RowContent = styled.div<RowContentProps>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`
