import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { ReactComponent as Plus } from "../../../../assets/plus.svg"
import { useIntl } from "react-intl"
import {
  Option,
  Select,
  emptyOptionValue,
} from "../../../../components/Commons/Select"
import { Button } from "../../../../components/Commons/Button"
import { CreateJournalModal } from "../../../../components/accountingPlan/CreateJournalModal"
import {
  changeJournalThunk,
  getJournalsThunk,
} from "../../../../store/ducks/companySettings.ducks"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../../store/rootReducer"

export const AccountingPlanJournals = () => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  type JournalType = "buy" | "sell" | "misc" | null

  const [buySelectedJournal, setBuySelectedJournal] = useState<Option<string>>({
    value: "",
    label: "",
  })
  const [sellSelectedJournal, setSellSelectedJournal] = useState<
    Option<string>
  >({ value: "", label: "" })
  const [miscSelectedJournal, setMiscSelectedJournal] = useState<
    Option<string>
  >({ value: "", label: "" })

  const selectedJournals = useRNBSelector(
    (state) => state.companySettings.selectedJournals
  )
  const possibleJournals = useRNBSelector(
    (state) => state.companySettings.possibleJournals
  )

  const [displayModal, setDisplayModal] = useState(false)
  const [journalToCreateType, setJournalToCreateType] =
    useState<JournalType>(null)

  const possibleJournalsOptions = possibleJournals.map((journal) => ({
    value: String(journal.id),
    label: journal.journal_code,
  }))

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getJournalsThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (selectedJournals.buy)
      setBuySelectedJournal({
        value: String(selectedJournals.buy?.id),
        label: selectedJournals.buy?.journal_code,
      })
    if (selectedJournals.sell)
      setSellSelectedJournal({
        value: String(selectedJournals.sell?.id),
        label: selectedJournals.sell?.journal_code,
      })
    if (selectedJournals.misc)
      setMiscSelectedJournal({
        value: String(selectedJournals.misc?.id),
        label: selectedJournals.misc?.journal_code,
      })
  }, [dispatch, selectedJournals])

  return (
    <Wrapper>
      <StyledSection>
        <Scroll>
          <Container>
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.journals.title",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Spacer height={3} />

            <BorderedContainer>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.journals.buy.title",
                })}
                textStyle={{ lineHeight: 3 }}
              />
              <Ct.Row>
                <Select
                  intl={intl}
                  disabled={false}
                  value={buySelectedJournal || emptyOptionValue}
                  options={possibleJournalsOptions}
                  onChangeCallback={(e) => {
                    if (selectedCompanyId)
                      dispatch(
                        changeJournalThunk(selectedCompanyId, "buy", e.value)
                      )
                  }}
                />

                <Ct.Spacer />
                <Button
                  label={intl.formatMessage({
                    id: "accounting-plan.journals.create-journal.button",
                  })}
                  onClick={() => {
                    setJournalToCreateType("buy")
                    setDisplayModal(true)
                  }}
                  width={22}
                  prefix={<Plus />}
                  textTransform="uppercase"
                />
                <Ct.Spacer />
              </Ct.Row>

              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.journals.sell.title",
                })}
                textStyle={{ lineHeight: 3 }}
              />
              <Ct.Row>
                <Select
                  intl={intl}
                  disabled={false}
                  value={sellSelectedJournal || emptyOptionValue}
                  options={possibleJournalsOptions}
                  onChangeCallback={(e) => {
                    if (selectedCompanyId)
                      dispatch(
                        changeJournalThunk(selectedCompanyId, "sell", e.value)
                      )
                  }}
                />

                <Ct.Spacer />
                <Button
                  label={intl.formatMessage({
                    id: "accounting-plan.journals.create-journal.button",
                  })}
                  onClick={() => {
                    setJournalToCreateType("sell")
                    setDisplayModal(true)
                  }}
                  width={22}
                  prefix={<Plus />}
                  textTransform="uppercase"
                />
                <Ct.Spacer />
              </Ct.Row>

              <Ct.Spacer />

              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.journals.misc.title",
                })}
                textStyle={{ lineHeight: 3 }}
              />
              <Ct.Row>
                <Select
                  intl={intl}
                  disabled={false}
                  value={miscSelectedJournal || emptyOptionValue}
                  options={possibleJournalsOptions}
                  onChangeCallback={(e) => {
                    if (selectedCompanyId)
                      dispatch(
                        changeJournalThunk(selectedCompanyId, "misc", e.value)
                      )
                  }}
                />

                <Ct.Spacer />
                <Button
                  label={intl.formatMessage({
                    id: "accounting-plan.journals.create-journal.button",
                  })}
                  onClick={() => {
                    setJournalToCreateType("misc")
                    setDisplayModal(true)
                  }}
                  width={22}
                  prefix={<Plus />}
                  textTransform="uppercase"
                />
                <Ct.Spacer />
              </Ct.Row>
              <Ct.Spacer height={3} />
            </BorderedContainer>
          </Container>
        </Scroll>

        <CreateJournalModal
          journalType={journalToCreateType}
          isDisplayed={displayModal}
          onClose={() => setDisplayModal(false)}
        />
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Scroll = styled.div`
  overflow-y: auto;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 25px, 100% 25px;
  background-attachment: local, local, scroll, scroll;
`
const Container = styled.div`
  padding: 0 6rem;
`
const BorderedContainer = styled.div`
  border: 1px solid ${colors.cornflower};
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
`
