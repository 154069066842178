export type Coordinates = [
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number
]

export type NumberOrientation =
  | 0 //facing up
  | 180
  | 90
  | 270

export const drawCanvas = ({
  image,
  canvas,
  scale = 1,
  rotationAngle = 0,
  dragOffset = {
    x: 0,
    y: 0,
  },
  zoomOffset = {
    x: 0,
    y: 0,
  },
  dimensions,
}: {
  image: HTMLImageElement
  canvas: HTMLCanvasElement
  scale?: number
  rotationAngle: NumberOrientation
  dragOffset?: DragCoordinates
  zoomOffset?: DragCoordinates
  dimensions: {
    width: number
    height: number
  }
}) => {
  const canvasWrapperRatio = (dimensions.height || 0) / (dimensions.width || 1)

  canvas.width = image.width
  canvas.height = image.height

  let imageRatio = image.height / image.width

  let rotationDezoomMultiplier = 1
  const ctx = canvas.getContext("2d")

  const rotationX = canvas.width / (imageRatio * 2)
  const rotationY = canvas.height / (imageRatio * 2)

  switch (rotationAngle) {
    case 0:
      ctx?.translate(rotationX, rotationY)
      ctx?.rotate((rotationAngle * Math.PI) / 180)
      ctx?.translate(-rotationX, -rotationY)
      ctx?.translate(dragOffset.x, dragOffset.y)
      ctx?.translate(zoomOffset.x, zoomOffset.y)
      ctx?.scale(scale, scale)
      ctx?.scale(
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier,
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier
      )
      ctx?.translate(-zoomOffset.x, -zoomOffset.y)
      break
    case 90:
      ctx?.translate(rotationX, rotationY)
      ctx?.rotate((rotationAngle * Math.PI) / 180)
      ctx?.translate(-rotationX, -rotationY)
      ctx?.translate(dragOffset.y, -dragOffset.x)
      ctx?.translate(zoomOffset.x, zoomOffset.y)
      ctx?.scale(scale, scale)
      ctx?.scale(
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier,
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier
      )

      ctx?.translate(-zoomOffset.x, -zoomOffset.y)
      break
    case 180:
      ctx?.translate(rotationX, rotationY)
      ctx?.rotate((rotationAngle * Math.PI) / 180)
      ctx?.translate(-rotationX, -rotationY)
      ctx?.translate(-dragOffset.x, -dragOffset.y)
      ctx?.translate(zoomOffset.x, zoomOffset.y)
      ctx?.scale(scale, scale)
      ctx?.scale(
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier,
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier
      )

      ctx?.translate(-zoomOffset.x, -zoomOffset.y)
      break
    case 270:
      ctx?.translate(rotationX, rotationY)
      ctx?.rotate((rotationAngle * Math.PI) / 180)
      ctx?.translate(-rotationX, -rotationY)
      ctx?.translate(-dragOffset.y, dragOffset.x)
      ctx?.translate(zoomOffset.x, zoomOffset.y)
      ctx?.scale(scale, scale)
      ctx?.scale(
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier,
        (canvasWrapperRatio / imageRatio) * rotationDezoomMultiplier
      )

      ctx?.translate(-zoomOffset.x, -zoomOffset.y)
      break
  }

  ctx?.drawImage(image, 0, 0, canvas.width, canvas.height)
}

export interface DragCoordinates {
  x: number
  y: number
}

export type CanvasColorsOCR =
  | "rgba(255, 171, 17, 0.7)"
  | "rgba(24, 24, 255, 0.7)"

export const drawRectangle = ({
  canvas,
  coordinates,
  color,
  rotation,
  pixelGap,
}: {
  canvas: HTMLCanvasElement
  coordinates: Coordinates
  color: string
  rotation: NumberOrientation
  pixelGap: PixelGap
}) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) return

  const [x0, y0, x1, y1, x2, y2, x3, y3] = coordinates
  const [gapX0, gapY0, gapX1, gapY1, gapX2, gapY2, gapX3, gapY3] =
    getPixelGaps({ pixelGap, rotation }) || []
  // TODO : find why gaps seems off when changing orientation
  ctx.beginPath()

  ctx.strokeStyle = color
  ctx.lineWidth = 4

  ctx.moveTo(gapX0 + x0, gapY0 + y0)
  ctx.lineTo(gapX1 + x1, gapY1 + y1)
  ctx.lineTo(gapX2 + x2, gapY2 + y2)
  ctx.lineTo(gapX3 + x3, gapY3 + y3)
  ctx.lineTo(gapX0 + x0, gapY0 + y0)
  ctx.stroke()

  ctx.restore()
}

type PixelGap = 2.5

const getPixelGaps = ({
  pixelGap,
  rotation,
}: {
  pixelGap: PixelGap
  rotation: NumberOrientation
}) => {
  const gapByRotations: Record<NumberOrientation, number[]> = {
    0: [
      -pixelGap,
      -pixelGap,
      pixelGap,
      -pixelGap,
      pixelGap,
      pixelGap,
      -pixelGap,
      pixelGap,
    ],
    180: [
      pixelGap,
      pixelGap,
      -pixelGap,
      pixelGap,
      -pixelGap,
      -pixelGap,
      pixelGap,
      -pixelGap,
    ],
    90: [
      -pixelGap,
      pixelGap,
      -pixelGap,
      -pixelGap,
      pixelGap,
      -pixelGap,
      pixelGap,
      pixelGap,
    ],
    270: [
      pixelGap,
      -pixelGap,
      pixelGap,
      pixelGap,
      -pixelGap,
      pixelGap,
      -pixelGap,
      -pixelGap,
    ],
  }
  return gapByRotations[rotation]
}
