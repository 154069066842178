import { useEffect, useState, VFC } from "react"
import styled, { css } from "styled-components/macro"
import ReactTooltip from "react-tooltip"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { ReactComponent as ChevronLeft } from "../assets/chevron-left.svg"
import { ReactComponent as ChevronRight } from "../assets/chevron-right.svg"
import { ReactComponent as Bulb } from "../assets/bulb.svg"
import { boxShadow, colors } from "../styles/design.config"
import * as Ct from "ldlj"
import {
  findMostRecentFiscalYearWithFullDocumentsSuccessAction,
  FiscalYear,
  getFiscalYears,
  getMostRecentFiscalYearWithFullDocuments,
  selectFiscalYearAction,
} from "../store/ducks/fiscalYears.ducks"
import { useRNBSelector } from "../store/rootReducer"
import { convertToMonth } from "../utils/fiscalYears"
import { sortByDate } from "../utils/filesList"
import { SpotNotifLeft, SpotNotifRight } from "./SpotNotification"
import { hasSeenWritings } from "../store/ducks/writings.ducks"
import { BuyOrSell } from "../utils/company"

interface FiscalYearPickerProps {
  companyId: number
  handleNotifs?: boolean
  notifsTypes?: "writings" | "bank"
  fromWritingPage?: boolean
  buyOrSell?: BuyOrSell
}

export const FiscalYearPicker: VFC<FiscalYearPickerProps> = ({
  companyId,
  handleNotifs,
  notifsTypes,
  fromWritingPage,
  buyOrSell,
}: FiscalYearPickerProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const [notifications, setNotifications] = useState<[number, number]>([0, 0])
  const [sortedFYs, setSortedFYs] = useState<FiscalYear[]>([])
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYear>({
    id: 0,
    beginExercise: "",
    endExercise: "",
    companyId: 0,
  })

  const [notifsPresent, setNotifsPresent] = useState(false)

  const refreshToolTips = () => {
    if (notifsPresent) setNotifsPresent(false)

    const id = setTimeout(() => {
      if (notifications[0] || notifications[1]) setNotifsPresent(true)
    }, 1000)

    return () => clearTimeout(id)
  }

  useEffect(() => {
    if (fromWritingPage) {
      refreshToolTips()
    }
  }, [notifications])

  const {
    selectedFYid,
    FYsByCompanyId,
    FYsByCompanyStatus,
    writingsNotifPerFY,
    hasSeenNotifs,
    mostRecentFiscalYearIdWithFullDocuments,
    mostRecentFiscalYearIdWithFullDocumentsStatus,
  } = useRNBSelector((state) => ({
    selectedFYid: state.fiscalYears.selectedFiscalYearId,
    FYsByCompanyId: state.fiscalYears.fiscalYearsByCompanyId,
    FYsByCompanyStatus: state.fiscalYears.fiscalYearsStatus,
    writingsNotifPerFY: state.writings.notifsPerFiscalYear,
    hasSeenNotifs: state.writings.hasSeenNotifsWritings,
    mostRecentFiscalYearIdWithFullDocuments:
      state.fiscalYears.mostRecentFiscalYearIdWithFullDocuments,
    mostRecentFiscalYearIdWithFullDocumentsStatus:
      state.fiscalYears.mostRecentFiscalYearIdWithFullDocumentsStatus,
  }))

  const FYsForSelectedCompany = FYsByCompanyId[companyId]

  useEffect(() => {
    if (handleNotifs && sortedFYs.length > 0) {
      const [prev, next] = sortedFYs.reduce(
        (acc, fy: FiscalYear) => {
          if (fy.id === selectedFYid) {
            acc[2] = true
            return acc
          }
          if (!acc[2] && writingsNotifPerFY[fy.id]) {
            acc[0]++
          }
          if (acc[2] && writingsNotifPerFY[fy.id]) {
            acc[1]++
          }
          return acc
        },
        [0, 0, false] as [number, number, boolean]
      )
      if (notifications[0] !== prev || notifications[1] !== next) {
        setNotifications([prev, next])
      }
    }
  }, [handleNotifs, sortedFYs, selectedFYid, writingsNotifPerFY])

  useEffect(() => {
    if (companyId) {
      dispatch(getFiscalYears(companyId))
    }
  }, [dispatch, companyId])

  useEffect(() => {
    if (
      FYsByCompanyStatus === "SUCCESS" &&
      (!FYsForSelectedCompany || FYsForSelectedCompany?.length === 0)
    ) {
      navigate(`/office/company/${companyId}/settings/fiscalyears`)
      toast.error(<ToasterContainerStyled />)
    }
  }, [FYsForSelectedCompany, navigate, companyId, FYsByCompanyStatus])

  // Set The Sorted FYs
  useEffect(() => {
    if (!FYsForSelectedCompany) {
      return
    }
    setSortedFYs(
      FYsForSelectedCompany.sort((x, y) =>
        sortByDate(x.beginExercise, y.beginExercise)
      )
    )
    if (buyOrSell === "buy" && companyId) {
      dispatch(getMostRecentFiscalYearWithFullDocuments(companyId, buyOrSell))
    } else {
      dispatch(findMostRecentFiscalYearWithFullDocumentsSuccessAction(null))
    }
  }, [setSortedFYs, buyOrSell, FYsForSelectedCompany])

  // Set Selected FY id
  useEffect(() => {
    if (
      buyOrSell === "buy" &&
      mostRecentFiscalYearIdWithFullDocumentsStatus !== "SUCCESS"
    ) {
      return
    }
    if (sortedFYs.length === 0) {
      return
    }
    const reselectedFY = sortedFYs.find(
      (fy: FiscalYear) => fy.id === selectedFYid
    )

    if (selectedFYid && reselectedFY) {
      return setSelectedFiscalYear(reselectedFY)
    }

    const setDefaultFiscalYear = () => {
      const dayDate = new Date()
      const actualFY = sortedFYs.find((el) => {
        const begin = new Date(el.beginExercise)
        const end = new Date(el.endExercise)
        return begin <= dayDate && end >= dayDate
      })

      const defaultFiscalYear =
        mostRecentFiscalYearIdWithFullDocuments ||
        actualFY ||
        sortedFYs[sortedFYs.length - 1]

      setSelectedFiscalYear(defaultFiscalYear)
    }

    setDefaultFiscalYear()
  }, [
    dispatch,
    mostRecentFiscalYearIdWithFullDocuments,
    selectedFYid,
    sortedFYs,
    mostRecentFiscalYearIdWithFullDocumentsStatus,
    setSelectedFiscalYear,
  ])

  useEffect(() => {
    if (!selectedFiscalYear || selectedFiscalYear.id === 0) {
      return
    }
    dispatch(selectFiscalYearAction(selectedFiscalYear.id))
  }, [dispatch, selectedFiscalYear])

  type Following = "next" | "previous"
  const selectPreviousFiscalYear = () => selectFollowingFiscalYear("previous")
  const selectNextFiscalYear = () => selectFollowingFiscalYear("next")
  const selectFollowingFiscalYear = (following: Following) => {
    if (!selectedFiscalYear) {
      return
    }
    const currentFYindex = Object.values(sortedFYs).findIndex(
      (fy: FiscalYear) => fy.id === selectedFiscalYear.id
    )
    const followingFY =
      following === "next"
        ? sortedFYs[currentFYindex + 1]
        : sortedFYs[currentFYindex - 1]
    if (followingFY) setSelectedFiscalYear(followingFY)
  }

  useEffect(() => {
    if (!hasSeenNotifs && !fromWritingPage) {
      if (
        (notifications[0] > 0 && notifications[1] > 0) ||
        notifications[0] > 0 ||
        notifications[1] > 0
      ) {
        dispatch(
          hasSeenWritings(
            notifications[0] && notifications[1]
              ? "prevAndNext"
              : notifications[0]
              ? "prev"
              : "next"
          )
        )
      }
    }
  }, [notifications, hasSeenNotifs, dispatch])

  return (
    <Ct.ColumnCenterCenter>
      <Content>
        <Ct.Spacer />

        <Ct.SpacedBetweenCenter>
          {notifications[0] ? (
            <>
              <ButtonLeft
                id="prevFiscalYear"
                onClick={() => {
                  selectPreviousFiscalYear()
                }}
                disabled={selectedFiscalYear === sortedFYs[0]}
                data-tip={intl.formatMessage({
                  id: "writings.tooltip.hover.previous",
                })}
              />
              <ReactTooltip
                delayShow={300}
                effect={"solid"}
                eventOff="mouseleave scroll mousewheel blur"
              />
              <SpotNotifLeft>{notifications[0]}</SpotNotifLeft>
            </>
          ) : (
            <>
              <ButtonLeft
                id="prevFiscalYear"
                onClick={() => {
                  selectPreviousFiscalYear()
                }}
                disabled={selectedFiscalYear === sortedFYs[0]}
              />
              <Ct.Spacer width={2.25} />
            </>
          )}
          <FiscalYearRange id={"fiscalYearRange"} data-cy={"fiscal-year-range"}>
            {convertToMonth(selectedFiscalYear?.beginExercise)} -{" "}
            {convertToMonth(selectedFiscalYear?.endExercise)}
          </FiscalYearRange>
          {notifications[1] ? (
            <>
              <SpotNotifRight>{notifications[1]}</SpotNotifRight>
              <ButtonRight
                id="nextFiscalYear"
                onClick={() => {
                  selectNextFiscalYear()
                }}
                disabled={
                  selectedFiscalYear === sortedFYs[sortedFYs.length - 1]
                }
                data-tip={intl.formatMessage({
                  id: "writings.tooltip.hover.next",
                })}
              />
              <ReactTooltip
                delayShow={300}
                effect={"solid"}
                eventOff="mouseleave scroll mousewheel blur"
              />
            </>
          ) : (
            <>
              <Ct.Spacer width={2.25} />
              <ButtonRight
                id="nextFiscalYear"
                onClick={() => {
                  selectNextFiscalYear()
                }}
                disabled={
                  selectedFiscalYear === sortedFYs[sortedFYs.length - 1]
                }
              />
            </>
          )}
        </Ct.SpacedBetweenCenter>

        <Ct.Spacer />
      </Content>
      <StyledToolTip
        disabled={
          !fromWritingPage ||
          (!notifications[0] && !notifications[1]) ||
          !notifsPresent
        }
      >
        <Ct.RowCenter>
          <BulbPurple />
        </Ct.RowCenter>
        <StyledPurpleText
          text={intl.formatMessage({
            id:
              notifications[0] > 0 && notifications[1] > 0
                ? "writings.tooltip.previous-and-next"
                : notifications[0]
                ? "writings.tooltip.previous"
                : "writings.tooltip.next",
          })}
          textStyle={{
            fontWeight: 400,
            fontSize: 2,
          }}
        />
      </StyledToolTip>
    </Ct.ColumnCenterCenter>
  )
}

const ToasterContainerStyled = () => {
  const intl = useIntl()
  return (
    <div>
      <TitleToast>
        {intl.formatMessage({
          id: "company-office.fiscal-years-picker.empty.title",
        })}
      </TitleToast>
      <BodyToast>
        {intl.formatMessage({
          id: "company-office.fiscal-years-picker.empty.body",
        })}
      </BodyToast>
    </div>
  )
}

const TitleToast = styled.h4`
  color: ${colors.navy};
  font-weight: 700;
`

const BodyToast = styled.p`
  font-size: 1.5rem;
  color: #6e7c98;
  font-weight: 500;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  border-radius: 1.25rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  flex-shrink: 0;
  width: 50rem;
`

interface ButtonProps {
  disabled: boolean
}

const ButtonCommon = css<ButtonProps>`
  cursor: pointer;
  & path {
    fill: ${({ disabled }) => (disabled ? colors.hawkes : "")};
  }

  :focus {
    outline: 0;
  }
`

const ButtonLeft = styled(ChevronLeft)`
  ${ButtonCommon}
`
const ButtonRight = styled(ChevronRight)`
  ${ButtonCommon}
`

const FiscalYearRange = styled.h3`
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: ${colors.navy};
  text-transform: capitalize;
  line-height: 4rem;
  min-width: 32rem;
  display: inline-flex;
  justify-content: space-between;
  flex-shrink: 0;
`

interface StyledDivProps {
  disabled?: boolean
}

const StyledToolTip = styled.div<StyledDivProps>`
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  display: ${({ disabled }) => (disabled ? "none" : "flex")};
`
const BulbPurple = styled(Bulb)`
  & path {
    fill: ${colors.purple};
  }
`
const StyledPurpleText = styled(Ct.Text)`
  padding-top: 2px;
  margin-left: 3px;
  color: ${colors.purple};
`
