import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { Alert } from "../Commons/Alert"
import { createWritingArchiveThunk } from "../../store/ducks/writings.ducks"
import { colors, sizes } from "../../styles/design.config"
import { useEffect, useState } from "react"
import { useRNBSelector } from "../../store/rootReducer"
import { isOnboardingFinishedThunk } from "../../store/ducks/companySettings.ducks"
import ReactTooltip from "react-tooltip"
import styled from "styled-components/macro"
import { Switch } from "../Commons/Switch"

interface ConfirmVerificationModalModalProps extends Ct.ModalComponentsProps {
  onVerifyWritings: () => void
  selectedFiscalYearId: number
  selectedCompanyId: number
  sendToApi: (selectedSendToApi: boolean) => void
}

export const ConfirmVerificationModalModal = ({
  isDisplayed,
  onClose,
  selectedFiscalYearId,
  selectedCompanyId,
  onVerifyWritings,
  sendToApi,
}: ConfirmVerificationModalModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [selectedSendToApi, setSelectedSendToApi] = useState(true)

  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )
  const isConnectedToApi = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]?.is_connected_to_api
  )

  useEffect(() => {
    if (selectedCompanyId)
      dispatch(isOnboardingFinishedThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card width="109rem">
          <Ct.CloseCross
            onClick={() => {
              onClose()
            }}
          />
          <Ct.Title
            text={intl.formatMessage({
              id: "writings.confirm-verification.title",
            })}
          />
          <Ct.Spacer height={4} />
          <Alert alertType="info">
            <Ct.Text
              text={intl.formatMessage({
                id: "writings.confirm-verification.description.1",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "writings.confirm-verification.description.2",
              })}
              textStyle={{ lineHeight: 3 }}
            />
          </Alert>
          {isConnectedToApi && (
            <>
              <Ct.Spacer height={4} />
              <Wrapper>
                <Switch
                  value={selectedSendToApi}
                  onToggle={() => {
                    setSelectedSendToApi(!selectedSendToApi)
                  }}
                  size={"normal"}
                />
                <Ct.Spacer width={2} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: `writings.send-to-api.${selectedSendToApi}`,
                  })}
                  textStyle={{
                    fontSize: 2,
                  }}
                />
              </Wrapper>
            </>
          )}
          <Ct.Spacer height={4} />
          <Ct.SpacedBetweenCenter>
            <ReactTooltip
              effect={"solid"}
              backgroundColor={colors.lavender}
              textColor={colors.navy}
              borderColor={colors.desertStorm}
            />
            <Ct.Button
              width={sizes.button.standard}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              label={intl.formatMessage({
                id: "writings.confirm-verification.button.verify",
              })}
              onClick={() => {
                onVerifyWritings()
                if (isConnectedToApi) {
                  sendToApi(selectedSendToApi)
                }
                onClose()
              }}
              disabled={companyOnboarding === "unfinished"}
            />
            <Ct.Spacer height={4} />
            <Ct.Button
              width={sizes.button.standard}
              label={intl.formatMessage({
                id: "writings.confirm-verification.button.generate",
              })}
              onClick={() => {
                if (isConnectedToApi) {
                  dispatch(
                    createWritingArchiveThunk(
                      selectedFiscalYearId,
                      selectedCompanyId,
                      selectedSendToApi
                    )
                  )
                } else {
                  dispatch(
                    createWritingArchiveThunk(
                      selectedFiscalYearId,
                      selectedCompanyId
                    )
                  )
                }

                onClose()
              }}
            />
          </Ct.SpacedBetweenCenter>
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
`
