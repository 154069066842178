import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import {
  CreateWritingLabelRuleThunk,
  Field,
  WritingLabelRule,
} from "../../store/ducks/writingLabels.ducks"
import { Select } from "../Commons/Select"
import { Modal } from "../Commons/Modal"
import { Fragment, useEffect, useState } from "react"
import { Alert } from "../Commons/Alert"
import { Input } from "../Commons/Input"
import { Button } from "../Commons/Button"
import { ReactComponent as Remove } from "../../assets/cross-white-full.svg"
import { Checkbox } from "../Commons/Checkbox"
import {
  ArrayBuyOrSell,
  ArrayCropType,
  ArrayDocumentType,
  ArraySelectableValues,
  ArraySeparator,
  CropType,
  FreeText,
  removeSpacesFromString,
  RuleValue,
  SelectableValues,
  Separators,
  WritingLabelBuyOrSell,
  WritingLabelDocumentType,
} from "../../utils/writingLabelRule"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"

/* eslint-disable camelcase */

interface CreateOrEditWritingLabelProps extends Ct.ModalComponentsProps {
  field: Field
  fieldName: string
  createOrEdit: "create" | "edit"
  rule?: WritingLabelRule
}

export const CreateOrEditWritingLabelModal = ({
  onClose,
  isDisplayed,
  field,
  fieldName,
  createOrEdit,
  rule,
}: CreateOrEditWritingLabelProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const [documentType, setDocumentType] = useState<
    Ct.Option<WritingLabelDocumentType>
  >({
    value: "all",
    label: "Tous",
  })
  const [buyOrSell, setBuyOrSell] = useState<Ct.Option<WritingLabelBuyOrSell>>({
    value: "all",
    label: "Tous",
  })
  const [selectedValues, setSelectedValues] = useState<Ct.Option<RuleValue>[]>(
    []
  )
  const [freeText, setFreeText] = useState<string>("")
  const [selectedFreeText, setSelectedFreeText] = useState<FreeText>({
    value: "free_text",
    label: "",
  })
  const [selectedCrop, setSelectedCrop] = useState<CropType | null>(null)
  const [buySellOptions, setBuySellOptions] = useState<
    Ct.Option<WritingLabelBuyOrSell>[]
  >(
    ArrayBuyOrSell.map((o) => ({
      value: o,
      label: intl.formatMessage({
        id: `office-company.writing-labels.table.buy-or-sell.${o}`,
      }),
      disabled: false,
    }))
  )

  const documentTypeOption: Ct.Option<string>[] = ArrayDocumentType.map(
    (type) => ({
      value: type,
      label: intl.formatMessage({
        id: `office-company.writing-labels.table.document-type.${type}`,
      }),
    })
  )
  const ValuesOptions: Ct.Option<SelectableValues>[] =
    ArraySelectableValues.map((o) => ({
      value: o,
      label: intl.formatMessage({
        id: `office-company.writing-labels.table.values.${o}`,
      }),
    }))
  const SeparatorsOptions: Ct.Option<Separators>[] = ArraySeparator.map(
    (o) => ({
      value: o,
      label: intl.formatMessage({
        id: `office-company.writing-labels.table.values.${o}`,
      }),
    })
  )
  const CropOptions: { value: CropType; label: string }[] = ArrayCropType.map(
    (o) => ({
      value: o as CropType,
      label: intl.formatMessage({
        id: `office-company.writing-labels.table.values.${o}`,
      }),
    })
  )

  useEffect(() => {
    if (createOrEdit === "edit" && rule) {
      setDocumentType({
        value: rule.document_type,
        label: intl.formatMessage({
          id: `office-company.writing-labels.table.document-type.${rule.document_type}`,
        }),
      })
      setBuyOrSell({
        value: rule.buy_or_sell,
        label: intl.formatMessage({
          id: `office-company.writing-labels.table.buy-or-sell.${rule.buy_or_sell}`,
        }),
      })
      setSelectedCrop(rule.crop)
      setSelectedValues(
        rule.values.map((o) => ({
          value: o,
          label:
            o === "free_text"
              ? rule.free_text || ""
              : intl.formatMessage({
                  id: `office-company.writing-labels.table.values.${o}`,
                }),
        }))
      )
      setSelectedFreeText({
        value: "free_text",
        label: rule.free_text ? rule.free_text : "",
      })
      setFreeText(rule.free_text ? rule.free_text : "")
    }
  }, [createOrEdit, rule])

  useEffect(() => {
    setBuySellOptions(
      ArrayBuyOrSell.map((o) => ({
        value: o,
        label: intl.formatMessage({
          id: `office-company.writing-labels.table.buy-or-sell.${o}`,
        }),
        disabled: o === "sell" && documentType.value === "bill_of_exchange",
      }))
    )
  }, [documentType, buyOrSell])

  const areArraysEqual = (array1: RuleValue[], array2: string[]) => {
    if (array1.length !== array2.length) {
      return false
    }
    return array1.every((element) => array2.includes(element))
  }

  const hasValueChanged =
    createOrEdit === "create"
      ? true
      : rule?.document_type !== documentType.value ||
        rule?.buy_or_sell !== buyOrSell.value ||
        rule?.crop !== selectedCrop ||
        (rule?.free_text ? rule?.free_text : "") !== selectedFreeText.label ||
        !(
          selectedValues &&
          areArraysEqual(
            rule?.values,
            selectedValues?.map((v) => v.value)
          )
        )

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      top="3vh"
      left="10%"
      right="10%"
      height={"90%"}
    >
      <StyledCard width={"100%"} height={"100%"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Text
          text={intl.formatMessage({
            id: `office-company.writing-labels.${createOrEdit}.title`,
          })}
          textStyle={{
            fontSize: 5,
            fontWeight: 600,
          }}
        />
        <Ct.Spacer height={2} />
        <Line />
        <Ct.Spacer height={2} />

        <Wrapper>
          <WrapperScroll>
            <Ct.Text
              text={intl.formatMessage({
                id: `office-company.writing-labels.modal-create-edit.part-one.title`,
              })}
              textStyle={{
                fontSize: 2,
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            />
            <Ct.Spacer height={3} />

            <SelectWrapper>
              <Select
                intl={intl}
                disabled={false}
                value={documentType}
                options={documentTypeOption}
                domain={"office-company.writing-labels.table.title"}
                optionType={"document-type"}
                onChangeCallback={(value) => {
                  if (
                    value.value === "bill_of_exchange" &&
                    buyOrSell.value === "sell"
                  ) {
                    setBuyOrSell({
                      value: "all",
                      label: "Tous",
                    })
                  }
                  setDocumentType(value as Ct.Option<WritingLabelDocumentType>)
                }}
              />

              <Ct.Spacer width={2} />

              <Select
                intl={intl}
                disabled={false}
                value={buyOrSell}
                options={buySellOptions}
                domain={"office-company.writing-labels.table.title"}
                optionType={"buy-or-sell"}
                onChangeCallback={(value) => {
                  if (
                    documentType.value === "bill_of_exchange" &&
                    value.value === "sell"
                  ) {
                    return
                  }
                  setBuyOrSell(value as Ct.Option<WritingLabelBuyOrSell>)
                }}
              />
            </SelectWrapper>

            <Ct.Spacer height={4} />
            <Ct.Text
              text={intl.formatMessage({
                id: `office-company.writing-labels.modal-create-edit.part-two.title`,
              })}
              textStyle={{
                fontSize: 2,
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            />

            <Ct.Spacer height={2} />

            <Alert alertType="bulb">
              <Ct.Text
                text={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.part-two.alert1`,
                })}
              />
              <Ct.Spacer height={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.part-two.alert2`,
                })}
              />
            </Alert>

            <Ct.Spacer height={3} />
            <WrapperValues>
              <StyledText
                text={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.part-two.values`,
                })}
                width={"8%"}
              />

              <BlockValues>
                {ValuesOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                ).map((value) => (
                  <Fragment key={value.value}>
                    <Values
                      onClick={() => {
                        if (
                          selectedValues?.some((v) => v.value === value.value)
                        ) {
                          return
                        }
                        setSelectedValues(
                          (prevSelectedValues: Ct.Option<RuleValue>[]) => {
                            const updatedSelectedValues =
                              prevSelectedValues || []
                            return [...updatedSelectedValues, value]
                          }
                        )
                      }}
                      disabled={selectedValues?.some(
                        (v) => v.value === value.value
                      )}
                      cursor={true}
                    >
                      <Ct.Text
                        text={value.label}
                        textStyle={{
                          color: selectedValues?.some(
                            (v) => v.value === value.value
                          )
                            ? "moon"
                            : "cornflower",
                          cursor: "pointer",
                          fontWeight: 500,
                        }}
                      />
                    </Values>
                  </Fragment>
                ))}
              </BlockValues>
            </WrapperValues>
            <Ct.Spacer height={1} />

            <WrapperInput>
              <Input
                value={freeText}
                label={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.part-two.free-text`,
                })}
                onChange={(e) => {
                  setFreeText(e.target.value.replace(/ {2}/g, " "))
                }}
                disabled={!!selectedFreeText.label}
              />
              <Ct.Spacer width={3} />
              <Button
                label={intl.formatMessage({
                  id: "office-company.writing-labels.modal-create-edit.part-two.add-free-text",
                })}
                onClick={() => {
                  setSelectedFreeText({ value: "free_text", label: freeText })
                  setSelectedValues(
                    (prevSelectedValues: Ct.Option<RuleValue>[]) => {
                      const updatedSelectedValues = prevSelectedValues || []
                      const freeTextOption: FreeText = {
                        value: "free_text",
                        label: freeText,
                      }
                      return [...updatedSelectedValues, freeTextOption]
                    }
                  )
                }}
                disabled={freeText.length === 0 || !!selectedFreeText.label}
              />
            </WrapperInput>

            <Ct.Spacer height={3} />

            <WrapperValues>
              <StyledText
                text={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.part-two.separators`,
                })}
                width={"8%"}
              />

              <BlockValues>
                {SeparatorsOptions.map((value) => (
                  <Fragment key={value.value}>
                    <Values
                      onClick={() => {
                        setSelectedValues(
                          (prevSelectedValues: Ct.Option<RuleValue>[]) => {
                            const updatedSelectedValues =
                              prevSelectedValues || []
                            return [...updatedSelectedValues, value]
                          }
                        )
                      }}
                      cursor={true}
                    >
                      <Ct.Text
                        text={value.label}
                        textStyle={{
                          color: "cornflower",
                          cursor: "pointer",
                          fontWeight: 500,
                        }}
                      />
                    </Values>
                  </Fragment>
                ))}
              </BlockValues>
            </WrapperValues>
            <Ct.Spacer height={3} />

            <WrapperBlock>
              <BlockSelectedValues>
                {!selectedValues || selectedValues?.length === 0 ? (
                  <CenteredCentered>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: `office-company.writing-labels.modal-create-edit.part-two.empty-values`,
                      })}
                      textStyle={{
                        color: "rock",
                      }}
                    />
                  </CenteredCentered>
                ) : (
                  <>
                    <BlockValues>
                      {selectedValues.map((value, index) => (
                        <Fragment key={value.value}>
                          <Values>
                            <Ct.Text
                              text={value.label}
                              textStyle={{
                                color: "cornflower",
                                fontWeight: 500,
                              }}
                            />
                          </Values>
                          <span
                            onClick={() => {
                              if (value.value === "free_text") {
                                setSelectedFreeText({
                                  value: "free_text",
                                  label: "",
                                })
                              }
                              setSelectedValues(
                                selectedValues.filter(
                                  (val, idx) => idx !== index
                                )
                              )
                            }}
                          >
                            <StyledRemove />
                          </span>
                        </Fragment>
                      ))}
                    </BlockValues>
                  </>
                )}
              </BlockSelectedValues>

              <Ct.Spacer height={0} width={1} />

              <Button
                label={intl.formatMessage({
                  id: `office-company.writing-labels.modal.delete-all`,
                })}
                disabled={!selectedValues || selectedValues.length === 0}
                onClick={() => {
                  setSelectedValues([])
                  setFreeText("")
                  setSelectedFreeText({ value: "free_text", label: "" })
                }}
              />
            </WrapperBlock>

            <Ct.Spacer />

            <PreviewHeader>
              <Ct.Text
                text={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.preview`,
                })}
                textStyle={{
                  fontSize: 1.75,
                  fontWeight: 600,
                }}
              />
            </PreviewHeader>
            <PreviewBody>
              {selectedValues &&
                selectedValues.length > 0 &&
                selectedValues.map((value) => (
                  <>
                    <Ct.Text
                      text={
                        value.value === "space"
                          ? " "
                          : removeSpacesFromString(value.label)
                      }
                      textStyle={{
                        fontSize: 1.75,
                      }}
                    />
                    {value.value === "space" && (
                      <Ct.Spacer height={0} width={1} />
                    )}
                  </>
                ))}
            </PreviewBody>
            <Ct.Spacer height={4} />

            {field && field.max_length && (
              <>
                <Ct.Text
                  text={intl.formatMessage({
                    id: `office-company.writing-labels.modal-create-edit.part-three.title`,
                  })}
                  textStyle={{
                    fontSize: 2,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                />
                <Ct.Spacer height={3} />
                <Ct.Text
                  text={intl.formatMessage(
                    {
                      id: `office-company.writing-labels.modal-create-edit.part-three.text1`,
                    },
                    { max_length: field.max_length }
                  )}
                />
                <Ct.Spacer height={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: `office-company.writing-labels.modal-create-edit.part-three.text2`,
                  })}
                />
                <Ct.Spacer height={3} />
                <Centered>
                  {CropOptions.map((o) => (
                    <Fragment key={o.value}>
                      <WrapperValues>
                        <Checkbox
                          id={o.value}
                          label={""}
                          isChecked={selectedCrop === o.value}
                          value={selectedCrop === o.value}
                          onChange={() => {
                            setSelectedCrop(o.value)
                          }}
                        />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: `office-company.writing-labels.modal-create-edit.crop-${o.value}`,
                          })}
                        />
                      </WrapperValues>
                      <Ct.Spacer height={0} width={3} />
                    </Fragment>
                  ))}
                </Centered>
              </>
            )}

            <Ct.Spacer height={4} />
            <Centered>
              <Button
                label={intl.formatMessage({
                  id: `office-company.writing-labels.modal-create-edit.create-label`,
                })}
                width={35}
                disabled={
                  (field?.max_length !== null && !selectedCrop) ||
                  selectedValues?.length === 0 ||
                  !hasValueChanged
                }
                onClick={() => {
                  if (!selectedCompanyId || selectedValues.length === 0) return
                  dispatch(
                    CreateWritingLabelRuleThunk(selectedCompanyId, {
                      document_type: documentType.value,
                      buy_or_sell: buyOrSell.value,
                      values: selectedValues.map((v) => v.value),
                      field: fieldName,
                      free_text: selectedFreeText.label,
                      crop: selectedCrop,
                      id: 0,
                    })
                  )
                  onClose()
                }}
              />
            </Centered>
          </WrapperScroll>
        </Wrapper>
      </StyledCard>
    </Modal>
  )
}

const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  overflow: auto;
`
const WrapperScroll = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 2rem;
`
const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
`
const WrapperValues = styled.div`
  display: flex;
`
const BlockValues = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Values = styled.div<{ disabled?: boolean; cursor?: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? colors.desertStorm : colors.mist};
  padding: 0.5rem 1rem;
  margin: 0rem 1rem 2rem 1rem;
  border-radius: 0.5rem;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "initial")};
`
const StyledText = styled((props) => <Ct.Text {...props} />)<{
  width: boolean
}>`
  width: ${({ width }) => width};
`
const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10rem;
`
const BlockSelectedValues = styled.div`
  height: 15rem;
  border: 1px dashed ${colors.rock};
  border-radius: 2rem;
  padding: 2rem 0;
  overflow: auto;
  width: 100%;
`
const CenteredCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const StyledRemove = styled(({ ...props }) => <Remove {...props} />)`
  position: relative;
  right: 2rem;
  bottom: 1rem;
  cursor: pointer;
`
const PreviewHeader = styled.div`
  background-color: ${colors.lavender};
  padding: 1rem 3rem;
  border-radius: 1rem 1rem 0 0;
`
const PreviewBody = styled.div`
  border: 1px solid ${colors.lavender};
  border-radius: 0 0 1rem 1rem;
  padding: 2rem 3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const WrapperBlock = styled.div`
  display: flex;
  justify-content: flex-start;
`
