import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import * as Ct from "ldlj"
import { MouseEvent } from "react"

interface RowCheckboxProps {
  label: string
  name: string
  isChecked: boolean
  onChange: (e: MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
}

export const RowCheckbox = ({
  label,
  name = "",
  isChecked,
  onChange,
  disabled,
}: RowCheckboxProps) => {
  const detectOtherData = (label: string) => {
    if (!label.includes("/")) {
      return <span>{label}</span>
    }
    const parts = label.split("/")
    return (
      <span>
        {parts[0]} <StyledOtherData>{parts[1]}</StyledOtherData>
      </span>
    )
  }

  return (
    <>
      <HiddenInput
        type="checkbox"
        id={name}
        name={name}
        defaultChecked={isChecked}
      />
      <StyledInput
        onClick={(e) => {
          onChange(e)
        }}
      >
        <StyledInputLabel disabled={disabled}>
          {detectOtherData(label)}
        </StyledInputLabel>
        <Ct.Spacer height={0} width={1} />
        <StyledSquare checked={isChecked} disabled={disabled}>
          {isChecked && (
            <StyledChecked>
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.36023 3.2801C1.96718 2.89211 1.33403 2.89621 0.946044 3.28925C0.558057 3.6823 0.562157 4.31545 0.955202 4.70344L3.49616 7.2117C3.91418 7.62434 4.59615 7.58954 4.96999 7.13648L9.0957 2.13648C9.4472 1.71049 9.38682 1.08021 8.96083 0.728708C8.53484 0.377208 7.90456 0.437592 7.55306 0.863579L4.12315 5.02033L2.36023 3.2801Z" />
              </svg>
            </StyledChecked>
          )}
        </StyledSquare>
      </StyledInput>
    </>
  )
}

interface CheckedProp {
  checked: boolean
  disabled?: boolean
}

interface Props {
  disabled?: boolean
}

const HiddenInput = styled.input`
  display: none;
`
const StyledInput = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  height: 100%;
`
const StyledSquare = styled.div<CheckedProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 2rem;
  width: 2rem;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) =>
    props.checked
      ? colors.shamrock
      : props.disabled
      ? colors.white
      : colors.rock};
  border-radius: 0.5rem;
  flex-shrink: 0;
`
const StyledInputLabel = styled.span<Props>`
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  font-family: "Roboto", sans-serif;
  color: ${({ disabled }) =>
    disabled ? `${colors.hawkes}` : `${colors.navy}`};
  font-weight: 400;
  font-size: 1.75rem;
  user-select: none;
`

const StyledChecked = styled.div`
  display: flex;
  user-select: none;
  & svg {
    fill: ${colors.white};
  }
`

const StyledOtherData = styled.span`
  color: ${colors.cornflower};
  font-style: italic;
`
