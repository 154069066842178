import axios from "axios"
import { chunkArray } from "../../utils/array"
import { wait } from "../../utils/asyncTools"
import { RootState } from "../rootReducer"
import { Dispatch } from "../store.config"
import { getUrlFailureAction } from "./invoicing.duck"

const enum BatchesForCompanyActionsEnum {
  GET_BATCHES_FOR_COMPANY_ATTEMPT = "BATCHES_FOR_COMPANY/getBatchesForCompanyAttempt",
  GET_BATCHES_FOR_COMPANY_SUCCESS = "BATCHES_FOR_COMPANY/getBatchesForCompanySuccess",
  GET_BATCHES_FOR_COMPANY_ERROR = "BATCHES_FOR_COMPANY/getBatchesForCompanyError",

  GET_SINGLE_BATCH_ATTEMPT = "BATCHES_FOR_COMPANY/getSingleBatchAttempt",
  GET_SINGLE_BATCH_SUCCESS = "BATCHES_FOR_COMPANY/getSingleBatchSuccess",
  GET_SINGLE_BATCH_ERROR = "BATCHES_FOR_COMPANY/getSingleBatchError",

  UPDATE_BATCH_RESET = "BATCHES_FOR_COMPANY/updateBatchReset",
  UPDATE_BATCH_ATTEMPT = "BATCHES_FOR_COMPANY/updateBatchAttempt",
  UPDATE_BATCH_SUCCESS = "BATCHES_FOR_COMPANY/updateBatchSuccess",
  UPDATE_BATCH_ERROR = "BATCHES_FOR_COMPANY/updateBatchError",

  DEACTIVATE_BATCH_RESET = "BATCHES_FOR_COMPANY/deactivateBatchReset",
  DEACTIVATE_BATCH_ATTEMPT = "BATCHES_FOR_COMPANY/deactivateBatchAttempt",
  DEACTIVATE_BATCH_SUCCESS = "BATCHES_FOR_COMPANY/deactivateBatchSuccess",
  DEACTIVATE_BATCH_ERROR = "BATCHES_FOR_COMPANY/deactivateBatchError",

  UPDATE_FULL_DOCUMENTS_NAME = "BATCHES_FOR_COMPANY/updateFullDocumentName",
}

export const getBatchesForCompanyAttemptAction = (payload: {
  companyId: string
}) =>
  ({
    type: BatchesForCompanyActionsEnum.GET_BATCHES_FOR_COMPANY_ATTEMPT,
    payload,
  } as const)
export const getBatchesForCompanySuccessAction = (payload: {
  batches: BatchesForCompanyResponse[]
  companyId: string
}) =>
  ({
    type: BatchesForCompanyActionsEnum.GET_BATCHES_FOR_COMPANY_SUCCESS,
    payload,
  } as const)
export const getBatchesForCompanyErrorAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.GET_BATCHES_FOR_COMPANY_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-drop-document.get.error.title",
      bodyKey: "company-drop-document.get.error.body",
    },
  } as const)

export const getSingleBatchAttemptAction = (payload: {
  batchDocumentId: number
}) =>
  ({
    type: BatchesForCompanyActionsEnum.GET_SINGLE_BATCH_ATTEMPT,
    payload,
  } as const)
export const getSingleBatchSuccessAction = (payload: {
  batch: getSingleBatchAPI
}) =>
  ({
    type: BatchesForCompanyActionsEnum.GET_SINGLE_BATCH_SUCCESS,
    payload,
  } as const)
export const getSingleBatchErrorAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.GET_SINGLE_BATCH_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-drop-document.get.error.title",
      bodyKey: "company-drop-document.get.error.body",
    },
  } as const)

export const updateBatchSuccessAction = ({
  batchDocumentId,
  newName,
  companyId,
}: {
  companyId: number
  batchDocumentId: number
  newName: string
}) =>
  ({
    type: BatchesForCompanyActionsEnum.UPDATE_BATCH_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "company-drop-document.rename.success.title",
      bodyKey: "company-drop-document.rename.success.body",
    },
    payload: {
      batchDocumentId,
      newName,
      companyId,
    },
  } as const)
export const updateBatchAttemptAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.UPDATE_BATCH_ATTEMPT,
  } as const)
export const updateBatchResetAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.UPDATE_BATCH_RESET,
  } as const)
export const updateBatchErrorAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.UPDATE_BATCH_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "company-drop-document.rename.error.title",
      bodyKey: "company-drop-document.rename.error.body",
    },
  } as const)

export const deactivateBatchSuccessAction = ({
  documentIds,
  companyId,
}: {
  documentIds: number[]
  companyId: number
}) =>
  ({
    type: BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_SUCCESS,
    payload: {
      documentIds,
      companyId,
    },
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `batch_document.toaster.deactivate.success.title`,
      bodyKey: `batch_document.toaster.deactivate.success.body`,
    },
  } as const)
export const deactivateBatchResetAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_RESET,
  } as const)
export const deactivateBatchAttemptAction = () =>
  ({
    type: BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_ATTEMPT,
  } as const)
export const deactivateBatchErrorAction = (showToast: boolean = true) =>
  ({
    type: BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_ERROR,
    withToast: showToast,
    toasterType: "error",
    message: {
      titleKey: "company-drop-document.deactivate.error.title",
      bodyKey: "company-drop-document.deactivate.error.body",
    },
  } as const)

export const updateFullDocumentNameSuccess = () =>
  ({ type: BatchesForCompanyActionsEnum.UPDATE_FULL_DOCUMENTS_NAME } as const)

type BatchesForCompanyActionsType = ReturnType<
  | typeof getBatchesForCompanyAttemptAction
  | typeof getBatchesForCompanySuccessAction
  | typeof getBatchesForCompanyErrorAction
  | typeof updateBatchResetAction
  | typeof updateBatchAttemptAction
  | typeof updateBatchSuccessAction
  | typeof updateBatchErrorAction
  | typeof deactivateBatchResetAction
  | typeof deactivateBatchAttemptAction
  | typeof deactivateBatchSuccessAction
  | typeof deactivateBatchErrorAction
  | typeof updateFullDocumentNameSuccess
  | typeof getSingleBatchAttemptAction
  | typeof getSingleBatchSuccessAction
  | typeof getSingleBatchErrorAction
>

interface Batches {
  [index: string]: BatchDocument
}

export interface BatchDocument {
  id: number
  originalFileName: string
  fingerprint: string
  email: string | null
  createdAt: string
  duplicate: boolean
  exchange: boolean
  userBatchName: string | null
  writing_validated_at: string
  captureEmail: string | null
  get_url: string | null
  image_data: string | null
  toDeactivate?: boolean
}
export const batchDocumentsInitialState: BatchesForCompanyState = {
  batchesFetchStatus: "idle",
  batchesUpdateStatus: "idle",
  batchesDeactivateStatus: "idle",
  batchesByCompany: {},
}

export interface BatchesForCompanyState {
  batchesFetchStatus: "loading" | "success" | "error" | "idle"
  batchesUpdateStatus: "loading" | "success" | "error" | "idle"
  batchesDeactivateStatus: "loading" | "success" | "error" | "idle"
  batchesByCompany: { [index: string]: Batches }
}

export function batchDocumentsReducer(
  state = batchDocumentsInitialState,
  action: BatchesForCompanyActionsType
): BatchesForCompanyState {
  switch (action.type) {
    case BatchesForCompanyActionsEnum.GET_BATCHES_FOR_COMPANY_ATTEMPT:
      return {
        ...state,
        batchesFetchStatus: "loading",
        batchesByCompany: {
          ...state.batchesByCompany,
          [action.payload.companyId]: {},
        },
      }
    case BatchesForCompanyActionsEnum.GET_BATCHES_FOR_COMPANY_SUCCESS: {
      const batches = action.payload.batches.reduce(
        (batches, batch) => ({
          ...batches,
          [batch.id]: {
            id: batch.id,
            originalFileName: batch.original_file_name,
            email: batch.email,
            createdAt: batch.created_at,
            duplicate: batch.duplicate,
            exchange: batch.exchange,
            userBatchName: batch.user_batch_name,
            writing_validated_at: batch.writing_validated_at,
            captureEmail: batch.capture_email,
            fingerprint: batch.fingerprint,
            get_url:
              batches[batch.id] && batches[batch.id].get_url
                ? batches[batch.id].get_url
                : null,
          },
        }),
        state.batchesByCompany[action.payload.companyId]
      )

      return {
        ...state,
        batchesFetchStatus: "success",
        batchesByCompany: {
          ...state.batchesByCompany,
          [action.payload.companyId]: batches,
        },
      }
    }
    case BatchesForCompanyActionsEnum.GET_SINGLE_BATCH_SUCCESS: {
      const batches = state.batchesByCompany[action.payload.batch.company_id]
      const oldBatch = batches[action.payload.batch.id]
      const newBatch = {
        ...oldBatch,
        id: action.payload.batch.id,
        originalFileName: action.payload.batch.original_file_name,
        email: action.payload.batch.email,
        createdAt: action.payload.batch.created_at.split("T")[0],
        duplicate: action.payload.batch.duplicate,
        exchange: action.payload.batch.exchange,
        userBatchName: action.payload.batch.user_batch_name,
        writing_validated_at: action.payload.batch.writing_validated_at,
        captureEmail: action.payload.batch.capture_email,
        get_url: action.payload.batch.get_url,
      }
      const newBatches = { ...batches, [action.payload.batch.id]: newBatch }

      return {
        ...state,
        batchesByCompany: {
          ...state.batchesByCompany,
          [action.payload.batch.company_id]: newBatches,
        },
      }
    }
    case BatchesForCompanyActionsEnum.UPDATE_BATCH_RESET:
      return { ...state, batchesUpdateStatus: "idle" }
    case BatchesForCompanyActionsEnum.UPDATE_BATCH_ATTEMPT:
      return { ...state, batchesUpdateStatus: "loading" }
    case BatchesForCompanyActionsEnum.UPDATE_BATCH_ERROR:
      return { ...state, batchesUpdateStatus: "error" }
    case BatchesForCompanyActionsEnum.UPDATE_BATCH_SUCCESS:
      return {
        ...state,
        batchesUpdateStatus: "success",
        batchesByCompany: {
          ...state.batchesByCompany,
          [action.payload.companyId]: {
            ...state.batchesByCompany[action.payload.companyId],
            [action.payload.batchDocumentId]: {
              ...state.batchesByCompany[action.payload.companyId][
                action.payload.batchDocumentId
              ],
              userBatchName: action.payload.newName,
            },
          },
        },
      }
    case BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_RESET: {
      return {
        ...state,
        batchesDeactivateStatus: "idle",
      }
    }
    case BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_ATTEMPT: {
      return {
        ...state,
        batchesDeactivateStatus: "loading",
      }
    }
    case BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_SUCCESS: {
      const batchDocuments = {
        ...state.batchesByCompany[action.payload.companyId],
      }

      const batchesWithoutDeactivated = Object.fromEntries(
        Object.entries(batchDocuments).filter(
          ([key]) => !action.payload.documentIds.includes(Number(key))
        )
      )

      return {
        ...state,
        batchesDeactivateStatus: "success",
        batchesByCompany: {
          ...state.batchesByCompany,
          [action.payload.companyId]: {
            ...batchesWithoutDeactivated,
          },
        },
      }
    }
    case BatchesForCompanyActionsEnum.DEACTIVATE_BATCH_ERROR: {
      return { ...state, batchesDeactivateStatus: "error" }
    }

    case BatchesForCompanyActionsEnum.UPDATE_FULL_DOCUMENTS_NAME: {
      return { ...state, batchesUpdateStatus: "success" }
    }
    default:
      return { ...state }
  }
}

interface getSingleBatchAPI {
  id: number
  original_file_name: string
  email: string | null
  created_at: string
  duplicate: boolean
  exchange: boolean
  extension: string
  user_batch_name: string | null
  writing_validated_at: string | null
  capture_email: string | null
  company_id: number
  get_url: string | null
}

export interface BatchesForCompanyResponse {
  id: number
  original_file_name: string
  user_batch_name: string | null
  email: string
  created_at: string
  duplicate: boolean
  exchange: boolean
  extension: string
  writing_validated_at: string
  capture_email: string | null
  fingerprint: string
}

export const getBatchesForCompanyThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch) => {
    dispatch(
      getBatchesForCompanyAttemptAction({ companyId: String(companyId) })
    )
    axios
      .get<BatchesForCompanyResponse[]>(
        `/batch_documents/batches_for_company`,
        { params: { company_id: companyId } }
      )
      .then(async ({ data }) => {
        const chunks = chunkArray(data, 200)
        if (chunks.length === 0) {
          dispatch(
            getBatchesForCompanySuccessAction({
              batches: [],
              companyId: String(companyId),
            })
          )
        }
        for (const chunk of chunks) {
          dispatch(
            getBatchesForCompanySuccessAction({
              batches: chunk,
              companyId: String(companyId),
            })
          )
          await wait(200)
        }
      })
      .catch(() => {
        dispatch(getBatchesForCompanyErrorAction())
      })
  }

export const renameBatchDocumentThunk =
  ({
    companyId,
    batchDocumentId,
    newName,
  }: {
    companyId: number
    batchDocumentId: number
    newName: string
  }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const fiduciary = getState().fiduciary

    dispatch(updateBatchAttemptAction())
    axios
      .put<{ status: "ok" }>(
        `/fiduciaries/${fiduciary.id}/companies/${companyId}/batch_documents/${batchDocumentId}`,
        {
          user_batch_name: newName,
        }
      )
      .then(() => {
        dispatch(
          updateBatchSuccessAction({
            batchDocumentId,
            newName,
            companyId,
          })
        )
      })
      .catch(() => {
        dispatch(updateBatchErrorAction())
      })
  }

export const deactivateBatchDocumentThunk =
  ({
    companyId,
    documentIdList,
  }: {
    companyId: number
    documentIdList: number[]
  }) =>
  (dispatch: Dispatch) => {
    dispatch(deactivateBatchAttemptAction())
    axios
      .put<{ status: "ok" }>(
        `/companies/${companyId}/batch_documents_user_deactivate`,
        documentIdList
      )
      .then(() => {
        dispatch(
          deactivateBatchSuccessAction({
            documentIds: documentIdList,
            companyId,
          })
        )
      })
      .catch(() => dispatch(deactivateBatchErrorAction()))
  }

export const getSingleBatchThunk =
  (batchDocumentId: number) => (dispatch: Dispatch) => {
    dispatch(getSingleBatchAttemptAction({ batchDocumentId: batchDocumentId }))
    axios
      .get<getSingleBatchAPI>(`batch_documents/get_single_batch`, {
        params: { id: batchDocumentId },
      })
      .then((response) =>
        dispatch(getSingleBatchSuccessAction({ batch: response.data }))
      )
  }

export const getUrlForBatchDocumentThunk =
  (batchDocumentId: number) => (dispatch: Dispatch) => {
    axios
      .get<string>("/batch_documents/get_url_for_batch_document", {
        params: { id: batchDocumentId },
      })
      .then((response) => {
        window.open(response.data, "_blank")
      })
      .catch(() => dispatch(getUrlFailureAction()))
  }
