import * as Ct from "ldlj"
import { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import ReactTooltip from "react-tooltip"
import styled from "styled-components/macro"
import {
  CreateMerchantCodeReset,
  CreateMerchantCodeThunk,
  MerchantCode,
} from "../../store/ducks/merchantCodes.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import {
  addPrefixToCode,
  normalizeMerchantCode,
} from "../../utils/merchantCodes"
import { capitalizeFirstLetter } from "../../utils/string"
import { LockableInput } from "../Commons/LockableInput"
import { MultiSelect } from "../Commons/MultiSelect"
import { ReactComponent as Warning } from "../../assets/warning-orange.svg"

interface CreateCodeProps extends Ct.ModalComponentsProps {
  companyId: number
}
export type optionType = { value: string; label: string }

export const CreateCodeModal = ({
  isDisplayed,
  onClose,
  companyId,
}: CreateCodeProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const company = useRNBSelector(
    (state) => state.companies.companies[companyId]
  )
  const merchantsOfCompany = useRNBSelector(
    (state) => state.merchantCodes.merchantsOfCompany
  )
  const createMerchantCodeStatus = useRNBSelector(
    (state) => state.merchantCodes.createMerchantCodeStatus
  )
  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )

  const [isCentralize, setIsCentralize] = useState<boolean>(false)
  const [selectForBuyDefault, setSelectForBuyDefault] = useState<boolean>(false)
  const [selectForSellDefault, setSelectForSellDefault] =
    useState<boolean>(false)
  const [buyDefaultForCompany, setBuyDefaultForCompany] =
    useState<MerchantCode>()
  const [sellDefaultForCompany, setSellDefaultForCompany] =
    useState<MerchantCode>()
  const [merchantCode, setMerchantCode] = useState<string>("")
  const [options, setOptions] = useState<Ct.Option<string>[]>()
  const [selection, setSelection] = useState<ReadonlyArray<optionType> | null>(
    []
  )
  const [displayErrorCentralize, setDisplayErrorCentralize] =
    useState<boolean>(false)
  const [prefixes, setPrefixes] = useState<{ buy: string; sell: string }>({
    buy: "",
    sell: "",
  })

  useEffect(() => {
    setPrefixes(addPrefixToCode(company))
  }, [company])

  const close = () => {
    if ((selection && selection.length > 0) || merchantCode !== "") {
      const warningMessage = intl.formatMessage({
        id: "accounting-plan.create-code.exit-message-confirm",
      })
      if (window.confirm(warningMessage)) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  const saveNewCode = () => {
    let merchantIds: number[] | [] = []
    if (selection && selection.length > 0) {
      merchantIds = selection.map((s) => Number(s.value))
    }

    dispatch(
      CreateMerchantCodeThunk(
        companyId,
        merchantCode,
        isCentralize,
        merchantIds,
        selectForSellDefault,
        selectForBuyDefault
      )
    )
  }

  useEffect(() => {
    setBuyDefaultForCompany(
      merchantCodes.find((c) => c.default_buy_code === true)
    )
    setSellDefaultForCompany(
      merchantCodes.find((c) => c.default_sell_code === true)
    )
  }, [merchantCodes])

  useEffect(() => {
    setOptions(
      merchantsOfCompany.map((m) => {
        return {
          value: String(m.id),
          label: m.code
            ? capitalizeFirstLetter(m.name) + (" / code: " + m.code)
            : capitalizeFirstLetter(m.name),
        }
      })
    )
  }, [merchantsOfCompany])

  useEffect(() => {
    if (!isCentralize && selection && selection.length > 1) {
      setDisplayErrorCentralize(true)
    } else {
      setDisplayErrorCentralize(false)
    }
  }, [isCentralize, selection])

  useEffect(() => {
    if (createMerchantCodeStatus === "success") {
      dispatch(CreateMerchantCodeReset())
      onClose()
    }
  }, [createMerchantCodeStatus])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(30vh - 25rem)`}
    >
      <StyledCard width={"140rem"} height={`75vh`}>
        <Header>
          <Ct.CloseCross onClick={close} />
          <Ct.Title
            text={intl.formatMessage({
              id: "accounting-plan.create-code.title",
            })}
            size={7}
          />

          <Ct.Spacer height={4} />
          <Ct.Separator size="full" />
          <Ct.Spacer height={4} />
        </Header>

        {/* PART ONE : CREATE CODE */}
        <Wrapper>
          <Ct.Spacer height={4} />
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.create-code.part-one.title",
            })}
            textStyle={{
              fontSize: 2,
              color: "navy",
              textTransform: "uppercase",
              fontWeight: 700,
            }}
          />

          <Ct.Spacer height={4} />

          <JustifyBetween>
            <Ct.Input
              value={merchantCode}
              onChange={(e) => {
                setMerchantCode(normalizeMerchantCode(e.target.value))
              }}
              label={intl.formatMessage({
                id: "accounting-plan.create-code.part-one.code-label",
              })}
            />

            <Ct.Spacer width={7} />

            <LockableInput
              label={intl.formatMessage(
                {
                  id: "accounting-plan.create-code.part-one.output-label",
                },
                { buyOrSell: " fournisseurs" }
              )}
              value={merchantCode}
              maxWidth={"35rem"}
              locked={true}
              readOnly={true}
              cursor={"default"}
              prefixString={prefixes.buy}
              padding={
                prefixes.buy && prefixes.buy.length > 0
                  ? `0 1.75rem 0 ${prefixes.buy.length + 2.5}rem`
                  : "0 1.75rem"
              }
            />

            <Ct.Spacer width={2} />

            <LockableInput
              label={intl.formatMessage(
                {
                  id: "accounting-plan.create-code.part-one.output-label",
                },
                { buyOrSell: " clients" }
              )}
              value={merchantCode}
              maxWidth={"35rem"}
              locked={true}
              readOnly={true}
              prefixString={prefixes.sell}
              cursor={"default"}
              padding={
                prefixes.sell && prefixes.sell.length > 0
                  ? `0 1.75rem 0 ${prefixes.sell.length + 2.5}rem`
                  : "0 1.75rem"
              }
            />
          </JustifyBetween>

          <Ct.Spacer height={4} />

          <SwitchWrapper>
            <Ct.Switch
              value={isCentralize}
              onToggle={() => {
                setIsCentralize(!isCentralize)
              }}
            />
            <Ct.Spacer width={2} />
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.create-code.part-one.switch-text",
              })}
              textStyle={{
                fontSize: 1.75,
                color: "navy",
              }}
            />
          </SwitchWrapper>
        </Wrapper>

        {/* PART TWO : ADD MERCHANTS */}
        <Wrapper>
          <Ct.Spacer height={4} />
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.create-code.part-two.title",
            })}
            textStyle={{
              fontSize: 2,
              color: "navy",
              textTransform: "uppercase",
              fontWeight: 700,
            }}
          />
          <Ct.Spacer height={displayErrorCentralize ? 1 : 4} />

          {displayErrorCentralize && !isCentralize && (
            <>
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.add-merchant.not-centralize-error",
                })}
                textStyle={{
                  color: "amaranth",
                  fontSize: 2,
                  fontWeight: 700,
                }}
              />
              <Ct.Spacer height={1} />
            </>
          )}

          {options && (
            <StyledMultiSelectContainer>
              <MultiSelect
                intl={intl}
                field={{}}
                options={options}
                value={selection}
                label={"details"}
                domain={`accounting-plan.add-merchant`}
                optionType={"drop-down-label"}
                onChange={(newValue) => {
                  if (!isCentralize && selection?.length === 1) {
                    setDisplayErrorCentralize(true)
                    return
                  }
                  setSelection(newValue as optionType[])
                }}
                canToggleAllOptions={true}
                allOptionDisabled={!isCentralize}
              />
            </StyledMultiSelectContainer>
          )}

          {options && options.length === selection?.length && (
            <>
              <Ct.Spacer />
              <WrapperWarning>
                <Warning />
                <Ct.Spacer height={0} width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "accounting-plan.create-code.warning-all-selected",
                  })}
                  textStyle={{
                    color: "orange",
                    fontSize: 1.75,
                    fontWeight: 700,
                  }}
                />
              </WrapperWarning>
            </>
          )}

          <Ct.Spacer height={3} />
          <RowFlex
            data-tip={
              !isCentralize && buyDefaultForCompany === undefined
                ? intl.formatMessage({
                    id: "accounting-plan.create-code.uniq-code.tooltip",
                  })
                : ""
            }
          >
            <Ct.Checkbox
              label={""}
              isChecked={selectForSellDefault}
              name=""
              onChange={() => {
                if (!isCentralize || !!buyDefaultForCompany) return
                setSelectForSellDefault(!selectForSellDefault)
              }}
              disabled={!isCentralize || !!buyDefaultForCompany}
            />

            <Ct.Text
              text={intl.formatMessage(
                {
                  id:
                    buyDefaultForCompany === undefined
                      ? "accounting-plan.create-code.uniq-code"
                      : "accounting-plan.create-code.uniq-code.already-has-for-company",
                },
                { type: "tous", code: buyDefaultForCompany?.code }
              )}
              textStyle={{
                color: "navy",
                fontSize: 1.75,
              }}
            />
            <Ct.Spacer width={0.5} />
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.create-code.uniq-code.buy",
              })}
              textStyle={{
                color: "orange",
                fontSize: 1.75,
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            />
          </RowFlex>

          {!isCentralize &&
            (buyDefaultForCompany === undefined ||
              sellDefaultForCompany === undefined) && (
              <ReactTooltip
                delayShow={300}
                effect={"solid"}
                eventOff="mouseleave scroll mousewheel blur"
              />
            )}
          <Ct.Spacer height={2} />

          <RowFlex
            data-tip={
              !isCentralize && sellDefaultForCompany === undefined
                ? intl.formatMessage({
                    id: "accounting-plan.create-code.uniq-code.tooltip",
                  })
                : ""
            }
          >
            <Ct.Checkbox
              label={""}
              isChecked={selectForBuyDefault}
              name=""
              onChange={() => {
                if (!isCentralize || !!sellDefaultForCompany) return
                setSelectForBuyDefault(!selectForBuyDefault)
              }}
              disabled={!isCentralize || !!sellDefaultForCompany}
            />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id:
                    sellDefaultForCompany === undefined
                      ? "accounting-plan.create-code.uniq-code"
                      : "accounting-plan.create-code.uniq-code.already-has-for-company",
                },
                { type: "toutes", code: sellDefaultForCompany?.code }
              )}
              textStyle={{
                color: "navy",
                fontSize: 1.75,
              }}
            />
            <Ct.Spacer width={0.5} />
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.create-code.uniq-code.sell",
              })}
              textStyle={{
                color: "brightSun",
                fontSize: 1.75,
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            />
          </RowFlex>
        </Wrapper>

        <Wrapper>
          <Ct.Spacer height={4} />

          <WrapperButtons>
            <Ct.Button
              label="Retour"
              width={42.5}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              onClick={close}
            />
            <Ct.Spacer width={4} />
            <Ct.Button
              label={"Sauvegarder"}
              width={42.5}
              onClick={saveNewCode}
              disabled={
                merchantCode === "" ||
                (selection
                  ? selection && selection?.length > 1 && !isCentralize
                    ? true
                    : false
                  : false)
              }
            />
          </WrapperButtons>
        </Wrapper>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: space-around;
`
const Header = styled.div`
  width: 100%;
  text-align: center;
`
const Wrapper = styled.div`
  width: 70%;
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledMultiSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const RowFlex = styled.div`
  display: flex;
  width: fit-content;
`
const WrapperWarning = styled.div`
  display: flex;
  align-items: center;
`
