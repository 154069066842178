import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { ButtonsHeader } from "./ButtonsHeader"
import { StyledTopBar } from "./StyledTopBar"
import styled from "styled-components/macro"

export const FiduciaryTopBar = () => {
  const intl = useIntl()

  const fiduciaryName = useRNBSelector((state) => state.fiduciary.name)

  return (
    <StyledTopBar>
      <Ct.Column>
        <TopBarTitle size={2.25} text={fiduciaryName} />
        <Ct.Spacer height={0.5} />
        <TopBarSubTitle
          size={1.75}
          text={intl.formatMessage({
            id: "fiduciary-top-bar.subtitle",
          })}
        />
      </Ct.Column>
      <ButtonsHeader />
    </StyledTopBar>
  )
}

const TopBarSubTitle = styled((props) => <Ct.Subtitle {...props} />)`
  size: 1.75rem;
`

const TopBarTitle = styled((props) => <Ct.Title {...props} />)`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`
