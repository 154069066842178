import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  getCurrentUserThunk,
  getUserAllAccessThunk,
} from "../../store/ducks/user.ducks"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { logoutResetStoreAction } from "../../store/rootActions"
import { Link, useNavigate } from "react-router-dom"
import {
  attemptLoginThunk,
  loginSuccessAction,
} from "../../store/ducks/login.ducks"
import { sizes } from "../../styles/design.config"
import { RedirectionDirective } from "../../model/users"
import { Alert } from "../../components/Commons/Alert"
import { Background } from "../../components/Background"
import { ReactComponent as LoginSVG } from "../../assets/illustration_connexion.svg"

const UserLogin = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const schema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const login = ({ email, password }: { email: string; password: string }) => {
    dispatch(attemptLoginThunk(email, password, rememberMe))
  }

  const email = watch("email")
  const password = watch("password")
  const [rememberMe, setRememberMe] = useState(false)

  const [emailPrefilled, setEmailPrefilled] = useState(false)
  const [passwordPrefilled, setPasswordPrefilled] = useState(false)
  const formPrefilled = emailPrefilled && passwordPrefilled

  const { getCurrentUserStatus, loginStatus, redirectionDirective } =
    useRNBSelector((state) => ({
      getCurrentUserStatus: state.user.status,
      loginStatus: state.login.status,
      redirectionDirective: state.user.redirection,
    }))

  useEffect(() => {
    if (loginStatus === "DISCONNECTED") {
      dispatch(logoutResetStoreAction())
    } else if (getCurrentUserStatus === "IDLE") {
      dispatch(getCurrentUserThunk())
    } else if (
      getCurrentUserStatus === "SUCCESS" &&
      loginStatus !== "LOADING"
    ) {
      dispatch(loginSuccessAction())
    }
  }, [dispatch, getCurrentUserStatus, loginStatus])

  useEffect(() => {
    prefilledFormHack(setEmailPrefilled, setPasswordPrefilled)
  }, [getCurrentUserStatus])

  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (loginStatus === "LOGGED") {
      setShouldRedirect(true)
    }
  }, [loginStatus])

  useEffect(() => {
    if (shouldRedirect) {
      dispatch(getCurrentUserThunk())
      dispatch(getUserAllAccessThunk())
    }
  }, [shouldRedirect, dispatch])

  useEffect(() => {
    const routeByDirectives: Record<RedirectionDirective, string> = {
      UNSET: "",
      ACCESS_DENIED: "/access_denied",
      COMPANIES_LIST: "/office",
      SPECIFIC_COMPANY: `/office/company/${redirectionDirective.companyToRedirect}`,
      COMPANY_CREATION: "/office/fiduciary/company_accounting_type",
      ERROR: "",
      TERMS_OF_SERVICE_NOT_ACCEPTED: "/user/accept_terms_and_conditions",
      TERMS_OF_SALES_NOT_ACCEPTED: "/user/accept_terms_and_conditions",
      FIRST_CONNEXION: "/user/incomplete_registration",
      FIDUCIARY_NOT_CREATED: "/fiduciary/register",
      SSO: redirectionDirective.SSO_URL,
    }
    if (redirectionDirective.directive === "SSO") {
      navigate(routeByDirectives[redirectionDirective.directive])
    }

    if (
      redirectionDirective.directive !== "UNSET" &&
      loginStatus === "LOGGED" &&
      routeByDirectives[redirectionDirective.directive]
    ) {
      navigate(routeByDirectives[redirectionDirective.directive])
    }
  }, [
    shouldRedirect,
    navigate,
    redirectionDirective.directive,
    redirectionDirective.companyToRedirect,
    redirectionDirective.SSO_URL,
    loginStatus,
  ])
  return (
    <Background centeredTop={false}>
      <Content>
        <Ct.Title text={intl.formatMessage({ id: "login.title" })} />
        <Ct.Subtitle
          text={intl.formatMessage({
            id: "login.subtitle",
          })}
        />
        <Ct.Spacer height={9} />
        <RowStyle>
          <StyledLoginSVG height={300} />
          <Ct.Card>
            <Ct.StyledForm onSubmit={handleSubmit(login)} id="form">
              {getCurrentUserStatus !== "LOADING" ? (
                <>
                  <Ct.Input
                    name="email"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({ id: "login.email" })}
                    type="text"
                    value={email}
                    showError={loginStatus === "ERROR"}
                    id="email_input"
                    isPrefilled={emailPrefilled}
                    disabled={
                      loginStatus === "LOADING" ||
                      getCurrentUserStatus === "SUCCESS"
                    }
                  />
                  <Ct.Spacer height={4} />
                  <Ct.PasswordInput
                    name="password"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({ id: "login.password" })}
                    type="password"
                    value={password}
                    showError={loginStatus === "ERROR"}
                    id="password_input"
                    isPrefilled={passwordPrefilled}
                    disabled={
                      loginStatus === "LOADING" ||
                      getCurrentUserStatus === "SUCCESS"
                    }
                  />
                  <Ct.Spacer height={4} />
                  {loginStatus === "ERROR" ? (
                    <>
                      <Alert alertType="error">
                        <Ct.Text
                          text={intl.formatMessage({ id: "login.error" })}
                          textStyle={{ fontWeight: 700, color: "amaranth" }}
                        />
                      </Alert>
                      <Ct.Spacer height={4} />
                    </>
                  ) : (
                    <></>
                  )}
                  <SpaceBetween>
                    <Ct.Checkbox
                      onChange={() => setRememberMe(!rememberMe)}
                      name="rememberMeCheckbox"
                      isChecked={rememberMe}
                      label={intl.formatMessage({
                        id: "login.remember-me",
                      })}
                    />
                    <Ct.Link
                      RouterLink={Link}
                      text={intl.formatMessage({
                        id: "login.forgotten-password",
                      })}
                      href={`/user/forgotten_password`}
                    />
                  </SpaceBetween>

                  <Ct.Spacer height={4} />
                </>
              ) : (
                <>
                  <Ct.Spacer height={6} />
                  <Ct.Spacer height={6} />
                  <Ct.Spacer height={6} />
                  <Ct.Spacer height={6} />
                  <Ct.Spacer height={2} />
                </>
              )}
              <Ct.Button
                width={sizes.button.standard}
                disabled={
                  (!isValid && !formPrefilled) ||
                  getCurrentUserStatus === "LOADING"
                }
                type="submit"
                label={intl.formatMessage({ id: "login.cta" })}
              />
            </Ct.StyledForm>
          </Ct.Card>
        </RowStyle>
      </Content>
    </Background>
  )
}
export default UserLogin

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

function prefilledFormHack(
  setEmailPrefilled: Dispatch<SetStateAction<boolean>>,
  setPasswordPrefilled: Dispatch<SetStateAction<boolean>>
) {
  // see https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
  setTimeout(() => {
    try {
      if (
        document
          .querySelector("#email_input")
          ?.matches(":-internal-autofill-selected")
      ) {
        setEmailPrefilled(true)
      }
      if (
        document
          .querySelector("#password_input")
          ?.matches(":-internal-autofill-selected")
      ) {
        setPasswordPrefilled(true)
      }
    } catch (e) {
      // error on other browsers than chrome
    }
  }, 300)
}

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
`

const StyledLoginSVG = styled(LoginSVG)`
  height: 300px;
  padding-right: 30rem;
`
