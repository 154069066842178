import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"

import { buyOrSell, getIdFromParams } from "../../utils/company"
import styled from "styled-components/macro"
import { colors, sizes } from "../../styles/design.config"
import {
  getMerchantData,
  getOutputDisplayThunk,
  modifyMerchantThunk,
} from "../../store/ducks/merchants.ducks"
import { useRNBSelector } from "../../store/rootReducer"

interface MerchantRenameModalProps extends Ct.ModalComponentsProps {
  merchantId: number
  buyOrSell: buyOrSell
}
export const MerchantRenameModal = ({
  onClose,
  isDisplayed,
  merchantId,
  buyOrSell,
}: MerchantRenameModalProps) => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()

  const merchant = useRNBSelector(
    (state) => state.merchants.merchants[merchantId]
  )
  const saveStatus = useRNBSelector((state) => state.merchants.saveStatus)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )
  const standardLedger = useRNBSelector(
    (state) =>
      state.merchants.merchants[merchant?.id]?.outputDisplay?.account_line
  )

  useEffect(() => {
    if (!selectedCompanyId || !merchantId) return
    if (standardLedger) return

    dispatch(getOutputDisplayThunk(selectedCompanyId, merchantId, buyOrSell))
  }, [merchantId, selectedCompanyId])

  useEffect(() => {
    if (!merchant?.name) {
      dispatch(getMerchantData(merchantId, selectedCompanyId))
    }
  }, [merchant?.name])

  useEffect(() => {
    if (saveStatus === "success") {
      onClose()
    }
  }, [saveStatus])

  const [merchantName, setMerchantName] = useState<string>(merchant?.name)
  const [compteNum, setCompteNum] = useState<string>("")
  const [compteLib, setCompteLib] = useState<string>("")
  const [auxNum, setAuxNum] = useState<string>("")
  const [auxLib, setAuxLib] = useState<string>("")

  const softwareRules = useRNBSelector((state) => state.softwareRules.rules)

  useEffect(() => {
    setCompteNum(standardLedger?.number || "")
    setCompteLib(standardLedger?.details || "")
    setAuxLib(standardLedger?.auxiliaryDetails || "")
    setAuxNum(standardLedger?.auxiliaryNumber || "")
  }, [standardLedger])

  useEffect(() => {
    if (merchantName && merchantName !== "" && merchantName !== merchant.name) {
      if (company.auxiliary) {
        setAuxLib(merchantName)
      } else {
        setCompteLib(merchantName)
      }
    }
  }, [merchantName])

  if (!merchant) {
    return null
  }

  const isMerchantNameError = (name: string | undefined) =>
    !name ||
    name.length < 1 ||
    (softwareRules !== null &&
      softwareRules.merchant &&
      name.length > softwareRules.merchant.name)

  const merchantNameErrorText = (name: string | undefined) => {
    if (!name || name.length < 1) {
      return intl.formatMessage({
        id: `office.modify-merchant.${buyOrSell}.merchant-name.empty-name`,
      })
    } else if (
      softwareRules !== null &&
      softwareRules.merchant &&
      name.length > softwareRules.merchant.name
    ) {
      return intl.formatMessage({
        id: `office.modify-merchant.${buyOrSell}.merchant-name.name-too-long`,
      })
    }
    return "error"
  }

  const updateMerchant = () => {
    if (selectedCompanyId && buyOrSell) {
      dispatch(
        modifyMerchantThunk(selectedCompanyId)(buyOrSell)(merchant)(
          merchantName.trim()
        )(null)([])(merchant.merchant_code)(null)
      )
    }
  }

  const isMerchantEdited = merchant.name !== merchantName && merchantName !== ""

  const close = () => {
    if (isMerchantEdited) {
      const warningMessage = `Vous êtes en train de modifier un ${
        buyOrSell === "buy" ? "fournisseur" : "client"
      }. \nSi vous quittez maintenant, les modifications ne seront pas sauvegardées`
      if (window.confirm(warningMessage)) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="calc(25vh - 25rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Title
          text={`${intl.formatMessage(
            { id: "ged.modal.edit-merchant.title" },
            { buyOrSell: buyOrSell === "buy" ? "Fournisseur" : "Client" }
          )} : ${merchant.name}`}
          size={7}
        />

        <Ct.Spacer height={4} />

        <WrapperLedger>
          <Header>
            <Ct.Text
              text={intl.formatMessage({
                id: "ged.modal.standard-ledger.compteNum",
              })}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "ged.modal.standard-ledger.compteLib",
              })}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "ged.modal.standard-ledger.compteAuxNum",
              })}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={intl.formatMessage({
                id: "ged.modal.standard-ledger.compteAuxLib",
              })}
              textStyle={{
                fontWeight: 700,
              }}
            />
          </Header>
          <Ct.Spacer height={3} />
          <CardBody id={"cardMerchantStandardLedger"}>
            <Ct.Text text={compteNum || ""} />
            <Ct.Text text={compteLib || ""} />
            <Ct.Text text={auxNum} />
            <Ct.Text text={auxLib} />
          </CardBody>
          <Ct.Spacer height={3} />
        </WrapperLedger>

        <Ct.Spacer height={4} />

        <WrapperEdit>
          <ChangeBlock>
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "ged.modal.edit-merchant.edit-name",
                },
                { buyOrSell: buyOrSell === "buy" ? "fournisseur" : "client" }
              )}
              textStyle={{
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            />

            <Ct.Spacer height={2} />

            <Ct.Input
              label={intl.formatMessage({
                id: `office.modify-merchant.${buyOrSell}.merchant-name.placeholder`,
              })}
              value={merchantName}
              onChange={(event) =>
                setMerchantName(event.target.value.replace(/\s\s+/g, " "))
              }
              showError={isMerchantNameError(merchantName)}
              name={"merchantName"}
            />

            {isMerchantNameError(merchantName) && (
              <StyledErrorText
                text={merchantNameErrorText(merchantName)}
                textStyle={{
                  color: "amaranth",
                  fontSize: 1.75,
                  lineHeight: 3,
                }}
                data-cy={"merchant-name-empty-name"}
              />
            )}
          </ChangeBlock>

          <Ct.Spacer width={4} />
        </WrapperEdit>

        <Ct.Spacer height={4} />

        <Ct.Spacer height={4} />

        <Ct.JustifyCenter>
          <Ct.Button
            colorType="Secondary"
            label={intl.formatMessage({
              id: "office.modify-merchant.cancel",
            })}
            width={sizes.button.standard}
            onClick={() => {
              onClose()
            }}
          />
          <Ct.Spacer width={4} />
          <Ct.Button
            label={intl.formatMessage({
              id: "office.modify-merchant.cta",
            })}
            width={sizes.button.standard}
            onClick={updateMerchant}
            disabled={!isMerchantEdited}
            dataCy={"cta"}
          />
        </Ct.JustifyCenter>
      </Ct.Card>
    </Ct.Modal>
  )
}

const WrapperLedger = styled.div`
  border-radius: 1.25rem;
  width: 110rem;
  border: 1px solid ${colors.lavender};
`

const Header = styled.header`
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  width: initial;
  background-color: ${colors.lavender};
  padding: 2rem 3rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`

const CardBody = styled.div`
  padding: 0 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`

const WrapperEdit = styled.div`
  width: 110rem;
  display: flex;
  justify-content: space-evenly;
`
const ChangeBlock = styled.div`
  width: 100%;
`

const StyledErrorText = styled((props) => <Ct.Text {...props} />)`
  padding-left: 2rem;
`
