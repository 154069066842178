import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import * as Ct from "ldlj"
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import {
  getBuyOrSellFromParams,
  getIdFromParams,
} from "../../../../utils/company"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Chevron } from "../../../../assets/right-chevron-solo.svg"
import { ReactComponent as Calendar } from "../../../../assets/calendarSearch.svg"
import { ReactComponent as InfoFull } from "../../../../assets/info-full.svg"

import { FiscalYearPicker } from "../../../../components/FiscalYearPicker"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useDispatch } from "react-redux"
import {
  GetFiscalYearMerchantsThunk,
  Merchant,
  Document,
} from "../../../../store/ducks/merchants.ducks"
import {
  filterMerchantsAndDocs,
  sortMerchants,
  sortMerchantsByDocumentsCount,
  sortMerchantsByName,
} from "../../../../utils/merchants"
import { Alert } from "../../../../components/Commons/Alert"
import { capitalizeFirstLetter } from "../../../../utils/string"
import { loadPNLAccountsThunk } from "../../../../store/ducks/accounts.ducks"
import { ModalFullDocumentViewer } from "../../../../components/Commons/ModalFullDocumentViewer"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../components/dropDocuments/StyledDropsComponents"
import AutoSizer from "react-virtualized-auto-sizer"
import React from "react"
import { VariableSizeGrid } from "react-window"
import { MerchantChangeModal } from "../../../../components/modifyMerchant/MerchantChangeModal"
import {
  DisplayedMask,
  FullDocumentsTable,
} from "../../../../components/EDM/FullDocumentsTable"
import { MerchantHistoryModal } from "../../../../components/EDM/MerchantHistoryModal"
import { DeactivateDocumentModal } from "../../../../components/DeactivateDocumentModal"
import { FullDocumentHistoryModal } from "../../../../components/inputMask/FullDocumentHistoryModal"
import { GetCompanyMerchantCodesDataThunk } from "../../../../store/ducks/merchantCodes.ducks"
import { getVatAccountSettingsThunk } from "../../../../store/ducks/companySettings.ducks"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../components/Commons/Table"
import { InputMaskModal } from "../../../../components/inputMask/InputMaskModal"
import { deactivateFullDocumentReset } from "../../../../store/ducks/fullDocuments.ducks"
import { InputDateRange } from "../../../../components/Commons/InputDateRange"
import { DateTime } from "luxon"
import { FiscalYear } from "../../../../store/ducks/fiscalYears.ducks"
import { filterDocs } from "../../../../utils/fullDocuments"

export const getHighlightedText = (text: string, highlight: string) => {
  if (!highlight || !text) {
    return text
  }

  const parts = text.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={
            part.toLowerCase().includes(highlight.toLowerCase()) ||
            highlight.toLowerCase().includes(part.toLowerCase())
              ? {
                  background: "rgba(255, 197, 66, 0.3)",
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </>
  )
}

export const Merchants = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const intl = useIntl()
  const dispatch = useDispatch()
  const buyOrSell = getBuyOrSellFromParams(useParams())

  const { selectedFiscalYearId, fiscalYearsCompany } = useRNBSelector(
    (state) => ({
      selectedFiscalYearId: state.fiscalYears.selectedFiscalYearId,
      fiscalYearsCompany:
        state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId],
    })
  )
  const merchants = useRNBSelector((state) => state.merchants.merchants)
  const userTypology = useRNBSelector((state) => state.user.typology)
  const fullDocumentsDeactivateStatus = useRNBSelector(
    (state) => state.fullDocuments.fullDocumentsDeactivateStatus
  )

  const listRef = React.createRef<VariableSizeGrid>()

  const [search, setSearch] = useState<string>("")
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYear>({
    id: 0,
    beginExercise: "",
    endExercise: "",
    companyId: 0,
  })
  const [customDateFilterIsActive, setCustomDateFilterIsActive] =
    useState(false)
  const [customDateRange, setCustomDateRange] = useState<[Date, Date]>([
    DateTime.now().set({ day: 1 }).toJSDate(),
    DateTime.now().set({ day: DateTime.now().daysInMonth }).toJSDate(),
  ])
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })
  const [sortedMerchants, setSortedMerchants] = useState<Merchant[]>([])
  const [displayChanges, setDisplayChanges] = useState<number | null>(null)
  const [displayHistory, setDisplayHistory] = useState<Merchant | undefined>(
    undefined
  )
  const [displayPreview, setDisplayPreview] = useState<{
    isDisplayed: boolean
    fullDocId: number | null
    elementName: string
    merchantId: number | null
  }>({
    isDisplayed: true,
    fullDocId: null,
    elementName: "",
    merchantId: null,
  })
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [fullDocIdListToDelete, setFullDocIdListToDelete] = useState<number[]>(
    []
  )
  const [historyDisplayFullDoc, setHistoryDisplayFullDoc] = useState<{
    isDisplayed: boolean
    fullDocId: null | number
  }>({
    isDisplayed: false,
    fullDocId: null,
  })
  const [toggles, setToggles] = useState<{ id: number; toggle: boolean }[]>([])
  const [rowHeigths, setRowHeights] = useState<number[]>([])

  enum SortOptionsValues {
    "merchantName",
    "documentsCount",
  }

  const [sortType, setSortType] = useState<
    { type: SortOptionsValues; asc: boolean } | undefined
  >({ type: SortOptionsValues?.merchantName, asc: true })

  const [displayMask, setDisplayMask] = useState<DisplayedMask>({
    isDisplayed: false,
    fullDocumentId: null,
    elementName: "",
    element: {} as Document,
    merchantId: 0,
  })

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setSortType({ type: option, asc: asc })

    if (option === SortOptionsValues?.merchantName) {
      setSortedMerchants(sortMerchantsByName(sortedMerchants, asc))
    } else if (option === SortOptionsValues?.documentsCount) {
      setSortedMerchants(sortMerchantsByDocumentsCount(sortedMerchants, asc))
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const filterAndSortMerchants = useCallback(
    (
        search: string,
        customDateFilterIsActive: boolean,
        customDateRange: [Date, Date]
      ) =>
      (merchants: { [index: number]: Merchant }) => {
        if (merchants) {
          setSortedMerchants(
            Object.values(merchants)
              .filter(
                filterMerchantsAndDocs(
                  search,
                  customDateFilterIsActive,
                  customDateRange
                )
              )
              .sort(sortMerchants(true))
          )
        }
      },
    []
  )

  const autoToggle = () => {
    const newToggle = sortedMerchants.map((me) => {
      const merchantNeedToggle = toggles.some(
        (t) => t.id === me.id && t.toggle === true
      )
      return { id: me.id, toggle: merchantNeedToggle }
    })

    setToggles(newToggle)

    const newRows = new Array(sortedMerchants.length)
      .fill(true)
      .map((_, index) => {
        return newToggle[index].toggle === true ? 60 + 440 : 60
      })

    setRowHeights(newRows)
    onResize()
  }

  const toogleHandler = (merchantId: number, forceToggle?: boolean) => {
    const found = toggles.find((t) => t.id === merchantId)
    if (found) {
      const index = toggles.indexOf(found)
      toggles.splice(index, 1, {
        id: merchantId,
        toggle: forceToggle ? forceToggle : !found.toggle,
      })
    }

    const newRows = new Array(sortedMerchants.length)
      .fill(true)
      .map((_, index) => {
        return toggles[index].toggle === true ? 60 + 440 : 60
      })

    setRowHeights(newRows)
    onResize()
  }

  const deToggleAll = () => {
    setToggles(
      sortedMerchants.map((me) => {
        return { id: me.id, toggle: false }
      })
    )

    setRowHeights(new Array(sortedMerchants.length).fill(true).map(() => 60))

    onResize()
  }

  const onResize = () => {
    if (listRef.current !== null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const handleDelete = (fullDocId: number, uniqueDelete: boolean) => {
    if (uniqueDelete) {
      setFullDocIdListToDelete([fullDocId])
      setDisplayDeleteModal(true)
    } else {
      if (fullDocIdListToDelete.includes(fullDocId)) {
        setFullDocIdListToDelete((prevValue) =>
          prevValue.filter((e) => e !== fullDocId)
        )
      } else setFullDocIdListToDelete((prevValue) => [...prevValue, fullDocId])
    }
  }

  const getFullDocumentCountByMerchant = (merchant: Merchant) => {
    const { documents } = merchant
    if (documents && documents.length > 0) {
      return documents.filter(
        filterDocs(
          search || "",
          merchant.name,
          customDateFilterIsActive,
          customDateRange
        )
      ).length
    } else return "0"
  }

  useMemo(() => {
    if (merchants) {
      filterAndSortMerchants(
        search,
        customDateFilterIsActive,
        customDateRange
      )(merchants)

      if (toggles.length > 0) {
        autoToggle()
        onResize()
      } else {
        setToggles(
          sortedMerchants.map((me) => {
            return { id: me.id, toggle: false }
          })
        )

        setRowHeights(
          new Array(sortedMerchants.length).fill(true).map(() => 60)
        )
      }
      return
    }
  }, [search, merchants, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (sortedMerchants.length > 0 && sortType) {
      sorter(sortType.asc)(sortType.type)
    }
    setFullDocIdListToDelete([])
  }, [merchants, selectedFiscalYearId])

  useEffect(() => {
    if (!(buyOrSell && selectedFiscalYearId)) {
      return
    }
    if (displayMask.isDisplayed) {
      // avoid reloading on input mask display (only reload on input mask modal close)
      return
    }

    setSearch("")
    setRowHeights([])
    setToggles([])
    setSortedMerchants([])
    setHighlightValues({ highlight: false, searchString: "" })

    dispatch(
      GetFiscalYearMerchantsThunk({
        fiscalYearId: selectedFiscalYearId,
        buyOrSell: buyOrSell,
      })
    )

    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))

    setSearch("")
  }, [
    dispatch,
    buyOrSell,
    selectedFiscalYearId,
    selectedCompanyId,
    displayMask.isDisplayed,
  ])

  useEffect(() => {
    if (sortedMerchants.length > 0 && sortType) {
      sorter(sortType.asc)(sortType.type)
    }
  }, [merchants, selectedFiscalYearId])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedMerchants(
          Object.values(merchants)
            .filter(
              filterMerchantsAndDocs(
                search,
                customDateFilterIsActive,
                customDateRange
              )
            )
            .sort(sortMerchants(true))
        )
      }
    }
  }, [columnToSort, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    dispatch(loadPNLAccountsThunk(selectedCompanyId))
    dispatch(getVatAccountSettingsThunk({ companyId: selectedCompanyId }))
  }, [dispatch])

  useEffect(() => {
    if (sortedMerchants.length > 0 && search.length >= 1) {
      const timeOutId = setTimeout(() => {
        filterAndSortMerchants(
          search,
          customDateFilterIsActive,
          customDateRange
        )(merchants)
        setHighlightValues({ searchString: search, highlight: true })
      }, 500)

      return function cleanUp() {
        clearTimeout(timeOutId)
      }
    } else {
      setHighlightValues({ searchString: "", highlight: false })
    }
  }, [dispatch, merchants, search, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (sortedMerchants.length > 0 && search.length >= 1) {
      const merchantsFullDocsFound = Object.values(merchants).filter(
        filterMerchantsAndDocs(
          search,
          customDateFilterIsActive,
          customDateRange
        )
      )

      const newToggle = sortedMerchants.map((me) => {
        const merchantNeedToggle = merchantsFullDocsFound.some(
          (m) => m.id === me.id
        )
        return { id: me.id, toggle: merchantNeedToggle }
      })

      setToggles(newToggle)

      const newRows = new Array(sortedMerchants.length)
        .fill(true)
        .map((_, index) => {
          return newToggle[index].toggle === true ? 60 + 240 : 60
        })

      setRowHeights(newRows)
      onResize()
    } else {
      autoToggle()
    }
  }, [sortedMerchants, search, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (fiscalYearsCompany) {
      let companyFySelected = fiscalYearsCompany.find(
        (fy) => fy.id === selectedFiscalYearId
      )
      if (companyFySelected) {
        setSelectedFiscalYear(companyFySelected)
        setCustomDateRange([
          new Date(companyFySelected.beginExercise),
          new Date(companyFySelected.endExercise),
        ])
      }
    }
  }, [selectedFiscalYearId])

  useEffect(() => {
    if (["success", "error"].includes(fullDocumentsDeactivateStatus)) {
      dispatch(deactivateFullDocumentReset())
      setFullDocIdListToDelete([])
    }
  }, [fullDocumentsDeactivateStatus])

  const columns: Ct.TableBuilder<Merchant>[] = [
    {
      headerText: `ged.merchants.table-header.merchant-name.${buyOrSell}`,
      content: (merchant: Merchant) => (
        <StyledCenter isFirstColumn={true} marginTop={"2%"}>
          {getHighlightedText(
            capitalizeFirstLetter(merchant.name),
            highlightValues.searchString
          )}
        </StyledCenter>
      ),
    },
    {
      headerText: "ged.merchants.table-header.document-count",
      content: (merchant: Merchant) => (
        <StyledCenter marginTop={"2%"}>
          {getFullDocumentCountByMerchant(merchant)}
        </StyledCenter>
      ),
    },
    {
      headerText: "empty",
      content: (merchant: Merchant) => (
        <StyledCenter isLastColumn={true}>
          <ChevronWrapper
            onClick={() => {
              toogleHandler(merchant.id)
            }}
            rotation={toggles.find((t) => t.id === merchant.id)?.toggle}
            isCustomer={false}
          >
            <Chevron />
          </ChevronWrapper>
        </StyledCenter>
      ),
    },
  ]

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const merchant: Merchant = sortedMerchants[rowIndex]
    const index = sortedMerchants.indexOf(merchant)
    const isToggled = toggles.find((t) => t.id === merchant.id)?.toggle === true
    const divContainer = useRef<HTMLDivElement>(null)

    let containerHeight = 60
    useEffect(() => {
      if (divContainer.current) {
        containerHeight = divContainer.current.offsetHeight
      }
    }, [])

    return (
      <>
        <div
          ref={divContainer}
          style={{
            ...style,
            backgroundColor: rowIndex % 2 === 0 ? "white" : "#F4F8FF",
          }}
        >
          {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
          <Ct.Row>
            {columnIndex === columns.length && <Ct.Spacer width={4} />}
            <StyledCell width={"100%"} height={containerHeight + "px"}>
              {columns[columnIndex].content(merchant)}
            </StyledCell>
          </Ct.Row>
        </div>

        {rowIndex === index &&
          columnIndex + 1 === columns.length &&
          isToggled && (
            <>
              <div
                style={{
                  ...style,
                  width: "98%",
                  top: Number(style?.top) + 60,
                  left: 0,
                  height: "420px",
                  padding: "0 2rem",
                  marginTop: "1rem",
                }}
              >
                <FullDocumentsTable
                  documents={merchant.documents}
                  merchantId={merchant.id}
                  highlightValues={highlightValues}
                  fullDocIdListToDelete={fullDocIdListToDelete}
                  customDateFilterIsActive={customDateFilterIsActive}
                  customDateRange={customDateRange}
                  onOpenInputMask={(displayedMask: DisplayedMask) => {
                    setDisplayMask(displayedMask)
                  }}
                  onPreviewFullDoc={(
                    isDisplayed: boolean,
                    fullDocId: number | null,
                    elementName: string,
                    merchantId: number
                  ) => {
                    setDisplayPreview({
                      isDisplayed,
                      fullDocId,
                      elementName,
                      merchantId,
                    })
                  }}
                  onSelectFullDocToDelete={handleDelete}
                  onHistoryFullDoc={(
                    isDisplayed: boolean,
                    fullDocId: number | null
                  ) => {
                    setHistoryDisplayFullDoc({ isDisplayed, fullDocId })
                  }}
                />
              </div>
            </>
          )}
      </>
    )
  }

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <HeaderLeftWrapper>
            <StyledInput
              name={"searchMerchant"}
              id={"searchMerchant"}
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(event: { target: HTMLInputElement }) => {
                const value = event.target.value.trimStart().replace("  ", " ")
                if (event.target.value === "") {
                  deToggleAll()
                }
                setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
              }}
              shadowed={true}
              noBorder={true}
            />
          </HeaderLeftWrapper>
          {selectedCompanyId && (
            <FiscalYearPicker
              companyId={selectedCompanyId}
              buyOrSell={buyOrSell}
            />
          )}
          <HeaderRightWrapper>
            <CustomDateContainer>
              {customDateFilterIsActive ? (
                <InputDateRange
                  value={customDateRange}
                  onChange={(value) => setCustomDateRange(value as never)}
                  onClose={() => setCustomDateFilterIsActive(false)}
                  placement="bottomEnd"
                  minimum={selectedFiscalYear.beginExercise}
                  maximum={selectedFiscalYear.endExercise}
                />
              ) : (
                <CustomDateInnerContainer>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "ged.header.date-custom.message",
                    })}
                    textStyle={{
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: 1.8,
                    }}
                  />
                  <StyledInfoFull
                    data-tip={intl.formatMessage({
                      id: "ged.header.date-custom.message.tooltip",
                    })}
                  />
                  <Ct.Button
                    label={<Calendar />}
                    onClick={() => {
                      setCustomDateFilterIsActive(true)
                    }}
                    width={6}
                    height={6}
                  />
                </CustomDateInnerContainer>
              )}
            </CustomDateContainer>

            {fullDocIdListToDelete.length > 0 && <Ct.Spacer width={1} />}
            {fullDocIdListToDelete.length > 0 && (
              <Ct.Button
                label={
                  fullDocumentsDeactivateStatus === "loading" ? (
                    <Ct.SpinningLoader />
                  ) : (
                    `Supprimer la sélection (${fullDocIdListToDelete.length})`
                  )
                }
                onClick={() => {
                  setDisplayDeleteModal(true)
                }}
                width={30}
                colorType="Tertiary"
                colorScheme={{
                  background: "amaranth",
                  color: "white",
                  border: "amaranth",
                }}
              />
            )}
          </HeaderRightWrapper>
        </Header>

        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                return (width - 15) / 3
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 1rem"}>
                    {columns.map((column, index) => (
                      <StyledHeaderTitleWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                        isCustomer={userTypology === "customer"}
                        isFirstIndex={index === 0}
                      >
                        {index === 0 || index === 1 ? (
                          <ClickableTitleSort
                            tid={column.headerText}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable tid={column.headerText} intl={intl} />
                        )}
                      </StyledHeaderTitleWrapper>
                    ))}
                  </HeaderWrapper>

                  {sortedMerchants.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage(
                            {
                              id: "ged.merchants.table.no-merchant1",
                            },
                            {
                              merchantType:
                                `${buyOrSell}` === "buy"
                                  ? "fournisseur"
                                  : "clients",
                            }
                          )}
                        />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "ged.merchants.table.no-merchant2",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <>
                      {rowHeigths.length > 0 && (
                        <GridWrapper totalWidth={width}>
                          <VariableSizeGrid
                            ref={listRef}
                            height={height - 56} // header size
                            rowCount={sortedMerchants.length}
                            width={width} //scrollbar
                            columnWidth={(index) => columnWidths[index]}
                            rowHeight={(index) => rowHeigths[index]}
                            columnCount={columns.length}
                          >
                            {Cell}
                          </VariableSizeGrid>
                        </GridWrapper>
                      )}
                    </>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>

        {displayPreview.fullDocId && displayPreview.merchantId && (
          <ModalFullDocumentViewer
            isDisplayed={!!displayPreview.fullDocId}
            onClose={() => {
              setDisplayPreview({
                isDisplayed: false,
                fullDocId: null,
                elementName: "",
                merchantId: null,
              })
            }}
            elementName={displayPreview.elementName}
            url={
              merchants[displayPreview.merchantId]?.documents?.find(
                (d) => d.id === displayPreview.fullDocId
              )?.url || ""
            }
          />
        )}

        {selectedFiscalYearId &&
          displayMask.isDisplayed &&
          displayMask.fullDocumentId && (
            <InputMaskModal
              isDisplayed={!!displayMask.merchantId && displayMask.isDisplayed}
              fiscalYearId={selectedFiscalYearId}
              merchantId={displayMask.merchantId}
              fullDocumentId={displayMask.fullDocumentId}
              onClose={() => {
                setDisplayMask({
                  isDisplayed: false,
                  fullDocumentId: null,
                  elementName: "",
                  element: {} as Document,
                  merchantId: 0,
                })
              }}
            />
          )}

        {displayHistory && (
          <MerchantHistoryModal
            merchantName={displayHistory.name}
            merchant={displayHistory}
            isDisplayed={
              !!sortedMerchants.find((m) => m.id === displayHistory.id)
                ?.user_merchant_instructions
            }
            onClose={() => {
              setDisplayHistory(undefined)
            }}
          />
        )}

        {displayChanges && (
          <MerchantChangeModal
            isDisplayed={
              !!sortedMerchants.find((m) => m.id === displayChanges)
                ?.outputDisplay
            }
            onClose={() => {
              setDisplayChanges(null)
            }}
            merchantId={displayChanges}
          />
        )}

        <DeactivateDocumentModal
          isDisplayed={displayDeleteModal}
          onClose={() => {
            setDisplayDeleteModal(false)
          }}
          documentIdList={fullDocIdListToDelete}
          companyId={selectedCompanyId || 0}
          whichDocument={"fullDocument"}
          buyOrSell={buyOrSell}
        />

        {historyDisplayFullDoc.isDisplayed && (
          <FullDocumentHistoryModal
            isDisplayed={historyDisplayFullDoc.isDisplayed}
            fullDocumentId={historyDisplayFullDoc.fullDocId}
            onClose={() => {
              setHistoryDisplayFullDoc({ isDisplayed: false, fullDocId: null })
            }}
          />
        )}
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`

const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`

interface FisrItemProps {
  isFirstColumn?: boolean
  isLastColumn?: boolean
  marginTop?: string
}
const StyledCenter = styled.div<FisrItemProps>`
  justify-content: ${(props) =>
    props.isFirstColumn
      ? "flex-start"
      : props.isLastColumn
      ? "flex-end"
      : "center"};
  align-items: center;
  display: flex;
  width: 90%;
  height: 5rem;
  margin-right: ${(props) => (props.isLastColumn ? "20%" : "")};
`

interface EditActionProps {
  isCustomer: boolean
}

interface RotationProps extends EditActionProps {
  rotation?: boolean
}
const ChevronWrapper = styled.span<RotationProps>`
  && svg {
    & path {
      fill: ${(props) => (props.isCustomer ? colors.lavender : colors.rock)};
    }
    transition-duration: 0.2s;
    transform: ${({ rotation }) => (rotation ? "rotate(90deg)" : ``)};
    cursor: ${(props) => (props.isCustomer ? "not-allowed" : "pointer")};
  }
`

interface StyledCellProps {
  width: string
  alignItems: string
  height?: string
}
const StyledCell = styled(Ct.ColumnCenterCenter)<StyledCellProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;
`

interface HeaderProps {
  isCustomer: boolean
  isFirstIndex: boolean
}
const StyledHeaderTitleWrapper = styled(HeaderTitleWrapper)<HeaderProps>`
  text-align: ${(props) => (props.isCustomer ? "left" : "center")};
  justify-content: ${(props) => (props.isFirstIndex ? "flex-start" : "")};
`

const StyledInfoFull = styled(InfoFull)`
  margin: 0 2rem 0 1rem;
  & path {
    fill: ${colors.black};
  }
`

const HeaderLeftWrapper = styled.div`
  width: 70rem;
`
const HeaderRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70rem;
`
const CustomDateContainer = styled.div`
  width: 35rem;
  display: flex;
  justify-content: flex-end;
`
const CustomDateInnerContainer = styled.div`
  display: flex;
  align-items: center;
`
