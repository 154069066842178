import { format } from "date-fns"
import { DateTime } from "luxon"
import frFR from "date-fns/locale/fr"

export const formatTimeFRstring = (dateString: string) => {
  if (!dateString) return ""
  return new Date(dateString).toLocaleTimeString("fr")
}
export const formatDateFRstring = (dateString: string) => {
  if (!dateString) return "Aucune"
  return new Date(dateString).toLocaleDateString("fr")
}

export const formatDateDate = (dateString: Date) => {
  return new Date(dateString).toLocaleDateString()
}

export const formatDateFRdate = (dateString: Date) => {
  if (!dateString) return "Aucune"
  return new Date(dateString).toLocaleDateString("fr")
}

export const dateToFullDocumentFormat = (date: Date | null | undefined) => {
  if (!date) return null
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
}

export function formatDateNumbering(
  numberingDateFormat: string,
  dateString: Date
): string {
  if (!numberingDateFormat) {
    return ""
  }
  const englishFormat = numberingDateFormat
    .replace(/A/g, "y")
    .replace(/J/g, "d")

  return format(dateString, englishFormat)
}

export const monthsArray = [
  { value: "1", label: "Janvier" },
  { value: "2", label: "Février" },
  { value: "3", label: "Mars" },
  { value: "4", label: "Avril" },
  { value: "5", label: "Mai" },
  { value: "6", label: "Juin" },
  { value: "7", label: "Juillet" },
  { value: "8", label: "Août" },
  { value: "9", label: "Septembre" },
  { value: "10", label: "Octobre" },
  { value: "11", label: "Novembre" },
  { value: "12", label: "Décembre" },
]

export function formatDateFrWithSeconds(dateString: string) {
  const date = new Date(dateString)
  const datePattern = new Intl.DateTimeFormat("fr", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
  return datePattern.format(date)
}

export function getDueDate({
  billDate,
  paymentPeriod,
}: {
  billDate: string
  paymentPeriod: number
}) {
  return DateTime.fromFormat(billDate, "yyyy-MM-dd")
    .plus({
      day: paymentPeriod,
    })
    .toFormat("yyyy-MM-dd")
}

export const optionsConfig = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
}

const Calendar = {
  sunday: "Di",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Je",
  friday: "Ve",
  saturday: "Sa",
  ok: "Appliquer",
  today: "Aujourd'hui",
  yesterday: "Hier",
  hours: "Heures",
  minutes: "Minutes",
  seconds: "Secondes",
  formattedMonthPattern: "MMMM yyyy",
  formattedDayPattern: "dd MMMM yyyy",
  dateLocale: frFR,
}

export const locale = {
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "7 derniers jours",
  },
}
