import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { getErrorCodes } from "../../utils/form"
import styled from "styled-components/macro"
import { UserRegisterPayload } from "../../store/ducks/login.ducks"
import { sizes } from "../../styles/design.config"
import {
  getCurrentUserThunk,
  updateUserThunk,
} from "../../store/ducks/user.ducks"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../components/Commons/Alert"
import { Background } from "../../components/Background"
import { ReactComponent as SignupSVG } from "../../assets/illustration_inscription.svg"

const IncompleteRegistration = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [acceptedSalesConditions, setAcceptedSalesConditions] = useState(false)
  const userEmail = useRNBSelector((state) => state.user.email)
  const userId = useRNBSelector((state) => state.user.id)
  const typology = useRNBSelector((state) => state.user.typology)
  const updateStatus = useRNBSelector((state) => state.user.updateStatus)
  const user = useRNBSelector((state) => state.user)
  const initialFirstName = useRNBSelector((state) => state.user.firstName)
  const initialLastName = useRNBSelector((state) => state.user.lastName)

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    password: yup
      .string()
      .min(8, "length")
      .required()
      .matches(RegExp(/^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).*$/gm), "format"),
    confirmPassword: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const firstName = watch("firstName")
  const lastName = watch("lastName")
  const password = watch("password")
  const confirmPassword = watch("confirmPassword")

  useEffect(() => {
    setValue("firstName", initialFirstName)
    setValue("lastName", initialLastName)
  }, [initialFirstName, initialLastName])

  const userRegistration = ({
    firstName,
    lastName,
    password,
  }: UserRegisterPayload) => {
    dispatch(
      updateUserThunk(
        firstName,
        lastName,
        userId,
        userEmail,
        acceptedSalesConditions,
        acceptedConditions,
        password,
        true
      )
    )
  }

  const registeringStatus = useRNBSelector((state) => {
    return state.login.registering
  })
  const getCurrentUserStatus = useRNBSelector((state) => state.user.status)

  const passwordErrors = getErrorCodes(errors, password, confirmPassword)

  useEffect(() => {
    if (
      updateStatus === "SUCCESS" &&
      getCurrentUserStatus === "SUCCESS" &&
      user.first_connexion_at
    ) {
      navigate(`/office/fiduciary/companies`)
    } else if (
      updateStatus === "IDLE" &&
      getCurrentUserStatus === "IDLE" &&
      !user.first_connexion_at
    ) {
      dispatch(getCurrentUserThunk())
    }
  }, [dispatch, updateStatus, navigate, getCurrentUserStatus])

  useEffect(() => {
    if (user.status === "ERROR") navigate("/user/login")
    if (
      user.status === "SUCCESS" &&
      ["customer", "collaborator"].includes(user.typology) &&
      user.terms_of_service_accepted_at
    )
      navigate("/office/fiduciary/companies")
    if (
      user.status === "SUCCESS" &&
      ["administrator", "manager"].includes(user.typology) &&
      user.terms_of_sales_accepted_at &&
      user.terms_of_service_accepted_at &&
      user.first_connexion_at
    ) {
      navigate("/office/fiduciary/companies")
    }
  }, [user])

  return (
    <Background>
      <Content>
        <Ct.Title
          text={intl.formatMessage({
            id: "user.incomplete-registration.title",
          })}
        />
        <Ct.Subtitle text={intl.formatMessage({ id: "register.subtitle" })} />
        <Ct.Spacer height={5} />
        <RowStyle>
          <StyledSignupSVG />
          <StyledCard>
            <Ct.StyledForm onSubmit={handleSubmit(userRegistration)}>
              <>
                <Ct.Spacer height={1.5} />

                <Ct.Input
                  name="email"
                  label={intl.formatMessage({
                    id: "user.incomplete-registration.email",
                  })}
                  type="email"
                  value={userEmail}
                  disabled={true}
                />

                <Ct.Spacer height={2} />

                {registeringStatus === "EMAIL_EXISTING_ERROR" && (
                  <Ct.Text
                    text={intl.formatMessage({
                      id: `user.incomplete-registration.duplicate`,
                    })}
                    textStyle={{ color: "amaranth", fontSize: 1.75 }}
                  />
                )}

                <Ct.Spacer
                  height={registeringStatus === "EMAIL_EXISTING_ERROR" ? 3 : 2}
                />

                <Ct.StyledDuoInput>
                  <Ct.Input
                    name="firstName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.first-name",
                    })}
                    type="text"
                    value={firstName}
                  />
                  <Ct.Spacer width={3} />
                  <Ct.Input
                    name="lastName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.last-name",
                    })}
                    type="text"
                    value={lastName}
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={4} />
                <Ct.StyledDuoInput>
                  <Ct.PasswordInput
                    name="password"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.password",
                    })}
                    type="password"
                    value={password}
                    showWarning={
                      passwordErrors.includes("format") ||
                      passwordErrors.includes("length")
                    }
                  />
                  <Ct.Spacer width={3} />
                  <Ct.PasswordInput
                    name="confirmPassword"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.confirm-password",
                    })}
                    type="password"
                    value={confirmPassword}
                    showError={
                      passwordErrors.includes("mismatch") &&
                      confirmPassword.length
                    }
                  />
                </Ct.StyledDuoInput>
                <Ct.Spacer height={3} />
                {passwordErrors.length > 0 && (
                  <>
                    <Alert alertType="bulb" centerIcon={false}>
                      {passwordErrors.map((passwordError) => (
                        <Ct.Text
                          key={passwordError}
                          text={intl.formatMessage({
                            id: `password-errors.${passwordError}`,
                          })}
                          textStyle={{ lineHeight: 3 }}
                        />
                      ))}
                    </Alert>
                    <Ct.Spacer height={3} />
                  </>
                )}

                <AlignedStart>
                  <Ct.Checkbox
                    onChange={() => setAcceptedConditions(!acceptedConditions)}
                    name="acceptConditions"
                    isChecked={acceptedConditions}
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.conditions",
                    })}
                  />
                  <Ct.Spacer width={0.5} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "user.incomplete-registration.conditions-link",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      color: "cornflower",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      typology === "customer"
                        ? window.open(
                            `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGU_CLIENTS.pdf`,
                            "_blank"
                          )
                        : window.open(
                            `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGU_EC.pdf`,
                            "_blank"
                          )
                    }}
                  />
                </AlignedStart>

                {["administrator", "manager"].includes(typology) && (
                  <>
                    <Ct.Spacer height={2} />
                    <AlignedStart>
                      <Ct.Checkbox
                        onChange={() => {
                          setAcceptedSalesConditions(!acceptedSalesConditions)
                        }}
                        name="acceptSalesConditions"
                        isChecked={acceptedSalesConditions}
                        label={intl.formatMessage({
                          id: "user.incomplete-registration.conditions",
                        })}
                      />
                      <Ct.Spacer width={0.5} />

                      <Ct.Text
                        text={intl.formatMessage({
                          id: "user.incomplete-registration.sales-conditions-link",
                        })}
                        textStyle={{
                          fontWeight: 600,
                          color: "cornflower",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGV_EC.pdf`,
                            "_blank"
                          )
                        }
                      />
                    </AlignedStart>
                  </>
                )}

                <Ct.Spacer height={4} />
                <Ct.Button
                  width={sizes.button.standard}
                  disabled={
                    !isValid ||
                    !acceptedConditions ||
                    password !== confirmPassword ||
                    (["administrator", "manager"].includes(typology) &&
                      !acceptedSalesConditions)
                  }
                  type="submit"
                  label={intl.formatMessage({
                    id: "user.incomplete-registration.cta",
                  })}
                />
              </>
            </Ct.StyledForm>
          </StyledCard>
        </RowStyle>
      </Content>
    </Background>
  )
}

export default IncompleteRegistration

const AlignedStart = styled.div`
  align-self: start;
  display: flex;
  align-items: column;
`

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  margin-bottom: 5rem;
`
const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const RowStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledSignupSVG = styled(SignupSVG)`
  height: 350px;
  padding-right: 20rem;
`
