import React from "react"
import * as Sentry from "@sentry/react"
import styled from "styled-components/macro"
import * as Ct from "ldlj"

export interface ErrorBoundaryProps {
  children: React.ReactNode
}
const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  if (!process.env.REACT_APP_ENV) {
    return <>{children}</>
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorPlaceholder>
          <div>Une erreur est survenue : </div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
          <Ct.Button
            label="Recharger la page"
            onClick={() => {
              resetError()
            }}
          />
        </ErrorPlaceholder>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary

const ErrorPlaceholder = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
