import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { Fragment, useEffect, useState } from "react"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as PlusRounded } from "../../../../assets/plusRounded.svg"
import { ReactComponent as Down } from "../../../../assets/chevron_down_2.svg"
import { ReactComponent as Up } from "../../../../assets/chevron_up_2.svg"
import { ReactComponent as Trash } from "../../../../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { useIntl } from "react-intl"
import { Text } from "../../../../components/Commons/Text"
import { Select, emptyOptionValue } from "../../../../components/Commons/Select"
import { CreateOrModifyAccountModal } from "../../../../components/CreateOrModifyAccountModal"
import { CreateRuleBillOfExchangeModal } from "../../../../components/accountingPlan/CreateRuleBillOfExchangeModal"
import { useDispatch } from "react-redux"
import {
  GetBillOfExchangeAccountsThunk,
  RulesBillOfExchange,
  UpdateBillOfExchangeAccountsThunk,
} from "../../../../store/ducks/accounts.ducks"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../../../store/rootReducer"
import { Button } from "../../../../components/Commons/Button"
import {
  searchRulesBillOfExchange,
  sortRulesByPriority,
} from "../../../../utils/accounts"
import { Alert } from "../../../../components/Commons/Alert"
import { DestroyRuleBillOfExchangeThunk } from "../../../../store/ducks/merchantCodes.ducks"
import { DeleteRuleModal } from "../../../../components/bank/DeleteRuleModal"
import { monthsArray } from "../../../../utils/date"
/* eslint-disable camelcase */

export interface OptionAccounts {
  value: string
  label: string
  month_number: string
}

export const LCR = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [search, setSearch] = useState<string>("")
  const [selectedOption, setSelectedOption] = useState<{
    value: string
    label: string
  }>(emptyOptionValue)
  const [
    createOrModifyAccountModalDisplayed,
    setCreateOrModifyAccountModalDisplayed,
  ] = useState(false)

  const emptyOptionAccountsValue = { value: "", label: "", month_number: "" }

  const [selectAccounts, setSelectedAccounts] = useState<OptionAccounts[]>([
    emptyOptionAccountsValue,
  ])
  const [accounts, setAccounts] = useState<{ value: string; label: string }[]>([
    emptyOptionValue,
  ])
  const [asc, setAsc] = useState<boolean>(true)
  const [rules, setRules] = useState<RulesBillOfExchange[] | []>([])
  const [createOrEditRule, setCreateOrEditRule] = useState<{
    createOrEdit: "create" | "edit"
    rule: RulesBillOfExchange | null
  } | null>(null)
  const [deleteRule, setDeleteRule] = useState<{
    id: number
    priority_number: number
  } | null>(null)

  const {
    accountsForBillOfExchange,
    rulesBillOfExchange,
    createOrUpdateAccountStatus,
    billOfExchange,
  } = useRNBSelector((state) => ({
    accountsForBillOfExchange: state.accounts.accounts_for_bill_of_exchange,
    rulesBillOfExchange: state.accounts.rules_bill_of_exchange,
    createOrUpdateAccountStatus: state.accounts.accountCreationOrUpdateStatus,
    billOfExchange: state.accounts.bill_of_exchange,
  }))

  const systemOptions = [
    {
      value: "onePerYear",
      label: intl.formatMessage({
        id: "accounting-plan.lcr.parameter-system.option.per-year",
      }),
    },
    {
      value: "onePerMonth",
      label: intl.formatMessage({
        id: "accounting-plan.lcr.parameter-system.option.per-month",
      }),
    },
  ]

  useEffect(() => {
    if (
      selectedCompanyId ||
      (selectedCompanyId && createOrUpdateAccountStatus === "SUCCESS")
    ) {
      dispatch(GetBillOfExchangeAccountsThunk(selectedCompanyId))
    }
  }, [dispatch, createOrUpdateAccountStatus])

  useEffect(() => {
    if (accountsForBillOfExchange.length > 0) {
      const acc = accountsForBillOfExchange.map((a) => {
        return { value: String(a.id), label: a.number + ", " + a.details }
      })
      setAccounts(acc)
    }
  }, [accountsForBillOfExchange])

  useEffect(() => {
    if (rulesBillOfExchange.length > 0) {
      setRules(sortRulesByPriority(rulesBillOfExchange, true))
    }
  }, [rulesBillOfExchange])

  useEffect(() => {
    if (billOfExchange) {
      if (billOfExchange.length > 1) {
        setSelectedOption(systemOptions[1])
        let currentAccounts: OptionAccounts[] = []
        for (let i = 0; i < billOfExchange.length; i++) {
          const account = accountsForBillOfExchange.find(
            (a) => a.id === billOfExchange[i].account_id
          )
          currentAccounts.push({
            value: String(account?.id),
            label: account?.number + ", " + account?.details,
            month_number: String(billOfExchange[i].month_number),
          })
        }
        setSelectedAccounts(currentAccounts)
      } else if (billOfExchange.length === 1) {
        setSelectedOption(systemOptions[0])
        const account = accountsForBillOfExchange.find(
          (a) => a.id === billOfExchange[0].account_id
        )
        setSelectedAccounts([
          {
            value: String(account?.id),
            label: account?.number + ", " + account?.details,
            month_number: String(billOfExchange[0].month_number),
          },
        ])
      }
    }
  }, [billOfExchange])

  const hasChanged = () => {
    if (billOfExchange.length === 0) return true
    if (billOfExchange.length > 1 && selectedOption.value === "onePerYear")
      return false
    if (billOfExchange.length === 1 && selectedOption.value === "onePerMonth")
      return false

    return !billOfExchange.some(
      (b, index) => b.account_id !== Number(selectAccounts[index].value)
    )
  }

  const disabledAccountSave =
    selectedOption.value === "" ||
    (selectedOption.value === "onePerYear" && selectAccounts[0].value === "") ||
    (selectedOption.value === "onePerMonth" &&
      selectAccounts.some((a) => a.value === "")) ||
    hasChanged()

  const saveAccounts = () => {
    let accountsByMonth: { account_id: number; month_number: number }[] = []

    if (selectedOption.value === "onePerMonth") {
      for (let i = 0; i < selectAccounts.length; i++) {
        accountsByMonth.push({
          account_id: Number(selectAccounts[i].value),
          month_number: Number(selectAccounts[i].month_number),
        })
      }
    } else if (selectedOption.value === "onePerYear") {
      accountsByMonth = [
        { account_id: Number(selectAccounts[0].value), month_number: 0 },
      ]
    }

    if (selectedCompanyId) {
      dispatch(
        UpdateBillOfExchangeAccountsThunk(selectedCompanyId, accountsByMonth)
      )
    }
  }

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <StyledRow>
            <StyledInput
              name={"searchRule"}
              id={"searchRule"}
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(event: { target: HTMLInputElement }) => {
                const value = event.target.value
                  .trimStart()
                  .replace("  ", " ")
                  .replace(/[^a-zA-Z0-9 .-]/g, "")
                setSearch(value)
                setRules(
                  searchRulesBillOfExchange(
                    rulesBillOfExchange,
                    value.toLowerCase()
                  )
                )
              }}
              shadowed={true}
              noBorder={true}
            />
            <Ct.Spacer width={4} />
          </StyledRow>

          <Ct.Button
            width={30}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.create",
            })}
            prefix={<PlusRounded />}
            onClick={() => {
              setCreateOrModifyAccountModalDisplayed(true)
            }}
          />

          <Ct.Spacer />

          <Ct.Button
            width={35}
            label={intl.formatMessage({
              id: "accounting-plan.lcr.create-rule-button",
            })}
            prefix={<PlusRounded />}
            onClick={() => {
              setCreateOrEditRule({ createOrEdit: "create", rule: null })
            }}
          />
        </Header>

        <Body>
          <WrapperParts>
            <Text
              text={intl.formatMessage({
                id: "accounting-plan.lcr.parameter-system",
              })}
            />
            <Ct.Spacer />

            <div>
              <Select
                intl={intl}
                optionType={"option"}
                domain={`accounting-plan.lcr.parameter-system`}
                options={systemOptions}
                value={selectedOption}
                isClearable={false}
                onChangeCallback={(option) => {
                  setSelectedOption(option)
                  if (option.value === "onePerMonth") {
                    const accountsForMonths = monthsArray.map((m) => {
                      return emptyOptionAccountsValue
                    })
                    setSelectedAccounts(accountsForMonths)
                  } else if (selectedOption.value === "onePerYear") {
                    setSelectedAccounts([emptyOptionAccountsValue])
                  }
                }}
              />
            </div>

            <Ct.Spacer />

            {selectedOption.value === "onePerYear" && (
              <div>
                <Select
                  intl={intl}
                  optionType={"option"}
                  domain={`accounting-plan.lcr.parameter-system`}
                  options={accounts}
                  value={selectAccounts[0]}
                  isClearable={false}
                  onChangeCallback={(option) => {
                    setSelectedAccounts([{ ...option, month_number: "0" }])
                  }}
                />
              </div>
            )}
            {selectedOption.value === "onePerMonth" && (
              <WrapperMultipleSelect>
                <WrapperSelect>
                  {monthsArray.slice(0, 6).map((month, index) => (
                    <Fragment key={month.value}>
                      <Select
                        intl={intl}
                        optionType={month.label}
                        domain={""}
                        options={accounts}
                        value={selectAccounts[index]}
                        isClearable={false}
                        onChangeCallback={(option) => {
                          const updatedAccounts = [...selectAccounts]
                          const optionWithMonth = {
                            ...option,
                            month_number: month.value,
                          }
                          updatedAccounts[index] = optionWithMonth
                          setSelectedAccounts(updatedAccounts)
                        }}
                      />
                      <Ct.Spacer />
                    </Fragment>
                  ))}
                </WrapperSelect>
                <WrapperSelect>
                  {monthsArray.slice(6).map((month, index) => (
                    <Fragment key={month.value}>
                      <Select
                        intl={intl}
                        optionType={month.label}
                        domain={""}
                        options={accounts}
                        value={selectAccounts[index + 6]}
                        isClearable={false}
                        onChangeCallback={(option) => {
                          const updatedAccounts = [...selectAccounts]
                          const optionWithMonth = {
                            ...option,
                            month_number: month.value,
                          }
                          updatedAccounts[index + 6] = optionWithMonth
                          setSelectedAccounts(updatedAccounts)
                        }}
                        customWidth={"100%"}
                      />
                      <Ct.Spacer />
                    </Fragment>
                  ))}
                </WrapperSelect>
              </WrapperMultipleSelect>
            )}
            <Ct.Spacer height={4} />
            <Ct.JustifyCenter>
              <Button
                width={30}
                label={intl.formatMessage({
                  id: "accounting-plan.lcr.parameter-system.accounts.save",
                })}
                onClick={() => {
                  saveAccounts()
                }}
                disabled={disabledAccountSave}
              />
            </Ct.JustifyCenter>
          </WrapperParts>

          <FullSeparator />

          <WrapperParts>
            <RulesWrapper>
              {rules.length > 0 ? (
                <>
                  <Ct.Spacer width={2} height={0} />
                  <RulesPriority>
                    <OrderPriority>
                      <Action
                        size={"2rem"}
                        onClick={() => {
                          setRules(sortRulesByPriority(rules, true))
                          setAsc(true)
                        }}
                        asc={asc}
                      >
                        <Up />
                      </Action>
                      <Action
                        size={"2rem"}
                        onClick={() => {
                          setRules(sortRulesByPriority(rules, false))
                          setAsc(false)
                        }}
                        asc={!asc}
                      >
                        <Down />
                      </Action>
                    </OrderPriority>
                    <Ct.Spacer width={2} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "bank-management.rules.title",
                      })}
                      textStyle={{
                        color: "navy",
                        fontWeight: 700,
                        fontSize: 1.75,
                      }}
                    />
                  </RulesPriority>

                  <Ct.Spacer height={5} />
                  <RulesWrapperOverFlow>
                    {rules.map((rule) => (
                      <Fragment key={rule.priority_number}>
                        <RuleCard width={"100%"}>
                          <StyledRowRule>
                            <Ct.Spacer width={2} />
                            <Ct.Text
                              text={"#" + rule.priority_number}
                              textStyle={{
                                color: "cornflower",
                                fontWeight: 700,
                                fontSize: 2,
                              }}
                            />
                            <Ct.Spacer width={4} />

                            <Ct.Column>
                              <Ct.Text
                                text={rule.text_in_description}
                                textStyle={{
                                  color: "navy",
                                  fontSize: 1.75,
                                }}
                              />

                              <Ct.Spacer height={1} />
                              <Ct.Text
                                text={intl.formatMessage(
                                  {
                                    id: "accounting-plan.lcr.parameter-system.code",
                                  },
                                  { code: rule.code }
                                )}
                                textStyle={{
                                  color: "rock",
                                  fontSize: 1.75,
                                  fontStyle: "italic",
                                }}
                              />
                            </Ct.Column>
                          </StyledRowRule>

                          <RuleActions>
                            <Action
                              size={"3rem"}
                              onClick={() => {
                                setCreateOrEditRule({
                                  createOrEdit: "edit",
                                  rule,
                                })
                              }}
                            >
                              <StyledEdit />
                            </Action>
                            <Ct.Spacer width={2} />
                            <Action
                              size={"3rem"}
                              onClick={() => {
                                setDeleteRule({
                                  id: rule.id,
                                  priority_number: rule.priority_number,
                                })
                              }}
                            >
                              <Trash />
                            </Action>
                            <Ct.Spacer width={1} />
                          </RuleActions>
                        </RuleCard>
                        <Ct.Spacer height={3} />
                      </Fragment>
                    ))}
                  </RulesWrapperOverFlow>
                </>
              ) : (
                <>
                  <Ct.Spacer height={12} />
                  <Ct.RowCenter>
                    <Alert alertType="info">
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "accounting-plan.lcr.parameter-system.empty-rules",
                        })}
                      />
                      <Ct.Spacer height={1} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "accounting-plan.lcr.parameter-system.empty-rules2",
                        })}
                      />
                    </Alert>
                  </Ct.RowCenter>
                </>
              )}
            </RulesWrapper>
          </WrapperParts>
        </Body>
      </StyledSection>

      <CreateOrModifyAccountModal
        selectedAccount={undefined}
        createOrModify={"create"}
        onClose={() => {
          setCreateOrModifyAccountModalDisplayed(false)
        }}
        isDisplayed={createOrModifyAccountModalDisplayed}
      />

      {createOrEditRule !== null && selectedCompanyId && (
        <CreateRuleBillOfExchangeModal
          isDisplayed={!!createOrEditRule}
          onClose={() => {
            setCreateOrEditRule(null)
          }}
          createOrEdit={createOrEditRule?.createOrEdit}
          currentRulesLength={rules.length}
          companyId={selectedCompanyId}
          rule={
            createOrEditRule && createOrEditRule.createOrEdit === "edit"
              ? createOrEditRule.rule
              : null
          }
        />
      )}

      {deleteRule && (
        <DeleteRuleModal
          isDisplayed={!!deleteRule}
          onClose={() => {
            setDeleteRule(null)
          }}
          rule={deleteRule}
          deleteRule={() => {
            dispatch(
              DestroyRuleBillOfExchangeThunk(selectedCompanyId, deleteRule.id)
            )
            setDeleteRule(null)
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.75rem 3rem 4.5rem 3rem;
`
const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`
const StyledRow = styled(Ct.Row)`
  align-items: center;
  width: 100%;
`
const Body = styled.div`
  display: flex;
  height: 100%;
`
const WrapperParts = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0 5rem 0 3rem;
`
const FullSeparator = styled.hr`
  background-color: ${colors.lightGrey};
  border: none;
  height: 100%;
  width: 1px;
  flex-shrink: 0;
`
const WrapperMultipleSelect = styled.div`
  display: flex;
  overflow-y: auto;
`
const WrapperSelect = styled.div`
  flex-grow: 1;
  padding: 10px;
  width: 100%;
`
const OrderPriority = styled.div`
  display: flex;
  flex-direction: column;
`
const RulesPriority = styled.div`
  display: flex;
  align-items: center;
`
const RulesWrapperOverFlow = styled.div`
  overflow: auto;
  height: 55vh;
`
const RulesWrapper = styled.div`
  width: 65%;
  margin: 0 auto;
`
const Action = styled.span<{ size: string; asc?: boolean }>`
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
  cursor: pointer;
  & path {
    fill: ${({ asc }) =>
      asc === false || asc === null
        ? `${colors.lavender}`
        : `${colors.cornflower}`};
  }
`
const RuleCard = styled(Ct.Card)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 2rem 4rem;
  align-items: center;
`
const RuleActions = styled.div`
  display: flex;
`
const StyledEdit = styled(Edit)`
  width: 3rem;
  height: 3rem;
`
const StyledRowRule = styled.div`
  display: flex;
  align-items: center;
`
