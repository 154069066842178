import styled from "styled-components/macro"
import { colors, spacings } from "../../styles/design.config"

import { ReactComponent as Cross } from "../../assets/cross.svg"
import { ReactComponent as Bulb } from "../../assets/bulb.svg"
import { ReactComponent as Checked } from "../../assets/checked.svg"
import { ReactComponent as ExclamationMark } from "../../assets/exclamation-mark.svg"

export interface AlertProps {
  children: React.ReactNode
  alertType: alertType
  centerIcon?: boolean
  boxSizing?: string
  margin?: string
  padding?: string
  stretch?: boolean
}

type alertType = "error" | "bulb" | "warning" | "info" | "success"

const colorByAlertType: Record<alertType, keyof typeof colors> = {
  error: "amaranth",
  warning: "orange",
  bulb: "lightSun",
  info: "rock",
  success: "shamrock",
}

const borderColorByAlertType: Record<alertType, keyof typeof colors> = {
  error: "amaranth",
  warning: "orange",
  bulb: "brightSun",
  info: "rock",
  success: "shamrock",
}

export const Alert = ({
  children,
  alertType,
  centerIcon = true,
  boxSizing,
  margin,
  padding,
  stretch,
}: AlertProps) => {
  const color = colors[colorByAlertType[alertType]]
  const borderColor = colors[borderColorByAlertType[alertType]]
  const icon = iconsByAlertType[alertType]

  return (
    <StyledSection
      color={borderColor}
      boxSizing={boxSizing}
      margin={margin}
      padding={padding}
      stretch={stretch}
    >
      {alertType !== "info" && (
        <StyledIcon centerIcon={centerIcon} color={color}>
          {icon}
        </StyledIcon>
      )}
      <div />
      {children}
    </StyledSection>
  )
}

const SVGColorWrapper = styled.div<WithColor>`
  display: flex;
  justify-content: center;
  & path {
    fill: ${({ color }) => color};
  }
`

const ExclamationSVGWrapper = styled.div<WithColor>`
  display: flex;
  justify-content: center;
  & path:nth-of-type(2) {
    transition: fill 0.5s ease-in-out;
    fill: ${colors.white};
  }
  & path:nth-of-type(3) {
    transition: fill 0.5s ease-in-out;
    fill: ${colors.white};
  }
  & path:nth-of-type(1) {
    transition: fill 0.5s ease-in-out;
    fill: ${colors.orange};
  }
`

const iconsByAlertType: Record<alertType, JSX.Element> = {
  error: (
    <SVGColorWrapper color={colors.white}>
      <Cross />
    </SVGColorWrapper>
  ),
  bulb: (
    <SVGColorWrapper color={colors.brightSun}>
      <Bulb />
    </SVGColorWrapper>
  ),
  warning: (
    <ExclamationSVGWrapper color={colors.brightSun}>
      <ExclamationMark />
    </ExclamationSVGWrapper>
  ),
  info: <SVGColorWrapper />,
  success: (
    <SVGColorWrapper color={colors.white}>
      <Checked />
    </SVGColorWrapper>
  ),
}

interface WithColor {
  color?: string
}
interface WithCenter extends WithColor {
  centerIcon: boolean
}
interface WithProps extends WithColor {
  boxSizing?: string
  margin?: string
  stretch?: boolean
  padding?: string
}

const StyledSection = styled.section<WithProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: ${({ boxSizing }) => boxSizing};
  background: ${colors.white};
  border-radius: 2.5rem;
  padding: ${({ padding }) => (padding ? padding : spacings.alertPadding)};
  margin: ${({ margin }) => (margin ? margin : "")};
  align-self: ${({ stretch }) => (stretch ? "stretch" : "")};
  border: 0.125rem solid ${({ color }) => color};
`

const StyledIcon = styled.div<WithCenter>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: absolute;
  left: -2rem;
  top: ${({ centerIcon }) => (centerIcon ? "calc(50% - 2rem)" : "1.5rem")};
  background-color: ${({ color }) => color};
`
