import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { ReactComponent as Locked } from "../../assets/treasury-swtich-lock.svg"
import React from "react"

interface SwitchProps {
  value: boolean
  id?: string
  onToggle: () => void
  disabled?: boolean
  locked?: boolean
  icon?: React.ReactNode
  size?: "small" | "normal"
  offColor?: keyof typeof colors
  onColor?: keyof typeof colors
}

export function Switch({
  id,
  value,
  onToggle,
  disabled,
  locked,
  icon,
  size = "normal",
  offColor = "desertStorm",
  onColor = "cornflower",
}: SwitchProps) {
  return (
    <SwitchStyled
      size={size}
      onClick={() => {
        if (disabled || locked) {
          return
        }
        onToggle()
      }}
    >
      <Input
        id={id || "switch"}
        type="checkbox"
        checked={value}
        disabled={disabled}
      />
      <StyledSpan
        offColor={offColor}
        onColor={onColor}
        isToggled={value}
        disabled={disabled}
        locked={locked}
        size={size}
      >
        {locked && value && <StyledLocked />}
        {icon && (
          <WrapperStyledIcon on={value} size={size}>
            {icon}
          </WrapperStyledIcon>
        )}
      </StyledSpan>
    </SwitchStyled>
  )
}

type LabelProps = Required<Pick<SwitchProps, "size">>

interface IsToggledProps extends LabelProps {
  isToggled: boolean
  disabled?: boolean
  locked?: boolean
  offColor: keyof typeof colors
  onColor: keyof typeof colors
}

const SwitchStyled = styled.label<LabelProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => `${props.size === "small" ? 3.5 : 7}rem`};
  min-width: ${(props) => `${props.size === "small" ? 3.5 : 7}rem`};
  height: ${(props) => `${props.size === "small" ? 2 : 4}rem`};
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const StyledSpan = styled.label<IsToggledProps>`
  border-radius: 4rem;
  margin: 0;
  height: ${(props) => `${props.size === "small" ? 2 : 4}rem`};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.disabled && props.isToggled
      ? colors.lightBlueGrey
      : !props.disabled && props.isToggled
      ? colors[props.onColor]
      : colors[props.offColor]};
  transition: 0.4s;
  cursor: ${(props) =>
    props.disabled || props.locked ? "not-allowed" : "pointer"};
  :before {
    position: absolute;
    content: "";
    min-height: ${(props) => `${props.size === "small" ? 1.5 : 3.5}rem`};
    width: ${(props) => `${props.size === "small" ? 1.5 : 3.5}rem`};

    bottom: 2px;
    background-color: ${colors.white};
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.05), 0 3px 1px 0 rgba(0, 0, 0, 0.04),
      0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 8px 0 rgba(0, 0, 0, 0.04);
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) =>
      props.isToggled
        ? `translateX(${props.size === "small" ? 1.7 : 3.2}rem);`
        : `translateX(2px);`};
  }
  align-content: center;
`

const StyledLocked = styled(Locked)`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0.6rem;
  padding-left: 3.75rem;
`

const WrapperStyledIcon = styled.span<LabelProps & { on: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => `${props.size === "small" ? 2 : 2.5}rem`};
  height: ${(props) => `${props.size === "small" ? 2 : 2.5}rem`};

  position: absolute;
  top: 0;
  margin-left: ${(props) =>
    props.on
      ? `${props.size === "small" ? 1.5 : 4}rem`
      : `${props.size === "small" ? 0 : 0.75}rem`};
  padding-top: ${(props) => `${props.size === "small" ? 0 : 0.6}rem`};

  transition: 0.4s;
  & svg path {
    fill: ${(props) => (props.on ? colors.cornflower : colors.hawkes)};
  }
`
