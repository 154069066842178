import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import styled from "styled-components/macro"

interface DropDocumentsFormatsModalProps extends Ct.ModalComponentsProps {
  acceptedFormats: string[]
}

export const DropDocumentsFormatsModal = ({
  isDisplayed,
  onClose,
  acceptedFormats,
}: DropDocumentsFormatsModalProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      backgroundDisabled={true}
      isDisplayed={isDisplayed}
      onClose={onClose}
      top={"41rem"}
      right={"4rem"}
    >
      {isDisplayed && (
        <TootTip
          backgroundColor={"navy"}
          trianglePosition={{
            position: "top",
            right: 13.5,
            top: -0.5,
            size: 1.25,
          }}
        >
          <Ct.Text
            text={intl.formatMessage({
              id: "company-drop-document.accepted-formats",
            })}
            textStyle={{ color: "white", textAlign: "center", fontWeight: 500 }}
          />
          <Ct.Spacer height={0.5} />
          <Ct.Text
            text={acceptedFormats.join(" ")}
            textStyle={{
              color: "white",
              fontSize: 1.5,
              textAlign: "center",
              fontWeight: 500,
              lineHeight: 2.25,
            }}
          />
          <Ct.Spacer />
          <Ct.Text
            text={intl.formatMessage({
              id: "company-drop-document.empty",
            })}
            textStyle={{ color: "white", textAlign: "center", fontWeight: 500 }}
          />
        </TootTip>
      )}
    </Ct.Modal>
  )
}

const TootTip = styled((props) => <Ct.Card {...props} />)`
  padding: 2rem;
`
