import { useState } from "react"
import styled from "styled-components/macro"
import { IntlShape } from "react-intl"

import { ReactComponent as ChevronDown } from "../../assets/chevron-down.svg"
import { Text } from "../../components/Commons/Text"
import { colors } from "../../styles/design.config"

interface FilterDropdownProps {
  intl: IntlShape
  filterOptions: { key: string; onSelect: () => void }[]
  selectedOptionIndex: number
}

export const FilterDropdown = ({
  intl,
  selectedOptionIndex,
  filterOptions,
}: FilterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <FilterDropdownWrapper>
      <FilterButton
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <TextWrapper>
          <Text
            text={`${intl.formatMessage({
              id: "input-mask.filter-dropdown.show",
            })} : `}
            textStyle={{
              color: "steelBlue",
              cursor: "pointer",
            }}
          />
          <Text
            text={intl.formatMessage({
              id: filterOptions[selectedOptionIndex].key,
            })}
            textStyle={{
              fontWeight: 500,
              cursor: "pointer",
            }}
          />
        </TextWrapper>
        <Separator />
        <StyledChevronWrapper isOpen={isOpen}>
          <ChevronDown />
        </StyledChevronWrapper>
      </FilterButton>
      <Options isOpen={isOpen}>
        {filterOptions.map((o) => (
          <Option
            key={o.key}
            onClick={() => {
              o.onSelect()
              setIsOpen(false)
            }}
          >
            <Text
              text={intl.formatMessage({
                id: o.key,
              })}
              textStyle={{
                fontWeight: 500,
                cursor: "pointer",
              }}
            />
          </Option>
        ))}
      </Options>
    </FilterDropdownWrapper>
  )
}

const FilterButton = styled.div`
  display: flex;
  box-shadow: 0px 4px 14px rgba(2, 76, 248, 0.1);
  border-radius: 1rem;
  user-select: none;
  cursor: pointer;
`

const TextWrapper = styled.div`
  padding: 1rem;
  padding-right: 0rem;
`

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${colors.rock};
  margin: 0 1rem;
  cursor: pointer;
`

const FilterDropdownWrapper = styled.div`
  position: relative;

  display: flex;
  box-shadow: 0px 4px 14px rgba(2, 76, 248, 0.1);
`

const StyledChevronWrapper = styled.div<{ isOpen: boolean }>`
  margin-right: 1rem;
  display: flex;
  align-items: center;

  transition-duration: 0.2s;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : ``)};
`

const Options = styled.div<{ isOpen: boolean }>`
  visibility: ${({ isOpen }) => (isOpen ? "visible" : `hidden`)};
  opacity: ${({ isOpen }) => (isOpen ? "1" : `0`)};
  transition: all 0.5s ease-in-out;

  position: absolute;
  background: white;
  width: 100%;
  top: 6rem;

  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(2, 76, 248, 0.1);
`
const Option = styled.div`
  display: flex;
  padding: 1rem 0;
`
