import { useState, useEffect, Fragment } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { ReactComponent as TrashCan } from "../../../../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Plus } from "../../../../assets/circle-plus.svg"
import { ReactComponent as Warning } from "../../../../assets/warning.svg"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { colors } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import { useRNBSelector } from "../../../../store/rootReducer"
import {
  getBankAccountsForCompanyThunk,
  getAccountsByBankThunk,
  addBank,
  getBankAccountsForCompanyAttemptAction,
  ItemsResource,
  syncErrorThunk,
  deactivateItemReset,
} from "../../../../store/ducks/bank.ducks"
import * as Ct from "ldlj"
import { SortButton } from "../../../../components/Commons/SortButton"
import { SyncBankinTransactionsThunk } from "../../../../store/ducks/bankArchives.ducks"
import {
  DeleteBankOrAccount,
  DisplayDeletionModalParams,
} from "../../../../components/bank/DeleteBankOrAccount"
import { Button } from "../../../../components/Commons/Button"
import { accountIsSet, redirectionsAccount } from "../../../../utils/bank"
import ReactTooltip from "react-tooltip"
import { croppedEndString } from "../../../../utils/string"

/* eslint-disable camelcase */

export const Accounts = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [asc, setAsc] = useState(false)

  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const { fiduciaryName, fiduciaryId, pricingType, override_pricing } =
    useRNBSelector((state) => ({
      fiduciaryName: state.fiduciary.name,
      fiduciaryId: state.fiduciary.id,
      pricingType: state.fiduciary.active_pricing?.pricing_type,
      override_pricing: state.fiduciary.override_pricing,
    }))
  if (pricingType === "per_document" && !override_pricing) {
    navigate("/Unauthorized")
  }

  const itemsList = useRNBSelector((state) => state.bank.items)
  const accountsList = useRNBSelector((state) => state.bank.accounts)
  const deactivateItemStatus = useRNBSelector(
    (state) => state.bank.deactivateItemStatus
  )
  const userTypology = useRNBSelector((state) => state.user.typology)

  const [sortedItems, setSortedItems] = useState<ItemsResource[]>(
    Object.values(itemsList)
  )
  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<DisplayDeletionModalParams>({
      isDisplayed: false,
      accountOrBank: "",
      accountId: null,
      bankId: null,
    })

  const getAccountsNumber = (id: number) =>
    Object.values(accountsList).filter(
      (account) => account.bankin_item_id === id
    ).length

  const accountsOfBank = (id: number) => {
    return Object.values(accountsList).filter(
      (account) => account.bankin_item_id === id
    )
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setSortedItems(
      Object.values(itemsList).sort((a, b) =>
        asc
          ? a?.bank_name.localeCompare(b?.bank_name)
          : b?.bank_name.localeCompare(a?.bank_name)
      )
    )
    setAsc(!asc)
  }

  useEffect(() => {
    dispatch(getBankAccountsForCompanyAttemptAction())
    if (selectedCompanyId) {
      dispatch(getBankAccountsForCompanyThunk(fiduciaryId, selectedCompanyId))
    }
  }, [selectedCompanyId, dispatch, fiduciaryId])

  useEffect(() => {
    if (selectedCompanyId) {
      Object.values(itemsList).forEach((item) =>
        dispatch(
          getAccountsByBankThunk(fiduciaryId, selectedCompanyId, item.id)
        )
      )
    }
  }, [itemsList, selectedCompanyId, dispatch, fiduciaryId])

  useEffect(() => {
    setSortedItems(
      Object.values(itemsList).sort((a, b) =>
        a.bank_name.localeCompare(b.bank_name)
      )
    )
  }, [itemsList])

  type SortOptionsValues = "alphabetical"

  const sortOptions: Ct.OptionList<SortOptionsValues> = [
    { value: "alphabetical", label: "Alphabétique", sortType: "string" },
  ]

  const clickAddBank = () => {
    if (selectedCompanyId) {
      const name = fiduciaryName.replace(/\s+/g, "").toLowerCase()
      dispatch(addBank(fiduciaryId, selectedCompanyId, name))
    }
  }

  useEffect(() => {
    if (deactivateItemStatus === "SUCCESS" && selectedCompanyId) {
      dispatch(deactivateItemReset())
      setDisplayDeletionModal({
        isDisplayed: false,
        accountOrBank: "",
        accountId: null,
        bankId: null,
      })

      dispatch(getBankAccountsForCompanyThunk(fiduciaryId, selectedCompanyId))
    }
  }, [deactivateItemStatus])

  return (
    <>
      <DeleteBankOrAccount
        onClose={() => {
          setDisplayDeletionModal({
            isDisplayed: false,
            accountOrBank: "",
            accountId: null,
            bankId: null,
          })
        }}
        isDisplayed={displayDeletionModal.isDisplayed}
        accountOrBank={displayDeletionModal.accountOrBank}
        accountId={displayDeletionModal.accountId}
        bankId={displayDeletionModal.bankId}
      />

      <ControlBar>
        <Ct.Input
          label="Rechercher"
          value={search}
          suffix={<Search />}
          maxWidth={30}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          shadowed={true}
          noBorder={true}
        />

        <Ct.Row>
          <Button
            label={intl.formatMessage({ id: "bank-accounts.add-bank" })}
            onClick={() => clickAddBank()}
            prefix={<Plus />}
            width={25}
          />

          <Ct.Spacer width={4} />

          <SortButton
            intl={intl}
            sortMethod={sorter(asc)}
            options={sortOptions}
            defaultOption={sortOptions[0]}
            asc={!asc}
          />
        </Ct.Row>
      </ControlBar>
      <Content>
        {Object.values(sortedItems)
          .filter((element) =>
            element.bank_name.toLowerCase().includes(search.toLowerCase())
          )
          .map((bank) => (
            <BankCard key={bank.bankin_id}>
              <CardContent>
                <BankHeader>
                  <BankDetails
                    onClick={() => {
                      navigate(
                        `/office/company/${selectedCompanyId}/bank/accounts/bank/${bank.id}`
                      )
                    }}
                  >
                    <BankName
                      text={bank.bank_name}
                      textStyle={{
                        color: "navy",
                        fontSize: 2,
                        textTransform: "uppercase",
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        cursor: "pointer",
                      }}
                    />

                    <BankName
                      text={intl.formatMessage(
                        { id: "bank-accounts.count-accounts" },
                        {
                          count: getAccountsNumber(bank.id),
                          plural: getAccountsNumber(bank.id) > 1 ? "s" : "",
                        }
                      )}
                      textStyle={{
                        color: "slateGrey",
                        fontSize: 1.5,
                        fontWeight: 500,
                        cursor:
                          userTypology !== "customer" ? "pointer" : "default",
                      }}
                    />
                  </BankDetails>

                  {userTypology !== "customer" && (
                    <TrashWrapper
                      onClick={() => {
                        setDisplayDeletionModal({
                          isDisplayed: true,
                          accountOrBank: "banque",
                          accountId: null,
                          bankId: bank.id,
                        })
                      }}
                    >
                      <TrashCan />
                    </TrashWrapper>
                  )}
                </BankHeader>

                <BankBody>
                  {bank.status > 0 && bank.status !== 1100 && (
                    <ErrorStatusWrapper>
                      <Ct.Spacer />

                      <TextWarning>
                        <StyledWarning color={colors.amaranth} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "bank-accounts.error-bridge1",
                          })}
                          textStyle={{
                            color: "amaranth",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        />
                      </TextWarning>

                      <Ct.Spacer height={1} />

                      <Ct.Text
                        text={intl.formatMessage(
                          { id: "bank-accounts.error-bridge-status" },
                          { bankinId: bank.bankin_id, status: bank.status }
                        )}
                        textStyle={{
                          color: "amaranth",
                          fontStyle: "italic",
                        }}
                      />

                      <Ct.Spacer />

                      <Button
                        label={intl.formatMessage({
                          id: "bank-accounts.error-bridge.reconnect",
                        })}
                        colorType="Tertiary"
                        colorScheme={{
                          background: "amaranth",
                          color: "white",
                          border: "amaranth",
                        }}
                        onClick={() => {
                          if (!selectedCompanyId) return
                          dispatch(
                            syncErrorThunk(
                              fiduciaryId,
                              selectedCompanyId,
                              bank.id,
                              fiduciaryName
                            )
                          )
                        }}
                      />
                    </ErrorStatusWrapper>
                  )}

                  {userTypology === "customer" &&
                    accountsOfBank(bank.id).some((a) => !accountIsSet(a)) && (
                      <ErrorStatusWrapper>
                        <Ct.Spacer />

                        <TextWarning>
                          <StyledWarning color={colors.orange} />
                          <Ct.Text
                            text={intl.formatMessage({
                              id: "bank-accounts.customer.is-account-not-set",
                            })}
                            textStyle={{
                              color: "orange",
                              textAlign: "center",
                              fontSize: 1.5,
                            }}
                          />
                        </TextWarning>
                      </ErrorStatusWrapper>
                    )}

                  <Ct.Spacer />

                  <div>
                    {accountsOfBank(bank.id) &&
                      accountsOfBank(bank.id).length > 0 &&
                      accountsOfBank(bank.id).map((bankaccount) => (
                        <Fragment key={bankaccount.id}>
                          <AccountStrip
                            data-cy={"bankAccountUnset"}
                            clickable={
                              !(
                                userTypology === "customer" &&
                                !accountIsSet(bankaccount)
                              )
                            }
                            onClick={() => {
                              if (
                                selectedCompanyId &&
                                !(
                                  userTypology === "customer" &&
                                  !accountIsSet(bankaccount)
                                )
                              ) {
                                dispatch(
                                  SyncBankinTransactionsThunk(
                                    bankaccount.id,
                                    fiduciaryId,
                                    selectedCompanyId,
                                    bankaccount.bankin_item_id
                                  )
                                )
                                navigate(
                                  redirectionsAccount(
                                    selectedCompanyId,
                                    bankaccount.bankin_item_id,
                                    bankaccount
                                  )
                                )
                              }
                            }}
                          >
                            <Ct.Row>
                              <Ct.Spacer />
                              <ColoredCircle
                                data-tip={
                                  !accountIsSet(bankaccount)
                                    ? intl.formatMessage({
                                        id: "bank-accounts.is-account-set",
                                      })
                                    : ""
                                }
                                color={
                                  accountIsSet(bankaccount)
                                    ? colors.shamrock
                                    : colors.orange
                                }
                              />

                              {!accountIsSet(bankaccount) && (
                                <ReactTooltip
                                  delayShow={200}
                                  effect={"solid"}
                                  eventOff="mouseleave scroll mousewheel blur"
                                />
                              )}

                              <Ct.Spacer />

                              <Ct.Text
                                text={croppedEndString(bankaccount.name, 35)}
                                textStyle={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontSize: 1.75,
                                  color:
                                    userTypology === "customer" &&
                                    !accountIsSet(bankaccount)
                                      ? "moon"
                                      : "navy",
                                  fontWeight: 600,
                                  cursor:
                                    userTypology === "customer" &&
                                    !accountIsSet(bankaccount)
                                      ? "default"
                                      : "pointer",
                                }}
                              />
                            </Ct.Row>

                            {userTypology !== "customer" && (
                              <AccountActions>
                                <span
                                  onClick={() => {
                                    if (selectedCompanyId) {
                                      dispatch(
                                        SyncBankinTransactionsThunk(
                                          bankaccount.id,
                                          fiduciaryId,
                                          selectedCompanyId,
                                          bankaccount.bankin_item_id
                                        )
                                      )
                                      navigate(
                                        redirectionsAccount(
                                          selectedCompanyId,
                                          bankaccount.bankin_item_id,
                                          bankaccount
                                        )
                                      )
                                    }
                                  }}
                                >
                                  <StyledEdit />
                                </span>
                              </AccountActions>
                            )}
                          </AccountStrip>

                          <Ct.Spacer height={2} />
                        </Fragment>
                      ))}
                  </div>
                </BankBody>
              </CardContent>
            </BankCard>
          ))}
      </Content>
    </>
  )
}

const ControlBar = styled((props) => <Ct.SpacedBetweenCenter {...props} />)`
  box-sizing: border-box;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 55rem);
  justify-content: left;
  padding-top: 4rem;
  grid-column-gap: 4rem;
  grid-row-gap: 36rem;
  grid-auto-rows: 20rem;
  height: fit-content;
  padding-bottom: 5rem;
`
const BankHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const BankCard = styled.section`
  display: flex;
  padding: 2rem;
  height: 48rem;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(2, 76, 248, 0.1);
  border-radius: 10px;
`

const CardContent = styled.div`
  width: 100%;
  padding-top: 1rem;
`

const BankName = styled(Ct.Text)`
  transition: all 0.2s ease-in-out;
`

const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  cursor: "pointer";
  &:hover ${BankName} {
    color: ${colors.cornflower};
  }
`

const ErrorStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AccountStrip = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  width: 96%;
  height: 7rem;
  box-shadow: 0px 5px 10px rgba(2, 76, 248, 0.15);
  border-radius: 1.25rem;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  background-color: ${(props) =>
    props.clickable ? colors.white : colors.desertStorm};
  margin: 0 auto;
  :hover {
    transition: all 0.2s ease-in-out;
    box-shadow: ${(props) =>
      props.clickable && "0px 5px 15px rgba(2, 76, 248, 0.4)"};
  }
`

const AccountActions = styled.div`
  display: flex;
  padding-right: 3rem;
`
const StyledEdit = styled(({ ...props }) => <Edit {...props} />)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`

const ColoredCircle = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: none;
  width: 2rem;
  height: 2rem;
`

const StyledWarning = styled(({ ...props }) => <Warning {...props} />)<{
  color: string
}>`
  width: 5rem;
  height: 2rem;
  & path {
    fill: ${(props) => props.color};
  }
`

const TextWarning = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  width: 80%;
`

const BankBody = styled.div`
  overflow-y: auto;
  height: 38rem;
`

const TrashWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-self: center;
`

export default Accounts
