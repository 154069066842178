import {
  DecentralizeAndUpdateMerchantCodeThunk,
  MerchantCode,
  MerchantsOfCode,
} from "../../store/ducks/merchantCodes.ducks"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { LockableInput } from "../Commons/LockableInput"
import { CreatableSelect } from "../Commons/CreatableSelect"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect, useState } from "react"
import { capitalizeFirstLetter } from "../../utils/string"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { normalizeMerchantCode } from "../../utils/merchantCodes"

/* eslint-disable camelcase */

interface DecentralizeCodeProps extends Ct.ModalComponentsProps {
  merchantCode: MerchantCode
  merchants: MerchantsOfCode[]
}

export const DecentralizeCodeModal = ({
  merchantCode,
  merchants,
  isDisplayed,
  onClose,
}: DecentralizeCodeProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  type option = { value: string; label: string }

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const decentraliseMerchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  ).filter((code) => !code.centralize && code.merchants?.length > 0)

  const [merchantCodeExist, setMerchantCodeExist] = useState(false)

  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  ).filter((code) => code.centralize || code.merchants?.length === 0)

  const [codeOptions, setCodeOptions] = useState<Ct.Option<string>[]>(
    merchantCodes.map((code) => ({
      value: code.id.toString(),
      label: code.code,
      disabled: code.id === merchantCode.id ? true : false,
    }))
  )

  const [selectedCodes, setSelectedCodes] = useState<
    { value: string; label: string; disabled: boolean }[]
  >(
    merchants.map((m) => {
      return {
        value: `${merchantCode.id}`,
        label: merchantCode.code,
        disabled: false,
      }
    })
  )
  const [changeCode, setChangeCode] = useState<{
    value: string
    label: string
    index: number
  } | null>(null)

  const isMerchantSelectedOnlyOnce =
    selectedCodes.filter((c) => c.value === String(merchantCode.id)).length > 1

  useEffect(() => {
    if (changeCode) {
      if (changeCode.value === "") {
        // CREATION
        selectedCodes.splice(changeCode.index, 1, {
          value: changeCode.value,
          label: changeCode.label,
          disabled: true,
        })

        setCodeOptions([
          ...codeOptions,
          { value: "", label: changeCode.label, disabled: true },
        ])
      } else {
        const newOption = codeOptions.find((c) => c.value === changeCode.value)
        const newCode = merchantCodes.find(
          (c) => c.id === Number(newOption?.value)
        )

        if (newOption) {
          if (newCode) {
            setCodeOptions(
              codeOptions.map((o) => {
                if (
                  !newCode.centralize &&
                  o.value === String(newCode.id) &&
                  newCode.merchants?.length === 0
                ) {
                  return {
                    value: o.value,
                    label: o.label,
                    disabled: true,
                  }
                } else {
                  return {
                    value: o.value,
                    label: o.label,
                    disabled: merchantCodes.some(
                      (c) => c.id === Number(o.value) && c.centralize
                    )
                      ? false
                      : selectedCodes.some(
                          (c) => c.value === o.value && c.disabled
                        )
                      ? true
                      : false,
                  }
                }
              })
            )
          }

          selectedCodes.splice(changeCode.index, 1, {
            value: newOption.value,
            label: newOption.label,
            disabled: true,
          })
          setSelectedCodes(selectedCodes)
          setChangeCode(null)
        }
      }
    }
  }, [changeCode])

  useEffect(() => {
    const merchantCodeExists = selectedCodes.some((dmo) =>
      decentraliseMerchantCodes.find((c) => c.code === dmo.label)
    )
    setMerchantCodeExist(merchantCodeExists)
  }, [changeCode, selectedCodes, merchantCodeExist])

  const saveMerchantCodes = () => {
    const all: { merchant_id: number; code_id: number | null; code: string }[] =
      selectedCodes.map((c, index) => {
        return {
          merchant_id: merchants[index].merchant_id,
          code_id: c.value ? Number(c.value) : null,
          code: c.label,
        }
      })

    dispatch(
      DecentralizeAndUpdateMerchantCodeThunk(
        merchantCode.id,
        all,
        selectedCompanyId
      )
    )
    onClose()
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top={`calc(30vh - ${merchants.length > 7 ? "35rem" : "15rem"})`}
    >
      <Ct.Card
        width={"160rem"}
        height={`${merchants.length > 7 ? "95vh" : "75vh"}`}
      >
        <Ct.CloseCross onClick={onClose} />
        <Ct.Title
          text={intl.formatMessage(
            { id: "accounting-plan.decantralize-code.title" },
            { code: merchantCode.code }
          )}
          size={7}
        />

        <Ct.Spacer height={4} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={4} />

        <Ct.Text
          text={intl.formatMessage({
            id: "accounting-plan.decantralize-code.text",
          })}
        />
        <Ct.Spacer height={4} />

        <Scrollable>
          <Row>
            <Half>
              <BlockText
                text={intl.formatMessage({
                  id: "accounting-plan.decantralize-code.clients",
                })}
                textStyle={{
                  fontSize: 2,
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              />
              <Ct.Spacer width={3} />
              {merchantCode.merchants?.map((merchant) => (
                <div key={merchant.merchant_id}>
                  <LockableInput
                    locked={true}
                    label=""
                    value={capitalizeFirstLetter(merchant.merchant_name)}
                  />
                  <Ct.Spacer width={3} />
                </div>
              ))}
            </Half>

            <Ct.Spacer width={3} />
            <Ct.VerticalSeparator />
            <Ct.Spacer width={3} />

            <Half>
              <BlockText
                text={intl.formatMessage({
                  id: "accounting-plan.decantralize-code.new-codes",
                })}
                textStyle={{
                  fontSize: 2,
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              />
              <Ct.Spacer width={3} />
              {merchantCode.merchants?.map((merchant, index) => (
                <div key={merchant.merchant_id}>
                  <CreatableSelect
                    value={selectedCodes[index]}
                    disabled={false}
                    options={codeOptions}
                    onChangeCallback={(option: option | null) => {
                      if (option) {
                        setChangeCode({
                          value: option.value,
                          label: option.label,
                          index: index,
                        })
                      }
                    }}
                    onCreateOption={(option: string) => {
                      setChangeCode({
                        value: "",
                        label: normalizeMerchantCode(option),
                        index: index,
                      })
                    }}
                    placeholderText={intl.formatMessage({
                      id: `accounting-plan.history.code`,
                    })}
                    formatCreateLabel={intl.formatMessage({
                      id: `office.modify-merchant.merchant-code.create`,
                    })}
                    error={
                      decentraliseMerchantCodes.find(
                        (c) => c.code === selectedCodes[index].label
                      ) !== undefined ||
                      (isMerchantSelectedOnlyOnce &&
                        merchantCode.code === selectedCodes[index].label)
                    }
                  />
                  {decentraliseMerchantCodes.find(
                    (c) => c.code === selectedCodes[index].label
                  ) !== undefined && (
                    <>
                      <Ct.Spacer height={1} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "accounting-plan.decantralize-code-creation.error-already-exist",
                        })}
                        textStyle={{
                          color: "amaranth",
                        }}
                      />
                    </>
                  )}
                  <Ct.Spacer width={3} />
                </div>
              ))}
            </Half>
          </Row>

          <Ct.Spacer height={4} />

          <Buttons>
            <Ct.Button
              label="Annuler"
              width={42.5}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              onClick={onClose}
            />
            <Ct.Button
              label="Sauvegarder"
              width={42.5}
              disabled={merchantCodeExist || isMerchantSelectedOnlyOnce}
              onClick={() => {
                saveMerchantCodes()
              }}
            />
          </Buttons>
          <Ct.Spacer height={4} />
        </Scrollable>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 88%;
  padding: 0 8rem;
`
const Half = styled.div`
  width: 50%;
`
const BlockText = styled(Ct.Text)`
  display: block;
`
const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`
const Scrollable = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
`
