import { useRNBSelector } from "../../../../store/rootReducer"
import { useNavigate, Navigate } from "react-router-dom"
import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useEffect, useState } from "react"

import styled from "styled-components/macro"
import { ReactComponent as FECFile } from "../../../../assets/file.svg"
import { boxShadow, colors, sizes } from "../../../../styles/design.config"
import { ReactComponent as NoFec } from "../../../../assets/upload/no_fec.svg"

import {
  GetCompanyDeactivatedSettings,
  GetCompanyThunk,
  LedgerType,
  CompanyStub,
  SaveCompanySettingsThunk,
  DeactivatedSettingsKey,
  DeactivatedSettings,
} from "../../../../store/ducks/companies.ducks"
import "cropperjs/dist/cropper.css"
import { useParams } from "react-router-dom"
import { getIdFromParams } from "../../../../utils/company"
import { attemptToLoadUsersThunk } from "../../../../store/ducks/team.ducks"
import { usePrompt } from "../../../../utils/usePrompt.hook"
import { getCurrentUserThunk } from "../../../../store/ducks/user.ducks"
import { truncateFileName } from "../../../../utils/string"
import { Alert } from "../../../../components/Commons/Alert"
import { MyUnisoftToken } from "./MyUnisoftToken"
import { getUrlForStandardLedgerThunk } from "../../../../store/ducks/standardLedger.ducks"
import { formatDateDate } from "../../../../utils/date"
import { updateMyUnisoftTokenThunk } from "../../../../store/ducks/companySettings.ducks"
import { LockableInput } from "../../../../components/Commons/LockableInput"

const Settings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  const userTypology = useRNBSelector((state) => state.user.typology)

  if (userTypology === "customer") {
    navigate("/unauthorized")
  }
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  const users = useRNBSelector((state) => state.team.team)
  const companies = useRNBSelector((state) => state.companies.companies)

  const selectedCompany = companies[selectedCompanyId] || CompanyStub

  const { myUnisoftToken } = useRNBSelector((state) => state.companySettings)

  useEffect(() => {
    setHiddenFromTeam(selectedCompany.hidden_from_team)
  }, [selectedCompany])

  useEffect(() => {
    setModifiedMyUnisoftToken(myUnisoftToken)
  }, [myUnisoftToken])

  const [modifiedMyUnisoftToken, setModifiedMyUnisoftToken] =
    useState(myUnisoftToken)

  const myUnisoftTokenHasChanged = modifiedMyUnisoftToken !== myUnisoftToken

  let newDeactivatedSettings = {
    ...selectedCompany.deactivatedSettings,
  } as DeactivatedSettings
  const [deactivatedSettings, setDeactivatedSettings] = useState<
    DeactivatedSettings | undefined
  >(newDeactivatedSettings)
  const initialHiddenSwitch = selectedCompany.hidden_from_team

  const initialAcdApiDueDate = selectedCompany.acd_api_due_date
  const initialAcdApiDocumentReference =
    selectedCompany.acd_api_document_reference
  const [hiddenfromTeam, setHiddenFromTeam] = useState(initialHiddenSwitch)
  const [acdApiDueDate, setAcdApiDueDate] = useState(initialAcdApiDueDate)
  const [acdApiDocumentReference, setAcdApiDocumentReference] = useState(
    initialAcdApiDocumentReference
  )
  useEffect((): void => {
    dispatch(attemptToLoadUsersThunk())
  }, [dispatch])

  useEffect(() => {
    if (selectedCompanyId !== 0) {
      dispatch(GetCompanyThunk(selectedCompanyId))
      dispatch(GetCompanyDeactivatedSettings(selectedCompanyId))
    }
  }, [selectedCompanyId, dispatch])

  useEffect(() => {
    if (selectedCompany.deactivatedSettings) {
      setDeactivatedSettings(selectedCompany.deactivatedSettings)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (hiddenfromTeam === undefined) {
      setHiddenFromTeam(initialHiddenSwitch)
    }
  }, [initialHiddenSwitch, hiddenfromTeam])

  useEffect(() => {
    setAcdApiDueDate(initialAcdApiDueDate)
  }, [initialAcdApiDueDate])

  useEffect(() => {
    setAcdApiDocumentReference(initialAcdApiDocumentReference)
  }, [initialAcdApiDocumentReference])

  const settingsHasChanged =
    JSON.stringify(deactivatedSettings) !==
    JSON.stringify(newDeactivatedSettings)

  const switchHiddenCompanyHasChanged = hiddenfromTeam !== initialHiddenSwitch

  const switchAcdSentDataHasChanged =
    initialAcdApiDueDate !== acdApiDueDate ||
    initialAcdApiDocumentReference !== acdApiDocumentReference

  usePrompt(
    intl.formatMessage({ id: "form.unsaved.message" }),
    settingsHasChanged ||
      switchHiddenCompanyHasChanged ||
      switchAcdSentDataHasChanged
  )

  if (selectedCompanyId === 0) {
    return <Navigate to="/office/fiduciary/companies" />
  }

  const downloadText = intl.formatMessage({
    id: "office-company-settings.downloadFEC",
  })

  const changeCheckboxValue = (key: DeactivatedSettingsKey) => {
    if (deactivatedSettings) {
      setDeactivatedSettings({
        ...deactivatedSettings,
        [key]: {
          text: deactivatedSettings[key]?.text,
          value: !deactivatedSettings[key]?.value,
        },
      })
    }
  }

  const submit = () => {
    if (deactivatedSettings) {
      dispatch(
        SaveCompanySettingsThunk({
          deactivatedSettings: deactivatedSettings,
          companyId: selectedCompanyId,
          hiddenFromTeam: hiddenfromTeam !== undefined ? hiddenfromTeam : null,
          acdApiDueDate: acdApiDueDate !== undefined ? acdApiDueDate : null,
          acdApiDocumentReference:
            acdApiDocumentReference !== undefined
              ? acdApiDocumentReference
              : null,
        })
      )
    }

    if (myUnisoftTokenHasChanged) {
      dispatch(
        updateMyUnisoftTokenThunk({
          companyId: selectedCompanyId,
          token: modifiedMyUnisoftToken,
        })
      )
    }
    return
  }

  const getUserName = (id: string) => {
    const userToDisplay = Object.values(users).find(
      (user) => user.id === Number(id)
    )
    if (userToDisplay) {
      return userToDisplay.first_name + " " + userToDisplay.last_name
    }
  }

  return (
    <Wrapper>
      <StyledSection>
        <StyledContent>
          <Ct.StyledForm>
            {userTypology !== "customer" && (
              <>
                <WrapperInput>
                  <LockableInput
                    locked={true}
                    value={intl.formatMessage({
                      id: `office-company-settings.accounting_type.${selectedCompany.accounting_type}`,
                    })}
                    label={intl.formatMessage({
                      id: "office-company-settings.accounting_type-label",
                    })}
                  />
                </WrapperInput>

                <Ct.Spacer height={3} />
              </>
            )}

            {userTypology !== "customer" &&
              selectedCompany.accounting_software === "acd" && (
                <SwitchContainerBlock>
                  <SwitchContainer>
                    <Ct.Switch
                      value={acdApiDueDate || false}
                      onToggle={function (): void {
                        setAcdApiDueDate(!acdApiDueDate)
                      }}
                    />
                    <Ct.Spacer width={2} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "company-register.send-due-date-ACD",
                      })}
                    />
                    {acdApiDueDate && (
                      <>
                        <Ct.Spacer width={2} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "company-register.send-due-date-ACD.alert",
                          })}
                          textStyle={{
                            color: "amaranth",
                            fontWeight: 500,
                          }}
                        />
                      </>
                    )}
                  </SwitchContainer>
                  <Ct.Spacer height={4} />
                  <SwitchContainer>
                    <Ct.Switch
                      value={acdApiDocumentReference || false}
                      onToggle={function (): void {
                        setAcdApiDocumentReference(!acdApiDocumentReference)
                      }}
                    />
                    <Ct.Spacer width={2} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "company-register.send-document-reference-ACD",
                      })}
                    />
                    {acdApiDocumentReference && (
                      <>
                        <Ct.Spacer width={2} />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "company-register.send-document-reference-ACD.alert",
                          })}
                          textStyle={{
                            color: "amaranth",
                            fontWeight: 500,
                          }}
                        />
                      </>
                    )}
                  </SwitchContainer>
                  <Ct.Spacer height={4} />
                </SwitchContainerBlock>
              )}

            {userTypology !== "customer" &&
              selectedCompany.accounting_software === "my_unisoft" && (
                <>
                  <MyUnisoftToken
                    modifiedMyUnisoftToken={modifiedMyUnisoftToken || ""}
                    setModifiedMyUnisoftToken={(newToken) => {
                      setModifiedMyUnisoftToken(newToken)
                    }}
                  />
                  <Ct.Spacer height={3} />
                </>
              )}

            {selectedCompany.accounting_type === "accrual_accounting" && (
              <Alert alertType="info" margin={"0 4rem"}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-settings.checkbox-info",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Spacer height={3} />

                <CheckboxGrid>
                  {deactivatedSettings &&
                    Object.keys(deactivatedSettings).map(
                      (key, index: number) => (
                        <Ct.Checkbox
                          id={
                            deactivatedSettings?.[key as DeactivatedSettingsKey]
                              ?.text
                          }
                          key={index}
                          name={key}
                          isChecked={
                            deactivatedSettings[key as DeactivatedSettingsKey]
                              .value
                          }
                          onChange={() =>
                            changeCheckboxValue(key as DeactivatedSettingsKey)
                          }
                          label={
                            deactivatedSettings?.[key as DeactivatedSettingsKey]
                              ?.text || ""
                          }
                        />
                      )
                    )}
                </CheckboxGrid>

                <Ct.Spacer height={1} />
              </Alert>
            )}
            <Ct.Spacer height={2} />

            <FEC>
              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-settings.your-FEC",
                })}
              />
              <Ct.Spacer height={2} />
            </FEC>

            {selectedCompany.standard_ledgers &&
              selectedCompany.standard_ledgers.map((ledger: LedgerType) => (
                <CardNoFec width={"87rem"} padding={"1.5rem"} key={ledger.id}>
                  <CardFEC>
                    <FECFile />
                    <Ct.Text
                      text={truncateFileName(ledger.original_file_name, 20)}
                      textStyle={{
                        fontWeight: 600,
                      }}
                    />

                    <VerticalLine />

                    <Parts>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "office-company-settings.deposit-date",
                        })}
                        textStyle={{
                          fontWeight: 600,
                        }}
                      />
                      <Ct.Text
                        text={formatDateDate(ledger.created_at)}
                        textStyle={{
                          fontWeight: 600,
                        }}
                      />
                    </Parts>

                    <VerticalLine />

                    <Parts>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "office-company-settings.deposit-by",
                        })}
                        textStyle={{
                          fontWeight: 600,
                        }}
                      />
                      <Ct.Text
                        text={getUserName(ledger.confirm_user_id) || ""}
                        textStyle={{
                          fontWeight: 600,
                        }}
                      />
                    </Parts>

                    <VerticalLine />

                    <StyledLink
                      onClick={() => {
                        dispatch(getUrlForStandardLedgerThunk(ledger.id))
                      }}
                    >
                      {downloadText}
                    </StyledLink>
                  </CardFEC>
                </CardNoFec>
              ))}

            {selectedCompany.no_fec && (
              <CardNoFec width={"87rem"} padding={"1.5rem"} key={"no-fec"}>
                <NoFecWrapper>
                  <Ct.Spacer width={2} />
                  <StyledNoFec />
                  <Ct.Spacer width={2} />

                  <NoFecText>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "initializationFEC.no-fec.settings.title",
                      })}
                    />

                    <Ct.Text
                      text={intl.formatMessage({
                        id: "initializationFEC.no-fec.settings.message",
                      })}
                    />
                  </NoFecText>
                </NoFecWrapper>
              </CardNoFec>
            )}

            <Ct.Spacer height={4} />
            {userTypology === "administrator" && (
              <SwitchContainerBlock>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-settings.hidden-company",
                  })}
                />
                <Ct.Spacer height={2} />
                <SwitchContainer>
                  <Ct.Switch
                    value={hiddenfromTeam || false}
                    onToggle={function (): void {
                      userTypology === "administrator" &&
                        setHiddenFromTeam(!hiddenfromTeam)
                    }}
                  />
                  <Ct.Spacer width={2} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "company-register.hidden-from-team",
                    })}
                    textStyle={{
                      color:
                        userTypology !== "administrator"
                          ? "disabledGrey"
                          : undefined,
                    }}
                  />
                </SwitchContainer>
                <Ct.Spacer height={4} />
              </SwitchContainerBlock>
            )}
            <Ct.Spacer />

            <Ct.Button
              label={intl.formatMessage({ id: "office-company-settings.cta" })}
              onClick={() => submit()}
              width={sizes.button.standard}
              disabled={
                !settingsHasChanged &&
                !myUnisoftTokenHasChanged &&
                !switchHiddenCompanyHasChanged &&
                !switchAcdSentDataHasChanged
              }
            />
          </Ct.StyledForm>
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

export default Settings

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 10rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  flex: 1;
`
const StyledContent = styled.div`
  width: 94rem;
`
const Parts = styled.div`
  display: block;
  > *:nth-child(1) {
    padding: 1rem;
  }
`
const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3rem;
`
const FEC = styled.div`
  display: block;
  width: 100%;
  font-weight: 500;
`
const CardFEC = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  line-height: 3rem;
`
const VerticalLine = styled.div`
  background-color: ${colors.rock};
  border: 1px solid ${colors.rock};
  width: 1px;
`
const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
const SwitchContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`
const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Roboto-medium", sans-serif;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`
const CardNoFec = styled(Ct.Card)`
  border: 0.125rem solid ${colors.rock};
  box-shadow: none;
`
const NoFecText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
`
const NoFecWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`
const StyledNoFec = styled(NoFec)`
  width: 7rem;
  height: 7rem;
`
const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`
