import styled from "styled-components/macro"
import { ReactComponent as Lock } from "../../assets/lock.svg"
import { colors } from "../../styles/design.config"
import { BaseInputProps, Input } from "./Input"

interface LockableInputProps extends BaseInputProps {
  locked: boolean
}

export const LockableInput = (props: LockableInputProps) => {
  return (
    <Input
      {...props}
      onChange={props.locked ? () => {} : props.onChange}
      suffix={props.locked ? <StyledLock /> : undefined}
    />
  )
}

const StyledLock = styled(Lock)`
  fill: ${colors.cornflower};
`
