import * as Ct from "ldlj"
import { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import styled from "styled-components/macro"
import { useParams } from "react-router-dom"

import { useRNBSelector } from "../../store/rootReducer"
import {
  addPrefixToCode,
  normalizeMerchantCode,
} from "../../utils/merchantCodes"
import { Input } from "../Commons/Input"
import { LockableInput } from "../Commons/LockableInput"
import { buyOrSell, getIdFromParams } from "../../utils/company"
import {
  createMerchantCodeResetAction,
  createMerchantCodeThunk,
  updateCentralizeStatusFromInputMask,
} from "../../store/ducks/inputMask.ducks"
import { GetCompanyMerchantCodesDataThunk } from "../../store/ducks/merchantCodes.ducks"
import { useDebounce } from "../../hooks/useDebounce"

interface MerchantCodeCreateModalProps extends Ct.ModalComponentsProps {
  buyOrSell: buyOrSell
  merchantId: number
}
export type optionType = { value: string; label: string }

export const MerchantCodeCreateModalModal = ({
  isDisplayed,
  onClose,
  buyOrSell,
  merchantId,
}: MerchantCodeCreateModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const { company, merchantCodes, createOrUpdateMerchantCodeStatus } =
    useRNBSelector((state) => ({
      company: state.companies.companies[selectedCompanyId],
      merchantCodes: state.merchantCodes.merchantCodes,
      createOrUpdateMerchantCodeStatus:
        state.inputMask.createOrUpdateMerchantCodeStatus,
    }))

  const [isCentralize, setIsCentralize] = useState<boolean>(false)
  const [merchantCode, setMerchantCode] = useState<string>("")

  const [prefixes, setPrefixes] = useState<{ buy: string; sell: string }>({
    buy: "",
    sell: "",
  })

  useEffect(() => {
    setPrefixes(addPrefixToCode(company))
  }, [company])

  useEffect(() => {
    if (createOrUpdateMerchantCodeStatus === "success") {
      dispatch(createMerchantCodeResetAction())
      onClose()
    }
  }, [createOrUpdateMerchantCodeStatus])

  const resetForm = () => {
    setMerchantCode("")
    setIsCentralize(false)
    setPrefixes({
      buy: "",
      sell: "",
    })
  }
  const close = () => {
    if (merchantCode !== "") {
      const warningMessage = intl.formatMessage({
        id: `accounting-plan.create-code.${buyOrSell}.exit-message-confirm`,
      })
      if (window.confirm(warningMessage)) {
        resetForm()
        onClose()
      }
    } else {
      resetForm()
      onClose()
    }
  }

  const merchantCodeAlreadyExists = merchantCodes.find(
    (mc) => mc.code === merchantCode
  )

  const debouncedMerchantCode = useDebounce(merchantCode)

  useEffect(() => {
    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))
  }, [debouncedMerchantCode])

  const hasChangedCentralize =
    merchantCodeAlreadyExists &&
    merchantCodeAlreadyExists.centralize !== isCentralize

  const isUpdatingCentralize = hasChangedCentralize && isCentralize

  const saveNewCode = () => {
    if (!merchantCodeAlreadyExists) {
      dispatch(
        createMerchantCodeThunk(
          selectedCompanyId,
          merchantCode,
          isCentralize,
          [merchantId],
          false,
          false
        )
      )
    } else {
      dispatch(
        updateCentralizeStatusFromInputMask(
          merchantCodeAlreadyExists.id,
          isCentralize,
          selectedCompanyId
        )
      )
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(50% -32.5rem)`}
    >
      <StyledCard width={"140rem"} height={`65rem`}>
        <Header>
          <Ct.CloseCross onClick={close} />
          <Ct.Title
            text={intl.formatMessage({
              id: `accounting-plan.create-code.${buyOrSell}.title`,
            })}
            size={7}
          />

          <Ct.Spacer height={4} />
          <Ct.Separator size="full" />
          <Ct.Spacer height={4} />
        </Header>

        <ContentSpaceBetween>
          <Wrapper>
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.create-code.part-one.title",
              })}
              textStyle={{
                fontSize: 2,
                color: "navy",
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
          </Wrapper>
          <JustifyBetween>
            <Input
              value={merchantCode}
              onChange={(e) => {
                setMerchantCode(normalizeMerchantCode(e.target.value))
              }}
              label={intl.formatMessage({
                id: "accounting-plan.create-code.part-one.code-label",
              })}
              showWarning={Boolean(
                merchantCodeAlreadyExists &&
                  merchantCodeAlreadyExists?.merchants.length > 0
              )}
              showError={
                Boolean(merchantCodeAlreadyExists) &&
                merchantCodeAlreadyExists?.merchants.length === 0
              }
            />

            <Ct.Spacer width={7} />

            <LockableInput
              label={intl.formatMessage(
                {
                  id: "accounting-plan.create-code.part-one.output-label",
                },
                { buyOrSell: " fournisseurs" }
              )}
              value={merchantCode}
              maxWidth={"35rem"}
              locked={true}
              readOnly={true}
              cursor={"default"}
              prefixString={prefixes.buy}
              padding={
                prefixes.buy && prefixes.buy.length > 0
                  ? `0 1.75rem 0 ${prefixes.buy.length + 2.5}rem`
                  : "0 1.75rem"
              }
            />

            <Ct.Spacer width={2} />

            <LockableInput
              label={intl.formatMessage(
                {
                  id: "accounting-plan.create-code.part-one.output-label",
                },
                { buyOrSell: " clients" }
              )}
              value={merchantCode}
              maxWidth={"35rem"}
              locked={true}
              readOnly={true}
              prefixString={prefixes.sell}
              cursor={"default"}
              padding={
                prefixes.sell && prefixes.sell.length > 0
                  ? `0 1.75rem 0 ${prefixes.sell.length + 2.5}rem`
                  : "0 1.75rem"
              }
            />
          </JustifyBetween>
          {Boolean(merchantCodeAlreadyExists) && (
            <WarningMessage>
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id:
                      merchantCodeAlreadyExists?.merchants.length === 0
                        ? `accounting-plan.create-code.part-one.warning-existing-code`
                        : merchantCodeAlreadyExists?.centralize
                        ? `accounting-plan.create-code.part-one.warning-centralize-one.${buyOrSell}`
                        : `accounting-plan.create-code.part-one.warning-one.${buyOrSell}`,
                  },
                  {
                    merchantName: merchantCodeAlreadyExists?.merchants
                      .map((m) => m.merchant_name)
                      .join(", "),
                  }
                )}
                textStyle={{
                  fontSize: 1.75,
                  color:
                    merchantCodeAlreadyExists?.merchants.length === 0
                      ? "amaranth"
                      : "orange",
                }}
              />

              {!merchantCodeAlreadyExists?.centralize &&
                merchantCodeAlreadyExists &&
                merchantCodeAlreadyExists.merchants.length > 0 && (
                  <>
                    <br />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: isCentralize
                          ? `accounting-plan.create-code.part-one.warning-change-to_centralize-two`
                          : `accounting-plan.create-code.part-one.warning-two`,
                      })}
                      textStyle={{
                        fontSize: 1.75,
                        color: "orange",
                      }}
                    />
                  </>
                )}
            </WarningMessage>
          )}

          <SwitchWrapper>
            <Ct.Switch
              value={isCentralize}
              onToggle={() => {
                setIsCentralize(!isCentralize)
              }}
            />
            <Ct.Spacer width={2} />
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.create-code.part-one.switch-text",
              })}
              textStyle={{
                fontSize: 1.75,
                color: "navy",
              }}
            />
          </SwitchWrapper>

          <div>
            <Ct.Spacer height={4} />

            <WrapperButtons>
              <Ct.Button
                label="Retour"
                width={42.5}
                colorType={"Tertiary"}
                colorScheme={{
                  background: "mist",
                  color: "cornflower",
                  border: "mist",
                }}
                onClick={close}
              />
              <Ct.Spacer width={4} />
              <Ct.Button
                label={"Sauvegarder"}
                width={42.5}
                onClick={saveNewCode}
                disabled={
                  merchantCode === "" ||
                  (merchantCodeAlreadyExists && !isUpdatingCentralize)
                }
              />
            </WrapperButtons>
          </div>
        </ContentSpaceBetween>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: space-between;
`
const Header = styled.div`
  width: 100%;
  text-align: center;
`
const Wrapper = styled.div`
  width: 70%;
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
const ContentSpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

const WarningMessage = styled.div`
  margin-left: 2rem;
`
