export const truncateString = (value: string, maxLength: number) => {
  if (value && value.length <= maxLength) {
    return value
  }
  return value ? value.substring(0, maxLength - 5) + "[...]" : ""
}

export const croppedString = (name: string, maxLength: number) => {
  if (name && name.length > maxLength) {
    const croppedName =
      name.substring(0, Math.floor(maxLength / 2 - 1)) +
      "..." +
      name.substring(name.length - Math.ceil(maxLength / 2 + 1), name.length)
    name = croppedName
  }
  return name
}

export const croppedEndString = (name: string, maxLength: number) => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "..."
  }
  return name
}

export function normalizeString(str: string) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replaceAll(/\p{Diacritic}/gu, "")
    .replaceAll("’", "'")
}

export const fileExtensionOfString = (fileName: string) => {
  const lastDotPosition = fileName.lastIndexOf(".")

  // .foo does not have extension foo
  // a.foo does have extension foo
  if (lastDotPosition < 1) return null

  return fileName.substring(lastDotPosition + 1, fileName.length).toLowerCase()
}

export const truncateFileName = (value: string, maxLength: number) => {
  if (value.length > maxLength) {
    const extension = value
      .substring(value.lastIndexOf(".") + 1, value.length)
      .toLowerCase()
    let filename = value.replace("." + extension, "")
    if (filename.length <= maxLength) {
      return value
    }
    const renamed =
      filename.substr(0, maxLength) + (value.length > maxLength ? "[...]" : "")
    return renamed + "." + extension
  }
  return value
}

export const Uppercase = (str: string) => {
  return str.toLocaleUpperCase()
}

export function capitalizeFirstLetter(str: string) {
  return str?.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : ""
}
