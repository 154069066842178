import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { useRNBSelector } from "../../store/rootReducer"
import { getIdFromParams } from "../../utils/company"
import {
  deactivateAccountReset,
  deactivateAccountThunk,
  deactivateItemReset,
  deactivateItemThunk,
} from "../../store/ducks/bank.ducks"
import { sizes } from "../../styles/design.config"
import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"
import * as Ct from "ldlj"

export interface DisplayDeletionModalParams {
  isDisplayed: boolean
  accountOrBank: "banque" | "compte" | ""
  accountId: number | null
  bankId: number | null
}

interface DeleteBankOrAccountProps extends DisplayDeletionModalParams {
  onClose: () => void
}

export const DeleteBankOrAccount = (props: DeleteBankOrAccountProps) => {
  const intl = useIntl()
  const { register, watch, handleSubmit, reset } = useForm({})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const accounts = useRNBSelector((state) => state.bank.accounts)
  const items = useRNBSelector((state) => state.bank.items)
  const [elementToDelete, setElementToDelete] = useState<string | undefined>("")
  const inputDeleteEntity = watch("inputDeleteEntity")
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const fiduciaryId = useRNBSelector((state) => state.fiduciary.id)
  const deleteAccountStatus = useRNBSelector(
    (state) => state.bank.deactivateAccountStatus
  )
  const deleteItemStatus = useRNBSelector(
    (state) => state.bank.deactivateItemStatus
  )

  const selectedAccount = Object.values(accounts).find(
    (element) => element.id === props.accountId
  )
  const selectedItem = Object.values(items).find(
    (item) => item.id === props.bankId
  )

  useEffect(() => {
    switch (props.accountOrBank) {
      case "banque":
        return setElementToDelete(
          Object.values(items).find((item) => item.id === props.bankId)
            ?.bank_name
        )

      case "compte":
        return setElementToDelete(
          Object.values(accounts).find(
            (account) => account.id === props.accountId
          )?.name
        )
    }
  }, [accounts, props.accountId, props.accountOrBank, items])

  useEffect(() => {
    if (props.accountOrBank === "compte" && deleteAccountStatus === "SUCCESS") {
      reset({ inputDeleteEntity: "" })
      props.onClose()
      dispatch(deactivateAccountReset())
    } else if (
      props.accountOrBank === "banque" &&
      deleteItemStatus === "SUCCESS"
    ) {
      dispatch(deactivateItemReset())
      navigate(`/office/company/${companyId}/bank/accounts/`)
    }
  }, [
    deleteItemStatus,
    deleteAccountStatus,
    companyId,
    reset,
    dispatch,
    props,
    navigate,
    intl,
  ])

  const handleFormSubmit = () => {
    switch (props.accountOrBank) {
      case "banque":
        return (
          inputDeleteEntity.toUpperCase() ===
            selectedItem?.bank_name.toUpperCase() &&
          dispatch(
            deactivateItemThunk({
              fiduciaryId,
              companyId,
              itemId: props.bankId,
            })
          )
        )
      case "compte":
        return (
          inputDeleteEntity.toUpperCase() ===
            selectedAccount?.name.toUpperCase() &&
          dispatch(
            deactivateAccountThunk({
              fiduciaryId,
              companyId,
              itemId: props.bankId,
              accountId: props.accountId,
            })
          )
        )
    }
  }

  return (
    <Ct.Modal {...props} left="50%" right="50%" top="calc(50vh - 50rem)">
      <StyledCard width="80rem">
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage(
              {
                id: "bank-management.delete-warning.title",
              },
              {
                accountOrBank: props.accountOrBank,
                gender: props.accountOrBank === "banque" ? "la" : "le",
              }
            )}
          />

          <ModalClose onClick={() => props.onClose()}>
            <CloseCrossSVG />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={4} />

        <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
          <Ct.Text
            text={intl.formatMessage(
              {
                id: "bank-management.delete-warning.1",
              },
              { accountOrBank: props.accountOrBank }
            )}
          />
          <Ct.Spacer height={1} />
          <Ct.Text
            text={intl.formatMessage(
              {
                id: "bank-management.delete-warning.2",
              },
              { accountOrBankName: elementToDelete }
            )}
          />
          <Ct.Spacer height={1} />
          <Ct.Text
            text={intl.formatMessage(
              {
                id: "bank-management.delete-warning.3",
              },
              { accountOrBank: props.accountOrBank }
            )}
          />
          <Ct.Spacer height={1} />
          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.delete-warning.4",
            })}
          />
          <Ct.Spacer height={4} />

          <StyledInputWrapper>
            <Ct.Input
              type="text"
              name="inputDeleteEntity"
              register={register as unknown as UseFormRegister<FieldValues>}
              value={inputDeleteEntity}
              label={intl.formatMessage(
                {
                  id: "bank-management.delete-warning.label",
                },
                {
                  accountOrBank: props.accountOrBank,
                }
              )}
            />
          </StyledInputWrapper>

          <Ct.Spacer height={4} />
          <Ct.Button
            type="submit"
            label={intl.formatMessage(
              {
                id: "bank-management.delete-warning.validate-button",
              },
              {
                accountOrBank: props.accountOrBank,
                gender: props.accountOrBank === "banque" ? "la" : "le",
              }
            )}
            disabled={
              (props.accountOrBank === "banque"
                ? inputDeleteEntity?.toUpperCase() !==
                  selectedItem?.bank_name.toUpperCase()
                : inputDeleteEntity?.toUpperCase() !==
                  elementToDelete?.toUpperCase()) || !inputDeleteEntity
            }
            width={sizes.button.standard}
          />
        </Ct.StyledForm>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  position: relative;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`

const StyledInputWrapper = styled.div`
  width: 70%;
`
