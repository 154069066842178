import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import Cropper from "react-cropper"
import * as yup from "yup"
import { DropFileUpload, UploadStatus } from "../../../../components/DropFile"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  GetCompanyThunk,
  getUserCompaniesThunk,
  logoDeleteThunk,
  updateCompanyInformationsReset,
  updateCompanyInformationThunk,
  updateCompanyLogoThunk,
} from "../../../../store/ducks/companies.ducks"
import { useDispatch } from "react-redux"
import {
  CompanyLegalForm,
  getIdFromParams,
  legalFormOptionList,
  shareCapitalNotMandatory,
} from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelectedCompany } from "../../../../hooks/useSelectedCompany"
import * as Ct from "ldlj"
import { Alert } from "../../../../components/Commons/Alert"
import { Option, Select } from "../../../../components/Commons/Select"
import { Input } from "../../../../components/Commons/Input"
import { boxShadow, colors } from "../../../../styles/design.config"
import { PHONE_VALIDATOR } from "../../../../utils/phoneValidator"
import config from "../../../../config"
import { ReactComponent as Trash } from "../../../../assets/drop-documents/TrashCan.svg"
import { includes } from "../../../../utils/array"

export const CompanyInformations = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const updateCompanyInformationStatus = useRNBSelector(
    (state) => state.companies.updateCompanyInformationsStatus
  )

  const selectedCompany = useSelectedCompany(params, dispatch)
  const cropperRef = useRef<HTMLImageElement>(null)
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [formatError, setFormatError] = useState(false)
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null>(null)
  const [uploadStatus] = useState<UploadStatus>("start")
  const [uploadProgress] = useState(0)
  const [cropper, setCropper] = useState<Cropper>()
  const [mimeType, setMimeType] = useState("image/jpeg")

  const [hasDisabledShareCapital, setHasDisabledShareCapital] = useState(false)
  const [isCityRegistrationRCS, setisCityRegistrationRCS] = useState(false)

  const initialDisabledShareCapital =
    includes(
      shareCapitalNotMandatory,
      selectedCompany?.informations?.legalForm
    ) && !selectedCompany?.informations?.shareCapital

  useEffect(() => {
    setHasDisabledShareCapital(initialDisabledShareCapital)
  }, [initialDisabledShareCapital])

  const companyLogo = useRNBSelector(
    (state) => state.companies.companiesLogos[selectedCompany?.id || 0]
  )

  const logoCompany = useRNBSelector(
    (state) =>
      `${
        state.companies.companiesLogos[selectedCompanyId]
          ? config.invoicingLogosBucketUri +
            state.companies.companiesLogos[selectedCompanyId]?.file_name
          : ""
      }`
  )

  const initialOptionsList = useMemo(() => {
    return legalFormOptionList
  }, [])

  const selectedOption = initialOptionsList.find(
    (option) => option.value === selectedCompany?.informations?.legalForm
  )

  const [currentLegalFormValue, setCurrentLegalFormValue] = useState<
    Option<CompanyLegalForm> | undefined
  >(selectedOption)

  const uploadFile = (file: File): void => {
    if (!validateLogoFormat(file)) {
      setFormatError(true)
      return
    }
    setFormatError(false)
    setMimeType(file.type)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function (e) {
      setUploadedImageSrc(reader.result as string)
    }
  }

  const validateLogoFormat = (file: File): Boolean => {
    const format = file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
    return [".jpg", ".png", ".jpeg"].includes(format.toLowerCase())
  }

  const onCrop = useCallback(() => {
    if (!cropper) {
      return
    }

    let file: File
    cropper.getCroppedCanvas().toBlob(async function (blob: Blob | null) {
      if (!blob) {
        return
      }
      file = new File([blob], "company_logo.jpg", { type: mimeType })
      crypto.subtle
        .digest("SHA-512", await file.arrayBuffer())
        .then(function (h) {
          dispatch(
            updateCompanyLogoThunk({
              companyId: selectedCompanyId,
              originalFileName: file.name,
              file,
            })
          )
        })
        .then(() => {
          setUploadedImageSrc(null)
        })
    }, mimeType)
  }, [cropper, dispatch, setUploadedImageSrc, selectedCompanyId])

  const schema = yup.object({
    companyName: yup.string().required(),
    address: yup.string().required(),
    postalCode: yup.number().required(),
    city: yup.string().required(),
    siren: yup.string().required(),
    shareCapital: yup.number().positive().nullable(true),
    phone: yup
      .string()
      .nullable(true)
      .matches(PHONE_VALIDATOR, "invalid_phone"),
    email: yup.string().nullable(true).email(),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    reValidateMode: "onChange",
  })

  const companyName: string = watch("companyName")
  const address: string = watch("address")
  const postalCode: string = watch("postalCode")
  const city: string = watch("city")
  const siren: string = watch("siren")
  const cityRegistrationRCS: string = watch("cityRegistrationRCS")
  const shareCapital: number | null = watch("shareCapital")
  const phone: string = watch("phone")
  const email: string = watch("email")

  useEffect(() => {
    dispatch(getUserCompaniesThunk())
  }, [dispatch])

  useEffect(() => {
    setValue(
      "companyName",
      selectedCompany?.user_company_name || selectedCompany?.name
    )
    setValue("address", selectedCompany?.informations?.address)
    setValue(
      "postalCode",
      selectedCompany?.informations?.postalCode?.toString() || ""
    )
    setValue("city", selectedCompany?.informations?.city)
    setValue("phone", selectedCompany?.informations?.phone)
    setValue("email", selectedCompany?.informations?.email)
    setValue("siren", selectedCompany?.informations?.siren)
    setValue(
      "cityRegistrationRCS",
      selectedCompany?.informations?.cityRegistrationRCS
    )
    setValue("shareCapital", selectedCompany?.informations?.shareCapital)
    setisCityRegistrationRCS(
      Boolean(selectedCompany?.informations?.useCityRegistrationRCS)
    )
    setCurrentLegalFormValue(selectedOption)
  }, [selectedCompany, setValue, selectedOption])

  useEffect(() => {
    setValue("companyName", companyName?.replace(/\s\s+/g, " "))
    setValue("address", address?.replace(/\s\s+/g, " "))
    setValue("postalCode", postalCode?.replace(/\s\s+/g, " "))
    setValue("city", city?.replace(/\s\s+/g, " "))
    setValue("siren", siren?.replace(/\s\s+/g, " "))
    setValue("cityRegistrationRCS", cityRegistrationRCS?.replace(/\s\s+/g, " "))
  }, [address, companyName, postalCode, city, siren, cityRegistrationRCS])

  const handleFormSubmit = () => {
    dispatch(
      updateCompanyInformationThunk(
        {
          companyName,
          city,
          postalCode,
          address,
          siren,
          legalForm: currentLegalFormValue?.value,
          cityRegistrationRCS,
          shareCapital: shareCapital || null,
          useCityRegistrationRCS: isCityRegistrationRCS,
          phone,
          email,
        },
        selectedCompanyId
      )
    )
  }

  useEffect(() => {
    if (updateCompanyInformationStatus === "SUCCESS") {
      dispatch(GetCompanyThunk(selectedCompanyId))
      dispatch(getUserCompaniesThunk())
      reset({
        companyName: selectedCompany?.name,
        city: selectedCompany?.informations?.city,
        postalCode: selectedCompany?.informations?.postalCode,
        address: selectedCompany?.informations?.address,
        siren: selectedCompany?.informations?.siren,
        legalForm: selectedCompany?.informations?.legalForm,
        cityRegistrationRCS: selectedCompany?.informations?.cityRegistrationRCS,
        shareCapital: selectedCompany?.informations?.shareCapital,
        useCityRegistrationRCS:
          selectedCompany?.informations?.useCityRegistrationRCS,
      })
      setCurrentLegalFormValue(selectedOption || undefined)
      dispatch(updateCompanyInformationsReset())
    }
  }, [
    selectedCompany,
    updateCompanyInformationStatus,
    reset,
    dispatch,
    selectedCompanyId,
  ])

  const pristine =
    companyName === selectedCompany?.name &&
    address === selectedCompany?.informations?.address &&
    postalCode === selectedCompany?.informations?.postalCode?.toString() &&
    city === selectedCompany?.informations?.city &&
    shareCapital === selectedCompany?.informations?.shareCapital &&
    (cityRegistrationRCS ===
      selectedCompany?.informations?.cityRegistrationRCS ||
      cityRegistrationRCS === undefined) &&
    isCityRegistrationRCS ===
      selectedCompany?.informations?.useCityRegistrationRCS &&
    currentLegalFormValue?.value ===
      (selectedCompany?.informations?.legalForm || undefined) &&
    phone === selectedCompany?.informations?.phone &&
    email === selectedCompany?.informations?.email

  const areRequiredFieldsFilled =
    companyName !== null &&
    address !== null &&
    postalCode !== null &&
    city !== null

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && selectedCompany) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: selectedCompany.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    selectedCompany,
  ])

  const shareCapitalError = !hasDisabledShareCapital && (shareCapital || 0) < 1
  return (
    <Wrapper>
      <StyledSection>
        <StyledContent>
          <FullWidth>
            <DropZone>
              {!uploadedImageSrc && logoCompany ? (
                <>
                  <WrapperImg>
                    <StyledTrash
                      onClick={() => {
                        if (selectedCompanyId && companyLogo?.id) {
                          dispatch(
                            logoDeleteThunk(selectedCompanyId, companyLogo?.id)
                          )
                        }
                      }}
                    />
                    <StyledCompanyLogo src={logoCompany} alt="logo" />
                  </WrapperImg>
                </>
              ) : !uploadedImageSrc ? (
                <DropFileUpload
                  uploadFile={uploadFile}
                  dropText="Logo"
                  uploadStatus={uploadStatus}
                  uploadProgress={uploadProgress}
                />
              ) : (
                <>
                  <Cropper
                    src={uploadedImageSrc}
                    initialAspectRatio={1}
                    onInitialized={(instance) => {
                      setCropper(instance)
                    }}
                    ref={cropperRef}
                  />
                  <Ct.Spacer />
                  <Ct.JustifyCenter>
                    <Ct.Button
                      label={intl.formatMessage({
                        id: "company-register.update-logo.crop-button",
                      })}
                      onClick={() => {
                        onCrop()
                      }}
                      width={42.5}
                    />
                  </Ct.JustifyCenter>
                </>
              )}

              <Ct.Spacer height={4} />
            </DropZone>
          </FullWidth>

          <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
            <Ct.StyledDuoInput>
              <Ct.Input
                name="companyName"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "company.informations.company-name",
                })}
                type="text"
                value={companyName}
              />
              <Ct.Spacer width={3} />
              <Ct.Input
                name="address"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "company.informations.address",
                })}
                type="text"
                value={address}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <Ct.StyledDuoInput>
              <Ct.Input
                name="postalCode"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "company.informations.postal-code",
                })}
                type="text"
                value={postalCode}
                showWarning={errors["postalCode"]?.message.includes("number")}
              />
              <Ct.Spacer width={3} />
              <Ct.Input
                name="city"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({ id: "company.informations.city" })}
                type="text"
                value={city}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <Ct.StyledDuoInput>
              <Ct.LockableInput
                name="siren"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({ id: "company.informations.siren" })}
                type="text"
                value={siren}
                locked={true}
                disabled={true}
              />
              <Ct.Spacer width={3} />

              <Select
                intl={intl}
                options={
                  initialOptionsList as unknown as Option<CompanyLegalForm>[]
                }
                value={currentLegalFormValue || null}
                label={"legalForm"}
                domain={"company"}
                optionType={"informations.legal-form"}
                onChangeCallback={(e: Ct.Option<string>) => {
                  setCurrentLegalFormValue(e as Option<CompanyLegalForm>)
                  setHasDisabledShareCapital(false)
                }}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <Ct.StyledDuoInput>
              <Ct.LockableInput
                name="cityRegistrationRCS"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "company.informations.city-registration-rcs",
                })}
                type="text"
                value={cityRegistrationRCS}
                locked={!isCityRegistrationRCS}
                disabled={!isCityRegistrationRCS}
              />
              <Ct.Spacer width={3} />
              <Input
                name="shareCapital"
                label={intl.formatMessage({
                  id: "company.informations.share-capital",
                })}
                type="number"
                disabled={hasDisabledShareCapital}
                min={"1"}
                value={String(shareCapital)}
                onChange={(e) => {
                  if (e.target.value === "") return setValue("shareCapital", 1)
                  if (Number(e.target.value) < 0) {
                    return
                  }
                  setValue("shareCapital", e.target.value)
                }}
                isPrefilled={Boolean(shareCapital)}
                showError={shareCapitalError}
              />
            </Ct.StyledDuoInput>
            {includes(
              shareCapitalNotMandatory,
              currentLegalFormValue?.value
            ) ? (
              <Ct.StyledDuoInput>
                <Flex1 />
                <Ct.Spacer width={3} />
                <Flex1>
                  <Ct.Checkbox
                    isChecked={hasDisabledShareCapital}
                    label={intl.formatMessage({
                      id: "company.informations.has-disabled-share-capital",
                    })}
                    onChange={() => {
                      if (!hasDisabledShareCapital) {
                        setValue("shareCapital", null)
                      }
                      setHasDisabledShareCapital(!hasDisabledShareCapital)
                    }}
                    name="hasDisabledShareCapital"
                  />
                </Flex1>
              </Ct.StyledDuoInput>
            ) : (
              <Ct.Spacer height={4} />
            )}
            <Ct.StyledDuoInput>
              <Input
                name="phone"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "company.informations.phone",
                })}
                type="text"
                required={false}
                value={phone}
              />
              <Ct.Spacer width={3} />
              <Input
                required={false}
                name="email"
                label={intl.formatMessage({
                  id: "company.informations.email",
                })}
                register={register as unknown as UseFormRegister<FieldValues>}
                value={email}
              />
            </Ct.StyledDuoInput>
            <Ct.Spacer height={4} />
            <FullWidth>
              <Ct.Checkbox
                isChecked={!isCityRegistrationRCS}
                label={intl.formatMessage({
                  id: "company.informations.city-registration-rcs-not-applicable",
                })}
                onChange={() => {
                  if (isCityRegistrationRCS) {
                    setValue("cityRegistrationRCS", "")
                  }
                  setisCityRegistrationRCS(!isCityRegistrationRCS)
                }}
                name="useCityRegistrationRCS"
              />
            </FullWidth>
            <Ct.Spacer height={4} />

            <Ct.Button
              type="submit"
              label={intl.formatMessage({
                id: "company.informations.button.save",
              })}
              width={42.5}
              disabled={
                Object.keys(errors).length !== 0 ||
                (!cityRegistrationRCS && isCityRegistrationRCS) ||
                pristine ||
                !areRequiredFieldsFilled ||
                shareCapitalError
              }
            />
            <Ct.Spacer height={4} />
            <Alert alertType={"info"}>
              <Ct.Text
                text={intl.formatMessage({
                  id: "company.informations.alert.infos",
                })}
              />
            </Alert>
          </Ct.StyledForm>

          {formatError && (
            <>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "fiduciary-register.update-logo-failure.wrong-format",
                })}
                textStyle={{ color: "amaranth", fontWeight: 700 }}
              />
            </>
          )}
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 10rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  flex: 1;
`

const StyledContent = styled.div`
  width: 94rem;
`

const FullWidth = styled.div`
  width: 100%;
`
const Flex1 = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 6rem;
`

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10rem;
`
const WrapperImg = styled(Ct.Card)`
  height: 25rem;
  width: 35rem;
  padding: 2rem;
`
const StyledCompanyLogo = styled.img`
  border-radius: 1.25rem;
  object-fit: contain;
  cursor: pointer;
  flex-shrink: 0;
  height: 90%;
  width: 90%;
`
const StyledTrash = styled(({ ...props }) => <Trash {...props} />)`
  width: 2.5rem;
  height: 3rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }

  cursor: pointer;
`
