import styled from "styled-components/macro"
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom"
import { Fragment, ReactNode } from "react"
import { colors } from "../../styles/design.config"
import ReactTooltip from "react-tooltip"
import { useIntl } from "react-intl"

export interface TabObject {
  to: string
  title: string
  icon: ReactNode
  isDisplayed: boolean
  isSelected?: boolean
  colored?: boolean
  disabled?: boolean
  tooltip?: string
}

interface TabProps extends LinkProps {
  useMatch: typeof useMatch
  useResolvedPath: typeof useResolvedPath
  Link: typeof Link
  colored?: boolean
  disabled?: boolean
}

const Tab = ({
  useMatch,
  useResolvedPath,
  children,
  to,
  Link,
  ...props
}: TabProps) => {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <StyledLink to={to} match={match} Link={Link} {...props}>
      {children}
    </StyledLink>
  )
}

interface MatchingLink {
  match: unknown
}

interface TabsProps {
  useMatch: typeof useMatch
  useResolvedPath: typeof useResolvedPath
  Link: typeof Link
  tabs: TabObject[]
}

export const Tabs = ({ useMatch, useResolvedPath, tabs, Link }: TabsProps) => {
  const intl = useIntl()
  return (
    <>
      <nav>
        <TabsWrapper id="tabList">
          <ReactTooltip
            delayShow={200}
            effect={"solid"}
            eventOff="mouseleave scroll mousewheel blur"
          />
          {tabs.map((tab, i) => (
            <Fragment key={i}>
              {tab.isDisplayed && (
                <ListItem key={tab.to}>
                  {tab.disabled ? (
                    <TabDisabled
                      data-tip={
                        tab.tooltip && tab.tooltip !== ""
                          ? intl.formatMessage({ id: tab.tooltip })
                          : ""
                      }
                    >
                      {tab.icon}
                      {tab.title}
                    </TabDisabled>
                  ) : (
                    <Tab
                      to={tab.to}
                      useMatch={useMatch}
                      useResolvedPath={useResolvedPath}
                      Link={Link}
                      disabled={tab.disabled}
                      colored={tab.colored}
                    >
                      {tab.icon}
                      {tab.title}
                    </Tab>
                  )}
                </ListItem>
              )}
            </Fragment>
          ))}
        </TabsWrapper>
      </nav>
    </>
  )
}

const TabsWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  list-style-type: none;
  padding-right: 2.5rem;
`

// !important to override rsuite global css
const StyledLink = styled((props) => <props.Link {...props} />)<MatchingLink>`
  padding: 2rem;
  width: max-content;
  border-radius: 8px 8px 0 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.colored
      ? `${colors.amaranth} !important`
      : props.disabled
      ? `${colors.slateGrey} !important`
      : props.match
      ? `black !important`
      : `${colors.cornflower} !important`};

  background-color: ${(props) =>
    props.disabled
      ? `${colors.moon} !important`
      : props.match
      ? `${colors.white} !important`
      : `${colors.lavender} !important`};

  text-decoration: none;

  & svg {
    padding-right: 0.75rem;
    width: 2rem;
    height: 2rem;
  }

  & path {
    fill: ${(props) =>
      props.colored
        ? `${colors.amaranth} !important`
        : props.disabled
        ? `${colors.slateGrey} !important`
        : props.match
        ? `black`
        : `${colors.cornflower}`};
  }
`
const TabDisabled = styled.div`
  padding: 2rem;
  width: max-content;
  border-radius: 8px 8px 0 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  color: ${colors.slateGrey};

  background-color: ${colors.moon};

  text-decoration: none;

  & svg {
    padding-right: 0.75rem;
    width: 2rem;
    height: 2rem;
  }

  & path {
    fill: ${colors.slateGrey};
  }
`
