import * as Ct from "ldlj"
import { CSSProperties, useEffect, useState } from "react"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as EyeFilled } from "../../../../../assets/eyeFilled.svg"
import { useIntl } from "react-intl"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../../components/Commons/Table"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import {
  changeMerchantTreasurySettingsThunk,
  getTreasuryMerchantHistoryThunk,
  getTreasuryMerchantsThunk,
  TreasuryMerchants,
} from "../../../../../store/ducks/treasury.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import {
  HeadBand,
  StyledSection,
  TreasuryHeaderWrapper,
  Wrapper,
  WrapperTopBar,
  filterMerchants,
} from "../../../../../utils/treasury"
import { MerchantTreasuryHistoryModal } from "../../../../../components/treasury/MerchantTreasuryHistoryModal"
import { Switch } from "../../../../../components/Commons/Switch"
import { Input } from "../../../../../components/Commons/Input"
import React from "react"
import { VariableSizeGrid } from "react-window"
import { colors } from "../../../../../styles/design.config"
import {
  GridWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../../components/dropDocuments/StyledDropsComponents"
import AutoSizer from "react-virtualized-auto-sizer"
import { Alert } from "../../../../../components/Commons/Alert"

/* eslint-disable camelcase */

export const TreasuryAutovalidationSettings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const [search, setSearch] = useState("")
  const [merchantsToDisplay, setMerchantsToDisplay] = useState<
    TreasuryMerchants[]
  >([])
  const merchants = useRNBSelector((state) => {
    return state.treasury.merchants
  })
  const rowBackgroundColors = merchantsToDisplay.map((_, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })
  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight || !text) {
      return text
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase().includes(highlight.toLowerCase()) ||
              highlight.toLowerCase().includes(part.toLowerCase())
                ? {
                    background: "rgba(255, 197, 66, 0.3)",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </>
    )
  }
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )
  const navigate = useNavigate()
  const getCurrentUserPermissionsStatus = useRNBSelector(
    (state) => state.treasury.currentUserPermissionStatus
  )
  const [merchantSelected, setMerchantSelected] =
    useState<TreasuryMerchants | null>(null)
  const merchantHistory = useRNBSelector(
    (state) => state.treasury.merchant_history
  )
  const [displayHistory, setDisplayHistory] = useState(false)
  const historyAction = (merchant: TreasuryMerchants) => {
    dispatch(getTreasuryMerchantHistoryThunk(merchant.id))
    setMerchantSelected(merchant)
    setDisplayHistory(true)
  }
  const fiduciary = useRNBSelector((state) => state.fiduciary)

  useEffect(() => {
    if (fiduciary.status === "UNREGISTERED") return
    if (
      fiduciary.status === "SUCCESS" &&
      (fiduciary.active_pricing?.pricing_type !== "per_document" ||
        (fiduciary.active_pricing?.pricing_type === "per_document" &&
          fiduciary.override_pricing))
    )
      return
    navigate("/unauthorized")
  }, [fiduciary])

  useEffect(() => {
    if (
      getCurrentUserPermissionsStatus === "idle" ||
      getCurrentUserPermissionsStatus === "loading"
    )
      return
    if (
      getCurrentUserPermissionsStatus === "success" &&
      (currentUserPermissions.marked_as_appearing_in_permissions_list ===
        true ||
        currentUserTypology === "customer" ||
        currentUserTypology === "customer_accountant")
    )
      return
    navigate("/unauthorized")
  }, [
    getCurrentUserPermissionsStatus,
    currentUserPermissions,
    currentUserTypology,
  ])

  useEffect(() => {
    dispatch(getTreasuryMerchantsThunk(companyId))
  }, [dispatch])

  useEffect(() => {
    if (merchants.length !== 0) {
      setMerchantsToDisplay(merchants)
    }
  }, [merchants])

  useEffect(() => {
    if (search && search !== "" && merchants) {
      const filteredMerchants: TreasuryMerchants[] =
        filterMerchants(search, merchants) || []
      setMerchantsToDisplay(filteredMerchants)
      setHighlightValues({ highlight: true, searchString: search })
    } else {
      setMerchantsToDisplay(merchants)
      setHighlightValues({ highlight: false, searchString: "" })
    }
  }, [search, merchants])

  enum SortOptionsValues {
    "merchants",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.merchants) {
      const merchantsSorted = merchantsToDisplay.sort(
        (a: TreasuryMerchants, b: TreasuryMerchants) =>
          asc ? a?.name.localeCompare(b?.name) : b?.name.localeCompare(a?.name)
      )
      setMerchantsToDisplay(merchantsSorted)
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  const listRef = React.createRef<VariableSizeGrid>()
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const onResize = () => {
    if (listRef.current !== null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    return (
      <div
        style={{
          ...style,
          backgroundColor: colors[rowBackgroundColors[rowIndex]],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        key={columnIndex}
      >
        {columns[columnIndex].content(merchantsToDisplay[rowIndex])}
      </div>
    )
  }

  const columns = [
    {
      headerText: intl.formatMessage({
        id: "treasury.auto-validation-settings.merchant",
      }),
      content: (merchant: TreasuryMerchants) => (
        <>{getHighlightedText(merchant.name, highlightValues.searchString)}</>
      ),
    },
    {
      headerText: "treasury.auto-validation-settings.validate_directly",
      content: (merchant: TreasuryMerchants) => (
        <Switch
          value={merchant.validate_directly}
          onToggle={() => {
            const type =
              merchant.validate_directly === true ? "revoke" : "grant"
            dispatch(
              changeMerchantTreasurySettingsThunk(
                merchant.id,
                type,
                "validate_directly",
                companyId
              )
            )
          }}
          locked={
            currentUserTypology !== "customer" &&
            currentUserTypology !== "customer_accountant"
          }
        />
      ),
    },
    {
      headerText: "treasury.auto-validation-settings.mark_as_paid_directly",
      content: (merchant: TreasuryMerchants) => (
        <Switch
          value={merchant.mark_as_paid_directly}
          onToggle={() => {
            const type =
              merchant.mark_as_paid_directly === true ? "revoke" : "grant"
            dispatch(
              changeMerchantTreasurySettingsThunk(
                merchant.id,
                type,
                "mark_as_paid_directly",
                companyId
              )
            )
          }}
          locked={
            currentUserTypology !== "customer" &&
            currentUserTypology !== "customer_accountant"
          }
        />
      ),
    },
    {
      headerText: "treasury.auto-validation-settings.historic",
      flexGrow: "10rem",
      content: (merchant: TreasuryMerchants) => (
        <EyeFilled
          cursor={"pointer"}
          onClick={() => {
            historyAction(merchant)
          }}
        />
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        <Ct.Spacer height={1} />
        <HeadBand>
          <Ct.Text text={"🏷️"} />
          <Ct.Spacer width={1} />
          <Ct.Text
            text={intl.formatMessage({
              id: "treasury.try_access.headband",
            })}
            textStyle={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 2,
              fontStyle: "italic",
              color: "white",
            }}
          />
        </HeadBand>
        <Ct.Spacer height={4} />

        <WrapperTopBar>
          <Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={"30rem"}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            dataCy={"searchUser"}
            shadowed={true}
            noBorder={true}
            isSearch={true}
          />
        </WrapperTopBar>

        <MerchantTreasuryHistoryModal
          merchantName={merchantSelected?.name || ""}
          userMerchantInstruction={merchantHistory || []}
          isDisplayed={displayHistory ? true : false}
          onClose={() => setDisplayHistory(false)}
        />

        <Ct.Spacer height={3} />

        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                return (width - 10) / columns.length
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 0rem"}>
                    {columns.map((column, index) => (
                      <TreasuryHeaderWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                      >
                        {index === 0 ? (
                          <ClickableTitleSort
                            tid={column.headerText || ""}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable
                            tid={column.headerText || ""}
                            intl={intl}
                          />
                        )}
                      </TreasuryHeaderWrapper>
                    ))}
                  </HeaderWrapper>

                  {merchantsToDisplay.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "treasury.auto-validation.empty.merchants",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <GridWrapper totalWidth={width}>
                      <VariableSizeGrid
                        ref={listRef}
                        height={height - 56}
                        rowCount={merchantsToDisplay.length}
                        width={width}
                        columnWidth={(index) => columnWidths[index]}
                        rowHeight={(index) => 60}
                        columnCount={columns.length}
                      >
                        {Cell}
                      </VariableSizeGrid>
                    </GridWrapper>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}
