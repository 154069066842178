import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { ImageOrPdfViewer } from "../dropDocuments/ImageOrPdfViewer"
import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"

interface ModalFullDocumentProps extends Ct.ModalComponentsProps {
  elementName: string
  url: string
}

export const ModalFullDocumentViewer = ({
  isDisplayed,
  onClose,
  elementName,
  url,
}: ModalFullDocumentProps) => {
  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="25%"
      right="25%"
      top="calc(25vh - 30rem)"
    >
      <StyledCard width="130rem" height={"calc(100vh - 20rem)"}>
        <Ct.Spacer height={2} />
        <HeaderPreviewModal>
          <TextWrapper>
            <Ct.Title text={elementName} size={3} />
          </TextWrapper>
          <CrossWrapper onClick={onClose}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Ct.Spacer height={2} />

        <ImageOrPdfViewer getUrl={url} />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  border-radius: 1rem;
`
const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`
const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
