import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { boxShadow, colors } from "../../../../../styles/design.config"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as LocationArrowEmpty } from "../../../../../assets/location-arrow-empty.svg"
import { ReactComponent as Plus } from "../../../../../assets/circle-plus.svg"
import { ReactComponent as Edit } from "../../../../../assets/edit.svg"
import { ReactComponent as Eye } from "../../../../../assets/eye.svg"
import { ReactComponent as Trash } from "../../../../../assets/drop-documents/TrashCan.svg"
import { getIdFromParams } from "../../../../../utils/company"
import { useParams } from "react-router-dom"
import {
  AccountRulesWithoutDocument,
  DeleteRuleThunk,
  GetAccountsOfItemsForCompanyThunk,
  GetRuleEventsThunk,
  getRulesWithoutDocumentThunk,
} from "../../../../../store/ducks/bank.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { CreateOrEditRuleModal } from "../../../../../components/bank/CreateOrEditRuleModal"
import { RuleEventsModal } from "../../../../../components/bank/RuleEventsModal"
import { DeleteRuleModal } from "../../../../../components/bank/DeleteRuleModal"
import { TransferRulesModal } from "../../../../../components/bank/TransferRulesModal"
import { Alert } from "../../../../../components/Commons/Alert"
/* eslint-disable camelcase */

export const RulesWithoutDocument = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const accountID = getIdFromParams(useParams())("account_id") || 0
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [search, setSearch] = useState<string>("")
  const [rules, setRules] = useState<AccountRulesWithoutDocument[]>([])
  const [createOrEditRule, setCreateOrEditRule] = useState<
    "create" | "edit" | null
  >(null)
  const [ruleToEdit, setRuleToEdit] =
    useState<AccountRulesWithoutDocument | null>(null)
  const [showRuleEvents, setShowRulEvents] =
    useState<AccountRulesWithoutDocument | null>(null)
  const [deleteRule, setDeleteRule] = useState<{
    id: number
  } | null>(null)
  const [displayTransferRules, setDisplayTransferRules] =
    useState<boolean>(false)

  const accounts = useRNBSelector((state) => state.bank.accounts)

  const currentAcccount = Object.values(accounts).find(
    (a) => a.id === accountID
  )

  useEffect(() => {
    if (accountID && Object.values(accounts).length > 0) {
      dispatch(getRulesWithoutDocumentThunk(accountID))
    }
  }, [accountID, dispatch, Object.values(accounts).length])

  useEffect(() => {
    if (currentAcccount && currentAcccount.rules_without_document) {
      if (search === "") setRules(currentAcccount.rules_without_document)
      else {
        setRules(
          currentAcccount.rules_without_document.filter((rwd) =>
            rwd.text_in_description
              ?.toLowerCase()
              .includes(search.toLowerCase())
          )
        )
      }
    }
  }, [currentAcccount, search])

  return (
    <Content>
      <StyledSection>
        <Ct.Spacer height={3} />
        <Header>
          <StyledInput
            name={"searchRule"}
            id={"searchRule"}
            label="Rechercher une règle"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(event: { target: HTMLInputElement }) => {
              const value = event.target.value.trimStart().replace("  ", " ")
              setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
            }}
            shadowed={true}
            noBorder={true}
          />

          <Ct.Row>
            <Ct.Button
              type="submit"
              label={intl.formatMessage({
                id: "bank-management.rules.transfer-rule",
              })}
              width={40}
              disabled={
                currentAcccount &&
                currentAcccount.rules_without_document?.length === 0
              }
              onClick={() => {
                dispatch(
                  GetAccountsOfItemsForCompanyThunk(
                    selectedCompanyId,
                    accountID
                  )
                )
                setDisplayTransferRules(true)
              }}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              prefix={<LocationArrowEmpty />}
            />
            <Ct.Spacer width={2} />

            <Ct.Button
              type="submit"
              label={intl.formatMessage({
                id: "bank-management.rules-without-document.add-rule",
              })}
              width={40}
              onClick={() => {
                setCreateOrEditRule("create")
              }}
              prefix={<Plus />}
            />
          </Ct.Row>
        </Header>
        <Ct.Spacer height={5} />

        <RulesWrapper>
          {rules.length > 0 ? (
            <>
              <Ct.Spacer width={2} height={0} />
              <RulesPriority>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "bank-management.rules-without-document.title",
                  })}
                  textStyle={{
                    color: "navy",
                    fontWeight: 700,
                    fontSize: 1.75,
                  }}
                />
              </RulesPriority>

              <Ct.Spacer height={5} />
              <RulesWrapperOverFlow>
                {rules.map((rule) => (
                  <Fragment key={rule.id}>
                    <RuleCard width={"100%"}>
                      <Ct.Row>
                        <Ct.Spacer width={2} />
                        <Ct.Spacer width={4} />
                        <Ct.Text
                          text={rule.text_in_description || ""}
                          textStyle={{
                            color: "navy",
                            fontSize: 1.75,
                            fontWeight: 600,
                          }}
                        />
                        <Ct.Spacer width={0.5} />
                        <Ct.Text
                          text={intl.formatMessage(
                            {
                              id: "bank-management.rules.bank-line-type",
                            },
                            {
                              inOrOut:
                                rule.bank_line_type === "in"
                                  ? "entrée"
                                  : "sortie",
                            }
                          )}
                          textStyle={{
                            color: "navy",
                            fontSize: 1.75,
                          }}
                        />
                      </Ct.Row>

                      <RuleActions>
                        <Action
                          size={"3rem"}
                          onClick={() => {
                            setCreateOrEditRule("edit")
                            setRuleToEdit(rule)
                          }}
                        >
                          <StyledEdit />
                        </Action>
                        <Ct.Spacer width={5} />
                        <Action
                          size={"3rem"}
                          onClick={() => {
                            setDeleteRule({ id: rule.id })
                          }}
                        >
                          <Trash />
                        </Action>
                        <Ct.Spacer width={5} />
                        <Action
                          size={"3rem"}
                          onClick={() => {
                            dispatch(
                              GetRuleEventsThunk(accountID || 0, rule.id, true)
                            )
                            setShowRulEvents(rule)
                          }}
                        >
                          <StyledEye />
                        </Action>
                        <Ct.Spacer width={3} />
                      </RuleActions>
                    </RuleCard>
                    <Ct.Spacer height={3} />
                  </Fragment>
                ))}
              </RulesWrapperOverFlow>
            </>
          ) : (
            <>
              <Ct.Spacer height={12} />
              <LoaderAlert>
                <Alert alertType={search.length === 0 ? "info" : "warning"}>
                  <Ct.Text
                    text={intl.formatMessage({
                      id:
                        search.length === 0
                          ? "bank-management.rules.empty"
                          : "bank-management.rules.no-match",
                    })}
                  />
                </Alert>
              </LoaderAlert>
            </>
          )}
        </RulesWrapper>
      </StyledSection>

      {createOrEditRule && (
        <CreateOrEditRuleModal
          isDisplayed={!!createOrEditRule}
          ruleOrRuleWithoutDocument={"ruleWithoutDocument"}
          onClose={() => {
            setRuleToEdit(null)
            setCreateOrEditRule(null)
          }}
          rulesLength={
            createOrEditRule === "create"
              ? (rules.length || 0) + 1
              : rules.length || 0
          }
          createOrEdit={createOrEditRule}
          ruleToEdit={createOrEditRule === "edit" ? ruleToEdit : null}
        />
      )}

      {showRuleEvents && (
        <RuleEventsModal
          isDisplayed={!!showRuleEvents}
          onClose={() => {
            setShowRulEvents(null)
          }}
          rule={showRuleEvents}
        />
      )}

      {deleteRule && (
        <DeleteRuleModal
          isDisplayed={!!deleteRule}
          onClose={() => {
            setDeleteRule(null)
          }}
          rule={deleteRule}
          deleteRule={() => {
            dispatch(
              DeleteRuleThunk(
                accountID || 0,
                deleteRule.id,
                "rule_without_document"
              )
            )
            setDeleteRule(null)
          }}
        />
      )}

      {displayTransferRules && (
        <TransferRulesModal
          isDisplayed={displayTransferRules}
          onClose={() => {
            setDisplayTransferRules(false)
          }}
          ruleWithoutDocument={true}
        />
      )}
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const RulesWrapper = styled.div`
  width: 65%;
  margin: 0 auto;
`
const LoaderAlert = styled.div`
  display: flex;
  justify-content: center;
`

const RuleCard = styled(Ct.Card)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 3rem 0;
`

const RuleActions = styled.div`
  display: flex;
`
const Action = styled.span<{ size: string; asc?: boolean }>`
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
  cursor: pointer;
  & path {
    fill: ${({ asc }) =>
      asc === false || asc === null
        ? `${colors.lavender}`
        : `${colors.cornflower}`};
  }
`
const StyledEye = styled(Eye)`
  width: 3rem;
  height: 3rem;
`

const StyledEdit = styled(Edit)`
  width: 3rem;
  height: 3rem;
`
const RulesPriority = styled.div`
  display: flex;
  align-items: center;
`
const RulesWrapperOverFlow = styled.div`
  overflow: auto;
  height: 55vh;
`
