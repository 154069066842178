import { useEffect, useState, VFC } from "react"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import * as Ct from "ldlj"
import { sizes } from "../../styles/design.config"
import { SIRENCheckerPanel } from "../../components/SIRENCheckerPanel"
import { RegistrationPanel } from "../../components/RegistrationPanel"
import { useRNBSelector } from "../../store/rootReducer"
import { checkExistingFiduciaryThunk } from "../../store/ducks/fiduciary.ducks"
import { Background } from "../../components/Background"

const FiduciaryRegister: VFC = () => {
  const intl = useIntl()
  const [sirenRegistered, setSirenRegistered] = useState(false)
  const handleSirenRegistration = () => setSirenRegistered(!sirenRegistered)
  const navigate = useNavigate()

  const registeringStatus = useRNBSelector((state) => state.fiduciary.status)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkExistingFiduciaryThunk())
  }, [dispatch])

  useEffect(() => {
    if (registeringStatus === "SUCCESS") {
      navigate(`/office/fiduciary/company_accounting_type`)
    }
  }, [navigate, registeringStatus])

  return (
    <Background centeredTop={true}>
      <Ct.Title text={intl.formatMessage({ id: "fiduciary-register.title" })} />
      <Ct.Subtitle
        text={intl.formatMessage({ id: "fiduciary-register.subtitle" })}
      />
      <Ct.Spacer height={5} />
      <FormSection>
        <SIRENCheckerPanel
          handleSirenRegistration={handleSirenRegistration}
          sirenRegistered={sirenRegistered}
          sirenType="fiduciary"
        />
        <Ct.Spacer width={4} />
        <RegistrationPanel sirenRegistered={sirenRegistered} />
      </FormSection>
    </Background>
  )
}

const FormSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (${sizes.screen.breakpoint}) {
    flex-direction: column;
  }
`

export default FiduciaryRegister
