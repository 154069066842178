import { Company } from "../store/ducks/companies.ducks"
import { KPIFile } from "../store/ducks/kpis.ducks"

export function sortCompaniesAlphabetically(
  companies: Company[],
  asc: boolean
) {
  const sortedCompanies = companies.sort((a, b) => {
    const displayNameA = a?.user_company_name || a?.name
    const displayNameB = b?.user_company_name || b?.name

    return asc
      ? displayNameA.localeCompare(displayNameB)
      : displayNameB.localeCompare(displayNameA)
  })

  return sortedCompanies
}

export function sortCompaniesByClientsCount(
  companies: Company[],
  asc: boolean
) {
  return companies.sort((a, b) =>
    asc
      ? (a?.customer_count ? a?.customer_count : 0) -
        (b?.customer_count ? b?.customer_count : 0)
      : (b?.customer_count ? b?.customer_count : 0) -
        (a?.customer_count ? a?.customer_count : 0)
  )
}

export function sortCompaniesByCollaboratorsCount(
  companies: Company[],
  asc: boolean
) {
  return companies.sort((a, b) =>
    asc
      ? (a?.collaborator_count ? a?.collaborator_count : 0) -
        (b?.collaborator_count ? b?.collaborator_count : 0)
      : (b?.collaborator_count ? b?.collaborator_count : 0) -
        (a?.collaborator_count ? a?.collaborator_count : 0)
  )
}

export const sortCompaniesByFavorite = (companies: Company[]) => {
  return [
    ...companies.sort((a, b) => (a.is_favorite && !b.is_favorite ? -1 : 1)),
  ]
}

export const sortCompaniesByFavoriteAndName = (
  companies: Company[],
  asc: boolean
) => {
  let sorted = sortCompaniesAlphabetically(companies, asc)

  sorted = [
    ...sorted.sort((a, b) =>
      asc
        ? a.is_favorite && !b.is_favorite
          ? -1
          : 1
        : a.is_favorite && !b.is_favorite
        ? 1
        : -1
    ),
  ]

  return sorted
}

export function sortCompaniesByWritingsToDownloadOrGenerate(
  companies: Company[],
  asc: boolean
) {
  const readyToDownloadAndGenerate = companies.filter(
    (c) => c.readyToDownload && c.readyToGenerate
  )
  const readyToDownload = companies.filter(
    (c) => c.readyToDownload && !c.readyToGenerate
  )
  const readyToGenerate = companies.filter(
    (c) => c.readyToGenerate && !c.readyToDownload
  )
  const notReady = companies.filter(
    (c) => !c.readyToGenerate && !c.readyToDownload
  )

  const allCompanies: Company[] = [
    ...sortReadyToDownload(readyToDownloadAndGenerate),
    ...sortReadyToDownload(readyToDownload),
    ...sortReadyToGenerate(readyToGenerate),
    ...sortCompaniesAlphabetically(notReady, asc),
  ]

  return asc ? allCompanies : allCompanies.reverse()
}

export const sortReadyToDownload = (companies: Company[]) => {
  return companies.sort(
    (a, b) =>
      (b?.readyToDownload ? b?.readyToDownload : 0) -
      (a?.readyToDownload ? a?.readyToDownload : 0)
  )
}

export const sortReadyToGenerate = (companies: Company[]) => {
  return companies.sort((a, b) => {
    const aVal = a?.readyToGenerate === true ? 1 : 0
    const bVal = b?.readyToGenerate === true ? 1 : 0
    return bVal - aVal
  })
}

export function filterCompanies(companies: Company[], search: string) {
  if (!search) {
    return companies
  }

  return companies.filter((company) => {
    const name = company?.user_company_name
      ? company?.user_company_name
      : company?.name
    return (
      name.toLowerCase().includes(search.toLowerCase()) ||
      company?.accounting_software_reference
        ?.toLowerCase()
        .includes(search.toLowerCase())
    )
  })
}

// KPIS
export const sortMergedCompaniesKpisByFavoriteAndCount = (
  companies: KPIFile[]
) => {
  return [
    ...companies.sort((a, b) => {
      if (a.is_favorite !== b.is_favorite) {
        return a.is_favorite ? -1 : 1
      }
      return b.total_fd_count - a.total_fd_count
    }),
  ]
}

export const sortKpisByName = (companies: KPIFile[], asc: boolean) => {
  const sorted = [
    ...companies.sort((a, b) => a.company_name.localeCompare(b.company_name)),
  ]
  return asc ? sorted : sorted.reverse()
}

export const sortKpisByAllFdCount = (companies: KPIFile[], asc: boolean) => {
  const sorted = [
    ...companies.sort((a, b) => a.total_fd_count - b.total_fd_count),
  ]
  return asc ? sorted : sorted.reverse()
}

export const sortKpisByfdOnPeriod = (companies: KPIFile[], asc: boolean) => {
  const sorted = [
    ...companies.sort((a, b) => a.total_fd_period - b.total_fd_period),
  ]
  return asc ? sorted : sorted.reverse()
}

export const sortKpisByBuy = (companies: KPIFile[], asc: boolean) => {
  const sorted = [...companies.sort((a, b) => a.buy - b.buy)]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisBySell = (companies: KPIFile[], asc: boolean) => {
  const sorted = [...companies.sort((a, b) => a.sell - b.sell)]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisByPending = (companies: KPIFile[], asc: boolean) => {
  const sorted = [...companies.sort((a, b) => a.pending - b.pending)]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisByDeactivated = (companies: KPIFile[], asc: boolean) => {
  const sorted = [...companies.sort((a, b) => a.deactivated - b.deactivated)]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisByReference = (companies: KPIFile[], asc: boolean) => {
  const sorted = [
    ...companies.sort((a, b) => {
      const aRef = a.accounting_software_reference || " "
      const bRef = b.accounting_software_reference || " "

      return aRef.localeCompare(bRef)
    }),
  ]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisByCreatedAt = (companies: KPIFile[], asc: boolean) => {
  const sorted = [
    ...companies.sort(
      (a, b) => +new Date(a.created_at) - +new Date(b.created_at)
    ),
  ]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisBySiren = (companies: KPIFile[], asc: boolean) => {
  const sorted = [...companies.sort((a, b) => a.siren.localeCompare(b.siren))]

  return asc ? sorted : sorted.reverse()
}

export const sortKpisByAccountingType = (
  companies: KPIFile[],
  asc: boolean
) => {
  const sorted = [
    ...companies.sort((a, b) =>
      a.accounting_type.localeCompare(b.accounting_type)
    ),
  ]

  return asc ? sorted : sorted.reverse()
}

export const searchOnCompaniesData = (companies: KPIFile[], search: string) => {
  const lowerSearch = search
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
  const cash = "tresorerie"
  const accrual = "engagement"

  return [
    ...companies.filter(
      (c) =>
        (c.accounting_software_reference || "")
          .toLowerCase()
          .includes(lowerSearch) ||
        c.company_name.toLowerCase().includes(lowerSearch) ||
        (c.accounting_type === "cash_accounting" ? cash : accrual)
          .toLowerCase()
          .includes(lowerSearch) ||
        c.siren.toLowerCase().includes(lowerSearch) ||
        c.email.toLowerCase().includes(lowerSearch)
    ),
  ]
}
