import {
  ChangeEventHandler,
  forwardRef,
  MutableRefObject,
  RefObject,
} from "react"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { FieldValues, UseFormRegister } from "react-hook-form"

export interface BaseInputProps {
  label: string
  value: number
  name?: string
  register?: UseFormRegister<FieldValues>
  maxWidth?: number | "auto"
  onChange?: ChangeEventHandler<HTMLInputElement>
  maxLength?: number
  max?: number
  min?: number
  step?: number
  onInput?: () => void
}

interface InputProps extends BaseInputProps {
  suffix?: string
  suffixPosition?: number
  isChanged?: boolean
}

// eslint-disable-next-line react/display-name
export const InputNumber = forwardRef<RefObject<HTMLInputElement>, InputProps>(
  (
    {
      label,
      name = "",
      suffix,
      suffixPosition,
      register,
      value,
      maxWidth = "auto",
      isChanged = false,
      onChange,
      maxLength,
      onInput,
      max,
      min,
      step,
    }: InputProps,
    forwardRef
  ) => {
    if (!register) {
      return (
        <StyledInputWrapper maxWidth={maxWidth} isChanged={isChanged}>
          <StyledInput
            isChanged={isChanged}
            type={"number"}
            onChange={onChange}
            value={value}
            onInput={onInput}
            maxLength={maxLength}
            max={max}
            min={min}
            step={step}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
          <StyledLabel isChanged={isChanged}>{label}</StyledLabel>
          <SuffixWrapper>{suffix}</SuffixWrapper>
        </StyledInputWrapper>
      )
    } else {
      const { ref, ...rest } = register(name)

      return (
        <StyledInputWrapper maxWidth={maxWidth} isChanged={isChanged}>
          <StyledInput
            isChanged={isChanged}
            {...rest}
            ref={(e) => {
              ref(e)
              if (forwardRef && e) {
                const inputRef =
                  forwardRef as unknown as MutableRefObject<HTMLInputElement>
                inputRef.current = e
              }
            }}
            type={"number"}
            onChange={onChange}
            value={value}
            onInput={onInput}
            maxLength={maxLength}
            max={max}
            min={min}
            step={step}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
          <StyledLabel isChanged={isChanged}>{label}</StyledLabel>
          <SuffixWrapper suffixPosition={suffixPosition}>
            {suffix}
          </SuffixWrapper>
        </StyledInputWrapper>
      )
    }
  }
)

interface InputWithError {
  maxWidth?: number | "auto"
  isChanged?: boolean
}

const StyledInputWrapper = styled.div<InputWithError>`
  width: 100%;
  max-width: ${({ maxWidth }) =>
    maxWidth === "auto" ? "auto" : maxWidth + "rem"};
  display: flex;
  position: relative;
  font-family: "Roboto", sans-serif;
  border-radius: 0.75rem;
  box-shadow: "none";
  & input:focus {
    + label {
      color: ${(props) =>
        props.isChanged ? `purple` : `${colors.cornflower} `};
      top: -0.75rem;
      font-size: 1.5rem;
    }

    + label + div > div > svg > path {
      fill: ${(props) =>
        props.isChanged ? `purple` : `${colors.cornflower} `};
    }
  }
`

const SuffixWrapper = styled.div<{ suffixPosition?: number }>`
  position: absolute;
  left: ${({ suffixPosition }) =>
    suffixPosition ? `${suffixPosition}rem` : "5rem"};

  top: 1.9rem;
  max-height: 2rem;
  max-width: 2rem;
  font-size: 1.8rem;
`

const StyledLabel = styled.label<{ isChanged: boolean }>`
  position: absolute;
  left: 1.25rem;
  top: -0.75rem;
  font-size: 1.5rem;

  transition: top 0.15s ease-in-out, color 0.5s ease-in-out,
    font-size 0.15s ease-in-out;

  background-color: ${colors.white};
  color: ${(props) => (props.isChanged ? `purple` : `${colors.rock} `)};

  padding: 0 0.5rem;
  pointer-events: none;
  user-select: none;
`

const StyledInput = styled.input<{ isChanged: boolean }>`
  flex: 1;
  height: 6rem;
  transition: border 0.3s ease-in-out;
  outline: none;
  border-radius: 1.25rem;
  font-size: 1.75rem;
  width: 1rem;
  padding: 0 1.75rem 0 1.75rem;
  border: ${(props) =>
    props.isChanged ? `3px solid purple` : `1px solid ${colors.cornflower}`};
  color: ${colors.navy};
  box-sizing: border-box;
  background-color: ${colors.white};

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
    transition: 0.5s color ease-in-out;
  }
  :focus {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${colors.rock};
    }
    outline: none;
  }
`
