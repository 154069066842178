import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useState } from "react"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createJournalThunk } from "../../store/ducks/companySettings.ducks"
import styled from "styled-components/macro"
import { useRNBSelector } from "../../store/rootReducer"

interface JournalsProps extends Ct.ModalComponentsProps {
  journalType: "buy" | "sell" | "misc" | null
}

export const CreateJournalModal = ({
  isDisplayed,
  onClose,
  journalType,
}: JournalsProps) => {
  const intl = useIntl()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const dispatch = useDispatch()

  const [journalCode, setJournalCode] = useState("")
  const [details, setDetails] = useState("")
  const codeLimit = useRNBSelector((state) => state.companySettings.codeLimit)
  const detailsLimit = 256

  const isNotValidLength = (): boolean => {
    if (!journalCode) return false
    if (journalCode === "") return false
    return journalCode.length > codeLimit || codeLimit === 0
  }

  const isNotValidFormat = (): boolean => {
    if (!journalCode) return false
    if (journalCode === "") return false
    return !/^[A-Za-z0-9]+$/.test(journalCode)
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        setJournalCode("")
        onClose()
      }}
      left="45%"
      right="45%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"90rem"}>
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          size={3}
          text={intl.formatMessage({
            id: `accounting-plan.journals.modal-create.title.${journalType}`,
          })}
        />
        <Ct.Spacer height={4} />

        <InputWrapper>
          <Ct.Input
            name="journalToCreate"
            label={intl.formatMessage({
              id: "accounting-plan.journals.modal-create.journal-code.placeholder",
            })}
            value={journalCode}
            onChange={(e) => {
              setJournalCode(e.target.value)
            }}
            showError={isNotValidLength() || isNotValidFormat()}
            maxLength={codeLimit}
          />
          <Ct.Spacer />
          <Ct.Input
            name="details"
            label={intl.formatMessage({
              id: "accounting-plan.journals.modal-create.details.placeholder",
            })}
            type="text"
            value={details}
            onChange={(e) => {
              setDetails(e.target.value)
            }}
            maxLength={detailsLimit}
          />
        </InputWrapper>
        {(isNotValidLength() || isNotValidFormat()) && (
          <>
            <Ct.Spacer />
            <Ct.Spacer height={0.5} />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: isNotValidLength()
                    ? "accounting-plan.journals.invalid-journal-length"
                    : "accounting-plan.journals.invalid-journal-format",
                },
                { char: codeLimit }
              )}
              textStyle={{
                color: "amaranth",
                fontWeight: 600,
              }}
            />
            <Ct.Spacer height={0.5} />
          </>
        )}
        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: `office-company-settings.vat-modal.cta`,
          })}
          onClick={() => {
            if (selectedCompanyId && journalType) {
              dispatch(
                createJournalThunk(
                  selectedCompanyId,
                  journalCode,
                  details,
                  journalType
                )
              )
            }
            onClose()
          }}
          disabled={!selectedCompanyId || !journalCode || !details}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60rem;
`
