import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Input } from "../Commons/Input"

interface DashboardInvitationUsersModalProps extends Ct.ModalComponentsProps {
  onValidateClick: (selectedValue: string) => void
  errorCode: string | null
}

export const DashboardInvitationUsersModal = ({
  isDisplayed,
  onClose,
  onValidateClick,
  errorCode,
}: DashboardInvitationUsersModalProps) => {
  const intl = useIntl()
  const [selectedValue, setSelectedValue] = useState<string>("")

  useEffect(() => {
    setSelectedValue("")
  }, [isDisplayed])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="10rem"
      right="10rem"
      top={"35%"}
      bottom={"35%"}
    >
      {isDisplayed && (
        <Ct.Card width="60%">
            <Ct.CloseCross
              onClick={onClose}
              />
            <Ct.Title
                text={intl.formatMessage({ id: "treasury.dashboard-invit-user.modal.title" })}
                size={3}
            />
            <Ct.Spacer height={4} />
            <Ct.Separator size="full" color="mist" />
            <Ct.Spacer height={6} />

            <InputWrapper>
              <Input
                type="email"
                label={intl.formatMessage({id: "treasury.dashboard-invit-user.input.placeholder"})}
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                showError={!!errorCode && errorCode !== "idle"}
              />
              {errorCode && errorCode !== "idle" && (
              <>
                <Ct.Spacer height={2} />
                <Ct.Text
                  text={intl.formatMessage({ id: `treasury.dashboard-invit-user.input.error.message.${errorCode}` })}
                  textStyle={{
                    color: "amaranth",
                    fontSize: 1.5,
                  }}
                />
              </>
            )}
            </InputWrapper>
            <Ct.Spacer height={5} />
            <ButtonsWrapper>
                <Ct.Button
                  width={45}
                  label={intl.formatMessage({id: "treasury.dashboard-invit-user.button.title"})}
                  disabled={!selectedValue}
                  onClick={() => {
                    onValidateClick(selectedValue)
                  }}
                />
            </ButtonsWrapper>
            <Ct.Spacer height={5} />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const ButtonsWrapper = styled.div`
    width: 80%;
    display:flex;
    justify-content: center;
    align-items: center;
`
const InputWrapper = styled.div`
    width: 80%;
    display:flex;
    justify-content: center;
    overflow: visible;
    flex-direction: column;
`