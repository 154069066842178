export const cleanSIREN = (spacedSIREN: string): string =>
  spacedSIREN.replace(/\s/g, "")

export const validateAPE = (testedAPE: string): boolean =>
  testedAPE.startsWith("69.2")

export const validateSIREN = (
  testedSIREN: string,
  duplicateAllowed: string | null = null
): boolean => /^(?:[0-9]{9}|[0-9]{14})$/.test(cleanSIREN(testedSIREN))

export type SirenStatus =
  | null
  | "not found"
  | "taken fiduciary"
  | "ok"
  | "bad APE"
  | "hidden" // société non diffusable

export interface SirenCheckResponseType {
  name: string
  address: string
  postal_code: string
  city: string
  siren: string
  code_ape: string
  sirenState: null
  ape_details: string
  country: string
  country_code: string
  full_legal_form: string
  legal_form: string
  max_work_force: number
  min_work_force: number
  share_capital: string | null
  siege_siret: string
  vat_number: string
  work_force: string
}
