import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { getIdFromParams } from "../../utils/company"
import {
  Account,
  AccountsVatRates,
  AccountVatRatesKey,
  activateOrDeactivateAutoliquidatedVatThunk,
  createAccountResetAction,
  getAutoliquidatedVatAccountSettingsThunk,
  getAutoliquidatedVatRatesThunk,
  getCompanyAutoliquidatedVatAccountsHistoryResetAction,
  VatType,
  vatTypeArray,
} from "../../store/ducks/companySettings.ducks"
import { Select, emptyOptionValue } from "../Commons/Select"
import { useRNBSelector } from "../../store/rootReducer"
import { ReactComponent as Plus } from "../../assets/plus.svg"
import {
  CreateAutoliquidatedVatModal,
  AutoliquidatedVatAccountPayload,
} from "./CreateAutoliquidatedVatModal"
import { ReactComponent as Eye } from "../../assets/eyeFilled.svg"
import { colors } from "../../styles/design.config"
import { AutoliquidatedHistoryModal } from "./AutoliquidatedHistoryModal"
import { CompanyStub } from "../../store/ducks/companies.ducks"
import { Switch } from "../Commons/Switch"
import ReactTooltip from "react-tooltip"

/* eslint-disable camelcase */
export interface AutoliquidatedVatAccountsState {
  constructionVatAccount: Account | null
  constructionReverseVatAccount: Account | null
  euVatAccount: Account | null
  euReverseVatAccount: Account | null
  euGoodsVatAccount: Account | null
  euGoodsReverseVatAccount: Account | null
  worldVatAccount: Account | null
  worldReverseVatAccount: Account | null
  worldGoodsVatAccount: Account | null
  worldGoodsReverseVatAccount: Account | null
}

interface AutoliquidatedVatAccountProps {
  onAccountsUpdated: ({
    disabled,
    autoliquidatedVatAccounts,
    autoliquidatedVatRates,
  }: {
    disabled: boolean
    autoliquidatedVatAccounts: AutoliquidatedVatAccountsState
    autoliquidatedVatRates: AccountsVatRates
  }) => void
}

export const AutoliquidatedVatAccounts = ({
  onAccountsUpdated,
}: AutoliquidatedVatAccountProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    createdAutoliquidatedVatAccount,
    selectedAccounts,
    construction_vat,
    construction_reverse_vat,
    eu_vat,
    eu_reverse_vat,
    eu_goods_vat,
    eu_goods_reverse_vat,
    world_vat,
    world_reverse_vat,
    world_goods_vat,
    world_goods_reverse_vat,
  } = useRNBSelector((state) => state.companySettings)
  const companySettings = useRNBSelector((state) => state.companySettings)

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || CompanyStub
  )
  const companyNotOnboarded =
    company.buy_third_party_account_id === null &&
    company.sell_third_party_account_id === null &&
    company.auxiliary === null

  useEffect(() => {
    dispatch(
      getAutoliquidatedVatAccountSettingsThunk({ companyId: selectedCompanyId })
    )
    dispatch(getAutoliquidatedVatRatesThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (createdAutoliquidatedVatAccount) {
      const vatType = createdAutoliquidatedVatAccount.vatType
      const newAutoliquidatedVatAccounts = {
        ...accounts,
        [`${vatType}Account`]: createdAutoliquidatedVatAccount,
      }
      setAccounts(newAutoliquidatedVatAccounts)
      dispatch(createAccountResetAction())
    }
  }, [createdAutoliquidatedVatAccount])

  const [accounts, setAccounts] = useState<AutoliquidatedVatAccountsState>({
    constructionVatAccount: null,
    constructionReverseVatAccount: null,
    euVatAccount: null,
    euReverseVatAccount: null,
    euGoodsVatAccount: null,
    euGoodsReverseVatAccount: null,
    worldVatAccount: null,
    worldReverseVatAccount: null,
    worldGoodsVatAccount: null,
    worldGoodsReverseVatAccount: null,
  })

  const [vatRates, setVatRates] = useState<AccountsVatRates>({
    selected_eu_vat_rate: null,
    selected_eu_goods_vat_rate: null,
    selected_construction_vat_rate: null,
    selected_world_vat_rate: null,
    selected_world_goods_vat_rate: null,
  })

  const [displayVatRateHistory, setDisplayVatRateHistory] = useState(false)

  const vatTypeMapping: Record<VatType, keyof typeof accounts> = {
    eu_vat: "euVatAccount",
    eu_reverse_vat: "euReverseVatAccount",
    construction_vat: "constructionVatAccount",
    construction_reverse_vat: "constructionReverseVatAccount",
    eu_goods_vat: "euGoodsVatAccount",
    eu_goods_reverse_vat: "euGoodsReverseVatAccount",
    world_vat: "worldVatAccount",
    world_reverse_vat: "worldReverseVatAccount",
    world_goods_vat: "worldGoodsVatAccount",
    world_goods_reverse_vat: "worldGoodsReverseVatAccount",
  }

  const vatRateMapping: Record<VatType, AccountVatRatesKey> = {
    eu_vat: "selected_eu_vat_rate",
    eu_reverse_vat: "selected_eu_vat_rate",
    construction_vat: "selected_construction_vat_rate",
    construction_reverse_vat: "selected_construction_vat_rate",
    eu_goods_vat: "selected_eu_goods_vat_rate",
    eu_goods_reverse_vat: "selected_eu_goods_vat_rate",
    world_vat: "selected_world_vat_rate",
    world_reverse_vat: "selected_world_vat_rate",
    world_goods_vat: "selected_world_goods_vat_rate",
    world_goods_reverse_vat: "selected_world_goods_vat_rate",
  }

  const [initialAccounts, setInitialAccounts] =
    useState<AutoliquidatedVatAccountsState>({
      constructionVatAccount: null,
      constructionReverseVatAccount: null,
      euVatAccount: null,
      euReverseVatAccount: null,
      euGoodsVatAccount: null,
      euGoodsReverseVatAccount: null,
      worldVatAccount: null,
      worldReverseVatAccount: null,
      worldGoodsVatAccount: null,
      worldGoodsReverseVatAccount: null,
    })

  const [initialVatRates, setInitialVatRates] = useState<AccountsVatRates>({
    selected_eu_vat_rate: null,
    selected_eu_goods_vat_rate: null,
    selected_construction_vat_rate: null,
    selected_world_vat_rate: null,
    selected_world_goods_vat_rate: null,
  })
  const accountsOptions = (vatType: VatType) => {
    return companySettings[vatType].possible_accounts
      .map((account) => ({
        value: account.id.toString(),
        label: `${account.number} ${account.details}`,
      }))
      .filter(
        (option) =>
          !selectedAccounts.some(
            (selectedAccount) =>
              selectedAccount.accountId === Number(option.value)
          )
      )
  }

  const vatRatesOptions = () => {
    return companySettings.rates.map((rate) => ({
      value: rate.id.toString(),
      label: `${rate.rate}`,
    }))
  }

  const findSelectedOptionValue = (vatType: VatType) => {
    const selectedAccount = accounts[vatTypeMapping[vatType]]

    return selectedAccount
      ? {
          value: selectedAccount.id.toString(),
          label: `${selectedAccount.number} ${selectedAccount.details}`,
        }
      : emptyOptionValue
  }

  const isAutoliquidatedVatDisalbed = (vatType: VatType) => {
    return companySettings[vatType].deactivated_at !== null
  }

  useEffect(() => {
    const accounts = {
      constructionVatAccount: construction_vat.selected_account,
      constructionReverseVatAccount: construction_reverse_vat.selected_account,
      euVatAccount: eu_vat.selected_account,
      euReverseVatAccount: eu_reverse_vat.selected_account,
      euGoodsVatAccount: eu_goods_vat.selected_account,
      euGoodsReverseVatAccount: eu_goods_reverse_vat.selected_account,
      worldVatAccount: world_vat.selected_account,
      worldReverseVatAccount: world_reverse_vat.selected_account,
      worldGoodsVatAccount: world_goods_vat.selected_account,
      worldGoodsReverseVatAccount: world_goods_reverse_vat.selected_account,
    }
    const vatRates = {
      selected_eu_vat_rate:
        companySettings.accounts_vat_rates.selected_eu_vat_rate,
      selected_eu_goods_vat_rate:
        companySettings.accounts_vat_rates.selected_eu_goods_vat_rate,
      selected_construction_vat_rate:
        companySettings.accounts_vat_rates.selected_construction_vat_rate,
      selected_world_vat_rate:
        companySettings.accounts_vat_rates.selected_world_vat_rate,
      selected_world_goods_vat_rate:
        companySettings.accounts_vat_rates.selected_world_goods_vat_rate,
    }
    setAccounts(accounts)
    setVatRates(vatRates)
    setInitialAccounts(accounts)
    setInitialVatRates(vatRates)
  }, [
    construction_vat.selected_account,
    construction_reverse_vat.selected_account,
    eu_vat.selected_account,
    eu_reverse_vat.selected_account,
    eu_goods_vat.selected_account,
    eu_goods_reverse_vat.selected_account,
    world_vat.selected_account,
    world_reverse_vat.selected_account,
    world_goods_vat.selected_account,
    world_goods_reverse_vat.selected_account,
    companySettings.accounts_vat_rates,
  ])

  const findSelectedVatRateValue = (vatType: VatType) => {
    const selectedVatRate = vatRates[vatRateMapping[vatType]]

    return selectedVatRate
      ? {
          value: selectedVatRate.id.toString(),
          label: `${selectedVatRate.rate}`,
        }
      : emptyOptionValue
  }

  const [isModalDisplayed, setIsModalDisplayed] = useState(false)
  const [
    autoliquidatedVatAccountToCreate,
    setAutoliquidatedVatAccountToCreate,
  ] = useState<AutoliquidatedVatAccountPayload | null>(null)
  const [historyDisplay, setHistoryDisplay] = useState<{
    isDisplayed: boolean
    vatType: VatType | null
  }>({
    isDisplayed: false,
    vatType: null,
  })

  const allAutoliquidatedFieldsFilled = () => {
    if (
      Object.values(accounts).every((entry) => entry !== null) &&
      Object.values(vatRates).every((entry) => entry !== null)
    )
      return true
    const filteredVatTypes = vatTypeArray.filter(
      (item) => !item.includes("reverse")
    )
    const isDisabled = filteredVatTypes.every((vat) => {
      return (
        companySettings[vat].deactivated_at ||
        (accounts[vatTypeMapping[vat]] &&
          accounts[
            vatTypeMapping[vat.replace("vat", "reverse_vat") as VatType]
          ] &&
          vatRates[vatRateMapping[vat]])
      )
    })
    return isDisabled
  }

  useEffect(() => {
    const accountsChanged =
      JSON.stringify(accounts) !== JSON.stringify(initialAccounts)
    const vatRatesChanged =
      JSON.stringify(vatRates) !== JSON.stringify(initialVatRates)

    const disabled =
      (!accountsChanged && !vatRatesChanged) || !allAutoliquidatedFieldsFilled()

    onAccountsUpdated({
      autoliquidatedVatAccounts: accounts,
      autoliquidatedVatRates: vatRates,
      disabled,
    })
  }, [accounts, vatRates])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <>
      <AutoliquidatedHistoryModal
        isDisplayed={historyDisplay.isDisplayed}
        onClose={() => {
          setHistoryDisplay({
            isDisplayed: false,
            vatType: null,
          })
          setDisplayVatRateHistory(false)
          dispatch(
            getCompanyAutoliquidatedVatAccountsHistoryResetAction(
              historyDisplay.vatType
            )
          )
        }}
        vatType={historyDisplay.vatType}
        rate={displayVatRateHistory}
      />
      <CreateAutoliquidatedVatModal
        isDisplayed={isModalDisplayed}
        onClose={() => {
          setIsModalDisplayed(false)
          setAutoliquidatedVatAccountToCreate(null)
        }}
        autoliquidatedVatAccountToCreate={
          autoliquidatedVatAccountToCreate || {
            vatType: null,
            prefix: [],
            forbid: [],
          }
        }
      />
      <Container>
        <Ct.Text
          text={intl.formatMessage({
            id: "office-company-settings.auto-vat-accounts-info",
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer height={2} />
        <BorderedContainer>
          <>
            {vatTypeArray.map((vatType) => (
              <>
                {!vatType.includes("reverse") && (
                  <SwitchWrapper>
                    <SwitchTooltipWrapper
                      data-tip={intl.formatMessage({
                        id: `office-company-settings.auto-vat-accounts-tooltip.${
                          isAutoliquidatedVatDisalbed(vatType)
                            ? "activate"
                            : "deactivate"
                        }.${vatType}`,
                      })}
                    >
                      <Switch
                        value={!isAutoliquidatedVatDisalbed(vatType)}
                        onToggle={() => {
                          dispatch(
                            activateOrDeactivateAutoliquidatedVatThunk(
                              selectedCompanyId,
                              vatType,
                              isAutoliquidatedVatDisalbed(vatType)
                                ? "activate"
                                : "deactivate"
                            )
                          )
                        }}
                      />
                    </SwitchTooltipWrapper>
                    <ReactTooltip
                      delayHide={500}
                      effect={"solid"}
                      eventOff="mouseleave scroll mousewheel blur"
                      borderColor={colors.navy}
                    />
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: `office-company-settings.auto-vat-accounts-title.${vatType}`,
                      })}
                      textStyle={{
                        lineHeight: 3,
                        color: "cornflower",
                        textTransform: "uppercase",
                        fontWeight: 700,
                        fontSize: 2.1,
                      }}
                    />
                  </SwitchWrapper>
                )}
                <div key={vatType}>
                  <Ct.Spacer height={2} />
                  <div>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: `office-company-settings.eu-construction-vat-accounts.${vatType}`,
                      })}
                      textStyle={{
                        fontWeight: 700,
                        fontSize: 1.9,
                        color: isAutoliquidatedVatDisalbed(vatType)
                          ? "disabledGrey"
                          : "navy",
                      }}
                    />
                  </div>
                  <Ct.Spacer height={2} />
                  <Ct.Row>
                    <Select
                      intl={intl}
                      disabled={
                        companyNotOnboarded ||
                        accountsOptions(vatType)?.length === 0 ||
                        isAutoliquidatedVatDisalbed(vatType)
                      }
                      value={findSelectedOptionValue(vatType)}
                      options={accountsOptions(vatType) || []}
                      domain={"office-company-settings.vat-accounts"}
                      optionType={"buy-account"}
                      onChangeCallback={(selectedAccount) => {
                        const splittedLabel = selectedAccount.label.split(" ")
                        const number = splittedLabel[0]
                        const details = splittedLabel.slice(1).join(" ")
                        const account = {
                          id: Number(selectedAccount.value),
                          number: number,
                          details: details,
                        }

                        const accountToSet = vatTypeArray.includes(vatType)
                          ? account
                          : accounts[vatTypeMapping[vatType]]

                        setAccounts({
                          ...accounts,
                          [vatTypeMapping[vatType]]: accountToSet,
                        })
                      }}
                    />
                    <Ct.Spacer />
                    <Ct.Button
                      label={intl.formatMessage({
                        id: "office-company-settings.vat-accounts.create",
                      })}
                      onClick={() => {
                        setAutoliquidatedVatAccountToCreate({
                          vatType: vatType,
                          prefix: companySettings[vatType].prefix,
                          forbid: companySettings[vatType].forbid,
                        })
                        setIsModalDisplayed(true)
                      }}
                      width={22}
                      prefix={<Plus />}
                      disabled={
                        companyNotOnboarded ||
                        isAutoliquidatedVatDisalbed(vatType)
                      }
                    />
                    <Ct.Spacer />
                    <EditWrapper
                      onClick={() => {
                        setHistoryDisplay({
                          isDisplayed: true,
                          vatType: vatType,
                        })
                        setDisplayVatRateHistory(false)
                      }}
                    >
                      <StyledEye />
                    </EditWrapper>
                  </Ct.Row>

                  {vatType.includes("reverse") && (
                    <>
                      <Ct.Spacer height={2} />
                      <div>
                        <Ct.Text
                          text={intl.formatMessage({
                            id: `office-company-settings.auto-vat-accounts.rates.title.${vatType}`,
                          })}
                          textStyle={{
                            fontWeight: 700,
                            fontSize: 1.9,
                            color: isAutoliquidatedVatDisalbed(vatType)
                              ? "disabledGrey"
                              : "navy",
                          }}
                        />
                      </div>
                      <Ct.Spacer height={2} />
                      <Ct.Row>
                        <Select
                          intl={intl}
                          disabled={
                            companyNotOnboarded ||
                            accountsOptions(vatType)?.length === 0 ||
                            isAutoliquidatedVatDisalbed(vatType)
                          }
                          value={findSelectedVatRateValue(vatType)}
                          options={vatRatesOptions()}
                          domain={"office-company-settings.vat-accounts"}
                          optionType={"rate"}
                          onChangeCallback={(selectedVatRate) => {
                            const vatRate = {
                              id: Number(selectedVatRate.value),
                              rate: selectedVatRate.label,
                            }

                            setVatRates({
                              ...vatRates,
                              [vatRateMapping[vatType]]: vatRate,
                            })
                          }}
                        />
                        <Ct.Spacer />
                        <EditWrapper
                          onClick={() => {
                            setHistoryDisplay({
                              isDisplayed: true,
                              vatType: vatType,
                            })
                            setDisplayVatRateHistory(true)
                          }}
                        >
                          <StyledEye />
                        </EditWrapper>
                      </Ct.Row>
                      <Ct.Spacer height={4} />
                    </>
                  )}
                </div>
              </>
            ))}
            <Ct.Spacer />
          </>
          <Ct.Spacer height={1} />
        </BorderedContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 0 6rem;
`
const BorderedContainer = styled.div`
  border: 1px solid ${colors.cornflower};
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
`
const EditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: pointer;
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
const SwitchTooltipWrapper = styled.div`
  height: 2rem;
`
