import { SetStateAction, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { getIdFromParams } from "../../../../utils/company"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { OtherFilesRows } from "../../../../components/OtherFilesRows"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Calendar } from "../../../../assets/calendarSearch.svg"
import { ReactComponent as InfoFull } from "../../../../assets/info-full.svg"
import { FiscalYearPicker } from "../../../../components/FiscalYearPicker"
import * as Ct from "ldlj"
import {
  deactivateFullDocumentReset,
  FullDocument,
  getDeactivatedDocumentsThunk,
  getDeactivatedReasonsThunk,
} from "../../../../store/ducks/fullDocuments.ducks"
import {
  filterDocuments,
  sortFullDocsByArchiveId,
  sortFullDocsByDate,
  sortFullDocsByFileName,
  sortFullDocsByOriginalFileName,
  sortFullDocsByQualification,
} from "../../../../utils/filesList"
import { Alert } from "../../../../components/Commons/Alert"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../components/Commons/Table"
import { DeactivateDocumentModal } from "../../../../components/DeactivateDocumentModal"
import { Checkbox } from "../../../../components/Commons/Checkbox"
import { InputDateRange } from "../../../../components/Commons/InputDateRange"
import { DateTime } from "luxon"
import { FiscalYear } from "../../../../store/ducks/fiscalYears.ducks"

export type FileType =
  | "unsupported"
  | "bank"
  | "fiscal"
  | "permanent"
  | "social"

export interface DocumentType {
  fileType: FileType
}

export const OtherFiles = ({ fileType }: DocumentType) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const { selectedFiscalYearId, fiscalYearsCompany } = useRNBSelector(
    (state) => ({
      selectedFiscalYearId: state.fiscalYears.selectedFiscalYearId,
      fiscalYearsCompany:
        state.fiscalYears.fiscalYearsByCompanyId[selectedCompanyId],
    })
  )
  const { deactivatedDocuments } = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || {}
  )
  const userDeactivatedReasons = useRNBSelector(
    (state) => state.writings.deactivatedReasons.user_deactivated_reasons
  )
  const userTypology = useRNBSelector((state) => state.user.typology)
  const fullDocumentsDeactivateStatus = useRNBSelector(
    (state) => state.fullDocuments.fullDocumentsDeactivateStatus
  )

  const [search, setSearch] = useState<string>("")
  const [fullDocuments, setFullDocuments] = useState<FullDocument[]>([])
  const [sortedDocuments, setSortedDocuments] = useState<FullDocument[]>([])
  const [filteredDocuments, setFilteredDocuments] = useState<FullDocument[]>([])
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [fullDocIdListToDelete, setFullDocIdListToDelete] = useState<number[]>(
    []
  )
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYear>({
    id: 0,
    beginExercise: "",
    endExercise: "",
    companyId: 0,
  })
  const [customDateFilterIsActive, setCustomDateFilterIsActive] =
    useState<boolean>(false)
  const [customDateRange, setCustomDateRange] = useState<[Date, Date]>([
    DateTime.now().set({ day: 1 }).toJSDate(),
    DateTime.now().set({ day: DateTime.now().daysInMonth }).toJSDate(),
  ])
  const [shiftHeld, setShiftHeld] = useState<boolean>(false)

  const getColumnsConfig = () => {
    if (["customer", "customer_accountant"].includes(userTypology)) {
      return [
        { title: "deposit-name", width: 35 },
        { title: "file-name", width: 35 },
        { title: "qualification", width: 40 },
        { title: "date", width: 20 },
        { title: "actions", width: 30 },
        { title: "archive", width: 20 },
      ]
    } else {
      return [
        { title: "multiDel", width: 70 },
        { title: "deposit-name", width: 35 },
        { title: "file-name", width: 35 },
        { title: "qualification", width: 40 },
        { title: "date", width: 20 },
        { title: "actions", width: 30 },
        { title: "archive", width: 20 },
      ]
    }
  }

  const columnsConfig: { title: string; width: number }[] = getColumnsConfig()

  const sorter = (asc: boolean) => (option: string) => {
    if (option === "deposit-name") {
      setSortedDocuments(sortFullDocsByOriginalFileName(sortedDocuments, asc))
    } else if (option === "file-name") {
      setSortedDocuments(sortFullDocsByFileName(sortedDocuments, asc))
    } else if (option === "qualification") {
      setSortedDocuments(sortFullDocsByQualification(sortedDocuments, asc))
    } else if (option === "date") {
      setSortedDocuments(sortFullDocsByDate(sortedDocuments, asc))
    } else if (option === "archive") {
      setSortedDocuments(sortFullDocsByArchiveId(sortedDocuments, asc))
    }
  }

  const updateSortedDocuments = () => {
    if (fullDocuments && fullDocuments.length > 0) {
      const filteredDocuments = filterDocuments(
        fullDocuments,
        search,
        customDateFilterIsActive,
        customDateRange
      )
      setSortedDocuments(filteredDocuments)
    } else {
      setSortedDocuments([])
    }
  }

  const handleDelete = (fullDocId: number, uniqueDelete: boolean) => {
    if (uniqueDelete) {
      setFullDocIdListToDelete([fullDocId])
      setDisplayDeleteModal(true)
    } else {
      if (fullDocIdListToDelete.includes(fullDocId)) {
        setFullDocIdListToDelete((prevValue) =>
          prevValue.filter((e) => e !== fullDocId)
        )
      } else setFullDocIdListToDelete((prevValue) => [...prevValue, fullDocId])
    }
  }

  const handleCheckboxChange = (fullDocId: number, uniqueDelete: boolean) => {
    if (
      shiftHeld &&
      !fullDocIdListToDelete.includes(fullDocId) &&
      !uniqueDelete
    ) {
      const firstIndex = filteredDocuments.findIndex(
        (fd) => fullDocIdListToDelete.includes(fd.id) || fullDocId === fd.id
      )
      const lastElem = filteredDocuments
        .slice()
        .reverse()
        .find(
          (fd) => fullDocIdListToDelete.includes(fd.id) || fullDocId === fd.id
        )
      const lastIndex = filteredDocuments.findIndex(
        (fd) => fd.id === lastElem?.id || 0
      )

      if (firstIndex !== -1 && lastIndex !== -1) {
        for (let i = firstIndex; i <= lastIndex; i++) {
          if (!fullDocIdListToDelete.includes(filteredDocuments[i].id)) {
            handleDelete(filteredDocuments[i].id, uniqueDelete)
          }
        }
      } else handleDelete(fullDocId, uniqueDelete)
    } else handleDelete(fullDocId, uniqueDelete)
  }

  const handleCheckboxHeader = () => {
    const allDocAddedToDelete = filteredDocuments.every((e) =>
      fullDocIdListToDelete.includes(e.id)
    )
    const fullDocIds = allDocAddedToDelete
      ? filteredDocuments.map((e) => e.id)
      : filteredDocuments
          .filter((e) => !fullDocIdListToDelete.includes(e.id))
          .map((e) => e.id)
    for (const fullDocId of fullDocIds) handleDelete(fullDocId, false)
  }

  const downHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(true)
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(false)
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort = columnsConfig[columnToSort.index].title
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        updateSortedDocuments()
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getDeactivatedDocumentsThunk(selectedCompanyId))
      dispatch(getDeactivatedReasonsThunk())
    }
  }, [selectedCompanyId, dispatch])

  useEffect(() => {
    const fullDocsOfFiscalYear =
      deactivatedDocuments?.deactivatedDocumentsByFiscalYear.find(
        (documents) => documents.fiscal_year_id === selectedFiscalYearId
      )
    setFullDocuments(fullDocsOfFiscalYear?.full_documents || [])
    setFullDocIdListToDelete([])
  }, [deactivatedDocuments, selectedFiscalYearId])

  useEffect(() => {
    updateSortedDocuments()
  }, [
    setSortedDocuments,
    search,
    fullDocuments,
    customDateFilterIsActive,
    customDateRange,
  ])

  useEffect(() => {
    if (fiscalYearsCompany) {
      let companyFySelected = fiscalYearsCompany.find(
        (fy) => fy.id === selectedFiscalYearId
      )
      if (companyFySelected) {
        setSelectedFiscalYear(companyFySelected)
        setCustomDateRange([
          new Date(companyFySelected.beginExercise),
          new Date(companyFySelected.endExercise),
        ])
      }
    }
  }, [selectedFiscalYearId])

  useEffect(() => {
    setFilteredDocuments(
      sortedDocuments.filter((doc) =>
        (doc.deactivated_type || "").includes(String(fileType))
      )
    )
  }, [sortedDocuments, fileType])

  useEffect(() => {
    if (["success", "error"].includes(fullDocumentsDeactivateStatus)) {
      dispatch(deactivateFullDocumentReset())
      setFullDocIdListToDelete([])
    }
  }, [fullDocumentsDeactivateStatus])

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  const deactivatedReasonsOptions = useMemo(() => {
    return Object.keys(userDeactivatedReasons).map((key: string) => ({
      value: key,
      label: userDeactivatedReasons[key],
    }))
  }, [userDeactivatedReasons])

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <HeaderLeftWrapper>
            <StyledInput
              name={"searchDocument"}
              id={"searchDocument"}
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(e: { target: { value: SetStateAction<string> } }) => {
                setSearch(e.target.value)
              }}
              shadowed={true}
              noBorder={true}
            />
          </HeaderLeftWrapper>

          {selectedCompanyId && (
            <FiscalYearPicker companyId={selectedCompanyId} />
          )}

          <HeaderRightWrapper>
            <CustomDateContainer>
              {customDateFilterIsActive ? (
                <InputDateRange
                  value={customDateRange}
                  onChange={(value) => setCustomDateRange(value as never)}
                  onClose={() => setCustomDateFilterIsActive(false)}
                  placement="bottomEnd"
                  minimum={selectedFiscalYear.beginExercise}
                  maximum={selectedFiscalYear.endExercise}
                />
              ) : (
                <CustomDateInnerContainer>
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "ged.header.date-custom.message",
                    })}
                    textStyle={{
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: 1.8,
                    }}
                  />
                  <StyledInfoFull
                    data-tip={intl.formatMessage({
                      id: "ged.header.date-custom.message.tooltip",
                    })}
                  />
                  <Ct.Button
                    label={<Calendar />}
                    onClick={() => {
                      setCustomDateFilterIsActive(true)
                    }}
                    width={6}
                    height={6}
                  />
                </CustomDateInnerContainer>
              )}
            </CustomDateContainer>

            {fullDocIdListToDelete.length > 0 && <Ct.Spacer width={1} />}
            {fullDocIdListToDelete.length > 0 && (
              <Ct.Button
                label={
                  fullDocumentsDeactivateStatus === "loading" ? (
                    <Ct.SpinningLoader />
                  ) : (
                    `Supprimer la sélection (${fullDocIdListToDelete.length})`
                  )
                }
                onClick={() => {
                  setDisplayDeleteModal(true)
                }}
                width={30}
                colorType="Tertiary"
                colorScheme={{
                  background: "amaranth",
                  color: "white",
                  border: "amaranth",
                }}
              />
            )}
          </HeaderRightWrapper>
        </Header>

        {selectedCompanyId && (
          <>
            <Ct.TableWrapper>
              <StyledTableHeader>
                {columnsConfig.map(({ title, width }, index) => {
                  if (title === "multiDel") {
                    return (
                      <HeaderCheckbox
                        calculatedWidth={width}
                        key={`header-${index}`}
                      >
                        <Checkbox
                          label=""
                          isChecked={
                            filteredDocuments.every((e) =>
                              fullDocIdListToDelete.includes(e.id)
                            ) && filteredDocuments.length > 0
                          }
                          value={
                            filteredDocuments.every((e) =>
                              fullDocIdListToDelete.includes(e.id)
                            ) && filteredDocuments.length > 0
                          }
                          name=""
                          onChange={() => handleCheckboxHeader()}
                          colorChecked={colors.amaranth}
                          checkBoxSize={2.5}
                        />
                      </HeaderCheckbox>
                    )
                  } else {
                    return (
                      <HeaderItem
                        calculatedWidth={width}
                        key={title}
                        isFirstColumn={title === "deposit-name"}
                      >
                        {title !== "actions" ? (
                          <ClickableTitleSort
                            tid={`ged.other-files.${title}`}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable
                            tid={`ged.other-files.${title}`}
                            intl={intl}
                          />
                        )}
                      </HeaderItem>
                    )
                  }
                })}
              </StyledTableHeader>

              <Ct.TableBody>
                {fullDocuments && fullDocuments.length > 0 ? (
                  <>
                    {filteredDocuments.map((doc: FullDocument) => (
                      <Ct.WrapperRows key={doc.id} id={`${doc.archive_id}`}>
                        <OtherFilesRows
                          fullDoc={doc}
                          selectedCompanyId={selectedCompanyId}
                          deactivatedReasonsOptions={deactivatedReasonsOptions}
                          fullDocIdListToDelete={fullDocIdListToDelete}
                          columnsConfig={columnsConfig}
                          onSelectFullDocToDelete={handleCheckboxChange}
                        />
                      </Ct.WrapperRows>
                    ))}
                  </>
                ) : (
                  <NoFilesDisplay />
                )}
              </Ct.TableBody>
            </Ct.TableWrapper>
          </>
        )}
      </StyledSection>

      <DeactivateDocumentModal
        isDisplayed={displayDeleteModal}
        onClose={() => {
          setDisplayDeleteModal(false)
        }}
        documentIdList={fullDocIdListToDelete || 0}
        companyId={selectedCompanyId || 0}
        whichDocument={"fullDocument"}
      />
    </Wrapper>
  )
}

const NoFilesDisplay = () => {
  const intl = useIntl()
  return (
    <WrapperAlert>
      <Alert alertType="info" margin={"11rem 38rem"}>
        <Ct.Text
          text={intl.formatMessage({ id: "ged.other-files.alert.1" })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Text
          text={intl.formatMessage({ id: "ged.other-files.alert.2" })}
          textStyle={{ lineHeight: 3 }}
        />
      </Alert>
    </WrapperAlert>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`

const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`

const WrapperAlert = styled.div``

const StyledTableHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  height: 7rem;
  width: 100%;
  background-color: ${colors.lavender};
`
const HeaderCheckbox = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}px`};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
`
const HeaderItem = styled.div<{
  calculatedWidth: number
  isFirstColumn: boolean
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  padding: 0 4rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isFirstColumn ? "start" : "center")};
  align-items: center;
  user-select: none;
  margin: auto;
`

const StyledInfoFull = styled(InfoFull)`
  margin: 0 2rem 0 1rem;
  & path {
    fill: ${colors.black};
  }
`

const HeaderLeftWrapper = styled.div`
  width: 70rem;
`
const HeaderRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70rem;
`
const CustomDateContainer = styled.div`
  width: 35rem;
  display: flex;
  justify-content: flex-end;
`
const CustomDateInnerContainer = styled.div`
  display: flex;
  align-items: center;
`
