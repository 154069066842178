import styled from "styled-components/macro"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import * as yup from "yup"
import {
  Control,
  Controller,
  FieldValues,
  useForm,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"

import { useRNBSelector } from "../store/rootReducer"
import {
  getAssignableTypologies,
  getUserPermissions,
  TeamMember,
  UserTypes,
} from "../model/users"
import { ReactComponent as Cross } from "../assets/cross.svg"
import { boxShadow, colors } from "../styles/design.config"

import {
  editUserResetAction,
  editUserThunk,
  putGrantAccessThunk,
  getUserPermissionThunk,
  getAccessibleCompaniesThunk,
  grantCompanies,
  revokeCompany,
  putRevokeAccessThunk,
  editUserTypologyThunk,
  deactivateUserThunk,
  deactivateUserResetAction,
  TeamStateEditRequestsStatuses,
} from "../store/ducks/team.ducks"

import { findCompaniesToAdd } from "../utils/company"
import { Company } from "../store/ducks/companies.ducks"
import { usePrompt } from "../utils/usePrompt.hook"
import { userInvitationUninvited } from "../store/ducks/user.ducks"
import { Alert } from "./Commons/Alert"
import { Select } from "./Commons/Select"
import { MultiSelect } from "./Commons/MultiSelect"

interface UserRolesOptions {
  value: UserTypes
  label: string
}

export const UserEditModal = ({
  isDisplayed,
  onClose,
}: Ct.ModalComponentsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const deactivateUserStatus = useRNBSelector(
    (state) => state.team.deactivateUserStatus
  )
  const selectedUserId = useRNBSelector((state) => state.team.editedUserId)
  const selectedUser = useRNBSelector(
    (state) => state.team.team[selectedUserId as number]
  )
  const assignableTypologies = useRNBSelector(getAssignableTypologies)
  const someRequestAreLoading = useRNBSelector((state) => {
    const teamStateEditRequestsStatuses: Array<
      keyof TeamStateEditRequestsStatuses
    > = [
      "editNameStatus",
      "editTypologyStatus",
      "grantAccessStatus",
      "revokeAccessStatus",
    ]

    return teamStateEditRequestsStatuses.some((requestKey) => {
      return state.team[requestKey] === "LOADING"
    })
  })
  const grantedCompanies = useRNBSelector(
    (state) => state.team.grantedCompanies
  )
  const revokedCompanies = useRNBSelector(
    (state) => state.team.revokedCompanies
  )
  const userId = useRNBSelector((state) => state.user.id)
  const isSelfEditing = selectedUserId === userId
  const editNameStatus = useRNBSelector((state) => state.team.editNameStatus)
  const editTypologyStatus = useRNBSelector(
    (state) => state.team.editTypologyStatus
  )
  const editGrantAccessStatus = useRNBSelector(
    (state) => state.team.editGrantAccessStatus
  )

  const [currentRole, setCurrentRole] = useState<UserTypes>(
    selectedUser?.typology
  )

  const schema = yup.object({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.object({ value: yup.string().required() }),
  })

  const {
    register,
    watch,
    control,
    setValue,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const initialOptionsList: Array<UserRolesOptions> = useMemo(() => {
    return [
      { value: "administrator", label: "Administrateur" },
      { value: "manager", label: "Manager" },
      { value: "collaborator", label: "Collaborateur" },
      { value: "customer_accountant", label: "Client comptable" },
      { value: "customer", label: "Client" },
    ]
  }, [])

  const [userOptionsList, setUserOptionsList] =
    useState<Array<UserRolesOptions>>(initialOptionsList)

  const selectedOption: UserRolesOptions = userOptionsList.find(
    (o) => o.value === currentRole
  ) as UserRolesOptions

  const initialRole = selectedUser?.typology
  useEffect(() => {
    setCurrentRole(selectedUser?.typology || null)
    setValue("email", selectedUser?.email)
    setValue("firstName", selectedUser?.first_name)
    setValue("lastName", selectedUser?.last_name)
    setValue(
      "role",
      userOptionsList.find((o) => o.value === selectedUser?.typology)
    )
  }, [selectedUser, setValue, userOptionsList])

  const email = watch("email")
  const firstName = watch("firstName")
  const lastName = watch("lastName")

  const close = () => {
    if (isDirty) {
      if (!window.confirm(intl.formatMessage({ id: "form.unsaved.message" }))) {
        return
      }
    }
    onClose()
    setCurrentRole(selectedUser?.typology || null)
    reset({
      email: selectedUser?.email,
      firstName: selectedUser?.first_name,
      lastName: selectedUser?.last_name,
      role: userOptionsList.find((o) => o.value === selectedUser?.typology),
    })
    dispatch(editUserResetAction())
    dispatch(userInvitationUninvited())
  }

  const onEditUser = () => {
    if (isDirty) {
      dispatch(
        editUserThunk({
          email,
          firstName,
          lastName,
          isSelfEditing,
          currentRole,
        })
      )
    }
    if (grantedCompanies.length > 0) {
      dispatch(putGrantAccessThunk())
    }
    if (revokedCompanies.length > 0) {
      dispatch(putRevokeAccessThunk())
    }
    if (initialRole !== currentRole) {
      dispatch(editUserTypologyThunk({ role: currentRole }))
    }
  }

  useEffect(() => {
    if (deactivateUserStatus === "SUCCESS") {
      dispatch(deactivateUserResetAction())
      onClose()
    }
  }, [deactivateUserStatus, dispatch])

  useEffect(() => {
    const filteredOptionList = initialOptionsList.filter((option) =>
      assignableTypologies.includes(option.value)
    )

    if (
      JSON.stringify(userOptionsList) !== JSON.stringify(filteredOptionList)
    ) {
      setUserOptionsList(filteredOptionList)
    }
  }, [
    assignableTypologies,
    setUserOptionsList,
    userOptionsList,
    initialOptionsList,
  ])

  useEffect(() => {
    if (!someRequestAreLoading) {
      // once all request are resolved, reset form so we can use isDirty to disable button when no change
      reset()
    }
  }, [reset, someRequestAreLoading])

  useEffect(() => {
    if (
      [editNameStatus, editTypologyStatus, editGrantAccessStatus].some(
        (status) => status === "SUCCESS"
      )
    ) {
      setCurrentRole(selectedUser?.typology || null)
      reset({
        email: selectedUser?.email,
        firstName: selectedUser?.first_name,
        lastName: selectedUser?.last_name,
        role: userOptionsList.find((o) => o.value === selectedUser?.typology),
      })
      dispatch(editUserResetAction())
    }
  }, [
    editNameStatus,
    editTypologyStatus,
    editGrantAccessStatus,
    selectedUser,
    userOptionsList,
    dispatch,
    reset,
  ])
  usePrompt(intl.formatMessage({ id: "form.unsaved.message" }), isDirty)

  useEffect(() => {
    if (isDisplayed) {
      dispatch(getUserPermissionThunk())
    }
  }, [dispatch, isDisplayed])

  useEffect(() => {
    if (isDisplayed) {
      dispatch(getAccessibleCompaniesThunk())
    }
  }, [dispatch, isDisplayed])

  useEffect(() => {
    dispatch(editUserResetAction())
  }, [dispatch])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(30vh - 25rem)`}
    >
      {isDisplayed && (
        <FullWidthCard>
          <Ct.CloseCross onClick={close} />
          <Ct.Title
            text={intl.formatMessage({ id: "edit-user.title" })}
            size={7}
          />
          <Ct.Spacer height={5} />
          <Content>
            <UserInfoPanel
              register={register as unknown as UseFormRegister<FieldValues>}
              watch={watch}
              control={control}
              selectedOption={selectedOption}
              userOptionsList={userOptionsList}
              setCurrentRole={setCurrentRole}
            />
            <Ct.Spacer width={3} />
            <UserAccessPanel
              onEditUser={onEditUser}
              isDirty={isDirty || initialRole !== currentRole}
              someRequestAreLoading={someRequestAreLoading}
              errors={errors}
              selectedUser={selectedUser}
            />
          </Content>
        </FullWidthCard>
      )}
    </Ct.Modal>
  )
}

interface UserAccessPanelProps {
  onEditUser: () => void
  isDirty: boolean
  someRequestAreLoading: boolean
  errors: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
  }
  selectedUser: TeamMember
}

const UserAccessPanel = ({
  onEditUser,
  isDirty,
  someRequestAreLoading,
  errors,
  selectedUser,
}: UserAccessPanelProps) => {
  const intl = useIntl()
  const editStatus = useRNBSelector((state) => state.user.editStatus)
  const dispatch = useDispatch()
  const userId = useRNBSelector((state) => state.user.id)
  const selectedUserId = useRNBSelector((state) => state.team.editedUserId)
  const isSelfEditing = selectedUserId === userId

  const mainUserCompanies = useRNBSelector((state) =>
    Object.values(state.companies.companies)
  )
  const accessibleCompanies = useRNBSelector(
    (state) => state.team.accessibleCompanies
  )
  const grantedCompanies = useRNBSelector(
    (state) => state.team.grantedCompanies
  )
  const revokedCompanies = useRNBSelector(
    (state) => state.team.revokedCompanies
  )

  const companiesToAdd = findCompaniesToAdd({
    mainUserCompanies,
    accessibleCompanies,
  })

  const schema = yup.object({
    companies: yup.object({ value: yup.string().required() }),
  })

  const { control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const companiesToAddOptions = companiesToAdd
    .filter(
      (c) =>
        !grantedCompanies.find((grantedCompany) => grantedCompany.id === c.id)
    )
    .map((c) => ({
      value: String(c.id),
      label: c.accounting_software_reference
        ? c.name + (" / " + c.accounting_software_reference)
        : c.name,
    }))

  const addCompanies = () => {
    if (!companies) {
      return
    }
    dispatch(
      grantCompanies(
        companies.map((c: { value: string; label: string }) => ({
          id: Number(c.value),
          name: c.label,
        }))
      )
    )
    setCompanies([])
  }

  const revokeCompanyOnClick = (company: Company) => {
    dispatch(revokeCompany(company))
  }

  type optionType = { value: string; label: string }
  const [companies, setCompanies] = useState<ReadonlyArray<optionType>>([])

  const companiesToDisplay = [...accessibleCompanies, ...grantedCompanies]

  return (
    <UserAccess>
      <StyledTabContainer>
        <StyledTabHeader>
          <Ct.Text
            text={intl.formatMessage({ id: "edit-user.list" })}
            textStyle={{
              color: "white",
              textTransform: "uppercase",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 2.25,
            }}
          />
        </StyledTabHeader>
        <StyledTabContent>
          <Ct.SpacedBetween>
            <Controller
              name="companies"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  intl={intl}
                  field={field}
                  options={companiesToAddOptions}
                  value={companies}
                  label={""}
                  domain={"edit-user"}
                  optionType={"multiselect"}
                  onChange={setCompanies}
                  disabled={
                    selectedUser?.typology === "administrator" || isSelfEditing
                  }
                  canToggleAllOptions={true}
                />
              )}
            />

            <Ct.Spacer width={3} />
            <Ct.Button
              label={intl.formatMessage({ id: "edit-user.add" })}
              onClick={() => {
                addCompanies()
              }}
              disabled={!companies || companies.length === 0}
              width={12.8}
            />
          </Ct.SpacedBetween>
          <Ct.Spacer height={4} />
          <Ct.Text
            text={intl.formatMessage({ id: "edit-user.list" })}
            textStyle={{
              fontFamily: "Poppins",
              fontSize: 1.75,
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          />
          <Ct.Spacer height={3} />

          {companiesToDisplay.length === 0 ? (
            <AlertArea>
              <Alert alertType="bulb" centerIcon={false}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "edit-user.warning-message.no-access",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "edit-user.warning-message.give-access",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
              </Alert>
            </AlertArea>
          ) : (
            <CompaniesList>
              <CompanyListItems
                companies={companiesToDisplay.filter(
                  (company, index) => index % 3 === 0
                )}
                mainUserCompanies={mainUserCompanies}
                revokedCompanies={revokedCompanies}
                revokeCompanyOnClick={revokeCompanyOnClick}
              />
              {companiesToDisplay.length > 1 ? (
                <Ct.VerticalSeparator />
              ) : (
                <div />
              )}
              <CompanyListItems
                companies={companiesToDisplay.filter(
                  (_, index) => index % 3 === 1
                )}
                mainUserCompanies={mainUserCompanies}
                revokedCompanies={revokedCompanies}
                revokeCompanyOnClick={revokeCompanyOnClick}
              />
              {companiesToDisplay.length > 2 ? (
                <Ct.VerticalSeparator />
              ) : (
                <div />
              )}
              <CompanyListItems
                companies={companiesToDisplay.filter(
                  (_, index) => index % 3 === 2
                )}
                mainUserCompanies={mainUserCompanies}
                revokedCompanies={revokedCompanies}
                revokeCompanyOnClick={revokeCompanyOnClick}
              />
            </CompaniesList>
          )}
        </StyledTabContent>
      </StyledTabContainer>

      <Ct.Spacer height={4} />

      <Ct.Button
        loadingStatus={editStatus.toLowerCase() as Ct.LoadingStatus}
        onClick={() => {
          onEditUser()
        }}
        label={intl.formatMessage({ id: "edit-user.save" })}
        width={42}
        disabled={
          someRequestAreLoading ||
          Object.keys(errors).length !== 0 || //TODO : switch the Object.keys(errors).length !== 0 for !isValid but the button stay disabled (error with isValid) when we do this
          (!isDirty &&
            revokedCompanies.length === 0 &&
            grantedCompanies.length === 0)
        }
      />
    </UserAccess>
  )
}

interface CompanyListItemsProps {
  companies: { id: number; name: string; hidden_from_team?: boolean }[]
  mainUserCompanies: Company[]
  revokedCompanies: Company[]
  revokeCompanyOnClick: (revokedCompany: {
    id: number
    name: string
    hidden_from_team?: boolean
  }) => void
}

const CompanyListItems = ({
  companies,
  mainUserCompanies,
  revokedCompanies,
  revokeCompanyOnClick,
}: CompanyListItemsProps) => {
  const selectedUserId = useRNBSelector((state) => state.team.editedUserId)
  const selectedUserTypology = useRNBSelector(
    (state) => state.team.team[selectedUserId as number]?.typology
  )

  const userPermissions = useRNBSelector(getUserPermissions)

  const canRevokeAccessOnEditedUser =
    userPermissions && userPermissions[selectedUserTypology]?.revoke_access

  return (
    <Wrapper>
      {companies
        .filter(
          (c) =>
            !revokedCompanies.find(
              (revokedCompany) => c.id === revokedCompany.id
            )
        )
        .map((c) => {
          const label = c.name.split("/")
          return (
            <MarginRowCenter key={c.id}>
              <Ct.SpacedBetween>
                <div>
                  <Ct.Text
                    text={label[0]}
                    textStyle={{
                      fontFamily: "Roboto",
                      fontSize: 1.75,
                      textTransform: "uppercase",
                    }}
                  />
                  {label[1] && (
                    <Ct.Text
                      text={label[1]}
                      textStyle={{
                        fontFamily: "Roboto",
                        fontSize: 1.75,
                        textTransform: "uppercase",
                        color: "cornflower",
                      }}
                      css={{ paddingLeft: "0.5rem" }}
                    />
                  )}
                </div>
                {(mainUserCompanies.find(
                  (mainUserCompany) => mainUserCompany.id === c.id
                ) &&
                  canRevokeAccessOnEditedUser === "yes") ||
                (c.hidden_from_team &&
                  canRevokeAccessOnEditedUser === "only_hidden") ? (
                  <RemoveCompanyButton
                    onClick={() => {
                      revokeCompanyOnClick(c)
                    }}
                  >
                    <RemoveCompanyCross />
                  </RemoveCompanyButton>
                ) : (
                  <div />
                )}
              </Ct.SpacedBetween>
            </MarginRowCenter>
          )
        })}
    </Wrapper>
  )
}

interface UserInfoPanelProps {
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues, object>
  selectedOption: UserRolesOptions
  setCurrentRole: Dispatch<SetStateAction<UserTypes>>
  userOptionsList: UserRolesOptions[]
}

const UserInfoPanel = ({
  watch,
  register,
  control,
  selectedOption,
  userOptionsList,
  setCurrentRole,
}: UserInfoPanelProps) => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const userId = useRNBSelector((state) => state.user.id)
  const editStatus = useRNBSelector((state) => state.user.editStatus)
  const userPermissions = useRNBSelector(getUserPermissions)

  const selectedUserId = useRNBSelector((state) => state.team.editedUserId)
  const selectedUser = useRNBSelector(
    (state) => state.team.team[selectedUserId as number]
  )
  const permissionsOnEditedUser =
    userPermissions && userPermissions[selectedUser?.typology]

  const isSelfEditing = selectedUserId === userId

  const email = watch("email")
  const firstName = watch("firstName")
  const lastName = watch("lastName")

  return (
    <UserInfo>
      <StyledTabContainer>
        <StyledTabHeader>
          <Ct.Text
            text={intl.formatMessage({ id: "edit-user.user" })}
            textStyle={{
              color: "white",
              textTransform: "uppercase",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 2.25,
            }}
          />
        </StyledTabHeader>
        <StyledTabContent>
          <Ct.LockableInput
            name="firstName"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "register.first-name" })}
            type="text"
            value={firstName}
            locked={
              permissionsOnEditedUser?.edit === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
            disabled={
              editStatus === "LOADING" ||
              permissionsOnEditedUser?.edit === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
          />
          <Ct.Spacer height={4} />
          <Ct.LockableInput
            name="lastName"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "register.last-name" })}
            type="text"
            value={lastName}
            locked={
              permissionsOnEditedUser?.edit === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
            disabled={
              editStatus === "LOADING" ||
              permissionsOnEditedUser?.edit === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
          />
          <Ct.Spacer height={4} />

          <Ct.LockableInput
            name="email"
            register={register as unknown as UseFormRegister<FieldValues>}
            label={intl.formatMessage({ id: "register.email" })}
            type="email"
            value={email}
            locked={
              permissionsOnEditedUser?.edit === "no" ||
              permissionsOnEditedUser?.edit_email === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
            disabled={
              editStatus === "LOADING" ||
              permissionsOnEditedUser?.edit === "no" ||
              permissionsOnEditedUser?.edit_email === "no" ||
              (permissionsOnEditedUser?.edit === "self" && !isSelfEditing)
            }
          />
          <Ct.Spacer height={4} />

          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                intl={intl}
                field={field}
                value={selectedOption}
                options={userOptionsList}
                label={"role"}
                domain={"edit-user"}
                optionType={"role"}
                disabled={
                  editStatus === "LOADING" ||
                  permissionsOnEditedUser?.change_role === "no" ||
                  isSelfEditing
                }
                // eslint-disable-next-line
                onChangeCallback={(e: any) => {
                  setCurrentRole(e.value)
                }}
              />
            )}
          />
        </StyledTabContent>
      </StyledTabContainer>

      <Ct.Spacer height={4} />
      <Ct.Button
        label={intl.formatMessage({ id: "edit-user.deactivate" })}
        colorType="Tertiary"
        width={42}
        disabled={
          editStatus === "LOADING" || permissionsOnEditedUser?.delete === "no"
        }
        onClick={() => {
          dispatch(deactivateUserThunk())
        }}
        colorScheme={{
          border: "amaranth",
          color: "amaranth",
          background: "white",
        }}
      />
    </UserInfo>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const FullWidthCard = styled((props) => <Ct.Card {...props} />)`
  width: calc(100vw - 41rem);
  @media (max-width: 1260px) {
    width: calc(100vw - 8rem);
    height: calc(100vh - 16rem);
  }
`

const StyledTabContent = styled.div`
  padding: 4.2rem 3rem 3rem;
  @media (max-width: 1260px) {
    padding: 4.2rem 1.5rem 3rem;
  }
`

const StyledTabContainer = styled.div`
  width: 100%;
  height: 52.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: left;
  background-color: ${colors.white};
  border-radius: 1.5rem;
  border: 0.25rem solid ${colors.cornflower};
  border-width: 0 0.25rem 0.25rem 0.25rem;
  box-sizing: border-box;
  box-shadow: ${boxShadow};
`

const StyledTabHeader = styled.div`
  position: relative;
  width: 100%;
  height: 6.5rem;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  background-color: ${colors.cornflower};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
`

const RemoveCompanyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  background-color: ${colors.amaranth};
  margin-left: 1rem;
`

const RemoveCompanyCross = styled(Cross)`
  height: 1rem;
  width: 1rem;

  & path {
    fill: ${colors.white};
  }
`

const Content = styled.div`
  @media screen and (max-width: 1300px) {
    padding-bottom: 2rem;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  display: flex;
  width: 100%;
`
const UserInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const UserAccess = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const CompaniesList = styled.section`
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 0px;
  max-height: 22rem;
  overflow-y: auto;

  /* FIREFOX */
  scrollbar-color: ${colors.cornflower} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.cornflower};
    border-radius: 3px;
    width: 4px;
  }
`

const MarginRowCenter = styled((props) => <Ct.SpacedBetween {...props} />)`
  padding: 1.5rem 0;
`

const AlertArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`
