import { Location } from "react-router-dom"

export const isRouteSelected = (
  location: Location,
  parentRoutePattern: string,
  path: string
) => {
  const regex = RegExp(`${parentRoutePattern}/${path}`)
  return location.pathname.match(regex) !== null
}
