import {
  createFilter,
  default as ReactSelect,
  GroupBase,
  OptionProps,
  Props,
  InputActionMeta,
} from "react-select"
import styled from "styled-components/macro"
import { IntlShape } from "react-intl"
import { useState } from "react"
import { colors } from "../../styles/design.config"
import { FixedSizeList as List } from "react-window"
import { RowCheckbox } from "./RowCheckbox"
import { MouseEvent } from "react"

export type Option<T extends string> = {
  value: T | "all"
  label: string
  disabled?: boolean
}

const MenuList = (
  props: OptionProps<Option<string>, true, GroupBase<Option<string>>>
) => {
  const height = 20
  const { options, getValue } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  delete props.innerProps.onMouseMove
  delete props.innerProps.onMouseOver

  return (
    <List
      height={30}
      itemCount={1}
      itemSize={20}
      initialScrollOffset={initialOffset}
      width={"100%"}
    >
      {({ index }) => (
        <StyledDiv
          isOptionAll={props.data.value === "all"}
          optionAllDisabled={
            props.data.value === "all" && props.data.disabled ? true : false
          }
        >
          <RowCheckbox
            disabled={props.data.disabled}
            label={props.label}
            name={props.label}
            isChecked={props.isSelected}
            onChange={(e: MouseEvent<HTMLDivElement>) => {
              if (props.data.disabled) {
                return
              }
              props.innerProps?.onClick?.(e)
            }}
          />
        </StyledDiv>
      )}
    </List>
  )
}

interface StyledDivProps {
  isOptionAll: boolean
  optionAllDisabled: boolean
}
const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0.5rem 1rem;

  & span {
    font-weight: ${(props) => (props.isOptionAll ? "700" : "400")};
    border-bottom: ${(props) =>
      props.optionAllDisabled
        ? "none"
        : props.isOptionAll
        ? `1px solid ${colors.cornflower}`
        : "none"};
  }

  & :hover {
    background-color: ${(props) =>
      props.optionAllDisabled ? colors.white : colors.lavender};
  }
`

export interface MultiSelectProps {
  options: Array<Option<string>>
  value: ReadonlyArray<Option<string>> | null
  field: Props<Option<string>, true, GroupBase<Option<string>>>
  label: string
  domain: string
  optionType: string
  disabled?: boolean
  onChange?: (newValue: ReadonlyArray<Option<string>>) => void
  autoFocus?: boolean
  intl: IntlShape
  canToggleAllOptions?: boolean
  allOptionDisabled?: boolean
  placeHolder?: string
  noOptionsCustom?: string
  customMenuWidth?: string
  renderValues?: boolean
}

export function MultiSelect(props: MultiSelectProps) {
  const {
    options,
    value,
    field,
    label,
    domain,
    optionType,
    disabled,
    onChange,
    intl,
    canToggleAllOptions,
    allOptionDisabled = false,
    placeHolder,
    noOptionsCustom,
    customMenuWidth,
    renderValues,
  } = props
  const [selectAll, setSelectAll] = useState(false)
  const [input, setInput] = useState("")

  const isValue: boolean = value ? value.length > 0 : false

  const selectAllOption: { value: "all"; label: string; disabled?: boolean } = {
    label: intl.formatMessage({ id: "accounting-plan.select-all-option" }),
    value: "all",
    disabled: allOptionDisabled,
  }
  const allOptions =
    options.length > 1 && canToggleAllOptions
      ? [selectAllOption, ...options]
      : options

  const CustomPlaceholder = () => (
    <>
      {placeHolder && (
        <StyledPlaceHolder color={disabled ? "rock" : "navy"}>
          {placeHolder}
        </StyledPlaceHolder>
      )}
    </>
  )

  const onInputChange = (value: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === "input-change") setInput(value)
    else if (actionMeta.action === "menu-close") setInput("")
  }

  return (
    <StyledSelect
      value={value}
      disabled={disabled || false}
      isValue={isValue}
      customMenuWidth={customMenuWidth}
    >
      <ReactSelect
        inputValue={input}
        onInputChange={onInputChange}
        controlShouldRenderValue={!renderValues ? renderValues : true}
        {...field}
        isMulti
        isDisabled={disabled}
        options={allOptions}
        value={value}
        filterOption={createFilter({ ignoreAccents: false })}
        onKeyDown={() => {
          return false
        }}
        components={{
          Placeholder: CustomPlaceholder,
          Option: MenuList,
        }}
        styles={{
          option: (defaultOptions, selectState) => ({
            ...defaultOptions,
            backgroundColor: selectState.isSelected
              ? colors.mist
              : colors.white,
          }),
          control: (defaultOptions) => ({
            ...defaultOptions,
            backgroundColor: colors.white,
            borderRadius: "0.75rem",
          }),
        }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        noOptionsMessage={() =>
          noOptionsCustom ? noOptionsCustom : "Aucune société"
        }
        onChange={(selectedValues, actionMeta) => {
          if (!onChange) {
            return
          }

          if (actionMeta.option?.value === "all") {
            if (!selectAll) {
              setSelectAll(true)
              onChange([...allOptions.filter((o) => o.value !== "all")])
            } else {
              setSelectAll(false)
              onChange([])
            }
          } else {
            setSelectAll(false)
            onChange(selectedValues)
          }
        }}
      />
      <label htmlFor={label}>
        {intl.formatMessage({ id: `${domain}.${optionType}` })}
      </label>
    </StyledSelect>
  )
}

interface Selector {
  value: ReadonlyArray<unknown> | null
  disabled: boolean
  isValue: boolean
  customMenuWidth?: string
}

// label always top, otherwise input research not updating
const StyledSelect = styled.div<Selector>`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  font-family: "Roboto", sans-serif;
  border-radius: 0.75rem;

  & label {
    position: absolute;
    left: 1.25rem;
    top: -0.75rem;
    transition: top 0.15s ease-in-out, color 0.5s ease-in-out;
    background-color: ${colors.white};
    color: ${({ disabled }) => (disabled ? colors.hawkes : colors.rock)};
    font-size: 1.5rem;
    padding: 0 0.5rem;
    pointer-events: none;
    user-select: none;
  }

  & [class*="container"] {
    flex-grow: 1;
  }

  & [class*="ValueContainer"] {
    overflow-y: auto;
  }

  & div > [class*="control"] {
    flex-grow: 1;
    box-shadow: 0 0 0 0;
    color: ${colors.navy};
    height: 6rem;
    transition: border 0.3s ease-in-out;
    outline: none;
    border-radius: 0.75rem;
    font-size: 1.75rem;
    width: 100%;
    border: ${({ disabled }) =>
      disabled ? `1px solid ${colors.hawkes}` : `1px solid ${colors.rock}`};
    box-sizing: border-box;
  }

  & div > [class*="control"] > div:first-of-type {
    height: 100%;
    width: 100%;
    display: flex;

    :before {
      content: ${({ disabled }) =>
        disabled ? "" : "Sélectionner une ou plusieurs société(s)"};
      color: ${({ isValue }) => (isValue ? colors.white : colors.rock)};
      display: ${({ isValue }) => (isValue ? "none" : "inline")};
      padding-left: 5px;
    }
  }

  & div > [class*="control"] > div:first-of-type > [class*="multiValue"] {
    background-color: ${colors.cornflower};
    border-radius: 8px;
    height: 22px;
    color: ${colors.white};
    align-items: center;
    font-size: 12px;
    & div {
      font-size: 12px;
      color: ${colors.white};
    }
  }

  & div > [class*="IndicatorsContainer"] {
    flex-grow: 0;
  }

  & [class*="indicatorContainer"]:hover {
    color: ${colors.cornflower};
    cursor: pointer;
  }

  & div > [class*="indicatorSeparator"] {
    display: none;
  }

  & div > [class*="Input"]:focus + label {
    top: -0.75rem;
  }

  & div > [class*="option"] {
    padding: 10px 12px;
    cursor: pointer;
    transition: 0.4s background-color ease-in-out;
    :active {
      background-color: ${colors.mist};
    }
  }

  & div > [class*="menu"] {
    right: 0;
    width: ${({ customMenuWidth }) =>
      customMenuWidth ? customMenuWidth : "500px"};
    z-index: 2;
  }

  & div > [class*="MenuList"] {
    padding: 1rem;
  }
`

interface PlaceHolderProps {
  color: keyof typeof colors
}

const StyledPlaceHolder = styled.div<PlaceHolderProps>`
  color: ${({ color }) => colors[color]};
`
