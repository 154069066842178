import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { Option, Select, emptyOptionValue } from "../Commons/Select"
import {
  AccountRulesWithoutDocument,
  BankLineType,
  CompareAmountType,
  CreateOrEditRuleReset,
  EditRuleThunk,
  NewRuleParams,
  createRuleThunk,
} from "../../store/ducks/bank.ducks"
import { Input } from "../Commons/Input"
import { useEffect, useState } from "react"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../store/rootReducer"

/* eslint-disable camelcase */

interface RuleWithoutDocumentCreateEditPartProps {
  isDisplayed: boolean
  onClose: () => void
  createOrEdit: "create" | "edit"
  ruleToEdit: AccountRulesWithoutDocument | null
  width?: string
}

export const RuleWithoutDocumentCreateEditPart = ({
  isDisplayed,
  onClose,
  createOrEdit,
  ruleToEdit,
  width,
}: RuleWithoutDocumentCreateEditPartProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const accountID = getIdFromParams(useParams())("account_id")

  const createOrEditRuleStatus = useRNBSelector(
    (state) => state.bank.createOrEditRuleStatus
  )

  const inOutOptions: Array<Option<string>> = [
    { value: "out", label: "Sortie" },
    { value: "in", label: "Entrée" },
  ]
  const compareAmountOptions: Array<Option<string>> = [
    emptyOptionValue,
    { value: "greater", label: "Supérieur" },
    { value: "lesser", label: "Inférieur" },
    { value: "equal", label: "Egal" },
  ]

  const [textInDescription, SetTextInDescription] = useState<string>("")
  const [inOut, setInOut] = useState<Option<string>>(inOutOptions[0])
  const [compareAmount, setCompareAmount] =
    useState<Option<string>>(emptyOptionValue)
  const [amount, setAmount] = useState<string>("")

  const checkIfRuleChanged = () => {
    if (createOrEdit === "create") return false
    if (ruleToEdit === null) return false

    const check =
      textInDescription === ruleToEdit?.text_in_description &&
      amount === (ruleToEdit.amount ? String(ruleToEdit.amount) : "") &&
      inOut.value === ruleToEdit.bank_line_type &&
      compareAmount.value ===
        (ruleToEdit.compare_amount ? ruleToEdit.compare_amount : "")

    return check
  }

  const reset = () => {
    dispatch(CreateOrEditRuleReset())
    SetTextInDescription("")
    setAmount("")
    setInOut(inOutOptions[0])
    setCompareAmount(emptyOptionValue)
  }

  const saveRule = () => {
    if (accountID) {
      const newRule: NewRuleParams = {
        account_id: null,
        amount: amount === "" ? null : Number(amount),
        bank_line_type: inOut.value as BankLineType,
        compare_amount:
          compareAmount.value === ""
            ? null
            : (compareAmount.value as CompareAmountType),
        merchant_code_id: null,
        prefix: null,
        priority_number: null,
        text_in_description:
          textInDescription === "" ? null : textInDescription,
        vat_rate_id: null,
      }

      if (createOrEdit === "create") {
        dispatch(createRuleThunk(newRule, accountID, "rule_without_document"))
      } else if (ruleToEdit) {
        dispatch(EditRuleThunk(newRule, accountID, ruleToEdit?.id, true))
      }
    }
  }

  useEffect(() => {
    if (ruleToEdit && createOrEdit === "edit") {
      SetTextInDescription(ruleToEdit.text_in_description || "")
      setAmount(
        ruleToEdit.amount !== null && ruleToEdit.amount !== undefined
          ? String(ruleToEdit.amount)
          : ""
      )
      setCompareAmount(
        ruleToEdit.compare_amount
          ? compareAmountOptions.find(
              (o) => o.value === ruleToEdit.compare_amount
            ) || emptyOptionValue
          : emptyOptionValue
      )
      setInOut(
        inOutOptions.find((o) => o.value === ruleToEdit.bank_line_type) ||
          inOutOptions[0]
      )
    }
  }, [ruleToEdit])

  useEffect(() => {
    if (createOrEditRuleStatus === "SUCCESS") {
      onClose()
      reset()
    }
  }, [createOrEditRuleStatus])

  return (
    <Wrapper width={width}>
      <Trigger>
        <Ct.Text
          text={intl.formatMessage({
            id: "bank-management.rules.add-rule.trigger",
          })}
          textStyle={{
            fontWeight: 700,
            fontSize: 2,
            textTransform: "uppercase",
          }}
        />
        <Ct.Spacer height={2} />

        <Input
          label={intl.formatMessage({
            id: "bank-management.rules.add-rule.text-in-description",
          })}
          value={textInDescription}
          onChange={(e) => {
            SetTextInDescription(e.target.value)
          }}
        />

        <Ct.Spacer height={4} />

        <Ct.Row>
          <Select
            intl={intl}
            options={inOutOptions}
            value={inOut}
            onChangeCallback={(e: Ct.Option<string>) => {
              setInOut(e)
            }}
            label={""}
            domain={"bank-management.rules.add-rule"}
            optionType={"in-out"}
            defaultValue={inOutOptions[0]}
          />

          <Ct.Spacer width={2} />

          <Select
            intl={intl}
            options={compareAmountOptions}
            value={compareAmount}
            onChangeCallback={(e: Ct.Option<string>) => {
              setCompareAmount(e)
            }}
            label={""}
            domain={"bank-management.rules.add-rule"}
            optionType={"compare-amount"}
            defaultValue={compareAmountOptions[0]}
            warning={compareAmount.value === "" && amount !== ""}
          />

          <Ct.Spacer width={2} />

          <Input
            label={intl.formatMessage({
              id: "bank-management.rules.add-rule.amount",
            })}
            value={amount}
            showWarning={compareAmount?.value !== "" && amount === ""}
            onChange={(e) => {
              setAmount(e.target.value.replace(/\D/g, ""))
            }}
          />
        </Ct.Row>

        <ErrorBlock>
          <Ct.Spacer width={2} />
          <ErrorDisplay>
            {compareAmount?.value === "" && amount !== "" ? (
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.rules.add-rule.amount.no-compare`,
                })}
                textStyle={{
                  color: "orange",
                  fontSize: 1.75,
                }}
              />
            ) : (
              <Ct.Spacer width={2} />
            )}
          </ErrorDisplay>
          <ErrorDisplay>
            {compareAmount.value !== "" && amount === "" ? (
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.rules.add-rule.amount.no-amount`,
                })}
                textStyle={{
                  color: "orange",
                  fontSize: 1.75,
                }}
              />
            ) : (
              <Ct.Spacer width={2} />
            )}
          </ErrorDisplay>
        </ErrorBlock>

        <Ct.Spacer height={2} />
        <ButtonCenter>
          <Ct.Button
            label={intl.formatMessage({
              id: `bank-management.rules.${createOrEdit}-rule.cta`,
            })}
            width={40}
            onClick={() => {
              saveRule()
            }}
            disabled={checkIfRuleChanged()}
          />
        </ButtonCenter>
      </Trigger>
    </Wrapper>
  )
}

interface WrapperProps {
  width?: string
}

const Wrapper = styled.div<WrapperProps>`
  align-items: flex-start;
  width: ${({ width }) => (width ? width : "80%")};
  margin: 0 auto;
`
const Trigger = styled.div``

const ErrorDisplay = styled.div`
  display: flex;
  justify-content: center;
`
const ErrorBlock = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonCenter = styled.div`
  display: flex;
  justify-content: center;
`
