import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"

import { colors } from "../../styles/design.config"
import {
  changeMerchant,
  getInputMaskMerchantImpactedDocuments,
} from "../../store/ducks/inputMask.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Option, Select } from "../Commons/Select"
import { capitalizeFirstLetter } from "../../utils/string"
import { ReactComponent as Edit } from "../../assets/buttons/edit.svg"
import { ReactComponent as Lock } from "../../assets/lock.svg"
import { ReactComponent as Plus } from "../../assets/smallPlus.svg"

import { getIdFromParams } from "../../utils/company"
import { MerchantRenameModal } from "./MerchantRenameModal"
import { MerchantCreateModal } from "./MerchantCreateModal"

export const MerchantSelectCell = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [isRenameModalDisplayed, setIsRenameModalDisplayed] = useState(false)
  const [isCreateMerchantModalDisplayed, setIsCreateMerchantModalDisplayed] =
    useState(false)
  const [options, setOptions] = useState<Option<string>[]>([])

  const {
    isCurrentDocumentBeingReactivated,
    merchantsOfCompany,
    editedFullDocument,
    initialFullDocument,
  } = useRNBSelector((state) => {
    const editedFullDocument = state.inputMask.edited_full_document
    const deactivatedFullDocuments = state.inputMask.deactivated_full_documents

    const isCurrentDocumentBeingReactivated =
      deactivatedFullDocuments.find(
        (d) => d.fd_id === editedFullDocument?.fd_id
      )?.is_being_reactivated === true

    return {
      merchantCodes: state.merchantCodes.merchantCodes,
      merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
      initialFullDocument: state.inputMask.initial_full_document,
      editedFullDocument,
      isCurrentDocumentBeingReactivated,
    }
  })

  const selectedOption =
    options.find((o) => o.value === String(editedFullDocument?.merchant_id)) ||
    null

  useEffect(() => {
    setOptions(
      merchantsOfCompany.map((m) => {
        return {
          value: String(m.id),
          label: m.code
            ? intl.formatMessage(
                {
                  id: "input-mask.merchant-and-code.label",
                },
                {
                  merchantName: capitalizeFirstLetter(m.name),
                  merchantCode: m.code,
                }
              )
            : capitalizeFirstLetter(m.name),
        }
      })
    )
  }, [merchantsOfCompany])

  const isLocked =
    !isCurrentDocumentBeingReactivated &&
    initialFullDocument?.merchant_code_id !==
      editedFullDocument?.merchant_code_id

  if (isLocked) {
    return (
      <LockedCellWrapper>
        {capitalizeFirstLetter(editedFullDocument?.merchant_name || "")}
        <StyledLock />
      </LockedCellWrapper>
    )
  }

  return (
    <>
      {Boolean(editedFullDocument?.merchant_id) && (
        <MerchantRenameModal
          isDisplayed={isRenameModalDisplayed}
          onClose={() => {
            setIsRenameModalDisplayed(false)
          }}
          merchantId={editedFullDocument?.merchant_id || 0}
          buyOrSell={editedFullDocument?.buy_or_sell || "buy"}
        />
      )}
      <MerchantCreateModal
        isDisplayed={isCreateMerchantModalDisplayed}
        onClose={() => {
          setIsCreateMerchantModalDisplayed(false)
        }}
        buyOrSell={editedFullDocument?.buy_or_sell || "buy"}
        companyId={selectedCompanyId}
      />

      {isCurrentDocumentBeingReactivated && !selectedOption ? (
        <FlexCenter>
          <StyledCenterPlus
            onClick={() => {
              setIsCreateMerchantModalDisplayed(true)
            }}
          />
        </FlexCenter>
      ) : (
        <AbsoluteWrapper>
          <StyledSelect
            height="8rem"
            intl={null}
            value={selectedOption}
            defaultValue={null}
            options={options}
            onChangeCallback={(e) => {
              if (!editedFullDocument) {
                return
              }
              const merchant = merchantsOfCompany.find(
                (m) => m.id === Number(e.value)
              )
              dispatch(
                changeMerchant({
                  merchantId: Number(e.value),
                  merchantName: merchant?.name || "",
                  merchantCode: merchant?.code || null,
                  merchantCodeId: merchant?.code_id || null,
                })
              )
              dispatch(
                getInputMaskMerchantImpactedDocuments({
                  companyId: selectedCompanyId,
                  legalEntityId: editedFullDocument.legal_entity_id,
                })
              )
            }}
            border="none"
            borderRadius="0"
            hasTwoButton={true}
          />
          <StyledEdit
            onClick={() => {
              setIsRenameModalDisplayed(true)
            }}
          />
          <StyledPlus
            onClick={() => {
              setIsCreateMerchantModalDisplayed(true)
            }}
          />
        </AbsoluteWrapper>
      )}
    </>
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
`
const StyledEdit = styled(Edit)`
  width: 2rem;
  height: 2rem;
  top: 3rem;
  right: 3rem;

  position: absolute;
  cursor: pointer;
  fill: ${colors.rock};
  transition: 0.5s fill ease-in-out;
  &:hover {
    fill: ${colors.cornflower};
  }
`
const StyledPlus = styled(Plus)`
  width: 1.6rem;
  height: 1.6rem;
  top: 3.2rem;
  right: 1rem;
  position: absolute;
  cursor: pointer;
  fill: ${colors.rock};
  transition: 0.5s fill ease-in-out;
  &:hover {
    fill: ${colors.cornflower};
  }
`

const AbsoluteWrapper = styled.div`
  position: relative;
  width: 100%;
`

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const LockedCellWrapper = styled.div`
  position: relative;
  padding-right: 4rem;
`

const StyledLock = styled(Lock)`
  position: absolute;
  top: calc(50% - 1rem);
  right: 1rem;
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
  fill: ${colors.purple};
`
const StyledCenterPlus = styled(Plus)`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  fill: ${colors.rock};
  transition: 0.5s fill ease-in-out;
  &:hover {
    fill: ${colors.cornflower};
  }
`
