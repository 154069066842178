import { IntlShape } from "react-intl"
import {
  InvoiceBlock,
  InvoicePayload,
  LegalEntityInfo,
  LegalEntityWithoutInfos,
  ProductFormatted,
  Units,
} from "../store/ducks/invoicing.duck"
import { VatRate } from "../store/ducks/vatRates.ducks"
import { Indexed } from "./asyncTools"
import { CompanyLegalForm } from "./company"
import { ProductsWithPrice } from "./invoices"

export function getPriceIncludedTax({
  price,
  rate,
  quantity,
  productDiscount,
}: {
  price: number
  rate: number
  quantity: number
  productDiscount: number
}): string {
  const discountMultiplier = (100 - productDiscount) / 100
  const priceWithoutTaxes = discountMultiplier * price

  const taxes = priceWithoutTaxes * (rate / 100)
  return ((priceWithoutTaxes + taxes) * quantity).toFixed(2)
}

export const sortLegalEntitiesAlphabetically = (
  legalEntities: (LegalEntityInfo | LegalEntityWithoutInfos)[],
  asc: boolean
) => {
  return legalEntities.sort((a, b) =>
    asc ? a?.name.localeCompare(b?.name) : b?.name.localeCompare(a?.name)
  )
}

export const sortLegalEntitiesBySiren = (
  legalEntities: (LegalEntityInfo | LegalEntityWithoutInfos)[],
  asc: boolean
) => {
  const companies = legalEntities.filter(
    (l) => l.identification_number !== null
  )
  const privatePersons = legalEntities.filter(
    (l) => l.identification_number === null
  )

  const sortedCompanies = companies.sort((a, b) =>
    asc
      ? trimNumber(Number(a?.identification_number) || 0) -
        trimNumber(Number(b?.identification_number) || 0)
      : trimNumber(Number(b?.identification_number) || 0) -
        trimNumber(Number(a?.identification_number) || 0)
  )

  return asc
    ? [
        ...sortedCompanies,
        ...sortLegalEntitiesAlphabetically(privatePersons, asc),
      ]
    : [
        ...sortedCompanies,
        ...sortLegalEntitiesAlphabetically(privatePersons, !asc),
      ]
}

export const trimNumber = (siren: number) => {
  const stringedSiren = String(siren).replace(/\s/g, "")
  return Number(stringedSiren)
}

export const isLegalEntityInfos = (
  legalEntityLine: LegalEntityInfo | LegalEntityWithoutInfos
): legalEntityLine is LegalEntityInfo => {
  return (legalEntityLine as LegalEntityInfo).typology !== undefined
}

export const isLegalEntityWithoutInfos = (
  legalEntityLine: LegalEntityInfo | LegalEntityWithoutInfos
): legalEntityLine is LegalEntityInfo => {
  return (legalEntityLine as LegalEntityInfo).typology === undefined
}

export const sortLegalEntitiesByTypology = (
  legalEntities: (LegalEntityInfo | LegalEntityWithoutInfos)[],
  asc: boolean
) => {
  const withoutInfos = legalEntities.filter(isLegalEntityWithoutInfos)
  const withInfos = legalEntities.filter(isLegalEntityInfos)
  return [
    ...withInfos.sort((a, b) =>
      asc
        ? a?.typology.localeCompare(b?.typology)
        : b?.typology.localeCompare(a?.typology)
    ),
    ...withoutInfos,
  ]
}

export function isLegalEntityInfo(
  le: LegalEntityInfo | LegalEntityWithoutInfos
): le is LegalEntityInfo {
  return (le as LegalEntityInfo).total_discount !== undefined
}

export const sortLegalEntitiesByTotalDiscount = (
  legalEntities: (LegalEntityInfo | LegalEntityWithoutInfos)[],
  asc: boolean
) => {
  return legalEntities.sort((a, b) => {
    const bTotalDiscount = isLegalEntityInfo(b) ? b?.total_discount : -1
    const aTotalDiscount = isLegalEntityInfo(a) ? a?.total_discount : -1
    return asc
      ? bTotalDiscount - aTotalDiscount
      : aTotalDiscount - bTotalDiscount
  })
}

export const sortProductByName = (
  products: ProductFormatted[],
  asc: boolean
) => {
  return products.sort((a, b) =>
    asc
      ? a.productName?.localeCompare(b.productName)
      : b.productName?.localeCompare(a.productName)
  )
}

export const sortProductByDescription = (
  products: ProductFormatted[],
  asc: boolean
) => {
  return products.sort((a, b) =>
    asc
      ? a.productDescription?.localeCompare(b.productDescription)
      : b.productDescription?.localeCompare(a.productDescription)
  )
}

export const sortProductByUnit = (
  products: ProductFormatted[],
  asc: boolean,
  unitsRate: { [index: number]: Units }
) => {
  return products.sort((a, b) =>
    asc
      ? unitsRate[a.unitId].unit?.localeCompare(unitsRate[b.unitId].unit)
      : unitsRate[b.unitId].unit?.localeCompare(unitsRate[a.unitId].unit)
  )
}

export const sortProductByPrice = (
  products: ProductFormatted[],
  asc: boolean
) => {
  return products.sort((a, b) =>
    asc
      ? Number(a.unitPrice) - Number(b.unitPrice)
      : Number(b.unitPrice) - Number(a.unitPrice)
  )
}

export const sortProductByPriceTaxIncluded = (
  products: ProductFormatted[],
  asc: boolean,
  vatRates: {
    [index: number]: VatRate
  }
) => {
  return products.sort((a, b) => {
    const priceA =
      Number(a.unitPrice) *
      (1 +
        (vatRates[a.vatRateId]?.rate
          ? Number(vatRates[a.vatRateId]?.rate)
          : 0) /
          100)
    const priceB =
      Number(b.unitPrice) *
      (1 +
        (vatRates[b.vatRateId]?.rate
          ? Number(vatRates[b.vatRateId]?.rate)
          : 0) /
          100)

    return asc ? priceA - priceB : priceB - priceA
  })
}

export const sortProductByVatRates = (
  products: ProductFormatted[],
  asc: boolean
) => {
  return products.sort((a, b) => (asc ? a.rate - b.rate : b.rate - a.rate))
}

export const sortInvoicesByNumber = (
  invoices: InvoiceBlock[],
  asc: boolean
) => {
  return invoices.sort((a, b) =>
    asc
      ? b.invoices[0].numbering?.localeCompare(a.invoices[0].numbering)
      : a.invoices[0].numbering?.localeCompare(b.invoices[0].numbering)
  )
}

export const sortInvoicesByClient = (
  invoices: InvoiceBlock[],
  asc: boolean
) => {
  return invoices.sort((a, b) =>
    asc
      ? b.invoices[0].client?.localeCompare(a.invoices[0].client)
      : a.invoices[0].client?.localeCompare(b.invoices[0].client)
  )
}

export const sortInvoicesByEmailSentTo = (
  invoices: InvoiceBlock[],
  asc: boolean
) => {
  const result = invoices.sort((a, b) => {
    const emailA = a.invoices[0].emailedTo[0] || ""
    const emailB = b.invoices[0].emailedTo[0] || ""
    return asc ? emailA.localeCompare(emailB) : emailB.localeCompare(emailA)
  })

  return result
}

export const sortInvoicesByIssueBy = (
  invoices: InvoiceBlock[],
  asc: boolean
) => {
  return invoices.sort((a, b) =>
    asc
      ? b.invoices[0].issueBy?.localeCompare(a.invoices[0].issueBy)
      : a.invoices[0].issueBy?.localeCompare(b.invoices[0].issueBy)
  )
}

export const sortInvoicesByAmount = (
  invoices: InvoiceBlock[],
  asc: boolean
) => {
  return invoices.sort((a, b) =>
    asc
      ? parseInt(b.invoices[0].amount) - parseInt(a.invoices[0].amount)
      : parseInt(a.invoices[0].amount) - parseInt(b.invoices[0].amount)
  )
}

const getTimestampFromText = (fullDateText: string): number => {
  const dateString = fullDateText.split(" - ")[0]
  const dateParts = dateString.split("/")

  // month is 0-based, that's why we need dataParts[1] - 1
  const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])

  return dateObject.getTime()
}

export const findMostRecentInvoice = (
  invoiceBlock: InvoiceBlock
): InvoicePayload => {
  return invoiceBlock.invoices.sort((a, b) => {
    const aDate = getTimestampFromText(a.date)
    const bDate = getTimestampFromText(b.date)
    return bDate - aDate
  })[0]
}

export const sortInvoicesByDate = (invoices: InvoiceBlock[], asc: boolean) => {
  return invoices.sort((a, b) => {
    const aMostRecentInvoice = findMostRecentInvoice(a)
    const bMostRecentInvoice = findMostRecentInvoice(b)

    const aDate = getTimestampFromText(aMostRecentInvoice.date)
    const bDate = getTimestampFromText(bMostRecentInvoice.date)

    return asc ? bDate - aDate : aDate - bDate
  })
}

export const sortProductBySearch = (
  products: ProductFormatted[],
  search: string
) => {
  return Object.values(products).filter((product) =>
    product.productName?.toLowerCase().includes(search.toLowerCase())
  )
}

export const sortClientsBySearch = (
  legalEntities: (LegalEntityInfo | LegalEntityWithoutInfos)[],
  search: string
) => {
  return [
    ...legalEntities.filter(
      (entity) =>
        entity.name.toLowerCase().includes(search.toLowerCase()) ||
        entity.identification_number?.toString().includes(search)
    ),
  ]
}

export const isColoredLine = (
  line: InvoicePayload,
  invoices: Indexed<InvoiceBlock>
) => {
  if (line.documentType === "" || line.overtakingId) {
    return true
  }

  const isExtended = Object.values(invoices).some(
    (b: InvoiceBlock) =>
      b.status === "extended" && b.invoices.some((i) => i.id === line.id)
  )
  return isExtended
}

export const getTotalTaxExcluded = ({
  productsWithPrice,
  totalDiscount,
}: {
  productsWithPrice: ProductsWithPrice[]
  totalDiscount: number
}) => {
  const totalDiscountMultiplier = (100 - totalDiscount) / 100
  const totalNumber = productsWithPrice.reduce((total, product) => {
    const productDiscountMultiplier = (100 - product.productDiscount) / 100
    const productPrice =
      Number((product.price * productDiscountMultiplier).toFixed(2)) *
      product.quantity

    return total + productPrice * totalDiscountMultiplier
  }, 0)

  return totalNumber.toFixed(2).replace(",00", "").replace(".", ",") + "€"
}

export const getTotalTax = ({
  productsWithPrice,
  totalDiscount,
}: {
  productsWithPrice: ProductsWithPrice[]
  totalDiscount: number
}) => {
  const totalDiscountMultiplier = (100 - totalDiscount) / 100

  const totalNumber = productsWithPrice.reduce((total, product) => {
    const productDiscountMultiplier = (100 - product.productDiscount) / 100
    const productPrice = product.price * productDiscountMultiplier

    return (
      total +
      ((productPrice *
        product.quantity *
        totalDiscountMultiplier *
        Number(product.vatRate)) /
        100) *
        product.quantity
    )
  }, 0)

  return totalNumber.toFixed(2).replace(",00", "").replace(".", ",") + "€"
}

export const getTotalTaxIncluded = ({
  productsWithPrice,
  totalDiscount,
}: {
  productsWithPrice: ProductsWithPrice[]
  totalDiscount: number
}) => {
  const totalDiscountMultiplier = (100 - totalDiscount) / 100

  const totalNumber = productsWithPrice.reduce((total, product) => {
    const productDiscountMultiplier = (100 - product.productDiscount) / 100
    const productPrice = product.price * productDiscountMultiplier

    return (
      total +
      totalDiscountMultiplier *
        (((productPrice * Number(product.vatRate)) / 100) * product.quantity +
          productPrice * product.quantity)
    )
  }, 0)

  return totalNumber.toFixed(2).replace(",00", "").replace(".", ",") + "€"
}

export const getLegalNotice = ({
  companyName,
  legalForm,
  shareCapital,
  siren,
  useCityRegistrationRCS,
  cityRegistrationRCS,
  intl,
}: {
  companyName: string
  legalForm: CompanyLegalForm | ""
  shareCapital: number | null
  siren: number
  useCityRegistrationRCS: boolean
  cityRegistrationRCS: string
  intl: IntlShape
}) => {
  if (!legalForm) {
    return ""
  }
  const showShareCapital = Boolean(shareCapital)
  const showRCS = Boolean(useCityRegistrationRCS && cityRegistrationRCS)

  return intl.formatMessage(
    {
      id: `issuance.legal-notice.RCS.${showRCS}.shareCapital.${showShareCapital}`,
    },
    {
      companyName,
      legalForm,
      shareCapital,
      siren,
      cityRegistrationRCS,
    }
  )
}
