import * as Ct from "ldlj"
import { Fragment, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as FinanceBuilding } from "../../../../assets/finance-building.svg"
import { ReactComponent as ReturnIcon } from "../../../../assets/left-chevron.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { ReactComponent as TrashCan } from "../../../../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Warning } from "../../../../assets/warning.svg"
import {
  AccountsResource,
  deactivateAccountReset,
  deactivateItemReset,
  getAccountsByBankThunk,
  getBankAccountsForCompanyThunk,
  syncErrorThunk,
} from "../../../../store/ducks/bank.ducks"
import { getCurrentUserThunk } from "../../../../store/ducks/user.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { getIdFromParams } from "../../../../utils/company"
import {
  DeleteBankOrAccount,
  DisplayDeletionModalParams,
} from "../../../../components/bank/DeleteBankOrAccount"
import { colors } from "../../../../styles/design.config"
import { SyncBankinTransactionsThunk } from "../../../../store/ducks/bankArchives.ducks"
import { accountIsSet, redirectionsAccount } from "../../../../utils/bank"
import {
  capitalizeFirstLetter,
  croppedEndString,
} from "../../../../utils/string"
import { Button } from "../../../../components/Commons/Button"

/* eslint-disable camelcase */

export const Bank = () => {
  const userTypology = useRNBSelector((state) => state.user.typology)
  const navigate = useNavigate()
  const { fiduciaryName, fiduciaryId, pricingType, override_pricing } =
    useRNBSelector((state) => ({
      fiduciaryName: state.fiduciary.name,
      fiduciaryId: state.fiduciary.id,
      pricingType: state.fiduciary.active_pricing?.pricing_type,
      override_pricing: state.fiduciary.override_pricing,
    }))

  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  if (pricingType === "per_document" && !override_pricing) {
    navigate("/unauthorized")
  }

  const bankAccountID = getIdFromParams(useParams())("bank_id")
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const accountsState = useRNBSelector((state) => state.bank.accounts)
  const itemsState = useRNBSelector((state) => state.bank.items)
  const itemsStatus = useRNBSelector((state) => state.bank.itemsLoading)
  const deleteAccountStatus = useRNBSelector(
    (state) => state.bank.deactivateAccountStatus
  )
  const deactivateItemStatus = useRNBSelector(
    (state) => state.bank.deactivateItemStatus
  )
  const itemAccount = Object.values(itemsState).find(
    (item) => item.id === bankAccountID
  )
  const accountList = Object.values(accountsState).filter(
    (account) => account.bankin_item_id === bankAccountID
  )

  const [accounts, setAccounts] = useState<AccountsResource[]>([])

  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<DisplayDeletionModalParams>({
      isDisplayed: false,
      accountOrBank: "",
      accountId: null,
      bankId: null,
    })

  useEffect(() => {
    if (bankAccountID === null || (!itemAccount && itemsStatus === "LOADED"))
      navigate("/Unauthorized")
  }, [itemAccount, itemsStatus, bankAccountID, navigate])

  const bankLogo = itemAccount?.bank_name.charAt(0) || ""
  const getAccountsNumber = accounts.length
  const accountsNumberString =
    `${getAccountsNumber} compte` +
    (getAccountsNumber > 1 ? "s " : " ") +
    "connecté" +
    (getAccountsNumber > 1 ? "s " : " ")

  useEffect(() => {
    if (selectedCompanyId && bankAccountID) {
      dispatch(getBankAccountsForCompanyThunk(fiduciaryId, selectedCompanyId))
      dispatch(
        getAccountsByBankThunk(fiduciaryId, selectedCompanyId, bankAccountID)
      )
    }
  }, [dispatch, selectedCompanyId, bankAccountID, fiduciaryId])

  useEffect(() => {
    if (accountList.length > 0) {
      setAccounts(accountList)
    }
  }, [JSON.stringify(accountList), dispatch])

  const returnBanksView = () => {
    navigate(`/office/company/${selectedCompanyId}/bank/accounts/`)
  }

  useEffect(() => {
    if (
      deleteAccountStatus === "SUCCESS" &&
      selectedCompanyId &&
      bankAccountID
    ) {
      setDisplayDeletionModal({
        isDisplayed: false,
        accountOrBank: "",
        accountId: null,
        bankId: null,
      })
      dispatch(
        getAccountsByBankThunk(fiduciaryId, selectedCompanyId, bankAccountID)
      )
    }
  }, [deleteAccountStatus])

  useEffect(() => {
    if (deactivateItemStatus === "SUCCESS" && selectedCompanyId) {
      setDisplayDeletionModal({
        isDisplayed: false,
        accountOrBank: "",
        accountId: null,
        bankId: null,
      })
      dispatch(getBankAccountsForCompanyThunk(fiduciaryId, selectedCompanyId))
      returnBanksView()
    }
  }, [deactivateItemStatus])

  return (
    <Wrapper>
      <DeleteBankOrAccount
        onClose={() => {
          if (displayDeletionModal.accountOrBank === "banque") {
            dispatch(deactivateItemReset())
          } else {
            dispatch(deactivateAccountReset())
          }
          setDisplayDeletionModal({
            isDisplayed: false,
            accountOrBank: "",
            accountId: null,
            bankId: null,
          })
        }}
        isDisplayed={displayDeletionModal.isDisplayed}
        accountOrBank={displayDeletionModal.accountOrBank}
        accountId={displayDeletionModal.accountId}
        bankId={displayDeletionModal.bankId}
      />

      <Content>
        <Ct.Button
          label={"retour"}
          prefix={<ReturnIcon />}
          width={12.75}
          height={4}
          onClick={() => returnBanksView()}
        />
        <Ct.Title
          text={intl.formatMessage({ id: "bank-accounts.bank-account.title" })}
        />

        <AccountBlock>
          <ActiveBankWrapper>
            <Ct.Spacer />
            <RowBetween>
              <BankDetails>
                <LogoWrapper>
                  <Ct.Text
                    text={bankLogo}
                    textStyle={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontSize: 4,
                      color: "cornflower",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  />
                </LogoWrapper>
                <BankInformationsWrapper>
                  <BankName>{itemAccount?.bank_name}</BankName>
                  <Ct.Text
                    text={accountsNumberString}
                    textStyle={{
                      fontSize: 1.5,
                      color: "slateGrey",
                      fontWeight: 500,
                    }}
                  />
                </BankInformationsWrapper>
              </BankDetails>
              {userTypology !== "customer" && (
                <TrashWrapper
                  onClick={() => {
                    setDisplayDeletionModal({
                      isDisplayed: true,
                      accountOrBank: "banque",
                      accountId: null,
                      bankId: bankAccountID,
                    })
                  }}
                >
                  <TrashCan />
                </TrashWrapper>
              )}
            </RowBetween>

            {itemAccount &&
              itemAccount.status > 0 &&
              itemAccount.status !== 1100 && (
                <ErrorStatusWrapper>
                  <Ct.Spacer />

                  <TextWarning>
                    <StyledWarning />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "bank-accounts.error-bridge2",
                      })}
                      textStyle={{
                        color: "amaranth",
                        textAlign: "center",
                      }}
                    />
                  </TextWarning>

                  <Ct.Spacer height={1} />

                  <Ct.Text
                    text={intl.formatMessage(
                      { id: "bank-accounts.error-bridge-status" },
                      { bankinId: itemAccount.id, status: itemAccount.status }
                    )}
                    textStyle={{
                      color: "amaranth",
                      fontStyle: "italic",
                    }}
                  />

                  <Ct.Spacer />

                  <Button
                    label={intl.formatMessage({
                      id: "bank-accounts.error-bridge.reconnect",
                    })}
                    colorType="Tertiary"
                    colorScheme={{
                      background: "amaranth",
                      color: "white",
                      border: "amaranth",
                    }}
                    onClick={() => {
                      if (!selectedCompanyId) return
                      dispatch(
                        syncErrorThunk(
                          fiduciaryId,
                          selectedCompanyId,
                          itemAccount.id,
                          fiduciaryName
                        )
                      )
                    }}
                  />

                  <Ct.Spacer />
                </ErrorStatusWrapper>
              )}
            <Ct.Spacer />
          </ActiveBankWrapper>

          <Ct.Spacer height={4} />
          {accounts && accounts.length ? (
            <Ct.Text
              text={intl.formatMessage({ id: "bank-accounts.account-label" })}
              textStyle={{
                fontSize: 1.75,
                color: "navy",
              }}
            />
          ) : (
            <Ct.Text
              text={intl.formatMessage({
                id: "bank-accounts.no-accounts-linked",
              })}
              textStyle={{
                fontSize: 1.75,
                color: "navy",
                fontWeight: 500,
              }}
            />
          )}
          <Ct.Spacer height={4} />
          <BankAccountsWrapper>
            {accounts.map((bankaccount) => (
              <Fragment key={bankaccount.id}>
                <AccountStrip
                  data-cy={"bankAccountUnset"}
                  clickable={
                    !(userTypology === "customer" && !accountIsSet(bankaccount))
                  }
                  onClick={() => {
                    if (
                      selectedCompanyId &&
                      bankAccountID &&
                      !(
                        userTypology === "customer" &&
                        !accountIsSet(bankaccount)
                      )
                    ) {
                      dispatch(
                        SyncBankinTransactionsThunk(
                          bankaccount.id,
                          fiduciaryId,
                          selectedCompanyId,
                          bankaccount.bankin_item_id
                        )
                      )
                      navigate(
                        redirectionsAccount(
                          selectedCompanyId,
                          bankAccountID,
                          bankaccount
                        )
                      )
                    }
                  }}
                >
                  <Ct.Row>
                    <BankIconWrapper>
                      <StyledFinanceBuilding
                        disabled={
                          userTypology === "customer" &&
                          !accountIsSet(bankaccount)
                        }
                      />
                    </BankIconWrapper>
                    <Ct.Text
                      text={croppedEndString(bankaccount.name, 45)}
                      textStyle={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontSize: 1.75,
                        color:
                          userTypology === "customer" &&
                          !accountIsSet(bankaccount)
                            ? "moon"
                            : "navy",
                        fontWeight: 600,
                        cursor: !(
                          userTypology === "customer" &&
                          !accountIsSet(bankaccount)
                        )
                          ? "pointer"
                          : "default",
                      }}
                    />
                  </Ct.Row>

                  <AccountActions>
                    <ColoredCircle
                      color={
                        accountIsSet(bankaccount)
                          ? colors.shamrock
                          : colors.orange
                      }
                    />
                    <Ct.Spacer />
                    <StyledIsAccountSet
                      text={capitalizeFirstLetter(
                        intl.formatMessage(
                          {
                            id: "bank-accounts.is-set",
                          },
                          { isSet: accountIsSet(bankaccount) ? "" : "Non " }
                        )
                      )}
                      textStyle={{
                        fontStyle: "normal",
                        fontSize: 1.75,
                        color: accountIsSet(bankaccount)
                          ? "shamrock"
                          : "orange",
                        cursor: !(
                          userTypology === "customer" &&
                          !accountIsSet(bankaccount)
                        )
                          ? "pointer"
                          : "default",
                      }}
                    />

                    <Ct.Spacer width={accountIsSet(bankaccount) ? 7 : 4} />
                    {userTypology !== "customer" && (
                      <>
                        <span
                          onClick={() => {
                            if (selectedCompanyId && bankAccountID) {
                              dispatch(
                                SyncBankinTransactionsThunk(
                                  bankaccount.id,
                                  fiduciaryId,
                                  selectedCompanyId,
                                  bankaccount.bankin_item_id
                                )
                              )
                              navigate(
                                redirectionsAccount(
                                  selectedCompanyId,
                                  bankAccountID,
                                  bankaccount
                                )
                              )
                            }
                          }}
                        >
                          <StyledEdit />
                          <Ct.Spacer width={5} height={0} />
                        </span>

                        <span
                          onClick={(event) => {
                            event.stopPropagation() // need this to prevent redirection of the main div AccountStrip
                            setDisplayDeletionModal({
                              isDisplayed: true,
                              accountOrBank: "compte",
                              accountId: bankaccount.id,
                              bankId: bankAccountID,
                            })
                          }}
                        >
                          <StyledTrashCan />
                        </span>
                      </>
                    )}
                  </AccountActions>
                </AccountStrip>

                <Ct.Spacer height={2} />
              </Fragment>
            ))}

            {userTypology === "customer" &&
              accounts.some((a) => !accountIsSet(a)) && (
                <ErrorStatusWrapper>
                  <Ct.Spacer />

                  <TextWarning>
                    <StyledWarning color={colors.orange} />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "bank-accounts.customer.is-account-not-set",
                      })}
                      textStyle={{
                        color: "orange",
                        textAlign: "center",
                      }}
                    />
                  </TextWarning>
                </ErrorStatusWrapper>
              )}
          </BankAccountsWrapper>
        </AccountBlock>
      </Content>
    </Wrapper>
  )
}

const AccountBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 86.25rem;
  align-self: center;
  margin-top: 5rem;
`
const BankIconWrapper = styled.div`
  margin-left: 3rem;
  margin-right: 1rem;
`
const BankAccountsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const AccountStrip = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  width: 86.25rem;
  height: 7rem;
  box-shadow: 0px 5px 10px rgba(2, 76, 248, 0.15);
  border-radius: 1.25rem;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  :hover {
    transition: all 0.2s ease-in-out;
    box-shadow: ${(props) =>
      props.clickable && "0px 5px 15px rgba(2, 76, 248, 0.4)"};
  }
  background-color: ${(props) =>
    props.clickable ? colors.white : colors.desertStorm};
`
const Content = styled((props) => <Ct.Card {...props} />)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-right: 2rem;
  padding-bottom: 2rem;
  overflow: auto;
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
  box-sizing: border-box;
`
const StyledEdit = styled(({ ...props }) => <Edit {...props} />)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`

const StyledTrashCan = styled(TrashCan)`
  cursor: pointer;
`

const AccountActions = styled.div`
  display: flex;
  padding-right: 3rem;
`
const ActiveBankWrapper = styled.div`
  width: 83.25rem;
  box-shadow: 0px 5px 10px rgba(2, 76, 248, 0.15);
  border-radius: 1.25rem;
  align-self: center;
  padding-right: 3rem;
`
const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
const BankDetails = styled.div`
  display: flex;
  align-items: center;
`
const LogoWrapper = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  align-self: center;
  margin-left: 3rem;
  border-radius: 1rem;
  border: 1px solid ${colors.cornflower};
  display: flex;
  align-items: center;
  justify-content: center;
`
const BankInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`
const BankName = styled.h3`
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: ${colors.navy};
  text-transform: uppercase;
  line-height: 3rem;
`
const TrashWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  align-self: center;
`

const ColoredCircle = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: none;
  width: 2rem;
  height: 2rem;
`
const StyledIsAccountSet = styled(Ct.Text)`
  font-style: italic;
`

const ErrorStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledWarning = styled(({ ...props }) => <Warning {...props} />)<{
  color: string
}>`
  width: 5rem;
  height: 2rem;
  & path {
    fill: ${(props) => props.color};
  }
`

const TextWarning = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  width: 80%;
`

const StyledFinanceBuilding = styled(({ ...props }) => (
  <FinanceBuilding {...props} />
))<{ disabled: boolean }>`
  & path {
    fill: ${({ disabled }) =>
      disabled ? `${colors.moon}` : `${colors.cornflower}`};
  }
`
