import styled from "styled-components/macro"
import { IntlShape } from "react-intl"
import * as Ct from "ldlj"
import { colors } from "../../styles/design.config"
import { ProductsWithPrice } from "../../utils/invoices"
import {
  getTotalTax,
  getTotalTaxExcluded,
  getTotalTaxIncluded,
} from "../../utils/invoicing"

interface AmountTotalsProps {
  productsWithPrice: ProductsWithPrice[]
  intl: IntlShape
  totalDiscount: number
}

export const AmountTotals = ({
  productsWithPrice,
  intl,
  totalDiscount,
}: AmountTotalsProps) => {
  return (
    <TotalWrapper>
      <TotalCellBorder>
        <Ct.Text
          text={intl.formatMessage({ id: "invoicing.text.total-HT" })}
          textStyle={{
            color: "cornflower",
            fontFamily: "Poppins",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        />
        <Ct.Text
          text={`${getTotalTaxExcluded({ productsWithPrice, totalDiscount })}`}
          textStyle={{ fontWeight: 600, fontFamily: "Poppins" }}
        />
      </TotalCellBorder>
      <TotalCellBorder>
        <Ct.Text
          text={intl.formatMessage({ id: "invoicing.text.total-VAT" })}
          textStyle={{
            color: "cornflower",
            fontFamily: "Poppins",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        />
        <Ct.Text
          text={`${getTotalTax({ productsWithPrice, totalDiscount })}`}
          textStyle={{ fontWeight: 600, fontFamily: "Poppins" }}
        />
      </TotalCellBorder>
      <TotalCell>
        <Ct.Text
          text={intl.formatMessage({
            id: "invoicing.text.total-tax-included",
          })}
          textStyle={{
            color: "cornflower",
            fontFamily: "Poppins",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        />
        <Ct.Text
          text={`${getTotalTaxIncluded({ productsWithPrice, totalDiscount })}`}
          textStyle={{ fontWeight: 600, fontFamily: "Poppins" }}
        />
      </TotalCell>
    </TotalWrapper>
  )
}

const TotalCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 20rem;
`

const TotalCellBorder = styled(TotalCell)`
  border-right: 1px solid ${colors.cornflower};
  height: 100%;
`

const TotalWrapper = styled.div`
  max-width: 60rem;
  height: 7.5rem;
  border-radius: 1.25rem;
  border: 1px solid ${colors.cornflower};
  background: ${colors.mist};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
