/* eslint-disable camelcase */
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useState } from "react"

import {
  FullDocumentOnly,
  GiveMerchantInstructionPayload,
  setMerchantInstruction,
} from "../../store/ducks/inputMask.ducks"
import {
  AllEntryDatumTypes,
  MagicCheckBoxDatumTypes,
  isMagicCheckBoxDatumType,
} from "../../utils/inputMask"

interface MagicCheckboxProps {
  fullDocument: FullDocumentOnly | null
  accountId: number | undefined
  isDisplayed: boolean
  datumType: AllEntryDatumTypes
}

export const MagicCheckbox = ({
  isDisplayed,
  fullDocument,
  accountId,
  datumType,
}: MagicCheckboxProps) => {
  const intl = useIntl()

  const [saveAccount, setSaveAccount] = useState(false)
  const dispatch = useDispatch()

  return (
    <MagicCheckboxWrapper isDisplayed={isDisplayed}>
      <Ct.Checkbox
        onChange={() => {
          if (!isDisplayed) return
          if (!fullDocument) return
          if (!isMagicCheckBoxDatumType(datumType)) return

          if (!saveAccount && accountId) {
            const merchantInstructionsByDatumType: Record<
              MagicCheckBoxDatumTypes,
              GiveMerchantInstructionPayload
            > = {
              vat: {
                instruction: "lock_vat_account",
                payload:
                  fullDocument.buy_or_sell === "buy"
                    ? { buy_vat_default_account_id: accountId }
                    : { sell_vat_default_account_id: accountId },
                buyOrSell: fullDocument.buy_or_sell,
                merchantId: fullDocument.merchant_id,
              },
              tax_excluded: {
                instruction: "lock_account",
                payload:
                  fullDocument.buy_or_sell === "buy"
                    ? { buy_default_account_id: accountId }
                    : { sell_default_account_id: accountId },
                buyOrSell: fullDocument.buy_or_sell,
                merchantId: fullDocument.merchant_id,
              },
            }

            dispatch(
              setMerchantInstruction({
                datum_type: datumType,
                merchant_instruction:
                  merchantInstructionsByDatumType[datumType],
              })
            )
          } else {
            const merchantInstructionsByDatumType: Record<
              MagicCheckBoxDatumTypes,
              GiveMerchantInstructionPayload
            > = {
              vat: {
                instruction: "unlock_vat_account",
                payload:
                  fullDocument.buy_or_sell === "buy"
                    ? { buy_vat_default_account_id: null }
                    : { sell_vat_default_account_id: null },
                buyOrSell: fullDocument.buy_or_sell,
                merchantId: fullDocument.merchant_id,
              },
              tax_excluded: {
                instruction: "unlock_account",
                payload:
                  fullDocument.buy_or_sell === "buy"
                    ? { buy_default_account_id: null }
                    : { sell_default_account_id: null },
                buyOrSell: fullDocument.buy_or_sell,
                merchantId: fullDocument.merchant_id,
              },
            }

            dispatch(
              setMerchantInstruction({
                datum_type: datumType,
                merchant_instruction:
                  merchantInstructionsByDatumType[datumType],
              })
            )
          }
          setSaveAccount(!saveAccount)
        }}
        name="block"
        isChecked={saveAccount}
        label={intl.formatMessage({
          id: `input-mask.block-${
            fullDocument?.buy_or_sell || "buy"
          }.${datumType}`,
        })}
      />
    </MagicCheckboxWrapper>
  )
}

const MagicCheckboxWrapper = styled.div<{ isDisplayed: boolean }>`
  transition: max-height 0.5s ease-in-out, height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  max-height: ${({ isDisplayed }) => (isDisplayed ? "4rem" : "0rem")};
  height: ${({ isDisplayed }) => (isDisplayed ? "4rem" : "0rem")};
  overflow: hidden;
  opacity: ${({ isDisplayed }) => (isDisplayed ? "1" : "0")};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 3rem;
`
