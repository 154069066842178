import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import {
  TableBody,
  TableHeader,
  TableWrapper,
} from "../Commons/TableStylesForSelect"
import { ReactComponent as File } from "../../assets/fileSimple.svg"
import { ReactComponent as More } from "../../assets/more.svg"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { getUrlForFullDocumentThunk } from "../../store/ducks/invoicing.duck"
import { colors } from "../../styles/design.config"
import { useCallback, useEffect, useState } from "react"
import { useRNBSelector } from "../../store/rootReducer"
import { MoreActions } from "../MoreActions"
import { ArchiveDownLink } from "../ArchiveDownLink"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import {
  Document,
  getUrlForFullDocumentDisplayThunk,
} from "../../store/ducks/merchants.ducks"
import { filterDocs } from "../../utils/fullDocuments"
import { getHighlightedText } from "../../routes/office/company/EDM/Merchants"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../Commons/Table"
import {
  sortDocsByName,
  sortDocsByDate,
  sortDocsByExternalReference,
  sortDocsByInternalReference,
  sortDocsByAmount,
  sortDocsByArchiveId,
} from "../../utils/documents"
import { IconActions } from "../IconActions"
import { Alert } from "../Commons/Alert"
import { Checkbox } from "../Commons/Checkbox"

export interface DisplayedMask {
  elementName: string
  element: Document
  fullDocumentId: number | null
  isDisplayed: boolean
  merchantId: number
}

interface FullDocumentsProps {
  documents: Document[]
  merchantId: number
  highlightValues?: {
    highlight: boolean
    searchString: string
  }
  fullDocIdListToDelete?: number[]
  customDateFilterIsActive: boolean
  customDateRange: [Date, Date]
  onOpenInputMask: (displayedMask: DisplayedMask) => void
  onPreviewFullDoc: (
    isDisplayed: boolean,
    fullDocId: number | null,
    elementName: string,
    merchantId: number
  ) => void
  onSelectFullDocToDelete: (fullDocId: number, uniqueDelete: boolean) => void
  onHistoryFullDoc: (isDisplayed: boolean, fullDocId: number | null) => void
}

export const FullDocumentsTable = ({
  documents,
  merchantId,
  highlightValues,
  fullDocIdListToDelete = [],
  customDateFilterIsActive,
  customDateRange,
  onPreviewFullDoc,
  onOpenInputMask,
  onSelectFullDocToDelete,
  onHistoryFullDoc,
}: FullDocumentsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const merchant = useRNBSelector(
    (state) => state.merchants.merchants[merchantId]
  )
  const userTypology = useRNBSelector((state) => state.user.typology)

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([])
  const [showAction, setShowAction] = useState<boolean>(false)
  // here we can't use setActionId because it re-renders all the component and always reset the showAction to false
  const [showActionId, setShowActionId] = useState<number | null>(null)
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [shiftHeld, setShiftHeld] = useState<boolean>(false)

  const getColumnsConfig = () => {
    if (userTypology === "customer") {
      return [
        { title: "internal", width: 40 },
        { title: "external", width: 30 },
        { title: "deposit", width: 40 },
        { title: "amount", width: 20 },
        { title: "date", width: 20 },
        { title: "action", width: 30 },
      ]
    } else if (userTypology === "customer_accountant") {
      return [
        { title: "internal", width: 40 }, // rem
        { title: "external", width: 30 },
        { title: "deposit", width: 30 },
        { title: "amount", width: 16 },
        { title: "date", width: 15 },
        { title: "archive", width: 20 },
        { title: "action", width: 30 },
      ]
    } else {
      return [
        { title: "multiDel", width: 70 },
        { title: "internal", width: 35 },
        { title: "external", width: 25 },
        { title: "deposit", width: 30 },
        { title: "amount", width: 15 },
        { title: "date", width: 15 },
        { title: "archive", width: 20 },
        { title: "action", width: 30 },
      ]
    }
  }

  const columnsConfig: { title: string; width: number }[] = getColumnsConfig()

  const getColumnWidth = (title: string): number => {
    const column = columnsConfig.find((e) => e.title === title)
    return column?.width || 20
  }

  const filterDocuments = useCallback(
    (
        search: string,
        customDateFilterIsActive: boolean,
        customDateRange: [Date, Date]
      ) =>
      (documents: Document[]) => {
        if (documents && documents.length > 0) {
          if (
            highlightValues?.highlight ||
            (customDateFilterIsActive && customDateRange)
          ) {
            setFilteredDocuments(
              documents.filter(
                filterDocs(
                  search || "",
                  merchant.name,
                  customDateFilterIsActive,
                  customDateRange
                )
              )
            )
          } else {
            setFilteredDocuments(documents)
          }
        }
      },
    []
  )

  const sorter = (asc: boolean) => (option: string) => {
    if (option === "deposit") {
      setFilteredDocuments(sortDocsByName(filteredDocuments, asc))
    } else if (option === "internal") {
      setFilteredDocuments(sortDocsByInternalReference(filteredDocuments, asc))
    } else if (option === "external") {
      setFilteredDocuments(sortDocsByExternalReference(filteredDocuments, asc))
    } else if (option === "amount") {
      setFilteredDocuments(sortDocsByAmount(filteredDocuments, asc))
    } else if (option === "date") {
      setFilteredDocuments(sortDocsByDate(filteredDocuments, asc))
    } else if (option === "archive") {
      setFilteredDocuments(sortDocsByArchiveId(filteredDocuments, asc))
    }
  }

  const toggleAction = (docId: number) => {
    setShowAction(showActionId !== docId ? true : !showAction)
    setShowActionId(showActionId !== docId ? docId : 0)
  }

  const handleCheckboxHeader = () => {
    const allDocAddedToDelete = filteredDocuments.every((e) =>
      fullDocIdListToDelete.includes(e.id)
    )
    const fullDocIds = allDocAddedToDelete
      ? filteredDocuments.map((e) => e.id)
      : filteredDocuments
          .filter((e) => !fullDocIdListToDelete.includes(e.id))
          .map((e) => e.id)
    for (const fullDocId of fullDocIds)
      onSelectFullDocToDelete(fullDocId, false)
  }

  const handleCheckboxChange = (fullDocId: number) => {
    if (shiftHeld && !fullDocIdListToDelete.includes(fullDocId)) {
      const firstIndex = filteredDocuments.findIndex(
        (fd) => fullDocIdListToDelete.includes(fd.id) || fullDocId === fd.id
      )
      const lastElem = filteredDocuments
        .slice()
        .reverse()
        .find(
          (fd) => fullDocIdListToDelete.includes(fd.id) || fullDocId === fd.id
        )
      const lastIndex = filteredDocuments.findIndex(
        (fd) => fd.id === lastElem?.id || 0
      )

      if (firstIndex !== -1 && lastIndex !== -1) {
        for (let i = firstIndex; i <= lastIndex; i++) {
          if (!fullDocIdListToDelete.includes(filteredDocuments[i].id)) {
            onSelectFullDocToDelete(filteredDocuments[i].id, false)
          }
        }
      } else onSelectFullDocToDelete(fullDocId, false)
    } else onSelectFullDocToDelete(fullDocId, false)
  }

  const downHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(true)
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(false)
    }
  }
  
  useEffect(() => {
    if (documents) {
      filterDocuments(
        highlightValues?.searchString || "",
        customDateFilterIsActive,
        customDateRange
      )([...documents])
    }
  }, [highlightValues, documents, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    if (columnToSort) {
      const currentSort = columnsConfig[columnToSort.index].title
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        filterDocuments(
          highlightValues?.searchString || "",
          customDateFilterIsActive,
          customDateRange
        )([...documents])
      }
    }
  }, [columnToSort, customDateFilterIsActive, customDateRange])

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        {columnsConfig.map(
          (column: { title: string; width: number }, index) => {
            if (column.title === "multiDel") {
              return (
                <HeaderCheckbox
                  calculatedWidth={column.width}
                  key={`header-${index}`}
                >
                  <Checkbox
                    label=""
                    isChecked={
                      filteredDocuments.every((e) =>
                        fullDocIdListToDelete.includes(e.id)
                      ) && filteredDocuments.length > 0
                    }
                    value={
                      filteredDocuments.every((e) =>
                        fullDocIdListToDelete.includes(e.id)
                      ) && filteredDocuments.length > 0
                    }
                    name=""
                    onChange={() => handleCheckboxHeader()}
                    colorChecked={colors.amaranth}
                    checkBoxSize={2.5}
                  />
                </HeaderCheckbox>
              )
            } else {
              return (
                <HeaderItem key={column.title} calculatedWidth={column.width}>
                  {column.title !== "action" ? (
                    <ClickableTitleSort
                      tid={`merchants.header.${column.title}`}
                      intl={intl}
                      index={index}
                      sortToReturn={(column: SortToReturn) => {
                        setColumnToSort(column)
                      }}
                      currentChevron={currentChevron}
                    />
                  ) : (
                    <TitleTable
                      tid={`merchants.header.${column.title}`}
                      intl={intl}
                    />
                  )}
                </HeaderItem>
              )
            }
          }
        )}
      </StyledTableHeader>

      <StyledTableBody>
        {filteredDocuments?.length > 0 ? (
          <>
            {filteredDocuments.map((doc: Document, index) => (
              <Ct.WrapperRows key={doc.id} id={`${doc.archive_id}`}>
                <Ct.FlexStart>
                  {!["customer", "customer_accountant"].includes(
                    userTypology
                  ) && (
                    <StyledCellCheckbox>
                      <Checkbox
                        label=""
                        isChecked={fullDocIdListToDelete.includes(doc.id)}
                        value={fullDocIdListToDelete.includes(doc.id)}
                        name=""
                        onChange={() => {
                          handleCheckboxChange(doc.id)
                        }}
                        colorChecked={colors.amaranth}
                        checkBoxSize={2.5}
                      />
                    </StyledCellCheckbox>
                  )}
                  <StyledCellStart calculatedWidth={getColumnWidth("internal")}>
                    <Ct.Spacer height={2} />
                    <div>
                      <File />
                    </div>
                    <Ct.Spacer width={1.75} />
                    <StyledLink
                      onClick={() => {
                        if (doc.merchant_id && !doc.url) {
                          dispatch(
                            getUrlForFullDocumentDisplayThunk(
                              doc.merchant_id,
                              doc.id
                            )
                          )
                        }

                        onPreviewFullDoc(
                          true,
                          doc.id,
                          doc.file_name
                            ? doc.user_file_name
                            : doc.original_file_name,
                          merchant.id
                        )
                      }}
                    >
                      {highlightValues && (
                        <>
                          {getHighlightedText(
                            doc.user_file_name,
                            highlightValues.searchString
                          )}
                        </>
                      )}
                    </StyledLink>
                  </StyledCellStart>

                  <StyledCell calculatedWidth={getColumnWidth("external")}>
                    <TextRef>
                      {highlightValues && (
                        <>
                          {getHighlightedText(
                            doc.document_reference,
                            highlightValues.searchString
                          )}
                        </>
                      )}
                    </TextRef>
                  </StyledCell>

                  <StyledCell calculatedWidth={getColumnWidth("deposit")}>
                    <StyledP>
                      {highlightValues && (
                        <>
                          {getHighlightedText(
                            doc.original_file_name,
                            highlightValues.searchString
                          )}
                        </>
                      )}
                    </StyledP>
                  </StyledCell>

                  <StyledCell calculatedWidth={getColumnWidth("amount")}>
                    <StyledP>
                      {highlightValues && (
                        <>
                          {getHighlightedText(
                            doc.amount,
                            highlightValues.searchString
                          )}
                        </>
                      )}
                    </StyledP>
                  </StyledCell>

                  <StyledCell calculatedWidth={getColumnWidth("date")}>
                    <StyledP>
                      {new Date(doc.document_date).toLocaleDateString()}
                    </StyledP>
                  </StyledCell>

                  {userTypology !== "customer" && (
                    <StyledCell calculatedWidth={getColumnWidth("archive")}>
                      <ArchiveDownLink
                        userTypology={userTypology}
                        selectedCompanyId={Number(selectedCompanyId)}
                        archiveId={doc.archive_id}
                      />
                    </StyledCell>
                  )}

                  <StyledCell calculatedWidth={getColumnWidth("action")}>
                    {userTypology !== "customer" ? (
                      <DropActionButton
                        onClick={() => {
                          toggleAction(doc.id)
                        }}
                      >
                        <StyledMore
                          open={showAction && showActionId === doc.id}
                        />
                      </DropActionButton>
                    ) : (
                      <IconActions
                        actionsToDisplay={["download"]}
                        onSetDownload={() => {
                          dispatch(getUrlForFullDocumentThunk(doc.id))
                        }}
                      />
                    )}
                    {showAction && (
                      <MoreActions
                        onBlur={() => {
                          setShowAction(!showAction)
                          setShowActionId(0)
                        }}
                        showActions={showAction && showActionId === doc.id}
                        actionsToDisplay={
                          userTypology === "customer"
                            ? ["download"]
                            : ["download", "inputMask", "history"]
                        }
                        onSetDisplayPreview={() => {
                          dispatch(getUrlForFullDocumentThunk(doc.id))
                          setShowAction(!showAction)
                          setShowActionId(0)
                        }}
                        onSetDisplayMask={() => {
                          onOpenInputMask({
                            isDisplayed: true,
                            fullDocumentId: doc.id,
                            elementName: doc.user_file_name,
                            element: doc,
                            merchantId: merchant.id,
                          })
                          setShowAction(!showAction)
                          setShowActionId(0)
                        }}
                        onDelete={() => {
                          onSelectFullDocToDelete(doc.id, true)
                        }}
                        onSetHistory={() => {
                          onHistoryFullDoc(true, doc.id)
                        }}
                        deleteAction={
                          ["customer", "customer_accountant"].includes(
                            userTypology
                          )
                            ? "hide"
                            : "enable"
                        }
                        onSetDownload={() => {
                          dispatch(getUrlForFullDocumentThunk(doc.id))
                          setShowAction(!showAction)
                          setShowActionId(0)
                        }}
                        customWidth={32}
                        top={
                          filteredDocuments.length > 5 &&
                          index >= filteredDocuments.length - 3
                            ? "-16rem"
                            : "5rem"
                        }
                      />
                    )}
                  </StyledCell>
                </Ct.FlexStart>
              </Ct.WrapperRows>
            ))}
          </>
        ) : (
          <>
            <Ct.Spacer height={4} />
            <WrapperAlert>
              <Alert alertType="info">
                <Ct.Text
                  text={intl.formatMessage({
                    id: "merchants.viewer.alert",
                  })}
                />
              </Alert>
            </WrapperAlert>
          </>
        )}
      </StyledTableBody>
    </StyledTableWrapper>
  )
}

const StyledTableWrapper = styled(TableWrapper)`
  border: 1px solid ${colors.cornflower};
  z-index: 2;
`

const WrapperAlert = styled.div`
  display: flex;
  justify-content: center;
`

const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`

const StyledP = styled.p`
  font-size: 1.5rem;
  text-align: center;
  word-break: break-word;
`
const DropActionButton = styled.div`
  cursor: pointer;
`

const StyledTableBody = styled(TableBody)`
  overflow-y: auto;
  max-height: 60rem;
  background-color: ${colors.white};
  padding-bottom: 8rem;
`
const StyledTableHeader = styled(TableHeader)`
  background-color: ${colors.mist};
  justify-content: flex-start;
`

const TextRef = styled.span`
  font-weight: bold;
  padding: 0 0.5rem;
  font-size: 1.5rem;
`

const StyledMore = styled(({ ...props }) => <More {...props} />)<{
  open?: boolean
}>`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${(props) => (props.open ? colors.navy : colors.cornflower)};
  }
  cursor: pointer;
`

const HeaderCheckbox = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}px`};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
`
const HeaderItem = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  padding: 0 4rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin: auto;
`

const StyledCellCheckbox = styled.div`
  height: 6rem;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledCellStart = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-all;
  margin: auto;
`
const StyledCell = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
`
