/* eslint-disable camelcase */
import { useParams } from "react-router-dom"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../store/rootReducer"
import { getIdFromParams } from "../../utils/company"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { useEffect, Fragment, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import {
  fullDocumentUpdateThunk,
  getDeactivatedReasonsThunk,
} from "../../store/ducks/fullDocuments.ducks"
import { truncateFileName } from "../../utils/string"
import { Select } from "../Commons/Select"
import { Alert } from "../Commons/Alert"
import { colors } from "../../styles/design.config"
import { ReactComponent as Eye } from "../../assets/eyeFilled.svg"
import { ReactComponent as File } from "../../assets/fileSimple.svg"
import { ReactComponent as FileEdit } from "../../assets/file-edit.svg"
import { useKeyDown } from "../../hooks/useKeyDown"
import {
  reactivateDocumentAction,
  setSelectedFullDocument,
} from "../../store/ducks/inputMask.ducks"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
} from "../Commons/Table"
import { DeactivatedDocumentInWritingForInputMask } from "../../utils/inputMask"
import {
  sortDeactivatedDocByDate,
  sortDeactivatedDocByName,
  sortDeactivatedDocByQualification,
  sortDeactivatedDocByType,
} from "../../utils/inputMaskTables"

const columnWidth = [
  { width: "20rem" },
  { width: "20rem" },
  { width: "30rem" },
  { width: "15rem" },
  { width: "15rem" },
]

const columnsTitle: {
  title: string
}[] = [
  { title: "name" },
  { title: "document-type" },
  { title: "qualification" },
  { title: "date" },
  { title: "input" },
]

const columnsConfig = columnWidth.map((widthConfig, index) => ({
  ...widthConfig,
  ...columnsTitle[index],
}))

enum SortOptionsValues {
  "name",
  "document-type",
  "qualification",
  "date",
}

export interface DeactivatedDocumentInWritingForInputMaskWithLabels
  extends DeactivatedDocumentInWritingForInputMask {
  labelTranslated: string
  documentTypeTranslated: string
}

export const WritingLinesDeactivated = () => {
  const intl = useIntl()

  const {
    allDeactivatedFullDocuments,
    deactivatedReasonsGroup,
    userDeactivatedReasons,
  } = useRNBSelector((state) => ({
    allDeactivatedFullDocuments: state.inputMask.deactivated_full_documents,
    deactivatedReasonsGroup:
      state.writings.deactivatedReasons.deactivated_reasons,
    userDeactivatedReasons:
      state.writings.deactivatedReasons.user_deactivated_reasons,
  }))

  const deactivatedFullDocuments: DeactivatedDocumentInWritingForInputMaskWithLabels[] =
    allDeactivatedFullDocuments
      .filter((d) => !d.is_being_reactivated)
      .map((d) => {
        const category = Object.entries(deactivatedReasonsGroup).find(
          ([e, f]: [string, {}]) =>
            Object.keys(f).includes(d.deactivated_because)
        )

        const documentTypeKey = category
          ? category[0]
          : d.deactivated_because === "duplicate"
          ? "Doublon"
          : "default"

        const labelObject =
          category &&
          Object.entries(category[1]).find(
            (element) => element[0] === d.deactivated_because
          )
        const labelTranslated =
          labelObject && labelObject[1]
            ? labelObject[1]
            : d.deactivated_because === "duplicate"
            ? "Doublon"
            : "default"

        return {
          ...d,
          labelTranslated: labelTranslated,
          documentTypeTranslated: intl.formatMessage({
            id: `writing-lines.deactivated.table.change-deactivated.type.${documentTypeKey}`,
          }),
        }
      })

  useEffect(() => {
    setSortedDocuments(deactivatedFullDocuments)
  }, [allDeactivatedFullDocuments, deactivatedReasonsGroup])

  const [sortedDocuments, setSortedDocuments] = useState<
    DeactivatedDocumentInWritingForInputMaskWithLabels[]
  >(deactivatedFullDocuments)

  const selectedDocumentRef = useRef<HTMLDivElement>(null)
  const [selectedRow, setSelectedRow] = useState<number>(0)

  const [
    alreadySeenDeactivatedFullDocuments,
    setAlreadySeenDeactivatedFullDocuments,
  ] = useState<number[]>(
    JSON.parse(localStorage.getItem("seenDeactivatedFullDocuments") || "[]")
  )

  useEffect(() => {
    if (
      !alreadySeenDeactivatedFullDocuments.includes(selectedRow) &&
      sortedDocuments[selectedRow]
    ) {
      addToAlreadySeen(sortedDocuments[selectedRow].fd_id)
    }

    if (selectedDocumentRef && selectedDocumentRef.current) {
      selectedDocumentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    }
  }, [selectedRow])

  const addToAlreadySeen = (fullDocumentId: number) => {
    const newAlreadySeen = [
      ...alreadySeenDeactivatedFullDocuments,
      fullDocumentId,
    ]
    setAlreadySeenDeactivatedFullDocuments(newAlreadySeen)
    localStorage.setItem(
      "seenDeactivatedFullDocuments",
      JSON.stringify(newAlreadySeen)
    )
  }
  const removeFromAlreadySeen = (fullDocumentId: number) => {
    const withoutCurrentFullDoc = alreadySeenDeactivatedFullDocuments.filter(
      (id) => id !== fullDocumentId
    )
    setAlreadySeenDeactivatedFullDocuments(withoutCurrentFullDoc)
    localStorage.setItem(
      "seenDeactivatedFullDocuments",
      JSON.stringify(withoutCurrentFullDoc)
    )
  }

  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.["name"]) {
      setSortedDocuments(
        sortDeactivatedDocByName(deactivatedFullDocuments, asc)
      )
    } else if (option === SortOptionsValues?.["document-type"]) {
      setSortedDocuments(
        sortDeactivatedDocByType(deactivatedFullDocuments, asc)
      )
    } else if (option === SortOptionsValues?.["qualification"]) {
      setSortedDocuments(
        sortDeactivatedDocByQualification(deactivatedFullDocuments, asc)
      )
    } else if (option === SortOptionsValues?.["date"]) {
      setSortedDocuments(
        sortDeactivatedDocByDate(deactivatedFullDocuments, asc)
      )
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  const changeSelectedRow = (row: number) => {
    setSelectedRow(row)
  }

  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  useEffect(() => {
    dispatch(getDeactivatedReasonsThunk())
  }, [dispatch])

  const deactivatedReasonsOptions: Ct.OptionList<string> = Object.keys(
    userDeactivatedReasons
  ).map((key: string) => ({
    value: key,
    label: userDeactivatedReasons[key],
  }))

  useKeyDown(() => {
    if (selectedRow >= 0 && selectedRow < sortedDocuments.length - 1) {
      setSelectedRow(selectedRow + 1)
      dispatch(setSelectedFullDocument(sortedDocuments[selectedRow + 1]))
    }
  }, ["ArrowDown"])

  useKeyDown(() => {
    if (selectedRow > 0) {
      setSelectedRow(selectedRow - 1)
      dispatch(setSelectedFullDocument(sortedDocuments[selectedRow - 1]))
    }
  }, ["ArrowUp"])

  return (
    <>
      <FlexWrapper>
        {sortedDocuments.length === 0 ? (
          <>
            <Ct.Spacer height={2} />
            <AlertWrapper>
              <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: "writing-lines.deactivated.table.empty",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
              </Alert>
            </AlertWrapper>
          </>
        ) : (
          <>
            <StyledTableWrapper>
              <StyledTableHeader>
                {columnsConfig.map(({ title, width }, index) =>
                  index === 0 ? (
                    <HeaderFirstItem width={width}>
                      <ClickableTitleSort
                        key={title}
                        tid={`writing-lines.deactivated.table.${title}`}
                        intl={intl}
                        index={index}
                        sortToReturn={(column: SortToReturn) => {
                          setColumnToSort(column)
                        }}
                        currentChevron={currentChevron}
                      />
                    </HeaderFirstItem>
                  ) : index < columnsConfig.length - 1 ? (
                    <HeaderItem width={width}>
                      <ClickableTitleSort
                        key={title}
                        tid={`writing-lines.deactivated.table.${title}`}
                        intl={intl}
                        index={index}
                        sortToReturn={(column: SortToReturn) => {
                          setColumnToSort(column)
                        }}
                        currentChevron={currentChevron}
                      />
                    </HeaderItem>
                  ) : (
                    <HeaderItem width={width}>
                      <Ct.StyledTitles
                        key={title}
                        text={intl.formatMessage({
                          id: `writing-lines.deactivated.table.${title}`,
                        })}
                        textStyle={{
                          fontWeight: 700,
                          fontSize: 1.75,
                          textTransform: "uppercase",
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                      />
                    </HeaderItem>
                  )
                )}
              </StyledTableHeader>

              <StyledTableBody>
                <Ct.WrapperRows>
                  {sortedDocuments.map((line, index) => {
                    return (
                      <Fragment key={index}>
                        <Ct.WrapperRows
                          onClick={() => {
                            dispatch(
                              setSelectedFullDocument(sortedDocuments[index])
                            )
                            changeSelectedRow(index)
                          }}
                          style={
                            selectedRow === index
                              ? { backgroundColor: colors.lavender }
                              : {}
                          }
                          ref={
                            selectedRow === index ? selectedDocumentRef : null
                          }
                        >
                          <PaddingRow>
                            <RowFirstItem
                              id="depositName"
                              width={columnWidth[0].width}
                            >
                              <Ct.Checkbox
                                label={""}
                                name={`already-seen-${line.fd_id}`}
                                isChecked={alreadySeenDeactivatedFullDocuments.includes(
                                  line.fd_id
                                )}
                                onChange={() => {
                                  if (
                                    alreadySeenDeactivatedFullDocuments.includes(
                                      line.fd_id
                                    )
                                  ) {
                                    removeFromAlreadySeen(line.fd_id)
                                  } else {
                                    addToAlreadySeen(line.fd_id)
                                  }
                                }}
                              />
                              <Ct.Spacer width={1} />
                              <Ct.RowCenter>
                                <Ct.FlexShrinkZero>
                                  {selectedRow === index && <Eye />}
                                  {selectedRow !== index && <File />}
                                </Ct.FlexShrinkZero>
                                <Ct.Spacer width={0.5} />
                                <StyledLink
                                  id={"previewFile"}
                                  onClick={() => {
                                    dispatch(
                                      setSelectedFullDocument(
                                        sortedDocuments[index]
                                      )
                                    )
                                    changeSelectedRow(index)
                                  }}
                                >
                                  {truncateFileName(
                                    line.user_batch_name ||
                                      line.original_batch_name,
                                    30
                                  )}
                                </StyledLink>
                              </Ct.RowCenter>
                            </RowFirstItem>
                            <RowItem width={columnWidth[1].width}>
                              <Ct.Text
                                text={line.documentTypeTranslated}
                                textStyle={{
                                  textAlign: "center",
                                }}
                              />
                            </RowItem>
                            <RowItem width={columnWidth[2].width}>
                              <Select
                                customWidth="28rem"
                                intl={intl}
                                options={deactivatedReasonsOptions}
                                value={{
                                  value: line.deactivated_because,
                                  label: line.labelTranslated,
                                }}
                                onChangeCallback={(selectedOption) => {
                                  dispatch(
                                    fullDocumentUpdateThunk(
                                      line.fd_id,
                                      selectedCompanyId,
                                      selectedOption.value
                                    )
                                  )
                                }}
                                domain="input-mask"
                                optionType="qualification"
                                disabled={
                                  line.deactivated_because ===
                                  String("duplicate")
                                }
                              />
                            </RowItem>
                            <RowItem width={columnWidth[3].width}>
                              <Ct.Text
                                text={line.fd_created_at
                                  .toString()
                                  .substring(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                                textStyle={{ textAlign: "center" }}
                              />
                            </RowItem>
                            <RowItem width={columnWidth[4].width} last="true">
                              <StyledFileEdit
                                onClick={() => {
                                  dispatch(
                                    reactivateDocumentAction({
                                      fd_id: line.fd_id,
                                    })
                                  )
                                }}
                              />
                            </RowItem>
                          </PaddingRow>
                        </Ct.WrapperRows>
                      </Fragment>
                    )
                  })}
                </Ct.WrapperRows>
              </StyledTableBody>
            </StyledTableWrapper>
          </>
        )}
      </FlexWrapper>
    </>
  )
}

const HeaderFirstItem = styled((props) => <Ct.Cell {...props} />)`
  min-width: ${({ width }) => (width ? width : `auto`)};
  justify-content: space-between;
  padding: 0 4rem;
  flex-grow: 1;
`
const HeaderItem = styled((props) => <Ct.Cell {...props} />)`
  max-width: ${({ width }) => (width ? width : `auto`)};
  min-width: ${({ width }) => (width ? width : `auto`)};
  justify-content: space-between;
  padding: 0 4rem;
`
const RowFirstItem = styled((props) => <Ct.Cell {...props} />)`
  min-width: ${({ width }) => (width ? width : `auto`)};
  justify-content: flex-start;
  padding: 0 7rem 0 1rem;
  flex-grow: 1;
  word-break: break-word;
`
const RowItem = styled((props) => <Ct.Flex1 {...props} />)`
  max-width: ${({ width }) => (width ? width : `auto`)};
  min-width: ${({ width }) => (width ? width : `auto`)};
  padding: ${({ last }) => (last ? "0 4rem" : `0 7rem 0 1rem`)};
  display: flex;
  align-items: center;
  align-self: center;
`
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 20rem);
`
const StyledTableWrapper = styled(Ct.TableWrapper)`
  height: auto;
  flex: 1;
`
const StyledTableHeader = styled(Ct.TableHeader)`
  padding: 0 3rem;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.hawkes};
`
const PaddingRow = styled(Ct.Row)`
  padding-left: 3rem;
  height: 12rem;
`
const StyledTableBody = styled(Ct.TableBody)``
const AlertWrapper = styled.div`
  align-self: center;
  width: 50rem;
`
const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`
const StyledFileEdit = styled(FileEdit)`
  flex: 1;
  align-self: end;
  cursor: pointer;
`
