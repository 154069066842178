import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { ReactComponent as CircledCrossSVG } from "../assets/circled-cross.svg"
import { useRNBSelector } from "../store/rootReducer"

export interface CaptureMailUser {
  id: number
  email: string
  denomination: string
}

interface CaptureMaiProps {
  user: CaptureMailUser
  onClick: () => void
}

export const CaptureMailUserRow = ({ user, onClick }: CaptureMaiProps) => {
  const intl = useIntl()

  const usersWhoAccessToCompany = useRNBSelector(
    (state) => state.user.usersWhoAccessToCompany
  )
  const currentUserTypology = useRNBSelector((state) => state.user.typology)

  const userIsInTeam = Object.entries(usersWhoAccessToCompany).find(
    (element) => element[1].email === user.email
  )

  return (
    <>
      <User>
        <Ct.Column>
          <Ct.Text
            text={user.denomination}
            textStyle={{
              fontSize: 2,
              fontFamily: "Roboto",
              fontWeight: 500,
              lineHeight: 3.25,
            }}
          />
        </Ct.Column>
        <Ct.Column>
          <Ct.Text
            text={user?.email}
            textStyle={{
              fontSize: 1.75,
              fontFamily: "Poppins",
              lineHeight: 2.625,
            }}
          />
        </Ct.Column>
        <Ct.Column>
          {userIsInTeam === undefined && currentUserTypology !== "customer" ? (
            <Ct.Button
              label={intl.formatMessage({
                id: "capture-mail.delete-access",
              })}
              onClick={() => onClick()}
              prefix={<CircledCrossSVG />}
              colorType="Tertiary"
              colorScheme={{
                border: "amaranth",
                color: "amaranth",
                background: "white",
              }}
              width={23.5}
              height={6}
            />
          ) : (
            <></>
          )}
        </Ct.Column>
      </User>
    </>
  )
}

const User = styled((props) => <Ct.Row {...props} />)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;

  > *:first-child {
    width: 30%;
  }

  > *:last-child {
    width: 37%;
    align-items: flex-end;
  }
`
