/* eslint-disable camelcase */
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"

import * as Ct from "ldlj"
import { useRNBSelector } from "../../../../../store/rootReducer"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  TextNoChanges,
} from "../../../../../components/ChangesList"
import { sizes } from "../../../../../styles/design.config"
import {
  Numbering,
  getNumberingHistoryThunk,
} from "../../../../../store/ducks/numberings.ducks"

interface NumberingHistoryModalProps extends Ct.ModalComponentsProps {
  numbering: Numbering | null
}
export const NumberingHistoryModal = ({
  isDisplayed,
  onClose,
  numbering,
}: NumberingHistoryModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { numberingHistoryEvent } = useRNBSelector((state) => ({
    numberingHistoryEvent: state.numberings.numberingHistory,
  }))
  useEffect(() => {
    if (!numbering?.id) {
      return
    }
    dispatch(
      getNumberingHistoryThunk({
        numberingId: numbering.id,
      })
    )
  }, [numbering?.id])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          text={intl.formatMessage({
            id: "invoicing.numbering.modal-history.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={intl.formatMessage({
                id: `invoicing.numbering.modal-history.client`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={" : "}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={numbering?.numbering || ""}
              textStyle={{ fontWeight: 700 }}
            />
          </ChangeHeader>
          <ChangesList>
            {numberingHistoryEvent.length > 0 ? (
              numberingHistoryEvent.map((e) => {
                return (
                  <ChangeItem key={e.created_at}>
                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: `invoicing.numbering.modal-history.${e.instruction_type}`,
                        },
                        {
                          date: DateTime.fromJSDate(
                            new Date(e.created_at)
                          ).toFormat("dd/MM/yyyy à HH:mm"),
                          email: e.user_email,
                        }
                      )}
                    />
                  </ChangeItem>
                )
              })
            ) : (
              <TextNoChanges
                text={intl.formatMessage({
                  id: `invoicing.numbering.modal-history.no-changes`,
                })}
              />
            )}
          </ChangesList>
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: "invoicing.numbering.modal-history.ok",
          })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
