import * as Ct from "ldlj"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as Boxes } from "../../../../../assets/boxes.svg"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import {
  addProductReset,
  deleteProductThunk,
  getProductsThunk,
  getUnitsThunk,
  modifyProductReset,
  ProductFormatted,
} from "../../../../../store/ducks/invoicing.duck"
import { useParams } from "react-router-dom"
import { useSelectedCompany } from "../../../../../hooks/useSelectedCompany"
import { useRNBSelector } from "../../../../../store/rootReducer"
import {
  sortProductByPrice,
  sortProductByName,
  sortProductByUnit,
  sortProductByVatRates,
  sortProductBySearch,
  sortProductByDescription,
  sortProductByPriceTaxIncluded,
} from "../../../../../utils/invoicing"
import { boxShadow, colors } from "../../../../../styles/design.config"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../../components/Commons/Table"
import { CreateOrModifyProductModal } from "../../../../../components/CreateOrModifyProductModal"
import { IconActions } from "../../../../../components/IconActions"
import { capitalizeFirstLetter } from "../../../../../utils/string"
import { getIdFromParams } from "../../../../../utils/company"
import { getVatRatesThunk } from "../../../../../store/ducks/vatRates.ducks"

export const Products = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const [createOrModify, setCreateOrModify] = useState<"create" | "modify">(
    "create"
  )
  const selectedCompanyId = getIdFromParams(params)("company_id")
  const [productToEdit, setProductToEdit] = useState<ProductFormatted>()

  const selectedCompany = useSelectedCompany(params, dispatch)

  const products = useRNBSelector((state) => state.invoicing.products).sort(
    (a, b) => a.productName?.localeCompare(b.productName)
  )
  const vatRates = useRNBSelector((state) => state.vatRates.vatRates)
  const unitsRate = useRNBSelector((state) => state.invoicing.units)
  const addProductStatus = useRNBSelector(
    (state) => state.invoicing.addProductStatus
  )
  const deleteProductStatus = useRNBSelector(
    (state) => state.invoicing.deleteProductStatus
  )
  const modifyProductStatus = useRNBSelector(
    (state) => state.invoicing.modifyProductStatus
  )
  const [displayModal, setDisplayModal] = useState(false)
  const [search, setSearch] = useState("")
  const [sortedRows, setSortedRows] = useState<ProductFormatted[]>([])

  const getRateFromVatRateId = (vatRateId: number) => {
    let rate = vatRates.find((e) => e.id === vatRateId)?.rate.replace(".", ",")
    return rate ? `${rate}%` : ""
  }
  const getPriceTaxIncluded = (product: ProductFormatted) => {
    const rate = vatRates.find((e) => e.id === product.vatRateId)?.rate
    const formatedRate = 1 + (rate ? Number(rate) : 0) / 100
    const priceTaxIncluded = (Number(product.unitPrice) * formatedRate)
      .toFixed(2)
      .replace(".", ",")

    return `${priceTaxIncluded} €`
  }

  const columns = [
    {
      headerText: "invoicing.settings.products.header-name",
      content: (product: ProductFormatted) => (
        <NameWrapper>
          <Ct.Text
            text={capitalizeFirstLetter(product.productName)}
            textStyle={{
              fontSize: 2,
              fontWeight: 500,
            }}
          />
          {product.creditNoteOnly === true ? (
            <>
              <Ct.Spacer height={1.5} />
              <CreditNoteWrapper>AVOIR</CreditNoteWrapper>
            </>
          ) : (
            ""
          )}
        </NameWrapper>
      ),
    },
    {
      headerText: "invoicing.settings.products.header-description",
      content: (product: ProductFormatted) => (
        <Ct.Text
          text={product.productDescription}
          textStyle={{
            fontSize: 2,
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.products.header-unit",
      content: (product: ProductFormatted) => (
        <Ct.Text
          text={`${unitsRate.find((u) => u.id === product.unitId)?.unit || ""}`}
          textStyle={{
            fontSize: 2,
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.products.header-price",
      content: (product: ProductFormatted) => {
        return (
          <Ct.Text
            text={`${Number(product.unitPrice).toFixed(2).replace(".", ",")} €`}
            textStyle={{
              fontSize: 2,
            }}
          />
        )
      },
    },
    {
      headerText: "invoicing.settings.products.header-vat-rate",
      content: (product: ProductFormatted) => (
        <Ct.Text
          text={getRateFromVatRateId(product.vatRateId)}
          textStyle={{
            fontSize: 2,
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.products.header-price-tax-included",
      content: (product: ProductFormatted) => (
        <Ct.Text
          text={getPriceTaxIncluded(product)}
          textStyle={{
            fontSize: 2,
          }}
        />
      ),
    },
    {
      headerText: "invoicing.settings.products.header-action",
      content: (product: ProductFormatted) => (
        <IconActions
          actionsToDisplay={["rename", "delete"]}
          onRename={() => {
            setCreateOrModify("modify")
            setProductToEdit(product)
            dispatch(addProductReset())
            dispatch(modifyProductReset())
            setDisplayModal(true)
          }}
          onDelete={() => {
            const alertResponse = window.confirm(
              intl.formatMessage({
                id: "invoicing.settings.products.delete-product.confirmation",
              })
            )
            if (selectedCompany && alertResponse) {
              dispatch(deleteProductThunk(selectedCompany.id, product.id))
            }
          }}
          deleteAction={"enable"}
        />
      ),
    },
  ]

  enum SortOptionsValues {
    "productName",
    "description",
    "unit",
    "price",
    "vat_rate",
    "price_tax_included",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.productName) {
      setSortedRows(sortProductByName(sortedRows, asc))
    } else if (option === SortOptionsValues?.description) {
      setSortedRows(sortProductByDescription(sortedRows, asc))
    } else if (option === SortOptionsValues?.unit) {
      setSortedRows(sortProductByUnit(sortedRows, asc, unitsRate))
    } else if (option === SortOptionsValues?.price) {
      setSortedRows(sortProductByPrice(sortedRows, asc))
    } else if (option === SortOptionsValues?.vat_rate) {
      setSortedRows(sortProductByVatRates(sortedRows, asc))
    } else if (option === SortOptionsValues?.price_tax_included) {
      setSortedRows(sortProductByPriceTaxIncluded(sortedRows, asc, vatRates))
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedRows(sortProductBySearch(products, search))
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getProductsThunk(selectedCompany.id))
      dispatch(getUnitsThunk(selectedCompany.id))
      dispatch(getVatRatesThunk())
    }
  }, [dispatch, selectedCompany])

  useEffect(() => {
    if (
      selectedCompany &&
      (addProductStatus === "SUCCESS" ||
        deleteProductStatus === "SUCCESS" ||
        modifyProductStatus === "SUCCESS")
    ) {
      dispatch(getProductsThunk(selectedCompany.id))
    }
  }, [
    dispatch,
    selectedCompany,
    addProductStatus,
    deleteProductStatus,
    modifyProductStatus,
  ])

  useEffect(() => {
    if (products) {
      setSortedRows(sortProductBySearch(products, search))
    }
  }, [products, search])

  const rowBackgroundColors = sortedRows.map((_, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])
  return (
    <Wrapper>
      <StyledSection>
        <CreateOrModifyProductModal
          isDisplayed={displayModal}
          selectedProduct={productToEdit}
          createOrModify={createOrModify}
          onClose={() => {
            setDisplayModal(false)
          }}
        />
        <Ct.Spacer height={4} />
        <SortingArea>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            dataCy={"searchProduct"}
            shadowed={true}
            noBorder={true}
            isSearch={true}
          />
          <OtherActionsArea>
            <Ct.Button
              width={31}
              height={5}
              prefix={<Boxes />}
              onClick={() => {
                setCreateOrModify("create")
                dispatch(addProductReset())
                dispatch(modifyProductReset())
                setDisplayModal(true)
                setProductToEdit(undefined)
              }}
              label={intl.formatMessage({
                id: "invoicing.settings.products.add-product.add-button",
              })}
            />
            <Ct.Spacer width={4} />
          </OtherActionsArea>
        </SortingArea>
        <Ct.Spacer height={3} />
        <TableWrapper>
          <Table
            intl={intl}
            columns={columns}
            rows={sortedRows}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            padding={"0"}
            paddingHeader={"0 7rem 0 4rem"}
            paddingRows={"8px 9rem 8px 17px"}
            fontWeightTitle={600}
            alertMessage={"invoicing.settings.products.filter.empty-rows"}
            keyBuilder={(rowData) => String(rowData.id)}
            sortableColumnsLength={columns.length - 1}
            sortingMainFunction={(columnToSort) => {
              setColumnToSort(columnToSort)
            }}
            currentSortColumn={currentChevron}
            rowBackgroundColors={rowBackgroundColors}
            customScrollBar={true}
          />
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}

export interface ModalPropsExtended extends Ct.ModalComponentsProps {
  documentType?: "creditNote" | "quotation" | "invoicing"
}

const SortingArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
`

const OtherActionsArea = styled.div`
  display: flex;
`

const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
`
const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CreditNoteWrapper = styled.div`
  background: ${colors.cornflower};
  color: ${colors.white};
  border-radius: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  width: min-content;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
