import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components/macro"
import { getFiduciaryThunk } from "../store/ducks/fiduciary.ducks"
import { useRNBSelector } from "../store/rootReducer"
import { boxShadow, colors, gradients } from "../styles/design.config"
import { ReactComponent as ChevronRight } from "../assets/chevron-right.svg"
import { useIntl } from "react-intl"
import { CompanyPickerModal } from "./CompanyPickerModal"
import { useNavigate, useParams } from "react-router-dom"
import {
  getCompanyLogos,
  GetCompanyThunk,
  registerCompanyResetStatus,
} from "../store/ducks/companies.ducks"
import { CompanyMenu } from "./CompanyMenu"
import { resetUpload } from "../store/ducks/standardLedger.ducks"
import { getIdFromParams } from "../utils/company"
import * as Ct from "ldlj"
import Avatar, { ConfigProvider } from "react-avatar"
import { croppedString } from "../utils/string"
import config from "../config"
import { getEmailForCustomer } from "../store/ducks/user.ducks"
import { ReactComponent as Illustration } from "../assets/illustration_centre_aide.svg"

/* eslint-disable camelcase */

export const MenuSidebar = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()

  const [displayModal, setDisplayModal] = useState(false)

  const companies = useRNBSelector((state) => state.companies.companies)

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const selectedCompany = selectedCompanyId
    ? companies[selectedCompanyId]
    : null

  const selectedCompanyName = selectedCompany?.user_company_name
    ? selectedCompany?.user_company_name
    : selectedCompany?.name

  const fiduciaryName = useRNBSelector((state) => state.fiduciary.name)
  const logoUri = useRNBSelector((state) => state.fiduciary.logoUrl)
  const logoCompany = useRNBSelector(
    (state) =>
      `${
        state.companies.companiesLogos[selectedCompanyId]
          ? config.invoicingLogosBucketUri +
            state.companies.companiesLogos[selectedCompanyId]?.file_name
          : ""
      }`
  )

  const getCompaniesLogosStatus = useRNBSelector(
    (state) => state.companies.getCompaniesLogosStatus
  )

  const userTypology = useRNBSelector((state) => state.user.typology)
  const creatorEmail = useRNBSelector((state) => state.user.creatorEmail)

  useEffect(() => {
    dispatch(getFiduciaryThunk())
  }, [dispatch])

  useEffect(() => {
    if (userTypology === "customer") {
      dispatch(getEmailForCustomer())
    }
  }, [dispatch, userTypology])

  const clickToCompanies = () => {
    dispatch(registerCompanyResetStatus())
    dispatch(resetUpload())
    navigate("/office/fiduciary/companies")
  }

  useEffect(() => {
    const companiesNumber = Object.keys(companies).length
    if (companiesNumber === 1 && companies[0]) {
      return
    }
    if (companiesNumber > 0 && getCompaniesLogosStatus === "UNSET") {
      dispatch(getCompanyLogos())
    }
  }, [companies, dispatch, getCompaniesLogosStatus])

  useEffect(() => {
    if (selectedCompanyId !== 0) {
      dispatch(GetCompanyThunk(selectedCompanyId))
    }
  }, [selectedCompanyId, dispatch])

  const Menu =
    selectedCompany &&
    ((selectedCompany.standard_ledgers &&
      selectedCompany.standard_ledgers.length > 0) ||
      selectedCompany.no_fec) ? (
      <CompanyMenu />
    ) : (
      <div />
    )

  return (
    <StyledSection>
      {logoUri ? (
        <StyledFiduciaryLogo
          onClick={clickToCompanies}
          src={logoUri}
          alt="fiduciary logo"
        />
      ) : (
        <ConfigProvider
          colors={[
            colors.amaranth,
            colors.radicalRed,
            colors.brightSun,
            colors.cornflower,
            colors.columbia,
          ]}
        >
          <StyledAvatar
            data-cy="fiduciary-name"
            name={fiduciaryName}
            round={true}
            onClick={clickToCompanies}
          />
        </ConfigProvider>
      )}
      <Ct.Spacer height={2} />
      <StyledFiduciaryName
        onClick={clickToCompanies}
        data-cy={"fiduciary-name"}
      >
        {croppedString(fiduciaryName, 80)}
      </StyledFiduciaryName>
      <Ct.Spacer height={1} />
      <Ct.Separator size="half" />
      <Ct.Spacer height={4} />
      <StyledCompanyPicker
        hasCompaniesToSelect={Object.keys(companies).length > 0}
        id="selectedCompany"
        onClick={() => {
          if (Object.keys(companies).length > 0) {
            setDisplayModal(!displayModal)
          }
        }}
        selectedCompanyNameLength={
          selectedCompanyName ? selectedCompanyName.length : 0
        }
      >
        <WrapperCompanyName>
          {logoCompany && selectedCompany ? (
            <>
              <StyledCompanyLogo src={logoCompany} alt="logo" />
              <Ct.Spacer width={1} />
            </>
          ) : (
            <Ct.Spacer height={3.75} width={0} />
          )}
          <StyledCompanyName>
            {selectedCompany
              ? croppedString(
                  selectedCompanyName ? selectedCompanyName : "",
                  60
                )
              : intl.formatMessage({
                  id: "menu.company-picker",
                })}
          </StyledCompanyName>

          <FlexSpace />
          <StyledChevronWrapper
            hasCompaniesToSelect={Object.keys(companies).length > 0}
          >
            <ChevronRight />
          </StyledChevronWrapper>
        </WrapperCompanyName>

        {userTypology !== "customer" &&
          selectedCompany?.accounting_software_reference && (
            <>
              <Ct.Spacer height={1} />
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: "menu.company-picker.reference",
                  },
                  {
                    accounting_reference:
                      selectedCompany?.accounting_software_reference,
                  }
                )}
                textStyle={{
                  color: "slateGrey",
                }}
              />
            </>
          )}
      </StyledCompanyPicker>
      <CompanyPickerModal
        isDisplayed={displayModal}
        onClose={() => {
          setDisplayModal(!displayModal)
        }}
      />
      <Ct.Spacer height={3} />
      {selectedCompany && <SmallSeparator />}
      {Menu}
      <SpecialSpacer />
      <FooterMenu>
        {window.innerHeight > 780 && <StyledIllustration />}
        <ContactText>
          <Ct.Text
            text={intl.formatMessage({
              id: "menu.footer.text",
            })}
            textStyle={{
              fontFamily: "Roboto",
              fontSize: 1.5,
              fontWeight: 500,
              textAlign: "center",
            }}
          />
          <Ct.Spacer height={2} />
        </ContactText>

        <ContactLink
          href={
            userTypology === "customer" && creatorEmail
              ? `mailto:${creatorEmail}`
              : `https://aide.chaintrust.io/hc/fr`
          }
          target={userTypology === "customer" && creatorEmail ? "" : "_blank"}
          rel={
            userTypology === "customer" && creatorEmail
              ? ""
              : "noopener noreferrer"
          }
        >
          <Ct.Text
            text={
              userTypology === "customer" && creatorEmail
                ? intl.formatMessage({ id: "menu.footer.contact.cta" })
                : intl.formatMessage({ id: "menu.footer.contact.centre-aide" })
            }
            textStyle={{
              color: "white",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 1.5,
              cursor: "pointer",
            }}
          />
        </ContactLink>
      </FooterMenu>
    </StyledSection>
  )
}

const FlexSpace = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`

const SpecialSpacer = styled.div`
  height: 100%;
`
const SmallSeparator = styled.hr`
  background-color: ${colors.rock};
  height: 1px;
  width: 5rem;
  border: none;
  flex-shrink: 0;
  position: relative;
  right: 10rem;
`

const StyledSection = styled.section`
  position: relative;
  width: 30.25rem;
  min-width: 30.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background: radial-gradient(
    102.52% 357.96% at 0% 2%,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.52) 100%
  );
  border-radius: 2.5rem;
  padding: 3rem 2rem 4rem 3rem;
  box-shadow: ${boxShadow};
  margin: 4rem;
  margin-right: 0;

  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`
const StyledAvatar = styled(Avatar)`
  max-height: 8rem;
  max-width: 8rem;

  flex-shrink: 0;
  border: 1px solid ${colors.rock};
  cursor: pointer;

  & > div {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
`

const StyledFiduciaryLogo = styled.img`
  border-radius: 50%;
  height: 8rem;
  width: 8rem;
  flex-shrink: 0;
  object-fit: contain;
  border: 1px solid ${colors.rock};
  cursor: pointer;
`

const StyledCompanyLogo = styled.img`
  border-radius: 1.25rem;
  height: 3.75rem;
  width: 3.75rem;
  object-fit: contain;
  cursor: pointer;
  flex-shrink: 0;
`

const StyledFiduciaryName = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.navy};
  cursor: pointer;
  word-break: break-word;
`
const StyledCompanyName = styled.div`
  word-break: break-word;
`

interface CompanyPicker {
  hasCompaniesToSelect: boolean
  selectedCompanyNameLength?: number
}

const StyledChevronWrapper = styled.div<CompanyPicker>`
  display: flex;
  align-items: center;

  & path {
    fill: ${({ hasCompaniesToSelect }) =>
      hasCompaniesToSelect ? "" : colors.moon};
  }
`

const StyledCompanyPicker = styled.button<CompanyPicker>`
  cursor: ${({ hasCompaniesToSelect }) =>
    hasCompaniesToSelect ? "pointer" : "initial"};

  background: ${({ hasCompaniesToSelect }) =>
    hasCompaniesToSelect
      ? "linear-gradient( 125.36deg,rgba(107, 146, 255, 0.2) 5.35%,rgba(159, 216, 255, 0.2) 94.88%)"
      : colors.desertStorm};
  color: ${({ hasCompaniesToSelect }) =>
    hasCompaniesToSelect ? colors.cornflower : colors.moon};
  text-align: start;
  border-radius: 1.25rem;
  border: none;
  width: 25.25rem;
  padding: 1rem;
  padding-right: 1.5rem;

  :focus {
    outline: ${({ hasCompaniesToSelect }) =>
      hasCompaniesToSelect
        ? css`2px solid ${colors.cornflower}` /* stylelint-disable-line */
        : "2px solid transparent"};
  }
`

const WrapperCompanyName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  font-size: 1.75rem;
  text-transform: uppercase;
  transition: outline 0.3s ease-in-out;
  outline: 2px solid transparent;
  box-sizing: border-box;
  word-break: break-word;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`

const ContactText = styled.div`
  @media (max-height: 590px) {
    display: none;
  }
`
const FooterMenu = styled.div`
  background-color: ${colors.lavender};
  margin-right: 1rem;
  padding: 3rem 2rem;
  width: 18.25rem;
  border-radius: 2.5rem;
  text-align: center;
  @media (max-height: 520px) {
    display: none;
  }
`

const ContactLink = styled.a`
  display: flex;
  justify-content: center;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 17.75rem;
    border-radius: 1.25rem;
    font-family: "poppins", sans-serif;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    background: ${gradients.blue};
    cursor: pointer;
    text-transform: uppercase;
  }
`
const StyledIllustration = styled(Illustration)`
  display: column;
`
