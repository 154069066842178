import { toast } from "react-toastify"
import { ReactComponent as Remove } from "../assets/remove.svg"
import { ReactComponent as Check } from "../assets/check.svg"
import { ReactComponent as InfoFull } from "../assets/info-full.svg"
import { ReactComponent as Warning } from "../assets/info-warning.svg"
import { createIntl } from "react-intl"
import { translations } from "../i18n"

export type ToasterMessageAction = {
  type: string
  withToast: boolean
  toasterType?: toasterType
  message: {
    titleKey: string
    titleValues?: Record<string, string>
    bodyKey: string
    bodyValues?: Record<string, string>
  }
}

type toasterType = "error" | "info" | "warning" | "success"

export type ToasterErrorAction = {
  type: string
  withoutToast: boolean
  error: {
    config: {
      method: string
      url: string
    }
    message: string
  }
}

export type ToasterAction = ToasterErrorAction & ToasterMessageAction

export const notificationMiddleware =
  (_: unknown) =>
  (next: (action: ToasterAction) => void) =>
  (action: ToasterAction) => {
    if (!action) {
      return
    }
    if (action.error && !action.withoutToast) {
      showDefaultErrorToaster(action)
      return next(action)
    }

    const toasterType = action.toasterType || "success"

    if (action.withToast) {
      if (toasterType === "success") {
        toast.success(() => <ToasterContent action={action} />, {
          icon: () => {
            return <Check />
          },
        })
      }
      if (toasterType === "error") {
        toast.error(() => <ToasterContent action={action} />, {
          icon: () => {
            return <Remove />
          },
        })
      }
      if (toasterType === "info") {
        toast.info(() => <ToasterContent action={action} />, {
          icon: () => {
            return <InfoFull />
          },
        })
      }
      if (toasterType === "warning") {
        toast.warning(() => <ToasterContent action={action} />, {
          icon: () => {
            return <Warning />
          },
        })
      }
    }
    return next(action)
  }

const ToasterContent = ({ action }: { action: ToasterMessageAction }) => {
  const intl = createIntl({
    locale: "fr",
    messages: translations,
  })
  return (
    <>
      <h4 data-cy={"toast-title"}>
        {
          <b>
            {intl.formatMessage(
              { id: action.message.titleKey },
              action.message.titleValues
            )}
          </b>
        }
      </h4>
      <p data-cy={"toast-message"}>
        {intl.formatMessage(
          { id: action.message.bodyKey },
          action.message.bodyValues
        )}
      </p>
    </>
  )
}

const showDefaultErrorToaster = (action: ToasterErrorAction) => {
  toast.error(
    () => (
      <>
        <h4>
          {<b>{action.error.config?.method.toUpperCase() || action.type}</b>}
          &nbsp;&nbsp;
          {<b>{action.error.config?.url}</b>}
        </h4>
        <p>{action.error.message}</p>
      </>
    ),
    {
      icon: () => {
        return <Remove />
      },
    }
  )
}
