import ReactCreatable from "react-select/creatable"
import styled from "styled-components/macro"
import { components, SingleValue } from "react-select"
import { ReactComponent as Down } from "../../assets/down.svg"
import { colors } from "../../styles/design.config"
import { useState } from "react"
import * as Ct from "ldlj"

export interface selectOption {
  value: string
  label: string
}

export interface creatableSelectProps {
  options: Array<Ct.Option<string>>
  value: Ct.Option<string> | null
  disabled: boolean
  onChangeCallback?: (option: Ct.Option<string> | null) => void
  onCreateOption: (text: string) => void
  placeholderText: string
  formatCreateLabel: string
  inputId?: string
  error?: boolean
}

export const CreatableSelect = (props: creatableSelectProps) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <StyledCreatableContainer
      disabled={props.disabled}
      isFocused={true}
      error={props.error}
    >
      <ReactCreatable
        isDisabled={props.disabled}
        value={props.value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        options={props.options}
        onChange={(e: SingleValue<Ct.Option<string>>) =>
          props.onChangeCallback && props.onChangeCallback(e)
        }
        onCreateOption={props.onCreateOption}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: StyledDropDownIndicator,
        }}
        formatCreateLabel={(inputValue) =>
          `${props.formatCreateLabel} ${inputValue}`
        }
        id={"creatableSelect"}
        inputId={props.inputId}
        isOptionDisabled={(option) => option.disabled as boolean}
      />
      <Ct.InputMovingPlaceholder
        hasValue={props.value !== null}
        disabled={props.disabled}
        isFocused={isFocused}
      >
        {" "}
        {props.placeholderText}{" "}
      </Ct.InputMovingPlaceholder>
    </StyledCreatableContainer>
  )
}

interface StyledCreatableContainerProps {
  disabled: boolean
  isFocused: boolean
  error?: boolean
}

const StyledCreatableContainer = styled.div<StyledCreatableContainerProps>`
  position: relative;
  width: 100%;

  & div > [class*="control"] {
    flex-grow: 1;
    color: ${colors.navy};
    box-shadow: unset;
    height: 6rem;
    transition: border 0.3s ease-in-out;
    outline: none;
    border-radius: 1.25rem;
    font-size: 1.75rem;
    width: 100%;
    box-sizing: border-box;
    border: ${({ disabled, isFocused, error }) =>
      disabled
        ? `1px solid ${colors.hawkes}`
        : error
        ? `1px solid ${colors.amaranth}`
        : `1px solid ${isFocused ? colors.cornflower : colors.rock}`};
  }

  & label {
    color: ${({ error }) =>
      error ? `${colors.amaranth}` : `${colors.cornflower}`};
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Down />
    </components.DropdownIndicator>
  )
}
const StyledDropDownIndicator = styled((props) => (
  <DropdownIndicator {...props} />
))`
  svg {
    transition: 0.5s fill ease-in-out;
    fill: ${({ disabled, isFocused }) =>
      disabled
        ? `${colors.hawkes}`
        : isFocused
        ? `${colors.cornflower}`
        : `${colors.rock}`};
  }
  :hover {
    cursor: pointer;
    svg {
      fill: ${colors.cornflower};
    }
  }
`
