import React, {
  Suspense,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react"
import styled, { createGlobalStyle } from "styled-components/macro"
import * as Ct from "ldlj"
import { ValueType, RangeType } from "rsuite/esm/DateRangePicker/types"

import { boxShadow, colors, gradients } from "../../styles/design.config"
import { ReactComponent as Chevron } from "../../assets/chevron-down-bigger.svg"
import { locale, optionsConfig } from "../../utils/date"

interface TreasuryInputDateProps {
  value: [Date, Date]
  onChange: (value: ValueType, event: SyntheticEvent<Element, Event>) => void
  minimum?: Date
  maximum?: Date
  predefinedRanges: RangeType[]
}

const DateRangePicker = React.lazy(() => import("rsuite/DateRangePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

export const TreasuryInputDateRange = (props: TreasuryInputDateProps) => {
  const { value, onChange, maximum, minimum } = props
  const [labelValue, setLabelValue] = useState<string>("Mois en cours")
  const [calendarToogle, setCalendarToogle] = useState<boolean>(false)
  const findValue = () => {
    const options = optionsConfig

    const foundDate = props.predefinedRanges.find((range: RangeType) => {
      const rangeValues = range.value as [Date, Date]
      return (
        // @ts-ignore next-line
        rangeValues.toLocaleString("fr-Fr", options) ===
        // @ts-ignore next-line
        value.toLocaleString("fr-Fr", options)
      )
    })
    return String(foundDate?.label) || "Mois en cours"
  }
  const disabledDate = (date: Date): boolean => {
    if (minimum && maximum) {
      const startDate = minimum
      const endDate = maximum
      return date < startDate || date > endDate
    }
    return false
  }

  useEffect(() => {
    const foundDate = findValue()
    if (foundDate !== "undefined" && foundDate) setLabelValue(foundDate)
    else {
      const formatedDate = value.map((date) =>
        date.toLocaleString().slice(0, 10)
      )
      setLabelValue(formatedDate.join(" - "))
    }
    if (calendarToogle) setCalendarToogle(false)
  }, [value])

  const wrapperRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleDocumentMouseDown = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setCalendarToogle(false)
      }
    }
    document.addEventListener("mousedown", handleDocumentMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown)
    }
  }, [])

  const handlePickerMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <StyledDiv ref={wrapperRef} onClick={() => setCalendarToogle(true)}>
      <Wrapper>
        <StyledLabel>
          <TextWrapper>
            <Ct.Text
              text={"Filtrer :"}
              textStyle={{
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "slateGrey",
              }}
            />
            <Ct.Spacer width={0.5} />
            <Ct.Text
              text={labelValue}
              textStyle={{
                fontFamily: "Roboto",
                fontWeight: 500,
                color: "navy",
              }}
            />
          </TextWrapper>
          <SeparatorBar />
          <IconChevronWrapper>
            <StyledChevronSquare rotation={calendarToogle ? 1 : 0} />
          </IconChevronWrapper>
        </StyledLabel>
        <StyledCalendar />
        <CalendarWraper ref={pickerRef} onMouseDown={handlePickerMouseDown}>
          <Suspense fallback={<Ct.SpinningLoader />}>
            <CustomProvider locale={locale}>
              <DateRangePicker
                ranges={props.predefinedRanges}
                style={{ width: 160 }}
                value={value}
                isoWeek={true}
                onChange={onChange}
                onClose={() => setCalendarToogle(!calendarToogle)}
                disabledDate={disabledDate}
              />
            </CustomProvider>
          </Suspense>
        </CalendarWraper>
      </Wrapper>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  width: 33rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  background-color: ${colors.white};
  font-weight: 600;
  font-size: 3.25rem;
  box-shadow: ${boxShadow};

  & > div {
    position: relative;
  }
`
const IconChevronWrapper = styled.div`
  width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-self: end;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const SeparatorBar = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${colors.rock};
`
const StyledChevronSquare = styled(Chevron)<{ rotation: number }>`
  transition-duration: 0.2s;
  transform: ${({ rotation }) => (rotation ? "rotate(180deg)" : ``)};
  fill: ${colors.cornflower};
`
const StyledLabel = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  z-index: 10;
  border-radius: 5px;
`
const StyledCalendar = createGlobalStyle`
  .rs-picker-daterange-header {
    display: none;
  }

  .rs-picker-toolbar {
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    border-top: none;
    border-left: 1px solid ${colors.moon}
  }

  .rs-picker-daterange-panel {
    display: flex;
    flex-direction: row;
    padding: 2rem;
  }

  .rs-picker-toolbar-ranges {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -1rem;
  }

  .rs-picker-toolbar-right {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-content: center;
    margin-top: 3rem;
  }

  .calendar-container {
    position: relative;
  }

  .rs-picker-default .rs-picker-toggle {
    border: none;
  }

  .rs-picker-daterange, .rs-picker-toggle.rs-btn, .rs-picker-toggle-caret {
    color: transparent;
  }

  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    width: 26rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .rs-picker-toggle-textbox {
    display: none ;
    pointer-events: none;
  }

  .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: transparent;
  }

  .rs-picker-toggle-clean, .rs-btn-close {
    display: none;
    color: transparent;
  }

  .rs-btn-link {
    color: ${colors.navy}
  }

  .rs-btn-primary {
    background: ${gradients.blue};
    font-weight: 500;
  }

  .rs-btn-link.rs-btn-disabled, .rs-btn-link:disabled, .rs-btn.rs-btn-disabled, .rs-btn:disabled {
    opacity: 1;
    font-weight: 500;
    cursor: auto;
    color: ${colors.white}
  }
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const TextWrapper = styled.div`
  width: 30rem;
  display: flex;
  justify-self: flex-start;
  padding-left: 0.5rem;
`
const CalendarWraper = styled.div`
  display: flex;
`
