import { ReactComponent as BankCardPaidIcon } from "../../../../assets/treasury-bank-card-checked-paid.svg"
import { ReactComponent as ToReceiveHourGlass } from "../../../../assets/treasury-hourglass.svg"
import { ReactComponent as IrrecoverableCross } from "../../../../assets/treasury-cross.svg"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { isRouteSelected } from "../../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"
import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import { getIdFromParams } from "../../../../utils/company"
import { useDispatch } from "react-redux"
import {
  TreasuryFullDocuments,
  getTreasuryFullDocumentsThunk,
} from "../../../../store/ducks/treasury.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { StyledWarning, allTime, lateDate } from "../../../../utils/treasury"

/* eslint-disable camelcase */

export const TreasurySell = () => {
  const intl = useIntl()
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/invoicing\\/settings"
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  const [toReceiveCount, setToReceiveCount] = useState<number>(0)
  const fullDocuments = useRNBSelector((state) => state.treasury.dashboard)
  const needWarning = (fullDocs: TreasuryFullDocuments[]): boolean => {
    if (!fullDocs || fullDocs.length === 0) return false
    if (
      fullDocs.some(
        (doc) =>
          (doc.document_due_date &&
            new Date(doc.document_due_date) <= lateDate) ||
          (doc.document_date && new Date(doc.document_date) <= lateDate)
      )
    )
      return true
    return false
  }
  const navigate = useNavigate()
  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const dateRange = useRNBSelector((state) => state.treasury.range)
  const filterDocuments = (fds: TreasuryFullDocuments[]) => {
    if (dateRange === allTime) return fds
    return fds.filter((fd) =>
      fd.document_due_date
        ? fd.document_due_date <= dateRange[1].toISOString().slice(0, 10)
        : fd.document_date <= dateRange[1].toISOString().slice(0, 10)
    )
  }

  useEffect(() => {
    if (fiduciary.status === "UNREGISTERED") return
    if (
      fiduciary.status === "SUCCESS" &&
      (fiduciary.active_pricing?.pricing_type !== "per_document" ||
        (fiduciary.active_pricing?.pricing_type === "per_document" &&
          fiduciary.override_pricing))
    )
      return
    navigate("/unauthorized")
  }, [fiduciary])

  useEffect(() => {
    dispatch(getTreasuryFullDocumentsThunk(companyId, "sell"))
  }, [])

  useEffect(() => {
    if (!fullDocuments) return
    if (fullDocuments.to_receive)
      setToReceiveCount(filterDocuments(fullDocuments.to_receive).length)
    else setToReceiveCount(0)
  }, [toReceiveCount, fullDocuments, dateRange, dispatch])

  const linkTabs: TabObject[] = [
    {
      to: "to_receive",
      title:
        intl.formatMessage({ id: "menu.treasury.sell.to_receive" }) +
        ` (${toReceiveCount})`,
      icon: needWarning(fullDocuments.to_receive) ? (
        <StyledWarning />
      ) : (
        <ToReceiveHourGlass />
      ),
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "to_receive"),
      colored: needWarning(fullDocuments.to_receive),
    },
    {
      to: "received",
      title: intl.formatMessage({ id: "menu.treasury.sell.received" }),
      icon: <BankCardPaidIcon />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "received"),
    },
    {
      to: "irrecoverable",
      title: intl.formatMessage({ id: "menu.treasury.sell.irrecoverable" }),
      icon: <IrrecoverableCross />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "irrecoverable"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
        Link={Link}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}
