import { Outlet, Route, Routes } from "react-router-dom"
import styled from "styled-components/macro"
import * as Sentry from "@sentry/react"
import { MenuSidebar } from "../../components/MenuSidebar"
import { OfficeHeader } from "./OfficeHeader"
import { TopBar } from "../../components/topBars/MainTopBar"
import { ManagementTopBar } from "../../components/topBars/ManagementTopBar"
import { CompanyTopBar } from "../../components/topBars/CompanyTopBar"
import { FiduciaryTopBar } from "../../components/topBars/FiduciayTopBar"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect } from "react"
import ErrorBoundary from "../../errorboundary"
import { Background } from "../../components/Background"

const Office = () => {
  const userEmail = useRNBSelector((state) => state.user.email)
  const userFirstName = useRNBSelector((state) => state.user.firstName)
  const userLastName = useRNBSelector((state) => state.user.lastName)
  const userTypology = useRNBSelector((state) => state.user.typology)

  useEffect(() => {
    Sentry.setUser({
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      typology: userTypology,
    })
  }, [userEmail, userFirstName, userLastName, userTypology])

  return (
    <Background centeredTop={false} padding={0}>
      <Page>
        <MenuSidebar />
        <Content>
          <ErrorBoundary>
            <Routes>
              <Route element={<OfficeHeader />}>
                <Route index element={<TopBar />} />
                <Route path="initialization" element={<TopBar />} />
                <Route path="management" element={<ManagementTopBar />} />
                <Route path="company/*" element={<CompanyTopBar />} />
                <Route path="fiduciary/*" element={<FiduciaryTopBar />} />
              </Route>
            </Routes>
          </ErrorBoundary>
          <Outlet />
        </Content>
      </Page>
    </Background>
  )
}

export default Office

const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 5rem 0 0;
  @media (max-height: 960px) {
    padding: 3rem 0 0;
  }
`
