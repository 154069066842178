import * as Ct from "ldlj"
import React, { useEffect } from "react"
import { useLocation, useNavigate, useRoutes } from "react-router-dom"
import { useDispatch } from "react-redux"
import { IntlProvider } from "react-intl"
import { translations } from "./i18n"
import { ConfigureAxios } from "./axios.config"
import { routes } from "./routes/router"
import { useRNBSelector } from "./store/rootReducer"
import { getUserAllAccessThunk } from "./store/ducks/user.ducks"
import ErrorBoundary from "./errorboundary"
import { StyledToastContainer } from "./components/Toaster"

interface ChildrenProp {
  children: React.ReactNode
}

export const CustomIntlProvider = ({ children }: ChildrenProp) => (
  <IntlProvider
    locale="fr"
    messages={translations}
    defaultRichTextElements={{
      boldBlack: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{ color: "navy", fontWeight: 700 }}
        />
      ),
      boldColored: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{ color: "cornflower", fontWeight: 500 }}
        />
      ),
      bolderColored: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{ color: "cornflower", fontWeight: 700 }}
        />
      ),
      boldColoredTitle: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            color: "cornflower",
            lineHeight: 3,
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 3,
          }}
        />
      ),
      boldColoredLarger: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            color: "cornflower",
            lineHeight: 3,
            fontWeight: 700,
            fontSize: 2.25,
          }}
        />
      ),
      boldItalicGrey: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontStyle: "italic",
            fontSize: 2,
            color: "slateGrey",
          }}
        />
      ),
      boldSmallGrey: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontStyle: "italic",
            fontSize: 2,
            color: "lightBlueGrey",
          }}
        />
      ),
      italicColored: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            fontFamily: "Roboto",
            fontStyle: "italic",
            color: "cornflower",
          }}
        />
      ),
      boldUnderlineColored: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            color: "cornflower",
            fontWeight: 500,
            underline: true,
            fontSize: 2.25,
          }}
        />
      ),
      underlineColored: (chunks) => (
        <Ct.Text
          text={chunks as unknown as string}
          textStyle={{
            color: "cornflower",
            underline: true,
          }}
        />
      ),
      b: (chunks) => <b>{chunks}</b>,
      br: () => <br />,
      p: (chunks) => <p>{chunks}</p>,
      i: (chunks) => <i>{chunks}</i>,
      dot: (chunks) => <b>&#8226;</b>,
      nbsp: (chunks) => <span>&nbsp;</span>,
    }}
  >
    {children}
  </IntlProvider>
)

const UserPermissionRedirection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const redirectionDirective = useRNBSelector((state) => {
    return state.user.redirection
  })
  const user = useRNBSelector((state) => {
    return state.user
  })

  useEffect(() => {
    if (user.typology !== "customer" && user.status === "SUCCESS") {
      // zendesk connexion script only for connected non customers users
      const script = document.createElement("script")
      script.id = "ze-snippet"
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=87da3ba8-b8e8-4c1d-863a-533c7d5a6772`
      document.body.appendChild(script)
    }
  }, [user.typology, user.status])

  useEffect(() => {
    dispatch(getUserAllAccessThunk())
  }, [dispatch, location, navigate])

  useEffect(() => {
    if (
      redirectionDirective.directive === "ACCESS_DENIED" &&
      location.pathname !== "/access_denied"
    ) {
      navigate("/access_denied")
    } else if (
      redirectionDirective.directive === "FIRST_CONNEXION" &&
      location.pathname !== "/user/incomplete_registration"
    ) {
      navigate("/user/incomplete_registration")
    } else if (
      (user.typology === "administrator" || user.typology === "manager") &&
      redirectionDirective.directive === "TERMS_OF_SALES_NOT_ACCEPTED" &&
      location.pathname !== "/user/accept_terms_and_conditions"
    ) {
      navigate("/user/accept_terms_and_conditions")
    } else if (
      redirectionDirective.directive === "TERMS_OF_SERVICE_NOT_ACCEPTED" &&
      location.pathname !== "/user/accept_terms_and_conditions"
    ) {
      navigate("/user/accept_terms_and_conditions")
    } else if (
      redirectionDirective.directive === "FIDUCIARY_NOT_CREATED" &&
      location.pathname !== "/fiduciary/register"
    ) {
      navigate("/fiduciary/register")
    }
  }, [navigate, location.pathname, redirectionDirective.directive])
  return <></>
}

function RNB() {
  ConfigureAxios()
  const element = useRoutes(routes)
  return (
    <ErrorBoundary>
      <CustomIntlProvider>
        {element}
        <StyledToastContainer data-cy="toast-error" hideProgressBar={true} />
        <UserPermissionRedirection />
      </CustomIntlProvider>
    </ErrorBoundary>
  )
}

export default RNB
