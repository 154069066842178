import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Text } from "../Commons/Text"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { SoftwareEvent } from "../../store/ducks/companySettings.ducks"
import { DateTime } from "luxon"
import { getHistoryValues } from "../../utils/company"

interface SoftwareHistoryProps extends Ct.ModalComponentsProps {
  softwareEvents: SoftwareEvent[] | []
}

export const SoftwareHistoryModal = ({
  isDisplayed,
  onClose,
  softwareEvents,
}: SoftwareHistoryProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="10%"
    >
      <Ct.Card width="80vw">
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={2} />
        <Ct.Title
          text={intl.formatMessage({ id: "ged.client.modal.title" })}
          size={7}
        />
        <Ct.Spacer height={2} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            <Text
              text={intl.formatMessage({
                id: `office-company-change-software.history.title`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
          </ChangeHeader>

          {softwareEvents.length > 0 ? (
            <ChangesList>
              {softwareEvents.map((event) => (
                <ChangeItem key={event.date}>
                  <Text
                    text={intl.formatMessage({
                      id: `office-company-change-software.history.change`,
                    })}
                  />

                  <Mailto href={`mailto:${event.email}`}>{event.email}</Mailto>

                  <Text
                    text={
                      " (" +
                      DateTime.fromJSDate(new Date(event.date)).toFormat(
                        "dd/MM/yyyy HH:mm:ss"
                      ) +
                      ")."
                    }
                  />

                  <Text
                    text={" (" + (getHistoryValues(event.metadata) || "") + ")"}
                    textStyle={{
                      fontWeight: 700,
                    }}
                  />
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `office-company-change-software.history.empty`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>
      </Ct.Card>
    </Ct.Modal>
  )
}
