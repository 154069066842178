/* eslint-disable camelcase */
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import styled, { css, keyframes } from "styled-components/macro"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Ct from "ldlj"
import { getIdFromParams } from "../utils/company"
import { ReactComponent as ParamsLine } from "../assets/params-line.svg"
import { ReactComponent as ParamsSolid } from "../assets/params-solid.svg"
import { ReactComponent as Info } from "../assets/info-line.svg"
import { ReactComponent as BankLine } from "../assets/bank-line.svg"
import { ReactComponent as BankSolid } from "../assets/bank-solid.svg"
import { ReactComponent as FileUploadLine } from "../assets/file-upload-line.svg"
import { ReactComponent as FileUploadSolid } from "../assets/file-upload-solid.svg"
import { ReactComponent as FileDownloadLine } from "../assets/file-download-line.svg"
import { ReactComponent as FileDownloadSolid } from "../assets/file-download-solid.svg"
import { ReactComponent as FileLine } from "../assets/file-search-line.svg"
import { ReactComponent as FileSolid } from "../assets/file-search-solid.svg"
import { ReactComponent as InvoicingLine } from "../assets/file-invoice-line.svg"
import { ReactComponent as InvoicingSolid } from "../assets/file-invoice-solid-navbar.svg"
import { ReactComponent as ChevronUp } from "../assets/chevron-up.svg"
import { ReactComponent as List } from "../assets/list.svg"
import { ReactComponent as Treasury } from "../assets/treasury-menu.svg"
import { MenuItem } from "./Commons/MenuItem"

import { useRNBSelector } from "../store/rootReducer"
import {
  generateEaseInOut,
  generateEaseInOutReverse,
} from "../utils/keyframesGenerator"
import { ReactComponent as Circle } from "../assets/circle.svg"
import { isRouteSelected } from "../utils/routes"
import { colors } from "../styles/design.config"
import { useDispatch } from "react-redux"
import { getCurrentUserPermissionsThunk } from "../store/ducks/treasury.ducks"

export const CompanyMenu = () => {
  const location = useLocation()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const intl = useIntl()
  const userState = useRNBSelector((state) => state.user)
  const loadingMerchantsStatus = useRNBSelector(
    (state) => state.merchants.loading_status
  )

  const [toggleMenuInvoicing, setToggleMenuInvoicing] = useState<
    boolean | null
  >(null)

  const [toogleMenuTreasury, setToogleMenuTreasury] = useState<boolean | null>(
    null
  )

  const linkToBuilder = (destinationPath: string) => {
    return `company/${selectedCompanyId}/${destinationPath}`
  }
  const { pricingType, override_pricing } = useRNBSelector((state) => ({
    pricingType: state.fiduciary.active_pricing?.pricing_type,
    override_pricing: state.fiduciary.override_pricing,
  }))

  const settingsLink =
    userState.typology === "customer" ? "settings/informations" : "settings"

  const routePattern = "\\/company\\/\\d+"

  const invoicingSelected = isRouteSelected(location, routePattern, "invoicing")
  const treasurySelected = isRouteSelected(location, routePattern, "treasury")

  const [invoicingIconMenu, setInvoicingIconMenu] = useState<
    React.SVGProps<SVGSVGElement>
  >(<ChevronRotate rotation={1} selected={invoicingSelected} />)

  const [treasuryIconMenu, setTreasuryIconMenu] = useState<
    React.SVGProps<SVGSVGElement>
  >(<ChevronRotate rotation={1} selected={treasurySelected} />)

  const dispatch = useDispatch()

  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )

  useEffect(() => {
    dispatch(getCurrentUserPermissionsThunk(selectedCompanyId))
  }, [dispatch])

  useEffect(() => {
    setInvoicingIconMenu(
      <ChevronRotate
        rotation={toggleMenuInvoicing !== true ? 1 : 0}
        selected={invoicingSelected}
      />
    )
  }, [invoicingSelected, toggleMenuInvoicing])

  useEffect(() => {
    setTreasuryIconMenu(
      <ChevronRotate
        rotation={toogleMenuTreasury !== true ? 1 : 0}
        selected={invoicingSelected}
      />
    )
  }, [treasurySelected, toggleMenuInvoicing])

  return (
    <section>
      <Ct.Spacer height={2} />

      <MenuItem
        useNavigate={useNavigate}
        label={intl.formatMessage({ id: "menu.drop" })}
        selected={isRouteSelected(location, routePattern, "drop")}
        linkTo={linkToBuilder("drop")}
        iconIdle={<FileUploadLine />}
        iconSelected={<FileUploadSolid />}
      />
      {userState.typology !== "customer" && (
        <>
          <Ct.Spacer height={2} />
          <MenuItem
            useNavigate={useNavigate}
            label={intl.formatMessage({ id: "menu.writings" })}
            selected={isRouteSelected(location, routePattern, "writings")}
            linkTo={linkToBuilder("writings")}
            iconIdle={<FileDownloadLine />}
            iconSelected={<FileDownloadSolid />}
          />
        </>
      )}

      <Ct.Spacer height={2} />

      <MenuItem
        useNavigate={useNavigate}
        label={intl.formatMessage({ id: "bank.menu" })}
        selected={isRouteSelected(location, routePattern, "bank/accounts")}
        linkTo={linkToBuilder("bank/accounts")}
        iconIdle={<BankLine />}
        iconSelected={<BankSolid />}
        disabled={pricingType === "per_document" && !override_pricing}
      />
      <Ct.Spacer height={2} />

      <MenuItem
        useNavigate={useNavigate}
        label={intl.formatMessage({ id: "menu.edm" })}
        selected={isRouteSelected(location, routePattern, "edm")}
        linkTo={linkToBuilder("edm/buy")}
        iconIdle={<FileLine />}
        iconSelected={<FileSolid />}
      />

      <Ct.Spacer height={2} />

      {userState.typology !== "customer" && (
        <>
          <MenuItem
            useNavigate={useNavigate}
            label={intl.formatMessage({ id: "menu.accounting-plan" })}
            selected={isRouteSelected(
              location,
              routePattern,
              "accounting_plan"
            )}
            linkTo={linkToBuilder("accounting_plan")}
            iconIdle={<List />}
            iconSelected={<List />}
          />

          <Ct.Spacer height={2} />
        </>
      )}

      <div
        onClick={() => {
          const newInvoicingToggle = !toggleMenuInvoicing
          setToggleMenuInvoicing(newInvoicingToggle)
          if (newInvoicingToggle) {
            setInvoicingIconMenu(
              <ChevronRotate
                rotation={newInvoicingToggle !== true ? 1 : 0}
                selected={invoicingSelected}
              />
            )
          } else {
            setInvoicingIconMenu(
              <ChevronRotate
                rotation={newInvoicingToggle === false ? 1 : 0}
                selected={invoicingSelected}
              />
            )
          }
        }}
      >
        <Icon>
          <MenuItem
            useNavigate={useNavigate}
            label={intl.formatMessage({ id: "menu.invoicing" })}
            selected={isRouteSelected(location, routePattern, "invoicing")}
            iconIdle={<InvoicingLine />}
            iconSelected={<StyledInvoicingSolid />}
            iconMenu={invoicingIconMenu}
          />
        </Icon>
      </div>
      <ChildMenu expandMenu={toggleMenuInvoicing} nestedItemsNumber={3}>
        <StyledCircle
          selectedindex={[
            "invoicing/navigation",
            "invoicing/documents",
            "invoicing/settings",
          ].findIndex((route) =>
            isRouteSelected(location, routePattern, route)
          )}
        />
        <Ct.NestedMenuItem
          useNavigate={useNavigate}
          label={intl.formatMessage({ id: "menu.invoicing.navigation" })}
          selected={isRouteSelected(
            location,
            routePattern,
            "invoicing/navigation"
          )}
          linkTo={linkToBuilder("invoicing/navigation")}
          blocked={loadingMerchantsStatus === "loading"}
        />
        <Ct.NestedMenuItem
          useNavigate={useNavigate}
          label={intl.formatMessage({ id: "menu.invoicing.documents" })}
          selected={isRouteSelected(
            location,
            routePattern,
            "invoicing/documents"
          )}
          linkTo={linkToBuilder("invoicing/documents")}
          blocked={loadingMerchantsStatus === "loading"}
        />
        <Ct.NestedMenuItem
          useNavigate={useNavigate}
          label={intl.formatMessage({ id: "menu.invoicing.settings" })}
          selected={isRouteSelected(
            location,
            routePattern,
            "invoicing/settings"
          )}
          linkTo={linkToBuilder("invoicing/settings")}
          blocked={loadingMerchantsStatus === "loading"}
        />
      </ChildMenu>

      <>
        <div
          onClick={() => {
            if (pricingType === "per_document" && !override_pricing) return
            const newTreasuryToogle = !toogleMenuTreasury
            setToogleMenuTreasury(newTreasuryToogle)
            if (newTreasuryToogle) {
              setTreasuryIconMenu(
                <ChevronRotate rotation={newTreasuryToogle !== true ? 1 : 0} />
              )
            } else {
              setTreasuryIconMenu(
                <ChevronRotate rotation={newTreasuryToogle === false ? 1 : 0} />
              )
            }
          }}
        >
          <Icon>
            <MenuItem
              useNavigate={useNavigate}
              label={intl.formatMessage({ id: "menu.treasury" })}
              selected={isRouteSelected(location, routePattern, "treasury")}
              iconIdle={<Treasury />}
              iconSelected={<Treasury />}
              iconMenu={treasuryIconMenu}
              disabled={pricingType === "per_document" && !override_pricing}
            />
          </Icon>
        </div>
        <ChildMenu expandMenu={toogleMenuTreasury} nestedItemsNumber={3}>
          <StyledCircle
            selectedindex={[
              "treasury/buy/",
              "treasury/sell/",
              "treasury/settings/",
            ].findIndex((route) =>
              isRouteSelected(location, routePattern, route)
            )}
          />
          <Ct.NestedMenuItem
            useNavigate={useNavigate}
            label={intl.formatMessage({ id: "menu.treasury.buy" })}
            selected={isRouteSelected(location, routePattern, "treasury/buy")}
            linkTo={linkToBuilder("treasury/buy")}
            blocked={pricingType === "per_document" && !override_pricing}
          />
          <Ct.NestedMenuItem
            useNavigate={useNavigate}
            label={intl.formatMessage({ id: "menu.treasury.sell" })}
            selected={isRouteSelected(location, routePattern, "treasury/sell")}
            linkTo={linkToBuilder("treasury/sell")}
            blocked={pricingType === "per_document" && !override_pricing}
          />
          {(userState.typology === "customer" ||
            userState.typology === "customer_accountant" ||
            currentUserPermissions.marked_as_appearing_in_permissions_list) && (
            <Ct.NestedMenuItem
              useNavigate={useNavigate}
              label={intl.formatMessage({ id: "menu.treasury.settings" })}
              selected={isRouteSelected(
                location,
                routePattern,
                "treasury/settings"
              )}
              linkTo={linkToBuilder("treasury/settings")}
              blocked={pricingType === "per_document" && !override_pricing}
            />
          )}
        </ChildMenu>
      </>

      <MenuItem
        useNavigate={useNavigate}
        label={
          userState.typology === "customer"
            ? intl.formatMessage({ id: "menu.informations" })
            : intl.formatMessage({ id: "menu.settings" })
        }
        selected={isRouteSelected(location, routePattern, "settings")}
        linkTo={linkToBuilder(settingsLink)}
        iconIdle={userState.typology === "customer" ? <Info /> : <ParamsLine />}
        iconSelected={
          userState.typology === "customer" ? <Info /> : <ParamsSolid />
        }
      />
    </section>
  )
}

const StyledCircle = styled(Circle)<{ selectedindex: number }>`
  position: relative;
  opacity: ${({ selectedindex }) => (selectedindex === -1 ? "0" : "1")};
  left: -0.4rem;
  top: ${({ selectedindex }) =>
    selectedindex === -1 ? "2.5rem" : `${selectedindex * 4 + 2.5}rem`};
  transition: top 0.35s cubic-bezier(0.32, 1.77, 0.5, 0.75) 0.2s,
    opacity 0.2s ease-in-out;

  @media (max-height: 960px) {
    left: -0.5rem;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 2rem;
`
const ChildMenu = styled.ul<{
  expandMenu: boolean | null
  nestedItemsNumber: number
}>`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;

  overflow: hidden;

  opacity: ${({ expandMenu }) => `${expandMenu ? "1" : "0"}`};
  transition: opacity 0.6s ease-in-out;

  height: ${({ expandMenu }) => `${expandMenu ? "auto" : "1rem"}`};
  animation: ${({ expandMenu, nestedItemsNumber }) => {
    const easeInOutElastic = keyframes`
    ${generateEaseInOut(nestedItemsNumber * 4)}
    `
    const easeInOutElasticReverse = keyframes`
    ${generateEaseInOutReverse(nestedItemsNumber * 4)}
    `
    return expandMenu
      ? css`0.6s ${easeInOutElastic}`
      : expandMenu === false
      ? css`0.6s ${easeInOutElasticReverse}`
      : ""
  }};
`

interface RotationProps {
  rotation: number
  selected?: boolean
}

const ChevronRotate = styled(ChevronUp)<RotationProps>`
  transition-duration: 0.2s;
  transform: ${({ rotation }) => (rotation ? "rotate(180deg)" : ``)};

  & path:nth-of-type(1) {
    fill: ${({ selected }) => (selected ? colors.white : colors.cornflower)};
  }
  background-color: ${({ selected }) =>
    selected ? colors.white : "transparent"};
  border-radius: 50%;
`

const StyledInvoicingSolid = styled(({ ...props }) => (
  <InvoicingSolid {...props} />
))`
  & path:nth-of-type(2) {
    fill: ${colors.cornflower};
  }
`
