import * as Ct from "ldlj"
import { Text } from "../Commons/Text"
import { useIntl } from "react-intl"
import { Select } from "../Commons/Select"
import {
  AccountingSoftware,
  AccountingSoftwareArray,
} from "../../utils/company"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Button } from "../Commons/Button"
import { useRNBSelector } from "../../store/rootReducer"
import { useDispatch } from "react-redux"
import { UpdateDefaultAccountingSoftwareThunk } from "../../store/ducks/fiduciary.ducks"

export const ChangeSoftwareFiduciaryModal = ({
  isDisplayed,
  onClose,
}: Ct.ModalComponentsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const fiduciary = useRNBSelector((state) => state.fiduciary)

  const [selectedSoftware, setSelectedSoftware] = useState<{
    value: string
    label: string
  } | null>(null)

  const softwareOptions: { value: AccountingSoftware; label: string }[] =
    AccountingSoftwareArray.filter((soft) => soft !== "unknown")
      .map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

  useEffect(() => {
    if (fiduciary.comptaSoftware && !selectedSoftware?.value) {
      const softwareOption = softwareOptions.find(
        (soft) => soft.value === fiduciary.comptaSoftware
      )
      if (softwareOption) {
        setSelectedSoftware(softwareOption)
      }
    }
  }, [fiduciary.comptaSoftware, dispatch])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="20%"
    >
      <Ct.Card width="70vw">
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={2} />
        <Text
          text={intl.formatMessage({
            id: `fiduciary-settings.change-software.title`,
          })}
          textStyle={{ fontSize: 5, fontWeight: 600 }}
        />
        <Ct.Spacer height={2} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={5} />

        <Wrapper>
          <Select
            options={softwareOptions}
            value={selectedSoftware}
            onChangeCallback={(e) => {
              setSelectedSoftware(e)
            }}
            intl={intl}
            label={""}
            domain={"office-company-change-software"}
            optionType={"new-software"}
            customWidth={"100%"}
          />

          <Ct.Spacer height={5} />

          <Button
            label={intl.formatMessage({
              id: "fiduciary-settings.change-software.confirm",
            })}
            width={45}
            disabled={selectedSoftware?.value === fiduciary.comptaSoftware}
            onClick={() => {
              if (
                selectedSoftware &&
                selectedSoftware.value !== fiduciary.comptaSoftware
              ) {
                dispatch(
                  UpdateDefaultAccountingSoftwareThunk(
                    fiduciary.id,
                    selectedSoftware?.value
                  )
                )
                onClose()
              }
            }}
          />
        </Wrapper>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Wrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
