import * as Ct from "ldlj"
import { CSSProperties, useEffect, useState } from "react"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as EyeFilled } from "../../../../../assets/eyeFilled.svg"
import { ReactComponent as Control } from "../../../../../assets/treasury-to-control.svg"
import { useIntl } from "react-intl"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../../components/Commons/Table"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../../utils/company"
import { useParams } from "react-router-dom"
import {
  getCurrentUserPermissionsThunk,
  getTreasuryFullDocumentHistoryThunk,
  setDateRange,
  TreasuryFullDocuments,
  updateFullDocumentPaymentStatusThunk,
} from "../../../../../store/ducks/treasury.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { Checkbox } from "../../../../../components/Commons/Checkbox"
import { truncateFileName } from "../../../../../utils/string"
import { TreasuryInputDateRange } from "../../../../../components/Commons/TreasuryInputDateRange"
import {
  HeadBand,
  StyledCenter,
  StyledLink,
  StyledSection,
  StyledTotalAmount,
  TreasuryHeaderWrapper,
  Wrapper,
  WrapperCalendar,
  WrapperTopBar,
  allTime,
  filterDocuments,
  predefinedRanges,
  sortFullDocumentsByDate,
} from "../../../../../utils/treasury"
import styled from "styled-components/macro"
import { colors } from "../../../../../styles/design.config"
import { getUrlForFullDocumentThunk } from "../../../../../store/ducks/invoicing.duck"
import { FullDocumentTreasuryHistoryModal } from "../../../../../components/treasury/FullDocumentTreasuryHistoryModal"
import { sortByDate } from "../../../../../utils/filesList"
import { Button } from "../../../../../components/Commons/Button"
import {
  GridWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../../components/dropDocuments/StyledDropsComponents"
import AutoSizer from "react-virtualized-auto-sizer"
import React from "react"
import { VariableSizeGrid } from "react-window"
import { Alert } from "../../../../../components/Commons/Alert"

export const Paid = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const currentUserId = useRNBSelector((state) => state.user.id)
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )
  const [search, setSearch] = useState("")
  const [fullDocumentsToDisplay, setfullDocumentsToDisplay] = useState<
    TreasuryFullDocuments[]
  >([])
  const fullDocuments = useRNBSelector((state) => {
    return state.treasury.dashboard.paid
  })
  const rowBackgroundColors = fullDocumentsToDisplay.map((fullDoc, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [fullDocumentChecked, setFullDocumentChecked] = useState<number[]>([])
  const [allFullDocumentsChecked, setAllFullDocumentsChecked] =
    useState<boolean>(false)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const range = useRNBSelector((state) => state.treasury.range)

  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false)

  const [calendarLabel, setCalendarLabel] = useState("")
  const handleCalendarChange = (value: [Date, Date]) => {
    dispatch(setDateRange(value))
    const finded = predefinedRanges.find((range) => range.value === value)
    setCalendarLabel(String(finded?.label))
  }
  const updateCheckBoxList = (id: number, amount: number) => {
    if (fullDocumentChecked.some((f) => f === id)) {
      setFullDocumentChecked((previous) =>
        previous.filter((elem) => elem !== id)
      )
      setTotalAmount((previousAmount) =>
        Math.abs(Number(previousAmount) - Number(amount))
      )
    } else {
      setFullDocumentChecked((previous) => [...previous, id])
      setTotalAmount((previousAmount) =>
        Math.abs(Number(previousAmount) + Number(amount))
      )
    }
  }
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight || !text) {
      return text
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase().includes(highlight.toLowerCase()) ||
              highlight.toLowerCase().includes(part.toLowerCase())
                ? {
                    background: "rgba(255, 197, 66, 0.3)",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </>
    )
  }
  const setFullDocumentsFilteredByRangeAndHighlight = (
    fullDocumentsArray: TreasuryFullDocuments[]
  ) => {
    setfullDocumentsToDisplay(
      sortFullDocumentsByDate(
        filterDocuments(search, fullDocumentsArray) || [],
        false
      )
    )
    setHighlightValues({ highlight: true, searchString: search })
  }
  const updateTopBarData = (fullDocumentId: number, amount: number) => {
    if (fullDocumentChecked.length !== 0) {
      setFullDocumentChecked(
        fullDocumentChecked.filter((fd) => fd !== fullDocumentId)
      )
      setTotalAmount(Math.abs(totalAmount - amount))
    }
  }
  const [fullDocumentSelected, setFullDocumentSelected] =
    useState<TreasuryFullDocuments | null>(null)
  const fullDocumentHistory = useRNBSelector(
    (state) => state.treasury.full_document_history
  )
  const [displayHistory, setDisplayHistory] = useState(false)
  const historyAction = (fullDocument: TreasuryFullDocuments) => {
    dispatch(getTreasuryFullDocumentHistoryThunk(fullDocument.id))
    setFullDocumentSelected(fullDocument)
    setDisplayHistory(true)
  }
  const noSearchDisplayFullDocuments = (
    fullDocuments: TreasuryFullDocuments[]
  ) => {
    setfullDocumentsToDisplay(sortFullDocumentsByDate(fullDocuments, false))
    setHighlightValues({ highlight: false, searchString: "" })
  }
  enum SortOptionsValues {
    "_",
    "due_date",
    "date",
    "payment_date",
    "merchants",
    "file_name",
    "amount",
  }

  const sorter = (asc: boolean, option: SortOptionsValues) => {
    const fullDocumentsSorted = [...fullDocumentsToDisplay]
    if (option === SortOptionsValues?.due_date) {
      fullDocumentsSorted.sort(
        (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
          asc
            ? sortByDate(a.document_due_date, b.document_due_date)
            : sortByDate(b.document_due_date, a.document_due_date)
      )
    } else if (option === SortOptionsValues?.date) {
      fullDocumentsSorted.sort(
        (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
          asc
            ? sortByDate(a.document_date, b.document_date)
            : sortByDate(b.document_date, a.document_date)
      )
    } else if (option === SortOptionsValues?.merchants) {
      fullDocumentsSorted.sort(
        (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
          asc
            ? a?.merchant_name.localeCompare(b?.merchant_name)
            : b?.merchant_name.localeCompare(a?.merchant_name)
      )
    } else if (option === SortOptionsValues?.file_name) {
      fullDocumentsSorted.sort(
        (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
          asc
            ? a?.user_file_name.localeCompare(b?.user_file_name)
            : b?.user_file_name.localeCompare(a?.user_file_name)
      )
    } else if (option === SortOptionsValues?.amount) {
      fullDocumentsSorted.sort(
        (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
          asc ? a.amount - b.amount : b.amount - a.amount
      )
    }
    setfullDocumentsToDisplay(fullDocumentsSorted)
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc, currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc, currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (!fullDocuments || fullDocuments.length === 0)
      return setFullDocumentsFilteredByRangeAndHighlight([])

    if (!range || calendarLabel === "Tout le temps")
      return search && search !== ""
        ? setFullDocumentsFilteredByRangeAndHighlight(fullDocuments)
        : noSearchDisplayFullDocuments(fullDocuments)

    const fullDocumentsDate =
      range === allTime
        ? fullDocuments
        : fullDocuments.filter((fd) =>
            fd.document_due_date
              ? fd.document_due_date <= range[1].toISOString().slice(0, 10)
              : fd.document_date <= range[1].toISOString().slice(0, 10)
          )

    return search && search !== ""
      ? setFullDocumentsFilteredByRangeAndHighlight(fullDocumentsDate)
      : noSearchDisplayFullDocuments(fullDocumentsDate)
  }, [fullDocuments, range, search])

  useEffect(() => {
    if (fullDocumentChecked.length === 0) setAllFullDocumentsChecked(false)
  }, [fullDocumentChecked])

  useEffect(() => {
    if (currentUserId && companyId && companyId !== 0)
      dispatch(getCurrentUserPermissionsThunk(companyId))
  }, [companyId, currentUserId])

  const listRef = React.createRef<VariableSizeGrid>()
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const onResize = () => {
    if (listRef.current !== null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    return (
      <div
        style={{
          ...style,
          backgroundColor: colors[rowBackgroundColors[rowIndex]],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        key={columnIndex}
      >
        {columns[columnIndex].content(fullDocumentsToDisplay[rowIndex])}
      </div>
    )
  }

  const columns = [
    {
      flexGrow: "5rem",
      headerIcon: (
        <div style={{ paddingLeft: "3.2rem" }}>
          <Checkbox
            label=""
            isChecked={
              allFullDocumentsChecked && fullDocumentChecked.length !== 0
            }
            value={allFullDocumentsChecked}
            onChange={() => {
              setAllFullDocumentsChecked(!allFullDocumentsChecked)

              if (allFullDocumentsChecked) {
                setFullDocumentChecked([])
                setTotalAmount(0)
              } else {
                setFullDocumentChecked(
                  fullDocumentsToDisplay.map((fullDocument) => fullDocument.id)
                )
                setTotalAmount(
                  Math.abs(
                    fullDocumentsToDisplay.reduce((acc, curr) => {
                      return Number(acc) + Number(curr.amount)
                    }, 0)
                  )
                )
              }
            }}
          />
        </div>
      ),
      content: (fullDocument: TreasuryFullDocuments) => (
        <div style={{ paddingLeft: "4rem" }}>
          <Checkbox
            id={String(fullDocument.id)}
            label={""}
            isChecked={fullDocumentChecked.some((f) => f === fullDocument.id)}
            value={fullDocumentChecked.some((f) => f === fullDocument.id)}
            onChange={() => {
              updateCheckBoxList(fullDocument.id, fullDocument.amount)
            }}
          />
        </div>
      ),
    },
    {
      headerText: "treasury.buy.paid.due-date",
      flexGrow: "20rem",
      content: (fullDocument: TreasuryFullDocuments) => (
        <Ct.Text
          text={fullDocument.document_due_date}
          textStyle={{
            fontFamily: "Poppins",
          }}
        />
      ),
    },
    {
      headerText: "treasury.buy.paid.date",
      content: (fullDocument: TreasuryFullDocuments) => (
        <Ct.Text
          text={fullDocument.document_date}
          textStyle={{
            fontFamily: "Poppins",
          }}
        />
      ),
    },
    {
      headerText: "treasury.buy.paid.payment-date",
      flexGrow: "20rem",
      content: (fullDocument: TreasuryFullDocuments) => (
        <Ct.Text
          text={""}
          textStyle={{
            fontFamily: "Poppins",
          }}
        />
      ),
    },
    {
      headerText: "treasury.buy.paid.merchant",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          {getHighlightedText(
            fullDocument.merchant_name,
            highlightValues.searchString
          )}
        </StyledCenter>
      ),
    },
    {
      flexGrow: "25rem",
      headerText: "treasury.buy.paid.file_name",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          <StyledLink
            onClick={() => {
              dispatch(getUrlForFullDocumentThunk(fullDocument.id))
            }}
          >
            {(fullDocument.user_file_name &&
              getHighlightedText(
                truncateFileName(fullDocument.user_file_name, 30),
                highlightValues.searchString
              )) ||
              ""}
          </StyledLink>
        </StyledCenter>
      ),
    },
    {
      flexGrow: "15rem",
      headerText: "treasury.buy.paid.amount",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          {getHighlightedText(
            String(fullDocument.amount.toFixed(2).replace(".", ",")),
            highlightValues.searchString
          )}
        </StyledCenter>
      ),
    },
    {
      flexGrow: "35rem",
      headerText: "treasury.buy.paid.action",
      content: (fullDocument: TreasuryFullDocuments) => (
        <div
          style={{
            width: "40rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            label={intl.formatMessage({
              id: "treasury.buy.paid.button.mark_as_to_control",
            })}
            prefix={<StyledControl />}
            onClick={() =>
              dispatch(
                updateFullDocumentPaymentStatusThunk(
                  companyId,
                  [fullDocument.id],
                  "mark_as_to_control",
                  "buy",
                  fullDocument.amount
                )
              ) && updateTopBarData(fullDocument.id, fullDocument.amount)
            }
            width={35}
            height={5}
            colorType="Tertiary"
            colorScheme={{
              background: "cornflower",
              color: "white",
              border: "cornflower",
              fontFamily: "Roboto",
              fontSize: "1.75rem",
              fontWeight: "500",
            }}
            disabled={
              currentUserTypology !== "customer" &&
              currentUserTypology !== "customer_accountant" &&
              (currentUserPermissions === null ||
                currentUserPermissions.id === 0)
            }
          />
          <Ct.Spacer width={1} />
          <EyeFilled
            cursor={"pointer"}
            onClick={() => {
              historyAction(fullDocument)
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        {" "}
        <Ct.Spacer height={1} />
        <HeadBand>
          <Ct.Text text={"🏷️"} />
          <Ct.Spacer width={1} />
          <Ct.Text
            text={intl.formatMessage({
              id: "treasury.try_access.headband",
            })}
            textStyle={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 2,
              fontStyle: "italic",
              color: "white",
            }}
          />
        </HeadBand>
        <Ct.Spacer height={4} />
        <WrapperTopBar>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            dataCy={"searchUser"}
            shadowed={true}
            noBorder={true}
            isSearch={true}
          />
          <Ct.Spacer width={1} />
          <WrapperCalendar
            onClick={() => {
              setDisplayCalendar(!displayCalendar)
            }}
          >
            <TreasuryInputDateRange
              value={range}
              onChange={(value) => {
                handleCalendarChange(value as never)
              }}
              predefinedRanges={predefinedRanges}
            />
          </WrapperCalendar>
          <Ct.FlexEnd>
            <StyledTotalAmount>
              <Ct.Text
                text={intl.formatMessage({
                  id: "treasury.buy.paid.total_amount",
                })}
                textStyle={{
                  fontFamily: "Poppins",
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontSize: 2,
                }}
              />
              <Ct.Spacer width={0.5} />
              <Ct.Text
                text={`${totalAmount.toFixed(2)} €`}
                textStyle={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "orange",
                  fontSize: 2,
                }}
              />
            </StyledTotalAmount>
          </Ct.FlexEnd>
        </WrapperTopBar>
        <FullDocumentTreasuryHistoryModal
          file_name={
            fullDocumentSelected?.user_file_name ||
            fullDocumentSelected?.file_name ||
            ""
          }
          fullDocumentHistory={fullDocumentHistory}
          isDisplayed={displayHistory ? true : false}
          onClose={() => setDisplayHistory(false)}
        />
        <Ct.Spacer height={3} />
        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                if (index === 0) return 40
                if (index === 7) return 320

                return (width - 360 - 10) / (columns.length - 2)
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 0rem"}>
                    {columns.map((column, index) => (
                      <TreasuryHeaderWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                      >
                        {column.headerIcon}
                        {index !== 0 && index !== columns.length - 1 ? (
                          <ClickableTitleSort
                            tid={column.headerText || ""}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable
                            tid={column.headerText || ""}
                            intl={intl}
                          />
                        )}
                      </TreasuryHeaderWrapper>
                    ))}
                  </HeaderWrapper>

                  {fullDocumentsToDisplay.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "treasury.buy.paid.filter.empty",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <GridWrapper totalWidth={width}>
                      <VariableSizeGrid
                        ref={listRef}
                        height={height - 56}
                        rowCount={fullDocumentsToDisplay.length}
                        width={width}
                        columnWidth={(index) => columnWidths[index]}
                        rowHeight={(index) => 60}
                        columnCount={columns.length}
                      >
                        {Cell}
                      </VariableSizeGrid>
                    </GridWrapper>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}

const StyledControl = styled(Control)`
  width: 3rem;
  height: 3rem;
  & path {
    fill: ${colors.white};
  }
`
