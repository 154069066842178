/* eslint-disable camelcase */
import { useState } from "react"
import styled, { css } from "styled-components/macro"
import { useDispatch } from "react-redux"

import { formatAmount } from "../../utils/money"
import {
  CreditOrDebit,
  WritingLinePayload,
  getAmountFromUserInput,
} from "../../utils/inputMask"
import { colors } from "../../styles/design.config"
import { formattedCurrencySymbol } from "../../utils/currencyUtils"
import {
  decreaseCentsAction,
  focusLine,
  increaseCentsAction,
  updateAmountAction,
} from "../../store/ducks/inputMask.ducks"
import { ReactComponent as Minus } from "../../assets/buttons/minus-secondary.svg"
import { ReactComponent as Plus } from "../../assets/buttons/plus-secondary.svg"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect, useRef } from "react"

export const AmountCell = ({
  direction,
  writingLine,
  isHoveredForDeletion,
  isHoveredForFuel,
  index,
}: {
  direction: CreditOrDebit
  writingLine: WritingLinePayload
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
  index: number
}) => {
  const dispatch = useDispatch()

  const [modifiedValue, setModifiedValue] = useState("")

  const {
    increased_cents,
    decreased_cents,
    new_line_to_select,
    updateWritinglinesStatus,
  } = useRNBSelector((state) => ({
    increased_cents: state.inputMask.increased_cents,
    decreased_cents: state.inputMask.decreased_cents,
    new_line_to_select: state.inputMask.new_line_to_select,
    updateWritinglinesStatus: state.inputMask.updateWritinglinesStatus,
  }))

  const inputToSelectRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (updateWritinglinesStatus === "success") {
      setModifiedValue("")
    }
  }, [updateWritinglinesStatus])

  useEffect(() => {
    if (index === 0 && writingLine.writing_line_uuid === new_line_to_select) {
      if (inputToSelectRef && inputToSelectRef.current) {
        inputToSelectRef.current.focus()
        dispatch(focusLine())
      }
    }
  }, [new_line_to_select, inputToSelectRef])

  if (direction !== writingLine.direction) {
    return (
      <BlueCell
        isHoveredForDeletion={isHoveredForDeletion}
        isHoveredForFuel={isHoveredForFuel}
        empty={true}
      />
    )
  }

  if (
    [
      "tax_excluded",
      "deposit",
      "reverse_deposit",
      "custom",
      "tax_excluded_bill_of_exchange",
    ].includes(writingLine.datum_type)
  ) {
    return (
      <InputWrapper
        showError={
          modifiedValue
            ? Number(modifiedValue) <= 0
            : writingLine?.amount
            ? Number(writingLine?.amount) <= 0
            : true
        }
      >
        <StyledInput
          tabIndex={-1}
          ref={inputToSelectRef}
          isHoveredForDeletion={isHoveredForDeletion}
          isHoveredForFuel={isHoveredForFuel}
          type="text"
          value={
            modifiedValue.replace(".", ",") ||
            writingLine.amount.replace(".", ",")
          }
          onChange={(e) => {
            const amountFromUserInput = getAmountFromUserInput({
              userInput: e.target.value,
              exchangeRate: writingLine.exchange_rate,
            })
            if (!amountFromUserInput) return

            setModifiedValue(e.target.value)
            dispatch(
              updateAmountAction({
                newAmount: amountFromUserInput.newAmount,
                writingLine,
                newCurrencyAmount: amountFromUserInput.newCurrencyAmount,
              })
            )
          }}
        />
        <CurrencyWrapper>{formattedCurrencySymbol("EUR")}</CurrencyWrapper>
      </InputWrapper>
    )
  }
  if (
    [
      "construction_vat",
      "eu_reverse_vat",
      "eu_vat",
      "eu_goods_reverse_vat",
      "eu_goods_vat",
      "world_reverse_vat",
      "world_vat",
      "world_goods_reverse_vat",
      "world_goods_vat",
      "construction_reverse_vat",
      "vat",
    ].includes(writingLine.datum_type)
  ) {
    const disableDecreaseCents = decreased_cents.some(
      (d) => d.writing_line_uuid === writingLine.writing_line_uuid
    )
    const disableIncreaseCents = increased_cents.some(
      (d) => d.writing_line_uuid === writingLine.writing_line_uuid
    )
    return (
      <BlueCell
        isHoveredForDeletion={isHoveredForDeletion}
        isHoveredForFuel={isHoveredForFuel}
      >
        <SpacedBetweenAligned>
          <VatButtonsWrapper>
            <StyledMinus
              disabled={disableDecreaseCents}
              onClick={() => {
                if (disableDecreaseCents) {
                  return
                }
                dispatch(
                  decreaseCentsAction({
                    writingLine,
                  })
                )
              }}
            />
            <StyledPlus
              disabled={disableIncreaseCents}
              onClick={() => {
                if (disableIncreaseCents) {
                  return
                }
                dispatch(
                  increaseCentsAction({
                    writingLine,
                  })
                )
              }}
            />
          </VatButtonsWrapper>
          <div>{formatAmount(writingLine.amount)}</div>
        </SpacedBetweenAligned>
      </BlueCell>
    )
  }
  return (
    <BlueCell
      isHoveredForDeletion={isHoveredForDeletion}
      isHoveredForFuel={isHoveredForFuel}
    >
      {formatAmount(writingLine.amount)}
    </BlueCell>
  )
}

const BlueCell = styled.div<{
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
  empty?: boolean
}>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  background-color: ${({ isHoveredForDeletion, isHoveredForFuel, empty }) =>
    isHoveredForDeletion
      ? "rgba(252, 90, 90, 0.2)"
      : isHoveredForFuel && !empty
      ? "rgba(107, 146, 255, 0.2)"
      : isHoveredForFuel
      ? "transparent"
      : colors.blueGreyBackgroung};
  transition: 0.5s background-color ease-in-out;
  border: 2px solid transparent;
  box-sizing: border-box;
  user-select: none;
`
const StyledInput = styled.input<{
  isHoveredForDeletion: boolean
  isHoveredForFuel: boolean
}>`
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  position: relative;
  padding-right: 2.6rem;
  outline: none;
  box-sizing: border-box;
  caret-color: ${colors.cornflower};
  text-align: right;

  background-color: ${({ isHoveredForDeletion, isHoveredForFuel }) =>
    isHoveredForDeletion
      ? "rgba(252, 90, 90, 0.2)"
      : isHoveredForFuel
      ? "rgba(107, 146, 255, 0.2)"
      : colors.white};
  transition: 0.5s background-color ease-in-out, 0.5s border ease-in-out;

  &:focus {
    border: 2px solid ${colors.cornflower};
  }
`
const InputWrapper = styled.div<{
  showError: boolean
}>`
  height: 100%;
  width: 100%;
  position: relative;
  border: ${({ showError }) =>
    showError ? `1px solid ${colors.amaranth}` : "auto"};
  box-sizing: border-box;
`
const CurrencyWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 3.6rem;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VatButtonsWrapper = styled.div`
  display: flex;
`
const SpacedBetweenAligned = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledButton = css`
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  transition: 0.5s fill ease-in-out;
`
const StyledMinus = styled(Minus)<{ disabled: boolean }>`
  ${StyledButton};
  fill: ${({ disabled }) => (disabled ? colors.grey : colors.cornflower)};
`
const StyledPlus = styled(Plus)<{ disabled: boolean }>`
  ${StyledButton};
  fill: ${({ disabled }) => (disabled ? colors.grey : colors.cornflower)};
`
