import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"

import { getIdFromParams } from "../../../../utils/company"
import { ReactComponent as ArrowRight } from "../../../../assets/arrow-right.svg"
import { ReactComponent as InvoiceSVG } from "../../../../assets/invoicesvg.svg"
import { ReactComponent as CreditNoteSVG } from "../../../../assets/creditNotesvg.svg"
import { ReactComponent as QuotationSVG } from "../../../../assets/quotationsvg.svg"
import { colors, gradients } from "../../../../styles/design.config"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useEffect } from "react"

export const InvoiceNavigation = () => {
  const intl = useIntl()
  const params = useParams()
  const navigate = useNavigate()
  const selectedCompanyId = getIdFromParams(params)("company_id")

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <>
      <RowCenterWrapper>
        <Ct.Spacer width={8} />
        <ClicableDiv
          onClick={() =>
            navigate(
              `/office/company/${selectedCompanyId}/invoicing/issuance/quotation`
            )
          }
        >
          <IssuanceCards>
            <Ellipse className="white-bg">
              <QuotationSVG />
            </Ellipse>
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-quotation",
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 3,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-quotation.description",
              })}
              textStyle={{
                fontWeight: 600,
                fontSize: 2.1,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <Ellipse className="white-bg shaking">
              <ArrowRight />
            </Ellipse>
          </IssuanceCards>
        </ClicableDiv>
        <ClicableDiv
          onClick={() =>
            navigate(
              `/office/company/${selectedCompanyId}/invoicing/issuance/invoicing`
            )
          }
        >
          <IssuanceCards>
            <Ellipse className="white-bg">
              <InvoiceSVG />
            </Ellipse>
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-issuance",
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 3,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-issuance.description",
              })}
              textStyle={{
                fontWeight: 600,
                fontSize: 2.1,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <Ellipse className="white-bg shaking">
              <ArrowRight />
            </Ellipse>
          </IssuanceCards>
        </ClicableDiv>
        <ClicableDiv
          onClick={() =>
            navigate(
              `/office/company/${selectedCompanyId}/invoicing/issuance/creditNote`
            )
          }
        >
          <IssuanceCards>
            <Ellipse className="white-bg">
              <CreditNoteSVG />
            </Ellipse>
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-creditNote",
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 3,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <StyledText
              text={intl.formatMessage({
                id: "invoicing.navigation.invoice-creditNote.description",
              })}
              textStyle={{
                fontWeight: 600,
                fontSize: 2.1,
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            />
            <Ellipse className="white-bg shaking">
              <ArrowRight />
            </Ellipse>
          </IssuanceCards>
        </ClicableDiv>
        <Ct.Spacer width={8} />
      </RowCenterWrapper>
    </>
  )
}

const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  padding: 10rem 5rem 0 0;
  width: 100%;
  justify-content: space-between;
`

const StyledText = styled(Ct.Text)`
  margin: 0;
  cursor: pointer;
  transition-duration: 0.15s;
`
const IssuanceCards = styled(Ct.Card)`
  transition-duration: 0.15s;
  justify-content: space-evenly;
  border-radius: 2.5rem 2.5rem 2.5rem 2.5rem;
  min-width: 15em;
  @media (min-height: 961px) {
    min-height: calc(100vh - 61rem);
    width: calc(30vw - 25rem);
  }
  @media (max-height: 960px) {
    min-height: calc(100vh - 61rem);
    width: calc(30vw - 25rem);
  }

  :hover {
    box-shadow: 0px 0px 7rem ${colors.rock};
    background: ${gradients.blue};
    & path {
      fill: ${colors.cornflower};
    }

    & [class*="white-bg"] {
      transition-duration: 0.2s;
      background-color: white;
    }

    & ${StyledText} {
      color: white;
    }

    & [class*="shaking"] {
      animation: shake 1.2s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 0px);
    }
    10% {
      transform: translate(-1px, 0px);
    }
    20% {
      transform: translate(-2px, 0px);
    }
    30% {
      transform: translate(2px, 0px);
    }
    40% {
      transform: translate(1px, 0px);
    }
    50% {
      transform: translate(-1px, 0px);
    }
    60% {
      transform: translate(-2px, 0px);
    }
    70% {
      transform: translate(2px, 0px);
    }
    80% {
      transform: translate(-1px, 0px);
    }
    90% {
      transform: translate(1px, 0px);
    }
    100% {
      transform: translate(1px, 0px);
    }
  }
`
const Ellipse = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.lavender};
  border-radius: 50%;
  border: none;
  width: auto;
  height: auto;
  padding: 1.75rem;
  text-align: start;
  box-sizing: border-box;
`
const ClicableDiv = styled.div`
  cursor: pointer;
`
