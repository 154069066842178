import styled, { css } from "styled-components/macro"
import { ReactComponent as Eye } from "../assets/eyeFilled.svg"
import { ReactComponent as Trash } from "../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Download } from "../assets/FileDownloadSolid.svg"
import { ReactComponent as EditFilled } from "../assets/EditFilled.svg"
import { ReactComponent as EditDraft } from "../assets/edit_draft.svg"
import { colors } from "../styles/design.config"
import { usePopperTooltip } from "react-popper-tooltip"
import "react-popper-tooltip/dist/styles.css"

type DisplayActions =
  | "preview"
  | "seeDetails"
  | "download"
  | "rename"
  | "inputMask"
  | "history"
  | "overtaking_to_credit_note"
  | "overtaking_to_invoice"
  | "edit"
  | "delete"

type DeleteActions = "hide" | "disable" | "enable"
type HideActions = "exchange" | "duplicate" | "writing_not_validated" | null

interface IconActionsProps {
  actionsToDisplay: DisplayActions[]
  deleteAction?: DeleteActions
  hideDetails?: HideActions
  isRenameDisabled?: boolean
  renameDisabledTooltip?: string
  onSetDisplayPreview?: () => void
  onSetDownload?: () => void
  onSetDisplayDetails?: () => void
  onRename?: () => void
  onHistory?: () => void
  onDelete?: () => void
  onEdit?: () => void
}

export const IconActions = ({
  actionsToDisplay,
  deleteAction,
  hideDetails,
  isRenameDisabled = false,
  renameDisabledTooltip = "",
  onRename,
  onHistory,
  onSetDisplayPreview,
  onSetDownload,
  onSetDisplayDetails,
  onDelete,
  onEdit,
}: IconActionsProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip()

  return (
    <FlexContainer>
      {actionsToDisplay.includes("edit") && (
        <StyledIcon
          onClick={() => {
            if (onEdit) {
              onEdit()
            }
          }}
        >
          <EditDraft />
        </StyledIcon>
      )}

      {actionsToDisplay.includes("preview") && (
        <StyledIcon
          onClick={() => {
            if (onSetDisplayPreview) {
              onSetDisplayPreview()
            }
          }}
        >
          <Eye />
        </StyledIcon>
      )}
      {actionsToDisplay.includes("download") && (
        <StyledIcon
          onClick={() => {
            if (onSetDownload) {
              onSetDownload()
            }
          }}
        >
          <Download />
        </StyledIcon>
      )}
      {actionsToDisplay.includes("rename") && (
        <>
          <div ref={isRenameDisabled ? setTriggerRef : null}>
            <StyledIcon
              disabled={isRenameDisabled}
              onClick={() => {
                if (onRename && !isRenameDisabled) {
                  onRename()
                }
              }}
            >
              <EditFilled />
            </StyledIcon>
          </div>
          {visible && (
            <div
              ref={setTooltipRef}
              {...getTooltipProps({ className: "tooltip-container" })}
            >
              {renameDisabledTooltip}
              <div {...getArrowProps({ className: "tooltip-arrow" })} />
            </div>
          )}
        </>
      )}
      {actionsToDisplay.includes("history") && (
        <StyledIcon
          onClick={() => {
            if (onHistory) {
              onHistory()
            }
          }}
        >
          <Eye />
        </StyledIcon>
      )}
      {actionsToDisplay.includes("delete") && deleteAction !== "hide" && (
        <StyledIcon
          disabled={deleteAction === "disable"}
          onClick={() => {
            if (deleteAction === "enable" && onDelete) {
              onDelete()
            }
          }}
        >
          <Trash />
        </StyledIcon>
      )}
      {actionsToDisplay.includes("seeDetails") && (
        <StyledIcon
          disabled={hideDetails}
          onClick={() => {
            if (!hideDetails && onSetDisplayDetails) {
              onSetDisplayDetails()
            }
          }}
        >
          <Eye />
        </StyledIcon>
      )}
    </FlexContainer>
  )
}

interface ActionProps {
  disabled: boolean
}

const styledButton = css<ActionProps>`
  path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }

  :hover {
    path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  fill-opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const StyledIcon = styled(({ ...props }) => {
  return <div {...props}>{props.children} </div>
})`
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  ${styledButton}
`
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`
