import { isRouteSelected } from "../../../../utils/routes"
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useParams,
  useResolvedPath,
} from "react-router-dom"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { ReactComponent as Folder } from "../../../../assets/folder.svg"
import { ReactComponent as PermanentFolder } from "../../../../assets/permanentFolder.svg"
import { ReactComponent as FolderUser } from "../../../../assets/folderUser.svg"
import { ReactComponent as Star } from "../../../../assets/star.svg"
import { ReactComponent as Bank } from "../../../../assets/bank.svg"
import { ReactComponent as FilledUsers } from "../../../../assets/filledUsers.svg"
import { ReactComponent as FilledBoxes } from "../../../../assets/filledBoxes.svg"

import { useRNBSelector } from "../../../../store/rootReducer"
import { useEffect } from "react"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"
import { getIdFromParams } from "../../../../utils/company"

export const EdmMenu = () => {
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/edm"

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  const linkTabs: TabObject[] = [
    {
      to: "buy",
      title: "Achats",
      isDisplayed: true,
      icon: <FilledBoxes />,
      isSelected: isRouteSelected(location, routePattern, "buy"),
    },
    {
      to: "sell",
      title: "Ventes",
      isDisplayed: true,
      icon: <FilledUsers />,
      isSelected: isRouteSelected(location, routePattern, "sell"),
    },
    {
      to: "bank",
      title: "Banque",
      isDisplayed: true,
      icon: <Bank />,
      isSelected: isRouteSelected(location, routePattern, "other"),
    },
    {
      to: "fiscal",
      title: "Fiscal",
      isDisplayed: true,
      icon: <Star />,
      isSelected: isRouteSelected(location, routePattern, "buy"),
    },
    {
      to: "social",
      title: "Social",
      isDisplayed: true,
      icon: <FolderUser />,
      isSelected: isRouteSelected(location, routePattern, "sell"),
    },
    {
      to: "permanent",
      title: "Dossier permanent",
      isDisplayed: true,
      icon: <PermanentFolder />,
      isSelected: isRouteSelected(location, routePattern, "other"),
    },
    {
      to: "other",
      title:
        userInformations.typology !== "customer"
          ? "Dossier de contrôle"
          : "Divers",
      isDisplayed: true,
      icon: <Folder />,
      isSelected: isRouteSelected(location, routePattern, "other"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}
