import axios, { AxiosError } from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"

import { AnyAction } from "redux"
import { FiduciaryFormData } from "../../components/RegistrationPanel"
import {
  cleanSIREN,
  SirenCheckResponseType,
  SirenStatus,
  validateAPE,
} from "../../utils/siren"
import { RootState } from "../rootReducer"
import { updateStatus } from "../../utils/asyncTools"
import { cleanPhone } from "../../utils/phoneValidator"
import { getUrlFailureAction } from "./invoicing.duck"

const enum FiduciaryActionsEnum {
  SIREN_ATTEMPT = "FIDUCIARY/SIRENAttempt",
  FIDUCIARY_SIREN_SUCCESS = "FIDUCIARY/SIRENSuccess",
  SIREN_FAILURE = "FIDUCIARY/SIRENFailure",
  REGISTRATION_ATTEMPT = "FIDUCIARY/RegistrationAttempt",
  REGISTRATION_SUCCESS = "FIDUCIARY/RegistrationSuccess",
  REGISTRATION_FAILURE = "FIDUCIARY/RegistrationFailure",
  REGISTRATION_FAILURE_TAKEN_NAME = "FIDUCIARY/REGISTRATION_FAILURE_TAKEN_NAME",
  REGISTRATION_FAILURE_WRONG_PHONE = "FIDUCIARY/REGISTRATION_FAILURE_WRONG_PHONE",
  GET_FIDUCIARY_ATTEMPT = "FIDUCIARY/getFiduciaryAttempt",
  GET_FIDUCIARY_SUCCESS = "FIDUCIARY/getFiduciarySuccess",
  GET_FIDUCIARY_FAILURE = "FIDUCIARY/getFiduciaryFailure",
  GET_BILLS_FROM_FIDUCIARY_ATTEMPT = "FIDUCIARY/getBillsFromFiduciaryAttempt",
  GET_BILLS_FROM_FIDUCIARY_SUCCESS = "FIDUCIARY/getBillsFromFiduciarySuccess",
  GET_BILLS_FROM_FIDUCIARY_FAILURE = "FIDUCIARY/getBillsFromFiduciaryFailure",
  CHECK_EXISTING_FIDUCIARY_ATTEMPT = "FIDUCIARY/checkExistingFiduciaryAttempt",
  CHECK_EXISTING_FIDUCIARY_SUCCESS = "FIDUCIARY/checkExistingFiduciarySuccess",
  CHECK_EXISTING_FIDUCIARY_FAILURE = "FIDUCIARY/checkExistingFiduciaryFailure",
  FIDUCIARY_SIREN_HIDDEN = "FIDUCIARY/SIRENHiddenFailure",
  GET_COMPANIES_ATTEMPT = "FIDUCIARY/getCompaniesAttempt",
  GET_COMPANIES_SUCCESS = "FIDUCIARY/getCompaniesSuccess",
  GET_COMPANIES_FAILURE = "FIDUCIARY/getCompaniesFailure",
  FIDUCIARY_LOGO_UPDATE_ATTEMPT = "FIDUCIARY/logoUpdateAttempt",
  FIDUCIARY_LOGO_UPDATE_SUCCESS = "FIDUCIARY/logoUpdateSuccess",
  FIDUCIARY_LOGO_UPDATE_FAILURE = "FIDUCIARY/logoUpdateFailure",
  FIDUCIARY_INFOS_UPDATE_ATTEMPT = "FIDUCIARY/infosUpdateAttempt",
  FIDUCIARY_INFOS_UPDATE_SUCCESS = "FIDUCIARY/infosUpdateSuccess",
  FIDUCIARY_INFOS_UPDATE_FAILURE = "FIDUCIARY/infosUpdateFailure",
  REGISTER_FIDUCIARY_FRIEND = "FIDUCIARY/registerFiduciaryFriend",
  GET_INQOM_SIGN_REQUEST_ATTEMPT_ACTION = "FIDUCIARY/getInqomSignRequestAttemptAction",
  GET_INQOM_SIGN_REQUEST_SUCCESS = "FIDUCIARY/getInqomSignRequestSucessAction",
  GET_INQOM_SIGN_REQUEST_FAILURE = "FIDUCIARY/getInqomSignRequestFailureAction",
  INQOM_SIGN_REQUEST_CALLBACK_ATTEMPT_ACTION = "FIDUCIARY/inqomSignRequestCallbackAttemptAction",
  INQOM_SIGN_REQUEST_CALLBACK_SUCCESS = "FIDUCIARY/inqomSignRequestCallbackSucessAction",
  INQOM_SIGN_REQUEST_CALLBACK_FAILURE = "FIDUCIARY/inqomSignRequestCallbackFailureAction",
  UPDATE_ACD_SENT_DATA_ATTEMPT = "FIDUCIARY/updateAcdSentDataAttemptActionAttempt",
  UPDATE_ACD_SENT_DATA_SUCCESS = "FIDUCIARY/updateAcdSentDataAttemptActionSuccess",
  UPDATE_ACD_SENT_DATA_ERROR = "FIDUCIARY/updateAcdSentDataAttemptActionError",
  REGISTER_ACD_ATTEMPT = "FIDUCIARY/registerAcdAttemptAction",
  REGISTER_ACD_SUCCESS = "FIDUCIARY/registerAcdSuccessAction",
  REGISTER_ACD_ERROR = "FIDUCIARY/registerAcdErrorAction",
  DISCONNECT_ACD_ATTEMPT = "FIDUCIARY/disconnectAcdAttemptAction",
  DISCONNECT_ACD_SUCCESS = "FIDUCIARY/disconnectAcdSuccessAction",
  DISCONNECT_ACD_ERROR = "FIDUCIARY/disconnectAcdErrorAction",
  GET_API_CONNECT_ATTEMPT = "FIDUCIARY/getApiConnectedAttemptAction",
  GET_API_CONNECT_SUCCESS = "FIDUCIARY/getApiConnectedSuccessAction",
  GET_API_CONNECT_ERROR = "FIDUCIARY/getApiConnectedErrorAction",
  GET_API_CONNECT_RESET = "FIDUCIARY/getApiConnectedResetAction",

  UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_ATTEMPT = "FIDUCIARY/UpdateDefaultAccountingSoftwareAttempt",
  UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_SUCCESS = "FIDUCIARY/UpdateDefaultAccountingSoftwareSuccess",
  UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_FAILURE = "FIDUCIARY/UpdateDefaultAccountingSoftwareFailure",
}

export const sirenAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.SIREN_ATTEMPT } as const)
export const fiduciarySirenSuccessAction = (
  fiduciary: FiduciaryObjectType,
  duplicate: boolean
) =>
  ({
    type: FiduciaryActionsEnum.FIDUCIARY_SIREN_SUCCESS,
    payload: { fiduciary, duplicate },
  } as const)

export const sirenFailureAction = () =>
  ({ type: FiduciaryActionsEnum.SIREN_FAILURE } as const)
export const registrationAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.REGISTRATION_ATTEMPT } as const)
export const registrationSuccessAction = (payload: { id: number }) =>
  ({ type: FiduciaryActionsEnum.REGISTRATION_SUCCESS, payload } as const)
export const registrationFailureAction = (error: AxiosError) =>
  ({
    type: FiduciaryActionsEnum.REGISTRATION_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
export const registrationFailureActionTakenFiduciaryName = () =>
  ({
    type: FiduciaryActionsEnum.REGISTRATION_FAILURE_TAKEN_NAME,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "fiduciary-register.error.existing-fiduciary-name-title",
      bodyKey: "fiduciary-register.error.existing-fiduciary-name-message",
    },
  } as const)
export const registrationFailureActionWrongPhoneNumber = () =>
  ({
    type: FiduciaryActionsEnum.REGISTRATION_FAILURE_WRONG_PHONE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "fiduciary-register.error.phone-field.title",
      bodyKey: "fiduciary-register.error.phone-field.message",
    },
  } as const)

export const getFiduciaryAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.GET_FIDUCIARY_ATTEMPT } as const)
export const getFiduciarySuccessAction = (payload: FiduciaryPayload) =>
  ({ type: FiduciaryActionsEnum.GET_FIDUCIARY_SUCCESS, payload } as const)
export const getFiduciaryFailureAction = (error: AxiosError) =>
  ({ type: FiduciaryActionsEnum.GET_FIDUCIARY_FAILURE, error } as const)
export const getBillsFromFiduciaryAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_ATTEMPT } as const)
export const getBillsFromFiduciarySuccessAction = (payload: billsResponse[]) =>
  ({
    type: FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_SUCCESS,
    payload,
  } as const)
export const getBillsFromFiduciaryFailureAction = (error: AxiosError) =>
  ({
    type: FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_FAILURE,
    error,
  } as const)
export const checkExistingFiduciaryAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_ATTEMPT } as const)
export const checkExistingFiduciarySuccessAction = () =>
  ({ type: FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_SUCCESS } as const)
export const checkExistingFiduciaryFailureAction = (error: AxiosError) =>
  ({
    type: FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_FAILURE,
    error,
    withoutToast: true,
  } as const)
export const fiduciarySirenHiddenAction = () =>
  ({ type: FiduciaryActionsEnum.FIDUCIARY_SIREN_HIDDEN } as const)

export const fiduciaryLogoUpdateAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.FIDUCIARY_LOGO_UPDATE_ATTEMPT } as const)
export const fiduciaryLogoUpdateSuccessAction = (
  payload: UpdateFiduciaryLogoResponse
) =>
  ({
    type: FiduciaryActionsEnum.FIDUCIARY_LOGO_UPDATE_SUCCESS,
    payload,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "fiduciary-register.update-logo-success.title",
      bodyKey: "fiduciary-register.update-logo-success.body",
    },
  } as const)
export const fiduciaryLogoUpdateFailureAction = () =>
  ({
    type: FiduciaryActionsEnum.FIDUCIARY_LOGO_UPDATE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "fiduciary-register.update-logo-failure.title",
      bodyKey: "fiduciary-register.update-logo-failure.body",
    },
  } as const)
export const fiduciaryInfosUpdateAttemptAction = () =>
  ({ type: FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_ATTEMPT } as const)
export const fiduciaryInfosUpdateSuccessAction = (
  payload: UpdateFiduciaryInfosResponse
) =>
  ({
    type: FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_SUCCESS,
    payload,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "fiduciary-register.update-infos-success.title",
      bodyKey: "fiduciary-register.update-infos-success.body",
    },
  } as const)
export const fiduciaryInfosUpdateFailureAction = (error: AxiosError) =>
  ({
    type: FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_FAILURE,
    error,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: error.name,
      bodyKey: error.message,
    },
  } as const)
export const registerFiduciaryFriend = (payload: UPdateFiduciaryReferer) =>
  ({
    type: FiduciaryActionsEnum.REGISTER_FIDUCIARY_FRIEND,
    payload,
  } as const)

export const getInqomSignRequestAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.GET_INQOM_SIGN_REQUEST_ATTEMPT_ACTION,
  } as const)
export const getInqomSignRequestSuccessAction = (
  payload: GetInqomSignRequestInfosResponse
) =>
  ({
    type: FiduciaryActionsEnum.GET_INQOM_SIGN_REQUEST_SUCCESS,
    payload,
  } as const)
export const getInqomSignRequestFailureAction = () =>
  ({
    type: FiduciaryActionsEnum.GET_INQOM_SIGN_REQUEST_FAILURE,
  } as const)

export const inqomSignRequestCallbackAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.INQOM_SIGN_REQUEST_CALLBACK_ATTEMPT_ACTION,
  } as const)
export const inqomSignRequestCallbackSuccessAction = () =>
  ({
    type: FiduciaryActionsEnum.INQOM_SIGN_REQUEST_CALLBACK_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "fiduciary.settings.inqom.connect.success.title",
      bodyKey: "fiduciary.settings.inqom.connect.success.body",
    },
  } as const)
export const inqomSignRequestCallbackFailureAction = () =>
  ({
    type: FiduciaryActionsEnum.INQOM_SIGN_REQUEST_CALLBACK_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "fiduciary.settings.inqom.connect.failure.title",
      bodyKey: "fiduciary.settings.inqom.connect.failure.body",
    },
  } as const)

export const updateAcdSentDataAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.UPDATE_ACD_SENT_DATA_ATTEMPT,
  } as const)

export const updateAcdSentDataSuccessAction = ({
  fiduciaryId,
  dueDate,
  documentReference,
}: {
  fiduciaryId: number
  dueDate: boolean
  documentReference: boolean
}) =>
  ({
    type: FiduciaryActionsEnum.UPDATE_ACD_SENT_DATA_SUCCESS,
    payload: { fiduciaryId, dueDate, documentReference },
  } as const)

export const updateAcdSentDataErrorAction = () =>
  ({
    type: FiduciaryActionsEnum.UPDATE_ACD_SENT_DATA_ERROR,
  } as const)

export const registerAcdAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.REGISTER_ACD_ATTEMPT,
  } as const)
export const registerAcdSuccessAction = () =>
  ({
    type: FiduciaryActionsEnum.REGISTER_ACD_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `fiduciary-settings.toaster.success.sign.in.acd.title`,
      bodyKey: `fiduciary-settings.toaster.success.sign.in.acd.body`,
    },
  } as const)
export const registerAcdErrorAction = () =>
  ({
    type: FiduciaryActionsEnum.REGISTER_ACD_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `fiduciary-settings.toaster.error.sign.in.acd.title`,
      bodyKey: `fiduciary-settings.toaster.error.sign.in.acd.body`,
    },
  } as const)

export const getApiConnectedAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.GET_API_CONNECT_ATTEMPT,
  } as const)
export const getApiConnectedSuccessAction = (apiConnected: boolean) =>
  ({
    type: FiduciaryActionsEnum.GET_API_CONNECT_SUCCESS,
    payload: { apiConnected },
  } as const)
export const getApiConnectedErrorAction = () =>
  ({
    type: FiduciaryActionsEnum.GET_API_CONNECT_ERROR,
  } as const)
export const getApiConnectedResetAction = () =>
  ({
    type: FiduciaryActionsEnum.GET_API_CONNECT_RESET,
  } as const)

export const disconnectAcdAttemptAction = () =>
  ({
    type: FiduciaryActionsEnum.DISCONNECT_ACD_ATTEMPT,
  } as const)
export const disconnectAcdSuccessAction = () =>
  ({
    type: FiduciaryActionsEnum.DISCONNECT_ACD_SUCCESS,
  } as const)
export const disconnectAcdErrorAction = () =>
  ({
    type: FiduciaryActionsEnum.DISCONNECT_ACD_ERROR,
  } as const)

export const UpdateDefaultAccountingSoftwareAttempt = () =>
  ({
    type: FiduciaryActionsEnum.UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_ATTEMPT,
  } as const)
export const UpdateDefaultAccountingSoftwareSuccess = (newSoftware: string) =>
  ({
    type: FiduciaryActionsEnum.UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_SUCCESS,
    newSoftware,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "fiduciary-settings.change-software.tooltip.success-title",
      bodyKey: "fiduciary-settings.change-software.tooltip.success-message",
    },
  } as const)
export const UpdateDefaultAccountingSoftwareFailure = () =>
  ({
    type: FiduciaryActionsEnum.UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "fiduciary-settings.change-software.tooltip.error-title",
      bodyKey: "fiduciary-settings.change-software.tooltip.error-message",
    },
  } as const)

type FiduciaryActionsType = ReturnType<
  | typeof sirenAttemptAction
  | typeof fiduciarySirenSuccessAction
  | typeof sirenFailureAction
  | typeof registrationAttemptAction
  | typeof registrationSuccessAction
  | typeof registrationFailureAction
  | typeof registrationFailureActionTakenFiduciaryName
  | typeof getFiduciaryAttemptAction
  | typeof getFiduciarySuccessAction
  | typeof getFiduciaryFailureAction
  | typeof getBillsFromFiduciaryAttemptAction
  | typeof getBillsFromFiduciarySuccessAction
  | typeof getBillsFromFiduciaryFailureAction
  | typeof checkExistingFiduciaryAttemptAction
  | typeof checkExistingFiduciarySuccessAction
  | typeof checkExistingFiduciaryFailureAction
  | typeof fiduciarySirenHiddenAction
  | typeof fiduciaryLogoUpdateAttemptAction
  | typeof fiduciaryLogoUpdateSuccessAction
  | typeof fiduciaryLogoUpdateFailureAction
  | typeof fiduciaryInfosUpdateAttemptAction
  | typeof fiduciaryInfosUpdateSuccessAction
  | typeof fiduciaryInfosUpdateFailureAction
  | typeof registerFiduciaryFriend
  | typeof registrationFailureActionWrongPhoneNumber
  | typeof getInqomSignRequestAttemptAction
  | typeof getInqomSignRequestSuccessAction
  | typeof getInqomSignRequestFailureAction
  | typeof inqomSignRequestCallbackAttemptAction
  | typeof inqomSignRequestCallbackSuccessAction
  | typeof inqomSignRequestCallbackFailureAction
  | typeof updateAcdSentDataAttemptAction
  | typeof updateAcdSentDataSuccessAction
  | typeof updateAcdSentDataErrorAction
  | typeof registerAcdAttemptAction
  | typeof registerAcdSuccessAction
  | typeof registerAcdErrorAction
  | typeof getApiConnectedAttemptAction
  | typeof getApiConnectedSuccessAction
  | typeof getApiConnectedErrorAction
  | typeof getApiConnectedResetAction
  | typeof disconnectAcdAttemptAction
  | typeof disconnectAcdSuccessAction
  | typeof disconnectAcdErrorAction
  | typeof UpdateDefaultAccountingSoftwareAttempt
  | typeof UpdateDefaultAccountingSoftwareSuccess
  | typeof UpdateDefaultAccountingSoftwareFailure
>

interface FiduciaryObjectType {
  code_ape: null | string
  siren: null | string
  sirenState: SirenStatus
  isBadApe: boolean
  name: string
  companies: Array<CompanyObjectType>
  address: string
  city: string
  postalCode: string
  phone: string
  comptaSoftware: string
  created_at: string
  companies_creation_locked_at: boolean
  ape_details: string
  country: string
  country_code: string
  full_legal_form: string
  legal_form: string | null
  max_work_force: number
  min_work_force: number
  postal_code: string
  share_capital: string | null
  siege_siret: string
  vat_number: string
  work_force: string
}

export interface FiduciaryState extends FiduciaryObjectType {
  id: number
  sirenCheck: "CHECKING" | "VALIDATED" | "INVALID" | "UNCHECKED"
  referer: null | string
  logoId: number | null
  status: "UNREGISTERED" | "INVALID" | "SUCCESS"
  fiduciaryInfoUpdateStatus: updateStatus
  logoUrl: null | string
  bills: null | billsResponse[]
  inqom_sign_request?: GetInqomSignRequestInfosResponse
  acd_api_due_date: boolean
  acd_api_document_reference: boolean
  api_connected: boolean
  apiConnectedStatus: "CHECKING" | "CHECKED" | "ERROR" | "IDLE"
  active_pricing: Pricing | null
  override_pricing: boolean
  cash_or_accrual: "cash" | "accrual" | "both"
}

export interface billsResponse {
  id: number
  date: Date
  amount: number
  status: string
}

export interface StandardLedgerType {
  id: number
  confirmed: boolean
  original_file_name: string
  file_name: string
}

interface FiscalYearType {
  id: number
  begin_exercise: string
  end_exercise: string
}
export interface CompanyObjectType {
  id: number
  company_name: string
  identification_number: string
  email_capture: string
  user_company_name: null | string
  hidden_from_team: boolean
  accounting_software_reference: null | string
  standard_ledgers: Array<StandardLedgerType>
  fiscal_years: Array<FiscalYearType>
}
export const fiduciaryInitialState: FiduciaryState = {
  id: 0,
  sirenCheck: "UNCHECKED",
  referer: null,
  code_ape: null,
  siren: null,
  sirenState: null,
  isBadApe: false,
  logoId: null,
  name: "",
  address: "",
  city: "",
  postalCode: "",
  phone: "",
  status: "UNREGISTERED",
  companies: [],
  comptaSoftware: "",
  fiduciaryInfoUpdateStatus: "IDLE",
  logoUrl: null,
  bills: null,
  created_at: "",
  companies_creation_locked_at: false,
  acd_api_due_date: true,
  acd_api_document_reference: true,
  api_connected: false,
  apiConnectedStatus: "IDLE",
  active_pricing: null,
  override_pricing: false,
  cash_or_accrual: "both",
  ape_details: "",
  country: "",
  country_code: "",
  full_legal_form: "",
  legal_form: "",
  max_work_force: 0,
  min_work_force: 0,
  postal_code: "",
  share_capital: null,
  siege_siret: "",
  vat_number: "",
  work_force: "",
}

export function fiduciaryReducer(
  state = fiduciaryInitialState,
  action: FiduciaryActionsType
): FiduciaryState {
  switch (action.type) {
    case FiduciaryActionsEnum.SIREN_ATTEMPT:
      return { ...state, sirenCheck: "CHECKING" }
    case FiduciaryActionsEnum.FIDUCIARY_SIREN_SUCCESS: {
      let sirenState: SirenStatus = "ok"
      let isBadApe = false
      if (action.payload.duplicate) {
        sirenState = "taken fiduciary"
      }
      if (
        action.payload.fiduciary.code_ape &&
        !validateAPE(action.payload.fiduciary.code_ape)
      ) {
        isBadApe = true
      }
      return {
        ...state,
        ...action.payload.fiduciary,
        sirenCheck: "VALIDATED",
        sirenState,
        isBadApe,
      }
    }
    case FiduciaryActionsEnum.SIREN_FAILURE:
      return { ...state, sirenCheck: "INVALID", sirenState: "not found" }
    case FiduciaryActionsEnum.GET_FIDUCIARY_ATTEMPT:
      return { ...state }
    case FiduciaryActionsEnum.GET_FIDUCIARY_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        address: action.payload.address,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
        phone: action.payload.phone,
        comptaSoftware: action.payload.comptaSoftware,
        logoId: action.payload.logoId,
        status: "SUCCESS",
        logoUrl: action.payload.logoUrl,
        created_at: action.payload.created_at,
        companies_creation_locked_at:
          action.payload.companies_creation_locked_at,
        acd_api_due_date: action.payload.acd_api_due_date,
        acd_api_document_reference: action.payload.acd_api_document_reference,
        active_pricing: action.payload.active_pricing,
        override_pricing: action.payload.override_pricing,
        cash_or_accrual: action.payload.cash_or_accrual,
      }
    case FiduciaryActionsEnum.GET_FIDUCIARY_FAILURE:
      return { ...state }
    case FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_ATTEMPT:
      return { ...state }
    case FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_SUCCESS:
      return {
        ...state,
        bills: action.payload,
      }
    case FiduciaryActionsEnum.GET_BILLS_FROM_FIDUCIARY_FAILURE:
      return { ...state }
    case FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_ATTEMPT:
      return { ...state }
    case FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_SUCCESS:
      return { ...state, status: "SUCCESS" }
    case FiduciaryActionsEnum.CHECK_EXISTING_FIDUCIARY_FAILURE:
      return { ...state, status: "UNREGISTERED" }
    case FiduciaryActionsEnum.REGISTRATION_SUCCESS:
      return { ...state, id: action.payload.id, status: "SUCCESS" }
    case FiduciaryActionsEnum.REGISTRATION_FAILURE:
      return { ...state }
    case FiduciaryActionsEnum.REGISTRATION_FAILURE_TAKEN_NAME:
      return { ...state }
    case FiduciaryActionsEnum.FIDUCIARY_SIREN_HIDDEN:
      return { ...state, sirenCheck: "INVALID", sirenState: "hidden" }
    case FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_ATTEMPT:
      return {
        ...state,
        fiduciaryInfoUpdateStatus: "ATTEMPT",
      }
    case FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_SUCCESS:
      return {
        ...state,
        name: action.payload.fiduciary.fiduciary_name,
        address: action.payload.fiduciary.street_infos,
        city: action.payload.fiduciary.city,
        postalCode: action.payload.fiduciary.postal_code,
        phone: action.payload.fiduciary.phone,
        fiduciaryInfoUpdateStatus: "SUCCESS",
        acd_api_due_date: action.payload.fiduciary.acdApiDueDate,
        acd_api_document_reference:
          action.payload.fiduciary.acdApiDocumentReference,
      }
    case FiduciaryActionsEnum.FIDUCIARY_INFOS_UPDATE_FAILURE:
      return {
        ...state,
        fiduciaryInfoUpdateStatus: "ERROR",
      }
    case FiduciaryActionsEnum.FIDUCIARY_LOGO_UPDATE_SUCCESS:
      return {
        ...state,
        logoId: action.payload.obj_id,
      }
    case FiduciaryActionsEnum.REGISTER_FIDUCIARY_FRIEND:
      return {
        ...state,
        referer: action.payload.referer,
      }
    case FiduciaryActionsEnum.GET_INQOM_SIGN_REQUEST_SUCCESS:
      return {
        ...state,
        inqom_sign_request: action.payload,
      }
    case FiduciaryActionsEnum.UPDATE_ACD_SENT_DATA_SUCCESS: {
      return {
        ...state,
        acd_api_due_date: action.payload.dueDate,
        acd_api_document_reference: action.payload.documentReference,
      }
    }
    case FiduciaryActionsEnum.GET_API_CONNECT_SUCCESS: {
      return {
        ...state,
        api_connected: action.payload.apiConnected,
        apiConnectedStatus: "CHECKED",
      }
    }
    case FiduciaryActionsEnum.GET_API_CONNECT_ERROR: {
      return {
        ...state,
        apiConnectedStatus: "ERROR",
      }
    }
    case FiduciaryActionsEnum.GET_API_CONNECT_ATTEMPT: {
      return {
        ...state,
        apiConnectedStatus: "CHECKING",
      }
    }
    case FiduciaryActionsEnum.GET_API_CONNECT_RESET: {
      return {
        ...state,
        apiConnectedStatus: "IDLE",
      }
    }
    case FiduciaryActionsEnum.UPDATE_DEFAULT_ACCOUNTING_SOFTWARE_SUCCESS: {
      return {
        ...state,
        comptaSoftware: action.newSoftware,
      }
    }

    default:
      return { ...state }
  }
}

export const attemptFiduciaryRegistrationThunk =
  (fiduciaryFormData: FiduciaryFormData) =>
  (dispatch: Dispatch<FiduciaryActionsType>, getState: () => RootState) => {
    dispatch(registrationAttemptAction())
    const fiduciaryRegistrationURI = "/fiduciaries"
    const fiduciary = getState().fiduciary

    const requestPayload = {
      fiduciary_name: fiduciaryFormData.fiduciaryName,
      street_infos: fiduciaryFormData.address,
      city: fiduciaryFormData.city,
      postal_code: fiduciaryFormData.postalCode,
      phone: cleanPhone(fiduciaryFormData.phone),
      origin: fiduciaryFormData.localisation.value,
      accounting_software: fiduciaryFormData.comptaSoftware.value,
      how_did_you_hear: fiduciaryFormData.discovery.value,
      identification_number: fiduciary.siren,
      code_ape: fiduciary.code_ape,
      fiduciary_friend: fiduciary.referer,
    }

    axios
      .post<{ id: number }>(`${fiduciaryRegistrationURI}`, requestPayload)
      .then((response) => {
        return dispatch(registrationSuccessAction(response.data))
      })
      .catch((e) => {
        if ("fiduciary_name" in e.response.data.error) {
          return dispatch(registrationFailureActionTakenFiduciaryName())
        }
        if ("phone" in e.response.data.error) {
          return dispatch(registrationFailureActionWrongPhoneNumber())
        }
        return dispatch(registrationFailureAction(e))
      })
  }

export const attemptSirenValidationThunk =
  (siren: string) => (dispatch: Dispatch<FiduciaryActionsType>) => {
    dispatch(sirenAttemptAction())
    const redundancyCheckURI = "/fiduciaries/existing_fiduciary"
    const sirenValidationURI = "/companies/siren_to_name"

    let fiduciaryExistsInDB: null | boolean = null
    let fiduciary = {}

    axios
      .get<SirenCheckResponseType>(`${sirenValidationURI}`, {
        params: {
          siren: cleanSIREN(siren),
          scope: "fiduciary",
        },
      })
      .then((response) => {
        fiduciary = {
          code_ape: response.data.code_ape || null,
          siren: cleanSIREN(siren),
          name: response.data.name || "",
          address: response.data.address || "",
          city: response.data.city || "",
          postalCode: response.data.postal_code || "",
          phone: "",
          sirenState: null,
          isBadApe: false,
          companies: [],
          comptaSoftware: "",
          created_at: "",
          companies_creation_locked_at: false,
          ape_details: response.data.ape_details || "",
          country: response.data.country || "",
          country_code: response.data.country_code || "FR",
          full_legal_form: response.data.full_legal_form || "",
          legal_form: response.data.legal_form || "",
          max_work_force: response.data.max_work_force || 0,
          min_work_force: response.data.min_work_force || 0,
          share_capital: response.data.share_capital || null,
          siege_siret: response.data.siege_siret || "",
          vat_number: response.data.vat_number || "",
          work_force: response.data.work_force || "",
        }
      })
      .then(() =>
        axios.get<boolean>(`${redundancyCheckURI}`, {
          params: {
            identification_number: cleanSIREN(siren),
          },
        })
      )
      .then((response) => {
        fiduciaryExistsInDB = response.data
        return dispatch(
          fiduciarySirenSuccessAction(
            fiduciary as FiduciaryObjectType,
            fiduciaryExistsInDB
          )
        )
      })
      .catch((e) => {
        if (
          typeof e.response.data === "string" &&
          e.response.data.includes("Unité légale non diffusable")
        ) {
          return dispatch(fiduciarySirenHiddenAction())
        }
        return dispatch(sirenFailureAction())
      })
  }

export const getFiduciaryThunk = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(getFiduciaryAttemptAction())
  return axios
    .get<FiduciaryResponse>(`/users/get_fiduciary`)
    .then(({ data }) => {
      dispatch(
        getFiduciarySuccessAction({
          id: data.fiduciary.id,
          name: data.fiduciary.fiduciary_name,
          address: data.fiduciary.street_infos,
          city: data.fiduciary.city,
          postalCode: data.fiduciary.postal_code,
          phone: data.fiduciary.phone,
          comptaSoftware: data.fiduciary.accounting_software,
          logoId: data.logo,
          logoUrl: data.logo_url,
          created_at: data.fiduciary.created_at,
          companies_creation_locked_at: data.fiduciary
            .companies_creation_locked_at
            ? true
            : false,
          acd_api_due_date: data.fiduciary.acd_api_due_date,
          acd_api_document_reference: data.fiduciary.acd_api_document_reference,
          active_pricing: data.active_pricing,
          override_pricing: data.override_pricing,
          cash_or_accrual: data.fiduciary.cash_or_accrual,
        })
      )
    })
    .catch((e: AxiosError) => {
      dispatch(getFiduciaryFailureAction(e))
    })
}
export const checkExistingFiduciaryThunk =
  () => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFiduciaryAttemptAction())
    return axios
      .get<FiduciaryResponse>(`/users/get_fiduciary`)
      .then(({ data }) => {
        dispatch(checkExistingFiduciarySuccessAction())
      })
      .catch((e: AxiosError) => {
        dispatch(checkExistingFiduciaryFailureAction(e))
      })
  }

interface FiduciaryPayload {
  id: number
  name: string
  address: string
  city: string
  postalCode: string
  phone: string
  logoId: number
  logoUrl: string | null
  comptaSoftware: string
  created_at: string
  companies_creation_locked_at: boolean
  acd_api_due_date: boolean
  acd_api_document_reference: boolean
  active_pricing: Pricing | null
  override_pricing: boolean
  cash_or_accrual: "cash" | "accrual" | "both"
}

interface FiduciaryResponse {
  fiduciary: {
    id: number
    fiduciary_name: string
    street_infos: string
    city: string
    postal_code: string
    phone: string
    accounting_software: string
    created_at: string
    companies_creation_locked_at: boolean
    acd_api_due_date: boolean
    acd_api_document_reference: boolean
    cash_or_accrual: "cash" | "accrual" | "both"
  }
  logo: number
  logo_url: string | null
  active_pricing: Pricing
  override_pricing: boolean
}

interface Pricing {
  cash_accounting_price_in_cents: string
  price_in_cents: string
  pricing_type: "per_company_month" | "per_document" | null
}

export interface UploadPayload {
  originalFileName: string
  originalFileSize: number
  fingerprint: string
  file: File
}

interface UpdateFiduciaryLogoResponse {
  status: "ok"
  obj_id: number
  file_name: string
}

interface UPdateFiduciaryReferer {
  referer: string
}

export interface SignRequestResponse {
  put_url: string
}

interface UpdateFiduciaryInfosPayload {
  name: string
  address: string
  city: string
  postalCode: string
  phone: string
  acdApiDueDate: boolean | null
  acdApiDocumentReference: boolean | null
}

interface UpdateFiduciaryInfosResponse {
  fiduciary: {
    fiduciary_name: string
    street_infos: string
    city: string
    postal_code: string
    phone: string
    acdApiDueDate: boolean
    acdApiDocumentReference: boolean
  }
}

interface GetInqomSignRequestInfosResponse {
  connected: boolean
  params: {
    response_type: string
    scope: string
    client_id: string
    state: string
    redirect_uri: string
    request_url: string
  }
}

interface GetBillDownloadUrlResponse {
  url: string
  status: "success" | "error"
}

export const updateFiduciaryLogoThunk =
  ({ originalFileName, originalFileSize, fingerprint, file }: UploadPayload) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(fiduciaryLogoUpdateAttemptAction())
    return axios
      .post<UpdateFiduciaryLogoResponse>(`/fiduciary_logos`, {
        fiduciary_id: getState().fiduciary.id,
        fingerprint: fingerprint,
        original_file_name: originalFileName,
        original_file_size: originalFileSize,
      })
      .then(({ data }) => {
        signRequest(data.obj_id).then((res) => {
          const headers = { "Cache-Control": "max-age=31536000" }
          axios
            .put(res.data.put_url, file, {
              withCredentials: false,
              headers: headers,
            })
            .then(() => {
              dispatch(
                fiduciaryLogoUpdateSuccessAction({
                  obj_id: data.obj_id,
                  file_name: originalFileName,
                  status: "ok",
                })
              )
            })
        })
      })
      .catch(() => {
        dispatch(fiduciaryLogoUpdateFailureAction())
      })
  }

function signRequest(id: number) {
  return axios.put<SignRequestResponse>(`/fiduciary_logos/sign_request`, {
    id,
  })
}

export const getBillsFromFiduciaryThunk =
  (fiduciaryId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getBillsFromFiduciaryAttemptAction())
    return axios
      .get<billsResponse[]>(`/fiduciaries/get_bills_per_fiduciary`, {
        params: { fiduciary_id: fiduciaryId },
      })
      .then((response) => {
        if (response.data.length === 0) {
          dispatch(getBillsFromFiduciarySuccessAction([]))
        } else {
          dispatch(getBillsFromFiduciarySuccessAction(response.data))
        }
      })
      .catch((e: AxiosError) => {
        dispatch(getBillsFromFiduciaryFailureAction(e))
      })
  }

export const updateFiduciaryInfosThunk =
  ({
    name,
    address,
    city,
    postalCode,
    phone,
    acdApiDueDate,
    acdApiDocumentReference,
  }: UpdateFiduciaryInfosPayload) =>
  (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
    dispatch(fiduciaryInfosUpdateAttemptAction())
    const fiduciary = getState().fiduciary
    return axios
      .put<UpdateFiduciaryInfosResponse>(`/fiduciaries/${fiduciary.id}`, {
        fiduciary: {
          id: fiduciary.id,
          fiduciary_name: name,
          street_infos: address,
          city,
          postal_code: postalCode,
          phone,
          acd_api_due_date: acdApiDueDate || false,
          acd_api_document_reference: acdApiDocumentReference || false,
        },
      })
      .then(() => {
        dispatch(
          fiduciaryInfosUpdateSuccessAction({
            fiduciary: {
              fiduciary_name: name,
              street_infos: address,
              city: city,
              postal_code: postalCode,
              phone,
              acdApiDueDate: acdApiDueDate || false,
              acdApiDocumentReference: acdApiDocumentReference || false,
            },
          })
        )
      })
      .catch((e: AxiosError) => {
        dispatch(fiduciaryInfosUpdateFailureAction(e))
      })
  }

export const getUrlForBillThunk =
  (fullDocumentId: number) => (dispatch: Dispatch<AnyAction>) => {
    axios
      .get<GetBillDownloadUrlResponse>("/fiduciaries/get_bill_download_url", {
        params: { id: fullDocumentId },
      })
      .then(({ data }) => {
        if (data.status === "success") window.open(data.url, "download")
        else dispatch(getUrlFailureAction())
      })
      .catch(() => dispatch(getUrlFailureAction()))
  }

export const getInqomSignRequestThunk =
  (fiduciary_id: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getInqomSignRequestAttemptAction())
    return axios
      .get<GetInqomSignRequestInfosResponse>(
        "fiduciaries/inqom_sign_request_params",
        {
          params: { fiduciary_id: fiduciary_id },
        }
      )
      .then((response) => {
        dispatch(getInqomSignRequestSuccessAction(response.data))
      })
      .catch((e: AxiosError) => {
        dispatch(getInqomSignRequestFailureAction())
      })
  }

export const inqomSignRequestCallback =
  (params: {}, fiduciary_id: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(inqomSignRequestCallbackAttemptAction())
    return axios
      .post("fiduciaries/inqom_sign_request_callback", params)
      .then((response) => {
        dispatch(getInqomSignRequestThunk(fiduciary_id))
        dispatch(inqomSignRequestCallbackSuccessAction())
      })
      .catch((e) => {
        dispatch(inqomSignRequestCallbackFailureAction())
      })
  }

export const updateAcdSentDataFiducaryThunk =
  ({
    fiduciaryId,
    dueDate,
    documentReference,
  }: {
    fiduciaryId: number
    dueDate: boolean | null
    documentReference: boolean | null
  }) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(updateAcdSentDataAttemptAction())
    return axios
      .post(`/fiduciaries/update_acd_sent_data_for_fiduciary`, {
        fiduciary_id: fiduciaryId,
        acd_api_due_date: dueDate || false,
        acd_api_document_reference: documentReference || false,
      })
      .then(() => {
        dispatch(
          updateAcdSentDataSuccessAction({
            fiduciaryId: fiduciaryId,
            dueDate: dueDate || false,
            documentReference: documentReference || false,
          })
        )
      })
      .catch(() => {
        dispatch(updateAcdSentDataErrorAction())
      })
  }

export const getApiConnected =
  (fiduciaryId: number) => (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(getApiConnectedResetAction())
    dispatch(getApiConnectedAttemptAction())
    return axios
      .get<{ api_connected: boolean }>("fiduciaries/api_connected_request", {
        params: { fiduciary_id: fiduciaryId },
      })
      .then((response) => {
        dispatch(getApiConnectedSuccessAction(response.data.api_connected))
      })
      .catch((e: AxiosError) => {
        dispatch(getApiConnectedErrorAction())
      })
  }

export const registerACDApi =
  ({
    fiduciaryId,
    identifier,
    password,
    cnx,
    apiUrl,
  }: {
    fiduciaryId: number
    identifier: string
    password: string
    cnx: string
    apiUrl: string
  }) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(registerAcdAttemptAction())
    return axios
      .post("fiduciaries/acd_sign_request", {
        fiduciary_id: fiduciaryId,
        identifier: identifier,
        password: password,
        cnx: cnx,
        api_url: apiUrl,
      })
      .then(() => {
        dispatch(registerAcdSuccessAction())
        dispatch(getApiConnected(fiduciaryId))
      })
      .catch((e) => {
        dispatch(registerAcdErrorAction())
      })
  }

export const UpdateDefaultAccountingSoftwareThunk =
  (fiduciaryId: number, newSoftware: string) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(UpdateDefaultAccountingSoftwareAttempt())
    return axios
      .post("fiduciaries/update_default_software", {
        fiduciary_id: fiduciaryId,
        accounting_software: newSoftware,
      })
      .then(() => {
        dispatch(UpdateDefaultAccountingSoftwareSuccess(newSoftware))
      })
      .catch((e) => {
        dispatch(UpdateDefaultAccountingSoftwareFailure())
      })
  }
