import { ReactComponent as Settings } from "../../../../assets/settingsAdjustVertically.svg"
import { ReactComponent as BankSettings } from "../../../../assets/bankSettings.svg"
import { ReactComponent as Products } from "../../../../assets/products.svg"
import { ReactComponent as UsersDuotone } from "../../../../assets/usersDuotone.svg"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useEffect } from "react"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { useDispatch } from "react-redux"
import {
  GetAllLegalEntitiesForCompanyThunk,
  getProductsThunk,
} from "../../../../store/ducks/invoicing.duck"
import { getIdFromParams } from "../../../../utils/company"
import { useLocation, useParams } from "react-router-dom"
import { isRouteSelected } from "../../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"

export const InvoicingMenu = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const selectedCompanyId = getIdFromParams(params)("company_id")
  const routePattern = "\\/company\\/\\d+\\/invoicing\\/settings"

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(GetAllLegalEntitiesForCompanyThunk(selectedCompanyId))
      dispatch(getProductsThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  const customers = useRNBSelector((state) => state.invoicing.legalEntities)
  const products = useRNBSelector((state) => state.invoicing.products)

  const linkTabs: TabObject[] = [
    {
      to: "customers",

      title: `Clients ${
        Object.keys(customers).length > 0
          ? `(${Object.keys(customers).length})`
          : ""
      }`,
      icon: <UsersDuotone />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "customers"),
    },
    {
      to: "products",
      title: `Produits ${
        Object.keys(products).length > 0
          ? `(${Object.keys(products).length})`
          : ""
      }`,
      icon: <Products />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "products"),
    },
    {
      to: "numbering",
      title: "Numérotations",
      icon: <Settings />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "numbering"),
    },
    {
      to: "bankSettings",
      title: "Paramètres",
      icon: <BankSettings />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "bankSettings"),
    },
  ]

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <>
      <Tabs
        tabs={linkTabs}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
        Link={Link}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}
