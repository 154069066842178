import { Document } from "../store/ducks/merchants.ducks"
import { getFileExtension } from "./batchDocuments"
import { capitalizeFirstLetter } from "./string"

export const filterDocs =
  (
    search: string,
    merchantName: string,
    customDateFilterIsActive: boolean,
    customDateRange: [Date, Date]
  ) =>
  (document: Document) => {
    if (!document) return

    const dateDefined = customDateFilterIsActive && customDateRange
    const isInRange =
      dateDefined &&
      new Date(document.document_date) >= customDateRange[0] &&
      new Date(document.document_date) <= customDateRange[1]
    const matchingDate = dateDefined ? isInRange : true
    if (!matchingDate) return false

    const searchLower = search.toLowerCase()
    return (
      merchantName.toLowerCase().includes(searchLower) ||
      searchLower.includes(merchantName.toLowerCase()) ||
      document.amount?.toLowerCase().includes(searchLower) ||
      document.document_reference?.toLowerCase().includes(searchLower) ||
      document.user_file_name?.toLowerCase().includes(searchLower) ||
      document.original_file_name?.toLowerCase().includes(searchLower)
    )
  }

export function getDocumentTypeDisplayed(document: Document) {
  const extension = getFileExtension(document.original_file_name)
  return capitalizeFirstLetter(extension.replace(".", ""))
}
