import { useCallback, useEffect, useState } from "react"
import { getBuyOrSellFromParams } from "../utils/company"
import { Params } from "react-router-dom"

export const useSubtitle = (
  pathname: string,
  params: Readonly<Params<string>>,
  prefix: string
) => {
  const [subtextId, setSubtextId] = useState<null | string>(null)

  const changeSubTextId = useCallback(
    (suffix: string) => {
      return suffix
        ? setSubtextId(`${prefix}.${suffix}`)
        : setSubtextId(`${prefix}`)
    },
    [prefix]
  )

  useEffect(() => {
    if (pathname.includes("drop")) {
      changeSubTextId("drop")
    } else if (pathname.includes("bank")) {
      changeSubTextId("bank")
    } else if (pathname.includes("invoicing/settings")) {
      changeSubTextId("invoicing.customers")
    } else if (pathname.includes("settings/options")) {
      changeSubTextId("settings")
    } else if (pathname.includes("writings/")) {
      changeSubTextId("writing")
    } else if (pathname.includes("writings")) {
      changeSubTextId("writings")
    } else if (pathname.includes("initialize")) {
      changeSubTextId("initialize")
    } else if (pathname.includes("merchants/")) {
      const buyOrSell = getBuyOrSellFromParams(params)
      if (pathname.includes("list"))
        changeSubTextId(`edm.${buyOrSell}.merchants.list`)
      else if (pathname.includes("edit"))
        changeSubTextId(`edm.${buyOrSell}.merchants.modify`)
    } else if (pathname.includes("merchants")) {
      const buyOrSell = getBuyOrSellFromParams(params)
      changeSubTextId(`edm.${buyOrSell}.merchants`)
    } else {
      setSubtextId(null)
    }
  }, [pathname, params, changeSubTextId])

  return subtextId
}
