import { useCallback, useEffect, useRef, useState } from "react"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import "cropperjs/dist/cropper.css"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { getIdFromParams } from "../../../../../utils/company"
import {
  getUserCompaniesThunk,
  updateBankSettingsThunk,
  UpdateCompanyPayload,
} from "../../../../../store/ducks/companies.ducks"
import styled from "styled-components/macro"
import { InputNumber } from "../../../../../components/Commons/InputNumber"
import {
  addAdditionalLogoReset,
  addInvoicingAdditionalLogoThunk,
  AdditionalLogo,
  deleteAdditionalLogoReset,
  getInvoicingAdditionalLogosThunk,
  updateAdditionalLogoReset,
} from "../../../../../store/ducks/invoicing.duck"
import {
  DropFileUpload,
  UploadStatus,
} from "../../../../../components/DropFile"
import { Input } from "../../../../../components/Commons/Input"

import { ReactComponent as Edit } from "../../../../../assets/edit.svg"
import { Alert } from "../../../../../components/Commons/Alert"
import { EditInvoicingAdditionalLogosModal } from "../../../../../components/EditInvoicingAdditionalLogosModal"
import config from "../../../../../config"
import Cropper from "react-cropper"
import { boxShadow, colors } from "../../../../../styles/design.config"

const BankSettings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()

  const selectedCompanyId = getIdFromParams(params)("company_id")
  const company = useRNBSelector((state) =>
    selectedCompanyId ? state.companies.companies[selectedCompanyId] : null
  )
  const updateCompanyInformationStatus = useRNBSelector(
    (state) => state.companies.updateCompanyInformationsStatus
  )
  useEffect(() => {
    dispatch(getUserCompaniesThunk())
  }, [dispatch])

  const initIban = company?.iban
  const initBic = company?.bic
  const initBankAccountOwner = company?.bankAccountOwner
  const initSpecialMentions = company?.specialMentions
  const initSpecialDiscount = company?.specialDiscount
  const initPaymentPeriod = company?.paymentPeriod

  const schema = yup.object({
    iban: yup
      .string()
      .trim()
      .matches(/[a-zA-Z]{2}[0-9]{2}([a-zA-Z0-9]?){10,30}|^$/)
      .max(34, "length"),
    bic: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$|^$/)
      .max(11, "length"),
    bankAccountOwner: yup.string().max(255, "length"),
    specialMentions: yup.string(),
    specialDiscount: yup.string().matches(/^[0-9][0-9]?$|^100$|^$/),
    paymentPeriod: yup.number(),
  })

  const {
    register,
    watch,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateCompanyPayload>({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })
  const [
    paymentPeriod,
    iban,
    bic,
    bankAccountOwner,
    specialMentions,
    specialDiscount,
  ] = watch([
    "paymentPeriod",
    "iban",
    "bic",
    "bankAccountOwner",
    "specialMentions",
    "specialDiscount",
  ])

  const [specialDiscountSetted, setSpecialDiscount] = useState(
    company?.specialDiscount || 0
  )

  const logosList = useRNBSelector((state) => state.invoicing.additionalLogos)
  const [defaultLogos, setDefaultLogos] = useState<AdditionalLogo[]>([])
  const [uploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("start")
  const [formatError, setFormatError] = useState(false)
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null>(null)
  const cropperRef = useRef<HTMLImageElement>(null)
  const [cropper, setCropper] = useState<Cropper>()
  const [currentLogoFileName, setCurrentLogoFileName] = useState<string>("")
  const addLogoStatus = useRNBSelector((state) => state.invoicing.addLogoStatus)
  const deleteAdditionalLogoStatus = useRNBSelector(
    (state) => state.invoicing.deleteAdditionalLogoStatus
  )
  const updateAdditionalLogoStatus = useRNBSelector(
    (state) => state.invoicing.updateAdditionalLogoStatus
  )
  const [displayModal, setDisplayModal] = useState(false)

  useEffect(() => {
    if (logosList) {
      setDefaultLogos([...logosList.filter((logo) => logo.is_default)])
    }
  }, [logosList])

  const [paymentPeriodSetted, setPaymentPeriod] = useState(
    company?.paymentPeriod || 30
  )
  useEffect(() => {
    if (selectedCompanyId && addLogoStatus === "SUCCESS") {
      dispatch(addAdditionalLogoReset())
      dispatch(getInvoicingAdditionalLogosThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId, addLogoStatus])

  useEffect(() => {
    if (selectedCompanyId && deleteAdditionalLogoStatus === "SUCCESS") {
      dispatch(deleteAdditionalLogoReset())
      dispatch(getInvoicingAdditionalLogosThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId, deleteAdditionalLogoStatus])

  useEffect(() => {
    if (selectedCompanyId && updateAdditionalLogoStatus === "SUCCESS") {
      dispatch(updateAdditionalLogoReset())
      dispatch(getInvoicingAdditionalLogosThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId, updateAdditionalLogoStatus])

  useEffect(() => {
    setSpecialDiscount(specialDiscount)
  }, [specialDiscount])

  useEffect(() => {
    setPaymentPeriod(paymentPeriod)
  }, [paymentPeriod])

  useEffect(() => {
    if (company) {
      if (selectedCompanyId) {
        dispatch(getInvoicingAdditionalLogosThunk(selectedCompanyId))
      }
      setValue("iban", initIban ? initIban : "")
      setValue("bic", initBic ? initBic : "")
      setValue(
        "bankAccountOwner",
        initBankAccountOwner ? initBankAccountOwner : ""
      )
      setValue(
        "specialMentions",
        initSpecialMentions ? initSpecialMentions : ""
      )
      setValue("specialDiscount", initSpecialDiscount ? initSpecialDiscount : 0)
      setValue("paymentPeriod", initPaymentPeriod ? initPaymentPeriod : 30)
    }
  }, [
    initIban,
    initBic,
    initBankAccountOwner,
    initSpecialMentions,
    initSpecialDiscount,
    initPaymentPeriod,
    setValue,
  ])

  const submitForm = () => {
    if (selectedCompanyId) {
      dispatch(
        updateBankSettingsThunk(
          {
            iban,
            bic,
            bankAccountOwner,
            specialMentions,
            specialDiscount: specialDiscountSetted,
            paymentPeriod: paymentPeriodSetted,
          },
          selectedCompanyId
        )
      )
      setIsDisabled(true)
    }
  }

  useEffect(() => {
    if (updateCompanyInformationStatus === "SUCCESS" && selectedCompanyId) {
      reset({
        iban: company?.iban,
        bic: company?.bic,
        bankAccountOwner: company?.bankAccountOwner,
        specialMentions: company?.specialMentions,
        specialDiscount: company?.specialDiscount,
        paymentPeriod: company?.paymentPeriod,
      })
    }
  }, [
    updateCompanyInformationStatus,
    initIban,
    initBic,
    initBankAccountOwner,
    initSpecialMentions,
    initSpecialDiscount,
    initPaymentPeriod,
    reset,
  ])

  const uploadFile = (file: File): void => {
    if (!validateLogoFormat(file) || !file.size) {
      setFormatError(true)
      return
    }
    setFormatError(false)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function (e) {
      setCurrentLogoFileName(file.name)
      setUploadedImageSrc(reader.result as string)
    }
  }

  const validateLogoFormat = (file: File): Boolean => {
    const format = file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
    return [".jpg", ".png", ".jpeg"].includes(format.toLowerCase())
  }

  const onCrop = useCallback(() => {
    if (!cropper || !selectedCompanyId) {
      return
    }

    setUploadStatus("confirmation")
    let file: File
    cropper.getCroppedCanvas().toBlob(async function (blob: Blob | null) {
      if (!blob) {
        return
      }
      file = new File([blob], currentLogoFileName || "logo.jpg", {
        type: "image/jpeg",
      })
      crypto.subtle
        .digest("SHA-512", await file.arrayBuffer())
        .then(function (h) {
          dispatch(
            addInvoicingAdditionalLogoThunk(selectedCompanyId, file.name, file)
          )
        })
        .then(() => {
          setUploadStatus("start")
          setCurrentLogoFileName("")
          setUploadedImageSrc(null)
        })
    }, "image/jpeg")
  }, [cropper, dispatch, setUploadedImageSrc, selectedCompanyId])
  const [disabledSaveButton, setIsDisabled] = useState(true)

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <Wrapper>
      <StyledSection>
        <Ct.StyledForm>
          <EditInvoicingAdditionalLogosModal
            selectedCompanyId={selectedCompanyId}
            logosList={logosList}
            isDisplayed={displayModal}
            onClose={() => {
              setDisplayModal(!displayModal)
            }}
          />
          <MiddleDiv>
            <StyledCard>
              <StyledText
                text={intl.formatMessage({
                  id: "invoicing.bill-settings.title-settings",
                })}
                textStyle={{ color: "navy", fontWeight: 600 }}
              />
              <Ct.Spacer height={4} />
              <Ct.Input
                name="iban"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.iban",
                })}
                type="text"
                value={iban}
              />
              {errors["iban"] && (
                <>
                  <Ct.Spacer height={1} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.bill-settings.iban-error",
                    })}
                    textStyle={{
                      color: "amaranth",
                    }}
                  />
                </>
              )}
              <Ct.Spacer height={4} />
              <Ct.Input
                name="bic"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.bic",
                })}
                type="text"
                value={bic}
              />
              {errors["bic"] && (
                <>
                  <Ct.Spacer height={1} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.bill-settings.bic-error",
                    })}
                    textStyle={{
                      color: "amaranth",
                    }}
                  />
                </>
              )}
              <Ct.Spacer height={4} />
              <Ct.Input
                name="bankAccountOwner"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.owner-name",
                })}
                type="text"
                value={bankAccountOwner}
              />
              <Ct.Spacer height={4} />
              <StyledText
                text={intl.formatMessage({
                  id: "invoicing.bill-settings.title-mentions",
                })}
                textStyle={{ color: "navy", fontWeight: 600 }}
              />
              <Ct.Spacer height={4} />
              <Input
                AutoGrowing={true}
                height="8rem"
                name="specialMentions"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.special-mentions",
                })}
                type="text"
                value={specialMentions}
              />
              <Ct.Spacer height={4} />
              <InputNumber
                name="specialDiscount"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.special-discount",
                })}
                value={specialDiscountSetted}
                onChange={(e) => {
                  setIsDisabled(
                    Number(initSpecialDiscount) ===
                      Math.round(e.target.valueAsNumber) &&
                      Number(initPaymentPeriod) === Number(paymentPeriodSetted)
                  )
                  if (!e.target.value || parseFloat(e.target.value) < 0) {
                    setSpecialDiscount(0)
                  } else if (parseFloat(e.target.value) > 100) {
                    setSpecialDiscount(100)
                  } else {
                    setSpecialDiscount(Math.round(e.target.valueAsNumber))
                  }
                }}
                max={100}
                min={0}
                step={1}
                suffix={"%"}
              />
              {errors["specialDiscount"] && (
                <>
                  <Ct.Spacer height={1} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.bill-settings.special-discount-error",
                    })}
                    textStyle={{
                      color: "amaranth",
                    }}
                  />
                </>
              )}
              <Ct.Spacer height={4} />

              <InputNumber
                suffix="jours"
                suffixPosition={8}
                name="paymentPeriod"
                register={register as unknown as UseFormRegister<FieldValues>}
                label={intl.formatMessage({
                  id: "invoicing.bill-settings.payment-period",
                })}
                value={paymentPeriodSetted}
                onChange={(e) => {
                  setIsDisabled(
                    Number(initPaymentPeriod) ===
                      Math.round(e.target.valueAsNumber) &&
                      Number(initSpecialDiscount) ===
                        Number(specialDiscountSetted)
                  )
                  if (!e.target.value || parseFloat(e.target.value) < 0) {
                    setPaymentPeriod(0)
                  } else {
                    setPaymentPeriod(Math.round(e.target.valueAsNumber))
                  }
                }}
                min={0}
                step={1}
              />
            </StyledCard>
            <StyledCard>
              <StyledText
                text={intl.formatMessage({
                  id: "invoicing.bill-settings.title-logos",
                })}
                textStyle={{ color: "navy", fontWeight: 600 }}
              />
              <Ct.Spacer height={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.bill-settings.title-logos.explanation",
                })}
              />
              <Ct.Spacer height={2} />

              <DropZone>
                {!uploadedImageSrc && (
                  <DropFileUpload
                    uploadFile={uploadFile}
                    dropText="Logo"
                    uploadStatus={uploadStatus}
                    uploadProgress={uploadProgress}
                  />
                )}
                <Ct.Spacer height={4} />
                {uploadedImageSrc && (
                  <>
                    <Cropper
                      src={uploadedImageSrc}
                      initialAspectRatio={1}
                      onInitialized={(instance) => {
                        setCropper(instance)
                      }}
                      ref={cropperRef}
                    />
                    <Ct.Spacer />
                    <Ct.JustifyCenter>
                      <Ct.Button
                        label={intl.formatMessage({
                          id:
                            uploadStatus === "confirmation"
                              ? "invoicing.settings.additional-logos.add-additional-logo.crop.confirmation"
                              : "invoicing.settings.additional-logos.add-additional-logo.crop.save",
                        })}
                        onClick={() => {
                          onCrop()
                        }}
                        width={42.5}
                        disabled={uploadStatus === "confirmation"}
                      />
                    </Ct.JustifyCenter>
                    <Ct.Spacer height={4} />
                  </>
                )}
              </DropZone>

              {formatError && (
                <>
                  <Ct.Spacer height={2} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.bill-settings.additional-logos.import.wrong-format",
                    })}
                    textStyle={{ color: "amaranth", fontWeight: 700 }}
                  />
                </>
              )}

              <Ct.Spacer height={2} />
              <RowCenterWrapper>
                <StyledText
                  text={intl.formatMessage({
                    id: "invoicing.bill-settings.additional-logos.defaults.title",
                  })}
                  textStyle={{ color: "navy", fontWeight: 600 }}
                />

                <StyledLink
                  onClick={() => {
                    setDisplayModal(true)
                  }}
                >
                  <EditLogo />
                  {intl.formatMessage({
                    id: "invoicing.bill-settings.additional-logos.edit",
                  })}
                </StyledLink>
              </RowCenterWrapper>
              <Ct.Spacer height={4} />
              {defaultLogos.length > 0 ? (
                <DefaultLogosContent>
                  {defaultLogos.map((aDefaultLogo, i) => (
                    <LogoCard key={aDefaultLogo.file_name}>
                      <StyledCompanyLogo
                        src={`${config.invoicingAdditionalLogosBucketUri}${aDefaultLogo.file_name}`}
                        alt="logo"
                      />
                    </LogoCard>
                  ))}
                </DefaultLogosContent>
              ) : (
                <AlertWrapper>
                  <Alert
                    alertType={"info"}
                    boxSizing={"border-box"}
                    stretch={true}
                  >
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "invoicing.bill-settings.additional-logos.empty",
                      })}
                      textStyle={{ lineHeight: 3 }}
                    />
                  </Alert>
                </AlertWrapper>
              )}
              <Ct.Spacer height={4} />
            </StyledCard>
          </MiddleDiv>
          <Ct.Button
            label={intl.formatMessage({
              id: "invoicing.bill-settings.update-infos.save-button",
            })}
            width={42.5}
            onClick={submitForm}
            disabled={
              disabledSaveButton &&
              (Object.values(errors).length > 0 || !isDirty)
            }
          />
        </Ct.StyledForm>
      </StyledSection>
    </Wrapper>
  )
}

export default BankSettings

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  margin: 4rem 4rem 4rem 6.5rem;
  box-shadow: none;
`
const StyledText = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  justify-content: space-between;
`
const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 2rem;
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  :hover {
    color: ${colors.cornflower};
  }
  width: 34rem;
`
const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10rem;
  width: 100%;
`
const EditLogo = styled(Edit)`
  & path {
    fill: ${colors.cornflower};
  }
  width: 3rem;
  height: 3rem;
`
const AlertWrapper = styled.div`
  align-self: center;
  width: 50rem;
`
const DefaultLogosContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`
const LogoCard = styled.div`
  width: 17rem;
  display: flex;
  justify-content: center;
  border: 2px ${colors.lavender} solid;
  border-radius: 10px;
`
const StyledCompanyLogo = styled.img`
  border-radius: 1.25rem;
  height: 15rem;
  width: 15rem;
  object-fit: contain;
  flex-shrink: 0;
  margin: 2rem 4rem;
`
const MiddleDiv = styled.div`
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
