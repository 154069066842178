import { useEffect, useState } from "react"
import { useRNBSelector } from "../../../../store/rootReducer"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import {
  AccountingSoftware,
  AccountingSoftwareArray,
  displayPrefix,
  getIdFromParams,
} from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { Logos } from "../../../../components/Commons/Logos"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { ReactComponent as Eye } from "../../../../assets/eye.svg"
import { ReactComponent as Warning } from "../../../../assets/warning.svg"
import { Text } from "../../../../components/Commons/Text"
import { Select, Option } from "../../../../components/Commons/Select"
import { Input } from "../../../../components/Commons/Input"
import { Alert } from "../../../../components/Commons/Alert"
import { useDispatch } from "react-redux"
import { GetDataToUpdateAccountingSoftwareThunk } from "../../../../store/ducks/companySettings.ducks"
import { Button } from "../../../../components/Commons/Button"
import { AccountsOrCodesTooLongModal } from "../../../../components/company/AccountsOrCodesTooLongModal"
import { SoftwareHistoryModal } from "../../../../components/company/SoftwareHistoryModal"
import { UpdateAccountingSoftwareThunk } from "../../../../store/ducks/companies.ducks"
import { LockableInput } from "../../../../components/Commons/LockableInput"
import { addPrefixToCode } from "../../../../utils/merchantCodes"
import { PennyLaneLoginButton } from "./PennyLaneLoginButton"
/* eslint-disable camelcase */

export const ChangeSoftware = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId || 0]
  )
  const {
    codes_too_long,
    accounts_too_long,
    auxiliary_or_principal,
    accounting_software_prefix,
    software_events,
  } = useRNBSelector(
    (state) => state.companySettings.dataToUpdateAccountingSoftware
  )

  const softwareOptions: { value: AccountingSoftware; label: string }[] =
    AccountingSoftwareArray.filter((soft) => soft !== "unknown")
      .map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  const [selectedSoftware, setSelectedSoftware] = useState<{
    value: AccountingSoftware
    label: string
  } | null>(null)
  const [prefixBuy, setPrefixBuy] = useState<string>("")
  const [prefixSell, setPrefixSell] = useState<string>("")
  const [reference, setReference] = useState<string>("")
  const [hasValuesChanged, setHasValuesChanged] = useState<boolean>(false)
  const [displayModalTooLong, setDisplayModalTooLong] = useState<boolean>(false)
  const [displayHistory, setDisplayHistory] = useState<boolean>(false)

  const dataToLong = accounts_too_long.length > 0 || codes_too_long.length > 0
  const bothToLong = accounts_too_long.length > 0 && codes_too_long.length > 0

  useEffect(() => {
    if (!selectedSoftware && company?.accounting_software) {
      const companySoftware = softwareOptions.find(
        (soft) => soft.value === company.accounting_software
      )
      if (companySoftware && selectedCompanyId) {
        setSelectedSoftware(companySoftware)
        dispatch(
          GetDataToUpdateAccountingSoftwareThunk(
            selectedCompanyId,
            companySoftware.value,
            company.buy_auxiliary_prefix || "",
            company.sell_auxiliary_prefix || ""
          )
        )
      }
    }
    if (prefixBuy === "" && company?.buy_auxiliary_prefix) {
      setPrefixBuy(company.buy_auxiliary_prefix)
    }
    if (prefixSell === "" && company?.sell_auxiliary_prefix) {
      setPrefixSell(company.sell_auxiliary_prefix)
    }
    if (reference === "" && company?.accounting_software_reference) {
      setReference(company.accounting_software_reference)
    }
  }, [company, dispatch])

  useEffect(() => {
    if (!selectedCompanyId || !selectedSoftware) return
    if (
      selectedSoftware?.value &&
      company?.accounting_software &&
      selectedSoftware.value !== company?.accounting_software
    ) {
      setHasValuesChanged(true)
    }
    if (
      prefixBuy !== company?.buy_auxiliary_prefix ||
      prefixSell !== company?.sell_auxiliary_prefix
    ) {
      setHasValuesChanged(true)
    }
  }, [company, dispatch, selectedSoftware, prefixBuy, prefixSell])

  useEffect(() => {
    if (!selectedCompanyId || !selectedSoftware || !hasValuesChanged) return
    const delayAfterTyping = setTimeout(() => {
      dispatch(
        GetDataToUpdateAccountingSoftwareThunk(
          selectedCompanyId,
          selectedSoftware.value,
          prefixBuy,
          prefixSell
        )
      )
      setHasValuesChanged(false)
    }, 600)
    return () => clearTimeout(delayAfterTyping)
  }, [hasValuesChanged])

  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  const prefixBuyToDisplay = displayPrefix(
    auxiliary_or_principal,
    accounting_software_prefix,
    company?.auxiliary,
    "buy"
  )

  const prefixSellToDisplay = displayPrefix(
    auxiliary_or_principal,
    accounting_software_prefix,
    company?.auxiliary,
    "sell"
  )

  const disableButtons =
    company?.accounting_software === selectedSoftware?.value &&
    !(selectedSoftware?.value === "isacompta" && reference === "") &&
    company?.buy_auxiliary_prefix === prefixBuy &&
    company?.sell_auxiliary_prefix === prefixSell &&
    (company?.accounting_software_reference || "") === reference

  return (
    <Wrapper>
      <StyledSection>
        <WrapperParts>
          <WrapperDivider>
            <DivLeft>
              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-change-software.current-software",
                })}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 2,
                }}
              />
              <Ct.Spacer height={3} />

              <Ct.ColumnCenterCenter>
                {company?.accounting_software && (
                  <Logos
                    software={company.accounting_software as string}
                    width={"30rem"}
                    height={"15rem"}
                  />
                )}

                {company?.accounting_software === "pennylane" && (
                  <PennyLaneLoginButton />
                )}
                <Ct.Spacer height={4} />

                <LockableInput
                  locked={true}
                  value={intl.formatMessage({
                    id: `office-company-settings.${
                      company == null
                        ? "auxiliary-null"
                        : company.auxiliary === null
                        ? "auxiliary-null"
                        : company.auxiliary
                        ? "auxiliary"
                        : "principal"
                    }`,
                  })}
                  label={intl.formatMessage({
                    id: "office-company-settings.accounting_type-label",
                  })}
                  maxWidth={"100%"}
                />
              </Ct.ColumnCenterCenter>

              <Ct.Spacer height={8} />

              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-change-software.example-output",
                })}
                textStyle={{
                  fontWeight: 600,
                  fontSize: 2,
                }}
              />

              <Ct.Spacer height={3} />
              <Ct.ColumnCenterCenter>
                <WrapperLedger>
                  <Header>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "ged.modal.standard-ledger.compteNum",
                      })}
                      textStyle={{
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "ged.modal.standard-ledger.compteLib",
                      })}
                      textStyle={{
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "ged.modal.standard-ledger.compteAuxNum",
                      })}
                      textStyle={{
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "ged.modal.standard-ledger.compteAuxLib",
                      })}
                      textStyle={{
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    />
                  </Header>
                  <Ct.Spacer height={3} />

                  <CardBody id={"cardMerchantStandardLedger"}>
                    {company && company.auxiliary !== null && (
                      <>
                        <Ct.Text
                          text={
                            (company?.auxiliary
                              ? "401000"
                              : `${
                                  (company.accounting_software === "acd"
                                    ? "F"
                                    : "") +
                                  (addPrefixToCode(company).buy + "CHAIN")
                                }`) || ""
                          }
                          textStyle={{ textAlign: "center" }}
                        />
                        <Ct.Text
                          text={
                            (company?.auxiliary
                              ? "Fournisseurs Généraux"
                              : "CHAINTRUST") || ""
                          }
                          textStyle={{ textAlign: "center" }}
                        />
                        <Ct.Text
                          text={
                            (company?.auxiliary
                              ? `${addPrefixToCode(company).buy + "CHAIN"}`
                              : "") || ""
                          }
                          textStyle={{ textAlign: "center" }}
                        />
                        <Ct.Text
                          text={(company?.auxiliary ? "CHAINTRUST" : "") || ""}
                          textStyle={{ textAlign: "center" }}
                        />
                      </>
                    )}
                  </CardBody>
                  <Ct.Spacer height={3} />
                </WrapperLedger>
              </Ct.ColumnCenterCenter>

              <Ct.Spacer />
            </DivLeft>

            <Divider />

            <DivRight>
              <History>
                <StyledEye />
                <Ct.Spacer width={1} />
                <StyledHistory
                  text={intl.formatMessage({
                    id: "office-company-change-software.history",
                  })}
                  textStyle={{
                    color: "cornflower",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDisplayHistory(true)
                  }}
                />
              </History>
              <Ct.Spacer height={4} />

              <Ct.ColumnCenterCenter>
                <Alert alertType={"bulb"}>
                  <Text
                    text={intl.formatMessage({
                      id: "office-company-change-software.alert",
                    })}
                  />
                </Alert>
                <Ct.Spacer height={6} />

                <Select
                  options={softwareOptions}
                  value={selectedSoftware}
                  onChangeCallback={(e) => {
                    setSelectedSoftware(e as Option<AccountingSoftware>)
                  }}
                  intl={intl}
                  label={""}
                  domain={"office-company-change-software"}
                  optionType={"new-software"}
                  customWidth={"100%"}
                />
                <Ct.Spacer height={4} />

                <Input
                  value={prefixBuy}
                  label={intl.formatMessage({
                    id: "office-company-change-software.prefix-buy",
                  })}
                  onChange={(e) => {
                    setPrefixBuy(e.target.value)
                  }}
                  prefixString={prefixBuyToDisplay}
                  padding={`0 1.75rem 0 ${
                    prefixBuyToDisplay.length > 0
                      ? prefixBuyToDisplay.length + 3
                      : 2
                  }rem`}
                />
                <Ct.Spacer height={4} />

                <Input
                  value={prefixSell}
                  label={intl.formatMessage({
                    id: "office-company-change-software.prefix-sell",
                  })}
                  onChange={(e) => {
                    setPrefixSell(e.target.value)
                  }}
                  prefixString={prefixSellToDisplay}
                  padding={`0 1.75rem 0 ${
                    prefixSellToDisplay.length > 0
                      ? prefixSellToDisplay.length + 3
                      : 2
                  }rem`}
                />
                <Ct.Spacer height={4} />

                <Input
                  value={reference}
                  label={intl.formatMessage(
                    {
                      id: "office-company-change-software.reference",
                    },
                    {
                      mandatory:
                        selectedSoftware?.value === "isacompta" ? "*" : "",
                    }
                  )}
                  onChange={(e) => {
                    setReference(e.target.value)
                  }}
                  showError={
                    selectedSoftware?.value === "isacompta" &&
                    (!reference || reference === "")
                  }
                />

                {selectedSoftware?.value === "isacompta" &&
                  (!reference || reference === "") && (
                    <WrapperWarning>
                      <Ct.Spacer height={1} />
                      <Text
                        text={intl.formatMessage({
                          id: "office-company-change-software.empty-reference",
                        })}
                        textStyle={{ color: "amaranth" }}
                      />
                      <Ct.Spacer />
                    </WrapperWarning>
                  )}

                <Ct.Spacer height={1} />

                <WrapperErrors>
                  {dataToLong && (
                    <>
                      <Ct.Row>
                        <StyledWarning />
                        <Ct.Spacer width={1} />
                        <Text
                          text={intl.formatMessage(
                            {
                              id: "office-company-change-software.warning-message",
                            },
                            {
                              accounts:
                                accounts_too_long.length > 0 ? "comptes" : "",
                              and: bothToLong ? "et" : "",
                              codes:
                                codes_too_long.length > 0 ? "codes tiers" : "",
                            }
                          )}
                          textStyle={{ color: "amaranth" }}
                        />
                      </Ct.Row>

                      <Ct.Spacer />

                      {accounts_too_long.length > 0 && (
                        <WrapperWarning>
                          <Text
                            text={intl.formatMessage(
                              {
                                id: "office-company-change-software.warning-accounts",
                              },
                              {
                                accounts: accounts_too_long
                                  .map((acc) => acc.number)
                                  .join(", "),
                              }
                            )}
                            textStyle={{ color: "amaranth" }}
                          />
                        </WrapperWarning>
                      )}

                      {codes_too_long.length > 0 && (
                        <WrapperWarning>
                          <Ct.Spacer />

                          <Text
                            text={intl.formatMessage(
                              {
                                id: "office-company-change-software.warning-codes",
                              },
                              {
                                codes: codes_too_long
                                  .map((code) => code.code)
                                  .join(", "),
                              }
                            )}
                            textStyle={{ color: "amaranth" }}
                          />
                        </WrapperWarning>
                      )}
                    </>
                  )}

                  <Ct.Spacer height={2} />

                  {company?.accounting_software &&
                    (dataToLong ? (
                      <Button
                        label={intl.formatMessage(
                          {
                            id: "office-company-change-software.button-error",
                          },
                          {
                            accounts:
                              accounts_too_long.length > 0 ? "comptes" : "",
                            and: bothToLong ? "et" : "",
                            codes:
                              codes_too_long.length > 0 ? "codes tiers" : "",
                          }
                        )}
                        width={45}
                        disabled={
                          disableButtons ||
                          (selectedSoftware?.value === "isacompta" &&
                            reference === "")
                        }
                        colorType={"Tertiary"}
                        colorScheme={{
                          background: "amaranth",
                          color: "white",
                          border: "amaranth",
                        }}
                        onClick={() => {
                          setDisplayModalTooLong(true)
                        }}
                      />
                    ) : (
                      <Button
                        label={intl.formatMessage({
                          id: "office-company-change-software.button-confirm",
                        })}
                        width={45}
                        disabled={disableButtons}
                        onClick={() => {
                          if (selectedCompanyId && selectedSoftware) {
                            dispatch(
                              UpdateAccountingSoftwareThunk(
                                selectedCompanyId,
                                selectedSoftware.value,
                                prefixBuy,
                                prefixSell,
                                reference,
                                [],
                                []
                              )
                            )
                          }
                        }}
                      />
                    ))}
                </WrapperErrors>
              </Ct.ColumnCenterCenter>

              <Ct.Spacer height={4} />
            </DivRight>
          </WrapperDivider>

          {displayModalTooLong &&
            dataToLong &&
            selectedSoftware &&
            selectedCompanyId && (
              <AccountsOrCodesTooLongModal
                isDisplayed={displayModalTooLong}
                onClose={() => {
                  setDisplayModalTooLong(false)
                }}
                newSoftware={selectedSoftware}
                prefixBuy={prefixBuy}
                prefixSell={prefixSell}
                reference={reference}
                companyId={selectedCompanyId}
                prefixBuyToDisplay={prefixBuyToDisplay}
                prefixSellToDisplay={prefixSellToDisplay}
              />
            )}

          {displayHistory && (
            <SoftwareHistoryModal
              isDisplayed={displayHistory}
              onClose={() => {
                setDisplayHistory(false)
              }}
              softwareEvents={software_events}
            />
          )}
        </WrapperParts>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 5rem 4rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
  height: 94%;
`
const WrapperDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`
const DivLeft = styled.div`
  flex: 1;
  height: 100%;
`
const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${colors.lightGrey};
  margin: 0 4rem;
`
const DivRight = styled.div`
  flex: 1;
  height: 100%;
`
const WrapperParts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 100%;
`
const StyledEye = styled((props) => <Eye {...props} />)<{ disabled: boolean }>`
  & path {
    fill: ${(props) => (props.disabled ? colors.hawkes : colors.cornflower)};
  }
  width: 2rem;
  height: 2rem;
`
const History = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const StyledHistory = styled(Text)`
  :hover {
    text-decoration: underline;
  }
`
const StyledWarning = styled((props) => <Warning {...props} />)`
  width: 3rem;
  height: 2rem;
`
const WrapperWarning = styled.div`
  width: 100%;
`
const WrapperErrors = styled.div`
  overflow: auto;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const WrapperLedger = styled.div`
  border-radius: 1.25rem;
  width: 100%;
  border: 1px solid ${colors.lavender};
`
const Header = styled.header`
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  width: initial;
  background-color: ${colors.lavender};
  padding: 2rem 0rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`
const CardBody = styled.div`
  padding: 0rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  text-align: center;

  && {
    text-align: center;
    grid-area: 1 / 1 / 2 / 5;
  }
`
