import axios from "axios"
import { Dispatch } from "../store.config"

export interface VatRate {
  id: number
  rate: string
}

export interface VatRatesOptions {
  value: string
  label: string
}

export interface VatRatesState {
  vatRatesStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  vatRates: VatRate[]
  vatRatesOptions: VatRatesOptions[]
}

export interface VatRatesResponse {
  vat_rates: VatRate[]
}

const enum VatRatesActionsEnum {
  GET_VAT_RATES_ATTEMPT = "VAT_RATES/getVatRatesAttempt",
  GET_VAT_RATES_SUCCESS = "VAT_RATES/getVatRatesSuccess",
  GET_VAT_RATES_ERROR = "VAT_RATES/getVatRatesError",
}

export const getVatRatesAttemptAction = () =>
  ({ type: VatRatesActionsEnum.GET_VAT_RATES_ATTEMPT } as const)
export const getVatRatesSuccessAction = (vatRates: VatRate[]) =>
  ({
    type: VatRatesActionsEnum.GET_VAT_RATES_SUCCESS,
    vatRates,
  } as const)
export const getVatRatesErrorAction = () =>
  ({ type: VatRatesActionsEnum.GET_VAT_RATES_ERROR } as const)

type VatRatesActionType = ReturnType<
  | typeof getVatRatesAttemptAction
  | typeof getVatRatesSuccessAction
  | typeof getVatRatesErrorAction
>

export const vatRatesInitialState: VatRatesState = {
  vatRatesStatus: "IDLE",
  vatRates: [],
  vatRatesOptions: [],
}

export function vatRatesReducer(
  state = vatRatesInitialState,
  action: VatRatesActionType
): VatRatesState {
  switch (action.type) {
    case VatRatesActionsEnum.GET_VAT_RATES_ATTEMPT: {
      return { ...state, vatRatesStatus: "LOADING" }
    }

    case VatRatesActionsEnum.GET_VAT_RATES_SUCCESS: {
      const options = action.vatRates.map((e) => {
        return { value: String(e.id), label: e.rate + "%" }
      })
      return {
        ...state,
        vatRatesStatus: "SUCCESS",
        vatRates: [...action.vatRates],
        vatRatesOptions: [...options],
      }
    }

    case VatRatesActionsEnum.GET_VAT_RATES_ERROR: {
      return { ...state, vatRatesStatus: "ERROR" }
    }

    default:
      return { ...state }
  }
}

export const getVatRatesThunk =
  () => (dispatch: Dispatch<VatRatesActionType>) => {
    dispatch(getVatRatesAttemptAction())
    return axios
      .get<VatRatesResponse>("/vat_rates/get_vat_rates")
      .then((res) => {
        dispatch(getVatRatesSuccessAction(res.data.vat_rates))
      })
      .catch(() => {
        dispatch(getVatRatesErrorAction())
      })
  }
