import { ReactComponent as BankCardPaidIcon } from "../../../../assets/treasury-bank-card-checked-paid.svg"
import { ReactComponent as RejectedIcon } from "../../../../assets/treasury-rejected.svg"
import { ReactComponent as ToControlIcon } from "../../../../assets/treasury-to-control.svg"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { isRouteSelected } from "../../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { TabObject, Tabs } from "../../../../components/Commons/Tabs"
import { useIntl } from "react-intl"
import {
  PaymentStatus,
  TreasuryFullDocuments,
  getTreasuryFullDocumentsThunk,
} from "../../../../store/ducks/treasury.ducks"
import { getIdFromParams } from "../../../../utils/company"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../../store/rootReducer"
import { StyledWarning, allTime, lateDate } from "../../../../utils/treasury"

/* eslint-disable camelcase */

export const TreasuryBuy = () => {
  const intl = useIntl()
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/invoicing\\/settings"
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  const [toControlCount, setToControlCount] = useState<number>(0)
  const [toPayCount, setToPayCount] = useState<number>(0)
  const fullDocuments = useRNBSelector((state) => state.treasury.dashboard)
  const dateRange = useRNBSelector((state) => state.treasury.range)
  const filterDocuments = (fds: TreasuryFullDocuments[]) => {
    if (dateRange === allTime) return fds
    return fds.filter((fd) =>
      fd.document_due_date
        ? fd.document_due_date <= dateRange[1].toISOString().slice(0, 10)
        : fd.document_date <= dateRange[1].toISOString().slice(0, 10)
    )
  }

  const needWarning = (
    fullDocs: TreasuryFullDocuments[],
    scope: PaymentStatus
  ): boolean => {
    if (!fullDocs || fullDocs.length === 0) return false
    if (
      scope === "to_control" &&
      fullDocs.some((doc) => doc.validation_request !== null)
    )
      return true
    if (
      (scope === "to_control" || scope === "to_pay") &&
      fullDocs.some(
        (doc) =>
          (doc.document_due_date &&
            new Date(doc.document_due_date) <= lateDate) ||
          (doc.document_date && new Date(doc.document_date) <= lateDate)
      )
    )
      return true
    return false
  }
  const navigate = useNavigate()
  const fiduciary = useRNBSelector((state) => state.fiduciary)

  useEffect(() => {
    if (fiduciary.status === "UNREGISTERED") return
    if (
      fiduciary.status === "SUCCESS" &&
      (fiduciary.active_pricing?.pricing_type !== "per_document" ||
        (fiduciary.active_pricing?.pricing_type === "per_document" &&
          fiduciary.override_pricing))
    )
      return
    navigate("/unauthorized")
  }, [fiduciary])

  useEffect(() => {
    dispatch(getTreasuryFullDocumentsThunk(companyId, "buy"))
  }, [])

  useEffect(() => {
    if (!fullDocuments) return
    if (fullDocuments.to_control)
      setToControlCount(filterDocuments(fullDocuments.to_control).length)
    else setToControlCount(0)
    if (fullDocuments.to_pay)
      setToPayCount(filterDocuments(fullDocuments.to_pay).length)
    else setToPayCount(0)
  }, [toControlCount, toPayCount, fullDocuments, dateRange])

  const linkTabs: TabObject[] = [
    {
      to: "to_control",
      title:
        intl.formatMessage({ id: "menu.treasury.buy.to_control" }) +
        ` (${toControlCount})`,
      icon: needWarning(fullDocuments.to_control, "to_control") ? (
        <StyledWarning />
      ) : (
        <ToControlIcon />
      ),
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "to_control"),
      colored: needWarning(fullDocuments.to_control, "to_control"),
    },
    {
      to: "to_pay",
      title:
        intl.formatMessage({ id: "menu.treasury.buy.to_pay" }) +
        ` (${toPayCount})`,
      icon: needWarning(fullDocuments.to_pay, "to_pay") ? (
        <StyledWarning />
      ) : (
        <ToControlIcon />
      ),
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "to_pay"),
      colored: needWarning(fullDocuments.to_pay, "to_pay"),
    },
    {
      to: "paid",
      title: intl.formatMessage({ id: "menu.treasury.buy.paid" }),
      icon: <BankCardPaidIcon />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "paid"),
    },
    {
      to: "rejected",
      title: intl.formatMessage({ id: "menu.treasury.buy.rejected" }),
      icon: <RejectedIcon />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "rejected"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
        Link={Link}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}
