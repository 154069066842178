import { DropDocumentsFormatsModal } from "./DropDocumentsFormatsModal"
import { DropDocumentsDetailsModal } from "./DropDocumentsDetailsModal"
import { DropDocumentsDuplicateDetailsModal } from "./DropDocumentsDuplicateDetailsModal"
import { DropDocumentsOfficeFormatModal } from "./DropDocumentsOfficeFormatModal"
import { RenameBatchDocumentModal } from "./RenameBatchDocumentModal"
import { renameBatchDocumentThunk } from "../../store/ducks/batchDocuments.ducks"
import { DeactivateDocumentModal } from "../DeactivateDocumentModal"
import { useDispatch } from "react-redux"
import {
  BatchDocumentPreviewModal,
  displayPreviewParams,
} from "./BatchDocumentPreviewModal"
import { resetDuplicateDetailBatchDocumentIdAction } from "../../store/ducks/dropDocuments.ducks"

interface DropsModalsProps {
  selectedCompanyId: number | null
  displayPreview: displayPreviewParams
  displayDetails: string | undefined
  displayDuplicateDetails?: boolean
  deactivatedBatchDocumentIdList: number[]
  displayDeactivateModal?: boolean
  renamedBatchDocumentId: number | null
  displayOfficeAccepted: boolean
  displayAcceptedTooltip: boolean
  acceptedFormats: string[]
  originalFileName?: string | null
  setDisplayAcceptedTooltip: (value: boolean) => void
  setDisplayOfficeAccepted: (value: boolean) => void
  setRenamedBatchDocumentId: (value: number | null) => void
  handleDeactivatedModalClose: () => void
  setDisplayDeactivateModal: (value: boolean) => void
  setDisplayDetails: (value: string | undefined) => void
  setDisplayDuplicateDetails?: (value: boolean) => void
  setDisplayPreview: (value: displayPreviewParams) => void
}

export const Modals = ({
  selectedCompanyId,
  displayPreview,
  displayDuplicateDetails,
  displayDetails,
  deactivatedBatchDocumentIdList,
  displayDeactivateModal = false,
  renamedBatchDocumentId,
  displayOfficeAccepted,
  displayAcceptedTooltip,
  acceptedFormats,
  originalFileName,
  setDisplayAcceptedTooltip,
  setDisplayOfficeAccepted,
  setRenamedBatchDocumentId,
  handleDeactivatedModalClose,
  setDisplayDeactivateModal,
  setDisplayDetails,
  setDisplayDuplicateDetails,
  setDisplayPreview,
}: DropsModalsProps) => {
  const dispatch = useDispatch()

  return (
    <>
      <BatchDocumentPreviewModal
        isDisplayed={displayPreview.isDisplayed}
        onClose={() => {
          setDisplayPreview({
            isDisplayed: false,
            batchDocumentId: null,
            companyId: null,
            elementName: null,
            displayInvoice: false,
          })
        }}
        companyId={displayPreview.companyId}
        batchDocumentId={displayPreview.batchDocumentId}
        elementName={displayPreview.elementName}
        displayInvoice={displayPreview.displayInvoice}
        key={`${displayPreview.isDisplayed}${displayPreview.batchDocumentId}${displayPreview.elementName}`}
      />
      <DropDocumentsFormatsModal
        isDisplayed={displayAcceptedTooltip}
        onClose={() => {
          setDisplayAcceptedTooltip(!displayAcceptedTooltip)
        }}
        acceptedFormats={acceptedFormats}
      />
      <DropDocumentsDetailsModal
        isDisplayed={!!displayDetails}
        onClose={() => {
          setDisplayDetails(undefined)
        }}
        batch={displayDetails}
      />
      {displayDuplicateDetails !== undefined && (
        <DropDocumentsDuplicateDetailsModal
          isDisplayed={displayDuplicateDetails}
          onClose={() => {
            dispatch(resetDuplicateDetailBatchDocumentIdAction())
            setDisplayDuplicateDetails && setDisplayDuplicateDetails(false)
          }}
        />
      )}
      <DropDocumentsOfficeFormatModal
        isDisplayed={displayOfficeAccepted}
        onClose={() => {
          setDisplayOfficeAccepted(false)
        }}
      />
      <RenameBatchDocumentModal
        isDisplayed={renamedBatchDocumentId !== null}
        onClose={() => {
          setRenamedBatchDocumentId(null)
        }}
        onClick={(newName: string) => {
          selectedCompanyId &&
            renamedBatchDocumentId &&
            dispatch(
              renameBatchDocumentThunk({
                batchDocumentId: renamedBatchDocumentId,
                companyId: selectedCompanyId,
                newName,
              })
            )
        }}
        inputLabelId="company-drop-document.rename.label"
        additionalTextId="company-drop-document.rename.only-batch"
      />
      <DeactivateDocumentModal
        isDisplayed={displayDeactivateModal}
        onClose={() => {
          handleDeactivatedModalClose()
        }}
        documentIdList={deactivatedBatchDocumentIdList || []}
        companyId={selectedCompanyId || 0}
        whichDocument={"batchDocument"}
      />
    </>
  )
}
