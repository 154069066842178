import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import { useState } from "react"

import { useRNBSelector } from "../../store/rootReducer"
import {
  DeactivatedDocumentInWritingForInputMask,
  FullDocumentForInputMask,
} from "../../utils/inputMask"
import {
  FullDocumentForInputMaskWithNewMerchant,
  FullDocumentsList,
} from "./FullDocumentsList"
import { TabObject, FullTabs } from "../FullTabs"
import { WritingLines } from "./WritingLines"
import { FullDocumentInfo } from "./FullDocumentInfo"
import { WritingLinesDeactivated } from "./DeactivatedWritingLines"
import { ControlBar } from "./ControlBar"
import { ReactComponent as FolderCheck } from "../../assets/tabs/folder-check.svg"
import { ReactComponent as FolderSearch } from "../../assets/tabs/folder-search.svg"
import { Alert } from "../Commons/Alert"
import { selectTab } from "../../store/ducks/inputMask.ducks"

interface ContentProps {
  selectedFullDocument:
    | FullDocumentForInputMask
    | DeactivatedDocumentInWritingForInputMask
    | null
  isWriting: boolean
  onCreateWritingArchive: () => void
}

export const Content = ({
  selectedFullDocument,
  isWriting,
  onCreateWritingArchive,
}: ContentProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { fullDocuments, deactivatedFullDocuments, selectedTab } =
    useRNBSelector((state) => ({
      fullDocuments: state.inputMask.full_documents_to_check,
      deactivatedFullDocuments: state.inputMask.deactivated_full_documents,
      selectedTab: state.inputMask.selectedTab,
    }))

  const [sortedDocuments, setSortedDocuments] = useState<
    FullDocumentForInputMaskWithNewMerchant[]
  >([])

  const DeactivatedFullDocumentContent = <WritingLinesDeactivated />

  const FullDocumentsContent =
    fullDocuments.length === 0 ? (
      <>
        <AlertWrapper>
          <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
            <Ct.Text
              text={intl.formatMessage({
                id: "writing-lines.full-documents.empty",
              })}
              textStyle={{ lineHeight: 3 }}
            />
          </Alert>
        </AlertWrapper>
        <Ct.JustifyCenter>
          <Ct.Button
            width={30}
            label={intl.formatMessage({
              id: "input-mask.invoices.generate-writing",
            })}
            onClick={() => {
              onCreateWritingArchive()
            }}
          />
        </Ct.JustifyCenter>
      </>
    ) : (
      <>
        <FullDocumentDetailsWrapper>
          <Ct.Spacer />
          <FullDocumentInfo />
          <Ct.Spacer />
          <WritingLines />
          <Ct.Spacer />
          <ControlBar sortedDocuments={sortedDocuments} />
        </FullDocumentDetailsWrapper>
        <FullDocumentsList
          selectedFullDocument={selectedFullDocument}
          sortedDocuments={sortedDocuments}
          setSortedDocuments={setSortedDocuments}
        />

        {isWriting && (
          <>
            <Ct.Spacer />
            <Ct.JustifyCenter>
              <Ct.Button
                width={30}
                label={intl.formatMessage({
                  id: "input-mask.invoices.generate-writing",
                })}
                onClick={() => {
                  onCreateWritingArchive()
                }}
              />
            </Ct.JustifyCenter>
          </>
        )}
      </>
    )

  if (!isWriting) {
    return FullDocumentsContent
  }

  const tabs: TabObject[] = [
    {
      title: `${intl.formatMessage({
        id: "input-mask.content.tabs.processed",
      })}${` (${fullDocuments.length})`}`,
      icon: <FolderCheck />,
      onClick: () => {
        dispatch(selectTab("processed"))
      },
      content: FullDocumentsContent,
    },
    {
      title: `${intl.formatMessage({
        id: "input-mask.content.tabs.deactivated",
      })}${` (${
        deactivatedFullDocuments.filter((d) => !d.is_being_reactivated).length
      })`}`,
      icon: <FolderSearch />,
      onClick: () => {
        dispatch(selectTab("deactivated"))
      },
      content: DeactivatedFullDocumentContent,
    },
  ]

  return (
    <>
      <FullTabs
        tabs={tabs}
        selectedTabIndex={selectedTab === "processed" ? 0 : 1}
      />
    </>
  )
}

const FullDocumentDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`
const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2rem;
  margin-top: 4rem;
`
