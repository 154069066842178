import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import {
  canDeactivateStatus,
  CompanyStub,
  deactivateCompany,
} from "../../../../store/ducks/companies.ducks"

import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useNavigate, useParams } from "react-router-dom"
import { boxShadow, colors, sizes } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getCurrentUserThunk } from "../../../../store/ducks/user.ducks"
import { Alert } from "../../../../components/Commons/Alert"
import { DeleteCompanyModal } from "../../../../components/DeleteCompanyModal"
import styled from "styled-components/macro"

const Deactivate = () => {
  const userTypology = useRNBSelector((state) => state.user.typology)
  const navigate = useNavigate()

  const intl = useIntl()
  const { register, watch, handleSubmit } = useForm({})
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  if (
    userTypology === "collaborator" ||
    userTypology === "customer" ||
    userTypology === "customer_accountant"
  ) {
    navigate("/unauthorized")
  }
  const companies = useRNBSelector((state) => state.companies.companies)
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const selectedCompany = companies[selectedCompanyId] || CompanyStub
  const companyName = selectedCompany?.user_company_name
    ? selectedCompany.user_company_name
    : selectedCompany?.name

  const inputCompanyName = watch("inputCompanyName")
  const [displayModal, setDisplayModal] = useState(false)
  const canDeactivateStatusBool = useRNBSelector(
    (state) => state.companies.canDeactivateCurrentCompanyStatus
  )
  const reasonsList = useRNBSelector(
    (state) => state.companies.deactiveCompanyReasons
  )

  const handleFormSubmit = () => {
    if (inputCompanyName === companyName) {
      dispatch(canDeactivateStatus(false))
      setDisplayModal(true)
    }
  }

  useEffect(() => {
    if (canDeactivateStatusBool) {
      dispatch(canDeactivateStatus(false))
      dispatch(deactivateCompany(selectedCompanyId as number, reasonsList))
    }
  }, [canDeactivateStatusBool])

  useEffect(() => {
    if (selectedCompany.deactivatedCompany === "SUCCESS") {
      navigate(`/office/fiduciary/companies`)
      toast.success(
        intl.formatMessage({ id: "office-company-deactivate.toaster-success" })
      )
    }
  }, [navigate, selectedCompany, intl])

  const placeholder =
    intl.formatMessage({ id: "office-company-deactivate.placeholder.1" }) +
    companyName +
    intl.formatMessage({ id: "office-company-deactivate.placeholder.2" })

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && selectedCompany.id !== 0) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: selectedCompany.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    selectedCompany,
  ])

  return (
    <>
      <DeleteCompanyModal
        isDisplayed={displayModal}
        onClose={() => {
          setDisplayModal(!displayModal)
        }}
        accountingType={selectedCompany.accounting_type || "accrual_accounting"}
      />
      <Wrapper>
        <StyledSection>
          <StyledContent>
            <Ct.StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
              <Alert alertType="info" padding="2rem 3rem 3rem 3rem">
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-deactivate.delete-company",
                  })}
                  textStyle={{ lineHeight: 3, fontWeight: 500 }}
                />
                <Ct.Spacer height={1.5} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-deactivate.delete-permanently",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-deactivate.cannot-recreate",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-deactivate.company-info",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
                <Ct.Spacer height={2} />
                <Ct.Bar width={19.25} align="center" />
                <Ct.Spacer height={2} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "office-company-deactivate.company-name",
                  })}
                />
                <Ct.Spacer height={2} />
                <StyledText text={placeholder} />
                <Ct.Spacer height={2} />
                <Ct.Input
                  name="inputCompanyName"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={""}
                  type="text"
                  value={inputCompanyName}
                  showError={
                    !companyName?.includes(inputCompanyName) &&
                    inputCompanyName !== undefined
                  }
                />
              </Alert>

              <Ct.Spacer height={4} />

              <Ct.Button
                type="submit"
                label={intl.formatMessage({
                  id: "office-company-deactivate.cta",
                })}
                disabled={inputCompanyName !== companyName}
                width={sizes.button.standard}
              />
            </Ct.StyledForm>
          </StyledContent>
        </StyledSection>
      </Wrapper>
    </>
  )
}

export default Deactivate

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 10rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  flex: 1;
`

const StyledContent = styled.div`
  width: 86.75rem;
`

const StyledText = styled(Ct.Text)`
  white-space: pre-wrap;
`
