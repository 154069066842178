import { ReactComponent as SettingsHorizontally } from "../../../assets/settingsAdjustHorizontally.svg"
import { ReactComponent as MailView } from "../../../assets/mail-view.svg"
import { ReactComponent as CalendarEdit } from "../../../assets/calendarEdit.svg"
import { ReactComponent as Info } from "../../../assets/info.svg"
import { ReactComponent as RemoveLine } from "../../../assets/removeLine.svg"
import { ReactComponent as Monitor } from "../../../assets/monitor.svg"
import { ReactComponent as DesignPencil } from "../../../assets/design-pencil.svg"
import { useRNBSelector } from "../../../store/rootReducer"
import { useLocation, useParams } from "react-router-dom"
import { isRouteSelected } from "../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { ScrollableOutlet } from "../../../components/Commons/ScrollableOutlet"
import { TabObject, Tabs } from "../../../components/Commons/Tabs"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../utils/company"
import { useEffect } from "react"
import { GetWritingLabelDataThunk } from "../../../store/ducks/writingLabels.ducks"

const CompanySettingsMenu = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const userTypology = useRNBSelector((state) => state.user.typology)
  const fields = useRNBSelector(
    (state) => state.writingLabels.writing_labels_data.fields
  )
  const routePattern = "\\/company\\/\\d+\\/settings"
  const companyId = getIdFromParams(useParams())("company_id") || 0

  useEffect(() => {
    if (companyId && companyId !== 0) {
      dispatch(GetWritingLabelDataThunk(companyId))
    }
  }, [companyId, dispatch])

  const linkTabs: TabObject[] = [
    {
      to: "options",
      title: "Paramètres",
      isDisplayed: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
      ].includes(userTypology),
      icon: <SettingsHorizontally />,
      isSelected: isRouteSelected(location, routePattern, "options"),
    },
    {
      to: "writing_labels",
      title: "Libellés",
      isDisplayed:
        [
          "administrator",
          "manager",
          "collaborator",
          "customer_accountant",
        ].includes(userTypology) && Object.values(fields).length > 0,
      icon: <DesignPencil />,
      isSelected: isRouteSelected(location, routePattern, "writing_labels"),
    },
    {
      to: "change_software",
      title: "Logiciel",
      isDisplayed: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
      ].includes(userTypology),
      icon: <Monitor />,
      isSelected: isRouteSelected(location, routePattern, "change_software"),
    },
    {
      to: "informations",
      title: "Informations",
      icon: <Info />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "informations"),
    },
    {
      to: "capture",
      title: "Accès Capture Mail",
      icon: <MailView />,
      isDisplayed: true,
      isSelected: isRouteSelected(location, routePattern, "capture"),
    },
    {
      to: "fiscalyears",
      title: "Années fiscales",
      icon: <CalendarEdit />,
      isDisplayed: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
      ].includes(userTypology),
      isSelected: isRouteSelected(location, routePattern, "fiscalyears"),
    },
    {
      to: "deactivate",
      title: "Désactiver",
      icon: <RemoveLine />,
      isDisplayed: ["administrator", "manager"].includes(userTypology),
      isSelected: isRouteSelected(location, routePattern, "deactivate"),
    },
  ]

  return (
    <>
      <Tabs
        tabs={linkTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}

export default CompanySettingsMenu
