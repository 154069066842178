import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { boxShadow, colors, sizes } from "../../../../styles/design.config"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import {
  GetCompanyGoodsTransferAccountsThunk,
  SelectedGoodsTransferAccounts,
  GoodsTransferType,
  Account,
  UpdateGoodsTransferAccountsThunk,
  AccountGoodsTransfer,
  CreateGoodsTransferAccountReset,
  CommissionAccountVatRate,
  DestroyCommissionAccountVatRateThunk,
  UpdateGoodsTransferAccountsReset,
} from "../../../../store/ducks/companySettings.ducks"
import { useIntl } from "react-intl"
import { OptionList, Select } from "../../../../components/Commons/Select"
import { GoodsTransferHistoryModal } from "../../../../components/accountingPlan/GoodsTransferHistoryModal"
import { ReactComponent as Plus } from "../../../../assets/plus.svg"
import { useRNBSelector } from "../../../../store/rootReducer"
import { ReactComponent as Eye } from "../../../../assets/eyeFilled.svg"
import { ReactComponent as Trash } from "../../../../assets/drop-documents/TrashCan.svg"
import { CreateGoodsTransferAccountModal } from "../../../../components/accountingPlan/CreateGoodsTransferAccountModal"
import { usePrompt } from "../../../../utils/usePrompt.hook"
import { Alert } from "../../../../components/Commons/Alert"
import { CompanyStub } from "../../../../store/ducks/companies.ducks"
/* eslint-disable camelcase */

export const GoodsTransfer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || CompanyStub
  )

  if (company.scratch_games_paid_lots_goods_transfer_account_id === null) {
    navigate("/unauthorized")
  }

  const {
    commission_account_vat_rates,
    enum_translations,
    goods_transfer_commission_type_enum,
    goods_transfer_type_enum,
    possible_goods_transfer_accounts,
    possible_goods_transfer_commission_accounts,
    selected_accounts,
  } = useRNBSelector((state) => state.companySettings.goods_transfer)
  const createdAccount = useRNBSelector(
    (state) => state.companySettings.createdGoodsTransferAccount
  )

  const updatedGoodsTransferStatus = useRNBSelector(
    (state) => state.companySettings.updatedGoodsTransferStatus
  )

  const allTypes = goods_transfer_type_enum
    .concat(goods_transfer_commission_type_enum)
    .sort((a, b) => a.localeCompare(b))

  const possibleAccounts: OptionList<string> =
    possible_goods_transfer_accounts.map((a) => {
      return { value: String(a.id), label: a.number + " " + a.details }
    })
  const possibleCommissionAccounts: OptionList<string> =
    possible_goods_transfer_commission_accounts.map((a) => {
      return { value: String(a.id), label: a.number + " " + a.details }
    })

  const emptyOption = {
    value: "",
    label: "",
  }

  const [isAccountsChanged, setIsAccountsChanged] = useState<boolean>(false)
  const [displayHistoryModal, setDisplayHistoryModal] =
    useState<AccountGoodsTransfer | null>(null)
  const [createAccount, setCreateAccount] = useState<GoodsTransferType | null>(
    null
  )
  const [destroyCommissionAccount, setDestroyCommissionAccount] = useState<{
    commission: CommissionAccountVatRate
    type: GoodsTransferType
  } | null>(null)

  const [createAccountWithVat, setCreateAccountWithVat] = useState<{
    type: GoodsTransferType
    existingVatRates: number[]
  } | null>(null)

  const [accounts, setAccounts] = useState<SelectedGoodsTransferAccounts>({
    draw_games_goods_transfer: null,
    draw_games_goods_transfer_commission: null,
    draw_games_paid_lots_goods_transfer: null,
    metro_goods_transfer: null,
    metro_goods_transfer_commission: null,
    monetics_goods_transfer: null,
    monetics_goods_transfer_commission: null,
    other_goods_transfer: null,
    other_goods_transfer_commission: null,
    postage_stamps_goods_transfer: null,
    postage_stamps_goods_transfer_commission: null,
    press_goods_transfer: null,
    press_goods_transfer_commission: null,
    scratch_games_goods_transfer: null,
    scratch_games_goods_transfer_commission: null,
    scratch_games_paid_lots_goods_transfer: null,
    tax_stamps_goods_transfer: null,
    tax_stamps_goods_transfer_commission: null,
    tobacco_goods_transfer: null,
    tobacco_goods_transfer_commission: null,
  })
  const [initialAccounts, setInitialAccounts] =
    useState<SelectedGoodsTransferAccounts>({
      draw_games_goods_transfer: null,
      draw_games_goods_transfer_commission: null,
      draw_games_paid_lots_goods_transfer: null,
      metro_goods_transfer: null,
      metro_goods_transfer_commission: null,
      monetics_goods_transfer: null,
      monetics_goods_transfer_commission: null,
      other_goods_transfer: null,
      other_goods_transfer_commission: null,
      postage_stamps_goods_transfer: null,
      postage_stamps_goods_transfer_commission: null,
      press_goods_transfer: null,
      press_goods_transfer_commission: null,
      scratch_games_goods_transfer: null,
      scratch_games_goods_transfer_commission: null,
      scratch_games_paid_lots_goods_transfer: null,
      tax_stamps_goods_transfer: null,
      tax_stamps_goods_transfer_commission: null,
      tobacco_goods_transfer: null,
      tobacco_goods_transfer_commission: null,
    })

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(GetCompanyGoodsTransferAccountsThunk(selectedCompanyId))
    }
  }, [selectedCompanyId])

  useEffect(() => {
    if (selected_accounts) {
      setAccounts(selected_accounts)
      setInitialAccounts(selected_accounts)
    }
  }, [selected_accounts])

  const submit = () => {
    const differentObjects: { [index: string]: Account | null } = {}

    for (const key in initialAccounts) {
      const type = key as GoodsTransferType

      if (accounts[type] && initialAccounts[type]?.id !== accounts[type]?.id) {
        differentObjects[type] = accounts[type]
      }
    }

    if (differentObjects && selectedCompanyId) {
      dispatch(
        UpdateGoodsTransferAccountsThunk(selectedCompanyId, differentObjects)
      )
    }
  }

  usePrompt(
    intl.formatMessage({ id: "form.unsaved.message" }),
    isAccountsChanged
  )

  useEffect(() => {
    if (createdAccount !== null) {
      setIsAccountsChanged(true)
      setAccounts({
        ...accounts,
        [createdAccount.type]: createdAccount,
      })
      if (accounts[createdAccount.type]?.id === createdAccount.id) {
        dispatch(CreateGoodsTransferAccountReset())
      }
    }
  }, [
    createdAccount,
    possible_goods_transfer_accounts,
    possible_goods_transfer_commission_accounts,
  ])

  useEffect(() => {
    if (updatedGoodsTransferStatus === "success") {
      setIsAccountsChanged(false)
      dispatch(UpdateGoodsTransferAccountsReset())
    }
  }, [updatedGoodsTransferStatus])

  return (
    <Wrapper>
      <StyledSection>
        <Scroll>
          <Container>
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.goods-transfer.title",
              })}
              textStyle={{ lineHeight: 3 }}
            />
            <Ct.Spacer height={3} />

            <BorderedContainer>
              <>
                {enum_translations &&
                  selected_accounts &&
                  allTypes.map((type, index) => (
                    <Fragment key={type}>
                      <Ct.Spacer />
                      <Ct.Row>
                        <Select
                          intl={intl}
                          disabled={false}
                          value={
                            (accounts[type] !== null && {
                              value: String(accounts[type]?.id),
                              label:
                                accounts[type]?.number +
                                " " +
                                accounts[type]?.details,
                            }) ||
                            emptyOption
                          }
                          options={
                            type.includes("commission")
                              ? possibleCommissionAccounts
                              : possibleAccounts
                          }
                          domain={""}
                          optionType={enum_translations[type]}
                          onChangeCallback={(selectedAccount) => {
                            const possibleAccounts = type.includes("commission")
                              ? possible_goods_transfer_commission_accounts
                              : possible_goods_transfer_accounts

                            const account =
                              possibleAccounts.find(
                                (a) => a.id === Number(selectedAccount.value)
                              ) || null
                            setIsAccountsChanged(
                              initialAccounts[type]?.id !== account?.id
                            )
                            setAccounts({
                              ...accounts,
                              [type]: account,
                            })
                          }}
                          error={accounts[type] === null}
                        />

                        <Ct.Spacer />
                        <Ct.Button
                          label={intl.formatMessage({
                            id: "office-company-settings.vat-accounts.create",
                          })}
                          onClick={() => {
                            setCreateAccount(type)
                          }}
                          width={22}
                          prefix={<Plus />}
                        />
                        <Ct.Spacer />
                        <EyeWrapper
                          onClick={() => {
                            const account = selected_accounts[type]
                            if (account) {
                              setDisplayHistoryModal(account)
                            }
                          }}
                        >
                          <StyledEye
                            disabled={selected_accounts[type] === null}
                          />
                        </EyeWrapper>
                      </Ct.Row>

                      <WrapperCommissions>
                        {type.includes("commission") && (
                          <>
                            <div>
                              {commission_account_vat_rates[type] &&
                                commission_account_vat_rates[type]
                                  .sort((a, b) => a.rate - b.rate)
                                  .map((com) => (
                                    <Fragment key={com.id}>
                                      <Commission>
                                        <TrashWrapper
                                          onClick={() => {
                                            setDestroyCommissionAccount({
                                              commission: com,
                                              type,
                                            })
                                          }}
                                        >
                                          <StyledTrash />
                                        </TrashWrapper>

                                        <Ct.Spacer width={2} />

                                        <Ct.Text
                                          text={com.rate + "%"}
                                          textStyle={{
                                            fontSize: 1.5,
                                            color: "navy",
                                          }}
                                        />

                                        <Ct.Spacer width={2} />

                                        <Ct.Text
                                          text={
                                            com.number + " " + com.details + " "
                                          }
                                          textStyle={{
                                            fontSize: 1.5,
                                            color: "navy",
                                          }}
                                        />
                                      </Commission>
                                    </Fragment>
                                  ))}
                            </div>

                            <StyledButtonVat>
                              <Ct.Button
                                label={intl.formatMessage({
                                  id: "accounting-plan.goods-transfer.create-by-vat",
                                })}
                                onClick={() => {
                                  setCreateAccountWithVat({
                                    type: type,
                                    existingVatRates:
                                      commission_account_vat_rates[type].map(
                                        (r) => r.rate
                                      ),
                                  })
                                }}
                                width={18}
                                height={5}
                                prefix={<Plus />}
                              />
                            </StyledButtonVat>
                          </>
                        )}
                      </WrapperCommissions>

                      {index !== allTypes.length - 1 && (
                        <>
                          <Ct.Spacer width={0} height={2} />
                          <Ct.Separator size="full" />
                        </>
                      )}
                    </Fragment>
                  ))}
              </>
            </BorderedContainer>
          </Container>
        </Scroll>

        <Ct.Spacer height={4} />

        <CenteredButton>
          <Ct.Button
            label={intl.formatMessage({ id: "office-company-settings.cta" })}
            onClick={() => submit()}
            width={sizes.button.standard}
            disabled={
              !isAccountsChanged ||
              Object.values(accounts).some((a) => a === null)
            }
          />
        </CenteredButton>

        {displayHistoryModal !== null && (
          <GoodsTransferHistoryModal
            isDisplayed={!!displayHistoryModal}
            onClose={() => {
              setDisplayHistoryModal(null)
            }}
            account={displayHistoryModal}
          />
        )}

        {createAccount && (
          <CreateGoodsTransferAccountModal
            isDisplayed={!!createAccount}
            onClose={() => {
              setCreateAccount(null)
            }}
            type={createAccount}
          />
        )}

        {createAccountWithVat && (
          <CreateGoodsTransferAccountModal
            isDisplayed={!!createAccountWithVat}
            onClose={() => {
              setCreateAccountWithVat(null)
            }}
            type={createAccountWithVat.type}
            createWithVat={true}
            existingVatRates={createAccountWithVat.existingVatRates}
          />
        )}

        {destroyCommissionAccount && selectedCompanyId && (
          <DestroyCommssionAccountModal
            isDisplayed={!!destroyCommissionAccount}
            onClose={() => {
              setDestroyCommissionAccount(null)
            }}
            type={destroyCommissionAccount.type}
            commissionAccount={destroyCommissionAccount.commission}
            companyId={selectedCompanyId}
          />
        )}
      </StyledSection>
    </Wrapper>
  )
}

interface DestroyCommissionAccountProps extends Ct.ModalComponentsProps {
  type: GoodsTransferType
  commissionAccount: CommissionAccountVatRate
  companyId: number
}

export const DestroyCommssionAccountModal = ({
  isDisplayed,
  onClose,
  type,
  commissionAccount,
  companyId,
}: DestroyCommissionAccountProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const enum_translations = useRNBSelector(
    (state) => state.companySettings.goods_transfer.enum_translations
  )

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="45%"
      right="45%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"80rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer />
        <Alert alertType={"info"}>
          {enum_translations && (
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "accounting-plan.goods-transfer.modal-destroy-commission.message1",
                },
                {
                  account:
                    commissionAccount.number + " " + commissionAccount.details,
                  rate: commissionAccount.rate,
                  type: enum_translations[type],
                }
              )}
              textStyle={{
                lineHeight: 3,
              }}
            />
          )}
          <Ct.Spacer />
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.goods-transfer.modal-destroy-commission.message2",
            })}
          />
        </Alert>
        <Ct.Spacer height={4} />
        <CenteredButton>
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.goods-transfer.modal-destroy-commission.cancel",
            })}
            onClick={() => onClose()}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
          />
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.goods-transfer.modal-destroy-commission.destroy",
            })}
            onClick={() => {
              dispatch(
                DestroyCommissionAccountVatRateThunk(
                  companyId,
                  type,
                  commissionAccount.id
                )
              )
              onClose()
            }}
          />
        </CenteredButton>
      </Ct.Card>
    </Ct.Modal>
  )
}

/* stylelint-disable no-descending-specificity */

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Scroll = styled.div`
  overflow-y: auto;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 25px, 100% 25px;
  background-attachment: local, local, scroll, scroll;
`
const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  padding: 0 6rem;
`
const BorderedContainer = styled.div`
  border: 1px solid ${colors.cornflower};
  border-radius: 2.5rem;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
`
const EyeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)<{
  disabled: boolean
}>`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${(props) => (props.disabled ? colors.moon : colors.cornflower)};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${(props) => (props.disabled ? colors.moon : colors.lavender)};
    }
  }
  cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
`
const WrapperCommissions = styled.div`
  display: flex;
  justify-content: space-between;
`
const Commission = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: center;
`
const TrashWrapper = styled.div`
  display: flex;
`
const StyledTrash = styled(({ ...props }) => <Trash {...props} />)`
  width: 2rem;
  height: 2rem;
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }

  cursor: pointer;
`
const StyledButtonVat = styled.div`
  margin-right: 5rem;
  padding-top: 2rem;
`
