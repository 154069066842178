export interface FormErrors {
  email?: {
    types?: {
      [s: string]: "email"
    }
  }
  password?: {
    types?: {
      [s: string]: passwordErrors
    }
  }
}

type passwordErrors = "format" | "length"
type returnedErrors = passwordErrors | "mismatch" | "email"

export const getErrorCodes = (
  errors: FormErrors,
  password: string,
  confirmPassword: string
): returnedErrors[] => {
  let errorMessages: returnedErrors[] = []

  if (password !== confirmPassword) {
    errorMessages = ["mismatch"]
  }
  if (errors?.email?.types) {
    errorMessages = ["email"]
  }
  if (errors?.password?.types) {
    errorMessages = [...errorMessages, ...Object.values(errors.password.types)]
  }

  return errorMessages
}
