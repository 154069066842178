import { AxiosError } from "axios"

export const wait = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms))

export const indexedObjectFromArray = <T extends { id: number }>(
  array: Array<T>
) => {
  return array.reduce((acc, e) => ({ ...acc, [e.id]: e }), {})
}

export interface Indexed<T> {
  [index: number]: T
}

export function isAxiosError(error: AxiosError | Error): error is AxiosError {
  return (error as AxiosError).response !== undefined
}

export type updateStatus = "IDLE" | "ATTEMPT" | "SUCCESS" | "ERROR"
