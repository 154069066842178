import { Dispatch } from "../store.config"
import axios from "axios"

const enum SoftwareRulesActionsEnum {
  GET_SOFTWARE_RULES_ATTEMPT = "SOFTWARE_RULES/getSoftwareRulesAttempt",
  GET_SOFTWARE_RULES_SUCCESS = "SOFTWARE_RULES/getSoftwareRulesSuccess",
  GET_SOFTWARE_RULES_FAILURE = "SOFTWARE_RULES/getSoftwareRulesFailure",
}

export const getSoftwareRulesAttemptAction = () =>
  ({ type: SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_ATTEMPT } as const)

export const getSoftwareRulesSuccessAction = (payload: {
  softwareRules: SoftwareRules
  pennylaneTokenCreatedAt: string
}) =>
  ({
    type: SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_SUCCESS,
    payload,
  } as const)

export const getSoftwareRulesFailureAction = (error: Error) =>
  ({
    type: SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_FAILURE,
    error,
  } as const)

type SoftwareRulesActionsType = ReturnType<
  | typeof getSoftwareRulesAttemptAction
  | typeof getSoftwareRulesSuccessAction
  | typeof getSoftwareRulesFailureAction
>

type SoftwareRulesLoadingState = "INITIAL" | "LOADING" | "SUCCESS" | "FAILURE"

export interface SoftwareRules {
  merchant?: {
    name: number
  }
  auxiliary_number?: number
  account?: {
    number: number
  }
  journal_code?: number
  reference?: number
}

export const softwareRulesInitialState: SoftwareRulesState = {
  rules: null,
  pennylaneTokenCreatedAt: null,
  loadingState: "INITIAL",
}

export interface SoftwareRulesState {
  rules: SoftwareRules | null
  loadingState: SoftwareRulesLoadingState
  pennylaneTokenCreatedAt: string | null
}

export function softwareRulesReducer(
  state = softwareRulesInitialState,
  action: SoftwareRulesActionsType
): SoftwareRulesState {
  switch (action.type) {
    case SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_ATTEMPT:
      return { ...state, loadingState: "LOADING" }
    case SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_SUCCESS:
      return {
        ...state,
        loadingState: "SUCCESS",
        rules: action.payload.softwareRules,
        pennylaneTokenCreatedAt: action.payload.pennylaneTokenCreatedAt,
      }
    case SoftwareRulesActionsEnum.GET_SOFTWARE_RULES_FAILURE:
      return { ...state, loadingState: "FAILURE" }
    default:
      return { ...state }
  }
}

export const loadSoftwareRulesThunk =
  (companyId: number) => (dispatch: Dispatch<SoftwareRulesActionsType>) => {
    return axios
      .get<{
        software_rules: SoftwareRules
        pennylane_token_created_at: string
      }>(`/companies/accounting_software_for_company`, {
        params: { id: companyId },
      })
      .then(
        ({
          data: {
            software_rules: softwareRules,
            pennylane_token_created_at: pennylaneTokenCreatedAt,
          },
        }) =>
          dispatch(
            getSoftwareRulesSuccessAction({
              softwareRules,
              pennylaneTokenCreatedAt,
            })
          )
      )
      .catch((error) => dispatch(getSoftwareRulesFailureAction(error)))
  }
