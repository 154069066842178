import { Company } from "../store/ducks/companies.ducks"
import {
  MerchantCode,
  MerchantsOfCode,
} from "../store/ducks/merchantCodes.ducks"
import { capitalizeFirstLetter } from "./string"

export const sortMerchantCodes = (merchantCodes: MerchantCode[]) => {
  const centralized = merchantCodes.filter((c) => c.centralize === true)
  const notCentralized = merchantCodes.filter(
    (c) => c.centralize === false && c.merchants && c.merchants.length > 0
  )
  const noMerchants = merchantCodes.filter(
    (c) => c.centralize === false && c.merchants && c.merchants.length === 0
  )

  return [
    ...centralized.sort(sortByCode),
    ...notCentralized.sort(sortByCode),
    ...noMerchants.sort(sortByCode),
  ]
}

const sortByCode = (a: MerchantCode, b: MerchantCode) => {
  return a.code.localeCompare(b.code)
}

export const filterMerchantCodes =
  (search: string) => (merchantCode: MerchantCode) => {
    return search.length === 0
      ? merchantCode
      : merchantCode.code.toLowerCase().includes(search.toLowerCase()) ||
          merchantCode?.merchants?.find((m) =>
            m.merchant_name.toLowerCase().includes(search.toLowerCase())
          )
  }

export const addPrefixToCode = (company: Company) => {
  let accountingPrefix = { buy: "", sell: "" }
  if (company.accounting_software === "acd") {
    accountingPrefix.buy = "F"
    accountingPrefix.sell = "C"
  } else if (
    !company.auxiliary ||
    company.accounting_software === "isacompta"
  ) {
    accountingPrefix.buy = "401"
    accountingPrefix.sell = "411"
  }

  accountingPrefix.buy += company.buy_auxiliary_prefix
  accountingPrefix.sell += company.sell_auxiliary_prefix

  return accountingPrefix
}

export const normalizeMerchantCode = (string: string) => {
  return string
    .replace(/@/g, "A")
    .toUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "")
    .replace(/\W|/g, "")
    .replace(/[-']/g, "")
    .replace(/\s\s+/g, " ")
}

export const sortMerchantCodesByCode = (
  merchantCodes: MerchantCode[],
  asc: boolean
) => {
  return merchantCodes.sort((a, b) =>
    asc ? a.code.localeCompare(b.code) : b.code.localeCompare(a.code)
  )
}

export const sortMerchantCodesByTiers = (
  merchantCodes: MerchantCode[],
  asc: boolean
) => {
  let sortedCodes: MerchantCode[] = []

  const codesWithTiers = merchantCodes.filter(
    (code) => code.merchants.length > 0
  )

  const codesWithoutTiers = merchantCodes.filter(
    (code) => code.merchants.length === 0
  )

  const sortedCodesWithTiers = codesWithTiers.sort((a, b) =>
    sortMerchantCodeMerchants(a.merchants)
      .join(", ")
      .localeCompare(sortMerchantCodeMerchants(b.merchants).join(", "))
  )

  sortedCodes.push(...sortedCodesWithTiers)
  sortedCodes.push(...codesWithoutTiers)

  return asc ? sortedCodes : sortedCodes.reverse()
}

export const sortMerchantCodeMerchants = (merchants: MerchantsOfCode[]) => {
  const sortedMerchants = merchants.sort((a, b) =>
    a.merchant_name.localeCompare(b.merchant_name)
  )
  return sortedMerchants.map((m) => capitalizeFirstLetter(m.merchant_name))
}
