export const enum ActionsEnum {
  SET_ACTIONS = "action/setActionId",
}

export const setActionId = (actionId: number) =>
  ({
    type: ActionsEnum.SET_ACTIONS,
    actionId: actionId,
  } as const)

type ActionsType = ReturnType<typeof setActionId>

export interface ActionsState {
  actionId: number | undefined
}
export const actionsInitialState: ActionsState = {
  actionId: 0,
}

export function actionsReducer(
  state = actionsInitialState,
  action: ActionsType
): ActionsState {
  switch (action.type) {
    case ActionsEnum.SET_ACTIONS:
      return { ...state, actionId: action.actionId }

    default:
      return { ...state }
  }
}
