import {
  DeactivatedSettings,
  DeactivatedSettingsKey,
} from "../store/ducks/companies.ducks"

export const differenceBetweenObjects = (
  object1: DeactivatedSettings,
  object2: DeactivatedSettings
) => {
  return Object.keys(object2).reduce<{}>((diff, key) => {
    if (
      object1[key as DeactivatedSettingsKey].value ===
      object2[key as DeactivatedSettingsKey].value
    ) {
      return diff
    }
    return {
      ...diff,
      [key]: { value: !object2[key as DeactivatedSettingsKey].value },
    }
  }, {})
}

export const copyObject = <TObjectToCopyType>(
  objectToCopy: TObjectToCopyType
): TObjectToCopyType => {
  // create a new copy of array, sadly structuredClone not available on every browser
  return JSON.parse(JSON.stringify(objectToCopy))
}
