import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import styled, { css } from "styled-components/macro"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { boxShadow, colors } from "../../../../styles/design.config"
import { ReactComponent as PrinterSVG } from "../../../../assets/printer.svg"
import { ReactComponent as Popup } from "../../../../assets/pop-up-bulb.svg"
import { ReactComponent as Bulb } from "../../../../assets/bulb-big.svg"
import { ReactComponent as CloseCross } from "../../../../assets/close-cross-yellow.svg"
import {
  addCaptureMailResetAction,
  attemptToDeleteCaptureMailEntitiesThunk,
  attemptToRegisterCaptureMailEntitiesThunk,
  CaptureMailRegisterPayload,
  deleteCaptureMailEntityResetAction,
  getCaptureMailEntitiesThunk,
} from "../../../../store/ducks/capture.ducks"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../../store/rootReducer"
import {
  CaptureMailUser,
  CaptureMailUserRow,
} from "../../../../components/CaptureMailUserRow"
import { getIdFromParams } from "../../../../utils/company"
import { getUsersWhoAccessToCompanyThunk } from "../../../../store/ducks/user.ducks"
import { Alert } from "../../../../components/Commons/Alert"

export const CaptureMail = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayPopUp, setDisplayPopUp] = useState(true)

  const companies = Object.values(
    useRNBSelector((state) => state.companies.companies)
  )
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const selectedCompany = companies.find(
    (company) => company.id === selectedCompanyId
  )
  const currentUserTypology = useRNBSelector((state) => state.user.typology)

  useEffect(() => {
    if (!selectedCompanyId) {
      return
    }

    dispatch(getCaptureMailEntitiesThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      selectedCompany
        ? `${selectedCompany.email_capture}@capture.chaintrust.io`
        : ""
    )
    toast.success(intl.formatMessage({ id: "capture-mail.toaster.success" }))
  }

  const MessagePopUp = () => {
    return (
      <Ct.Card width={"63rem"} height={"31rem"} backgroundColor={"lightSun"}>
        <HeaderWrapper>
          <HeaderWrapperText>
            <Bulb />
            <Ct.Spacer width={1} />
            <Ct.Text
              text={intl.formatMessage({ id: "capture-mail.rules-title" })}
              textStyle={{
                fontFamily: "Roboto",
                color: "navy",
                fontWeight: 600,
                fontSize: 2,
              }}
            />
          </HeaderWrapperText>
          <ModalCloseCross
            onClick={() => {
              setDisplayPopUp(false)
            }}
          >
            <CloseCross />
          </ModalCloseCross>
        </HeaderWrapper>
        <Ct.Spacer height={1} />
        <RulesWrapper>
          <StyledText
            text={intl.formatMessage({ id: "capture-mail.rules-body" })}
            textStyle={{
              fontFamily: "Roboto",
              color: "navy",
              fontWeight: 400,
              fontSize: 1.75,
              lineHeight: 2.5,
            }}
          />
        </RulesWrapper>
      </Ct.Card>
    )
  }

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  return (
    <>
      <Wrapper>
        <StyledSection>
          <StyledContent>
            <Backbone>
              <AddUsersCaptureMail
                isDisplayed={displayModal}
                onClose={() => {
                  setDisplayModal(false)
                }}
              />
              <CopyEmailArea>
                <StyledEmailArea
                  text={
                    selectedCompany
                      ? `${selectedCompany.email_capture}@capture.chaintrust.io`
                      : ""
                  }
                  textStyle={{
                    fontFamily: "Roboto",
                  }}
                />
                <Ct.Spacer width={1} />
                <Ct.Button
                  label={intl.formatMessage({
                    id: "capture-mail.email-address-capture",
                  })}
                  width={26.375}
                  height={5}
                  textTransform={"none"}
                  onClick={copyToClipboard}
                />
              </CopyEmailArea>
              <Ct.Spacer height={4} />
              <AddArea>
                {currentUserTypology !== "customer" && (
                  <Ct.Button
                    prefix={<PrinterSVG />}
                    height={5}
                    width={13.5}
                    textTransform={"capitalize"}
                    label={intl.formatMessage({
                      id: "capture-mail.add",
                    })}
                    onClick={() => {
                      setDisplayModal(!displayModal)
                    }}
                  />
                )}
              </AddArea>
              <Ct.Spacer height={2} />
              <Scroller>
                <StickyHead>
                  <Titles />
                </StickyHead>
                <CaptureMailContent />
              </Scroller>
            </Backbone>
          </StyledContent>
        </StyledSection>
      </Wrapper>
      <PopUpWrapper>
        {displayPopUp ? (
          <MessagePopUp />
        ) : (
          <ButtonPopUp
            onClick={() => {
              setDisplayPopUp(true)
            }}
          />
        )}
      </PopUpWrapper>
    </>
  )
}

const AddUsersCaptureMail = (props: Ct.ModalComponentsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const schema = yup.object({
    email: yup.string().email().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  })

  const email = watch("email")

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const addCaptureMailStatus = useRNBSelector(
    (state) => state.capture.captureMailStatus
  )

  useEffect(() => {
    if (
      addCaptureMailStatus === "SUCCESS" ||
      addCaptureMailStatus === "REACTIVATED"
    ) {
      dispatch(getCaptureMailEntitiesThunk(selectedCompanyId))
      dispatch(addCaptureMailResetAction())
      reset({ email: "" })
      props.onClose()
    }
  }, [dispatch, addCaptureMailStatus, selectedCompanyId, props, reset])

  useEffect(() => {
    if (addCaptureMailStatus === "EMAIL_USED") {
      dispatch(addCaptureMailResetAction())
    }
    // eslint-disable-next-line
  }, [email])

  interface UnknownErrorMessageProps {
    message: string
  }

  const UnknownErrorMessage = ({ message }: UnknownErrorMessageProps) => (
    <StyledMessage
      text={intl.formatMessage({
        id: `${message}`,
      })}
      textStyle={{ color: "amaranth", fontSize: 1.5 }}
    />
  )

  const addSendingEmail = ({ email }: CaptureMailRegisterPayload) => {
    dispatch(
      attemptToRegisterCaptureMailEntitiesThunk({
        email: email,
        companyId: selectedCompanyId || undefined,
      })
    )
  }

  return (
    <Ct.Modal {...props} left="50%" right="50%" top="calc(50vh - 25rem)">
      <Ct.Card width={"95.5rem"}>
        <Alert
          alertType={"info"}
          margin={"0 0 0 2rem"}
          boxSizing={"border-box"}
          stretch={true}
        >
          <Ct.Text
            text={intl.formatMessage({ id: "capture-mail.add-email-printer" })}
            textStyle={{
              lineHeight: 3,
              color: "navy",
              fontFamily: "Roboto",
              fontWeight: 500,
            }}
          />
        </Alert>
        <Ct.Spacer height={4} />
        <Ct.StyledForm onSubmit={handleSubmit(addSendingEmail)}>
          <SendEmailArea>
            <ErrorMessageArea>
              <Ct.Input
                required={false}
                register={register as unknown as UseFormRegister<FieldValues>}
                name="email"
                dataCy="capture-email"
                type="email"
                value={email}
                label={intl.formatMessage({
                  id: "capture-mail.add-email-printer.email",
                })}
                showError={
                  errors?.email?.type === "required" ||
                  addCaptureMailStatus === "EMAIL_USED"
                }
              />
              {errors?.email?.type === "required" && (
                <UnknownErrorMessage message="invitation.email.missing" />
              )}
              {addCaptureMailStatus === "EMAIL_USED" && (
                <UnknownErrorMessage message="capture-mail.add-email-printer.error.email-already-exist" />
              )}
            </ErrorMessageArea>
            <Ct.Spacer width={3} />
            <Ct.Button
              label={intl.formatMessage({
                id: "capture-mail.add-email-printer.submit",
              })}
              type="submit"
              width={22.5}
              height={6}
            />
          </SendEmailArea>
        </Ct.StyledForm>
      </Ct.Card>
    </Ct.Modal>
  )
}

export const CaptureMailContent = () => {
  const dispatch = useDispatch()

  const captureMailEntities = useRNBSelector(
    (state) => state.capture.captureMailEntities
  )

  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  useEffect(() => {
    dispatch(getUsersWhoAccessToCompanyThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  const deleteCaptureMailStatus = useRNBSelector(
    (state) => state.capture.deleteCaptureMailEntitiesStatus
  )

  useEffect(() => {
    if (deleteCaptureMailStatus === "SUCCESS") {
      dispatch(getCaptureMailEntitiesThunk(selectedCompanyId))
    }
  }, [dispatch, deleteCaptureMailStatus, selectedCompanyId])

  const deleteUser = (
    selectedCompanyId: number | null,
    user: CaptureMailUser
  ) => {
    dispatch(deleteCaptureMailEntityResetAction())
    const realIDOfEntity = Object.entries(captureMailEntities).find(
      (element) => element[1].email === user.email
    )
    if (realIDOfEntity !== undefined) {
      const id = Number(realIDOfEntity[1].id)
      dispatch(
        attemptToDeleteCaptureMailEntitiesThunk({ id, selectedCompanyId })
      )
      dispatch(getCaptureMailEntitiesThunk(selectedCompanyId))
    }
  }

  return (
    <ContentSection>
      <Ct.Spacer height={3} />
      {captureMailEntities.map((user, index) => (
        <Fragment key={`User-${index}`}>
          <Ct.Separator size="full" />
          <Ct.Spacer height={3} />
          <CaptureMailUserRow
            user={user}
            onClick={() => deleteUser(selectedCompanyId, user)}
          />
          <Ct.Spacer height={3} />
        </Fragment>
      ))}
    </ContentSection>
  )
}

const Titles = () => (
  <StyledTextRow>
    <SettingsTitles>
      <Ct.Column>
        <Title tid={"capture-mail.user"} />
      </Ct.Column>
      <Ct.Column>
        <Title tid={"capture-mail.send-mail"} />
      </Ct.Column>
      <Ct.Column>
        <Title tid={"capture-mail.delete-access"} />
      </Ct.Column>
    </SettingsTitles>
  </StyledTextRow>
)

const Title = ({ tid }: { tid: string }) => {
  const intl = useIntl()
  return (
    <Ct.Text
      text={intl.formatMessage({ id: tid })}
      textStyle={{
        fontSize: 2,
        fontFamily: "Poppins",
        fontWeight: 600,
        textTransform: "uppercase",
      }}
    />
  )
}

export default CaptureMail

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 5rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`

const StyledContent = styled.div`
  width: fit-content;
  height: 100%;
`

const CopyEmailArea = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const SendEmailArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledTextRow = styled.div`
  justify-content: space-between;
  flex-grow: 1;
  margin: 2rem 7.25rem 2rem 4rem;
`

const SettingsTitles = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
`

const ContentSection = styled((props) => <Ct.Card {...props} />)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 0rem 0rem 2.5rem 2.5rem;
  border: 1px ${colors.lavender} solid;
  padding: 0rem 4rem 4rem 4rem;

  > hr:first-of-type {
    height: 0;
    display: none;
  }

  > div:last-of-type {
    height: 0;
  }

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`

const StickyHead = styled.header`
  position: static;
  z-index: 2;
  top: 8rem;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  width: 100%;
  flex-shrink: 0;
  background-color: ${colors.lavender};
`

const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`

const StyledEmailArea = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  background-color: ${colors.white};
  box-shadow: 0px 5px 15px rgba(2, 76, 248, 0.1);
  border-radius: 1.25rem;
  align-items: center;
  color: ${colors.navy};
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 3rem;
  letter-spacing: 0rem;
  justify-content: center;
  padding: 0rem 3rem 0rem 3rem;
  width: 100%;
`

const AddArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const Backbone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 107.375rem;
  align-self: center;
  overflow: hidden;
  height: 100%;
`

const StyledMessage = styled((props) => <Ct.Text {...props} />)`
  width: 100%;
  padding: 0.5rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
`

const ErrorMessageArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const PopUpWrapper = styled.div`
  bottom: 22rem;
  right: 2.5rem;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 4rem 1rem 4rem;
  z-index: 1222;
`

const ModalCloseCross = styled.div`
  cursor: pointer;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const HeaderWrapperText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
`

const RulesWrapper = styled.div`
  margin-left: 3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const ButtonCommon = css`
  cursor: pointer;
  & path {
    fill: "";
  }

  :focus {
    outline: 0;
  }
`

const ButtonPopUp = styled(Popup)`
  ${ButtonCommon}
`

const StyledText = styled((props) => <Ct.Text {...props} />)`
  text-align: justify;
`
