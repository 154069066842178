import { useEffect, useState, useRef, SetStateAction, Dispatch } from "react"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import ReactTooltip from "react-tooltip"

import { Text } from "../../components/Commons/Text"
import { colors } from "../../styles/design.config"
import { useRNBSelector } from "../../store/rootReducer"
import { ReactComponent as Info } from "../../assets/infoDuotone.svg"

import {
  AlreadySeenFilterOptions,
  DeactivatedDocumentInWritingForInputMask,
  FullDocumentForInputMask,
  filterAlreadySeenDocuments,
  getDisplayedCode,
} from "../../utils/inputMask"
import { formatAmount } from "../../utils/money"
import { capitalizeFirstLetter } from "../../utils/string"
import { getIdFromParams } from "../../utils/company"
import { setSelectedFullDocument } from "../../store/ducks/inputMask.ducks"
import { useDispatch } from "react-redux"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
} from "../Commons/Table"
import {
  sortDocsByBuyOrSellTypology,
  sortDocsByDate,
  sortDocsByIncludingTaxesAmount,
  sortDocsByIsNewMerchant,
  sortDocsByMerchantCode,
  sortDocsByMerchantName,
  sortDocsByReference,
} from "../../utils/inputMaskTables"
import { FilterDropdown } from "../Commons/FilterDropdown"

interface FullDocumentsListProps {
  selectedFullDocument:
    | FullDocumentForInputMask
    | DeactivatedDocumentInWritingForInputMask
    | null
  sortedDocuments: FullDocumentForInputMaskWithNewMerchant[]
  setSortedDocuments: Dispatch<
    SetStateAction<FullDocumentForInputMaskWithNewMerchant[]>
  >
}

enum SortOptionsValues {
  "already-seen", // not used for sorting, only here for index
  "merchant-code",
  "document-date",
  "including-taxes",
  "buy-or-sell",
  "reference",
  "merchant-name",
  "is-new-merchant",
}

export interface FullDocumentForInputMaskWithNewMerchant
  extends FullDocumentForInputMask {
  isNewMerchant: boolean
}

export const FullDocumentsList = ({
  selectedFullDocument,
  sortedDocuments,
  setSortedDocuments,
}: FullDocumentsListProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [asc, setAsc] = useState<Record<number, boolean> | null>(null)
  const [option, setOption] = useState<SortOptionsValues | null>(null)

  const selectedDocumentRef = useRef<HTMLDivElement>(null)
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const {
    fullDocuments,
    merchantCodes,
    editedFullDocument,
    initialFullDocument,
    merchantsOfCompany,
    company,
  } = useRNBSelector((state) => ({
    merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
    fullDocuments: state.inputMask.full_documents_to_check,
    editedFullDocument: state.inputMask.edited_full_document,
    initialFullDocument: state.inputMask.initial_full_document,
    merchantCodes: state.merchantCodes.merchantCodes,
    company: state.companies.companies[selectedCompanyId],
  }))
  const [alreadySeenFullDocuments, setAlreadySeenFullDocuments] = useState<
    number[]
  >(JSON.parse(localStorage.getItem("seenFullDocuments") || "[]"))

  useEffect(() => {
    setSortedDocuments(
      fullDocuments.map((fd) => {
        return {
          ...fd,
          isNewMerchant:
            merchantsOfCompany.find((m) => m.id === fd.merchant_id)
              ?.is_new_merchant || false,
        }
      })
    )
    if (asc && option) {
      sorter(asc[option])(option)
    }
  }, [fullDocuments])

  useEffect(() => {
    if (!selectedFullDocument) return

    if (!alreadySeenFullDocuments.includes(selectedFullDocument.fd_id)) {
      addToAlreadySeen(selectedFullDocument.fd_id)
    }

    if (selectedDocumentRef && selectedDocumentRef.current) {
      selectedDocumentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    }
  }, [selectedFullDocument])

  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setAsc({ [option]: asc })
    setOption(option)
    const fds = fullDocuments.map((fd) => {
      const isNewMerchant =
        merchantsOfCompany.find((m) => m.id === fd.merchant_id)
          ?.is_new_merchant || false
      return {
        ...fd,
        isNewMerchant,
      }
    })

    if (option === SortOptionsValues?.["merchant-code"]) {
      setSortedDocuments(sortDocsByMerchantCode(fds, asc))
    } else if (option === SortOptionsValues?.["document-date"]) {
      setSortedDocuments(sortDocsByDate(fds, asc))
    } else if (option === SortOptionsValues?.["including-taxes"]) {
      setSortedDocuments(sortDocsByIncludingTaxesAmount(fds, asc))
    } else if (option === SortOptionsValues?.["buy-or-sell"]) {
      setSortedDocuments(sortDocsByBuyOrSellTypology(fds, asc))
    } else if (option === SortOptionsValues?.["reference"]) {
      setSortedDocuments(sortDocsByReference(fds, asc))
    } else if (option === SortOptionsValues?.["merchant-name"]) {
      setSortedDocuments(sortDocsByMerchantName(fds, asc))
    } else if (option === SortOptionsValues?.["is-new-merchant"]) {
      setSortedDocuments(sortDocsByIsNewMerchant(fds, asc))
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  const addToAlreadySeen = (fullDocumentId: number) => {
    const newAlreadySeen = [...alreadySeenFullDocuments, fullDocumentId]
    setAlreadySeenFullDocuments(newAlreadySeen)
    localStorage.setItem("seenFullDocuments", JSON.stringify(newAlreadySeen))
  }

  const removeFromAlreadySeen = (fullDocumentId: number) => {
    const withoutCurrentFullDoc = alreadySeenFullDocuments.filter(
      (id) => id !== fullDocumentId
    )
    setAlreadySeenFullDocuments(withoutCurrentFullDoc)
    localStorage.setItem(
      "seenFullDocuments",
      JSON.stringify(withoutCurrentFullDoc)
    )
  }

  const columnsClient = [
    {
      headerText: "input-mask.table-headers.already-seen",
      width: "2rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => (
        <FullSize>
          <Ct.Checkbox
            label={""}
            name={`already-seen-${row.fd_id}`}
            isChecked={alreadySeenFullDocuments.includes(row.fd_id)}
            onChange={() => {
              if (alreadySeenFullDocuments.includes(row.fd_id)) {
                removeFromAlreadySeen(row.fd_id)
              } else {
                addToAlreadySeen(row.fd_id)
              }
            }}
          />
        </FullSize>
      ),
    },
    {
      headerText: "input-mask.table-headers.merchant-code",
      width: "3rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => {
        const isCurrentDocument = row.fd_id === initialFullDocument?.fd_id

        const hasChangedMerchant =
          initialFullDocument?.merchant_id !== editedFullDocument?.merchant_id

        const hasChangedMerchantCode =
          initialFullDocument?.merchant_code_id !==
          editedFullDocument?.merchant_code_id

        const merchantCode =
          isCurrentDocument && hasChangedMerchant
            ? merchantCodes.find((mc) =>
                mc.merchants.some(
                  (m) => m.merchant_id === editedFullDocument?.merchant_id
                )
              )
            : isCurrentDocument && hasChangedMerchantCode
            ? merchantCodes.find(
                (mc) => mc.id === editedFullDocument?.merchant_code_id
              )
            : merchantCodes.find((mc) => mc.id === row?.merchant_code_id)

        const diplayedMerchantCode = getDisplayedCode({
          buyOrSell:
            (isCurrentDocument
              ? editedFullDocument?.buy_or_sell
              : row?.buy_or_sell) || "buy",
          merchantCode,
          buyAuxiliaryPrefix: company?.buy_auxiliary_prefix || "",
          sellAuxiliaryPrefix: company?.sell_auxiliary_prefix || "",
        })

        return (
          <FullSizeLeft
            onClick={() => {
              dispatch(setSelectedFullDocument(row))
            }}
          >
            {diplayedMerchantCode}
          </FullSizeLeft>
        )
      },
    },
    {
      headerText: "input-mask.table-headers.document-date",
      width: "4rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => {
        const isCurrentDocument = row.fd_id === initialFullDocument?.fd_id

        const date = isCurrentDocument
          ? editedFullDocument?.fd_document_date
          : row.fd_document_date
        return (
          <FullSize
            onClick={() => {
              dispatch(setSelectedFullDocument(row))
            }}
          >
            {date}
          </FullSize>
        )
      },
    },
    {
      headerText: "input-mask.table-headers.including-taxes",
      width: "4rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => {
        const vatIncludedAmount = row.writing_lines.find(
          (line) =>
            line.datum_type === "tax_included" ||
            line.datum_type === "tax_included_bill_of_exchange"
        )
        return vatIncludedAmount ? (
          <FullSize
            onClick={() => {
              dispatch(setSelectedFullDocument(row))
            }}
          >
            {formatAmount(vatIncludedAmount.amount)}
          </FullSize>
        ) : null
      },
    },
    {
      headerText: "input-mask.table-headers.buy-or-sell",
      width: "3rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => (
        <FullSize
          onClick={() => {
            dispatch(setSelectedFullDocument(row))
          }}
        >
          {intl.formatMessage({
            id: `input-mask.document-type.${row.buy_or_sell}-${row.document_type}`,
          })}
        </FullSize>
      ),
    },
    {
      headerText: "input-mask.table-headers.reference",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => (
        <FullSize
          onClick={() => {
            dispatch(setSelectedFullDocument(row))
          }}
        >
          {row.fd_document_reference}
        </FullSize>
      ),
    },
    {
      headerText: "input-mask.table-headers.merchant-name",
      width: "6rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => {
        const isCurrentDocument = row.fd_id === initialFullDocument?.fd_id

        const merchantName = isCurrentDocument
          ? capitalizeFirstLetter(
              merchantsOfCompany.find(
                (m) => m.id === editedFullDocument?.merchant_id
              )?.name || ""
            )
          : capitalizeFirstLetter(row.merchant_name)

        return (
          <FullSize
            onClick={() => {
              dispatch(setSelectedFullDocument(row))
            }}
          >
            {merchantName}
          </FullSize>
        )
      },
    },
    {
      headerText: "input-mask.table-headers.is-new-merchant",
      width: "3rem",
      content: (row: FullDocumentForInputMaskWithNewMerchant) => {
        return (
          <FullSize
            onClick={() => {
              dispatch(setSelectedFullDocument(row))
            }}
          >
            {row.isNewMerchant && <NewBadge>NEW</NewBadge>}
          </FullSize>
        )
      },
    },
  ]

  const filterOptions: {
    key: AlreadySeenFilterOptions
    onSelect: () => void
  }[] = [
    {
      key: "input-mask.filter-dropdown.all",
      onSelect: () => {
        setSelectedOptionIndex(0)
      },
    },
    {
      key: "input-mask.filter-dropdown.not-seen",
      onSelect: () => {
        setSelectedOptionIndex(1)
      },
    },
    {
      key: "input-mask.filter-dropdown.already-seen",
      onSelect: () => {
        setSelectedOptionIndex(2)
      },
    },
  ]

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)

  const filteredDocuments = filterAlreadySeenDocuments({
    sortedDocuments,
    alreadySeenFilterOption: filterOptions[selectedOptionIndex].key,
  })
  return (
    <List>
      <TitleBar>
        <div />
        <Text
          text={intl.formatMessage({ id: "input-mask.invoices" })}
          textStyle={{ fontSize: 2, fontWeight: 600 }}
        />
        <FilterDropdown
          intl={intl}
          selectedOptionIndex={selectedOptionIndex}
          filterOptions={filterOptions}
        />
      </TitleBar>
      <Ct.Spacer />
      <StyledTableWrapper>
        <StyledTableHeader>
          {columnsClient.map(({ headerText, width }, index) => {
            return (
              <SizedTitle key={headerText}>
                {index > 0 ? (
                  <ClickableTitleSort
                    tid={headerText}
                    intl={intl}
                    index={index}
                    sortToReturn={(column: SortToReturn) => {
                      setColumnToSort(column)
                    }}
                    currentChevron={currentChevron}
                  />
                ) : (
                  <AlreadySeenHeader>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "input-mask.table-headers.already-seen",
                      })}
                      textStyle={{
                        fontWeight: 700,
                        fontSize: 1.75,
                        textTransform: "uppercase",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        lineHeight: 1,
                      }}
                    />
                    <Ct.Spacer width={0.5} height={0.5} />

                    <ReactTooltip
                      id="already-seen-tooltip"
                      place="bottom"
                      effect={"solid"}
                    />
                    <StyledInfo
                      data-tip={intl.formatMessage({
                        id: "input-mask.table-headers.already-seen.tooltip",
                      })}
                      data-for="already-seen-tooltip"
                      className=""
                    />
                  </AlreadySeenHeader>
                )}
              </SizedTitle>
            )
          })}
        </StyledTableHeader>
        <StyledTableBody>
          {filteredDocuments.map((fullDocument, lineIndex) => {
            const isSelected =
              selectedFullDocument?.fd_id === fullDocument.fd_id
            return (
              <ContentLine
                ref={isSelected ? selectedDocumentRef : null}
                key={fullDocument.fd_id}
                lineIndex={lineIndex}
                isSelected={isSelected}
                alreadySeen={alreadySeenFullDocuments.includes(
                  fullDocument.fd_id
                )}
              >
                {columnsClient.map(({ content, width, headerText }) => (
                  <SizedContent
                    width={width}
                    key={fullDocument.fd_id + headerText}
                  >
                    {content(fullDocument)}
                  </SizedContent>
                ))}
              </ContentLine>
            )
          })}
        </StyledTableBody>
      </StyledTableWrapper>
    </List>
  )
}

const List = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const StyledTableWrapper = styled(Ct.TableWrapper)`
  height: auto;
  flex: 1;
  box-shadow: 0px 4px 11px 7px #e5e5e540;
  border: none;
  border-radius: 1rem;
`
const StyledTableHeader = styled(Ct.TableHeader)`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`
const StyledTableBody = styled(Ct.TableBody)`
  max-height: 20rem;
  overflow: scroll;
  padding-bottom: 0;
`

const SizedTitle = styled.div<{ width?: string }>`
  display: flex;
  flex: ${({ width }) => (width ? width : `1`)};
  align-items: center;
  justify-content: center;
`

const SizedContent = styled.div<{ width?: string }>`
  flex: ${({ width }) => (width ? width : `1`)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  cursor: pointer;
`

const FullSize = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FullSizeLeft = styled(FullSize)`
  justify-content: flex-start;
`

const ContentLine = styled.div<{
  lineIndex: number
  isSelected: boolean
  alreadySeen: boolean
}>`
  opacity: ${({ alreadySeen, isSelected }) =>
    alreadySeen && !isSelected ? "0.5" : `1`};
  transition: 0.5s opacity ease-in-out, 0.5s color ease-in-out,
    0.5s background-color ease-in-out;
  display: flex;
  background-color: ${({ lineIndex, isSelected }) =>
    isSelected
      ? colors.cornflower
      : lineIndex % 2 === 0
      ? colors.clearBlue
      : colors.white};
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.navy)};
  min-height: 4rem;
`

const NewBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.purpleBackground};
  color: ${colors.purple};
  border-radius: 0.5rem;
  width: 4rem;
  height: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 10px;
`

const StyledInfo = styled(Info)`
  fill: ${colors.cornflower};
  height: 2.5rem;
  width: 2.5rem;
`
const AlreadySeenHeader = styled.div`
  display: flex;
  align-items: center;
`
