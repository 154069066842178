import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { BaseSyntheticEvent, useEffect, useState } from "react"
import * as yup from "yup"
import * as Ct from "ldlj"
import { yupResolver } from "@hookform/resolvers/yup"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"

import { InvoiceRecap, ProductsWithPrice } from "../../../../../utils/invoices"
import { colors } from "../../../../../styles/design.config"
import { AmountTotals } from "../../../../../components/Commons/AmountTotals"
import { ReactComponent as WhiteCross } from "../../../../../assets/white-close-cross.svg"
import { ReactComponent as Info } from "../../../../../assets/info.svg"
import { Switch } from "../../../../../components/Commons/Switch"
import { Text } from "../../../../../components/Commons/Text"
import {
  getMailMessage,
  multipleEmailsValidation,
  optionalMultipleEmailsValidation,
} from "../../../../../utils/emails"
import { Input } from "../../../../../components/Commons/Input"
import { InvoiceEmailConfig } from "../../../../../store/ducks/invoicing.duck"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { toFixed } from "../../../../../utils/numbers"

interface InvoiceMailInfo {
  companyName: string
  legalEntityId: string
  dueDate: string
}

interface ConfirmPreviewModalProps extends Ct.ModalComponentsProps {
  onConfirmPreview: (invoiceEmailConfig: InvoiceEmailConfig | null) => void
  pdfPreviewUrl: string
  productsWithPrice: ProductsWithPrice[]
  totalDiscount: number
  invoiceRecap: InvoiceRecap
  invoiceMailInfo: InvoiceMailInfo
}

export const ConfirmPreviewModal = ({
  isDisplayed,
  onClose,
  onConfirmPreview,
  pdfPreviewUrl,
  productsWithPrice,
  totalDiscount,
  invoiceRecap,
  invoiceMailInfo,
}: ConfirmPreviewModalProps) => {
  const intl = useIntl()

  const [sendByEmail, setSendByEmail] = useState(true)

  const schema = yup.object({
    emails: multipleEmailsValidation.required(),
    bccEmails: optionalMultipleEmailsValidation,
    object: yup.string().required(),
    message: yup.string().required(),
  })

  const { numberingPreview, legalEntityInfoEmails, userEmail } = useRNBSelector(
    (state) => ({
      numberingPreview: state.invoicing.numberingPreview,
      legalEntityInfoEmails: state.invoicing.legalEntities.find(
        (le) => le.legal_entity_id === Number(invoiceMailInfo.legalEntityId)
      )?.emails,
      userEmail: state.user.email,
    })
  )

  useEffect(() => {
    if (!legalEntityInfoEmails || !legalEntityInfoEmails[0]) {
      setSendByEmail(false)
    } else {
      setSendByEmail(true)
    }
  }, [legalEntityInfoEmails])

  const vatIncludedTotal = toFixed(
    productsWithPrice.reduce((total, product) => {
      return (
        total +
        (((Number(product.price) * Number(product.vatRate)) / 100) *
          product.quantity +
          Number(product.price) * product.quantity)
      )
    }, 0),
    2
  )

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    reValidateMode: "onChange",
  })

  const emails: string = watch("emails")
  const bccEmails: string = watch("bccEmails")
  const object: string = watch("object")
  const message: string = watch("message")

  const handleFormSubmit = () => {
    const htmlMessage = message.replaceAll("\n", "<br/>")
    onConfirmPreview({
      to: emails.split(","),
      bcc: bccEmails.split(","),
      cc: [userEmail],
      object,
      message: htmlMessage,
      from: userEmail,
      numberingPreview: numberingPreview,
    })
  }

  // PRE-SET VALUES
  useEffect(() => {
    if (isDisplayed) {
      setValue("emails", legalEntityInfoEmails?.join(",") || "")
      setValue("bccEmails", "")
      setValue(
        "object",
        intl.formatMessage(
          {
            id: `${invoiceRecap.invoiceType}.issuance.email.object`,
          },
          { numberingPreview: numberingPreview }
        )
      )

      const message = getMailMessage({
        invoiceType: invoiceRecap.invoiceType,
        numberingPreview,
        companyName: invoiceMailInfo.companyName,
        vatIncludedTotal,
        dueDate: invoiceMailInfo.dueDate,
        intl,
      })
      setValue("message", message)
    }
  }, [
    isDisplayed,
    setValue,
    numberingPreview,
    legalEntityInfoEmails,
    userEmail,
    vatIncludedTotal,
    invoiceMailInfo.companyName,
    invoiceMailInfo.dueDate,
  ])

  return (
    <Ct.Modal
      left="50%"
      right="50%"
      top="2rem"
      isDisplayed={isDisplayed}
      onClose={onClose}
    >
      <NoPaddingCard width="calc(100vw - 4rem)" height="calc(100vh - 4rem)">
        <FullHeight>
          <Ct.Flex1>
            <StyledIframe src={pdfPreviewUrl} title="invoice-pdf-preview" />
          </Ct.Flex1>
          <ContentCard>
            <Tableheader>
              <Ct.Text
                text={intl.formatMessage({
                  id: invoiceRecap.isEditingQuotation
                    ? `quotationEdit.preview.header.edit`
                    : `${invoiceRecap.invoiceType}.issuance.modal.title`,
                })}
                textStyle={{
                  textTransform: "uppercase",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: 2.75,
                  color: "white",
                }}
              />
              <Close onClick={onClose}>
                <WhiteCross />
              </Close>
            </Tableheader>
            <Content>
              <EmitDate>
                <Ct.Text
                  text={intl.formatMessage({
                    id: `${invoiceRecap.invoiceType}.issuance.preview.invoice-date`,
                  })}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: 1.9,
                    textAlign: "center",
                  }}
                />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "invoicing.text.double-points",
                  })}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: 1.9,
                    textAlign: "center",
                  }}
                />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={invoiceRecap.invoiceDate}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: 1.9,
                    textAlign: "center",
                  }}
                />
              </EmitDate>
              <Ct.Spacer height={4} />
              <RowStyle>
                <RowCenterWrapper>
                  <CompanyInfos>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "invoicing.preview.seller",
                      })}
                      textStyle={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: 2.2,
                      }}
                    />
                    <Ct.Spacer height={1} />
                    <Ct.Text
                      text={invoiceRecap.sellerName}
                      textStyle={{
                        fontWeight: 500,
                        textTransform: "uppercase",
                        fontSize: 1.85,
                      }}
                    />
                    <RowStyle>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "invoicing.text.siren-number",
                        })}
                        textStyle={{
                          fontWeight: 500,
                          fontSize: 1.85,
                        }}
                      />
                      <Ct.Spacer width={1} />
                      <Ct.Text
                        text={invoiceRecap.sellerSiren}
                        textStyle={{
                          fontWeight: 400,
                          fontSize: 1.85,
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "invoicing.text.adress",
                        })}
                        textStyle={{
                          fontWeight: 500,
                          fontSize: 1.85,
                        }}
                      />
                      <Ct.Spacer width={1} />
                      <Ct.Text
                        text={invoiceRecap.sellerAdress}
                        textStyle={{
                          fontWeight: 400,
                          fontSize: 1.85,
                        }}
                      />
                    </RowStyle>
                  </CompanyInfos>
                </RowCenterWrapper>
                <RowCenterWrapper>
                  <ClientInfos>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "invoicing.preview.buyer",
                      })}
                      textStyle={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: 2.2,
                      }}
                    />
                    <Ct.Spacer height={1} />
                    <Ct.Text
                      text={invoiceRecap.buyerName}
                      textStyle={{
                        fontWeight: 500,
                        textTransform: "uppercase",
                        fontSize: 1.85,
                      }}
                    />
                    <RowStyle>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "invoicing.text.siren-number",
                        })}
                        textStyle={{
                          fontWeight: 500,
                          fontSize: 1.85,
                        }}
                      />
                      <Ct.Spacer width={1} />
                      <Ct.Text
                        text={invoiceRecap.buyerSiren}
                        textStyle={{
                          fontWeight: 400,
                          fontSize: 1.85,
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: "invoicing.text.adress",
                        })}
                        textStyle={{
                          fontWeight: 500,
                          fontSize: 1.85,
                        }}
                      />
                      <Ct.Spacer width={1} />
                      <Ct.Text
                        text={invoiceRecap.buyerAdress}
                        textStyle={{
                          fontWeight: 400,
                          fontSize: 1.85,
                        }}
                      />
                    </RowStyle>
                  </ClientInfos>
                </RowCenterWrapper>
              </RowStyle>
              <Ct.FlexEnd>
                <AmountTotals
                  intl={intl}
                  productsWithPrice={productsWithPrice}
                  totalDiscount={totalDiscount}
                />
              </Ct.FlexEnd>
            </Content>
            <SwitchWrapper>
              <Switch
                value={sendByEmail}
                onToggle={() => {
                  if (!legalEntityInfoEmails || !legalEntityInfoEmails[0]) {
                    return
                  }

                  setSendByEmail(!sendByEmail)
                }}
              />
              <Ct.Spacer />
              <Text
                text={intl.formatMessage(
                  {
                    id: `${invoiceRecap.invoiceType}.invoicing.preview.modal.send-by-email`,
                  },
                  { invoiceType: invoiceRecap.invoiceType }
                )}
                textStyle={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: 2,
                }}
              />
            </SwitchWrapper>
            <Form
              onSubmit={(
                e: BaseSyntheticEvent<object, unknown, unknown> | undefined
              ) => {
                handleSubmit(handleFormSubmit)(e)
              }}
            >
              <FormContent>
                <Input
                  disabled={!sendByEmail}
                  label={intl.formatMessage({
                    id: "invoicing.preview.modal.emails",
                  })}
                  value={emails}
                  name={"emails"}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  showError={
                    sendByEmail &&
                    errors[`emails`]?.message.includes(
                      "Toutes les valeurs ne sont pas des emails"
                    )
                  }
                />
                <Ct.Spacer />
                <Explanation>
                  <StyledInfo />
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "invoicing.settings.create-client.form.email.explanation",
                    })}
                    textStyle={{
                      fontSize: 1.5,
                    }}
                  />
                </Explanation>

                <Ct.Spacer />
                <Input
                  required={false}
                  disabled={!sendByEmail}
                  label={intl.formatMessage({
                    id: "invoicing.preview.modal.bccEmails",
                  })}
                  value={bccEmails}
                  name={"bccEmails"}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  showError={
                    sendByEmail &&
                    errors[`bccEmails`]?.message?.includes(
                      "Toutes les valeurs ne sont pas des emails"
                    )
                  }
                />
                <Ct.Spacer />
                <Input
                  disabled={!sendByEmail}
                  label={intl.formatMessage({
                    id: "invoicing.preview.modal.object",
                  })}
                  value={object}
                  name={"object"}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  showError={sendByEmail && errors[`object`]?.message}
                />
                <Ct.Spacer />
                <Input
                  disabled={!sendByEmail}
                  AutoGrowing={true}
                  height="18rem"
                  label={intl.formatMessage({
                    id: "invoicing.preview.modal.message",
                  })}
                  value={message}
                  name={"message"}
                  register={register as unknown as UseFormRegister<FieldValues>}
                  showError={sendByEmail && errors[`message`]?.message}
                />
              </FormContent>
              <Ct.Spacer />
              <EvenlyButtons>
                <Ct.Button
                  label={intl.formatMessage({
                    id: "invoicing.preview.modal.cancel",
                  })}
                  colorType={"Tertiary"}
                  textTransform={"uppercase"}
                  colorScheme={{
                    background: "mist",
                    color: "cornflower",
                    border: "mist",
                  }}
                  onClick={onClose}
                  width={30}
                />
                <Ct.Spacer width={7} />
                {sendByEmail ? (
                  <Ct.Button
                    label={intl.formatMessage({
                      id: invoiceRecap.isEditingQuotation
                        ? `quotationEdit.issuance.edit-and-email.cta`
                        : `${invoiceRecap.invoiceType}.issuance.save-and-email.cta`,
                    })}
                    width={30}
                    type="submit"
                    disabled={Object.values(errors).length > 0}
                  />
                ) : (
                  <Ct.Button
                    label={intl.formatMessage({
                      id: invoiceRecap.isEditingQuotation
                        ? `quotationEdit.issuance.edit.cta`
                        : `${invoiceRecap.invoiceType}.issuance.save.cta`,
                    })}
                    onClick={() => {
                      onConfirmPreview(null)
                    }}
                    width={30}
                  />
                )}
                <Ct.Spacer height={7} />
              </EvenlyButtons>
            </Form>
          </ContentCard>
        </FullHeight>
      </NoPaddingCard>
    </Ct.Modal>
  )
}
const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border-radius: 2.5rem;
  border: none;
`
const FullHeight = styled(Ct.Row)`
  height: 100%;
  width: 100%;
  align-items: stretch;
`
const EvenlyButtons = styled(Ct.RowCenter)`
  justify-content: space-evenly;
`
const NoPaddingCard = styled(Ct.Card)`
  padding: 0;
`
const ContentCard = styled(Ct.Flex1)`
  padding: 0;
  min-width: 100rem;
  border-left: 1px solid ${colors.lightBlueGrey};
  border-top-left-radius: 3rem;
  overflow-y: scroll;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
    margin-top: 7rem;
    margin-bottom: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const Content = styled.div`
  padding: 4rem;
  margin: 2rem 4rem;
  border: 1px solid ${colors.rock};
  border-radius: 2rem;
`

const FormContent = styled.div`
  padding: 4rem;
  margin: 2rem 4rem;
  border: 1px solid ${colors.rock};
  border-radius: 2rem;
  width: calc(100% - 8rem);
  box-sizing: border-box;
`

const Form = styled((props) => <Ct.StyledForm {...props} />)`
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4rem;
`
const EmitDate = styled(Ct.FlexEnd)`
  padding: 0;
`
const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 50%;
  justify-content: space-between;
`
const RowStyle = styled.div`
  display: flex;
  flex-direction: row;
`
const CompanyInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`
const ClientInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`
const Tableheader = styled(Ct.TableHeader)`
  background-color: ${colors.rock};
`
const Close = styled.div`
  right: 2rem;
  position: absolute;
  cursor: pointer;
`
const Explanation = styled.div`
  display: flex;
  align-items: center;
`
const StyledInfo = styled(Info)`
  fill: ${colors.cornflower};
`
