import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { Modal, Card, ModalComponentsProps } from "ldlj"
import { useIntl } from "react-intl"
import * as Ct from "ldlj"

import { Select } from "../Commons/Select"
import { useRNBSelector } from "../../store/rootReducer"
import {
  deactivateFullDocumentResetAction,
  getInputMaskAccountsThunk,
  getInputMaskMerchantThunk,
  getInputMaskWritingThunk,
  inputMaskDeactivateFullDocumentThunk,
} from "../../store/ducks/inputMask.ducks"
import {
  GetAllMerchantsOfCompanyForCodes,
  GetCompanyMerchantCodesDataThunk,
} from "../../store/ducks/merchantCodes.ducks"
import { getDeactivatedReasonsThunk } from "../../store/ducks/fullDocuments.ducks"

interface ModalDeactivateProps extends ModalComponentsProps {
  fullDocumentId: number
}

export const ModalDeactivate = ({
  fullDocumentId,
  isDisplayed,
  onClose,
}: ModalDeactivateProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [selectedReason, setSelectedReason] = useState<string | null>(null)

  useEffect(() => {
    dispatch(getDeactivatedReasonsThunk())
  }, [dispatch])

  const {
    editedFullDocument,
    deactivateStatus,
    merchantId,
    companyId,
    fiscalYearId,
  } = useRNBSelector((state) => ({
    editedFullDocument: state.inputMask.edited_full_document,
    deactivateStatus: state.inputMask.deactivate_status,
    companyId: state.inputMask.companyId,
    merchantId: state.inputMask.merchantId,
    fiscalYearId: state.inputMask.fiscalYearId,
  }))

  const userDeactivatedReasons = useRNBSelector(
    (state) => state.writings.deactivatedReasons.user_deactivated_reasons
  )

  if (deactivateStatus === "success") {
    if (companyId) {
      dispatch(deactivateFullDocumentResetAction())

      if (merchantId && companyId && fiscalYearId) {
        dispatch(
          getInputMaskMerchantThunk({
            merchantId,
            companyId,
            fiscalYearId,
          })
        )
      } else if (companyId && fiscalYearId) {
        dispatch(
          getInputMaskWritingThunk({
            companyId,
            fiscalYearId,
          })
        )
      }
      dispatch(getInputMaskAccountsThunk({ companyId }))
      dispatch(GetCompanyMerchantCodesDataThunk(companyId))
      dispatch(GetAllMerchantsOfCompanyForCodes(companyId))

      onClose()
    }
  }

  const deactivatedReasonsOptions: Ct.OptionList<string> = Object.keys(
    userDeactivatedReasons
  ).map((key: string) => ({
    value: key,
    label: userDeactivatedReasons[key],
  }))

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      top={"30vh"}
      left={"calc(50% - 35rem)"}
    >
      <Card>
        <Ct.Title size={3} text="Désactiver le document" />
        <Ct.Spacer />
        <Select
          customWidth="39rem"
          intl={intl}
          options={deactivatedReasonsOptions}
          value={
            selectedReason
              ? {
                value: selectedReason,
                label: userDeactivatedReasons[selectedReason],
              }
              : null
          }
          onChangeCallback={(selectedOption) => {
            setSelectedReason(selectedOption.value)
          }}
          alignSelf="center"
          domain="input-mask"
          optionType="deactivate_modal.deactivation"
        />
        <Ct.Spacer height={4} />
        <Controls>
          <Ct.Button
            label={intl.formatMessage({
              id: `input-mask.deactivate_modal.cancel`,
            })}
            colorType="Tertiary"
            onClick={() => {
              onClose()
            }}
          />
          <Ct.Spacer height={4} />
          <Ct.Button
            label={intl.formatMessage({
              id: `input-mask.deactivate_modal.deactivate`,
            })}
            onClick={() => {
              if (!editedFullDocument || !selectedReason) {
                return
              }
              dispatch(
                inputMaskDeactivateFullDocumentThunk({
                  fullDocumentId: editedFullDocument?.fd_id,
                  deactivatedBecause: selectedReason,
                })
              )
            }}
            disabled={!selectedReason}
          />
        </Controls>
      </Card>
    </Modal>
  )
}

const Controls = styled.div`
  display: flex;
`
