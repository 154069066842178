import { RootState } from "../store/rootReducer"

export type UserTypes =
  | "administrator"
  | "manager"
  | "collaborator"
  | "customer_accountant"
  | "customer"

interface PermissionsByTypologies {
  change_role: "yes" | "no"
  create: "yes" | "no"
  delete: "yes" | "no"
  edit: "self" | "yes" | "no"
  edit_email: "self" | "yes" | "no"
  edit_notifications: "self" | "yes" | "no"
  grant_access: "visible" | "yes" | "no"
  revoke_access: "only_hidden" | "yes" | "no"
}

interface UserPermissionsByType {
  administrator: PermissionsByTypologies
  manager: PermissionsByTypologies
  collaborator: PermissionsByTypologies
  customer_accountant: PermissionsByTypologies
  customer: PermissionsByTypologies
  can_assign_typologies: UserTypes[]
}

export function getUserPermissions(state: RootState) {
  const userPermissions =
    state.team.userPermissions &&
    state.team.userPermissions[state.user.typology as UserTypes]

  return userPermissions
}

export function getAssignableTypologies(state: RootState) {
  const userPermissions =
    state.team.userPermissions &&
    state.team.userPermissions[state.user.typology as UserTypes]

  return (userPermissions && userPermissions["can_assign_typologies"]) || []
}

export type UserPermissions = Record<UserTypes, UserPermissionsByType>

export interface TeamMember {
  id: number
  first_name: string
  last_name: string | null
  email: string
  typology: UserTypes
  notifications_preferences: string
  companies_permissions: unknown[]
}

export type UsersTeamResponse = Array<TeamMember>

export type NotificationPreferences = "weekly" | "none" | "daily"

export type RedirectionDirective =
  | "UNSET"
  | "ACCESS_DENIED"
  | "COMPANIES_LIST"
  | "SPECIFIC_COMPANY"
  | "COMPANY_CREATION"
  | "ERROR"
  | "TERMS_OF_SERVICE_NOT_ACCEPTED"
  | "FIDUCIARY_NOT_CREATED"
  | "TERMS_OF_SALES_NOT_ACCEPTED"
  | "FIRST_CONNEXION"
  | "SSO"

export function sortByLastName(
  users: TeamMember[],
  asc: boolean
): TeamMember[] {
  return [
    ...(asc
      ? users.sort((a, b) => a.last_name?.localeCompare(b.last_name || "") || 0)
      : users.sort(
          (a, b) => b.last_name?.localeCompare(a.last_name || "") || 0
        )),
  ]
}

export function sortByTypology(
  asc: boolean,
  users: TeamMember[]
): TeamMember[] {
  return [
    ...(asc
      ? users.sort((a, b) => b.typology.localeCompare(a.typology))
      : users.sort((a, b) => a.typology.localeCompare(b.typology))),
  ]
}

export function sortByEmail(asc: boolean, users: TeamMember[]): TeamMember[] {
  const filteredUsers = users.filter((user) => user.email)
  return [
    ...(asc
      ? filteredUsers.sort((a, b) => a.email.localeCompare(b.email))
      : filteredUsers.sort((a, b) => b.email.localeCompare(a.email))),
  ]
}

export function sortAlphabetically(asc: boolean, users: TeamMember[]) {
  const filterUsersByType = (type: string) =>
    Object.values(users).filter((user) => user.typology === type)

  const administrators = filterUsersByType("administrator")
  const managers = filterUsersByType("manager")
  const collaborators = filterUsersByType("collaborator")
  const customer_accountant = filterUsersByType("customer_accountant")
  const customers = filterUsersByType("customer")

  const sortedAdministrators = sortByLastName(administrators, asc)
  const sortedManagers = sortByLastName(managers, asc)
  const sortedCollaborators = sortByLastName(collaborators, asc)
  const Sortedcustomer_accountant = sortByLastName(customer_accountant, asc)
  const sortedCustomers = sortByLastName(customers, asc)

  return [
    ...sortedAdministrators,
    ...sortedManagers,
    ...sortedCollaborators,
    ...Sortedcustomer_accountant,
    ...sortedCustomers,
  ]
}

export function searchUsers(users: TeamMember[], search: string) {
  const lowercaseSearch = search.toLowerCase()
  return users.filter((user) => {
    const firstName = user.first_name?.toLowerCase()
    const lastName = user.last_name?.toLowerCase()
    const email = user.email?.toLowerCase()

    return [firstName, lastName, email]
      .filter(Boolean) // remove null values
      .some((userField) => userField?.includes(lowercaseSearch))
  })
}
