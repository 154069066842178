import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { Fragment, useEffect, useState } from "react"
import { useRNBSelector } from "../../../../store/rootReducer"
import * as Ct from "ldlj"
import { Logos } from "../../../../components/Commons/Logos"
import { Text } from "../../../../components/Commons/Text"
import { ReactComponent as Tag } from "../../../../assets/tag-empty.svg"
import { ReactComponent as ChevronUp } from "../../../../assets/chevron_up_2.svg"
import { ReactComponent as ChevronDown } from "../../../../assets/chevron_down_2.svg"
import { ReactComponent as Plus } from "../../../../assets/plus.svg"
import { useDispatch } from "react-redux"
import {
  DestroyWritingLabelRuleThunk,
  Field,
  GetWritingLabelDataThunk,
  WritingLabelRule,
} from "../../../../store/ducks/writingLabels.ducks"
import { Table, TableBuilder } from "../../../../components/Commons/Table"
import { formatValuesRules } from "../../../../utils/writingLabelRule"
import { ReactComponent as TrashCan } from "../../../../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { DeleteModalTemplate } from "../../../../components/Commons/DeleteModalTemplate"
import { CreateOrEditWritingLabelModal } from "../../../../components/company/CreateOrEditWritingLabelModal"

/* eslint-disable camelcase */

export const WritingLabels = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId || 0]
  )
  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const fields = useRNBSelector(
    (state) => state.writingLabels.writing_labels_data.fields
  )
  const [toggles, setToggles] = useState<{ index: number; toggle: boolean }[]>(
    []
  )
  const [createOrEditWritingLabel, setCreateOrEDitWritingLabel] = useState<{
    field: Field
    fieldName: string
    createOrEdit: "create" | "edit"
    rule?: WritingLabelRule
  } | null>(null)
  const [deleteRuleDisplay, setDeleteRuleDisplay] = useState<{
    id: number | null
    field: string | null
  }>({ id: null, field: null })

  useEffect(() => {
    if (selectedCompanyId && selectedCompanyId !== 0) {
      dispatch(GetWritingLabelDataThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (userInformations) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company?.accounting_type,
        },
      })
    }
  }, [userInformations, fiduciaryInformations, userInformations.typology])

  useEffect(() => {
    if (Object.values(fields).length > 0) {
      Object.values(fields).map((field) => {
        const default_row: WritingLabelRule = {
          id: 0,
          buy_or_sell: "all",
          document_type: "all",
          values: field.default_fields,
          field: "",
          free_text: null,
          crop: null,
        }
        field.writing_label_rules.splice(0, 0, default_row)

        return field
      })

      const newRows = Object.values(fields).map((_, index) => {
        return { index: index, toggle: toggles[index]?.toggle || false }
      })
      setToggles(newRows)
    }
  }, [fields])

  const handleToggle = (index: number, toggle: boolean) => {
    const newToggles = [...toggles]
    newToggles[index] = { index: index, toggle: toggle }
    setToggles(newToggles)
  }

  const getObjectByKey = (obj: { [key: string]: Field }, key: string) => {
    const lowerCaseKey = key.toLowerCase()
    const matchingKey = Object.keys(obj).find(
      (objKey) => objKey.toLowerCase() === lowerCaseKey
    )
    return matchingKey ? obj[matchingKey] : Object.values(obj)[0]
  }

  const columns: TableBuilder<WritingLabelRule>[] = [
    {
      headerText: "office-company.writing-labels.table.title.document-type",
      width: "30%",
      flexGrow: "none",
      content: (row: WritingLabelRule) => (
        <ColumnRowFullDocs justifyContent={"flex-start"}>
          {row && (
            <>
              <Ct.Spacer width={2} height={0} />
              <Text
                text={intl.formatMessage({
                  id: `office-company.writing-labels.table.document-type.${row.document_type}`,
                })}
              />
              {row.id === 0 && (
                <>
                  <Ct.Spacer height={0} width={1} />
                  <Text
                    text={intl.formatMessage({
                      id: `office-company.writing-labels.table.default-chaintrust`,
                    })}
                    textStyle={{
                      color: "cornflower",
                      fontStyle: "italic",
                    }}
                  />
                </>
              )}
            </>
          )}
        </ColumnRowFullDocs>
      ),
    },
    {
      headerText: "office-company.writing-labels.table.title.buy-or-sell",
      width: "20%",
      flexGrow: "none",
      content: (row: WritingLabelRule) => (
        <ColumnRowFullDocs justifyContent={"center"}>
          {row && (
            <Text
              text={intl.formatMessage({
                id: `office-company.writing-labels.table.buy-or-sell.${row.buy_or_sell}`,
              })}
            />
          )}
        </ColumnRowFullDocs>
      ),
    },
    {
      headerText: "office-company.writing-labels.table.title.preview",
      width: "45%",
      flexGrow: "none",
      content: (row: WritingLabelRule) => (
        <ColumnRowFullDocs justifyContent={"center"}>
          {row && (
            <Text
              text={formatValuesRules(row, intl)}
              textStyle={{
                wordBreak: "break-word",
                textAlign: "center",
              }}
            />
          )}
        </ColumnRowFullDocs>
      ),
    },
    {
      headerText: "empty",
      width: "5%",
      flexGrow: "none",
      content: (row: WritingLabelRule) => (
        <ColumnRowFullDocs justifyContent={"center"}>
          <IconWrapper
            disabled={row.id === 0}
            onClick={() => {
              if (row.id === 0) return
              setCreateOrEDitWritingLabel({
                field: getObjectByKey(fields, row.field),
                fieldName: row.field,
                createOrEdit: "edit",
                rule: row,
              })
            }}
          >
            <Edit />
          </IconWrapper>
          <Ct.Spacer height={0} width={1} />
          <IconWrapper
            disabled={row.id === 0}
            onClick={() => {
              if (row.id === 0 || !selectedCompanyId) return
              setDeleteRuleDisplay({ id: row.id, field: row.field })
            }}
          >
            <TrashCan />
          </IconWrapper>
          <Ct.Spacer height={0} width={1} />
        </ColumnRowFullDocs>
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        <Ct.Card width={"100%"} padding={"1rem"}>
          <Ct.ColumnCenterCenter>
            {company?.accounting_software && (
              <Logos
                software={company.accounting_software as string}
                width={"30rem"}
                height={"15rem"}
              />
            )}
            <Ct.Spacer height={1} />
            <Text
              text={intl.formatMessage(
                {
                  id: `office-company.writing-labels.title-software`,
                },
                { software: company?.accounting_software?.toUpperCase() }
              )}
              textStyle={{
                fontWeight: 600,
                fontSize: 2,
              }}
            />
          </Ct.ColumnCenterCenter>
        </Ct.Card>
        <Ct.Spacer height={2} />

        <WrapperCards>
          {toggles.length === Object.values(fields).length &&
            Object.values(fields).map((label, index) => {
              return (
                <Fragment key={index}>
                  <StyledCard width={"98%"}>
                    <TitleCard>
                      <FlexCenter>
                        <Tag />
                        <Ct.Spacer width={1} height={0} />
                        <Text
                          text={
                            Object.keys(fields)[index] +
                            ` (${label.translation})`
                          }
                          textStyle={{
                            fontWeight: 600,
                          }}
                        />
                      </FlexCenter>

                      <FlexCenter>
                        <Text
                          text={intl.formatMessage({
                            id: `${
                              toggles[index].toggle ? "see-less" : "see-more"
                            }`,
                          })}
                          onClick={() => {
                            handleToggle(index, !toggles[index].toggle)
                          }}
                          textStyle={{
                            color: "cornflower",
                            underline: true,
                          }}
                        />
                        {toggles[index].toggle ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </FlexCenter>
                    </TitleCard>

                    {toggles[index].toggle && (
                      <>
                        <Ct.Spacer width={0} height={2} />

                        <Ct.Row>
                          &#x2022;
                          <Ct.Spacer width={1} height={0} />
                          <Text
                            text={intl.formatMessage(
                              {
                                id: `office-company.writing-labels.field-name-translation`,
                              },
                              {
                                field: Object.keys(fields)[index],
                                translation: label.translation,
                              }
                            )}
                          />
                        </Ct.Row>
                        <Ct.Spacer width={0} height={1} />

                        <Ct.Row>
                          &#x2022;
                          <Ct.Spacer width={1} height={0} />
                          <Text
                            text={intl.formatMessage(
                              {
                                id: `office-company.writing-labels.field-position`,
                              },
                              {
                                position:
                                  label.start + (label.max_length ? 1 : 0),
                                software: company?.accounting_software,
                              }
                            )}
                          />
                        </Ct.Row>
                        <Ct.Spacer width={0} height={1} />

                        {label.max_length && (
                          <>
                            <Ct.Row>
                              &#x2022;
                              <Ct.Spacer width={1} height={0} />
                              <Text
                                text={intl.formatMessage(
                                  {
                                    id: `office-company.writing-labels.field-max-length`,
                                  },
                                  { max_length: label.max_length }
                                )}
                              />
                            </Ct.Row>
                            <Ct.Spacer width={0} height={1} />
                          </>
                        )}

                        <Table
                          intl={intl}
                          columns={columns}
                          rows={label.writing_label_rules}
                          alignItems={"center"}
                          width={"100%"}
                          height={"100%"}
                          padding={"0"}
                          paddingHeader={"0rem 0rem 0rem 1rem"}
                          paddingRows={"0px 0px 8px 0px"}
                          heightHeader={"5rem"}
                          fontWeightTitle={600}
                          keyBuilder={(rowData) => String(rowData.id)}
                          customScrollBar={true}
                        />

                        <Ct.Spacer height={2} />
                        <Centered>
                          <StyledPlus />
                          <Ct.Spacer width={1} />
                          <Text
                            text={intl.formatMessage({
                              id: `office-company.writing-labels.create`,
                            })}
                            textStyle={{
                              color: "cornflower",
                              underline: true,
                              fontSize: 1.75,
                            }}
                            onClick={() => {
                              setCreateOrEDitWritingLabel({
                                field: Object.values(fields)[index],
                                fieldName: Object.keys(fields)[index],
                                createOrEdit: "create",
                              })
                            }}
                          />
                        </Centered>
                      </>
                    )}
                  </StyledCard>
                  <Ct.Spacer height={2} />
                </Fragment>
              )
            })}
        </WrapperCards>

        {deleteRuleDisplay.id && (
          <DeleteModalTemplate
            isDisplayed={!!deleteRuleDisplay.id}
            onClose={() => {
              setDeleteRuleDisplay({ id: null, field: null })
            }}
            onConfirm={() => {
              if (!selectedCompanyId || !deleteRuleDisplay.id) return
              dispatch(
                DestroyWritingLabelRuleThunk(
                  selectedCompanyId,
                  deleteRuleDisplay.id
                )
              )
              setDeleteRuleDisplay({ id: null, field: null })
            }}
            titleIntl={intl.formatMessage({
              id: `office-company.writing-labels.destroy-label.title`,
            })}
            firstTextIntl={
              intl.formatMessage({
                id: `office-company.writing-labels.destroy-label.text1`,
              }) +
              deleteRuleDisplay.field +
              "."
            }
            secondTextIntl={intl.formatMessage({
              id: `office-company.writing-labels.destroy-label.text2`,
            })}
          />
        )}

        {createOrEditWritingLabel && (
          <CreateOrEditWritingLabelModal
            isDisplayed={!!createOrEditWritingLabel}
            onClose={() => {
              setCreateOrEDitWritingLabel(null)
            }}
            field={createOrEditWritingLabel.field}
            createOrEdit={createOrEditWritingLabel.createOrEdit}
            rule={createOrEditWritingLabel.rule}
            fieldName={createOrEditWritingLabel.fieldName}
          />
        )}
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 5rem 6rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
  height: 94%;
`
const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
`
const TitleCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`
const WrapperCards = styled.div`
  overflow: auto;
  width: 100%;
`
const ColumnRowFullDocs = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
  padding: 1rem 0;
`
const IconWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  && svg {
    width: 3rem;
    height: 3rem;
    & path {
      fill: ${(props) => (props.disabled ? colors.moon : colors.cornflower)};
    }
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`
const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`
const StyledPlus = styled(({ ...props }) => <Plus {...props} />)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  & path {
    fill: ${colors.cornflower};
  }
`
