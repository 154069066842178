import Dinero from "dinero.js"
import { CurrencyCode, formattedCurrencySymbol } from "./currencyUtils"

export const formatAmount = (
  amount: string = "0",
  currency: CurrencyCode = "EUR"
) => {
  const dineroAmount = getDineroAmount(amount)

  const format = dineroAmount.hasSubUnits() ? "0.00" : "0"
  const formatted = dineroAmount.toFormat(format).replaceAll(".", ",")

  return `${formatted} ${formattedCurrencySymbol(currency)}`
}

export const getDineroAmount = (amount: string) => {
  const amountInCents = Math.round(
    Number(parseFloat(amount.replace(",", ".")).toFixed(2)) * 100
  )

  const dineroAmount = Dinero({ amount: amountInCents })
  return dineroAmount
}
