import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as FourOThree } from "../../assets/illustration_403.svg"
import { useDispatch } from "react-redux"
import { setCompanyStatusForUser } from "../../store/ducks/companies.ducks"
import { Background } from "../../components/Background"
import * as Ct from "ldlj"

const Unauthorized403 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const redirectHome = () => {
    dispatch(setCompanyStatusForUser())
    navigate("/")
  }

  return (
    <Background centeredTop={true}>
      <Ct.Card width={"125rem"} height={"71rem"} radius={1.5}>
        <Ct.Spacer height={2} />
        <StyledFourOThree />
        <Ct.Spacer height={4} />
        <StyledErrorText
          text={intl.formatMessage({ id: "403.text" })}
          textStyle={{
            textTransform: "initial",
            lineHeight: 2.75,
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: 2.25,
          }}
        />
        <Ct.Spacer height={4} />
        <Ct.Button
          label={intl.formatMessage({ id: "403.link" })}
          onClick={redirectHome}
          width={31}
          height={7}
        />
        <Ct.Spacer height={3} />
      </Ct.Card>
    </Background>
  )
}

export default Unauthorized403

const StyledFourOThree = styled(FourOThree)`
  width: 100%;
  height: 100%;
`
const StyledErrorText = styled(Ct.Text)`
  font-weight: 800;
`
