import { ReactComponent as SettingsHorizontally } from "../../../assets/settingsAdjustHorizontally.svg"
import { ReactComponent as Companies } from "../../../assets/companiesLine.svg"
import { ReactComponent as Chart } from "../../../assets/chart.svg"
import { ReactComponent as Bills } from "../../../assets/bills.svg"
import { useRNBSelector } from "../../../store/rootReducer"
import { useLocation } from "react-router-dom"
import { isRouteSelected } from "../../../utils/routes"
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom"
import { ScrollableOutlet } from "../../../components/Commons/ScrollableOutlet"
import { TabObject, Tabs } from "../../../components/Commons/Tabs"
import styled from "styled-components/macro"

const Fiduciary = () => {
  const location = useLocation()
  const userTypology = useRNBSelector((state) => state.user.typology)
  const companies = useRNBSelector((state) => state.companies.companies)
  const routePattern = "\\/fiduciary"

  const LinkTabs: TabObject[] = [
    {
      to: "companies",
      title: `Sociétés ${
        Object.keys(companies).length > 0
          ? `(${Object.keys(companies).length})`
          : ""
      }`,
      icon: <Companies />,
      isDisplayed: [
        "administrator",
        "manager",
        "collaborator",
        "customer_accountant",
        "customer",
      ].includes(userTypology),
      isSelected: isRouteSelected(location, routePattern, "companies"),
    },
    {
      to: "indicators",
      title: "Indicateurs",
      icon: <Chart />,
      isDisplayed: ["administrator", "manager", "collaborator"].includes(
        userTypology
      ),
      isSelected: isRouteSelected(location, routePattern, "indicators"),
    },
    {
      to: "settings",
      title: "Paramètres",
      icon: <SettingsHorizontally />,
      isDisplayed: ["administrator", "manager"].includes(userTypology),
      isSelected: isRouteSelected(location, routePattern, "settings"),
    },
    {
      to: "bills",
      title: "Factures",
      icon: <Bills />,
      isDisplayed: ["administrator"].includes(userTypology),
      isSelected: isRouteSelected(location, routePattern, "bills"),
    },
  ]

  return (
    <ScrollableSection>
      <Tabs
        tabs={LinkTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </ScrollableSection>
  )
}

export default Fiduciary

const ScrollableSection = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 4rem 4rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`
