import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import {
  CreateGoodsTransferAccountThunk,
  CreateGoodsTransferAccountWithVathunk,
  GoodsTransferType,
} from "../../store/ducks/companySettings.ducks"
import { useState } from "react"
import { useRNBSelector } from "../../store/rootReducer"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { Select } from "../Commons/Select"
/* eslint-disable camelcase */

interface CreateGoodsTransferProps extends Ct.ModalComponentsProps {
  type: GoodsTransferType
  createWithVat?: boolean
  existingVatRates?: number[]
}

export const CreateGoodsTransferAccountModal = ({
  isDisplayed,
  onClose,
  type,
  createWithVat,
  existingVatRates,
}: CreateGoodsTransferProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const [number, setNumber] = useState("")
  const [details, setDetails] = useState("")

  const [vat, setVat] = useState<{ value: string; label: string }>({
    value: "",
    label: "",
  })

  const accountNumberLimit = useRNBSelector(
    (state) => state.companySettings.accountNumberLimit
  )
  const { goods_transfer_rules, goods_transfer_commission_rules } =
    useRNBSelector((state) => state.companySettings.goods_transfer)

  const rates = useRNBSelector((state) => state.companySettings.rates)

  const rateOptions = rates
    .filter((rate) => !existingVatRates?.includes(Number(rate.rate)))
    .map((rate) => {
      return { value: String(rate.id), label: rate.rate }
    })

  const rules = type.includes("commission")
    ? goods_transfer_commission_rules
    : goods_transfer_rules

  const isValidNumber =
    rules.some((r) => number.startsWith(r)) && number.length > 3

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="45%"
      right="45%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"80rem"}>
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          size={3}
          text={intl.formatMessage(
            {
              id: "accounting-plan.goods-transfer.modal-create.title",
            },
            { withVat: createWithVat ? "par taux" : "" }
          )}
        />
        <Ct.Spacer height={4} />

        <Ct.Row>
          <Ct.Input
            name="number"
            label={intl.formatMessage({
              id: "office-company-settings.vat-modal.number",
            })}
            value={number}
            onChange={(e) => {
              setNumber(e.target.value)
            }}
            maxLength={accountNumberLimit || undefined}
          />
          <Ct.Spacer />
          <Ct.Input
            name="details"
            label={intl.formatMessage({
              id: "office-company-settings.vat-modal.details",
            })}
            type="text"
            value={details}
            onChange={(e) => {
              setDetails(e.target.value)
            }}
          />

          {createWithVat && (
            <>
              <Ct.Spacer />
              <Select
                intl={intl}
                disabled={false}
                value={vat}
                options={rateOptions}
                domain={"accounting-plan.goods-transfer.modal-create"}
                optionType={"vat"}
                onChangeCallback={(selectedVat) => {
                  setVat(selectedVat)
                }}
              />
            </>
          )}
        </Ct.Row>
        {!isValidNumber && number.length > 3 && (
          <>
            <Ct.Spacer />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "accounting-plan.goods-transfer.modal-create.error-number",
                },
                { rules: rules.join(" ou ") }
              )}
              textStyle={{ color: "radicalRed" }}
            />
          </>
        )}
        <Ct.Spacer height={4} />
        <Ct.Button
          label={intl.formatMessage({
            id: `office-company-settings.vat-modal.cta`,
          })}
          onClick={() => {
            if (selectedCompanyId) {
              if (createWithVat) {
                dispatch(
                  CreateGoodsTransferAccountWithVathunk(
                    selectedCompanyId,
                    { number: String(number), details: details },
                    type,
                    vat.value
                  )
                )
              } else {
                dispatch(
                  CreateGoodsTransferAccountThunk(
                    selectedCompanyId,
                    { number: Number(number), details: details },
                    type + "_account_id"
                  )
                )
              }
              onClose()
            }
          }}
          disabled={
            !isValidNumber ||
            details.length === 0 ||
            (createWithVat ? vat.value === "" : false)
          }
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
