import { useIntl } from "react-intl"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as Private } from "../assets/private.svg"
import { ReactComponent as PrivateSolid } from "../assets/private-solid.svg"
import { ReactComponent as Company } from "../assets/company.svg"
import { ReactComponent as CompanySolid } from "../assets/company-solid.svg"
import { ReactComponent as Lock } from "../assets/lock.svg"
import { ReactComponent as Info } from "../assets/info.svg"

import { colors, sizes } from "../styles/design.config"
import { SirenInput } from "./SIRENInput"
import { cleanSIREN, validateSIREN } from "../utils/siren"
import { useRNBSelector } from "../store/rootReducer"
import { shallowEqual, useDispatch } from "react-redux"
import { debounce } from "../utils/debounce"
import * as Ct from "ldlj"
import { Input } from "../components/Commons/Input"

import {
  ClientForm,
  InvoicingSirenValidationThunk,
  invoicingSirenResetStatus,
  GetAllLegalEntitiesForCompanyThunk,
  ModifyClientThunk,
  CreateClientThunk,
  GetTypologiesThunk,
  LegalEntityInfo,
  getLegalEntityFromSirenThunk,
  LegalEntityWithoutInfos,
} from "../store/ducks/invoicing.duck"
import { getIdFromParams } from "../utils/company"
import { capitalizeFirstLetter } from "../utils/string"
import { trimNumber } from "../utils/invoicing"
import { optionalMultipleEmailsValidation } from "../utils/emails"
import { InputNumber } from "./Commons/InputNumber"

interface CreateClientModalProps {
  createOrModify: "create" | "modify"
  selectedClient?: LegalEntityInfo
  clientToCreate: LegalEntityWithoutInfos | null
  onClose: (clientCreatedForInvoiceId: number | null) => void
  isDisplayed: boolean
}

export const CreateOrModifyClientModal = ({
  isDisplayed,
  onClose,
  createOrModify,
  clientToCreate,
  selectedClient,
}: CreateClientModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [selectedTypology, setSelectedTypology] = useState("company")
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const [existingClient, setExistingClient] = useState<
    LegalEntityInfo | undefined
  >(selectedClient ? selectedClient : undefined)

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(GetAllLegalEntitiesForCompanyThunk(selectedCompanyId))
      dispatch(GetTypologiesThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  const clientCreatedForInvoiceId = useRNBSelector(
    (state) => state.invoicing.clientCreatedForInvoiceId
  )
  const clientCreationStatus = useRNBSelector(
    (state) => state.invoicing.clientCreationStatus
  )
  const clientNameFromSiren = useRNBSelector((state) => state.invoicing.name)
  const adressFromSiren = useRNBSelector((state) => state.invoicing.address)
  const postalCodeFromSiren = useRNBSelector(
    (state) => state.invoicing.postalCode
  )
  const cityFromSiren = useRNBSelector((state) => state.invoicing.city)
  const sirenFromSirenCheck = useRNBSelector((state) => state.invoicing.siren)
  const typologies = useRNBSelector((state) => state.invoicing.typologies)
  const existingLegalEntityId = useRNBSelector(
    (state) => state.invoicing.legalEntityId
  )
  const legalEntities = useRNBSelector((state) => state.invoicing.legalEntities)
  const { sirenCheck, sirenState } = useRNBSelector(
    (state) => state.invoicing,
    shallowEqual
  )

  useEffect(() => {
    const selectedLegalEntityInfo = legalEntities.find(
      (le) => le.legal_entity_info_id === selectedClient?.legal_entity_info_id
    )
    if (selectedLegalEntityInfo) {
      setTotalDiscount(selectedLegalEntityInfo.total_discount)
    }
  }, [legalEntities, selectedClient?.legal_entity_info_id])

  const currentSiren = useRNBSelector((state) =>
    selectedCompanyId && state.companies.companies[selectedCompanyId]
      ? state.companies.companies[selectedCompanyId].informations?.siren
      : ""
  )
  const currentCompany = useRNBSelector((state) =>
    selectedCompanyId && state.companies.companies[selectedCompanyId]
      ? state.companies.companies[selectedCompanyId].name
      : ""
  )

  const close = () => {
    onClose(clientCreatedForInvoiceId || null)
  }

  useEffect(() => {
    if (clientCreationStatus === "SUCCESS") {
      close()
    }
  }, [clientCreationStatus])

  const [totalDiscount, setTotalDiscount] = useState(0)

  const handleFormSubmit = () => {
    if (selectedCompanyId) {
      const clientForm: ClientForm = {
        name: {
          name:
            selectedTypology === "company"
              ? clientNameFromSiren
              : clientName?.trim(),
        },
        typology: {
          typology: selectedTypology,
          translation: typologies[selectedTypology],
        },
        adress: address,
        postalCode: postalCode,
        city: city,
        phone: phone,
        emails: emails.split(","),
        siren: trimNumber(siren) || null,
        totalDiscount: totalDiscount,
        legalEntityId:
          existingLegalEntityId || selectedClient?.legal_entity_id || null,
      }

      if (selectedClient) {
        dispatch(
          ModifyClientThunk(selectedCompanyId, clientForm, createOrModify)
        )
      } else {
        dispatch(
          CreateClientThunk(selectedCompanyId, clientForm, createOrModify)
        )
      }
    }
  }

  useEffect(() => {
    if (clientToCreate && isDisplayed) {
      setValue("clientName", clientToCreate.name)

      if (clientToCreate.identification_number) {
        setValue("siren", clientToCreate.identification_number)
        setSelectedTypology("company")
        setValue("entity", "company")
      } else {
        setSelectedTypology("private_person")
        setValue("entity", "private_person")
      }
    }
  }, [dispatch, isDisplayed, clientToCreate])

  const regexSirenFormat = new RegExp(`^(?:[0-9]{9}|[0-9]{14})$`)

  const schema = yup.object({
    entity: yup.string(),
    clientName: yup
      .string()
      .matches(RegExp(/^\s*|\s*$|\s*(\r?\n)\s*|(\s)\s+/g), "format")
      .required(),
    address: yup.string().required(),
    city: yup.string().required(),
    postalCode: yup.string().required(),
    phone: yup.string().nullable(),
    emails: optionalMultipleEmailsValidation,

    siren: yup.string().when("entity", {
      is: (entity: string) => entity === "company",
      then: yup
        .string()
        .required()
        .transform(cleanSIREN)
        .min(9, "length")
        .max(14, "length")
        .matches(regexSirenFormat, "format"),
      otherwise: yup.string().nullable(),
    }),
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    reValidateMode: "onChange",
  })

  const siren = watch("siren")
  const clientName = watch("clientName")
  const address: string = watch("address")
  const city = watch("city")
  const postalCode = watch("postalCode")
  const phone = watch("phone")
  const emails: string = watch("emails")

  const isValidated = sirenCheck === "VALIDATED"
  const isLoading = sirenCheck === "CHECKING"
  const isInvalid = sirenCheck === "INVALID"
  const isHiddenSiren = sirenState === "hidden"

  // RESET VALUES IF MODAL CLOSED
  useEffect(() => {
    if (!isDisplayed) {
      resetForm()
    }
  }, [isDisplayed])

  const resetForm = () => {
    reset({
      siren: undefined,
      clientName: undefined,
      address: undefined,
      city: undefined,
      postalCode: undefined,
      phone: undefined,
      email: undefined,
    })

    setValue("siren", "")
    setValue("clientName", "")
    setValue("address", "")
    setValue("city", "")
    setValue("postalCode", "")
    setValue("phone", "")
    setValue("emails", "")

    dispatch(invoicingSirenResetStatus())
    setExistingClient(undefined)
  }

  // GET EXISTING LEGAL ENTITY FROM SIREN CHECKER
  useEffect(() => {
    if (
      isDisplayed &&
      clientNameFromSiren &&
      selectedCompanyId &&
      siren &&
      sirenCheck === "VALIDATED"
    ) {
      setValue("clientName", clientNameFromSiren)
      if (createOrModify === "create") {
        setValue("address", adressFromSiren)
        setValue("postalCode", postalCodeFromSiren)
        setValue("city", cityFromSiren)
      }
      if (trimNumber(siren) !== trimNumber(Number(sirenFromSirenCheck))) {
        return
      }
      dispatch(
        getLegalEntityFromSirenThunk(
          selectedCompanyId,
          trimNumber(siren),
          clientNameFromSiren
        )
      )
    }
  }, [
    setValue,
    clientNameFromSiren,
    selectedCompanyId,
    siren,
    dispatch,
    isDisplayed,
    createOrModify,
    adressFromSiren,
    postalCodeFromSiren,
    cityFromSiren,
  ])

  const existingCompany = legalEntities.find(
    (entity) =>
      siren &&
      String(entity.identification_number)?.replace(" ", "") ===
        siren?.replace(" ", "") &&
      entity.identification_number !== null
  )
  const existingPrivatePerson = legalEntities.find(
    (entity) =>
      !siren &&
      entity.name.toLowerCase() === clientName?.toString().toLowerCase() &&
      entity.identification_number === null
  )
  const existingSiren = siren === currentSiren

  useEffect(() => {
    if (
      !selectedClient &&
      (clientName !== undefined || sirenCheck === "VALIDATED")
    ) {
      setExistingClient(
        existingCompany || existingPrivatePerson || existingClient
      )
    }
  }, [setExistingClient, clientName, legalEntities, selectedClient, sirenCheck])

  useEffect(() => {
    if (selectedClient) {
      setExistingClient(selectedClient)
    }
  }, [selectedClient, setExistingClient])

  // SIREN CHECKER
  useEffect(() => {
    if (!isDisplayed || !siren || !validateSIREN(siren)) {
      return
    }
    if (clientNameFromSiren && sirenFromSirenCheck) {
      dispatch(invoicingSirenResetStatus())
    }
    if (createOrModify === "create") {
      debounce(dispatch(InvoicingSirenValidationThunk(siren?.trim())), 800)
    }
  }, [siren, dispatch, isDisplayed])

  // PRE-SET VALUES
  useEffect(() => {
    if (clientToCreate) {
      return
    }
    setSelectedTypology(existingClient ? existingClient.typology : "company")
    setValue("entity", existingClient ? existingClient.typology : "company")

    if (isDisplayed && existingClient) {
      setValue("siren", existingClient?.identification_number)
      setValue("clientName", capitalizeFirstLetter(existingClient.name))
      setValue("address", existingClient.adress)
      setValue("city", existingClient.city)
      setValue("postalCode", existingClient.postal_code)
      setValue("phone", existingClient.phone)
      const emailsString = existingClient.emails?.join(",") || ""
      setValue("emails", emailsString)
    }
  }, [setValue, existingClient, isDisplayed, selectedClient])

  useEffect(() => {
    if (clientName) {
      setValue("clientName", clientName.replace(/  +/, " ").trimStart())
    }
  }, [clientName])

  const isFormInvalid =
    !clientName ||
    !address ||
    !city ||
    !postalCode ||
    (selectedTypology === "company"
      ? !siren || sirenCheck === "INVALID"
      : false)

  const showCompany = clientToCreate
    ? clientToCreate?.identification_number
    : createOrModify === "create" ||
      (existingClient?.identification_number !== null &&
        createOrModify === "modify")

  const showPrivate = clientToCreate
    ? !clientToCreate?.identification_number
    : createOrModify === "create" ||
      !existingClient ||
      (existingClient?.identification_number === null &&
        createOrModify === "modify")

  const blockClientNameEdition =
    createOrModify === "modify" ||
    selectedTypology === "company" ||
    clientToCreate !== null

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top="2rem"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={close} />
        <Ct.Spacer height={3} />
        <Ct.Title
          text={intl.formatMessage({
            id: `invoicing.settings.${createOrModify}-client.title`,
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <StyledClientForm>
          <EntityPicker>
            {showCompany && (
              <CircledCompany
                onClick={() => {
                  if (existingClient) {
                    return
                  }
                  resetForm()
                  setSelectedTypology("company")
                  setValue("entity", "company")
                }}
                selectedTypology={selectedTypology}
                data-cy={"company"}
                disabled={existingClient !== undefined}
              >
                {selectedTypology === "company" ? (
                  <CompanySolidLogo />
                ) : (
                  <CompanyLogo />
                )}

                <Ct.Text
                  text={`${typologies.company}`}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: selectedTypology === "company" ? 2 : 1.5,
                    fontFamily: "Poppins",
                    cursor:
                      existingClient !== undefined ? "default" : "pointer",
                    color:
                      selectedTypology === "company" ? "white" : "cornflower",
                  }}
                />
              </CircledCompany>
            )}

            {showPrivate && (
              <CircledPrivate
                onClick={() => {
                  if (existingClient) {
                    return
                  }
                  resetForm()
                  setSelectedTypology("private_person")
                  setValue("entity", "private_person")
                }}
                selectedTypology={selectedTypology}
                data-cy={"private_person"}
                disabled={existingClient !== undefined}
              >
                {selectedTypology === "private_person" ? (
                  <PrivatePersonSolidLogo />
                ) : (
                  <PrivatePersonLogo />
                )}
                <Ct.Text
                  text={`${typologies.private_person}`}
                  textStyle={{
                    fontWeight: 600,
                    fontSize: selectedTypology === "private_person" ? 2 : 1.5,
                    fontFamily: "Poppins",
                    color:
                      selectedTypology === "private_person"
                        ? "white"
                        : "cornflower",
                    cursor:
                      existingClient !== undefined ? "default" : "pointer",
                  }}
                />
              </CircledPrivate>
            )}
          </EntityPicker>

          <Ct.Spacer height={6} />

          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            {selectedTypology === "company" && (
              <>
                {createOrModify === "create" && !clientToCreate ? (
                  <SirenInput
                    sirenRegistered={false}
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    siren={siren}
                    errors={errors}
                    isInvalid={isInvalid}
                    sirenState={sirenState}
                    isValidated={isValidated}
                    isLoading={isLoading}
                    isHiddenSiren={isHiddenSiren}
                    existingCompany={
                      existingCompany !== undefined && !selectedClient
                    }
                    existingSiren={existingSiren}
                    sirenType={"company"}
                    disabled={
                      existingClient?.identification_number !== undefined
                    }
                    currentCompany={currentCompany}
                  />
                ) : (
                  <Ct.LockableInput
                    name="sirenView"
                    value={siren}
                    locked={true}
                    label={intl.formatMessage({
                      id: "fiduciary-register.siren",
                    })}
                  />
                )}

                <Ct.Spacer height={4} />
              </>
            )}

            <InputName>
              <Ct.Input
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.label.name",
                })}
                value={capitalizeFirstLetter(clientName)}
                name={"clientName"}
                register={register as unknown as UseFormRegister<FieldValues>}
                suffix={blockClientNameEdition ? <StyledLock /> : <></>}
                readOnly={blockClientNameEdition}
                showError={
                  !selectedClient && existingPrivatePerson !== undefined
                }
              />
              {!selectedClient && existingPrivatePerson !== undefined && (
                <StyledMessage
                  text={intl.formatMessage({
                    id: "invoicing.settings.create-client.know-private_person",
                  })}
                  textStyle={{ color: "amaranth", fontSize: 1.5 }}
                />
              )}
            </InputName>

            <Ct.Spacer height={4} />
            <Ct.StyledDuoInput>
              <Ct.Input
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.label.address",
                })}
                value={address}
                name={"address"}
                register={register as unknown as UseFormRegister<FieldValues>}
              />

              <Ct.Spacer height={4} />
              <Ct.Input
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.label.city",
                })}
                value={city}
                name={"city"}
                register={register as unknown as UseFormRegister<FieldValues>}
              />
            </Ct.StyledDuoInput>

            <Ct.Spacer height={4} />

            <Ct.StyledDuoInput>
              <Ct.Input
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.label.postalCode",
                })}
                value={postalCode}
                name={"postalCode"}
                register={register as unknown as UseFormRegister<FieldValues>}
              />
              <Ct.Spacer width={2} />
              <Ct.Input
                label={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.label.phone",
                })}
                value={phone}
                onChange={(e) => {
                  setValue("phone", e.target.value)
                }}
                name={"phone"}
              />
            </Ct.StyledDuoInput>

            <Ct.Spacer height={4} />

            <Input
              AutoGrowing={true}
              required={false}
              height="12rem"
              label={intl.formatMessage({
                id: "invoicing.settings.create-client.form.label.emails",
              })}
              value={emails || ""}
              name={"emails"}
              register={register as unknown as UseFormRegister<FieldValues>}
              showError={errors[`emails`]?.message.includes(
                "Toutes les valeurs ne sont pas des emails"
              )}
            />
            <Ct.Spacer height={1} />
            <Explanation>
              <StyledInfo />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "invoicing.settings.create-client.form.email.explanation",
                })}
                textStyle={{
                  fontSize: 1.5,
                }}
              />
            </Explanation>

            <Ct.Spacer height={2} />
            <InputNumber
              label={intl.formatMessage({
                id: "invoicing.issuance.total-discount",
              })}
              value={totalDiscount}
              onChange={(e) => {
                if (!e.target.value || parseFloat(e.target.value) < 0) {
                  setTotalDiscount(0)
                } else if (parseFloat(e.target.value) > 99) {
                  setTotalDiscount(99)
                } else {
                  setTotalDiscount(Math.round(e.target.valueAsNumber))
                }
              }}
              max={99}
              min={0}
              step={1}
              suffix={"%"}
            />

            <Ct.Spacer height={4} />

            {!selectedClient &&
              (existingCompany !== undefined ||
                existingPrivatePerson !== undefined ||
                existingSiren) && (
                <Ct.Button
                  label={intl.formatMessage({
                    id: `invoicing.settings.create-client.form.reset`,
                  })}
                  width={sizes.button.standard}
                  onClick={resetForm}
                />
              )}

            {(selectedClient ||
              (existingCompany === undefined &&
                existingPrivatePerson === undefined &&
                !existingSiren)) && (
              <Ct.Button
                label={intl.formatMessage({
                  id:
                    createOrModify === "create"
                      ? `invoicing.settings.create-client.form.create.cta`
                      : `invoicing.settings.create-client.form.modify.cta`,
                })}
                disabled={
                  Object.values(errors).length > 0 ||
                  isFormInvalid ||
                  (!selectedClient &&
                    (existingCompany !== undefined ||
                      existingPrivatePerson !== undefined ||
                      existingSiren)) ||
                  (existingClient
                    ? clientName?.toString().toLowerCase() ===
                        existingClient.name.toLowerCase() &&
                      emails === (existingClient.emails || []).join(",") &&
                      address === existingClient.adress &&
                      city === existingClient.city &&
                      phone === existingClient.phone &&
                      postalCode === existingClient.postal_code &&
                      totalDiscount === existingClient.total_discount
                    : false)
                }
                type="submit"
                width={sizes.button.standard}
                id={"submitClient"}
              />
            )}
          </Form>
        </StyledClientForm>
      </Ct.Card>
    </Ct.Modal>
  )
}

const StyledClientForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const Explanation = styled.div`
  display: flex;
  align-items: center;
`

const EntityPicker = styled.div`
  width: 60rem;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 15rem;
`

const Form = styled((props) => <Ct.StyledForm {...props} />)`
  width: 62rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const PrivatePersonLogo = styled(Private)`
  width: 3rem;
  height: 3rem;
`

const PrivatePersonSolidLogo = styled(PrivateSolid)`
  width: 4rem;
  height: 4rem;
`

const CompanyLogo = styled(Company)`
  width: 3rem;
  height: 3rem;
`

const CompanySolidLogo = styled(CompanySolid)`
  width: 4rem;
  height: 4rem;
`

interface EntityProps {
  selectedTypology: string
  disabled?: boolean
}

const CircledEntity = styled.div<EntityProps>`
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default " : "pointer")};
`

const CircledCompany = styled(CircledEntity)<EntityProps>`
  width: ${({ selectedTypology }) =>
    selectedTypology === "company" ? "15rem " : "12rem"};
  height: ${({ selectedTypology }) =>
    selectedTypology === "company" ? "15rem " : "12rem"};
  background-color: ${({ selectedTypology }) =>
    selectedTypology === "company" ? colors.cornflower : colors.mist};

  & path {
    fill: ${({ selectedTypology }) =>
      selectedTypology === "company" ? colors.white : colors.cornflower};
  }

  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`

const CircledPrivate = styled(CircledEntity)<EntityProps>`
  width: ${({ selectedTypology }) =>
    selectedTypology === "private_person" ? "15rem " : "12rem"};
  height: ${({ selectedTypology }) =>
    selectedTypology === "private_person" ? "15rem " : "12rem"};
  background-color: ${({ selectedTypology }) =>
    selectedTypology === "private_person" ? colors.cornflower : colors.mist};

  & path {
    fill: ${({ selectedTypology }) =>
      selectedTypology === "private_person" ? colors.white : colors.cornflower};
  }

  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
`

const StyledMessage = styled(Ct.Text)`
  width: 100%;
  padding: 0.5rem 1.75rem 0 1.75rem;
  box-sizing: border-box;
`

const InputName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledLock = styled(Lock)`
  fill: ${colors.cornflower};
`
const StyledInfo = styled(Info)`
  fill: ${colors.cornflower};
`
