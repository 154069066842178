import { Numbering, NumberingOrder } from "../store/ducks/numberings.ducks"
import { formatDateNumbering } from "./date"

export interface NumberingPayload {
  numberingOrder: NumberingOrder | null
  numberingSeparator: string
  numberingDateFormat: string
  numberingRoot: string
  nextInvoiceNumber: string
  numberingLength: string
}

export interface ValidNumberingPayload extends NumberingPayload {
  preview: string
  numberingMaxLength: number | null
}

export const isValidNumberingPayload = (
  payload: ValidNumberingPayload
): boolean => {
  if (!payload.numberingOrder) {
    return false
  }

  if (
    payload.numberingMaxLength &&
    payload.preview.length > payload.numberingMaxLength
  ) {
    return false
  }

  if (payload.numberingOrder && payload.numberingOrder.length === 3) {
    return (
      payload.numberingSeparator.length > 0 &&
      payload.numberingDateFormat.length > 0 &&
      payload.numberingRoot.length > 0 &&
      payload.nextInvoiceNumber.length > 0 &&
      payload.numberingLength.length > 0
    )
  }

  return (
    payload.numberingSeparator.length > 0 &&
    payload.numberingLength.length > 0 &&
    payload.numberingRoot.length > 0 &&
    payload.nextInvoiceNumber.length > 0
  )
}

export function disableDateInput(numberingOrderValue: NumberingOrder | null) {
  return !numberingOrderValue || ["nr", "rn"].includes(numberingOrderValue)
}

export function sortNumberingsAlphabetically(
  numberings: Numbering[],
  asc?: boolean
) {
  const sortedNumberings = numberings.sort((a, b) =>
    a?.numbering.localeCompare(b?.numbering)
  )
  return asc ? sortedNumberings : sortedNumberings.reverse()
}

export function sortNumberingsByDate(numberings: Numbering[], asc: boolean) {
  const sortedNumberings = numberings.sort(
    (a, b) => +new Date(a.created_at) - +new Date(b.created_at)
  )

  return asc ? sortedNumberings : sortedNumberings.reverse()
}

export function sortNumberingsByEmail(numberings: Numbering[], asc: boolean) {
  return numberings.sort((a, b) =>
    asc
      ? a.user_email.localeCompare(b.user_email)
      : b.user_email.localeCompare(a.user_email)
  )
}

export const generateNumberingPreview = (
  payload: NumberingPayload,
  date: Date
): string => {
  const formattedDate = formatDateNumbering(payload.numberingDateFormat, date)
  const separator = formatSeparator(payload.numberingSeparator)
  const number = formatNumber({
    nextInvoiceNumber: payload.nextInvoiceNumber,
    numberingLength: payload.numberingLength,
  })
  const root = payload.numberingRoot

  switch (payload.numberingOrder) {
    case "dnr":
      return `${formattedDate}${separator}${number}${separator}${root}`
    case "drn":
      return `${formattedDate}${separator}${root}${separator}${number}`
    case "ndr":
      return `${number}${separator}${formattedDate}${separator}${root}`
    case "nr":
      return `${number}${separator}${root}`
    case "nrd":
      return `${number}${separator}${root}${separator}${formattedDate}`
    case "rdn":
      return `${root}${separator}${formattedDate}${separator}${number}`
    case "rn":
      return `${root}${separator}${number}`
    case "rnd":
      return `${root}${separator}${number}${separator}${formattedDate}`
    default:
      return `${number}`
  }
}

function formatSeparator(separator: string): string {
  if (separator === "Espace") {
    return " "
  }
  if (separator === "Aucun") {
    return ""
  }
  return separator
}

function formatNumber({
  nextInvoiceNumber,
  numberingLength,
}: {
  nextInvoiceNumber: string
  numberingLength: string
}): string {
  return nextInvoiceNumber.padStart(Number(numberingLength), "0")
}

export const searchNumberings = (numberings: Numbering[], search: string) => {
  const filtered = (numberings || []).filter((numberings) =>
    numberings.numbering?.toLowerCase().includes(search.toLowerCase())
  )
  return filtered
}
