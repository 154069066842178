import { useEffect } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../../../store/rootReducer"
import { loadSoftwareRulesThunk } from "../../../store/ducks/softwareRules.ducks"
import { getIdFromParams } from "../../../utils/company"
import { getStandardLedgersThunk } from "../../../store/ducks/user.ducks"
import { getFiscalYears } from "../../../store/ducks/fiscalYears.ducks"
import { ScrollableOutlet } from "../../../components/Commons/ScrollableOutlet"
import { useSelectedCompany } from "../../../hooks/useSelectedCompany"
import { CheckCreateWithoutFecReset } from "../../../store/ducks/standardLedger.ducks"
/* eslint-disable camelcase */

const Company = () => {
  const params = useParams()
  const companyId = getIdFromParams(params)("company_id") || 0
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const companyStatusForUser = useRNBSelector(
    (state) => state.companies.companyStatusForUser
  )
  const selectedCompany = useSelectedCompany(params, dispatch)

  const FYsByCompanyId = useRNBSelector(
    (state) => state.fiscalYears.fiscalYearsByCompanyId
  )
  const FYsForSelectedCompany = FYsByCompanyId[companyId]
  const fiscalYearsStatus = useRNBSelector(
    (state) => state.fiscalYears.fiscalYearsStatus
  )

  const standardLedgers = useRNBSelector((state) => state.user.standardLedgers)
  const standardLedgersStatus = useRNBSelector(
    (state) => state.user.standardLedgersStatus
  )
  const standardLedgersForCompany = standardLedgers.find(
    (s) => s.companyId === companyId
  )

  useEffect(() => {
    dispatch(CheckCreateWithoutFecReset())
  }, [dispatch, selectedCompany])

  useEffect(() => {
    if (
      fiscalYearsStatus === "SUCCESS" &&
      (standardLedgersStatus === "SUCCESS" || selectedCompany?.no_fec) &&
      FYsForSelectedCompany
    ) {
      if (!standardLedgersForCompany && !selectedCompany?.no_fec) {
        navigate(`/office/company/${companyId}/initialize`)
      } else if (FYsForSelectedCompany && FYsForSelectedCompany.length === 0) {
        navigate(`/office/company/${companyId}/settings/fiscalyears`)
      }
    }
  }, [
    fiscalYearsStatus,
    FYsForSelectedCompany,
    standardLedgers,
    standardLedgersForCompany,
    selectedCompany?.no_fec,
  ])

  useEffect(() => {
    dispatch(getStandardLedgersThunk())
    dispatch(getFiscalYears(companyId))
  }, [companyId, navigate, dispatch])

  const location = useLocation()

  useEffect(() => {
    if (companyId === 0) navigate("/404")
    if (companyId !== 0) dispatch(loadSoftwareRulesThunk(companyId))
    if (companyStatusForUser === "NOT_FOUND") {
      navigate("/404")
    } else if (companyStatusForUser === "FORBIDDEN") {
      navigate("/unauthorized")
    }
  }, [companyId, navigate, companyStatusForUser, dispatch])

  const invoicingPadding =
    window.innerHeight < 960 ? "2rem 2rem 0" : "4rem 2rem 0"

  return (
    <ScrollableOutlet
      id={"company-content"}
      Outlet={Outlet}
      padding={
        location.pathname.includes("invoicing")
          ? invoicingPadding
          : "4rem 4rem 0"
      }
    />
  )
}

export default Company
