import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { Text } from "../Commons/Text"
import { useRNBSelector } from "../../store/rootReducer"
import styled from "styled-components/macro"
import { LockableInput } from "../Commons/LockableInput"
import { Alert } from "../Commons/Alert"
import { Input } from "../Commons/Input"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "../Commons/Button"
import { UpdateAccountingSoftwareThunk } from "../../store/ducks/companies.ducks"
import { normalizeMerchantCode } from "../../utils/merchantCodes"
/* eslint-disable camelcase */

interface AccountsOrCodesTooLongProps extends Ct.ModalComponentsProps {
  newSoftware: { value: string; label: string }
  prefixBuy: string
  prefixSell: string
  reference: string
  companyId: number
  prefixSellToDisplay: string
  prefixBuyToDisplay: string
}

export const AccountsOrCodesTooLongModal = ({
  isDisplayed,
  onClose,
  newSoftware,
  prefixBuy,
  prefixSell,
  reference,
  companyId,
  prefixSellToDisplay,
  prefixBuyToDisplay,
}: AccountsOrCodesTooLongProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    length_rules,
    codes_too_long,
    accounts_too_long,
    all_accounts,
    all_codes,
  } = useRNBSelector(
    (state) => state.companySettings.dataToUpdateAccountingSoftware
  )

  const [accountsEdited, setAccountsEdited] = useState<
    { id: number; value: string }[] | []
  >([])
  const [codesEdited, setCodesEdited] = useState<
    { id: number; value: string }[] | []
  >([])

  useEffect(() => {
    if (accounts_too_long.length > 0) {
      setAccountsEdited(
        accounts_too_long.map((acc) => {
          return { id: acc.id, value: acc.number }
        })
      )
    }
  }, [accounts_too_long, dispatch])

  useEffect(() => {
    if (codes_too_long.length > 0) {
      setCodesEdited(
        codes_too_long.map((code) => {
          return { id: code.id, value: code.code }
        })
      )
    }
  }, [codes_too_long, dispatch])

  const confirmDisabled =
    codesEdited.some(
      (c) => c.value.length > (length_rules.max_code_length || 0)
    ) ||
    accountsEdited.some(
      (a) => a.value.length > (length_rules.max_account_number_length || 0)
    ) ||
    all_accounts.some((a) =>
      accountsEdited.some((acc) => a.number === acc.value && a.id !== acc.id)
    ) ||
    all_codes.some((c) =>
      codesEdited.some((code) => c.code === code.value && c.id !== code.id)
    ) ||
    accountsEdited.some((a) =>
      accountsEdited.some((acc) => a.value === acc.value && a.id !== acc.id)
    ) ||
    codesEdited.some((c) =>
      codesEdited.some((code) => c.value === code.value && c.id !== code.id)
    )

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="10%"
    >
      <Ct.Card width="80vw">
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={2} />
        <Text
          text={intl.formatMessage({
            id: `office-company-change-software.modal.title`,
          })}
          textStyle={{ fontSize: 5, fontWeight: 600 }}
        />
        <Ct.Spacer height={2} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={5} />

        <Wrapper>
          <InputsBlock>
            <LockableInput
              value={newSoftware.label}
              label={intl.formatMessage({
                id: `office-company-change-software.new-software`,
              })}
              locked={true}
            />
            <Ct.Spacer />
            <LockableInput
              value={prefixBuy}
              label={intl.formatMessage({
                id: `office-company-change-software.prefix-buy`,
              })}
              locked={true}
              prefixString={prefixBuyToDisplay}
              padding={`0 1.75rem 0 ${
                prefixBuyToDisplay.length > 0
                  ? prefixBuyToDisplay.length + 3
                  : 2
              }rem`}
            />
            <Ct.Spacer />
            <LockableInput
              value={prefixSell}
              label={intl.formatMessage({
                id: `office-company-change-software.prefix-sell`,
              })}
              locked={true}
              prefixString={prefixSellToDisplay}
              padding={`0 1.75rem 0 ${
                prefixSellToDisplay.length > 0
                  ? prefixSellToDisplay.length + 3
                  : 2
              }rem`}
            />
          </InputsBlock>

          <Ct.Spacer height={3} />

          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "office-company-change-software.modal.alert",
              })}
              textStyle={{ lineHeight: 3 }}
            />
          </Alert>

          <Ct.Spacer height={3} />

          <WrapperTooLong>
            {accountsEdited &&
              accountsEdited?.length > 0 &&
              all_accounts?.length > 0 &&
              length_rules.max_account_number_length && (
                <CardTooLong>
                  <Ct.Spacer height={2} />
                  <Text
                    text={intl.formatMessage({
                      id: "office-company-change-software.modal.accounts-title",
                    })}
                    textStyle={{
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: 1.75,
                      fontFamily: "Poppins",
                    }}
                  />
                  <Ct.Spacer height={2} />
                  <Text
                    text={intl.formatMessage(
                      {
                        id: "office-company-change-software.modal.length-max",
                      },
                      {
                        limit: length_rules.max_account_number_length,
                        accountOrCode: "compte",
                      }
                    )}
                  />

                  <Ct.Spacer height={2} />

                  <ScrollableDiv>
                    {accountsEdited.map((account, index) => (
                      <Fragment key={account.id}>
                        <StyledInput
                          value={account.value}
                          label={intl.formatMessage({
                            id: "office-company-change-software.modal.account",
                          })}
                          maxWidth={"98%"}
                          onChange={(e) => {
                            const updatedAccounts = [...accountsEdited]
                            updatedAccounts[index] = {
                              ...account,
                              value: e.target.value,
                            }
                            setAccountsEdited(updatedAccounts)
                          }}
                          showError={
                            account.value.length >
                              (length_rules.max_account_number_length || 0) ||
                            account.value === ""
                          }
                          showWarning={
                            all_accounts.some(
                              (a) =>
                                a.number === account.value &&
                                a.id !== account.id
                            ) ||
                            accountsEdited.some(
                              (a) =>
                                a.value === account.value && a.id !== account.id
                            )
                          }
                        />
                        {all_accounts.some(
                          (a) =>
                            a.number === account.value && a.id !== account.id
                        ) ||
                        accountsEdited.some(
                          (a) =>
                            a.value === account.value && a.id !== account.id
                        ) ? (
                          <Text
                            text={intl.formatMessage(
                              {
                                id: "office-company-change-software.modal.warning-already-exists",
                              },
                              { accountOrCode: "compte" }
                            )}
                            textStyle={{
                              color: "orange",
                            }}
                          />
                        ) : (
                          <Ct.Spacer />
                        )}
                        {index !== accountsEdited.length - 1 && (
                          <Ct.Spacer height={2} />
                        )}
                      </Fragment>
                    ))}
                  </ScrollableDiv>
                </CardTooLong>
              )}

            {accounts_too_long.length > 0 && codes_too_long.length > 0 && (
              <Ct.Spacer width={3} />
            )}

            {codesEdited &&
              codesEdited.length > 0 &&
              all_codes?.length > 0 &&
              length_rules.max_code_length && (
                <CardTooLong>
                  <Ct.Spacer height={2} />
                  <Text
                    text={intl.formatMessage({
                      id: "office-company-change-software.modal.codes-title",
                    })}
                    textStyle={{
                      textTransform: "uppercase",
                      fontWeight: 600,
                      fontSize: 1.75,
                      fontFamily: "Poppins",
                    }}
                  />
                  <Ct.Spacer height={2} />
                  <Text
                    text={intl.formatMessage(
                      {
                        id: "office-company-change-software.modal.length-max",
                      },
                      {
                        limit: length_rules.max_code_length,
                        accountOrCode: "code tiers",
                      }
                    )}
                  />

                  <Ct.Spacer height={2} />

                  <ScrollableDiv>
                    {codesEdited.map((code, index) => (
                      <Fragment key={code.id}>
                        <StyledInput
                          value={code.value}
                          label={intl.formatMessage({
                            id: "office-company-change-software.modal.code",
                          })}
                          onChange={(e) => {
                            const updatedCodes = [...codesEdited]
                            updatedCodes[index] = {
                              ...code,
                              value: normalizeMerchantCode(e.target.value),
                            }
                            setCodesEdited(updatedCodes)
                          }}
                          showError={
                            code.value.length >
                              (length_rules.max_code_length || 0) ||
                            code.value === ""
                          }
                          showWarning={
                            all_codes.some(
                              (c) => c.code === code.value && c.id !== code.id
                            ) ||
                            codesEdited.some(
                              (c) => c.value === code.value && c.id !== code.id
                            )
                          }
                          maxWidth={"98%"}
                        />
                        {all_codes.some(
                          (c) => c.code === code.value && c.id !== code.id
                        ) ||
                        codesEdited.some(
                          (c) => c.value === code.value && c.id !== code.id
                        ) ? (
                          <Text
                            text={intl.formatMessage(
                              {
                                id: "office-company-change-software.modal.warning-already-exists",
                              },
                              { accountOrCode: "code" }
                            )}
                            textStyle={{
                              color: "orange",
                            }}
                          />
                        ) : (
                          <Ct.Spacer height={2} />
                        )}
                        {index !== codesEdited.length - 1 && (
                          <Ct.Spacer height={2} />
                        )}
                      </Fragment>
                    ))}
                  </ScrollableDiv>
                </CardTooLong>
              )}
          </WrapperTooLong>

          <Ct.Spacer height={5} />

          <WrapperButtons>
            <Button
              label={intl.formatMessage({
                id: "office-company-change-software.modal.cancel",
              })}
              width={45}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              onClick={onClose}
            />
            <Ct.Spacer />

            {(length_rules.max_code_length ||
              length_rules.max_account_number_length) && (
              <Button
                label={intl.formatMessage({
                  id: "office-company-change-software.modal.confirm",
                })}
                width={45}
                disabled={confirmDisabled}
                onClick={() => {
                  dispatch(
                    UpdateAccountingSoftwareThunk(
                      companyId,
                      newSoftware.value,
                      prefixBuy,
                      prefixSell,
                      reference,
                      codesEdited,
                      accountsEdited
                    )
                  )
                  onClose()
                }}
              />
            )}
          </WrapperButtons>
        </Wrapper>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Wrapper = styled.div`
  width: 80%;
`

const InputsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

const WrapperTooLong = styled.div`
  display: flex;
  justify-content: center;
`

const CardTooLong = styled(Ct.Card)`
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1.75rem rgba(2, 76, 248, 0.2);
  height: 40vh;
  justify-content: flex-start;
  overflow-y: auto;
`

const ScrollableDiv = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 2rem 0;
`

const StyledInput = styled(Input)`
  width: 98%;
`

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`
