import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as AddUsers } from "../../../../../assets/add-users.svg"
import { ReactComponent as EyeFilled } from "../../../../../assets/eyeFilled.svg"
import { useIntl } from "react-intl"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../../components/Commons/Table"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import {
  changeValidationAndPaymentLimitForUser,
  CurrentUserPermission,
  getCurrentUserPermissionsThunk,
  getPaymentLimitsThunk,
  getTreasuryUserHistoryThunk,
  grantOrRevokeTreasuryAccessThunk,
  invitUserToDashboardReset,
  invitUserToDashboardThunk,
  UsersWithPermissions,
} from "../../../../../store/ducks/treasury.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { Select } from "../../../../../components/Commons/Select"
import {
  HeadBand,
  PaymentOptionType,
  StyledSection,
  TableWrapper,
  Wrapper,
  WrapperTopBar,
  filterUsers,
} from "../../../../../utils/treasury"
import { DashboardInvitationUsersModal } from "../../../../../components/treasury/DashboardInvitationUsersModal"
import { UserTypes } from "../../../../../model/users"
import { Switch } from "../../../../../components/Commons/Switch"
import { Button } from "../../../../../components/Commons/Button"
import { UserTreasurSettingsyHistoryModal } from "../../../../../components/treasury/UserTreasurySettingsHistoryModal"
import { Input } from "../../../../../components/Commons/Input"

/* eslint-disable camelcase */

export const TreasuryBuySettings = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const [search, setSearch] = useState("")
  const users = useRNBSelector((state) => {
    return state.treasury.usersWithPermission
  })
  const paymentLimits = useRNBSelector((state) => state.treasury.payment_limits)
  const [usersToDisplay, setusersToDisplay] =
    useState<UsersWithPermissions[]>(users)
  const [invitationUsersModalDisplayed, setInvitationUsersModalDisplayed] =
    useState<boolean>(false)
  const errorCode = useRNBSelector((state) => state.treasury.invit_user_error)

  const rowBackgroundColors = usersToDisplay.map((_, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight || !text) {
      return text
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase().includes(highlight.toLowerCase()) ||
              highlight.toLowerCase().includes(part.toLowerCase())
                ? {
                    background: "rgba(255, 197, 66, 0.3)",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </>
    )
  }
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const currentUserId = useRNBSelector((state) => state.user.id)
  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )
  const navigate = useNavigate()
  const getCurrentUserPermissionsStatus = useRNBSelector(
    (state) => state.treasury.currentUserPermissionStatus
  )
  const isAuthorizedToGiveAccess = (
    userId: number,
    userTypology: UserTypes,
    permission: keyof CurrentUserPermission,
    currentPermissionValue: boolean | string
  ) => {
    if (userId === currentUserId) return false
    if (userTypology === "customer" || userTypology === "customer_accountant")
      return false
    if (
      currentUserTypology === "customer" ||
      currentUserTypology === "customer_accountant"
    )
      return true
    if (
      permission === "validation_payment_limit_value" &&
      currentUserPermissions.validation_payment_limit_value !== "none"
    )
      return true
    if (
      permission === "payment_limit_value" &&
      currentUserPermissions.payment_limit_value !== "none"
    )
      return true
    if (
      currentUserPermissions[permission] === true &&
      !currentPermissionValue
    ) {
      return true
    }
    return false
  }
  const paymentOptionFiltered = (
    limit: string,
    permission: keyof CurrentUserPermission
  ): PaymentOptionType[] =>
    paymentOptions.filter((opt) => {
      if (
        currentUserTypology === "customer" ||
        currentUserTypology === "customer_accountant"
      )
        return true
      if (currentUserPermissions[permission] === "all") {
        if (limit === "all") return false
        if (limit === "none" || opt.value === "all") return true
        if (Number(opt.value) >= Number(limit)) return true
        return false
      }
      if (opt.value === "all") return false
      if (
        limit === "none" &&
        Number(currentUserPermissions[permission]) >= Number(opt.value)
      )
        return true
      if (
        Number(opt.value) <= Number(currentUserPermissions[permission]) &&
        Number(opt.value) > Number(limit)
      )
        return true
      return false
    })
  const [userSelected, setUserSelected] = useState<UsersWithPermissions | null>(
    null
  )
  const userHistory = useRNBSelector((state) => state.treasury.user_history)
  const [displayHistory, setDisplayHistory] = useState(false)
  const historyAction = (user: UsersWithPermissions) => {
    dispatch(getTreasuryUserHistoryThunk(user.user_id))
    setUserSelected(user)
    setDisplayHistory(true)
  }
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionType[]>([])
  const usersWithoutCustomer = users.filter(
    (user) =>
      user.user_typology !== "customer" &&
      user.user_typology !== "customer_accountant"
  )
  const fiduciary = useRNBSelector((state) => state.fiduciary)

  useEffect(() => {
    if (fiduciary.status === "UNREGISTERED") return
    if (
      fiduciary.status === "SUCCESS" &&
      (fiduciary.active_pricing?.pricing_type !== "per_document" ||
        (fiduciary.active_pricing?.pricing_type === "per_document" &&
          fiduciary.override_pricing))
    )
      return
    navigate("/unauthorized")
  }, [fiduciary])

  useEffect(() => {
    dispatch(getCurrentUserPermissionsThunk(companyId))
    dispatch(getPaymentLimitsThunk())
  }, [])

  useEffect(() => {
    if (
      getCurrentUserPermissionsStatus === "idle" ||
      getCurrentUserPermissionsStatus === "loading"
    )
      return
    if (
      getCurrentUserPermissionsStatus === "success" &&
      (currentUserPermissions.marked_as_appearing_in_permissions_list ===
        true ||
        currentUserTypology === "customer" ||
        currentUserTypology === "customer_accountant")
    )
      return
    navigate("/unauthorized")
  }, [
    getCurrentUserPermissionsStatus,
    currentUserPermissions,
    currentUserTypology,
  ])

  useEffect(() => {
    if (search && search !== "" && users) {
      const filteredMerchants: UsersWithPermissions[] =
        filterUsers(search, users) || []
      setusersToDisplay(filteredMerchants)
      setHighlightValues({ highlight: true, searchString: search })
    } else {
      setusersToDisplay(users)
      setHighlightValues({ highlight: false, searchString: "" })
    }
  }, [search, users])

  useEffect(() => {
    if (errorCode === null) {
      setInvitationUsersModalDisplayed(false)
    }
  }, [errorCode])

  useEffect(() => {
    if (paymentLimits) {
      setPaymentOptions(
        paymentLimits.map((current) => {
          return {
            label:
              current === "none"
                ? "Aucun"
                : current === "all"
                ? "Tous"
                : !isNaN(+current)
                ? `Jusqu'à ${current} €`
                : "Undefined",
            value: current,
          } as PaymentOptionType
        })
      )
    } else setPaymentOptions([])
  }, [paymentLimits])

  const columns = [
    {
      headerText: intl.formatMessage({ id: "treasury.buy-settings.users" }),
      footerText: intl.formatMessage({
        id: "treasury.buy-settings.footer.all",
      }),
      content: (user: UsersWithPermissions) => (
        <>{getHighlightedText(user.email, highlightValues.searchString)}</>
      ),
    },
    {
      headerText: "treasury.buy-settings.payment_validation",
      content: (user: UsersWithPermissions) => (
        <Select
          intl={intl}
          value={
            user.user_typology === "customer" ||
            user.user_typology === "customer_accountant"
              ? { label: "Tous", value: "all" }
              : paymentOptions.find(
                  (curr) => user.validation_payment_limit_value === curr.value
                ) || paymentOptions[0]
          }
          options={paymentOptionFiltered(
            user.validation_payment_limit_value,
            "validation_payment_limit_value"
          )}
          customWidth="85%"
          onChangeCallback={(selectedOption) => {
            dispatch(
              changeValidationAndPaymentLimitForUser(
                [user.id],
                selectedOption.value,
                "validation_payment_limit",
                companyId
              )
            )
          }}
          disabled={
            !isAuthorizedToGiveAccess(
              user.id,
              user.user_typology,
              "validation_payment_limit_value",
              user.validation_payment_limit_value
            )
          }
        />
      ),
      footerIcon: (
        <Select
          intl={intl}
          value={
            (usersWithoutCustomer.length &&
            usersWithoutCustomer.every(
              (user) =>
                user.validation_payment_limit_value ===
                usersWithoutCustomer[0].validation_payment_limit_value
            )
              ? paymentOptions.find(
                  (option) =>
                    option.value ===
                    usersWithoutCustomer[0].validation_payment_limit_value
                )
              : paymentOptions[0]) || paymentOptions[0]
          }
          options={paymentOptions}
          customWidth="85%"
          onChangeCallback={(selectedOption) => {
            dispatch(
              changeValidationAndPaymentLimitForUser(
                usersWithoutCustomer.map((permission) => permission.id),
                selectedOption.value,
                "validation_payment_limit",
                companyId
              )
            )
          }}
          disabled={
            currentUserTypology !== "customer" &&
            currentUserTypology !== "customer_accountant"
          }
        />
      ),
    },
    {
      headerText: "treasury.buy-settings.pay",
      content: (user: UsersWithPermissions) => (
        <Select
          intl={intl}
          value={
            user.user_typology === "customer" ||
            user.user_typology === "customer_accountant"
              ? { label: "Tous", value: "all" }
              : paymentOptions.find(
                  (curr) => user.payment_limit_value === curr.value
                ) || paymentOptions[0]
          }
          options={paymentOptionFiltered(
            user.payment_limit_value,
            "payment_limit_value"
          )}
          customWidth="85%"
          onChangeCallback={(selectedOption) => {
            dispatch(
              changeValidationAndPaymentLimitForUser(
                [user.id],
                selectedOption.value,
                "payment_limit",
                companyId
              )
            )
          }}
          disabled={
            !isAuthorizedToGiveAccess(
              user.id,
              user.user_typology,
              "payment_limit_value",
              user.payment_limit_value
            )
          }
        />
      ),
      footerIcon: (
        <Select
          intl={intl}
          value={
            (usersWithoutCustomer.length &&
            usersWithoutCustomer.every(
              (user) =>
                user.payment_limit_value ===
                usersWithoutCustomer[0].payment_limit_value
            )
              ? paymentOptions.find(
                  (option) =>
                    option.value === usersWithoutCustomer[0].payment_limit_value
                )
              : paymentOptions[0]) || paymentOptions[0]
          }
          options={paymentOptions}
          customWidth="85%"
          onChangeCallback={(selectedOption) => {
            dispatch(
              changeValidationAndPaymentLimitForUser(
                usersWithoutCustomer.map((permission) => permission.id),
                selectedOption.value,
                "payment_limit",
                companyId
              )
            )
          }}
          disabled={
            currentUserTypology !== "customer" &&
            currentUserTypology !== "customer_accountant"
          }
        />
      ),
    },
    {
      headerText: "treasury.buy-settings.mark_as_paid",
      content: (user: UsersWithPermissions) => (
        <Switch
          value={
            user.user_typology === "customer" ||
            user.user_typology === "customer_accountant"
              ? true
              : user.can_mark_as_paid
          }
          onToggle={() => {
            const type = user.can_mark_as_paid === true ? "revoke" : "grant"
            dispatch(
              grantOrRevokeTreasuryAccessThunk(
                [user.id],
                type,
                "can_mark_as_paid",
                companyId
              )
            )
          }}
          locked={
            !isAuthorizedToGiveAccess(
              user.id,
              user.user_typology,
              "can_mark_as_paid",
              user.can_mark_as_paid
            )
          }
        />
      ),
      footerIcon: (
        <Switch
          value={usersWithoutCustomer.every(
            (user) => user.can_mark_as_paid === true
          )}
          onToggle={() => {
            const type = usersWithoutCustomer.every(
              (user) => user.can_mark_as_paid === true
            )
              ? "revoke"
              : "grant"
            dispatch(
              grantOrRevokeTreasuryAccessThunk(
                usersWithoutCustomer.map((permission) => permission.id),
                type,
                "can_mark_as_paid",
                companyId
              )
            )
          }}
          disabled={
            currentUserTypology !== "customer" &&
            currentUserTypology !== "customer_accountant"
          }
        />
      ),
    },
    {
      headerText: "treasury.buy-settings.reject_payment",
      content: (user: UsersWithPermissions) => (
        <Switch
          value={
            user.user_typology === "customer" ||
            user.user_typology === "customer_accountant"
              ? true
              : user.can_reject_payment
          }
          onToggle={() => {
            const type = user.can_reject_payment === true ? "revoke" : "grant"
            dispatch(
              grantOrRevokeTreasuryAccessThunk(
                [user.id],
                type,
                "can_reject_payment",
                companyId
              )
            )
          }}
          locked={
            !isAuthorizedToGiveAccess(
              user.id,
              user.user_typology,
              "can_reject_payment",
              user.can_reject_payment
            )
          }
        />
      ),
      footerIcon: (
        <Switch
          value={usersWithoutCustomer.every(
            (user) => user.can_reject_payment === true
          )}
          onToggle={() => {
            const type = usersWithoutCustomer.every(
              (user) => user.can_reject_payment === true
            )
              ? "revoke"
              : "grant"
            dispatch(
              grantOrRevokeTreasuryAccessThunk(
                usersWithoutCustomer.map((permission) => permission.id),
                type,
                "can_reject_payment",
                companyId
              )
            )
          }}
          disabled={
            currentUserTypology !== "customer" ||
            currentUserTypology !== "customer"
          }
        />
      ),
    },
    {
      headerText: "treasury.buy-settings.historic",
      flexGrow: "10rem",
      content: (user: UsersWithPermissions) => (
        <EyeFilled
          cursor={"pointer"}
          onClick={() => {
            historyAction(user)
          }}
        />
      ),
    },
  ]

  enum SortOptionsValues {
    "users",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.users) {
      const usersSorted = usersToDisplay.sort(
        (a: UsersWithPermissions, b: UsersWithPermissions) =>
          asc
            ? a?.email.localeCompare(b?.email)
            : b?.email.localeCompare(a?.email)
      )
      setusersToDisplay(usersSorted)
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  return (
    <Wrapper>
      <StyledSection>
        <Ct.Spacer height={1} />
        <HeadBand>
          <Ct.Text text={"🏷️"} />
          <Ct.Spacer width={1} />
          <Ct.Text
            text={intl.formatMessage({
              id: "treasury.try_access.headband",
            })}
            textStyle={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 2,
              fontStyle: "italic",
              color: "white",
            }}
          />
        </HeadBand>
        <Ct.Spacer height={4} />

        <WrapperTopBar>
          <Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={"30rem"}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            dataCy={"searchUser"}
            shadowed={true}
            noBorder={true}
            isSearch={true}
          />

          <Ct.FlexEnd>
            <Button
              textTransform={"uppercase"}
              prefix={<AddUsers />}
              label={intl.formatMessage({
                id: "treasury.buy-settings.invit_user",
              })}
              type="submit"
              width={28.5}
              height={4.5}
              disabled={
                currentUserTypology !== "customer" &&
                !currentUserPermissions.marked_as_appearing_in_permissions_list
              }
              onClick={() => setInvitationUsersModalDisplayed(true)}
              colorType="Tertiary"
              colorScheme={{
                background: "cornflower",
                color: "white",
                border: "cornflower",
              }}
            />
            <Ct.Spacer width={4} />
          </Ct.FlexEnd>
        </WrapperTopBar>

        <UserTreasurSettingsyHistoryModal
          userEmail={userSelected?.email || ""}
          userHistory={userHistory}
          isDisplayed={displayHistory ? true : false}
          onClose={() => setDisplayHistory(false)}
        />

        <DashboardInvitationUsersModal
          isDisplayed={invitationUsersModalDisplayed}
          onClose={() => {
            dispatch(invitUserToDashboardReset())
            setInvitationUsersModalDisplayed(false)
          }}
          onValidateClick={(selectedUser) => {
            dispatch(invitUserToDashboardReset())
            dispatch(invitUserToDashboardThunk(companyId, selectedUser))
          }}
          errorCode={errorCode}
        />
        <Ct.Spacer height={3} />

        <TableWrapper>
          <Table
            intl={intl}
            columns={columns}
            rows={usersToDisplay}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            padding={"0"}
            paddingRows={"1rem 2rem 1rem 4rem"}
            fontWeightTitle={600}
            alertMessage={"treasury.buy-settings.empty.users"}
            sortableColumnsLength={1}
            sortingMainFunction={(columnToSort) => {
              setColumnToSort(columnToSort)
            }}
            currentSortColumn={currentChevron}
            rowBackgroundColors={rowBackgroundColors}
            customScrollBar={true}
            footer={true}
          />
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}
