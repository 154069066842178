import axios, { AxiosError } from "axios"
import { Dispatch } from "react"
import { RNBThunkAction } from "../store.config"
import { UserTypes } from "../../model/users"
import { allTime } from "../../utils/treasury"

export const enum TreasuryActionEnum {
  GET_USERS_WITH_PERMISSIONS_ATTEMPT = "TREASURY/getUsersWithPermissionsAttempt",
  GET_USERS_WITH_PERMISSIONS_SUCCESS = "TREASURY/getUsersWithPermissionsSuccess",
  GET_USERS_WITH_PERMISSIONS_FAILURE = "TREASURY/getUsersWithPermissionsFailure",
  GRANT_OR_REVOKE_ACCESS_ATTEMPT = "TREASURY/grantOrRevokeAccessAttempt",
  GRANT_OR_REVOKE_ACCESS_SUCCESS = "TREASURY/grantOrRevokeAccessSuccess",
  GRANT_OR_REVOKE_ACCESS_FAILURE = "TREASURY/grantOrRevokeAccessFailure",
  CHANGE_VALIDATION_OR_PAYMENT_LIMIT_ATTEMPT = "TREASURY/changeOrValidationPaymentLimitAttempt",
  CHANGE_VALIDATION_OR_PAYMENT_LIMIT_SUCCESS = "TREASURY/changeOrValidationPaymentLimitSuccess",
  CHANGE_VALIDATION_OR_PAYMENT_LIMIT_FAILURE = "TREASURY/changeOrValidationPaymentLimitFailure",
  GET_TREASURY_MERCHANTS_ATTEMPT = "TREASURY/getTreasuryMerchantsAttempt",
  GET_TREASURY_MERCHANTS_SUCCESS = "TREASURY/getTreasuryMerchantsSuccess",
  GET_TREASURY_MERCHANTS_FAILURE = "TREASURY/getTreasuryMerchantsFailure",
  CHANGE_MERCHANT_TREASURY_SETTINGS_ATTEMPT = "TREASURY/changeMerchantTreasurySettingsAttempt",
  CHANGE_MERCHANT_TREASURY_SETTINGS_SUCCESS = "TREASURY/changeMerchantTreasurySettingsSuccess",
  CHANGE_MERCHANT_TREASURY_SETTINGS_FAILURE = "TREASURY/changeMerchantTreasurySettingsFailure",
  GET_BUY_FULL_DOCUMENTS_ATTEMPT = "TREASURY/getTreasuryFullDocumentsAttemptAttempt",
  GET_BUY_FULL_DOCUMENTS_SUCCESS = "TREASURY/getTreasuryFullDocumentsSuccessSuccess",
  GET_BUY_FULL_DOCUMENTS_FAILURE = "TREASURY/getTreasuryFullDocumentsFailureFailure",
  UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_ATTEMPT = "TREASURY/updateFullDocumentPaymentStatusAttempt",
  UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_SUCCESS = "TREASURY/updateFullDocumentPaymentStatusSuccess",
  UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_FAILURE = "TREASURY/updateFullDocumentPaymentStatusFailure",
  ASK_FOR_VALIDATION_ATTEMPT = "TREASURY/askForValidationAttempt",
  ASK_FOR_VALIDATION_SUCCESS = "TREASURY/askForValidationSuccess",
  ASK_FOR_VALIDATION_FAILURE = "TREASURY/askForValidationFailure",
  GET_USERS_AVAILAIBLE_FOR_VALIDATION_ATTEMPT = "TREASURY/getUsersAvailableForValidationAttempt",
  GET_USERS_AVAILAIBLE_FOR_VALIDATION_SUCCESS = "TREASURY/getUsersAvailableForValidationSuccess",
  GET_USERS_AVAILAIBLE_FOR_VALIDATION_FAILURE = "TREASURY/getUsersAvailableForValidationFailure",
  GET_CURRENT_USER_PERMISSIONS_ATTEMPT = "TREASURY/getCurrentUserPermissionsAttempt",
  GET_CURRENT_USER_PERMISSIONS_SUCCESS = "TREASURY/getCurrentUserPermissionsSuccess",
  GET_CURRENT_USER_PERMISSIONS_FAILURE = "TREASURY/getCurrentUserPermissionsFailure",
  GET_CURRENT_USER_PERMISSIONS_RESET = "TREASURY/getCurrentUserPermissionsReset",
  INVIT_USER_TO_DASHBOARD_ATTEMPT = "TREASURY/invitUserToDashboardAttempt",
  INVIT_USER_TO_DASHBOARD_SUCCESS = "TREASURY/invitUserToDashboardSuccess",
  INVIT_USER_TO_DASHBOARD_FAILURE = "TREASURY/invitUserToDashboardFailure",
  INVIT_USER_TO_DASHBOARD_RESET = "TREASURY/invitUserToDashboardReset",
  GET_TREASURY_FULL_DOCUMENT_HISTORY_ATTEMPT = "TREASURY/getTreasuryFullDocumentHistoryAttempt",
  GET_TREASURY_FULL_DOCUMENT_HISTORY_SUCCESS = "TREASURY/getTreasuryFullDocumentHistorySuccess",
  GET_TREASURY_FULL_DOCUMENT_HISTORY_FAILURE = "TREASURY/getTreasuryFullDocumentHistoryFailure",
  GET_TREASURY_FULL_DOCUMENT_HISTORY_RESET = "TREASURY/getTreasuryFullDocumentHistoryReset",
  GET_TREASURY_USER_HISTORY_ATTEMPT = "TREASURY/getTreasuryUserHistoryAttempt",
  GET_TREASURY_USER_HISTORY_SUCCESS = "TREASURY/getTreasuryUserHistorySuccess",
  GET_TREASURY_USER_HISTORY_FAILURE = "TREASURY/getTreasuryUserHistoryFailure",
  GET_TREASURY_USER_HISTORY_RESET = "TREASURY/getTreasuryUserHistoryReset",
  GET_TREASURY_MERCHANT_HISTORY_ATTEMPT = "TREASURY/getTreasuryMerchantHistoryAttempt",
  GET_TREASURY_MERCHANT_HISTORY_SUCCESS = "TREASURY/getTreasuryMerchantHistorySuccess",
  GET_TREASURY_MERCHANT_HISTORY_FAILURE = "TREASURY/getTreasuryMerchantHistoryFailure",
  GET_TREASURY_MERCHANT_HISTORY_RESET = "TREASURY/getTreasuryMerchantHistoryReset",
  GET_PAYMENT_LIMIT_ATTEMPT = "TREASURY/getPaymentLimitsAttempt",
  GET_PAYMENT_LIMIT_SUCCESS = "TREASURY/getPaymentLimitsSuccess",
  GET_PAYMENT_LIMIT_FAILURE = "TREASURY/getPaymentLimitsFailure",
  SET_DATE_RANGE = "TREASURY/setDateRange",
}

export const getUsersWithPermissionsAttempt = () =>
  ({ type: TreasuryActionEnum.GET_USERS_WITH_PERMISSIONS_ATTEMPT } as const)
export const getUsersWithPermissionsSuccess = (
  payload: UsersWithPermissions[]
) =>
  ({
    type: TreasuryActionEnum.GET_USERS_WITH_PERMISSIONS_SUCCESS,
    payload,
  } as const)
export const getUsersWithPermissionsFailure = () =>
  ({ type: TreasuryActionEnum.GET_USERS_WITH_PERMISSIONS_FAILURE } as const)
export const grantOrRevokeAccessAttempt = () =>
  ({ type: TreasuryActionEnum.GRANT_OR_REVOKE_ACCESS_ATTEMPT } as const)
export const grantOrRevokeAccessSuccess = () =>
  ({
    type: TreasuryActionEnum.GRANT_OR_REVOKE_ACCESS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `treasury.grant_or_revoke_access.header.success`,
      bodyKey: `treasury.grant_or_revoke_access.body.success`,
    },
  } as const)
export const grantOrRevokeAccessFailure = (payload: AxiosError) =>
  ({
    type: TreasuryActionEnum.GRANT_OR_REVOKE_ACCESS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.grant_or_revoke_access.header.failure`,
      bodyKey: `treasury.grant_or_revoke_access.body.failure.${payload}`,
    },
  } as const)
export const changeOrValidationPaymentLimitAttempt = () =>
  ({
    type: TreasuryActionEnum.CHANGE_VALIDATION_OR_PAYMENT_LIMIT_ATTEMPT,
  } as const)
export const changeOrValidationPaymentLimitSuccess = () =>
  ({
    type: TreasuryActionEnum.CHANGE_VALIDATION_OR_PAYMENT_LIMIT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "treasury.change_validation_and_payment_limits_for_user.header.success",
      bodyKey: `treasury.change_validation_and_payment_limits_for_user.body.success`,
    },
  } as const)
export const changeOrValidationPaymentLimitFailure = (payload: AxiosError) =>
  ({
    type: TreasuryActionEnum.CHANGE_VALIDATION_OR_PAYMENT_LIMIT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.change_validation_and_payment_limits_for_user.header.failure`,
      bodyKey: `treasury.change_validation_and_payment_limits_for_user.body.failure.${payload}`,
    },
  } as const)
export const getTreasuryMerchantsAttempt = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_MERCHANTS_ATTEMPT } as const)
export const getTreasuryMerchantsSuccess = (payload: TreasuryMerchants[]) =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_MERCHANTS_SUCCESS,
    payload,
  } as const)
export const getTreasuryMerchantsFailure = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_MERCHANTS_FAILURE } as const)
export const changeMerchantTreasurySettingsAttempt = () =>
  ({
    type: TreasuryActionEnum.CHANGE_MERCHANT_TREASURY_SETTINGS_ATTEMPT,
  } as const)
export const changeMerchantTreasurySettingsSuccess = () =>
  ({
    type: TreasuryActionEnum.CHANGE_MERCHANT_TREASURY_SETTINGS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "treasury.change_merchant_treasury_settings.header.success",
      bodyKey: "treasury.change_merchant_treasury_settings.body.success",
    },
  } as const)
export const changeMerchantTreasurySettingsFailure = (payload: AxiosError) =>
  ({
    type: TreasuryActionEnum.CHANGE_MERCHANT_TREASURY_SETTINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.change_merchant_treasury_settings.header.failure`,
      bodyKey: `treasury.change_merchant_treasury_settings.body.failure.${payload}`,
    },
  } as const)
export const getTreasuryFullDocumentsAttempt = () =>
  ({ type: TreasuryActionEnum.GET_BUY_FULL_DOCUMENTS_ATTEMPT } as const)
export const getTreasuryFullDocumentsSuccess = (
  payload: getTreasuryFullDocumentsResponse
) =>
  ({
    type: TreasuryActionEnum.GET_BUY_FULL_DOCUMENTS_SUCCESS,
    payload,
  } as const)
export const getTreasuryFullDocumentsFailure = () =>
  ({ type: TreasuryActionEnum.GET_BUY_FULL_DOCUMENTS_FAILURE } as const)
export const updateFullDocumentPaymentStatusAttempt = () =>
  ({
    type: TreasuryActionEnum.UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_ATTEMPT,
  } as const)
export const updateFullDocumentPaymentStatusSuccess = () =>
  ({
    type: TreasuryActionEnum.UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `treasury.update_full_document_payment_status.success.title`,
      bodyKey: `treasury.update_full_document_payment_status.success.body`,
    },
  } as const)
export const updateFullDocumentPaymentStatusFailure = (
  payload: AxiosError,
  actionType: FullDocumentPaymentActionType
) =>
  ({
    type: TreasuryActionEnum.UPDATE_FULL_DOCUMENTS_PAYMENT_STATUS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.failure.${actionType}.header`,
      bodyKey: `treasury.buy.update_full_document_payment_status.body.failure.${payload}`,
    },
  } as const)
export const askForValidationAttempt = () =>
  ({ type: TreasuryActionEnum.ASK_FOR_VALIDATION_ATTEMPT } as const)
export const askForValidationSuccess = () =>
  ({
    type: TreasuryActionEnum.ASK_FOR_VALIDATION_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `treasury.buy.to-control.ask-validation.header.success`,
      bodyKey: `treasury.buy.to-control.ask-validation.body.success`,
    },
  } as const)
export const askForValidationFailure = (payload: AxiosError) =>
  ({
    type: TreasuryActionEnum.ASK_FOR_VALIDATION_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.buy.to-control.ask-validation.header.failure`,
      bodyKey: `treasury.buy.to-control.ask-validation.body.failure.${payload}`,
    },
  } as const)
export const getUsersAvailableForValidationAttempt = () =>
  ({
    type: TreasuryActionEnum.GET_USERS_AVAILAIBLE_FOR_VALIDATION_ATTEMPT,
  } as const)
export const getUsersAvailableForValidationSuccess = (
  payload: UsersAvailableForValidation[]
) =>
  ({
    type: TreasuryActionEnum.GET_USERS_AVAILAIBLE_FOR_VALIDATION_SUCCESS,
    payload,
  } as const)
export const getUsersAvailableForValidationFailure = () =>
  ({
    type: TreasuryActionEnum.GET_USERS_AVAILAIBLE_FOR_VALIDATION_FAILURE,
  } as const)
export const getCurrentUserPermissionsAttempt = () =>
  ({ type: TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_ATTEMPT } as const)
export const getCurrentUserPermissionsSuccess = (
  payload: CurrentUserPermission
) =>
  ({
    type: TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_SUCCESS,
    payload,
  } as const)
export const getCurrentUserPermissionsFailure = () =>
  ({ type: TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_FAILURE } as const)
export const getCurrentUserPermissionsReset = () =>
  ({ type: TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_RESET } as const)
export const invitUserToDashboardAttempt = () =>
  ({ type: TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_ATTEMPT } as const)
export const invitUserToDashboardSuccess = () =>
  ({
    type: TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `treasury.dashboard-invit-user.modal.success.title`,
      bodyKey: `treasury.dashboard-invit-user.modal.success.body`,
    },
  } as const)
export const invitUserToDashboardFailure = (payload: string | null) =>
  ({
    type: TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_FAILURE,
    payload,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `treasury.dashboard-invit-user.modal.failure.title`,
      bodyKey: `treasury.dashboard-invit-user.modal.failure.body`,
    },
  } as const)
export const invitUserToDashboardReset = () =>
  ({ type: TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_RESET } as const)
export const getTreasuryFullDocumentHistorySuccess = (
  payload: TreasuryFullDocumentHistory[]
) =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_SUCCESS,
    payload,
  } as const)
export const getTreasuryFullDocumentHistoryFailure = () =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_FAILURE,
  } as const)
export const getTreasuryFullDocumentHistoryAttempt = () =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_ATTEMPT,
  } as const)
export const getTreasuryFullDocumentHistoryReset = () =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_RESET,
  } as const)
export const getTreasuryUserHistoryAttempt = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_USER_HISTORY_ATTEMPT } as const)
export const getTreasuryUserHistorySuccess = (payload: TreasuryUserHistory[]) =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_USER_HISTORY_SUCCESS,
    payload,
  } as const)
export const getTreasuryUserHistoryFailure = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_USER_HISTORY_FAILURE } as const)
export const getTreasuryUserHistoryReset = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_USER_HISTORY_RESET } as const)
export const getTreasuryMerchantHistoryAttempt = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_ATTEMPT } as const)
export const getTreasuryMerchantHistorySuccess = (
  payload: TreasuryMerchantHistory[]
) =>
  ({
    type: TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_SUCCESS,
    payload,
  } as const)
export const getTreasuryMerchantHistoryFailure = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_FAILURE } as const)
export const getTreasuryMerchantHistoryReset = () =>
  ({ type: TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_RESET } as const)
export const getPaymentLimitsAttempt = () =>
  ({ type: TreasuryActionEnum.GET_PAYMENT_LIMIT_ATTEMPT } as const)
export const getPaymentLimitsSuccess = (payload: String[]) =>
  ({ type: TreasuryActionEnum.GET_PAYMENT_LIMIT_SUCCESS, payload } as const)
export const getPaymentLimitsFailure = () =>
  ({ type: TreasuryActionEnum.GET_PAYMENT_LIMIT_FAILURE } as const)
export const setDateRange = (payload: [Date, Date]) =>
  ({ type: TreasuryActionEnum.SET_DATE_RANGE, payload } as const)

type TreasuryActionsType = ReturnType<
  | typeof getUsersWithPermissionsAttempt
  | typeof getUsersWithPermissionsSuccess
  | typeof getUsersWithPermissionsFailure
  | typeof grantOrRevokeAccessAttempt
  | typeof grantOrRevokeAccessSuccess
  | typeof grantOrRevokeAccessFailure
  | typeof changeOrValidationPaymentLimitAttempt
  | typeof changeOrValidationPaymentLimitSuccess
  | typeof changeOrValidationPaymentLimitFailure
  | typeof getTreasuryMerchantsAttempt
  | typeof getTreasuryMerchantsSuccess
  | typeof getTreasuryMerchantsFailure
  | typeof changeMerchantTreasurySettingsAttempt
  | typeof changeMerchantTreasurySettingsSuccess
  | typeof changeMerchantTreasurySettingsFailure
  | typeof getTreasuryFullDocumentsAttempt
  | typeof getTreasuryFullDocumentsSuccess
  | typeof getTreasuryFullDocumentsFailure
  | typeof updateFullDocumentPaymentStatusAttempt
  | typeof updateFullDocumentPaymentStatusSuccess
  | typeof updateFullDocumentPaymentStatusFailure
  | typeof askForValidationAttempt
  | typeof askForValidationSuccess
  | typeof askForValidationFailure
  | typeof getUsersAvailableForValidationAttempt
  | typeof getUsersAvailableForValidationSuccess
  | typeof getUsersAvailableForValidationFailure
  | typeof getCurrentUserPermissionsAttempt
  | typeof getCurrentUserPermissionsSuccess
  | typeof getCurrentUserPermissionsFailure
  | typeof getCurrentUserPermissionsReset
  | typeof invitUserToDashboardAttempt
  | typeof invitUserToDashboardSuccess
  | typeof invitUserToDashboardFailure
  | typeof invitUserToDashboardReset
  | typeof getTreasuryFullDocumentHistoryAttempt
  | typeof getTreasuryFullDocumentHistorySuccess
  | typeof getTreasuryFullDocumentHistoryFailure
  | typeof getTreasuryFullDocumentHistoryReset
  | typeof getTreasuryUserHistoryAttempt
  | typeof getTreasuryUserHistorySuccess
  | typeof getTreasuryUserHistoryFailure
  | typeof getTreasuryUserHistoryReset
  | typeof getPaymentLimitsAttempt
  | typeof getPaymentLimitsSuccess
  | typeof getPaymentLimitsFailure
  | typeof getTreasuryMerchantHistoryAttempt
  | typeof getTreasuryMerchantHistorySuccess
  | typeof getTreasuryMerchantHistoryFailure
  | typeof getTreasuryMerchantHistoryReset
  | typeof setDateRange
>

export type ValidationLimit = "payment_limit" | "validation_payment_limit"
export type PaymentStatus =
  | "to_control"
  | "to_pay"
  | "paid"
  | "rejected"
  | "to_receive"
  | "received"
  | "irrecoverable"
export type TreasuryUserMerchantInstructionType =
  | "validate_directly"
  | "mark_as_paid_directly"
export type TreasuryUserAccess =
  | "can_mark_as_paid"
  | "can_reject_payment"
  | "can_mark_as_irrecoverable"
  | "can_mark_as_payment_received"

export type FullDocumentPaymentActionType =
  | "validate"
  | "mark_as_paid"
  | "reject"
  | "mark_as_to_control"
  | "mark_as_irrecoverable"
  | "mark_as_payment_received"
  | "mark_as_to_receive"
  | "mark_as_received"

export type BuyPaymentStatus = "to_control" | "to_pay" | "paid" | "rejected"
export type SellPaymentStatus = "to_receive" | "received" | "irrecoverable"
export type UserHistoryStatus =
  | ValidationLimit
  | TreasuryUserAccess
  | "invit_user"

export interface CurrentUserPermission {
  id: number
  user_id: number
  can_mark_as_paid: boolean
  can_reject_payment: boolean
  can_mark_as_payment_received: boolean
  can_mark_as_irrecoverable: boolean
  marked_as_appearing_in_permissions_list: boolean
  validation_payment_limit_value: string
  payment_limit_value: string
}

export interface UsersWithPermissions extends CurrentUserPermission {
  user_id: number
  email: string
  user_typology: UserTypes
}

export interface getTreasuryMerchantResponse {
  merchants: TreasuryMerchants[]
}

export interface TreasuryMerchants {
  id: number
  name: string
  validate_directly: boolean
  mark_as_paid_directly: boolean
}

export interface TreasuryFullDocuments {
  id: number
  document_reference: string
  document_date: string
  document_due_date: string
  user_file_name: string
  file_name: string
  buy_or_sell: "buy" | "sell"
  merchant_name: string
  amount: number
  validation_request: number | null
}

export interface DashBoardFullDocuments {
  to_control: TreasuryFullDocuments[]
  to_pay: TreasuryFullDocuments[]
  paid: TreasuryFullDocuments[]
  rejected: TreasuryFullDocuments[]
  to_receive: TreasuryFullDocuments[]
  received: TreasuryFullDocuments[]
  irrecoverable: TreasuryFullDocuments[]
}

export interface TreasuryMerchantHistory {
  merchant_id: number
  metadata: {
    validate_directly: boolean
    mark_as_paid_directly: boolean
  }
  instruction_type: TreasuryUserMerchantInstructionType
  created_at: string
  user_email: string
}

export interface UsersAvailableForValidation {
  user_id: number
  user_email: string
}

export interface ValidatioNRequest {
  id: number
  full_document_id: number
  request_user_id: number
  requested_user_id: number
  created_at: string
}

export interface getTreasuryFullDocumentsResponse {
  dashboard: DashBoardFullDocuments
}

export interface TreasuryFullDocumentHistory {
  id: number
  full_document_id: number
  user_id: number
  user_email: string
  event_type: FullDocumentPaymentActionType
  created_at: string
  updated_at: string
}

export interface TreasuryUserHistory {
  id: number
  current_user_id: number
  receiving_user_id: number
  current_user_email: string
  receiving_user_email: string
  metadata: {
    old_value: string
    new_value: string
  }
  created_at: string
  updated_at: string
  event_type: UserHistoryStatus
}

export interface TreasuryState {
  usersWithPermission: UsersWithPermissions[]
  merchants: TreasuryMerchants[]
  dashboard: DashBoardFullDocuments
  user_available_for_validation: UsersAvailableForValidation[]
  current_user_permissions: CurrentUserPermission
  currentUserPermissionStatus: "idle" | "loading" | "success" | "failure"
  invit_user_error: string | null
  full_document_history: TreasuryFullDocumentHistory[]
  user_history: TreasuryUserHistory[]
  merchant_history: TreasuryMerchantHistory[]
  payment_limits: String[]
  range: [Date, Date]
}

export const TreasuryInitialState: TreasuryState = {
  usersWithPermission: [],
  merchants: [],
  dashboard: {
    to_control: [],
    to_pay: [],
    paid: [],
    rejected: [],
    to_receive: [],
    received: [],
    irrecoverable: [],
  },
  currentUserPermissionStatus: "idle",
  user_available_for_validation: [],
  current_user_permissions: {
    id: 0,
    user_id: 0,
    can_mark_as_paid: false,
    can_reject_payment: false,
    can_mark_as_payment_received: false,
    can_mark_as_irrecoverable: false,
    marked_as_appearing_in_permissions_list: false,
    validation_payment_limit_value: "none",
    payment_limit_value: "none",
  },
  invit_user_error: "idle",
  full_document_history: [],
  user_history: [],
  merchant_history: [],
  payment_limits: [],
  range: allTime,
}

export function TreasuryReducer(
  state = TreasuryInitialState,
  action: TreasuryActionsType
): TreasuryState {
  switch (action.type) {
    case TreasuryActionEnum.GET_USERS_WITH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        usersWithPermission: action.payload,
      }
    case TreasuryActionEnum.GET_TREASURY_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: action.payload,
      }
    case TreasuryActionEnum.GET_BUY_FULL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        dashboard: action.payload.dashboard,
      }
    case TreasuryActionEnum.GET_USERS_AVAILAIBLE_FOR_VALIDATION_SUCCESS:
      return {
        ...state,
        user_available_for_validation: action.payload,
      }
    case TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_ATTEMPT:
      return {
        ...state,
        currentUserPermissionStatus: "loading",
      }
    case TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        current_user_permissions: action.payload,
        currentUserPermissionStatus: "success",
      }
    case TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        currentUserPermissionStatus: "failure",
      }
    case TreasuryActionEnum.GET_CURRENT_USER_PERMISSIONS_RESET:
      return {
        ...state,
        currentUserPermissionStatus: "idle",
      }
    case TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_FAILURE:
      return {
        ...state,
        invit_user_error: action.payload,
      }
    case TreasuryActionEnum.INVIT_USER_TO_DASHBOARD_RESET:
      return {
        ...state,
        invit_user_error: "idle",
      }
    case TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_SUCCESS:
      return {
        ...state,
        full_document_history: action.payload,
      }
    case TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_FAILURE:
      return {
        ...state,
        full_document_history: [],
      }
    case TreasuryActionEnum.GET_TREASURY_FULL_DOCUMENT_HISTORY_RESET:
      return {
        ...state,
        full_document_history: [],
      }
    case TreasuryActionEnum.GET_TREASURY_USER_HISTORY_SUCCESS:
      return {
        ...state,
        user_history: action.payload,
      }
    case TreasuryActionEnum.GET_TREASURY_USER_HISTORY_FAILURE:
      return {
        ...state,
        user_history: [],
      }
    case TreasuryActionEnum.GET_TREASURY_USER_HISTORY_RESET:
      return {
        ...state,
        user_history: [],
      }
    case TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_SUCCESS:
      return {
        ...state,
        merchant_history: action.payload,
      }
    case TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_FAILURE:
      return {
        ...state,
        merchant_history: [],
      }
    case TreasuryActionEnum.GET_TREASURY_MERCHANT_HISTORY_RESET:
      return {
        ...state,
        merchant_history: [],
      }
    case TreasuryActionEnum.GET_PAYMENT_LIMIT_SUCCESS:
      return {
        ...state,
        payment_limits: action.payload,
      }
    case TreasuryActionEnum.SET_DATE_RANGE:
      return {
        ...state,
        range: action.payload,
      }
    default:
      return { ...state }
  }
}

export const getCurrentUserPermissionsThunk =
  (company_id: number) => (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getCurrentUserPermissionsReset())
    dispatch(getCurrentUserPermissionsAttempt())
    return axios
      .get<CurrentUserPermission>(
        `/user_company_permissions/get_treasury_current_user_permissions`,
        {
          params: {
            company_id: company_id,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getCurrentUserPermissionsSuccess(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getCurrentUserPermissionsFailure())
      })
  }

export const getUsersWithPermissions =
  (company_id: number) => (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getUsersWithPermissionsAttempt())
    return axios
      .get<UsersWithPermissions[]>(
        `/user_company_permissions/get_treasury_access`,
        {
          params: {
            company_id: company_id,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getUsersWithPermissionsSuccess(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getUsersWithPermissionsFailure())
      })
  }

export const grantOrRevokeTreasuryAccessThunk =
  (
    permissions_ids: number[],
    type: string,
    access_action: string,
    company_id: number
  ) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(grantOrRevokeAccessAttempt())
    return axios
      .post(`/user_company_permissions/grant_or_revoke_treasury_access`, {
        permissions_ids,
        type,
        access_action,
        company_id,
      })
      .then(() => {
        dispatch(grantOrRevokeAccessSuccess())
        dispatch(getUsersWithPermissions(company_id))
      })
      .catch((e) => {
        dispatch(grantOrRevokeAccessFailure(e.response.data.message))
      })
  }

export const changeValidationAndPaymentLimitForUser =
  (
    permissions_ids: number[],
    limit: string,
    access_action: ValidationLimit,
    company_id: number
  ) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(changeOrValidationPaymentLimitAttempt())
    return axios
      .post(
        `/user_company_permissions/change_validation_and_payment_limits_for_user`,
        {
          permissions_ids,
          limit,
          access_action,
          company_id,
        }
      )
      .then(() => {
        dispatch(changeOrValidationPaymentLimitSuccess())
        dispatch(getUsersWithPermissions(company_id))
      })
      .catch((e) => {
        dispatch(changeOrValidationPaymentLimitFailure(e.response.data.message))
      })
  }

export const getTreasuryMerchantsThunk =
  (company_id: number) => (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getTreasuryMerchantsAttempt())
    return axios
      .get<TreasuryMerchants[]>(
        `/merchants/get_merchants_treasury_for_company`,
        {
          params: {
            company_id: company_id,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getTreasuryMerchantsSuccess(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getTreasuryMerchantsFailure())
      })
  }

export const changeMerchantTreasurySettingsThunk =
  (
    merchant_id: number,
    type: string,
    access_action: string,
    company_id: number
  ) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(changeMerchantTreasurySettingsAttempt())
    return axios
      .put(`/merchants/${merchant_id}/change_merchant_treasury_settings`, {
        type,
        access_action,
        company_id,
      })
      .then(() => {
        dispatch(changeMerchantTreasurySettingsSuccess())
        dispatch(getTreasuryMerchantsThunk(company_id))
      })
      .catch((e) => {
        dispatch(changeMerchantTreasurySettingsFailure(e.response.data.message))
      })
  }

export const getTreasuryFullDocumentsThunk =
  (company_id: number, scope: "buy" | "sell") =>
  (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getTreasuryFullDocumentsAttempt())
    return axios
      .get<getTreasuryFullDocumentsResponse>(
        `/full_documents/get_full_documents_for_treasury`,
        {
          params: {
            company_id,
            scope,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getTreasuryFullDocumentsSuccess(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getTreasuryFullDocumentsFailure())
      })
  }

export const updateFullDocumentPaymentStatusThunk =
  (
    company_id: number,
    full_document_ids: number[],
    access_action: FullDocumentPaymentActionType,
    scope: "buy" | "sell",
    total_amount: number
  ) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(updateFullDocumentPaymentStatusAttempt())
    return axios
      .post(`/full_documents/treasury_update_payment_status`, {
        full_document_ids,
        access_action,
        total_amount,
        company_id,
      })
      .then(() => {
        dispatch(updateFullDocumentPaymentStatusSuccess())
        dispatch(getTreasuryFullDocumentsThunk(company_id, scope))
      })
      .catch((e) => {
        dispatch(
          updateFullDocumentPaymentStatusFailure(
            e.response.data.message,
            access_action
          )
        )
      })
  }

export const askForValidationThunk =
  (
    company_id: number,
    full_document_ids: number[],
    request_user_id: number,
    requested_user_id: number,
    scope: "buy" | "sell"
  ) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(askForValidationAttempt())
    return axios
      .post(`/full_documents/treasury_request_payment_validation`, {
        company_id,
        full_document_ids,
        request_user_id,
        requested_user_id,
      })
      .then(() => {
        dispatch(askForValidationSuccess())
        dispatch(getTreasuryFullDocumentsThunk(company_id, scope))
      })
      .catch((e) => {
        dispatch(askForValidationFailure(e.response.data.message))
      })
  }

export const getUserAvailableForValidationThunk =
  (company_id: number, amount: number) =>
  (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getUsersAvailableForValidationAttempt())
    return axios
      .get<UsersAvailableForValidation[]>(
        `/user_company_permissions/treasury_users_available_for_validation`,
        {
          params: {
            company_id,
            amount,
          },
        }
      )
      .then(({ data }) => {
        dispatch(getUsersAvailableForValidationSuccess(data))
      })
      .catch((e: AxiosError) => {
        dispatch(getUsersAvailableForValidationFailure())
      })
  }

export const invitUserToDashboardThunk =
  (company_id: number, requested_user_email: string) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(invitUserToDashboardAttempt())
    return axios
      .post(`/user_company_permissions/invite_user_to_treasury_dashboard`, {
        company_id,
        requested_user_email,
      })
      .then(() => {
        dispatch(invitUserToDashboardSuccess())
        dispatch(invitUserToDashboardReset())
        dispatch(getUsersWithPermissions(company_id))
      })
      .catch((e) => {
        dispatch(invitUserToDashboardFailure(e.response.data.message))
      })
  }

export const getTreasuryFullDocumentHistoryThunk =
  (full_document_id: number) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(getTreasuryFullDocumentHistoryReset())
    dispatch(getTreasuryFullDocumentHistoryAttempt())
    return axios
      .get<TreasuryFullDocumentHistory[]>(
        `full_documents/${full_document_id}/get_treasury_full_document_history`
      )
      .then(({ data }) => {
        dispatch(getTreasuryFullDocumentHistorySuccess(data))
      })
      .catch(() => {
        dispatch(getTreasuryFullDocumentHistoryFailure())
      })
  }

export const getTreasuryUserHistoryThunk =
  (receiving_user_id: number) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(getTreasuryUserHistoryAttempt())
    dispatch(getTreasuryUserHistoryReset())
    return axios
      .get<TreasuryUserHistory[]>(
        `user_company_permissions/get_user_treasury_settings_history`,
        { params: { receiving_user_id } }
      )
      .then(({ data }) => {
        dispatch(getTreasuryUserHistorySuccess(data))
      })
      .catch(() => {
        dispatch(getTreasuryUserHistoryFailure())
      })
  }

export const getTreasuryMerchantHistoryThunk =
  (merchant_id: number) =>
  (dispatch: Dispatch<TreasuryActionsType | RNBThunkAction>) => {
    dispatch(getTreasuryMerchantHistoryAttempt())
    dispatch(getTreasuryMerchantHistoryReset())
    return axios
      .get<TreasuryMerchantHistory[]>(
        `merchants/${merchant_id}/get_merchant_treasury_history`
      )
      .then(({ data }) => {
        dispatch(getTreasuryMerchantHistorySuccess(data))
      })
      .catch(() => {
        dispatch(getTreasuryMerchantHistoryFailure())
      })
  }

export const getPaymentLimitsThunk =
  () => (dispatch: Dispatch<TreasuryActionsType>) => {
    dispatch(getPaymentLimitsAttempt())
    return axios
      .get<String[]>(`user_company_permissions/get_payment_limits`)
      .then(({ data }) => {
        dispatch(getPaymentLimitsSuccess(data))
      })
      .catch(() => {
        dispatch(getPaymentLimitsFailure())
      })
  }
