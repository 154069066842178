import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { ReactComponent as CheckedSvg } from "../../assets/checked.svg"
import * as Ct from "ldlj"
import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form"
import { forwardRef, MutableRefObject, RefObject } from "react"

interface CheckboxProps {
  label: string
  name?: string
  isChecked: boolean
  onChange?: () => void
  id?: string
  disabled?: boolean
  register?: UseFormRegister<FieldValues>
  validations?: RegisterOptions
  value: boolean
  colorChecked?: string
  checkBoxSize?: number
}

export const Checkbox = forwardRef<RefObject<HTMLInputElement>, CheckboxProps>(
  (
    {
      label,
      name = "",
      isChecked,
      onChange,
      disabled = false,
      register,
      validations,
      value,
      colorChecked,
      checkBoxSize = 2,
    }: CheckboxProps,
    forwardRef
  ) => {
    if (!register) {
      return (
        <div>
          <HiddenInput
            type="checkbox"
            id={name}
            name={name}
            defaultChecked={value}
          />
          <StyledInput
            onClick={onChange}
            htmlFor={name}
            data-cy={name}
            aria-disabled={disabled}
          >
            <StyledSquare
              checked={value}
              disabled={disabled}
              colorChecked={colorChecked}
              checkBoxSize={checkBoxSize}
            >
              {value && (
                <StyledChecked>
                  <CheckedSvg />
                </StyledChecked>
              )}
            </StyledSquare>
            <Ct.Spacer height={0} width={1} />
            <StyledInputLabel disabled={disabled}>{label}</StyledInputLabel>
          </StyledInput>
        </div>
      )
    }

    const { ref, ...rest } = register(name, validations)
    return (
      <div>
        <HiddenInput
          type="checkbox"
          id={name}
          defaultChecked={isChecked}
          {...rest}
          ref={(e) => {
            ref(e)
            if (forwardRef && e) {
              const inputRef =
                forwardRef as unknown as MutableRefObject<HTMLInputElement>
              inputRef.current = e
            }
          }}
        />
        <StyledInput
          onClick={onChange}
          htmlFor={name}
          data-cy={name}
          aria-disabled={disabled}
        >
          <StyledSquare
            checked={isChecked}
            disabled={disabled}
            colorChecked={colorChecked}
            checkBoxSize={checkBoxSize}
          >
            {isChecked && (
              <StyledChecked>
                <CheckedSvg />
              </StyledChecked>
            )}
          </StyledSquare>
          <Ct.Spacer height={0} width={1} />
          <StyledInputLabel htmlFor={name} data-cy={name} disabled={disabled}>
            {label}
          </StyledInputLabel>
        </StyledInput>
      </div>
    )
  }
)

Checkbox.displayName = "Checkbox"

interface CheckedProp {
  checked: boolean
  disabled?: boolean
  colorChecked?: string
  checkBoxSize: number
}

interface DisabledProp {
  disabled: boolean
}

const HiddenInput = styled.input`
  display: none;
`
const StyledInput = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const StyledSquare = styled.div<CheckedProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: ${(props) => props.checkBoxSize}rem;
  min-width: ${(props) => props.checkBoxSize}rem;
  transition: all 0.5s ease-in-out;

  background-color: ${(props) =>
    props.checked
      ? props.colorChecked || colors.shamrock
      : props.disabled
      ? colors.hawkes
      : colors.rock};
  border-radius: 0.5rem;
  margin-top: 0.1rem;
  align-self: start;
`
const StyledInputLabel = styled.label<DisabledProp>`
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  user-select: none;
  transition: all 0.3s ease-in-out;

  color: ${({ disabled }) => (disabled ? colors.hawkes : colors.navy)};
`

const StyledChecked = styled.div`
  display: flex;
  user-select: none;
  & svg {
    fill: ${colors.white};
  }
`
