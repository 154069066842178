import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useEffect } from "react"

import styled from "styled-components/macro"
import { getIdFromParams } from "../../../../utils/company"
import { useParams } from "react-router-dom"
import { getMyUnisoftTokenThunk } from "../../../../store/ducks/companySettings.ducks"
import { Alert } from "../../../../components/Commons/Alert"

interface MyUnisoftTokenProps {
  modifiedMyUnisoftToken: string
  setModifiedMyUnisoftToken: (newToken: string) => void
}
export const MyUnisoftToken = ({
  modifiedMyUnisoftToken,
  setModifiedMyUnisoftToken,
}: MyUnisoftTokenProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  useEffect(() => {
    dispatch(getMyUnisoftTokenThunk({ companyId: selectedCompanyId }))
  }, [selectedCompanyId])

  return (
    <Container>
      <Alert alertType="info" margin={"0 4rem"}>
        <Ct.Text
          text={intl.formatMessage({
            id: "office-company-settings.my-unisoft.explanation",
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer />
        <Ct.RowCenter>
          <Ct.Text
            text={intl.formatMessage({
              id: "office-company-settings.my-unisoft.label",
            })}
            textStyle={{ lineHeight: 3, fontWeight: 700 }}
          />
          <Ct.Spacer />
          <Ct.Input
            label={intl.formatMessage({
              id: "office-company-settings.my-unisoft.placeholder",
            })}
            value={modifiedMyUnisoftToken}
            maxWidth={40}
            onChange={(e) => {
              setModifiedMyUnisoftToken(e.target.value)
            }}
          />
        </Ct.RowCenter>
      </Alert>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
