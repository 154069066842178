import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import {
  deactivateBatchDocumentThunk,
  deactivateBatchErrorAction,
} from "../store/ducks/batchDocuments.ducks"
import {
  deactivateFullDocumentFailure,
  deactivateFullDocumentThunk,
} from "../store/ducks/fullDocuments.ducks"
import { useKeyDown } from "../hooks/useKeyDown"

interface DeactivateBatchDocumentModalProps extends Ct.ModalComponentsProps {
  documentIdList: number[]
  companyId: number
  whichDocument: "fullDocument" | "batchDocument"
  buyOrSell?: "buy" | "sell" | null
}

export const DeactivateDocumentModal = ({
  isDisplayed,
  onClose,
  documentIdList,
  companyId,
  whichDocument,
  buyOrSell,
}: DeactivateBatchDocumentModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleValidation = () => {
    whichDocument === "fullDocument"
      ? dispatch(
          deactivateFullDocumentThunk({
            companyId,
            documentIdList,
            buyOrSell,
          })
        )
      : dispatch(
          deactivateBatchDocumentThunk({
            companyId,
            documentIdList,
          })
        )
    onClose()
  }

  const handleClose = () => {
    whichDocument === "fullDocument"
      ? dispatch(deactivateFullDocumentFailure(false))
      : dispatch(deactivateBatchErrorAction(false))
    onClose()
  }

  useKeyDown(() => {
    if (isDisplayed) handleValidation()
  }, ["Enter"])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={handleClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"96rem"}>
        <Ct.Title
          text={intl.formatMessage(
            { id: "company-drop-document.deactivate.about" },
            {
              documentType:
                whichDocument === "batchDocument" ? "dépôt(s)" : "document(s)",
            }
          )}
          size={3}
        />
        <Ct.Spacer height={3} />
        <Ct.Bar width={100} />
        <Ct.Spacer height={4} />

        <Ct.Text
          text={intl.formatMessage({
            id:
              whichDocument === "batchDocument"
                ? "company-drop-document.deactivate.disappear"
                : "company-EDM.document.deactivate.full-document",
          })}
          textStyle={{ lineHeight: 3, textAlign: "center" }}
        />

        <Ct.Spacer height={4} />

        <Ct.FlexShrinkZero>
          <Ct.Button
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "company-drop-document.deactivate.cancel",
            })}
            onClick={handleClose}
          />
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: "company-drop-document.deactivate.confirm",
            })}
            onClick={() => handleValidation()}
          />
        </Ct.FlexShrinkZero>
      </Ct.Card>
    </Ct.Modal>
  )
}
