import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import { useEffect } from "react"
import styled from "styled-components/macro"
import { ReactComponent as CloseCrossSVG } from "../../../assets/close-cross.svg"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { registerACDApi } from "../../../store/ducks/fiduciary.ducks"
import { useRNBSelector } from "../../../store/rootReducer"
import { Logos } from "../../../components/Commons/Logos"

interface ConnectFiduciaryApiModalProps extends Ct.ModalComponentsProps {
  fiduciarySoftware: string
}

export const ConnectFiduciaryApiModal = ({
  isDisplayed,
  onClose,
  fiduciarySoftware,
}: ConnectFiduciaryApiModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const apiConnectedStatus = useRNBSelector(
    (state) => state.fiduciary.apiConnectedStatus
  )

  const schema = yup.object({
    identifier: yup.string().required(),
    password: yup.string().required(),
    cnx: yup.string().required(),
    apiUrl: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const login = () => {
    dispatch(
      registerACDApi({
        fiduciaryId: fiduciary.id,
        identifier: identifier,
        password: password,
        cnx: cnx,
        apiUrl: apiUrl,
      })
    )
  }

  const identifier = watch("identifier")
  const password = watch("password")
  const cnx = watch("cnx")
  const apiUrl = watch("apiUrl")

  const cleanupAndClose = () => {
    onClose()
  }

  useEffect(() => {
    if (apiConnectedStatus === "CHECKED") cleanupAndClose()
  }, [apiConnectedStatus])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        cleanupAndClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card width="100rem">
          <Ct.StyledForm onSubmit={handleSubmit(login)}>
            <CloseCrossWrapper
              onClick={() => {
                cleanupAndClose()
              }}
            >
              <CloseCrossSVG />
            </CloseCrossWrapper>

            {fiduciarySoftware && (
              <Logos
                software={fiduciarySoftware as string}
                width={"30rem"}
                height={"15rem"}
              />
            )}

            <Ct.Title
              size={3}
              text={intl.formatMessage(
                { id: `fiduciary.settings.acd-modal.connect-with` },
                {
                  fiduciarySoftware: fiduciarySoftware,
                }
              )}
            />
            <Ct.Spacer height={4} />
            <div>
              <Ct.Row>
                <Ct.StyledDuoInput>
                  <Ct.Input
                    name={"identifier"}
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    value={identifier}
                    label={intl.formatMessage({
                      id: "fiduciary.settings.acd-modal.identifier",
                    })}
                    required={true}
                  />
                  <Ct.Spacer />

                  <Ct.PasswordInput
                    name="password"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "fiduciary.settings.acd-modal.password",
                    })}
                    type="password"
                    value={password}
                  />
                </Ct.StyledDuoInput>
              </Ct.Row>

              <Ct.Spacer height={4} />

              <Ct.Row>
                <Ct.Input
                  name="cnx"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({
                    id: "fiduciary.settings.acd-modal.cnx",
                  })}
                  type="text"
                  value={cnx}
                />
              </Ct.Row>

              <Ct.Spacer height={4} />

              <Ct.Row>
                <Ct.Input
                  name="apiUrl"
                  register={register as unknown as UseFormRegister<FieldValues>}
                  label={intl.formatMessage({
                    id: "fiduciary.settings.acd-modal.apiUrl",
                  })}
                  type="text"
                  value={apiUrl}
                  placeholder="https://example.com/iSuiteExpert/api"
                />
              </Ct.Row>
            </div>
            <Ct.Spacer height={4} />
            <Ct.Button
              width={50}
              label={intl.formatMessage({
                id: `fiduciary.settings.acd-connect`,
              })}
              onClick={() => {}}
              type="submit"
              disabled={!isValid}
            />
          </Ct.StyledForm>
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const CloseCrossWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
