import styled from "styled-components/macro"
import { colors } from "../styles/design.config"
import * as Ct from "ldlj"

interface Width {
  width?: string
}
export const ChangeCardContent = styled.div<Width>`
  border-radius: 1.25rem;
  max-width: ${({ width }) => (width ? width : `109rem`)};
  width: 100%;
  border: 1px solid ${colors.lavender};
`

export const ChangeHeader = styled.header`
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  width: initial;
  background-color: ${colors.lavender};
  padding: 2rem 3rem;
`

export const ChangesList = styled.ul<{ height?: string }>`
  padding: 3rem 5rem;
  margin: 0;
  height: ${({ height }) => (height ? height : `40rem`)};
  overflow: auto;
`

export const TextNoChanges = styled((props) => <Ct.Text {...props} />)`
  padding-left: 5rem;
`

export const ChangeItem = styled.li`
  padding: 0.3rem 0;
  list-style: none;

  :before {
    content: "·";
    font-size: 32px;
    vertical-align: middle;
    line-height: 20px;
    padding-right: 6px;
  }
`

export const Mailto = styled.a`
  color: ${colors.cornflower};
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.75rem;

  :hover {
    color: ${colors.cornflower};
  }
`
