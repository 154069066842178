import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import {
  AccountRules,
  AccountRulesWithoutDocument,
  DeleteRuleThunk,
  Transaction,
} from "../../store/ducks/bank.ducks"
import { RuleCreateParts } from "./RuleCreateParts"
import styled from "styled-components/macro"
import { DateTime } from "luxon"
import { Table } from "../Commons/Table"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect, useState, Fragment } from "react"
import { colors } from "../../styles/design.config"
import {
  displayRule,
  fullDocNotSelectable,
  isAccountRuleWithoutDocument,
} from "../../utils/bank"
import { ReactComponent as Trash } from "../../assets/drop-documents/TrashCan.svg"
import { ReactComponent as Pencil } from "../../assets/design-pencil.svg"
import { ReactComponent as Plus } from "../../assets/single-plus.svg"
import { ReactComponent as FileEye } from "../../assets/file-eye.svg"
import { ReactComponent as Warning } from "../../assets/warning.svg"
import { ReactComponent as FilePlus } from "../../assets/file-plus.svg"
import { ReactComponent as Bulb } from "../../assets/bulb.svg"
import { DeleteRuleModal } from "./DeleteRuleModal"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { Alert } from "../Commons/Alert"
import { Text } from "../Commons/Text"
import {
  DetachFullDocumentOfTransactionThunk,
  FullDocumentToAttach,
} from "../../store/ducks/fullDocuments.ducks"
import { Button } from "../Commons/Button"

/* eslint-disable camelcase */

interface DisplayTransactionProps {
  isDisplayed: boolean
  onClose: () => void
  transaction: Transaction
  goToFullDoc: (attachOrImport: "attach" | "import") => void
}

export const DisplayTransactionModal = ({
  isDisplayed,
  onClose,
  transaction,
  goToFullDoc,
}: DisplayTransactionProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [possibleRules, setPossibleRules] = useState<
    AccountRules[] | AccountRulesWithoutDocument[]
  >([])
  const [deleteRule, setDeleteRule] = useState<{
    id: number
    priority_number?: number
  } | null>(null)
  const [selectedTab, setSelectedTab] = useState<
    "createForOneTransaction" | "create"
  >("createForOneTransaction")
  const [currentTransaction, setCurrentTransaction] =
    useState<Transaction>(transaction)
  const [detachFullDoc, setDetachFullDoc] = useState<boolean>(false)
  const [fullDoc, setFullDoc] = useState<FullDocumentToAttach | null>(null)

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const accountID = getIdFromParams(useParams())("account_id") || 0

  const {
    rules,
    accounts,
    fullDocuments,
    attachDocumentStatus,
    documentsToAttach,
    batch_document,
  } = useRNBSelector((state) => ({
    rules: state.bank.dataToCreateRule.rules,
    accounts: state.bank.accounts,
    fullDocuments: state.bank.documentsToAttach.full_documents,
    attachDocumentStatus: state.bank.attachDocumentStatus,
    documentsToAttach: state.bank.documentsToAttach,
    batch_document: state.bank.documentsToAttach.batch_data?.current_batch,
  }))

  const currentAcccount = Object.values(accounts).find(
    (a) => a.id === accountID
  )

  useEffect(() => {
    if (
      Object.values(accounts).length > 0 &&
      currentAcccount &&
      currentAcccount.transactions &&
      currentAcccount.transactions.length > 0
    ) {
      const transactionChanged = currentAcccount.transactions.find(
        (t) => t.transaction_id === transaction.transaction_id
      )
      if (transactionChanged) {
        setCurrentTransaction(transactionChanged)
      }
    }
  }, [currentAcccount?.transactions, attachDocumentStatus])

  useEffect(() => {
    if (
      currentTransaction.possible_rules &&
      currentTransaction.possible_rules.length > 0
    ) {
      setPossibleRules(
        rules.filter(
          (r) =>
            (currentTransaction.possible_rules?.includes(r.id) &&
              r.priority_number) ||
            currentTransaction.rule_without_document_id === r.id
        )
      )
    }
  }, [rules, currentTransaction, currentAcccount?.transactions])

  useEffect(() => {
    if (transaction.full_document_id && fullDocuments.length > 0) {
      setFullDoc(
        fullDocuments.find((fd) => fd.id === transaction.full_document_id) ||
          null
      )
    }
  }, [fullDocuments, transaction])

  const ruleOnlyForThisTransaction =
    transaction.rule_id &&
    rules.find((r) => r.id === transaction.rule_id) !== undefined &&
    rules.find((r) => r.id === transaction.rule_id)?.transaction_id ===
      transaction.transaction_id
      ? rules.find((r) => r.id === transaction.rule_id)
      : null

  const hasTransactionFullDoc =
    !!currentTransaction.full_document_id && !currentTransaction.fd_deactivated

  const disabledRules =
    Boolean(ruleOnlyForThisTransaction) || hasTransactionFullDoc

  const columnsTransaction = [
    {
      headerText: "bank-management.transaction.table-header.date",
      content: (row: Transaction) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={DateTime.fromJSDate(new Date(row.date)).toFormat(
                  "dd/MM/yyyy"
                )}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        </>
      ),
    },
    {
      headerText: "bank-management.transaction.table-header.label",
      content: (row: Transaction) => (
        <ColumnRow>
          {row.lines.map((l) => (
            <Row text={row.description} key={row.transaction_id} />
          ))}
        </ColumnRow>
      ),
    },
    {
      headerText: "bank-management.transaction.table-header.debit",
      content: (row: Transaction) => {
        const empty = intl.formatMessage({
          id: "bank-management.transaction.table-body.empty-space",
        })

        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={l.direction === "debit" ? l.amount : empty}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
    {
      headerText: "bank-management.transaction.table-header.credit",
      content: (row: Transaction) => {
        const empty = intl.formatMessage({
          id: "bank-management.transaction.table-body.empty-space",
        })

        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={l.direction === "credit" ? l.amount : empty}
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
    {
      headerText: "bank-management.transaction.table-header.assignment",
      content: (row: Transaction) => {
        return (
          <ColumnRow>
            {row.lines.map((l) => (
              <Row
                text={
                  l.assignment +
                  (l.auxiliary_assignment ? ", " + l.auxiliary_assignment : "")
                }
                key={row.transaction_id}
              />
            ))}
          </ColumnRow>
        )
      },
    },
  ]

  const columnsFullDocument = [
    {
      headerText:
        "bank-management.transaction.attach.table-header.user_file_name",
      content: (row: FullDocumentToAttach) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            <Row text={row.user_file_name} />
          </ColumnRow>
        </>
      ),
    },
    {
      headerText:
        "bank-management.transaction.attach.table-header.original_file_name",
      content: (row: FullDocumentToAttach) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            <Row text={row.original_file_name} />
          </ColumnRow>
        </>
      ),
    },
    {
      headerText: "bank-management.transaction.attach.table-header.type",
      content: (row: FullDocumentToAttach) => {
        return (
          <>
            <Ct.Spacer width={2} height={0} />
            <ColumnRow>
              <Row
                text={
                  row.document_type && row.buy_or_sell
                    ? intl.formatMessage({
                        id: `bank-management.transaction.attach.table-header.type-${row.document_type}`,
                      }) +
                      " " +
                      (row.document_type &&
                      row.document_type !== "bill_of_exchange"
                        ? intl.formatMessage({
                            id: `bank-management.transaction.attach.table-header.${row.buy_or_sell}`,
                          })
                        : "")
                    : "-"
                }
              />
            </ColumnRow>
          </>
        )
      },
    },
    {
      headerText:
        "bank-management.transaction.attach.table-header.merchant_name",
      content: (row: FullDocumentToAttach) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            <Row text={row.merchant_name || "-"} />
          </ColumnRow>
        </>
      ),
    },
    {
      headerText: "bank-management.transaction.attach.table-header.date",
      content: (row: FullDocumentToAttach) => (
        <>
          <Ct.Spacer width={2} height={0} />
          <ColumnRow>
            <Row
              text={DateTime.fromJSDate(new Date(row.date)).toFormat(
                "dd/MM/yyyy"
              )}
            />
          </ColumnRow>
        </>
      ),
    },
    {
      headerText: "bank-management.transaction.attach.table-header.amount",
      content: (row: FullDocumentToAttach) => {
        const text = row.amount ? row.amount + (row.amount ? "€" : "") : "-"

        return (
          <>
            <Ct.Spacer width={2} height={0} />
            <ColumnRow>
              <Row text={text} />
            </ColumnRow>
          </>
        )
      },
    },
    {
      headerText: "empty-text",
      flexGrow: "6rem",
      content: (row: FullDocumentToAttach) => {
        return (
          <>
            <ColumnRowRow>
              <div onClick={() => goToFullDoc("attach")}>
                {" "}
                <FileEye />{" "}
              </div>
              <Ct.Spacer width={1} />
              <div onClick={() => setDetachFullDoc(true)}>
                {" "}
                <Trash />
              </div>
              <Ct.Spacer width={1} />
            </ColumnRowRow>
          </>
        )
      },
    },
  ]

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="25%"
      right="25%"
      top="2%"
    >
      <StyledCard
        width={"155rem"}
        height={
          selectedTab === "createForOneTransaction" && window.innerHeight < 1000
            ? "90vh"
            : selectedTab === "createForOneTransaction" &&
              window.innerHeight > 1000
            ? "90vh"
            : "95vh"
        }
        padding={"0"}
      >
        <Ct.Spacer height={2} />
        <Ct.CloseCross onClick={onClose} />

        <Ct.Text
          text={intl.formatMessage({
            id: `bank-management.transaction.detail.title`,
          })}
          textStyle={{
            fontWeight: 700,
            fontSize: 3,
            fontFamily: "Poppins",
          }}
        />

        <Ct.Spacer height={2} />
        <Line />
        <Ct.Spacer height={3} />
        <Wrapper>
          <TableWrapper>
            <Table
              intl={intl}
              columns={columnsTransaction}
              rows={[currentTransaction]}
              alignItems={"center"}
              width={"98%"}
              height={"100%"}
              padding={"0 1rem"}
              paddingHeader={"0rem 0rem 0rem 2rem"}
              paddingRows={"0px 0px 8px 0px"}
              heightHeader={"5rem"}
              fontWeightTitle={600}
              keyBuilder={(rowData) => String(rowData.transaction_id)}
              customScrollBar={true}
            />
          </TableWrapper>

          <Rules>
            <Ct.Text
              text={intl.formatMessage({
                id: `bank-management.transaction.detail.rules.title`,
              })}
              textStyle={{
                color: "navy",
                fontWeight: 700,
                fontSize: 2,
                textTransform: "uppercase",
              }}
            />
          </Rules>

          <Ct.Spacer height={2} />

          <RulesWrapperOverFlow>
            {possibleRules.length > 0 ? (
              <>
                {possibleRules.map((rule) => (
                  <Fragment key={rule.id}>
                    {!isAccountRuleWithoutDocument(rule) ? (
                      <RuleCard width={"100%"} disabled={disabledRules}>
                        <Ct.Row>
                          <Ct.Spacer width={2} />
                          <Ct.Text
                            text={"#" + rule.priority_number}
                            textStyle={{
                              color: disabledRules ? "moon" : "cornflower",
                              fontWeight: 700,
                              fontSize: 2,
                            }}
                          />

                          <Ct.Spacer width={4} />

                          <Ct.Text
                            text={intl.formatMessage({
                              id: "bank-management.rules.rule-text",
                            })}
                            textStyle={{
                              color: disabledRules ? "moon" : "navy",
                              fontSize: 1.75,
                            }}
                          />
                          <Ct.Text
                            text={displayRule(rule)}
                            textStyle={{
                              color: disabledRules ? "moon" : "navy",
                              fontSize: 1.75,
                              fontWeight: 600,
                            }}
                          />
                        </Ct.Row>

                        {!disabledRules && (
                          <RuleActions>
                            <Action
                              size={"3rem"}
                              onClick={() => {
                                setDeleteRule({
                                  id: rule.id,
                                  priority_number: rule.priority_number,
                                })
                              }}
                            >
                              <Trash />
                            </Action>
                            <Ct.Spacer width={3} />
                          </RuleActions>
                        )}
                      </RuleCard>
                    ) : (
                      <>
                        <RuleCard width={"100%"} disabled={false}>
                          <Ct.Row>
                            <Ct.Text
                              text={rule.text_in_description || ""}
                              textStyle={{
                                color: "navy",
                                fontSize: 1.75,
                                fontWeight: 600,
                              }}
                            />
                            <Ct.Spacer width={0.5} />
                            <Ct.Text
                              text={intl.formatMessage(
                                {
                                  id: "bank-management.rules.bank-line-type",
                                },
                                {
                                  inOrOut:
                                    rule.bank_line_type === "in"
                                      ? "entrée"
                                      : "sortie",
                                }
                              )}
                              textStyle={{
                                color: "navy",
                                fontSize: 1.75,
                              }}
                            />
                          </Ct.Row>

                          <RuleActions>
                            <Ct.Spacer width={5} />
                            <Action
                              size={"3rem"}
                              onClick={() => {
                                setDeleteRule({ id: rule.id })
                              }}
                            >
                              <Trash />
                            </Action>
                            <Ct.Spacer width={3} />
                          </RuleActions>
                        </RuleCard>
                      </>
                    )}

                    <Ct.Spacer height={2} />
                  </Fragment>
                ))}
              </>
            ) : (
              <WrapperAlert>
                <Ct.Spacer height={3} />
                <Alert alertType="info">
                  <Text
                    text={intl.formatMessage({
                      id: "bank-management.transaction.detail.rules.empty",
                    })}
                  />
                </Alert>
              </WrapperAlert>
            )}
          </RulesWrapperOverFlow>

          <Ct.Spacer height={4} />

          <Tabs disabled={hasTransactionFullDoc}>
            <Part
              selected={selectedTab === "createForOneTransaction"}
              onClick={() => {
                if (hasTransactionFullDoc) return
                setSelectedTab("createForOneTransaction")
              }}
              disabled={hasTransactionFullDoc}
            >
              <Pencil />
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.detail.rules.edit-line`,
                })}
                textStyle={{
                  color:
                    hasTransactionFullDoc &&
                    selectedTab !== "createForOneTransaction"
                      ? "moon"
                      : selectedTab === "createForOneTransaction"
                      ? "white"
                      : "navy",
                  fontWeight: 700,
                  fontSize: 2,
                  cursor: hasTransactionFullDoc ? "default" : "pointer",
                }}
              />
            </Part>

            <Ct.Spacer />

            <Part
              selected={selectedTab === "create"}
              onClick={() => {
                if (hasTransactionFullDoc) return
                setSelectedTab("create")
              }}
              disabled={hasTransactionFullDoc}
            >
              <Plus />
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.detail.rules.add-line`,
                })}
                textStyle={{
                  color:
                    hasTransactionFullDoc && selectedTab !== "create"
                      ? "moon"
                      : selectedTab === "create"
                      ? "white"
                      : "navy",
                  fontWeight: 700,
                  fontSize: 2,
                  cursor: hasTransactionFullDoc ? "default" : "pointer",
                }}
              />
            </Part>
          </Tabs>

          <ContentTab>
            <Ct.Spacer height={2} />
            <RuleCreateParts
              isDisplayed={isDisplayed}
              onClose={onClose}
              rulesLength={rules.length + 1}
              createOrEdit={
                selectedTab === "createForOneTransaction"
                  ? "createForOneTransaction"
                  : "create"
              }
              ruleToEdit={ruleOnlyForThisTransaction || null}
              createForOneTransaction={transaction}
              width={"100%"}
              from={"transactions"}
              selectedTab={selectedTab}
              disableAll={hasTransactionFullDoc}
            />
          </ContentTab>

          <Ct.Spacer />
          <WrapperFullDocText>
            <Ct.Text
              text={intl.formatMessage({
                id: `bank-management.transaction.attach-modal.full-doc-attached`,
              })}
              textStyle={{
                fontWeight: 700,
                fontSize: 2,
                textTransform: "uppercase",
              }}
            />
          </WrapperFullDocText>

          <Ct.Spacer height={1} />

          {currentTransaction.full_document_id && fullDoc ? (
            <>
              <WrapperFullDocText>
                {detachFullDoc ? (
                  <AttachBlock attach={false}>
                    <Ct.Text
                      text={intl.formatMessage({
                        id: `bank-management.transaction.attach-modal.full-doc.detach`,
                      })}
                      textStyle={{
                        fontWeight: 600,
                      }}
                    />
                    <Ct.Spacer />

                    <Ct.Row>
                      <Button
                        label={intl.formatMessage({
                          id: `bank-management.transaction.attach-modal.full-doc.detach.cancel`,
                        })}
                        colorType={"Tertiary"}
                        colorScheme={{
                          background: "white",
                          color: "amaranth",
                          border: "white",
                        }}
                        onClick={() => setDetachFullDoc(false)}
                      />
                      <Ct.Spacer />
                      <Button
                        label={intl.formatMessage({
                          id: `bank-management.transaction.attach-modal.full-doc.detach.confirm`,
                        })}
                        colorType={"Tertiary"}
                        colorScheme={{
                          background: "amaranth",
                          color: "white",
                          border: "amaranth",
                        }}
                        onClick={() => {
                          dispatch(
                            DetachFullDocumentOfTransactionThunk(
                              accountID,
                              currentTransaction.transaction_id
                            )
                          )
                        }}
                      />
                    </Ct.Row>
                  </AttachBlock>
                ) : (
                  <>
                    {!currentTransaction.fd_deactivated && (
                      <>
                        <Ct.Row>
                          <Warning />
                          <Ct.Spacer width={1} />
                          <Ct.Text
                            text={intl.formatMessage({
                              id: `bank-management.transaction.attach-modal.full-doc-attached-message`,
                            })}
                            textStyle={{
                              fontStyle: "italic",
                            }}
                          />
                          <Ct.Spacer />
                        </Ct.Row>
                        <Ct.Spacer />
                      </>
                    )}
                  </>
                )}
              </WrapperFullDocText>

              {!detachFullDoc && (
                <TableWrapper>
                  <Table
                    intl={intl}
                    columns={columnsFullDocument}
                    rows={[fullDoc]}
                    alignItems={"center"}
                    width={"98%"}
                    height={"100%"}
                    padding={"0px"}
                    paddingHeader={"0rem 0rem 0rem 2rem"}
                    paddingRows={"0px 0px 8px 0px"}
                    heightHeader={"5rem"}
                    fontWeightTitle={600}
                    keyBuilder={(rowData) => String(rowData.id)}
                    customScrollBar={true}
                  />
                </TableWrapper>
              )}
            </>
          ) : currentTransaction.marked_without_document ? (
            <AttachBlock attach={true}>
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.attach-modal.marked-without-document`,
                })}
                textStyle={{
                  fontSize: 2,
                }}
              />
            </AttachBlock>
          ) : currentTransaction.batch_document_id ? (
            <AttachBlock attach={true}>
              {documentsToAttach.full_documents.length === 1 ? (
                <>
                  {fullDocNotSelectable(
                    documentsToAttach.full_documents[0],
                    currentTransaction.amount_type
                  ).impossible && (
                    <>
                      <Ct.Spacer height={2} />
                      <Ct.Text
                        text={intl.formatMessage({
                          id: `bank-management.transaction.attach-modal.not-selectable.${
                            fullDocNotSelectable(
                              documentsToAttach.full_documents[0],
                              transaction.amount_type
                            ).documentType
                          }`,
                        })}
                        textStyle={{
                          color: "amaranth",
                        }}
                      />
                      <Ct.Spacer height={3} />
                    </>
                  )}

                  <Button
                    label={intl.formatMessage({
                      id: "bank-management.transaction.attach-modal.attach-doc",
                    })}
                    width={35}
                    onClick={() => {
                      goToFullDoc("import")
                    }}
                  />
                </>
              ) : documentsToAttach.batch_data &&
                documentsToAttach.batch_data.batch_original !== null ? (
                <>
                  <Text
                    text={intl.formatMessage(
                      {
                        id: "bank-management.transaction.details.batch-duplicate",
                      },
                      {
                        currentName: batch_document?.original_file_name,
                        currentDate: batch_document?.created_at,
                        currentEmail: batch_document?.email,
                      }
                    )}
                    textStyle={{
                      textAlign: "center",
                      color: "orange",
                    }}
                  />
                  <Ct.Spacer height={3} />

                  <Button
                    label={intl.formatMessage({
                      id: "bank-management.transaction.attach-modal.attach-original",
                    })}
                    colorType={"Tertiary"}
                    colorScheme={{
                      background: "orange",
                      color: "white",
                      border: "orange",
                    }}
                    width={35}
                    onClick={() => {
                      if (
                        documentsToAttach.batch_data &&
                        documentsToAttach?.batch_data.batch_original !== null &&
                        documentsToAttach?.batch_data.batch_original.id
                      ) {
                        goToFullDoc("import")
                      }
                    }}
                  />
                </>
              ) : documentsToAttach.batch_data?.has_archive_to_generate ? (
                <>
                  <Ct.RowCenter>
                    <StyledBulb />
                    <Ct.Spacer width={1} />
                    <Text
                      text={intl.formatMessage(
                        {
                          id: "bank-management.transaction.details.batch-to-generate",
                        },
                        {
                          currentName: batch_document?.original_file_name,
                          currentDate: batch_document?.created_at,
                          currentEmail: batch_document?.email,
                        }
                      )}
                      textStyle={{
                        textAlign: "center",
                        color: "purple",
                      }}
                    />
                  </Ct.RowCenter>
                  <Text
                    text={intl.formatMessage({
                      id: "bank-management.transaction.details.batch-to-generate2",
                    })}
                    textStyle={{
                      textAlign: "center",
                      color: "purple",
                    }}
                  />
                  <Ct.Spacer height={3} />

                  <Button
                    label={intl.formatMessage({
                      id: "bank-management.transaction.attach-modal.go-to-generate",
                    })}
                    onClick={() =>
                      navigate(`/office/company/${selectedCompanyId}/writings`)
                    }
                  />
                </>
              ) : (
                <>
                  <Button label={""} loadingStatus={"loading"} />
                  <Ct.Spacer height={3} />
                  <Text
                    text={intl.formatMessage(
                      {
                        id: "bank-management.transaction.attach-modal.batch-doc",
                      },
                      {
                        fileName: batch_document?.original_file_name,
                        date: batch_document?.created_at,
                        email: batch_document?.email,
                      }
                    )}
                    textStyle={{
                      textAlign: "center",
                    }}
                  />
                </>
              )}
            </AttachBlock>
          ) : (
            <AttachBlock attach={true}>
              <RowCenter onClick={() => goToFullDoc("attach")}>
                <StyledFilePlus />
                <Ct.Spacer />
                <TextClickable
                  text={intl.formatMessage({
                    id: `bank-management.transaction.attach-modal.full-doc.click-to-attach`,
                  })}
                  textStyle={{
                    color: "cornflower",
                    cursor: "pointer",
                  }}
                />
              </RowCenter>
            </AttachBlock>
          )}
          <Ct.Spacer height={2} />
        </Wrapper>

        {deleteRule && (
          <DeleteRuleModal
            isDisplayed={!!deleteRule}
            onClose={() => {
              setDeleteRule(null)
            }}
            rule={deleteRule}
            deleteRule={() => {
              dispatch(
                DeleteRuleThunk(
                  accountID || 0,
                  deleteRule.id,
                  deleteRule.priority_number
                    ? "transactions"
                    : "rule_without_document"
                )
              )
              setDeleteRule(null)
            }}
          />
        )}
        <Ct.Spacer height={3} />
      </StyledCard>
    </Ct.Modal>
  )
}

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const ColumnRowRow = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 2rem;
`
const Row = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  align-items: center;
  padding-top: 2rem;
  cursor: pointer;
`
const TableWrapper = styled.div`
  border-radius: 2.5rem;
  margin: 0 0 4rem 0rem;
  width: 100%;
  height: 15rem;
`
const Rules = styled.div`
  align-items: flex-start;
  width: 100%;
`
const RulesWrapperOverFlow = styled.div`
  overflow-y: auto;
  height: 25rem;
  width: 100%;
`
const RuleCard = styled(Ct.Card)<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 2rem 4rem;
  background-color: ${({ disabled }) =>
    disabled ? `${colors.desertStorm}` : `${colors.white}`};
`

const RuleActions = styled.div`
  display: flex;
  align-items: center;
`

const Action = styled.span<{ size: string; asc?: boolean }>`
  width: ${({ size }) => `${size}`};
  height: ${({ size }) => `${size}`};
  cursor: pointer;
  & path {
    fill: ${({ asc }) =>
      asc === false || asc === null
        ? `${colors.lavender}`
        : `${colors.cornflower}`};
  }
`
const Wrapper = styled.div`
  overflow-y: auto;
  width: 95%;
`
const WrapperAlert = styled.div`
  width: 50%;
  margin: 0 auto;
`
const Tabs = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin: 0 1rem;
  border-bottom: ${({ disabled }) =>
    disabled ? `1px solid ${colors.moon}` : `1px solid ${colors.navy}`};
`

const Part = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
  color: ${({ disabled, selected }) =>
    disabled && !selected
      ? `${colors.moon}`
      : selected
      ? `${colors.white}`
      : `${colors.navy}`};
  & path {
    fill: ${({ selected, disabled }) =>
      disabled && !selected
        ? `${colors.moon}`
        : selected
        ? `${colors.white}`
        : `${colors.navy}`};
  }
  background-color: ${({ disabled, selected }) =>
    disabled && selected
      ? `${colors.moon}`
      : selected
      ? `${colors.navy}`
      : `${colors.white}`};
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px -5px 10px rgba(2, 76, 248, 0.15);
`

const ContentTab = styled.div`
  border: 1px solid ${colors.lavender};
  margin: 0rem 0rem 1rem 1rem;
  width: 96%;
  padding: 1rem 1.6rem 1rem 1rem;
  border-radius: 0 0 1rem 1rem;
`

const WrapperFullDocText = styled.div`
  align-items: flex-start;
  width: 96%;
`
const AttachBlock = styled.div<{ attach: boolean }>`
  background-color: ${({ attach }) =>
    attach ? `${colors.white}` : `${colors.amaranthLight}`};
  border: ${({ attach }) => (attach ? `1px solid ${colors.moon}` : `none`)};
  width: 98%;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
`

const StyledFilePlus = styled(({ ...props }) => <FilePlus {...props} />)`
  & path {
    fill: ${colors.cornflower};
  }
`
const RowCenter = styled(Ct.Row)`
  align-items: center;
`

const TextClickable = styled((props) => <Ct.Text {...props} />)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const StyledBulb = styled(({ ...props }) => <Bulb {...props} />)`
  & path {
    fill: ${colors.purple};
  }
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const StyledCard = styled((props) => <Ct.Card {...props} />)`
  justify-content: flex-start;
`
