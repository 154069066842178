import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import {
  ModalComponentsProps,
  Modal,
  Title,
  Spacer,
  JustifyCenter,
  Text,
  Checkbox,
} from "ldlj"
import styled from "styled-components/macro"
import { ReactComponent as CloseCrossSVG } from "../assets/close-cross.svg"
import { ReactComponent as Trash } from "../assets/drop-documents/TrashCan.svg"
import { colors } from "../styles/design.config"
import { useEffect, useState } from "react"
import {
  AdditionalLogo,
  deleteInvoicingAdditionalLogoThunk,
  updateInvoicingAdditionalLogoThunk,
} from "../store/ducks/invoicing.duck"
import { Alert } from "./Commons/Alert"
import config from "../config"
import { copyObject } from "../utils/object"

interface EditLogosModalProps extends ModalComponentsProps {
  logosList: AdditionalLogo[] | []
  selectedCompanyId: number | null
}

export const EditInvoicingAdditionalLogosModal = ({
  onClose,
  isDisplayed,
  logosList,
  selectedCompanyId,
}: EditLogosModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [updatedLogosList, setUpdatedLogosList] = useState<AdditionalLogo[]>([])

  const [displayedLogos, setDisplayedLogos] = useState<AdditionalLogo[]>()

  const [defaultLogos, setDefaultLogos] = useState<AdditionalLogo[]>([])

  useEffect(() => {
    if (!isDisplayed) {
      resetModalStates()
    } else {
      setDisplayedLogos(copyObject(logosList))
      if (!defaultLogos.length) {
        setDefaultLogos([...logosList.filter((logo) => logo.is_default)])
      }
    }
  }, [isDisplayed, logosList])

  const handleSaveClick = () => {
    if (selectedCompanyId) {
      updatedLogosList.forEach((logo) => {
        dispatch(
          updateInvoicingAdditionalLogoThunk(
            selectedCompanyId,
            logo.original_file_name,
            logo.is_default,
            logo.id
          )
        )
      })
    }
    onClose()
  }

  const resetModalStates = () => {
    setDisplayedLogos([])
    setDefaultLogos([])
    setUpdatedLogosList([])
  }

  const handleClickCard = (currentLogo: AdditionalLogo) => {
    if (!selectedCompanyId) {
      return
    }

    if (defaultLogos.length >= 3) {
      if (!defaultLogos.find((logo) => logo.id === currentLogo.id)) {
        return
      }
    }

    // eslint-disable-next-line camelcase
    currentLogo.is_default = !currentLogo.is_default
    let newLogosList = [...updatedLogosList]
    let newDefaultLogos = [...defaultLogos]
    if (currentLogo.is_default) {
      newDefaultLogos.push(currentLogo)
    } else {
      newDefaultLogos = newDefaultLogos.filter(
        (logo) => logo.id !== currentLogo.id
      )
    }

    if (
      updatedLogosList.length > 0 &&
      updatedLogosList.find((logo) => logo.id === currentLogo.id)
    ) {
      newLogosList = newLogosList.filter((logo) => logo.id !== currentLogo.id)
    } else {
      newLogosList.push(currentLogo)
    }
    setUpdatedLogosList(newLogosList)
    setDefaultLogos(newDefaultLogos)
  }

  return (
    <Modal isDisplayed={isDisplayed} onClose={onClose} top={"14rem"}>
      <StyledCard>
        <HeaderPreviewModal>
          <TextWrapper>
            <Title
              text={intl.formatMessage({
                id: "invoicing.bill-settings.additional-logos.modal.title",
              })}
              size={3}
            />
          </TextWrapper>
          <CrossWrapper onClick={() => onClose()}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Line />
        <Spacer height={2} />

        {displayedLogos && displayedLogos.length > 0 ? (
          <ContentWrapper>
            <JustifyCenter>
              <Text
                text={intl.formatMessage({
                  id: "invoicing.bill-settings.additional-logos.modal.header",
                })}
                textStyle={{ lineHeight: 3, fontWeight: 500 }}
              />
            </JustifyCenter>
            <Spacer height={3} />
            <Content>
              {displayedLogos.map((logo, i) => (
                <LogoWrapper key={i}>
                  <LogoCard
                    key={i}
                    color={
                      defaultLogos.find((l) => l.id === logo.id)
                        ? "shamrock"
                        : "lavender"
                    }
                    onClick={() => {
                      handleClickCard(logo)
                    }}
                  >
                    <StyledCompanyLogo
                      src={`${config.invoicingAdditionalLogosBucketUri}${logo.file_name}`}
                      alt="logo"
                    />

                    <CheckboxDiv>
                      <Checkbox
                        label={""}
                        name={""}
                        isChecked={
                          defaultLogos.find((l) => l.id === logo.id)
                            ? true
                            : false
                        }
                        onChange={() => {}}
                      />
                    </CheckboxDiv>
                  </LogoCard>
                  <StyledTextWrapper key={logo.original_file_name}>
                    <Text
                      text={logo.original_file_name}
                      textStyle={{ color: "navy", fontWeight: 600 }}
                    />
                    <TrashCanDiv>
                      <StyledTrashCan
                        onClick={() => {
                          if (selectedCompanyId) {
                            dispatch(
                              deleteInvoicingAdditionalLogoThunk(
                                selectedCompanyId,
                                logo.original_file_name,
                                logo.id
                              )
                            )
                            setUpdatedLogosList(
                              updatedLogosList.filter(
                                (logoU) => logoU.id !== logo.id
                              )
                            )
                          }
                        }}
                      />
                    </TrashCanDiv>
                  </StyledTextWrapper>
                </LogoWrapper>
              ))}
            </Content>
          </ContentWrapper>
        ) : (
          <AlertContent>
            <AlertWrapper>
              <Alert alertType={"info"} boxSizing={"border-box"} stretch={true}>
                <Text
                  text={intl.formatMessage({
                    id: "invoicing.bill-settings.additional-logos.modal.empty",
                  })}
                  textStyle={{ lineHeight: 3 }}
                />
              </Alert>
            </AlertWrapper>
          </AlertContent>
        )}

        <ButtonsPanel>
          <StyledButton
            onClick={() => {
              onClose()
            }}
          >
            {intl.formatMessage({
              id: `office-company-deactivate.modal.back`,
            })}
          </StyledButton>
          <Spacer width={5} />
          <StyledButton
            disabled={updatedLogosList.length === 0}
            onClick={() => {
              handleSaveClick()
            }}
          >
            {intl.formatMessage({
              id: `office-company-deactivate.modal.save`,
            })}
          </StyledButton>
        </ButtonsPanel>
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled.div`
  border-radius: 2.5rem;
  background-color: ${colors.white};
  padding: 5rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150rem;
  overflow: hidden;
  flex: 1;
`
const ButtonsPanel = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  width: 100%;
`
const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 3rem 0;
`
const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 50rem;
  overflow-y: hidden;
  padding-bottom: 15rem;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  justify-content: center;
  grid-column-gap: 4rem;
  grid-row-gap: 15rem;
  grid-auto-rows: 20rem;
  height: 100%;
  overflow-y: auto;
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;

  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const StyledButton = styled.button`
  background: ${colors.mist};
  color: ${(props) => (props.disabled ? colors.moon : colors.cornflower)};
  border-radius: 1.25rem;
  border: none;
  width: 34rem;
  height: 4rem;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
`

const AlertWrapper = styled.div`
  width: 50rem;
`
const AlertContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
interface LogoCardProps {
  color: keyof typeof colors
}

const LogoCard = styled.div<LogoCardProps>`
  position: relative;
  cursor: pointer;
  width: 20rem;
  display: flex;
  justify-content: center;
  border: ${({ color }) => `2px ${colors[color]} solid`};
  border-radius: 10px;
`
const StyledCompanyLogo = styled.img`
  border-radius: 1.25rem;
  height: 15rem;
  width: 15rem;
  object-fit: contain;
  flex-shrink: 0;
  margin: 4rem;
`
const CheckboxDiv = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
`
const StyledTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  width: 85%;
  padding-top: 1rem;
  padding-left: 4px;
`
const StyledTrashCan = styled(Trash)`
  cursor: pointer;
  min-width: 3rem;
  min-height: 3rem;
`
const TrashCanDiv = styled.div`
  position: absolute;
  right: -4rem;
  top: 1rem;
`
