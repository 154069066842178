import axios, { AxiosError } from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"

import { addOrReplaceBy } from "../../utils/array"
import { RootState } from "../rootReducer"
import * as R from "ramda"
import { getDifferenceInDays } from "../../utils/fiscalYears"
import { buyOrSell } from "../../utils/company"

const enum FiscalYearsActionsEnum {
  GET_FISCAL_YEARS_ATTEMPT = "FISCAL_YEARS/getFiscalYearsAttempt",
  GET_FISCAL_YEARS_SUCCESS = "FISCAL_YEARS/getFiscalYearsSuccess",
  GET_FISCAL_YEARS_ERROR = "FISCAL_YEARS/getFiscalYearsError",
  GET_FISCAL_YEARS_RESET = "FISCAL_YEARS/getFiscalYearsReset",

  CREATE_FISCAL_YEARS_ATTEMPT = "FISCAL_YEARS/createFiscalYearAttempt",
  CREATE_FISCAL_YEARS_SUCCESS = "FISCAL_YEARS/createFiscalYearSuccess",
  CREATE_FISCAL_YEARS_ERROR = "FISCAL_YEARS/createFiscalYearError",
  CREATE_FISCAL_YEARS_RESET = "FISCAL_YEARS/createFiscalYearReset",

  UPDATE_FISCAL_YEARS_ATTEMPT = "FISCAL_YEARS/updateFiscalYearAttempt",
  UPDATE_FISCAL_YEARS_SUCCESS = "FISCAL_YEARS/updateFiscalYearSuccess",
  UPDATE_FISCAL_YEARS_ERROR = "FISCAL_YEARS/updateFiscalYearError",
  SELECT_FISCAL_YEAR = "FISCAL_YEARS/selectFiscalYear",

  FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ATTEMPT = "FISCAL_YEARS/findMostRecentFiscalYearWithFullDocumentsAttempt",
  FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_SUCCESS = "FISCAL_YEARS/findMostRecentFiscalYearWithFullDocumentsSuccessAction",
  FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ERROR = "FISCAL_YEARS/findMostRecentFiscalYearWithFullDocumentsErrorAction",
}

export const getFiscalYearsAttemptAction = () =>
  ({ type: FiscalYearsActionsEnum.GET_FISCAL_YEARS_ATTEMPT } as const)
export const getFiscalYearsSuccessAction = (
  fiscalYears: FiscalYearApi[],
  companyId: number
) =>
  ({
    type: FiscalYearsActionsEnum.GET_FISCAL_YEARS_SUCCESS,
    fiscalYears,
    companyId,
  } as const)
export const getFiscalYearsErrorAction = () =>
  ({ type: FiscalYearsActionsEnum.GET_FISCAL_YEARS_ERROR } as const)
export const getFiscalYearsResetAction = () =>
  ({ type: FiscalYearsActionsEnum.GET_FISCAL_YEARS_RESET } as const)

export const createFiscalYearAttemptAction = () =>
  ({ type: FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_ATTEMPT } as const)
export const createFiscalYearSuccessAction = ({
  fiscalYear,
}: {
  fiscalYear: FiscalYear
}) =>
  ({
    type: FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "office-company-fiscal-years.create.success.title",
      bodyKey: "office-company-fiscal-years.create.success.body",
    },
    payload: fiscalYear,
  } as const)
export const createFiscalYearErrorConflictAction = (body: string) =>
  ({
    type: FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office-company-fiscal-years.create-update.error.title",
      bodyKey: `office-company-fiscal-years.create-update.error.${body}`,
    },
  } as const)

export const createFiscalYearErrorAction = (error: AxiosError) =>
  ({
    type: FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_ERROR,
    error,
  } as const)
export const createFiscalYearResetAction = () =>
  ({
    type: FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_RESET,
  } as const)

export const updateYearAttemptAction = () =>
  ({ type: FiscalYearsActionsEnum.UPDATE_FISCAL_YEARS_ATTEMPT } as const)
export const updateYearsSuccessAction = (
  companyId: number,
  fiscalYears: FiscalYear[]
) =>
  ({
    type: FiscalYearsActionsEnum.UPDATE_FISCAL_YEARS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "office-company-fiscal-years.update.success.title",
      bodyKey: "office-company-fiscal-years.update.success.body",
    },
    payload: {
      companyId,
      fiscalYears,
    },
  } as const)
export const updateYearErrorConflictAction = (body: string) =>
  ({
    type: FiscalYearsActionsEnum.UPDATE_FISCAL_YEARS_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "office-company-fiscal-years.create-update.error.title",
      bodyKey: `office-company-fiscal-years.create-update.error.${body}`,
    },
  } as const)

export const updateYearErrorAction = (error: AxiosError) =>
  ({ type: FiscalYearsActionsEnum.UPDATE_FISCAL_YEARS_ERROR, error } as const)
export const selectFiscalYearAction = (fyid: number | null) =>
  ({
    type: FiscalYearsActionsEnum.SELECT_FISCAL_YEAR,
    fyid,
  } as const)

export const findMostRecentFiscalYearWithFullDocumentsAttempt = () =>
  ({
    type: FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ATTEMPT,
  } as const)

export const findMostRecentFiscalYearWithFullDocumentsSuccessAction = (
  fiscalYearId: FiscalYear | null
) =>
  ({
    type: FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_SUCCESS,
    fiscalYearId,
  } as const)

export const findMostRecentFiscalYearWithFullDocumentsErrorAction = (
  error: AxiosError
) =>
  ({
    type: FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ERROR,
    error,
  } as const)

type FiscalYearsActionsType = ReturnType<
  | typeof getFiscalYearsAttemptAction
  | typeof getFiscalYearsSuccessAction
  | typeof getFiscalYearsErrorAction
  | typeof getFiscalYearsResetAction
  | typeof createFiscalYearAttemptAction
  | typeof createFiscalYearSuccessAction
  | typeof createFiscalYearErrorConflictAction
  | typeof createFiscalYearErrorAction
  | typeof createFiscalYearResetAction
  | typeof updateYearAttemptAction
  | typeof updateYearsSuccessAction
  | typeof updateYearErrorAction
  | typeof updateYearErrorConflictAction
  | typeof selectFiscalYearAction
  | typeof findMostRecentFiscalYearWithFullDocumentsAttempt
  | typeof findMostRecentFiscalYearWithFullDocumentsSuccessAction
  | typeof findMostRecentFiscalYearWithFullDocumentsErrorAction
>

export const fiscalYearsInitialState: FiscalYearsState = {
  fiscalYearsStatus: "IDLE",
  createFiscalYearsStatus: "IDLE",
  fiscalYearsByCompanyId: {},
  selectedFiscalYearId: null,
  mostRecentFiscalYearIdWithFullDocuments: null,
  mostRecentFiscalYearIdWithFullDocumentsStatus: "IDLE",
}

export interface FiscalYearsState {
  fiscalYearsStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  createFiscalYearsStatus: "IDLE" | "LOADING" | "SUCCESS" | "ERROR"
  fiscalYearsByCompanyId: {
    [index: string]: FiscalYear[]
  }
  selectedFiscalYearId: number | null
  mostRecentFiscalYearIdWithFullDocuments: FiscalYear | null
  mostRecentFiscalYearIdWithFullDocumentsStatus:
    | "IDLE"
    | "LOADING"
    | "SUCCESS"
    | "ERROR"
}

export function fiscalYearsReducer(
  state = fiscalYearsInitialState,
  action: FiscalYearsActionsType
): FiscalYearsState {
  switch (action.type) {
    case FiscalYearsActionsEnum.GET_FISCAL_YEARS_ATTEMPT:
      return { ...state, fiscalYearsStatus: "LOADING" }
    case FiscalYearsActionsEnum.GET_FISCAL_YEARS_SUCCESS:
      return {
        ...state,
        fiscalYearsStatus: "SUCCESS",
        fiscalYearsByCompanyId: {
          ...state.fiscalYearsByCompanyId,
          ...action.fiscalYears.reduce(
            (acc, fy) => ({
              ...acc,
              [fy.company_id]: [
                ...acc[fy.company_id],
                {
                  id: fy.id,
                  createdAt: fy.created_at,
                  updatedAt: fy.updated_at,
                  beginExercise: fy.begin_exercise,
                  endExercise: fy.end_exercise,
                  companyId: fy.company_id,
                },
              ],
            }),
            { [action.companyId]: [] } as FiscalYearsByCompanyId
          ),
        },
      }
    case FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_ATTEMPT: {
      return {
        ...state,
        createFiscalYearsStatus: "LOADING",
      }
    }
    case FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_SUCCESS: {
      const fyLens = R.lensPath([
        "fiscalYearsByCompanyId",
        action.payload.companyId,
      ])
      return R.set(fyLens, [action.payload], {
        ...state,
        createFiscalYearsStatus: "SUCCESS",
      })
    }
    case FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_ERROR: {
      return {
        ...state,
        createFiscalYearsStatus: "ERROR",
      }
    }
    case FiscalYearsActionsEnum.CREATE_FISCAL_YEARS_RESET: {
      return {
        ...state,
        createFiscalYearsStatus: "IDLE",
      }
    }
    case FiscalYearsActionsEnum.GET_FISCAL_YEARS_ERROR:
      return { ...state, fiscalYearsStatus: "ERROR" }
    case FiscalYearsActionsEnum.GET_FISCAL_YEARS_RESET:
      return { ...state, fiscalYearsByCompanyId: {}, fiscalYearsStatus: "IDLE" }
    case FiscalYearsActionsEnum.UPDATE_FISCAL_YEARS_SUCCESS: {
      let fiscalYearsWithUpdate =
        state.fiscalYearsByCompanyId[action.payload.companyId]

      action.payload.fiscalYears.forEach((fy) => {
        fiscalYearsWithUpdate = addOrReplaceBy<FiscalYear>({
          array: fiscalYearsWithUpdate,
          addIfItemNotFound: (f) => f.id === fy.id,
          item: fy,
        })
      })

      const fiscalYearsWithUpdateLens = R.lensPath([
        "fiscalYearsByCompanyId",
        action.payload.companyId,
      ])
      return R.set(fiscalYearsWithUpdateLens, fiscalYearsWithUpdate, state)
    }
    case FiscalYearsActionsEnum.SELECT_FISCAL_YEAR:
      return { ...state, selectedFiscalYearId: action.fyid }
    case FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        mostRecentFiscalYearIdWithFullDocumentsStatus: "LOADING",
      }
    case FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        mostRecentFiscalYearIdWithFullDocuments: action.fiscalYearId,
        mostRecentFiscalYearIdWithFullDocumentsStatus: "SUCCESS",
      }
    case FiscalYearsActionsEnum.FIND_MOST_RECENT_FISCAL_YEAR_WITH_FULL_DOCUMENTS_ERROR:
      return {
        ...state,
        mostRecentFiscalYearIdWithFullDocumentsStatus: "ERROR",
      }
    default:
      return { ...state }
  }
}

export const getFiscalYears =
  (id: number) => (dispatch: Dispatch<FiscalYearsActionsType>) => {
    dispatch(getFiscalYearsAttemptAction())

    const params = {
      id,
    }
    return axios
      .get<FiscalYearResponse>(`/companies/get_fiscal_years`, {
        params,
      })
      .then((res) => {
        dispatch(getFiscalYearsSuccessAction(res.data.fiscal_years, id))
      })
      .catch(() => {
        dispatch(getFiscalYearsErrorAction())
      })
  }

export const getMostRecentFiscalYearWithFullDocuments =
  (companyId: number, buyOrSell: buyOrSell) =>
  (dispatch: Dispatch<FiscalYearsActionsType>) => {
    dispatch(findMostRecentFiscalYearWithFullDocumentsAttempt())

    return axios
      .get<FiscalYear | null>(
        `/full_documents/find_most_recent_fiscal_year_with_full_documents`,
        {
          params: {
            company_id: companyId,
            buy_or_sell: buyOrSell,
          },
        }
      )
      .then((res) => {
        dispatch(
          findMostRecentFiscalYearWithFullDocumentsSuccessAction(res.data)
        )
      })
      .catch((e) => {
        dispatch(findMostRecentFiscalYearWithFullDocumentsErrorAction(e))
      })
  }

export interface FiscalYearsByCompanyId {
  [index: string]: FiscalYear[]
}

export interface FiscalYear {
  id: number
  createdAt?: number
  updatedAt?: number
  beginExercise: string
  endExercise: string
  companyId: number
}

export interface FiscalYearApi {
  id: number
  created_at: number
  updated_at: number
  begin_exercise: string
  end_exercise: string
  company_id: number
}

export interface FiscalYearResponse {
  fiscal_years: FiscalYearApi[]
}

export interface FiscalYearCreationResponse {
  fiscal_year: FiscalYearApi
}

export const createFiscalYear =
  (fiscalYear: Omit<FiscalYear, "id" | "createdAt" | "updatedAt">) =>
  (
    dispatch: Dispatch<FiscalYearsActionsType | RNBThunkAction>,
    getState: () => RootState
  ) => {
    dispatch(createFiscalYearAttemptAction())
    const companyId = fiscalYear.companyId
    const fiscalYearRegistrationURI = `/fiduciaries/${
      getState().fiduciary.id
    }/companies/${companyId}/fiscal_years`
    const params = {
      fiscal_year: {
        begin_exercise: fiscalYear.beginExercise,
        end_exercise: fiscalYear.endExercise,
      },
    }
    if (!fiscalYear.beginExercise || !fiscalYear.endExercise) {
      dispatch(createFiscalYearErrorConflictAction("empty-date"))
      return
    }

    const FiscalYears = getState().fiscalYears.fiscalYearsByCompanyId[companyId]
    const endExercises = FiscalYears.map((fy: FiscalYear) => {
      return fy.endExercise
    })

    if (endExercises.includes(fiscalYear.beginExercise)) {
      dispatch(createFiscalYearErrorConflictAction("conflict"))
      return
    }

    return axios
      .post<{ id: number }>(fiscalYearRegistrationURI, params)
      .then((res) => {
        const fiscalYearCreated = {
          id: res.data.id,
          ...fiscalYear,
        }
        dispatch(
          createFiscalYearSuccessAction({ fiscalYear: fiscalYearCreated })
        )
        dispatch(getFiscalYears(fiscalYear.companyId))
      })
      .catch((e) => {
        const errorMessage = errorFiscalYearMessage(e.response?.data.error)

        if (errorMessage !== "") {
          dispatch(createFiscalYearErrorConflictAction(errorMessage))
        } else {
          dispatch(createFiscalYearErrorAction(e))
        }
      })
  }

export const updateManyFiscalYears =
  (fiscalYears: FiscalYear[]) =>
  (dispatch: Dispatch<FiscalYearsActionsType>) => {
    dispatch(updateYearAttemptAction())
    const fiscalYearUpdateURI = `/fiscal_years/update_many_fiscal_years`

    const companyId = fiscalYears[0].companyId
    const params = {
      company_id: companyId,
      fiscal_years: fiscalYears,
    }

    for (let i = 0; i < fiscalYears.length - 1; i++) {
      if (
        !(
          getDifferenceInDays(
            fiscalYears[i].endExercise,
            fiscalYears[i + 1].beginExercise
          ) === 1
        )
      ) {
        dispatch(updateYearErrorConflictAction("contiguous"))
        return
      }
      const diffInDays =
        Math.abs(
          +new Date(fiscalYears[i].endExercise) -
            +new Date(fiscalYears[i].beginExercise)
        ) /
        (1000 * 60 * 60 * 24)
      if (diffInDays === 0) {
        dispatch(updateYearErrorConflictAction("no-match"))
        return
      }
    }

    return axios
      .put<{ id: number }>(fiscalYearUpdateURI, params)
      .then(() => {
        dispatch(updateYearsSuccessAction(companyId, fiscalYears))
      })
      .catch((e) => {
        const errorMessage = errorFiscalYearMessage(e.response?.data.error)

        if (errorMessage !== "") {
          dispatch(updateYearErrorConflictAction(errorMessage))
        } else {
          dispatch(updateYearErrorAction(e))
        }
      })
  }

const errorFiscalYearMessage = (message: string) => {
  switch (true) {
    case /Il y a une autre année fiscale en conflit sur ces dates/.test(
      message
    ):
      return "conflict"
    case /Vous ne pouvez pas créer d'exercice plus de 5 ans avant aujourd'hui/.test(
      message
    ):
      return "before-today"
    case /Vous ne pouvez pas créer d'exercice plus de 5 ans après aujourd'hui/.test(
      message
    ):
      return "after-today"
    case /Vos années fiscales doivent être contiguës : merci de vérifier les dates pour continuer/.test(
      message
    ):
      return "contiguous"
    case /Vous ne pouvez pas créer d'exercice d'une durée supérieure à 5 ans/.test(
      message
    ):
      return "distant-dates"
    case /Les dates ne coïncident pas/.test(message):
      return "no-match"
    case /invalid date/.test(message):
      return "empty-date"
    default:
      return ""
  }
}
