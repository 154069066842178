import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"
import { ReactComponent as FourOFour } from "../../assets/illustration_404.svg"
import * as Ct from "ldlj"
import { setCompanyStatusForUser } from "../../store/ducks/companies.ducks"
import { useDispatch } from "react-redux"
import { Background } from "../../components/Background"

const NotFound404 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const redirectHome = () => {
    // We need to decide where this should really redirect to since there is no actual home page
    dispatch(setCompanyStatusForUser())
    navigate("/")
  }

  return (
    <Background centeredTop={true}>
      <Ct.Card width={"125rem"} height={"71rem"} radius={1.5}>
        <Ct.Spacer height={2} />
        <StyledFourOFour />
        <Ct.Spacer height={5} />
        <StyledErrorText
          text={intl.formatMessage({ id: "404.text" })}
          textStyle={{ textTransform: "initial", fontSize: 2.25 }}
        />
        <Ct.Spacer height={5} />
        <Ct.Button
          width={31}
          height={7}
          label={intl.formatMessage({ id: "404.link" })}
          onClick={redirectHome}
        />
        <Ct.Spacer height={3} />
      </Ct.Card>
    </Background>
  )
}

export default NotFound404

const StyledFourOFour = styled(FourOFour)`
  width: 100%;
  height: 100%;
`

const StyledErrorText = styled(Ct.Text)`
  font-weight: 800;
`
