import axios from "axios"
import { Dispatch } from "react"

const enum PreviewFullDocumentActionsEnum {
  GET_FULL_DOCUMENT_ATTEMPT = "PREVIEW_FULL_DOCUMENT/getFullDocumentAttempt",
  GET_FULL_DOCUMENT_SUCCESS = "PREVIEW_FULL_DOCUMENT/getFullDocumentSuccess",
  GET_FULL_DOCUMENT_FAILURE = "PREVIEW_FULL_DOCUMENT/getFullDocumentFailure",
}

export const getFullDocumentAttemptAction = () =>
  ({ type: PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_ATTEMPT } as const)

export const getFullDocumentSuccessAction = (payload: PreviewFullDocumentAPI) =>
  ({
    type: PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_SUCCESS,
    payload,
  } as const)

export const getFullDocumentFailureAction = (payload: { id: number }) =>
  ({
    type: PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_FAILURE,
    payload,
  } as const)

type PreviewFullDocumentActionsType = ReturnType<
  | typeof getFullDocumentAttemptAction
  | typeof getFullDocumentSuccessAction
  | typeof getFullDocumentFailureAction
>

export const previewFullDocumentInitialState: PreviewFullDocumentState = {
  fullDocuments: {},
  loadingState: "idle",
}

export interface PreviewFullDocumentState {
  fullDocuments: {
    [index: number]: PreviewFullDocumentAPI | undefined
  }
  loadingState: "idle" | "loading" | "success" | "error"
}

export function previewFullDocumentReducer(
  state = previewFullDocumentInitialState,
  action: PreviewFullDocumentActionsType
): PreviewFullDocumentState {
  switch (action.type) {
    case PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_ATTEMPT:
      return { ...state, loadingState: "idle" }
    case PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_SUCCESS:
      const fullDocument = state.fullDocuments[action.payload.id]
      const newFullDocument = fullDocument
        ? { ...fullDocument, ...action.payload }
        : action.payload
      return {
        ...state,
        loadingState: "success",
        fullDocuments: {
          ...state.fullDocuments,
          [newFullDocument.id]: newFullDocument,
        },
      }
    case PreviewFullDocumentActionsEnum.GET_FULL_DOCUMENT_FAILURE:
      return { ...state, loadingState: "error" }
    default:
      return { ...state }
  }
}

interface PreviewFullDocumentAPI {
  id: number
  get_url: null | string
}

export const loadSingleFullDocumentThunk =
  (fullDocumentId: number) =>
  (dispatch: Dispatch<PreviewFullDocumentActionsType>) => {
    axios
      .get<PreviewFullDocumentAPI>("/full_documents/get_single_full_document", {
        params: { id: fullDocumentId },
      })
      .then((response) => dispatch(getFullDocumentSuccessAction(response.data)))
      .catch(() =>
        dispatch(getFullDocumentFailureAction({ id: fullDocumentId }))
      )
  }
