import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import {
  getCompanyNumberingsThunk,
  Numbering,
} from "../../../../../store/ducks/numberings.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { getIdFromParams } from "../../../../../utils/company"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as Settings } from "../../../../../assets/settingsAdjustVertically.svg"
import { CreateOrModifyNumberingModal } from "./CreateOrModifyNumberingModal"
import { NumberingHistoryModal } from "./NumberingHistoryModal"
import { DeleteNumberingModal } from "./DeleteNumberingModal"
import { boxShadow, colors } from "../../../../../styles/design.config"
import { Alert } from "../../../../../components/Commons/Alert"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../../components/Commons/Table"
import {
  sortNumberingsAlphabetically,
  sortNumberingsByDate,
  sortNumberingsByEmail,
  searchNumberings,
} from "../../../../../utils/numberings"
import { IconActions } from "../../../../../components/IconActions"
import { usePopperTooltip } from "react-popper-tooltip"
import "react-popper-tooltip/dist/styles.css"

export const Numberings = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const intl = useIntl()

  const [numberingForHistory, setNumberingForHistory] =
    useState<Numbering | null>(null)
  const [displayCreateModal, setDisplayCreateModal] = useState(false)
  const [numberingToModify, setNumberingToModify] = useState<Numbering | null>(
    null
  )
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [numberingIdToDelete, setNumberingIdToDelete] = useState<number | null>(
    null
  )
  const [search, setSearch] = useState("")

  const selectedCompanyId = getIdFromParams(params)("company_id")

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getCompanyNumberingsThunk(selectedCompanyId))
    }
  }, [dispatch, selectedCompanyId])

  const numberings = useRNBSelector((state) =>
    selectedCompanyId ? state.numberings.numberings[selectedCompanyId] : []
  )

  const [displayedNumberings, setDisplayedNumberings] = useState<Numbering[]>(
    []
  )

  const numberingColumns = [
    {
      headerText: "invoicing.numbering.table.numbering",
      content: (numbering: Numbering) => (
        <NumberingWrapper>
          <Ct.Text
            text={numbering.numbering}
            textStyle={{ fontSize: 1.75, fontWeight: 600 }}
          />
          <Ct.Spacer height={1.5} />
          <TypeWrapper>
            {numbering.type === "invoice"
              ? "FACTURE"
              : numbering.type === "creditNote"
              ? "AVOIR"
              : "DEVIS"}
          </TypeWrapper>
        </NumberingWrapper>
      ),
    },
    {
      headerText: "invoicing.numbering.table.creation-date",
      content: (numbering: Numbering) => (
        <Ct.Text text={numbering.created_at} />
      ),
    },
    {
      headerText: "invoicing.numbering.table.creation-email",
      content: (numbering: Numbering) => (
        <Ct.Text text={numbering.user_email} />
      ),
    },
    {
      headerText: "invoicing.numbering.table.action",
      content: (numbering: Numbering) => (
        <IconActions
          actionsToDisplay={["rename", "delete", "history"]}
          isRenameDisabled={numbering.already_used}
          renameDisabledTooltip={intl.formatMessage({
            id: "office.company.numberings.disabled",
          })}
          onRename={() => {
            setNumberingToModify(numbering)
          }}
          onHistory={() => {
            setNumberingForHistory(numbering)
          }}
          onDelete={() => {
            setNumberingIdToDelete(numbering.id)
            setDisplayDeleteModal(true)
          }}
          deleteAction={"enable"}
        />
      ),
    },
  ]

  useEffect(() => {
    if (numberings) {
      setDisplayedNumberings([...numberings])
    }
  }, [numberings])

  enum SortOptionsValues {
    "numbering",
    "date",
    "email",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.numbering) {
      setDisplayedNumberings(
        sortNumberingsAlphabetically(
          searchNumberings(displayedNumberings, search),
          asc
        )
      )
    } else if (option === SortOptionsValues?.date) {
      setDisplayedNumberings(
        sortNumberingsByDate(searchNumberings(displayedNumberings, search), asc)
      )
    } else if (option === SortOptionsValues?.email) {
      setDisplayedNumberings(
        sortNumberingsByEmail(
          searchNumberings(displayedNumberings, search),
          asc
        )
      )
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setDisplayedNumberings(searchNumberings(numberings, search))
      }
    } else {
      setDisplayedNumberings(searchNumberings(numberings, search))
    }
  }, [columnToSort, search])

  const rowBackgroundColors = displayedNumberings.map((_, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })

  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip()

  return (
    <SectionWrapper>
      <StyledSection>
        <CreateOrModifyNumberingModal
          createOrModify={"create"}
          isDisplayed={displayCreateModal}
          onClose={() => {
            setDisplayCreateModal(false)
          }}
        />
        <CreateOrModifyNumberingModal
          createOrModify={"modify"}
          isDisplayed={!!numberingToModify}
          numberingToModify={numberingToModify}
          onClose={() => {
            setNumberingToModify(null)
          }}
        />
        <NumberingHistoryModal
          isDisplayed={numberingForHistory !== null}
          onClose={() => {
            setNumberingForHistory(null)
          }}
          numbering={numberingForHistory}
        />
        <DeleteNumberingModal
          numberingIdToDelete={numberingIdToDelete}
          isDisplayed={displayDeleteModal}
          onClose={() => {
            setDisplayDeleteModal(false)
          }}
        />
        <Wrapper>
          <SpacedBetweenWrapper>
            <Ct.Spacer height={4} />
            <WrapperTop>
              <Ct.Input
                label="Rechercher"
                value={search}
                suffix={<Search />}
                maxWidth={30}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                dataCy={"searchNumbering"}
                shadowed={true}
                noBorder={true}
                isSearch={true}
              />
              <OtherActionsArea ref={setTriggerRef}>
                <Ct.Button
                  label={intl.formatMessage({
                    id: "office.company.numberings.add",
                  })}
                  onClick={() => {
                    setDisplayCreateModal(true)
                  }}
                  prefix={<StyledSettings />}
                  height={5}
                  width={31}
                />
                {visible && (
                  <div
                    ref={setTooltipRef}
                    {...getTooltipProps({ className: "tooltip-container" })}
                  >
                    Tooltip element
                    <div {...getArrowProps({ className: "tooltip-arrow" })} />
                  </div>
                )}

                <Ct.Spacer width={4} />
              </OtherActionsArea>
            </WrapperTop>
            <Ct.Spacer height={3} />
            {numberings && (
              <WrapperTable>
                <Table
                  intl={intl}
                  padding="0"
                  height="100%"
                  columns={numberingColumns}
                  rows={displayedNumberings}
                  alertMessage={"office.company.numberings.no-content"}
                  paddingHeader={"0 7rem 0 4rem"}
                  paddingRows={"8px 7rem 8px 4rem"}
                  fontWeightTitle={600}
                  suffixContent={
                    <div>
                      <Ct.Spacer height={3} />
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "office.company.numberings.warning",
                          })}
                          textStyle={{
                            lineHeight: 3,
                          }}
                        />
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "office.company.numberings.no-change",
                          })}
                          textStyle={{
                            lineHeight: 3,
                          }}
                        />
                      </Alert>
                    </div>
                  }
                  sortableColumnsLength={numberingColumns.length - 1}
                  sortingMainFunction={(columnToSort) => {
                    setColumnToSort(columnToSort)
                  }}
                  currentSortColumn={currentChevron}
                  rowBackgroundColors={rowBackgroundColors}
                  customScrollBar={true}
                />
              </WrapperTable>
            )}
          </SpacedBetweenWrapper>
        </Wrapper>
      </StyledSection>
    </SectionWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
`

const WrapperTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem;
`

const WrapperTable = styled.div`
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
  height: 100%;
  overflow-y: auto;
`

const NumberingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SpacedBetweenWrapper = styled((props) => (
  <Ct.ColumnSpacedBetween {...props} />
))`
  flex: 1;
`

const TypeWrapper = styled.div`
  background: ${colors.cornflower};
  color: ${colors.white};
  border-radius: 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  width: min-content;
`
const OtherActionsArea = styled.div`
  display: flex;
`

const StyledSettings = styled(Settings)`
  & path {
    fill: white;
  }
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const SectionWrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
