import { useIntl } from "react-intl"
import {
  ModalComponentsProps,
  Modal,
  Title,
  Spacer,
  Text,
  TableHeader,
  Button,
  Alert,
} from "ldlj"
import {} from "../store/ducks/companies.ducks"
import styled from "styled-components/macro"
import { ReactComponent as CloseCrossSVG } from "../assets/close-cross.svg"
import { colors } from "../styles/design.config"
import { useRNBSelector } from "../store/rootReducer"
import { Key } from "react"
import { sortWritingArchiveDownloadByDate } from "../utils/writings"
import { DateTime } from "luxon"

interface WritingArchiveDownloadHistoryModalProps extends ModalComponentsProps {
  archiveId: number
}

export const WritingArchiveDownloadHistoryModal = ({
  onClose,
  isDisplayed,
  archiveId,
}: WritingArchiveDownloadHistoryModalProps) => {
  const intl = useIntl()

  const downloadHistoryState = useRNBSelector(
    (state) => state.writings.downloadHistoryForCurrentArchive
  )
  const userArchiveGenerator = useRNBSelector(
    (state) => state.writings.userArchiveGenerator
  )
  const userWritingValidator = useRNBSelector(
    (state) => state.writings.user_writing_validator
  )

  return (
    <Modal isDisplayed={isDisplayed} onClose={onClose} top={"14rem"}>
      <StyledCard>
        <HeaderPreviewModal>
          <TextWrapper>
            <Title
              text={intl.formatMessage({
                id: "writings.download.history.modal.title",
              })}
              size={3}
            />
          </TextWrapper>
          <CrossWrapper onClick={() => onClose()}>
            <CloseCrossSVG />
          </CrossWrapper>
        </HeaderPreviewModal>
        <Line />
        <Spacer height={5} />
        <AlignCenter>
          <Tableheader>
            <Text
              text={"Archive n°" + String(archiveId)}
              textStyle={{
                textTransform: "uppercase",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: 2.5,
                color: "navy",
              }}
            />
          </Tableheader>
          <Content>
            <Spacer height={4} />
            {userArchiveGenerator.email &&
            userArchiveGenerator.archive_generated_at ? (
              <>
                <StyledColumn>
                  {downloadHistoryState.length > 0 &&
                    sortWritingArchiveDownloadByDate(downloadHistoryState).map(
                      (event, index) => (
                        <ModalRow
                          textId="writings.download.history.modal.line"
                          userEmail={event.email}
                          date={event.created_at}
                          key={index}
                        />
                      )
                    )}
                  {userArchiveGenerator.email && (
                    <ModalRow
                      textId="writings.download.history.modal.user-archive-generator"
                      userEmail={userArchiveGenerator.email}
                      date={userArchiveGenerator.archive_generated_at}
                    />
                  )}
                  {userWritingValidator.length > 0 &&
                    userWritingValidator.map((event, index) => (
                      <ModalRow
                        textId="writings.download.history.modal.user-writing-validator"
                        userEmail={event.email}
                        date={event.writing_validated_at}
                        key={index}
                        count={event.count_fd}
                      />
                    ))}
                  <Spacer height={2} />
                </StyledColumn>
              </>
            ) : (
              <AlertContent>
                <AlertWrapper>
                  <Alert alertType={"info"}>
                    <Text
                      text={intl.formatMessage({
                        id: "writings.download.history.modal.no-content",
                      })}
                    />
                  </Alert>
                  <Spacer height={4} />
                </AlertWrapper>
              </AlertContent>
            )}
          </Content>
          <Spacer height={4} />
          <Button label={"ok"} width={50} onClick={() => onClose()} />
          <Spacer height={4} />
        </AlignCenter>
      </StyledCard>
    </Modal>
  )
}

interface ModalRowProps {
  textId: string
  userEmail: string
  date: string
  key?: Key | null | undefined
  count?: number
}

const ModalRow = ({ textId, userEmail, date, key, count }: ModalRowProps) => {
  const intl = useIntl()
  let dateFirstPart = DateTime.fromJSDate(new Date(date)).toFormat("dd/MM/yyyy")
  let dateSecondPart = DateTime.fromJSDate(new Date(date)).toFormat("HH:mm:ss")
  return (
    <StyledRow key={key}>
      <Text
        text={intl.formatMessage(
          {
            id: textId,
          },
          {
            user: userEmail,
            date1: dateFirstPart,
            date2: dateSecondPart,
            count: count,
          }
        )}
        textStyle={{ fontSize: 1.75 }}
      />
    </StyledRow>
  )
}

const StyledCard = styled.div`
  width: 140rem;
  background-color: white;
  border-radius: 10px;
`
const HeaderPreviewModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 3rem 0;
`
const TextWrapper = styled.div`
  display: flex;
  margin: auto;
`
const CrossWrapper = styled.div`
  cursor: pointer;
  position: relative;
  right: 18px;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Content = styled.div`
  width: 90%;
  outline: 1px ${colors.lavender} solid;
  outline-offset: -1px;
  border-radius: 0 0 8px 8px;
`
const StyledColumn = styled.ul`
  margin-left: 2rem;
  max-height: 50rem;
  overflow: auto;
`
const StyledRow = styled.li`
  margin-bottom: 15px;
`
const Tableheader = styled(TableHeader)`
  background-color: ${colors.lavender};
  justify-content: left;
  padding-left: 5rem;
  width: 90%;
  border-radius: 8px 8px 0 0;
`
const AlignCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const AlertWrapper = styled.div`
  width: 50rem;
`
const AlertContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
