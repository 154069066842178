import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Transaction } from "../../store/ducks/bank.ducks"
import { ChangeItem, ChangesList, Mailto, TextNoChanges } from "../ChangesList"
import { useRNBSelector } from "../../store/rootReducer"
import { DateTime } from "luxon"
import { colors, sizes } from "../../styles/design.config"
import styled from "styled-components/macro"

interface TransactionEventsProps {
  isDisplayed: boolean
  onClose: () => void
  transaction: Transaction
  bankAccountId: number
}

export const TransactionEventsModal = ({
  isDisplayed,
  onClose,
  transaction,
  bankAccountId,
}: TransactionEventsProps) => {
  const intl = useIntl()
  const events = useRNBSelector(
    (state) =>
      state.bank.accounts[bankAccountId].transactions?.find(
        (t) => t.transaction_id === transaction.transaction_id
      )?.events
  )

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 55rem)"
    >
      <Ct.Card width={"136rem"} padding={"0"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={4} />
        <Ct.Text
          text={intl.formatMessage({
            id: `bank-management.rules.events.title`,
          })}
          textStyle={{
            fontSize: 3,
            fontWeight: 700,
          }}
        />
        <Ct.Spacer height={4} />
        <Line />
        <Ct.Spacer height={4} />

        <Tableheader>
          <Ct.Text
            text={intl.formatMessage({
              id: "bank-management.rules.events.head-title",
            })}
            textStyle={{
              textTransform: "uppercase",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: 2.5,
              color: "navy",
            }}
          />
        </Tableheader>

        <Content>
          <ChangesList height={"32rem"}>
            {events && events?.length > 0 ? (
              <>
                {events &&
                  events.map((e) => (
                    <ChangeItem key={e.email + transaction.transaction_id}>
                      <Ct.Text
                        text={intl.formatMessage({
                          id: `bank-management.transaction.events.${e.event_type}`,
                        })}
                        textStyle={{
                          fontWeight: 700,
                        }}
                      />

                      <Mailto href={`mailto:${e.email}`}>{e.email}</Mailto>

                      <Ct.Text
                        text={
                          " (" +
                          DateTime.fromJSDate(new Date(e.created_at)).toFormat(
                            "dd/MM/yyyy HH:mm:ss"
                          ) +
                          "). "
                        }
                      />
                    </ChangeItem>
                  ))}
              </>
            ) : (
              <>
                <Ct.Spacer height={5} />
                <Centered>
                  <TextNoChanges
                    text={intl.formatMessage({
                      id: `bank-management.transaction.events.empty`,
                    })}
                  />
                </Centered>
                <Ct.Spacer height={2} />
              </>
            )}
          </ChangesList>
        </Content>
        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={onClose}
          width={sizes.button.standard}
        />

        <Ct.Spacer height={4} />
      </Ct.Card>
    </Ct.Modal>
  )
}

const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Tableheader = styled(Ct.TableHeader)`
  background-color: ${colors.lavender};
  justify-content: left;
  padding-left: 5rem;
  width: 90%;
  border-radius: 8px 8px 0 0;
`
const Content = styled.div`
  width: 90%;
  outline: 1px ${colors.lavender} solid;
  outline-offset: -1px;
  border-radius: 0 0 8px 8px;
`
