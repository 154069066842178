import { ReactComponent as LeftDisabledArrow } from "../assets/arrows/left-disabled.svg"
import { ReactComponent as RightDisabledArrow } from "../assets/arrows/right-disabled.svg"
import { ReactComponent as LeftEnabledArrow } from "../assets/arrows/left-enabled.svg"
import { ReactComponent as RightEnabledArrow } from "../assets/arrows/right-enabled.svg"
import styled from "styled-components/macro"

interface ArrowProps {
  disabled: boolean
  onClick: () => void
}

export const LeftArrow = ({ disabled, onClick }: ArrowProps) => {
  return (
    <Wrapper>
      <StyledLeftEnabledArrow disabled={disabled} />
      <StyledLeftDisabledArrow disabled={disabled} onClick={onClick} />
    </Wrapper>
  )
}

export const RightArrow = ({ disabled, onClick }: ArrowProps) => {
  return (
    <Wrapper>
      <StyledRightDisabledArrow disabled={disabled} />
      <StyledRightEnabledArrow disabled={disabled} onClick={onClick} />
    </Wrapper>
  )
}

const sharedCSS = `
  height: 6rem;
  width: 6rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s opacity ease-in-out;
`
const sharedEnabledCSS = `
  cursor: pointer;
`
const sharedDisabledCSS = `
cursor: not-allowed;
`

const Wrapper = styled.div`
  position: relative;
  height: 6rem;
  width: 6rem;
`
const StyledLeftDisabledArrow = styled(LeftDisabledArrow)<{
  disabled: boolean
}>`
  ${sharedCSS} ${sharedDisabledCSS};
  opacity: ${({ disabled }) => (disabled ? 1 : 0)};
`

const StyledLeftEnabledArrow = styled(LeftEnabledArrow)<{ disabled: boolean }>`
  ${sharedCSS}
  ${sharedEnabledCSS}
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
`
const StyledRightDisabledArrow = styled(RightDisabledArrow)<{
  disabled: boolean
}>`
  ${sharedCSS}
  ${sharedDisabledCSS}
  opacity: ${({ disabled }) => (disabled ? 1 : 0)};
`
const StyledRightEnabledArrow = styled(RightEnabledArrow)<{
  disabled: boolean
}>`
  ${sharedCSS}
  ${sharedEnabledCSS}
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
`
