import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { colors } from "../styles/design.config"
import ReactTooltip from "react-tooltip"

export type DisplayActions =
  | "preview"
  | "seeDetails"
  | "download"
  | "rename"
  | "inputMask"
  | "history"
  | "overtaking_to_credit_note"
  | "overtaking_to_invoice"
  | "ask_validation"
  | "mark_as_paid"
  | "reject"
  | "treasury_historic"
  | "mark_as_to_control"
  | "mark_as_irrecoverable"
  | "validate_and_pay"
  | "pay"
  | "reminder"
  | "duplicate"
  | "edit"

type DeleteActions = "hide" | "disable" | "enable"
type HideActions = "exchange" | "duplicate" | "writing_not_validated" | null

interface MoreActionsProps {
  showActions: boolean
  actionsToDisplay: DisplayActions[]
  downloadUrl?: string
  deleteAction: DeleteActions
  hideDetails?: HideActions
  alreadyOvertaking?: boolean
  onSetDisplayPreview?: () => void
  onSetDownload?: () => void
  onSetDisplayDetails?: () => void
  onSetDisplayMask?: () => void
  onSetOvertaking?: () => void
  onSetHistory?: () => void
  onSetReminder?: () => void
  onRename?: () => void
  onEdit?: () => void
  onDelete: () => void
  onBlur: () => void
  onSetAskValidation?: () => void
  onSetMarkAsPaid?: () => void
  onSetMarkAsToControl?: () => void
  onSetReject?: () => void
  onSetMarkAsIrrecoverable?: () => void
  onSetTreasuryHistoric?: () => void
  onSetValidateAndPay?: () => void
  onSetPay?: () => void
  onDuplicate?: () => void
  customWidth?: number
  top?: string
  canNotMarkAsPaid?: boolean
  canNotValidateAndPay?: boolean
  canNotReject?: boolean
  canNotMarkAsIrrecoverable?: boolean
  canNotPay?: boolean
  canNotAskValidation?: boolean
  canNotMarkAsToControl?: boolean
}

export const MoreActions = ({
  showActions,
  actionsToDisplay,
  downloadUrl,
  deleteAction,
  hideDetails,
  alreadyOvertaking,
  onRename,
  onEdit,
  onSetDisplayPreview,
  onSetDownload,
  onSetDisplayDetails,
  onSetDisplayMask,
  onSetHistory,
  onSetReminder,
  onSetOvertaking,
  onDelete,
  onBlur,
  onSetAskValidation,
  onSetMarkAsPaid,
  onSetReject,
  onSetTreasuryHistoric,
  onSetMarkAsToControl,
  onSetMarkAsIrrecoverable,
  onSetValidateAndPay,
  onSetPay,
  onDuplicate,
  customWidth,
  top,
  canNotMarkAsPaid = true,
  canNotValidateAndPay = true,
  canNotReject = true,
  canNotMarkAsIrrecoverable = true,
  canNotPay = true,
  canNotAskValidation = true,
  canNotMarkAsToControl = true,
}: MoreActionsProps) => {
  const intl = useIntl()

  return (
    <StyledCard
      customWidth={customWidth}
      overridePosition
      width={customWidth ? `${customWidth}rem` : "20rem"}
      showActions={showActions}
      onBlur={onBlur}
      tabIndex={0}
      contentEditable
      top={top}
    >
      {actionsToDisplay.includes("inputMask") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.inputMask" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onSetDisplayMask}
          />
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("overtaking_to_invoice") && (
        <>
          <Action
            disabled={alreadyOvertaking ? true : false}
            text={intl.formatMessage({
              id: "more-actions.overtakingToInvoice",
            })}
            textStyle={{
              cursor: alreadyOvertaking ? "default" : "pointer",
              color: alreadyOvertaking ? "moon" : "navy",
            }}
            onClick={() =>
              !alreadyOvertaking && onSetOvertaking ? onSetOvertaking() : ""
            }
          />
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("overtaking_to_credit_note") && (
        <>
          <Action
            disabled={alreadyOvertaking ? true : false}
            text={intl.formatMessage({
              id: "more-actions.overtakingToCreditNote",
            })}
            textStyle={{
              cursor: alreadyOvertaking ? "default" : "pointer",
              color: alreadyOvertaking ? "moon" : "navy",
            }}
            onClick={() =>
              !alreadyOvertaking && onSetOvertaking ? onSetOvertaking() : ""
            }
          />
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("seeDetails") && (
        <>
          <Action
            disabled={hideDetails ? true : false}
            text={intl.formatMessage({ id: "more-actions.seeDetails" })}
            textStyle={{
              cursor: hideDetails ? "default" : "pointer",
              color: hideDetails ? "moon" : "navy",
            }}
            onClick={() => {
              if (!hideDetails && onSetDisplayDetails) {
                onSetDisplayDetails()
              }
            }}
            data-tip={
              hideDetails
                ? intl.formatMessage({
                    id: `company-drop-document.tooltip.${hideDetails}`,
                  })
                : ""
            }
          />
          <ReactTooltip
            effect={"solid"}
            backgroundColor={colors.lavender}
            textColor={colors.navy}
            borderColor={colors.desertStorm}
          />
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("preview") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.preview" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onSetDisplayPreview}
          />
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("download") && (
        <>
          <a
            href={downloadUrl}
            target="_blank"
            id={"downloadFile"}
            rel="noreferrer"
            download
          >
            <Action
              text={intl.formatMessage({ id: "more-actions.download" })}
              textStyle={{
                cursor: "pointer",
              }}
              onClick={onSetDownload}
            />
          </a>
          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("rename") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.rename" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onRename}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("edit") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.edit" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onEdit}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("reminder") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.reminder" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onSetReminder}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("duplicate") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.duplicate" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onDuplicate}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("history") && (
        <>
          <Action
            text={intl.formatMessage({ id: "more-actions.history" })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onSetHistory}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("validate_and_pay") && (
        <>
          <Action
            disabled={canNotValidateAndPay ? true : false}
            text={intl.formatMessage({
              id: "treasury.buy.to-control.validate_and_pay",
            })}
            textStyle={{
              cursor: canNotValidateAndPay ? "default" : "pointer",
              color: canNotValidateAndPay ? "moon" : "navy",
            }}
            onClick={() =>
              !canNotValidateAndPay &&
              onSetValidateAndPay &&
              onSetValidateAndPay()
            }
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("ask_validation") && (
        <>
          <Action
            disabled={canNotAskValidation ? true : false}
            text={intl.formatMessage({
              id: "treasury.buy.to-control.ask_validation",
            })}
            textStyle={{
              cursor: canNotAskValidation ? "default" : "pointer",
              color: canNotAskValidation ? "moon" : "navy",
            }}
            onClick={() =>
              !canNotAskValidation && onSetAskValidation && onSetAskValidation()
            }
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("mark_as_paid") && (
        <>
          <Action
            disabled={canNotMarkAsPaid ? true : false}
            text={intl.formatMessage({
              id: "treasury.buy.to-control.mark_as_paid",
            })}
            textStyle={{
              cursor: canNotMarkAsPaid ? "default" : "pointer",
              color: canNotMarkAsPaid ? "moon" : "navy",
            }}
            onClick={() =>
              !canNotMarkAsPaid && onSetMarkAsPaid && onSetMarkAsPaid()
            }
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("reject") && (
        <>
          <Action
            disabled={canNotReject ? true : false}
            text={intl.formatMessage({ id: "treasury.buy.to-control.reject" })}
            textStyle={{
              cursor: canNotReject ? "default" : "pointer",
              color: canNotReject ? "moon" : "navy",
            }}
            onClick={() => !canNotReject && onSetReject && onSetReject()}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("pay") && (
        <>
          <Action
            disabled={canNotPay ? true : false}
            text={intl.formatMessage({ id: "treasury.buy.to-pay.pay" })}
            textStyle={{
              cursor: canNotPay ? "default" : "pointer",
              color: canNotPay ? "moon" : "navy",
            }}
            onClick={() => canNotPay && onSetPay}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("mark_as_to_control") && (
        <>
          <Action
            disabled={canNotMarkAsToControl ? true : false}
            text={intl.formatMessage({
              id: "treasury.buy.to-pay.mark_as_to_control",
            })}
            textStyle={{
              cursor: canNotMarkAsToControl ? "default" : "pointer",
              color: canNotMarkAsToControl ? "moon" : "navy",
            }}
            onClick={onSetMarkAsToControl}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("mark_as_irrecoverable") && (
        <>
          <Action
            disabled={canNotMarkAsIrrecoverable ? true : false}
            text={intl.formatMessage({
              id: "treasury.sell.to-receive.mark_as_irrecoverable",
            })}
            textStyle={{
              cursor: canNotMarkAsIrrecoverable ? "default" : "pointer",
              color: canNotMarkAsIrrecoverable ? "moon" : "navy",
            }}
            onClick={() =>
              !canNotMarkAsIrrecoverable &&
              onSetMarkAsIrrecoverable &&
              onSetMarkAsIrrecoverable()
            }
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {actionsToDisplay.includes("treasury_historic") && (
        <>
          <Action
            text={intl.formatMessage({
              id: "treasury.buy.to-control.historic",
            })}
            textStyle={{
              cursor: "pointer",
            }}
            onClick={onSetTreasuryHistoric}
          />

          <Ct.Spacer height={1.25} />
        </>
      )}

      {deleteAction !== "hide" && (
        <Delete
          disabled={deleteAction === "disable"}
          onClick={() => {
            if (deleteAction === "enable") {
              onDelete()
            }
          }}
        >
          <Action
            disabled={deleteAction === "disable"}
            text={intl.formatMessage({ id: "more-actions.delete" })}
            textStyle={{
              cursor: deleteAction === "disable" ? "default" : "pointer",
              color: deleteAction === "disable" ? "moon" : "navy",
            }}
          />
        </Delete>
      )}
    </StyledCard>
  )
}

interface DropdownMenuProps {
  showActions: boolean
  tabIndex: number
  contentEditable: boolean
  customWidth?: number
  top?: string
}

const StyledCard = styled(Ct.Card)<DropdownMenuProps>`
  position: absolute;
  top: ${({ top }) => (top ? top : "8rem")};

  right: ${({ customWidth }) =>
    customWidth ? `calc(50% - ${customWidth / 2}rem)` : "calc(50% - 10rem)"};

  align-self: flex-end;
  z-index: 99999999;
  transition: opacity 0.5s ease-in-out;
  padding: 2.5rem;
  padding-bottom: 0.5rem;
  opacity: ${({ showActions }) => (showActions ? "1" : "0")};
  display: ${({ showActions }) => (showActions ? "block" : "none")};
  box-shadow: 0px 4px 14px rgba(2, 76, 248, 0.1);

  && {
    cursor: pointer;
    align-items: center;
  }
`

interface DeleteProps {
  disabled?: boolean
}

const Delete = styled.div<DeleteProps>`
  align-items: center;
`

const Action = styled(Ct.Text)<DeleteProps>`
  display: block;
  transition: all 0.3s ease-in-out;
  width: fit-content;

  :after {
    content: " ";
    width: 100%;
    height: 1px;
    background-color: ${colors.white};
    display: ${({ disabled }) => (disabled ? "none" : "block")};
    transition: background-color 0.3s ease-in-out;
  }

  :hover {
    font-weight: ${({ disabled }) => (disabled ? "400" : "600")};

    :after {
      background-color: ${colors.navy};
    }
  }
`
