import styled from "styled-components/macro"
import { fileExtensionOfString } from "../../utils/string"

export const ImageOrPdfViewer = ({ getUrl }: { getUrl: string | null }) => {
  const fileName = getUrl ? getUrl.split("?")[0] : null
  const extension = fileName ? fileExtensionOfString(fileName) : null
  return (
    <StyledDiv>
      {getUrl !== null ? (
        <StyledObject data={getUrl} isPdf={extension === "pdf"} />
      ) : (
        <></>
      )}
    </StyledDiv>
  )
}

interface ObjectProperties {
  isPdf: boolean
}

const StyledDiv = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`

const StyledObject = styled.object<ObjectProperties>`
  width: 100%;
  box-sizing: border-box;
  height: ${({ isPdf }) => (isPdf ? "100%" : "auto")};
`
