import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import * as Ct from "ldlj"

import { getIdFromParams } from "../../utils/company"
import {
  createOrUpdateAccountResetAction,
  createAccountThunk,
  getAllAccountsThunk,
  deactivateOrReactivateAccountReset,
} from "../../store/ducks/accounts.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { useDispatch } from "react-redux"

interface CreateAccountModalProps {
  onClose: () => void
  onAccountCreated: (accountId: number) => void
  isDisplayed: boolean
  writingLineUuid: string
}

export const CreateAccountModal = ({
  isDisplayed,
  onClose,
  writingLineUuid,
}: CreateAccountModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const createOrUpdateAccountStatus = useRNBSelector(
    (state) => state.accounts.accountCreationOrUpdateStatus
  )
  const reactivateOrDeactivateAccountStatus = useRNBSelector(
    (state) => state.accounts.reactivateOrDeactivateAccountStatus
  )
  const [number, setNumber] = useState<string>("")
  const [details, setDetails] = useState<string>("")

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    if (
      createOrUpdateAccountStatus === "SUCCESS" ||
      reactivateOrDeactivateAccountStatus === "SUCCESS"
    ) {
      if (selectedCompanyId) {
        dispatch(getAllAccountsThunk(selectedCompanyId))
      }
      closeModal()
    }
  }, [createOrUpdateAccountStatus, reactivateOrDeactivateAccountStatus])

  const handleFormSubmit = () => {
    if (selectedCompanyId) {
      dispatch(
        createAccountThunk(selectedCompanyId, number, details, writingLineUuid)
      )
    }
  }

  useEffect(() => {
    if (!isDisplayed) {
      resetForm()
    }
  }, [isDisplayed])

  const resetForm = () => {
    setNumber("")
    setDetails("")
    dispatch(createOrUpdateAccountResetAction())
    dispatch(deactivateOrReactivateAccountReset())
  }

  const disableSaveButton = !number || !details

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 50rem)"
    >
      <Ct.Card width={"136rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={3} />
        <Ct.Title
          text={intl.formatMessage({
            id: `accounting-plan.accounts.modal.title.create`,
          })}
          size={3}
        />
        <Ct.Spacer height={3} />
        <VerticalLine />
        <Ct.Spacer height={5} />

        <Content>
          <Ct.Input
            value={number}
            onChange={(e) => {
              setNumber(
                isNaN(Number(e.target.value)) ||
                  [".", " "].includes(
                    e.target.value.charAt(e.target.value.length - 1)
                  )
                  ? number
                  : e.target.value
              )
            }}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.input.number",
            })}
          />
          <Ct.Spacer height={4} />

          <Ct.Input
            value={details || ""}
            onChange={(e) => {
              setDetails(e.target.value)
            }}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.input.details",
            })}
          />

          <Ct.Spacer height={4} />
        </Content>

        <Ct.Spacer height={4} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />

          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.save",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            disabled={disableSaveButton}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
        </ButtonsPanel>
      </Ct.Card>
    </Ct.Modal>
  )
}

const Content = styled.div`
  width: 100rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const VerticalLine = styled.div`
  height: 2px;
  background: ${colors.mist};
  width: 100%;
`

const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100rem;
`
