import { Outlet } from "react-router-dom"
import styled from "styled-components/macro"

export const OfficeHeader = () => {
  return (
    <StyledHeader>
      <Outlet />
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  margin: 0 4rem;
`
