import axios, { AxiosError } from "axios"
import config from "./config"

const requestsWithoutAuth = [
  "sign_in.json",
  "get_current_user",
  "check_sso_redirection",
  "fiduciary-logos.s3",
  "invoicing-logos.s3",
  "invoicing-additional-logos.s3",
  "standard-ledgers.s3",
  "batch-invoices.s3",
  "writings.s3",
  "bankin-archives.s3",
]
export const ConfigureAxios = () => {
  axios.defaults.baseURL = config.baseURL
  axios.defaults.withCredentials = true
  axios.defaults.maxRedirects = 0
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"
  axios.defaults.headers.put["Access-Control-Allow-Origin"] = "*"
  axios.defaults.headers.common["X-Client"] = "RNB"

  axios.interceptors.request.use(
    (request) => {
      // Do not set the Authorization header for sign_in
      if (
        !(
          request.headers &&
          requestsWithoutAuth.some((r) => request.url?.includes(r)) &&
          localStorage.getItem("token") !== null
        )
      ) {
        // @ts-ignore
        request.headers.common["Authorization"] = String(
          localStorage.getItem("token")
        )
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (
        error?.response?.status === 401 &&
        redirectOn401.some((redirect) =>
          error?.request?.responseURL.includes(redirect)
        ) &&
        !pathToAvoid401.some((path) => window.location.pathname.includes(path))
      ) {
        localStorage.removeItem("token")
        window.location.href = `${config.baseURL}/rnb/user/login`
      }
      return Promise.reject(error)
    }
  )
}

// During login or user creation, we want to check if we are logged in by calling /get_current_user
// However, we do not want to redirect to login in those case when we are not logged because it is the normal situation
const pathToAvoid401 = [
  "rnb/user/sso",
  "rnb/user/login",
  "rnb/user/register",
  "rnb/user/incomplete_registration",
]

const redirectOn401 = [
  "get_current_user",
  "companies_for_user",
  "get_fiduciary",
]
