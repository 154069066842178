import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import {
  createAutoliquidatedVatAccountThunk,
  VatType,
} from "../../store/ducks/companySettings.ducks"
import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"
import { isValidAutoliquidatedVatAccount } from "../../utils/accounts"

import styled from "styled-components/macro"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../store/rootReducer"
import { Alert } from "../Commons/Alert"

interface CreateAutoliquidatedVatAccountModalProps
  extends Ct.ModalComponentsProps {
  autoliquidatedVatAccountToCreate: AutoliquidatedVatAccountPayload
}

export interface AutoliquidatedVatAccountPayload {
  vatType: VatType | null
  prefix: string[]
  forbid: string[]
}

export const CreateAutoliquidatedVatModal = ({
  isDisplayed,
  onClose,
  autoliquidatedVatAccountToCreate,
}: CreateAutoliquidatedVatAccountModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const createdAutoliquidatedVatAccount = useRNBSelector(
    (state) => state.companySettings.createdAutoliquidatedVatAccount
  )

  const selectedAccount = useRNBSelector((state) =>
    autoliquidatedVatAccountToCreate.vatType
      ? state.companySettings[autoliquidatedVatAccountToCreate.vatType]
          .selected_account
      : null
  )

  const possibleAccounts = useRNBSelector((state) =>
    autoliquidatedVatAccountToCreate.vatType
      ? state.companySettings[autoliquidatedVatAccountToCreate.vatType]
          .possible_accounts
      : []
  )

  const cleanupAndClose = () => {
    setNumber("")
    setSuffix("")
    setDetails("")
    onClose()
  }

  useEffect(() => {
    if (createdAutoliquidatedVatAccount) {
      cleanupAndClose()
    }
  }, [createdAutoliquidatedVatAccount])

  const [suffix, setSuffix] = useState("")
  const [number, setNumber] = useState("")
  const [details, setDetails] = useState("")
  const prefixValue = autoliquidatedVatAccountToCreate.prefix.join("")

  useEffect(() => {
    setNumber(prefixValue + suffix)
  }, [prefixValue, suffix])

  const vatTypeText = autoliquidatedVatAccountToCreate.vatType
    ? intl.formatMessage({
        id: `office-company-settings.eu-construction-vat-accounts.${autoliquidatedVatAccountToCreate.vatType}`,
      })
    : ""

  const titleText = intl.formatMessage(
    { id: `office-company-settings.autoliquidated.title` },
    {
      vatTypeText: vatTypeText,
    }
  )

  const accountNumberIsValid = isValidAutoliquidatedVatAccount(
    number,
    autoliquidatedVatAccountToCreate.prefix,
    autoliquidatedVatAccountToCreate.forbid
  )

  const numberAccountAlreadyExists =
    (selectedAccount !== null && selectedAccount.number === number) ||
    (possibleAccounts.length > 0 &&
      possibleAccounts.find((account) => account.number === number) !==
        undefined)

  const isCreationButtonDisabled =
    !accountNumberIsValid || details.length === 0 || numberAccountAlreadyExists

  const suffixesTips = (vatType: VatType | null) => {
    switch (vatType) {
      case "eu_vat":
        return "0"
      case "eu_reverse_vat":
        return "000"
      case "eu_goods_vat":
        return "0"
      case "eu_goods_reverse_vat":
        return "000"
      case "world_vat":
        return "0"
      case "world_reverse_vat":
        return "000"
      case "world_goods_vat":
        return "0"
      case "world_goods_reverse_vat":
        return "000"
      case "construction_vat":
        return "6660"
      case "construction_reverse_vat":
        return "7770"
      default:
        return ""
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        cleanupAndClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card width="100rem">
          <CloseCrossWrapper
            onClick={() => {
              cleanupAndClose()
            }}
          >
            <CloseCrossSVG />
          </CloseCrossWrapper>

          <Ct.Title size={3} text={titleText} />
          <Ct.Spacer height={4} />
          <div>
            <Ct.Row>
              <Ct.Column>
                <Ct.Input
                  name={"suffix"}
                  label={intl.formatMessage({
                    id: "office-company-settings.vat-modal.number",
                  })}
                  value={suffix}
                  showError={numberAccountAlreadyExists}
                  onChange={(e) => {
                    setSuffix(e.target.value)
                    setNumber(prefixValue + e.target.value)
                  }}
                />
                {numberAccountAlreadyExists && (
                  <>
                    <Ct.Spacer />
                    <Ct.Text
                      text={intl.formatMessage({
                        id: "office-company-settings.autoliquidated.account-already-exists",
                      })}
                      textStyle={{ color: "radicalRed" }}
                    />
                  </>
                )}
                <Ct.Spacer />
                <Ct.Text
                  text={intl.formatMessage(
                    {
                      id: "office-company-settings.autoliquidated.account-modification.suffix.result",
                    },
                    {
                      prefixValue: prefixValue,
                      suffixValue: suffix,
                    }
                  )}
                  textStyle={{
                    fontSize: 1.75,
                  }}
                />
              </Ct.Column>
              <Ct.Spacer />

              <Ct.Column>
                <Alert alertType="bulb" centerIcon={true} stretch={false}>
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: "office-company-settings.autoliquidated.tips",
                      },
                      {
                        prefixValue: prefixValue,
                        suffixValue: suffixesTips(
                          autoliquidatedVatAccountToCreate.vatType
                        ),
                      }
                    )}
                    textStyle={{ lineHeight: 3 }}
                  />
                </Alert>
              </Ct.Column>
            </Ct.Row>
            <Ct.Spacer />
            <Ct.Row>
              <Ct.Input
                name="details"
                label={intl.formatMessage({
                  id: "office-company-settings.vat-modal.details",
                })}
                type="text"
                value={details}
                onChange={(e) => {
                  setDetails(e.target.value)
                }}
              />
            </Ct.Row>
          </div>
          {!accountNumberIsValid && (
            <>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: "office-company-settings.autoliquidated.error",
                  },
                  {
                    prefixValue:
                      autoliquidatedVatAccountToCreate.prefix.join(" ou "),
                    forbiddenPrefixText:
                      autoliquidatedVatAccountToCreate.forbid.length === 0
                        ? ""
                        : `(mais pas par  ${autoliquidatedVatAccountToCreate.forbid.join(
                            " ni "
                          )})`,
                  }
                )}
                textStyle={{ color: "radicalRed" }}
              />
            </>
          )}
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: `office-company-settings.vat-modal.cta`,
            })}
            onClick={() => {
              dispatch(
                createAutoliquidatedVatAccountThunk({
                  companyId: selectedCompanyId,
                  number,
                  details,
                  vatType: autoliquidatedVatAccountToCreate.vatType,
                })
              )
            }}
            disabled={isCreationButtonDisabled}
          />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const CloseCrossWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
