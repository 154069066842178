import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { ReactComponent as ChevronLeft } from "../../../../assets/chevron-left.svg"
import { ArchiveStatus } from "./Writings"
import {
  ArchiveDetail,
  downloadWritingThunk,
  getWritingArchiveDetailsThunk,
} from "../../../../store/ducks/writings.ducks"
import { getUrlForBatchDocumentThunk } from "../../../../store/ducks/batchDocuments.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as FileDownload } from "../../../../assets/littleFileDownload.svg"
import {
  filterArchivedBatches,
  sortBatchDocumentByEmail,
  sortDisplayedBatchByDate,
  sortDisplayedNameBatches,
  sortDocumentNameBatches,
} from "../../../../utils/batchDocuments"
import * as Ct from "ldlj"
import { getUrlForFullDocumentThunk } from "../../../../store/ducks/invoicing.duck"
import { colors } from "../../../../styles/design.config"
import {
  ChevronProps,
  SortToReturn,
  Table,
} from "../../../../components/Commons/Table"

export const WritingDetail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const [query] = useSearchParams()

  const selectedFiscalYearId = Number(useParams().selectedFiscalYearId)

  const archiveStatus = query.get("archive_status")?.toUpperCase() || ""

  const selectedCompanyId = Number(query.get("company_id")) || 0
  const accountSoftwareReference =
    query.get("accounting_software_reference") || ""

  const accountSoftware = query.get("accounting_software") || ""
  const archiveId = Number(query.get("archive_id")) || 0

  const details = useRNBSelector((state) => state.writings.detailsArchive)

  const wasDownloaded = useRNBSelector((state) => {
    const archives =
      archiveStatus === "CREATED"
        ? state.writings.data[selectedFiscalYearId]?.archives_created
        : archiveStatus === "GENERATED"
        ? state.writings.data[selectedFiscalYearId]?.archives_generated
        : []
    const archive = archives?.find((a) => a.id === archiveId)
    return Boolean(archive?.download_user)
  })

  // need to refetch here in case of refresh or access by URL
  useEffect(() => {
    if (!(selectedCompanyId && selectedFiscalYearId)) {
      return
    }
    if (archiveStatus === "PENDING") {
      dispatch(
        getWritingArchiveDetailsThunk(
          selectedFiscalYearId,
          selectedCompanyId,
          null,
          "PENDING"
        )
      )
    } else if (archiveStatus === "WRITING_READY") {
      dispatch(
        getWritingArchiveDetailsThunk(
          selectedFiscalYearId,
          selectedCompanyId,
          null,
          "WRITING_READY"
        )
      )
    } else if (archiveStatus === "READY_FOR_ARCHIVE") {
      dispatch(
        getWritingArchiveDetailsThunk(
          selectedFiscalYearId,
          selectedCompanyId,
          null,
          "READY_FOR_ARCHIVE"
        )
      )
    } else {
      dispatch(
        getWritingArchiveDetailsThunk(
          selectedFiscalYearId,
          selectedCompanyId,
          archiveId,
          undefined
        )
      )
    }
  }, [
    dispatch,
    selectedCompanyId,
    selectedFiscalYearId,
    archiveStatus,
    archiveId,
  ])

  if (!archiveStatus || !selectedCompanyId) {
    return <Navigate to={"/unauthorized"} />
  }

  const getTitleByStatus = ({
    archiveStatus,
    archiveId,
  }: {
    archiveStatus: ArchiveStatus
    archiveId: number
  }) => {
    if (["PENDING", "READY_FOR_ARCHIVE"].includes(archiveStatus)) {
      return intl.formatMessage({ id: "writing-detail.title.generic" })
    }
    return intl.formatMessage(
      { id: "writing-detail.title.specific" },
      { archiveId }
    )
  }

  return (
    <Wrapper>
      <StyledCard>
        <Ct.FlexStart css={{ paddingLeft: "4rem" }}>
          <Ct.Button
            prefix={<StyledChevronLeft />}
            height={4}
            width={13}
            label="Retour"
            onClick={() => {
              navigate(`/office/company/${selectedCompanyId}/writings`)
            }}
          />
        </Ct.FlexStart>
        <Ct.Title
          text={getTitleByStatus({
            archiveStatus: archiveStatus as ArchiveStatus,
            archiveId,
          })}
          size={3}
        />
        <Ct.Spacer />

        <ArchiveBatchDocumentsTable
          details={details}
          archiveId={archiveId}
          accountSoftwareReference={accountSoftwareReference}
          accountSoftware={accountSoftware}
          selectedCompanyId={selectedCompanyId}
          wasDownloaded={wasDownloaded}
          wasGenerated={archiveStatus === "GENERATED"}
        />
      </StyledCard>
    </Wrapper>
  )
}

const ArchiveBatchDocumentsTable = ({
  details,
  archiveId,
  accountSoftwareReference,
  accountSoftware,
  selectedCompanyId,
  wasDownloaded,
  wasGenerated,
}: {
  details: ArchiveDetail[]
  archiveId: number
  accountSoftwareReference: string
  accountSoftware: string
  selectedCompanyId: number
  wasDownloaded: boolean
  wasGenerated: boolean
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [search, setSearch] = useState("")
  const [displayedBatches, setDisplayedBatches] =
    useState<ArchiveDetail[]>(details)

  useEffect(() => {
    if (details.length === 0) {
      return
    }
    if (search === "") {
      setDisplayedBatches([...details])
      return
    }
    const filteredBatches = filterArchivedBatches([...details], search)
    setDisplayedBatches(filteredBatches)
  }, [search, details])

  const columns: Ct.TableBuilder<ArchiveDetail>[] = [
    {
      headerText: "writing-detail.batch-table.document",
      content: (batch: ArchiveDetail) =>
        batch.full_doc_id ? (
          <>
            <FileDownload />
            <Ct.Spacer width={1} />
            <StyledLink
              onClick={() => {
                if (batch.full_doc_id) {
                  dispatch(getUrlForFullDocumentThunk(batch.full_doc_id))
                }
              }}
            >
              {batch.full_doc_name}
            </StyledLink>
          </>
        ) : (
          <Ct.Text
            text={batch.full_doc_name}
            textStyle={{
              textAlign: "center",
            }}
          />
        ),
    },
    {
      headerText: "writing-detail.batch-table.batch",
      content: (batch: ArchiveDetail) => (
        <>
          <FileDownload />
          <Ct.Spacer width={1} />
          <StyledLink
            onClick={() => {
              dispatch(getUrlForBatchDocumentThunk(batch.batch_id))
            }}
          >
            {batch.original_file_name}
          </StyledLink>
        </>
      ),
    },
    {
      headerText: "writing-detail.batch-table.date",
      content: (batch: ArchiveDetail) => (
        <Ct.Text
          text={batch.creation_date}
          textStyle={{
            textAlign: "center",
          }}
        />
      ),
    },
    {
      headerText: "writing-detail.batch-table.email",
      content: (batch: ArchiveDetail) => (
        <Ct.Text
          text={batch.email !== "null" ? batch.email : ""}
          textStyle={{
            textAlign: "center",
          }}
        />
      ),
    },
  ]

  enum SortOptionsValues {
    "documentName",
    "displayedName",
    "date",
    "email",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.displayedName) {
      setDisplayedBatches(sortDisplayedNameBatches(displayedBatches, asc))
    } else if (option === SortOptionsValues?.documentName) {
      setDisplayedBatches(sortDocumentNameBatches(displayedBatches, asc))
    } else if (option === SortOptionsValues?.email) {
      setDisplayedBatches(sortBatchDocumentByEmail(displayedBatches, asc))
    } else if (option === SortOptionsValues?.date) {
      setDisplayedBatches(sortDisplayedBatchByDate(displayedBatches, asc))
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setDisplayedBatches(filterArchivedBatches([...details], search))
      }
    }
  }, [columnToSort])

  return (
    <StyledColumnStretch>
      <ControlBar>
        <Ct.Input
          label="Rechercher"
          value={search}
          suffix={<Search />}
          maxWidth={30}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          shadowed={true}
          noBorder={true}
        />
        {!wasGenerated ? (
          <div />
        ) : wasDownloaded ? (
          <Ct.Button
            width={30}
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={`Télécharger archive n°${archiveId}`}
            onClick={() => {
              dispatch(
                downloadWritingThunk(
                  archiveId,
                  selectedCompanyId,
                  accountSoftwareReference,
                  accountSoftware
                )
              )
            }}
          />
        ) : (
          <Ct.Button
            width={30}
            label={`Télécharger archive n°${archiveId}`}
            onClick={() => {
              dispatch(
                downloadWritingThunk(
                  archiveId,
                  selectedCompanyId,
                  accountSoftwareReference,
                  accountSoftware
                )
              )
            }}
          />
        )}
      </ControlBar>
      <Ct.Spacer height={4} />
      <Table
        intl={intl}
        columns={columns}
        rows={displayedBatches}
        sortableColumnsLength={columns.length}
        sortingMainFunction={(columnToSort) => {
          setColumnToSort(columnToSort)
        }}
        currentSortColumn={currentChevron}
      />
    </StyledColumnStretch>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  display: flex;
  padding-bottom: 2rem;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  display: flex;
  padding-bottom: 4rem;
  box-sizing: border-box;
`

const ControlBar = styled((props) => <Ct.SpacedBetweenCenter {...props} />)`
  box-sizing: border-box;
  padding-right: 2rem;
  padding-left: 2rem;
`

const StyledColumnStretch = styled((props) => <Ct.ColumnStretch {...props} />)`
  overflow-y: hidden;
`

const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`

const StyledChevronLeft = styled(ChevronLeft)`
  & path {
    fill: white;
  }
`
