export const translations = {
  back: "Retour",

  empty: "  ",
  "empty-text": "<nbsp> </nbsp>",
  "see-more": "Voir plus",
  "see-less": "Voir moins",
  "delete-title": "Suppression",
  cancel: "Annuler",
  "delete-item": "Supprimer",
  "delete-text": "Êtes-vous sûr de vouloir supprimer définitivement ? ",

  "login.title": "Connexion",
  "login.subtitle":
    "La comptabilité de <boldItalicGrey>demain</boldItalicGrey>",
  "login.email": "Adresse email",
  "login.password": "Mot de passe",
  "login.cta": "Se connecter",
  "login.sso.description":
    "<b>Optimisez la récupération de vos factures</b> et <b>automatisez la saisie de vos dossiers</b> en vous <b>connectant à votre compte Chaintrust</b>",
  "login.sso.cta": "Se connecter via SSO",
  "login.remember-me": "Se souvenir de moi",
  "login.forgotten-password": "Mot de passe oublié ?",
  "login.error": "Mot de passe ou identifiant incorrect.",
  "forgotten-password.title": "Mot de passe oublié ?",
  "forgotten-password.cta": "Réinitialiser le mot de passe",
  "forgotten-password.do-not-panic": "Pas de panique !",
  "forgotten-password.instructions":
    "Remplissez le formulaire ci-dessous et nous vous enverrons par email les informations pour vous créer un nouveau mot de passe.",
  "forgotten-password.success":
    "Vous allez recevoir un email de réinitialisation.",
  "forgotten-password.error": "L'email est incorrect.",
  "forgotten-password.login-link": "Se connecter",
  "reset-password.title": "Réinitialiser le mot de passe",
  "reset-password.password": "Saisissez votre nouveau mot de passe",
  "reset-password.confirm-password": "Confirmez votre nouveau mot de passe",
  "reset-password.cta": "Changer le mot de passe",
  "reset-password.failure-token": "Votre token d'authentification est périmé",
  "reset-password.success":
    "Félicitation, votre mot de passe a bien été changé",
  "password-errors.length":
    "Votre mot de passe doit faire au moins  <boldColored>8 caractères</boldColored>.",
  "password-errors.format":
    "Votre mot de passe doit contenir au moins : <boldColored>1 majuscule, 1 minuscule, et 1 chiffre</boldColored>.",
  "password-errors.mismatch":
    "Les deux mots de passe doivent être <boldColored>identiques</boldColored>.",
  "password-errors.password is a required field":
    "Votre mot de passe est <boldColored>requis</boldColored>.",
  "password-errors.email": "Votre adresse email est invalide",
  "register.title": "Inscription",
  "register.subtitle":
    "La comptabilité de <boldItalicGrey>demain</boldItalicGrey>",
  "register.email": "Adresse email",
  "register.first-name": "Prénom",
  "register.last-name": "Nom",
  "register.password": "Saisissez votre mot de passe",
  "register.confirm-password": "Confirmez votre mot de passe",
  "register.length":
    "Votre mot de passe doit faire au moins <boldColored>8 caractères </boldColored>.",
  "register.format":
    "Votre mot de passe doit contenir au moins : <boldColored>1 majuscule, 1 minuscule, et 1 chiffre</boldColored>.",
  "register.mismatch":
    "Les deux mots de passe doivent être <boldColored>identiques</boldColored>.",
  "register.conditions": "J'accepte les",
  "register.conditions-link": "conditions générales d'utilisation",
  "register.duplicate": "Cet email existe déjà.",
  "register.cta": "Créez votre compte",
  "register.email-taken": "Cet email a déjà un compte associé",
  "register.choose-another": "Utilisez un autre email pour créer votre compte",
  "register.toaster.success.title": "Inscription enregistrée",
  "register.toaster.success.body": "Vous êtes maintenant connecté",

  "accept-terms.title": "Merci d'accepter les CGUs {adminTitle}",

  "fiduciary-top-bar.subtitle": "Synthèse de votre fiduciaire",
  "fiduciary-register.title": "Créez votre fiduciaire",
  "fiduciary-register.subtitle": "La comptabilité de <i><b>demain</b></i>",
  "fiduciary-register.siren": "SIREN / SIRET (9-14 Chiffres)",
  "fiduciary-register.placeholder": "Entrer le SIREN ex : 123456789",
  "fiduciary-register.siren.validate": "Valider votre SIREN",
  "fiduciary-register.siren.change": "Modifier votre SIREN",
  "fiduciary-register.siren.format":
    "Votre numéro SIREN doit contenir <b>9 chiffres</b>",
  "fiduciary-register.siren.error":
    "<b>SIREN Invalide</b> : impossible de créer la fiduciaire",
  "fiduciary-register.siren.dont-panic":
    "<b>Pas de panique !</b> nous pouvons créer votre fiduciaire manuellement.",
  "fiduciary-register.siren.manual":
    "Pour cela, merci de contacter l'équipe produit <b>Chaintrust</b>.",
  "fiduciary-register.siren.verification": "Vérification en cours",
  "fiduciary-register.siren.warning":
    "<b>Attention</b>, nous avons remarqué que le code APE de votre société : <br></br>",
  "fiduciary-register.siren.accounting-issue":
    " n’est pas celui d’un cabinet d’expertise comptable. Pouvez-vous s’il vous plaît remplir ci-dessous le nom du cabinet d’expertise comptable qui vous a fait découvrir <b>Chaintrust</b> ? ",
  "fiduciary-register.siren.accounting-name":
    "Nom du cabinet d'expertise comptable",
  "fiduciary-register.siren.name": "Le nom de votre fiduciaire est :",
  "fiduciary-register.siren.existing-fiduciary":
    "Attention cette fiduciaire existe déjà !",
  "fiduciary-register.siren.hidden.message-first":
    "<b>Pas de panique !</b> nous pouvons créer votre fiduciaire manuellement.",
  "fiduciary-register.settings-title": "Paramètres de votre fiduciaire",
  "fiduciary-register.name": "Nom de votre fiduciaire",
  "fiduciary-register.address": "Adresse",
  "fiduciary-register.city": "Ville",
  "fiduciary-register.postal-code": "Code postal",
  "fiduciary-register.phone": "Téléphone",
  "fiduciary-register.conditions.disabled":
    "J'accepte les <b>conditions générales de vente</b>",
  "fiduciary-register.conditions": "J'accepte les",
  "fiduciary-register.conditions-link": "conditions générales de vente",
  "fiduciary-register.discovery": "Comment avez-vous entendu parler de nous",
  "fiduciary-register.localisation": "Localisation",
  "fiduciary-register.compta-software": "Logiciel de comptabilité",
  "fiduciary-register.cta": "Créer ma fiduciaire",
  "fiduciary-register.error.existing-fiduciary-name-title":
    "Création impossible",
  "fiduciary-register.error.existing-fiduciary-name-message":
    "Une autre fiduciaire existe avec ce nom",
  "fiduciary-register.error.phone-field.title": "Création impossible",
  "fiduciary-register.error.phone-field.message":
    "Vous devez rentrer un numéro de téléphone correct",
  "fiduciary.companies.addCompany": "Ajouter une société",
  "fiduciary.companies.no-fec": "Paramètrage incomplet : FEC manquant",
  "fiduciary.companies.stop-create":
    "<nbsp></nbsp>Pour <nbsp></nbsp><b>ajouter une société</b> , rapprochez-vous de votre manager",
  "fiduciary.companies.deposit_in_review": "Dépôts en cours de traitement",
  "fiduciary.companies.ready_to_generate_single":
    "{readyToGenerate} archive prête à être générée",
  "fiduciary.companies.ready_to_generate_plural":
    "{readyToGenerate} archives prêtes à être générées",
  "fiduciary.companies.ready_to_download_single":
    "{readyToDownload} archive prête à être téléchargée",
  "fiduciary.companies.ready_to_download_plural":
    "{readyToDownload} archives prêtes à être téléchargées",
  "fiduciary.companies.collaborators":
    "{countCollaborators} collaborateur{pluralCollaborators} / {clientCount} client{pluralClient} ",
  "fiduciary.companies.accounting_type.cash_accounting":
    "<b>Type de dossier : </b> Compta de trésorerie",
  "fiduciary.companies.accounting_type.accrual_accounting":
    "<b>Type de dossier : </b> Compta d'engagement",
  "fiduciary-register.update-logo-success.title": "Import réussi",
  "fiduciary-register.update-logo-success.body":
    "Le logo de votre fiduciaire a été mis à jour",
  "fiduciary-register.update-logo-failure.title": "Erreur",
  "fiduciary-register.update-logo-failure.body":
    "Une erreur est survenue lors de l'import",
  "fiduciary-register.update-logo-failure.wrong-format":
    "Seuls les formats JPG/PNG/JPEG sont acceptés",
  "fiduciary-register.accounting-software-settings-title":
    "Paramètres logiciel de comptabilité",
  "fiduciary-register.update-infos-success.title": "Mise à jour réussie",
  "fiduciary-register.update-infos-success.body":
    "Les informations de votre fiduciaire ont été mises à jour",
  "fiduciary-register.update-infos-failure.title": "Erreur",
  "fiduciary-register.update-infos-failure.body":
    "Une erreur est survenue lors de la mise à jour",
  "fiduciary-register.update-infos.save-button": "Sauvegarder",
  "fiduciary-register.update-logo.crop-button": "SAUVEGARDER MON LOGO",
  "fiduciary-bill.date": "Date",
  "fiduciary-bill.amount": "Montant",
  "fiduciary-bill.status": "Statut",
  "fiduciary-bill.download": "Télécharger",
  "fiduciary-bill.empty": "Pas de factures pour le moment",
  "fiduciary.indicators.generate-csv": "Générer un csv",
  "404.text": "Cette page n'a pas été trouvée",
  "404.link": "Revenir sur Chaintrust",
  "403.text": "Vous n'avez pas l'autorisation d'accéder à cette page",
  "403.link": "Revenir sur Chaintrust",
  "logout.greeting": "Bonjour <b>{userFirstName}</b>",
  "logout.cta": "Déconnexion",
  "centre.aide": "Centre d'aide",
  "invitation.title": "Inviter un nouvel utilisateur",
  "invitation.email": "Adresse Email",
  "invitation.first-name": "Prénom",
  "invitation.last-name": "Nom",
  "invitation.first-name.missing": "Merci de remplir un prénom",
  "invitation.last-name.missing": "Merci de remplir un nom",
  "invitation.email.missing": "Merci de remplir un email",
  "invitation.email.wrongFormat": "Merci de renseigner un email valide",
  "invitation.email.already-exist":
    "Cet email existe déjà, vous devez le <b>modifier</b>",
  "invitation.toaster.user-created": "Votre nouvel utilisateur a été créé.",
  "invitation.toaster.error.in-another-fiduciary.title":
    "Erreur lors de l'invitation",
  "invitation.toaster.error.in-another-fiduciary.body":
    "L'utilisateur est désactivé et dans une autre fiduciaire !",
  "invitation.toaster.error.email-used.title": "Erreur lors de l'invitation",
  "invitation.toaster.error.email-used.body":
    "Cette adresse email est déjà utilisée par un autre utilisateur (sur cette fiduciaire ou sur une autre) !",

  "authorize-list.title.manager": "Manager",
  "authorize-list.title.collaborator": "Collaborateur",
  "authorize-list.title.customer_accountant": "Client comptable",
  "authorize-list.title.customer": "Client",
  "authorize-list.create-company": "Créer une société",
  "authorize-list.deactivate-company": "Désactiver une société",
  "authorize-list.manage-bill": "Afficher et gérer les factures",
  "autorize-list.manage-bill-credit-quote":
    "Afficher et gérer les factures, avoirs et devis ",
  "autorize-list.issue-quote": "Emettre un devis",
  "autorize-list.issue-credit": "Emettre un avoir",
  "authorize-list.sell-bill": "Emettre une facture de vente",
  "authorize-list.accounting-entries":
    "Afficher et gérer les écritures comptables",
  "authorize-list.parameter": "Afficher les paramètres",
  "authorize-list.bank": "Connecter la banque",
  "authorize-list.download-bank": "Télécharger les écritures de banque",
  "authorize-list.bill-email": "Envoyer une facture par mail",
  "authorize-list.email-chaintrust": "Créer un email d'envoi à Chaintrust",
  "authorize-list.display-team": "Afficher et gérer l'équipe",
  "authorize-list.display-client": "Afficher et gérer les clients",
  "authorize-list.invite-user": "Inviter un {userTitle}",
  "authorize-list.first-text":
    "1 - Renseigner le nom, prénom et mail de l'utilisateur",
  "authorize-list.second-text": "2 - Sélectionnez son rôle",

  "menu.company-picker": "Choix de la société",
  "menu.company-picker.title": "Vos sociétés",
  "menu.company-picker.subtitle": "Sélection rapide d'une société",
  "menu.company-picker.search": "Recherche",
  "menu.company-picker.cta": "Sélectionner",
  "menu.company-picker.reference": "RÉF : {accounting_reference}",
  "home.greeting": "Bonjour {firstName},",
  "home.subtitle": "Initialisez votre nouvelle société",
  "menu.drop": "Dépôt",
  "menu.edm": "GED",
  "menu.writings": "Mes écritures",
  "menu.accounting-plan": "Plan comptable",
  "menu.settings": "Paramètres société",
  "menu.informations": "Informations",
  "menu.footer.text":
    "Besoin d’un <b>conseil ?</b> <br></br> Nous sommes là <br></br> pour vous <b>aider</b>.",
  "menu.footer.contact.cta": "Nous contacter",
  "menu.footer.contact.centre-aide": "Centre d'aide",
  "menu.bank": "Banque",

  "initializationFEC.title": "Importer votre FEC",
  "initializationFEC.alert.1":
    "Le paramétrage de la société se fait autour du <b>FEC</b>.",
  "initializationFEC.alert.2":
    "Assurez-vous que le <b>FEC</b> que vous avez déposé <b>est le bon</b> et qu’il n’a <b>pas été modifié manuellement</b>, sinon le paramétrage sera incorrect et les écritures que vous recevrez ne correspondront pas à ce que vous attendez.",
  "initializationFEC.drop-file":
    "Déposez votre FEC ici ou <boldUnderlineColored>cliquez ici pour parcourir</boldUnderlineColored>",
  "initializationFEC.confirm": "Êtes-vous sûr qu’il s’agit du bon <b>FEC</b> ?",
  "initializationFEC.formatError":
    "Le FEC doit être un format <b>txt</b> ou <b>csv</b>",
  "initializationFEC.error": "Certains documents ont été rejetés",
  "initializationFEC.uploading":
    "<bolderColored>Attention !</bolderColored> Merci d’attendre la <bolderColored>fin du téléversement</bolderColored> pour fermer votre navigateur",
  "initializationFEC.success": "Importation réussie",
  "initializationFEC.uploading-not-finished.prompt":
    "Votre FEC n'a pas finit d'être téléversé ! Merci de patentier afin ne pas perdre de données !",
  "initializationFEC.error.description.1":
    "Votre <b>FEC</b> est invalide et ne sera pas enregistré.",
  "initializationFEC.error.description.2":
    "Merci de déposer un <b>FEC valide</b>.",
  "initializationFEC.error.description.cta": "Réimporter un FEC",

  "initializationFEC.no-fec.title": "Vous n'avez pas de FEC ?",
  "initializationFEC.no-fec.text1":
    "Utilisez notre option de création via <b>un FEC Type</b>",
  "initializationFEC.no-fec.text2":
    "La société sera mise en place automatiquement <b>avec des données standard</b>.",
  "initializationFEC.no-fec.cta": "Création via un FEC Type",
  "initializationFEC.no-fec.loading":
    "Veuillez patienter le temps que nous recherchons les meilleures données disponibles pour votre société",
  "initializationFEC.no-fec.transfer-title": "Votre société a bien été créée",
  "initializationFEC.no-fec.transfer-message":
    "Merci de patienter pour le transfert de données.",
  "initializationFEC.no-fec.settings.title":
    "<b>Vous avez utilisé notre option de création sans dépôt.</b>",
  "initializationFEC.no-fec.settings.message":
    "La société a été mise en place automatiquement <b>avec des données standard</b>.",

  "importLogo.drop-file.1": "Déposez votre logo ici",
  "importLogo.drop-file.2":
    "ou <boldUnderlineColored>cliquez ici pour parcourir</boldUnderlineColored>",
  "office.management": "Liste des Utilisateurs",
  "office.list": "Liste de vos Utilisateurs",
  "office.invite": "Inviter",
  "office.users": "Utilisateurs",
  "office.administrator": "Administrateur",
  "office.collaborator": "Collaborateur",
  "office.customer": "Client",
  "office.customer_accountant": "Client comptable",
  "office.manager": "Manager",
  "office.edit-access": "éditer les accès",
  "office.company.top-bar.settings": "Paramètres de votre société",
  "office.company.top-bar.drop": "Vue des fichiers déposés",
  "office.company.top-bar.edm.buy.merchants": "Liste de tous vos fournisseurs",
  "office.company.top-bar.edm.sell.merchants": "Liste de tous vos clients",
  "office.company.top-bar.edm.buy.merchants.list": "Détails du fournisseur",
  "office.company.top-bar.edm.sell.merchants.list": "Détails du client",
  "office.company.top-bar.edm.buy.merchants.modify":
    "Modification du fournisseur",
  "office.company.top-bar.edm.sell.merchants.modify": "Modification du client",
  "office.company.top-bar.bank": "Comptes bancaire de la société",
  "office.company.top-bar.writings": "Vos archives",
  "office.company.top-bar.writing": "Votre archive",
  "office.company.top-bar.initialize": "Initialisez votre nouvelle société",
  "office.company.top-bar.invoicing.issuance": "Émettre une facture",

  "office.company.top-bar.invoicing.customers": "Liste de vos clients",
  "office.company.top-bar.invoicing.products": "Liste de vos produits",
  "office.company.top-bar.invoicing.numbering": "Numérotation",

  "office.company.numberings.add": "Ajouter une numérotation",
  "office.company.numberings.disabled":
    "Cette numérotation n'est plus modifiable car au moins un document a été émis",
  "office.company.numberings.warning":
    "<boldBlack>Attention, la législation oblige à avoir une numérotation croissante</boldBlack> et continue.",
  "office.company.numberings.no-change":
    "Une fois paramétré, le numéro initial de la facture <boldBlack>ne pourra plus être changé</boldBlack>.",
  "office.company.numberings.no-content":
    "Vous n'avez encore aucune numérotation",

  "office.company.numberings.add-modal.create.title":
    "Ajouter une numérotation",
  "office.company.numberings.add-modal.modify.title":
    "Modifier la numérotation",
  "office.company.numberings.add-modal.numbering-length": "Longueur du numéro*",
  "office.company.numberings.add-modal.next-invoice-number":
    "Prochain numéro de facture*",
  "office.company.numberings.add-modal.numbering-order": "Ordre*",
  "office.company.numberings.add-modal.numbering-separator": "Séparateur*",
  "office.company.numberings.add-modal.numbering-root": "Racine*",
  "office.company.numberings.add-modal.numbering-date": "Date",
  "office.company.numberings.add-modal.preview":
    "Prévisualisation du numéro qui sera présent sur votre {documentType}",
  "office.company.numberings.add-modal.create": "Ajouter une numérotation",
  "office.company.numberings.add-modal.modify": "Modifier la numérotation",
  "office.company.numberings.add-modal.max-length":
    "{currentLength}/{maxLength} caractères maximum",
  "office.company.numberings.add-modal.errors.title":
    "Erreur lors de la création",
  "office.company.numberings.add-modal.errors.existing_root":
    "Vous ne pouvez pas avoir deux numérotations avec une racine identique",
  "office.company.numberings.add-modal.errors.too_long":
    "Cette numérotation est trop longue !",
  "office.company.numberings.add-modal.errors.unknown":
    "La numérotation n'a pas pu être créée",
  "office.company.numberings.add-modal.success.title": "Succès de la création",
  "office.company.numberings.add-modal.success.body":
    "La numérotation a bien été créée",
  "office.company.numberings.add-modal.form-error.root":
    "Cette racine <b>existe déjà</b> : vous ne pouvez pas avoir deux racines en double sur la même société",
  "office.company.numberings.add-modal.form-error.root-modification":
    "Cette racine <b>a été supprimée</b> : vous ne pouvez pas modifier cette numérotation en utilisant une racine qui a déjà existé. Veuillez en créer une nouvelle",
  "office.company.numberings.add-modal.warning.root-reactivated":
    "<b>ATTENTION !</b> Vous allez recréer une numérotation <b>qui existait déjà et avait été désactivée.</b>",
  "office.company.numberings.delete-modal.about":
    "Vous allez supprimer cette numérotation !",
  "office.company.numberings.delete-modal.sure": "Êtes-vous sûr(e) ? !",
  "office.company.numberings.delete-modal.cancel": "Annuler",
  "office.company.numberings.delete-modal.confirm": "Supprimer",

  "office-company-settings.label-company-name": "Nom de la société",
  "office-company-settings.accounting_type-label": "Type de comptabilité",
  "office-company-settings.accounting_type.accrual_accounting":
    "Compta d'engagement",
  "office-company-settings.accounting_type.cash_accounting":
    "Compta de trésorerie",
  "office-company-settings.auxiliary": "Auxiliaire",
  "office-company-settings.principal": "Principal",
  "office-company-settings.auxiliary-null": "En attente",
  "office-company-settings.checkbox-info":
    "Toutes les <b>factures cochées</b> ci dessous, immobilisations comprises, seront <b>comptabilisées</b> dans les <b>comptes de charge</b> (comptes 6) ou <b>de produits</b> (compte 7) les plus pertinents du <b>FEC déposé</b>.",
  "office-company-settings.checkbox-relaunch": "Relance",
  "office-company-settings.checkbox-rent": "Quittance de loyer",
  "office-company-settings.checkbox-deposit": "Facture d'acompte",
  "office-company-settings.checkbox-immobility": "Immobilité",
  "office-company-settings.checkbox-estimate": "Bon, Devis, Réservation",
  "office-company-settings.checkbox-bills":
    "Relevé, Rapport ou Récapitulatif de factures",
  "office-company-settings.vat-accounts-info":
    "Vous pouvez ici paramétrer le <b>mode de comptabilisation</b> de la TVA normale",
  "office-company-settings.auto-vat-accounts-info":
    "Vous pouvez ici paramétrer le <b>mode de comptabilisation</b> de la TVA autoliquidée",

  "office-company-settings.auto-vat-accounts-title.eu_vat":
    "Autoliquidation Intracommunautaire Services",
  "office-company-settings.auto-vat-accounts-title.eu_goods_vat":
    "Autoliquidation Intracommunautaire Biens",
  "office-company-settings.auto-vat-accounts-title.world_vat":
    "Autoliquidation Hors UE Services",
  "office-company-settings.auto-vat-accounts-title.world_goods_vat":
    "Autoliquidation Hors UE Biens",
  "office-company-settings.auto-vat-accounts-title.construction_vat":
    "Autoliquidation BTP",

  "office-company-settings.auto-vat-accounts.rates.title.eu_reverse_vat":
    "Taux applicable pour l'Autoliquidation Intracommunautaire Services",
  "office-company-settings.auto-vat-accounts.rates.title.eu_goods_reverse_vat":
    "Taux applicable pour l'Autoliquidation Intracommunautaire Biens",
  "office-company-settings.auto-vat-accounts.rates.title.world_reverse_vat":
    "Taux applicable pour l'Autoliquidation Hors UE Services",
  "office-company-settings.auto-vat-accounts.rates.title.world_goods_reverse_vat":
    "Taux applicable pour l'Autoliquidation Hors UE Biens",
  "office-company-settings.auto-vat-accounts.rates.title.construction_reverse_vat":
    "Taux applicable pour l'autoliquidation BTP",

  "office-company-settings.auto-vat-accounts-tooltip.activate.eu_vat":
    "Activer Autoliquidation Intraco Services",
  "office-company-settings.auto-vat-accounts-tooltip.deactivate.eu_vat":
    "Désactiver Autoliquidation Intraco Services",
  "office-company-settings.auto-vat-accounts-tooltip.activate.eu_goods_vat":
    "Activer Autoliquidation Intraco Biens",
  "office-company-settings.auto-vat-accounts-tooltip.deactivate.eu_goods_vat":
    "Désactiver Autoliquidation Intraco Biens",
  "office-company-settings.auto-vat-accounts-tooltip.activate.world_vat":
    "Activer Autoliquidation Hors UE Services",
  "office-company-settings.auto-vat-accounts-tooltip.deactivate.world_vat":
    "Désactiver Autoliquidation Hors UE Services",
  "office-company-settings.auto-vat-accounts-tooltip.activate.world_goods_vat":
    "Activer Autoliquidation Hors UE Biens",
  "office-company-settings.auto-vat-accounts-tooltip.deactivate.world_goods_vat":
    "Désactiver Autoliquidation Hors UE Biens",
  "office-company-settings.auto-vat-accounts-tooltip.activate.construction_vat":
    "Activer Autoliquidation BTP",
  "office-company-settings.auto-vat-accounts-tooltip.deactivate.construction_vat":
    "Désactiver Autoliquidation BTP",

  "office-company-settings.vat-remove": "Mettre à jour sans TVA",
  "office-company-settings.vat-update": "Mettre à jour les comptes de TVA",
  "office-company-settings.differentiated-vat":
    "Je souhaite distinguer la TVA des services vs des marchandises en vente",
  "office-company-settings.vat-modal.title":
    "Créer un compte de TVA en {buyOrSell}{rateWithPercent}",
  "office-company-settings.vat-modal.number": "Numéro de compte",
  "office-company-settings.vat-modal.details": "Libellé",
  "office-company-settings.vat-modal.cta": "Créer le compte",
  "office-company-settings.vat-modal.error":
    "Le compte de TVA doit commencer par 4456 en achats et par 4457 en ventes.",
  "office-company-settings.vat-modal.no-vat":
    "Votre société sera paramétrée sans TVA, cliquez sur 'Sauvegarder' pour enregistrer",

  "buy-or-sell.buy": "achat",
  "buy-or-sell.sell": "vente",

  "office-company-settings.vat-accounts.buy-account": "Compte de TVA",
  "office-company-settings.vat-accounts.rate": "Taux de TVA",
  "office-company-settings.vat-accounts.create": "Créer un compte",
  "office-company-settings.vat-accounts.buy-rate": "Achat {rate}%",
  "office-company-settings.vat-accounts.buy": "Achat",
  "office-company-settings.vat-accounts.sell": "Vente",
  "office-company-settings.vat-accounts.sell-rate": "Vente {rate}%",

  "office-company-settings.vat-accounts.tabs.no-vat": "Sans TVA",
  "office-company-settings.vat-accounts.tabs.unique": "Un seul compte",
  "office-company-settings.vat-accounts.tabs.per-rate": "Un compte par taux",
  "office-company-settings.vat-accounts.soon":
    "Vous pourrez bientôt paramétrer ici votre TVA",
  "office-company-settings.vat-accounts.account-creation-success-title":
    "Création réussie",
  "office-company-settings.vat-accounts.account-creation-success-body":
    "Le compte a bien été crée",
  "office-company-settings.vat-accounts.account-creation-error-title": "Erreur",
  "office-company-settings.vat-accounts.account-creation-error-body":
    "Le compte existe déja",

  "office-company-settings.autoliquidated.title":
    "Créer un compte de {vatTypeText}",
  "office-company-settings.autoliquidated.account-modification.suffix.result":
    "Numéro de compte : <b>{prefixValue}{suffixValue}</b>",
  "office-company-settings.autoliquidated.tips":
    "Saisir le suffixe après {prefixValue}, <b>Exemple: {prefixValue}{suffixValue}, dont suffixe {suffixValue}</b> ",
  "office-company-settings.autoliquidated.account-already-exists":
    "Le compte existe déjà",
  "office-company-settings.autoliquidated.error":
    "Le compte de TVA doit commencer par {prefixValue} {forbiddenPrefixText}",

  "office-company-settings.eu-construction-vat-accounts-info":
    "Vous pouvez ici paramétrer <b>les comptes de TVA autoliquidation BTP et Intraco</b>",
  "office-company-settings.eu-construction-vat-accounts.eu_vat":
    "Autoliquidation déductible intracommunautaire services",
  "office-company-settings.eu-construction-vat-accounts.eu_reverse_vat":
    "Autoliquidation due/collectée intracommunautaire services",
  "office-company-settings.eu-construction-vat-accounts.construction_vat":
    "Autoliquidation déductible BTP",
  "office-company-settings.eu-construction-vat-accounts.construction_reverse_vat":
    "Autoliquidation due/collectée BTP",
  "office-company-settings.eu-construction-vat-accounts.eu_goods_vat":
    "Autoliquidation déductible intracommunautaire biens",
  "office-company-settings.eu-construction-vat-accounts.eu_goods_reverse_vat":
    "Autoliquidation due/collectée intracommunautaire biens",
  "office-company-settings.eu-construction-vat-accounts.world_goods_vat":
    "Autoliquidation déductible importation hors UE biens",
  "office-company-settings.eu-construction-vat-accounts.world_goods_reverse_vat":
    "Autoliquidation due/collectée importation hors UE biens",
  "office-company-settings.eu-construction-vat-accounts.world_vat":
    "Autoliquidation déductible importation hors UE services",
  "office-company-settings.eu-construction-vat-accounts.world_reverse_vat":
    "Autoliquidation due/collectée importation hors UE services",

  "office-company-settings.autoliquidated-vat.activate-or-deactivate.success.title":
    "Modifications effectuée",
  "office-company-settings.autoliquidated-vat.activate-or-deactivate.success.body":
    "L'autoliquidation a bien été activée/désactivée",

  "office-company-settings.autoliquidated-vat.activate-or-deactivate.error.title":
    "Erreur",
  "office-company-settings.autoliquidated-vat.activate-or-deactivate.error.body":
    "L'activation/désactivation n'a pas pu être effectuée, contactez l'équipe Chaintrust",

  "office-company-settings.autoliquidated-history.title":
    "Dernières Modifications",
  "office-company-settings.autoliquidated-history.previously":
    "Ancien compte : {value}",
  "office-company-settings.autoliquidated-history.create":
    "<b>Création</b> du compte {value} ",
  "office-company-settings.autoliquidated-history.change":
    "<b>Changement</b> du compte de TVA {value} ",
  "office-company-settings.autoliquidated-history.no-changes":
    "Aucune modification",
  "office-company-settings.autoliquidated-history.ok": "OK",
  "office-company-settings.autoliquidated-history.change_eu_vat_account_vat_rate":
    "<b>Changement</b> du taux de TVA applicable pour l'autoliquidation intracommunautaire services {value}%",
  "office-company-settings.autoliquidated-history.change_eu_goods_vat_account_vat_rate":
    "<b>Changement</b> du taux de TVA applicable pour l'autoliquidation intracommunautaire biens {value}%",
  "office-company-settings.autoliquidated-history.change_world_vat_account_vat_rate":
    "<b>Changement</b> du taux de TVA applicable pour l'autoliquidation monde services {value}%",
  "office-company-settings.autoliquidated-history.change_world_goods_vat_account_vat_rate":
    "<b>Changement</b> du taux de TVA applicable pour l'autoliquidation monde biens {value}%",
  "office-company-settings.autoliquidated-history.change_construction_vat_account_vat_rate":
    "<b>Changement</b> du taux de TVA applicable pour l'autoliquidation BTP {value}%",
  "office-company-settings.autoliquidated-history.previously_vat_rate":
    "Ancien taux : {value}",

  "office-company-settings.your-FEC": "Votre <b>FEC</b>",
  "office-company-settings.hidden-company": "Société <b>cachée</b>",
  "office-company-settings.cta": "Sauvegarder",
  "office-company-settings.deposit-date": "Déposé le",
  "office-company-settings.deposit-by": "Par",
  "office-company-settings.downloadFEC": "Télécharger le FEC",
  "office-company-settings.toaster-success.title": "Sauvegarde réussie",
  "office-company-settings.toaster-success.body":
    "Les paramètres de la société ont été mis à jour",
  "office-company-settings.my-unisoft.explanation":
    "Vos écritures comptables générées sur Chaintrust sont directement intégrées à votre logiciel de production, sans aucune action de votre part. <italicColored>Collez votre token MyUnisoft ici</italicColored>",
  "office-company-settings.my-unisoft.label": "MY UNISOFT",
  "office-company-settings.my-unisoft.placeholder": "Token",

  "office-company-deactivate.delete-company":
    "Êtes-vous vraiment sûr(e) de vouloir supprimer la société ?",
  "office-company-deactivate.delete-permanently":
    "Toute <b>suppression</b> est <b>définitive</b>.",
  "office-company-deactivate.cannot-recreate":
    "<b>Vous ne pourrez plus recréer la société.</b>",
  "office-company-deactivate.company-info":
    "L'intégralité des <b>informations comptables</b> (factures, écritures, banque, accès clients) de la société va <b>disparaître</b>.",
  "office-company-deactivate.company-name":
    "Merci de <b>saisir le nom complet</b> de la société pour la <b>supprimer définitivement.</b>",
  "company-register.title": "Créer une société",
  "office-company-deactivate.placeholder.1": 'Saisir "',
  "office-company-deactivate.placeholder.2": '" pour supprimer la société.',
  "office-company-deactivate.cta": "Supprimer la société",
  "office-company-deactivate.toaster-success": "La société a été désactivée",

  "office-company-deactivate.modal.title": "Confirmer la suppression",
  "office-company-deactivate.modal.back": "Retour",
  "office-company-deactivate.modal.save": "Sauvegarder",
  "office-company-deactivate.modal.reason.header":
    "Vous allez supprimer une société, veuillez sélectionner une ou plusieurs raisons de désactivation :",
  "office-company-deactivate.modal.reason.time":
    "Temps de traitement trop long",
  "office-company-deactivate.modal.reason.quantity": "Volume trop faible",
  "office-company-deactivate.modal.reason.leave":
    "Dossier ayant quitté le cabinet",
  "office-company-deactivate.modal.reason.quality":
    "Les écritures générées ne sont pas assez qualitatives",
  "office-company-deactivate.modal.reason.closed": "Société fermée",
  "office-company-deactivate.modal.reason.price": "Prix",
  "office-company-deactivate.modal.reason.functionalities":
    "Fonctionnalités manquantes",
  "office-company-deactivate.modal.reason.unavailable_bank":
    "La banque du client n’est pas disponible",

  "office-company-fiscal-years.update.success.title": "Année fiscale modifiée",
  "office-company-fiscal-years.update.success.body":
    "La modification de l'année fiscale a été prise en compte",

  "office-company-fiscal-years.create-update.error.title":
    "Impossible de sauvegarder",
  "office-company-fiscal-years.create-update.error.conflict":
    "Il y a une autre année fiscale en conflit sur ces dates",
  "office-company-fiscal-years.create-update.error.contiguous":
    "Vos années fiscales doivent être contiguës : merci de vérifier les dates pour continuer",
  "office-company-fiscal-years.create-update.error.before-today":
    "Vous ne pouvez pas créer d'exercice plus de 5 ans avant aujourd'hui. Vos années fiscales doivent être contiguës.",
  "office-company-fiscal-years.create-update.error.after-today":
    "Vous ne pouvez pas créer d'exercice plus de 5 ans après aujourd'hui. Vos années fiscales doivent être contiguës.",
  "office-company-fiscal-years.create-update.error.distant-dates":
    "Vous ne pouvez pas créer d'exercice d'une durée supérieure à 5 ans.",

  "office-company-fiscal-years.create-update.error.empty-date":
    "Une date est nécessaire pour créer ou mettre à jour une année fiscale : merci de vérifier que vous avez sélectionné une date.",
  "office-company-fiscal-years.create-update.error.no-match":
    "Les dates ne coïncident pas : merci de vérifier vos dates.",
  "office-company-fiscal-years.create.success.title": "Année fiscale créée",
  "office-company-fiscal-years.create.success.body":
    "L'année fiscale a été créée",
  "office-company-fiscal-years.create.action": "Créer une année fiscale",

  "office-company-change-software.history": "Historique",
  "office-company-change-software.alert":
    "Sélectionnez <b>un nouveau logiciel de comptabilité</b>, renseignez si vous le souhaitez les <b>préfixes</b> de votre dossier en <b>achats</b> et en <b>ventes</b>, ainsi que la <b>référence</b> de votre dossier. ",
  "office-company-change-software.reference": "Référence dossier {mandatory}",
  "office-company-change-software.new-software":
    "Nouveau logiciel de comptabilité",
  "office-company-change-software.prefix-buy":
    "Préfixe de votre dossier en achats",
  "office-company-change-software.prefix-sell":
    "Prefixe de votre dossier en ventes",
  "office-company-change-software.empty-reference":
    "Vous devez saisir une référence pour ce logiciel",
  "office-company-change-software.warning-message":
    "<b>ATTENTION ! </b> Certains de vos <b>{accounts}</b> {and} <b>{codes}</b> sont <b>trop longs</b>, merci de les mettre à jour avant de procéder au changement de logiciel de comptabilité.",
  "office-company-change-software.warning-accounts":
    "<b>Comptes</b>: {accounts}",
  "office-company-change-software.warning-codes": "<b>Codes</b>: {codes}",
  "office-company-change-software.button-error":
    "Mettre à jour les {accounts} {and} {codes}",
  "office-company-change-software.button-confirm":
    "Sauvegarder les changements",
  "office-company-change-software.modal.title":
    "Mise à jour des comptes et codes tiers",
  "office-company-change-software.current-software": "Logiciel actuel",
  "office-company-change-software.pennylane.login":
    "Se connecter avec Pennylane",
  "office-company-change-software.pennylane.connected-at":
    "Dossier connecté à Pennylane le {pennylaneTokenCreatedAt}",
  "office-company-change-software.example-output":
    "Exemple de prévisualisation FEC",
  "office-company-change-software.modal.alert":
    'Vous avez sélectionné un nouveau logiciel de comptabilité, avant de procéder au changement, <b>vous devez réduire la longueur de certains de vos comptes et codes tiers, directement dans les tableaux ci-dessous</b>. Une fois les changements apportés, cliquez sur "<b>Changer de logiciel</b>" pour enregistrer vos modifications. <b>Le nouveau logiciel de comptabilité sera mis à jour</b>.',
  "office-company-change-software.modal.accounts-title": "Comptes à modifier",
  "office-company-change-software.modal.codes-title": "Codes tiers à modifier",
  "office-company-change-software.modal.length-max":
    "La longueur maximale est de <boldColored>{limit} caractères</boldColored> pour un {accountOrCode}.",
  "office-company-change-software.modal.code": "Code",
  "office-company-change-software.modal.account": "Compte",
  "office-company-change-software.modal.warning-already-exists":
    "Ce {accountOrCode} existe déjà",
  "office-company-change-software.modal.cancel": "Retour",
  "office-company-change-software.modal.confirm": "Changer de logiciel",
  "office-company-change-software.tooltip.success.title":
    "Changement de logiciel effectué!",
  "office-company-change-software.tooltip.success.message":
    "Le changement de logiciel s'est correctement effectué",
  "office-company-change-software.tooltip.error.title":
    "Une erreur est survenue",
  "office-company-change-software.tooltip.error.message":
    "Le changement de logiciel n'a pas pu s'effectuer",
  "office-company-change-software.history.title":
    "Changements de logiciels de comptabilité",
  "office-company-change-software.history.empty":
    "Aucun changement de logiciel",
  "office-company-change-software.history.change":
    "<b>Modification</b> de logiciel par ",

  "office-company.writing-labels.title-software":
    "Votre logiciel est {software}. Vous pouvez configurer les libellés de votre dossier.",
  "office-company.writing-labels.field-name-translation":
    "Ce logiciel a un champ qui s'appelle <b>{field}</b> ({translation})",
  "office-company.writing-labels.field-position":
    "Ce champ est situé en position <b>{position}</b> de l'enregistrement (<i>n'hésitez pas à regarder la documentation de {software} pour en savoir plus</i>)",
  "office-company.writing-labels.field-max-length":
    "Ce champ a une limite <b>maximale de {max_length} caractères</b>.",
  "office-company.writing-labels.table.title.document-type": "Type de document",
  "office-company.writing-labels.table.title.buy-or-sell": "Achat / vente",
  "office-company.writing-labels.table.title.preview": "Prévisualisation",
  "office-company.writing-labels.table.values.amount_tax_excluded":
    "Montant HT",
  "office-company.writing-labels.table.values.amount_tax_included":
    "Montant TTC",
  "office-company.writing-labels.table.values.currency": "Monnaie",
  "office-company.writing-labels.table.values.merchant_name":
    "Tiers (Fournisseur ou Client)",
  "office-company.writing-labels.table.values.merchant_code": "Code Tiers",
  "office-company.writing-labels.table.values.date": "Date",
  "office-company.writing-labels.table.values.due_date": "Date d'échéance",
  "office-company.writing-labels.table.values.periodicity": "Périodicité",
  "office-company.writing-labels.table.values.original_batch_name":
    "Dépôt original",
  "office-company.writing-labels.table.values.document_reference":
    "Référence document",
  "office-company.writing-labels.table.values.automatic_reference":
    "Référence automatique (B000x)",
  "office-company.writing-labels.table.values.document_reference_or_automatic_reference":
    "Référence document ou automatique (B000x)",
  "office-company.writing-labels.table.values.automatic_reference_or_document_reference":
    "Référence automatique (B000x) ou document",
  "office-company.writing-labels.table.values.space": "Espace",
  "office-company.writing-labels.table.values.dot": ".",
  "office-company.writing-labels.table.values.underscore": "_",
  "office-company.writing-labels.table.values.hyphen": "-",
  "office-company.writing-labels.table.buy-or-sell.buy": "Achats",
  "office-company.writing-labels.table.buy-or-sell.sell": "Ventes",
  "office-company.writing-labels.table.buy-or-sell.all": "Tous",
  "office-company.writing-labels.table.document-type.invoice": "Factures",
  "office-company.writing-labels.table.document-type.credit_note": "Avoir",
  "office-company.writing-labels.table.document-type.bill_of_exchange":
    "Lettre de crédit",
  "office-company.writing-labels.table.document-type.all": "Tous",
  "office-company.writing-labels.table.default-chaintrust":
    "(Mis par défaut par Chaintrust)",
  "office-company.writing-labels.destroy-tooltip-succes.title":
    "Libellé supprimé !",
  "office-company.writing-labels.destroy-tooltip-succes.message":
    "Le libellé a bien été supprimé",
  "office-company.writing-labels.destroy-tooltip-error.title":
    "Une erreur est survenue",
  "office-company.writing-labels.destroy-tooltip-error.message":
    "Le libellé n'a pas pu être supprimé",
  "office-company.writing-labels.destroy-label.title": "Supprimer le libellé ",
  "office-company.writing-labels.destroy-label.text1":
    "Vous souhaitez supprimer définitivement le libellé pour ",
  "office-company.writing-labels.destroy-label.text2":
    "Êtes-vous sûr de vouloir le supprimer ? ",
  "office-company.writing-labels.create": "Ajouter une personnalisation",

  "office-company.writing-labels.create.title":
    "Ajouter une personnalisation: Libellé de mouvement",
  "office-company.writing-labels.edit.title":
    "Modifier une personnalisation: Libellé de mouvement",
  "office-company.writing-labels.modal-create-edit.part-one.title":
    "1 - Renseignez les éléments ci-dessous : ",
  "office-company.writing-labels.modal-create-edit.part-two.title":
    "2 - Glissez les éléments que vous souhaitez afficher : ",
  "office-company.writing-labels.modal-create-edit.part-two.alert1":
    "<b>Cliquez sur un élément</b> avec votre souris (par exemple: Montant HT) pour l'ajouter <b>dans la zone ci-dessous</b>. ",
  "office-company.writing-labels.modal-create-edit.part-two.alert2":
    "Pour <b>ajouter une description libre</b>, renseigner le champ, puis cliquer pour ajouter.",
  "office-company.writing-labels.modal-create-edit.part-two.values":
    "Valeurs: ",
  "office-company.writing-labels.modal-create-edit.part-two.separators":
    "Séparateurs: ",
  "office-company.writing-labels.modal-create-edit.part-two.free-text":
    "Description libre",
  "office-company.writing-labels.modal-create-edit.part-two.add-free-text":
    "Ajouter",
  "office-company.writing-labels.modal-create-edit.part-two.empty-values":
    "Cliquez sur les champs et les séparateurs",
  "office-company.writing-labels.modal-create-edit.preview":
    "Prévisualisation du format libellé",
  "office-company.writing-labels.modal-create-edit.part-three.title":
    "3 - Nombre de caractères",
  "office-company.writing-labels.modal-create-edit.part-three.text1":
    "Le nombre de caractères autorisés <b>dépend de votre logiciel</b>. La limite de caractères pour votre logiciel est de : <b>{max_length}</b>",
  "office-company.writing-labels.modal-create-edit.part-three.text2":
    "Si le libellé dépasse cette limite, <b>veuillez choisir comment le raccourcir</b> :",
  "office-company.writing-labels.modal-create-edit.crop-left": "À gauche",
  "office-company.writing-labels.modal-create-edit.crop-middle": "Au milieu",
  "office-company.writing-labels.modal-create-edit.crop-right": "À droite",
  "office-company.writing-labels.modal-create-edit.create-label":
    "Ajouter le libellé",
  "office-company.writing-labels.create-tooltip-succes.title": "Libellé créé !",
  "office-company.writing-labels.create-tooltip-succes.message":
    "Le libellé a bien été créé",
  "office-company.writing-labels.create-tooltip-error.title":
    "Une erreur est survenue",
  "office-company.writing-labels.create-tooltip-error.message":
    "Le libellé n'a pas pu être créé",
  "office-company.writing-labels.modal.delete-all": "Tout supprimer",

  "company-register.subtitle": "La comptabilité de <i><b>demain</b></i>",
  "company-register.name": "Nom de la société",
  "company-register.begin-exercise": "Début d'exercice (jj/mm/aaaa)",
  "company-register.end-exercise": "Fin d'exercice (jj/mm/aaaa)",
  "company-register.compta-software": "Logiciel de comptabilité",
  "company-register.send-due-date-ACD":
    "Envoyer la date d’écheance dans l’API ACD",
  "company-register.send-due-date-ACD.alert":
    "Veillez à l'activer aussi dans les paramètres de votre fiduciaire",
  "company-register.send-document-reference-ACD":
    "Envoyer le numéro de facture dans l’API ACD",
  "company-register.send-document-reference-ACD.alert":
    "Veillez à l'activer aussi dans les paramètres de votre fiduciaire",
  "company-register.accounting-software-reference":
    "Référence dossier pour Isacompta",
  "company-register.hidden-from-team":
    "<b>Masquer la société</b> aux autres utilisateurs de votre fiduciaire",
  "company-register.cta": "Créer la société",
  "company-register.siren.name": "Le nom de votre société est :",
  "company-register.siren.error":
    "<b>SIREN Invalide</b> : impossible de créer la société",
  "company-register.siren.hidden.message-first":
    "<b>Pas de panique !</b> nous pouvons créer votre société manuellement.",
  "company-register.siren.hidden.message-second":
    " Pour cela, merci de contacter l'équipe produit <b>Chaintrust.</b>",
  "company-register.siren.error.siren-taken.title": "Création impossible",
  "company-register.siren.error.siren-taken.message":
    "Une société avec le même SIREN existe déjà : contactez votre fiduciaire pour obtenir un accès",
  "company-register.siren.error.siren-recreation.title": "Création impossible",
  "company-register.siren.error.siren-recreation.message":
    "Vous avez déjà créé cette société. Merci de contacter l'équipe commerciale pour la réactiver",
  "company-register.siren.error.siren-not-french.title": "Création impossible",
  "company-register.siren.error.siren-not-french.message":
    "Votre société n'est pas française",
  "company-register.siren.error.siren.format-error.title":
    "Création impossible",
  "company-register.siren.error.siren.format-error.message":
    "Le format du siren n'est pas valide",
  "company-register.error.fiduciary_deactivated.message":
    "Votre fiduciaire est désactivée : Vous ne pouvez pas créer de société.",

  "company-office.fiscal-years":
    "Vous pouvez ici modifier les années fiscales de votre dossier {name}.",
  "company-office.fiscal-years.create-title":
    "Créez une nouvelle année fiscale",
  "company-office.fiscal-years-title-years":
    "Gérez les différentes années fiscales de la société {name}.",
  "company-office.fiscal-years.create":
    "Créez une année fiscale pour votre dossier {name}.",
  "company-office.fiscal-years.begin-exercise": "Début d’exercice",
  "company-office.fiscal-years.end-exercise": "Fin d’exercice",
  "company-office.fiscal-years.modify": "Modifier",
  "company-office.fiscal-years.save-change": "Sauvegarder le changement",
  "company-office.fiscal-years-picker.empty.title": "Aucune année fiscale",
  "company-office.fiscal-years-picker.empty.body":
    "Année fiscale nécessaire pour accéder à cette page.",
  "company-register.update-logo-success.title": "Import réussi",
  "company-register.update-logo-success.body":
    "Le logo de votre société a été mis à jour",
  "company-register.delete-logo-success.title": "Suppression réussie",
  "company-register.delete-logo-success.body":
    "Le logo de votre société a bien été supprimé",
  "company-register.update-logo.crop-button": "SAUVEGARDER MON LOGO",

  "company-writings.header.state": "État de l'écriture",
  "company-writings.header.progress": "Avancement",
  "company-writings.header.downloaded": "Téléchargé par",
  "company-writings.header.date": "Date",
  "company-writings.header.writing": "Archive",

  "company-writings.create.success.title": "Archive créée",
  "company-writings.create.success.body": "L'archive a été créée avec succès",
  "company-writings.download.success.title": "Téléchargement réussi",
  "company-writings.download.success.body":
    "L'archive a été téléchargée avec succès",
  "company-writings.import_api.imported": "Importées par API le {date}",
  "company-writings.import_api.in_progress": "En cours d'import par API...",
  "company-writings.import_api.import_error": "Erreur lors de l'import par API",
  "company-writings.no_import": "Non importée par API",
  "company-writings.only_deactivated":
    ", ne contient qu'un dossier de contrôle",
  "company-writings.deactivated_present": " présence d'un dossier de contrôle",
  "company-writings.import_number":
    " {importedNumber} lot(s) envoyé(s) sur {total}",
  "company-writings.inqom.import_number":
    " {importedNumber} document(s) envoyé(s) sur {total}",
  "company-writings.acd.import_number":
    " {importedNumber} document(s) envoyé(s) sur {total}",

  "writing-detail.title.generic": "Détails des dépôts",
  "writing-detail.title.specific": "Détails de l'archive N°{archiveId}",
  "writing-detail.batch-table.document": "Document",
  "writing-detail.batch-table.batch": "Dépôt",
  "writing-detail.batch-table.date": "Date de dépôt",
  "writing-detail.batch-table.email": "Déposé par",
  "writing-detail.buy-or-sell.buy": "Facture d'achat",
  "writing-detail.buy-or-sell.sell": "Facture de vente",
  "writing-detail.buy-or-sell.null": "Facture",
  "writing-archives.no-archives-available.1":
    "Aucune archive disponible sur cette année fiscale !",
  "writing-archives.no-archives-available.2":
    "Naviguez entre les <b>années fiscales</b> pour pouvoir afficher les archives",
  "writing-archives.regenerate": "Regénérer l'archive",
  "writing-archives.regenerate-cancel": "Retour",
  "writing-archives.regenerate.title": "Regénérer l'archive N°{archiveId}",
  "writing-archives.regenerate.alert1":
    "Vous allez re-générer <b>l'archive {archiveId}</b>",
  "writing-archives.regenerate.alert2":
    "<nbsp></nbsp><dot></dot><nbsp></nbsp> <b>Si des modifications ont été apportées </b> sur ces factures, sur les codes tiers du dossiers <br></br> et/ou sur vos préfixes, depuis la génération de l’archive <b>{archiveId} celles-ci seront prises en <br></br> compte lors de la re-génération.</b>",
  "writing-archives.regenerate.alert3":
    "<nbsp></nbsp><dot></dot><nbsp></nbsp> <b>Si vous avez modifié votre logiciel de production depuis la génération de l’archive {archiveId} <br></br></b> ce changement <b>sera pris en compte et votre format d’import adapté</b> à l’outil sélectionné.",
  "writing-archives.regenerate.alert4":
    "<nbsp></nbsp><dot></dot><nbsp></nbsp> Si vous avez des écritures <b>« prête à être générées »</b> sur la même année fiscale que <br></br> l’archive <b>{archiveId} la re-génération entraînera automatiquement avec elle la génération de <br></br> ces écritures en attente. </b>",
  "writing-archives.regenerate.alert5":
    "<nbsp></nbsp><dot></dot><nbsp></nbsp> <b>La numérotation de vos factures d’achat sera incrémentée.</b>",
  "writing-archives.regenerate.alert6":
    "<nbsp></nbsp><dot></dot><nbsp></nbsp>Si votre dossier est connecté en API, <b>la nouvelle archive sera envoyée à votre logiciel <br></br>comptable.</b>",

  "writing-archives.regenerate.success.title": "Archive regénérée",
  "writing-archives.regenerate.success.message":
    "L'archive a bien été regénérée",
  "writing-archives.regenerate.error.title": "Une erreur est survenue",
  "writing-archives.regenerate.error.message":
    "L'archive n'a pas pu être regénérée",

  "office.role": "Rôle",
  "office.access": "Accès",
  "office.preferences": "Préférences de notification",
  "office.sort": "Trier par :",
  "office.abc": "<boldBlack>Alphabétique</boldBlack>",
  "office.timeframe": "Notifications",
  "office.no-typology-selected":
    "Sélectionnez une typologie pour <b>afficher les utilisateurs</b>",
  "office.no-user-match-typology":
    "<b>Aucun utilisateur présent</b> parmi les <b>typologies sélectionnées</b>.",
  "office.no-user-match-search":
    "<b>Aucun utilisateur</b> ne correspond à votre <b>recherche</b>.",
  "edit-user.title": "Utilisateur & accès",
  "edit-user.role": "Rôle",
  "edit-user.deactivate": "Désactiver l’utilisateur",
  "edit-user.save": "Sauvegarder les changements",
  "office.companies-list": "Liste des sociétés",
  "edit-user.user": "Utilisateur",
  "edit-user.list": "Liste des sociétés accordées",
  "edit-user.warning-message.no-access":
    "Cet utilisateur n'a <b>aucun accès pour le moment</b>.",
  "edit-user.warning-message.give-access":
    "<b>Donnez-lui accès</b> à l'une de vos sociétés grâce au menu déroulant ci-dessus !",
  "edit-user.grant-access.success.title": "Accès ajoutés",
  "edit-user.grant-access.success.body":
    "Les accès aux sociétés ajoutées ont bien été accordés",
  "edit-user.revoke-access.success.title": "Accès supprimés",
  "edit-user.revoke-access.success.body":
    "Les accès au sociétés supprimées ont bien été supprimés",
  "edit-user.edit-name.success.title": "Utilisateur modifié",
  "edit-user.edit-name.success.body": "L'utilisateur a bien été modifié",
  "edit-user.edit-typoplogy.success.title": "Rôle modifié",
  "edit-user.edit-typoplogy.success.body":
    "Le changement de rôle de l'utilisateur a été pris en compte",
  "edit-user.edit-typoplogy.failure.title": "Echec modification rôle",
  "edit-user.edit-typoplogy.failure.body":
    "Vous n'êtes pas autorisé à modifier le rôle de cet utilisateur",
  "edit-user.update-infos-failure.title": "Erreur",
  "edit-user.update-infos-failure.body":
    "Une erreur est survenue lors de la mise à jour",
  "edit-user.update-email-failure.title": "Erreur",
  "edit-user.update-email-failure.body":
    "Vous n'êtes pas autorisé à changer l'email de cet utilisateur",
  "capture-mail.add": "Ajouter",
  "capture-mail.user": "Utilisateurs",
  "capture-mail.email": "email de capture",
  "capture-mail.send-mail": "Emails d'envoi",
  "capture-mail.delete-access": "Supprimer l'accès",
  "capture-mail.email-address-capture": "Copier l’email de capture",
  "capture-mail.add-email-printer":
    "Veuiller ajouter l’email d’envoi de votre imprimante ici.",
  "capture-mail.add-email-printer.email": "Adresse Email",
  "capture-mail.add-email-printer.submit": "Créer l’email d’envoi",
  "capture-mail.add-email-printer.error.email-already-exist":
    "Cet email existe déjà",
  "capture-mail.toaster.title": "Succès",
  "capture-mail.toaster.success":
    "L'email de capture a été copié dans votre presse-papier !",
  "capture-mail.addition.success": "Vos modifications ont été enregistrées",
  "capture-mail.removal.success": "Vos modifications ont été enregistrées",
  "capture-mail.toast.error.title": "Erreur",
  "capture-mail.rules-title": "<boldBlack>Règles à respecter : </boldBlack>",
  "capture-mail.rules-body":
    "<dot></dot> L'adresse mail de l'émetteur doit être présente <b>dans la liste ci-dessous, dans les accès clients</b> de ce dossier ou en tant que collaborateur au sein votre équipe <br></br><dot></dot> Vos pièces jointes doivent être <b>au format pdf/jpg/png/tiff/jpeg</b>",

  "edit-user.add": "Ajouter",
  "edit-user.multiselect": "Liste des sociétés",
  "edit-user.deactivation-title": "Désactivation réussie",
  "edit-user.deactivation-body":
    "L'utilisateur {firstName} {lastName} a bien été désactivé",
  "edit-user.deactivation-failure.title": "Désactivation impossible",
  "edit-user.deactivation-failure.body":
    "Vous n'êtes pas autorisé à désactiver l'utilisateur {firstName} {lastName}",
  "edit-user.notification-title": "Préférences de notifications",
  "edit-user.notification-message": "Vos modifications ont été enregistrées.",

  "office.fiduciary.kpis.deposit-range": "Dépôts sur la période",
  "office.fiduciary.kpis.deposit": "Dépôts stockés",
  "office.fiduciary.kpis.purchases": "Achats",
  "office.fiduciary.kpis.sales": "Ventes",
  "office.fiduciary.kpis.pending": "En Cours",
  "office.fiduciary.kpis.other": "Autres Factures",
  "office.fiduciary.kpis.permanent": "Dossier Permanent",
  "office.fiduciary.kpis.bank": "Banque",
  "office.fiduciary.kpis.fiscal-and-social": "Fiscal & Social",
  "office.fiduciary.kpis.company.siren": "<b>Siren :</b> {siren}",
  "office.fiduciary.kpis.company.created_by": "<b>Créée par :</b> {email}",
  "office.fiduciary.kpis.company.created_at": "le : {date}",

  "office.modify-merchant.go-back": "Retour",
  "office.modify-merchant.buy.title": "Modification Fournisseur",
  "office.modify-merchant.sell.title": "Modification Client",
  "office.modify-merchant.buy.merchant-name.label":
    "éditer le nom du fournisseur",
  "office.modify-merchant.cta": "Sauvegarder",
  "office.modify-merchant.cancel": "Annuler",
  "office.modify-merchant.buy.toast.success.title": "Fournisseur Sauvegardé",
  "office.modify-merchant.buy.toast.success.message":
    "Votre fournisseur a bien été sauvegardé!",
  "office.modify-merchant.sell.toast.success.title": "Client sauvegardé",
  "office.modify-merchant.sell.toast.success.message":
    "Votre client a bien été sauvegardé!",
  "office.modify-merchant.see.output": "Voir la sortie",
  "office.modify-merchant.sell.merchant-name.label": "éditer le nom du client",
  "office.modify-merchant.buy.merchant-name.empty-name":
    "Le nom du fournisseur ne peut pas être vide !",
  "office.modify-merchant.sell.merchant-name.empty-name":
    "Le nom du client ne peut pas être vide !",
  "office.modify-merchant.buy.merchant-name.name-too-long":
    "Le nom du fournisseur est trop long !",
  "office.modify-merchant.sell.merchant-name.name-too-long":
    "Le nom du client est trop long !",
  "office.modify-merchant.buy.merchant-name.placeholder": "Nom du fournisseur",
  "office.modify-merchant.sell.merchant-name.placeholder": "Nom du client",
  "office.modify-merchant.merchant-name.error.already-taken.title":
    "Nom indisponible",
  "office.modify-merchant.merchant-name.error.already-taken.message":
    "Ce nom est déjà utilisé !",
  "office.modify-merchant.buy.default-account.label": "Compte de charges",
  "office.modify-merchant.sell.default-account.label": "Compte de produits",
  "office.modify-merchant.buy.forbidden-account.label":
    "Interdire des comptes de charges",
  "office.modify-merchant.sell.forbidden-account.label":
    "Interdire des comptes de produits",
  "office.modify-merchant.ban.error.locked.title": "Impossible d’interdire",
  "office.modify-merchant.ban.error.locked.message":
    "Un des comptes interdit est le compte par défaut",
  "office.modify-merchant.lock.error.banned.title": "Impossible de verouiller",
  "office.modify-merchant.lock.error.banned.message":
    "Le compte figure parmi les comptes interdits",
  "office.modify-merchant.deactivated.account.error.title":
    "Impossible de verrouiller",
  "office.modify-merchant.name-with-extra-spaces.error.title":
    "Impossible de modifier",
  "office.modify-merchant.name-with-extra-spaces.error.message":
    "Il y a des espaces en trop au début ou à la fin du nom",
  "office.modify-merchant.name-with-unauthorized-characters.error.message":
    "Il y a un/des caractère(s) interdit(s) dans le nom",
  "office.modify-merchant.buy.merchant-code.label":
    "choisir ou créer un code Fournisseur",
  "office.modify-merchant.sell.merchant-code.label":
    "choisir ou créer un code Client",
  "office.modify-merchant.merchant-code.create": "Créer un nouveau code :",

  "office.modify-merchant.buy.merchant-code.rename.label":
    "renommer le code fournisseur",

  "office.modify-merchant.sell.merchant-code.rename.label":
    "renommer le code client",

  "office.modify-merchant.merchant-code.rename.error.empty.title":
    "Code vide !",
  "office.modify-merchant.merchant-code.rename.error.empty.message":
    "Le code ne peut pas être vide!",
  "office.modify-merchant.merchant-code.rename.error.too-long.title":
    "Code trop long !",
  "office.modify-merchant.merchant-code.rename.error.too-long.message":
    "Le code est trop long!",
  "office.modify-merchant.merchant-code.error.already-taken.title":
    "Code déjà utilisé",
  "office.modify-merchant.merchant-code.error.already-taken.message":
    "Ce code est déjà utilisé! ",
  "office.modify-merchant.merchant-code.rename.error.forbidden-characters.title":
    "Caractère interdit",
  "office.modify-merchant.merchant-code.rename.error.forbidden-characters.message":
    "Le code ne doit être composé que de caratères alphanumériques !",
  "office.modify-merchant.merchant-code.error.decentralize-account.title":
    "Impossible de modifier le regroupement",
  "office.modify-merchant.merchant-code.error.decentralize-account.message":
    "Ce code de regroupement est déjà associé à un autre client ou fournisseur.",
  "office.modify-merchant.sell.merchant-code.centralize.text":
    "En activant cette option, ce <boldColored>code client</boldColored> deviendra un <boldColored>code de regroupement</boldColored>, " +
    "permettant le regroupement de plusieurs clients.",
  "office.modify-merchant.buy.merchant-code.centralize.text":
    "En activant cette option, ce <boldColored>code fournisseur</boldColored> deviendra un <boldColored>code de regroupement</boldColored>, " +
    "permettant le regroupement de plusieurs fournisseurs.",
  "office.modify-merchant.merchant-code.centralize.label":
    "Définir comme code de regroupement",

  "office.modify-merchant.buy.merchant-code.rename.placeholder":
    "modification du code fournisseur",
  "office.modify-merchant.sell.merchant-code.rename.placeholder":
    "modification du code client",

  "office.modify-merchant.merchant-code.rename.button-text": "Renommer",
  "office.modify-merchant.merchant-code.rename-validate.button-text": "Valider",

  "office.modify-merchant.buy.merchant-code.placeholder": "code fournisseur",
  "office.modify-merchant.sell.merchant-code.placeholder": "code client",

  "office.modify-merchant.autoliquidated_vat_rate.toast.success.title":
    "Modification effectuée",
  "office.modify-merchant.autoliquidated_vat_rate.toast.success.message":
    "Le taux de TVA pour ce compte d'autoliquidation a été enregistré",

  "form.unsaved.message":
    "Voulez-vous vraiment continuer ? Vos modifications ne seront pas enregistrées",

  "ged.client.modal.title": "Dernières Modifications",
  "ged.client.modal.sell": "Client",
  "ged.client.modal.buy": "Fournisseur",
  "ged.client.modal.ok": "OK",
  "ged.client.modal.name_modification": "<b>Changement</b> du nom par ",
  "ged.client.modal.lock_account":
    "<b>Verrouillage</b> de compte de {buy_or_sell} par ",
  "ged.client.modal.unlock_account":
    "<b>Déverrouillage</b> de compte de {buy_or_sell} par ",
  "ged.client.modal.ban_account": "<b>Interdiction</b> de compte par ",
  "ged.client.modal.unban_account": "<b>Autorisation</b> de compte par ",
  "ged.client.modal.code_creation": "<b>Création</b> de code fournisseur par ",
  "ged.client.modal.merchant_code_change":
    "<b>Changement</b> de code fournisseur par ",
  "ged.client.modal.no-changes":
    "Aucune modification n'a été effectuée sur ce ",
  "ged.client.modal.lock_vat_account":
    "<b>Rattachement</b> d'un compte de tva par ",
  "ged.client.modal.unlock_vat_account":
    "<b>Détachement</b> d'un compte de tva par ",
  "ged.client.modal.lock_without_vat": "<b>Verrouillage</b> sans tva par ",
  "ged.client.modal.unlock_without_vat": "<b>Déverrouillage</b> sans tva par ",
  "ged.client.modal.change_merchant":
    "<b>Déplacement</b> de l'entité légale par ",
  "ged.client.modal.lock_eu_vat": "<b>Verrouillage</b> TVA Intraco par ",
  "ged.client.modal.lock_construction_vat": "<b>Verrouillage</b> TVA BTP par ",

  "ged.modal.edit-merchant.title": "Modification {buyOrSell}",
  "ged.modal.edit-merchant.edit-name": "éditer le nom du {buyOrSell}",
  "ged.modal.edit-merchant.edit-code": "modifier le code",
  "ged.modal.edit-merchant.edit-account":
    "forcer le compte de {accountType} par défaut",
  "ged.modal.edit-merchant.edit-vat-account": "Compte de tva par défaut",
  "ged.modal.edit-merchant.vat-account.label": "Compte de tva",

  "ged.modal.standard-ledger.ok": "OK",
  "ged.modal.standard-ledger.title": "Sortie comptable au format FEC",
  "ged.modal.standard-ledger.compteNum": "CompteNum",
  "ged.modal.standard-ledger.compteLib": "CompteLib",
  "ged.modal.standard-ledger.compteAuxNum": "CompteAuxNum",
  "ged.modal.standard-ledger.compteAuxLib": "CompteAuxLib",

  "bank.menu": "Banque",

  "ged.merchants.table-header.merchant-name.buy": "Fournisseurs",
  "ged.merchants.table-header.merchant-name.sell": "Clients",
  "ged.merchants.table-header.buy": "Compte de charge par défaut",
  "ged.merchants.table-header.sell": "Compte de produit par défaut",
  "ged.merchants.table-header.banned-account": "Comptes interdits",
  "ged.merchants.table-header.document-count": "Nombre de factures",
  "ged.merchants.table-header.historical": "Historique",
  "ged.merchants.table-header.vat-account": "Compte de TVA par défaut",
  "ged.merchants.table-header.fuel-vat": "TVA Essence VTC",
  "ged.merchants.table.no-merchant1":
    "Aucun {merchantType} sur cette période !",
  "ged.merchants.table.no-merchant2":
    "Choisissez une autre <b>année fiscale</b> pour afficher les documents",
  "ged.merchants.code.dropdown": "Code",
  "ged.merchants.code.vat-account.label": "Compte de TVA",
  "ged.merchants.code.vat-account-vat.label": "Tx Autol",

  "office.modify-merchant.autoliquidated_vat_rate.toast.failure.title":
    "Une erreur s'est produite",
  "office.modify-merchant.autoliquidated_vat_rate.toast.failure.message":
    "Nous n'avons pu sauvegarder vos changements, contactez l'équipe Chaintrust si l'erreur persiste",

  "ged.other-files.deposit-name": "Nom du dépot",
  "ged.other-files.file-name": "Nom du fichier",
  "ged.other-files.type": "Type",
  "ged.other-files.qualification": "Qualification",
  "ged.other-files.date": "Date",
  "ged.other-files.actions": "Actions",
  "ged.other-files.archive": "Archive",
  "ged.other-files.number": "N°",
  "ged.other-files.toaster-success.title": "Sauvegarde réussie",
  "ged.other-files.toaster-success.body":
    "La qualification du document a bien été modifiée",
  "ged.other-files.alert.1": "Aucun document sur cette période !",
  "ged.other-files.alert.2":
    "Choisissez une <b>année fiscale</b> pour afficher les documents",
  "ged.other-files.type.unsupported": "Autres documents",
  "ged.other-files.type.bank": "Documents bancaires",
  "ged.other-files.type.fiscal": "Fiscal & social",
  "ged.other-files.type.permanent": "Dossier permanent",
  "ged.other.files.archive.toaster.title": "Téléchargement réussi !",
  "ged.other.files.archive.toaster.body": "L'archive a bien été téléchargée",
  "ged.header.date-custom.message": "Filtrer par date",
  "ged.header.date-custom.message.tooltip":
    "Ce filtre est basé sur l'année fiscale : sélectionnez des dates compatibles",

  "company-drop-document.drop-file":
    "Déposez vos fichiers ici ou <boldUnderlineColored>cliquer ici pour parcourir</boldUnderlineColored>",
  "company-drop-document.drop-file-format":
    "Formats traitables : .png, .jpg, .jpeg, .pdf, .tiff, .tif",
  "company-drop-document.max-size": "Merci de ne pas dépasser 100Mo",
  "company-drop-document.accepted":
    "Documents en cours de traitement : {accepted}",
  "company-drop-document.officeAccepted":
    "Documents offices : {officeAccepted}",
  "company-drop-document.rejected": "Documents rejetés : {rejected}",
  "company-drop-document.totalRejected":
    "Documents non traitables : {totalRejected}",
  "company-drop-document.prompt":
    "Tous les documents n'ont pas été téléversés ! Vous risquez de perdre des documents, êtes-vous sûrs de vouloir fermer le dépôt ?",
  "company-drop-document.filter-date":
    "<boldColoredLarger>Sur cette période :</boldColoredLarger> {total} dépôts, {duplicate} doublons et {office} offices",

  "company-drop-document.header.state": "état",
  "company-drop-document.header.type": "type de document",
  "company-drop-document.header.name": "nom du document",
  "company-drop-document.header.email": "dépôt par",
  "company-drop-document.header.date": "date de dépôt",
  "company-drop-document.header.actions": "actions",
  "company-drop-document.accepted-formats":
    "Voici la liste des types de fichier acceptés chez Chaintrust :",
  "company-drop-document.office-untreated-format":
    "Les documents Office (word, excel, ppt) ne sont pas traités en comptabilité",
  "company-drop-document.empty":
    "Les fichiers vides sont automatiquement rejetés",
  "company-drop-document.rename.about":
    "Vous êtes sur le point de renommer votre document.",
  "company-drop-document.rename.only-batch":
    "Seul le <b>dépôt</b> sera <b>renommé</b>. Les fichiers présents dans la GED ne seront pas renommés",
  "company-drop-document.rename.label": "Nouveau nom du dépôt",
  "company-drop-document.rename.confirm": "Valider",
  "company-drop-document.rename.cancel": "Annuler",
  "company-drop-document.rename.error.title":
    "Erreur lors du changement de nom",
  "company-drop-document.rename.error.body":
    "Le document n'a pas pu être modifié",
  "company-drop-document.rename.success.title": "Succès",
  "company-drop-document.rename.success.body":
    "Vos modifications ont été enregistrées",
  "company-drop-document.no-documents":
    "Vous n'avez pas encore déposé de documents.",

  "company-drop-document.get.error.title": "Erreur de récupérations",
  "company-drop-document.get.error.body":
    "La liste des documents n'a pas pu être récupérée",

  "company-drop-document.deactivate.about":
    "Vous allez supprimer ce(s) {documentType} !",
  "company-drop-document.deactivate.disappear":
    "Tous les documents de la GED qui lui sont associés vont également disparaître. <br></br> <b>Veuillez noter que le traitement ne sera pas interrompu lors de la suppression.</b> <br></br> Si vous souhaitez une suppression totale, veuillez contacter l'équipe commerciale. <br></br> <b>Êtes-vous sûr(e) de vouloir supprimer ce(s) dépôt(s) ?</b>",

  "company-drop-document.deactivate.confirm": "Valider",
  "company-drop-document.deactivate.cancel": "Annuler",
  "company-drop-document.deactivate.error.title": "Suppression impossible",
  "company-drop-document.deactivate.error.body":
    "Un ou plusieurs documents n'ont pas pu être supprimés",

  "company-EDM.document.deactivate.full-document":
    "<b>Êtes-vous sûr(e) de vouloir supprimer ce(s) document(s) ?</b>",

  "company-drop-document.details.ref": "Référence",
  "company-drop-document.details.date": "Date",
  "company-drop-document.details.suspense_account": "Fournisseur/Client",
  "company-drop-document.details.page": "N° de Page",
  "company-drop-document.details.archive": "Archive",
  "company-drop-document.details.title": "<b>Dépôt : </b> {batch}",

  "company-drop-document.duplicate-details.title":
    "<b>Dépôt : </b> {originalFileName}",
  "company-drop-document.duplicate-details.filename": "Nom des doublons",
  "company-drop-document.duplicate-details.date": "Date de dépôt",
  "company-drop-document.duplicate-details.email": "Dépot par",

  "company-drop-document.tooltip.exchange":
    "Ce dépôt est un document office et ne peut donc pas être traité.",
  "company-drop-document.tooltip.duplicate":
    "Ce dépôt est un doublon et ne peut donc pas être traité.",
  "company-drop-document.tooltip.writing_not_validated":
    "Ce dépôt est en cours de traitement.",

  "merchants.header.internal": "Référence Interne",
  "merchants.header.external": "Référence Externe",
  "merchants.header.deposit": "Dépôt",
  "merchants.header.amount": "Montant",
  "merchants.header.date": "Date",
  "merchants.header.action": "Actions",
  "merchants.header.archive": "Archive",
  "merchants.header.empty": "Non renseigné",

  "merchants.viewer.customer.name": "Nom du Client",
  "merchants.viewer.supplier.name": "Nom du Fournisseur",
  "merchants.viewer.customer.code": "Code Client\n",
  "merchants.viewer.supplier.code": "Code Fournisseur\n",
  "merchants.viewer.account.produit": "Comptes de produits par défaut\n",
  "merchants.viewer.account.charge": "Comptes de charges par défaut\n",
  "merchants.viewer.last.change": "Dernières modifications\n",
  "merchants.viewer.customer.change": "Modifier le client\n\n",
  "merchants.viewer.supplier.change": "Modifier le fournisseur\n\n",
  "merchants.viewer.alert": "Aucune facture sur cette période !",
  "merchants.data.toaster.error.title": "Une erreur s'est produite !",
  "merchants.data.toaster.error.body":
    "Impossible de récupérer la donnée demandée.",

  "merchants.display.customer.none": "Aucun client sur cette période !",
  "merchants.display.supplier.none": "Aucun fournisseur sur cette période !",
  "merchants.display.choose-fiscal-year":
    "Choisissez une <boldBlack>année fiscale</boldBlack> pour afficher les fournisseurs",
  "merchants.display.invoices":
    "{invoices} facture{length} comptabilisée{length}",
  "merchants.display.code.buy": "Code fournisseur : ",
  "merchants.display.code.sell": "Code client : ",

  "access-denied.title": "Vous n'avez pas accès à notre interface 😔",
  "access-denied.first-block.1":
    "Cela peut être dû à <b>plusieurs raisons</b> :",
  "access-denied.first-block.2":
    "1. Vous n'avez <b>accès</b> à <b>aucune société</b>.",
  "access-denied.first-block.3":
    "2. Votre <b>société</b> n'est <b>pas</b> complètement <b>configurée</b>.",
  "access-denied.first-block.4":
    "3. Votre <b>compte</b> a été <b>désactivé</b>.",
  "access-denied.first-block.5":
    "4. Votre <b>fiduciaire</b> a été <b>désactivée</b>.",
  "access-denied.second-block.1":
    "Si vous <b>représentez</b> un <b>cabinet d'expertise comptable</b>, <boldColored>contactez notre équipe</boldColored> pour réactiver votre compte ou récupérer vos données.",
  "access-denied.third-block.1":
    "Si vous avez <b>obtenu ce lien par votre cabinet d'expertise comptable</b>, <boldColored>contactez votre expert comptable</boldColored> pour qu'il vous donne accès à une société ou qu'il termine la configuration de cette société.",
  "access-denied.fourth-block.1":
    "Les <b>étapes de configuration</b> sont les suivantes :",
  "access-denied.fourth-block.2": "1. <b>Déposer</b> un <b>FEC</b>.",
  "access-denied.fourth-block.3": "2. <b>Valider</b> le <b>FEC</b> déposé.",
  "access-denied.fourth-block.4": "3. <b>Créer</b> une <b>année fiscale</b>.",
  "access-denied.fourth-block.5.1":
    "4. Vous <b>ouvrir</b> un <b>accès</b> pour la société",
  "access-denied.fourth-block.5.2":
    "(au sein de l'espace de votre expert comptable : onglet",
  "access-denied.fourth-block.5.3": '"<b>Utilisateurs</b>").',

  "bank-accounts.add-bank": "Ajoutez une banque",
  "bank-accounts.bank-account.title": "Votre banque",
  "bank-accounts.account-label": "Vos <b>comptes</b>",
  "bank-accounts.no-accounts-linked":
    "Aucun compte synchronisé pour cette banque.",
  "bank-accounts.no-bankin-items.first":
    "Votre <b>demande</b> d'accès à la banque a été <boldColored>acceptée</boldColored> !",
  "bank-accounts.no-bankin-items.second":
    "Pour <b>ajouter</b> vos premiers <boldColored>comptes</boldColored>, veuillez enregistrer votre banque en <b>cliquant ci-dessous</b>.",
  "bank-account.get-new-operations.error.title": "Erreur",
  "bank-account.get-new-operations.error.body":
    "Impossible de récupérer les dernières opérations",
  "bank-accounts.select-account": "Compte",
  "bank-accounts.error-bridge1":
    "Attention, récupération d'écritures impossibles, compte bancaire déconnecté",
  "bank-accounts.error-bridge2":
    "<b>Attention !</b> Votre compte bancaire est <b>déconnecté</b>, la récupération d'écritures est impossible, <b>veuillez vous reconnecter</b>.",
  "bank-accounts.error-bridge-status":
    "<b>Erreur</b>: bankind_id: {bankinId}, status: {status} ",
  "bank-accounts.error-bridge.reconnect": "Se reconnecter",
  "bank-accounts.count-accounts": " {count} compte{plural} connecté{plural} ",
  "bank-accounts.is-account-set": "Compte non paramétré",
  "bank-accounts.is-set": "{isSet}paramétré",
  "bank-accounts.customer.is-account-not-set":
    "Un ou plusieurs comptes <b>ne sont pas encore paramétrés. Veuillez vous rapprocher de votre comptable</b> pour les paramétrer.",

  "bank-accounts.bank-account.settings.title": "Paramètrage du compte",
  "bank-accounts.bank-account.settings.steps": "Étape {step}/2",
  "bank-accounts.bank-account.settings.date-setting":
    "Paramétrer la date du premier import",
  "bank-accounts.bank-account.settings.date-input": "Date du premier import",
  "bank-accounts.bank-account.settings.date-alert":
    "Vos <b>écritures bancaires</b> seront <b>importées</b> de <boldColored>cette date à aujourd'hui</boldColored> lors du premier import",
  "bank-accounts.bank-account.settings.date-infos":
    "Vous ne pouvez pas choisir de date antérieure à un an avant aujourd'hui. Les prochains imports prendront en compte toutes les écritures qui n'ont pas été encore importées.",
  "bank-accounts.bank-account.settings.date-cta":
    "Valider la date de premier import",
  "bank-accounts.bank-account.settings.modal.title": "Date d'import",
  "bank-accounts.bank-account.settings.modal.selectedDate":
    "Vous avez choisi le <boldColored>{selectedDate}</boldColored> comme date d'import.",
  "bank-accounts.bank-account.settings.modal.confirm":
    "<b>En êtes-vous sûr ?</b>",
  "bank-accounts.bank-account.settings.modal.careful":
    "<boldColored>Attention</boldColored><b>, vous ne pourrez pas revenir en arrière.</b>",
  "bank-accounts.bank-account.settings.modal.cta": "Importer la date d'import",
  "bank-accounts.bank-account.settings.suffix.error":
    "<i>Veuillez n'utiliser que des <b>caractères alphanumériques</b>.</i>",

  "archives.title": "Vos archives",
  "archives.go-back": "retour",
  "archives.table-header.archive": "Archive",
  "archives.table-header.creation-date": "Date de creation",
  "archives.table-header.fiscal-year": "Année fiscale",
  "archives.table-header.download-date": "Date",
  "archives.table-header.download-by": "Téléchargé par",
  "archives.table-header.download": "Télécharger",
  "archives.table-content.archive-link": "{companyName} Archive N{id}.zip",
  "archives.table-content.download-achive-button": "télécharger archive N°{id}",

  "bank-management.button.save": "Sauvegarder",
  "bank-management.button.back": "Retour",
  "bank-management.delete.cta": "Supprimer ce compte",
  "bank-management.delete-warning.title": "Supprimer {gender} {accountOrBank}",
  "bank-management.delete-warning.1":
    "Vous souhaitez <boldColored>supprimer définitivement</boldColored> votre <b>{accountOrBank}</b> nommée",
  "bank-management.delete-warning.2":
    "<boldColored> {accountOrBankName} </boldColored> et <boldColored>tous les comptes associés</boldColored> ?",
  "bank-management.delete-warning.3":
    "Pour supprimer votre {accountOrBank}, tapez son nom ci-dessous. ",
  "bank-management.delete-warning.4":
    "<b>Attention, cette <boldColored>action</boldColored> est <boldColored>irréversible</boldColored>.</b>",
  "bank-management.delete-warning.label":
    "Entrez le nom de votre {accountOrBank}",
  "bank-management.delete-warning.validate-button":
    "Supprimer définitivement {gender} {accountOrBank}",
  "bank-management.delete.toast.success.title": "{accountOrBank} {gender} !",
  "bank-management.delete.toast.success.body":
    "Votre {accountOrBank} a été définitivement {gender} !",
  "bank-management.delete.toast.error.title": "Erreur lors de la suppression",
  "bank-management.delete.toast.error.body":
    "Votre {accountOrBank} n'a pas pu être {gender}",
  "bank-management.account-modification.journal-code": "CODE JOURNAL",
  "bank-management.account-modification.journal-code.label": "Code journal",
  "bank-management.account-modification.journal-code.example":
    "Saisir le code journal du compte, <b>Ex : BQ</b>",
  "bank-management.account-modification.accounts": "COMPTES",
  "bank-management.account-modification.suffix.label-financial_account":
    "Compte financier",
  "bank-management.account-modification.suffix.label-suspense_account":
    "Compte d'attente",
  "bank-management.account-modification.suffix.example":
    "Saisir le suffixe après {suffixDefaultValue}, <b>Ex : 000</b>",
  "bank-management.account-modification.financial_account.example":
    "Saisir le compte financier, <b>Ex : 512000</b>",
  "bank-management.account-modification.suspense_account.example":
    "Saisir le compte d'attente, <b>Ex : 471000</b>",
  "bank-management.account-modification.toast.success.title":
    "Compte modifié !",
  "bank-management.account-modification.toast.success.body":
    "Votre compte a été mis à jour",
  "bank-management.account-modification.toast.error.title":
    "Erreur lors de la modification",
  "bank-management.account-modification.toast.error.body":
    "Votre compte n'a pas pu être mis à jour",
  "bank-management.name-modification.toast.success.title":
    "Nom des comptes modifiés !",
  "bank-management.name-modification.toast.success.body":
    "Les noms de vos comptes ont été mis à jour",
  "bank-management.name-modification.toast.error.title":
    "Erreur lors de la modification des noms",
  "bank-management.name-modification.toast.error.body":
    "Vos comptes n'ont pas pu être mis à jour",
  "bank-management.name-modification.toast.error.body.point": "{errorMessage}.",
  "archives.table-content.generate-archive-button": "générer les écritures",
  "archives.table-content.archive.ready-to-generate":
    "{count} écritures prêtes à être générés",
  "archives.table-content.archive.generating":
    "{count} écritures en cours de génération",
  "archives.table-content.archive.generated": "{count} écritures générées",
  "archives.table-content.archive.generating-failure.title": "Erreur",
  "archives.table-content.archive.generating-failure.body":
    "Impossible de générer votre archive, réessayer ou contacter Chaintrust",
  "archives.table-content.archive.generating-failure.body.no_transaction":
    "Aucune transaction disponible, l'archive ne sera pas générée",
  "bank.archives.no-archives-available.1":
    "Aucune archive disponible sur ce compte!",
  "bank.archives.no-archives-available.2":
    "Vérifiez vos transactions pour les créer.",
  "bank.archives.no-archives-available.search":
    "Aucune archive trouvée avec cette recherche",

  "bank-management.rules.create-rule": "Ajouter une règle d'affectation",
  "bank-management.rules.edit-rule": "Modifier une règle d'affectation",
  "bank-management.rules.transfer-rule": "Transférer toutes les règles",
  "bank-management.rules.title": "Règles d'affectation : ",
  "bank-management.rules.empty":
    "Vous n'avez pas encore de règles d'affectation pour ce compte. <br></br> Cliquer sur \"Ajouter une règle d'affectation\" pour en créer une.",
  "bank-management.rules.no-match":
    "Aucune règle ne correspond à votre recherche",
  "bank-management.rules.rule-text":
    "Remplacer le 471 par un compte<nbsp></nbsp>",
  "bank-management.rules.bank-line-type": " en {inOrOut} d'argent",
  "bank-management.rules.add-rule.trigger": "Déclenchement : ",
  "bank-management.rules.add-rule.priority":
    "Priorité <boldColored>*</boldColored>",
  "bank-management.rules.add-rule.text-in-description":
    "Texte présent dans le libellé de l'écriture bancaire",
  "bank-management.rules.add-rule.in-out":
    "Sortie/Entrée d'argent <boldColored>*</boldColored>",
  "bank-management.rules.add-rule.compare-amount": "Le montant doit être...",
  "bank-management.rules.add-rule.amount": "Montant",
  "bank-management.rules.add-rule.amount.no-amount":
    "Il faut saisir un montant",
  "bank-management.rules.add-rule.amount.no-compare":
    "Il faut choisir un comparateur",
  "bank-management.rules.add-rule.assignment": "Affectation : ",
  "bank-management.rules.add-rule.prefix-assignment": "Type d'affectation",
  "bank-management.rules.add-rule.vat-rate": "Taux TVA",
  "bank-management.rules.add-rule.preview": "Prévisualisation",
  "bank-management.rules.add-rule.tooltip.disable-vat":
    "Les taux de TVA sont désactivés sur cette société",
  "bank-management.rules.add-rule.tooltip.no-vat-for-accounts":
    "Pas de TVA disponible sur ce type de compte",
  "bank-management.rules.create-rule.cta": "Créer la règle",
  "bank-management.rules.edit-rule.cta": "Modifier la règle",
  "bank-management.rules.createForOneTransaction-rule.cta": "Modifier la ligne",
  "bank-management.rules.add-rule.success.title": "Nouvelle règle créée",
  "bank-management.rules.add-rule.success.message":
    "Votre nouvelle règle s'applique dès maintenant.",
  "bank-management.rules.add-rule.error.title": "Erreur lors de la création",
  "bank-management.rules.add-rule.error.message": "Une erreur est survenue",
  "bank-management.rules.edit-rule.success.title": "La règle a été modifiée",
  "bank-management.rules.edit-rule.success.message":
    "Le(s) modification(s) s'appliquent dès maintenant.",
  "bank-management.rules.edit-rule.error.title":
    "Erreur lors de la modification",
  "bank-management.rules.add-rule-on-transaction.success.title":
    "Nouvelle affectation créée",
  "bank-management.rules.add-rule-on-transaction.success.message":
    "La nouvelle affectation s'applique dès maintenant sur cette transaction",
  "bank-management.rules.add-rule-on-transaction.error.title":
    "Une erreur est survenue",
  "bank-management.rules.add-rule-on-transaction.error.message":
    "Impossible de créer l'affectation pour cette transaction",
  "bank-management.rules.events.title": "Dernières modifications",
  "bank-management.rules.events.head-title": "Détails",
  "bank-management.rules.events.header": "Règle: #{priority}",
  "bank-management.rules.events.create_rule": "Création de la règle par ",
  "bank-management.rules.events.edit_rule": "Modification de la règle par ",
  "bank-management.rules.events.edit_rule.priority_number":
    "ordre de priorité (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.text_in_description":
    "texte présent dans le libellé (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.bank_line_type":
    "sortie / entrée d'argent (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.amount": "montant (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.compare_amount":
    "comparateur (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.account_id":
    "compte appliqué (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.merchant_code_id":
    "compte appliqué (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.vat_rate_id":
    "taux de tva appliqué (<b>{value}</b>)",
  "bank-management.rules.events.edit_rule.prefix": "préfixe (<b>{value}</b>)",
  "bank-management.rules.delete-rule.title": "Suppression d'une règle",
  "bank-management.rules.delete-rule.text1":
    "Vous souhaitez <boldColored> supprimer définitivement</boldColored> la règle <b>#{priority}</b>.",
  "bank-management.rules.delete-rule.text1-rule-without-document":
    "Vous souhaitez <boldColored> supprimer définitivement</boldColored> la règle.",
  "bank-management.rules.delete-rule.text2":
    "Si vous supprimez cette règle, toutes celles qui suivent seront réordonnées.",
  "bank-management.rules.delete-rule.text3":
    "Êtes-vous sûr de vouloir la supprimer ?",
  "bank-management.rules.delete-rule.cancel": "Annuler",
  "bank-management.rules.delete-rule.delete": "Supprimer cette règle",
  "bank-management.rules.delete-rule.delete.success.tilte": "Règle supprimée",
  "bank-management.rules.delete-rule.delete.success.message":
    "Les autres règles ont été réordonnées",
  "bank-management.rules.delete-rule-without-document.delete.success.message":
    "Les transactions ont été mises à jour",
  "bank-management.rules.delete-rule.delete.error.title":
    "Un problème est survenu",
  "bank-management.rules.delete-rule.delete.error.message":
    "La règle n'a pas été supprimée",
  "bank-management.rules.onboarding_unfinished":
    "Vous n'avez pas encore de comptes comptables disponibles sur lesquels vous pouvez affecter vos écritures bancaires",
  "bank-management.rules.transfer-rules.title": "Transférer toutes les règles",
  "bank-management.rules.transfer-rules.companies": "Société",
  "bank-management.rules.transfer-rules.account": "Compte bancaire",
  "bank-management.rules.transfer-rules.transfer":
    "Transférer toutes les règles",
  "bank-management.rules.transfer-rules.transfer.success-title":
    "Règles transférées",
  "bank-management.rules.transfer-rules.transfer.success-message":
    "Les règles ont bien été transférées sur votre compte bancaire",
  "bank-management.rules.transfer-rules.transfer.error-title":
    "Une erreur est survenue",
  "bank-management.rules.transfer-rules.transfer.error-message":
    "Les règles n'ont pas pu être transférées sur votre compte bancaire",

  "bank-management.rules-without-document.title":
    "Ne pas mettre de pièce jointe sur les conditions suivantes: ",
  "bank-management.rules-without-document.add-rule":
    "Ajouter une règle sans pièce jointe",
  "bank-management.rules-without-document.marked_without_document.success.title":
    "La transaction a été mise à jour!",
  "bank-management.rules-without-document.marked_without_document.success.message":
    "La transaction a bien été marquée {withOrWithout} document",
  "bank-management.rules-without-document.marked_without_document.error.title":
    "Une erreur est survenue",
  "bank-management.rules-without-document.marked_without_document.error.message":
    "Impossible de mettre à jour la transaction",
  "bank-management.rules-without-document.marked_without_document.error.message-full-doc":
    "Cette transaction a une pièce jointe rattachée",
  "bank-management.rules-without-document.marked_without_document.error.message-archive":
    "Cette transaction est déjà dans un archive",
  "bank-management.rules-without-document.empty1":
    "Vous n'avez pas encore de règles sans pièce jointe pour ce compte",
  "bank-management.rules-without-document.empty2":
    'Cliquer sur "Ajouter une règle sans pièce jointe" pour en créer une.',

  "bank-management.transaction.table-header.date": "Date",
  "bank-management.transaction.table-header.label": "Libellé",
  "bank-management.transaction.table-header.debit": "Débit",
  "bank-management.transaction.table-header.credit": "Crédit",
  "bank-management.transaction.table-header.amount": "Montant",
  "bank-management.transaction.table-header.assignment": "Affectation",
  "bank-management.transaction.table-header.status": "Statut",
  "bank-management.transaction.table-header.full-doc": "Document rattaché",
  "bank-management.transaction.table-header.status-affected": "Affecté",
  "bank-management.transaction.table-header.status-not-affected": "Non affecté",
  "bank-management.transaction.table-body.empty-space": "<nbsp></nbsp>",
  "bank-management.transaction.table-body.empty-transactions":
    "Aucune transaction",
  "bank-management.transaction.table-body.empty-transactions-search":
    "Aucune transaction ne correspond à votre recherche",
  "bank-management.transaction.table-body.empty-full-docs": "Aucune facture",
  "bank-management.transaction.tooltip.document.attached": "Document rattaché",
  "bank-management.transaction.tooltip.document.to-treat":
    "Pièce jointe en cours de traitement",
  "bank-management.transaction.tooltip.document.nothing-attached":
    "Aucun document rattaché",
  "bank-management.transaction.tooltip.document.need_action":
    "Votre pj est prête, une action est requise de votre part pour terminer le process",
  "bank-management.transaction.tooltip.document.marked-without-document":
    "Marquée comme sans pièce jointe",
  "bank-management.transaction.tooltip.history": "Historique",
  "bank-management.transaction.tooltip.toggle-deactivated":
    "Marquer sans pièce jointe",
  "bank-management.transaction.tooltip.toggle-activated":
    "Désactiver marquer sans pièce jointe",

  "bank-management.transaction.events.marked_without_document":
    "Marquer sans pièce jointe par ",
  "bank-management.transaction.events.remove_marked_without_document":
    "Désactiver marquer sans pièce jointe par ",
  "bank-management.transaction.events.attach_document":
    "Attacher une pièce jointe par ",
  "bank-management.transaction.events.detach_document":
    "Détacher une pièce jointe par ",
  "bank-management.transaction.events.create_uniq_rule":
    "Création d'une règle unique par ",
  "bank-management.transaction.events.empty":
    "Aucune modification n'a été effectué sur cette transaction.",

  "bank-management.transaction.detail.title": "Détails de la transaction",
  "bank-management.transaction.detail.rules.title":
    "Règle(s) d'affectation appliquée(s)",
  "bank-management.transaction.detail.rules.empty":
    "Aucune règle appliquée sur cette transaction",
  "bank-management.transaction.detail.rules.edit-line":
    "Ajouter une affectation uniquement sur cette ligne",
  "bank-management.transaction.detail.rules.add-line":
    "Ajouter une nouvelle règle",
  "bank-management.transaction.detail.rules.warning":
    "Cette nouvelle règle d'affectation sera appliquée sur <b>toutes les lignes</b>",
  "bank-management.transaction.details.batch-duplicate":
    "Votre pièce jointe <b>{currentName}</b> importée le <b>{currentDate}</b> par <b>{currentEmail}</b> a été traitée, vous pouvez choisir le document à rattacher. <b>Celle-ci est un doublon d'un document déjà déposé dans la boîte de dépôt.</b> ",
  "bank-management.transaction.details.batch-to-generate":
    "Votre pièce jointe <b>{currentName}</b> importée le <b>{currentDate}</b> par <b>{currentEmail}</b> a été traitée. ",
  "bank-management.transaction.details.batch-to-generate2":
    "<b>Générez maintenant vos écritures comptables pour accéder aux écritures bancaires.</b>",

  "bank-management.transaction.attach.table-header.user_file_name": "Document",
  "bank-management.transaction.attach.table-header.original_file_name": "Dépôt",
  "bank-management.transaction.attach.table-header.type": "Type",
  "bank-management.transaction.attach.table-header.merchant_name": "Tiers",
  "bank-management.transaction.attach.table-header.date": "Date facture/dépôt",
  "bank-management.transaction.attach.table-header.amount": "Montant",
  "bank-management.transaction.attach.table-header.type-invoice": "Facture",
  "bank-management.transaction.attach.table-header.type-credit_note": "Avoir",
  "bank-management.transaction.attach.table-header.type-bill_of_exchange":
    "Lettre de crédit",
  "bank-management.transaction.attach.table-header.buy": "achat",
  "bank-management.transaction.attach.table-header.sell": "vente",

  "bank-management.transaction.attach-modal.title": "Ajouter une pièce jointe",
  "bank-management.transaction.attach-modal.not-selected":
    "Cliquez sur une ligne pour visualiser la pièce jointe ",
  "bank-management.transaction.attach-modal.empty":
    "Aucune pièce jointe à sélectionner",
  "bank-management.transaction.attach-modal.download-batch":
    "Télécharger la pièce <b>{name}</b> importée le <b>{date}</b> par <b>{email}</b>",
  "bank-management.transaction.attach-modal.button-add":
    "Ajouter cette pièce jointe",
  "bank-management.transaction.attach-modal.button-replace":
    "Remplacer par cette pièce jointe",
  "bank-management.transaction.attach-modal.success-title":
    "Pièce jointe attachée",
  "bank-management.transaction.attach-modal.success-message":
    "La pièce jointe a bien été attachée à la transaction",
  "bank-management.transaction.attach-modal.error-title":
    "Pièce jointe non attachée",
  "bank-management.transaction.attach-modal.error-message":
    "La pièce jointe n'a pas pu être attachée à la transaction",
  "bank-management.transaction.attach-modal.not-selectable.invoice-buy":
    "Vous ne pouvez pas rattacher <b>une facture d'achat</b> sur une ligne en <b>entrée</b> d'argent",
  "bank-management.transaction.attach-modal.not-selectable.invoice-sell":
    "Vous ne pouvez pas rattacher <b>une facture de vente</b> sur une ligne en <b>sortie</b> d'argent",
  "bank-management.transaction.attach-modal.not-selectable.credit-note-buy":
    "Vous ne pouvez pas rattacher <b>un avoir d'achat</b> sur une ligne en <b>sortie</b> d'argent",
  "bank-management.transaction.attach-modal.not-selectable.credit-note-sell":
    "Vous ne pouvez pas rattacher <b>un avoir de vente</b> sur une ligne en <b>entrée</b> d'argent",
  "bank-management.transaction.attach-modal.not-selectable.bill_of_exchange-sell":
    "Vous ne pouvez pas rattacher <b>une lettre de crédit</b> sur une ligne en <b>entrée</b> d'argent",
  "bank-management.transaction.attach-modal.full-doc-attached":
    "Pièce jointe rattachée",
  "bank-management.transaction.attach-modal.marked-without-document":
    "Marqué comme sans pièce jointe",
  "bank-management.transaction.attach-modal.full-doc-attached-message":
    "Une pièce jointe a été rattachée, <b>vous ne pouvez plus ajouter d'affectation, ni de nouvelle règle</b> à cette transaction.",
  "bank-management.transaction.attach-modal.full-doc.detach":
    "Êtes-vous sûr de vouloir  détacher cette pièce jointe ?",
  "bank-management.transaction.attach-modal.full-doc.detach.cancel": "Annuler",
  "bank-management.transaction.attach-modal.full-doc.detach.confirm":
    "Détacher",
  "bank-management.transaction.attach-modal.full-doc.click-to-attach":
    "Cliquez ici pour ajouter une pièce jointe",
  "bank-management.transaction.attach-modal.full-doc.detach.title":
    "Pièce jointe détachée",
  "bank-management.transaction.attach-modal.full-doc.detach.message":
    "La pièce jointe a bien été détachée de la transaction",
  "bank-management.transaction.attach-modal.transaction-attached":
    "Cette pièce <b>a déjà été rattachée</b> à la ligne bancaire du {date} d'un montant de <b>{amount}</b>€",
  "bank-management.transaction.full-document-modal.attach-tab":
    "Attacher un document",
  "bank-management.transaction.full-document-modal.import-tab":
    "Importer une pièce jointe",
  "bank-management.transaction.full-document-modal.mark_without_doc":
    "Marquer sans pièce jointe",
  "bank-management.transaction.attach-modal.alert":
    "Vous avez la possibilité d'ajouter un document directement depuis votre ordinateur.",
  "bank-management.transaction.attach-modal.alert2":
    "<b>Une fois que ce document aura été traité, vous pourrez le rattacher.</b>",
  "bank-management.transaction.attach-modal.alert2-customer":
    "<b>Dès que votre comptable aura validé le document, vous pourrez le rattacher</b>",
  "bank-management.transaction.attach-modal.drop-text":
    "Cliquez ici pour ajouter une pièce jointe",
  "bank-management.transaction.attach-modal.batch-doc":
    "La pièce <b>{fileName}</b> importée le <b>{date}</b> par <b>{email}</b> est en cours de traitement.",
  "bank-management.transaction.attach-modal.batch-doc-customer":
    "La pièce <b>{fileName}</b> importée le <b>{date}</b> par <b>{email}</b> est en cours de validation.",
  "bank-management.transaction.attach-modal.detach-batch-doc":
    "Détacher cette pièce jointe",
  "bank-management.transaction.attach-modal.tooltip-warning-detach-batch":
    "Vous ne pouvez pas marquer cette transaction comme étant sans pièce jointe car un dépôt est en cours de traitement. Cliquez sur 'Détacher cette pièce jointe' à gauche si vous souhaitez ensuite pouvoir marquer la transaction comme pièce jointe",
  "bank-management.transaction.attach-modal.drop-batch-tooltip-success.title":
    "Pièce jointe importée!",
  "bank-management.transaction.attach-modal.drop-batch-tooltip-success.message":
    "La pièce jointe a bien été attachée à la transaction.",
  "bank-management.transaction.attach-modal.drop-batch-tooltip-error.title":
    "Une erreur est survenue",
  "bank-management.transaction.attach-modal.drop-batch-tooltip-error.message":
    "La pièce jointe n'a pas pu être rattachée à la transation",
  "bank-management.transaction.attach-modal.batch-duplicate":
    "Votre pièce jointe <b>{currentName}</b> importée le <b>{currentDate}</b> par <b>{currentEmail}</b>, est un <b>doublon</b> d'un document déjà déposé dans la boîte de dépôt: <b>{originalName}</b> importée le <b>{originalDate}</b> par <b>{originalEmail}</b> ",
  "bank-management.transaction.attach-modal.attach-original":
    "Attacher l'original",
  "bank-management.transaction.attach-modal.attach-doc": "Attacher le document",
  "bank-management.transaction.attach-modal.archive-ready":
    "<b>Certaines pièces sont prêtes à être générées.</b>",
  "bank-management.transaction.attach-modal.archive-ready2":
    "<b>Générez maintenant</b> vos écritures comptables <b>pour accéder aux écritures bancaires</b>.",
  "bank-management.transaction.attach-modal.archive-ready-customer":
    "<b>Votre pièce doit être validée par votre comptable.</b>",
  "bank-management.transaction.attach-modal.archive-ready2-customer":
    "Dès que cela sera fait, vous retrouverez <b>la pièce ici</b> afin de finaliser son <b>rattachement à la transaction.</b>",
  "bank-management.transaction.attach-modal.go-to-generate":
    "Voir mes écritures",

  "bank-management.archives.generate-archive-modal.title":
    "Générer les écritures",
  "bank-management.archives.generate-archive-modal.text1":
    "<b>Choisissez le niveau de détail des écritures à générer.</b>",
  "bank-management.archives.generate-archive-modal.text2":
    "Vous avez la possibilité de sélectionner parmi ces trois options:",
  "bank-management.archives.generate-archive-modal.choice1":
    "Générer <b>toutes les écritures</b>",
  "bank-management.archives.generate-archive-modal.choice2":
    "Générer toutes les écritures <b>sauf celles qui ne sont pas encore affectées (471)</b>",
  "bank-management.archives.generate-archive-modal.choice3":
    "Générer toutes les écritures <b>sauf celles qui n'ont pas encore de pièce jointe</b>",
  "bank-management.archives.generate-archive-modal.confirm":
    "Générer les écritures",
  "bank-management.archives.generate-archive-modal.cancel": "Retour",

  "full-documents.rename.label": "Nouveau nom du document",
  "company-full-document.rename.error.title":
    "Erreur lors du changement de nom",
  "company-full-document.rename.error.body":
    "Le document n'a pas pu être modifié",
  "company-full-document.rename.success.title": "Succès",
  "company-full-document.rename.success.body":
    "Vos modifications ont été enregistrées",

  "company.informations.company-name": "Nom de la société *",
  "company.informations.address": "Adresse *",
  "company.informations.postal-code": "Code postal *",
  "company.informations.city": "Ville *",
  "company.informations.siren": "SIREN / SIRET (9-14 Chiffres)",
  "company.informations.legal-form": "Forme juridique",
  "company.informations.city-registration-rcs": "Ville d'immatriculation R.C.S",
  "company.informations.share-capital": "Capital social (en €)",
  "company.informations.email": "Email",
  "company.informations.phone": "Numéro de téléphone",
  "company.informations.city-registration-rcs-not-applicable":
    "Ville d’immatriculation R.C.S non applicable pour la facturation",
  "company.informations.has-disabled-share-capital": "Pas de capital social",
  "company.informations.button.save": "Sauvegarder",
  "company.informations.toast.success.title": "Succès",
  "company.informations.toast.success.body":
    "Vos modifications ont bien été enregistrées",
  "company.informations.toast.error.title": "Erreur lors de la modification",
  "company.informations.toast.error.body":
    "Les modifications sur votre société n'ont pas pu aboutir",
  "company.informations.alert.infos":
    "Pour activer la facturation, vous devez remplir la <b>forme juridique</b> / le <b>capital social</b> de la société",
  "company.update-settings-success.title": "Mise à jour réussie",
  "company.update-settings-success.body":
    "Les informations de votre company ont été mises à jour",

  "menu.invoicing": "Facturation",
  "menu.invoicing.navigation": "Émettre un document",
  "menu.invoicing.documents": "Documents",
  "menu.invoicing.settings": "Paramètres de facturation",
  "menu.invoicing.settings.add-user": "Créer un nouveau client",
  "menu.invoicing.settings.filter.not-found":
    "Aucun client ne correspond à ces critères",

  "invoicing.settings.header-name": "Nom",
  "invoicing.settings.header-typology": "Typologie",
  "invoicing.settings.header-siren": "Siren",
  "invoicing.settings.header-total-discount": "Remise globale",
  "invoicing.settings.header-action": "Action",
  "invoicing.settings.header-action.edit": "éditer le client",
  "invoicing.settings.create-client.title": "Ajouter un nouveau client",
  "invoicing.settings.modify-client.title": "Modifier mon client",
  "invoicing.settings.create-client.private": "Particulier",
  "invoicing.settings.create-client.company": "Entreprise",
  "invoicing.settings.create-client.form.email.explanation":
    "Pour ajouter plusieurs emails, il vous suffit d'écrire chaque email séparé d'une virgule",
  "invoicing.settings.create-client.form.label.name": "Nom*",
  "invoicing.settings.create-client.form.label.address": "Adresse*",
  "invoicing.settings.create-client.form.label.city": "Ville*",
  "invoicing.settings.create-client.form.label.postalCode": "Code postal*",
  "invoicing.settings.create-client.form.label.phone": "Téléphone",
  "invoicing.settings.create-client.form.label.emails": "Email(s)",
  "invoicing.settings.create-client.form.create.cta": "Ajouter le client",
  "invoicing.settings.create-client.form.modify.cta": "Modifier le client",
  "invoicing.settings.create-client.form.reset": "Réinitialiser",
  "invoicing.settings.create-client.form.create.success.title":
    "Sauvegarde réussie!",
  "invoicing.settings.create-client.form.create.success.body":
    "Le nouveau client a bien été enregistré.",
  "invoicing.settings.modify-client.form.create.success.body":
    "Le client a bien été mis à jour.",
  "invoicing.settings.create-client.form.create.error.title":
    "Sauvegarde impossible",
  "invoicing.settings.create-client.form.create.error.body":
    "Le client n'a pas pu être enregistré.",
  "invoicing.settings.modify-client.form.create.error.body":
    "Le client n'a pas pu être mis à jour.",
  "invoicing.settings.create-client.know-company":
    "Un client <b>existe déjà avec ce SIREN / SIRET</b>.",
  "invoicing.settings.create-client.hidden-siren":
    "<b>Société non diffusable</b>",
  "invoicing.settings.create-client.know-private_person":
    "Un client <b>existe déjà avec ce nom</b>.",
  "invoicing.settings.create-client.know-siren":
    "Vous ne pouvez pas créer un client qui a le <b>même SIREN que votre société {companyName}</b>.",

  "invoicing.numbering.table.numbering": "Numérotations",
  "invoicing.numbering.table.creation-date": "Date de création",
  "invoicing.numbering.table.creation-email": "Créée par",
  "invoicing.numbering.table.action": "Action",

  "invoicing.bill-settings.title-settings": "PARAMETRES BANCAIRES ",
  "invoicing.bill-settings.title-mentions": "MENTIONS",
  "invoicing.bill-settings.title-logos": "LOGOS ADDITIONNELS",
  "invoicing.bill-settings.title-logos.explanation":
    "Vous avez la possibilité <boldColored>d'afficher jusqu'à trois logos additionnels sur vos factures</boldColored>, en plus du logo principal.",
  "invoicing.bill-settings.iban": "IBAN",
  "invoicing.bill-settings.bic": "BIC",
  "invoicing.bill-settings.owner-name": "Titulaire du compte",
  "invoicing.bill-settings.special-mentions": "Mentions particulières",
  "invoicing.bill-settings.special-discount":
    "Escompte pour paiement anticipé (%)",
  "invoicing.bill-settings.additional-logos.import.wrong-format":
    "Seuls les formats JPG/PNG/JPEG sont acceptés",
  "invoicing.bill-settings.additional-logos.defaults.title":
    "Logos additionnels mis en avant :",
  "invoicing.bill-settings.additional-logos.edit": "Gérer tous les logos",
  "invoicing.bill-settings.additional-logos.empty":
    "Vous n'avez aucun logo par défaut !",
  "invoicing.bill-settings.additional-logos.modal.title":
    "Gérer tous les logos additionnels",
  "invoicing.bill-settings.additional-logos.modal.empty":
    "Vous ne possédez aucun logo !",
  "invoicing.bill-settings.additional-logos.modal.header":
    "Sélectionnez <boldColored>3 logos à afficher par défaut</boldColored> sur vos factures",
  "invoicing.bill-settings.payment-period": "Délais de paiement",
  "invoicing.bill-settings.update-infos.save-button":
    "Valider mes informations",
  "invoicing.bill-settings.iban-error": "L'IBAN n'est pas au bon format.",
  "invoicing.bill-settings.bic-error": "Le BIC n'est pas au bon format.",
  "invoicing.bill-settings.special-discount-error":
    "L'escompte doit être compris entre 0 et 100 (nombre entier).",

  "invoicing.url.error.title": "Erreur de téléchargement",
  "invoicing.url.error.body": "Impossible de télécharger la facture",

  "invoicing.reminder.error.title": "Erreur de relancer",
  "invoicing.reminder.error.body": "L'email de relance n'a pas été envoyé",

  "invoicing.reminder.succes.title": "Relance envoyée",
  "invoicing.reminder.succes.body": "L'email de relance a été envoyé",

  "invoicing.customer.modal-history.title": "Dernières Modifications",
  "invoicing.customer.modal-history.client": "Client",
  "invoicing.customer.modal-history.ok": "Ok",
  "invoicing.customer.modal-history.created":
    "Client créé le {date} par {email}",
  "invoicing.customer.modal-history.changed_total_discount":
    "Modification du pourcentage de remise le {date} effectuée par {email}",
  "invoicing.customer.modal-history.no-changes":
    "Aucune modification n'a été effectuée sur ce client.",

  "invoicing.numbering.modal-history.title": "Dernières Modifications",
  "invoicing.numbering.modal-history.client": "Numérotation",
  "invoicing.numbering.modal-history.ok": "Ok",
  "invoicing.numbering.modal-history.no-changes": "Pas de modifications",
  "invoicing.numbering.modal-history.create":
    "Numérotation créée le {date} par {email}",
  "invoicing.numbering.modal-history.modify":
    "Numérotation modifiée le {date} par {email}",
  "invoicing.numbering.modal-history.deactivate":
    "Numérotation désactivée le {date} par {email}",
  "invoicing.numbering.modal-history.reactivate":
    "Numérotation réactivée le {date} par {email}",

  "writings.tooltip.next":
    "<b>ATTENTION !</b><nbsp></nbsp>Des écritures sont <b>disponibles</b> dans les <b>années fiscales suivantes</b>",
  "writings.tooltip.previous":
    "<b>ATTENTION !</b><nbsp></nbsp>Des écritures sont <b>disponibles</b> dans les <b>années fiscales précédentes</b>",
  "writings.tooltip.previous-and-next":
    "<b>ATTENTION !</b><nbsp></nbsp>Des écritures sont <b>disponibles</b> dans les <b>années fiscales précédentes et suivantes</b>",

  "writings.tooltip.hover.next":
    "Des écritures sont disponibles pour les années fiscales suivantes",
  "writings.tooltip.hover.previous":
    "Des écritures sont disponibles pour les années fiscales précédentes",
  "writings.tooltip.pending":
    "Ces dépôts sont en cours de traitement et s'affichent sur toutes les années fiscales par défaut, car le robot n’a pas encore extrait leur date",

  "writings.notifs.toaster.title": "Attention",
  "writings.notifs.toaster.prev-years":
    "Des écritures sont disponibles dans les exercices antérieurs.",
  "writings.notifs.toaster.next-years":
    "Des écritures sont disponibles dans les exercices postérieurs.",
  "writings.notifs.toaster.prevAndNext-years":
    "Des écritures sont disponibles dans les exercices antérieurs et postérieurs.",

  "user.incomplete-registration.error.title": "Erreur",
  "user.incomplete-registration.error.body":
    "Vos modifications n'ont pu être enregistrées.",
  "user.incomplete-registration.success.title": "Succès",
  "user.incomplete-registration.success.body":
    "Vos modifications ont été enregistrées",
  "user.incomplete-registration.title": "Inscription",
  "user.incomplete-registration.email": "Adresse email",
  "user.incomplete-registration.first-name": "Prénom",
  "user.incomplete-registration.last-name": "Nom",
  "user.incomplete-registration.password": "Saisissez votre mot de passe",
  "user.incomplete-registration.confirm-password":
    "Confirmez votre mot de passe",
  "user.incomplete-registration.length":
    "Votre mot de passe doit faire au moins <boldColored>8 caractères </boldColored>.",
  "user.incomplete-registration.format":
    "Votre mot de passe doit contenir au moins : <boldColored>1 majuscule, 1 minuscule, et 1 chiffre</boldColored>.",
  "user.incomplete-registration.mismatch":
    "Les deux mots de passe doivent être <boldColored>identiques</boldColored>.",
  "user.incomplete-registration.conditions": "J'accepte les",
  "user.incomplete-registration.conditions-link":
    "conditions générales d'utilisation",
  "user.incomplete-registration.sales-conditions-link":
    "conditions générales de ventes",
  "user.incomplete-registration.duplicate": "Cet email existe déjà.",
  "user.incomplete-registration.cta": "Créez votre compte",
  "user.incomplete-registration.email-taken":
    "Cet email a déjà un compte associé",
  "user.incomplete-registration.choose-another":
    "Utilisez un autre email pour créer votre compte",

  "menu.invoicing.settings.header-name": "Nom",
  "menu.invoicing.settings.header-typology": "Typologie",
  "menu.invoicing.settings.header-siren": "Siren",
  "menu.invoicing.settings.header-action": "Action",
  "menu.invoicing.settings.header-action.edit": "éditer le client",

  "invoicing.settings.products.header-name": "Nom",
  "invoicing.settings.products.header-unit": "Unité",
  "invoicing.settings.products.header-price": "Prix € HT",
  "invoicing.settings.products.header-vat-rate": "TAUX TVA",
  "invoicing.settings.products.header-price-tax-included": "Prix € TTC",
  "invoicing.settings.products.header-action": "Action",
  "invoicing.settings.products.header-description": "Description",
  "invoicing.settings.products.add-product.add-button":
    "Créer un nouveau produit",
  "invoicing.settings.products.create-product.title":
    "Créer un nouveau produit",
  "invoicing.settings.products.modify-product.title": "Modifier le produit",
  "invoicing.settings.products.add-product.label.product-name":
    "Nom du produit",
  "invoicing.settings.products.add-product.label.product-description":
    "Description",
  "invoicing.settings.products.add-product.label.unit": "Unité",
  "invoicing.settings.products.add-product.label.price": "Prix Hors Taxes",
  "invoicing.settings.products.add-product.label.price-vat-included":
    "Prix TTC",
  "invoicing.settings.products.add-product.label.vat": "TVA",
  "invoicing.settings.products.add-product.only-credit-note":
    "Article valable pour <boldBlack>avoirs uniquement</boldBlack>",
  "invoicing.settings.products.create-product.validate-button":
    "Ajouter le produit",
  "invoicing.settings.products.modify-product.validate-button":
    "Modifier le produit",
  "invoicing.settings.products.filter.empty-rows":
    "Aucun produit ne correspond à votre recherche",
  "invoicing.settings.products.toast.add-product.success.title":
    "Produit ajouté",
  "invoicing.settings.products.toast.add-product.success.body":
    "Le produit a été créé",
  "invoicing.settings.products.toast.add-product.error.title": "Erreur",
  "invoicing.settings.products.toast.add-product.error.body":
    "Un produit avec le même nom existe",
  "invoicing.settings.products.toast.modify-product.success.title":
    "Produit modifié",
  "invoicing.settings.products.toast.modify-product.success.body":
    "Le produit a été modifié",
  "invoicing.settings.products.toast.modify-product.error.title": "Erreur",
  "invoicing.settings.products.toast.modify-product.error.body":
    "Une erreur s'est produite lors de la modification d'un produit",
  "invoicing.settings.products.toast.delete-product.success.title":
    "Produit supprimé",
  "invoicing.settings.products.toast.delete-product.success.body":
    "Le produit a été correctement supprimé",
  "invoicing.settings.products.toast.delete-product.error.title": "Erreur",
  "invoicing.settings.products.toast.delete-product.error.body":
    "Une erreur s'est produite lors de la suppression du produit",
  "invoicing.settings.products.delete-product.confirmation":
    "Voulez-vous vraiment supprimer ce produit ?",
  "invoicing.additional-logos.add-additional-logos.drop-down-label":
    "Logos additionnels",
  "invoicing.additional-logos.add-additional-logos.no-options": "Aucun logo",
  "invoicing.settings.additional-logos.toast.add-additional-logo.success.title":
    "Logo ajouté",
  "invoicing.settings.additional-logos.toast.add-additional-logo.success.body":
    "Le logo a été créé",
  "invoicing.settings.additional-logos.toast.add-additional-logo.error.title":
    "Erreur",
  "invoicing.settings.additional-logos.toast.add-additional-logo.error.body":
    "Une erreur s'est produite lors de la création du logo",
  "invoicing.settings.additional-logos.toast.delete-additional-logo.success.title":
    "Logo supprimé",
  "invoicing.settings.additional-logos.toast.delete-additional-logo.success.body":
    "Le logo a été correctement supprimé",
  "invoicing.settings.additional-logos.toast.delete-additional-logo.error.title":
    "Erreur",
  "invoicing.settings.additional-logos.toast.delete-additional-logo.error.body":
    "Une erreur s'est produite lors de la suppression du logo",
  "invoicing.settings.additional-logos.delete-additional-logo.confirmation":
    "Voulez-vous vraiment supprimer ce logo ?",
  "invoicing.settings.additional-logos.toast.update-additional-logo.success.title":
    "Logo modifié",
  "invoicing.settings.additional-logos.toast.update-additional-logo.success.body":
    "Le logo a été correctement modifié",
  "invoicing.settings.additional-logos.toast.update-additional-logo.error.title":
    "Erreur",
  "invoicing.settings.additional-logos.toast.update-additional-logo.error.body":
    "Une erreur s'est produite lors de la modification du logo",
  "invoicing.settings.additional-logos.add-additional-logo.crop.save":
    "Sauvegarder mon logo",
  "invoicing.settings.additional-logos.add-additional-logo.crop.confirmation":
    "Sauvegarde en cours ...",
  "invoicing.text.double-points": " : ",
  "invoicing.text.phone": "Téléphone :",
  "invoicing.text.email": "Email :",
  "invoicing.text.siren-number": "Numéro de siren :",
  "invoicing.text.adress": "Adresse :",
  "invoicing.text.payment-infos": "Informations de paiement :",
  "invoicing.text.bic": "BIC :",
  "invoicing.text.iban": "IBAN :",
  "invoicing.text.account-owner": "Titulaire du compte :",
  "invoicing.text.legit-mention": "Mentions légales",
  "invoicing.text.mention": "Vos mentions particulières",
  "invoicing.text.description": "Description",
  "invoicing.text.free-text": "Texte libre",
  "invoicing.text.seller": "Vendeur",
  "invoicing.text.client.options": "Choix du client",
  "invoicing.text.client": "Client",
  "invoicing.text.client.create-client": "Créer un nouveau client",
  "invoicing.text.client.input.issuance-date": "Date d'émission",
  "invoicing.text.client.input.due-date": "Date d'échéance*",
  "quotation.text.client.input.due-date": "Date de validité*",
  "invoicing.text.client.select.numbering": "Choisir une numérotation*",
  "invoicing.text.table.product": "Produit",
  "invoicing.text.table.quantity": "Quantité",
  "invoicing.text.table.price": "Prix unitaire HT",
  "invoicing.text.table.vatRate": "Taux de TVA",
  "invoicing.text.table.productDiscount": "Remise",
  "invoicing.text.table.taxIncludedPrice": "Prix TTC",
  "invoicing.text.table.action": "Action",
  "invoicing.text.table.choose-product": "Choisir un produit*",
  "invoicing.text.total-HT": "Total HT :",
  "invoicing.text.total-VAT": "Total TVA :",
  "invoicing.text.total-tax-included": "Total TTC :",

  "invoicing.issuance.client.input.bill-date": "Date de la vente*",
  "invoicing.issuance.client.settings": "Paramètres de facturation",
  "invoicing.issuance.client.number-issuance": "Facture n°",
  "invoicing.issuance.email.object":
    "Votre facture N° {numberingPreview} est disponible",
  "invoicing.issuance.email.message": "Voici votre dernière facture",

  "quotation.issuance.client.input.bill-date": "Date du devis*",
  "quotation.issuance.client.settings": "Paramètres du devis",
  "quotation.issuance.client.number-issuance": "Devis n°",
  "quotation.issuance.email.object":
    "Votre devis N° {numberingPreview} est disponible",
  "quotation.issuance.email.message": "Voici votre dernier devis",

  "creditNote.issuance.client.input.bill-date": "Date de l'avoir*",
  "creditNote.issuance.client.settings": "Paramètres de l'avoir",
  "creditNote.issuance.client.number-issuance": "Avoir n°",
  "creditNote.issuance.email.object":
    "Votre avoir N° {numberingPreview} est disponible",
  "creditNote.issuance.email.message": "Voici votre dernier avoir",

  "invoicing.navigation.invoice-quotation": "Émettre un devis",
  "invoicing.navigation.invoice-quotation.description":
    "Créez un document professionnel en quelques clics pour vos futurs clients.",
  "invoicing.navigation.invoice-issuance": "Émettre une facture",
  "invoicing.navigation.invoice-issuance.description":
    "Éditez vos factures rapidement et concrétisez votre relation client à l’aide d’un modèle simple à remplir.",
  "invoicing.navigation.invoice-creditNote": "Émettre un avoir",
  "invoicing.navigation.invoice-creditNote.description":
    "Émettez un avoir pour corriger ou annuler une facture envoyée à vos clients.",
  "invoicing.issuance.product-discount": "Remise (%)",

  "invoicing.issuance.discount-alert":
    "<b>ATTENTION</b> : vous avez effectué <b>une remise par produit</b> et <b>une remise globale, ces deux remises vont s'additionner</b>",
  "invoicing.issuance.total-discount": "Remise globale (%)",
  "invoicing.issuance.special-discount": "Escompte pour paiement anticipé (%)",
  "invoicing.issuance.no-company-settings.1":
    "Chaintrust vous permet <b>d'émettre vos factures</b> et de les transférer<br></br> directement dans votre <boldColored>GED</boldColored>.",
  "invoicing.issuance.no-company-settings.2":
    "Votre <b>forme juridique / capital</b> social n'ont pas été remplis.",
  "invoicing.issuance.no-company-settings.button": "Paramétrer ma société",

  "invoicing.issuance.no-invoice-settings.1":
    "Chaintrust vous permet <b>d'émettre vos factures</b> et de les transférer<br></br> directement dans votre <boldColored>GED</boldColored>.",
  "invoicing.issuance.no-invoice-settings.2":
    "Les <b>paramètres de facturation</b> n’ont pas été remplis.",
  "invoicing.issuance.no-invoice-settings.3":
    "(Créer au moins un client, un produit, et une numérotation).",
  "invoicing.issuance.no-invoice-settings.button": "Paramétrer mes factures",

  "quotationEdit.preview.header.edit": "Modifier le devis",
  "quotationEdit.issuance.header.edit": "Modifier le devis {invoiceNumbering}",
  "quotationToInvoice.issuance.header.create-issuance":
    "passer le devis {invoiceNumbering} en facture",
  "invoiceToCreditNote.issuance.header.create-issuance":
    "contrepasser la facture {invoiceNumbering} en avoir",
  "invoiceToCreditNote.issuance.text.special-mention":
    "La durée de validité d'une facture d'avoir est de 5 ans (Art. L 110-4 du Code de Commerce). La facture d'avoir doit être conservée durant 10 ans et doit être accessible à tout moment par l'administration française.",
  "noType.issuance.header.create-issuance": "Créer un document",
  "document.issuance.toaster.error.title": "Impossible de créer le document",
  "document.issuance.toaster.error.body": "Le document n'a pas pu être créé",

  "draft.issuance.cta": "Sauvegarder le brouillon et quitter",
  "draft.issuance.toaster-preview.success.title": "Brouillon sauvegardé",
  "draft.issuance.toaster-preview.success.body":
    "Le brouillon a été correctement sauvegardé",

  "draft.destroy.toaster-preview.success.title": "Brouillon supprimé",
  "draft.destroy.toaster-preview.success.body":
    "Le brouillon a été correctement supprimé",

  "draft.issuance.toaster-preview.error.title":
    "Impossible de sauvegarder le brouillon",
  "draft.issuance.toaster-preview.error.body":
    "Le brouillon n'a pas pu être sauvegardé",

  "invoicing-document-history.modal.title": "Dernières modifications",
  "invoicing-document-history.modal.header": "Facture",
  "invoicing-document-history.modal.reminder":
    "Relance le {date} effectuée par {email}",
  "invoicing-document-history.modal.edit_quotation":
    "Modification du devis le {date} effectuée par {email}",
  "invoicing-document-history.modal.creation":
    "Création le {date} effectuée par {email}",
  "invoicing-document-history.modal.no-change":
    "Aucun évènement sur cette facture",

  "reminder.modal.title": "Relancer par email",
  "reminder.modal.cancel": "Retour",
  "reminder.modal.cta": "Envoyer",

  "issuance.legal-notice.RCS.true.shareCapital.true":
    "{companyName}, {legalForm} au Capital de {shareCapital} euros enregistrée sous le numéro de SIREN {siren} au R.C.S de {cityRegistrationRCS}",
  "issuance.legal-notice.RCS.true.shareCapital.false":
    "{companyName}, {legalForm} enregistrée sous le numéro de SIREN {siren} au R.C.S de {cityRegistrationRCS}",
  "issuance.legal-notice.RCS.false.shareCapital.true":
    "{companyName}, {legalForm} au Capital de {shareCapital} euros enregistrée sous le numéro de SIREN {siren}",
  "issuance.legal-notice.RCS.false.shareCapital.false":
    "{companyName}, {legalForm} enregistrée sous le numéro de SIREN {siren}",

  "invoicing.issuance.visualize.cta": "Prévisualiser la facture",
  "invoicing.issuance.save.cta": "Créer une facture",
  "invoicing.issuance.save-and-email.cta": "Créer et envoyer la facture",
  "invoicing.issuance.modal.title": "Créer une facture",
  "invoicing.issuance.product-name.label": " ",
  "invoicing.issuance.duplicate":
    "Vous avez déjà créé une même facture avec ce montant, ce fournisseur, et cette date : êtes-vous sûr de vouloir continuer ?",

  "invoicing.issuance.modal.text":
    "<b>Attention</b> une fois votre facture émise, elle sera <b>prise en compte comptablement</b>.<br> </br><br> </br> Vous ne pourrez plus l'annuler, êtes-vous sûr(e) ? <br> </br><br> </br> Vous pourrez <b>récupérer</b> cette facture dans l'onglet \"<bolderColored>Vos dépôts</bolderColored>\" <b>immédiatement</b> après sa création.<br> </br> <br> </br> Elle sera <bolderColored>saisie en GED sous 24h</bolderColored>.",

  "invoice.issuance.toaster.success.title": "Facture créée",
  "invoice.issuance.toaster.success.body": "La facture a bien été créée",
  "invoice.issuance.toaster.error.title": "Impossible de créer la facture",
  "invoice.issuance.toaster.error.body": "La facture n'a pas pu être créée",

  "invoice.issuance.toaster-preview.error.title":
    "Impossible de prévisualiser la facture",
  "invoice.issuance.toaster-preview.error.body":
    "La prévisualisation de facture n'a pas pu être créée",
  "invoice.issuance.modifications":
    "*Vos changements seront pris en compte pour les prochaines factures",

  "invoicing.issuance.preview.invoice-date": "Date de facture",

  "quotation.issuance.visualize.cta": "Prévisualiser le devis",
  "quotation.issuance.save.cta": "Créer un devis",
  "quotation.issuance.save-and-email.cta": "Créer et envoyer le devis",

  "quotationEdit.issuance.edit.cta": "Modifier le devis",
  "quotationEdit.issuance.edit-and-email.cta": "Modifier et envoyer le devis",

  "quotation.issuance.modal.title": "Créer un devis",
  "quotation.issuance.product-name.label": " ",
  "quotation.issuance.duplicate":
    "Vous avez déjà créé un même devis avec ce montant, ce fournisseur, et cette date : êtes-vous sûr de vouloir continuer ?",

  "quotation.issuance.modal.text":
    "<b>Vous allez émettre un devis. Êtes-vous sûr(e) ?</b> ",

  "quotation.issuance.toaster.success.title": "Devis créé",
  "quotation.issuance.toaster.success.body": "Le devis a bien été créé",
  "quotation.issuance.toaster.error.title": "Impossible de créer le devis",
  "quotation.issuance.toaster.error.body": "Le devis n'a pas pu être créé",

  "quotation.issuance.toaster-preview.error.title":
    "Impossible de prévisualiser le devis",
  "quotation.issuance.toaster-preview.error.body":
    "La prévisualisation du devis n'a pas pu être créée",
  "quotation.issuance.preview.invoice-date": "Date du devis",

  "creditNote.issuance.visualize.cta": "Prévisualiser l'avoir",
  "creditNote.issuance.save.cta": "Créer un avoir",
  "creditNote.issuance.save-and-email.cta": "Créer et envoyer l'avoir",
  "creditNote.issuance.modal.title": "Créer un avoir",
  "creditNote.issuance.product-name.label": " ",
  "creditNote.issuance.duplicate":
    "Vous avez déjà créé un même avoir avec ce montant, ce fournisseur, et cette date : êtes-vous sûr de vouloir continuer ?",

  "creditNote.issuance.modal.text":
    "<b>Attention</b> une fois votre avoir émis, il sera <b>pris en compte comptablement</b>.<br> </br><br> </br> Vous ne pourrez plus l'annuler, êtes-vous sûr(e) ? <br> </br><br> </br> Vous pourrez <b>récupérer</b> cet avoir dans l'onglet \"<bolderColored>Vos dépôts</bolderColored>\" <b>immédiatement</b> après sa création.<br> </br> <br> </br> Il sera <bolderColored>saisie en GED sous 24h</bolderColored>.",

  "creditNote.issuance.toaster.success.title": "Avoir créé",
  "creditNote.issuance.toaster.success.body": "L'avoir a bien été créé",
  "creditNote.issuance.toaster.error.title": "Impossible de créer l'avoir",
  "creditNote.issuance.toaster.error.body": "L'avoir n'a pas pu être créé",

  "creditNote.issuance.toaster-preview.error.title":
    "Impossible de prévisualiser l'avoir",
  "creditNote.issuance.toaster-preview.error.body":
    "La prévisualisation de l'avoir n'a pas pu être créée",
  "creditNote.issuance.preview.invoice-date": "Date de l'avoir",

  "overtaking.issuance.toaster-preview.error.title":
    "Impossible de créer le document",
  "overtaking.issuance.toaster-preview.error.body":
    "Le document précédent n'a pas pu être chargé",

  "url.issuance.toaster-preview.error.title": "Impossible de créer le document",
  "url.issuance.toaster-preview.error.body": "L'url n'est pas valide",

  "invoicing.preview.seller": "Vendeur",
  "invoicing.preview.buyer": "Client",

  "invoicing.preview.modal.title": "Créer une facture",
  "invoicing.preview.modal.cancel": "Annuler",
  "creditNote.invoicing.preview.modal.send-by-email":
    "Envoyer l'avoir par email",
  "quotation.invoicing.preview.modal.send-by-email":
    "Envoyer le devis par email",
  "invoicing.invoicing.preview.modal.send-by-email":
    "Envoyer la facture par email",
  "invoicing.preview.modal.emails": "Email(s)* / À",
  "invoicing.preview.modal.bccEmails": "Copie carbone invisible / CCI",
  "invoicing.preview.modal.object": "Objet de l'email*",
  "invoicing.preview.modal.message": "Message*",

  "invoicing.documents.header-number": "Numéro",
  "invoicing.documents.header-emailedTo": "Envoyé à",
  "invoicing.documents.header-date": "Date d'émission",
  "invoicing.documents.header-amount": "Montant TTC",
  "invoicing.documents.header-client": "Client",
  "invoicing.documents.header-issue": "Emis par",
  "invoicing.documents.header-action": "Action",
  "invoicing.documents.empty": "Vous n'avez pas encore émis de documents",

  "invoicing-draft.documents.header-type": "Type de document",
  "invoicing-draft.documents.header-emailedTo": "Envoyé à",
  "invoicing-draft.documents.header-date": "Date",
  "invoicing-draft.documents.header-amount": "Montant TTC",
  "invoicing-draft.documents.header-client": "Client",
  "invoicing-draft.documents.header-issue": "Emis par",
  "invoicing-draft.documents.header-action": "Action",
  "invoicing-draft.documents.empty": "Vous n'avez pas encore de brouillons",

  "more-actions.seeDetails": "Voir les détails",
  "more-actions.inputMask": "Masque de Saisie",
  "more-actions.reminder": "Relancer",
  "more-actions.duplicate": "Dupliquer",
  "more-actions.history": "Voir les dernières modifications",
  "more-actions.rename": "Renommer",
  "more-actions.edit": "Modifier",
  "more-actions.delete": "Supprimer",
  "more-actions.download": "Télécharger",
  "more-actions.preview": "Visualiser",
  "more-actions.overtakingToInvoice": "Passer en facture",
  "more-actions.overtakingToCreditNote": "Contrepasser en avoir",

  "input-mask.code.sell-invoice": "Code Client: <b>{merchantCode}</b>",
  "input-mask.code.buy-invoice": "Code Fournisseur: <b>{merchantCode}</b>",
  "input-mask.code.sell-credit_note": "Code Client: <b>{merchantCode}</b>",
  "input-mask.code.buy-credit_note": "Code Fournisseur: <b>{merchantCode}</b>",

  "merchant-buy.fuel.tooltip":
    "Sur ce fournisseur, 80% de la TVA sera comptabilisée",
  "merchant-buy.fuel.disabled.tooltip":
    "Sur ce fournisseur, 100% de la TVA sera comptabilisée",

  "merchant-sell.fuel.tooltip":
    "Sur ce client, 80% de la TVA sera comptabilisée",
  "merchant-sell.fuel.disabled.tooltip":
    "Sur ce client, 100% de la TVA sera comptabilisée",

  "input-mask.fuel.tooltip":
    "TVA ESSENCE VTC : 80% du montant de la TVA normale sera appliquée sur ces écritures",
  "input-mask.original-batch-name": "<b>Dépôt original</b> :",
  "input-mask.info": "Informations",
  "input-mask.invoice-info": "Informations sur votre facture",
  "input-mask.accounting": "Comptabilité",
  "input-mask.doctype": "Type de document",
  "input-mask.reference.duplicate-error":
    "Cette <b>référence</b> a <b>déjà</b> été <b>utilisée</b> en vente.",
  "input-mask.reference.empty": "La référence est <b>obligatoire</b> en vente",
  "input-mask.writings": "Écritures comptables",
  "input-mask.creditNote": "Crédit",
  "input-mask.debit": "Débit",
  "input-mask.account": "Numéro de compte & Libellé du compte",
  "input-mask.block-message":
    "Rappel : vous pouvez modifier les règles de ce fournisseur dans votre ",
  "input-mask.taxed": "TTC",
  "input-mask.untaxed": "HT",
  "input-mask.alert.date":
    "Attention, la facture <b>changera d'année fiscale</b> et partira dans l'année <b>{beginExercise} - {endExercise}</b>.",
  "input-mask.entry_type": "{entryType}",
  "input-mask.amount": "{amount}{currency_symbol}",
  "input-mask.qualification": "Qualification",

  "input-mask.deactivate_modal.deactivation": "Raison de désactivation",
  "input-mask.deactivate_modal.cancel": "Annuler",
  "input-mask.deactivate_modal.deactivate": "Désactiver",

  "input-mask.update.informations.success.title": "Facture modifiée",
  "input-mask.update.informations.success.body":
    "Les informations concernant votre facture ont bien été modifiées",
  "input-mask.update.informations.failure.title": "Facture non modifiée",
  "input-mask.update.due_date.failure.title": "Date d'échéance non modifiée",
  "input-mask.update.informations.failure.body":
    "Certaines informations de votre factures n'ont pu être modifiées",
  "input-mask.update.accounts.success.title": "Comptes modifiés",
  "input-mask.update.accounts.success.body":
    "Les modifications concernant les comptes de votre facture ont bien été modifiés",
  "input-mask.update.accounts.failure.title": "Comptes modifiés",
  "input-mask.update.accounts.failure.body":
    "Un ou plusieurs comptes n'ont pu être modifié",
  "input.mask.no-documents": "Aucun document à afficher",
  "input-mask.leave.alert-message":
    "Vous êtes en train de vérifier des écritures. Si vous quittez maintenant, l'archive ne se génèrera pas",

  "input-mask.history.title": "Dernières modifications",
  "input-mask.history.none": "Aucune",
  "input-mask.history.modification.due_date":
    "<b>Modification</b> de date d'échéance par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancienne date d'échéance : </b><boldColored>{oldValue}</boldColored><b> ; Nouvelle date d'échéance : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.document_date":
    "<b>Modification</b> de date par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancienne date : </b><boldColored>{oldValue}</boldColored><b> ; Nouvelle date : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.document_type":
    "<b>Modification</b> de type de document par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancienne valeur : </b><boldColored>{oldValue}</boldColored><b> ; Nouvelle valeur : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.buy_or_sell":
    "<b>Modification</b> achat/vente par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancienne valeur : </b><boldColored>{oldValue}</boldColored><b> ; Nouvelle valeur : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.reference":
    "<b>Modification</b> de référence par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancienne référence : </b><boldColored>{oldValue}</boldColored><b> ; Nouvelle référence : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.account":
    "<b>Modification</b> du compte de la ligne <boldColored>{entryType} {amount}{currencyCode}</boldColored> par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancien compte : </b><boldColored>{oldValue}</boldColored><b> ; Nouveau compte : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.amount":
    "<b>Modification</b> du montant de la ligne <boldColored>{entryType} {amount}{currencyCode}</boldColored> par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Ancien montant : </b><boldColored>{oldValue}</boldColored><b> ; Nouveau montant : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.modification.create_line":
    "<b>Ajout d'une ligne d'écriture</b> {entryType} {amount}{currencyCode} par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> ",
  "input-mask.history.modification.delete_line":
    "<b>Suppression d'une ligne d'écriture</b> {entryType} {amount}{currencyCode} par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> ",

  "input-mask.history.modification.block":
    "<b>Verrouillage</b> de compte par <boldColored>{email}</boldColored> le <boldColored>{dateTimestamp}</boldColored> à <boldColored>{timeTimestamp}</boldColored> <b>(Compte : </b><boldColored>{newValue}</boldColored><b>)</b>",
  "input-mask.history.button": "OK",
  "input-mask.history.nothing-display": "Pas de dernières modifications",
  "input-mask.get-documents-from-full-document.error.title": "Erreur",
  "input-mask.get-documents-from-full-document.error.body":
    "Impossible de récupérer les documents.",

  "input-mask.leave.without-saving":
    "Vous avez effectué des <b>modifications</b> sur cette facture, si vous <b>quittez</b> cette page, ces modifications <b>ne seront pas prises en compte</b>.",
  "input-mask.leave.without-saving.next":
    "Vous avez effectué des <b>modifications</b> sur cette facture, si vous <b>avancez</b> sur la <b>prochaine facture</b>, ces modifications <b>ne seront pas prises en compte</b>.",
  "input-mask.leave.without-saving.previous":
    "Vous avez effectué des <b>modifications</b> sur cette facture, si vous <b>revenez</b> en <b>arrière</b>, ces modifications <b>ne seront pas prises en compte</b>.",
  "input-mask.leave.without-saving.cancel": "annuler",
  "input-mask.leave.without-saving.continue": "ok",
  "input-mask.button.first-page":
    "Cette facture est la <b>première</b> facture",
  "input-mask.button.last-page": "Cette facture est la <b>dernière</b> facture",

  "input-mask.full-document.periodicity": "Périodicité :",
  "input-mask.full-document.periodicity-none": "Aucune",
  "input-mask.table-headers.already-seen.tooltip":
    "Cette colonne vous permet d’avoir un suivi visuel, sans impacter la génération de vos écritures comptables.",

  "input-mask.table-headers.already-seen": "Suivi",
  "input-mask.table-headers.merchant-code.buy": "Fournisseur (401)",
  "input-mask.table-headers.merchant-code": "Code",
  "input-mask.table-headers.merchant-code.sell": "Client (411)",
  "input-mask.table-headers.merchant-code.loading": " ",
  "input-mask.table-headers.document-date": "Date",
  "input-mask.table-headers.including-taxes": "TTC",
  "input-mask.table-headers.buy-or-sell": "Typologie",
  "input-mask.table-headers.reference": "Référence document",
  "input-mask.table-headers.merchant-name": "Nom",
  "input-mask.table-headers.merchant-name.buy": "Libellé Fournisseur",
  "input-mask.table-headers.merchant-name.sell": "Libellé Client",
  "input-mask.table-headers.is-new-merchant": "New",
  "input-mask.table-headers.merchant-name.loading": " ",
  "input-mask.table-headers.due-date": "Échéance",
  "input-mask.table-headers.original-batch-name": "Dépôt original",

  "input-mask.table-headers.account": "Compte",
  "input-mask.table-headers.debit": "Débit",
  "input-mask.table-headers.credit": "Crédit",

  "input-mask.buy.already-taken-code.warning":
    "Ce code fournisseur est déjà utilisé par <b>{merchantNames}</b>, voulez-vous le passer en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés fournisseurs ?",
  "input-mask.buy.already-taken-code.warning-and-centralize":
    "Ce code fournisseur est déjà utilisé par <b>{merchantNames}</b>, voulez-vous le passer en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés fournisseurs ? <b>Attention</b>, votre société est indiquée comme ne souhaitant pas de code de regroupement. Afin de passer ce code en code de regroupement, nous allons désactiver cette option sur votre société",
  "input-mask.buy.already-taken-code.confirm":
    "Ce code fournisseur passera en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés fournisseurs",

  "input-mask.sell.already-taken-code.warning":
    "Ce code client est déjà utilisé par <b>{merchantNames}</b>, voulez-vous le passer en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés clients ? <b>Attention</b>, votre société est indiquée comme ne souhaitant pas de code de regroupement.",
  "input-mask.sell.already-taken-code.warning-and-centralize":
    "Ce code client est déjà utilisé par <b>{merchantNames}</b>, voulez-vous le passer en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés clients ? <b>Attention</b>, votre société est indiquée comme ne souhaitant pas de code de regroupement. Afin de passer ce code en code de regroupement, nous allons désactiver cette option sur votre société",
  "input-mask.sell.already-taken-code.confirm":
    "Ce code client passera en <b>code de regroupement</b> pour pouvoir accueillir plusieurs libellés clients",

  "input-mask.impacted-documents":
    "{impactedDocuments} factures vont être impactées",
  "input-mask.impacted-document": "1 facture va être impactée",
  "input-mask.fiscal-year-change":
    "<b>Attention,</b> la facture <b>changera d'année fiscale</b> et partira dans l'année <b>{begin_exercise} - {end_exercise}.</b>",

  "input-mask.invoices": "Toutes les factures",
  "input-mask.invoices.pages": "Pages :",
  "input-mask.invoices.generate-writing": "Générer automatiquement",
  "input-mask.content.tabs.processed": "Traités",
  "input-mask.content.tabs.deactivated": "Dossier de contrôle",
  "input-mask.document-type.sell-invoice": "Facture de vente",
  "input-mask.document-type.buy-invoice": "Facture d'achat",
  "input-mask.document-type.sell-credit_note": "Avoir de vente",
  "input-mask.document-type.buy-credit_note": "Avoir d'achat",
  "input-mask.document-type.buy-bill_of_exchange": "Lettre de crédit",
  "input-mask.document-type.sell-bill_of_exchange": "Lettre de crédit",
  "input-mask.invoicing": "Date de facture",
  "input-mask.reference": "Référence",
  "input-mask.term": "Date d'échéance",
  "input-mask.save": "Sauvegarder",
  "input-mask.save.tooltip":
    "Veuillez remplir tous les champs pour pouvoir sauvegarder",
  "input-mask.deactivate": "Désactiver",
  "input-mask.cancel": "Annuler",
  "input-mask.reset": "Annuler les modifications",
  "input-mask.block-sell.tax_excluded":
    "Bloquer ce compte produit pour ce client ?",
  "input-mask.block-buy.tax_excluded":
    "Bloquer ce compte de charges pour ce fournisseur ?",
  "input-mask.block-sell.vat": "Bloquer ce compte de TVA pour ce client ?",
  "input-mask.block-buy.vat": "Bloquer ce compte de TVA pour ce fournisseur ?",
  "input-mask.deactivated-account":
    "<boldSmallGrey>(désactivé le {deactivatedAt})</boldSmallGrey> {account}",

  "input-mask.merchant-and-code.label":
    "{merchantName} <italicColored>({merchantCode})</italicColored>",
  "input-mask.modify-merchant.success.title": "Modification effectuée",
  "input-mask.modify-merchant.success.body-buy":
    "Le changement de fournisseur par défaut a été pris en compte",
  "input-mask.modify-merchant.success.sell":
    "Le changement de client par défaut a été pris en compte",

  "input-mask.modify-merchant.error.title": "Erreur lors de la modification",
  "input-mask.modify-merchant.error.body-buy":
    "Le changement de fournisseur par défaut n'a pas pu être pris en compte",
  "input-mask.modify-merchant.error.sell":
    "Le changement de client par défaut n'a pas pu être pris en compte",

  "input-mask.deactivate-document.success.title": "Document désactivé",
  "input-mask.deactivate-document.success.body":
    "Votre document a bien été désactivé",

  "input-mask.entry-data.tax_included": "TTC",
  "input-mask.entry-data.tax_excluded": "HT",
  "input-mask.entry-data.vat": "TVA",
  "input-mask.entry-data.eu_vat": "TVA intraco UE services",
  "input-mask.entry-data.eu_goods_vat": "TVA intraco UE biens",
  "input-mask.entry-data.world_vat": "TVA hors UE services",
  "input-mask.entry-data.world_goods_vat": "TVA hors UE biens",
  "input-mask.entry-data.deposit": "Consigne",
  "input-mask.entry-data.reverse_deposit": "Déconsigne",
  "input-mask.entry-data.construction_vat": "Autoliquidation BTP",

  "input-mask.entry-data.goods_transfer_vat":
    "TVA sur transfert de marchandises",
  "input-mask.entry-data.public_receivables_goods_transfer":
    "Encaissement de créances publiques",
  "input-mask.entry-data.public_receivables_goods_transfer_commission":
    "Commission sur Encaissement de créances publiques",
  "input-mask.entry-data.tobacco_goods_transfer": "Marchandises de tabac",
  "input-mask.entry-data.tobacco_goods_transfer_commission":
    "Commission sur Marchandises de tabac",
  "input-mask.entry-data.monetics_goods_transfer": "Marchandises de monétique",
  "input-mask.entry-data.monetics_goods_transfer_commission":
    "Commission sur Marchandises de monétique",
  "input-mask.entry-data.scratch_games_goods_transfer":
    "Marchandises de jeux de grattage",
  "input-mask.entry-data.scratch_games_goods_transfer_commission":
    "Commission sur Marchandises de jeux de grattage",
  "input-mask.entry-data.scratch_games_paid_lots_goods_transfer":
    "Petits lots payés de jeux de grattage",
  "input-mask.entry-data.draw_games_goods_transfer":
    "Marchandises de jeux de tirage",
  "input-mask.entry-data.draw_games_paid_lots_goods_transfer":
    "Petits lots payés de jeux de tirage",
  "input-mask.entry-data.draw_games_goods_transfer_commission":
    "Commission sur Marchandises de jeux de tirage",
  "input-mask.entry-data.tax_stamps_goods_transfer":
    "Marchandises de timbres fiscaux",
  "input-mask.entry-data.tax_stamps_goods_transfer_commission":
    "Commission sur Marchandises de timbres fiscaux",
  "input-mask.entry-data.postage_stamps_goods_transfer":
    "Marchandises de timbres poste",
  "input-mask.entry-data.postage_stamps_goods_transfer_commission":
    "Commission sur Marchandises de timbres poste",
  "input-mask.entry-data.metro_goods_transfer":
    "Marchandises de transports publics",
  "input-mask.entry-data.metro_goods_transfer_commission":
    "Commission sur Marchandises de transports publics",
  "input-mask.entry-data.press_goods_transfer": "Marchandises de presse",
  "input-mask.entry-data.press_goods_transfer_commission":
    "Commission sur Marchandises de presse",
  "input-mask.entry-data.other_goods_transfer": "Marchandises autres",
  "input-mask.entry-data.other_goods_transfer_commission":
    "Commission sur Marchandises autres",
  "input-mask.entry-data.tax_excluded_bill_of_exchange": "Effet à payer",
  "input-mask.entry-data.tax_included_bill_of_exchange": "TTC",
  "input-mask.entry-data.custom": "Divers",

  "input-mask.select-add.hint": "Cliquez ci-dessous, pour ajouter une ligne",
  "input-mask.select-add.options.0": "0%",
  "input-mask.select-add.options.2.1": "2.1%",
  "input-mask.select-add.options.5.5": "5.5%",
  "input-mask.select-add.options.8.5": "8.5%",
  "input-mask.select-add.options.10": "10%",
  "input-mask.select-add.options.13": "13%",
  "input-mask.select-add.options.20": "20%",
  "input-mask.select-add.options.deposit": "Consigne",
  "input-mask.select-add.options.reverse_deposit": "Déconsigne",
  "input-mask.select-add.options.eu_vat": "Intraco UE services",
  "input-mask.select-add.options.eu_goods_vat": "Intraco UE biens",
  "input-mask.select-add.options.world_vat": "Hors UE services",
  "input-mask.select-add.options.world_goods_vat": "Hors UE biens",
  "input-mask.select-add.options.construction_vat": "BTP",
  "input-mask.select-add.options.custom_debit": "Divers débit",
  "input-mask.select-add.options.custom_credit": "Divers crédit",
  "input-mask.select-add.options.tax_excluded_bill_of_exchange":
    "Effet à payer",

  "input-mask.select-add.tooltip.onboarding-unfinished":
    "Le paramétrage n'est pas encore prêt",
  "input-mask.select-add.tooltip.missing-account":
    "Ce type de ligne doit d'abord être paramétré dans votre Plan Comptable",
  "input-mask.select-add.tooltip.missing-vat-account":
    "Ce type de TVA doit d'abord être paramétré dans votre Plan Comptable",
  "input-mask.select-add.tooltip.autoliquidation-sell":
    "L'autoliquidation n'est pas disponible sur les factures de vente",
  "input-mask.select-add.tooltip.multiple-vat":
    "Différents types de TVA ne peuvent pas être mélangés sur une même facture",

  "input-mask.add-line": "Ajouter une ligne",
  "input-mask.typology.invoice-buy": "Facture d'achat",
  "input-mask.typology.invoice-sell": "Facture de vente",
  "input-mask.typology.credit_note-buy": "Avoir d'achat",
  "input-mask.typology.credit_note-sell": "Avoir de vente",
  "input-mask.typology.bill_of_exchange-buy": "Lettre de crédit",

  "input-mask.create-merchant.title.buy": "Rattacher un fournisseur",
  "input-mask.create-merchant.title.sell": "Rattacher un client",
  "input-mask.create-merchant.with-siren": "Avec SIREN",
  "input-mask.create-merchant.without-siren": "Sans SIREN",
  "input-mask.create-merchant.no-siren-found":
    "<b>Nous n’avons trouvé aucun SIREN,</b> vous ne pouvez créer qu’un nouveau client <b>sans SIREN.</b>",

  "input-mask.create-merchant.identification-number": "Sélectionner un SIREN*",
  "input-mask.create-merchant.company-name": "Nom",
  "input-mask.create-merchant.buy.merchant-name":
    "Créer ou sélectionner le libellé du fournisseur",
  "input-mask.create-merchant.buy.merchant-code":
    "Créer ou sélectionner un code fournisseur",
  "input-mask.create-merchant.sell.merchant-name":
    "Créer ou sélectionner un client",
  "input-mask.create-merchant.sell.merchant-code":
    "Créer ou sélectionner un code client",
  "input-mask.create-merchant.code-warning.buy":
    "Ce code fournisseur sera créé",
  "input-mask.create-merchant.code-warning.sell": "Ce code client sera créé",
  "input-mask.create-merchant.cta.buy": "Ajouter le fournisseur",
  "input-mask.create-merchant.cta.sell": "Ajouter le client",
  "input-mask.create-merchant.merchant-code.option": "Créer le code",

  "input-mask.toaster.error.title": "Erreur lors de la modification",
  "input-mask.toaster.error.body":
    "Vos modifications n'ont pas pu être prises en compte",
  "input-mask.toaster.error-vat-buy.title": "Erreur lors de la modification",
  "input-mask.toaster.error-vat-buy.body":
    "Ce fournisseur est paramétré sans TVA",
  "input-mask.toaster.error-vat-sell.title": "Erreur lors de la modification",
  "input-mask.toaster.error-vat-sell.body": "Ce client est paramétré sans TVA",
  "input-mask.toaster.error-sell-reference.title": "Erreur de référence",
  "input-mask.toaster.error-sell-reference.body":
    "Vous devez rentrer une référence en ventes",
  "input-mask.toaster.error-sell-duplicate-reference.title":
    "Doublon de référence",
  "input-mask.toaster.error-sell-duplicate-reference.body":
    "Cette référence existe déjà en vente",
  "input-mask.toaster.error-bad-reverse-deposit.title":
    "Déconsigne non autorisée",
  "input-mask.toaster.error-bad-reverse-deposit.body":
    "Les lignes de type déconsigne sont autorisées uniquement pour les factures",
  "input-mask.toaster.error-code-already-used.title":
    "Code fournisseur déjà utilisé",
  "input-mask.toaster.success.title": "Lignes d'écriture modifiées",
  "input-mask.toaster.success.body":
    "Vos modifications ont bien été prises en compte",
  "input-mask.toaster.error.merchant-code-creation.title":
    "Erreur lors de la création du code",
  "input-mask.toaster.error.merchant-code-creation.body.too-long":
    "Le code est trop long !",
  "input-mask.toaster.error.merchant-code-creation.body.other":
    "Une erreur est survenue lors de la création du code.",
  "input-mask.toaster.error.merchant-creation.title":
    "Erreur lors de la création",
  "input-mask.toaster.error.merchant-creation.body":
    "Le fournisseur n'a pas pu être créé",

  "input-mask.filter-dropdown.show": "Afficher",
  "input-mask.filter-dropdown.all": "Tout",
  "input-mask.filter-dropdown.not-seen": "Non revues",
  "input-mask.filter-dropdown.already-seen": "Déjà revues",

  "writings.confirm-verification.title": "Vérification",
  "writings.confirm-verification.description.1":
    "Vous avez la possibilité de <b>vérifier</b> ou de <b>générer automatiquement</b><br></br>vos écritures.",
  "writings.confirm-verification.description.2":
    "Voulez-vous <b>vérifier</b> les écritures ?",
  "writings.confirm-verification.button.verify": "Vérifier les écritures",
  "writings.confirm-verification.button.generate": "Générer automatiquement",
  "writings.confirm-verification.cancel-button.confirm":
    "Vous êtes en train de vérifier des écritures. Si vous quittez maintenant, l'archive ne se génèrera pas.",
  "writings.download.history.check": "Voir l'historique des événements",
  "writings.download.history.modal.title": "Détails des derniers événements",
  "writings.download.history.modal.no-content":
    "Aucun téléchargement pour cette archive",
  "writings.download.history.modal.line":
    "<boldBlack>Téléchargée</boldBlack> par <underlineColored>{user}</underlineColored> le {date1} à {date2}",
  "writings.download.history.modal.user-archive-generator":
    "<boldBlack>Générée</boldBlack> par <underlineColored>{user}</underlineColored> le {date1} à {date2}",
  "writings.download.history.modal.user-writing-validator":
    "<boldBlack>{count} facture(s) validée(s)</boldBlack> par <underlineColored>{user}</underlineColored> le {date1} à {date2}",
  "writings.download.history.get-archive.error.title": "Erreur",
  "writings.download.history.get-archive.error.body":
    "Une erreur est survenue lors de la récupération de l'historique",
  "writings.send-to-api.true": "Envoyer les écritures par API",
  "writings.send-to-api.false": "Ne pas envoyer les écritures par API",

  "writings.validation-writing.title": "Validation des écritures",
  "writings.validation-writing.text1":
    "<b>Besoin de vos écritures comptables rapidement ?</b>",
  "writings.validation-writing.text2":
    "Chaintrust vous permet de les valider manuellement.",
  "writings.validation-writing.text3":
    "<b>Attention, cela signifie que les tests de cohérence ne seront pas finalisés par l'IA.</b> Ainsi <b>certaines écritures peuvent comporter des erreurs.</b> Cette action est <b>irréversible</b>. Après avoir validé, vous accéderez <b>au masque de saisie</b> pour vérifier vos écritures avant de les générer.",
  "writings.validation-writing.text4":
    "<b>À savoir</b>, si vous avez déposé en même temps des factures N et N-1, la validation des écritures sur l'année N entraînera la validation des écritures N-1 du même dépôt.",
  "writings.validation-writing.cancel": "Retour",
  "writings.validation-writing.tooltip.error.title": "Une erreur est survenue",
  "writings.validation-writing.tooltip.error.message":
    "Les écritures ne peuvent pas être validées pour le moment. Veuillez recharger votre navigateur",
  "writings.validation-writing.tooltip.success.title":
    "Les écritures ont bien été validées !",
  "writings.validation-writing.tooltip.success.message":
    "Vous pouvez maintenant vérifier et générer vos écritures.",

  "writings.set-status-title.pending": "Dépôts en cours de traitement",
  "writings.set-status-title.writing_ready": "Écritures prêtes à valider",
  "writings.set-status-title.ready_for_archive": "Écritures à générer",
  "writings.set-status-title.created": "Génération en cours",
  "writings.set-status-title.generated": "Écritures générées",

  "writings.set-action-title.pending": "Générer les écritures",
  "writings.set-action-title.writing_ready": "Valider les écritures",
  "writings.set-action-title.ready_for_archive": "Générer les écritures",
  "writings.set-action-title.created": "Télécharger archive n° {archiveId}",
  "writings.set-action-title.generated": "Télécharger archive n° {archiveId}",

  "writing-lines.processed.table.date": "Date",
  "writing-lines.processed.table.due-date": "Échéance",
  "writing-lines.processed.table.journal-code": "Journal",
  "writing-lines.processed.table.account": "Compte",
  "writing-lines.processed.table.client-supplier": "Client / Fournisseur",
  "writing-lines.processed.table.drop-file-name": "Dépôt",
  "writing-lines.processed.table.debit": "Débit",
  "writing-lines.processed.table.credit": "Crédit",
  "writing-lines.processed.table.reference": "Référence",
  "writing-lines.processed.table.merchant-value":
    "{merchantName} <b>({merchantCode})</b>",
  "writing-lines.processed.table.empty": "Aucune facture traitée dans ce batch",

  "writing-lines.deactivated.table.name": "Nom du Dépot",
  "writing-lines.deactivated.table.document-type": "Type",
  "writing-lines.deactivated.table.qualification": "Qualification",
  "writing-lines.deactivated.table.date": "Date",
  "writing-lines.deactivated.table.input": "Saisir",
  "writing-lines.deactivated.table.empty":
    "Aucune facture dans le dossier de contrôle",
  "writing-lines.full-documents.empty":
    "Il n'y a pas de document traité, n'oubliez pas de regarder votre dossier de contrôle",
  "writing-lines.deactivated.table.change-deactivated.success.title":
    "Sauvegarde réussie",
  "writing-lines.deactivated.table.change-deactivated.success.body":
    "La raison de désactivation de votre document a bien été mise à jour",
  "writing-lines.deactivated.table.change-deactivated.failure.title":
    "Sauvegarde impossible",
  "writing-lines.deactivated.table.change-deactivated.failure.body":
    "La raison de désactivation de votre document n'a pas pu être mise à jour",
  "writing-lines.deactivated.table.change-deactivated.type.unsupported":
    "Autres documents",
  "writing-lines.deactivated.table.change-deactivated.type.bank":
    "Documents bancaires",
  "writing-lines.deactivated.table.change-deactivated.type.fiscal":
    "Fiscal & social",
  "writing-lines.deactivated.table.change-deactivated.type.social":
    "Fiscal & social",
  "writing-lines.deactivated.table.change-deactivated.type.permanent":
    "Dossier permanent",
  "writing-lines.deactivated.table.change-deactivated.type.Doublon":
    "Autres documents",
  "writing-lines.deactivated.table.change-deactivated.type.default": "  ",

  "accounting-plan.table-header.code": "Code",
  "accounting-plan.table-header.clients": "Client / Fournisseur",
  "accounting-plan.table-header.buy": "Fournisseurs",
  "accounting-plan.table-header.sell": "Clients",
  "accounting-plan.table-header.centralize": "Regroupement",
  "accounting-plan.table-header.historical": "Historique",
  "accounting-plan.table-content.empty": "Aucun client",
  "accounting-plan.table-content.empty-search":
    "Aucun client trouvé avec cette recherche",
  "accounting-plan.merchant-code.error.decentralize-account.title":
    "Impossible de modifier le regroupement",
  "accounting-plan.merchant-code.error.decentralize-account.message":
    "Ce code de regroupement est déjà associé à un autre client ou fournisseur.",
  "accounting-plan.table-content.see-more": "Voir<nbsp></nbsp>{moreOrLess}",
  "accounting-plan.select-all-option": "Sélectionner tout",
  "accounting-plan.default_buy_or_sell_code":
    "Code unique par défaut pour les {buyOrSell}",
  "accounting-plan.switch-no-centralized-code":
    "Dossier sans code de regroupement",
  "accounting-plan-menu.tooltip.onboarding-unfinished":
    "Votre dossier n'est pas encore paramétré",

  "accounting-plan.open-create-client": "Créer un code tiers",
  "accounting-plan.rename-code.title": "Modifier le code",
  "accounting-plan.rename-code.error-already-exist": "Ce code existe déjà",
  "accounting-plan.history.code": "Code",
  "accounting-plan.history.code_modification":
    "<b>Modification</b> du code par ",
  "accounting-plan.history.code_creation": "<b>Création</b> du code par ",
  "accounting-plan.history.centralize_status":
    "<b>{centralize}</b> du code par ",
  "accounting-plan.history.previously": "Anciennement : {value}",
  "accounting-plan.history.default_buy_code":
    "<b>{addOrRemove}</b> du code par défaut en <b>achats</b> par <nbsp></nbsp>",
  "accounting-plan.history.default_sell_code":
    "<b>{addOrRemove}</b> du code par défaut en <b>ventes</b> par <nbsp></nbsp>",
  "accounting-plan.tooltip.default-no-decentralize":
    "Ce code est un code par défaut pour les {buyOrSell}, vous ne pouvez pas le dégrouper",
  "accounting-plan.tooltip.disabled-centralize-code":
    "Votre dossier est paramétré sans code de regroupement, vous ne pouvez donc pas regrouper ce code",
  "accounting-plan.tooltip.switch-disabled-centralized-code-tooltip":
    "Votre dossier contient encore des codes de regroupement, vous devez les dégrouper avant d'effectuer cette manipulation",
  "accounting-plan.tooltip.disabled-centralized-codes.error-title":
    "Impossible de sauvgarder",
  "accounting-plan.tooltip.disabled-centralized-codes.error-body":
    "Votre dossier contient encore des codes de regroupement, vous devez les dégrouper avant d'effectuer cette manipulation",

  "accounting-plan.decantralize-code-creation.error-already-exist":
    "Ce code existe déjà",
  "accounting-plan.decantralize-code.title": "Dégrouper le code {code}",
  "accounting-plan.decantralize-code.text":
    "Ce <boldColored>code tiers</boldColored> va être dégroupé, choisissez les codes de remplacements pour les différents clients / fournisseurs.",
  "accounting-plan.decantralize-code.clients": "Tiers",
  "accounting-plan.decantralize-code.new-codes": "Code tiers de remplacement",
  "accounting-plan.decantralize-code.success.title":
    "Dégroupement sauvegardé !",
  "accounting-plan.decantralize-code.success.body":
    "Le dégroupement est bien sauvegardé et codes tiers modifiés",

  "accounting-plan.add-merchant.title":
    "Ajouter des clients / fournisseurs pour le code {code}",
  "accounting-plan.add-merchant.list-merchants":
    "Liste des clients / fournisseurs attachés à ce code actuel",
  "accounting-plan.add-merchant.drop-down-label":
    "Ajouter des clients / fournisseurs",
  "accounting-plan.add-merchant.success.title": "Modifications sauvegardées !",
  "accounting-plan.add-merchant.success.body":
    "Les modifications sur le code {code} ont bien été sauvegardées.",
  "accounting-plan.add-merchant.not-centralize-error":
    "Ce code tiers n'est pas un code de regroupement, un seul client / fournisseur peut lui être attaché",
  "accounting-plan.add-merchant.exit-message-confirm":
    "Vous êtes en train de modifier le code {code}.\nSi vous quittez maintenant, les modifications ne seront pas sauvegardées",

  "accounting-plan.accounts.filter-prefix": "Filtrer par préfixes uniquement",
  "accounting-plan.accounts.create": "Créer un nouveau compte",
  "accounting-plan.accounts.table-header.number": "Numéro de compte",
  "accounting-plan.accounts.table-header.details": "Détails",
  "accounting-plan.accounts.table-header.activated": "Activé/Désactivé",
  "accounting-plan.accounts.table-header.actions": "Actions",
  "accounting-plan.accounts.table-body.empty":
    "Aucun compte disponible pour le moment",
  "accounting-plan.accounts.buy-third-party-account":
    "Compte d'achats par défaut",
  "accounting-plan.accounts.sell-third-party-account":
    "Compte de vente par défaut",
  "accounting-plan.accounts.third-party-account-buy":
    "Ce compte est un <boldColored> compte par défaut</boldColored> pour <boldColored>tous les ACHATS</boldColored> ",
  "accounting-plan.accounts.third-party-account-sell":
    "Ce compte est un <boldColored> compte par défaut</boldColored> pour <boldColored>toutes les VENTES</boldColored> ",

  "accounting-plan.accounts.reactivate.success.title": "Compte réactivé !",
  "accounting-plan.accounts.reactivate.success.body":
    "Le compte a bien été réactivé.",
  "accounting-plan.accounts.reactivate.error.title":
    "Erreur lors de la réactivation",
  "accounting-plan.accounts.deactivate.success.title": "Compte désactivé !",
  "accounting-plan.accounts.deactivate.success.body":
    "Le compte a bien été désactivé.",
  "accounting-plan.accounts.deactivate.error.title":
    "Erreur lors de la désactivation",

  "accounting-plan.accounts.deactivate.error.third-account.company":
    "Ce compte {accountNumber} est utilisé par défaut comme compte de Tiers sur votre dossier et vous ne pouvez pas le désactiver.",
  "accounting-plan.accounts.deactivate.error.merchant.default.buy":
    "Ce compte {accountNumber} est utilisé comme compte par défaut sur le fournisseur {merchantName}. Veuillez le détacher avant de désactiver le compte.",
  "accounting-plan.accounts.deactivate.error.merchant.default.sell":
    "Ce compte {accountNumber} est utilisé comme compte par défaut sur le client {merchantName}. Veuillez le détacher avant de désactiver le compte.",
  "accounting-plan.accounts.deactivate.error.merchant.vat.buy":
    "Ce compte {accountNumber} est utilisé comme compte de TVA sur le fournisseur {merchantName}. Veuillez le détacher avant de désactiver le compte.",
  "accounting-plan.accounts.deactivate.error.merchant.vat.sell":
    "Ce compte {accountNumber} est utilisé comme compte de TVA sur le client {merchantName}. Veuillez le détacher avant de désactiver le compte.",
  "accounting-plan.accounts.deactivate.error.pnl-account":
    "Impossible de désactiver le compte, il reste un ou plusieurs compte PNL.",
  "accounting-plan.accounts.deactivate.error.vat-account":
    "Ce compte {accountNumber} est utilisé pour la TVA de votre dossier, veuillez changez d'abord vos paramètres de TVA sur votre société.",
  "accounting-plan.accounts.deactivate.error.company.eu-vat":
    "Ce compte {accountNumber} est utilisé comme compte de TVA autoliquidée déductible intraco.",
  "accounting-plan.accounts.deactivate.error.company.eu-vat-reverse":
    "Ce compte {accountNumber} est utilisé comme compte de TVA autoliquidée collectée intraco.",
  "accounting-plan.accounts.deactivate.error.company.construction":
    "Ce compte {accountNumber} est utilisé comme compte de TVA autoliquidée déductible BTP.",
  "accounting-plan.accounts.deactivate.error.company.construction-reverse":
    "Ce compte {accountNumber} est utilisé comme compte de TVA autoliquidée collectée BTP.",
  "accounting-plan.accounts.deactivate.error.company.lcr":
    "Ce compte {accountNumber} est utilisé comme compte de LCR.",
  "accounting-plan.accounts.deactivate.error.company.goods-transfer":
    "Ce compte {accountNumber} est utilisé comme compte de transferts de marchandises",
  "accounting-plan.accounts.deactivate.error.change-rule":
    "Vous ne pouvez pas changer le compte {oldNumber} en {accountNumber} car ces deux numéros sont trop différents",
  "accounting-plan.accounts.deactivate.error.one-deactivator":
    "Impossible de désactiver le compte, il a déjà été désactivé par un administrateur.",
  "accounting-plan.accounts.error.no-account-found":
    "Erreur lors de la récupération du compte.",

  "accounting-plan.accounts.modal.title.create": "Ajouter un nouveau compte",
  "accounting-plan.accounts.modal.title.modify": "Modifier le compte",
  "accounting-plan.accounts.modal.input.number": "Compte",
  "accounting-plan.accounts.modal.input.number.error":
    "Ce numéro de compte existe déjà",
  "accounting-plan.accounts.modal.input.details": "Description",
  "accounting-plan.accounts.modal.switch.is_activated": "Ce compte est activé",
  "accounting-plan.accounts.modal.switch.is_deactivated":
    "Ce compte est désactivé",
  "accounting-plan.accounts.modal.button.back": "Retour",
  "accounting-plan.accounts.modal.button.save": "Sauvegarder",

  "accounting-plan.accounts.modal.create.success.title": "Compte créé !",
  "accounting-plan.accounts.modal.create.success.body":
    "Le compte a bien été créé.",
  "accounting-plan.accounts.modal.create.error.title":
    "Erreur lors de la création",
  "accounting-plan.accounts.modal.create.error.body":
    "Une erreur est survenue lors de la création du compte.",
  "accounting-plan.accounts.modal.create.error.body.unique-number":
    "Ce numéro de compte existe déjà sur votre dossier.",
  "accounting-plan.accounts.modal.create.error.body.number.empty":
    "Vous devez renseigner un numéro d'identification pour créer ce compte.",
  "accounting-plan.accounts.modal.create.error.body.details.empty":
    "Vous devez renseigner les détails pour créer ce compte.",
  "accounting-plan.accounts.modal.modify.success.title": "Compte modifié !",
  "accounting-plan.accounts.modal.modify.success.body":
    "Le compte a bien été modifé.",
  "accounting-plan.accounts.modal.modify.error.title":
    "Erreur lors de la modification",
  "accounting-plan.accounts.modal.modify.error.body":
    "Une erreur est survenue lors de la modification du compte.",

  "accounting-plan.accounts.modal.history.title":
    "Détails des dernières actions",
  "accounting-plan.accounts.modal.history..title.account.number":
    "Compte n°{number}",
  "accounting-plan.accounts.modal.history.line":
    "<boldBlack>{instructionType}</boldBlack> par <underlineColored>{user}</underlineColored> le {date1} à {date2}",
  "accounting-plan.accounts.modal.history.formatted-modifications.number":
    "<boldBlack> - ancien numéro:</boldBlack> <bolderColored>{oldAccountNumber}</bolderColored>,<boldBlack> nouveau numéro:</boldBlack> <bolderColored>{newAccountNumber}</bolderColored>",
  "accounting-plan.accounts.modal.history.formatted-modifications.details":
    "<boldBlack> - anciens détails:</boldBlack> <bolderColored>{oldAccountDetails}</bolderColored>,<boldBlack> nouveaux détails:</boldBlack> <bolderColored>{newAccountDetails}</bolderColored>",
  "accounting-plan.accounts.modal.history.no-content":
    "Aucune action pour ce compte",

  "accounting-plan.create-code.title": "Créer un nouveau code tiers",
  "accounting-plan.create-code.buy.title": "Créer un nouveau code fournisseur",
  "accounting-plan.create-code.sell.title": "Créer un nouveau code client",
  "accounting-plan.create-code.part-one.title":
    "1 - Créer un nouveau code tiers",
  "accounting-plan.create-code.part-one.code-label": "Code tiers",
  "accounting-plan.create-code.part-one.output-label":
    "Sortie comptable {buyOrSell}",
  "accounting-plan.create-code.part-one.switch-text":
    "En cochant cette case, ce <boldColored>code tiers</boldColored> deviendra un <boldColored>code de regroupement</boldColored>, permettant le regroupement de plusieurs clients / fournisseurs.",
  "accounting-plan.create-code.part-one.warning-centralize-one.buy":
    "Ce code fournisseur de regroupement existe déjà.",
  "accounting-plan.create-code.part-one.warning-centralize-one.sell":
    "Ce code client de regroupement existe déjà.",
  "accounting-plan.create-code.part-one.warning-existing-code":
    "Ce code existe déjà, vous pouvez le choisir directement dans la liste.",
  "accounting-plan.create-code.part-one.warning-one.buy":
    "Ce code existe déjà, le fournisseur affecté est {merchantName}.",
  "accounting-plan.create-code.part-one.warning-one.sell":
    "Ce code existe déjà, le client affecté est {merchantName}.",
  "accounting-plan.create-code.part-one.warning-two":
    "Vous pouvez le passer en code regroupement, en cliquant ci-dessous.",
  "accounting-plan.create-code.part-one.warning-change-to_centralize-two":
    "Ce code passera en code de regroupement à la sauvegarde.",
  "accounting-plan.create-code.part-two.title":
    "2 - Ajouter un client / fournisseur",
  "accounting-plan.create-code.part-two.unique-code":
    "Faire de ce code le code unique pour toutes les ventes",
  "accounting-plan.create-code.exit-message-confirm":
    "Vous êtes en train de créer un code tiers.\nSi vous quittez maintenant, la création ne sera pas sauvegardée",
  "accounting-plan.create-code.buy.exit-message-confirm":
    "Vous êtes en train de créer un code fournisseur.\nSi vous quittez maintenant, la création ne sera pas sauvegardée",
  "accounting-plan.create-code.sell.exit-message-confirm":
    "Vous êtes en train de créer un code client.\nSi vous quittez maintenant, la création ne sera pas sauvegardée",
  "accounting-plan.create-code.error.too-long.title": "Code trop long !",
  "accounting-plan.create-code.error.too-long.message":
    "Le code est trop long!",
  "accounting-plan.create-code.error.already-taken.title": "Code déjà utilisé",
  "accounting-plan.create-code.error.already-taken.message":
    "Ce code est déjà utilisé! ",
  "accounting-plan.create-code.error.forbidden-characters.title":
    "Caractère interdit",
  "accounting-plan.create-code.error.forbidden-characters.message":
    "Le code ne doit être composé que de caratères alphanumériques !",
  "accounting-plan.create-code.onboarding-unifinished.error.title":
    "Impossible de créer le code",
  "accounting-plan.create-code.onboarding-unifinished.error.message":
    "Merci d'attendre que votre premier dépôt soit traité",
  "accounting-plan.create-code.success.title":
    "Le code {code} a bien été créé !",
  "accounting-plan.create-code.success.message":
    "Le code {code} a bien été créé et les modifications sauvegardées !",
  "accounting-plan.create-code.uniq-code":
    "Faire de ce code le <b>code unique</b> pour {type} les",
  "accounting-plan.create-code.uniq-code.buy": "achats",
  "accounting-plan.create-code.uniq-code.sell": "ventes",
  "accounting-plan.create-code.uniq-code.tooltip":
    "Le code doit être un code de regroupement pour le mettre par défaut",
  "accounting-plan.create-code.uniq-code.already-has-for-company":
    "Le code <b>{code}</b> a déjà été sélectionné par défaut en ",
  "accounting-plan.create-code.uniq-code.this-code-selected":
    "Ce code est sélectionné par défaut en ",
  "accounting-plan.create-code.uniq-code.error.title":
    "Impossible de mettre le code par défaut",
  "accounting-plan.create-code.uniq-code.error.message":
    "Un autre code a déjà été sélectionné par défaut en achats/ventes",
  "accounting-plan.create-code.uniq-code-decentralize.error.title":
    "Impossible de dégrouper le code",
  "accounting-plan.create-code.uniq-code-decentralize.error.message":
    "Ce code est un code par défaut pour les achats/ventes, vous ne pouvez pas le dégrouper",
  "accounting-plan.create-code.uniq-code-not-centralize.error.title":
    "Impossible de mettre par défaut",
  "accounting-plan.create-code.uniq-code-not-centralize.error.message":
    "Ce code n'est pas un code de regroupement donc ne peut pas être mis en code par défaut",
  "accounting-plan.create-code.centralize.disabled-centralized-codes.error.title":
    "Impossible de mettre en code de regroupement",
  "accounting-plan.create-code.centralize.disabled-centralized-codes.error.message":
    "Votre dossier est paramétré sans code de regroupement, vous ne pouvez donc pas regrouper ce code",
  "accounting-plan.create-code.warning-all-selected":
    "Attention: vous avez sélectionné tous les tiers.",

  "accounting-plan.goods-transfer.title":
    "Compte de <b>transferts de marchandises</b>:",
  "accounting-plan.goods-transfer.create-by-vat": "Créer par taux",
  "accounting-plan.goods-transfer.modal-history.title":
    "Dernières Modifications",
  "accounting-plan.goods-transfer.modal-history.previously":
    "Ancien compte : {value}",
  "accounting-plan.goods-transfer.modal-history.create":
    "<b>Création</b> du compte {value} ",
  "accounting-plan.goods-transfer.modal-history.change":
    "<b>Changement</b> du compte de TVA {value} ",
  "accounting-plan.goods-transfer.modal-history.no-changes":
    "Aucune modification",
  "accounting-plan.goods-transfer.modal-history.ok": "OK",
  "accounting-plan.goods-transfer.modal-create.error-number":
    "Le compte doit commencer par {rules}",
  "accounting-plan.goods-transfer.modal-create.title":
    "Créer un compte {withVat}",
  "accounting-plan.goods-transfer.modal-create.vat": "Taux",
  "accounting-plan.goods-transfer.modal-create-success.title":
    "Nouveau compte créé",
  "accounting-plan.goods-transfer.modal-create-success.message":
    "Le nouveau compte n'a pas été enregistré comme transfert de marchandise",
  "accounting-plan.goods-transfer.destroy-commission.success.title":
    "Compte supprimé",
  "accounting-plan.goods-transfer.destroy-commission.success.message":
    "Le compte a bien été supprimé de vos transfert de marchandises",
  "accounting-plan.goods-transfer.destroy-commission.error.title":
    "Le compte n'a pas pu être supprimé",
  "accounting-plan.goods-transfer.destroy-commission.error.message":
    "Une erreur est survenue",
  "accounting-plan.goods-transfer.modal-destroy-commission.message1":
    "Vous allez supprimer le compte <b>{account}</b>, pour le taux à <b>{rate}%</b>, sur le transfert de merchandise <b>{type}</b> !",
  "accounting-plan.goods-transfer.modal-destroy-commission.message2":
    "Êtes-vous sûr(e) de vouloir le supprimer définitivement ?",
  "accounting-plan.goods-transfer.modal-destroy-commission.cancel": "Annuler",
  "accounting-plan.goods-transfer.modal-destroy-commission.destroy":
    "Supprimer",

  "accounting-plan.lcr.create-rule-button": "Ajouter une règle d'affectation",
  "accounting-plan.lcr.parameter-system":
    "Vous pouvez ici paramétrer <b>votre système de 403</b> :",
  "accounting-plan.lcr.parameter-system.option": "Sélectionner un type de 403",
  "accounting-plan.lcr.parameter-system.option.per-year":
    "Un seul 403 : Création et utilisation d'un unique compte 403",
  "accounting-plan.lcr.parameter-system.option.per-month":
    "Un 403 par mois : Choix mensuel de 12 comptes 403",
  "accounting-plan.lcr.parameter-system.option.label.per-year":
    "Sélectionner un compte 403",
  "accounting-plan.lcr.parameter-system.accounts.save": "Sauvegarder",
  "accounting-plan.lcr.parameter-system.accounts.tooltip.success-title":
    "Comptes sauvegardés",
  "accounting-plan.lcr.parameter-system.accounts.tooltip.success-message":
    "Les comptes ont bien été sauvegardés",
  "accounting-plan.lcr.parameter-system.accounts.tooltip.error-title":
    "Une erreur est survenue",
  "accounting-plan.lcr.parameter-system.accounts.tooltip.error-message":
    "Les comptes n'ont pas pu être sauvegardés",
  "accounting-plan.lcr.parameter-system.code": "Code fournisseur : {code}",
  "accounting-plan.lcr.parameter-system.empty-rules":
    "Vous n'avez pas encore de règles d'affectation.",
  "accounting-plan.lcr.parameter-system.empty-rules2":
    "Cliquez sur ajouter une règle d'affectation pour en créer une.",
  "accounting-plan.lcr.create-or-edit-modal.title.create":
    "Ajouter une règle d'affectation",
  "accounting-plan.lcr.create-or-edit-modal.title.edit":
    "Modifier la règle d'affectation",
  "accounting-plan.lcr.create-or-edit-modal.trigger": "Déclenchement : ",
  "accounting-plan.lcr.create-or-edit-modal.assignment": "Affectation : ",
  "accounting-plan.lcr.create-or-edit-modal.save": "Créer",
  "accounting-plan.lcr.create-or-edit-modal.edit": "Modifier",
  "accounting-plan.lcr.create-or-edit-modal.tooltip.success-title":
    "Règle d'affectation créée",
  "accounting-plan.lcr.create-or-edit-modal.tooltip.success-message":
    "La nouvelle règle d'affectation a bien été créée",
  "accounting-plan.lcr.create-or-edit-modal.tooltip.error-title":
    "Une erreur est survenue",
  "accounting-plan.lcr.create-or-edit-modal.tooltip.error-message":
    "La nouvelle règle n'a pas pu être créée",
  "accounting-plan.lcr.delete.tooltip.success-title": "Suppression effectuée !",
  "accounting-plan.lcr.delete.tooltip.success-message":
    "La règle a bien été supprimée",
  "accounting-plan.lcr.delete.tooltip.error-title": "Une erreur est survenue",
  "accounting-plan.lcr.delete.tooltip.error-message":
    "La règle n'a pas pu être supprimée",
  "accounting-plan.lcr.edit.tooltip.success-title": "Modification effectuée !",
  "accounting-plan.lcr.edit.tooltip.success-message":
    "La règle a bien été modifiée",
  "accounting-plan.lcr.edit.tooltip.error-title": "Une erreur est survenue",
  "accounting-plan.lcr.edit.tooltip.error-message":
    "La règle n'a pas pu être modifiée",

  "accounting-plan.journals.title":
    "Vous pouvez ici paramétrer <b>vos journaux</b> :",
  "accounting-plan.journals.buy.title": "<b>Journal d'achats </b>",
  "accounting-plan.journals.sell.title": "<b>Journal de ventes </b>",
  "accounting-plan.journals.misc.title": "<b>Journal d'OD </b>",
  "accounting-plan.journals.create-journal.button": "Créer un journal",
  "accounting-plan.journals.modal-create.title.buy": "Créer un journal d'achat",
  "accounting-plan.journals.modal-create.title.sell":
    "Créer un journal de vente",
  "accounting-plan.journals.modal-create.title.misc": "Créer un journal d'od",
  "accounting-plan.journals.modal-create.journal-code.placeholder":
    "Code journal",
  "accounting-plan.journals.modal-create.details.placeholder": "Libellé",
  "accounting-plan.journals.create.success.title": "Création réussie",
  "accounting-plan.journals.create.success.body":
    "Le journal <b>{journalCode} - {details}</b> a bien été crée",
  "accounting-plan.journals.set_account.success.title": "Changement réussi",
  "accounting-plan.journals.set_account.success.body":
    "Le changement de journal a été effectué",
  "accounting-plan.journals.set_account.failure.title":
    "Echec lors du changement",
  "accounting-plan.journals.set_account.failure.body":
    "Le changement de journal a échoué, veuillez contacter l'équipe Chaintrust",
  "accounting-plan.journals.invalid-journal-length":
    "Le code journal est de maximum {char} charactères",
  "accounting-plan.journals.invalid-journal-format":
    "Le code ne peux contenir que des lettres ou chiffres",

  "fiduciary.settings.inqom.connect.button": "Connecter l'API Inqom",
  "fiduciary.settings.inqom.connect.button.connected": "Connecté",
  "fiduciary.settings.inqom.connect.success.title": "Authentification Réussie",
  "fiduciary.settings.inqom.connect.success.body":
    "Votre cabinet est désormais connecté à Inqom !",
  "fiduciary.settings.inqom.connect.failure.title":
    "Echec lors de l'Authentification",
  "fiduciary.settings.inqom.connect.failure.body":
    "Echec lors de la connexion à Inqom, veuillez contacter Chaintrust",
  "fiduciary.settings.acd-connect": "Se connecter",
  "fiduciary.settings.acd-disconnect": "Se déconnecter",
  "fiduciary.settings.acd-connected": "Connecté",
  "fiduciary.settings.acd-not-connected": "Non connecté",
  "fiduciary.settings.acd-modal.connect-with":
    "Connexion avec {fiduciarySoftware}",
  "fiduciary.settings.acd-modal.identifier": "Identifiant",
  "fiduciary.settings.acd-modal.password": "Mot de passe",
  "fiduciary.settings.acd-modal.cnx": "CNX",
  "fiduciary.settings.acd-modal.apiUrl": "URL",
  "fiduciary-settings.acd-sent-data-handle.body":
    "Gestion de la date d'échéance et numéro de facture ACD pour tous les dossiers :",
  "fiduciary-settings.send-due-date-ACD":
    "Envoyer la date d’écheance dans l’API ACD",
  "fiduciary-settings.send-document-reference-ACD":
    "Envoyer le numéro de facture dans l’API ACD",
  "fiduciary-settings.toaster.success.sign.in.acd.title": "Connexion réussie",
  "fiduciary-settings.toaster.success.sign.in.acd.body":
    "Vous êtes maintenant connectés à l'API",
  "fiduciary-settings.toaster.error.sign.in.acd.title":
    "Erreur lors de la connexion à l'API",
  "fiduciary-settings.toaster.error.sign.in.acd.body":
    "Veuillez vérifiez vos identifiants et l'URL",
  "fiduciary-settings.change-software.title":
    "Changer de logiciel de comptabilité par défaut",
  "fiduciary-settings.change-software.confirm":
    "Changer de logiciel de comptabilité",
  "fiduciary-settings.change-software.tooltip.success-title":
    "Changement de logiciel effectué!",
  "fiduciary-settings.change-software.tooltip.success-message":
    "Le changement de logiciel s'est correctement effectué",
  "fiduciary-settings.change-software.tooltip.error-title":
    "Une erreur est survenue",
  "fiduciary-settings.change-software.tooltip.error-message":
    "Le changement de logiciel n'a pas pu s'effectuer",

  "company.accounting_type.title": "Créer un dossier",
  "company.accounting_type.accrual_accounting": "Comptabilité d'engagement",
  "company.accounting_type.accrual_accounting.subtitle":
    "Utiliser Chaintrust version complète",
  "company.accounting_type.cash_accounting": "Comptabilité de trésorerie",
  "company.accounting_type.cash_accounting.subtitle":
    "Importer vos flux bancaires",
  "menu.treasury": "Paiement",
  "menu.treasury.buy": "Achats",
  "menu.treasury.sell": "Ventes",
  "menu.treasury.settings": "Paramètres de paiement",
  "menu.treasury.buy.to_control": "À contrôler",
  "menu.treasury.buy.to_pay": "À payer",
  "menu.treasury.buy.paid": "Payés",
  "menu.treasury.buy.rejected": "Rejetés",
  "menu.treasury.sell.to_receive": "À recevoir",
  "menu.treasury.sell.received": "Paiements reçus",
  "menu.treasury.sell.irrecoverable": "Irrécouvrable",
  "menu.treasury.settings.buy": "Achats",
  "menu.treasury.settings.sell": "Ventes",
  "menu.treasury.settings.auto_validation": "Validation automatique",
  "treasury.buy-settings.invit_user": "Inviter un collaborateur",
  "treasury.buy-settings.users": "Utilisateurs",
  "treasury.buy-settings.payment_validation": "Validation paiement",
  "treasury.buy-settings.pay": "Payer",
  "treasury.buy-settings.mark_as_paid": "Marquer comme payé",
  "treasury.buy-settings.reject_payment": "Rejeter un paiement",
  "treasury.buy-settings.historic": "Historique",
  "treasury.buy-settings.footer.all": "Tous les utilisateurs :",
  "treasury.buy-settings.empty.users":
    "Aucun utilisateur ne correspond à ces critères",

  "treasury.sell-settings.invit_user": "Inviter un collaborateur",
  "treasury.sell-settings.users": "Utilisateurs",
  "treasury.sell-settings.can_mark_as_payment_received":
    "Marquer comme paiement reçu",
  "treasury.sell-settings.can_mark_as_irrecoverable":
    "Marquer comme irrécouvrable",
  "treasury.sell-settings.historic": "Historique",
  "treasury.sell-settings.footer.all": "Tous les utilisateurs :",
  "treasury.sell-settings.empty.users":
    "Aucun utilisateur ne correspond à ces critères",

  "treasury.auto-validation-settings.users": "Utilisateurs",
  "treasury.auto-validation-settings.merchant": "Fournisseurs",
  "treasury.auto-validation-settings.validate_directly": "Validé directement",
  "treasury.auto-validation-settings.mark_as_paid_directly": "Payé directement",
  "treasury.auto-validation-settings.historic": "Historique",

  "treasury.buy.to-control.due-date": "Date d'échéance",
  "treasury.buy.to-control.date": "Date",
  "treasury.buy.to-control.merchant": "Fournisseurs",
  "treasury.buy.to-control.file-name": "Nom du fichier",
  "treasury.buy.to-control.amount": "Montant",
  "treasury.buy.to-control.action": "Actions",
  "treasury.buy.to-control.mark_as_paid": "Marquer comme payé",
  "treasury.buy.to-control.ask_validation": "Demander une validation",
  "treasury.buy.to-control.reject": "Rejeter",
  "treasury.buy.to-control.validate": "Valider",
  "treasury.buy.to-control.validate_and_pay": "Valider et payer",
  "treasury.buy.to-control.historic": "Historique",
  "treasury.buy.to-control.total_amount": "Montant total:",

  "treasury.buy.to-control.ask_user_validation.modal":
    "Auprès de qui voulez-vous envoyer une validation de paiement ?",
  "treasury.buy.to-control.ask-validation.button.return": "Retour",
  "treasury.buy.to-control.ask-validation.button.validate":
    "Demander une validation",
  "treasury.buy.to-control.ask-validation.select.user":
    "Choisir un utilisateur",
  "treasury.buy.to-control.ask-validation.header.success": "Demande envoyée",
  "treasury.buy.to-control.ask-validation.body.success":
    "Votre demande de validation a bien été envoyée !",
  "treasury.buy.to-control.ask-validation.header.failure":
    "Demande de validation non envoyée",
  "treasury.buy.to-control.ask-validation.body.failure.full_document_ids_empty":
    "Aucun document selectionné",
  "treasury.buy.to-control.ask-validation.body.failure.current_user_has_no_permission_on_company":
    "Vous n'avez pas le droit d'effectué cette opération",
  "treasury.buy.to-control.ask-validation.body.failure.requested_user_has_no_permission_on_company":
    "L'utilisateur sélectionné n'a pas de droit sur ce dossier",
  "treasury.buy.to-control.ask-validation.body.failure.full_document_must_be_buy_or_sell":
    "Problème avec l'un de vos documents, veuillez contacter Chaintrust",
  "treasury.buy.to-control.ask-validation.body.failure.full_document_is_not_in_archive":
    "Problème avec l'un de vos documents, veuillez contacter Chaintrust",
  "treasury.buy.to-control.filter.empty":
    "Aucun document ne correspond à ces critères",

  "treasury.buy.update_full_document_payment_status.body.failure.full_document_ids_empty":
    "Aucun document selectioné",
  "treasury.buy.update_full_document_payment_status.body.failure.unauthorized_operation":
    "Opération non authorisée",
  "treasury.buy.update_full_document_payment_status.body.failure.unsupported_document":
    "Document non supporté, veuillez contacter Chaintrust",
  "treasury.buy.update_full_document_payment_status.body.failure.operation_not_exist":
    "L'opération demandée n'existe pas",
  "treasury.buy.update_full_document_payment_status.body.failure.no_validation_payment":
    "Opération impossible, aucun droit de validation accordé",
  "treasury.buy.update_full_document_payment_status.body.failure.amount_above_limit":
    "Montant total au dessus de la limite de la validation autorisée",
  "treasury.buy.update_full_document_payment_status.body.failure.full_document_not_in_archive":
    "Erreur ce document devrait être dans une archive",
  "treasury.buy.update_full_document_payment_status.body.failure.not_able_for_this_operation":
    "Vous n'êtes pas autorisé à effectuer cette opération",
  "treasury.update_full_document_payment_status.success.title":
    "Modification réussie",
  "treasury.update_full_document_payment_status.success.body":
    "Le traitement de votre document a été effectué avec succès",

  "treasury.grant_or_revoke_access.header.failure":
    "Échec lors du changement de droits",
  "treasury.grant_or_revoke_access.body.failure.no_authorization_on_this_company":
    "Vous n'avez pas de droit sur ce dossier",
  "treasury.grant_or_revoke_access.body.failure.empty_permissions_ids":
    "Aucun utilisateur selectionné",
  "treasury.grant_or_revoke_access.body.failure.unknown_type":
    "Opération inexistante",
  "treasury.grant_or_revoke_access.body.failure.unkown_action":
    "L'opération demandée n'existe pas",
  "treasury.grant_or_revoke_access.body.failure.no_validation_payment":
    "Opération impossible, aucun droit de validation accordé",
  "treasury.grant_or_revoke_access.body.failure.no_authorization_for_current_user":
    "Vous n'avez pas d'autorisation sur ce dossier",
  "treasury.grant_or_revoke_access.body.failure.no_authorization_to_upgrade_access":
    "Vous n'êtes pas autorisé à effectué ce changement",
  "treasury.grant_or_revoke_access.body.failure.no_authorization_to_change_customers":
    "Impossible de modifier un client",
  "treasury.grant_or_revoke_access.body.failure.no_authorization_to_downgrade_access":
    "Vous n'êtes pas autorisé à retirer un droit",
  "treasury.grant_or_revoke_access.header.success": "Modification réussie",
  "treasury.grant_or_revoke_access.body.success":
    "L'utilsateur a bien été modifié, les droits ont correctement été attribués/retirés",

  "treasury.change_validation_and_payment_limits_for_user.header.failure":
    "Échec lors du changement de droits",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.empty_permissions_ids":
    "Aucun utilisateur selectionné",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.unknown_limit":
    "Limite inexistante",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.unkown_action":
    "L'opération demandée n'existe pas",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.no_authorization_to_change_limit":
    "Opération impossible, aucun droit de changement accordé",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.no_authorization_for_current_user":
    "Vous n'avez pas d'autorisation sur ce dossier",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.given_user_have_all_access":
    "L'utilisateur a déjà tout les droits",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.no_authorization_to_upgrade_limit":
    "Vous n'êtes pas autorisé a changer la limite",
  "treasury.change_validation_and_payment_limits_for_user.body.failure.no_authorization_to_downgrade_limit":
    "Vous n'êtes pas autorisé à baisser la limite",
  "treasury.change_validation_and_payment_limits_for_user.header.success":
    "Modification réussie",
  "treasury.change_validation_and_payment_limits_for_user.body.success":
    "Les limites de l'utilisateur ont correctement été modifiée",

  "treasury.change_merchant_treasury_settings.header.failure":
    "Échec lors de la mise à jour",
  "treasury.change_merchant_treasury_settings.body.failure.unknown_type":
    "Opération non existante",
  "treasury.change_merchant_treasury_settings.body.failure.no_authorization_on_this_company":
    "Vous n'avez pas d'autorisation sur ce dossier",
  "treasury.change_merchant_treasury_settings.body.failure.no_authorization_for_this_operation":
    "Vous n'avez pas l'autorisation d'effectuer cette opération",
  "treasury.change_merchant_treasury_settings.body.failure.unkown_action":
    "Opération demandée non existante",
  "treasury.change_merchant_treasury_settings.header.success":
    "Modification réussie",
  "treasury.change_merchant_treasury_settings.body.success":
    "Le fournisseur a correctement été modifiée",

  "treasury.buy.to-pay.mark_as_to_control": "Marquer comme à contrôler",
  "treasury.buy.to-pay.due-date": "Date d'échéance",
  "treasury.buy.to-pay.date": "Date",
  "treasury.buy.to-pay.merchant": "Fournisseurs",
  "treasury.buy.to-pay.amount": "Montant",
  "treasury.buy.to-pay.action": "Actions",
  "treasury.buy.to-pay.mark_as_paid": "Marquer comme payé",
  "treasury.buy.to-pay.reference": "Numéro",
  "treasury.buy.to-pay.total_amount": "Montant total:",
  "treasury.buy.to-pay.filter.empty":
    "Aucun document ne correspond à ces critères",
  "treasury.buy.to-pay.filter.mark_as_paid": "Marquer comme payé",
  "treasury.buy.to-pay.file_name": "Nom du fichier",
  "treasury.buy.to-pay.pay": "Payer",

  "treasury.buy.paid.button.mark_as_to_control": "Marquer comme à contrôler",
  "treasury.buy.paid.due-date": "Date d'échéance",
  "treasury.buy.paid.date": "Date",
  "treasury.buy.paid.payment-date": "Date de paiement",
  "treasury.buy.paid.merchant": "Fournisseurs",
  "treasury.buy.paid.amount": "Montant",
  "treasury.buy.paid.action": "Actions",
  "treasury.buy.paid.reference": "Numéro",
  "treasury.buy.paid.file_name": "Nom du fichier",
  "treasury.buy.paid.total_amount": "Montant total:",
  "treasury.buy.paid.filter.empty":
    "Aucun document ne correspond à ces critères",

  "treasury.buy.rejected.due-date": "Date d'échéance",
  "treasury.buy.rejected.date": "Date",
  "treasury.buy.rejected.payment-date": "Date de paiement",
  "treasury.buy.rejected.merchant": "Fournisseurs",
  "treasury.buy.rejected.amount": "Montant",
  "treasury.buy.rejected.action": "Actions",
  "treasury.buy.rejected.reference": "Numéro",
  "treasury.buy.rejected.file_name": "Nom du fichier",
  "treasury.buy.rejected.total_amount": "Montant total:",
  "treasury.buy.rejected.filter.empty":
    "Aucun document ne correspond à ces critères",
  "treasury.buy.rejected.button.mark_as_to_control":
    "Marquer comme à contrôler",

  "treasury.sell.to-receive.mark_as_irrecoverable":
    "Marquer comme irrécouvrable",
  "treasury.sell.to-receive.mark_as_received": "Marquer comme paiement reçu",
  "treasury.sell.to-receive.due-date": "Date d'échéance",
  "treasury.sell.to-receive.date": "Date",
  "treasury.sell.to-receive.merchant": "Clients",
  "treasury.sell.to-receive.document_reference": "Numéro",
  "treasury.sell.to-receive.file_name": "Nom du fichier",
  "treasury.sell.to-receive.amount": "Montant",
  "treasury.sell.to-receive.action": "Actions",
  "treasury.sell.to-receive.reject": "Rejeter",
  "treasury.sell.to-receive.historic": "Historique",
  "treasury.sell.to-receive.total_amount": "Montant total:",
  "treasury.sell.to-receive.filter.empty":
    "Aucun document ne correspond à ces critères",

  "treasury.sell.received.mark_as_to_receive": "Marquer comme à recevoir",
  "treasury.sell.received.due-date": "Date d'échéance",
  "treasury.sell.received.date": "Date",
  "treasury.sell.received.merchant": "Clients",
  "treasury.sell.received.document_reference": "Numéro",
  "treasury.sell.received.file_name": "Nom du fichier",
  "treasury.sell.received.amount": "Montant",
  "treasury.sell.received.action": "Actions",
  "treasury.sell.received.reject": "Rejeter",
  "treasury.sell.received.historic": "Historique",
  "treasury.sell.received.total_amount": "Montant total:",
  "treasury.sell.received.filter.empty":
    "Aucun document ne correspond à ces critères",

  "treasury.sell.irrecoverable.mark_as_to_receive": "Marquer comme à recevoir",
  "treasury.sell.irrecoverable.due-date": "Date d'échéance",
  "treasury.sell.irrecoverable.date": "Date",
  "treasury.sell.irrecoverable.merchant": "Clients",
  "treasury.sell.irrecoverable.document_reference": "Numéro",
  "treasury.sell.irrecoverable.file_name": "Nom du fichier",
  "treasury.sell.irrecoverable.amount": "Montant",
  "treasury.sell.irrecoverable.action": "Actions",
  "treasury.sell.irrecoverable.reject": "Rejeter",
  "treasury.sell.irrecoverable.historic": "Historique",
  "treasury.sell.irrecoverable.total_amount": "Montant total:",
  "treasury.sell.irrecoverable.filter.empty":
    "Aucun document ne correspond à ces critères",

  "treasury.dashboard-invit-user.button.title": "Inviter",
  "treasury.dashboard-invit-user.modal.title": "Inviter un collaborateur",
  "treasury.dashboard-invit-user.input.placeholder":
    "Entrer l'email du collaborateur",
  "treasury.dashboard-invit-user.modal.success.title": "Invitation réussie",
  "treasury.dashboard-invit-user.modal.success.body":
    "L'utilisateur a désormais accès au dasboard de paiement",
  "treasury.dashboard-invit-user.modal.failure.title": "Invitation non envoyée",
  "treasury.dashboard-invit-user.modal.failure.body":
    "Erreur lors de l'envoi de l'invitation",

  "treasury.dashboard-invit-user.input.error.title": "Invitation impossible",
  "treasury.dashboard-invit-user.input.error.message.requested_user_not_found":
    "Cet utilisateur n'existe pas",
  "treasury.dashboard-invit-user.input.error.message.requested_user_is_customer":
    "Cet utilisateur a déjà accès au dashboard de paiement",
  "treasury.dashboard-invit-user.input.error.message.no_permission_exist":
    "Cet utilisateur doit d'abord être créé sur votre fiduciaire et disposer d'une permission sur votre dossier, contactez votre cabinet",
  "treasury.dashboard-invit-user.input.error.message.already_have_access":
    "Cet utilisateur a déjà accès au dashboard de paiement",

  "treasury.auto-validation.history.modal.title": "Dernières Modifications",
  "treasury.auto-validation.history.modal.validate_directly.true":
    "<b>Activation</b> de la validation automatique par ",
  "treasury.auto-validation.history.modal.mark_as_paid_directly.true":
    "<b>Activation</b> du marquage de paiement automatique par ",
  "treasury.auto-validation.history.modal.validate_directly.false":
    "<b>Désactivation</b> de la validation automatique par ",
  "treasury.auto-validation.history.modal.mark_as_paid_directly.false":
    "<b>Désactivation</b> du marquage de paiement automatique par ",
  "treasury.auto-validation.history.modal.no-changes":
    "Aucune modification n'a été effectuée",
  "treasury.auto-validation.history.modal.ok": "OK",
  "treasury.auto-validation.empty.merchants":
    "Aucun fournisseur ne correspond à ces critères",

  "treasury.full_document.history.modal.title": "Dernières Modifications",
  "treasury.full_document.history.modal.no-changes":
    "Aucune modification n'a été effectuée",
  "treasury.full_document.history.modal.ok": "OK",
  "treasury.full_document.history.modal.mark_as_to_control":
    "<b>Marqué comme à contrôler</b> par ",
  "treasury.full_document.history.modal.validate": "<b>Validé</b> par ",
  "treasury.full_document.history.modal.mark_as_to_pay":
    "<b>Marqué comme à payé</b> par ",
  "treasury.full_document.history.modal.mark_as_paid":
    "<b>Marqué comme payé</b> par ",
  "treasury.full_document.history.modal.reject": "<b>Rejeté</b> par ",
  "treasury.full_document.history.modal.mark_as_irrecoverable":
    "<b>Marqué comme irrécouvrable</b> par ",
  "treasury.full_document.history.modal.mark_as_payment_received":
    "<b>Marqué comme paiement reçu</b> par ",
  "treasury.full_document.history.modal.mark_as_to_receive":
    "<b>Marqué comme à recevoir</b> par ",
  "treasury.full_document.history.modal.mark_as_received":
    "<b>Marqué comme reçu</b> par ",

  "treasury.user.history.modal.title": "Dernières Modifications",
  "treasury.user.history.modal.no-changes":
    "Aucune modification n'a été effectuée",
  "treasury.user.history.modal.ok": "OK",
  "treasury.user.history.modal.payment_limit":
    "<b>Changement de la limite de paiement</b> de <b>{oldValue}</b> à <b>{newValue}</b> par ",
  "treasury.user.history.modal.validation_payment_limit":
    "<b>Changement de la limite de de validation</b> de <b>{oldValue}</b> à <b>{newValue}</b> par ",
  "treasury.user.history.modal.payment_limit.remove":
    "<b>Retrait</b> l'autorisation de paiement par ",
  "treasury.user.history.modal.validation_payment_limit.remove":
    "<b>Retrait</b> de l'autorisation de validation de paiement par ",
  "treasury.user.history.modal.can_mark_as_paid.false":
    "<b>Retrait du droit de l'option marqué comme payé</b> par ",
  "treasury.user.history.modal.can_mark_as_paid.true":
    "<b>Accès accordée pour l'option marqué comme payé</b> par ",
  "treasury.user.history.modal.can_reject_payment.false":
    "<b>Retrait du droit de rejet</b> par ",
  "treasury.user.history.modal.can_reject_payment.true":
    "<b>Accès accordé pour le rejet</b> par ",
  "treasury.user.history.modal.can_mark_as_irrecoverable.false":
    "<b>Retrait de l'option marqué comme irrécouvrable</b> par ",
  "treasury.user.history.modal.can_mark_as_irrecoverable.true":
    "<b>Accès accordé pour marquer comme irrécouvrable</b> par ",
  "treasury.user.history.modal.can_mark_as_payment_received.false":
    "<b>Retrait de l'option marqué comme paiement reçu</b> par ",
  "treasury.user.history.modal.can_mark_as_payment_received.true":
    "<b>Accès accordé pour l'option marquer comme paiement reçu</b> par ",
  "treasury.user.history.modal.invit_user": "<b>Utilisateur invité</b> par ",

  "treasury.success.header": "Modification réussie",
  "treasury.success.body": "L'accès a correctement été effectuée",
  "treasury.failure.header": "Erreur",
  "treasury.failure.validate.header": "Validation(s) impossible",
  "treasury.failure.mark_as_paid.header":
    "Document(s) non marqué(s) comme payé(s)",
  "treasury.failure.reject.header": "Rejet(s) impossible",
  "treasury.failure.body":
    "La modification n'a pas pu être effectuée, veuillez contacter l'équipe Chaintrust",
  "treasury.try_access.headband":
    "Testez cette fonctionnalité gratuitement et faîtes nous vos retours",

  "treasury.to-control.ask-validation-to-client":
    "Vous devez demander la permission à votre client",

  "batch_document.toaster.deactivate.success.title": "Document(s) supprimé(s)",
  "batch_document.toaster.deactivate.success.body":
    "Le ou les documents ont été supprimés",
  "full_document.toaster.deactivate.title": "Document(s) supprimé(s)",
  "full_document.toaster.deactivate.body":
    "Le ou les documents ont été supprimés",
}
