import styled from "styled-components/macro"
import { ReactNode } from "react"
import { colors } from "../styles/design.config"
import * as Ct from "ldlj"
import { SpotNotifCenter } from "./SpotNotification"

interface TabsProps {
  tabs: TabObject[]
  selectedTabIndex: number
}

export interface TabObject {
  title: string
  icon: ReactNode
  content: ReactNode
  onClick: () => void
  disabled?: boolean
  needNotif?: boolean
}

export const FullTabs = ({ tabs, selectedTabIndex = 0 }: TabsProps) => {
  return (
    <>
      <Nav>
        <TabsWrapper id="tabList">
          {tabs.map((tab, i) => {
            return (
              <>
                <TabWrapper
                  isSelected={i === selectedTabIndex}
                  key={tab.title}
                  onClick={() => {
                    tab.onClick()
                  }}
                  disabled={tab.disabled || false}
                >
                  <Tab
                    isSelected={i === selectedTabIndex}
                    disabled={tab.disabled || false}
                  >
                    {tab.icon}
                    <Ct.Spacer />
                    {tab.title}

                    {tab.needNotif && <SpotNotifCenter>1</SpotNotifCenter>}
                  </Tab>
                </TabWrapper>
                {i !== tabs.length && <Ct.Spacer width={1} />}
              </>
            )
          })}
        </TabsWrapper>
      </Nav>
      <Ct.Spacer height={2} />
      {tabs[selectedTabIndex].content}
    </>
  )
}

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid ${colors.navy};
`

const TabWrapper = styled.div<{ isSelected: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected, disabled }) =>
    disabled ? colors.moon : isSelected ? colors.navy : "white"};
  color: ${({ isSelected, disabled }) =>
    disabled ? colors.slateGrey : isSelected ? "white" : colors.navy};
  transition: 0.5s background-color ease-in-out, 0.5s color ease-in-out;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  flex: 1;

  box-shadow: ${({ isSelected }) =>
    isSelected
      ? "4px 0px 12px rgba(0, 0, 0, 0)"
      : "4px 0px 12px rgba(0, 0, 0, 0.1)"};

  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  height: 5rem;
`

const Tab = styled.div<{ isSelected: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: ${({ isSelected, disabled }) =>
      disabled ? colors.slateGrey : isSelected ? "white" : colors.navy};
    transition: 0.5s fill ease-in-out;
  }
`
const Nav = styled.nav`
  width: 100%;
`
