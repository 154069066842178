import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { Background } from "../../components/Background"
import styled from "styled-components/macro"
import { ReactComponent as AcceptTerms } from "../../assets/accept-terms.svg"
import { useEffect, useState } from "react"
import * as yup from "yup"
import { useRNBSelector } from "../../store/rootReducer"
import { FieldValues, UseFormRegister, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  getCurrentUserThunk,
  updateUserThunk,
} from "../../store/ducks/user.ducks"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { UserRegisterPayload } from "../../store/ducks/login.ducks"
import { sizes } from "../../styles/design.config"

export const AcceptTermsAndConditions = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [acceptedConditions, setAcceptedConditions] = useState(false)
  const [acceptedSalesConditions, setAcceptedSalesConditions] = useState(false)
  const userEmail = useRNBSelector((state) => state.user.email)
  const userId = useRNBSelector((state) => state.user.id)
  const typology = useRNBSelector((state) => state.user.typology)
  const updateStatus = useRNBSelector((state) => state.user.updateStatus)
  const user = useRNBSelector((state) => state.user)
  const initialFirstName = useRNBSelector((state) => state.user.firstName)
  const initialLastName = useRNBSelector((state) => state.user.lastName)
  const registeringStatus = useRNBSelector((state) => {
    return state.login.registering
  })
  const getCurrentUserStatus = useRNBSelector((state) => {
    return state.user.status
  })

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })

  const { register, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "onChange",
  })

  const userRegistration = ({ firstName, lastName }: UserRegisterPayload) => {
    dispatch(
      updateUserThunk(
        firstName,
        lastName,
        userId,
        userEmail,
        acceptedSalesConditions,
        acceptedConditions
      )
    )
  }

  const firstName = watch("firstName")
  const lastName = watch("lastName")

  useEffect(() => {
    setValue("firstName", initialFirstName)
    setValue("lastName", initialLastName)
  }, [initialFirstName, initialLastName])

  useEffect(() => {
    if (updateStatus === "SUCCESS" && getCurrentUserStatus === "SUCCESS") {
      navigate(`/office/fiduciary/companies`)
    } else if (updateStatus === "IDLE" && getCurrentUserStatus === "IDLE") {
      dispatch(getCurrentUserThunk())
    }
  }, [dispatch, updateStatus, navigate, getCurrentUserStatus])

  useEffect(() => {
    if (user.status === "ERROR") navigate("/user/login")
    if (
      user.status === "SUCCESS" &&
      ["customer", "collaborator"].includes(user.typology) &&
      user.terms_of_service_accepted_at
    )
      navigate("/office/fiduciary/companies")
    if (
      user.status === "SUCCESS" &&
      ["administrator", "manager"].includes(user.typology) &&
      user.terms_of_sales_accepted_at &&
      user.terms_of_service_accepted_at
    ) {
      navigate("/office/fiduciary/companies")
    }
  }, [user])

  return (
    <Background>
      <Content>
        <Ct.Title
          text={intl.formatMessage(
            { id: "accept-terms.title" },
            {
              adminTitle: ["administrator", "manager"].includes(typology)
                ? "& CGVs"
                : "",
            }
          )}
        />
        <Ct.Subtitle text={intl.formatMessage({ id: "register.subtitle" })} />
        <RowStyle>
          <StyledAccpetTerms />
          <Ct.Card>
            <Ct.StyledForm onSubmit={handleSubmit(userRegistration)}>
              <>
                <Ct.Spacer height={1.5} />

                <Ct.Input
                  name="email"
                  label={intl.formatMessage({
                    id: "user.incomplete-registration.email",
                  })}
                  type="email"
                  value={userEmail}
                  disabled={true}
                />

                <Ct.Spacer height={2} />

                {registeringStatus === "EMAIL_EXISTING_ERROR" && (
                  <Ct.Text
                    text={intl.formatMessage({
                      id: `user.incomplete-registration.duplicate`,
                    })}
                    textStyle={{ color: "amaranth", fontSize: 1.75 }}
                  />
                )}

                <Ct.Spacer
                  height={registeringStatus === "EMAIL_EXISTING_ERROR" ? 3 : 2}
                />

                <Ct.StyledDuoInput>
                  <Ct.Input
                    name="firstName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.first-name",
                    })}
                    type="text"
                    value={firstName}
                  />
                  <Ct.Spacer width={3} />
                  <Ct.Input
                    name="lastName"
                    register={
                      register as unknown as UseFormRegister<FieldValues>
                    }
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.last-name",
                    })}
                    type="text"
                    value={lastName}
                  />
                </Ct.StyledDuoInput>

                <Ct.Spacer height={3} />

                <AlignedStart>
                  <Ct.Checkbox
                    onChange={() => setAcceptedConditions(!acceptedConditions)}
                    name="acceptConditions"
                    isChecked={acceptedConditions}
                    label={intl.formatMessage({
                      id: "user.incomplete-registration.conditions",
                    })}
                  />
                  <Ct.Spacer width={0.5} />
                  <Ct.Text
                    text={intl.formatMessage({
                      id: "user.incomplete-registration.conditions-link",
                    })}
                    textStyle={{
                      fontWeight: 600,
                      color: "cornflower",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      typology === "customer"
                        ? window.open(
                          `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGU_CLIENTS.pdf`,
                          "_blank"
                        )
                        : window.open(
                          `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGU_EC.pdf`,
                          "_blank"
                        )
                    }}
                  />
                </AlignedStart>

                {["administrator", "manager"].includes(typology) && (
                  <>
                    <Ct.Spacer height={2} />
                    <AlignedStart>
                      <Ct.Checkbox
                        onChange={() => {
                          setAcceptedSalesConditions(!acceptedSalesConditions)
                        }}
                        name="acceptSalesConditions"
                        isChecked={acceptedSalesConditions}
                        label={intl.formatMessage({
                          id: "user.incomplete-registration.conditions",
                        })}
                      />
                      <Ct.Spacer width={0.5} />

                      <Ct.Text
                        text={intl.formatMessage({
                          id: "user.incomplete-registration.sales-conditions-link",
                        })}
                        textStyle={{
                          fontWeight: 600,
                          color: "cornflower",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            `https://chaintrust-cgus.s3.eu-west-3.amazonaws.com/CGV_EC.pdf`,
                            "_blank"
                          )
                        }
                      />
                    </AlignedStart>
                  </>
                )}

                <Ct.Spacer height={4} />
                <Ct.Button
                  width={sizes.button.standard}
                  disabled={
                    !firstName ||
                    firstName === "" ||
                    !lastName ||
                    lastName === "" ||
                    !acceptedConditions ||
                    (["administrator", "manager"].includes(typology) &&
                      !acceptedSalesConditions)
                  }
                  type="submit"
                  label={intl.formatMessage({
                    id: "user.incomplete-registration.cta",
                  })}
                />
              </>
            </Ct.StyledForm>
          </Ct.Card>
        </RowStyle>
      </Content>
    </Background>
  )
}

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const RowStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
`
const StyledAccpetTerms = styled(AcceptTerms)`
  height: 350px;
  padding-right: 20rem;
`

const AlignedStart = styled.div`
  align-self: start;
  display: flex;
  align-items: column;
`
