import { InvoiceDraftsPayload } from "../store/ducks/invoicing.duck"
import { formatDateFRstring } from "./date"

export const sortDraftsByType = (
  drafts: InvoiceDraftsPayload[],
  asc: boolean
) => {
  const documentTypeValues: Record<
    "creditNote" | "quotation" | "invoice",
    number
  > = {
    creditNote: 3,
    invoice: 2,
    quotation: 1,
  }
  const sortedDrafts = drafts.sort((a, b) => {
    return asc
      ? documentTypeValues[b.document_type] -
          documentTypeValues[a.document_type]
      : documentTypeValues[a.document_type] -
          documentTypeValues[b.document_type]
  })
  return sortedDrafts
}

export const sortDraftsByDate = (
  drafts: InvoiceDraftsPayload[],
  asc: boolean
) => {
  const sortedDrafts = drafts.sort((a, b) => {
    return asc
      ? +new Date(b.created_at) - +new Date(a.created_at)
      : +new Date(a.created_at) - +new Date(b.created_at)
  })
  return sortedDrafts
}
export const sortDraftsByAmount = (
  drafts: InvoiceDraftsPayload[],
  asc: boolean
) => {
  const sortedDrafts = drafts.sort((a, b) => {
    const amountA = a.amount || 0
    const amountB = b.amount || 0
    return asc ? amountB - amountA : amountA - amountB
  })

  return sortedDrafts
}

export const sortDraftsByClient = (
  drafts: InvoiceDraftsPayload[],
  asc: boolean
) => {
  const sortedDrafts = drafts.sort((a, b) => {
    const clientA = a.client || ""
    const clientB = b.client || ""
    return asc ? clientB.localeCompare(clientA) : clientA.localeCompare(clientB)
  })

  return sortedDrafts
}
export const sortDraftsByIssueBy = (
  drafts: InvoiceDraftsPayload[],
  asc: boolean
) => {
  const sortedDrafts = drafts.sort((a, b) => {
    const issueByA = a.issue_by || ""
    const issueByB = b.issue_by || ""
    return asc
      ? issueByB.localeCompare(issueByA)
      : issueByA.localeCompare(issueByB)
  })

  return sortedDrafts
}

export const filterDraftsBySearch = (
  invoices: InvoiceDraftsPayload[],
  search: string
) => {
  return invoices.filter(
    (invoice) =>
      invoice.client?.toLowerCase().includes(search.toLowerCase()) ||
      invoice.issue_by?.toLowerCase().includes(search.toLowerCase()) ||
      String(invoice.amount)?.toLowerCase().includes(search.toLowerCase()) ||
      formatDateFRstring(invoice.created_at)
        ?.toLowerCase()
        .includes(search.toLowerCase())
  )
}
