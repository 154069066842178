import { Merchant } from "../../store/ducks/merchants.ducks"
import { Account } from "../../store/ducks/accounts.ducks"
import { Indexed } from "../../utils/asyncTools"
import styled from "styled-components/macro"
import { colors } from "../../styles/design.config"
import { buyOrSell } from "../../utils/company"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ReactComponent as Cross } from "../../assets/cross.svg"
import { MultiSelect } from "../Commons/MultiSelect"

export interface modifyMerchantPros {
  merchant: Merchant
  availableAccounts: Indexed<Account>
  buyOrSell: buyOrSell
  onChange: (bannedAccounts: Array<Account>) => void
  selectedDefaultAccountId: number | null
}

export type optionType = { value: string; label: string }

export const ModifyMerchantBan = (props: modifyMerchantPros) => {
  const intl = useIntl()

  const [bannedAccounts, setBannedAccounts] = useState(
    props.merchant.banned_accounts
  )

  const optionOfAccount = (account: Account) => ({
    label: `${account.number} - ${account.details}`,
    value: String(account.id),
  })
  const [banableAccounts, setBanableAccounts] = useState<
    ReadonlyArray<Account>
  >([])
  const defaultAccount = props.merchant[`${props.buyOrSell}_default_account`]

  const availableAccounts = useMemo(
    () => ({ ...props.availableAccounts }),
    [props.availableAccounts]
  )
  const filterBanableAccounts = useCallback(
    (account: Account) =>
      !bannedAccounts?.map((a) => a.id).includes(account.id) &&
      props.selectedDefaultAccountId !== account.id &&
      (defaultAccount === null || defaultAccount.id !== account.id),
    [defaultAccount, bannedAccounts, props.selectedDefaultAccountId]
  )
  const onChange = useCallback(
    (accounts: Account[]) => props.onChange(accounts),
    [props]
  )

  const [options, setOptions] = useState<Ct.Option<string>[]>()
  const [selection, setSelection] = useState<ReadonlyArray<optionType> | null>(
    []
  )

  useEffect(() => {
    setBanableAccounts(
      (Object.values({ ...availableAccounts }) as Array<Account>).filter(
        filterBanableAccounts
      )
    )
    onChange(bannedAccounts)
  }, [
    availableAccounts,
    defaultAccount,
    bannedAccounts,
    props.selectedDefaultAccountId,
    onChange,
    filterBanableAccounts,
  ])

  useEffect(() => {
    setOptions(banableAccounts.map(optionOfAccount))
  }, [banableAccounts])

  const getAccountstoBanFromSelection = (
    selection: ReadonlyArray<optionType>
  ) => {
    const selectedAccountsToBan: Array<Account> = []
    for (const selected of selection) {
      const account = banableAccounts.find(
        (ba) => ba.id === Number(selected.value)
      )
      if (account) selectedAccountsToBan.push(account)
    }
    return selectedAccountsToBan
  }

  const onClick = () => {
    if (selection && selection.length > 0) {
      const selectedAccountsToBan = getAccountstoBanFromSelection(selection)
      setBannedAccounts(
        bannedAccounts.concat(
          selectedAccountsToBan.filter(filterBanableAccounts)
        )
      )
      setSelection([])
    }
  }

  const removeAccount = (id: number) => {
    setBannedAccounts(bannedAccounts.filter((account) => account.id !== id))
  }

  return (
    <StyledFullCell>
      <StyledInputLabel>
        {intl.formatMessage({
          id: `office.modify-merchant.${props.buyOrSell}.forbidden-account.label`,
        })}
      </StyledInputLabel>
      <Ct.Spacer height={2} />

      {options && (
        <StyledMultiSelectContainer>
          <MultiSelect
            intl={intl}
            field={{}}
            options={options}
            value={selection}
            label={"details"}
            domain={`office.modify-merchant.${props.buyOrSell}.forbidden-account`}
            optionType={"label"}
            onChange={(newValue) => {
              setSelection(newValue as optionType[])
            }}
            canToggleAllOptions={true}
          />
          <Ct.Spacer width={3} />
          <StyledButton label={"Ajouter"} onClick={onClick} />
        </StyledMultiSelectContainer>
      )}

      <Ct.Spacer height={2} />
      <StyledBanContainer>
        {bannedAccounts?.map((account) => (
          <StyledAccountContainer key={account.id}>
            <span>
              {account.number} - {account.details}
            </span>
            <StyledCross onClick={() => removeAccount(account.id)} />
          </StyledAccountContainer>
        ))}
      </StyledBanContainer>
    </StyledFullCell>
  )
}

const StyledCross = styled(Cross)`
  height: 1rem;
  width: 1rem;
  margin-left: 1rem;
  cursor: pointer;

  & path {
    fill: white;
  }
`

const StyledButton = styled((props) => <Ct.Button {...props} />)`
  width: 13rem;
`

const StyledMultiSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledFullCell = styled.div`
  flex-direction: column;
  display: flex;
  width: 110rem;
`

const StyledBanContainer = styled.div`
  width: 108rem;
  min-height: 15rem;
  background-color: ${colors.desertStorm};
  border-radius: 3rem;
  border: solid 1px;
  border-color: ${colors.moon};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
`

const StyledAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.cornflower};
  border-radius: 1rem;
  height: 2.75rem;
  color: ${colors.white};
  align-items: center;
  font-size: 1.5rem;
  line-height: 2.75rem;
  margin: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding-left: 1rem;
  padding-right: 1rem;
`

const StyledInputLabel = styled.h3`
  text-transform: uppercase;
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${colors.navy};
`
