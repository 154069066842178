import { Dispatch } from "../store.config"

import axios from "axios"
import { AnyAction } from "redux"

const enum CaptureActionsEnum {
  ADD_CAPTURE_MAIL_SUCCESS = "CAPTURE/addCaptureMailSuccess",
  ADD_CAPTURE_MAIL_FAILURE = "CAPTURE/addCaptureMailFailure",
  ADD_CAPTURE_MAIL_REACTIVATION = "CAPTURE/addCaptureMailReactivation",
  ADD_CAPTURE_MAIL_EMAIL_USED = "CAPTURE/addCaptureMailEmailUsed",
  ADD_CAPTURE_MAIL_RESET = "CAPTURE/addCaptureMailReset",
  GET_CAPTURE_MAIL_ENTITIES_ATTEMPT = "CAPTURE/getCaptureMailEntitiesAttempt",
  GET_CAPTURE_MAIL_ENTITIES_SUCCESS = "CAPTURE/getCaptureMailEntitiesSuccess",
  GET_CAPTURE_MAIL_ENTITIES_FAILURE = "CAPTURE/getCaptureMailEntitiesFailure",
  DELETE_CAPTURE_MAIL_USER_SUCCESS = "CAPTURE/deleteCaptureMailEntitySuccess",
  DELETE_CAPTURE_MAIL_USER_FAILURE = "CAPTURE/deleteCaptureMailEntityFailure",
  DELETE_CAPTURE_MAIL_USER_RESET = "CAPTURE/deleteCaptureMailEntityResetAction",
}

export const addCaptureMailSuccessAction = () =>
  ({
    type: CaptureActionsEnum.ADD_CAPTURE_MAIL_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "capture-mail.toaster.title",
      bodyKey: "capture-mail.addition.success",
    },
  } as const)
export const addCaptureMailFailureAction = () =>
  ({ type: CaptureActionsEnum.ADD_CAPTURE_MAIL_FAILURE } as const)
export const addCaptureMailReactivationAction = () =>
  ({ type: CaptureActionsEnum.ADD_CAPTURE_MAIL_REACTIVATION } as const)
export const addCaptureMailEmailUsedAction = (error: string) =>
  ({
    type: CaptureActionsEnum.ADD_CAPTURE_MAIL_EMAIL_USED,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "capture-mail.toast.error.title",
      bodyKey: error,
    },
  } as const)
export const addCaptureMailResetAction = () =>
  ({ type: CaptureActionsEnum.ADD_CAPTURE_MAIL_RESET } as const)
export const getCaptureMailEntitiesAttemptAction = () =>
  ({ type: CaptureActionsEnum.GET_CAPTURE_MAIL_ENTITIES_ATTEMPT } as const)
export const getCaptureMailEntitiesSuccessAction = (
  payload: CaptureMailEntities[]
) =>
  ({
    type: CaptureActionsEnum.GET_CAPTURE_MAIL_ENTITIES_SUCCESS,
    payload,
  } as const)
export const getCaptureMailEntitiesFailureAction = () =>
  ({ type: CaptureActionsEnum.GET_CAPTURE_MAIL_ENTITIES_FAILURE } as const)

export const deleteCaptureMailEntitySuccessAction = () =>
  ({
    type: CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "capture-mail.toaster.title",
      bodyKey: "capture-mail.removal.success",
    },
  } as const)
export const deleteCaptureMailEntityFailureAction = () =>
  ({ type: CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_FAILURE } as const)
export const deleteCaptureMailEntityResetAction = () =>
  ({ type: CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_RESET } as const)

type CaptureActionsType = ReturnType<
  | typeof addCaptureMailSuccessAction
  | typeof addCaptureMailFailureAction
  | typeof addCaptureMailReactivationAction
  | typeof addCaptureMailEmailUsedAction
  | typeof addCaptureMailResetAction
  | typeof getCaptureMailEntitiesAttemptAction
  | typeof getCaptureMailEntitiesSuccessAction
  | typeof getCaptureMailEntitiesFailureAction
  | typeof deleteCaptureMailEntitySuccessAction
  | typeof deleteCaptureMailEntityFailureAction
  | typeof deleteCaptureMailEntityResetAction
>

export interface CaptureState {
  captureMailStatus:
    | "UNSET"
    | "SUCCESS"
    | "ERROR"
    | "REACTIVATED"
    | "EMAIL_USED"
  captureMailEntities: CaptureMailEntities[]
  deleteCaptureMailEntitiesStatus: "UNSET" | "SUCCESS" | "ERROR"
}

export const captureInitialState: CaptureState = {
  captureMailStatus: "UNSET",
  captureMailEntities: [],
  deleteCaptureMailEntitiesStatus: "UNSET",
}

export function captureReducer(
  state = captureInitialState,
  action: CaptureActionsType
): CaptureState {
  switch (action.type) {
    case CaptureActionsEnum.ADD_CAPTURE_MAIL_SUCCESS:
      return { ...state, captureMailStatus: "SUCCESS" }
    case CaptureActionsEnum.ADD_CAPTURE_MAIL_FAILURE:
      return { ...state, captureMailStatus: "ERROR" }
    case CaptureActionsEnum.ADD_CAPTURE_MAIL_REACTIVATION:
      return { ...state, captureMailStatus: "REACTIVATED" }
    case CaptureActionsEnum.ADD_CAPTURE_MAIL_EMAIL_USED:
      return { ...state, captureMailStatus: "EMAIL_USED" }
    case CaptureActionsEnum.ADD_CAPTURE_MAIL_RESET:
      return { ...state, captureMailStatus: "UNSET" }
    case CaptureActionsEnum.GET_CAPTURE_MAIL_ENTITIES_SUCCESS:
      return { ...state, captureMailEntities: action.payload }
    case CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_SUCCESS:
      return { ...state, deleteCaptureMailEntitiesStatus: "SUCCESS" }
    case CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_FAILURE:
      return { ...state, deleteCaptureMailEntitiesStatus: "ERROR" }
    case CaptureActionsEnum.DELETE_CAPTURE_MAIL_USER_RESET:
      return { ...state, deleteCaptureMailEntitiesStatus: "UNSET" }
    default:
      return { ...state }
  }
}

interface CaptureMailEntities {
  id: number
  denomination: string
  email: string
}

interface CaptureMailEntitiesResponse {
  first_name: string
  last_name: string
  denomination?: string
  id: number
  email: string
}

export interface CaptureMailRegisterPayload {
  email: string
  companyId: number | undefined
}

export const attemptToRegisterCaptureMailEntitiesThunk =
  ({ email, companyId }: CaptureMailRegisterPayload) =>
  (dispatch: Dispatch<CaptureActionsType>) => {
    const data = {
      capture_email: {
        company_id: companyId,
        email: email,
      },
    }
    return axios
      .post(`/companies/${companyId}/capture_emails`, data)
      .then((response) => {
        if (response.data === "User reactivated") {
          dispatch(addCaptureMailReactivationAction())
        } else {
          dispatch(addCaptureMailSuccessAction())
        }
      })
      .catch((e) => {
        if (
          e.response.data.error ===
            "Cet email de capture est déjà enregistré" ||
          e.response.data.error ===
            "Un utilisateur existe déjà avec cet email : désactivez-le si vous souhaitez créer un email d'envoi" ||
          e.response.data.error === "Cet email appartient à une autre company !"
        ) {
          dispatch(addCaptureMailEmailUsedAction(e.response.data.error))
        } else {
          dispatch(addCaptureMailFailureAction())
        }
      })
  }

export const getCaptureMailEntitiesThunk =
  (currendCompanyId: number | null) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getCaptureMailEntitiesAttemptAction())
    return axios
      .get<CaptureMailEntitiesResponse[]>(
        `/companies/${currendCompanyId}/capture_emails`
      )
      .then(({ data }) => {
        const formattedUsers = data.map((user) => ({
          id: user.id,
          denomination: user.denomination
            ? user.denomination
            : user.first_name + " " + user.last_name,
          email: user.email,
        }))
        dispatch(getCaptureMailEntitiesSuccessAction(formattedUsers))
      })
      .catch(() => {
        dispatch(getCaptureMailEntitiesFailureAction())
      })
  }

export interface CaptureMailDeletePayload {
  id: number
  selectedCompanyId: number | null
}

export const attemptToDeleteCaptureMailEntitiesThunk =
  ({ id, selectedCompanyId }: CaptureMailDeletePayload) =>
  (dispatch: Dispatch<CaptureActionsType>) => {
    return axios
      .delete(`/companies/${selectedCompanyId}/capture_emails/${id}`)
      .then(() => {
        dispatch(deleteCaptureMailEntitySuccessAction())
      })
      .catch(() => {
        dispatch(deleteCaptureMailEntityFailureAction())
      })
  }
