export const isFile = (file: File | FileSystemFileEntry): file is File => {
  return (file as File).size !== undefined
}

export const isFileSystemFileEntry = (
  file: File | FileSystemFileEntry
): file is FileSystemFileEntry => {
  return (file as FileSystemFileEntry).file !== undefined
}

export const acceptedBatchExtensions = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "tiff",
  "tif",
]
