export function convertEnumToArray<T>(enumParam: T): Array<[string, string]> {
  return Object.entries(enumParam).map((option) => option)
}

interface AddOrReplaceBy<T> {
  array: Array<T>
  addIfItemNotFound: (elem: T) => boolean
  item: T
}
export function addOrReplaceBy<T>({
  array,
  addIfItemNotFound,
  item,
}: AddOrReplaceBy<T>): T[] {
  const index = array.findIndex(addIfItemNotFound)
  return index === -1
    ? [...array, item]
    : [...array.slice(0, index), item, ...array.slice(index + 1)]
}

export function chunkArray<T>(
  myArray: Array<T>,
  chunkSize: number
): Array<Array<T>> {
  let results = []

  if (chunkSize === 0 || chunkSize > myArray.length) {
    return [myArray]
  }

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize))
  }
  return results
}

type THelper<T, U> = T extends string
  ? { [P in T]: P extends U ? P : never }[T] extends { [P in T]: unknown }
    ? U
    : never
  : U

export function includes<
  T,
  U extends THelper<T, U> extends never ? unknown : never
>(arr: readonly T[], searchElement: U, fromIndex?: number): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (arr as any).includes(searchElement, fromIndex)
}
