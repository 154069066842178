import { useEffect } from "react"
import { useDispatch } from "react-redux"

import * as Ct from "ldlj"

import { useIntl } from "react-intl"
import {
  billsResponse,
  getBillsFromFiduciaryThunk,
  getUrlForBillThunk,
} from "../../../store/ducks/fiduciary.ducks"
import { useRNBSelector } from "../../../store/rootReducer"
import styled from "styled-components/macro"
import { boxShadow, colors, opacityColors } from "../../../styles/design.config"
import { useNavigate } from "react-router-dom"
import { getCurrentUserThunk } from "../../../store/ducks/user.ducks"
import { formatDateFRdate } from "../../../utils/date"
import { Table } from "../../../components/Commons/Table"

const Bills = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCurrentUserThunk())
  }, [dispatch])

  const userTypology = useRNBSelector((state) => state.user.typology)
  if (userTypology && userTypology !== "administrator")
    navigate("/unauthorized")

  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const bills = useRNBSelector((state) => state.fiduciary.bills)

  useEffect(() => {
    if (fiduciary.id !== 0 && !bills) {
      dispatch(getBillsFromFiduciaryThunk(fiduciary.id))
    }
  }, [dispatch, fiduciary.id])

  const formatAmount = (amount: number) => {
    var amountFormatted
    amountFormatted =
      (amount / 100).toLocaleString("nl-NL", { minimumFractionDigits: 2 }) +
      " \u20AC"
    return amountFormatted
  }

  const columns = [
    {
      headerText: "fiduciary-bill.date",
      content: (bill: billsResponse) => (
        <div>{formatDateFRdate(bill?.date)}</div>
      ),
    },
    {
      headerText: "fiduciary-bill.amount",
      content: (bill: billsResponse) => <div>{formatAmount(bill?.amount)}</div>,
    },
    {
      headerText: "fiduciary-bill.status",
      content: (bill: billsResponse) => (
        <ColorStatus status={bill.status}>
          {bill?.status ? "Payée" : "En Cours"}
        </ColorStatus>
      ),
    },
    {
      headerText: "fiduciary-bill.download",
      content: (bill: billsResponse) => (
        <Ct.Button
          width={15}
          height={4}
          label={intl.formatMessage({
            id: "fiduciary-bill.download",
          })}
          onClick={() => dispatch(getUrlForBillThunk(bill.id))}
        />
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        <StyledContent>
          {bills !== null && bills.length !== 0 ? (
            <Table
              intl={intl}
              columns={columns}
              alignItems={"center"}
              width={"100%"}
              rows={bills}
              customScrollBar={true}
            />
          ) : (
            <StyledCard>
              {intl.formatMessage({
                id: "fiduciary-bill.empty",
              })}
            </StyledCard>
          )}
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

export default Bills

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 3rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`

interface Status {
  status: string
}

const ColorStatus = styled.div<Status>`
  color: ${({ status }) =>
    status ? `${colors.shamrock}` : `${colors.orange}`};
  background-color: ${({ status }) =>
    status ? `${opacityColors.shamrock}` : `${opacityColors.orange}`};
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 11rem;
  height: 3rem;
`

const StyledContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
`

const StyledCard = styled(Ct.Card)`
  border: 1px ${colors.lavender} solid;
`
