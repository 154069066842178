import { Dispatch, RNBThunkAction } from "../store.config"

import { AnyAction } from "redux"
import { wait } from "../../utils/asyncTools"
import axios, { AxiosError } from "axios"
import { GetCompanyThunk, UpdateNoFec } from "./companies.ducks"
import { UploadStatus } from "../../components/DropFile"
import { getUrlFailureAction } from "./invoicing.duck"

const enum standardLedgerActionEnum {
  SET_UPLOAD_PROGRESS = "STANDARD_LEDGER/setUploadProgress",
  SET_UPLOAD_STATUS = "STANDARD_LEDGER/setUploadStatus",
  RESET_UPLOAD = "STANDARD_LEDGER/resetUpload",
  DELETE_AFTER_FAILURE_ERROR = "STANDARD_LEDGER/deleteFailureUpload",
  CHECK_CREATE_WITHOUT_FEC_ATTEMPT_ATTEMPT = "STANDARD_LEDGER/CheckCreateWithoutFecAttempt",
  CHECK_CREATE_WITHOUT_FEC_ATTEMPT_SUCCESS = "STANDARD_LEDGER/CheckCreateWithoutFecSuccess",
  CHECK_CREATE_WITHOUT_FEC_ATTEMPT_FAILURE = "STANDARD_LEDGER/CheckCreateWithoutFecFailure",
  CHECK_CREATE_WITHOUT_FEC_ATTEMPT_RESET = "STANDARD_LEDGER/CheckCreateWithoutFecReset",
  CREATE_WITHOUT_FEC_ATTEMPT = "STANDARD_LEDGER/CreateWithoutFecAttempt",
  CREATE_WITHOUT_FEC_SUCCESS = "STANDARD_LEDGER/CreateWithoutFecSuccess",
  CREATE_WITHOUT_FEC_FAILURE = "STANDARD_LEDGER/CreateWithoutFecFailure",
  CREATE_WITHOUT_FEC_RESET = "STANDARD_LEDGER/CreateWithoutFecReset",
}

const setUploadProgress = (payload: number) =>
  ({ type: standardLedgerActionEnum.SET_UPLOAD_PROGRESS, payload } as const)
export const setUploadStatus = (payload: UploadStatus) =>
  ({ type: standardLedgerActionEnum.SET_UPLOAD_STATUS, payload } as const)
export const resetUpload = () =>
  ({
    type: standardLedgerActionEnum.RESET_UPLOAD,
  } as const)
export const failAndDestroyFailure = (error: AxiosError) =>
  ({
    type: standardLedgerActionEnum.DELETE_AFTER_FAILURE_ERROR,
    error,
  } as const)

export const CheckCreateWithoutFecAttempt = () =>
  ({
    type: standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_ATTEMPT,
  } as const)
export const CheckCreateWithoutFecSuccess = (id: number | null) =>
  ({
    type: standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_SUCCESS,
    id,
  } as const)
export const CheckCreateWithoutFecFailure = () =>
  ({
    type: standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_FAILURE,
  } as const)
export const CheckCreateWithoutFecReset = () =>
  ({
    type: standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_RESET,
  } as const)

export const CreateWithoutFecAttempt = () =>
  ({
    type: standardLedgerActionEnum.CREATE_WITHOUT_FEC_ATTEMPT,
  } as const)
export const CreateWithoutFecReset = () =>
  ({
    type: standardLedgerActionEnum.CREATE_WITHOUT_FEC_RESET,
  } as const)
export const CreateWithoutFecSuccess = () =>
  ({
    type: standardLedgerActionEnum.CREATE_WITHOUT_FEC_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `initializationFEC.no-fec.transfer-title`,
      bodyKey: `initializationFEC.no-fec.transfer-message`,
    },
  } as const)
export const CreateWithoutFecFailure = () =>
  ({
    type: standardLedgerActionEnum.CREATE_WITHOUT_FEC_FAILURE,
  } as const)

type standardLedgerActionType = ReturnType<
  | typeof setUploadProgress
  | typeof setUploadStatus
  | typeof resetUpload
  | typeof failAndDestroyFailure
  | typeof CheckCreateWithoutFecAttempt
  | typeof CheckCreateWithoutFecSuccess
  | typeof CheckCreateWithoutFecFailure
  | typeof CheckCreateWithoutFecReset
  | typeof CreateWithoutFecAttempt
  | typeof CreateWithoutFecSuccess
  | typeof CreateWithoutFecReset
  | typeof CreateWithoutFecFailure
>

export interface standardLedgerState {
  uploadStatus: UploadStatus
  uploadProgress: number
  checkCreateWithoutFecStatus: "idle" | "loading" | "can" | "cannot" | "failure"
  company_to_copy: number | null
  createWithoutFecStatus: "idle" | "notIdle" | "success"
}

export const standardLedgerInitialState: standardLedgerState = {
  uploadStatus: "start",
  uploadProgress: 0,
  checkCreateWithoutFecStatus: "idle",
  company_to_copy: null,
  createWithoutFecStatus: "idle",
}

export function standardLedgerReducer(
  state = standardLedgerInitialState,
  action: standardLedgerActionType
): standardLedgerState {
  switch (action.type) {
    case standardLedgerActionEnum.SET_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload }
    case standardLedgerActionEnum.SET_UPLOAD_STATUS:
      return { ...state, uploadStatus: action.payload }
    case standardLedgerActionEnum.RESET_UPLOAD:
      return { ...state, uploadStatus: "start", uploadProgress: 0 }
    case standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_ATTEMPT:
      return {
        ...state,
        checkCreateWithoutFecStatus: "loading",
        createWithoutFecStatus: "idle",
      }
    case standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_SUCCESS:
      return {
        ...state,
        checkCreateWithoutFecStatus: action.id === null ? "cannot" : "can",
        company_to_copy: action.id,
      }
    case standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_FAILURE:
      return { ...state, checkCreateWithoutFecStatus: "failure" }
    case standardLedgerActionEnum.CHECK_CREATE_WITHOUT_FEC_ATTEMPT_RESET:
      return { ...state, checkCreateWithoutFecStatus: "idle" }
    case standardLedgerActionEnum.CREATE_WITHOUT_FEC_ATTEMPT:
      return { ...state, createWithoutFecStatus: "notIdle" }
    case standardLedgerActionEnum.CREATE_WITHOUT_FEC_SUCCESS:
      return { ...state, createWithoutFecStatus: "success" }
    case standardLedgerActionEnum.CREATE_WITHOUT_FEC_RESET:
      return { ...state, createWithoutFecStatus: "idle" }
    case standardLedgerActionEnum.CREATE_WITHOUT_FEC_FAILURE:
      return { ...state, createWithoutFecStatus: "idle" }

    default:
      return state
  }
}

interface uploadPayload {
  companyId: string
  file: File
}
interface standardLedgerResponse {
  obj_id: string
}

export const fail = (dispatch: Dispatch<AnyAction>) => () => {
  dispatch(setUploadStatus("error"))
  dispatch(setUploadProgress(100))
}

export const uploadAndValidateStandardLedgerThunk =
  ({ companyId, file }: uploadPayload) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(setUploadStatus("uploading"))
    return createStandardLedger({ companyId, file })
      .then(uploadStandardLedger(companyId)(file)(dispatch))
      .catch(fail(dispatch))
  }

const createStandardLedger = ({ companyId, file }: uploadPayload) => {
  return axios
    .post<standardLedgerResponse>("/standard_ledgers", {
      company_id: companyId,
      original_file_name: file.name,
    })
    .then((response) => response.data.obj_id)
}

const uploadStandardLedger =
  (companyId: string) =>
  (file: File) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) =>
  (id: string) => {
    signRequest(id)
      .then(uploadFileToS3(file)(dispatch))
      .then(validate)
      .then(pollForValidation(dispatch)(companyId))
      .catch(failAndDestroy(id)(dispatch))
  }

const failAndDestroy =
  (id: string) => (dispatch: Dispatch<AnyAction>) => (error: Error) => {
    deleteStandardLedger(id)
      .then(fail(dispatch))
      .then(() => {
        if (!(error instanceof ValidationError)) throw error
      })
      .catch((error: AxiosError) => {
        dispatch(failAndDestroyFailure(error))
      })
  }

interface SignRequestResponse {
  put_url: string
}

const signRequest = (id: string) => {
  return axios
    .put<SignRequestResponse>("/standard_ledgers/sign_request", { id })
    .then((response) => ({ put_url: response.data.put_url, id: id }))
}

const config = (dispatch: Dispatch<AnyAction>) => ({
  withCredentials: false,
  onUploadProgress: (progressEvent: ProgressEvent) => {
    const progress = Math.round(
      (progressEvent.loaded * 90) / progressEvent.total
    )
    dispatch(setUploadProgress(progress))
  },
})

const uploadFileToS3 =
  (file: File) =>
  (dispatch: Dispatch<AnyAction>) =>
  ({ put_url, id }: { put_url: string; id: string }) => {
    return axios.put(put_url, file, config(dispatch)).then(() => id)
  }

const validate = (id: string) =>
  axios.put("/standard_ledgers/validate", { id }).then(() => id)

interface GetStandardLedgerResponseSig {
  standard_ledger: { validated: boolean | null }[]
}

const checkStandardLedger = (companyId: string) =>
  axios
    .get<GetStandardLedgerResponseSig>(
      `/companies/get_standard_ledger?id=${companyId}`
    )
    .then((response) => response.data.standard_ledger[0].validated)

interface pollForValidationSig {
  (dispatch: Dispatch<AnyAction | RNBThunkAction>): (
    id: string
  ) => () => Promise<AnyAction | void>
}

class ValidationError extends Error {
  constructor(message: string) {
    super(message) // (1)
    this.name = "ValidationError" // (2)
  }
}

const pollForValidation: pollForValidationSig =
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) =>
  (companyId: string) =>
  () => {
    return checkStandardLedger(companyId).then((validated) => {
      switch (validated) {
        case true: {
          dispatch(setUploadProgress(100))
          dispatch(GetCompanyThunk(Number(companyId)))
          return dispatch(setUploadStatus("success"))
        }
        case false: {
          throw new ValidationError("Invalid Standard Ledger")
        }
        case null:
          return wait(2000).then(pollForValidation(dispatch)(companyId))
      }
    })
  }

const deleteStandardLedger = (id: string) =>
  axios.delete(`/standard_ledgers/${id}`)

export const getUrlForStandardLedgerThunk =
  (standardLedgerId: number) => (dispatch: Dispatch<AnyAction>) => {
    axios
      .get<string>(`/standard_ledgers/get_url_for_standard_ledger`, {
        params: { id: standardLedgerId },
      })
      .then((response) => {
        window.open(response.data, "_blank")
      })
      .catch(() => dispatch(getUrlFailureAction()))
  }

export const CheckCreateWithoutFecThunk =
  (company_id: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(CheckCreateWithoutFecAttempt())
    return axios
      .get<number | null>(`/companies/check_creation_without_fec`, {
        params: {
          company_id: company_id,
        },
      })
      .then((response) => {
        dispatch(CheckCreateWithoutFecSuccess(response.data))
      })
      .catch(() => {
        dispatch(CheckCreateWithoutFecFailure())
      })
  }

export const CreateWithoutFecThunk =
  (company_id: number, company_to_copy: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(CreateWithoutFecAttempt())
    return axios
      .post(`/companies/create_without_fec`, {
        company_id: company_id,
        company_to_copy: company_to_copy,
      })
      .then(() => {
        dispatch(CreateWithoutFecSuccess())
        dispatch(UpdateNoFec(company_id))
        dispatch(GetCompanyThunk(Number(company_id)))
      })
      .catch(() => {
        dispatch(CreateWithoutFecFailure())
      })
  }
