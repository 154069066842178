import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { buyOrSell, getIdFromParams } from "../../utils/company"
import { colors, sizes } from "../../styles/design.config"
import { useRNBSelector } from "../../store/rootReducer"
import { ReactComponent as WithoutSiren } from "../../assets/withoutSiren.svg"
import { ReactComponent as WithSiren } from "../../assets/withSiren.svg"
import {
  createMerchantFromInputMaskThunk,
  createMerchantResetAction,
  getInputMaskIdentificationNumbersThunk,
} from "../../store/ducks/inputMask.ducks"
import { Alert } from "../Commons/Alert"
import { Text } from "../Commons/Text"
import { Select } from "../Commons/Select"
import { LockableInput } from "../Commons/LockableInput"
import { capitalizeFirstLetter, normalizeString } from "../../utils/string"
import {
  GetCompanyMerchantCodesDataThunk,
  MerchantCode,
  MerchantsOfCompany,
} from "../../store/ducks/merchantCodes.ducks"
import {
  addPrefixToCode,
  normalizeMerchantCode,
} from "../../utils/merchantCodes"
import { useDebounce } from "../../hooks/useDebounce"
import { useParams } from "react-router-dom"

/* eslint-disable camelcase */

interface MerchantCreateModalProps extends Ct.ModalComponentsProps {
  buyOrSell: buyOrSell
  companyId: number
}
export const MerchantCreateModal = ({
  onClose,
  isDisplayed,
  buyOrSell,
  companyId,
}: MerchantCreateModalProps) => {
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [selectedTab, setSelectedTab] = useState<"withSiren" | "withoutSiren">(
    "withSiren"
  )
  const [selectedSiren, setSelectedSiren] = useState<null | Ct.Option<string>>(
    null
  )
  const [selectedMerchant, setSelectedMerchant] =
    useState<null | MerchantsOfCompany>(null)

  const [selectedMerchantCode, setSelectedMerchantCode] =
    useState<null | MerchantCode>(null)

  const [merchantToCreate, setMerchantToCreate] = useState<null | string>(null)
  const [merchantCodeToCreate, setMerchantCodeToCreate] = useState<
    null | string
  >(null)

  const [isMerchantLocked, setIsMerchantLocked] = useState(false)
  const [isCentralize, setIsCentralize] = useState<boolean>(false)
  const [prefixToDisplay, setPrefixToDisplay] = useState<string>("")

  const dispatch = useDispatch()

  const {
    merchantCodes,
    selectedFullDocumentId,
    identificationNumbers,
    identificationNumbersLoadingStatus,
    merchantsOfCompany,
    createMerchantStatus,
    selectedCompany,
  } = useRNBSelector((state) => ({
    merchantCodes: state.merchantCodes.merchantCodes,
    merchantsOfCompany: state.merchantCodes.merchantsOfCompany,
    selectedFullDocumentId: state.inputMask.initial_full_document?.fd_id,
    identificationNumbers: state.inputMask.identificationNumbers,
    identificationNumbersLoadingStatus:
      state.inputMask.identificationNumbersLoadingStatus,
    createMerchantStatus: state.inputMask.createMerchantStatus,
    selectedCompany: state.companies.companies[companyId],
  }))

  useEffect(() => {
    if (selectedCompany) {
      setPrefixToDisplay(
        buyOrSell === "buy"
          ? addPrefixToCode(selectedCompany).buy
          : addPrefixToCode(selectedCompany).sell
      )
    }
  }, [selectedCompany, dispatch])

  useEffect(() => {
    if (createMerchantStatus === "success") {
      dispatch(createMerchantResetAction())
      onClose()
    }
  }, [createMerchantStatus])

  const merchantCodeOptionsFromDB = merchantCodes
    .filter((m) => m.merchants.length === 0 || m.centralize === true)
    .map((m) => ({
      value: String(m.id),
      label: m.code,
    }))

  const merchantCodeOptions = merchantCodeToCreate
    ? [
        ...merchantCodeOptionsFromDB,
        { value: "0", label: merchantCodeToCreate },
      ]
    : merchantCodeOptionsFromDB

  const merchantOptionsFromDB = merchantsOfCompany.map((m) => {
    return {
      value: String(m.id),
      label: m.code
        ? capitalizeFirstLetter(m.name) + (" / code: " + m.code)
        : capitalizeFirstLetter(m.name),
    }
  })

  const merchantOptions = merchantToCreate
    ? [...merchantOptionsFromDB, { value: "0", label: merchantToCreate }]
    : merchantOptionsFromDB

  const selectedMerchantOption =
    merchantOptions.find(
      (o) => o.value === String(selectedMerchant?.id || "0")
    ) || null

  const selectedMerchantCodeOption =
    merchantCodeOptions.find(
      (o) => o.value === String(selectedMerchantCode?.id || "0")
    ) || null

  const reset = () => {
    setSelectedMerchant(null)
    setMerchantToCreate(null)
    setMerchantCodeToCreate(null)
    setSelectedMerchantCode(null)
    setSelectedSiren(null)
    setIsMerchantLocked(false)
  }

  useEffect(() => {
    if (!selectedFullDocumentId) return
    if (!isDisplayed) {
      reset()

      return
    }
    dispatch(
      getInputMaskIdentificationNumbersThunk({
        fullDocumentId: selectedFullDocumentId,
      })
    )
  }, [isDisplayed])

  useEffect(() => {
    if (identificationNumbers.length > 0) {
      setSelectedTab("withSiren")
    } else {
      setSelectedTab("withoutSiren")
    }
  }, [identificationNumbers])

  const sirenOptions = identificationNumbers
    ? identificationNumbers.map((identificationNumber) => ({
        value: identificationNumber.identification_number,
        label: identificationNumber.identification_number,
      }))
    : []
  const selectedSirenName =
    identificationNumbers.find(
      (i) => i.identification_number === selectedSiren?.value
    )?.legal_entity_name || ""

  const isMerchantSelected =
    selectedMerchant !== null ||
    (Boolean(merchantToCreate) && selectedMerchantCodeOption !== null)

  const merchantNameAlreadyExists = merchantsOfCompany.some(
    (m) => normalizeString(m.name) === normalizeString(merchantToCreate || "")
  )

  const merchantCodeToCreateAlreadyExists = merchantCodes.find(
    (mc) => mc.code === merchantCodeToCreate
  )

  const isCreateButtonEnabled =
    !merchantNameAlreadyExists &&
    (selectedTab === "withSiren"
      ? selectedSiren && isMerchantSelected
      : isMerchantSelected) &&
    (!merchantCodeToCreateAlreadyExists ||
      merchantCodeToCreateAlreadyExists?.merchants.length === 0 ||
      merchantCodeToCreateAlreadyExists?.centralize === true ||
      (!merchantCodeToCreateAlreadyExists?.centralize && isCentralize))

  const debouncedMerchantCodeToCreate = useDebounce(merchantCodeToCreate)

  useEffect(() => {
    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))
  }, [debouncedMerchantCodeToCreate])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(25vh - 25rem)"
    >
      <Ct.Card width={"160rem"}>
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          text={`${intl.formatMessage({
            id: `input-mask.create-merchant.title.${buyOrSell}`,
          })}`}
          size={7}
        />

        <Ct.Spacer height={4} />

        <SirenOrNotPicker>
          <CircledSelection
            onClick={() => {
              if (identificationNumbers.length > 0) {
                setSelectedTab("withSiren")
                setSelectedSiren(null)
                setIsMerchantLocked(false)
              }
            }}
            isSelected={selectedTab === "withSiren"}
          >
            <StyledWithSiren isSelected={selectedTab === "withSiren"} />
            <Ct.Spacer height={0.25} />
            {intl.formatMessage({
              id: `input-mask.create-merchant.with-siren`,
            })}
          </CircledSelection>
          <CircledSelection
            onClick={() => {
              setSelectedTab("withoutSiren")
              setSelectedSiren(null)
              setIsMerchantLocked(false)
            }}
            isSelected={selectedTab === "withoutSiren"}
          >
            <StyledWithoutSiren isSelected={selectedTab === "withoutSiren"} />
            <Ct.Spacer height={0.25} />
            {intl.formatMessage({
              id: `input-mask.create-merchant.without-siren`,
            })}
          </CircledSelection>
        </SirenOrNotPicker>

        <Ct.Spacer height={4} />

        <Content>
          {identificationNumbers.length === 0 &&
            identificationNumbersLoadingStatus === "finished" && (
              <>
                <Alert alertType="warning">
                  <span>
                    {intl.formatMessage({
                      id: `input-mask.create-merchant.no-siren-found`,
                    })}
                  </span>
                </Alert>
                <Ct.Spacer height={4} />
              </>
            )}
          <Ct.Spacer height={4} />
          {selectedTab === "withSiren" && (
            <SirenFormLine>
              <Flex1>
                <Select
                  intl={intl}
                  value={selectedSiren}
                  options={sirenOptions}
                  onChangeCallback={(e: Ct.Option<string>) => {
                    const merchantId = identificationNumbers.find(
                      (i) => i.identification_number === e.value
                    )?.merchant_id

                    setSelectedSiren(e)
                    if (merchantId) {
                      setIsMerchantLocked(true)
                      setSelectedMerchant(
                        merchantsOfCompany.find((m) => m.id === merchantId) ||
                          null
                      )
                    }
                  }}
                  domain={"input-mask.create-merchant"}
                  optionType={"identification-number"}
                />
              </Flex1>
              <Ct.Spacer />

              <Flex1>
                <LockableInput
                  label={intl.formatMessage({
                    id: `input-mask.create-merchant.company-name`,
                  })}
                  isPrefilled={true}
                  locked={true}
                  value={selectedSirenName}
                />
              </Flex1>
            </SirenFormLine>
          )}
          <Ct.Spacer />
          {isMerchantLocked ? (
            <LockableInput
              label={intl.formatMessage({
                id: `input-mask.create-merchant.${buyOrSell}.merchant-name`,
              })}
              isPrefilled={true}
              locked={true}
              value={selectedMerchantOption?.label || ""}
            />
          ) : (
            <Select
              disabled={selectedTab === "withSiren" && selectedSiren === null}
              intl={intl}
              value={selectedMerchantOption}
              options={merchantOptions}
              onInputChangeCallback={(e) => {
                if (!e) {
                  return
                }

                setMerchantToCreate(e)
                setSelectedMerchant(null)
              }}
              onChangeCallback={(e) => {
                if (e.label === merchantToCreate) {
                  // enter was pressed, not a real selection
                  return
                }
                setMerchantToCreate(null)
                setSelectedMerchant(
                  merchantsOfCompany.find((m) => m.id === Number(e.value)) ||
                    null
                )
              }}
              domain={`input-mask.create-merchant.${buyOrSell}`}
              optionType={`merchant-name`}
            />
          )}
          <Ct.Spacer />
          {selectedMerchant ? (
            <LockableInput
              label={intl.formatMessage({
                id: `input-mask.create-merchant.${buyOrSell}.merchant-code`,
              })}
              isPrefilled={true}
              locked={true}
              value={selectedMerchant?.code || ""}
              prefixString={prefixToDisplay}
              padding={`0 1.75rem 0 ${
                prefixToDisplay.length > 0 ? prefixToDisplay.length + 3 : 2
              }rem`}
            />
          ) : (
            <Select
              disabled={selectedTab === "withSiren" && selectedSiren === null}
              intl={intl}
              warning={
                merchantCodeToCreateAlreadyExists &&
                !merchantCodeToCreateAlreadyExists.centralize &&
                merchantCodeToCreateAlreadyExists.merchants.length > 0
              }
              value={selectedMerchantCodeOption}
              options={merchantCodeOptions}
              onChangeCallback={(e) => {
                if (e.label.toUpperCase() === merchantCodeToCreate) {
                  // enter was pressed, not a real selection
                  return
                }

                setMerchantCodeToCreate(null)
                setSelectedMerchantCode(
                  merchantCodes.find((mc) => String(mc.id) === e.value) || null
                )
              }}
              domain={`input-mask.create-merchant.${buyOrSell}`}
              optionType={`merchant-code`}
              noOptionsMessage={intl.formatMessage({
                id: "input-mask.create-merchant.merchant-code.option",
              })}
              onInputChangeCallback={(e) => {
                if (!e) return

                setSelectedMerchantCode(null)
                setMerchantCodeToCreate(normalizeMerchantCode(e))
              }}
              selectType={"selectWithPrefix"}
              prefixString={prefixToDisplay}
            />
          )}
        </Content>
        <Ct.Spacer />
        {Boolean(merchantCodeToCreateAlreadyExists) &&
          merchantCodeToCreateAlreadyExists &&
          merchantCodeToCreateAlreadyExists.merchants.length > 0 && (
            <>
              <WarningMessage>
                <Ct.Text
                  text={intl.formatMessage(
                    {
                      id: merchantCodeToCreateAlreadyExists?.centralize
                        ? `accounting-plan.create-code.part-one.warning-centralize-one.${buyOrSell}`
                        : `accounting-plan.create-code.part-one.warning-one.${buyOrSell}`,
                    },
                    {
                      merchantName: merchantCodeToCreateAlreadyExists?.merchants
                        .map((m) => m.merchant_name)
                        .join(", "),
                    }
                  )}
                  textStyle={{
                    fontSize: 1.75,
                    color: "orange",
                  }}
                />
              </WarningMessage>
              <Ct.Spacer height={1} />

              <SwitchWrapper>
                <Ct.Switch
                  value={isCentralize}
                  onToggle={() => {
                    setIsCentralize(!isCentralize)
                  }}
                />
                <Ct.Spacer width={2} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "accounting-plan.create-code.part-one.switch-text",
                  })}
                  textStyle={{
                    fontSize: 1.75,
                    color: "navy",
                  }}
                />
              </SwitchWrapper>
              <Ct.Spacer />
            </>
          )}

        {!merchantCodeToCreateAlreadyExists &&
          isCreateButtonEnabled &&
          merchantCodeToCreate && (
            <>
              <Text
                text={intl.formatMessage({
                  id: `input-mask.create-merchant.code-warning.${buyOrSell}`,
                })}
                textStyle={{ color: "purple", fontWeight: 700 }}
              />
              <Ct.Spacer />
            </>
          )}

        <Ct.JustifyCenter>
          <Ct.Button
            label={intl.formatMessage({
              id: `input-mask.create-merchant.cta.${buyOrSell}`,
            })}
            width={sizes.button.standard}
            onClick={() => {
              if (!selectedFullDocumentId) return

              const existingMerchantCodeTyped =
                merchantCodeToCreate &&
                merchantCodeOptionsFromDB.find(
                  (mc) => mc.label === merchantCodeToCreate
                )
              const merchantCodeFromOption =
                existingMerchantCodeTyped &&
                merchantCodes.find((mc) => mc.code === merchantCodeToCreate)

              if (merchantCodeFromOption) {
                dispatch(
                  createMerchantFromInputMaskThunk({
                    selectedMerchant,
                    merchantToCreate,
                    selectedMerchantCode: merchantCodeFromOption,
                    fullDocumentId: selectedFullDocumentId,
                    merchantCodeToCreate: "",
                    selectedSiren: selectedSiren?.value || null,
                    merchantCodeToCentralize:
                      (isCentralize && merchantCodeToCreateAlreadyExists) ||
                      null,
                  })
                )
              } else {
                dispatch(
                  createMerchantFromInputMaskThunk({
                    selectedMerchant,
                    merchantToCreate,
                    selectedMerchantCode,
                    fullDocumentId: selectedFullDocumentId,
                    merchantCodeToCreate: merchantCodeToCreate,
                    selectedSiren: selectedSiren?.value || null,
                    merchantCodeToCentralize:
                      (isCentralize && merchantCodeToCreateAlreadyExists) ||
                      null,
                  })
                )
              }
            }}
            disabled={!isCreateButtonEnabled}
          />
        </Ct.JustifyCenter>
      </Ct.Card>
    </Ct.Modal>
  )
}

const SirenOrNotPicker = styled.div`
  width: 60rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 15rem;
`
const SirenFormLine = styled.div`
  display: flex;
`
const Flex1 = styled.div`
  flex: 1;
`

const Content = styled.div`
  width: 88rem;
`

interface CircleProps {
  isSelected: boolean
  disabled?: boolean
}

const CircledSelection = styled.div<CircleProps>`
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "default " : "pointer")};

  width: ${({ isSelected }) => (isSelected ? "15rem " : "12rem")};
  height: ${({ isSelected }) => (isSelected ? "15rem " : "12rem")};
  background-color: ${({ isSelected }) =>
    isSelected ? colors.cornflower : colors.mist};

  color: ${({ isSelected }) => (isSelected ? colors.white : colors.cornflower)};
  font-size: ${({ isSelected }) => (isSelected ? "2rem" : "1.5rem")};
  font-weight: 600;
  transition: all 0.3s ease-in-out;
`

const StyledWithSiren = styled(WithSiren)<CircleProps>`
  & path {
    fill: ${({ isSelected }) =>
      isSelected ? colors.white : colors.cornflower};
    transition: fill 0.3s ease-in-out;
  }
`
const StyledWithoutSiren = styled(WithoutSiren)<CircleProps>`
  & path {
    fill: ${({ isSelected }) =>
      isSelected ? colors.white : colors.cornflower};
    transition: fill 0.3s ease-in-out;
  }
`

const SwitchWrapper = styled.div`
  width: 88rem;
  display: flex;
  align-items: center;
`
const WarningMessage = styled.div`
  width: 88rem;
  display: flex;
  align-items: flex-start;
`
