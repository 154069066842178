/* eslint-disable camelcase */
import { Company } from "../store/ducks/companies.ducks"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { ReactComponent as HeartFilled } from "../assets/heart-filled.svg"
import { ReactComponent as HeartEmpty } from "../assets/heart-empty.svg"
import { croppedEndString } from "../utils/string"
import ReactTooltip from "react-tooltip"
import { Text } from "../components/Commons/Text"
import { KPIFile } from "../store/ducks/kpis.ducks"

export const FileRow = ({
  company,
  companyKPIs,
}: {
  company: Company
  companyKPIs: KPIFile
}) => {
  const intl = useIntl()

  return (
    <CardWithMargin key={company?.id} width={"auto"}>
      <ContentRow>
        <CompanyInfo>
          <WrapperCompanyName
            data-tip={
              company?.user_company_name
                ? company.user_company_name
                : company?.name
            }
          >
            <Ct.Text
              text={croppedEndString(
                company?.user_company_name
                  ? company.user_company_name
                  : company?.name,
                21
              )}
              textStyle={{
                fontFamily: "Poppins",
                fontSize: 2,
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            />
            <Ct.Spacer width={1} />
            {company.is_favorite ? <HeartFilled /> : <HeartEmpty />}

            <ReactTooltip
              delayShow={300}
              effect={"solid"}
              eventOff="mouseleave scroll mousewheel blur"
            />
          </WrapperCompanyName>
          <Ct.Spacer height={0.5} />
          <Ct.Text
            text={company?.accounting_software_reference || " "}
            textStyle={{
              color: "cornflower",
              fontSize: 1.5,
              fontWeight: 600,
            }}
          />

          <Ct.Spacer height={0.5} />
          <Ct.Text
            text={intl.formatMessage(
              {
                id: "office.fiduciary.kpis.company.siren",
              },
              { siren: company.informations?.siren }
            )}
            textStyle={{
              fontSize: 1.5,
              color: "slateGrey",
            }}
          />

          <Ct.Spacer height={0.5} />
          <Text
            text={intl.formatMessage(
              {
                id: "office.fiduciary.kpis.company.created_by",
              },
              { email: companyKPIs.email }
            )}
            textStyle={{
              fontSize: 1.5,
              color: "slateGrey",
              wordBreak: "break-word",
            }}
          />

          <Ct.Spacer height={0.5} />
          <Ct.Text
            text={intl.formatMessage(
              {
                id: "office.fiduciary.kpis.company.created_at",
              },
              {
                date: String(
                  new Date(companyKPIs.created_at).toLocaleDateString()
                ),
              }
            )}
            textStyle={{
              fontSize: 1.5,
              color: "slateGrey",
            }}
          />

          <Ct.Spacer height={0.5} />
          <Ct.Text
            text={intl.formatMessage({
              id: `fiduciary.companies.accounting_type.${companyKPIs.accounting_type}`,
            })}
            textStyle={{
              color: "slateGrey",
              fontSize: 1.5,
              fontWeight: 500,
            }}
          />
        </CompanyInfo>
        <StyledVerticalSeparator />
        <Item>
          <Ct.Text
            text={String(companyKPIs?.total_fd_count || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <StyledVerticalSeparator />

        <Item>
          <Ct.Text
            text={String(companyKPIs?.total_fd_period || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <StyledVerticalSeparator />

        <Item>
          <Ct.Text
            text={String(companyKPIs?.buy || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <StyledVerticalSeparator />

        <Item>
          <Ct.Text
            text={String(companyKPIs?.sell || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <StyledVerticalSeparator />

        <Item>
          <Ct.Text
            text={String(companyKPIs?.pending || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <StyledVerticalSeparator />

        <Item>
          <Ct.Text
            text={String(companyKPIs?.deactivated || 0)}
            textStyle={{
              fontWeight: 600,
            }}
          />
        </Item>
        <Ct.Spacer width={0} />
      </ContentRow>
    </CardWithMargin>
  )
}

const CardWithMargin = styled((props) => <Ct.Card {...props} />)`
  margin: 0 1.5rem 3rem 1.5rem;
  padding: 2.5rem 1rem 2.5rem 2.5rem;
`
const ContentRow = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledVerticalSeparator = styled((props) => (
  <Ct.VerticalSeparator {...props} />
))`
  height: auto;
`
const WrapperCompanyName = styled.div`
  display: flex;
`
const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-width: 30rem;
`
const Item = styled.div`
  width: 5%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
`
