import {
  ChangeEventHandler,
  forwardRef,
  MutableRefObject,
  RefObject,
} from "react"
import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form"
import styled from "styled-components/macro"
import { boxShadow, colors } from "../../styles/design.config"
import { ReactComponent as Exclamation } from "../../assets/exclamation-mark.svg"
import { ReactComponent as Remove } from "../../assets/remove.svg"
import { ReactComponent as Check } from "../../assets/check.svg"

export interface BaseInputProps {
  label: string
  name?: string
  register?: UseFormRegister<FieldValues>
  validations?: RegisterOptions
  type?: "password" | "text" | "checkbox" | "email" | "number"
  value: string
  showError?: boolean
  showWarning?: boolean
  showSuccess?: boolean
  autoFocus?: boolean
  id?: string
  isPrefilled?: boolean
  disabled?: boolean
  bypassDisabled?: boolean
  maxWidth?: string | "auto"
  onChange?: ChangeEventHandler<HTMLInputElement>
  onTextAreaChange?: ChangeEventHandler<HTMLTextAreaElement>
  required?: boolean
  borderRadius?: number
  maxLength?: number
  max?: string
  min?: string
  placeholder?: string
  shadowed?: boolean
  noBorder?: boolean
  readOnly?: boolean
  dataCy?: string
  onInput?: () => void
  padding?: string
  isSearch?: boolean
  prefixString?: string
  cursor?: string
  AutoGrowing?: boolean
  height?: string
  overflow?: string
}

export interface InputProps extends BaseInputProps {
  suffix?: JSX.Element
}

type DisplayedSuffixType = Pick<
  InputProps,
  "suffix" | "showWarning" | "showError" | "showSuccess"
>
const displayedSuffix = ({
  suffix,
  showWarning,
  showError,
  showSuccess,
}: DisplayedSuffixType): JSX.Element | undefined => {
  if (suffix) {
    return suffix
  }
  if (showError) {
    return <Remove />
  }
  if (showWarning) {
    return <StyledExclamation />
  }
  if (showSuccess) {
    return <Check />
  }
}

export const Input = forwardRef<RefObject<HTMLInputElement>, InputProps>(
  (
    {
      label,
      name = "",
      type = "text",
      suffix,
      register,
      validations,
      value,
      showError = false,
      showWarning = false,
      showSuccess = false,
      autoFocus = false,
      id = "",
      isPrefilled = false,
      disabled = false,
      bypassDisabled = false,
      maxWidth = "auto",
      onChange,
      onTextAreaChange,
      required = true,
      borderRadius = 1.25,
      maxLength,
      max,
      min,
      placeholder,
      shadowed = false,
      noBorder = false,
      readOnly,
      dataCy,
      onInput,
      padding,
      isSearch,
      prefixString,
      cursor,
      AutoGrowing,
      height,
      overflow,
    }: InputProps,
    forwardRef
  ) => {
    if (!register) {
      return (
        <StyledInputWrapper
          showError={showError}
          showWarning={showWarning}
          showSuccess={showSuccess}
          disabled={disabled}
          maxWidth={maxWidth}
          bypassDisabled={bypassDisabled}
          shadowed={shadowed}
        >
          {AutoGrowing ? (
            <StyledAutoGrowingInput
              required={required}
              showWarning={showWarning}
              showError={showError}
              showSuccess={showSuccess}
              autoFocus={autoFocus}
              disabled={disabled}
              onInput={onInput}
              value={value}
              onChange={onTextAreaChange}
              borderRadius={borderRadius}
              bypassDisabled={bypassDisabled}
              id={id}
              maxLength={maxLength}
              placeholder={placeholder}
              noBorder={noBorder}
              readOnly={readOnly}
              data-cy={dataCy}
              prefixString={prefixString}
              padding={padding}
              cursor={cursor}
              overflow={overflow}
              height={height}
            />
          ) : (
            <StyledInput
              type={type}
              showError={showError}
              showWarning={showWarning}
              showSuccess={showSuccess}
              autoFocus={autoFocus}
              disabled={disabled}
              onChange={onChange}
              borderRadius={borderRadius}
              bypassDisabled={bypassDisabled}
              value={value}
              onInput={onInput}
              id={id}
              maxLength={maxLength}
              max={max}
              min={min}
              placeholder={placeholder}
              noBorder={noBorder}
              readOnly={readOnly}
              data-cy={dataCy}
              isSearch={isSearch}
              prefixString={prefixString}
              padding={padding}
              cursor={cursor}
            />
          )}

          <StyledLabel
            value={Number(value) >= 0 || value !== null ? value : ""}
            showError={showError}
            showWarning={showWarning}
            showSuccess={showSuccess}
            isPrefilled={isPrefilled || Boolean(AutoGrowing)}
            disabled={disabled}
            bypassDisabled={bypassDisabled}
            isSearch={isSearch}
            prefixString={prefixString}
            cursor={cursor}
          >
            {label}
          </StyledLabel>
          {prefixString && prefixString !== "" && (
            <PrefixStringWrapper>{prefixString + " "}</PrefixStringWrapper>
          )}
          <SuffixWrapper isSearch={isSearch}>
            {displayedSuffix({ suffix, showWarning, showError, showSuccess })}
          </SuffixWrapper>
        </StyledInputWrapper>
      )
    }

    const { ref, ...rest } = register(name, validations)

    return (
      <StyledInputWrapper
        showError={showError}
        showWarning={showWarning}
        showSuccess={showSuccess}
        disabled={disabled}
        maxWidth={maxWidth}
        bypassDisabled={bypassDisabled}
        shadowed={shadowed}
      >
        {AutoGrowing ? (
          <StyledAutoGrowingInput
            {...rest}
            ref={(e) => {
              ref(e)
              if (forwardRef && e) {
                const inputRef =
                  forwardRef as unknown as MutableRefObject<HTMLTextAreaElement>
                inputRef.current = e
              }
            }}
            required={required}
            showWarning={showWarning}
            showError={showError}
            showSuccess={showSuccess}
            autoFocus={autoFocus}
            disabled={disabled}
            onInput={onInput}
            borderRadius={borderRadius}
            bypassDisabled={bypassDisabled}
            id={id}
            maxLength={maxLength}
            placeholder={placeholder}
            noBorder={noBorder}
            readOnly={readOnly}
            data-cy={dataCy}
            prefixString={prefixString}
            padding={padding}
            cursor={cursor}
            overflow={overflow}
            height={height}
          />
        ) : (
          <StyledInput
            {...rest}
            ref={(e) => {
              ref(e)
              if (forwardRef && e) {
                const inputRef =
                  forwardRef as unknown as MutableRefObject<HTMLInputElement>
                inputRef.current = e
              }
            }}
            required={required}
            type={type}
            showWarning={showWarning}
            showError={showError}
            showSuccess={showSuccess}
            autoFocus={autoFocus}
            disabled={disabled}
            onInput={onInput}
            borderRadius={borderRadius}
            bypassDisabled={bypassDisabled}
            id={id}
            maxLength={maxLength}
            max={max}
            min={min}
            placeholder={placeholder}
            noBorder={noBorder}
            readOnly={readOnly}
            data-cy={dataCy}
            prefixString={prefixString}
            padding={padding}
            cursor={cursor}
            height={height}
            overflow={overflow}
          />
        )}

        <StyledLabel
          value={value || ""}
          showError={showError}
          showWarning={showWarning}
          showSuccess={showSuccess}
          disabled={disabled}
          isPrefilled={isPrefilled}
          bypassDisabled={bypassDisabled}
        >
          {label}
        </StyledLabel>

        {prefixString && prefixString !== "" && (
          <PrefixStringWrapper>{prefixString}</PrefixStringWrapper>
        )}

        <SuffixWrapper>
          {displayedSuffix({ suffix, showWarning, showError, showSuccess })}
        </SuffixWrapper>
      </StyledInputWrapper>
    )
  }
)

Input.displayName = "Input"

interface LabelWithValue {
  value: string
  showError: boolean
  showWarning: boolean
  showSuccess: boolean
  isPrefilled: boolean
  disabled: boolean
  bypassDisabled: boolean
  isSearch?: boolean
  prefixString?: string
  cursor?: string
}

interface SuffixProps {
  isSearch?: boolean
}

interface InputWithError {
  bypassDisabled: boolean
  showWarning: boolean
  showError: boolean
  showSuccess: boolean
  disabled: boolean
  maxWidth?: string | "auto"
  borderRadius?: number
  shadowed?: boolean
  noBorder?: boolean
  padding?: string
  isSearch?: boolean
  prefixString?: string
  cursor?: string
  overflow?: string
  AutoGrowing?: boolean
  height?: string
}

const StyledInputWrapper = styled.div<InputWithError>`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth === "auto" ? "auto" : maxWidth)};
  display: flex;
  position: relative;
  font-family: "Roboto", sans-serif;
  border-radius: 0.75rem;
  box-shadow: ${({ shadowed }) => (shadowed ? `${boxShadow}` : "none")};

  & input:focus {
    + label {
      color: ${(props) =>
        props.showWarning
          ? colors.orange
          : props.showError
          ? colors.amaranth
          : props.showSuccess
          ? colors.shamrock
          : colors.cornflower};
      top: -0.75rem;
      font-size: 1.5rem;
    }

    + label + div > div > svg > path {
      fill: ${(props) =>
        props.showWarning
          ? colors.orange
          : props.showError
          ? colors.amaranth
          : props.showSuccess
          ? colors.shamrock
          : colors.cornflower};
    }
  }
  & textarea:focus {
    + label {
      color: ${(props) =>
        props.showWarning
          ? colors.orange
          : props.showError
          ? colors.amaranth
          : props.showSuccess
          ? colors.shamrock
          : colors.cornflower};
      top: -0.75rem;
      font-size: 1.5rem;
    }

    + label + div > div > svg > path {
      fill: ${(props) =>
        props.showWarning
          ? colors.orange
          : props.showError
          ? colors.amaranth
          : props.showSuccess
          ? colors.shamrock
          : colors.cornflower};
    }
  }
`
const PrefixStringWrapper = styled.span`
  position: absolute;
  left: 2rem;
  top: 1.75rem;
  color: ${colors.cornflower};
`
const SuffixWrapper = styled.div<SuffixProps>`
  position: absolute;
  right: ${(props) => (props.isSearch ? "1rem" : "1.25rem")};
  top: ${(props) => (props.isSearch ? "1.5rem" : "2rem")};
  max-width: ${(props) => (props.isSearch ? "3rem" : "2rem")};
  max-height: ${(props) => (props.isSearch ? "3rem" : "2rem")};
`

const StyledLabel = styled.label<LabelWithValue>`
  position: absolute;
  left: ${(props) => (props.isSearch ? "2rem" : "1.25rem")};
  top: ${({ value, isPrefilled, isSearch, prefixString }) =>
    isPrefilled
      ? "-0.75rem"
      : isSearch
      ? "1.5rem"
      : prefixString
      ? "-0.75rem"
      : value && value.length > 0
      ? "-0.75rem"
      : "2rem"};
  font-size: ${({ value, isPrefilled }) =>
    isPrefilled
      ? "1.5rem"
      : value && value.length === 0
      ? "1.75rem"
      : "1.5rem"};

  transition: top 0.15s ease-in-out, color 0.5s ease-in-out,
    font-size 0.15s ease-in-out;

  background-color: ${colors.white};
  color: ${(props) =>
    props.isSearch
      ? colors.slateGrey
      : props.showWarning
      ? colors.orange
      : props.showError
      ? colors.amaranth
      : props.showSuccess
      ? colors.shamrock
      : props.bypassDisabled
      ? colors.rock
      : props.disabled
      ? colors.hawkes
      : colors.rock};

  font-style: ${(props) => (props.isSearch ? "italic" : "normal")};

  padding: 0 0.5rem;
  pointer-events: none;
  user-select: none;
  cursor: ${(props) => props.cursor};
`

const StyledInput = styled.input<InputWithError>`
  flex: 1;
  height: ${({ isSearch }) => (isSearch ? "5rem" : "6rem")};
  transition: border 0.3s ease-in-out;
  outline: none;
  border-radius: ${({ borderRadius }) => `${borderRadius}rem`};
  font-size: 1.75rem;
  width: 100%;
  cursor: ${(props) => props.cursor};
  padding: ${(props) =>
    props.padding
      ? props.padding
      : props.type === "password"
      ? `0 3.5rem 0 1.75rem`
      : `0 1.75rem 0 1.75rem`};
  border: ${(props) =>
    props.noBorder
      ? `0px solid ${colors.rock};`
      : props.showWarning
      ? `1px solid ${colors.orange};`
      : props.showError
      ? `1px solid ${colors.amaranth};`
      : props.showSuccess
      ? `1px solid ${colors.shamrock};`
      : props.bypassDisabled
      ? `1px solid ${colors.rock};`
      : props.disabled
      ? `1px solid ${colors.hawkes}`
      : `1px solid ${colors.rock};`};
  color: ${(props) =>
    props.bypassDisabled
      ? colors.navy
      : props.disabled
      ? colors.hawkes
      : colors.navy};
  box-sizing: border-box;
  background-color: ${colors.white};

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
    transition: 0.5s color ease-in-out;
  }
  &:focus {
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${colors.rock};
    }
    outline: none;

    border: ${(props) =>
      props.showWarning
        ? `1px solid ${colors.orange};`
        : props.showError
        ? `1px solid ${colors.amaranth};`
        : props.showSuccess
        ? `1px solid ${colors.shamrock};`
        : `1px solid ${colors.cornflower};`};
  }
`

const StyledAutoGrowingInput = styled.textarea<InputWithError>`
  flex: 1;
  overflow: visible;
  height: ${({ isSearch, height }) =>
    height ? height : isSearch ? "5rem" : "6rem"};
  min-height: ${({ height }) => (height ? height : "auto")};
  transition: border 0.3s ease-in-out;
  outline: none;
  border-radius: ${({ borderRadius }) => `${borderRadius}rem`};
  font-size: 1.75rem;
  width: 100%;
  padding: 15px;
  cursor: ${(props) => props.cursor};
  border: ${(props) =>
    props.noBorder
      ? `0px solid ${colors.rock};`
      : props.showWarning
      ? `1px solid ${colors.orange};`
      : props.showError
      ? `1px solid ${colors.amaranth};`
      : props.showSuccess
      ? `1px solid ${colors.shamrock};`
      : props.bypassDisabled
      ? `1px solid ${colors.rock};`
      : props.disabled
      ? `1px solid ${colors.hawkes}`
      : `1px solid ${colors.rock};`};
  color: ${(props) =>
    props.bypassDisabled
      ? colors.navy
      : props.disabled
      ? colors.hawkes
      : colors.navy};
  box-sizing: border-box;
  background-color: ${colors.white};
  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`

const StyledExclamation = styled(Exclamation)`
  width: 2.5rem;
  height: 2.5rem;
`
