import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"

import {
  CanvasColorsOCR,
  DragCoordinates,
  drawCanvas,
  drawRectangle,
  Coordinates,
  NumberOrientation,
} from "../../utils/canvas"
import { ControlBar } from "./ControlBar"
import { colors } from "../../styles/design.config"
import { Spacer } from "ldlj"

interface DocumentViewerProps {
  documentId: number
  rectanglesToDraw: [coordinates: Coordinates, color: CanvasColorsOCR][] | null
  isHighlighted: boolean
  fullDocumentId?: number
  fullDocCount?: number | null
  width?: string
}

export const DocumentViewer = ({
  documentId,
  rectanglesToDraw,
  isHighlighted,
  width,
}: DocumentViewerProps) => {
  const documentImageSrc = `/documents/show_image?id=${documentId}`

  const zoomScale = 3
  let canvasRef = useRef<HTMLCanvasElement>(null)
  const canvas = canvasRef?.current
  const emptyImage = new Image()
  const [image, setImage] = useState(emptyImage)
  const [imageStatus, setImageStatus] = useState<"initial" | "loaded">(
    "initial"
  )

  useEffect(() => {
    setImageStatus("initial")
    let newImage = new Image()
    newImage.onload = () => {
      setImageStatus("loaded")
    }
    newImage.src = documentImageSrc
    setImage(newImage)
  }, [documentId])

  // not used yet, keep for potential need to draw rectangles, see king-julian repo
  const intracom = {}

  const [scale, setScale] = useState(1)
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [rotationAngle, setRotationAngle] = useState<NumberOrientation>(0)

  const [previousCoordinates, setPreviousCoordinates] =
    useState<DragCoordinates>({
      x: 0,
      y: 0,
    })
  const [dragOffset, setDragOffset] = useState<DragCoordinates>({
    x: 0,
    y: 0,
  })
  const [zoomOffset, setZoomOffset] = useState<DragCoordinates>({
    x: 0,
    y: 0,
  })
  const [dragOrigin, setDragOrigin] = useState<DragCoordinates | null>(null)

  useEffect(() => {
    setRotationAngle(0)
    setPreviousCoordinates({
      x: 0,
      y: 0,
    })
    setDragOffset({
      x: 0,
      y: 0,
    })
    setZoomOffset({
      x: 0,
      y: 0,
    })
  }, [documentId])

  const canvasWrapperHeight = canvasRef.current?.parentElement?.clientHeight
  const canvasWrapperWidth = canvasRef.current?.parentElement?.clientWidth

  useEffect(() => {
    if (!canvas || !image || !documentId || imageStatus !== "loaded") {
      return
    }

    if (canvas.width !== image.width || canvas.height !== image.height) {
      canvas.width = image.width
      canvas.height = image.height
      setTimeout(() => {
        const canvasWrapperHeight =
          canvasRef.current?.parentElement?.clientHeight
        const canvasWrapperWidth = canvasRef.current?.parentElement?.clientWidth

        drawCanvas({
          image,
          canvas,
          scale,
          rotationAngle,
          dragOffset,
          zoomOffset,
          dimensions: {
            width: canvasWrapperWidth || 0,
            height: canvasWrapperHeight || 0,
          },
        })
      })
    } else {
      drawCanvas({
        image,
        canvas,
        scale,
        rotationAngle,
        dragOffset,
        zoomOffset,
        dimensions: {
          width: canvasWrapperWidth || 0,
          height: canvasWrapperHeight || 0,
        },
      })
    }
  }, [
    canvas,
    canvas?.width,
    canvas?.height,
    documentId,
    imageStatus,
    image.width,
    image.height,
    scale,
    rotationAngle,
    dragOffset,
    zoomOffset,
  ])

  useEffect(() => {
    setTimeout(() => {
      setDragOrigin(null)
    }, 1000)
  }, [])

  useEffect(() => {
    if (!canvas || !intracom || !documentId || !rectanglesToDraw) return

    rectanglesToDraw.forEach(([coordinates, color]) => {
      drawRectangle({
        canvas,
        color,
        coordinates,
        rotation: rotationAngle,
        pixelGap: 2.5,
      })
    })
  }, [intracom, scale, rotationAngle, dragOffset, dragOrigin, image, canvas])

  return (
    <>
      <Wrapper>
        {Boolean(documentId) && (
          <Canvas
            onClick={(event) => {
              if (!canvas) return

              if (isZoomedIn) {
                setScale(1)
                setDragOffset({
                  x: 0,
                  y: 0,
                })

                setScale(1)
                setIsZoomedIn(false)
                setPreviousCoordinates({
                  x: event.clientX,
                  y: event.clientY,
                })
                setZoomOffset({
                  x: 0,
                  y: 0,
                })
              } else {
                const canvasRealWidth =
                  canvasRef.current?.parentElement?.clientWidth || 0
                const clickInCanvasElementX = event.nativeEvent.offsetX
                let clickInRealCanvasX = 0

                const canvasRealHeight =
                  canvasRef.current?.parentElement?.clientHeight || 0
                const clickInCanvasElementY = event.nativeEvent.offsetY
                let clickInRealCanvasY = 0

                const imageRatio = image.height / image.width

                const Xmultiple = imageRatio
                const Ymultiple = 1 / imageRatio

                switch (rotationAngle) {
                  case 0:
                    clickInRealCanvasX =
                      (clickInCanvasElementX * canvas.width) /
                      (canvasRealWidth * Xmultiple)
                    clickInRealCanvasY =
                      (clickInCanvasElementY * canvas.height) /
                      (canvasRealHeight * Ymultiple)

                    break
                  case 180:
                    clickInRealCanvasX =
                      canvas.width -
                      (clickInCanvasElementX * canvas.width) /
                        (canvasRealWidth * Xmultiple)
                    clickInRealCanvasY =
                      canvas.height -
                      (clickInCanvasElementY * canvas.height) /
                        (canvasRealHeight * Ymultiple)
                    break
                  case 90:
                    clickInRealCanvasX =
                      (clickInCanvasElementY * canvas.height) /
                      (canvasRealHeight * Ymultiple)
                    clickInRealCanvasY =
                      canvas.width -
                      (clickInCanvasElementX * canvas.width) /
                        (canvasRealWidth * Xmultiple)
                    break
                  case 270:
                    clickInRealCanvasX =
                      canvas.height -
                      (clickInCanvasElementY * canvas.height) /
                        (canvasRealHeight * Ymultiple)

                    clickInRealCanvasY =
                      (clickInCanvasElementX * canvas.width) /
                      (canvasRealWidth * Xmultiple)
                    break
                  default:
                    clickInRealCanvasX =
                      (clickInCanvasElementX * canvas.width) /
                      (canvasRealWidth * Xmultiple)
                    clickInRealCanvasY =
                      (clickInCanvasElementY * canvas.height) /
                      (canvasRealHeight * Ymultiple)

                    break
                }

                const xPosition = clickInRealCanvasX
                const yPosition = clickInRealCanvasY

                setZoomOffset({
                  x: xPosition,
                  y: yPosition,
                })
                setScale(zoomScale)
                setIsZoomedIn(true)
                setPreviousCoordinates({
                  x: event.clientX,
                  y: event.clientY,
                })
              }
            }}
            isHighlighted={isHighlighted}
            width={width}
            zoomOrDezoom={isZoomedIn ? "zoom-out" : "zoom-in"}
            ref={canvasRef}
            onMouseMove={(event) => {
              if (scale === 1 || scale === image.width / image.height) {
                return
              }
              let gapX =
                (previousCoordinates.x - event.clientX) * scale * zoomScale
              let gapY =
                (previousCoordinates.y - event.clientY) * scale * zoomScale

              switch (rotationAngle) {
                case 90:
                  gapX = gapX * (image.height / image.width)
                  break
                case 270:
                  gapX = gapX * (image.height / image.width)
                  break
              }
              setDragOffset({
                x: dragOffset.x + gapX,
                y: dragOffset.y + gapY,
              })
              setPreviousCoordinates({
                x: event.clientX,
                y: event.clientY,
              })
            }}
          />
        )}
      </Wrapper>
      {Boolean(documentId) && (
        <>
          <Spacer />
          <ControlBarWrapper>
            <ControlBar
              setRotationAngle={(rotation: NumberOrientation) => {
                setDragOffset({ x: 0, y: 0 })
                setZoomOffset({ x: 0, y: 0 })

                setIsZoomedIn(false)
                setRotationAngle(rotation)
              }}
              rotationAngle={rotationAngle}
              documentImageSrc={documentImageSrc}
            />
          </ControlBarWrapper>
        </>
      )}
    </>
  )
}

const Canvas = styled.canvas<{
  isHighlighted: boolean
  zoomOrDezoom: "zoom-in" | "zoom-out"
  width?: string
}>`
  width: ${({ width }) => (width ? width : "100%")};
  cursor: ${({ zoomOrDezoom }) => zoomOrDezoom};
  opacity: ${({ isHighlighted }) => (isHighlighted ? `0.7` : `1`)};
  transition: 0.5s opacity ease-in-out;
`
const Wrapper = styled.div`
  border: 2px solid ${colors.grey};
  overflow: hidden;
  position: relative;
  align-items: center;
`

const ControlBarWrapper = styled.div``
